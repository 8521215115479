<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          dark
          class="bonus-list"
          color="transparent"
        >
          <v-card-title class="pb-0">
            <v-row>
              <v-col
                md-3
                class="pb-0"
              >
                <v-text-field
                  v-model.number="filters.year"
                  label="Ano Referência"
                  prepend-inner-icon="event"
                  type="number"
                  filled
                  hide-details
                />
              </v-col>
              <v-col
                md-3
                class="pb-0"
              >
                <routes-autocomplete-filter
                  v-model="filters.route"
                  label="Escolher Rota"
                  placeholder=" "
                  multiple
                  clearable
                  @change="onFilterChange"
                />
              </v-col>
              <v-col
                md-3
                class="pb-0"
              >
                <v-text-field
                  v-model="filters.search"
                  label="Pesquisar"
                  prepend-inner-icon="search"
                  filled
                  hide-details
                  single-line
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>

          <data-table
            ref="report"
            :name="title"
            :headers.sync="headers"
            :items="filteredBonusList"
            :sort-by.sync="filters.sort.by"
            :sort-desc.sync="filters.sort.desc"
            item-key="id"
            class="elevation-1 bonus-list-table"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, -1]
            }"
            :items-per-page="15"
          >
            <template v-slot:[`item.reasons`]="{ value }">
              <v-chip
                v-for="(reason, index) in value"
                :key="index"
                small
              >
                {{ reason }}
              </v-chip>
            </template>

            <template v-slot:[`item.lastActiveStatus`]="{ value }">
              <v-chip small>
                {{ dateFormat(value, 'DD/MM/YYYY') }}
              </v-chip>
            </template>

            <template v-slot:[`item.volSumPeriod`]="{ item }">
              <v-chip small>
                {{ dateFormat(item.volSumStartAt, 'DD/MM/YYYY') }} - {{ dateFormat(item.volSumEndAt, 'DD/MM/YYYY') }}
              </v-chip>
            </template>

            <template v-slot:[`item.installment1`]="{ item }">
              <installment-amount
                :installment="item.installment1"
                :has-status-change="item.hasStatusChange"
                hide-amount
              />
            </template>

            <template v-slot:[`item.installment2`]="{ item }">
              <installment-amount
                :installment="item.installment2"
                :has-status-change="item.hasStatusChange"
                hide-amount
              />
            </template>

            <template v-slot:[`item.installment3`]="{ item }">
              <installment-amount
                :installment="item.installment3"
                :has-status-change="item.hasStatusChange"
                hide-amount
              />
            </template>

            <template v-slot:[`item.installment4`]="{ item }">
              <installment-amount
                :installment="item.installment4"
                :has-status-change="item.hasStatusChange"
                hide-amount
              />
            </template>

            <template v-slot:[`item.installment1DueDate`]="{ item }">
              {{ item.installment1.date }}
            </template>

            <template v-slot:[`item.installment1Amount`]="{ item }">
              <installment-amount
                :installment="item.installment1"
                :has-status-change="item.hasStatusChange"
              />
            </template>

            <template v-slot:[`item.installment2DueDate`]="{ item }">
              {{ item.installment2.date }}
            </template>

            <template v-slot:[`item.installment2Amount`]="{ item }">
              <installment-amount
                :installment="item.installment2"
                :has-status-change="item.hasStatusChange"
              />
            </template>

            <template v-slot:[`item.installment3DueDate`]="{ item }">
              {{ item.installment3.date }}
            </template>

            <template v-slot:[`item.installment3Amount`]="{ item }">
              <installment-amount
                :installment="item.installment3"
                :has-status-change="item.hasStatusChange"
              />
            </template>

            <template v-slot:[`item.installment4DueDate`]="{ item }">
              {{ item.installment4.date }}
            </template>

            <template v-slot:[`item.installment4Amount`]="{ item }">
              <installment-amount
                :installment="item.installment4"
                :has-status-change="item.hasStatusChange"
              />
            </template>

            <template v-slot:[`item.history`]="{ item }">
              <v-btn
                icon
                color="white"
                @click="showProducerStatusHistory(item)"
              >
                <v-icon>history_edu</v-icon>
              </v-btn>
            </template>
          </data-table>
        </v-card>

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template #activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="printReport()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  print
                </v-icon>
              </template>

              Imprimir
            </v-tooltip>
          </v-btn>

          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="beneficiaries"
            color="deep-purple lighten-1"
            fab
            dark
            @click="showInstallmentPay"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  attach_money
                </v-icon>
              </template>

              Lança pagamento parcela
            </v-tooltip>
          </v-btn>

          <v-btn
            color="deep-orange lighten-1"
            fab
            dark
            @click="generate"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  refresh
                </v-icon>
              </template>

              <span>
                Regerar Relatório
              </span>
            </v-tooltip>
          </v-btn>
        </v-speed-dial>

        <producer-status-history-dialog ref="producerStatusHistoryDialog" />

        <producer-installment-pay-dialog
          ref="producerInstallmentPayDialog"
          :settings="settings"
          @save="onInstallmentsSave"
        />

        <v-overlay :value="loading">
          <v-card-text>
            Carregando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-overlay>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.row-editing {
  background: rgba(255, 0, 0, 0.25);
}
</style>

<script>
import _ from "lodash";
import moment from "moment";
import { sortItems } from "vuetify/lib/util/helpers";

import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import InstallmentAmount from "@/Domains/Financial/ProduerEndPeriodBonus/Components/InstallmentAmount.vue";
import ProducerStatusHistoryDialog from "@/Domains/Financial/ProduerEndPeriodBonus/Components/ProducerStatusHistoryDialog.vue";
import ProducerInstallmentPayDialog from "@/Domains/Financial/ProduerEndPeriodBonus/Components/ProducerInstallmentPayDialog.vue";

const ReasonsNonBeneficiary = {
  PRODUCER_WITHOUT_ACTIVITY_DATE: 'Produtor sem registro de aprovação',
  PRODUCER_ACTIVITY_OFF_RANGE: 'Produtor aprovado após data limite',
  PRODUCER_UNLINK_AFTER_PAYMENT: 'Produtor desvinculou após o recebimento',
};

export default {

  components: {
    ProducerStatusHistoryDialog,
    ProducerInstallmentPayDialog,
    InstallmentAmount,
    RoutesAutocompleteFilter,
  },

  mixins: [ReportMixin],

  props: {
    beneficiaries: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      // Loaders
      loading: false,

      // Filtros usados para buscar a rota
      filters: {
        sort: { by: 'producer.name', desc: false },

        year: moment().year(),

        // Rota filtrada para busca
        route: [],

        // Campo de busca para buscar pelo nome do produtor
        search: '',
      },

      // Lista de produtores para geração da bonificação
      rows: [],

      settings: {},
    };
  },

  computed: {

    title() {
      if (this.beneficiaries) {
        return `13º Produtor - Beneficiados`;
      }

      return `13º Produtor - Não Beneficiados`;
    },

    isYearFilterValid() {
      const currentYear = moment().year();

      return this.filters.year > 2020 && this.filters.year <= currentYear;
    },

    headers() {
      const beneficiaryInstallmentsHeaders = (this.settings.installments || []).reduce((acm, installment) => {
        return [
          ...acm,
          {
            text: `Parcela ${installment.index} - Vencimento`,
            value: `installment${installment.index}DueDate`,
            default: false,
            show: false,
            sortable: false,
          },
          {
            text: `Parcela ${installment.index} - Valor`,
            value: `installment${installment.index}Amount`,
            default: true,
            show: true,
            sortable: false,
          }
        ];
      }, []);

      const nonBeneficiaryInstallmentsHeaders = (this.settings.installments || []).reduce((acm, installment) => {
        return [
          ...acm,
          {
            text: `Parcela ${installment.index}`,
            value: `installment${installment.index}`,
            width: 40,
            default: true,
            show: true,
            sortable: false,
          },
        ];
      }, []);

      const beneficiaryHeaders = [
        {
          align: "center",
          text: "Cód. Produtor",
          value: "producer.code",
          default: true,
          width: 130,
          show: true,
        },
        {
          align: "start",
          text: "Produtor",
          value: "producer.name",
          default: true,
          show: true,
        },
        {
          align: "start",
          text: "Rota Principal",
          value: "route.description",
          default: true,
          show: true,
        },
        {
          align: "start",
          text: "Data Aprovação Produtor",
          value: "lastActiveStatus",
          default: false,
          show: false,
        },
        {
          align: "start",
          text: "Período Somatório de Volume",
          value: "volSumPeriod",
          width: 110,
          default: true,
          show: true,
        },
        {
          text: "Volume Total Período (L)",
          value: "totalVol",
          default: true,
          show: true,
        },
        {
          text: "Valor Bonificação",
          value: "producerTotalBonus",
          default: true,
          show: true,
        },
        ...beneficiaryInstallmentsHeaders,
        {
          text: "Histórico",
          align: "center",
          value: "history",
          default: true,
          show: true,
          sortable: false,
        },
      ];

      const nonBeneficiaryHeaders = [
        {
          align: "center",
          text: "Cód. Produtor",
          value: "producer.code",
          default: true,
          width: 130,
          show: true,
        },
        {
          align: "start",
          text: "Produtor",
          value: "producer.name",
          default: true,
          show: true,
        },
        {
          align: "start",
          text: "Rota Principal",
          value: "route.description",
          default: true,
          show: true,
        },
        {
          align: "start",
          text: "Data Aprovação Produtor",
          value: "lastActiveStatus",
          default: false,
          show: false,
        },
        {
          align: "start",
          text: "Motivos",
          value: "reasons",
          default: false,
          show: false,
        },
        ...nonBeneficiaryInstallmentsHeaders,
        {
          text: "Histórico",
          align: "center",
          value: "history",
          default: true,
          show: true,
          sortable: false,
        },
      ];

      if (this.beneficiaries) {
        return beneficiaryHeaders;
      }

      return nonBeneficiaryHeaders;
    },

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY");
    },

    /**
     * Lista filtrada de produtores
     * @returns {Array}
     */
    filteredBonusList() {
      if (_.isEmpty(this.filters.search)) {
        return this.rows;
      }

      return this.rows.filter(({ producer }) => {
        return `${producer.code || ''} - ${producer.name.toUpperCase()}`.includes(this.filters.search.toUpperCase());
      });
    },

    orderedBonusList() {
      return sortItems(this.filteredBonusList, this.filters.sort.by, this.filters.sort.desc, 'pt-BR');
    },
  },

  async mounted() {
    await this.onFilterChange();
    await this.loadSettings();
  },

  methods: {
    /**
     * Recupera a lista de bonificações
     * @returns {Promise<void>}
     */
    async loadList() {
      try {
        this.loading = true;

        let filters = {
          produtor_atende_criterios: this.beneficiaries,
        };

        if (!_.isEmpty(this.filters.route)) {
          filters = {
            ...filters,
            rotas: this.filters.route.map(route => route.id),
          }
        }

        const { data } = await this.$axios.get(
          `/bonus/end-period`,
          {
            params: filters,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.rows = data.map(producer => {
          const installmentsColumns = (producer.parcelas || []).reduce((acm, installment) => {
            return {
              ...acm,
              [`installment${installment.index}`]: installment,
            }
          }, {});

          return {
            id: producer.id_decimo_terceiro_produtor,
            producer: {
              id: producer.id_produtor,
              name: producer.nome_produtor,
              code: producer.codigo_laticinio,
              createdAt: producer.data_entrada_laticinio,
            },
            route: {
              id: producer.id_rota,
              description: producer.descricao_rota,
            },
            totalVol: producer.volume_total_periodo,
            lastActiveStatus: producer.data_ultima_aprovacao,
            volSumStartAt: producer.data_inicial_soma_volume,
            volSumEndAt: producer.data_final_soma_volume,
            producerTotalBonus: this.formatCurrency(producer.bonificacao_total_periodo, 2),
            hasStatusChange: producer.produtor_desvinculou_apos_recebimento,
            reasons: (producer.motivos_nao_beneficiario || []).map(reason => ReasonsNonBeneficiary[reason]),
            installments: producer.parcelas,
            history: producer.historico_produtor,
            ...installmentsColumns,
          }
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async loadSettings() {
      try {
        this.loading = true;

        const { data: { configuracao } } = await this.$axios.get(`/bonus/end-period/settings`,);

        if (_.isEmpty(configuracao)) {
          return false;
        }

        this.settings = configuracao;
      } catch (e) {
        console.error(e);

      } finally {
        this.loading = false;
      }
    },

    /**
     * @event void
     *
     * Evento acionado para refazer a lista com base nos novos filtros
     */
    async onFilterChange() {
      await this.loadList();
    },

    /**
     * @event string
     *
     * Evento acionado ao selecionar uma coluna para exibir na lista
     */
    onHeaderSelect(value) {
      this.headers = this.headers.map(header => {
        if (header.value === value || value === '*') {
          return {
            ...header,
            show: !header.show || header.default,
          }
        }

        return header;
      })
    },

    showProducerStatusHistory(producer) {
      return this.$refs.producerStatusHistoryDialog.show(producer);
    },

    showInstallmentPay() {
      return this.$refs.producerInstallmentPayDialog.show();
    },

    async onInstallmentsSave({ installment, dueDate, paid }) {
      try {
        this.loading = true;

        const rows = this.rows.map(row => {
          return {
            id_decimo_terceiro_produtor: row.id,
            parcelas: row.installments.map(rowInstallment => {
              if (installment.index === rowInstallment.index) {
                return {
                  ...rowInstallment,
                  date: dueDate,
                  paid,
                }
              }

              return rowInstallment;
            }),
          }
        });

        const { data } = await this.$axios.put(`/bonus/end-period/installments`, rows);

        await this.loadList();
      } catch (e) {

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async generate() {
      try {
        this.loading = true;

        if (!this.isYearFilterValid) {
          throw new Error('Ano de referência inválido');
        }

        const { data } = await this.$axios.post(`/bonus/end-period/generate`, {
          year_ref: this.filters.year,
        });

        await this.loadList();
      } catch (e) {

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    getReportTitle() {
      return `${this.title} - ${this.filters.year}`;
    },

    getReportJson() {
      return this.rows.map(row => {
        if (this.beneficiaries) {
          const rowInstallments = _.pickBy(row, (value) => value.index);
          const installments = Object.values(rowInstallments).reduce((acm, installment) => {
            acm[`Parecela ${installment.index} - Vencimento`] = this.dateFormat(installment.date, 'DD/MM/YYYY');
            acm[`Parecela ${installment.index} - Valor`] = installment.totalBonus;
            acm[`Parecela ${installment.index} - Pago`] = installment.paid ? 'Sim' : 'Não';

            return acm;
          }, {});

          return {
            'Cód. Produtor': row.producer.code,
            'Produtor': row.producer.name,
            'Rota Principal': row.route.description,
            'Data Aprovação Produtor': this.dateFormat(row.lastActiveStatus, 'DD/MM/YYYY'),
            'Data Inicial Somatório Volume': this.dateFormat(row.volSumStartAt, 'DD/MM/YYYY'),
            'Data Final Somatório Volume': this.dateFormat(row.volSumEndAt, 'DD/MM/YYYY'),
            'Volume Total Período (L)': row.totalVol,
            'Valor Bonificação': row.producerTotalBonus,
            ...installments,
          }
        }

        return {
          'Cód. Produtor': row.producer.code,
          'Produtor': row.producer.name,
          'Rota Principal': row.route.description,
          'Data Aprovação Produtor': this.dateFormat(row.lastActiveStatus, 'DD/MM/YYYY'),
          'Volume Total Período (L)': row.totalVol,
          'Motivos': row.reasons.join(','),
        }
      });
    },

    printReport() {
      const json = this.getReportJson();
      const title = this.getReportTitle();

      return this.$refs.report.print(json, title, true);
    },

    exportExcel() {
      const tipo = this.beneficiaries ? 'Beneficiados' : 'Nao_Beneficiados';
      const json = this.getReportJson();
      const filename = `13_Produtor_${this.filters.year}_${tipo}`;

      this.$refs.report.exportExcel(json, filename);
    },

    formatCurrency: (value, decimals = 4) => {
      const formatter = new Intl.NumberFormat(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
          maximumFractionDigits: decimals
        }
      );

      return formatter.format(value || 0);
    },

    dateFormat(date, format) {
      if (_.isEmpty(date)) {
        return 'Data não Localizada';
      }

      return moment(date).format(format);
    }
  },
};
</script>
