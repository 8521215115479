<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="630px"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        Finalização de Ordem de Produção nº {{ order.code }}
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="pt-5"
      >
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-text-field
                :value="order.item?.description"
                label="Produto"
                disabled
              />
            </v-col>
            <v-col
              v-if="hasPlannedQuantityAccess && order.quantity > 0"
              cols="6"
              class="pt-0"
            >
              <masked-text-field
                :value="order.quantity"
                label="Quantidade Planejada"
                :mask="masks.float"
                unmask
                :suffix="order.item?.measurement"
                disabled
              />
            </v-col>
            <v-col
              cols="6"
              class="pt-0"
            >
              <masked-text-field
                v-model="form.accomplishedQuantity"
                label="Quantidade Realizada"
                :mask="masks.float"
                unmask
                :suffix="order.item?.measurement"
                disabled
              />
            </v-col>
            <v-col
              v-if="order.lotNumber"
              cols="6"
              class="pt-0"
            >
              <v-text-field
                v-model="order.lotNumber"
                label="Nº Lote"
                prepend-inner-icon="subtitles"
                disabled
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Finalizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

import get from 'lodash/get'

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

export default {

  components: {
    MaskedTextField,
  },

  props: {
    value: Boolean,
    orderId: String,
  },

  data() {
    return {
      order: { item: {} },
      form: {},

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.loadOrder();
      }
    },
  },

  methods: {
    async loadOrder() {
      try {
        this.$root.$progressBar.loading();

        this.order = await api.show(this.orderId);

        this.form = {
          warehouseId: this.form.warehouseId,
          accomplishedQuantity: this.order.accomplishedQuantity,
        };

      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao carregar a ordem de produção');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          status: 'FECHADO',
          id_deposito: this.form.warehouseId,
          quantidade_realizada: this.form.accomplishedQuantity,
        };

        await api.update(this.order.id, payload);

        this.$emit('save');
        this.$snotify.success('Ordem finalizada com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao finalizar ordem de produção');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
    close() {
      this.show = false;
      this.order = { item: {} };
      this.form = {};
    }
  }
}
</script>
