<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Classificação de produtores
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <producer-rating
        ref="classification"
        view="goal"
      />
    </v-row>
  </div>
</template>

<script>
import ProducerRating from "@/Domains/Projects/Dairy/ProducerRating/Components/ProducerRating.vue";

export default {
  components: {
    ProducerRating,
  },

  props: ["parent"],

  data() {
    return {
     
    };
  },

  methods: {

    navigateBack() {
      this.$store.commit("setCurrentMenu", this.parent.name);
      this.$store.commit("setCurrentMenuBackground", this.parent.background);
    },
  },
};
</script>