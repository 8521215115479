<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Nota Fiscal Eletrônica</span>
        <v-spacer />
        <v-btn
          v-if="isCloseVisible"
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="6">
            <div class="font-weight-bold">
              Número da Nota
            </div>
            <div class="text-h6 secondary--text">
              {{ number }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="font-weight-bold">
              Cliente
            </div>
            <div class="text-h6 text-truncate secondary--text">
              {{ customer }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-timeline dense>
              <v-slide-x-reverse-transition
                group
                hide-on-leave
              >
                <v-timeline-item
                  v-if="isHomolog"
                  key="ambiente"
                  color="warning"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    type="warning"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          Ambiente de Homologação
                        </div>
                        <div class="body-1">
                          Documento sem valor fiscal
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
                <v-timeline-item
                  v-if="started.show"
                  key="auth"
                  :color="started.type"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    :type="started.type"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <template #prepend>
                      <v-progress-circular
                        v-if="started.loading"
                        indeterminate
                        color="info"
                      />
                    </template>
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          {{ started.text }}
                        </div>
                        <div
                          v-if="started.detail"
                          class="body-1"
                        >
                          {{ started.detail }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
                <v-timeline-item
                  v-if="checking.show"
                  key="status"
                  :color="checking.type"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    :type="checking.type"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <template #prepend>
                      <v-progress-circular
                        v-if="checking.loading"
                        indeterminate
                        color="info"
                      />
                    </template>
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          {{ checking.text }}
                        </div>
                        <div
                          v-if="checking.detail"
                          class="body-1"
                        >
                          {{ checking.detail }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="isCloseVisible"
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!started.show"
          outlined
          color="primary"
          @click="authorize()"
        >
          Autorizar
        </v-btn>
        <v-btn
          v-if="checking.text === 'Processado'"
          color="primary"
          @click="danfe()"
        >
          Imprimir Danfe
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {

  data() {
    return {
      dialog: false,

      id: null,
      number: null,
      customer: null,
      key: null,

      isHomolog: false,
      started: {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      },
      checking: {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      },
      tryNumber: 0,

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        length: len => v => (v || '').length >= len || 'Justificativa muito curta',
      },
    }
  },

  computed: {
    isCloseVisible() {
      return !this.started.show || this.checking.show || this.started.type === 'error' || this.checking.type === 'error';
    }
  },

  methods: {
    show({ id, env, number, customer, key }) {
      this.reset();
      this.isHomolog = env === 2;
      this.id = id;
      this.number = number;
      this.customer = customer;
      this.key = key;
      this.dialog = true;
    },

    reset() {
      this.isHomolog = false;
      this.started = {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      };
      this.checking = {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      };
      this.tryNumber = 0;
    },

    close() {
      this.$emit('close');
      this.dialog = false;
    },

    async authorize() {
      if (this.started.show) {
        return;
      }

      this.started.show = true;
      this.started.loading = true;
      this.started.type = 'info';
      this.started.text = 'Enviando para a SEFAZ';

      try {
        const { data } = await this.$axios.post(`/fiscal/invoice/authorize`, {
          ids: [this.id]
        });

        const { result, invoices } = data.responses[0];

        if (!result.code) {
          throw result.message;
        }

        this.started.loading = false;
        this.started.type = 'success';
        this.started.text = 'Enviado';

        this.checkStatus();

        this.handleStatusResult(invoices[0]);

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);

        this.started.loading = false;
        this.started.type = 'error';
        this.started.text = 'Erro ao autorizar nota';
        this.started.detail = message;
      }
    },

    async checkStatus() {

      try {
        const { data } = await this.$axios.post(`/fiscal/invoice/status`, {
          ids: [this.id]
        });

        const { result, invoice } = data.responses[0];

        if (!result.code) {
          throw result.message;
        }

        this.handleStatusResult(invoice);

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);

        this.checking.loading = false;
        this.checking.type = 'error';
        this.checking.text = 'Erro ao consultar status';
        this.checking.detail = message;
      }
    },

    async handleStatusResult(response) {
      this.$emit('response', response);

      if (response.status === 'PROCESSANDO') {
        this.tryNumber++;

        if (this.tryNumber >= 5) {
          this.checking.loading = false;
          this.checking.type = 'warning';
          this.checking.text = 'Processando';
          this.checking.detail = 'Não foi possível obter o retorno da SEFAZ. Tente novamente mais tarde.';
          return;
        }
        else {
          this.checking.show = true;
          this.checking.loading = true;
          this.checking.type = 'info';
          this.checking.text = 'Processando nota';
          this.checking.detail = `Obtenção do retorno [${this.tryNumber}]`;

          // Delay para consultar novamente o status do processamento
          await this.sleep(3000);

          this.checkStatus();
        }

      } else if (response.status === 'EMITIDA') {
        this.checking.show = true;
        this.checking.loading = false;
        this.checking.type = 'success';
        this.checking.text = 'Processado';
        this.checking.detail = 'Autorizada o uso da NF-e';
      }  else {
        this.checking.show = true;
        this.checking.loading = false;
        this.checking.type = 'error';
        this.checking.text = 'Rejeitado';
        this.checking.detail = response.motivo;
      }
    },

    async danfe() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/fiscal/invoice/print-danfe`, { ids: [this.id] }, { responseType: 'blob' });

        const dataObject = window.URL.createObjectURL(data);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = `${this.key}-nfe.pdf`;
        link.click();

        window.URL.revokeObjectURL(dataObject);
        link.remove();

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async sleep(delay) {
      return new Promise(function(resolve) {
        setTimeout(resolve, delay);
      })
    },

  },

}
</script>
