<template>
  <div>
    <v-dialog
      v-if="dialog.show"
      v-model="dialog.show"
      width="40%"
      persistent
    >
      <v-card :loading="loading">
        <v-card-title class="px-3 pt-0">
          <v-row>
            <v-col cols="12">
              <span class="text-h6">
                Laudos e Anexos
              </span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="unload.obs"
                label="Observações:"
                placeholder=" "
                filled
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-row>
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <v-btn
                    outlined
                    class="mb-5"
                    @click="showFileUpload"
                  >
                    <v-icon>attach_file</v-icon> Adicionar arquivos
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  v-for="(file, idx) in unload.reports"
                  :key="idx"
                  cols="3"
                >
                  <file-viewer
                    :value="file"
                    :preview-height="165"
                    @removeFile="onRemoveFile(idx)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-3"
            text
            @click="hide"
          >
            CANCELAR
          </v-btn>

          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            outlined
            @click="save"
          >
            SALVAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <file-upload
      v-model="fileUpload.show"
      extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
      accept="application/pdf,text/xml,image/*"
      @insertFiles="onSelectFiles"
    />
  </div>
</template>

<script>
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

export default {

  components: {
    FileUpload,
    FileViewer,
  },

  data() {
    return {
      loading: false,

      unload: {},

      // Dados referentes ao Dialog
      dialog: {
        show: false,
      },

      fileUpload: {
        show: false,
        tankIndex: -1
      },
    }
  },

  methods: {

    show(unload) {
      this.unload = unload;
      this.dialog.show = true;
    },

    hide() {
      this.dialog.show = false;
    },

    showFileUpload() {
      this.fileUpload.show = true;
    },

    onSelectFiles(reports) {
      for (let file of reports) {
        if (!this.unload.reports.find(o => o.name == file.name)) {
          this.unload.reports.push({
            success: true,
            name: file.name,
            size: file.size,
            url: file.url
          });
        }
      }
    },

    async onRemoveFile(idx) {
      try {
        this.loading = true;
        const fileUrl = this.unload.reports[idx].url;
        console.log({ fileUrl })
        if (fileUrl && fileUrl.includes('googleapis.com')) {
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
        }
      } catch (err) {
        console.warn(err);
      } finally {
        this.unload.reports.splice(idx, 1);
        this.loading = false;
      }
    },

    async save() {
      try {
        this.loading = true;

        const request = {
          id_descarga_coletas: this.unload.id,
          observacao: this.unload.obs,
          anexos: this.unload.reports,
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/salvaLaudos`,
          request,
        );

        if (!data.codigo) {
          throw 'Erro ao salvar';
        }

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");
        this.hide();
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

  },

}
</script>

