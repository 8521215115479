<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">{{ title }}</span>
      <v-spacer />
    </v-card-title>
    <v-row
      v-for="(item, index) in dairies"
      :key="index"
      no-gutters
      class="flex-grow-1"
    >
      <v-col
        cols="12"
        class="py-0 text-subtitle-1"
      >
        {{ item.name }}
      </v-col>
      <v-col cols="5">
        <span class="display-2 font-weight-bold mt-2 teal--text text--lighten-2">
          {{ formatNumber(item.volume?.current) }} L
        </span>
        <div>
          {{ formatDate(currentPeriod?.[0]) }} à {{ formatDate(currentPeriod?.[1]) }}
        </div>
      </v-col>
      <v-col
        cols="2"
        class="d-flex flex-column text-center pt-1"
      >
        <v-icon
          v-if="item.volume?.current > item.volume?.previous"
          size="48"
          color="green"
        >
          call_made
        </v-icon>

        <v-icon
          v-if="item.volume?.current < item.volume?.previous"
          size="48"
          color="red"
        >
          call_received
        </v-icon>
        <div class="mt-1 text-h6">
          {{ formatNumber(getPercentualDiff(item.volume?.current, item.volume?.previous)) }} %
        </div>
      </v-col>
      <v-col cols="5">
        <span class="display-2 font-weight-bold mt-2 teal--text text--lighten-2">
          {{ formatNumber(item.volume?.previous) }} L
        </span>
        <div>
          {{ formatDate(previousPeriod?.[0]) }} à {{ formatDate(previousPeriod?.[1]) }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    title: String,
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '250px',
    },
    currentPeriod: {
      type: Array,
      default: () => ([]),
    },
    previousPeriod: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      loading: false,
      dairies: [],
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      try {
        if (this.currentPeriod?.length !== 2 || this.previousPeriod?.length !== 2) {
          return
        }

        this.loading = true;

        const [currentStartDate, currentEndDate] = this.currentPeriod;
        const [previousStartDate, previousEndDate] = this.previousPeriod;

        const [{ data: { relatorio: currentAverages } }, { data: { relatorio: previousAverage } }] = await Promise.all([
          this.$axios.post(`/relatorios/mediaLeite`, { data_inicio: currentStartDate, data_fim: currentEndDate }),
          this.$axios.post(`/relatorios/mediaLeite`, { data_inicio: previousStartDate, data_fim: previousEndDate })
        ]);

        if (!_.isArray(currentAverages) || !_.isArray(previousAverage)) {
          throw "PHP Error";
        }

        const dairies = {};

        for (const average of currentAverages) {
          const name = average.laticinio;
          dairies[name] = {
            name,
            volume: {
              current: parseInt(average.volume),
              previous: 0
            }
          };
        }

        for (const average of previousAverage) {
          const name = average.laticinio;
          if (name in dairies) {
            dairies[name].volume.previous = parseInt(average.volume);
          } else {
            dairies[name] = {
              name,
              volume: {
                current: 0,
                previous: parseInt(average.volume)
              }
            };
          }
        }

        this.dairies = Object.values(dairies);

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de média de leite!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    getPercentualDiff: (firstValue, lastValue) => ((1 - (firstValue > lastValue ? (lastValue / firstValue) : (firstValue / lastValue))) * 100),
    formatDate: date => moment(date).format('DD/MM/YYYY'),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
