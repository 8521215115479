<template>
  <v-card
    class="pa-0 mb-6"
    rounded="rounded-lg elevator-1"
    color="transparent"
  >
    <v-card-title
      class="subtitle-1 pt-3 pb-3 font-weight-medium"
      style="background: rgba(0, 0, 0, 0.4);color: white !important;"
    >
      <v-tooltip
        v-if="label.trim().length > 30"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >{{ label }}</span>
        </template>
        <span>{{ label }}</span>
      </v-tooltip>
      <span v-else>{{ label }}</span>

      <v-spacer />

      <slot name="actions" />
    </v-card-title>

    <v-divider class="pa-0 ma-0" />

    <v-card-text
      style="background: rgba(0, 0, 0, 0.2);"
    >
      <!-- slot para o conteudo do card-->
      <slot />
    </v-card-text>
  </v-card>
</template>

<style lang="scss">

.question-chart{
    padding-top: 10px;
}

.card-question-chart > .v-card__title  {
  flex: 1;
  min-width: 0;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

</style>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    }
  }
}
</script>
