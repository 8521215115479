<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="orders-list"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-date-range-picker
          v-model="filters.range"
          dark
          @change="getOrders"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-select
          v-model="filters.status"
          :items="[
            { value: 'ORCAMENTO', text: 'ORÇAMENTO' },
            { value: 'PEDIDO', text: 'PEDIDO' },
            { value: 'VENDA', text: 'VENDA' }
          ]"
          dark
          clearable
          filled
          hide-details
          label="Status"
          prepend-inner-icon="rule"
          multiple
          small-chips
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-select
          v-model="filters.approvalStatus"
          :items="[
            'PENDENTE',
            'APROVADO',
            'REJEITADO'
          ]"
          dark
          clearable
          filled
          hide-details
          label="Aprovação"
          prepend-inner-icon="published_with_changes"
          multiple
          small-chips
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          v-model="filters.search"
          label="Pesquisar"
          prepend-inner-icon="search"
          dark
          filled
          hide-details
          clearable
        />
      </v-col>
    </v-row>

    <v-card
      class="report-card"
      color="transparent"
      dark
    >
      <v-card-title>
        <v-spacer />
        <div class="d-flex">
          <data-info
            title="Total Desconto"
            icon="credit_card_off"
            :value="formatCurrency(totalDiscount)"
          />
          <data-info
            title="Total Valor"
            icon="request_quote"
            :value="formatCurrency(totalAmount)"
          />
        </div>
      </v-card-title>
      <data-table
        ref="report"
        :headers="headers"
        :items="filteredOrders"
        :loading="loading"
        :name="type == 'PEDIDO_PRODUTOR' ? 'Pedidos de Produtores' : 'Pedidos Comerciais'"
        dark
        dense
        class="elevation-1 report-table"
        @click:row="showOrder"
      >
        <template #[`item.financial`]="{ value, item }">
          <template v-if="item.status == 'VENDA'">
            <v-menu
              v-if="value.length > 0"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  style="position:relative"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop
                >
                  <v-icon>
                    attach_money
                  </v-icon>
                  <v-icon
                    small
                    class="financial-badge"
                    color="green accent-4"
                  >
                    check_circle
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(conta, index) in value"
                  :key="index"
                  @click="showBill(conta.id_titulo)"
                >
                  <v-list-item-title>{{ formatCurrency(conta.valor) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip
              v-else
              top
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  style="position:relative"
                  @click.stop="generateFinancial(item)"
                  v-on="on"
                >
                  <v-icon>
                    attach_money
                  </v-icon>
                  <v-icon
                    small
                    class="financial-badge"
                    color="red accent-3"
                  >
                    remove_circle
                  </v-icon>
                </v-btn>
              </template>
              Gerar financeiro
            </v-tooltip>
          </template>
        </template>

        <template #[`item.discount`]="{ item }">
          <span v-if="item.discount">
            {{ formatCurrency(item.discount) }}
            <small>
              ({{ formatNumber(item.discountPerc) }} %)
            </small>
          </span>
        </template>

        <template #[`item.status`]="{ value }">
          <v-chip
            x-small
            :color="getStatusColor(value)"
          >
            {{ value }}
          </v-chip>
        </template>

        <template #[`item.approval.status`]="{ item }">
          <v-btn
            v-if="canApprovalPercent(item)"
            icon
            @click.stop="onApproval(item)"
          >
            <approval-chip :value="item.approval" />
          </v-btn>
          <approval-chip
            v-else
            :value="item.approval"
          />
        </template>

        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :disabled="!canApprovalPercent(item)"
                @click="onApproval(item)"
              >
                <v-list-item-icon>
                  <v-icon>pending_actions</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Aprovação</v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="remove(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <approval-dialog
      v-model="approvalDialog.show"
      :order-id="approvalDialog.id"
      @close="onCloseApproval()"
      @save="onSaveApproval()"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="showOrder({id: null})"
            v-on="on"
          >
            <v-icon>
              add
            </v-icon>
          </v-btn>
        </template>
        Novo Pedido
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel"
            v-on="on"
          >
            <v-icon>
              backup_table
            </v-icon>
          </v-btn>
        </template>
        Download (Excel)
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir
      </v-tooltip>
    </v-speed-dial>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      :type="type == 'PEDIDO_PRODUTOR' ? 'CONVENIO' : 'CONTA_A_RECEBER'"
      @input="billDialog.id = null"
    />
  </v-container>
</template>

<style lang="scss">
.orders-list {
  .financial-badge {
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 0px;
  }

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import ApprovalChip from '@/Domains/Orders/Components/ApprovalChip.vue';
import ApprovalDialog from '@/Domains/Orders/Components/ApprovalDialog.vue';
import DataInfo from '@/Domains/Financial/Components/DataInfo.vue';

import moment from "moment-timezone";
import isEmpty from "lodash/fp/isEmpty";
import sumBy from "lodash/fp/sumBy";
import { round, percentageBy } from "@/Support/Resources/utils";

moment.locale('pt');

export default {
  name: "orders-list",

  components: {
    VDateRangePicker,
    BillDialog,
    ApprovalChip,
    ApprovalDialog,
    DataInfo,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {
      loading: false,

      filters: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        search: '',
        status: [],
        approvalStatus: []
      },

      orders: [],

      billDialog: {
        show: false,
        id: null,
      },

      approvalDialog: {
        show: false,
        id: null,
      },

      commercialProfiles: {},
    };
  },

  computed: {
    headers() {
      let headers = [
        { text: 'Código', value: 'code' },
        { text: 'Data', value: 'date', formatter: value => this.dateFormat(value, 'DD/MM/YYYY') },
      ];

      if (this.type == 'PEDIDO_PRODUTOR') {
        headers.push(
          { text: 'Produtor', value: 'customer' },
          { text: 'Fornecedor', value: 'supplier' }
        );
      }
      else {
        headers.push(
          { text: 'Cliente', value: 'customer' },
          { text: 'Vendedor', value: 'seller' }
        );
      }

      headers.push(
        { text: 'Status', value: 'status' },
        { text: 'Desconto', value: 'discount', formatter: value => this.formatCurrency(value) },
        { text: 'Valor', value: 'total', formatter: value => this.formatCurrency(value) },
        { text: 'Financeiro', value: 'financial', align: 'center', width: 70, formatter: value => value.map(conta => this.formatCurrency(conta.valor)).join(' - ') },
        { text: 'Aprovação', value: 'approval.status', align: 'center', width: 150,  },
        { text: '', altText: 'Opções', value: 'action', width: 30, sortable: false, drag: false }
      );

      return headers;
    },

    /**
     * Exibe os pedidos com base nos filtros selecionados em tela
     */
    filteredOrders() {
      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;

      if (isEmpty(this.filters.status) && isEmpty(this.filters.approvalStatus) && !search) {
        return this.orders;
      }

      return this.orders.filter(order => {
        const hasStatus = isEmpty(this.filters.status) || this.filters.status.includes(order.status);
        const hasApprovalStatus = isEmpty(this.filters.approvalStatus) || this.filters.approvalStatus.includes(order.approval.status);
        const hasSearch = !search || JSON.stringify(Object.values(order)).toUpperCase().includes(search);

        return hasStatus && hasApprovalStatus && hasSearch;
      });
    },

    totalAmount() {
      return sumBy(x => parseFloat(x.total) || 0, this.filteredOrders)
    },

    totalDiscount() {
      return sumBy(x => parseFloat(x.discount) || 0, this.filteredOrders)
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    userProfile() {
      return this.$store.state.settings.user || {};
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasFinancialAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "orders-generate-financial" && o.tipo === "COMPONENTE");
    },

    hasApprovalAccess() {
      return this.isAdmin || (this.userProfile.id_cargo === 13 && !!this.userProfile.id_perfil_comercial);
    }
  },

  created() {
    this.getCommercialProfiles();
  },

  methods: {
    showOrder(order) {
      this.$emit("showOrder", order);
    },
    async getOrders() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filters.range;

        const { data } = await this.$axios.post(`/movimento/listaJson`, {
          tipo: this.type,
          data_inicio: startDate,
          data_fim: endDate
        });

        this.orders = data.map(p => {
          const discount = parseFloat(p.desconto) || 0;
          const total = parseFloat(p.valor);
          const subTotal = total + discount;
          return {
            id: p.id_movimento,
            code: p.cod_movimento,
            date: p.data_hora_cadastro,
            customer: p.nome_cliente,
            status: p.status,
            financial: JSON.parse(p.contas),
            discount: discount,
            discountPerc: p.desconto_percentual || round(percentageBy(subTotal, discount), 2),
            subTotal,
            total,
            seller: p.nome_vendedor,
            supplier: p.nome_fornecedor,
            approval: {
              status: p.aprovacao || 'PENDENTE',
              date: p.data_hora_aprovacao
            },
          }
        })
      } catch (error) {
        this.$snotify.error("Erro ao carregar os pedidos", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    showBill(id) {
      this.billDialog.id = id;
      this.billDialog.show = true;
    },

    async generateFinancial({ id }) {
      try {
        if (!this.hasFinancialAccess) {
          this.$snotify.info("Você não possui permissão para gerar esta conta", "Aviso");
          return;
        }
        this.loading = true;

        let { data } = await this.$axios.post(`/movimento/gerarFinanceiro`, { id_movimento: id });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Financeiro gerado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao gerar financeiro", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
        this.getOrders();
      }
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este pedido?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/movimento/remove`, { id_movimento: id });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.orders.splice(this.orders.findIndex(order => order.id == id), 1);
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async getCommercialProfiles() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/comercial/perfisComercial`);

        this.commercialProfiles = data.reduce((acc, cur) => ({
          ...acc,
          [cur.id_perfil_comercial]: {
            id: cur.id_perfil_comercial,
            name: cur.nome,
            description: `${cur.nome} | ${parseInt(cur.percentual_maximo)}%`,
            percent: parseInt(cur.percentual_maximo)
          }
        }), {});
      } catch (error) {
        this.$snotify.error("Erro ao carregar os perfis comerciais", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    getStatusColor(value) {
      const statusColors = {
        'PEDIDO': 'blue',
        'ORCAMENTO': 'orange darken-1',
        'VENDA': 'green darken-1',
      }
      return statusColors[value]
    },

    canApprovalPercent(item) {
      if (!this.hasApprovalAccess) {
        return false;
      }
      if (this.isAdmin) {
        return true;
      }
      const profile = this.commercialProfiles[this.userProfile.id_perfil_comercial] || {};
      return item.discountPerc <= profile.percent;
    },

    onApproval(item) {
      this.approvalDialog.id = item.id;
      this.approvalDialog.show = true;
    },

    onSaveApproval() {
      this.onCloseApproval();
      this.getOrders();
    },

    onCloseApproval() {
      this.approvalDialog.show = false;
      this.approvalDialog.id = null;
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      this.$refs.report.exportExcel();
    },

    /**
     * Imprime o relatório
     */
    print() {
      this.$refs.report.print();
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },
};
</script>
