<template>
  <div>
    <v-row>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Volume Bonificação"
          icon="icon-coleta_principal"
          :value="`${formatNumber(bonusVolume)} L`"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Volume de Coletas"
          icon="icon-frota"
          :value="`${formatNumber(collectsVolume)} L`"
        />
      </v-col>
      <v-col
        v-if="coopVolume"
        class="pt-0"
      >
        <CardInfo
          title="Volume Cooperativa"
          icon="icon-coleta_principal"
          :value="`${formatNumber(coopVolume)} L`"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Volume Descarregado"
          icon="icon-importacao"
          :value="`${formatNumber(unloadedVolume)} L`"
        />
      </v-col>
      <v-col
        v-if="totalDifference"
        class="pt-0"
      >
        <CardInfo
          title="Diferença entre Bonificação e Coletas"
          icon="report_gmailerrorred"
          icon-color="orange"
          :value="`${formatNumber(totalDifference)} L`"
        />
      </v-col>
    </v-row>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="2"
            class="pt-0"
          >
            <v-menu
              ref="menu"
              transition="scale-transition"
              offset-y
              content-class="menu-select"
            >
              <template #activator="{ on }">
                <v-text-field
                  :value="dateRefFilter"
                  label="Mês de Referência"
                  prepend-inner-icon="event"
                  readonly
                  dark
                  filled
                  hide-details
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="filter.date"
                :max="maxDateRef"
                type="month"
                no-title
                scrollable
                @change="getCollectAndBonus"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="2"
            class="pt-0"
          >
            <v-select
              v-model="filter.showType"
              :items="['DIFERENÇAS', 'TODOS', 'COOPERATIVAS']"
              label="Tipo de exibição"
              filled
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filter.search"
              label="Busca"
              append-icon="search"
              filled
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        v-if="items.length === 0"
        class="py-12 text-h6"
      >
        Selecione o mês de referência para verificação
      </v-card-text>
      <v-card-text
        v-else-if="items.length > 0 && itemsFiltered.length === 0"
        class="py-12 text-h6"
      >
        Nenhuma diferença encontrada
      </v-card-text>
      <data-table
        v-else
        :headers="headers"
        :items="itemsFiltered"
        item-key="id_produtor"
        show-expand
        single-expand
      >
        <template #[`item.nome_produtor`]="{ value, item }">
          {{ value }}
          <v-tooltip
            v-if="item.produtores.length > 1"
            top
          >
            <template #activator="{ on }">
              <v-icon
                color="rgba(255, 255, 255, 0.7)"
                v-on="on"
              >
                groups
              </v-icon>
            </template>
            {{ item.produtores.length }} produtores
          </v-tooltip>
          <v-tooltip
            v-if="item.id_cooperativa"
            top
          >
            <template #activator="{ on }">
              <v-icon
                color="orange"
                v-on="on"
              >
                groups
              </v-icon>
            </template>
            Cooperativa
          </v-tooltip>
        </template>

        <template #[`item.volume_coletas`]="{ value }">
          <v-chip small>
            {{ formatNumber(value) }} L
          </v-chip>
        </template>

        <template #[`item.volume_bonus`]="{ value }">
          <v-chip small>
            {{ formatNumber(value) }} L
          </v-chip>
        </template>

        <template #[`item.volume_diferenca`]="{ value }">
          <v-chip
            small
            :color="value != 0 ? 'orange darken-2' : 'gray'"
          >
            {{ formatNumber(value) }} L
          </v-chip>
        </template>

        <template #expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-0"
          >
            <v-row no-gutters>
              <v-col v-if="item.coletas.length > 0">
                <v-card
                  dark
                  color="transparent"
                >
                  <v-card-title class="justify-center text-body-2">
                    <span>Coletas</span>
                  </v-card-title>

                  <v-data-table
                    :headers="headersCollects"
                    :items="item.coletas"
                    item-key="id_coleta"
                    dark
                    dense
                    hide-default-footer
                    disable-pagination
                    show-expand
                    single-expand
                  >
                    <template #[`item.data`]="{ value }">
                      {{ formatDate(value, 'DD/MM/YYYY') }}
                    </template>

                    <template #[`item.quantidade_coleta`]="{ value }">
                      {{ formatNumber(value) }} L
                    </template>

                    <template #[`item.total_divisao`]="{ value }">
                      <span v-if="value">
                        {{ formatNumber(value) }} L
                      </span>
                    </template>

                    <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
                      <v-btn
                        v-if="item.coletas_agrupamento?.length > 0"
                        icon
                        link
                        transition="fab-transition"
                        @click="expand(!isExpanded)"
                      >
                        <v-icon>
                          {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                        </v-icon>
                      </v-btn>
                    </template>

                    <template #expanded-item="{ headers, item }">
                      <td
                        :colspan="headers.length"
                        class="pa-0"
                      >
                        <v-row no-gutters>
                          <v-col>
                            <v-card
                              dark
                              color="transparent"
                            >
                              <v-card-title class="justify-center text-body-2">
                                <span>Divisão de Volume do Grupo</span>
                              </v-card-title>

                              <v-data-table
                                :headers="headersGroupCollects"
                                :items="item.coletas_agrupamento"
                                dark
                                dense
                                hide-default-footer
                                disable-pagination
                              >
                                <template #[`item.volume`]="{ value }">
                                  {{ formatNumber(value) }} L
                                </template>
                              </v-data-table>
                            </v-card>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
              <v-col v-if="item.bonificacoes.length > 0">
                <v-card
                  dark
                  color="transparent"
                >
                  <v-card-title class="justify-center text-body-2">
                    <span>Bonificações</span>
                  </v-card-title>

                  <v-data-table
                    :headers="headersBonus"
                    :items="item.bonificacoes"
                    dark
                    dense
                    hide-default-footer
                    disable-pagination
                  >
                    <template #[`item.volume_resultado`]="{ value }">
                      {{ formatNumber(value) }} L
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
              <v-col v-if="item.produtores.length > 0">
                <v-card
                  dark
                  color="transparent"
                >
                  <v-card-title class="justify-center text-body-2">
                    <span>Produtores</span>
                  </v-card-title>

                  <v-data-table
                    :headers="headersProducers"
                    :items="item.produtores"
                    dark
                    dense
                    hide-default-footer
                    disable-pagination
                  />
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </data-table>
    </v-card>
  </div>
</template>

<script>
import CardInfo from "@/Domains/BI/Components/CardInfo.vue";

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

export default {
  components: {
    CardInfo
  },

  data() {
    return {
      filter: {
        date: null,
        showType: 'DIFERENÇAS',
        search: '',
      },

      headers: [
        { text: 'Código Laticinio', value: 'codigo_laticinio' },
        { text: 'Nome Produtor', value: 'nome_produtor' },
        { text: 'Rota', value: 'nome_rota' },
        { text: 'Quantidade Coletas', value: 'coletas.length', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Volume Coletas', value: 'volume_coletas', align: 'center', formatter: value => `${this.formatNumber(value)} L` },
        { text: 'Volume Bonificação', value: 'volume_bonus', align: 'center', formatter: value => `${this.formatNumber(value)} L` },
        { text: 'Diferença de Volume', value: 'volume_diferenca', align: 'center', formatter: value => `${this.formatNumber(value)} L` },
      ],

      items: [],

      bonusVolume: 0,
      collectsVolume: 0,
      unloadedVolume: 0,
      totalDifference: 0,
      coopVolume: 0,

      headersCollects: [
        { text: 'Itinerário', value: 'cod_itinerario' },
        { text: 'Produtor', value: 'nome_produtor' },
        { text: 'Rota', value: 'nome_rota' },
        { text: 'Data', value: 'data' },
        { text: 'Volume', value: 'quantidade_coleta', align: 'center' },
        { text: 'Divisão Grupo', value: 'total_divisao', align: 'center' },
      ],

      headersGroupCollects: [
        { text: 'Código Laticinio', value: 'codigo_laticinio' },
        { text: 'Nome Produtor', value: 'nome_produtor' },
        { text: 'Rota', value: 'nome_rota' },
        { text: 'Volume', value: 'volume', align: 'center' },
      ],

      headersBonus: [
        { text: 'Código Laticinio', value: 'codigo_laticinio' },
        { text: 'Nome Produtor', value: 'nome_produtor' },
        { text: 'Rota', value: 'nome_rota' },
        { text: 'Volume', value: 'volume_resultado', align: 'center' },
      ],

      headersProducers: [
        { text: 'Código Laticinio', value: 'codigo_laticinio' },
        { text: 'Nome Produtor', value: 'nome_produtor' },
        { text: 'Rota', value: 'nome_rota' },
      ],
    }
  },

  computed: {
    itemsFiltered() {
      if (this.filter.showType === 'TODOS' && !this.filter.search) {
        return this.items;
      }

      const showDifferences = this.filter.showType === 'DIFERENÇAS';
      const showCooperatives = this.filter.showType === 'COOPERATIVAS';
      const search = this.filter.search?.toUpperCase()?.trim();

      return this.items.filter(item => {
        const hasDiff = !showDifferences || item.volume_diferenca != 0;
        const hasCoop = !showCooperatives || item.id_cooperativa;
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasDiff && hasCoop && hasSearch;
      });
    },

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format('YYYY-MM');
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (isEmpty(this.filter.date)) {
        return null;
      }

      return moment(this.filter.date, 'YYYY-MM').format('MM/YYYY');
    },
  },

  methods: {
    async getCollectAndBonus() {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.post('/bonus/differences/collects', {
          'month_reference': this.filter.date,
        });
        this.items = data.comparison;
        this.bonusVolume = data.bonus_volume;
        this.collectsVolume = data.collects_volume;
        this.unloadedVolume = data.unloaded_volume;
        this.totalDifference = data.bonus_volume - data.collects_volume;
        this.coopVolume = data.coop_volume;
      } catch (error) {
        console.warn(error);
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatNumber: (value, decimals) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: decimals }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  }
}
</script>
