<template>
  <services-index
    :type="type"
    subtype="VETERINARIO"
  />
</template>

<script>
import ServicesIndex from "@/Domains/Financial/Prices/VeterinaryServices/ServicesIndex.vue";

export default {
  components: {
    ServicesIndex
  },

  props: {
    type: String,
  },
}

</script>
