<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Volume De Leite Por Produtor Visitado
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-container>
      <v-row>
        <v-spacer />
        <v-col md="4">
          <VDateRangePicker
            v-model="filter.date"
            dark
            @change="loadReport"
          />
        </v-col>
      </v-row>

      <v-card
        color="transparent"
        dark
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="2"
              class="d-flex flex-column justify-end px-14 py-0"
            >
              <span class="caption font-weight-light">
                Produtores
              </span>
              <span
                class="headline font-weight-bold text--accent-3 cyan--text"
              >{{ items.length }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="2"
              class="d-flex flex-column justify-end px-14 py-0"
            >
              <span class="caption font-weight-light">
                Visitas
              </span>
              <span
                class="headline font-weight-bold text--accent-3 cyan--text"
              >{{ coletasTotal }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              lg="2"
              class="d-flex flex-column justify-end px-14 py-0"
            >
              <span class="caption font-weight-light">
                Volume
              </span>
              <span
                class="headline font-weight-bold text--accent-3 teal--text"
              >{{ formatNumber(volumeTotal) }} L</span>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              offset-lg="2"
              class="py-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="filter.search"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.volume_total`]="{ value }">
            <span>{{ formatNumber(value) }} L</span>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "ColetaTotalLeite",

  components: {
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        search: '',
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },

      headers: [
        { text: "Produtor", value: "nome_produtor" },
        { text: "Visitas", value: "total_visita", width: 180, align: 'center' },
        { text: "Volume", value: "volume_total", width: 180, align: 'center' },
      ],
      items: [],
    };
  },

  computed: {
    coletasTotal() {
      return this.items.reduce((acc, cur) => acc + cur.total_visita, 0)
    },
    volumeTotal() {
      return this.items.reduce((acc, cur) => acc + cur.volume_total, 0)
    },
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.date;

        let { data } = await this.$axios.post(
          `/relatorios/totalLeiteProdutor`,
          this.$qs.stringify({
            data_ini: moment(startDate).format('DD/MM/YYYY'),
            data_fim: moment(endDate).format('DD/MM/YYYY'),
          })
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getReportJson() {
      return this.items.map(o => ({
        Produtor: o.nome_produtor,
        Visitas: o.total_visita,
        Volume: `${this.formatNumber(o.volume_total)} L`,
      }));
    },

    exportExcel() {
      const data = this.XLSX.utils.json_to_sheet(this.getReportJson());
      const workbook = this.XLSX.utils.book_new();
      const filename = 'Volume de leite por produtor';
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    print() {
      return this.printFromJson(this.getReportJson(), 'Volume de leite por produtor');
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
