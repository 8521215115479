<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Visitas de BPA
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <producer-rating
          view="qualidade_visita"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProducerRating from "@/Domains/Projects/PQFL/BPA/Components/ProducerRating.vue";

export default {
  components: {
    ProducerRating,
  },
};
</script>
