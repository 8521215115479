<template>
  <div>
    <v-dialog
      v-model="transferring.show"
      persistent
      max-width="630px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Transferindo entre Silos</span>
        </v-card-title>

        <v-card-text>
          <v-container class="pa-0">
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="saveTransferring()"
            >
              <v-row>
                <v-col cols="3">
                  <v-menu
                    v-model="transferring.form.transferredAt.picker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="transferring.form.transferredAt.date | date"
                        label="Data de Baixa *"
                        placeholder=" "
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="transferring.form.transferredAt.date"
                      no-title
                      @input="transferring.form.transferredAt.picker = false"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <masked-input
                    v-model="transferring.form.transferredAt.time"
                    label="Hora de Baixa *"
                    placeholder=" "
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    :mask="{
                      mask: '00:00',
                    }"
                    :max-length="5"
                    dense
                    persistent-hint
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    :value.sync="availableSilos"
                    :items="availableSilosToOrigin"
                    item-text="label"
                    item-value="id"
                    label="Origem *"
                    placeholder=" "
                    multiple
                    small-chips
                    deletable-chips
                    return-object
                    dense
                    :disabled="!canEditVolume"
                    :rules="[v => v.length > 0 || 'Campo obrigatório!']"
                  >
                    <template #item="{ item }">
                      <v-list-item-content
                        @click.stop="onSiloSelect(item)"
                      >
                        <v-list-item-title>
                          {{ item.label }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.rawProduct.id">
                          {{ item.rawProduct.name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template #selection="{ item }">
                      <v-chip x-small>
                        <span>{{ item.label }}</span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-divider v-if="availableSilos.length" />

              <div
                v-for="silo of availableSilos"
                :key="silo.id"
              >
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      :value="silo.label"
                      label="Silo"
                      filled
                      dense
                      disabled
                      hide-details
                    />
                  </v-col>

                  <v-col cols="6">
                    <masked-input
                      v-model.number="silo.transferringVol"
                      label="Volume (L) *"
                      :mask="IntNumberMask"
                      return-unmasked
                      filled
                      dense
                      :rules="getSiloRules(silo)"
                      :disabled="!canEditVolume"
                      @change="onWaterChange"
                    >
                      <template #append>
                        <v-btn
                          icon
                          @click="onSiloVolFillClick(silo)"
                        >
                          <v-icon>opacity</v-icon>
                        </v-btn>
                      </template>
                    </masked-input>
                  </v-col>

                  <v-col
                    cols="2"
                    class="d-flex justify-center align-center"
                  >
                    <v-btn
                      icon
                      shaped
                      :disabled="!canEditVolume"
                      @click="onRemoveSilo(silo)"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <v-row
                v-if="transferringVol"
                class="pa-0 justify-end text-end"
              >
                <div>
                  <v-input
                    :value="transferringVol"
                    :rules="getDestinationRules()"
                  />
                  <span
                    class="overline pr-2"
                    style="
                      font-size: 11px !important;
                      font-weight: bold !important;
                      line-height: 15px !important;
                    "
                  >
                    Total: {{ transferringVol | Mask(IntNumberMask) }} L
                  </span>
                </div>
              </v-row>

              <v-divider
                v-if="transferring.form.silosOrigin.length"
                style="padding-bottom: 20px;"
              />

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="transferring.form.destinationSilo"
                    :items="availableSiloToDestination"
                    item-text="label"
                    item-value="id"
                    label="Destino *"
                    placeholder=" "
                    small-chips
                    return-object
                    dense
                    :disabled="!canEditVolume"
                    :rules="[v => !!v.id || 'Campo obrigatório!']"
                  >
                    <template #item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.label }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="item.rawProduct.id">
                          {{ item.rawProduct.name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template #selection="{ item }">
                      <v-chip x-small>
                        <span>{{ item.label }}</span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-tabs
                v-model="analysisTab"
              >
                <v-tab
                  v-for="silo of availableSilos"
                  :key="silo.id"
                >
                  {{ silo.label }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="analysisTab">
                <v-tab-item
                  v-for="silo of availableSilos"
                  :key="silo.index"
                >
                  <v-card flat>
                    <v-card-text class="px-0 pb-0">
                      <v-row>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="silo.analysis.initialFat"
                            label="Gordura Inicial"
                            name="initialFat"
                            :mask="FloatNumberMask"
                            tabindex="1"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('acidity')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="silo.analysis.acidity"
                            label="Acidez"
                            name="acidity"
                            :mask="FloatNumberMask"
                            tabindex="2"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('finalFat')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="silo.analysis.finalFat"
                            label="Gordura Final"
                            name="finalFat"
                            :mask="FloatNumberMask"
                            tabindex="3"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('esd')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.esd"
                            label="ESD"
                            name="esd"
                            :mask="FloatNumberMask"
                            tabindex="5"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('protein')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.protein"
                            name="protein"
                            label="Proteína"
                            :mask="FloatNumberMask"
                            tabindex="6"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('milkWhey')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.milkWhey"
                            name="milkWhey"
                            label="Gordura Soro"
                            :mask="FloatNumberMask"
                            tabindex="7"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('crioscopy')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="silo.analysis.crioscopy"
                            name="crioscopy"
                            label="Crioscopia"
                            :mask="FloatNumberMask"
                            tabindex="8"
                            return-unmasked
                            dense
                            @change="onWaterChange"
                            @onEnter="goToNextInput('waterPercentage')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.waterPercentage"
                            name="waterPercentage"
                            label="Água (%)"
                            :mask="FloatNumberMask"
                            tabindex="9"
                            return-unmasked
                            dense
                            @change="onWaterPercentageChange"
                            @onEnter="goToNextInput('waterLiters')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.waterLiters"
                            name="waterLiters"
                            label="Água (L)"
                            :mask="FloatNumberMask"
                            tabindex="10"
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('phosphatase')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-autocomplete
                            ref="analysis-elm"
                            v-model="silo.analysis.phosphatase"
                            name="phosphatase"
                            label="Fosfatase"
                            placeholder=" "
                            :items="[
                              { text: 'Positivo', value: true },
                              { text: 'Negativo', value: false },
                            ]"
                            tabindex="3"
                            dense
                            @onEnter="goToNextInput('peroxide')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-autocomplete
                            ref="analysis-elm"
                            v-model="silo.analysis.peroxide"
                            name="peroxide"
                            label="Peroxidase"
                            placeholder=" "
                            :items="[
                              { text: 'Positivo', value: true },
                              { text: 'Negativo', value: false },
                            ]"
                            tabindex="4"
                            dense
                            @onEnter="goToNextInput('esd')"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="isSaveAvailable"
            color="blue darken-1"
            text
            @click="onTransferringSaveClick"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay
        v-model="loading"
        absolute
      >
        Carregando...
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import IMask from "imask";

import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    MaskedInput,
  },

  filters: {

    date(value) {
      return moment(value).format('DD/MM/YYYY');
    },

    Mask(value, mask) {
      return IMask.pipe((value || '').toString(), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },

  },

  data() {
    return {
      loading: false,

      // Tabs de anaises dos silos
      analysisTab: 0,

      // Dialog data
      transferring: {
        show: false,
        form: {
          siloTransferId: null,

          silosOrigin: [],
          destinationSilo: {},

          transferredAt: {
            picker: false,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },

          item: {},
          tinas: [],
        },
      },

      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: '.',
            min: -999999,
            max: 999999,
          },
        },
      },

      // Mascara usada nos campos numéricos float
      FloatNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      rawMaterials: [],

      options: {
        silos: [],
      },

    };
  },

  computed: {

    canEditVolume() {
      return !this.transferring.form.groupedTransferringId
    },

    transferringVol() {
      return _.sumBy(this.availableSilos, 'transferringVol');
    },

    availableSilos() {
      return this.transferring.form.silosOrigin.filter(silo => silo.status === 'ACTIVE');
    },

    selectedRawProductId() {
      const [firstSilo = null] = this.availableSilos;

      if (!firstSilo) {
        return null;
      }

      const rawMaterial = this.rawMaterials.find(({ id }) => id === firstSilo.rawProduct.id);

      return rawMaterial.rawMaterialGroupId || rawMaterial.id;
    },

    availableSilosToOrigin() {
      // A partir do id do agrupamento busca todas as matérias-primas cognatas
      const rawMaterials = this.rawMaterials.filter(({ id, rawMaterialGroupId }) => [id, rawMaterialGroupId].includes(this.selectedRawProductId));

      return this.options.silos.filter(silo => {

        const hasSiloInDestination = this.transferring.form.destinationSilo.id === silo.id;
        const hasRawProduct = silo.rawProduct.id && (!this.selectedRawProductId || rawMaterials.some(( { id }) => id === silo.rawProduct.id));

        return !hasSiloInDestination && hasRawProduct;
      });
    },

    availableSiloToDestination() {
      // A partir do id do agrupamento busca todas as matérias-primas cognatas
      const rawMaterial = this.rawMaterials.find(({ id }) => id === this.selectedRawProductId) || {};
      const parentRawMaterial = this.rawMaterials.find(({ id }) => id === rawMaterial.rawMaterialGroupId) || {};

      return this.options.silos.filter(silo => {

        const hasSiloInOrigin = this.transferring.form.silosOrigin.find(originSilo => originSilo.id === silo.id);
        const hasRawProduct = !silo.rawProduct.id || parentRawMaterial.id === silo.rawProduct.id || rawMaterial.id === silo.rawProduct.id;

        return !hasSiloInOrigin && hasRawProduct;
      });
    },

    isSaveAvailable() {
      return this.transferringVol > 0;
    },

  },

  watch: {
    availableSilos() {
      this.onSiloOriginChange();
    }
  },

  methods: {

    async show(transferring) {
      await this.loadOptions();
      await this.loadRawMaterials();

      return this.loadTransferringDetails(transferring);
    },

    async loadRawMaterials() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawMaterials = data.map(materiaPrima => {
          return {
            id: materiaPrima.id_item,
            description: materiaPrima.nome,
            type: materiaPrima.tipo,
            rawMaterialGroupId: materiaPrima.id_agrupador_materia_prima,
          }
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os matérias primas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadTransferringDetails(transferring) {
      try {
        this.loading = true;

        const {
          silo,
          transferringId,
        } = transferring;

        this.transferring = {
          ...this.transferring,
          show: true,
        };

        if (_.isEmpty(transferringId)) {
          const transferringSilo = this.options.silos.find(siloOption => siloOption.id === silo.id);

          this.transferring.form = {
            ...this.transferring.form,
            silosOrigin: [
              {
                ...transferringSilo,
                pending: true,
                status: 'ACTIVE',
                transferringId: null,
                analysis: {
                  initialFat: null,
                  acidity: null,
                  phosphatase: false,
                  peroxide: true,
                  esd: null,
                  finalFat: null,
                  protein: null,
                  milkWhey: null,
                  crioscopy: -0.5,
                  waterPercentage: null,
                  waterLiters: null,
                },
              },
            ],
          }

          return;
        }

        const { data } = await this.$axios.post(
          `/silo/detalheTransferenciaEntreSilos`,
          {
            id_transferencia: transferringId,
          }
        );

        if (!_.isArray(data)) {
          throw 'Erro ao carregar os dados';
        }

        const generalData = _.first(data);

        this.transferring = {
          show: true,
          form: {
            ...this.transferring.form,
            groupedTransferringId: generalData.id_agrupador_transferencia,
            transferredAt: {
              picker: false,
              date: generalData.data_hora_baixa ? moment(generalData.data_hora_baixa).format('YYYY-MM-DD') : null,
              time: generalData.data_hora_baixa ? moment(generalData.data_hora_baixa).format('HH:mm') : null,
            },
            destinationSilo: this.options.silos.find(siloOption => siloOption.id === generalData.id_silo_destino),
            silosOrigin: data.map(siloTransferred => {
              const transferringSilo = this.options.silos.find(siloOption => siloOption.id === siloTransferred.id_silo_origem);

              return {
                ...transferringSilo,
                transferringId: siloTransferred.id_transferencia_entre_silos,
                transferringVol: parseInt(siloTransferred.volume),
                status: 'ACTIVE',
                analysis: {
                  initialFat: parseFloat(siloTransferred.gordura_inicial) || null,
                  acidity: parseFloat(siloTransferred.acidez) || null,
                  phosphatase: siloTransferred.fosfatase,
                  peroxide: siloTransferred.peroxidase,
                  esd: parseFloat(siloTransferred.esd) || null,
                  finalFat: parseFloat(siloTransferred.gordura_final) || null,
                  protein: parseFloat(siloTransferred.proteina) || null,
                  milkWhey: parseFloat(siloTransferred.soro) || null,
                  crioscopy: parseFloat(siloTransferred.crioscopia) || null,
                  waterPercentage: parseFloat(siloTransferred.porcentagem_agua) || null,
                  waterLiters: parseInt(siloTransferred.volume_agua) || null,
                },
              };
            }),
          },
        };
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os detalhes da transferência!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    /**
     * Fecha todos os dialogos abertos na tela
     */
    closeDialogs() {
      this.transferring = {
        show: false,
        form: {
          silosOrigin: [],
          destinationSilo: {},

          transferredAt: {
            picker: false,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },

          processedAt: {
            picker: false,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },

          item: {},
          tinas: [],
        },
      };
    },

    async loadOptions() {
      try {
        const silos = await this.$axios.post(`/silo/principal`);

        if (!_.isArray(silos.data.silo)) {
          throw "PHP Error";
        }

        this.options.silos = silos.data.silo
          .map(silo => {
            return {
              index: (silo.descricao || '').replace(/\D/g, ''),
              id: silo.id_silo,
              label: silo.descricao,
              batchNumber: silo.numero_lote,
              vol: parseInt(silo.volume_atual),
              capacity: parseInt(silo.volume_total),
              transferringVol: null,
              transferringId: null,
              analysis: {
                initialFat: null,
                acidity: null,
                phosphatase: false,
                peroxide: true,
                esd: null,
                finalFat: null,
                protein: null,
                milkWhey: null,
                crioscopy: -0.5,
                waterPercentage: null,
                waterLiters: null,
              },
              rawProduct: {
                id: silo.id_materia_prima,
                name: silo.nome_materia_prima,
              },
            }
          })
          .sort((a, b) => a.index - b.index);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      }
    },

    onSiloVolFillClick(transferringSilo) {
      this.transferring.form.silosOrigin = this.transferring.form.silosOrigin.map(silo => {

        if (transferringSilo.id !== silo.id) {
          return silo;
        }

        return {
          ...silo,
          transferringVol: silo.vol,
        }
      });
    },

    getSiloRules(transfer) {
      const rules = [
        v => !!v || 'Informe o volume',
        v => v > 0 || 'Volume não pode ser negativo',
      ]

      if (this.$store.state.settings.gerais.validar_saldo_silo) {
        rules.push(() => transfer.transferringVol <= transfer.vol || 'Volume indisponível no silo')
      }

      return rules
    },

    getDestinationRules() {
      const silo = this.transferring.form.destinationSilo;

      if (!silo.id) {
        return []
      }

      const availableVol = silo.capacity - silo.vol;

      return [
        v => v <= availableVol || 'Volume excede a capacidade no silo de destino',
      ]
    },

    onTransferringSaveClick: _.debounce(function () {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return;
      }

      return this.saveTransferring(this.transferring.form);
    }, 500),

    async saveTransferring(form) {
      try {
        this.loading = true;

        const transferredAt = `${form.transferredAt.date} ${form.transferredAt.time}`;

        const transfers = form.silosOrigin.map(silo => {
          return {
            id_transferencia_entre_silos: silo.transferringId,
            id_agrupador_transferencia: form.groupedTransferringId,
            volume: silo.transferringVol,
            data_hora_baixa: transferredAt,
            id_silo_origem: silo.id,
            id_silo_destino: form.destinationSilo.id,
            id_materia_prima: silo.rawProduct.id,
            nome_materia_prima: silo.rawProduct.name,
            ativo: silo.status === 'ACTIVE',

            gordura_inicial: silo.analysis.initialFat,
            acidez: silo.analysis.acidity,
            gordura_final: silo.analysis.finalFat,
            fosfatase: silo.analysis.phosphatase,
            peroxidase: silo.analysis.peroxide,
            esd: silo.analysis.esd,
            proteina: silo.analysis.protein,
            soro: silo.analysis.milkWhey,
            crioscopia: silo.analysis.crioscopy,
            porcentagem_agua: silo.analysis.waterPercentage,
            volume_agua: silo.analysis.waterLiters, // novo
          }
        });

        const { data } = await this.$axios.post(
          `/silo/transferenciaEntreSilos`,
          {
            transferencias: transfers,
          },
        );

        const { codigo } = data;

        if (!codigo) {
          throw "PHP Error";
        }

        this.$emit('onSiloTransferred', form);

        return this.closeDialogs();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar a transferência!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    onWaterChange() {
      this.transferring.form.silosOrigin = this.transferring.form.silosOrigin.map(silo => {

        if (!silo.analysis.crioscopy || !silo.transferringVol) {
          silo.analysis.waterPercentage = 0;
          silo.analysis.waterLiters = 0;

          return silo;
        }

        const crioscopy = Math.abs(silo.analysis.crioscopy) * 1000;

        if (crioscopy >= 535) {
          silo.analysis.waterPercentage = 0;
          silo.analysis.waterLiters = 0;

          return silo;
        }

        const liters = (((crioscopy - 535) * 100) / 535) * silo.transferringVol;
        const waterPercentage = (liters / silo.transferringVol) || 0;

        silo.analysis.waterPercentage =  Math.abs(waterPercentage);

        return silo;
      });

      return this.onWaterPercentageChange();
    },

    onWaterPercentageChange() {
      this.transferring.form.silosOrigin = this.transferring.form.silosOrigin.map(silo => {
        // Caso não tenha volume indentificado, ou o % seja 0, não é possível calcular o volume de água
        if (!silo.transferringVol || silo.analysis.waterPercentage <= 0) {
          return silo;
        }

        const vol = (silo.transferringVol  * (silo.analysis.waterPercentage / 100)) || 0;

        silo.analysis.waterLiters = vol.toFixed(1);

        return silo;
      });
    },

    onRemoveSilo(silo) {
      this.transferring.form.silosOrigin = this.transferring.form.silosOrigin.map(transferringSilo => {

        if (transferringSilo.id === silo.id) {
          return {
            ...transferringSilo,
            status: 'INACTIVE',
          }
        }

        return transferringSilo;
      });
    },

    onSiloSelect(silo) {

      const hasSiloInList = this.transferring.form.silosOrigin.find(listedSilo => listedSilo.id === silo.id);

      if (!hasSiloInList) {
        this.transferring.form.silosOrigin = [
          ...this.transferring.form.silosOrigin,
          {
            ...silo,
            pending: true,
            status: 'ACTIVE',
          },
        ];

        return;
      }

      if (hasSiloInList.status === 'INACTIVE') {
        this.transferring.form.silosOrigin = this.transferring.form.silosOrigin.map(listedSilo => {

          if (listedSilo.id === silo.id) {
            return {
              ...listedSilo,
              status: 'ACTIVE',
            }
          }

          return listedSilo;
        });

        return;
      }

      if (hasSiloInList.pending) {
        const removedSiloIndex = this.transferring.form.silosOrigin.findIndex(listedSilo => listedSilo.id === silo.id);

        this.transferring.form.silosOrigin.splice(removedSiloIndex);

        return;
      }

      return this.onRemoveSilo(silo);
    },

    // Validate if the selected destination is still valid
    async onSiloOriginChange() {
      if (!this.transferring.form.destinationSilo?.id) {
        return
      }

      await this.$nextTick();

      if (!this.availableSiloToDestination.find(silo => silo.id === this.transferring.form.destinationSilo.id)) {
        this.transferring.form.destinationSilo = {}
      }
    },

    goToNextInput(nextInputName) {
      const inputs = this.$refs['analysis-elm'];

      const nextElm = inputs.find(elm => {
        return elm.$attrs.name === nextInputName
      });

      if (_.isEmpty(nextElm)) {
        return;
      }

      if (!_.isFunction(nextElm.focus)) {
        return;
      }

      return nextElm.focus();
    },

  },

}
</script>
