<template>
  <div
    id="content-chart"
  >
    <v-card
      class="card-question-chart pa-0"
      elevation-1
      rounded="rounded-lg elevator-1"
      max-height="1000"
    >
      <column-chart
        v-if="chartType === 'column'"
        :title="label"
        :series="series"
        :legend="false"
        :category="category"
      />

      <horizontal-chart
        v-if="chartType === 'horizontal'"
        :title="label"
        :series="series"
        :legend="false"
        :category="category"
      />
    </v-card>
  </div>
</template>

<style lang="scss">
#content-chart {
  display: inline-block;
  width: 100% !important;
  padding: 5px * $scale;
}

.question-chart {
  padding-top: 10px * $scale;
}

.card-question-chart > .v-card__title  {
  flex: 1;
  min-width: 0;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

</style>
<script>
import * as _ from "lodash";
import ColumnChart from "@/Domains/Questionnaires/Components/ColumnChart.vue"
import HorizontalChart from "@/Domains/Questionnaires/Components/HorizontalChart.vue"

const chartTypes = {
  PRODUTOR: {
    title: 'Produtores',
    label: 'Produtor(es)',
  },
  FROTA: {
    title: 'Equipamentos',
    label: 'Equipamento(s)',
  },
  RESPOSTA: {
    title: 'Respostas',
    label: 'Resposta(s)',
  },
};

export default {
  components: {
    ColumnChart,
    HorizontalChart
  },

  props: {
    label: {
      type: String,
    },

    question: {
      default: () => {},
    },

    chartType: {
      type: String,
      default: "column",
    },

    legend: {
      type: Boolean,
      default: true,
    },

    height: {
      default: 'auto',
    },

    type: {
      type: String,
      default: "PRODUTOR",
      validator: function (value) {
        return ['PRODUTOR', 'FROTA', 'RESPOSTA'].includes(value);
      }
    }
  },

  computed: {

    category: function() {
      if (_.isEmpty(this.question)) {
        return [];
      }

      return _.sortBy(this.question.answers);
    },

    series: function() {
      if (_.isEmpty(this.question)) {
        return [];
      }

      const tot = this.question.answered_questions.length;

      const dataAnswers = _.map(this.question.answers, (value) => {

        const totalByAnswer  = _.get(this.question.count_by_answers, value);

        const percentByAnswers = (100 * totalByAnswer / tot).toFixed(2);

        return {
          name: value,
          value: this.chartType === "horizontal" ? percentByAnswers : totalByAnswer,
          TotalByQuestion: tot,
          type: this.chartType,
          percentByAnswers,
          totalByAnswer
        };
      });

      /**
       * É necessário ordernar para mandar a possição correta das respostas, semelhante a categoria.
       */
      return  [{
        barWidth: '50%',
        name: this.titleSeries,
        type: 'bar',
        data: _.orderBy(dataAnswers, "name"),
        label: {
          show: this.chartType === "horizontal" ? true : false,
          formatter: ({ data }) => data.type === "horizontal" ? `${data.value}% | ${data.totalByAnswer} ${this.labelSeries}` : `${data.value}`
        }
      }];
    },

    titleSeries() {
      return chartTypes[this.type].title;
    },

    labelSeries() {
      return chartTypes[this.type].label;
    },

    max: function() {
      return  this.question.answered_questions.length;
    }
  },

  methods: {
    /**
     * Irá retornar um dos possiveis themas.
     */
    selectTheme() {
      return _.get(["palette1", "palette2", "palette3", "palette4", "palette5", "palette6", "palette7", "palette8", "palette9", ], Math.floor(Math.random() * 10), "palette4")
    }
  }
}
</script>
