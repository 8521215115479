<template id="report-totals">
  <div
    class="mx-6"
  >
    <questions-card label="Total de Respostas dos produtores classificados">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.project"
            :items="projectList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRanges"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="filter.project.protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="3"
          md="3"
        >
          <v-select
            v-model="filter.curve"
            dark
            filled
            return-object
            label="Curva"
            clearable
            :items="curveList"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <month-picker
            v-model="filter.date.input"
            :minMonth="filter.project.minMonth"
            :maxMonth="filter.project.maxMonth"
            disable-last-thirty
            not-clearable
            @change="onFilterDate"
          />
        </v-col>
        <v-col
          cols="3"
          md="3"
        >
          <v-select
            v-model="filter.questionnaire"
            dark
            filled
            item-value="id"
            item-text="name"
            return-object
            label="Checklist"
            clearable
            :items="questionnaireList"
          />
        </v-col>
        <v-col
          cols="6"
          md="6"
          class="white--text"
        >
          *considera somente checklists aplicados no período do projeto {{ formatDate(filter.project.dateStart, 'MM/YYYY') }} à {{ formatDate(filter.project.dateEnd, 'MM/YYYY') }}
        </v-col>
      </v-row>
      <div
        v-for="(values, alternatives,index ) in questions"
        :key="index"
      >
        <v-col
          :key="index"
          cols="12"
          height="500"
          class="pa-0 ma-0"
        >
          <list-anwers-by-counter
            :ref="`list-${index+1}`"
            :questions="values"
            :list-selected="ListSelected"
            :title="filter.questionnaire.name"
          />
        </v-col>
      </div>
    </questions-card>
    <v-overlay v-model="loading">
      Carregando ...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import * as _ from "lodash";
import moment from "moment-timezone";

import QuestionsCard from "@/Domains/Questionnaires/Components/QuestionsCard.vue";
import ListAnwersByCounter from "@/Domains/Projects/PQFL/BPA/Components/ListAnwersByCounter.vue";
import RatingService from "@/Domains/Projects/PQFL/BPA/Services/RatingService.js";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

const ratingService = new RatingService();
export default {

  components: {
    QuestionsCard,
    ListAnwersByCounter,
    MonthPicker,
  },
  filters: {
    /**
     * Retorna o valor corresponde ao total de respota de uma determinada alternativa da questão;
     * @returns {integer}
     */
    findSumAnswers: function (value, question) {
      if (_.isEmpty(question)) {
        return 0;
      }

      return _.get(question, `count_by_answers.${value}`, 0);
    },
  },

  data() {
    return {

      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
          id: '',
          maxMonth: moment().format("YYYY-MM"),
          minMonth: moment().format("YYYY-MM"),
        },
       
        date: {
          input: "thisMonth",
          month: null,
        },

        curve: [],

        questionnaire: [],

      },

      projectList:[],

      curveList: [],

      questionnaireList: [],

      questions: [],

      loading: false,

      /**
       * Será marcada quando alguma lista for selecionada
       */
      ListSelected: false,
    };
  },

  computed: {

    dateRefFilter() {
      if (_.isEmpty(this.filter.date)) {
        return null;
      }

      return moment(this.filter.date, "YYYY-MM").format("MM/YYYY");
    },
  },
 
  created() {
    this.loadProject();
  },

  methods: {
    onFilterDate([month]) {
      this.filter.date.month = month;
      this.loadAnswers();
    },
    
    async loadProject() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL"
          }
        );

        this.projectList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            protocol: proj.protocolo,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          this.filter.project.minMonth = moment(this.filter.project.dateStart).format('YYYY-MM');
          this.filter.project.maxMonth = moment(this.projectList[0].dateEnd).format("YYYY-MM");
          await this.loadRanges();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadRanges() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/rangesBpa`,
          {
            id_assistencia: this.filter.project.id,
          }
        );

        this.questionnaireList = data.filter(o => o.id_questionario).map((o) => {
          return {
            id: o.id_questionario,
            name: o.indicador,
          }
        });
        this.questionnaireList = _.uniqBy(this.questionnaireList, "id");

        let curve = [...new Set(data.filter(o => o.visitas).flatMap(o => o.tipo))];
        this.curveList = curve.map((c) => {
          return {
            text: ratingService.types[c],
            value: c,
          }
        });
        this.ranges = data;
        this.filter.curve = this.curveList[0];

        this.filter.questionnaire = _.first(this.questionnaireList);

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
        await this.loadAnswers();
      }

    },

    async loadAnswers() {
      try {
        this.loading = true;
        if (_.isEmpty(this.filter.questionnaire.id)) {
          this.withAnswers = false;
          this.questions = [];
          return false;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaRespostasCurvaABC`,
          {
            id_formulario: this.filter.questionnaire.id,
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            tipo_curva: this.filter.curve.value,
            data_inicio_projeto: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim_projeto: moment(this.filter.project.dateEnd).endOf('month').format('YYYY-MM-DD'),
          }
        );
       
        if (!data) {
          this.withAnswers = false;
          this.questions = [];
          throw "PHP Error";
        }

        /**
         * Verifica se passou
         */
        console.log('data',data);
        if (_.has(_.head(data), "codigo")) {
          this.withAnswers = false;
          this.questions = [];
          throw data.mensagem;
        }

        this.questions = _.orderBy(
          _.map(_.groupBy(data, "rotulo"), (itens, index) => {
            // conta quantidade de respotas e agrupando por respota
            const count = _.countBy(itens, (value) => {
              return value.resposta;
            });
            
            const producer = _.reduce(_.groupBy(itens, "id_produtor"), (acc, curr) => {
              
              _.map(curr, data => {
                acc.push({
                  code: data.codigo_laticinio,
                  name: data.nome_produtor,
                  curve: data.nome_faixa,
                  id: data.id_produtor,
                  answer: data.resposta,
                  date: data.data_hora_registro,
                  status: data.status === 'APROVADO' ? 'ATIVO' : data.status,
                });
              });

              return acc;
            }, []);
           
            return {
              label: index,
              answered_questions: itens,
              count_by_answers: count,
              detail: producer,
              answers: JSON.parse(_.get(_.head(itens), "valores")),
            };
          }),
          (value) => value.answers.length
        );
        console.log('this.questions',this.questions);

        // Agroupa por grupos de alternativas
        this.questions = _.groupBy(this.questions, "answers");
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * @void
     * Trata a lista que foi seleciona para gerar o gráfico
     */
    handleChartSelected(event) {

      if (_.isEmpty(event.data)) {
        this.generateChart.dataChart = {
          headers: [],
          data: [],
          sort: [],
        };

        this.ListSelected = false;

        return false;
      }

      // indica de já existe alguma lista selecionada
      this.ListSelected = true;

      this.generateChart.dataChart = event;
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
