<template>
  <visits
    project="PMLS"
  />
</template>

<script>

import Visits from "@/Domains/Projects/Visits/Index.vue";

export default {
  name: "visits-pmls",

  components: {
    Visits,
  },
};
</script>
