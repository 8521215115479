<template>
  <div>
    <v-row no-gutters>
      <v-col
        class="py-2"
        cols="12"
      >
        <card-title-settings class="mb-3">
          Parâmetros do Sorteio
        </card-title-settings>
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text
            class="pa-2 pt-3 pb-3"
          >
            <input
              v-model="settingPlatform.id_configuracao"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="settingPlatform.sorteio_periodicidade_analise"
                  :items="['MENSAL', 'BIMESTRAL', 'TRIMESTRAL', 'QUADRIMESTRAL', 'QUIMESTRAL', 'SEMESTRAL', 'ANUAL']"
                  label="Periodicidade"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="settingPlatform.sorteio_percentual"
                  label="Percentual por sorteio"
                  type="number"
                  hint="Número de 1 a 100"
                  :rules="rules.percentual"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="text-body-2 ml-1 mr-1">
      <v-col
        v-for="group in groups"
        :key="group.id_configuracao_grupo_analise"
        lg="3"
        md="3"
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title class="text-subtitle-1 py-2">
            {{ group.descricao }}

            <v-spacer />

            <v-btn
              v-if="hasPeriodicSetting"
              icon
              dark
              @click="openAlert(group), (showDeleteDialog = true)"
            >
              <v-icon>delete</v-icon>
            </v-btn>

            <v-btn
              v-if="hasPeriodicSetting"
              icon
              dark
              @click="openEdit(group)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </v-card-title>

          <v-simple-table>
            <template #default>
              <tbody>
                <tr
                  v-for="(item, index) in group.itens"
                  :key="index"
                >
                  <td class="text-capitalize">
                    {{ item }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Pagina de Cadastro de grupo -->
    <v-dialog
      width="500"
      max-width="500"
      :value="showCadastroGrupo"
      @input="showCadastroGrupo = false"
      @keydown.esc="showCadastroGrupo = false"
    >
      <v-card>
        <v-card-title>Cadastro de Grupos</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <input
              v-model="groupEdit.id_configuracao_grupo_analise"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="groupEdit.descricao"
                  type="text"
                  autocomplete="descricao"
                  label="Descrição"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="groupEdit.itens"
                  item-text="name"
                  item-value="id"
                  chips
                  small-chips
                  multiple
                  hide-details
                  label="Itens:"
                  :items="listItens"
                >
                  <template #selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      color="primary"
                      @click="data.select"
                      @click:close="removeGroupChip(data.item)"
                    >
                      <v-icon
                        left
                        small
                      >
                        label
                      </v-icon>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="black--text"
            text
            @click="showCadastroGrupo = false"
          >
            cancelar
          </v-btn>
          <v-btn
            v-if="hasPeriodicSetting"
            class="blue--text"
            text
            @click="onGroupSave()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Alert excluir -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false"
      @keydown.esc="showDeleteDialog = false"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-center text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-center black--text">
            Deseja realmente Executar a Ação ?
          </h3>
          <v-flex
            xs12
            class="font-weight-black"
          >
            <br>
          </v-flex>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="
              openDelete();
              showDeleteDialog = false;
            "
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          v-if="hasPeriodicSetting"
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="onSettingSave()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>
  </div>
</template>

<script>
import _ from "lodash";

import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

export default {
  name: "sort-settings",

  components: {
    CardTitleSettings,
  },

  data() {
    return {
      rules: {
        percentual: [
          (v) => !!v || "campo obrigatório",
          (v) => (v && v >= 1) || "min:1 ",
          (v) => (v && v <= 100) || "Max:100",
        ],
      },

      showCadastroGrupo: false,

      showDeleteDialog: false,

      hasPeriodicSetting: false,

      settingPlatform: [],

      groups: [],

      groupEdit: [],

      objetcToDelete: [],

      listItens: [
        { id: "temperatura", name: "Temperatura" },
        { id: "acidez", name: "Acidez" },
        { id: "alizarol", name: "Alizarol" },
        { id: "crioscopia", name: "Crioscopia" },
        { id: "porcentagem_agua", name: "Porcentagem de agua" },
        { id: "densidade", name: "Densidade" },
        { id: "esd", name: "ESD" },
        { id: "est", name: "EST" },
        { id: "gordura", name: "Gordura" },
        { id: "proteina", name: "Proteina" },
        { id: "antibiotico", name: "Antibiotico" },
        { id: "cloretos", name: "Cloretos" },
        { id: "mastite", name: "Mastite" },
        { id: "soda", name: "Soda" },
        { id: "formol", name: "Formaldeido" },
        { id: "peroxido", name: "Peroxido de hidrogenio" },
        { id: "cloro", name: "cloro" },
        { id: "amido", name: "Amido" },
        { id: "sacarose", name: "Sacarose" },
        { id: "neutralizantes", name: "Neutralizantes" },
        { id: "reconstituintes", name: "Reconstituintes" },
        { id: "conservantes", name: "Conservantes" },
        { id: "bicarbonato", name: "Bicarbonato" },
        { id: "ph", name: "PH" },
        { id: "betalactamico", name: "Betalactamico" },
        { id: "tetraciclina", name: "Tetraciclina" },
        { id: "sulfonamida", name: "Sulfonamida" },
        { id: "alcool_etilico", name: "Alcool etilico" },
        { id: "redutase", name: "Redutase" },
        { id: "alcalino", name: "Alcalino" },
        { id: "lactose", name: "Lactose" },
      ],

    };
  },
  mounted() {
    this.loadSetting();
    this.loadGroup();
    this.loadResourcesUser();
  },

  methods: {
    /**
     * carrega os recursos que usuario tem permissao para acessar
     *
     */
    loadResourcesUser() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      const hasPeriodicSetting = recursosUsuario.find((recurso) => recurso.recurso === "analysis-periodic-setting" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(hasPeriodicSetting)) {
        this.hasPeriodicSetting = false;
      } else {
        this.hasPeriodicSetting = true;
      }
    },

    openAlert(group) {
      this.objetcToDelete = group.id_configuracao_grupo_analise;
    },

    openDelete() {
      this.onGroupDelete(this.objetcToDelete);
    },

    openInsert() {
      this.showCadastroGrupo = true;
      this.groupEdit = [];
    },

    openEdit(grupo) {
      this.showCadastroGrupo = true;
      this.groupEdit = grupo;
    },

    async loadSetting() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/configuracao/listaConfiguracaoPlataforma`);

        this.settingPlatform = data;
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async onSettingSave() {
      try {
        this.loading = true;

        const request = {
          id_configuracao: this.settingPlatform.id_configuracao,
          sorteio_periodicidade_analise: this.settingPlatform.sorteio_periodicidade_analise,
          sorteio_percentual: parseFloat(this.settingPlatform.sorteio_percentual),
        };

        const { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoSorteio`,
          this.$qs.stringify({
            ...request,
          })
        );

        const [{ codigo }] = data;

        if (!codigo) {
          throw "Erro ao salvar";
        }

        this.$snotify.success("Configurações salvas!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar configurações", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadGroup() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/configuracao/listaConfiguracaoGrupoAnalise`);

        this.groups = data.map((group) => {
          return {
            id_configuracao_grupo_analise: group.id_configuracao_grupo_analise,
            descricao: group.descricao,
            itens: JSON.parse(group.itens),
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async onGroupDelete(toDelete) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/configuracao/deleteGrupoAnalise`,
          this.$qs.stringify({
            id_configuracao_grupo_analise: toDelete,
          })
        );

        const [{ codigo }] = data;

        if (!codigo) {
          throw "Erro ao salvar";
        }

        this.$snotify.success("Grupo excluído!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar configurações", "Atenção");
        console.warn(error);
      } finally {
        this.loadGroup();
        this.loading = false;
        this.showCadastroGrupo = false;
      }
    },

    async onGroupSave() {
      try {
        this.loading = true;

        const request = {
          id_configuracao_grupo_analise: this.groupEdit.id_configuracao_grupo_analise,
          descricao: this.groupEdit.descricao,
          itens: JSON.stringify(this.groupEdit.itens),
        };

        const { data } = await this.$axios.post(
          `/configuracao/salvaGrupoAnalise`,
          this.$qs.stringify({
            ...request,
          })
        );

        const [{ codigo }] = data;

        if (!codigo) {
          throw "Erro ao salvar";
        }

        this.$snotify.success("Configurações salvas!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar configurações", "Atenção");
        console.warn(error);
      } finally {
        this.loadGroup();
        this.loading = false;
        this.showCadastroGrupo = false;
      }
    },

    removeGroupChip(item) {
      this.groupEdit.itens = _.remove(this.groupEdit.itens, (data) => {
        return data !== item;
      });
    },
  },
};
</script>
