<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-card class="transparent list-pqfl">
      <v-card-title class="px-2">
        <v-row
          class="pa-0 ma-0 row-filters"
        >
          <v-col
            cols="3"
            class="pa-1"
            md-3
          >
            <v-select
              v-model="filters.typePQFL"
              label="Tipo"
              dark
              filled
              return-object
              :items="[{text:'Respondido',valeu:'ANSWERED'},{text:'Não Respondido',valeu:'NOTANSWERED'}]"
              class="pa-0"
              @change="onSelectedType"
            />
          </v-col>
          <v-col
            cols="3"
            md-3
            class="pa-1"
          >
            <period-date-picker
              v-model="filters.date.input"
              @change="onDateFilter"
            />
          </v-col>
          <v-col
            cols="3"
            md-3
            class="pa-1"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              append-icon="search"
              single-line
              hide-details
              filled
              clearable
              dark
            />
          </v-col>
          <v-col
            cols="3"
            md-3
            class="pa-1"
          >
            <v-autocomplete
              v-model="filters.activeProspection"
              :items="producerActiveProspection"
              label="Ativos/Prospectados"
              prepend-inner-icon="produtores"
              dark="dark"
              filled
              clearable
              @change="onFilter"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        dense
        dark
        :headers.sync="headers"
        :search="filters.search"
        :items="dataPQFL"
        class="elevation-1 list-producer-pqfl"
      >
        <template #[`item.pqfl`]="{item}">
          <v-badge
            color="deep-purple darken-2"
            :content="item.total_pqfl"
            overlap
          >
            <v-btn
              icon
              text
              @click="openDialogPQFL(item.id_pessoa)"
            >
              <v-icon>
                content_paste
              </v-icon>
            </v-btn>
          </v-badge>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <dialog-pqfl
      ref="pqfl"
    />
  </v-container>
</template>

<style lang="scss">
.list-pqfl > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.list-producer-pqfl {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import XLSX from 'xlsx-js-style';
import moment from "moment-timezone";
import PeriodDatePicker from "@/Support/Components/PeriodDatePicker.vue";
import qs from "qs";
import _ from "lodash";

import DialogPqfl from "@/Domains/Producer/Management/Components/Dialogs/PQFLChecklist.vue";

export default {
  components: {
    PeriodDatePicker,
    DialogPqfl
  },

  filters: {
    dateFormat: function(value, opts) {
      return moment(value).format(opts);
    },
  },

  data() {
    return {
      dateSign: moment().format("YYYY-MM-DD"),

      filters: {
        search: null,
        typePQFL: { text: 'Respondido', valeu: 'ANSWERED' },
        date: {
          input: "today",
          range: [],
        },
        activeProspection: "",
      },

      dataPQFL: [],

      producerActiveProspection: [
        'Produtores Ativos',
        'Produtores Prospectados'
      ],

      dataPQFLOrigin: [],
      itemsretorno: [],
    };
  },
  computed: {
    headers() {
      if (this.filters.typePQFL.valeu === "NOTANSWERED") {
        return [
          { text: 'Código', value: 'codigo_produtor', width: 100 },
          { text: 'Produtor', value: 'nome' }
        ];
      }

      return [
        { text: 'Código', value: 'codigo_produtor', width: 100 },
        { text: 'Produtor', value: 'nome' },
        { text: 'Documentos', value: 'pqfl', width: 150, align: 'center' },
      ];
    }
  },
  mounted() {
    this.loadPQFL();
  },
  methods: {
    async loadPQFL() {

      try {
        const url = this.filters.typePQFL.valeu === "ANSWERED" ? "pqflProdutores/producersWithOpenPQFL" : "pqflProdutores/producerWithoutOpenPQFL"

        const { data } = await this.$axios.post(
          `/${url}`,
          qs.stringify({
            data_inicio: _.first(this.filters.date.range) || moment().format("YYYY-MM-DD"),
            data_fim: _.last(this.filters.date.range) || moment().format("YYYY-MM-DD"),
          })
        );

        this.dataPQFL = data;
        this.dataPQFLOrigin = data;

        if (this.filters.activeProspection) {
          this.onFilter();
        }

      } catch (e) {
        console.warn(e);
        this.$snotify.error("Oops, ocorreu um erro ao carregar PQFL!", "Atenção");
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event;

      this.loadPQFL();
    },

    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.dataPQFL.map(item => {

        if (this.filters.typePQFL.valeu === "NOTANSWERED") {
          return {
            Código: item.codigo_produtor,
            Produtor: item.nome,
          }
        }

        return {
          Código: item.codigo_produtor,
          Produtor: item.nome,
          NºDocumento: item.total_pqfl,
          "Data último preenchimento": item.data
        }

      }), {
        header: this.filters.typePQFL.valeu === "ANSWERED" ?
          ["Código", "Produtor", "NºDocumento", "Data último preenchimento"] :
          ["Código", "Produtor"],
      });

      const workbook = XLSX.utils.book_new();
      const filename = this.filters.typePQFL.valeu === "ANSWERED" ? "com_pqfl_aberto" : "sem_pqfl_aberto";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    onSelectedType() {

      if (!this.filters.typePQFL) {
        this.filters.typePQFL = { text: 'Respondido', valeu: 'ANSWERED' };
      }

      this.loadPQFL();
    },

    openDialogPQFL(id) {
      this.$refs.pqfl.openDialog(id);
    },

    onFilter() {
      this.itemsretorno = this.dataPQFLOrigin;

      if (this.filters.activeProspection === "Produtores Ativos") {
        this.dataPQFL = this.itemsretorno.filter(x => x.status === "APROVADO" || (x.status === "" || x.status === null));
      } else if (this.filters.activeProspection === "Produtores Prospectados") {
        this.dataPQFL = this.itemsretorno.filter(x => (x.status === "APROVACAO" || x.status === "NEGOCIACAO" || x.status === "LEAD"));
      } else {
        this.loadPQFL();
      }
    }
  }
}
</script>
