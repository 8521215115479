<template>
  <div class="mx-6">
    <v-row class="">
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Volume
        </p>
        <v-card
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ formatNumber(volumeTotal) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Coletas
        </p>
        <v-card
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ formatNumber(coletasTotal) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="4"
        md="2"
      >
        <p class="white--text">
          Produtores
        </p>
        <v-card
          color="transparent"
          dark
          flat
        >
          <v-card-title class="px-0 py-1 d-flex justify-center body-2 white--text font-weight-bold ">
            {{ formatNumber(totalProdutores) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.referenceDate"
              :items="['DESCARGA', 'COLETA']"
              label="Data de referência"
              prepend-inner-icon="local_shipping"
              dark
              filled
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.routes"
              :items="routes"
              item-text="nome_rota"
              item-value="id_rota"
              label="Rota"
              prepend-inner-icon="icon-rota"
              multiple
              filled
              hint
              dark
              clearable
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="filters.search"
              placeholder="Busca"
              hide-details
              filled
              prepend-inner-icon="search"
              dark
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :items="filteredItems"
        :headers="headers"
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
        dark
      >
        <template #[`footer.prepend`]>
          <div class="text-caption text-left ml-4">
            Produtores
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatNumber(totalProdutores) }}
            </v-chip>
          </div>
          <div class="text-caption text-left ml-4">
            Coletas
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatNumber(coletasTotal) }}
            </v-chip>
          </div>
          <div class="text-caption text-left ml-4">
            Volume
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatNumber(volumeTotal) }}
            </v-chip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay
      v-model="loading"
      dark
    >
      Carregando
      <v-progress-circular
        indeterminate
        size="20"
      />
    </v-overlay>
  </div>
</template>

<script>
import isArray from "lodash/fp/isArray";
import isEmpty from "lodash/fp/isEmpty";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import { sortItems } from 'vuetify/lib/util/helpers'

export default {
  components: {
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,
      filters: {
        dateRange: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        search: '',
        routes: [],
        referenceDate: 'DESCARGA',
      },
      headers: [
        { text: 'Código', value: 'codigo_produtor' },
        { text: 'Produtor', value: 'nome_produtor' },
        { text: 'Rota', value: 'nome_rota' },
        { text: 'Coletas', value: 'qtde_coletas' },
        { text: 'Volume (L)', value: 'total_volume' },
      ],
      items: [],
      sort: { by: [], desc: [] }
    };
  },

  computed: {
    filteredItems() {
      if (isEmpty(this.filters.routes) && !this.filters.search) {
        return this.items;
      }

      const routes = this.filters.routes;
      const search = this.filters.search.toUpperCase().trim();

      return this.items.filter(item => {
        const routeFilter = isEmpty(routes) || routes.includes(item.id_rota);
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return routeFilter && searchFilter
      });
    },

    /**
     * Items do filtro de rota, baseando-se nas rotas encontradadas na tabela
     */
    routes() {
      return this.items.filter(f => !!f.nome_rota);
    },

    volumeTotal() {
      return Math.abs(this.filteredItems.reduce((acc, item) => (acc + parseInt(item.total_volume)), 0));
    },

    coletasTotal() {
      return Math.abs(this.filteredItems.reduce((acc, item) => (acc + parseInt(item.qtde_coletas)), 0));
    },

    totalProdutores() {
      return this.filteredItems.length;
    }
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = this.$options._componentTag;
        this.$store.commit("setFilter", { [filterId]: value });
      }
    }
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = this.$options._componentTag;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = this.$store.state.settings.filters[filterId];
      }
    },

    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        let { data }  = await this.$axios.post(
          `/coleta/volumeColeta`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            data_referencia: this.filters.referenceDate,
          }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.items = data;
      } catch (e) {
        console.warn(e);
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de volume de coletas!", "Atenção");

        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    onFilter() {
      return this.loadReport();
    },

    orderedItems() {
      return sortItems(this.filteredItems, this.sort.by, this.sort.desc, 'pt-BR');
    },

    getReportJson() {
      return this.orderedItems().map(item => ({
        'Código': item.codigo_produtor,
        'Produtor': item.nome_produtor,
        'Rota': item.nome_rota,
        'Coletas': item.qtde_coletas,
        'Volume (L)': item.total_volume,
      }));
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Volume de coletas - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      this.exportToFile({ report: this.getReportJson(), title: this.getReportTitle() });
    },

    print() {
      return this.printFromJson(this.getReportJson(), this.getReportTitle());
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),

  },
}
</script>
