<template>
  <v-dialog
    v-model="dialog.show"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Laudos e Anexos</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-col
          cols="12"
          class="pa-0"
        >
          <v-row class="text-body-2">
            <v-col
              cols="12"
              class="pt-0"
            >
              <span
                class="overline"
              >
                Observações
              </span>

              <v-divider
                class="mb-2"
                style="margin-top: -8px;"
              />

              {{ unload.observacao }}
            </v-col>

            <v-col
              cols="12"
              class="pt-0"
            >
              <span
                class="overline"
              >
                Anexos
              </span>

              <v-divider
                class="mb-2"
                style="margin-top: -8px;"
              />

              <div style="display: flex; flex-wrap: wrap;">
                <v-col
                  v-for="(file, idx) in unload.anexos"
                  :key="idx"
                  cols="4"
                >
                  <file-viewer
                    :value="file"
                    disable-delete
                    :preview-height="200"
                    @click="downloadFile(file)"
                  />
                </v-col>
              </div>
            </v-col>

            <v-col
              v-if="unload.responsavel === 'PRODUTOR'"
              cols="12"
            >
              <span
                class="overline"
              >
                Técnicos Notificados
              </span>

              <v-divider
                class="mb-2"
                style="margin-top: -8px;"
              />

              <v-row
                v-for="tecnico in technicals"
                :key="tecnico.id"
                class="overline"
                style="line-height: 20px !important; font-size: 10px !important;"
              >
                <v-col
                  cols="12"
                  class="py-0"
                >
                  - {{ tecnico.name }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="grey darken-1"
          text
          @click="hide()"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay v-model="loading">
      Carregando...
    </v-overlay>
  </v-dialog>
</template>

<style lang="scss">
.tank {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
}

.tank:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.08);
}
</style>

<script>
import FileViewer from "@/Support/Components/FileViewer.vue";

export default {

  components: {
    FileViewer,
  },

  data() {
    return {
      loading: false,

      dialog: {
        show: false,
      },

      unload: {},

      technicals: [],

    }
  },

  methods: {

    async show(unload) {
      try {
        this.dialog.show = true;

        const anexos = JSON.parse(unload.anexos) || [];

        this.unload = {
          ...unload,
          anexos,
        };

        const { data } = await this.$axios.post(
          `/pessoa/listaTecnicosProdutor`,
          {
            id_produtor: unload.id_produtor,
          },
        );

        this.technicals = data.tecnicos.map(tecnico => {
          return {
            id: tecnico.id_pessoa,
            name: tecnico.nome,
            email: tecnico.email,
          }
        });
      } catch (e) {
        console.warn(e);
      }
    },

    hide() {
      this.dialog.show = false;
    },

    downloadFile({ url, name }) {
      let f = document.createElement("a");
      f.setAttribute("href", url);
      f.setAttribute("download", name);
      f.click();
    }

  },

}
</script>
