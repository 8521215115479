<template>
  <v-dialog
    v-model="show"
    width="450"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Forma de Parcelamento
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="isValid"
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model="form.descricao"
                type="text"
                label="Descrição"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <masked-text-field
                v-model="form.numero_parcelas"
                type="text"
                label="Número de parcelas"
                :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <masked-text-field
                v-model="form.carencia_primeira_parcela"
                type="text"
                label="Carência da primeira parcela"
                :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <masked-text-field
                v-model="form.dias_entre_parcelas"
                type="text"
                label="Dias entre parcelas"
                :disabled="form.numero_parcelas <= 1"
                :mask="{ mask: Number, min: 1, scale: 0, signed: false }"
                :rules="[v => !!v || form.numero_parcelas <= 1 || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

import isObject from "lodash/fp/isObject";

export default {

  components: {
    MaskedTextField
  },

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      isValid: true,
      form: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = {
          numero_parcelas: 1,
          carencia_primeira_parcela: 30,
          dias_entre_parcelas: null
        }

        if (this.editId) {
          this.loadInstallmentMethod();
        }
      }
    }
  },

  methods: {
    async loadInstallmentMethod() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/formaParcelamento/detalhe`, {
          id_forma_parcelamento: this.editId
        });

        if (!isObject(data)) {
          throw data;
        }

        this.form = data;
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;
        const action = this.form.id_forma_parcelamento ? 'atualiza' : 'insere';
        
        const { data } = await this.$axios.post(`/formaParcelamento/${action}`, this.form);
        
        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Forma de parcelamento salva com sucesso!", "Sucesso");
        this.show = false;
        this.$emit('save', data.id_forma_parcelamento);
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    cancel() {
      this.show = false;
    }
  }
}
</script>