<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
  <price-list
    :type="type"
    :subtype="subtype"
  >
  </price-list>
  </v-container>
</template>

<script>
import PriceList from "@/Domains/Financial/Prices/Components/PriceList.vue";
export default {

  components: {
    PriceList,
  },

  props: {
    type: String,
    subtype: String,
  },
};
</script>

