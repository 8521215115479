<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <card-title-settings class="mb-3">
          Configuração da NF-e
        </card-title-settings>
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-select
                  v-model="settings.environment"
                  :items="[
                    { text: 'Produção', value: 1 },
                    { text: 'Homologação', value: 2 },
                  ]"
                  label="Ambiente"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-select
                  v-model="settings.taxRegime"
                  :items="[
                    { text: 'Simples Nacional', value: 1 },
                    { text: 'Simples Nacional - Excesso de Sublimite', value: 2 },
                    { text: 'Regime Normal', value: 3 },
                  ]"
                  label="Regime Tributário"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <masked-text-field
                  v-model.trim="settings.authorizedCnpjXmlDownload"
                  prepend-inner-icon="assignment_ind"
                  label="CNPJ Autorizado download XML *"
                  :mask="{ mask: '00.000.000/0000-00' }"
                  unmask
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <card-title-settings class="mb-3">
          Certificado Digital (A1)
        </card-title-settings>
        <v-card
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-row>
              <v-col
                v-if="settings.certificate"
                cols="6"
                class="py-0"
              >
                <file-viewer
                  :value="settings.certificate"
                  :preview-height="58"
                  @removeFile="onRemoveFile()"
                />
              </v-col>
              <v-col
                v-else
                cols="6"
                class="py-0 text-center"
              >
                <v-btn
                  outlined
                  class="mb-5 mt-5"
                  dark
                  @click="showFileUpload = !showFileUpload"
                >
                  <v-icon>attach_file</v-icon>
                  <span class="ml-2">Adicionar</span>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="py-0"
              >
                <v-text-field
                  v-model="settings.password"
                  label="Senha *"
                  type="password"
                  dark
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          color="transparent"
        >
          <v-card-title class="text-subtitle-1">
            Controle de Numeração da NF-e
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  v-on="on"
                  @click="addNumeration"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </template>

              <span>Incluir numeração</span>
            </v-tooltip>
          </v-card-title>
          <v-data-table
            dark
            :headers="[
              { text: 'Série', value: 'series' },
              { text: 'Próximo número', value: 'nextNumber' },
              { text: 'Ambiente', value: 'environment' },
              { text: 'Opções', value: 'actions', sortable: false, align: 'center' },
            ]"
            :items="numerations"
            @click:row="editNumeration"
          >
            <template #[`item.environment`]="{ value }">
              {{ getEnvironment(value) }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    small
                    @click="removeNumeration(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>

        <v-card
          dark
          color="transparent"
          class="mt-6"
        >
          <v-card-title class="text-subtitle-1">
            Data de Pagamento em Faturas de Contra-Notas
            <v-spacer />
            <v-col
              cols="6"
              class="py-0 pr-0"
            >
              <v-text-field
                v-model="filters.routeSearch"
                label="Procurar"
                dark
              />
            </v-col>
          </v-card-title>
          <v-data-table
            dark
            :search="filters.routeSearch"
            :sort-by="'descricao'"
            :sort-desc="false"
            :items-per-page="10"
            :headers="[
              { text: 'Rota', value: 'descricao' },
              { text: 'Data de Pagamento', value: 'dia_pagamento', width: 200 },
            ]"
            :items="settings.routes"
          >
            <template #[`item.dia_pagamento`]="{ item }">
              <div class="d-flex align-center my-1">
                <masked-text-field
                  v-model.number="item.dia_pagamento"
                  label="Dia"
                  :mask="masks.day"
                  unmask
                  placeholder="15"
                  outlined
                  dense
                  hide-details
                />
                <v-tooltip left>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      @click="applyToAllRoutes(item)"
                      v-on="on"
                    >
                      <v-icon
                        style="transform: rotate(90deg);"
                      >
                        multiple_stop
                      </v-icon>
                    </v-btn>
                  </template>
                  Aplicar a todos
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          dark
          color="transparent"
        >
          <v-card-title class="text-subtitle-1">
            Naturezas de Operação
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  v-on="on"
                  @click="addOperation"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </template>

              <span>Incluir natureza de operação</span>
            </v-tooltip>
          </v-card-title>
          <v-data-table
            dark
            :headers="[
              { text: 'Descrição', value: 'description' },
              { text: 'CFOP', value: 'icms.cfop' },
              { text: 'ICMS', value: 'icms.tributacao' },
              { text: 'PIS', value: 'pis.tributacao' },
              { text: 'COFINS', value: 'cofins.tributacao' },
              { text: 'Padrão', value: 'default' },
              { text: '', value: 'actions', width: 10, align: 'end'},
            ]"
            :items="operations"
            @click:row="editOperation"
          >
            <template #[`item.default`]="{ value }">
              {{ getDefault(value) }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template
                  #activator="{ on }"
                >
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="removeOperation(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <file-upload
      v-model="showFileUpload"
      :multiselect="false"
      extensions="p12,pfx"
      accept=".p12,.pfx"
      @insertFiles="onSelectFile"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          bottom
          color="green mr-5"
          dark
          fab
          fixed
          large
          right
          v-on="on"
          @click.stop="saveSettings()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações </span>
    </v-tooltip>

    <numeration-dialog
      v-model="numerationDialog.show"
      :edit-id="numerationDialog.id"
      :company-id="companyId"
      @save="loadNumerations"
    />

    <operation-dialog
      v-model="dialog.show"
      :edit-id="dialog.id"
      :company-id="companyId"
      :tax-regime-id="settings.taxRegime"
      @save="loadOperations"
    />
  </div>
</template>

<script>
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"
import NumerationDialog from "@/Domains/Settings/Views/Fiscal/Components/NumerationDialog.vue";
import OperationDialog from "@/Domains/Settings/Views/Fiscal/Components/OperationDialog.vue";

import IMask from 'imask';

export default {

  components: {
    FileUpload,
    FileViewer,
    MaskedTextField,
    CardTitleSettings,
    NumerationDialog,
    OperationDialog,
  },

  data() {
    return {
      filters: {
        routeSearch: null
      },

      settings: {
        environment: 2,
        taxRegime: 1,
        certificate: null,
        password: null,
        routes: [],
        authorizedCnpjXmlDownload: null,
      },

      showFileUpload: false,

      numerations: [],
      operations: [],

      numerationDialog: {
        show: false,
        id: null
      },

      dialog: {
        show: false,
        id: null
      },

      masks: {
        day: {
          mask: IMask.MaskedRange,
          placeholderChar: 'hh',
          from: 0,
          to: 31,
          maxLength: 2,
        },
      }
    };
  },

  computed: {
    companyId() {
      return this.$store.state.settings?.laticinio?.id
    }
  },

  mounted() {
    this.loadNumerations();
    this.loadOperations();
    this.loadSettings();
  },

  methods: {
    async loadSettings() {
      try {
        const { data } = await this.$axios.get(`/fiscal/settings`, { params: { id_empresa: this.companyId } });

        this.settings = {
          environment: data.ambiente,
          taxRegime: data.regime_tributario,
          certificate: data.certificado_digital,
          routes: data.rotas || [],
          authorizedCnpjXmlDownload: data.cnpj_autorizado_download_xml,
        };

      } catch (e) {
        console.error(e);
      }
    },

    async loadNumerations() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/fiscal/numeration`, { params: { id_empresa: this.companyId } });

        this.numerations = data.map(o => ({
          id: o.id,
          series: o.serie,
          nextNumber: o.prox_numero,
          environment: o.ambiente
        }));

      } catch (e) {
        console.error(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadOperations() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/fiscal/operation`, { params: { id_empresa: this.companyId } });

        this.operations = data.map(o => ({
          id: o.id,
          description: o.descricao,
          icms: o.icms,
          pis: o.pis,
          cofins: o.cofins,
          default: o.padrao
        }));

      } catch (e) {
        console.error(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async saveSettings() {
      try {
        this.$root.$progressBar.saving();

        const payLoad = {
          id_empresa: this.companyId,
          tipo: 'LATICINIO',
          ambiente: this.settings.environment,
          regime_tributario: this.settings.taxRegime,
          certificado_digital: this.settings.certificate,
          rotas: this.settings.routes,
          cnpj_autorizado_download_xml: this.settings.authorizedCnpjXmlDownload,
        };

        if (this.settings.password) {
          payLoad.senha_certificado = this.settings.password
        }

        await this.$axios.post(`/fiscal/settings`, payLoad);

        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        console.log(error);
        this.$snotify.error("Erro ao salvar configurações", "Atenção");
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    addNumeration() {
      this.numerationDialog = {
        show: true,
        id: null,
      }
    },

    editNumeration(item) {
      this.numerationDialog = {
        show: true,
        id: item.id,
      }
    },

    addOperation() {
      this.dialog = {
        show: true,
        id: null,
      }
    },

    editOperation(item) {
      this.dialog = {
        show: true,
        id: item.id,
      }
    },

    async removeOperation(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover esta natureza de operação?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/fiscal/operation/${id}`);

        this.loadOperations();
        this.$snotify.success('Natureza de Operação excluída com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onSelectFile([file]) {
      this.settings.certificate = {
        success: true,
        name: file.name,
        size: file.size,
        url: file.url
      }
    },

    async onRemoveFile() {
      try {
        const fileUrl = this.settings.certificate.url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('certificado_digital') && (fileUrl.includes('pfx') || fileUrl.includes('p12'))) {
          if (!(await this.$root.$confirm('Remover certificado', 'Tem certeza que deseja excluir este certificado?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.settings.certificate = null;
          await this.saveSettings();
        } else {
          this.settings.certificate = null;
        }
      } catch (err) {
        console.warn(err);
      }
    },

    applyToAllRoutes({ dia_pagamento }) {
      this.settings.routes = this.settings.routes.map(item => ({
        ...item,
        dia_pagamento
      }))
    },

    getEnvironment(value) {
      const envs = {
        1: 'Produção',
        2: 'Homologação',
      };
      return envs[value]
    },

    getDefault(value) {
      const defaults = {
        'VENDA': 'Venda',
        'COMPRA': 'Compra',
        'CONTRA_NOTA_PRODUTOR': 'Contra Nota Produtor',
        'DEVOLUCAO': 'Devolução',
        'BONIFICACAO': 'Bonificação',
        'REMESSA': 'Remessa',
        'NFPE_PADRAO': 'NFP-e Padrão',
      };
      return defaults[value] || '-'
    },

    async removeNumeration(o) {
      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir a Numeração NF-e ${o.series}?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/fiscal/numeration/${o.id}`);

        this.$snotify.success("Numeração de NF-e excluída com sucesso", "Sucesso");
        return await this.loadNumerations();
      } catch (e) {
        this.$snotify.error(`Oops, ocorreu um erro ao excluir a Numeração de NF-e`, "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
  },
};
</script>
