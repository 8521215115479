<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title class="px-3 pt-0">
        <v-row>
          <v-col cols="12">
            <span class="text-h6">
              Laudos e Anexos de Ordem de Produção nº {{ form.code }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="form.analysisNotes"
              label="Observações"
              placeholder=" "
              filled
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-row>
              <v-col
                class="text-center"
                cols="12"
              >
                <v-btn
                  outlined
                  class="mb-5"
                  @click="showFileUpload = true"
                >
                  <v-icon>attach_file</v-icon> Adicionar arquivos
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-for="(file, idx) in form.attachments"
                :key="idx"
                cols="3"
              >
                <file-viewer
                  :value="file"
                  :preview-height="165"
                  @removeFile="onRemoveFile(idx)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <file-upload
      v-model="showFileUpload"
      extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
      accept="application/pdf,text/xml,image/*"
      @insertFiles="onSelectFiles"
    />
  </v-dialog>
</template>

<script>
import FileUpload from '@/Support/Components/FileUpload.vue';
import FileViewer from '@/Support/Components/FileViewer.vue';

import get from 'lodash/get'

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

export default {

  components: {
    FileUpload,
    FileViewer,
  },

  props: {
    value: Boolean,
    orderId: String,
  },

  data() {
    return {
      form: {
        attachments: [],
      },

      showFileUpload: false,
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = {};
        this.loadOrder();
      }
    }
  },

  methods: {
    async loadOrder() {

      try {
        this.$root.$progressBar.loading();

        this.form = await api.show(this.orderId);
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao carregar ordem');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onSelectFiles(reports) {
      for (let file of reports) {
        if (!this.form.attachments.find(o => o.name === file.name)) {
          this.form.attachments.push({
            success: true,
            name: file.name,
            size: file.size,
            url: file.url
          });
        }
      }
    },

    async onRemoveFile(idx) {
      try {
        const fileUrl = this.form.attachments[idx].url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('ordem-producao')) {
          if (!(await this.$root.$confirm('Remover anexo', 'Tem certeza que deseja excluir este anexo?', { color: 'red' }))) {
            return;
          }
          this.$root.$progressBar.loading();
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.attachments.splice(idx, 1);
          await this.save();
        }
        else {
          this.form.attachments.splice(idx, 1);
        }
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao salvar');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      }
    },

    async save() {
      try {
        this.$root.$progressBar.saving();

        const payload = {
          observacao_laudo: this.form.analysisNotes,
          anexos: this.form.attachments,
        };

        await api.updateAttachments(this.orderId, payload);

        this.$snotify.success('Informações salvas com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao salvar');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.show = false;
    }
  },

}
</script>

