<template>
  <div class="pt-0">
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <data-table
            ref="report"
            :headers="headers"
            :items="search(items)"
            :search="filter.search"
            show-custom-group
            dark
            @click:row="edit"
          >
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="edit(item)">
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="blue"
        @click="add()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <add-vaccine-dialog
      v-model="showDialog"
      :edit-id="editId"
      @save="loadVaccines"
    />
  </div>
</template>

<script>
import AddVaccineDialog from "@/Domains/Projects/HealthExamination/Components/AddVaccineDialog.vue";

import moment from "moment/moment";

export default {

  components: {
    AddVaccineDialog,
  },

  data() {
    return {
      showDialog: false,

      editId: null,

      filter: {
        search: '',
      },

      headers: [
        { text: 'Produtor', value: 'producer.name', align: 'start' },
        { text: 'Tipo', value: 'type', align: 'start' },
        { text: 'Data', value: 'date', align: 'center' },
        { text: 'Quant. de Animais', value: 'quantity', align: 'center' },
        { text: 'Veterinário', value: 'veterinarian.name', align: 'start' },
        { text: 'Opções', value: 'action', width: 30, align: 'center' }
      ],

      items: [],
    };
  },

  mounted() {
    this.loadVaccines();
  },

  methods: {
    async loadVaccines() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam-vaccine`);

        this.items = data.map(item => {
          return {
            id: item.id,
            producer: item.producer ? {
              id: item.producer.id_pessoa,
              name: item.producer.nome,
            } : null,
            veterinarian: item.veterinarian ? {
              id: item.veterinarian.id_pessoa,
              name: item.veterinarian.nome,
            } : null,
            type: this.getType(item.tipo),
            date: this.formatDate(item.data, 'DD/MM/YYYY'),
            quantity: item.quantidade_animais,
          };
        });
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.editId = null;
      this.showDialog = true;
    },

    edit(item) {
      this.editId = item.id;

      this.showDialog = true;
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/projects/health-exam-vaccine/${id}`);

        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        await this.loadVaccines();
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

    getType: (value) => {
      const types = {
        B19: 'B19',
        RB51: 'VNIAA (RB51)'
      };

      return types[value] || value
    },

    print() {
      this.$refs.report.print(this.$refs.report.getReportJson().map(({Opções, ...rest}) => rest), 'Vacinas');
    },

    exportExcel() {
      this.$refs.report.exportExcel(this.$refs.report.getReportJson().map(({Opções, ...rest}) => rest), 'Vacinas');
    },

    search(data) {
      return this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
    }
  },
};
</script>
