export const ReasonsEnum = {
  IN_76_77: 'Salvo pela IN 76/77',
  INICIO_ATIVIDADES: 'Início de Atividades',
  TROCA_CADASTRO: 'Troca de Cadastro',
  PROSPECCAO_CONCORRENTE: 'Prospecção Concorrente',
  PAROU_ATIVIDADE: 'Parou com Atividade',
  TROCOU_EMPRESA: 'Trocou de Empresa',
  CORTADO_FALTA_VOLUME: 'Cortado por Falta de Volume',
  TROCA_TITULATIDADE: 'Troca de Titularidade',
  FORA_DE_ROTA: 'Fora de Rota',
  OBITO: 'Óbito',
  QUALIDADE: 'Qualidade',
  OUTROS: 'Outros',
  DIVISAO_LITRAGEM: 'Divisão de Litragem'
};
