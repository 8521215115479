/* eslint-disable no-undef */
import axios from "axios";
import Vue from "vue";
import store from "../Resources/vuex.js";
import _ from "lodash";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
});

const queries = axios.create({
  baseURL: process.env.VUE_APP_QUERIES_BASE_URL,
  withCredentials: true,
});

const ws = axios.create({
  baseURL: process.env.VUE_APP_WS_URL?.replace('wss://', 'https://')?.replace('ws://', 'http://'),
  withCredentials: true,
});

const partners = axios.create();

instance.interceptors.request.use(function (config) {
  const system = _.get(store, 'state.settings.sistema', null);
  const dairyId = _.get(store, 'state.settings.laticinio.id', null);
  const dairyName = _.get(store, 'state.settings.laticinio.name', null);

  if (!_.isEmpty(system)) {
    config.headers = {
      ...config.headers,
      'X-System': system,
    };
  }

  if (!_.isEmpty(dairyId) && !_.isEmpty(dairyName)) {
    config.headers = {
      ...config.headers,
      'X-Dairy-Id': dairyId,
      'X-Dairy-Name': dairyName,
    };
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    // intercept the global error
    //let originalRequest = error.config;
    let response = error.response || {};

    // passa adiante o erro se ele for um dos seguintes
    if (response.status === 400) {
      return Promise.reject(error);
    }

    if (response.status === 401) {
      window.localStorage.removeItem('vuex');
    }

    if (response.status === 401 && process.env.NODE_ENV !== 'development') {
      return window.location.replace(process.env.VUE_APP_HOME_URL);
    }

    if (response.status === 401 && process.env.NODE_ENV === 'development') {
      return window.location.replace('/local/login');
    }

    if (response.status === 500 && response?.data?.message === 'Service Unavailable') {
      return store.commit('maintenance/setMaintenanceMode');
    }

    /*  if (response.status === 405 && !originalRequest._retry) {
      originalRequest._retry = true;

      console.log("não foi possível remover", response.data);

      if (response.data.message) {
        Vue.prototype.$snotify.warning(response.data.message, "Atenção", { timeout: 5000 });
      }

      return response;
    }

    if (response && !originalRequest._retry) {
      originalRequest._retry = true;
      Vue.prototype.$snotify.error("Oops, ocorreu um erro inesperado, entre em contato com o suporte", "Atenção");
    } */

    return Promise.reject(error);
  }
);

Vue.prototype.$axios = instance;
Vue.prototype.$queries = queries;
Vue.prototype.$partners = partners;
Vue.prototype.$ws = ws;

export default instance;
export { queries, ws };
export const useAxios = () => instance;
