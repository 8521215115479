<template>
  <v-dialog
    v-model="show"
    width="600"
    max-width="800"
  >
    <v-card>
      <v-card-title>Editar Anexos</v-card-title>

      <v-card-text>
        <v-form
          ref="form"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-row>
                <v-col
                  class="py-0 text-center align-self-center flex-column justify-center"
                >
                  <v-btn
                    outlined
                    class="mb-5"
                    @click="showFileUpload = !showFileUpload"
                  >
                    <v-icon>attach_file</v-icon> Adicionar
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              v-for="(attachment, idx) in form.attachments"
              :key="attachment.url"
            >
              <file-viewer
                :value="attachment"
                @removeFile="onRemoveFile(idx)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          text
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-btn
          class="white--text"
          color="blue"
          text
          @click="atualizaResultado()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <file-upload
      v-model="showFileUpload"
      extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
      accept="application/pdf,text/xml,image/*"
      :multiselect="false"
      @insertFiles="onSelectFiles"
    />
  </v-dialog>
</template>

<script>
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

export default {
  components: {
    FileUpload,
    FileViewer
  },

  props: {
    value: Boolean,
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      showFileUpload: false,
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    async atualizaResultado() {
      try {
        this.$root.$progressBar.saving();

        await this.$axios.post(
          `/coleta/atualizaResultado`,
          this.$qs.stringify({
            id_coletas_amostra: this.form.id_coletas_amostra,
            anexos: this.form.attachments
          })
        );

        this.$emit('save', this.form.id_coletas_amostra);
        this.close();
      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.$refs.form && this.$refs.form.resetValidation();
      this.show = false;
    },

    onSelectFiles(files) {
      for (let file of files) {
        if (!this.form.attachments.find(o => o.url == file.url)) {
          this.form.attachments.push({
            success: true,
            tipo: file.name,
            size: file.size,
            url: file.url,
            formato: file.type
          });
        }
      }
    },

    async onRemoveFile(idx) {
      try {
        const fileUrl = this.form.attachments[idx].url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('exames')) {
          if (!(await this.$root.$confirm('Remover anexo', 'Tem certeza que deseja excluir este anexo?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.attachments.splice(idx, 1);
          await this.atualizaResultado();
        }
        else {
          this.form.attachments.splice(idx, 1);
        }
      } catch (err) {
        console.warn(err);
      }
    },
  }
}
</script>
