<template>
  <v-row>
    <v-col>
      <v-card
        color="transparent"
        dark
      >
        <v-data-table
          :headers="headers"
          :items="goals"
          hide-default-footer
          disable-sort
        >
          <template #[`item.class`]="{ item }">
            <v-text-field
              v-model.number="item.class"
              placeholder="A"
              type="text"
              dense
              outlined
              hide-details
            />
          </template>
          <template #[`item.start`]="{ item }">
            <v-text-field
              v-model.number="item.start"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.end`]="{ item }">
            <v-text-field
              v-model.number="item.end"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.buttons`]="{ item }">
            <v-btn
              icon
              color="white"
            >
              <v-icon
                @click="onRatingDelete(item.index)"
              >
                delete_outline
              </v-icon>
            </v-btn>
          </template>
          <template #[`body.append`]="{}">
            <tr>
              <td
                colspan="8"
                align="left"
              >
                <v-btn
                  outlined
                  rounded
                  text
                  @click="onRatingInsert();"
                >
                  <v-icon>
                    add
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {
  },

  props: {
    value: Array,
  },
  
  data() {
    return {
      headers: [
        { text: "Classe", value: "class", width: 80 },
        { text: "Pontos de:", value: "start", width: 100 },
        { text: "Até:", value: "end", width: 100 },
        { text: "", align: "right", value: "buttons", width: 8 },
      ],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    
  },
 
  methods: {
    
    onRatingInsert() {
      let range = this.goals;
      
      let index = !_.isEmpty(range) ? _.last(range).index + 1 : 1;
      let lastRange = range[0] ? _.last(range).end : 0;

      range.push({
        id: uuidv4(),
        index: index,
        class: '',
        start: lastRange + 1,
        end: 0,
      })
    },

    onRatingDelete(index) {
      const idx = this.goals.findIndex(o => o.index === index);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
}
</script>
