<template>
  <v-dialog
    v-model="show"
    width="30%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Edição/Cadastro de Crioscopia</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.valueCryoscopy"
                  label="Crioscopia *"
                  required
                  autofocus
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.waterPercent"
                  label="(%) Água *"
                  required
                  autofocus
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  props: {
    value: Boolean,
    editId: String,
    cryoscopys: [],
  },

  data() {
    return {
      form: {
        idCryoscopy: null,
        valueCryoscopy: '-0.',
        waterPercent: null,
      }
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          idCryoscopy: null,
          valueCryoscopy: '-0.',
          waterPercent: null,
        };
        
        if (this.editId) {
          this.loadPan();
        }
      }
    }
  },

  methods: {

    async loadPan() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/registrations/cryoscopy/${this.editId}`);

        this.form = {
          idCryoscopy: data.id_crioscopia,
          valueCryoscopy: data.valor_crioscopia,
          waterPercent: data.porcentagem_agua,
        };
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita uma crioscopia no banco de dados
     */
    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        this.form.valueCryoscopy = this.form.valueCryoscopy.replace(',', '.');
        this.form.waterPercent = this.form.waterPercent.replace(',', '.');

        const found = this.cryoscopys.find(item => parseFloat(item.valor_crioscopia) == parseFloat(this.form.valueCryoscopy)
          && item.id !== this.form.idCryoscopy);

        if (found) {
          this.$snotify.error("Não é possível cadastrar duas crioscopias com o mesmo valor", "Atenção");
          this.close();
          return;
        }

        const payload = {
          id_crioscopia: this.form.id,
          valor_crioscopia: this.form.valueCryoscopy,
          porcentagem_agua: this.form.waterPercent,
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);

        this.$snotify.success("Crioscopia cadastrada com sucesso", "Sucesso");
        
        this.close();
      
      } catch (e) {
        
        this.$snotify.error("Erro ao cadastrar crioscopia", "Atenção");
        
        console.warn(e);
      
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/cryoscopy`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/cryoscopy/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    }
  }

}
</script>