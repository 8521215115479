<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Integração por Arquivo XML NFe</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12">
              <v-file-input
                v-model="file"
                color="primary"
                counter
                label="Selecionar arquivo"
                placeholder="Selecionar arquivo XML"
                prepend-icon="attach_file"
                outlined
                accept=".xml"
                :show-size="1000"
                :rules="[
                  rules.required
                ]"
              >
                <template #selection="{ text }">
                  <v-chip
                    color="primary"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!!file"
          outlined
          color="primary"
          @click="save()"
        >
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import get from 'lodash/get'

export default {

  data() {
    return {
      dialog: false,

      file: null,

      rules: {
        required: v => !!v || 'Campo obrigatório!',
      },
    }
  },

  methods: {
    show() {
      this.reset();
      this.dialog = true;
    },

    reset() {
      this.file = null;
    },

    close() {
      this.dialog = false;
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const formData = new FormData();
        formData.append('file', this.file);

        const { data } = await this.$axios.post('/fiscal/invoice/import', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        this.$snotify.success('Nota importada com sucesso', 'Sucesso');
        this.close();
        this.$emit('import', data.invoice);
      } catch (error) {
        const message = get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

  },

}
</script>
