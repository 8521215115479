<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent="save()"
    >
      <v-row
        v-for="(data, idx) of items"
        :key="idx"
        style="border-bottom: 1px #eee solid;"
      >
        <v-col class="pt-1 pb-0">
          <v-text-field
            :value="data.item?.description"
            label="Produto"
            disabled
          />
        </v-col>
        <v-col
          v-if="hasInputsAccess && !$vuetify.breakpoint.mobile"
          class="pt-1 pb-0"
          cols="6"
          sm="3"
          md
        >
          <masked-text-field
            v-model="data.quantity"
            label="Qtde. Base"
            persistent-hint
            unmask
            :mask="masks.float"
            :suffix="data.item?.measurement"
            disabled
          />
        </v-col>
        <v-col
          v-if="hasPlannedQuantityAccess && data.plannedQuantity > 0"
          class="pt-1 pb-0"
          cols="6"
          sm="3"
          md
        >
          <masked-text-field
            :value="data.plannedQuantity"
            label="Qtde. Planejada"
            unmask
            :mask="masks.float"
            :suffix="data.item?.measurement"
            disabled
          />
        </v-col>
        <v-col
          v-if="hasInputsAccess"
          class="pt-1 pb-0"
          cols="6"
          sm="3"
          md
        >
          <masked-text-field
            v-model="data.accomplishedQuantity"
            label="Qtde. Realizada"
            persistent-hint
            unmask
            :mask="masks.float"
            :suffix="data.item?.measurement"
            disabled
          />
        </v-col>
        <v-col
          v-if="data.showLosses || data.lossesQuantity"
          class="pt-1 pb-0 d-flex align-center"
          cols="6"
          sm="3"
          md
        >
          <masked-text-field
            v-model="data.lossesQuantity"
            label="Perdas"
            persistent-hint
            unmask
            :mask="masks.float"
            :suffix="data.item?.measurement"
            disabled
          />
          <v-btn
            icon
            :color="canEdit ? 'green' : undefined"
            @click="addLoss(data)"
          >
            <v-icon>
              {{ canEdit ? 'add' : 'visibility' }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-if="canEdit"
          class="pl-0 d-flex flex-column justify-center"
          style="max-width: 149px;"
        >
          <v-btn
            v-if="data.item?.scaleItem"
            outlined
            color="purple"
            @click="onWeight(data)"
          >
            <v-icon left>
              scale
            </v-icon>
            Pesar
          </v-btn>
          <v-btn
            v-else
            outlined
            color="primary"
            @click="onAppointment([data])"
          >
            Apontar
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="canEdit && items.length === 0">
        <v-col
          cols="12"
          class="text-center"
          :class="{ 'has-error': hasError }"
        >
          Nenhum insumo disponível para efetuar baixas
        </v-col>
      </v-row>
    </v-form>

    <span class="text-h6 d-block mt-4">Registros</span>

    <v-divider class="mb-4" />

    <v-data-table
      :headers="headers"
      :items="records"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
    >
      <template #[`item.date`]="{ value }">
        {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
      </template>
      <template #[`item.quantity`]="{ item }">
        {{ formatNumber(item.quantity) }} {{ item.measurement }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-tooltip
          v-if="hasChargebackAccess"
          top
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click.stop="chargeback(item)"
            >
              <v-icon>
                settings_backup_restore
              </v-icon>
            </v-btn>
          </template>
          Estornar
        </v-tooltip>
      </template>
    </v-data-table>

    <product-weighing-dialog
      ref="productWeighingDialog"
      :records="records"
      @chargeback="chargeback"
      @save="onWeightSaved"
    />

    <product-write-off-dialog
      ref="productWriteOffDialog"
      @save="reload"
    />

    <production-losses-dialog
      ref="lossesDialog"
      @save="reload"
    />
  </div>
</template>

<style scoped>
.has-error {
  animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: red;
}
</style>

<script>
import ProductWriteOffDialog from '@/Domains/Industry/ProductionOrder/Components/ProductWriteOffDialog.vue';
import ProductionLossesDialog from '@/Domains/Industry/ProductionOrder/Components/ProductionLossesDialog.vue';
import ProductWeighingDialog from '@/Domains/Industry/ProductionOrder/Components/ProductWeighingDialog.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

import moment from 'moment';

export default {

  components: {
    ProductWriteOffDialog,
    ProductionLossesDialog,
    ProductWeighingDialog,
    MaskedTextField,
  },

  props: {
    canEdit: Boolean,
    order: {
      type: Object,
      default: () => ({
        items: [],
        records: [],
      })
    },
    step: Object,
  },

  data() {
    return {
      items: [],

      tinas: [],

      headers: [
        { text: 'Produto', value: 'product.name' },
        { text: 'Data e Hora', value: 'date' },
        { text: 'Pessoa', value: 'person' },
        { text: 'Qtde.', value: 'quantity', align: 'center' },
        { text: 'Lote', value: 'lotNumber', align: 'center' },
        { text: 'Queijomatic', value: 'tank.name', align: 'center' },
        { text: '', value: 'action', width: 30, sortable: false },
      ],

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },

      hasError: false,
    }
  },

  computed: {
    records() {
      const ids = this.items.map(item => item.id);
      return this.order.records.filter(record => ids.includes(record.itemId));
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasInputsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-inputs-apontamento' && o.tipo === 'COMPONENTE');
    },
    hasChargebackAccess() {
      if (!this.canEdit) {
        return false
      }
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-exclusao-apontamento' && o.tipo === 'COMPONENTE');
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.items = this.order.items
        .filter(data => !data.item.isRawMaterial)
        .map(item => {
          const plannedQuantity = item.quantity * this.order.quantity;
          const writeOffQuantity = plannedQuantity - item.accomplishedQuantity;
          return {
            ...item,
            plannedQuantity,
            writeOffQuantity: (this.hasInputsAccess && writeOffQuantity > 0) ? writeOffQuantity : null,
          }
        });

      if (this.step) {
        this.items = this.items
          .filter(item => !item.step || item.step.id === this.step.id)
      }

      this.loadTinas()
    },

    async loadTinas() {
      try {
        const { data } = await this.$axios.get(`/silo/listaTina`);

        this.tinas = data;
      } catch (e) {
        this.$snotify.error('Erro ao carregar as tinas', 'Atenção');
        console.warn(e);
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      this.hasError = this.items.length === 0;

      if (this.hasError) {
        setTimeout(() => {
          this.hasError = false;
        }, 2000);

        return;
      }

      const normalItems = this.items.filter(data => !data.item.scaleItem);
      const scaleItems = this.items.filter(data => data.item.scaleItem);

      if (normalItems.length > 0) {
        this.onAppointment(normalItems);
        return;
      }

      if (scaleItems.length > 0) {
        this.onWeight(scaleItems[0]);
      }
    },

    onAppointment(items) {
      this.$refs.productWriteOffDialog.show({
        order: this.order,
        tinas: this.tinas,
        items
      });
    },

    onWeight(data) {
      this.$refs.productWeighingDialog.show(data);
    },

    async onWeightSaved({ records }) {
      for (const history of records) {
        const product = this.items.find(p => p.item.id === history.id_item)
        const tina = this.tinas.find(tina => tina.id_tina === history.id_tina)
        const record = {
          id: history.id_movimento_item_estoque,
          itemId: history.id_ordem_producao_item,
          byproductId: history.id_ordem_producao_subproduto,
          product: product?.item ? {
            id: product.item.id,
            code: product.item.code,
            name: product.item.description,
          } : {},
          tank: tina ? {
            id: tina.id_tina,
            name: tina.descricao,
          } : {},
          date: history.data_hora_cadastro,
          operation: history.entrada_saida == 1 ? 'ENTRADA' : 'SAIDA',
          quantity: parseFloat(history.quantidade) * (history.entrada_saida == 2 ? -1 : 1),
          measurement: history.unidade,
          price: parseFloat(history.valor),
          person: history.nome_pessoa_registro,
          stock: parseFloat(history.estoque),
          lotNumber: history.numero_lote,
          lotStock: parseFloat(history.estoque_lote),
          lotStatus: history.status_lote,
          manufacturingDate: history.data_fabricacao,
          expirationDate: history.data_validade,
          notes: history.observacao,
        }

        this.order.records.push(record);

        if (product) {
          product.accomplishedQuantity += parseFloat(history.quantidade)
          product.writeOffQuantity = null
        }
      }
    },

    async chargeback(item) {
      if (!this.canEdit) {
        return;
      }

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente estornar este apontamento?<br><br>`, { color: 'red', token: 'ESTORNAR' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.post(`/stock/chargeback`, {
          id: item.id,
        });

        const idx = this.order.records.findIndex(record => record.id === item.id)

        this.order.records.splice(idx, 1)

        const product = this.items.find(p => p.item.id === item.product.id)

        if (product) {
          product.accomplishedQuantity += item.quantity
        }

        this.$snotify.success('Estorno efetuado com sucesso', 'Sucesso');
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao estornar o apontamento!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    addLoss(data) {
      this.$refs.lossesDialog.show({
        order: this.order,
        canEdit: this.canEdit,
        type: 'ITEM',
        tinas: this.tinas,
        data,
      });
    },

    reload() {
      this.$emit('reload');
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
