<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Tabela de valores
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="filter.search"
            dark
            class="elevation-1 report-table"
          >
            <template #[`item.settings`]="{ item }">
              <v-btn
                icon
                @click="editPrices(item)"
              >
                <v-icon>settings_applications</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="pricesDialog.show"
      scrollable
      :max-width="1200"
    >
      <v-card>
        <v-card-title>
          {{ pricesDialog.freighter }}
          <v-spacer />

          <v-btn
            icon
            small
            @click="pricesDialog.show = false"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <v-form
            ref="form"
            lazy-validation
            style="max-height: calc(100vh - 250px);"
          >
            <v-data-table
              :headers="pricesHeaders"
              :items="pricesDialog.items"
              class="elevation-1"
              show-expand
              single-expand
              hide-default-footer
              item-key="id_equipamento"
              :items-per-page="-1"
              dense
            >
              <template #[`item.capacidade`]="{ value }">
                {{ formatNumber(value) }} L
              </template>
              <template #[`item.data`]="{ item }">
                <v-text-field
                  v-model="item.data"
                  label="Data"
                  type="date"
                  outlined
                  dense
                  hide-details
                  :rules="[rules.date]"
                />
              </template>
              <template #[`item.tipo_pagamento`]="{ item }">
                <v-radio-group
                  v-model="item.tipo_pagamento"
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio
                    label="Km"
                    value="KM"
                    class="mb-0"
                  />
                  <v-radio
                    label="Volume"
                    value="VOLUME"
                    class="mb-0"
                  />
                  <v-radio
                    label="Faixa (Km)"
                    value="FAIXA"
                    class="mb-0"
                  />
                  <v-radio
                    label="Carga"
                    value="CARGA"
                  />
                </v-radio-group>
              </template>
              <template #[`item.tipo_km`]="{ item }">
                <v-radio-group
                  v-model="item.tipo_km"
                  dense
                  hide-details
                  class="mt-0"
                  :disabled="item.tipo_pagamento === 'VOLUME' || item.tipo_pagamento === 'CARGA'"
                >
                  <v-radio
                    label="Planejado"
                    value="PLANEJADO"
                    class="mb-0"
                  />
                  <v-radio
                    label="Odômetro"
                    value="ODOMETRO"
                  />
                </v-radio-group>
              </template>
              <template #[`item.valor`]="{ item }">
                <v-text-field
                  v-if="['KM', 'VOLUME', 'CARGA'].includes(item.tipo_pagamento)"
                  v-model="item.valor"
                  :label="`Valor por ${item.tipo_pagamento.toLowerCase()}`"
                  type="number"
                  prefix="R$"
                  outlined
                  dense
                  hide-details
                  :rules="[rules.notNegative]"
                  @keypress="onlyPositiveValues"
                />
                <div v-else>
                  <v-menu
                    v-model="item.show"
                    :close-on-content-click="false"
                    :nudge-width="200"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        label="Tabela"
                        :value="item.table ? item.table.titulo : null"
                        append-icon="expand_more"
                        outlined
                        dense
                        hide-details
                        readonly
                        v-bind="attrs"
                        :rules="[v => !!v || 'Faixas não configuradas']"
                        v-on="on"
                        @click:append="item.show = true"
                      />
                    </template>

                    <price-range-selector-settings
                      v-model="item.table"
                      :tables="tables"
                      @close="item.show = false"
                    />
                  </v-menu>
                </div>
              </template>
              <template #[`item.action`]="{ item }">
                <v-menu
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="applyToAll(item)"
                    >
                      <v-list-item-icon>
                        <v-icon
                          style="transform: rotate(90deg);"
                        >
                          multiple_stop
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Aplicar a todos</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
                <v-btn
                  v-if="item.tabela.length > 1"
                  icon
                  link
                  transition="fab-transition"
                  @click="expand(!isExpanded)"
                >
                  <v-icon>
                    {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                  </v-icon>
                </v-btn>
              </template>

              <template #expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0 text-center"
                >
                  <v-data-table
                    :headers="detailsDeaders"
                    :items="item.tabela"
                    :items-per-page="-1"
                    dense
                    hide-default-footer
                    class="elevation-1 ma-3"
                  >
                    <template #[`item.data`]="{value}">
                      <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
                    </template>
                    <template #[`item.valor`]="{ item }">
                      <span v-if="item.titulo_faixa_frete">{{ item.titulo_faixa_frete }}</span>
                      <span v-else>R$ {{ formatCurrency(item.valor) }}</span>
                    </template>

                    <template #[`item.action`]="{ item }">
                      <v-btn
                        icon
                        small
                        @click.stop="removeConfig(item.id_valor_frete)"
                      >
                        <v-icon
                          color="red"
                        >
                          delete
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            outlined
            color="grey"
            @click="pricesDialog.show = false"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="hasChanges"
            color="orange darken-1"
            outlined
            :disabled="!hasChanges"
            @click="discardChanges"
          >
            Desfazer alterações
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            :disabled="!hasChanges"
            :loading="saving"
            @click="savePrices"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="scss">
  .input-has-changed.v-input:not(.v-input--is-focused):not(.v-input--has-state) {
    color: orange !important;
    caret-color: orange !important;
    .v-input__control > .v-input__slot fieldset {
      color: orange !important;
    }

    .v-label {
      color: orange
    }
  }
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";

import PriceRangeSelectorSettings from '@/Domains/Collects/Management/FreightValueTable/Components/PriceRangeSelectorSettings.vue'

export default {

  components: {
    PriceRangeSelectorSettings
  },

  props: {
    type: {
      type: String,
      default: 'COLETA',
      validator: (value) => ['COLETA', 'TRANSFERENCIA'].includes(value)
    }
  },

  data() {
    return {

      loading: false,
      saving: false,

      filter: {
        search: '',
      },

      headers: [
        { text: 'Freteiro', value: 'prestador' },
        { text: 'Veículos', value: 'items.length' },
        { text: '', value: 'settings', width: 50, sortable: false },
      ],
      items: [],

      pricesDialog: {
        show: false,

        freighter: '',

        items: [],
      },

      rules: {
        date: v => !v || moment(v, 'YYYY-MM-DD').isValid() || 'Campo Inválido!',
        required: v => !!v || !_.isEmpty(v) || 'Campo Obrigatório!',
        notNegative: v => !v || v >= 0 || 'Valor não pode ser negativo',
      },

      tables: [],
    };
  },

  computed: {
    hasChanges() {
      return !_.isEqual(this.pricesDialog.items, this.pricesDialog.originalItems)
    },

    pricesHeaders() {
      const headers = [
        { text: 'Código', value: 'cod_equipamento', width: 100 },
        { text: 'Placa', value: 'placa' },
        { text: 'Modelo', value: 'modelo' },
        { text: 'Capacidade', value: 'capacidade' },
        { text: 'Data', value: 'data', sortable: false },
        { text: 'Tipo de Pagamento', value: 'tipo_pagamento', sortable: false },
      ];

      if (this.type === 'COLETA') {
        headers.push({ text: 'Tipo de Km', value: 'tipo_km', sortable: false });
      }

      headers.push(
        { text: 'Valor', value: 'valor', width: 180, sortable: false },
        { text: '', value: 'action', width: 30, sortable: false }
      );

      return headers;
    },

    detailsDeaders() {
      const headers = [
        { text: 'Data', value: 'data', sortable: false },
        { text: 'Tipo de Pagamento', value: 'tipo_pagamento', sortable: false },
      ];

      if (this.type === 'COLETA') {
        headers.push({ text: 'Tipo de Km', value: 'tipo_km', sortable: false });
      }

      headers.push(
        { text: 'Valor', value: 'valor', width: 180, sortable: false },
        { text: '', value: 'action', width: 30, sortable: false },
      );

      return headers;
    },
  },

  mounted() {
    this.loadRanges();
    this.loadFleet();
  },

  methods: {
    async loadRanges() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/settings/freight-ranges`);

        this.tables = data;

      }
      catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as faixas!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadFleet() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/fretes/tabelaDeFretes`, {
          params: {
            type: this.type
          }
        });

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = _(data)
          .groupBy('id_prestador')
          .map((items) => {
            const [{ prestador }] = items;
            return {
              prestador,
              items: items.map(o => ({
                ...o,
                tipo_pagamento: o.tipo_pagamento || 'KM',
                tipo_km: o.tipo_km || 'ODOMETRO',
                table: o.id_faixa_frete ? {
                  id_faixa_frete: o.id_faixa_frete,
                  titulo: o.titulo_faixa_frete,
                  faixas: o.faixas,
                } : null,
                valor: parseFloat(o.valor) || null,
                show: false
              }))
            }
          }).value();
      }
      catch (err) {
        this.$snotify.error("Erro ao carregar equipamentos", "Atenção");
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    editPrices({ prestador, items }) {
      this.pricesDialog.show = true;
      this.pricesDialog.freighter = prestador;
      this.pricesDialog.originalItems = _.cloneDeep(items);
      this.pricesDialog.items = _.cloneDeep(items);
    },

    discardChanges() {
      this.pricesDialog.items = _.cloneDeep(this.pricesDialog.originalItems);
      this.$refs.form.resetValidation()
    },

    async savePrices(closeDialog = true) {
      if (!await this.$refs.form.validate()) {
        return;
      }

      const items = this.pricesDialog.items.map(item => ({
        id_equipamento: item.id_equipamento,
        data: item.data || moment().format('YYYY-MM-DD'),
        tipo_pagamento: item.tipo_pagamento,
        tipo_km: item.tipo_pagamento !== 'VOLUME' ? item.tipo_km : null,
        id_faixa_frete: item.tipo_pagamento === 'FAIXA' && item.table ? item.table.id_faixa_frete : null,
        valor: item.tipo_pagamento !== 'FAIXA' ? (parseFloat(item.valor) || 0) : null,
      }));

      try {
        this.saving = true;

        let { data } = await this.$axios.post(`/fretes/salvaValoresFrete`, {
          type: this.type,
          items
        });

        if (!data || data.codigo != 1) {
          throw data;
        }
        this.$snotify.success("Tabela de valores atualizada com sucesso", "Sucesso");
        this.loadFleet();
      }
      catch (err) {
        this.$snotify.error("Erro ao atualizar tabela de valores", "Atenção");
        console.warn(err)
      }
      finally {
        this.saving = false;
        if (closeDialog) {
          this.pricesDialog.show = false;
        }
      }
    },

    applyToAll({ data, tipo_pagamento, tipo_km, valor, table }) {
      this.pricesDialog.items = this.pricesDialog.items.map(item => ({
        ...item,
        data: data || moment().format('YYYY-MM-DD'),
        tipo_pagamento,
        tipo_km,
        valor,
        table
      }))
    },

    async removeConfig(id_valor_frete) {
      if (!(await this.$root.$confirm(
        'Confirmar remoção?',
        'Esta ação não poderá ser desfeita.',
        { color: 'red' }
      ))) {
        return;
      }

      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/fretes/removerItemTabela`, { id_valor_frete });

        if (!data || data.codigo != 1) {
          throw data;
        }
        this.$snotify.success("Tabela de valores atualizada com sucesso", "Sucesso");
        this.loadFleet();
      }
      catch (err) {
        this.$snotify.error("Erro ao atualizar tabela de valores", "Atenção");
        console.warn(err)
      }
      finally {
        this.loading = false;
        this.pricesDialog.show = false;
      }
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    onlyPositiveValues: (e) => [45, 46].includes(e.charCode) &&  e.preventDefault(),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
