<template>
  <v-dialog
    v-model="dialog"
    :max-width="400"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">{{ form.description || 'Cadastro de Armazenamento' }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formRef">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.description"
                label="Descrição *"
                placeholder="Armário/Armazém/Rua..."
                :rules="[v => !!v || 'Campo obrigatório']"
              />
            </v-col>
            <v-col
              v-if="!warehouseId"
              cols="12"
            >
              <warehouse-select
                v-model="form.warehouseId"
                label="Depósito"
                auto-select-default
                :rules="[v => !!v || 'Campo obrigatório']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          outlined
          @click.native="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          outlined
          @click.native="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import { v4 as uuidv4 } from 'uuid'

const { progressBar, notify } = useUtils()

// eslint-disable-next-line
const props = defineProps({
  warehouseId: String,
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const dialog = ref(false)

const formRef = ref()

const form = reactive({
  description: null,
})

const show = (id = null) => {
  dialog.value = true
  form.id = id || uuidv4()
  form.warehouseId = props.warehouseId
  if (id) {
    load(id)
  }
  formRef.value?.reset()
}

const close = () => {
  form.id = null
  form.description = null
  form.warehouseId = props.warehouseId
  dialog.value = false
}

const load = async (id) => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`storage/${id}`)

    form.description = data.descricao;
    form.warehouseId = data.id_deposito;
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const save = async () => {
  try {
    if (!await formRef.value?.validate()) {
      return
    }

    progressBar?.saving()

    const payload = {
      id_deposito: form.warehouseId,
      armazenamentos: [{
        id: form.id,
        descricao: form.description,
        pallets: []
      }]
    };

    await axios.post(`storage/`, payload)

    emit('save')
    close()
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show, close })
</script>
