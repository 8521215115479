<template>
  <v-card
    color="transparent"
    dark
    class="technician-resume"
  >
    <v-card-title
      class="text-subtitle-2 pt-2 pb-2"
      style="background: rgba(0, 0, 0, 0.5);"
    >
      <slot name="title">
        Resumo do técnico
      </slot>

      <v-spacer />

      <slot name="actions" />
    </v-card-title>

    <v-card-text
      class="pa-0"
      style="background: rgba(0, 0, 0, 0.3);"
    >
      <v-row
        class="text-body-2 pt-1 pb-1 ma-0"
        align="center"
        justify="center"
      >
        <slot name="append" />

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ formatNumber(vol) }} L <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Média de volume coletado nos últimos 6 dias</span>
          </v-tooltip>
        </v-col>

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          {{ producers.length }} Produtores
        </v-col>

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-chip
            v-for="(route, idx) in routes"
            :key="idx"
            small
            color="primary"
          >
            <v-icon
              left
              small
            >
              map
            </v-icon>
            {{ route.descricao }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.technician-resume > .v-card__text {
  color: white !important;
}
</style>

<script>
export default {

  props: {
    producers: Array,
    textLeft: Boolean,
    routes: Array,
  },

  computed: {

    /**
     * Volume total de leite (Em Litros)
     */
    vol() {
      return this.producers
        .filter(producer => producer.status === 'ATIVO')
        .reduce((acc, producer) => (acc + producer.vol), 0);
    },

    textClass() {
      if (this.textLeft) {
        return {
          "text-left": true,
        };
      }

      return {};
    },
  },

  methods: {
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 0 }).format(value),
  }
};
</script>
