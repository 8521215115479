<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      item-key="id"
      show-expand
      single-expand
      disable-filtering
      disable-pagination
      hide-default-footer
      @click:row="$event => $emit('edit', $event)"
    >
      <template #expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pa-0 text-center"
        >
          <v-data-table
            :headers="productsHeaders"
            :items="item.products"
            :items-per-page="-1"
            dense
            hide-default-footer
            class="elevation-1 ma-3"
          >
            <template #[`item.quantity`]="{ value, item }">
              {{ formatNumber(value) }} {{ item.item.measurement }}
            </template>
          </v-data-table>
        </td>
      </template>

      <template #[`item.dateTime`]="{ value }">
        {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$emit('edit', item)">
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Editar
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('delete', item)">
              <v-list-item-icon>
                <v-icon>delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Excluir
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import moment from 'moment';
import { isEmpty } from 'lodash';

// eslint-disable-next-line no-undef
const props = defineProps({
  items: Array,
  search: String,
})

const headers = [
  { text: 'Código', value: 'code' },
  { text: 'Destinatário', value: 'customer.name' },
  { text: 'Data/Hora Entrega', value: 'dateTime' },
  { text: 'Produtos', value: 'products.length' },
  { text: 'Observação', value: 'notes' },
  { align: 'end', text: '', value: 'actions', width: 80 },
]

const productsHeaders = [
  { text: 'Produto', value: 'item.description' },
  { text: 'Quantidade', value: 'quantity' },
]

const filteredItems = computed(() => {
  if (!props.search) {
    return props.items;
  }

  const search = props.search?.toUpperCase()?.trim();

  return props.items.filter(item => {
    return JSON.stringify(Object.values(item)).toUpperCase().includes(search);
  });
})

const formatDate = (value, format) => isEmpty(value) ? '' : moment(value).format(format)
const formatNumber = val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val)

</script>
