<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Impacto De Volume
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-row>
        <v-col
          md="4"
        >
          <v-autocomplete
            v-model="filter.status"
            :items="[
              { text: 'Ativos', value: 'ATIVOS' },
              { text: 'Desvinculados', value: 'DESVINCULADOS' },
            ]"
            label="Exibir"
            placeholder=" "
            hide-details
            dark
            filled
            @change="onDateFilter"
          />
        </v-col>
        <v-spacer />
        <v-col md="4">
          <VDateRangePicker
            v-model="filter.date"
            dark
            @change="onDateFilter"
          />
        </v-col>
      </v-row>

      <v-flex xs12>
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#aba-impacto">
            Impacto Na Qualidade
          </v-tab>
          <v-tab href="#aba-graficoimpacto">
            Gráfico Impacto Na Qualidade
          </v-tab>
          <v-tab href="#aba-volume">
            Participação No Volume
          </v-tab>
          <v-tab href="#aba-graficovolume">
            Gráfico Participação No Volume
          </v-tab>

          <v-tab-item value="aba-impacto">
            <v-card
              color="transparent"
              dark
            >
              <v-card-title>
                <v-spacer />
                <v-col
                  cols="4"
                  class="pa-0"
                >
                  <v-text-field
                    v-model="filter.search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  />
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="impact.headers"
                :items="impact.items"
                :search="filter.search"
                :loading="loading"
                class="elevation-1"
              >
                <template #[`item.volume`]="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #[`item.result`]="{value}">
                  {{ formatNumber(value) }}%
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item value="aba-volume">
            <v-card
              color="transparent"
              dark
            >
              <v-card-title>
                <v-spacer />
                <v-col
                  cols="4"
                  class="pa-0"
                >
                  <v-text-field
                    v-model="filter.search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  />
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="volume.headers"
                :items="volume.items"
                :search="filter.search"
                class="elevation-1"
              >
                <template #[`item.mvolume`]="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #[`item.pvolume`]="{value}">
                  {{ formatNumber(value) }}%
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item value="aba-graficoimpacto">
            <v-card
              color="transparent"
              dark
            >
              <v-card-title>
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="impactGraph.options"
                    :style="{width: '100%', height: `${impactGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item value="aba-graficovolume">
            <v-card
              color="transparent"
              dark
            >
              <v-card-title>
                <v-card-text>
                  <v-chart
                    type="bar"
                    :options="volGraph.options"
                    :style="{width: '100%', height: `${volGraph.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card-title>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>

    <v-speed-dial
      v-if="['aba-impacto', 'aba-volume'].includes(tab)"
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "ColetaImpactoVolume",

  components: {
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      tab: "aba-impacto",

      filter: {
        search: '',
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        status: 'ATIVOS',
      },

      impact: {
        headers: [
          { text: "Produtor", value: "nome", width: 300 },
          { text: "CPP", value: "cbt", width: 200 },
          { text: "Volume", value: "volume", width: 200 },
          { text: "% Participação", value: "result", width: 200, groupDesc: true, sortDesc: true },
        ],
        items: []
      },
      volume: {
        headers: [
          { text: "Produtor", value: "nome", width: 300 },
          { text: "Geo. CPP", value: "cbt", width: 200 },
          { text: "Volume Médio", value: "mvolume", width: 200 },
          { text: "% Volume", value: "pvolume", width: 200, groupDesc: false, sortDesc: false },
        ],
        items: [],
      },
    };
  },
  computed: {

    impactGraph() {
      return {
        height: 50 + (this.impact.items.length * 25),
        options: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: ([param]) => `${param.name}<br>${param.marker} ${param.seriesName} <b>${this.formatNumber(param.data)}%</b>`
          },
          grid: {
            left: '1%',
            right: '5%',
            top: '0%',
            bottom: '0%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            scale: true,
            position: 'top',
            min: 0,
            boundaryGap: false,
            splitLine: { show: false },
            axisLine: { show: true },
            axisTick: { show: false },
            axisLabel: {
              textStyle: { color: 'transparent' }
            },
          },
          yAxis: {
            type: 'category',
            inverse: true,
            data: this.impact.items.map(({ nome }) => nome).reverse(),
            axisLabel: {
              fontSize: 10,
              textStyle: { color: '#ddd' }
            },
          },
          series: [{
            name: 'Impacto na Qualidade',
            type: 'bar',
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              distance: -1,
              padding: [2.5, 10],
              borderRadius: [0, 30, 30, 0],
              textShadowColor: '#000',
              textShadowBlur: 3,
              formatter: ({ value }) => `${this.formatNumber(value)}%`
            },
            data: this.impact.items.map(({ result }) => result).reverse(),
          }],
        }
      }
    },

    volGraph() {
      return {
        height: 50 + (this.volume.items.length * 25),
        options: {
          color: ["#B71C1C"],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: ([param]) => `${param.name}<br>${param.marker} ${param.seriesName} <b>${this.formatNumber(param.data)}%</b>`
          },
          grid: {
            left: '1%',
            right: '5%',
            top: '0%',
            bottom: '0%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            scale: true,
            position: 'top',
            min: 0,
            boundaryGap: false,
            splitLine: { show: false },
            axisLine: { show: true },
            axisTick: { show: false },
            axisLabel: {
              textStyle: { color: 'transparent' }
            },
          },
          yAxis: {
            type: 'category',
            inverse: true,
            data: this.volume.items.map(({ nome }) => nome).reverse(),
            axisLabel: {
              fontSize: 10,
              textStyle: { color: '#ddd' }
            },
          },
          series: [{
            name: 'Participação no Volume',
            type: 'bar',
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              distance: -1,
              padding: [2.5, 10],
              borderRadius: [0, 30, 30, 0],
              textShadowColor: '#000',
              textShadowBlur: 3,
              formatter: ({ value }) => `${this.formatNumber(value)}%`
            },
            data: this.volume.items.map(({ pvolume }) => pvolume).reverse(),
          }],
        }
      }
    },
  },

  methods: {
    onDateFilter() {
      this.loadImpactReport();
      this.loadVolReport();
    },

    async loadImpactReport() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.date;

        let { data } = await this.$axios.post(
          `/coleta/impactoVolumePercentual`,
          this.$qs.stringify({
            data_ini: moment(startDate).format('DD/MM/YYYY'),
            data_fim: moment(endDate).format('DD/MM/YYYY'),
            status: this.filter.status,
          })
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.impact.items = data.map(item => ({
          ...item,
          cbt: Math.abs(item.cbt).toFixed(0),
          volume: Math.abs(item.volume).toFixed(1),
          result: Math.abs(item.result).toFixed(3),
        }));
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadVolReport() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.date;

        let { data } = await this.$axios.post(
          `/coleta/impactoVolume`,
          this.$qs.stringify({
            data_ini: moment(startDate).format('DD/MM/YYYY'),
            data_fim: moment(endDate).format('DD/MM/YYYY'),
            status: this.filter.status,
          })
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.volume.items = data.map(item => ({
          ...item,
          cbt: Math.abs(item.cbt).toFixed(0),
          mvolume: Math.abs(item.mvolume).toFixed(1),
          pvolume: Math.abs(item.pvolume).toFixed(3),
        }));
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getImpactReportJson() {
      return this.impact.items.map(o => ({
        Produtor: o.nome,
        CPP: o.cbt,
        Volume: this.formatNumber(o.volume),
        '% Participação': `${this.formatNumber(o.result)}%`,
      }));
    },

    getVolReportJson() {
      return this.volume.items.map(o => ({
        Produtor: o.nome,
        'Geo. CPP': o.cbt,
        'Volume Médio': this.formatNumber(o.mvolume),
        '% Volume': `${this.formatNumber(o.pvolume)}%`,
      }));
    },

    exportExcel() {
      if (this.tab === 'aba-impacto') {
        const data = this.XLSX.utils.json_to_sheet(this.getImpactReportJson());
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Impacto na Qualidade';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else if (this.tab === 'aba-volume') {
        const data = this.XLSX.utils.json_to_sheet(this.getVolReportJson());
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Participação no Volume';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
    },

    print() {
      if (this.tab === 'aba-impacto') {
        return this.printFromJson(this.getImpactReportJson(), 'Impacto na Qualidade');
      }
      if (this.tab === 'aba-volume') {
        return this.printFromJson(this.getVolReportJson(), 'Participação no Volume');
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
