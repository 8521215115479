<template>
  <div>
    <v-timeline-item
      color="blue lighten-1"
      fill-dot
      right
      large
    >
      <template #icon>
        <span
          class="text-overline text-center"
          style="color: white; font-size: 12px !important;"
        >
          <v-icon
            small
            color="white"
            v-html="'done_all'"
          />
          {{ item.date | formatDate('HH:mm') }}
        </span>
      </template>

      <v-card class="elevation-2">
        <v-card-title
          class="text-overline pa-0 pt-1 pl-2 pr-2 pb-2"
          style="font-size: 12px !important;"
        >
          <span><b>{{ item.producer.code }} | {{ item.producer.name }}</b></span>
        </v-card-title>

        <v-card-text class="pb-2">
          <v-row
            v-if="showDate"
            class="text-caption pl-2 pr-2"
          >
            <v-col
              cols="12"
              class="text-left pa-0"
            >
              Data: {{ item.date | formatDate('DD/MM/YYYY') }}
            </v-col>
          </v-row>

          <v-row class="text-caption pl-2 pr-2">
            <v-col
              cols="12"
              class="text-left pa-0"
            >
              Tipo: {{ item.types.join(', ') }}
            </v-col>
          </v-row>

          <v-row class="text-caption pl-2 pr-2">
            <v-col
              cols="6"
              class="text-left pa-0"
            >
              Hora Chegada: {{ item.startedAt }}
            </v-col>

            <v-col
              cols="6"
              class="text-right pa-0"
            >
              Hora Saída: {{ item.endedAt }}
            </v-col>
          </v-row>

          <v-row
            v-if="item.distance"
            class="text-caption pl-2 pr-2"
            :class="{'red--text': item.distance >= 100}"
          >
            <v-col
              cols="12"
              class="text-left pa-0"
            >
              Distância: {{ item.distance | formatDistance }}
            </v-col>
          </v-row>

          <v-row class="text-caption pl-2 pr-2">
            <v-col
              cols="12"
              class="text-left pa-0"
            >
              Observação: {{ item.obs }}
            </v-col>
          </v-row>

          <v-row class="text-caption pl-2 pr-2">
            <v-col
              cols="12"
              class="text-right pa-0"
            >
              <v-btn
                v-if="item.markerId"
                icon
                @click="$emit('viewOnMap', item.markerId)"
              >
                <v-icon>place</v-icon>
              </v-btn>
              
              <v-btn
                v-if="pictures.length"
                icon
                @click="openGallery(0)"
              >
                <v-icon>image</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-timeline-item>

    <LightBox
      ref="lightbox"
      :media="pictures"
      :show-light-box="false"
      show-caption
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import LightBox from "vue-image-lightbox";

export default {

  components: {
    LightBox,
  },

  filters: {

    formatDate(value, opts) {
      return moment(value).format(opts);
    },

    formatDistance(distance) {
      if (distance >= 1000) {
        return (distance / 1000).toFixed(2) + ' Km'
      }
      return distance.toFixed(2) + ' m'
    }
  },

  props: {
    item: {
      type: Object,
      default: () => ({
        id: '',
        type: 'VISIT',
        date: '',
        producer: {
          id: null,
          code: '',
          name: '',
        },
      }),
    },

    showDate: Boolean,
  },

  computed: {

    pictures() {
      return this.item.pictures.filter(picture => picture.src);
    },

  },

  methods: {

    /**
     * Abre o lightbox com a imagem selecionada sendo exibida
     */
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },

  },

}
</script>
