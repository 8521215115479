<template>
  <div>
    <v-row>
      <v-col
        md="12"
        class="text-left"
      >
        <h3>Prenhez</h3>
      </v-col>
      <v-col
        md="3"
      >
        <masked-input
          v-model="localData.percentual_prenhez"
          label="Taxa de prenhez"
          placeholder="0"
          dense
          :mask="IntNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        md="3"
      >
        <masked-input
          v-model="localData.taxa_concepcao"
          label="Taxa de concepção"
          placeholder="0"
          dense
          :mask="IntNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        md="3"
      >
        <masked-input
          v-model="localData.taxa_aborto"
          label="Taxa de aborto"
          placeholder="0"
          dense
          :mask="IntNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        md="3"
      >
        <masked-input
          v-model="localData.percentual_rentencao"
          label="Taxa de retenção de placenta"
          placeholder="0"
          dense
          :mask="IntNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        md="3"
      >
        <masked-input
          v-model="localData.mortalidade"
          label="Mortalidade"
          placeholder="0"
          dense
          :mask="IntNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        md="3"
      >
        <masked-input
          v-model="localData.percentual_femeas"
          label="Percentual de fêmeas"
          placeholder="0"
          dense
          :mask="IntNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        md="3"
      >
        <masked-input
          v-model="localData.del_minimo"
          label="DEL Minimo"
          placeholder="0"
          dense
          :mask="IntNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        md="3"
      >
        <masked-input
          v-model="localData.del_maximo"
          label="DEL Máximo"
          placeholder="0"
          dense
          :mask="IntNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-select
          v-model="localData.como_adquire_semem"
          :items="['PELO PREÇO','INDICAÇÃO DE AMIGO','INDICAÇÃO DO TÉCNICO DO LATICÍNIO','FAZ ACASALAMENTO COM EMPRESA ESPECIALIZADA']"
          label="Como adquire o sêmem?"
          hide-details
          dense
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-text-field
          v-model="localData.fornecedor_semem"
          label="Fornecedor de sêmem"
          dense
        />
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col
        md="12"
      >
        <v-row>
          <v-col
            md="12"
          >
            <h3>Cobertura</h3>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col
            md="2"
            offset-md="2"
          >
            <v-checkbox
              v-model="localData.monta_natural"
              label="Monta natural"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col
            md="2"
          >
            <v-checkbox
              v-model="localData.ia"
              label="IA"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col
            md="2"
          >
            <v-checkbox
              v-model="localData.iatf"
              label="IATF"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col
            md="2"
          >
            <v-checkbox
              v-model="localData.te"
              label="TE"
              color="primary"
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col
        md="12"
      >
        <v-row>
          <v-col
            md="12"
          >
            <h3>Confirmação</h3>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col
            md="2"
            offset-md="2"
          >
            <v-checkbox
              v-model="localData.ultrasom"
              label="Ultrasom"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col
            md="2"
          >
            <v-checkbox
              v-model="localData.toque"
              label="Toque"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col
            md="2"
          >
            <v-checkbox
              v-model="localData.teste_leite"
              label="Teste do Leite"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col
            md="2"
          >
            <v-checkbox
              v-model="localData.obs_cio"
              label="Observação do CIO"
              color="primary"
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col
        md="12"
      >
        <v-row>
          <v-col
            md="12"
          >
            <h3>Registros</h3>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col
            md="1"
            offset-md="2"
            class="pb-2 font-weight-medium"
          >
            Cobertura
          </v-col>
          <v-col
            md="1"
            class="pb-2 font-weight-medium"
          >
            Prenhez
          </v-col>
          <v-col
            md="1"
            class="pb-2 font-weight-medium"
          >
            Aborto
          </v-col>
          <v-col
            md="1"
            class="pb-2 font-weight-medium"
          >
            Partos
          </v-col>
          <v-col
            md="1"
            class="pb-2 font-weight-medium"
          >
            Secagem
          </v-col>
          <v-col
            md="1"
            class="pb-2 font-weight-medium"
          >
            Baixas
          </v-col>
          <v-col
            md="1"
            class="pb-2 font-weight-medium"
          >
            Peso
          </v-col>
          <v-col
            md="2"
            class="pb-2 font-weight-medium"
          >
            Controle Leiteiro
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="1"
            offset-md="2"
            class="pt-0 pb-0"
          >
            Data
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            Data
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            Data
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            Data
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            Data
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            Data
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            Data
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="1"
            offset-md="2"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_cobertura_obj.data"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_prenhez_obj.data"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_aborto_obj.data"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_partos_obj.data"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_secagem_obj.data"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_baixas_obj.data"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_peso_obj.data"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="2"
            class="pt-0 pb-0"
          >
            <v-select
              v-model="localData.rg_controle_leiteiro_obj.periodo"
              :items="['As vezes','Semanal','Quinzenal','Mensal']"
              hide-details
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="1"
            offset-md="2"
            class="pt-4 pb-0"
          >
            Metodo
          </v-col>
          <v-col
            md="1"
            class="pt-4 pb-0"
          >
            Metodo
          </v-col>
          <v-col
            md="1"
            class="pt-4 pb-0"
          >
            Motivo
          </v-col>
          <v-col
            md="1"
            class="pt-4 pb-0"
          >
            Metodo
          </v-col>
          <v-col
            md="1"
            class="pt-4 pb-0"
          >
            Produto
          </v-col>
          <v-col
            md="1"
            class="pt-4 pb-0"
          >
            Causa
          </v-col>
          <v-col
            md="2"
            offset-md="2"
          />
        </v-row>
        <v-row>
          <v-col
            md="1"
            offset-md="2"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_cobertura_obj.metodo"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_prenhez_obj.metodo"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_aborto_obj.motivo"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_partos_obj.metodo"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_secagem_obj.produto"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_baixas_obj.causa"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="1"
            offset-md="2"
            class="pt-4 pb-0"
          >
            Pai
          </v-col>
          <v-col
            md="2"
            class="pt-4 pb-0"
          >
            Nº de Prenhez +
          </v-col>
          <v-col
            md="1"
            class="pt-4 pb-0"
          >
            Pai
          </v-col>
          <v-col
            md="2"
            offset-md="4"
          />
        </v-row>
        <v-row>
          <v-col
            md="1"
            offset-md="2"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_cobertura_obj.pai"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          <v-col
            md="1"
            class="pt-0 pb-0"
          >
            <v-checkbox
              v-model="localData.rg_prenhez_obj.positiva"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
          
          <v-col
            md="1"
            class="pt-0 pb-0"
            offset-md="1"
          >
            <v-checkbox
              v-model="localData.rg_partos_obj.pai"
              color="primary"
              class="mt-0 mb-0"
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
  h3{
    color: rgba(0, 0, 0, 0.87);
  }
</style>

<script>
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  name: "BreedingStep",
  components: {
    MaskedInput,
  },

  filters: {
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
  props: {
    diagnostic: {
      require: true,
    },
  },
    
  data() {
    return {
      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: ".",
            min: -999999,
            max: 999999,
          },
        },
      },
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },
      
    };
  },

  computed: {

    localData: {
      get() {
        return this.diagnostic;
      },
      set(newValue) {
        return this.$emit("update:dataDiagnostic", {
          ...newValue
        });
      },
      
    },
      
  },
  mounted() {
         
  },
  methods: {
       
  },
}
</script>