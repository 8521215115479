<template>
  <div>
    <v-dialog
      v-model="visible"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-if="analysis"
              ref="form"
              lazy-validation
              @submit.prevent="save()"
            >
              <v-row>
                <v-col
                  v-if="analysis.type === 'PRODUCER'"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="analysis.producer"
                    label="Produtor *"
                    prepend-inner-icon="person_outline"
                    placeholder=" "
                    :items="producers"
                    :rules="[(v) => !!v.id || 'Campo obrigatório!']"
                    item-value="id"
                    item-text="name"
                    :search-input.sync="search"
                    return-object
                    required
                  />
                </v-col>
                <v-col
                  v-else
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="analysis.producer.name"
                    label="Rota/Tanque/Resfriador *"
                    placeholder=" "
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    v-model="collectedAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="collectedAt"
                        label="Data da coleta*"
                        placeholder=" "
                        prepend-inner-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="analysis.collectedAt"
                      :min="dateMin"
                      :max="nowDate"
                      @input="analyzedAtMenu = false"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    v-model="analyzedAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="analyzedAt"
                        label="Data da análise"
                        placeholder=" "
                        prepend-inner-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="analysis.analyzedAt"
                      :min="dateMin"
                      :max="nowDate"
                      @input="analyzedAtMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model.number="analysis.ccs"
                    label="CCS"
                    type="number"
                    :mask="IntNumberMask"
                    currency
                    return-unmasked
                    @keypress="disableDotAndComma"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model.number="analysis.cbt"
                    label="CPP"
                    type="number"
                    :mask="IntNumberMask"
                    currency
                    return-unmasked
                    @keypress="disableDotAndComma"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <masked-input
                    v-model.number="analysis.caseina"
                    label="Caseína"
                    :mask="FloatNumberMask"
                    currency
                    return-unmasked
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <masked-input
                    v-model.number="analysis.gordura"
                    label="Gordura"
                    :mask="FloatNumberMask"
                    currency
                    return-unmasked
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <masked-input
                    v-model.number="analysis.ureia"
                    label="Uréia"
                    :mask="FloatNumberMask"
                    currency
                    return-unmasked
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <masked-input
                    v-model.number="analysis.lactose"
                    label="Lactose"
                    :mask="FloatNumberMask"
                    currency
                    return-unmasked
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <masked-input
                    v-model.number="analysis.proteina"
                    label="Proteína"
                    :mask="FloatNumberMask"
                    currency
                    return-unmasked
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <masked-input
                    v-model.number="analysis.esd"
                    label="ESD"
                    :mask="FloatNumberMask"
                    currency
                    return-unmasked
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <masked-input
                    v-model.number="analysis.solidos_totais"
                    label="Sólidos Totais"
                    :mask="FloatNumberMask"
                    currency
                    return-unmasked
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <masked-input
                    v-model.number="analysis.crioscopia"
                    label="Crioscopia"
                    :mask="FloatNumberMask"
                    currency
                    return-unmasked
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="analysis.numero_amostra"
                    label="Número amostra *"
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-checkbox
                    v-model="analysis.compoe_media"
                    label="Compõe Média"
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    MaskedInput,
  },

  data() {
    return {
      loading: false,
      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: ".",
            min: -999999,
            max: 999999,
          },
        },
      },
      // Mascara usada nos campos numéricos float
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      // Date Picker
      analyzedAtMenu: false,
      collectedAtMenu: false,

      visible: false,
      title: "",
      analysis: null,
      search: null,

      producers: [],

      dateMin: null,
      nowDate: moment().format("YYYY-MM-DD"),
    };
  },

  computed: {
    analyzedAt() {
      if (_.isEmpty(this.analysis)) {
        return moment().format("DD/MM/YYYY");
      }

      if (_.isEmpty(this.analysis.analyzedAt))  {
        return null;
      }

      return moment(this.analysis.analyzedAt).format("DD/MM/YYYY");
    },

    collectedAt() {
      if (_.isEmpty(this.analysis)) {
        return moment().format("DD/MM/YYYY");
      }

      if (_.isEmpty(this.analysis.collectedAt))  {
        return null;
      }


      return moment(this.analysis.collectedAt).format("DD/MM/YYYY");
    },
  },

  watch: {
    search(val) {
      val && val !== this.select && this.updateDate();
    },
  },

  async mounted() {
    await this.loadProducers();
  },

  methods: {
    disableDotAndComma(e) {
      if (e.charCode === 46 || e.charCode === 44) {
        e.preventDefault()
      }
    },

    updateDate() {
      this.dateMin = this.analysis.producer.mes_ultima_media ? moment(this.analysis.producer.mes_ultima_media).format("YYYY-MM-DD") : null ;
    },

    async loadProducers() {
      try {
        const { data } = await this.$axios.post(`/produtores/listaProdutoresUltimaAnalise`);

        if (_.isString(data)) {
          throw data;
        }

        this.producers = data.map((producer) => {
          return {
            id: producer.id_pessoa,
            mes_ultima_media: producer.mes_ultima_media,
            name: producer.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      }
    },

    show(analysis) {
      // Verifica se já existe um formulário carregado em memória e o reseta
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      if (!analysis.id) {
        this.title = `Nova Analise`;
      } else {
        this.title = `Editando Analise`;
      }

      this.analysis = analysis;
      this.visible = true;
    },

    close() {
      this.loading = false;
      this.title = ``;
      this.visible = false;
      this.analysis = null;
    },

    save() {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return;
      }

      this.loading = true;

      return this.$emit("onSave", this.analysis);
    },
  },
};
</script>
