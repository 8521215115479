<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Programação Leite</span>
      <v-spacer />
      <span class="caption font-weight-light">
        {{ dateFormat(averagePeriod[0]) }} - {{ dateFormat(averagePeriod[1]) }}
      </span>
      <v-menu
        v-model="showDatePicker"
        :close-on-content-click="false"
        offset-y
        bottom
        left
        content-class="menu-select"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>expand_more</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="averagePeriod"
          no-title
          range
          :max="maxPeriod"
          @change="onChangeDateRange"
        />
      </v-menu>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            small
            color="rgba(255, 255, 255, 0.7)"
            v-on="on"
          >
            info
          </v-icon>
        </template>
        Período usado para estimar a média de entrada de leite para os próximos dias
      </v-tooltip>
    </v-card-title>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '250px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      tab: null,
      category: [],
      ownMilk: [],
      spots: [],
      spotsOutgoing: [],
      totalDay: [],
      averagePeriod: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
      showDatePicker: false,
      maxPeriod: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    }
  },

  computed: {
    options() {
      const label = show => ({
        normal: {
          show,
          lineHeight: 20,
          height: 20,
          backgroundColor: '#6a7985',
          color: '#fff',
          borderRadius: 5,
          position: 'top',
          distance: 1,
          formatter: '  {c}  ',
        }
      });
      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(171, 71, 188, 0.7)', 'rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.category,
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Leite Próprio',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: this.ownMilk.map((value) => ({
              value,
              label: label(!!value)
            }))
          },
          {
            name: 'Leite Spot Entrada',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: this.spots.map((value) => ({
              value,
              label: label(!!value)
            })),
          },
          {
            name: 'Leite Spot Saída',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: this.spotsOutgoing.map((value) => ({
              value,
              label: label(!!value)
            })),
          },
          {
            name: 'Total',
            type: 'line',
            lineStyle: { width: 2 },
            showSymbol: false,
            smooth: this.smooth,
            emphasis: {
              focus: 'series'
            },
            data: this.totalDay
          }
        ]
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    onChangeDateRange() {
      this.showDatePicker = false
      this.loadReport()
    },
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;
        const [startDateAverage, endDateAverage] = this.averagePeriod;

        const { data: { leite_proprio, leite_spot, leite_spot_saida } } = await this.$axios.post(
          `/spot/programacaoLeite`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            data_inicio_media: startDateAverage,
            data_fim_media: endDateAverage
          }
        );

        if (!_.isArray(leite_proprio) || !_.isArray(leite_spot)) {
          throw "PHP Error";
        }

        const dates = leite_proprio.map(o => o.data);
        const ownMilk = leite_proprio.reduce((acc, cur) => ({ ...acc, [cur.data]: parseFloat(cur.volume) }), {});
        const spots = leite_spot.reduce((acc, cur) => ({ ...acc, [cur.data]: parseFloat(cur.volume) + (acc[cur.data] || 0) }), {});
        const spotsOutgoing = leite_spot_saida.reduce((acc, cur) => ({ ...acc, [cur.data]: parseFloat(cur.volume) + (acc[cur.data] || 0) }), {});

        this.category = leite_proprio.map(o => moment(o.data).format('DD/MM/YYYY'));
        this.ownMilk = leite_proprio.map(o => parseFloat(o.volume));
        this.spots = dates.map(date => spots[date] || 0);
        this.spotsOutgoing = dates.map(date => spotsOutgoing[date] || 0);
        this.totalDay = dates.map(date => (spots[date] || 0) - (spotsOutgoing[date] || 0) + (ownMilk[date] || 0));

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de programação de leite!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    dateFormat: date => moment(date).format('DD/MM/YYYY'),
  }
}
</script>
