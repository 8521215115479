<template>
  <div>
    <v-select
      v-model="selected"
      :items="selectItems"
      :prepend-inner-icon="prependInnerIcon"
      :label="label"
      :hide-details="hideDetails"
      :filled="filled"
      v-bind="$attrs"
      @change="onDateFilter"
    >
      <template #item="{ item }">
        <template v-if="selected === 'customRange' && item.value === 'customRange'">
          <span class="grow" v-if="!isDateTime"> {{ startDateText }} — {{ endDateText }} </span>
          <span v-else class="grow"> {{ startDateText }} {{ timeRange.time['start'] || '00:00' }} — {{ endDateText }} {{ timeRange.time['end'] || '23:59' }} </span>
          <v-btn
            icon
            light
            @click="refresh"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>

        <template v-else>
          {{ item.text }}
        </template>
      </template>

      <template #selection="{ item }">
        <span
          v-if="selected === 'customRange'"
          class="v-select__selection v-select__selection--comma"
        >
          <span v-if="!isDateTime"> {{ startDateText }} — {{ endDateText }} </span>
          <span v-else> {{ startDateText }} {{ timeRange.time['start'] || '00:00' }} — {{ endDateText }} {{ timeRange.time['end'] || '23:59' }} </span>
        </span>
        <span
          v-else
          class="v-select__selection v-select__selection--comma"
        >
          {{ item.text }}
        </span>
      </template>
    </v-select>

    <v-dialog
      :value="showDatePicker"
      persistent
      content-class="dialog-picker"
    >
      <v-date-picker
        v-model="dateRange"
        :class="{'custom-date-picker': isDateTime}"
        range
        reactive
        @change="onCustomDateRange($event, true)"
        @input="changeRangeDate"
      >
        <template
          v-if="isDateTime"
          #default
        >
          <v-col class="pa-0">
            <v-form
              ref="form"
            >
              <v-row
                v-for="(item, index) in ['start', 'end']"
                :key="index"
              >
                <v-col
                  v-if="dateRange[index]"
                  cols="6"
                >
                  <v-text-field
                    :value="timeRange.date[item]"
                    label="Data"
                    prepend-inner-icon="today"
                    disabled
                    dense
                  />
                </v-col>
                <v-col
                  v-if="dateRange[index]"
                  cols="6"
                >
                  <masked-input
                    v-model="timeRange.time[item]"
                    label="Horário"
                    prepend-inner-icon="access_time"
                    clearable
                    dense
                    :rules="[v => isValidTime(v) || 'Horário inválido.']"
                    placeholder="00:00"
                    :mask="{ mask: '00:00' }"
                    :max-length="5"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-container class="d-flex justify-center align-center">
              <v-btn
                class="blue--text"
                outlined
                @click="onFilter"
              >
                Filtrar
              </v-btn>
            </v-container>
          </v-col>
       </template>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<style lang="scss">
  .custom-date-picker .v-date-picker-table {
    height: auto !important;
  }
</style>

<script>
import moment from "moment";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import _ from "lodash";

export default {
  components: {
    MaskedInput,
  },

  props: {
    value: {
      type: Array,
    },

    label: {
      type: String,
      default: 'Data',
    },

    prependInnerIcon: {
      type: String,
      default: 'today',
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    filled: {
      type: Boolean,
      default: true,
    },

    disableCustomRange: Boolean,
    maxDays: Number,

    ranges: {
      type: Object,
      default() {
        return {
          'Hoje': [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          'Ontem': [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
          'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
          'Mês anterior': [moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")],
          'Este ano': [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
        }
      }
    },

    isDateTime: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDatePicker: false,
      selected: null,
      dateRange: [],
      timeRange: {
        date: {
          'start': '',
          'end': ''
        },
        time: {
          'start': '',
          'end': ''
        }
      },
    };
  },

  computed: {
    selectItems() {
      let items = [];

      Object.keys(this.ranges).forEach(key => {
        items.push({ text: key, value: key })
      })

      if (!this.disableCustomRange) {
        items.push({ text: 'Definir data', value: 'customRange' });
      }

      return items;
    },

    startDateText() {
      const startDate = _.first(this.dateRange);
      if (!startDate) {
        return;
      }

      return moment(startDate).format('DD/MM/YYYY');
    },

    endDateText() {
      const endDate = _.last(this.dateRange)
      if (!endDate) {
        return;
      }

      return moment(endDate).format('DD/MM/YYYY');
    },
  },

  watch: {
    value() {
      if(!this.isDateTime) {
        this.dateRange = this.value;
        if (this.value && this.value.length == 2) {
          this.checkSelected();
        }
        else {
          this.selected = null;
        }
      }
    },
  },

  mounted() {
    if (this.value && this.value.length == 2) {
      this.dateRange = this.value;

      if(this.isDateTime) {
        this.dateRange[0] =  moment(this.dateRange[0]).format('YYYY-MM-DD');
        this.dateRange[1] =  moment(this.dateRange[1]).format('YYYY-MM-DD');

        this.changeRangeDate();
      }
    }
    else {
      this.dateRange = Object.values(this.ranges)[0];
    }
    this.onCustomDateRange();
  },

  methods: {
    onDateFilter(selected) {
      if (selected === 'customRange') {
        this.showDatePicker = true;
        return;
      }

      this.dateRange = this.ranges[selected];

      if(this.isDateTime) {
        var dateRangeWithTime = _.cloneDeep(this.dateRange);
        dateRangeWithTime[0] = `${dateRangeWithTime[0]} 00:00`;
        dateRangeWithTime[1] = `${dateRangeWithTime[1]} 23:59`;
        this.$emit('input', dateRangeWithTime);

      } else {
        this.$emit('input', this.dateRange);
      }

      this.$emit('change');
    },

    onCustomDateRange(event, change = false) {

      if(change && this.isDateTime) {
        return;
      }

      var checkRange = false;
      if (this.maxDays) {
        const [startDate, endDate] = this.dateRange;
        let start = moment(new Date(startDate));
        let end = moment(new Date(endDate));
        let duration = moment.duration(end.diff(start));

        if((duration.asDays() - 1) <= this.maxDays) {
          checkRange = true;
        } else {
          this.$snotify.info("Intervalo máximo permitido " + this.maxDays + " dias!", "Aviso");
          this.dateRange = [];
        }
      } else {
         checkRange = true;
      }

      if(checkRange) {
        this.showDatePicker = false;

        if(this.dateRange) {
          this.checkSelected();
        }

        if(this.isDateTime) {
          var dateRangeWithTime = _.cloneDeep(this.dateRange);
          dateRangeWithTime[0] = this.timeRange.time['start'] ? `${dateRangeWithTime[0]} ${this.timeRange.time['start']}` : `${dateRangeWithTime[0]} 00:00`;
          dateRangeWithTime[1] = this.timeRange.time['end'] ? `${dateRangeWithTime[1]} ${this.timeRange.time['end']}` : `${dateRangeWithTime[1]} 23:59`;

          this.$emit('input', dateRangeWithTime);
        } else {
          this.$emit('input', this.dateRange);
        }
        this.$emit('change');
      }
    },

    checkSelected() {
      const [startDate, endDate] = this.dateRange;

      this.selected = 'customRange';

      Object.keys(this.ranges).forEach(key => {
        const [start, end] = this.ranges[key];
        if (startDate == start && endDate == end) {
          this.selected = key;
        }
      })
    },

    refresh() {
      this.showDatePicker = true;
    },

    changeRangeDate() {
      if(!this.isDateTime) {
        return;
      }

      var [startDate, endDate] = this.dateRange;
      var reverse = false;

      if(startDate && endDate && (endDate < startDate)) {
        let auxTime = this.timeRange.time['start'];

        this.dateRange = this.dateRange.reverse();
        [startDate, endDate] = this.dateRange;
        this.timeRange.time['start'] = this.timeRange.time['end'];
        this.timeRange.time['end'] = auxTime;
        reverse = true;
      }

      const formatedStartDate = moment(startDate).format('DD/MM/YYYY');
      const formatedEndDate = moment(endDate).format('DD/MM/YYYY');

      if(!endDate && (formatedStartDate != this.timeRange.date['start'])) {
        this.timeRange.time['start'] = '';
        this.timeRange.time['end'] = '';
      } else if(!reverse) {
        this.timeRange.time['end'] = '';
      }

      this.timeRange.date['start'] = startDate ? formatedStartDate : '';
      this.timeRange.date['end'] = endDate ? formatedEndDate : '';
    },

    isValidTime(value) {
      if(!value) {
        return true;

      } else if(value.length < 5) {
        return false;
      }

      var hours = value.split(':')[0];
      var minutes = value.split(':')[1];

      return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
    },

    async onFilter() {
      if(!await this.$refs.form.validate()) {
          return;
      }
      this.onCustomDateRange();
    }
  },
};
</script>
