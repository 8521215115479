<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Grupos a Serem Comparados com o Inventário
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-card
        dark
        color="transparent"
      >
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          :items-per-page="items.length"
          hide-default-footer
        >

          <template #[`item.fields`]="{ value }">
            <v-row
              v-for="field of value"
              :key="field.id"
            >
              <v-col
                cols="4"
              >
                Campo:
                <v-chip
                  small
                  color="green darken-1"
                >
                  {{ field.description }}
                </v-chip>
              </v-col>
              <v-col
                cols="3"
              >
                Tipo:
                <v-chip
                  small
                  color="gray darken-1"
                >
                  {{ getTypeById(field.type) }}
                </v-chip>
                <v-chip
                  v-if="field.type === 'select'"
                  small
                  color="gray darken-2"
                >
                  {{ formatValues(field.values) }}
                </v-chip>
              </v-col>
              <v-col
                cols="4"
              >
                Regras:
                <v-chip
                  v-for="rule of field.rules"
                  :key="rule.id"
                  small
                  color="red darken-1"
                >
                  {{ rule.rule }}
                </v-chip>
              </v-col>
            </v-row>
          </template>

          <template #[`item.settings.atestado_conformidade`]="{ item }">
            <v-switch
              v-model="item.settings.atestado_conformidade"
              label=""
              dense
              hide-details
              class="mt-0"
              color="green accent-3"
              @change="save(item)"
            />
          </template>

          <template #[`item.settings.brucelose`]="{ item }">
            <v-switch
              v-model="item.settings.brucelose"
              label=""
              dense
              hide-details
              class="mt-0"
              color="green accent-3"
              @change="save(item)"
            />
          </template>
          <template #[`item.settings.tuberculose`]="{ item }">
            <v-switch
              v-model="item.settings.tuberculose"
              label=""
              dense
              hide-details
              class="mt-0"
              color="green accent-3"
              @change="save(item)"
            />
          </template>
          <template #[`item.settings.vacinas`]="{ item }">
            <v-switch
              v-model="item.settings.vacinas"
              label=""
              dense
              hide-details
              class="mt-0"
              color="green accent-3"
              @change="save(item)"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-container>

  </div>
</template>

<script>
export default {

  components: {
  },

  data() {
    return {
      items: [],
      settings: [],

      filters: {
        search: "",
      },

      edit: false,
      editId: null,
    };
  },

  computed: {
    headers() {
      return [
        { text: 'Tipo de Animal', value: 'description', align: 'start'},
        { text: 'Atestado de Conformidade', value: 'settings.atestado_conformidade', align: 'start'},
        { text: 'Exames Brucelose', value: 'settings.brucelose', align: 'start'},
        { text: 'Exames Tuberculose', value: 'settings.tuberculose', align: 'start'},
        { text: 'Vacinas', value: 'settings.vacinas', align: 'start'},
      ];
    },

    //Pega os dados de permissão do usúario.
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
  },

  async mounted() {
    await this.loadSettings();
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('/projects/animals-type', { params: {
            deleted: true,
          } });

        this.items = data.map(item => {

          const configItem = this.settings.find(config => config.id_tipo_animal === item.id_tipo_animal);

          const idSettings = configItem ? configItem.id_configuracao_comparativo_lancamento : '';

          const settings = configItem ? JSON.parse(configItem.configuracao) : {
            atestado_conformidade: false,
            brucelose: false,
            tuberculose: false,
            vacinas: false
          };

          return {
            id_configuracao_comparativo_lancamento: idSettings,
            id: item.id_tipo_animal,
            description: item.descricao,
            settings: settings,
          };
        }, this);
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar tipos de animal!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadSettings() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('/projects/comparison-settings', { params: {
            deleted: true,
          } });

        this.settings = data;

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar tipos de animal!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save(item) {
      try {

        this.$root.$progressBar.saving();

        this.editId = item.id_configuracao_comparativo_lancamento;

        const payload = {
          id_tipo_animal: item.id,
          configuracao: JSON.stringify(item.settings),
        }

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        await this.loadSettings();
        await this.loadItems();

        this.$snotify.success("Registro salvo com sucesso!", "Sucesso");

      } catch (error) {
        this.$snotify.error("Erro ao salvar registro", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/comparison-settings`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/comparison-settings/${this.editId}`, payload);
      return data;
    },
  },
}
</script>
