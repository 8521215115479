<template>
  <div>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="pt-0"
          >
            <v-select
              v-model="filters.referenceDate"
              :items="['DESCARGA', 'COLETA']"
              label="Data de referência"
              prepend-inner-icon="local_shipping"
              dark
              filled
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              prepend-inner-icon="today"
              label="Data"
              filled
              hide-details
              dark
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="pt-0"
          >
            <routes-autocomplete-filter
              v-model="filters.routes"
              label="Rotas"
              dark
              multiple
              :return-object="false"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              label="Buscar"
              filled
              single-line
              hide-details
              dark
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        dark
        background-color="transparent"
        :items="items"
        single-expand
        show-expand
        item-key="id_agrupamento"
        :headers="headers"
        :search="filters.search"
      >
        <template #[`expanded-item`]="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col class="pa-3">
                <v-data-table
                  dark
                  :items="item.produtores"
                  hide-default-footer
                  disable-pagination
                  :headers="producersHeaders"
                >
                  <template #[`item.data_hora`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </td>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-4"
        @click="exportProducerExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download {{ getFormat() }} (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>
          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import RoutesAutocompleteFilter from '@/Support/Components/Filters/RoutesAutocompleteFilter.vue';
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import ReportMixin from '@/Support/Mixins/ReportMixin.js';

import moment from 'moment';
import debounce from 'lodash/debounce';

export default {
  components: {
    RoutesAutocompleteFilter,
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  props: {
    type: {
      type: String,
      default: 'CONDOMINIUM',
      validator: (value) => ['CONDOMINIUM', 'COMMUNITY-TANK', 'FAMILY-GROUP'].includes(value)
    },
    format: {
      type: String,
      default: 'VOLUME',
      validator: (value) => ['VOLUME', 'COLLECT'].includes(value)
    }
  },

  data() {
    return {
      filters: {
        referenceDate: 'DESCARGA',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        routes: [],
        search: null,
      },

      items: [],
    };
  },

  computed: {
    headers() {
      return  [
        { text: this.getTitle(), value: 'nome_agrupamento' },
        { text: 'Volume (L)', value: 'volume', width: 150, align: 'center' },
      ];
    },
    producersHeaders() {
      const headers = [
        { text: 'Código', value: 'codigo_laticinio', width: 100 },
        { text: 'Produtor', value: 'nome_produtor' },
      ];

      if (this.format === 'COLLECT') {
        headers.push({ text: 'Data', value: 'data_hora' });
      }

      headers.push({ text: 'Volume (L)', value: 'volume', width: 150, align: 'center' });

      return headers;
    },
  },

  methods: {

    onFilter: debounce(function () {
      this.loadGrouping();
    }, 1000),

    async loadGrouping() {
      try {
        this.$root.$progressBar.loading();

        const types = {
          'CONDOMINIUM': 'CONDOMINIO',
          'COMMUNITY-TANK': 'TANQUE',
          'FAMILY-GROUP': 'FAMILIAR',
        };

        const formats = {
          'VOLUME': 'VOLUME',
          'COLLECT': 'COLETA',
        };

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/relatorios/volume-agrupamento`, { params: {
          tipo: types[this.type],
          formato: formats[this.format],
          data_referencia: this.filters.referenceDate,
          rotas: this.filters.routes,
          data_inicio: startDate,
          data_fim: endDate,
        } });

        this.items = data;
      } catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getTitle() {
      const types = {
        'CONDOMINIUM': 'Condomínio',
        'COMMUNITY-TANK': 'Tanque Comunitário',
        'FAMILY-GROUP': 'Grupo Familiar',
      };

      return types[this.type];
    },

    getFormat() {
      const formats = {
        'VOLUME': 'Volume',
        'COLLECT': 'Coletas',
      };

      return formats[this.format];
    },

    getReportTitle(isProducer = false) {
      let title = `${this.getFormat()} por ${this.getTitle()}`;

      if (isProducer) {
        title += ` - Produtor`;
      }

      const [startDate, endDate] = this.filters.dateRange;

      return `${title} - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      const filename = this.getReportTitle();

      this.$refs.report.exportExcel(null, filename);
    },

    exportProducerExcel() {
      if (this.items.length === 0) {
        return;
      }

      const report = this.items.flatMap(item => item
        .produtores.map(producer => {
          const res = {
            'Código': producer.codigo_laticinio,
            'Produtor': producer.nome_produtor,
          }

          if (producer.data_hora) {
            res['Data'] = this.formatDate(producer.data_hora, 'DD/MM/YYYY HH:mm');
          }

          res['Volume (L)'] = producer.volume;

          return res;
        }));

      const title = this.getReportTitle(true);

      this.exportToFile({ report, title });
    },

    async print() {
      const title = this.getReportTitle();

      if (this.items.length === 0) {
        return;
      }

      let body = [];

      const producersHeaders = this.producersHeaders
        .map(header => ({
          key: header.value,
          label: header.text,
          class: header.align ? `text-${header.align}` : 'text-left'
        }));

      for (const item of this.items) {

        const totals = {
          'Total (L)': item.volume
        };

        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            tag: 'datatable',
            title: item.nome_agrupamento,
            headers: producersHeaders,
            items: item.produtores.map(producer => {
              if (producer.data_hora) {
                producer.data_hora = this.formatDate(producer.data_hora, 'DD/MM/YYYY HH:mm');
              }
              return producer;
            }),
          },
          {
            tag: 'div',
            children: [{
              tag: 'div',
              class: 'six columns',
              contents: '&nbsp;'
            },
            {
              tag: 'div',
              class: 'six columns',
              children: [{
                tag: 'datatable',
                headers: Object.keys(totals)
                  .map(header => ({
                    key: header,
                    label: header,
                    class: 'text-left'
                  })),
                items: [totals],
              }]
            }]
          },
          ]
        });
      }

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.$refs.report.printPdf({ pages: [{ title, header, body }] });
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
