<template>
  <div class="mx-6 px-16">
    <v-row
      xs12
      justify="center"
    >
      <v-col
        cols="12"
      >
        <h2 class="menu-header white--text">
          Coleta de Amostra
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#tab-samples">
        Normativas
      </v-tab>
      <v-tab href="#tab-reports">
        Relatório
      </v-tab>

      <v-tab
        href="#tab-reports"
        :disabled="!hasSamplesBillAccess"
        @click.stop="openSettings"
      >
        <v-icon>settings</v-icon>
      </v-tab>

      <v-tab-item value="tab-samples">
        <normative-tab v-if="tab === 'tab-samples'" />
      </v-tab-item>
      <v-tab-item value="tab-reports">
        <reports-tab v-if="tab === 'tab-reports'" />
      </v-tab-item>
    </v-tabs>

    <normative-price-settings v-model="showConfig" />
  </div>
</template>

<script>
import NormativeTab from "@/Domains/Collects/CollectionNormative/Views/NormativeTab.vue";
import ReportsTab from "@/Domains/Collects/CollectionNormative/Views/ReportsTab.vue";
import NormativePriceSettings from '@/Domains/Collects/CollectionNormative/Components/NormativePriceSettings.vue';

export default {
  components: {
    NormativeTab,
    ReportsTab,
    NormativePriceSettings
  },

  data() {
    return {
      tab: null,
      showConfig: false
    };
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasSamplesBillAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'samples-bill-access' && o.tipo === 'COMPONENTE');
    },
  },

  methods: {
    openSettings() {
      this.showConfig = true;
    },
  },
}
</script>
