<template>
  <div>
    <v-row class="mx-2">
      <template v-if="name">
        <v-col
          cols="4"
          sm="3"
          lg="2"
          class="d-flex flex-column justify-end"
        >
          <v-tooltip
            v-if="isLocked"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                icon
                color="white"
                x-large
                v-on="on"
                @click="toggleLock"
              >
                <v-icon>
                  settings_suggest
                </v-icon>
              </v-btn>
            </template>
            Personalizar {{ name }}
          </v-tooltip>
          <v-btn
            v-else
            color="white"
            block
            outlined
            x-large
            @click="toggleLock"
          >
            <v-icon>lock</v-icon>
            Bloquear e Salvar
          </v-btn>
        </v-col>
        <v-col
          cols="8"
          sm="9"
          md="5"
          lg="4"
        >
          <v-select
            v-if="!isLocked"
            v-model="selectedWidget"
            :items="availableWidgets"
            label="Widget"
            dark
            filled
            hide-details
            item-text="description"
            return-object
            @change="addNewWidget"
          >
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.description" />
                <v-list-item-subtitle
                  v-if="item.dateAdded && item.dateAdded > limitAddedDate"
                  class="mx-1 red--text"
                >
                  Novo
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
      </template>
      <v-spacer />
      <v-col
        cols="12"
        md="4"
      >
        <slot name="filter" />
      </v-col>
    </v-row>

    <div :class="{ 'grid-editing': !isLocked }">
      <div
        ref="gridStack"
        class="grid-stack"
      >
        <slot v-bind="{ widgets, isLocked, remove }" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.grid-editing .grid-stack {
  background: rgb(0 0 0 / 30%);
  border: 2px dashed rgb(255 255 255 / 50%);
  border-radius: 5px;

  .grid-stack-item-content {
    text-align: center;
    border: 1px dashed rgb(255 255 255 / 15%);
    border-radius: 2px;

    .close-btn {
      position: absolute;
      z-index: 1;
      right: 0;
    }
  }

  .grid-stack-item-removing {
    opacity: 0.5;
  }

  .grid-stack-item > .ui-resizable-se {
    filter: brightness(0) invert(1);
  }
}

.grid-stack > .grid-stack-item.grid-stack-sub-grid > .grid-stack-item-content {
  background: rgba(0,0,0,0.1);
  inset: 0 2px;
}
</style>

<script>

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.min.css';

export default {

  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    name: String,
  },

  data() {
    return {
      isLocked: false,
      grid: undefined,
      hasChanges: false,

      widgets: [],

      selectedWidget: null,
    }
  },

  computed: {
    availableWidgets() {
      const addedWidgets = this.widgets.map(item => item.component);
      return this.items.filter(item => !addedWidgets.includes(item.component));
    },

    limitAddedDate() {
      return moment().add(-31, 'days').format('YYYY-MM-DD');
    },
  },

  created() {
    this.loadWidgets();
  },

  mounted() {
    this.initLayout();
  },

  methods: {
    initLayout() {
      const ref = this.$refs.gridStack;

      const opts = {
        alwaysShowResizeHandle: true,
        float: false,
        cellHeight: 85,
        minRow: 1,
        resizable: { autoHide: true, handles: 'se' },
      };

      this.grid = GridStack.init(opts, ref);

      this.grid.on('change', this.onChange.bind(this));

      this.hasChanges = false;

      this.toggleLock();
    },

    async toggleLock() {
      this.isLocked = !this.isLocked;

      if (this.isLocked) {
        this.grid.disable();
        this.save();
      } else {
        this.grid.enable();
      }
    },

    findNextEmptyArea({ w, h }) {
      let x = 0;
      let y = 0;
      while (!this.grid.isAreaEmpty(x, y, w, h)) {
        // if (x < 12) {
        //   x++;
        //   continue;
        // }
        // x = 0;
        y++;
        if (y > 500) {
          y = 0;
          break;
        }
      }
      return { x, y };
    },

    async addNewWidget(item) {
      this.hasChanges = true;

      const node = {
        ...item,
        ...this.findNextEmptyArea(item),
        noMove: false,
        noResize: false,
        id: item.component,
      };

      this.widgets.push(node);

      await this.$nextTick();

      this.grid.makeWidget(node.id);
      this.selectedWidget = null;
    },

    onChange(event, changeWidgets) {
      this.hasChanges = true;

      // update item position
      changeWidgets.forEach(item => {
        const widget = this.widgets.find(w => w.component == item.id);
        if (!widget) {
          console.error('Widget not found: ', { item });
          return;
        }
        widget.x = item.x;
        widget.y = item.y;
        widget.w = item.w;
        widget.h = item.h;
      });
    },

    remove(widget) {
      this.hasChanges = true;

      var index = this.widgets.findIndex(w => w.component == widget.component);
      this.widgets.splice(index, 1);
      const selector = `#${widget.component}`;
      this.grid.removeWidget(selector, false);
    },

    loadWidgets() {
      if (this.name && !isEmpty(this.$store.state.settings.reports[this.name])) {
        this.widgets = this.$store.state.settings.reports[this.name];
      } else {
        this.widgets = this.items
          .filter(w => w.default)
          .map(w => ({
            ...w,
            ...w.default
          }));
      }
    },

    async save() {
      if (!this.hasChanges) {
        return;
      }

      this.$root.$progressBar.saving();
      try {
        const settings = this.widgets.map(item => ({
          w: item.w,
          h: item.h,
          x: item.x,
          y: item.y,
          component: item.component,
        }))

        const { data } = await this.$axios.post(`/configuracao/salvaConfiguracaoRelatorio`, {
          relatorio: this.name,
          configuracao: settings,
        });

        if (!data.codigo) {
          throw data;
        }

        this.hasChanges = false;

        this.$snotify.success('Configurações salvas com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error('Erro ao salvar as configurações', 'Atenção');
        console.error(error);
      } finally {
        this.$root.$progressBar.hide();
        this.$store.dispatch('updateReportsSettings');
      }
    },

  }
};
</script>
