<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Silo
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#silos-tab">
          Silos
        </v-tab>

        <v-tab href="#history-tab">
          Movimentações
        </v-tab>

        <v-tab href="#production-history-tab">
          Histórico Produção
        </v-tab>

        <v-tab-item value="silos-tab">
          <silos-tab
            v-if="tab === 'silos-tab'"
            ref="silosTab"
          />
        </v-tab-item>

        <v-tab-item value="history-tab">
          <silo-history-tab
            v-if="tab === 'history-tab'"
          />
        </v-tab-item>

        <v-tab-item value="production-history-tab">
          <production-history-tab
            v-if="tab === 'production-history-tab'"
            ref="productionTab"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import SilosTab from "@/Domains/Platform/Silos/Views/SilosTab.vue";
import ProductionHistoryTab from "@/Domains/Platform/Silos/Views/ProductionHistoryTab.vue";
import SiloHistoryTab from "@/Domains/Platform/Silos/Views/SiloHistoryTab.vue";

export default {
  name: "ColetaCadastroSilo",

  components: {
    SiloHistoryTab,
    SilosTab,
    ProductionHistoryTab,
  },

  data() {
    return {
      tab: 'silos-tab',
    };
  },
};
</script>
