<template>
  <div class="mx-6 px-16 pt-0">
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
      >
        <h2 class="menu-header white--text">
          Volume de Coletas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#report-tab">
        Relatório
      </v-tab>
      <v-tab href="#volume-cycle-tab">
        Volume Geral Por Ciclo
      </v-tab>
      <v-tab href="#volume-month-tab">
        Volume Geral Por Mês
      </v-tab>
      <v-tab href="#volume-route-tab">
        Volume Geral Por Rota
      </v-tab>

      <v-tab-item value="report-tab">
        <collection-reports-tab />
      </v-tab-item>
      <v-tab-item value="volume-cycle-tab">
        <cycle-volume-tab />
      </v-tab-item>
      <v-tab-item value="volume-month-tab">
        <volume-month-tab />
      </v-tab-item>
      <v-tab-item value="volume-route-tab">
        <route-volume-tab />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import CollectionReportsTab from "@/Domains/Collects/Reports/CollectedVol/Views/CollectionReportsTab.vue";
import CycleVolumeTab from "@/Domains/Collects/Reports/CollectedVol/Views/CycleVolumeTab.vue";
import VolumeMonthTab from "@/Domains/Collects/Reports/CollectedVol/Views/VolumeMonthTab.vue";
import RouteVolumeTab from "@/Domains/Collects/Reports/CollectedVol/Views/RouteVolumeTab.vue";

export default {
  components: {
    CollectionReportsTab,
    CycleVolumeTab,
    VolumeMonthTab,
    RouteVolumeTab
  },
  
  data() {
    return {
      tab: null,
    };
  },
}
</script>