<template>
  <div>
    <v-select
      v-model="input"
      :items="groups"
      :label="label"
      prepend-inner-icon="group_work"
      :return-object="returnObject"
      v-bind="$attrs"
      @input="onItemSelected"
    >
      <template
        #append-item
      >
        <v-divider class="mt-2" />
        <v-row no-gutters>
          <v-col>
            <v-btn
              block
              outlined
              @click="addNew"
            >
              Cadastrar novo
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-select>

    <v-dialog
      v-model="showNewDialog"
      width="290"
    >
      <v-row
        no-gutters
        class="white"
      >
        <v-col>
          <v-text-field
            v-model="groupName"
            label="Grupo"
            append-outer-icon="send"
            @click:append-outer="saveNew"
          />
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Object],
    },
    returnObject: Boolean,
    label: {
      type: String,
      default: 'Grupo'
    }
  },

  data() {
    return {
      showNewDialog: false,
      input: null,
      groups: [],
      groupName: ''
    };
  },

  watch: {
    value(value) {
      this.input = value;
    },
  },

  created() {
    this.getGroups();
  },

  methods: {
    onItemSelected() {
      return this.$emit('input', this.input);
    },

    async getGroups() {
      const { data } = await this.$axios.get(`/grupo/listaJson`);

      this.groups = data.map(o => ({
        value: o.id_grupo,
        text: o.nome_grupo
      }));
    },

    addNew() {
      this.showNewDialog = true;
    },

    async saveNew() {
      const groupName = this.groupName.trim();
      if (groupName == '') {
        this.showNewDialog = false;
        return;
      }

      try {
        const  { data } = await this.$axios.post(`/grupo/insere`, this.$qs.stringify({ nome_grupo: groupName }));
        const [response] = data;

        if (response && response.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        const newGroup = {
          text: groupName,
          value: response.id_grupo
        };
        this.groups.push(newGroup);

        this.groupName = '';
        this.showNewDialog = false;
        this.input = this.returnObject ? newGroup : response.id_grupo;
        this.onItemSelected(this.input);

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar a grupo!", "Atenção");
        console.warn(error);
      }
    }
  },
};
</script>
