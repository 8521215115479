<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#list">
          Análise Periódica de Silo
        </v-tab>

        <v-tab-item value="list">
          <periodic-silo-analysis-list
            v-if="tab === 'list'"
            ref="periodicSiloAnalysisList"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import PeriodicSiloAnalysisList from "@/Domains/Platform/PeriodicSiloAnalysis/Views/PeriodicSiloAnalysisList.vue";

export default {
  name: "PeriodicSiloAnalysis",

  components: {
    PeriodicSiloAnalysisList,
  },

  data() {
    return {
      tab: 'list',
    };
  },
};
</script>
