<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.project"
            :items="paeList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRange"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <month-range-picker
            v-model="filter.range"
            :ranges.sync="filter.ranges"
            :min="filter.min"
            :max="filter.max"
            dark
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <person-autocomplete-filter
            v-model="filter.technician"
            type="TECHNICAL"
            label="Técnico"
            dark
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <routes-autocomplete-filter
            v-model="filter.routes"
            label="Rota"
            dark
            multiple
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-flex>
            <v-tabs
              v-model="tabVertical"
              centered
              dark
              background-color="transparent"
              class="transparent"
              @change="onTabChange"
            >
              <v-tab href="#critical">
                Críticos
              </v-tab>
              <v-tab href="#priority">
                Prioritários CPP
              </v-tab>
              <v-tab href="#priority-ccs">
                Prioritários CCS
              </v-tab>

              <v-tab-item value="critical">
                <emergency-report
                  ref="critical"
                  type="critical"
                  :filter="filter"
                />
              </v-tab-item>

              <v-tab-item value="priority">
                <emergency-report
                  ref="priority"
                  type="priority"
                  :filter="filter"
                />
              </v-tab-item>
              <v-tab-item value="priority-ccs">
                <emergency-report
                  ref="priority_ccs"
                  type="priority-ccs"
                  :filter="filter"
                />
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-col>
      </v-row>
    </v-flex>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import EmergencyReport from "@/Domains/Projects/PQFL/PAE/Components/EmergencyReport.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "emergency-tab",

  components: {
    EmergencyReport,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthRangePicker,
  },
 
  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {
      loading: false,
      tabVertical: null,

      filter: {
        project: {
          start: null,
          end: null,
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        technician: {
          id: "",
          description: ""
        },
        routes: [],
        search: ''
      },

      paeList: [],
  
    };
  },

  watch: {
    filter: {
      handler() {
        this.onTabChange();
      },
      deep: true
    }
   
  },

  created() {
    this.loadPae();
  },

  methods: {
    onTabChange() {
      if (this.tabVertical == 'priority') {
        setTimeout(() => {
          this.$refs.priority.loadReport();
        }, 1000);

      } else if (this.tabVertical == 'priority-ccs') {
        setTimeout(() => {
          this.$refs.priority_ccs.loadPriorityCCSReport();
        }, 1000);

      } else if (this.tabVertical == 'critical' && this.$refs.critical) {
        this.$refs.critical.loadReport();
      }
      
    },

    async loadRange() {

      let dateEnd = (moment() < moment(this.filter.project.dateEnd)) ? moment().format('YYYY-MM') : moment(this.filter.project.dateEnd).format('YYYY-MM');
      
      this.filter.max = dateEnd;
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.range = [moment().format('YYYY-MM'), dateEnd];
      
    },
   
    async loadPae() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL"
          }
        );

        this.paeList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            emergency: proj.emergencial,
          }
        });
        
        if (!_.isEmpty(this.paeList )) {
          this.filter.project = this.paeList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>