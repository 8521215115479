<template>
  <div>
    <span
      class="overline"
      style="line-height: 15px !important; font-size: 10px !important;"
    >
      {{ tank.label }}
    </span>

    <v-divider />

    <v-row>
      <v-col cols="6">
        <masked-input
          v-model.number="localTank.inletWeight"
          label="Peso Inicial (Kg)"
          prepend-inner-icon="local_shipping"
          :mask="NumberMask"
          :max-length="8"
          filled
          hide-details
          return-unmasked
          @input="onTankWeightChange"
        >
          <template slot="append">
            <v-btn
              icon
              small
              @click="getTruckWeigh( 'inletWeight')"
            >
              <v-icon small>
                autorenew
              </v-icon>
            </v-btn>
          </template>
        </masked-input>
      </v-col>

      <v-col cols="6">
        <masked-input
          v-model.number="localTank.outletWeight"
          label="Peso Final (Kg)"
          prepend-inner-icon="local_shipping"
          :mask="NumberMask"
          :max-length="8"
          filled
          hide-details
          return-unmasked
          @input="onTankWeightChange"
        >
          <template slot="append">
            <v-btn
              icon
              small
              @click="getTruckWeigh('outletWeight')"
            >
              <v-icon small>
                autorenew
              </v-icon>
            </v-btn>
          </template>
        </masked-input>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {

  components: {
    MaskedInput,
  },

  props: {

    tank: {
      type: Object,
      default: () => ({}),
    },

  },

  data() {
    return {

      localTank: {},

      // Mascara usada nos damais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            signed: true,
            scale: 2,
            max: 99000,
          },
        },
      },
    }
  },

  watch: {

    tank: {
      deep: true,

      handler(updatedTank) {
        this.localTank = updatedTank;
      },
    },

  },

  mounted() {
    this.localTank = this.tank;
  },

  methods: {

    /**
     *  Método busca o peso da balança
     */
    async getTruckWeigh(field) {
      try {
        const { data: { valor } } = await this.$axios.post(`/balanca/listaDadosBalanca`);

        this.localTank = {
          ...this.localTank,
          [field]: parseInt(valor),
        }

        return this.onTankWeightChange();
      } catch (e) {
        console.warn(e);
      }
    },

    onTankWeightChange() {
      return this.$emit('onTankWeightChange', this.localTank);
    },

  },
}
</script>
