var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.validation.valid),callback:function ($$v) {_vm.$set(_vm.validation, "valid", $$v)},expression:"validation.valid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"flex-grow-1 text-h5 mt-4 black--text"},[_vm._v("Configuração da NFP-e")])]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"4"}},[_c('v-select',{attrs:{"items":[
              { text: 'Produção', value: 1 },
              { text: 'Homologação', value: 2 },
            ],"label":"Ambiente","rules":[v => !!v || 'Campo obrigatório']},model:{value:(_vm.settings.environment),callback:function ($$v) {_vm.$set(_vm.settings, "environment", $$v)},expression:"settings.environment"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"4"}},[_c('masked-text-field',{attrs:{"label":"Série NFP-e *","placeholder":"920-969","mask":{ mask: '000' },"unmask":"","prepend-inner-icon":"tag","rules":[
              v => !!v || 'Campo obrigatório!',
              v => (v >= 920 && v <= 969) || 'Série deve estar entre 920 e 969',
            ]},model:{value:(_vm.settings.series),callback:function ($$v) {_vm.$set(_vm.settings, "series", $$v)},expression:"settings.series"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"4"}},[_c('v-select',{attrs:{"items":[
              { value: 'LOCAL', text: 'Local' },
              { value: 'GLOBAL', text: 'Global' },
            ],"label":"Natureza de Operação","rules":[v => !!v || 'Campo obrigatório']},model:{value:(_vm.settings.operationType),callback:function ($$v) {_vm.$set(_vm.settings, "operationType", $$v)},expression:"settings.operationType"}})],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"flex-grow-1 text-h5 mt-4 black--text"},[_vm._v("Certificado Digital (A1)")])]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[(_vm.settings.certificate)?_c('file-viewer',{attrs:{"value":_vm.settings.certificate,"preview-height":58},on:{"removeFile":function($event){return _vm.onRemoveFile()}}}):_c('v-card',{staticClass:"text-center",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-btn',{staticClass:"mb-5 mt-5",attrs:{"outlined":""},on:{"click":function($event){_vm.showFileUpload = !_vm.showFileUpload}}},[_c('v-icon',[_vm._v("attach_file")]),_vm._v(" Adicionar ")],1)],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Senha","type":"password"},model:{value:(_vm.settings.password),callback:function ($$v) {_vm.$set(_vm.settings, "password", $$v)},expression:"settings.password"}})],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"flex-grow-1 text-h5 mt-4 black--text"},[_vm._v("Controle de Numeração da NFP-e")]),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"my-1",attrs:{"outlined":"","color":"blue"},on:{"click":function($event){return _vm.addNumeration()}}},[_c('v-icon',[_vm._v("add")]),_vm._v(" Adicionar Numeração ")],1)],1)]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":[
              { text: 'Série', value: 'series' },
              { text: 'Próximo número', value: 'nextNumber' },
              { text: 'Ambiente', value: 'environment' },
              { text: 'Opções', value: 'actions', sortable: false, align: 'center' },
            ],"items":_vm.numerations,"items-per-page":-1,"hide-default-footer":""},on:{"click:row":_vm.editNumeration},scopedSlots:_vm._u([{key:`item.environment`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.getEnvironment(value))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"small":""},on:{"click":function($event){return _vm.removeNumeration(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Excluir")])],1)],1)],1)]}}],null,true)})],1)],1)],1),(_vm.settings.operationType === 'LOCAL')?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"flex-grow-1 text-h5 mt-4 black--text"},[_vm._v("Natureza de Operação")]),_c('div',{staticClass:"d-flex justify-end"},[(_vm.operations.length === 0)?_c('v-btn',{staticClass:"my-1",attrs:{"outlined":"","color":"blue"},on:{"click":function($event){return _vm.addOperation()}}},[_c('v-icon',[_vm._v("add")]),_vm._v(" Adicionar Natureza ")],1):_vm._e()],1)]),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":[
              { text: 'Descrição', value: 'description' },
              { text: 'CFOP', value: 'icms.cfop' },
              { text: 'ICMS', value: 'icms.tributacao' },
              { text: 'PIS', value: 'pis.tributacao' },
              { text: 'COFINS', value: 'cofins.tributacao' },
              { text: '', value: 'actions', width: 10, align: 'end'},
            ],"items":_vm.operations,"items-per-page":-1,"hide-default-footer":""},on:{"click:row":_vm.editOperation},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.removeOperation(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Excluir")])],1)],1)],1)]}}],null,true)})],1)],1)],1):_vm._e()],1),_c('file-upload',{attrs:{"multiselect":false,"extensions":"p12,pfx","accept":".p12,.pfx"},on:{"insertFiles":_vm.onSelectFile},model:{value:(_vm.showFileUpload),callback:function ($$v) {_vm.showFileUpload=$$v},expression:"showFileUpload"}}),_c('numeration-dialog',{attrs:{"edit-id":_vm.numerationDialog.id,"company-id":_vm.producerId,"is-nfpe":""},on:{"save":_vm.loadNumerations},model:{value:(_vm.numerationDialog.show),callback:function ($$v) {_vm.$set(_vm.numerationDialog, "show", $$v)},expression:"numerationDialog.show"}}),_c('operation-dialog',{attrs:{"edit-id":_vm.operationDialog.id,"company-id":_vm.producerId,"default-type":"NFPE"},on:{"save":_vm.loadOperations},model:{value:(_vm.operationDialog.show),callback:function ($$v) {_vm.$set(_vm.operationDialog, "show", $$v)},expression:"operationDialog.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }