<template>
  <div
    :class="{
      'd-flex': true,
      'align-center': true,
      'justify-center': hideAmount
    }"
    :style="{
      width: hideAmount ? null : '90px'
    }"
  >
    <span v-if="!hideAmount">
      {{ formatCurrency(installment.totalBonus, 2) }}
    </span>
    <v-spacer v-if="!hideAmount" />
    <v-icon
      :color="icon.color"
      small
      class="ml-2"
    >
      {{ icon.name }}
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    installment: {
      type: Object,
      default: () => ({}),
    },

    hasStatusChange: {
      type: Boolean,
      default: false,
    },

    hideAmount: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    icon() {
      if (this.installment.paid) {
        return {
          name: 'check_circle',
          color: 'green',
        }
      }

      if (this.hasStatusChange) {
        return {
          name: 'report',
          color: 'red',
        }
      }

      return {
        name: 'event_available',
        color: 'white'
      }
    },
  },

  methods: {

    formatCurrency: (value, decimals = 4) => {
      const formatter = new Intl.NumberFormat(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
          maximumFractionDigits: decimals
        }
      );

      return formatter.format(value || 0);
    },
  },
};
</script>
