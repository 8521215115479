<template>
  <v-card
    color="transparent"
    dark
    class="rota-resumo"
  >
    <v-card-title
      class="text-subtitle-2 pt-2 pb-2"
      style="background: rgba(0, 0, 0, 0.5);"
    >
      <slot name="title">
        Resumo da rota
      </slot>

      <v-spacer />

      <slot name="actions" />
    </v-card-title>

    <v-card-text
      class="pa-0"
      style="background: rgba(0, 0, 0, 0.3);"
    >
      <v-row
        class="text-body-2 pt-1 pb-1 ma-0"
        align="center"
        justify="center"
      >
        <slot name="append" />

        <v-col
          v-if="!editable"
          :cols="textLeft ? 12 : null"
          :md12="textLeft"
          :class="textClass"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span>{{ distance.toFixed(1) }} Km</span>
              <span
                class="pl-1"
                v-on="on"
              >
                <v-icon
                  v-if="maps.needsUpdate"
                  small
                  v-text="`error_outline`"
                />
              </span>
            </template>

            <span>Esta informação pode estar desatualizada!</span>
          </v-tooltip>
        </v-col>

        <v-col
          v-if="editable"
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <masked-input
            v-model.number="distance"
            :mask="NumberMask"
            class="mt-0"
            hide-details
            dense
            return-unmasked
            currency
          >
            <template v-slot:prepend-inner>
              <v-icon dense>
                map
              </v-icon>
            </template>
          </masked-input>
        </v-col>

        <v-col
          v-if="!editable && duration"
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span>{{ duration }} h</span>
              <span
                class="pl-1"
                v-on="on"
              >
                <v-icon
                  v-if="maps.needsUpdate"
                  small
                  v-text="`error_outline`"
                />
              </span>
            </template>

            <span>Esta informação pode estar desatualizada!</span>
          </v-tooltip>
        </v-col>

        <v-col
          v-if="editable"
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <masked-input
            v-model="duration"
            :mask="TimeMask"
            class="mt-0"
            hide-details
            dense
          >
            <template v-slot:prepend-inner>
              <v-icon dense>
                alarm
              </v-icon>
            </template>
          </masked-input>
        </v-col>

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          {{ visibleCustomers.length }} Clientes
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.rota-resumo > .v-card__text {
  color: white !important;
}
</style>

<script>
import _ from "lodash";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import IMask from "imask";

export default {

  components: {
    MaskedInput,
  },

  props: {
    customers: {
      type: Array,
      default: () => ([]),
    },
    equipments: {
      type: Array,
      default: () => ([]),
    },

    params: {
      type: Object,
      default: () => ({}),
    },
    stats: {
      type: Object,
      default: () => ({}),
    },
    maps: {
      type: Object,
      default: () => ({}),
    },
    textLeft: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
    },
  },

  data() {
    return {

      TimeMask: {
        mask: 'HH:MM',
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            placeholderChar: 'HH',
            from: 0,
            to: 23,
            maxLength: 2,
          },
          MM: {
            mask: IMask.MaskedRange,
            placeholderChar: 'MM',
            from: 0,
            to: 59,
            maxLength: 2,
          },
        },
      },

      // Mascara usada nos demais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 1,
            thousandsSeparator: '.',
            min: -999999,
            max: 999999,
          },
        },
      },

    };
  },

  computed: {
    /**
     * Clientes visíveis da lista
     */
    visibleCustomers() {
      return this.customers.filter(customer => !customer.remove && customer.type !== "PARADA");
    },

    /**
     * Distância média da rota
     */
    distance: {
      get() {
        return (this.stats.distance || 0);
      },
      set(newValue) {
        return this.$emit('update:stats', {
          ...this.stats,
          distance: newValue,
        });
      },
    },

    /**
     * Duração prevista da rota
     */
    duration: {
      get() {
        if (_.isEmpty(this.stats.duration) || this.stats.duration === "00") {
          return null;
        }

        return this.stats.duration;
      },
      set(newValue) {
        return this.$emit('update:stats', {
          ...this.stats,
          duration: newValue,
        });
      },
    },

    textClass() {
      if (this.textLeft) {
        return {
          "text-left": true,
        };
      }

      return {};
    },
  },

};
</script>
