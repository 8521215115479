<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Gráficos/Relatórios PMLS
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#quality-reports">
          Qualidade
        </v-tab>
        <v-tab href="#visits-reports">
          Visitas
        </v-tab>
        <v-tab href="#evolution">
          Evolução dos produtores
        </v-tab>
        <v-tab href="#classification">
          Curva ABC
        </v-tab>

        <v-tab-item value="quality-reports">
          <quality-report />
        </v-tab-item>

        <v-tab-item value="visits-reports">
          <visit-report />
        </v-tab-item>

        <v-tab-item value="evolution">
          <evolution
            ref="evolution"
            aba="evolution"
          />
        </v-tab-item>

        <v-tab-item value="classification">
          <producer-rating
            ref="classification"
            view="goal-pmls"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.project-pmls {

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import QualityReport from '@/Domains/Projects/PMLS/Components/QualityReport.vue';
import VisitReport from '@/Domains/Projects/PMLS/Components/VisitReport.vue';
import Evolution from '@/Domains/Projects/PMLS/Views/Evolution.vue';
import ProducerRating from "@/Domains/Projects/PQFL/BPA/Components/ProducerRating.vue";

export default {

  components: {
    QualityReport,
    VisitReport,
    Evolution,
    ProducerRating,
  },

  data() {
    return {
      tab: null,

    };
  },


};
</script>
