<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <v-flex>
            <v-card
              class="v-tamanho mt-2"
              color="transparent"
              dark
            >
              <v-card-title>
                <v-row>
                  <v-col>
                    <period-date
                      v-model="filters.date.input"
                      @change="onDateFilter"
                    />
                  </v-col>
                  <v-col
                    md="3"
                  >
                    <person-autocomplete-filter
                      v-model="filters.technician"
                      type="TECHNICAL"
                      label="Técnico"
                      dark
                      @change="onLoad"
                    />
                  </v-col>
                  <v-col
                    md="3"
                  >
                    <routes-autocomplete-filter
                      v-model="filters.routes"
                      label="Rota"
                      dark
                      @change="onLoad"
                    />
                  </v-col>
                  <v-col
                    md="3"
                  >
                    <v-text-field
                      v-model="filters.search"
                      prepend-inner-icon="search"
                      label="Buscar"
                      hide-details
                      dark
                      filled
                    />
                  </v-col>
                </v-row>
              </v-card-title>

              <data-table
                ref="report"
                :headers="headers"
                :items="filteredProducers"
                class="elevation-1"
                dark
                :loading="loading"
                :search="filters.search"
              >
                <template #[`item.action`]="{ item }">
                  <v-menu
                    bottom
                    right
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                      >
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="onChangeProducerStatusToActive(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>person_add</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Reativar Produtor</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="onChangeProducerStatusToUnlink(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>person_add_disabled</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Desvincular</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </data-table>
            </v-card>
          </v-flex>
        </v-col>
      </v-row>
    </v-form>

    <status-producer-dialog
      ref="statusProducerDialog"
      @save="onSaveProducerStatus()"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay
      v-model="loading"
      absolute
    >
      <v-card-text>
        Carregando
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import StatusProducerDialog from "@/Domains/Producer/Management/Components/Dialogs/StatusProducerDialog.vue";
import PeriodDate from "@/Domains/Quality/Indicators/SuspendedProducers/Components/PeriodDate.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import { ReasonsEnum } from "@/Domains/Producer/Management/Enums/ReasonsEnum.js";

export default {

  components: {
    StatusProducerDialog,
    PeriodDate,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter
  },

  data() {
    return {
      tab: "suspended",

      loading: false,

      list: [],

      filters: {
        date: {
          input: null,
          range: [],
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        search: null,
      },

      statusDialog: {
        type: 'unlink'
      },
    };
  },

  computed: {
    headers() {
      let headers = [
        { text: "Produtor", value: "produtor", align: "", with: 150 },
        { text: "Responsável", value: "responsavel", align: "", with: 80 },
        { text: "Data Suspensão", value: "data_suspensao", align: "center", with: 50 },
        { text: "Período Suspensão", value: "periodo", align: "center", with: 50 },
        { text: "Motivo", value: "motivo", align: "center", with: 80 },
        { text: "Rota Principal", value: "rota_principal", align: "" },
        { text: "Volume (L)", value: "volume", align: "center", with: 80 },
        { text: "Última visita", value: "data_ultima_visita", align: "center", with: 80 },
        { text: '', altText: 'Opções', value: 'action', align: 'end' },
      ];

      return headers;
    },

    filteredProducers() {
      if (!this.filters.date.input) {
        return this.list;
      }

      return this.list.filter(x => x.data_suspenso >= this.filters.date.range[0] && x.data_suspenso <= this.filters.date.range[1]);
    },
  },

  mounted() {
    this.loadSuspended();
  },

  methods: {
    async loadSuspended() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/produtores/suspendedReport`,
          this.$qs.stringify({
            id_pessoa: this.filters.technician.id,
            rotas: this.filters.routes.id
          })
        );

        this.list = _.map(data, item => {
          return {
            id_pessoa: item.id_pessoa,
            nome: item.nome,
            produtor: item.produtor,
            responsavel: item.nome_pessoa_registro,
            data_suspensao: this.formatDate(item.data_suspensao, "DD/MM/YYYY"),
            data_suspenso: item.data_suspensao,
            motivo: item.motivo ? ReasonsEnum[item.motivo] : item.outros_motivos,
            rota_principal: item.rota,
            data_ultima_visita: item.data_ultima_visita ? this.formatDate(item.data_suspensao, "DD/MM/YYYY") : ' - ',
            periodo: item.periodo ? item.periodo + ' Dia (s)' : '-',
            volume: item.litros ? _.replace(parseFloat(item.litros).toFixed(2), '.', ',') : 0,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os suspensos!", "Atenção");
        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event;
    },

    onLoad() {
      this.loadSuspended();
    },

    onChangeProducerStatus(producer, status, title) {
      return this.$refs.statusProducerDialog.show({
        title,
        producer: {
          id: producer.id_pessoa,
          name: producer.nome,
        },
        form: {
          status: status,
          date: moment().toDate(),
          params: {
            showStatusSelect: false,
          },
        },
      });
    },

    onChangeProducerStatusToActive(producer) {
      return this.onChangeProducerStatus(producer, 'APROVADO', 'Reativar produtor');
    },

    onChangeProducerStatusToUnlink(producer) {
      return this.onChangeProducerStatus(producer, 'DESVINCULADO', 'Desvincular produtor');
    },

    onSaveProducerStatus() {
      this.loadSuspended();
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Suspensos - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Suspensos - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.print(null, filename);
    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
