<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color, overflow: 'hidden'}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">
        Silos {{ dairy.name ? `- ${dairy.name}` : '' }}
      </span>
    </v-card-title>
    <v-chart
      :options="options"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    graphColor: {
      type: String,
      default: 'rgba(38, 198, 218, 0.7)'
    },
    height: {
      type: String,
      default: '100px',
    },
    dairy: Object,
  },

  data() {
    return {
      loading: false,
      silos: [],
      average: 0
    }
  },

  computed: {
    options() {
      return {
        color: [this.graphColor],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: ([param]) => [
            param.name,
            `${param.marker} ${param.seriesName} <b>${param.value}%</b>`,
            `${this.silos[param.dataIndex].vol || 0}L / ${this.silos[param.dataIndex].capacity || 0}L`
          ].join('<br>')
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '5%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.silos.map(silo => silo.label),
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
        },
        series: [{
          name: 'Silos',
          type: 'bar',
          areaStyle: {},
          lineStyle: { width: 2 },
          showSymbol: false,
          smooth: false,
          emphasis: {
            focus: 'series'
          },
          data: this.silos.map((silo) => ({
            value: this.utilization(silo),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              formatter: ({ value }) => `  ${value} %  `
            }
          }))
        }]
      }
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {

        const { data } = await this.$axios.post(`/silo/principal`, {
          id_laticinio: this.dairy.id
        });

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.silos = data.silo.map(silo => {
          return {
            index: (silo.descricao || '').replace(/\D/g, ''),
            id: silo.id_silo,
            label: silo.descricao || '',
            vol: parseInt(silo.volume_atual),
            capacity: parseInt(silo.volume_total),
            rawProduct: {
              id: silo.id_materia_prima,
              name: silo.nome_materia_prima,
            },
          }
        }).sort((a, b) => a.index - b.index);

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de silos!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    utilization(silo) {
      if (!silo.vol || !silo.capacity) {
        return 0;
      }

      const perc = ((silo.vol / silo.capacity) * 100).toFixed(0);

      return perc > 0 ? perc : 0;
    },

  }
}
</script>
