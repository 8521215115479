<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    max-width="700px"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Padrões de Análise do {{ silo.label }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>{{ silo.rawProduct.name }}</v-card-subtitle>

      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <analysis-card
              v-for="analysis in analysisItems"
              :key="analysis.param"
              :analysis="analysis"
              :disabled="!selected.includes(analysis.param)"
              @onValidation="onValidation"
            >
              <template #prepend>
                <v-checkbox
                  v-model="selected"
                  :value="analysis.param"
                  class="ma-0"
                />
              </template>
            </analysis-card>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

import AnalysisCard from '@/Domains/Settings/Components/Analysis/AnalysisCard.vue';
import analysisParams from '@/Domains/Settings/Components/Analysis/Services/AnalysisParams.js';

export default {

  components: {
    AnalysisCard,
  },

  data() {
    return {
      showDialog: false,

      //flag de validação dos cards
      hasErrorAnalysis: true,

      silo: {
        rawProduct: {},
      },

      analysisItems: [],

      selected: [],
    }
  },

  methods: {
    show(silo) {
      this.showDialog = true;
      this.$refs.form && this.$refs.form.resetValidation();

      this.silo = { ...silo };
      this.analysisItems = [];
      this.selected = [];

      this.loadAnalysis();
    },

    async loadAnalysis() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/configuracao/listaConfiguracoesPlataforma`);

        const selectedAnalysis = data.parametros_analise.materia_prima[this.silo.rawProduct.id] || [];

        this.analysisItems = selectedAnalysis
          .map(data => {

            const analysis = analysisParams.find(item => item.param.trim() === data.parametro.trim());

            if (!analysis || !!analysis.depends || !_.isObject(analysis.standard)) {
              return null;
            }

            const parseAnalysis = {
              param: data.parametro,
              text: analysis.text,
              standard: data.padrao,
            };

            const savedParam = this.silo.analysisStandards.find(item => item.parametro.trim() === data.parametro.trim());

            if (savedParam) {
              parseAnalysis.standard = { ...(savedParam.padrao || {}) };
              this.selected.push(data.parametro);
            }

            if (_.has(data, 'unidade_medida') && !_.isEmpty(analysis.units)) {
              parseAnalysis.units = analysis.units
                .map(unit => ({
                  ...unit,
                  selected: unit.description === data.unidade_medida.descricao
                }))
                .filter(unit => unit.selected);
            }

            return parseAnalysis;
          })
          .filter(analysis => analysis);

        if (this.analysisItems.length === 0) {
          this.$snotify.warning('Não existem parâmetros configurados para esta matéria-prima');
          this.close();
          return;
        }

      } catch (e) {
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        // Verifica se existe alguma validação pendente
        if (!this.hasErrorAnalysis) {
          this.$snotify.error('Verifique os campos de parametrização!');
          return;
        }

        this.$root.$progressBar.saving();

        const analysisStandards = this.analysisItems
          .filter(analysis => this.selected.includes(analysis.param))
          .map(analysis => {

            const res =  {
              parametro: analysis.param,
              padrao: analysis.standard,
            };

            if (_.has(analysis, 'units')) {
              const unitSelected = analysis.units.find(u => u.selected);

              res.unidade_medida = {
                descricao: unitSelected.description,
                unidade: unitSelected.unit,
              };
            }

            return res;
          });

        await this.$axios.put(`/industry/silo/${this.silo.id}/analysis-standard`, {
          padroes_analise: analysisStandards
        });

        this.$emit('onSaved');
        this.$snotify.success('Cadastro efetuado com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        const message = _.get(e, 'response.data.message', 'Erro ao cadastrar');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Trata validação das analises
     */
    onValidation(event) {
      this.hasErrorAnalysis = event.valid;
    },

    close() {
      this.showDialog = false;
    }
  }

}
</script>
