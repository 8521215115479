<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Gráficos Brucelose
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

  <sanity-graphics-index
    :sanity="sanity"
  >

  </sanity-graphics-index>
  </div>
</template>

<script>
import SanityGraphicsIndex from "@/Domains/Projects/HealthExamination/SanityGraphicsIndex.vue";

export default {
  components: {
    SanityGraphicsIndex
  },

  data() {
    return {
      sanity: "BRUCELOSE",
    };
  },
}
</script>
