<template>
  <v-card
    :dark="dark"
    :color="dark ? 'transparent' : undefined"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title style="height: 70px;">
        <v-text-field
          v-if="isEditing"
          v-model="form.title"
          placeholder="Tabela de faixas"
          class="pt-0 mt-0"
          :rules="[v => !!v || 'Informe o título']"
        />
        <span v-else>
          {{ form.title || 'Tabela de faixas' }}
        </span>
        <v-spacer />
        <div class="align-self-start">
          <v-btn
            v-if="!isEditing"
            icon
            @click="remove()"
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
          <v-btn
            v-if="!isEditing"
            icon
            @click="toggleEditing()"
          >
            <v-icon>
              {{ isEditing ? 'visibility': 'edit' }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="isEditing && form.ranges.length > 1"
            icon
            color="red"
          >
            <v-icon
              @click="changeRange('remove')"
            >
              remove
            </v-icon>
          </v-btn>
          <v-btn
            v-if="isEditing"
            icon
            color="blue"
          >
            <v-icon
              @click="changeRange('add')"
            >
              add
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-divider />
      <v-simple-table>
        <template #default>
          <tbody>
            <tr
              v-for="(item, idx) in form.ranges"
              :key="idx"
            >
              <td class="text-left">
                {{ (idx == 0) ? 'Inicial' : '>' }}
              </td>
              <td class="text-left px-0">
                <masked-text-field
                  v-if="isEditing"
                  v-model="item.km"
                  label="Km"
                  :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                  unmask
                  outlined
                  dense
                  class="pt-4"
                  :disabled="idx == 0"
                  :rules="getKmRules(idx)"
                />
                <span v-else>{{ formatNumber(item.km) }} Km</span>
              </td>
              <td class="text-right">
                <masked-text-field
                  v-if="isEditing"
                  v-model="item.valor"
                  label="Valor"
                  :mask="{ mask: Number, min: 0, scale: 4, signed: false }"
                  unmask
                  outlined
                  dense
                  class="pt-4"
                  :rules="[v => !!v || 'Informe o valor']"
                />
                <span v-else>{{ formatCurrency(item.valor) }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-form>

    <v-card-actions
      :style="dark ? 'background: rgba(0, 0, 0, 0.3)': ''"
    >
      <v-spacer />

      <v-btn
        v-if="isEditing"
        outlined
        @click="close()"
      >
        Cancelar
      </v-btn>
      <v-btn
        v-if="isEditing"
        color="blue"
        outlined
        @click="save()"
      >
        Salvar
      </v-btn>
    </v-card-actions>

    <v-overlay
      v-if="saving"
      absolute
    >
      <v-card-text>
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-card>
</template>

<script>
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

import isEmpty from 'lodash/fp/isEmpty';
import cloneDeep from 'lodash/fp/cloneDeep';

export default {

  components: {
    MaskedTextField,
  },

  props: {
    id: String,
    title: String,
    ranges: Array,
    dark: Boolean,
    stored: Boolean,
    saving: Boolean,
    editing: Boolean,
  },

  data() {
    return {
      form: {
        id: '',
        title: '',
        ranges: []
      }
    }
  },

  computed: {
    isEditing: {
      get() {
        return this.editing;
      },
      set(value) {
        this.$emit("update:editing", value);
      },
    },
  },

  watch: {
    ranges: {
      deep: true,
      immediate: true,
      handler() {
        this.initialize();
      }
    }
  },

  methods: {
    initialize() {
      this.form.id = this.id;
      this.form.title = this.title;
      if (isEmpty(this.ranges)) {
        this.form.ranges = [{ km: 0, valor: null }];
      }
      else {
        this.form.ranges = cloneDeep(this.ranges);
      }
    },
    changeRange(type) {
      if (type == 'add') {
        this.form.ranges.push({ km: null, valor: null });
      }
      if (type == 'remove') {
        if (this.form.ranges.length > 1) {
          this.form.ranges.splice(-1, 1);
        }
      }
      this.resetValidation();
    },

    getKmRules(idx) {
      return [
        v => idx == 0 || parseFloat(v) > parseFloat(this.form.ranges[idx - 1].km) || 'Valor deve ser maior que o anterior',
        v => idx == this.form.ranges.length - 1 || parseFloat(v) < parseFloat(this.form.ranges[idx + 1].km) || 'Valor deve ser menor que o próximo',
      ]
    },

    toggleEditing() {
      this.isEditing = !this.isEditing;
      this.resetValidation();
    },

    resetValidation() {
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.resetValidation();
      });
    },

    remove() {
      this.$emit('remove');
    },

    close() {
      if (!this.stored) {
        this.$emit('cancel');
      }
      else {
        this.initialize();
        this.toggleEditing();
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      this.$emit('save', this.form);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
  }
}
</script>
