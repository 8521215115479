<template>
  <div class="mx-6 px-16">
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="loadItems"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="5"
            lg="4"
            class="py-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              dark
              label="Procurar"
              filled
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        name="Coleta de Antibiótico"
        :items="filteredItems"
        :headers="headers"
      >
        <template #[`item.avisado`]="{ item }">
          <v-checkbox
            v-model="item.avisado"
            @click.capture="atualizaResultado(item)"
          />
        </template>
        <template #[`item.nome_produtor`]="{ item }">
          {{ item.nome_produtor }}
          <v-icon
            v-if="item.id_condominio"
            color="rgba(255, 255, 255, 0.7)"
          >
            groups
          </v-icon>
        </template>
        <template #[`item.observacao`]="{ item }">
          <v-text-field
            v-model="item.observacao"
            autocomplete="off"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>
        <template #[`item.positivo`]="{ item }">
          <v-autocomplete
            v-model="item.positivo"
            autocomplete="off"
            :items="values"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>
        <template #[`item.principio_ativo`]="{ item }">
          <v-autocomplete
            v-model="item.principio_ativo"
            autocomplete="off"
            :items="antibioticTypes"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>
        <template #[`item.marca_comercial`]="{ item }">
          <v-autocomplete
            v-model="item.marca_comercial"
            autocomplete="off"
            :items="markTypes"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>
        <template #[`item.analista`]="{ item }">
          <v-autocomplete
            v-model="item.analista"
            autocomplete="off"
            :items="analistas"
            item-text="name"
            return-object
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>
        <template #[`item.lote_antibiotico`]="{ item }">
          <v-text-field
            v-model="item.lote_antibiotico"
            autocomplete="off"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>
        <template #[`item.data_analise`]="{ item }">
          <masked-text-field
            v-model="item.data_analise"
            :mask="dateMask"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>
        <template #[`item.imagens`]="{ item }">
          <v-btn
            icon
            @click="openGallery(item.anexos)"
          >
            <div v-if="!item.anexos || (item.anexos && JSON.parse(item.anexos).length < 1)">
              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-icon disabled>
                      image
                    </v-icon>
                  </div>
                </template>
                Sem Anexos
              </v-tooltip>
            </div>
            <div v-else>
              <v-icon>
                image
              </v-icon>
            </div>
          </v-btn>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                small
                @click="editAnexos(item)"
              >
                <v-list-item-icon>
                  <v-icon>image</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar Anexos</v-list-item-title>
              </v-list-item>

              <v-list-item
                small
                @click="excludeSample(item.id_coletas_amostra)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="purple darken-1"
        @click.stop="addSample()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          <span>Inserir Nova Amostra</span>
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color=" green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <add-sample-dialog
      v-model="showAddSample"
      type="Antibioticos"
      @save="loadItems"
    />

    <LightBox
      v-if="pictures.length > 0"
      ref="lightbox"
      :media="pictures"
      :show-light-box="false"
    />

    <anexos-dialog
      v-model="showAnexos"
      :form="form"
      @save="loadItems"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import AddSampleDialog from "@/Domains/Collects/Samples/Components/AddSampleDialog.vue";
import AnexosDialog from "@/Domains/Collects/Samples/Components/AnexosDialog.vue";
import LightBox from 'vue-image-lightbox';

export default {
  components: {
    VDateRangePicker,
    MaskedTextField,
    AddSampleDialog,
    LightBox,
    AnexosDialog,
  },

  data() {
    return {
      filters: {
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        search: null,
      },

      items: [],
      pictures: [],

      values: ["POSITIVO", "NEGATIVO", "ACIDO"],

      showAddSample: false,

      form: {},

      antibioticTypes: [],
      markTypes: [],

      showAnexos: false,

      loading: false,

      analistas: [],

      dateMask: {
        mask: "00/00/0000",
      },

      itemsToUpdate: {},
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = this.filters.search?.toUpperCase()?.trim();
      return this.items.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasAccessHideProducer() {
      return !this.isAdmin && this.userResources.some(o => o.recurso === "hide-producer-collecting-antibiotics" && o.tipo === "COMPONENTE");
    },

    headers() {
      const headers = [
        { text: 'Avisado', value: 'avisado', align: 'start', sortable: false, formatter: value => value ? 'Sim' : 'Não' },
        { text: 'Código', value: 'codigo_laticinio', align: 'center' },
      ];

      if (!this.hasAccessHideProducer) {
        headers.push({ text: 'Produtor', value: 'nome_produtor' });
      }

      headers.push(
        { text: 'Rota', value: 'rota', align: 'center' },
        { text: 'Amostra', value: 'numero_amostra', align: 'center' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Data', value: 'data'  },
        { text: 'Resultado', value: 'positivo'  },
        { text: 'Marca', value: 'marca_comercial', show: false, align: 'center'  },
        { text: 'Grupo de Antibiótico', value: 'principio_ativo', show: false },
        { text: 'Analista', value: 'analista', width: 150 },
        { text: 'Lote', value: 'lote_antibiotico', align: 'center' },
        { text: 'Data da Análise', value: 'data_analise', align: 'center' },
        { text: 'Motorista/Usuário', value: 'nome_pessoa_insert', align: 'center', width: 50 },
        { text: 'Usuário Atualização', value: 'nome_pessoa_registro', align: 'center', width: 50 },
        { text: 'Requerimento Amostra / Fotos', value: 'imagens', align: 'center' },
        { text: 'Opções', altText: 'Opções', value: 'actions', sortable: false, align: 'center' }
      );

      return headers;
    },
  },

  async mounted() {
    await this.loadAnalyst();
  },

  methods: {
    // Controla os campos de edição, para que não atualize o tempo todo, enquanto ainda está sendo preenchido os campos
    resultadoSelecionado({ id_coletas_amostra }) {
      if (this.itemsToUpdate[id_coletas_amostra] !== undefined) {
        clearTimeout(this.itemsToUpdate[id_coletas_amostra]);
        delete this.itemsToUpdate[id_coletas_amostra];
      }
    },

    atualizaResultado(item) {
      const id_coletas_amostra = item.id_coletas_amostra;

      this.resultadoSelecionado({ id_coletas_amostra });

      this.itemsToUpdate[id_coletas_amostra] = setTimeout(() => {
        this.salvaResultado(item);

        delete this.itemsToUpdate[id_coletas_amostra];
      }, 2000);
    },

    compareChanges(item, fields) {
      for (const field of fields) {
        if (item[field] != item.original[field]) {
          return true;
        }
      }
      return false;
    },

    async salvaResultado(item) {
      if (item.data_analise && !moment(item.data_analise, 'DD/MM/YYYY', true).isValid()) {
        item.data_analise = null;
      }

      const hasCommonChanges = this.compareChanges(item, ['avisado', 'observacao', 'analista', 'data_analise']);
      const hasAnalysisChanges = this.compareChanges(item, ['positivo', 'principio_ativo', 'marca_comercial', 'lote_antibiotico']);

      if (!hasCommonChanges && !hasAnalysisChanges) {
        return;
      }

      // Se a análise foi alterada e o campo de data da analise estiver vazio, insere a data atual
      if (hasAnalysisChanges && !item.data_analise) {
        item.data_analise = moment().format('DD/MM/YYYY');
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.post(
          `/coleta/atualizaResultado`,
          this.$qs.stringify({
            id_coletas_amostra: item.id_coletas_amostra,
            item: item.positivo,
            avisado: item.avisado ? 1 : null,
            observacao: item.observacao,
            principio_ativo: item.principio_ativo,
            marca_comercial: item.marca_comercial,
            id_analista: item.analista ? item.analista.id : null,
            analista: item.analista ? item.analista.name : null,
            lote_antibiotico: item.lote_antibiotico,
            data_analise: item.data_analise ? moment(item.data_analise, "DD/MM/YYYY").format("YYYY-MM-DD") : null
          })
        );

        // Atualiza o campo original
        delete item.original;
        item.original = { ...item };

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadItems();
      }
    },

    async loadItems() {
      this.storeTypes();
      try {
        this.$root.$progressBar.loading();
        const [dateStart, dateEnd] = this.filters.dateRange;
        let { data } = await this.$axios.post(
          `/coleta/listaColetaAmostra`,
          this.$qs.stringify({
            data_inicio: dateStart,
            data_fim: dateEnd,
          })
        );
        data.forEach(item => {
          if (item.principio_ativo && item.principio_ativo.startsWith('OUTROS - ')) {
            if (!this.antibioticTypes.includes(item.principio_ativo)) {
              this.antibioticTypes.push(item.principio_ativo);
            }
          }
          if (item.marca_comercial && item.marca_comercial.startsWith('OUTROS - ')) {
            if (!this.markTypes.includes(item.marca_comercial)) {
              this.markTypes.push(item.marca_comercial);
            }
          }

          // Salva o item original para comparar as alterações
          item.original = {
            ...item,
          }
        })
        this.items = data;
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as amostras!", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadAnalyst() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaAnalistas`);

        if (_.isString(data)) {
          throw data;
        }

        this.analistas = data.map(item => {
          return {
            id: item.id_pessoa,
            name: item.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os analistas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async excludeSample(id) {
      try {
        if (!(await this.$root.$confirm('Atenção', 'Deseja realmente Inativar/Excluir esta amostra?', { color: 'red' }))) {
          return;
        }
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.post(`/coleta/inativaColetaAmostra`, this.$qs.stringify({
          id_coleta: id,
        }));

        if (!data.codigo) {
          throw data.mensagem || data;
        }

      } catch (error) {
        console.log(error);
        this.$snotify.error(`Oops, ocorreu um erro ao excluir a amostra!\r\n${error}`, "Atenção");
      } finally {
        this.$root.$progressBar.hide();
        this.loadItems();
      }
    },

    addSample() {
      this.showAddSample = true;
    },

    editAnexos(item) {
      this.showAnexos = true;

      this.form = {
        id_coletas_amostra: item.id_coletas_amostra,
        attachments: item.anexos ? JSON.parse(item.anexos).map(item => {
          return {
            'url': item.url || item,
          }
        }) : [],
      };
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Coleta de Antibióticos - ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      // eslint-disable-next-line
      this.$refs.report.print(this.$refs.report.getReportJson().map(({Opções, Imagens, ...rest}) => rest), title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      // eslint-disable-next-line
      this.$refs.report.exportExcel(this.$refs.report.getReportJson().map(({Opções, Imagens, ...rest}) => rest), filename);
    },

    openGallery(images) {
      this.pictures = images ? JSON.parse(images).map(item => {
        return {
          'src': item.url || item,
          'thumb': item.url || item
        }
      }) : [];

      this.$nextTick(() => {
        this.$refs.lightbox.showImage(0);
      });
    },

    storeTypes() {
      const antibioticoPrincipioAtivo = this.$store.state.settings.gerais?.antibiotico_principio_ativo || [];
      const marcasAntibioticoAmostra = this.$store.state.settings.gerais?.marcas_antibiotico_amostra || [];

      this.antibioticTypes = antibioticoPrincipioAtivo.map(item => item.trim());
      this.markTypes = marcasAntibioticoAmostra.map(item => item.trim());
    },
  },
};
</script>
