<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <!-- Lista modulos -->
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="4"
            md-4
            sm-4
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
            />
          </v-col>
        </v-card-title>
        <v-data-table
          dark
          :headers="headersModule"
          :items="list"
          maxlength="19"
          class="diff-list-table"
          :search="filters.search"
          @click:row="handleClick"
        >
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="openEdit(item);">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item
                  small
                  @click="openAlert(item), showDeleteDialog = true;"
                >
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Alert excluir -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false"
      @keydown.esc="showDeleteDialog = false"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-center text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-center black--text">
            Deseja realmente Executar a Ação ?
          </h3>
          <v-flex
            xs12
            class="font-weight-black"
          >
            <br>
          </v-flex>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="openDelete(); showDeleteDialog = false;"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Botão Add nova module -->
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="openInsert(); cadastroSelecionado;"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Inserir Modulo</span>
    </v-tooltip>

    <!-- Pagina de Cadastro de module -->
    <v-dialog
      width="800"
      max-width="800"
      :value="showCadastro"
      @input="showCadastro = false"
      @keydown.esc="showCadastro = false"
    >
      <v-card>
        <v-card-title>Cadastro de Módulo</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="rules.isValid"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <input
                  v-model="module.id_modulo"
                  type="hidden"
                >
                <v-text-field
                  v-model="module.descricao"
                  type="text"
                  label="Descrição"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="laticinioSelected"
                  outlined
                  chips
                  small-chips
                  multiple
                  hide-details
                  label="Laticínios com Acesso"
                  :items="listLaticinio"
                  return-object
                  item-text="nome"
                  item-value="id_pessoa"
                >
                  <template #selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      color="primary"
                      @click="data.select"
                      @click:close="removeChip(data.item)"
                    >
                      <v-icon
                        left
                        small
                      >
                        person
                      </v-icon>
                      {{ data.item.nome }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="black--text"
            text
            @click="showCadastro = false;"
          >
            cancelar
          </v-btn>
          <v-btn
            class="blue--text"
            text
            @click="
              validateForm();
              onModuleSave(module);"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import IMask from "imask";
import _ from "lodash";

export default {
  name: "Module",

  filters: {
    //Filtro usado em todas as mascaras da view
    Mask(value, mask) {
      if (_.isEmpty(value)) {
        return value;
      }
      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  },

  data() {
    return {
      filters: {
        search: "",
      },
      loading: false,

      showCadastro: false,

      showDeleteDialog: false,

      cadastroSelecionado: {},

      rules: {
        isValid: false,
        nome_required: [v => !!v || "Campo obrigatorio"],
        codigo_required: [v => !!v || "Campo obrigatorio"],
      },

      module: {
        id_modulo: "",
        descricao: "",
      },
            
      objetcToDelete: "",

      headersModule: [
        {
          text: "Descrição",
          value: "descricao",
        },
        {   value: "actions",
          sortable: false,
          align: "end",
          width: 20,
        },
      ],

      list: [],

      listLaticinio: [],

      laticinioSelected: [],

      CNPJMask: {
        mask: "00.000.0000/0000-00",
        maxlength: 19,
      },
    };
  },

  mounted() {
    this.loadModules();
    this.loadLaticinio();
  },

  methods: {

    openInsert() {
      this.showCadastro = true;
      this.laticinioSelected = [];
      this.module = { id_modulo: "", descricao: "" };
    },

    async openEdit(module) {
      this.showCadastro = true;
      this.module = module;

      try {
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/listaLaticinioPorModulo`,
          this.$qs.stringify({
            id_modulo: module.id_modulo,
          })
        );
                
        this.laticinioSelected = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    openAlert(module) {
      this.objetcToDelete = module.id_modulo;
    },

    openDelete() {
      this.onModuleDelete(this.objetcToDelete);
    },

    validateForm() {
      this.$refs.form.validate();
    },

    async onModuleSave(modules) {
          
      try {
        this.showCadastro = false;
        this.loading = true;

        const request = {
          id_modulo: modules.id_modulo,
          descricao: modules.descricao,
          laticinios: JSON.stringify(this.laticinioSelected),
        };

        const { data } = await this.$axios.post(
          `/configuracaoMilkup/salvaModulo`,
          this.$qs.stringify({
            ...request,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }
            
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a analise!", "Atenção");
      } finally {
        await this.loadModules();
        this.loading = false;

        //this.$refs.dialog.close();
      }
    },

    async onModuleDelete(toDelete) {
      try {

        const { data } = await this.$axios.post(
          `/configuracaoMilkup/deleteModulo`,
          this.$qs.stringify({
            id_modulo: toDelete,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw "PHP Error";
        }

        return this.loadModules();

      } catch (error) {
        console.log(error);
      }
    },

    async loadModules() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaModulo`);
                
        this.list = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadLaticinio() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaLaticinio`);
                
        this.listLaticinio = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    //remove chip do autocomplete
    removeChip(lat) {
      this.laticinioSelected = _.remove(this.laticinioSelected, (data) => {
        return data.id_pessoa !== lat.id_pessoa;
      });
    },

    /**
     * Trata evento de clique na linha..
     */
    handleClick(item) {
      this.openEdit(item);
    }
        
  },
}
</script>

