<template>
  <div>
    <v-timeline-item
      color="orange"
      fill-dot
      right
      large
    >
      <template #icon>
        <span
          class="text-overline text-center"
          style="color: white; font-size: 12px !important;"
        >
          <v-icon
            small
            color="white"
            v-html="'error_outline'"
          />
          {{ item.date | date('HH:mm') }}
        </span>
      </template>

      <v-card class="elevation-2">
        <v-card-title
          class="text-overline pa-0 pt-1 pl-2 pr-2 pb-2"
          style="font-size: 12px !important;"
        >
          <span><b>{{ item.producer.code }} | {{ item.producer.name }}</b>  |  {{ item.date | date('DD/MM HH:mm') }}</span>
        </v-card-title>

        <v-card-text class="pb-2">
          <v-row class="text-caption pl-2 pr-2">
            <v-col
              cols="12"
              class="text-left pa-0"
            >
              {{ item.reason }}
            </v-col>
          </v-row>

          <v-row class="text-caption pl-2 pr-2">
            <v-col
              cols="12"
              class="text-right pa-0"
            >
              <v-btn
                v-if="pictures.length"
                icon
                @click="openGallery(0)"
              >
                <v-icon>image</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-timeline-item>

    <LightBox
      ref="lightbox"
      :media="pictures"
      :show-light-box="false"
      show-caption
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import LightBox from 'vue-image-lightbox';

export default {

  components: {
    LightBox,
  },

  filters: {

    date(value, opts) {
      return moment(value).format(opts);
    },

  },

  props: {
    item: {
      type: Object,
      default: () => ({
        id: '',
        type: 'OBSTACLE',
        date: '',
        producer: {
          id: null,
          code: '',
          name: '',
        },
        reason: '',
        pictures: [],
      }),
    },
  },

  computed: {

    pictures() {
      return this.item.pictures.filter(picture => picture.src);
    },

  },

  methods: {

    /**
     * Abre o lightbox com a imagem selecionada sendo exibida
     */
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },

  },

}
</script>
