<template>
  <v-dialog
    v-model="show"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      class="upload-parent"
    >
      <v-toolbar
        flat
        color="#1976D2"
        height="45px"
        class="pa-0"
      >
        <img
          src="@/Assets/logo-transparent-dark.png"
          height="30"
        >
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="show = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div
        v-show="uploader && uploader.dropActive"
        class="drop-active"
      >
        <div class="middle">
          <br>
          <v-icon
            :style="{fontSize: '60px'}"
            color="primary"
          >
            cloud_upload
          </v-icon>
          <div class="text-h4 primary--text">
            Solte o arquivo para enviar
          </div>
        </div>
      </div>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text class="pt-4">
        <div class="drop-field">
          <div class="middle">
            Arraste e solte ou
            <br>
            <vue-upload-component
              ref="upload"
              v-model="files"
              v-ripple
              :post-action="postAction"
              :extensions="extensions"
              :accept="accept"
              :size="size"
              :drop="true"
              :drop-directory="true"
              class="v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--text theme--light v-size--default primary--text"
              @input-file="inputFile"
            >
              <span style="line-height: 2.4em;">
                Faça upload
              </span>
            </vue-upload-component>
          </div>
        </div>
      </v-card-text>

      <v-card-text
        class="pt-4 text-left"
      >
        <v-form
          ref="form"
          lazy-validation
        >
          <v-expansion-panels v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="pa-2 d-inline-flex font-weight-bold">
                  Configurações
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="headersConfig.length === 0">
                  <v-col
                    cols="12"
                    class="px-2 py-1 text-center"
                  >
                    <v-alert
                      outlined
                      type="info"
                    >
                      Arquivo não enviado!
                    </v-alert>
                  </v-col>
                </v-row>
                <template v-else>
                  <v-row>
                    <v-col
                      cols="2"
                      class="px-2 py-1"
                    >
                      <span class="pa-2 d-inline-flex font-weight-bold">
                        Título
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="px-2 py-1"
                    >
                      <span class="pa-2 d-inline-flex font-weight-bold">
                        Valor
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="px-2 py-1"
                    >
                      <span class="pa-2 d-inline-flex font-weight-bold">
                        Campos
                      </span>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    v-for="(field, fieldIdx) in localfields"
                    :key="fieldIdx"
                  >
                    <v-col
                      cols="2"
                      class="px-2 py-0 d-flex align-center"
                    >
                      <span class="pa-2 d-inline-flex">
                        <span class="pt-1">
                          {{ field.text }}:
                        </span>
                        <v-input
                          v-if="field.required"
                          :value="field.value"
                          required
                          class="px-3 py-0"
                          :rules="[v => !!v || 'Campo obrigatório']"
                        />
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="px-2 py-0 d-flex align-center"
                    >
                      <v-chip
                        v-if="field.value"
                        close
                        class="ma-1"
                        color="primary"
                        small
                        @click:close="removeValue(fieldIdx)"
                      >
                        {{ field.value }}:
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="8"
                      class="px-2 py-0"
                    >
                      <v-chip
                        v-for="header in availableHeaders"
                        :key="header.value"
                        :disabled="!!field.value"
                        class="ma-1"
                        color="primary"
                        small
                        @click="selectHeader(fieldIdx, header.idx)"
                      >
                        {{ header.value }}
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
        <v-spacer
          class="pt-5"
        />
        <v-tabs
          v-model="selectedSheet"
        >
          <v-tab
            v-for="item in items"
            :key="item.sheet"
          >
            {{ item.sheet }}
          </v-tab>
          <v-tab-item
            v-for="item in items"
            :key="item.sheet"
          >
            <v-data-table
              :headers="localfields"
              :items="item.items"
              class="elevation-1"
              hide-default-footer
              disable-sort
              disable-pagination
              showModel
            >
            </v-data-table>
          </v-tab-item>
        </v-tabs>

        <template
          v-if="!hideModel"
        >
          <v-row
            class="justify-center align-center"
          >
            <v-btn
              color="primary"
              class="ma-6 white--text"
              @click="downloadModel"
            >
              Baixar Modelo
              <v-icon
                right
                dark
              >
                cloud_download
              </v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-card-text>

      <v-tooltip
        v-if="showBtnImport"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fixed
            fab
            large
            dark
            bottom
            right
            color="orange"
            class="mr-5"
            v-on="on"
            @click="importExcel()"
          >
            <v-icon class="material-icons-outlined">
              get_app
            </v-icon>
          </v-btn>
        </template>

        <span>Importar</span>
      </v-tooltip>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.upload-parent {
  position: relative;

  .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
    text-align: center;
    background: #fff;

    h3 {
      margin: -.5em 0 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 40px;
      color: #fff;
      padding: 0;
    }
  }
}

.middle {
  display: block;
  float: left;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.drop-field {
  width: 100%;
  box-sizing: border-box;
  border-color: rgb(207, 212, 219);
  border-style: dashed;
  border-width: 2px;
  border-radius: 3px;
  height: 211px;
  background: #efefef;

  .v-btn {
    background: #ffffff;

    label {
      cursor: pointer;
    }
  }
}
</style>

<script>
/**
 * VueUploadComponent
 * @docs https://lian-yue.github.io/vue-upload-component/#/en/documents
 */
import VueUploadComponent from 'vue-upload-component';
import XLSX from "xlsx-js-style";
import Ripple from 'vuetify/lib/directives/ripple';

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import { isArray, isObject } from "lodash";

export default {

  directives: { Ripple },

  components: {
    VueUploadComponent,
  },

  mixins: [ReportMixin],

  props: {
    value: {
      type: Boolean,
    },
    showModel: {
      type: Boolean,
    },
    title: {
      type: String,
      default: 'Importar Excel',
    },
    fields: {
      type: Array,
      default() {
        return [{ text: 'Exemplo', keys: ['Exemplo', 'Example', 'Sample'] }]
      }
    },
    sheetName: {
      type: String,
      default: 'Modelo Importação'
    }
  },

  data() {
    return {
      size: 1024 * 1024 * 10,

      uploader: null,
      postAction: null,

      files: [],

      extensions: 'csv,xlsx,xls',
      accept: '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

      sheet: [],
      headers: [],
      items: [],
      headersConfig: [],
      baseValue: 0,

      localfields: [],

      selectedSheet: null,

      panel: null,

      hideModel: false,
    };
  },
  computed: {
    //
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    showBtnImport() {
      return this.localfields.filter(field => !!field.value).length > 0
    },

    availableHeaders() {
      const props = Object.keys(this.headers);
      let sheet = props.pop();

      if (Object.keys(this.headers).length > 0) {
        return this.headers[sheet].filter(header => !header.selected);
      }

      return [];
    }
  },

  mounted() {
    this.localfields = this.fields.map(field => ({ ...field, value: null }));
    this.$nextTick(() => {
      // Ativa o dropzone
      this.uploader = this.$refs.upload;
    });
  },

  methods: {
    /**
     * Evento disparado pelo componente de upload
     */
    inputFile(newFile, oldFile) {
      const isNewFile = newFile && !oldFile;

      if (isNewFile) {
        const f = newFile.file;
        const reader = new FileReader();

        reader.onload = ({ target: { result } }) => {
          const workbook = XLSX.read(
            result,
            { type: 'binary', cellText: false, cellDates: true }
          );

          let data = [];

          workbook.SheetNames.forEach(function(sheet, key) {
            data[sheet] = XLSX.utils.sheet_to_json(
              workbook.Sheets[workbook.SheetNames[key]],
              { header: 1, raw: false, dateNF: 'yyyy-mm-dd' }
            );
          });

          this.parseSheet(data);
        };
        reader.readAsBinaryString(f);
      }
    },

    parseSheet(data) {

      let headers = [];
      let headerKeys = [];
      let items = [];

      for (let sheet in data) {
        if (data.hasOwnProperty(sheet)) {
          headers[sheet] = data[sheet].shift();
          headerKeys[sheet] = Object.keys(headers[sheet]);
          items = data[sheet]
            .filter(item => item.length > 0)
            .map(item => {
              return headerKeys[sheet].reduce((acc, cur) => {
                const key = headers[sheet][cur];
                acc[key] = item[cur];
                return acc;
              }, {});
            });

          this.headers[sheet] = Object.values(headers[sheet]).map((value, idx) => ({ value, idx, selected: false }));

          if (isObject(this.headers['BASE'])) {
            this.baseValue = this.headers['BASE'][0].value;
            delete this.headers['BASE'];
          }

          if (sheet !== 'BASE') {
            this.items = [
              ...this.items,
              {
                sheet,
                items
              }
            ];
          }
        }
      }

      this.resetSelection();
      this.autoSelection();
      this.checkAutoSelection();
    },

    resetSelection() {
      for (let fieldIdx in this.localfields) {
        this.localfields[fieldIdx].value = null;
      }
    },

    autoSelection() {

      this.hideModel = true;

      let sheetDefault = '';

      for (let sheet in this.headers) {
        sheetDefault = sheet;

        for (let fieldIdx in this.localfields) {
          const keys = this.localfields[fieldIdx].keys.map(key => key.toUpperCase());

          for (let headerIdx in this.headers[sheet]) {
            const header = this.headers[sheet][headerIdx];
            if (header.selected) {
              continue;
            }

            for (let key of keys) {
              if (header.value.toUpperCase().indexOf(key) !== -1) {
                this.selectHeader(fieldIdx, headerIdx);
              }
            }
          }
        }
      }

      this.headersConfig = this.headers[sheetDefault];

      return;
    },

    selectHeader(fieldIdx, headerIdx) {
      const props = Object.keys(this.headers);
      let sheet = props.pop();

      const { value } = this.headers[sheet][headerIdx];

      this.localfields[fieldIdx].value = value;
      this.headers[sheet][headerIdx].selected = true;
    },

    removeValue(fieldIdx) {
      const props = Object.keys(this.headers);
      let sheet = props.pop();

      const { value } = this.localfields[fieldIdx];
      const headerIdx = this.headers[sheet].findIndex(o => o.value == value);

      this.localfields[fieldIdx].value = null;
      this.headers[sheet][headerIdx].selected = false;
    },

    checkAutoSelection() {
      for (let sheet in this.headers) {
        if (this.headers[sheet].some(header => header.selected)) {
          this.panel = null;
        } else {
          this.panel = 0;
        }
      }
    },

    importExcel() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const data = this.items;

      let fields = this.localfields
        .map(({ text, value }) => ({ text, value }))
        .filter(field => field.value);

      if (this.baseValue) {
        fields = this.baseValue;
      }

      this.$emit('import', { data, fields });
      this.headers = [];
      this.items = [];
    },

    downloadModel() {
      const model = this.fields.reduce((acc, cur) => ({ ...acc, [cur.keys[0]]: cur.example || '' }), {});
      const title = 'Modelo Importação';
      const sheetName = this.sheetName;

      if (sheetName === 'Volume (Litros)') {

        const workbook = this.XLSX.utils.book_new();

        const dtsModelo = [model];

        const dtsBase = [['2,80']];

        const worksheetModelo = this.XLSX.utils.json_to_sheet(dtsModelo);
        const worksheetBase = this.XLSX.utils.aoa_to_sheet(dtsBase);

        this.XLSX.utils.book_append_sheet(workbook, worksheetModelo, sheetName);
        this.XLSX.utils.book_append_sheet(workbook, worksheetBase, 'BASE');

        this.XLSX.writeFile(workbook, `${title}.xlsx`);

        return;
      }

      this.exportToFile({ report: [ model ], title, sheetName});
    }
  },
};
</script>
