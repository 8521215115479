<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <!-- <v-row>
      <v-spacer />
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          :ranges="filter.ranges"
          dark
          @change="getCredits"
        />
      </v-col>
    </v-row> -->

    <v-card
      class="report-card"
      color="transparent"
      dark
    >
      <v-card-title>
        Créditos presumidos
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        dark
        dense
        disable-sort
        class="elevation-1 report-table"
      >
        <template #[`item.date`]="{ value }">
          {{ value | dateFormat('DD/MM/YYYY') }}
        </template>
        <template #[`item.value`]="{ value }">
          R$ {{ value | formatCurrency }}
        </template>
        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="remove(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <presumed-credit-dialog
      v-model="showCreditDialog"
      @newCredit="onNewCredit"
    />
    
    <confirm ref="confirm" />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue darken-1"
        @click="newCredit"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Novo crédito
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
//import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import PresumedCreditDialog from "@/Domains/Projects/PMLS/Components/PresumedCreditDialog.vue";
import Confirm from "@/Support/Components/Confirm.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";

moment.locale('pt'); 

export default {
  name: "pmls-credit",

  components: {
    //VDateRangePicker,
    PresumedCreditDialog,
    Confirm,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  mixins: [ReportMixin],

  props: ["parent"],

  data() {
    return {
      loading: false,

      filter: {
        ranges: {
          'Este ano': [moment().startOf("year").format("YYYY-MM-DD"), moment().endOf("year").format("YYYY-MM-DD")],
          'Ano anterior': [moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"), moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")],
        },

        range: [],
      },

      headers: [
        { text: 'Data de crédito', value: 'date' },
        { text: 'Descrição', value: 'description' },
        { text: 'Valor', value: 'value' },
        { text: '', value: 'action', width: 30 },
      ],

      items: [],

      showCreditDialog: false,
    };
  },

  created() {
    this.getCredits();
  },

  methods: {
    newCredit() {
      this.showCreditDialog = true;
    },
    onNewCredit() {
      this.getCredits();
    },
    async getCredits() {
      const [startDate, endDate] = this.filter.range;
      
      const {data} = await this.$axios.post(`/projetos/listaCreditos`, {
        data_inicio: startDate,
        data_fim: endDate
      });

      this.items = data.map(p => ({
        id: p.id_credito,
        date: p.data_credito,
        description: p.descricao,
        value: p.valor,
      }))
    },
    async remove({id}) {
      if (!(await this.$refs.confirm.open('Remover registro', 'Tem certeza que deseja remover este crédito?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/projetos/inativaCredito`,
          {
            id_credito: id
          }
        );
        
        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.getCredits();
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch(error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      let data = this.XLSX.utils.json_to_sheet(this.items.map(item => ({
          'Data de crédito': this.$options.filters.dateFormat(item.date, 'DD/MM/YYYY'),
          Descrição: item.description,
          Valor: `R$ ${this.$options.filters.formatCurrency(item.value)}`,
      })), {origin: 'A3'});

      data["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
      
      data['A1'] = { v: 'Créditos presumidos' };

      data['!cols'] = [
        { wch: 16 },
        { wch: 30 },
        { wch: 14 },
      ];

      const workbook = this.XLSX.utils.book_new();

      const filename = "pmls_creditos";
      
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /**
     * Imprime o relatório
     */
    print() {
      return this.printFromJson(this.items.map(item => ({
          'Data de crédito': this.$options.filters.dateFormat(item.date, 'DD/MM/YYYY'),
          Descrição: item.description,
          Valor: `R$ ${this.$options.filters.formatCurrency(item.value)}`,
      })), "Créditos presumidos");
    },
  },
};
</script>