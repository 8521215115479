<template>
  <div>
    <v-select
      v-model="selected"
      :items="[
        { text: 'Este mês', value: 'mensal' },
        { text: 'Bimestral', value: 'bimestral' },
        { text: 'Trimestral', value: 'trimestral' },
        { text: 'Quadrimestral', value: 'quadrimestral' },
        { text: 'Semestral', value: 'semestral' },
        { text: 'Este Ano', value: 'anual' },
        { text: 'Definir data', value: 'customRange' },
      ]"
      prepend-inner-icon="today"
      dark
      :label="label"
      hide-details
      filled
      clearable
      @click:clear="clear"
      @change="onDateFilterSelect"
    >
      <template v-slot:item="{ item }">
        <template v-if="selected === 'customRange' && item.value === 'customRange'">
          <span class="grow"> {{ startDate }} — {{ endDate }} </span>
          <v-btn
            icon
            light
            @click.stop="clear"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>

        <template v-else>
          {{ item.text }}
        </template>
      </template>

      <template v-slot:selection="{ item }">
        <span v-if="selected === 'customRange' && hasDateRange"> {{ startDate }} — {{ endDate }} </span>

        <span v-else>{{ item.text }}</span>
      </template>
    </v-select>

    <v-dialog
      :value="showSelectDate"
      persistent
      width="290"
    >
      <v-date-picker
        v-model="dateRange"
        :min="firstSelectedDate"
        :max="today"
        range
        reactive
        @change="onCustomDateRangeSelect"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  props: {
    value: {
      type: String,
    },

    label: {
      type: String,
      default: 'Data',
    }
  },

  data() {
    return {
      // Dialogs
      showSelectDate: false,

      // Models
      selected: null,
      dateRange: [],
    };
  },

  computed: {
    startDate() {
      if (!_.first(this.dateRange)) {
        return;
      }

      return moment(_.first(this.dateRange)).format("DD/MM/YYYY");
    },

    endDate() {
      if (!_.last(this.dateRange)) {
        return;
      }

      return moment(_.last(this.dateRange)).format("DD/MM/YYYY");
    },

    firstSelectedDate() {
      return _.first(this.dateRange);
    },

    hasDateRange() {
      return this.dateRange.length > 1;
    },

    today() {
      return moment().format("YYYY-MM-DD");
    },

    mensal() {
      const firstMonthDay = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    bimestral() {
      const firstMonthDay = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    trimestral() {
      const firstMonthDay = moment()
        .subtract(2, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    quadrimestral() {
      const firstMonthDay = moment()
        .subtract(3, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    semestral() {
      const firstMonthDay = moment()
        .subtract(5, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    anual() {
      const firstMonthDay = moment()
        .startOf("year")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .endOf("year")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    parsedDateRange() {
      switch (this.selected) {
        case "today":
          return [this.today, this.today];
        case "mensal":
          return this.mensal;
        case "bimestral":
          return this.bimestral;
        case "trimestral":
          return this.trimestral;
        case "quadrimestral":
          return this.quadrimestral;
        case "semestral":
          return this.semestral;
        case "anual":
          return this.anual;
        case "customRange":
          return this.dateRange;
      }

      return [];
    },
  },

  mounted() {
    // Atualiza o model local com o do componente pai
    this.selected = this.value;
  },

  methods: {
    onDateFilterSelect(event) {
      // Atualiza o v-model com o input selecionado
      this.$emit("input", event);

      // Somente exibir o modal caso seja selecionado o range customizado no select e caso não exista range selecionado
      if (this.selected === "customRange" && _.isEmpty(this.dateRange)) {
        this.showSelectDate = true;

        return;
      }

      // Limpa o range customizado quando o model é limpo
      if (_.isEmpty(this.selected)) {
        this.dateRange = [];
      }

      return this.$emit("change", this.parsedDateRange);
    },

    onCustomDateRangeSelect() {
      this.showSelectDate = false;

      return this.$emit("change", this.parsedDateRange);
    },

    clear() {
      this.showSelectDate = false;
      this.selected = null;
      this.dateRange = [];
    },
  },
};
</script>
