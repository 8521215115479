<template>
  <div class="mx-5">
    <v-flex>
      <v-tabs
        v-model="tab"
        vertical
        dark
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#tab-coleta">
          Coleta
        </v-tab>
        <v-tab href="#tab-visita">
          Visita
        </v-tab>
        <v-tab href="#tab-comercial">
          Comercial
        </v-tab>
        <v-tab href="#tab-produtor">
          Produtor
        </v-tab>
        <v-tab href="#tab-geral">
          Geral
        </v-tab>

        <v-tab-item value="tab-coleta">
          <collect-app-settings-tab />
        </v-tab-item>
        <v-tab-item value="tab-visita">
          <visit-app-settings />
        </v-tab-item>
        <v-tab-item value="tab-comercial">
          <comercial-app-settings />
        </v-tab-item>
        <v-tab-item value="tab-produtor">
          <producer-app-settings />
        </v-tab-item>
        <v-tab-item value="tab-geral">
          <general-app-settings />
        </v-tab-item>
      </v-tabs>
    </v-flex>
  </div>
</template>

<script>
import GeneralAppSettings from "@/Domains/Settings/Components/GeneralAppSettings.vue";
import CollectAppSettingsTab from "@/Domains/Settings/Components/CollectAppSettingsTab.vue";
import VisitAppSettings from "@/Domains/Settings/Components/VisitAppSettings.vue";
import ComercialAppSettings from "@/Domains/Settings/Components/ComercialAppSettings.vue";
import ProducerAppSettings from "@/Domains/Settings/Components/ProducerAppSettings.vue";

export default {

  components: {
    GeneralAppSettings,
    CollectAppSettingsTab,
    ProducerAppSettings,
    ComercialAppSettings,
    VisitAppSettings,
  },

  data() {
    return {
      tab: "tab-geral",
    };
  },
};
</script>
