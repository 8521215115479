<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Cadastro de Atestado de Conformidade
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <template>
      <div class="pt-0">
        <v-row>
          <v-col cols="12">
            <v-card
              dark
              color="transparent"
            >
              <v-card-title>
                <v-spacer />
                <v-col
                  cols="4"
                  md-4
                  sm-4
                  class="pa-0"
                >
                  <v-text-field
                    v-model="filter.search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                    dark
                    class="pt-0 my-2"
                  />
                </v-col>
              </v-card-title>
              <data-table
                ref="report"
                :headers="headers"
                :items="search(items)"
                :search="filter.search"
                show-custom-group
                dark
                @click:row="edit"
              >
                <template #[`item.action`]="{ item }">
                  <v-menu
                    bottom
                    right
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                      >
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="remove(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="edit(item)">
                        <v-list-item-icon>
                          <v-icon>edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template #activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            color="blue"
            @click="add()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  add
                </v-icon>
              </template>
              Novo
            </v-tooltip>
          </v-btn>
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  print
                </v-icon>
              </template>

              Imprimir
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
        <add-certificate-dialog
          v-model="showDialog"
          :edit-id="editId"
          :vaccinesTypes="vaccinesTypes"
          @save="loadVaccines"
        />
      </div>
    </template>

  </div>
</template>

<script>
import AddCertificateDialog from "@/Domains/Projects/HealthExamination/Components/AddCertificateDialog.vue";

import moment from "moment/moment";

export default {

  components: {
    AddCertificateDialog,
  },


  data() {
    return {

      sanity: 'VACINAS',

      showDialog: false,

      vaccinesTypes: [],

      editId: null,

      filter: {
        search: '',
      },

      headers: [
        { text: 'Produtor', value: 'producer.name', align: 'start' },
        { text: 'Tipo', value: 'type', align: 'start' },
        { text: 'Data', value: 'date', align: 'center' },
        { text: 'Quant. de Animais', value: 'quantity', align: 'center' },
        { text: 'Veterinário', value: 'veterinarian.name', align: 'start' },
        { text: 'Opções', value: 'action', width: 30, align: 'center' }
      ],

      items: [],
    };
  },

  async mounted() {
    await this.getVaccinesType();
    await this.loadVaccines();
  },

  methods: {
    async loadVaccines() {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.get(`/projects/health-exam-certificate`);

        this.items = data.map(item => {

          const vaccineType = this.vaccinesTypes
            && Array.isArray(this.vaccinesTypes)
            && this.vaccinesTypes.find(vaccine => vaccine['tipo-da-vacina'] === item.tipo);

          return {
            id: item.id,
            producer: item.producer ? {
              id: item.producer.id_pessoa,
              name: item.producer.nome,
            } : null,
            veterinarian: item.veterinarian ? {
              id: item.veterinarian.id_pessoa,
              name: item.veterinarian.nome,
            } : null,
            type: vaccineType && vaccineType['tipo-da-vacina'] ? vaccineType['tipo-da-vacina'] : ' - ',
            date: this.formatDate(item.data, 'DD/MM/YYYY'),
            quantity: item.quantidade_animais,
          };
        });
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.editId = null;
      this.showDialog = true;
    },

    edit(item) {
      this.editId = item.id;

      this.showDialog = true;
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/projects/health-exam-certificate/${id}`);

        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        await this.loadVaccines();
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

    async getVaccinesType() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/product', { params: {
            grupo: 'VACINAS',
          }
        });

        this.vaccinesTypes = data.map(item => {

          var fieldsMap = [];

          for (const key in item.campos) {
            fieldsMap[key] = item.campos[key];
          }

          return {
            id: item.id_produto,
            group: item.id_grupo_produto,
            name: item.nome,
            ... fieldsMap
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    print() {
      this.$refs.report.print(this.$refs.report.getReportJson().map(({options, ...rest}) => rest), 'Vacinas');
    },

    exportExcel() {
      this.$refs.report.exportExcel(this.$refs.report.getReportJson().map(({options, ...rest}) => rest), 'Vacinas');
    },

    search(data) {
      return this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
    }
  },
};
</script>
