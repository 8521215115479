<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.project"
            :items="paeList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRange"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="filter.project.protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <month-range-picker
            v-model="filter.range"
            :ranges.sync="filter.ranges"
            :min="filter.min"
            :max="filter.max"
            dark
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <person-autocomplete-filter
            v-model="filter.technician"
            type="TECHNICAL"
            label="Técnico"
            dark
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <routes-autocomplete-filter
            v-model="filter.routes"
            label="Rota"
            dark
            multiple
            hide-details
          />
        </v-col>
      </v-row>

      <visits-graphic
        v-for="q in visits"
        :key="q.nome+q.periodo"
        :goal="q"
        :loading="loading"
      />
    </v-flex>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import VisitsGraphic from "@/Domains/Projects/Visits/VisitsGraphic.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "visits-report",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    VisitsGraphic,
    MonthRangePicker,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {

      loading: false,

      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [moment().format('YYYY-MM'),  moment().format('YYYY-MM')],

        min: moment().subtract(5, 'years').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        technician: {
          id: "",
          description: ""
        },
        routes: [],

      },

      paeList: [],
      visits: [],

    };
  },

  watch: {
    filter: {
      handler() {
        this.loadVisits();
      },
      deep: true
    }

  },

  created() {
    this.loadGoals();
  },

  methods: {

    async loadRange() {

      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.max = moment(this.filter.project.dateEnd).format('YYYY-MM');

      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), moment().format('YYYY-MM')];

      this.loadVisits();

    },

    async loadVisits() {
      try {
        this.loading = true;
        let visits = this.filter.project.visits ;

        let { data } = await this.$axios.post(
          `/relatorioIn/loadVisitsGoals`,
          {
            meta: visits,
            data_inicio: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            id_tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
          }
        );
        this.visits = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    async loadGoals() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL",
          }
        );

        this.paeList = data.map((proj) => {
          return {
            visits: proj.visita ? JSON.parse(proj.visita) : [],
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            protocol: proj.protocolo,
          }
        });

        if (!_.isEmpty(this.paeList )) {
          this.filter.project = this.paeList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
