<template>
  <div>
    <data-table
      ref="report"
      dense
      :headers="[
        { text: 'Questão', value: 'label', sortable: false, show: true, deafult: true },
        ...headers
      ]"
      hide-default-footer
      :items="questions"
      dark
      disable-pagination
    >
      <template #[`body.append`]>
        <tr>
          <td />
          <td class="text-start">
            Total
          </td>
          <td
            v-for="t in totalAnswered"
            :key="t.date"
            class="text-start"
          >
            <h2>{{ t.date }}</h2>
            <p
              v-for="(q, index) in t.count_by_answers"
              :key="index"
            >
              {{ index }}: {{ q }} ({{ pecentByAnswers(t.total_answers, q) }} %)
            </p>
          </td>
        </tr>
      </template>
    </data-table>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
      <!-- <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Exportar
        </v-tooltip>
      </v-btn> -->
    </v-speed-dial>
  </div>
</template>

<script>
import * as _ from "lodash";
import XLSX from "xlsx-js-style";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {

  mixins: [ReportMixin],

  props: {
    questions: {
      default: () => [],
    },
    totalAnswered: {
      default: () => [],
    },
  },

  data() {
    return {
      headers: [],
    };
  },
  computed: {

  },

  watch: {
    questions() {
      this.mountedHeader();

    }

  },
  mounted() {
    this.mountedHeader();
  },
  methods: {

    /**
     * Monta o header da table
     * @returns {Array}
     */
    mountedHeader() {
      if (_.isEmpty(this.questions)) {
        return [{}];
      }

      let headers = _.map(this.questions[0].date, (value, key ) => {
        return {
          text: value,
          value: `answered_questions[${key}].resposta`,
          width: 150,
          sortable: true,
          show: true,
          default: true,
        };
      });

      this.headers = headers;
    },

    pecentByAnswers( total_answers, quantity) {

      const percent = (quantity * 100) / total_answers;

      return this.formatNumber(percent || 0);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),

    getReportTitle() {
      return `Checklists produtor `;
    },

    getReportJson() {
      return this.filteredItems.map(item => ({
        Código: item.code,
        Produtor: item.producer,
        Amostras: item.total,
        Valor: `R$ ${this.formatCurrency(item.amount)}`,
      }));
    },

    // async print() {
    //   await this.printFromJson(this.getReportJson(), this.getReportTitle());
    // },

    print() {

      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {

      let codigo_laticinio = '';

      let data = this.questions.map((item) => {

        codigo_laticinio = item.answered_questions[0].codigo_laticinio;

        const dates = item.answered_questions.reduce((acc, current) => {
          return { ...acc, [`${current.data_hora_registro}`]: `${current.resposta}` };
        }, {});

        return {
          Questão: item.label,
          ...dates,
        };
      });

      data = XLSX.utils.json_to_sheet(data);

      const workbook = XLSX.utils.book_new();

      const filename = `respostas_produtor_${codigo_laticinio}`;

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
  },
};
</script>
