<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Naturezas de Análises
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-select
                  v-model="filters.type"
                  :items="types"
                  label="Natureza"
                  clearable
                  hide-details
                  dark
                  filled
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            dark
            @click:row="edit"
          >
            <template #[`item.type`]="{ value }">
              <v-chip
                x-small
              >
                {{ getType(value) }}
              </v-chip>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template
                  #activator="{ on }"
                >
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template
        #activator="{ on }"
      >
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          v-on="on"
          @click="add()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Novo</span>
    </v-tooltip>

    <analysis-type-dialog
      v-model="dialog.show"
      :edit-id="dialog.id"
      @save="load"
    />
  </v-container>
</template>

<script>
import AnalysisTypeDialog from '@/Domains/Registrations/Analysis/Type/Components/AnalysisTypeDialog.vue'

export default {

  components: {
    AnalysisTypeDialog,
  },

  data() {
    return {
      types: [
        { value: 'FISICO-QUMICA', text: 'Físico-química' },
        { value: 'MICROBIOLOGICA', text: 'Microbiológica' },
      ],

      filters: {
        type: null,
        search: '',
      },

      headers: [
        { text: 'Descrição', value: 'description' },
        { text: 'Natureza', value: 'type' },
        { text: '', value: 'action', width: 30 }
      ],

      items: [],

      dialog: {
        show: false,
        id: null,
      },
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.type && !this.filters.search) {
        return this.items;
      }

      const type = this.filters.type;
      const search = String(this.filters.search).toUpperCase().trim();

      return this.items.filter(item => {
        const hasType = !type || item.type === type;
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasType && hasSearch;
      });
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        this.items = [];

        const { data } = await this.$axios.get(`/registrations/analysis/type`);

        this.items = data.map(item => ({
          id: item.id_tipo_analise,
          description: item.descricao,
          type: item.natureza,
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.dialog.id = null;
      this.dialog.show = true;
    },

    edit(item) {
      this.dialog.id = item.id;
      this.dialog.show = true;
    },

    async remove(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.delete(`/registrations/analysis/type/${id}`);

        this.load();
        this.$snotify.success('Item excluído com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getType(value) {
      const types = {
        'FISICO-QUMICA': 'Físico-química',
        'MICROBIOLOGICA': 'Microbiológica',
      }
      return types[value] || '-'
    }
  },
};
</script>
