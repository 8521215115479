var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0",attrs:{"text-xs-center":"","grid-list-lg":""}},[_c('v-card',{staticClass:"v-tamanho route-list",attrs:{"dark":"","color":"transparent"}},[_c('v-card-title',[_c('v-col',{staticClass:"pa-0",attrs:{"md-3":""}},[_c('v-date-range-picker',{on:{"change":_vm.loadRoutes},model:{value:(_vm.filters.dateRange),callback:function ($$v) {_vm.$set(_vm.filters, "dateRange", $$v)},expression:"filters.dateRange"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md-6":"","offset-md":"3"}},[_c('v-text-field',{attrs:{"label":"Pesquisar","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 lista-rotas-table",attrs:{"headers":[
        { align: 'start', text: 'Descrição', value: 'description' },
        { text: 'Tempo Previsto', value: 'expectedTime' },
        { text: 'Tempo Realizado', value: 'realizedTime' },
        { text: 'Distância Prevista', value: 'expectedDistance' },
        { text: 'Distância Realizada', value: 'realizedDistance' },
      ],"items":_vm.filteredRoutes,"loading":_vm.onLoading},scopedSlots:_vm._u([{key:"item.expectedTime",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("duration")(item.expectedTime))+" ")]}},{key:"item.realizedTime",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("duration")(item.realizedTime))+" ")]}}])})],1),_c('v-overlay',{attrs:{"value":_vm.onLoading}},[_c('v-card-text',[_vm._v(" Carregando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }