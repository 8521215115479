<template>
  <div>
    <v-col
      cols="12"
      class="px-0"
    >
      <card-title-settings>
        Parâmetros de Produtor
      </card-title-settings>
    </v-col>
    <v-card
      dark
      outlined
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="3"
          >
            <v-select
              v-model="producerLoginRequiresPassword"
              :items="booleanRules"
              label="Requer senha para acessar app"
              dense
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-select
              v-model="isChecklistEnabled"
              :items="booleanRules"
              label="Checklist"
              dense
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-select
              v-model="isExtractEnabled"
              :items="booleanRules"
              label="Extrato"
              dense
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-select
              v-model="isNotificationEnabled"
              :items="booleanRules"
              label="Notificação"
              dense
            />
          </v-col>
          <v-col
            cols="3"
          >
            <v-select
              v-model="isDocumentEnabled"
              :items="booleanRules"
              label="Documento"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="saveQualitySettings();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações do Aplicativo</span>
    </v-tooltip>
  </div>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

import _ from "lodash";

export default {
  components: {
    CardTitleSettings
  },
 
  data() {
    return {
      producerLoginRequiresPassword: false,
      isChecklistEnabled: false,
      isExtractEnabled: false,
      isNotificationEnabled: false,
      isDocumentEnabled: false,

      booleanRules: [
        { text: 'Ativo', value: true },
        { text: 'Inativo', value: false },
      ],
    };
  },

  mounted() {
    this.loadQualitySettings();
  },

  methods: {
    /**
     * carregar configuracoes do APP
     */
    async loadQualitySettings() {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.post(`/configuracao/listaConfiguracoesLaticinioApp`);

        if (_.isEmpty(data)) {
          return;
        }

        this.handleData(data);

      } catch (e) {
        console.error(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    handleData(data) {
      this.producerLoginRequiresPassword = !!data.login_produtor_requer_senha;
      this.isChecklistEnabled = !!data.exibir_checklist;
      this.isExtractEnabled = !!data.exibir_extrato;
      this.isNotificationEnabled = !!data.exibir_notificacao;
      this.isDocumentEnabled = !!data.exibir_documento;
    },

    async saveQualitySettings() {
      try {
        this.$root.$progressBar.saving();

        const payLoad = {
          login_produtor_requer_senha: !!this.producerLoginRequiresPassword,
          exibir_checklist: !!this.isChecklistEnabled,
          exibir_extrato: !!this.isExtractEnabled,
          exibir_notificacao: !!this.isNotificationEnabled,
          exibir_documento: !!this.isDocumentEnabled,
        };
        
        const { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoLaticinioApp`,
          payLoad
        );

        if (!_.get(data, 'codigo')) {
          throw new Error(data);
        }

        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.warn(error);
      } finally {
        this.$store.dispatch('updateStoreSetting');
        this.$root.$progressBar.hide();
      }
    },
  },
};
</script>