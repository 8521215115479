<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
          offset-md="2"
        >
          <v-menu
            v-model="dateAtMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="qualityValidityStart | dateFormat('MM/YYYY')"
                label="Mês de início"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="qualityValidityStart"
              :max="qualityValidityEnd"
              type="month"
              no-title
              scrollable
              @input="dateAtMenu = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateAtMenuEnd"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="qualityValidityEnd | dateFormat('MM/YYYY')"
                label="Mês de fim"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="qualityValidityEnd"
              :min="qualityValidityStart"
              type="month"
              no-title
              scrollable
              @input="dateAtMenuEnd = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="9"
          offset-md="2"
        >
          <v-autocomplete
            v-model="dairySelected"
            outlined
            chips
            small-chips
            dark
            filled
            multiple
            hide-details
            label="Unidades "
            :items="ListDairy"
            :loading="loading"
            item-text="nome"
            item-value="id_pessoa"
          >
            <template #selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                color="primary"
                @click="data.select"
                @click:close="removeChipDairy(data.item)"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>
                {{ data.item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="9"
          offset-md="2"
        >
          <v-text-field
            v-model="obs"
            label="Observações"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-flex>
            <v-tabs
              v-model="tab"
              vertical
              dark
              background-color="transparent"
              class="transparent"
            >
              <v-tab href="#aba-qualidade">
                Qualidade
              </v-tab>
              <v-tab href="#aba-emergencial">
                Emergenciais
              </v-tab>
              <v-tab href="#aba-visits">
                Visitas
              </v-tab>
              <v-tab-item value="aba-qualidade">
                <quality-actions
                  v-model="qualityGoals"
                />
              </v-tab-item>
              <v-tab-item value="aba-emergencial">
                <emergency-actions v-model="emergencyGoals" />
              </v-tab-item>
              <v-tab-item value="aba-visits">
                <visits-actions
                  v-model="visitsGoals"
                />
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-col>
      </v-row>
    </v-form>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click.stop="save"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar configuração
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import QualityActions from "@/Domains/Projects/PQFL/PAE/Components/QualityActions.vue";
import EmergencyActions from "@/Domains/Projects/PQFL/PAE/Components/EmergencyActions.vue";
import VisitsActions from "@/Domains/Projects/PQFL/PAE/Components/VisitsActions.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    QualityActions,
    EmergencyActions,
    VisitsActions,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },

  props: {
    value: String
  },

  data() {
    return {
      id: null,

      tab: "aba-qualidade",

      emergencyGoals: {},

      qualityGoals: [],

      visitsGoals: [],

      loading: false,

      qualityValidityStart: null,

      qualityValidityEnd: null,

      protocol: '',

      obs: '',

      ListDairy: [],

      dairySelected: [],

      // Date Picker
      dateAtMenu: false,

      dateAtMenuEnd: false,

      questionnairesGoals: [],

    };
  },

  watch: {
    value() {
      this.id = this.value;
      this.load();
    }
  },
  created() {
    this.id = this.value;
    this.load();
    this.getDairies();
  },

  methods: {
    async getDairies() {
      if (this.ListDairy.length > 0) {
        return this.ListDairy;
      }
      const { data } = await this.$axios.post(`/pessoa/listaLaticinioJson`);
      this.ListDairy = data;
    },
    removeChipDairy(lat) {
      this.dairySelected = this.dairySelected.filter((dairyId) => dairyId !== lat.id_pessoa);
    },

    async load() {
      if (this.id) {
        this.loadPlanning();
      }
      else {
        this.setState();
      }
    },

    async loadPlanning() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/recomendacoesQualidade/detalhe`, { id: this.id });

        if (!_.isObject(data)) {
          throw data;
        }

        this.setState(data);

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas !", "Atenção");
        console.warn(err)
      } finally {
        this.loading = false;
      }
    },

    setState(data = null) {

      /**
       * Metas de qualidade pae
       */
      this.qualityGoals = [];
      this.visitsGoals = [];
      this.qualityValidityStart = null;
      this.qualityValidityEnd = null;
      this.emergencyGoals = {
        CRITICAL: { status: 'Produtores Críticos CPP', visits: null, questionnaires: [], type: 'PERCENT' },
        PRIORITY: { status: 'Produtores Prioritários CPP', visits: null, questionnaires: [], type: 'UN', campo_media: 'cbt_geometrica' },
        PRIORITYCCS: { status: 'Produtores Prioritários CCS', visits: null, questionnaires: [], type: 'UN', campo_media: 'ccs_geometrica' },
      };

      if (!_.isEmpty(data)) {
        //meta da aba de questionario - traz somente para nao sobreescrever com null na hora de salvar
        this.questionnairesGoals = !_.isEmpty(data.questionario) ? data.questionario : [];

        if ( !_.isEmpty(data.qualidade)) {

          this.qualityGoals = data.qualidade.map(goal => ({
            indicator: goal.indicador,
            action: goal.acao,
            percent: goal.percentual,
            media: goal.media,
            type: !_.isEmpty(goal.tipo) ? goal.tipo : 'PERCENT',
          }));

        }
        if (!_.isEmpty(data.visita) ) {
          this.visitsGoals  = data.visita.map(goal => ({
            id: goal.id_pessoa,
            text: goal.nome,
            value: goal.tipo_visita,
            visits: goal.visitas,
            type: !_.isEmpty(goal.tipo) ? goal.tipo : 'PERCENT',
            period: goal.periodo,
            questionnaires: goal.questionarios,
          }));
        }

        this.qualityValidityStart = !_.isEmpty(data.data) ? moment(data.data).format("YYYY-MM") : moment().format("YYYY-MM");
        this.qualityValidityEnd = !_.isEmpty(data.data_fim) ? moment(data.data_fim).format("YYYY-MM") : moment().format("YYYY-MM");
        this.protocol = data.protocolo;
        this.obs = data.descricao;
        this.dairySelected = data.laticinios;

        if (data && !_.isEmpty(data.emergencial) ) {
          const { CRITICO, PRIORITARIO,  PRIORITARIOCCS } = data.emergencial;

          this.emergencyGoals = {
            CRITICAL: { status: 'Produtores Críticos CPP', visits: CRITICO.visitas, questionnaires: CRITICO.questionarios, type: !_.isEmpty(CRITICO.tipo_criticos) ? CRITICO.tipo : "PERCENT" },
            PRIORITY: { status: 'Produtores Prioritários CPP', visits: PRIORITARIO.visitas, questionnaires: PRIORITARIO.questionarios, type: !_.isEmpty(PRIORITARIO.tipo) ? PRIORITARIO.tipo : "UN",  field: !_.isEmpty(PRIORITARIO.campo_media) ? PRIORITARIO.campo_media : "cbt_geometrica" },
            PRIORITYCCS: { status: 'Produtores Prioritários CCS', visits: PRIORITARIOCCS.visitas, questionnaires: PRIORITARIOCCS.questionarios, type: !_.isEmpty(PRIORITARIOCCS.tipo) ? PRIORITARIOCCS.tipo : "UN",  field: !_.isEmpty(PRIORITARIOCCS.campo_media) ? PRIORITARIOCCS.campo_media : "ccs_geometrica_mes" },
          };
        }
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        this.$snotify.warning("Campos inválidos", "Atenção");
        return false;
      }
      if (_.isEmpty(this.qualityValidityStart) || _.isEmpty(this.qualityValidityEnd)) {
        this.$snotify.warning("Mêses de inicio e fim obrigatorios", "Atenção");
        return false;
      }
      try {
        this.loading = true;

        const { CRITICAL, PRIORITY, PRIORITYCCS } = this.emergencyGoals;

        const quantity = this.qualityGoals.map(goal => ({
          indicador: goal.indicator,
          acao: goal.action,
          percentual: goal.percent,
          tipo: goal.type,
          media: goal.media,
        }));

        const emergency = {
          CRITICO: { visitas: parseFloat(CRITICAL.visits), questionarios: CRITICAL.questionnaires, tipo: CRITICAL.type },
          PRIORITARIO: { visitas: parseFloat(PRIORITY.visits), questionarios: PRIORITY.questionnaires, tipo: PRIORITY.type, campo_media: PRIORITY.field  },
          PRIORITARIOCCS: { visitas: parseFloat(PRIORITYCCS.visits), questionarios: PRIORITYCCS.questionnaires, tipo: PRIORITYCCS.type, campo_media: PRIORITYCCS.field   },
        };

        const visits = this.visitsGoals.map(goal => ({
          id_pessoa: goal.id,
          nome: goal.text,
          tipo_visita: goal.value,
          visitas: goal.visits,
          tipo: goal.type,
          periodo: goal.period,
          questionarios: goal.questionnaires,
        }));

        const payload = {
          id_assistencia: this.id,
          data: moment(this.qualityValidityStart).startOf("month").format('YYYY-MM-DD'),
          data_fim: moment(this.qualityValidityEnd).endOf("month").format('YYYY-MM-DD'),
          qualidade: quantity,
          emergencial: emergency,
          visita: visits,
          protocolo: this.protocol,
          descricao: this.obs,
          laticinios: this.dairySelected,
          tipo: "PQFL",
          questionario: this.questionnairesGoals,
        };

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/salvaMetas`,
          payload
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.id = data.id;
        this.$emit('input', data.id);
        this.loadPlanning();
        this.$snotify.success("Planejamento salvo com sucesso", "Sucesso");

      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao salvar planejamento!", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
