<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="filter.project"
            :items="paeList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRange"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="filter.project.protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <month-range-picker
            v-model="filter.range"
            :ranges.sync="filter.ranges"
            :min="filter.min"
            :max="filter.max"
            dark
          />
        </v-col>
      </v-row>
     
      <average-graphic
        v-for="q in quality"
        :key="q.indicador+q.acao"
        :goal="q"
        :filter="filter"
      />
    </v-flex>
  </div>
</template>

<script>
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import AverageGraphic from "@/Domains/Projects/PQFL/PAE/Components/AverageGraphic.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "quality-report",

  components: {
    MonthRangePicker,
    AverageGraphic,
  },
 
  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {

      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),
        
        search: '',
        
      },

      paeList: [],
      quality: [],

    };
  },
  
  computed: {
    ccsGraph() {
      if (this.ccsVisible) {
        return this.generateGraphOptions(this.ccs, 'Média Geométrica CCS * (1000)');
      } else {
        return [];
      }
    },
    cppGraph() {
      if (this.cppVisible) {
        return this.generateGraphOptions(this.cpp, 'Média Geométrica CPP * (1000)');
      } else {
        return [];
      }
    },
    gorduraGraph() {
      if (this.gorduraVisible) {
        return this.generateGraphOptions(this.gordura, 'Média Aritmética de Gordura');
      } else {
        return [];
      }
    },
    proteinaGraph() {
      if (this.proteinaVisible) {
        return this.generateGraphOptions(this.proteina, 'Média Aritmética de Proteína');
      } else {
        return [];
      }
    },
    caseinaGraph() {
      if (this.caseinaVisible) {
        return this.generateGraphOptions(this.caseina, 'Média Aritmética de Caseína');
      } else {
        return [];
      }
    },
  },

  created() {
    this.loadPae();
  },

  methods: {

    async loadRange() {
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.max = moment(this.filter.project.dateEnd).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), moment().format('YYYY-MM')];
      
      this.quality = (this.quality.lenght > 0) ? this.quality : this.filter.project.quality ;

    },

    async loadPae() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL"
          }
        );

        this.paeList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            quality: proj.qualidade ? JSON.parse(proj.qualidade) : [],
            protocol: proj.protocolo,
          }
        });
        
        if (!_.isEmpty(this.paeList )) {
          this.filter.project = this.paeList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>