<template>
  <div>
    <v-flex
      xs12
      class="text-xs-center"
    >
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-tabs
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab
        v-if="hasPeriodicInsert"
        href="#aba-analises-pendentes"
      >
        Analises Pendentes
      </v-tab>
      <v-tab
        v-if="hasPeriodicProcessed"
        href="#aba-relatorio"
      >
        Analises Realizadas
      </v-tab>
      <v-tab
        v-if="hasPeriodicDraw"
        href="#aba-sorteio"
      >
        Sorteio
      </v-tab>
      <v-tab
        v-if="hasPeriodicSetting"
        href="#aba-configuracao"
      >
        <v-icon>settings</v-icon>
      </v-tab>
      <v-tab-item
        value="aba-analises-pendentes"
      >
        <analysis-pending />
      </v-tab-item>
      <v-tab-item
        value="aba-relatorio"
      >
        <analysis-processed />
      </v-tab-item>
      <v-tab-item
        value="aba-sorteio"
      >
        <sort-producer />
      </v-tab-item>
      <v-tab-item
        value="aba-configuracao"
      >
        <sort-settings />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import _ from "lodash";
import AnalysisPending from "@/Domains/Analysis/Periodic/Components/AnalysisPending.vue";
import AnalysisProcessed from "@/Domains/Analysis/Periodic/Components/AnalysisProcessed.vue";
import SortProducer from "@/Domains/Analysis/Periodic/Components/SortProducer.vue";
import SortSettings from "@/Domains/Settings/Views/SortSettings.vue";

export default {
  components: {
    AnalysisPending,
    AnalysisProcessed,
    SortProducer,
    SortSettings,
  },

  data() {
    return {
      hasPeriodicDraw: false,

      hasPeriodicSetting: false,

      hasPeriodicInsert: false,

      hasPeriodicProcessed: false,
    };
  },
  
  mounted() {
    this.loadResourcesUser();
  },

  methods: {

    /**
     * carrega os recursos que usuario tem permissao para acessar
     */
    loadResourcesUser() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      const hasPeriodicDraw = recursosUsuario.find(recurso => recurso.recurso === "analysis-periodic-draw" && recurso.tipo == "COMPONENTE");
            
      if (_.isEmpty(hasPeriodicDraw)) {
        this.hasPeriodicDraw = false;
      } else {
        this.hasPeriodicDraw = true;
      }

      const hasPeriodicSetting = recursosUsuario.find(recurso => recurso.recurso === "analysis-periodic-setting" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(hasPeriodicSetting)) {
        this.hasPeriodicSetting = false;
      } else {
        this.hasPeriodicSetting = true;
      }

      const hasPeriodicInsert = recursosUsuario.find(recurso => recurso.recurso === "analysis-periodic-insert" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(hasPeriodicInsert)) {
        this.hasPeriodicInsert = false;
      } else {
        this.hasPeriodicInsert = true;
      }

      const hasPeriodicProcessed = recursosUsuario.find(recurso => recurso.recurso === "analysis-periodic-processed" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(hasPeriodicProcessed)) {
        this.hasPeriodicProcessed = false;
      } else {
        this.hasPeriodicProcessed = true;
      }
    },
        
  },
    
}
</script>