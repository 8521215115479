<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="300px"
  >
    <v-card>
      <v-card-title>
        Configuração do Extrato
      </v-card-title>
      <v-divider />
      <v-card-text
        class="pt-5"
        style="max-height: calc(100vh - 250px);"
      >
        <v-form
          ref="form"
          lazy-validation
          @submit="saveSettings"
        >
          <v-select
            v-model="releaseDay"
            label="Dia de liberação do extrato"
            :items="days"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="grey darken-1"
          text
          @click="() => show = false"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :loading="saving"
          @click="saveSettings"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },

  data() {
    return {
      saving: false,
      releaseDay: 1,
    }
  },

  computed: {
    days() {
      return Array.from({ length: 31 }, (v, k) => k + 1)
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.getSettings();
      }
    }
  },

  methods: {
    getSettings() {
      if (this.$store.state.settings.gerais.extrato_produtor) {
        this.releaseDay = this.$store.state.settings.gerais.extrato_produtor.dia_liberacao || 1;
      }
    },

    async saveSettings() {
      this.saving = true;
      try {
        const payLoad = {
          configuracaoQualidade: JSON.stringify([{
            extrato_produtor: {
              dia_liberacao: this.releaseDay,
            }
          }]),
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify(payLoad)
        );

        [data] = data;

        if (data.codigo !== 1) {
          throw data;
        }

        this.$store.dispatch('updateStoreSetting');
        this.show = false;
        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.error(error);
      } finally {
        this.saving = false;
      }
    },

    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  }
}
</script>
