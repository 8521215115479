<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Configurações de atendimento {{ sanity }}
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-row>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                v-if="sanity !== 'INVENTARIO'"
                cols="12"
                class="py-0"
              >
                <span>Data de Aplicação</span>
                <v-switch
                  v-model="item.obrigatorio_data_aplicacao"
                  :label="item.obrigatorio_data_aplicacao ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_data_aplicacao"
                  :label="item.mostrar_data_aplicacao ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_data_aplicacao"
                />
              </v-col>

              <v-col
                v-if="sanity === 'INVENTARIO'"
                cols="12"
                class="py-0"
              >
                <span>Data da Contagem</span>
                <v-switch
                  v-model="item.obrigatorio_data_contagem"
                  :label="item.obrigatorio_data_contagem ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_data_contagem"
                  :label="item.mostrar_data_contagem ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_data_contagem"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="sanity !== 'INVENTARIO'"
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Data do Vencimento</span>
                <v-switch
                  v-model="item.obrigatorio_data_vencimento"
                  :label="item.obrigatorio_data_vencimento ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_data_vencimento"
                  :label="item.mostrar_data_vencimento ? 'Visivel' : 'Não Visivel'"
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_data_vencimento"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Veterinário</span>
                <v-switch
                  v-model="item.obrigatorio_veterinario"
                  :label="item.obrigatorio_veterinario ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_veterinario"
                  :label="item.mostrar_veterinario ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_veterinario"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="sanity !== 'INVENTARIO'"
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Laboratório</span>
                <v-switch
                  v-model="item.obrigatorio_laboratorio"
                  :label="item.obrigatorio_laboratorio ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_laboratorio"
                  :label="item.mostrar_laboratorio ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_laboratorio"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Fotos / Anexos</span>
                <v-switch
                  v-model="item.obrigatorio_fotos_anexos"
                  :label="item.obrigatorio_fotos_anexos ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_fotos_anexos"
                  :label="item.mostrar_fotos_anexos ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_fotos_anexos"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Quantidade de animais</span>
                <v-switch
                  v-model="item.obrigatorio_quantidade_animais"
                  :label="item.obrigatorio_quantidade_animais ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_quantidade_animais"
                  :label="item.mostrar_quantidade_animais ?  'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_quantidade_animais"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Id Animais</span>
                <v-switch
                  v-model="item.obrigatorio_id_animais"
                  :label="item.obrigatorio_id_animais ? 'Obrigatório' : 'Não Obrigatório'"
                  dense
                  hide-details
                  class="mt-0"
                  color="yellow accent-3"
                />
                <v-switch
                  v-model="item.mostrar_id_animais"
                  :label="item.mostrar_id_animais ?  'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                  :readonly="item.obrigatorio_id_animais"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="save();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import _ from "lodash";

export default {

  components: {
  },
  props: {
    sanity: String,

  },

  data() {
    return {
      item: {
        id_configuracao: null,
        obrigatorio_data_aplicacao: false,
        mostrar_data_aplicacao: false,
        obrigatorio_data_vencimento: false,
        mostrar_data_vencimento: false,
        obrigatorio_veterinario: false,
        mostrar_veterinario: false,
        obrigatorio_laboratorio: false,
        mostrar_laboratorio: false,
        obrigatorio_fotos_anexos: false,
        mostrar_fotos_anexos: false,
        obrigatorio_quantidade_animais: false,
        mostrar_quantidade_animais: false,
        obrigatorio_id_animais: false,
        mostrar_id_animais: false,
        obrigatorio_data_contagem: false,
        mostrar_data_contagem: false,
      },
    }

  },

  mounted() {
    this.getSettings();
  },

  watch: {
    'item.obrigatorio_data_aplicacao': function(newVal) {
      if (newVal) {
        this.item.mostrar_data_aplicacao = true;
      }
    },
    'item.obrigatorio_data_vencimento': function(newVal) {
      if (newVal) {
        this.item.mostrar_data_vencimento = true;
      }
    },
    'item.obrigatorio_veterinario': function(newVal) {
      if (newVal) {
        this.item.mostrar_veterinario = true;
      }
    },
    'item.obrigatorio_laboratorio': function(newVal) {
      if (newVal) {
        this.item.mostrar_laboratorio = true;
      }
    },
    'item.obrigatorio_fotos_anexos': function(newVal) {
      if (newVal) {
        this.item.mostrar_fotos_anexos = true;
      }
    },
    'item.obrigatorio_quantidade_animais': function(newVal) {
      if (newVal) {
        this.item.mostrar_quantidade_animais = true;
      }
    },
    'item.obrigatorio_id_animais': function(newVal) {
      if (newVal) {
        this.item.mostrar_id_animais = true;
      }
    },
    'item.obrigatorio_data_contagem': function(newVal) {
      if (newVal) {
        this.item.mostrar_data_contagem = true;
      }
    },
  },

  methods: {
    async getSettings() {
      try {
        this.$root.$progressBar.loading();
        let { data } = await this.$axios.get(`/settings/sanity`, { params: {
            tipo: this.sanity,
          } });

        if (!_.isEmpty(data)) {
          this.item = _.first(data);
        }

      } catch (e) {
        this.$snotify.error("Erro ao carregar configuracao", "Atenção");
        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {

        this.$root.$progressBar.saving();

        const payload = {
          tipo: this.sanity,
          obrigatorio_data_aplicacao: this.item.obrigatorio_data_aplicacao,
          mostrar_data_aplicacao: this.item.mostrar_data_aplicacao,
          obrigatorio_data_vencimento: this.item.obrigatorio_data_vencimento,
          mostrar_data_vencimento: this.item.mostrar_data_vencimento,
          obrigatorio_veterinario: this.item.obrigatorio_veterinario,
          mostrar_veterinario: this.item.mostrar_veterinario,
          obrigatorio_laboratorio: this.item.obrigatorio_laboratorio,
          mostrar_laboratorio: this.item.mostrar_laboratorio,
          obrigatorio_fotos_anexos: this.item.obrigatorio_fotos_anexos,
          mostrar_fotos_anexos: this.item.mostrar_fotos_anexos,
          obrigatorio_quantidade_animais: this.item.obrigatorio_quantidade_animais,
          mostrar_quantidade_animais: this.item.mostrar_quantidade_animais,
          obrigatorio_id_animais: this.item.obrigatorio_id_animais,
          mostrar_id_animais: this.item.mostrar_id_animais,
          obrigatorio_data_contagem: this.item.obrigatorio_data_contagem,
          mostrar_data_contagem: this.item.mostrar_data_contagem,
        };

        const response = !_.isEmpty(this.item.id_configuracao) ? await this.update(payload) : await this.store(payload);

        this.item.id_configuracao = response.id_configuracao;

        this.getSettings();

        this.$snotify.success("Configuração salva com sucesso", "Sucesso");

      } catch (e) {

        this.$snotify.error("Erro ao salvar Configuração", "Atenção");
        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/settings/sanity`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/settings/sanity/${this.item.id_configuracao}`, payload);
      return data;
    },
  }
}
</script>
