<template>
  <v-row>
    <template
      v-for="dairy of dairies"
    >
      <v-col
        :key="`silo-${dairy.id}`"
        cols="12"
        md="7"
        lg="8"
        xl="9"
      >
        <SilosChart
          :height="height"
          :dairy="dairy"
        />
      </v-col>
      <v-col
        :key="`raw-${dairy.id}`"
        cols="12"
        md="5"
        lg="4"
        xl="3"
      >
        <TotalByRawMaterialChart
          :height="height"
          :dairy="dairy"
          :period="period"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import moment from 'moment';

import SilosChart from "@/Domains/BI/Views/Platform/Components/SilosChart.vue";
import TotalByRawMaterialChart from "@/Domains/BI/Views/Platform/Components/TotalByRawMaterialChart.vue";

export default {
  components: {
    SilosChart,
    TotalByRawMaterialChart,
  },

  props: {
    height: {
      type: String,
      default: '100px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  computed: {
    dairies() {
      if (this.$store.state.settings.laticinio) {
        return [ this.$store.state.settings.laticinio ]
      }
      return this.$store.state.settings.laticinios || [{ id: null, name: '' }]
    }
  }
}
</script>