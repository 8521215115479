<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <masked-text-field
          v-model="form.cfop"
          label="CFOP *"
          :mask="masks.cfop"
          unmask
          prepend-inner-icon="tag"
          :rules="[v => !!v || 'Campo obrigatório!']"
        >
          <template #prepend>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  help
                </v-icon>
              </template>
              <div class="body-1">
                <p>Utilize o <b>?</b> no início do CFOP para substituí-lo conforme a UF do destinatário.</p>
                <span class="d-block">EX: ?.102 será substituído por:</span>
                <span class="d-block">{{ inOut === 'ENTRADA' ? 1 : 5 }} para operações internas</span>
                <span class="d-block">{{ inOut === 'ENTRADA' ? 2 : 6 }} para operações interestaduais</span>
                <!-- <span class="d-block">{{ inOut === 'ENTRADA' ? 3 : 7 }} para operações do exterior</span> -->
              </div>
            </v-tooltip>
          </template>

          <template #append>
            <v-btn
              icon
              small
              @click="openSearchCFOP"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon v-on="on">
                    search
                  </v-icon>
                </template>
                Procurar CFOP
              </v-tooltip>
            </v-btn>
          </template>
        </masked-text-field>
      </v-col>
    </v-row>

    <template>
      <span class="text-h6 d-block mt-4 black--text">ICMS</span>

      <v-divider class="mb-4" />

      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-select
            v-if="taxRegime === 'SIMPLES'"
            v-model="form.tributacao"
            :items="csosn"
            label="Código de situação da operação - Simples Nacional (CSOSN) *"
            prepend-inner-icon="category"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
          <v-select
            v-else
            v-model="form.tributacao"
            :items="cst"
            label="Situação Tributária (CST) *"
            prepend-inner-icon="category"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
        </v-col>

        <v-col
          v-if="showAliquotaIcmsSimples"
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_credito"
            label="Alíquota aplicável de cálculo do crédito (SN)"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>

        <v-col
          v-if="showModalidadeIcms"
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <v-select
            v-model="form.modalidade"
            :items="[
              { text: 'Margem de Valor Agregado (MVA)', value: 0 },
              { text: 'Valor da Operação', value: 3 },
            ]"
            label="Modalidade Determinação da BC do ICMS *"
            :rules="[v => v >= 0 || 'Campo obrigatório!']"
          />
        </v-col>

        <v-col
          v-if="showPercentualReducaoIcms"
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_reducao"
            label="Percentual de Redução da BC do ICMS"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>

        <v-col
          v-if="showAliquotaIcms"
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota"
            label="Alíquota"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>

        <v-col
          v-if="showDiferimentoIcms"
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_diferimento"
            label="Percentual de Diferimento"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <v-select
            v-model="form.somar_ipi_na_base"
            :items="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false },
            ]"
            label="Somar IPI na Base"
          />
        </v-col>

        <template v-if="showFcp">
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <masked-text-field
              v-model="form.aliquota_fcp"
              label="Alíquota FCP"
              :mask="masks.percentual"
              unmask
              prepend-inner-icon="percent"
            />
          </v-col>
          <v-col
            v-if="false"
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <v-select
              v-model="form.subtrair_fcp_no_st"
              :items="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]"
              label="Subtrair FCP no cálculo do FCP ST"
            />
          </v-col>
        </template>
        <template v-if="showValores">
          <v-col
            v-if="(showAliquotaIcms || showAliquotaIcmsSimples)"
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor_base"
              label="Valor base ICMS"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
          <v-col
            v-if="showAliquotaIcms"
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor"
              label="Valor ICMS"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
          <v-col
            v-if="showFcp"
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor_fcp"
              label="Valor FCP"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
          <v-col
            v-if="showAliquotaIcmsSimples"
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor_credito"
              label="Valor aplicável de crédito (SN)"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
        </template>
      </v-row>
    </template>

    <template v-if="showIcmsUFDestino">
      <span class="text-h6 d-block mt-4 black--text">ICMS - UF Destino</span>

      <v-divider class="mb-4" />

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="formUfDest.aliquota_interestadual"
            label="Alíquota Interestadual"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="formUfDest.aliquota_interna"
            label="Alíquota Interna"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="formUfDest.aliquota_fcp"
            label="Alíquota FCP"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <v-select
            v-model="formUfDest.calculo_base_por_dentro"
            :items="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false },
            ]"
            label="Cálculo Base Por Dentro"
          />
        </v-col>
      </v-row>
    </template>

    <template v-if="showIcmsSt">
      <span class="text-h6 d-block mt-4 black--text">ICMS - ST</span>

      <v-divider class="mb-4" />

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <v-select
            v-model="form.modalidade_st"
            :items="[
              { text: 'Margem de Valor Agregado (MVA)', value: 4 },
              { text: 'Pauta (valor)', value: 5 },
            ]"
            label="Modalidade Determinação da BC do ICMS ST *"
            :rules="[v => v >= 0 || 'Campo obrigatório!']"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_reducao_st"
            label="Percentual de Redução da BC do ICMS ST"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
        <v-col
          v-if="form.modalidade_st === 5"
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <money-input
            v-model="form.valor_pauta_st"
            label="Valor Unitário da Pauta"
            prefix="R$"
          />
        </v-col>
        <v-col
          v-else
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_mva_st"
            label="MVA do ICMS ST"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_st"
            label="Alíquota ST"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_fcp_st"
            label="Alíquota FCP ST"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
        <v-col
          v-if="false"
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <v-select
            v-model="form.somar_ipi_na_base_st"
            :items="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false },
            ]"
            label="Somar IPI na Base ST"
          />
        </v-col>
        <template v-if="showValores">
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor_base_st"
              label="Valor base ICMS ST"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor_st"
              label="Valor ICMS ST"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor_fcp_st"
              label="Valor FCP ST"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
        </template>
      </v-row>
    </template>

    <template v-if="showIcmsEfetivo">
      <span class="text-h6 d-block mt-4 black--text">ICMS - Efetivo</span>

      <v-divider class="mb-4" />

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_reducao_efetivo"
            label="Redução BC do ICMS Efetivo"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_efetivo"
            label="Alíquota ICMS Efetivo"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
      </v-row>
      <v-row v-if="showIcmsRetido">
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <money-input
            v-model="form.base_retido"
            label="Base ICMS ST retido"
            prefix="R$"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <money-input
            v-model="form.valor_retido"
            label="Valor ICMS ST retido"
            prefix="R$"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <masked-text-field
            v-model="form.aliquota_fcp_retido"
            label="Alíquota FCP ST retido"
            :mask="masks.percentual"
            unmask
            prepend-inner-icon="percent"
          />
        </v-col>
        <template v-if="showValores">
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor_base_efetivo"
              label="Valor base ICMS efetivo"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="pb-0"
          >
            <money-input
              v-model="form.valor_efetivo"
              label="Valor do ICMS efetivo"
              prefix="R$"
              :disabled="autocalculation"
            />
          </v-col>
        </template>
      </v-row>
    </template>

    <v-dialog
      v-model="cfopDialog.show"
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Busca de CFOP
          </span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="cfopDialog.search"
            label="Código ou Descrição"
            append-icon="search"
            @click:append="searchCFOP"
            @keyup.enter="searchCFOP"
          />
          <v-data-table
            :headers="cfopDialog.headers"
            :items="cfopDialog.items"
            :loading="cfopDialog.loading"
            class="elevation-1"
            @click:row="selectCFOP"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            outlined
            @click="cfopDialog.show = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

import _ from 'lodash';

export default {

  components: {
    MaskedTextField,
    MoneyInput,
  },

  props: {
    icms: {
      type: Object,
      default: () => {}
    },
    icmsUfDest: {
      type: Object,
      default: () => {}
    },
    taxRegime: {
      type: String,
      default: 'NORMAL',
      validator: (value) => ['NORMAL', 'SIMPLES'].includes(value)
    },
    inOut: {
      type: String,
      default: 'SAIDA',
      validator: (value) => ['ENTRADA', 'SAIDA'].includes(value)
    },
    interstate: {
      type: Boolean,
      default: true,
    },
    finalConsumer: Boolean,
    showRetido: Boolean,
    showValores: Boolean,
    autocalculation: Boolean,
  },

  data() {
    return {
      masks: {
        integer: { mask: Number, min: 0, scale: 0, signed: false },
        percentual: { mask: Number, min: 0, max: 100, scale: 4 },
        cfop: {
          mask: '#.000',
          definitions: {
            '#': /[123567?]/
          }
        },
      },

      // Regime Simples Nacional
      csosn: [
        { value: '101', text: '101 - Tributada pelo Simples Nacional com permissão de crédito' },
        { value: '102', text: '102 - Tributada pelo Simples Nacional sem permissão de crédito' },
        { value: '103', text: '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta' },
        { value: '201', text: '201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por ST' },
        { value: '202', text: '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por ST' },
        { value: '203', text: '203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por ST' },
        { value: '300', text: '300 - Imune' },
        { value: '400', text: '400 - Não tributada pelo Simples Nacional' },
        { value: '500', text: '500 - ICMS cobrado anteriormente por ST ou por antecipação' },
        { value: '900', text: '900 - Outros' },
      ],

      // Regime Normal
      cst: [
        { value: '00', text: '00 - Tributada integralmente' },
        { value: '10', text: '10 - Tributada e com cobrança do ICMS por ST' },
        { value: '20', text: '20 - Com redução da BC' },
        { value: '30', text: '30 - Isenta/ não tributada e com cobrança do ICMS por ST' },
        { value: '40', text: '40 - Isenta' },
        { value: '41', text: '41 - Não tributada' },
        { value: '50', text: '50 - Com suspensão' },
        { value: '51', text: '51 - Com diferimento' },
        { value: '60', text: '60 - ICMS cobrado anteriormente por ST' },
        { value: '70', text: '70 - Com redução da BC e cobrança do ICMS por ST' },
        { value: '90', text: '90 - Outras' },
      ],

      cfopDialog: {
        show: false,
        loading: false,
        search: '',

        headers: [
          { text: 'CFOP', value: 'codigo', width: 70 },
          { text: 'Descrição', value: 'descricao' },
        ],

        items: [],
      }
    }
  },

  computed: {
    form: {
      get() {
        return this.icms;
      },

      set(newValue) {
        return this.$emit("update:icms", newValue);
      },
    },

    formUfDest: {
      get() {
        return this.icmsUfDest;
      },

      set(newValue) {
        return this.$emit("update:icmsUfDest", newValue);
      },
    },

    showAliquotaIcms() {
      return ['00', '10', '20', '51', '70', '90', '900'].includes(this.form.tributacao)
    },

    showAliquotaIcmsSimples() {
      return ['101', '201', '900'].includes(this.form.tributacao)
    },

    showDiferimentoIcms() {
      return this.form.tributacao === '51'
    },

    showModalidadeIcms() {
      return ['00', '10', '20', '51', '70', '90', '900'].includes(this.form.tributacao)
    },

    showPercentualReducaoIcms() {
      return ['20', '51', '70', '90', '900'].includes(this.form.tributacao)
    },

    showIcmsUFDestino() {
      // Difal ocorre em operações interestadual para consumidor final
      return this.interstate && this.finalConsumer
    },

    showIcmsSt() {
      return ['10', '30', '70', '90', '201', '202', '203', '900'].includes(this.form.tributacao)
    },

    showIcmsEfetivo() {
      return ['60', '500'].includes(this.form.tributacao) && this.finalConsumer
    },

    showIcmsRetido() {
      return ['60', '500'].includes(this.form.tributacao) && this.showRetido
    },

    showFcp() {
      return ['00', '10', '20', '51', '70', '90'].includes(this.form.tributacao)
    },

  },

  methods: {
    openSearchCFOP() {
      this.cfopDialog.show = true;
      this.cfopDialog.search = '';
      this.cfopDialog.items = [];
    },

    async searchCFOP() {
      if (this.cfopDialog.loading) {
        return;
      }
      try {
        this.cfopDialog.loading = true;

        const search = (this.cfopDialog.search || '').trim();

        if (!search) {
          this.cfopDialog.items = [];
          return;
        }

        const { data } = await this.$queries.post(`/fiscal/cfop`, {
          modelo: 'NFE',
          tipo: this.inOut,
          busca: search
        });

        this.cfopDialog.items = data;

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.cfopDialog.loading = false;
      }
    },

    selectCFOP({ codigo }) {
      this.form.cfop = codigo;
      this.cfopDialog.show = false;
    },
  }
}
</script>
