<template>
  <div class="mt-2 financial">
    <v-data-table
      class="financial-table"
      :headers="headers"
      :style=" noBordered?'':'border: 1px solid #ccc !important; border-radius: 4px !important;'"
      :items="value"
      dense
      hide-default-footer
    >
      <template
        v-for="(header, index) in headers"
        #[`item.${header.value}`]="{item, headers}"
      >
        <v-text-field
          v-if="header.item.type === 'TEXTINPUT'"
          :key="index"
          v-model="item[header.value]"
          :placeholder="header.text"
          hide-details
          @change="onChange"
        />

        <v-text-field
          v-if="header.item.type === 'NUMBER'"
          :key="index"
          v-model="item[header.value]"
          hide-details
          :placeholder="header.text"
          type="number"
          @change="onChange"
        />

        <money-input
          v-if="header.item.type === 'MONEY'"
          :key="index"
          v-model="item[header.value]"
          hide-details
          :placeholder="header.text"
          prefix="R$"
        />

        <span
          v-if="header.item.type === 'TEXT'"
          :key="index"
        >{{ item[header.value] }}</span>
      </template>

      <template #[`item.remove`]="{item}">
        <v-btn
          icon
          small
          color="red lighten-2"
          @click="onRemoveRow(item)"
        >
          <v-icon>
            delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row
      v-if="rowAdd && editable"
      align="center"
    >
      <v-col
        class="py-0"
        style="max-height: 43px;"
      >
        <v-btn
          style="padding-bottom: 5px;padding-right: 15px;"
          icon
          color="blue darken-3"
          x-large
          @click="onAddRow"
        >
          <v-icon
            color="blue darken-3"
            large
          >
            add_circle
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.financial {
  .financial-table {
    .v-input {
      padding-bottom: 10px;
      padding-top: 0;
    }

    table {
      thead > tr {
        span {
          font-family: Roboto, Arial, sans-serif;
          font-size: 0.9rem;
          font-weight: 700;
        }
        background: rgba(226, 225, 225, 0.5);
      }

      tbody {
         tr:hover {
          background-color: #ECEFF1 !important;
        }
      }
    }
  }
}
</style>

<script>
import * as _ from "lodash";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

export default {
  components: {
    MoneyInput,
  },
  props: {
    settings: {
      type: Array,
    },
    value: {
      type: Array,
    },
    rowRemove: Boolean,
    rowAdd: Boolean,
    noBordered: Boolean,
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    headers() {
      // Adiciona um header onde deve ficar o evento de remoção da linha
      if (this.rowRemove && this.editable) {
        return _.concat(this.settings, { text: "", value: "remove", align: "center", width: 40 });
      }

      return this.settings;
    },
  },
  methods: {

    /**
     * @void
     * Adiciona nova linha da tabela
     */
    onAddRow() {
      // Busca o posição do array de itens, para obter a estrutura das linhas
      const row = _.head(this.value);
     
      //Faz o push do objeto com os campos vázios
      this.value.push(_.mapValues(row, ""));

      this.$emit("input", this.value);
    },
    
    /**
     * @void
     * Remove linha da tabela
     */
    onRemoveRow(row) {
      // busca a posição que dever ser removida
      const index = _.findIndex(this.value, row);

      this.value.splice(index, 1);

      this.$emit("input", this.value);
    },
    
    onChange() {
      this.$emit('change', this.value);
    }
  },
};
</script>
