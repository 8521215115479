<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col
        md="4"
      >
        <date-range-picker
          v-model="filter.date.input"
          @change="onDateFilter"
        />
      </v-col>
      <v-col
        md="4"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
        />
      </v-col>
      <v-col
        md="4"
      >
        <person-autocomplete-filter
          v-model="filter.producer"
          type="PRODUCER"
          label="Produtor"
          dark
        />
      </v-col>
    </v-row>
    <v-card
      class="report-card"
      color="transparent"
    >
      <v-card-title>
        <v-spacer />
        <v-col
          cols="4"
          md-4
          sm-4
          class="pa-0"
        >
          <v-text-field
            v-model="filter.search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            dark
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        :search="filter.search"
        :loading="loading"
        multi-sort
        dark
        class="elevation-1 report-table"
      >
        <template
          v-slot:item.data_agendamento="{ item }"
        >
          <v-chip
            small
            label
            :color="getDateVisitColor(item.data_agendamento, !!item.hora_chegada)"
          >
            {{ item.data_agendamento | dateFormat("DD/MM/YYYY") }}
          </v-chip>
        </template>
        <template
          v-slot:item.tipo="{ item }"
        >
          <v-chip
            v-for="(tipo, i) in item.tipo"
            :key="i"
            small
            color="blue"
          >
            {{ types[tipo] }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<style lang="scss">

.report-card {
  margin-top: 5px !important;

  > .v-card__title{
    background: rgba(0, 0, 0, 0.5);
  }
}

.report-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

import moment from "moment-timezone";
import XLSX from "xlsx-js-style";

const service = new VisitsService();

export default {
  name: "scheduled-visited-table",

  components: {
    PersonAutocompleteFilter,
    DateRangePicker,
  },

  filters: {
    dateFormat: (value, format) => {
      return !value ? "-" : moment(value).format(format);
    }
  },

  data() {
    return {
      loading: false,

      filter: {
        date: {
          input: "thisMonth",
          range: [],
        },
        technician: {
          id: "",
          description: ""
        },
        producer: {
          id: "",
          description: ""
        },
        search: '',
      },
      headers: [
        {
          text: 'Técnico',
          value: 'nome_tecnico',
          width: 200,
          align: 'start',
        },
        { text: 'Produtor', value: 'nome_produtor' },
        { text: 'Data', value: 'data_agendamento' },
        { text: 'Início', value: 'hora_chegada' },
        { text: 'Fim', value: 'hora_saida' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Tipo', value: 'tipo' },
      ],
      items: [],
      types: service.types,
    };
  },

  computed: {
    filteredItems() {
      if (!this.filter.technician.id && !this.filter.producer.id) {
        return this.items;
      }

      return this.items.filter(item => {

        const filterTechnician = item.id_tecnico == this.filter.technician.id;
        const filterProducer = item.id_produtor == this.filter.producer.id;

        return filterTechnician || filterProducer;
      });
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;

        const [start_date, end_date] = this.filter.date.range;

        const { data } = await this.$axios.post(`/agenda/listaAgendamentosVisitados`, { start_date, end_date });

        this.items = data.map(item => ({
          ...item,
          tipo: JSON.parse(item.tipo)
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    getDateVisitColor(date, visited) {
      const defaulColor = 'blue-grey darken-1';

      if (!date) {
        return defaulColor;
      }

      if (visited) {
        return "light-green darken-1";
      }
      else {
        return "red lighten-1";
      }
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter(range) {
      this.filter.date.range = range;
      this.loadReport();
    },

    exportExcel() {
      let data = XLSX.utils.json_to_sheet(this.filteredItems.map(value => {
        return {
          Técnico: value.nome_tecnico,
          Produtor: value.nome_produtor,
          Data: value.data_agendamento ? moment(value.data_agendamento).format("DD/MM/YYYY") : '',
          Início: value.hora_chegada,
          Fim: value.hora_saida,
          Observação: value.observacao,
          Tipo: value.tipo ? value.tipo.map(t => this.types[t]).join(',') : '',
        };
      }));

      const workbook = XLSX.utils.book_new();

      const [start_date, end_date] = this.filter.date.range;
      const filename = "agendados_visitados";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}-${start_date}-${end_date}.xlsx`);
    },
  },
};
</script>
