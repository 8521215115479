<template>
  <v-container>
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pt-0"
          >
            <date-range-picker
              ref="transferredAt"
              v-model="filters.transferredAt.input"
              label="Data de Baixa"
              @change="onTransferredAtDateFilter"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pt-0"
          >
            <date-range-picker
              ref="processedAt"
              v-model="filters.processedAt.input"
              label="Data de Produção"
              @change="onProcessedAtDateFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pt-0"
          >
            <v-autocomplete
              v-model="filters.products"
              :items="products"
              label="Produto"
              item-value="id"
              item-text="description"
              multiple
              clearable
              hide-details
              dark
              filled
              @input="onProductFilterChange"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        :headers="headers"
        :items="filteredItems"
        :loading="loading"
        :sort-by="['processedAt', 'transferredAt']"
        :sort-desc="[true, true]"
        @click:row="onEditSiloHistory"
      >
        <template #[`item.tinas`]="{ item }">
          <div
            v-for="tina in item.tinas"
            :key="tina.id"
          >
            <v-chip
              v-if="tina.description"
              x-small
            >
              {{ tina.description || '-' }}
            </v-chip>
          </div>
        </template>

        <template #[`item.transferredAt`]="{ item }">
          <v-chip x-small>
            {{ item.transferredAt | dateTime }}
          </v-chip>
        </template>

        <template #[`item.processedAt`]="{ item }">
          <v-chip x-small>
            {{ item.processedAt | dateTime }}
          </v-chip>
        </template>

        <template #[`item.transferredVol`]="{ item }">
          <v-chip x-small>
            <v-avatar left>
              <v-icon color="red">
                keyboard_arrow_up
              </v-icon>
            </v-avatar>

            {{ formatNumber(item.transferredVol) }}
          </v-chip>
        </template>

        <template #[`item.item.description`]="{ value }">
          <v-chip
            v-if="value"
            x-small
            v-text="value"
          />
        </template>

        <template #[`item.protein`]="{ item }">
          {{ formatNumber(item.protein) }}
        </template>

        <template #[`item.milkWhey`]="{ item }">
          {{ formatNumber(item.milkWhey) }}
        </template>

        <template #[`item.crioscopy`]="{ item }">
          {{ formatNumber(item.crioscopy) }}
        </template>

        <template #[`item.action`]="{ item }">
          <v-menu>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                small
                v-on.stop="on"
              >
                <v-icon
                  dark
                  dense
                >
                  more_vert
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasPermissionToEditHistoryProduction"
                @click="onEditSiloHistory(item)"
              >
                <v-list-item-avatar>
                  <v-icon>
                    create
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasPermissionToDeleteHistoryProduction"
                @click="onExcludeSiloHistory(item)"
              >
                <v-list-item-avatar>
                  <v-icon>
                    delete
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Excluir e Estornar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      bottom
      class="mr-5"
      dark
      direction="top"
      fixed
      open-on-hover
      right
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          fab
          large
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        color="green darken-1"
        dark
        fab
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <production-transfer-dialog
      ref="productionDialog"
      history
      @onSiloTransferred="onSiloTransferred"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import * as _ from "lodash";

import IMask from "imask";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import ProductionTransferDialog from "@/Domains/Platform/Silos/Components/ProductionTransferDialog.vue";

export default {

  components: {
    DateRangePicker,
    ProductionTransferDialog
  },

  filters: {

    date(value) {
      return moment(value).format('DD/MM/YYYY');
    },

    dateTime(value) {
      return moment(value).format('DD/MM/YYYY HH:mm');
    },

    /**
     * Filtro usado em todas as mascaras da view
     */
    Mask(value, mask) {
      return IMask.pipe((value || '').toString(), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },

  },

  data() {
    return {
      // Loader
      loading: false,

      filters: {
        products: [],
        transferredAt: {
          input: 'today',
          range: [],
        },
        processedAt: {
          input: null,
          range: [],
        },
        silo: {
          input: null,
          options: [],
        },
        tina: {
          input: null,
          options: [],
        },
      },

      headers: [
        { text: 'Silo', value: 'silo.description', align: 'start', width: 75 },
        { text: 'Tinas', value: 'tinas', width: 100, formatter: value => value.map(item => item.description).join(', '), align: 'center' },
        { text: 'Baixa', value: 'transferredAt', width: 100, formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Produção', value: 'processedAt', width: 110, formatter: value => this.formatDate(value, 'DD/MM/YYYY'), align: 'center' },
        { text: 'Pasteurizador', value: 'transferredVol', width: 130 },
        { text: 'Nº Lote', value: 'batchNumber', width: 130 },
        { text: 'OP', value: 'productionOrder.code', align: 'center' },
        { text: 'Produto', value: 'item.description', width: 95 },
        { text: 'Gordura (Ini.)', value: 'initialFat', width: 125, align: 'center' },
        { text: 'Acidez', value: 'acidity', width: 90, align: 'center' },
        { text: 'Gordura (Fim)', value: 'finalFat', width: 130, align: 'center' },
        { text: 'Fosfatase', value: 'phosphatase', width: 105, formatter: value => value ? 'Positivo' : 'Negativo', align: 'center' },
        { text: 'Peroxidase', value: 'peroxidase', width: 115, formatter: value => value ? 'Positivo' : 'Negativo', align: 'center' },
        { text: 'ESD', value: 'esd', width: 80, align: 'center' },
        { text: 'Proteína', value: 'protein', width: 100, align: 'center' },
        { text: 'Gordura Soro', value: 'milkWhey', width: 125, align: 'center' },
        { text: 'Crioscopia', value: 'crioscopy', width: 110, align: 'center' },
        { text: 'Água (%)', value: 'waterPercentage', width: 110, align: 'center' },
        { text: 'Água (L)', value: 'waterLiters', width: 100, align: 'center' },
        { text: 'Responsável', value: 'responsible.name' },
        { text: 'Opções', altText: 'Opções', value: 'action', width: 30, lign: 'end', sortable: false, drag: false, align: 'center' }
      ],

      history: [],

      products: [],
    };
  },

  computed: {
    filteredItems() {
      if (this.filters.products.length === 0) {
        return this.history;
      }

      const products = this.filters.products;

      return this.history.filter(history => {
        const historyFiltered = products.length === 0 || products.includes(history.item.id);
        return historyFiltered;
      });
    },
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasPermissionToEditHistoryProduction() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "can-production-history-edit" && o.tipo === "COMPONENTE");
    },

    hasPermissionToDeleteHistoryProduction() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "can-production-history-delete-and-reversed" && o.tipo === "COMPONENTE");
    },
  },

  async mounted() {
    this.loadList();
    this.loadProducts();
  },

  methods: {

    async loadList() {
      try {

        let filters = {
          tipo: 'SAIDA_PRODUCAO',
        };

        // Caso não exista nenhum filtro na tela
        if (_.isEmpty(this.filters.transferredAt.range) && _.isEmpty(this.filters.processedAt.range)) {
          filters = {
            ...filters,
            data_baixa_ini: moment().format("YYYY-MM-DD"),
            data_baixa_fim: moment().format("YYYY-MM-DD"),
          }
        }

        if (!_.isEmpty(this.filters.processedAt.range)) {
          filters = {
            ...filters,
            data_producao_ini: _.head(this.filters.processedAt.range),
            data_producao_fim: _.last(this.filters.processedAt.range),
          }
        }

        if (!_.isEmpty(this.filters.transferredAt.range)) {
          filters = {
            ...filters,
            data_baixa_ini: _.head(this.filters.transferredAt.range),
            data_baixa_fim: _.last(this.filters.transferredAt.range),
          }
        }

        const { data } = await this.$axios.post(`/silo/listaTransferencia`, this.$qs.stringify(filters));

        if (!_.isArray(data)) {
          throw "PHP Error";
        }

        this.history = data.map(history => {
          return {
            productionId: history.id_producao,
            movementId: history.id_silo_movimento,
            groupedProductionId: history.id_agrupador_producao,
            silo: {
              id: history.id_silo,
              description: history.descricao,
            },
            tinas: JSON.parse(history.tinas) || [],
            item: {
              id: history.id_item,
              description: history.cod_item ? `${history.cod_item} - ${history.nome_item}` : history.nome_item,
            },
            productionOrder: history.id_ordem_producao ? {
              id: history.id_ordem_producao,
              description: history.descricao_ordem_producao,
              code: history.codigo_ordem_producao,
            } : null,
            batchNumber: history.numero_lote,
            transferredVol: parseInt(history.volume),
            transferredAt: history.data,
            processedAt: history.data_producao,
            responsible: history.id_responsavel ? {
              id: history.id_responsavel,
              name: history.nome_responsavel,
            } : {},

            initialFat: parseFloat(history.gordura_inicial) || null,
            acidity: parseFloat(history.acidez) || null,
            phosphatase: history.fosfatase,
            peroxidase: history.peroxidase,
            esd: parseFloat(history.esd) || null,
            finalFat: parseFloat(history.gordura_final) || null,
            protein: parseFloat(history.proteina) || null,
            milkWhey: parseFloat(history.soro) || null,
            crioscopy: parseFloat(history.crioscopia) || null,
            waterPercentage: parseFloat(history.porcentagem_agua) || null,
            waterLiters: parseFloat(history.volume_agua) || null,
          };
        })
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      }
    },

    async loadProducts() {
      try {
        const { data } = await this.$axios.get(`/item`, {
          params: {
            tipos: ['PRODUTO_ACABADO', 'INSUMO_PRODUCAO', 'MATERIA_PRIMA']
          }
        });

        this.products = data.map(item => ({
          id: item.id_item,
          name: item.nome,
          code: item.cod_item,
          description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
        }));
      } catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os produtos!', 'Atenção');
      }
    },

    onTransferredAtDateFilter(event) {
      this.filters.transferredAt.range = event;

      this.filters.processedAt = {
        input: null,
        range: [],
      };

      this.$refs.processedAt.clear();

      return this.loadList();
    },

    onProductFilterChange(value) {
      this.filters.products = value;

      return this.loadList();
    },

    onProcessedAtDateFilter(event) {
      this.filters.processedAt.range = event;

      this.filters.transferredAt = {
        input: null,
        range: [],
      };

      this.$refs.transferredAt.clear();

      return this.loadList();
    },

    onEditSiloHistory(transferring) {
      return this.$refs.productionDialog.show(transferring);
    },

    async onExcludeSiloHistory(transferring) {
      try {
        this.loading = true;

        if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir esta produção?<br><br>`, { color: 'red', token: 'EXCLUIR' }))) {
          return;
        }

        await this.$axios.post(`/industry/silo/chargeback-movement`, {
          id: transferring.movementId,
        });

        return this.loadList();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a produção!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async onSiloTransferred() {
      return this.loadList();
    },

    getReportTitle() {
      const [startDate = moment(), endDate = moment()] = this.filters.transferredAt.range;
      return `Historico de produção - ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title, true);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    parseNumber: val => +String(val) || null,

  }

}
</script>
