<template>
  <indicator-index
    type="VISITA"
  />
</template>

<script>
import IndicatorIndex from "@/Domains/Registrations/Cares/Indicator/IndicatorIndex.vue";

export default {
  components: {
    IndicatorIndex
  },
}
</script>
