<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-card class="transparent list-pqfl">
      <v-card-title class="px-3">
        <v-row
          class="pa-0 ma-0 row-filters"
        >
          <v-col
            cols="3"
            class="pa-1"
            md-3
          >
            <v-select
              v-model="filters.typePQFL"
              label="Tipo"
              dark
              filled
              return-object
              :items="[{text:'Respondido',valeu:'ANSWERED'},{text:'Não Respondido',valeu:'NOTANSWERED'}]"
              class="pa-0"
              @change="onSelectedType"
            />
          </v-col>
          <v-col
            cols="3"
            md-3
            class="pa-1"
          >
            <period-date-picker
              v-model="filters.date.input"
              @change="onDateFilter"
            />
          </v-col>
          <v-col
            cols="3"
            md-3
            class="pa-1"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              append-icon="search"
              single-line
              hide-details
              clearable
              filled
              dark
            />
          </v-col>
          <v-col
            cols="3"
            md-3
            class="pa-1"
          >
            <v-autocomplete
              v-model="filters.activeProspection"
              :items="producerActiveProspection"
              label="Ativos/Prospectados"
              prepend-inner-icon="produtores"
              dark="dark"
              filled
              clearable
              @change="onFilter"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        dense
        dark
        :headers="headers"
        :search="filters.search"
        :items="dataPQFL"
        class="elevation-1 list-producer-pqfl"
      />
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<style lang="scss">
    .list-pqfl > .v-card__title {
    background: rgba(0, 0, 0, 0.5);
    }

    .list-producer-pqfl{
        background: transparent !important;

        table {
            thead > tr {
                background: rgba(0, 0, 0, 0.5);
            }

            tbody {
            background: rgba(255, 255, 255, 0.08);
            color: white !important;

            tr:hover {
                background: rgba(0, 0, 0, 0.2) !important;
            }
            }
        }

        .v-data-footer{
            background: rgba(0, 0, 0, 0.5);
        }
    }
</style>

<script>
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import PeriodDatePicker from "@/Support/Components/PeriodDatePicker.vue";
import qs from "qs";
import _ from "lodash";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PeriodDatePicker,
  },

  filters: {
    dateFormat: function(value, opts) {
      return moment(value).format(opts);
    },
  },

  data() {
    return {
      dateSign: moment().format("YYYY-MM-DD"),

      filters: {
        search: null,
        typePQFL: { text: 'Respondido', valeu: 'ANSWERED' },
        date: {
          input: "today",
          range: [],
        },
        activeProspection: "",
      },

      dataPQFL: [],

      producerActiveProspection: [
        'Produtores Ativos',
        'Produtores Prospectados'
      ],

      dataPQFLOrigin: [],
      itemsretorno: [],
    };
  },
  computed: {
    headers() {

      if (this.filters.typePQFL.valeu === "NOTANSWERED") {
        return [
          { text: 'Código', value: 'codigo_produtor', width: 100 },
          { text: 'Produtor', value: 'nome' }
        ];
      }

      return [
        { text: 'Código', value: 'codigo_produtor', width: 100 },
        { text: 'Produtor', value: 'nome' },
        { text: 'Nº Documento', value: 'total_ficha' },
        { text: 'Data', value: 'data_emissao' },
      ];
    }
  },
  mounted() {
    this.loadPQFL();
  },
  methods: {
    async loadPQFL() {
      try {

        const url = this.filters.typePQFL.valeu === "ANSWERED" || !this.filters.typePQFL  ? "produtoresComfichaTecnica" : "produtoresSemfichaTecnica"

        const { data } = await this.$axios.post(
          `/fichaTecnicaProdutor/${url}`,
          qs.stringify({
            data_inicio: _.first(this.filters.date.range) || moment().format("YYYY-MM-DD"),
            data_fim: _.last(this.filters.date.range) || moment().format("YYYY-MM-DD"),
          })
        );

        this.dataPQFL = data;
        this.dataPQFLOrigin = data;

        if (this.filters.activeProspection) {
          this.onFilter();
        }

      } catch (e) {
        console.warn(e);
        this.$snotify.error("Oops, ocorreu um erro ao carregar PQFL!", "Atenção");
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event;

      this.loadPQFL();
    },

    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.dataPQFL.map(item => {

        if (this.filters.typePQFL.valeu === "NOTANSWERED") {
          return {
            Código: item.codigo_produtor,
            Produtor: item.nome,
          }
        }

        return {
          Código: item.codigo_produtor,
          Produtor: item.nome,
          NºDocumento: item.total_ficha,
          Data: item.data_emissao
        }
      }), {
        header: this.filters.typePQFL.valeu === "ANSWERED" ?
          ["Código", "Produtor", "NºDocumento", "Data"] :
          ["Código", "Produtor"],
      });

      const workbook = XLSX.utils.book_new();
      const filename = this.filters.typePQFL.valeu === "ANSWERED" ? "com_pqfl_padrao" : "sem_pqfl_padrao";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    onSelectedType() {
      if (!this.filters.typePQFL) {
        this.filters.typePQFL = { text: 'Respondido', valeu: 'ANSWERED' };
      }

      this.loadPQFL();
    },

    onFilter() {
      this.itemsretorno = this.dataPQFLOrigin;

      if (this.filters.activeProspection === "Produtores Ativos") {
        this.dataPQFL = this.itemsretorno.filter(x => x.status === "APROVADO" || (x.status === "" || x.status === null));
      } else if (this.filters.activeProspection === "Produtores Prospectados") {
        this.dataPQFL = this.itemsretorno.filter(x => (x.status === "APROVACAO" || x.status === "NEGOCIACAO" || x.status === "LEAD"));
      } else {
        this.loadPQFL();
      }
    }
  }
}
</script>
