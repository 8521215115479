<template>
  <index
    type="VISITA"
  />
</template>

<script>
import Index from "@/Domains/Registrations/Cares/Settings/Index.vue";

export default {
  components: {
    Index
  },
}
</script>
