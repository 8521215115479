<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :loading="loading"
    :style="{background: color, overflow: 'hidden', height: height}"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">{{ title }}</span>
    </v-card-title>

    <v-card-text class="pb-0 flex-grow-0">
      <div class="d-flex white--text justify-space-between">
        <div class="d-flex flex-column text-left white--text">
          <span class="subtitle-1 font-weight-light pb-1">Máxima Geral</span>
          <span class="display-1">{{ formatNumber(maxGeneral) }} %</span>
          <span class="subtitle-1 font-weight-light pt-3 pb-1">Mínima Geral</span>
          <span class="display-1">{{ formatNumber(minGeneral) }} %</span>
        </div>
      </div>
    </v-card-text>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: "occupancy-rate-chart",

  props: {
    title: {
      type: String,
      default: 'Taxa de Ocupação'
    },
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '100px',
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    fleetType: String
  },

  data() {
    return {
      loading: false,
      category: [],
      min: [],
      max: [],
      avg: [],
    }
  },

  computed: {
    minGeneral() {
      return Math.min(...this.min);
    },
    maxGeneral() {
      return Math.max(...this.max);
    },
    options() {
      const label = {
        show: true,
        lineHeight: 17,
        height: 17,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      };

      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)} %</b>`),
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '12%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.category,
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          max: 100,
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [{
          name: 'Mínima',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: this.min,
          label
        }, {
          name: 'Máxima',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: this.max,
          label
        }, {
          name: 'Média',
          type: 'line',
          lineStyle: { width: 2 },
          showSymbol: false,
          smooth: this.smooth,
          emphasis: {
            focus: 'series'
          },
          data: this.avg
        }]
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const terceiro = this.fleetType === 'internal' ? 'nao' : this.fleetType === 'external' ? 'sim' : null;
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(
          `/relatorios/taxaOcupacaoColeta`,
          { data_inicio: startDate, data_fim: endDate, terceiro: terceiro }
        );

        this.category = data.map(({ data }) => moment(data).format('DD/MM/YYYY'));
        this.min = data.map(({ taxa_ocupacao_min }) => parseFloat(taxa_ocupacao_min).toFixed(1));
        this.max = data.map(({ taxa_ocupacao_max }) => parseFloat(taxa_ocupacao_max).toFixed(1));
        this.avg = data.map(({ taxa_ocupacao_media }) => parseFloat(taxa_ocupacao_media).toFixed(1));
        const occupancyRateAvg = (data.reduce((acc, cur) => acc + parseFloat(cur.taxa_ocupacao_media), 0) / data.length) || 0;
        this.$emit('change', { occupancyRateAvg })
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de taxa de ocupação!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
