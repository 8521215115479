<template>
  <div>
    <v-card
      class="graph-card"
      color="transparent"
      dark
    >
      <v-card-title>
        Checklist: {{ questionnaire.questionnaire.text }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            md="4"
          >
            Meta: {{ questionnaire.producer }} % dos Produtores {{ questionnaire.period }}<br>
            Realizado no período do projeto: {{ totalResponse }} ({{ percentResponse }}%)
            <v-icon
              right
              :color="icone == 'verified' ? 'green darken-1': 'red darken-1'"
            >
              {{ icone }}
            </v-icon>
          </v-col>
          <v-col
            md="4"
          >
            Reaplicar: {{ questionnaire.producer_reapply }} % dos Produtores {{ questionnaire.period_reapply }}<br>
            Realizado no período do projeto: {{ totalReapply }} ({{ percentReapply }}%)
            <v-icon
              right
              :color="icone_reapply == 'verified' ? 'green darken-1': 'red darken-1'"
            >
              {{ icone_reapply }}
            </v-icon>
          </v-col>
          <v-col
            md="4"
          >
            Produtores ativos/suspensos em  {{ today }} : <b> {{ totalProducer }} </b>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="4"
          >
            <v-btn
              dark
              color="green darken-1"
              @click="exportExcel()"
            >
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-icon v-on="on">
                    backup_table
                  </v-icon>
                  Exportar  {{ totalResponseMonth + totalReapplyMonth }} produtores do mês: {{ formatDate(filter.date.month,"MM/YYYY") }}
                </template>

                Exportar lista de  produtores
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels dark>
          <v-expansion-panel
            style="background: none"
          >
            <v-expansion-panel-header>Aplicado no mês {{ formatDate(filter.date.month,"MM/YYYY") }} : {{ totalResponseMonth }} produtores  </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="responseMonth"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template
                  #[`item.data_resposta`]="{ value }"
                >
                  {{ formatDate(value, "DD/MM/YYYY") }}
                </template>
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel
            style="background: none"
          >
            <v-expansion-panel-header>Reaplicado no mês {{ formatDate(filter.date.month,"MM/YYYY") }} : {{ totalReapplyMonth }} produtores </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="reapplyMonth"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template
                  #[`item.data_resposta`]="{ value }"
                >
                  {{ formatDate(value, "DD/MM/YYYY") }}
                </template>
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-overlay
          v-model="loading"
          absolute
        >
          Carregando checklists...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-overlay>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import moment from "moment-timezone";
import XLSX from "xlsx-js-style";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import { round, percentageBy } from "@/Support/Resources/utils";
import _ from "lodash";

export default {

  components: {

  },
  mixins: [ReportMixin],

  props: {
    questionnaire: Object,
    filter: Object,
  },

  data() {
    return {

      loading: false,

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Nome', value: 'nome_produtor' },
        { text: 'Rota', value: 'rota_principal' },
        { text: 'Data resposta', value: 'data_resposta' },
        { text: 'Status', value: 'status' },
      ],
      headersExpired: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Nome', value: 'nome_produtor' },
        { text: 'Rota', value: 'rota_principal' },
        { text: 'Data resposta', value: 'data_resposta' },
        { text: 'Data vencimento', value: 'data_vencimento' },
        { text: 'Status', value: 'status' },
      ],

      response: [],
      responseMonth: [],
      reapply: [],
      reapplyMonth: [],

      today: moment().format("DD/MM/YYYY"),

      totalProducer: 0,

      percentResponse: 0,
      totalResponse: 0,

      totalResponseMonth: 0,

      percentReapply: 0,
      totalReapply: 0,

      totalReapplyMonth: 0,

      icone: 'cancel',
      icone_reapply: 'cancel',

    };
  },

  watch: {
    filter: {
      handler() {
        this.loadResponses();
      },
      deep: true
    }

  },
  mounted() {
    this.loadResponses();
  },

  methods: {

    async loadResponses() {

      let id = this.questionnaire.id_formulario;
      if (_.isEmpty(id)) {
        return false;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaVencimentosChecklist`,
          this.$qs.stringify({
            id_formulario: id,
            data_inicio_projeto: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim_projeto: moment(this.filter.project.dateEnd).endOf('month').format('YYYY-MM-DD'),
            periodo_reaplicar: this.questionnaire.period_reapply || '',
            periodo_projeto: this.questionnaire.period,
            mes_ref: moment(this.filter.date.month).format('YYYY-MM') || '',
            id_assistencia: this.filter.project.id,
          })
        );

        this.response =  data.filter(item => item.respostas.length > 0 && item.ativo == '1' && item.status != 'DESVINCULADO')
          .map(item => ({
            ...item
          }));

        this.totalResponse = this.response.length;

        //ativos geral - considera somente produtores ativos
        let active =  data.filter( item => item.ativo == '1' && item.status != 'DESVINCULADO').map(item => ({
          ...item
        }));

        this.totalProducer = active.length ; //respondidos e nao respondidos

        this.percentResponse = round(percentageBy(this.totalProducer, this.totalResponse), 2);

        if (parseFloat(this.questionnaire.producer) <= parseFloat(this.percentResponse )) {
          this.icone = 'verified';
        }

        this.responseMonth =  data.filter( item => item.respostas.length == 1 && moment(item.data_resposta).isSame(moment(this.filter.date.month), 'month') )
          .map(item => ({
            ...item
          }));

        this.totalResponseMonth = this.responseMonth.length;

        //--------reaplicados geral - considera somente produtores ativos
        this.reapply =  data.filter(item =>  item.respostas.length > 1 && item.ativo == '1' && item.status != 'DESVINCULADO')
          .map(item => ({
            ...item
          }));

        this.totalReapply = this.reapply.length;
        this.percentReapply = round(percentageBy(this.totalProducer, this.totalReapply), 2);
        if (parseFloat(this.questionnaire.producer_reapply) <= parseFloat(this.percentReapply )) {
          this.icone_reapply = 'verified';
        }

        this.reapplyMonth =  data.filter(item => item.respostas.length > 1 && moment(item.data_resposta).isSame(moment(this.filter.date.month), 'month'))
          .map(item => ({
            ...item
          }));
        this.totalReapplyMonth = this.reapplyMonth.length;

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
        this.emitQuestionnaire();
      }

    },

    emitQuestionnaire() {

      let ids_visita = [];
      this.responseMonth.map(item => (
        ids_visita.push(item.id_visita)
      ));

      let data = {
        "name": this.questionnaire.questionnaire.text,
        "goalResponse": this.questionnaire.producer + '% dos Produtores ' + this.questionnaire.period,
        "totalResponse": this.totalResponse,
        "totalProducer": this.totalProducer,
        "percentResponse": this.percentResponse,
        "totalResponseMonth": this.totalResponseMonth,
        "goalReapply": this.questionnaire.producer_reapply + '% dos Produtores ' + this.questionnaire.period_reapply,
        "totalReapply": this.totalReapply,
        "percentReapply": this.percentReapply,
        "totalReapplyMonth": this.totalReapplyMonth,
        "icone_reapply": this.icone_reapply,
        "icone": this.icone,
        "ids_visita": ids_visita,
      }

      return this.$emit("onLoadQuestionnaire", data);
    },

    getReportJson() {
      const items = _.concat(this.responseMonth, this.reapplyMonth);
      const header = this.headers;

      return items.map(o => {
        let initial = {};
        return header.reduce((acc, header) => ({
          ...acc,
          [header.text]: 'formatter' in header ? header.formatter(_.get(o, header.value, '')) : _.get(o, header.value, ''),
        }), initial);
      });

    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const title = `${moment(this.filter.date.month).format("MM-yyyy")}`;

      let data = XLSX.utils.json_to_sheet(this.getReportJson());
      const filename = `Lista de produtores em ${title}`;

      const workbook = this.XLSX.utils.book_new();
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
}
</script>
