<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Metas dos Técnicos
    </v-card-title>
    <v-card-text>
      <v-row class="justify-center">
        <v-col
          cols="12"
          md="4"
        >
          <person-autocomplete-filter
            v-model="itemSelected"
            type="TECHNICAL"
            label="Inserir tecnico"
            dark
            @change="addItem"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="goals"
      hide-default-footer
      disable-sort
      :items-per-page="-1"
    >
      <template #[`item.action`]="{ item }">
        <v-select
          v-model="item.action"
          :items="options"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.visits`]="{ item }">
        <v-text-field
          v-model.number="item.visits"
          placeholder="0"
          type="number"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          @keypress="disableDotAndComma"
        />
      </template>
      <template #[`item.type`]="{ item }">
        <v-select
          v-model="item.type"
          :items="tipos"
          item-value="value"
          item-text="text"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.visitTypes`]="{ item }">
        <v-select
          v-model="item.visitTypes"
          :items="getTypesFilter"
          item-value="value"
          item-text="text"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>

      <template #[`item.period`]="{ item }">
        <v-select
          v-model="item.period"
          :items="periods"
          item-value="value"
          item-text="text"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          color="white"
        >
          <v-icon
            @click="removeItem(item)"
          >
            delete_outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

export default {

  components: {
    PersonAutocompleteFilter,
  },

  props: {
    goal: Array,
  },

  data() {
    return {

      headers: [
        { text: "Técnico", value: "name" },
        { text: "Quantidade", value: "visits", width: 100 },
        { text: '%', value: "type", width: 30  },
        { text: 'Tipo de visita', value: "visitTypes", width: 250  },
        { text: 'Período', value: "period", width: 250  },
        { text: "", value: "actions", width: 30 },
      ],

      periods: [
        { text: "Quinzenal", value: "quinzenal" },
        { text: "Mensal", value: "mensal" },
        { text: "Bimestral", value: "bimestral" },
        { text: "Trimestral", value: "trimestral" },
        { text: "Quadrimestral", value: "quadrimestral" },
        { text: "Quinquenal", value: "quinquenal" },
        { text: "Semestral", value: "semestral" },
        { text: "Anual", value: "anual" },
      ],

      tipos: [
        { text: '%', value: 'PERCENT' },
        { text: 'UN', value: 'UN' }
      ],

      visitTypes: {
        IN59: 'IN59',
        IN77: 'IN77',
        PMLS: 'PMLS',
        BPA: 'BPA',
        PROSPECCAO: 'PROSPECÇÃO',
        COLETA_AMOSTRA: 'COLETA DE AMOSTRA',
        VETERINARIO: 'RECOMENDAÇÕES VETERINÁRIAS',
        ZOO_TECNICO: 'ZOOTÉCNICO',
        VENDER_PRODUTOS: 'VENDA',
        CPP: 'CPP',
        CCS: 'CCS',
        ALTERACOES_NO_LEITE: 'ALTERAÇÕES NO LEITE',
        ACOMPANHAMENTO_DE_ORDENHA: 'ACOMPANHAMENTO DE ORDENHA',
        PAE: 'PAE',
        ACOMPANHAMENTO_DE_ROTA: 'ACOMPANHAMENTO DE ROTA',
        BRUCELOSE_TUBERCULOSE: 'BRUCELOSE/TUBERCULOSE',
        CHECKLIST_INICIAL: 'CHECKLIST INICIAL',
      },

      itemSelected: '',
    };
  },

  computed: {
    goals: {
      get() {
        return this.goal;
      },
      set(goal) {
        this.$emit("input", goal);
      },
    },

    getTypesFilter() {
      const values = Object.values(this.visitTypes);
      return Object.keys(this.visitTypes)
        .reduce((prev, key, i) => ([...prev, { value: key, text: values[i] }]), []);
    },

  },

  methods: {
    addItem() {
      const p = {
        ...this.itemSelected,
        visits: 0,
        visitTypes: '',
        type: 'UN',
        period: 'mensal',
      };

      this.goals.push(p);
      this.$nextTick(() => {
        this.itemSelected = "";
      });
    },

    removeItem(item) {
      const idx = this.goals.findIndex(o => o.id === item.id);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
