import { render, staticRenderFns } from "./LinkingProducersChart.vue?vue&type=template&id=07dd1726"
import script from "./LinkingProducersChart.vue?vue&type=script&lang=js"
export * from "./LinkingProducersChart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports