<template>
  <v-menu
    v-model="showProductMenu"
    transition="slide-y-transition"
    offset-y
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="input"
        :loading="loading"
        append-icon="search"
        v-bind="$attrs"
        v-on="on"
        @change="onChange"
        @click:append="searchProduct"
        @keyup.enter="searchProduct"
      />
    </template>
    <v-list
      v-if="products.length > 0"
      style="max-height: 200px;"
      class="overflow-y-auto overflow-x-hidden"
    >
      <v-list-item
        v-for="(product, i) in products"
        :key="i"
        two-line
        @click="onProductSelected(product)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ product.nome }}</v-list-item-title>
          <v-list-item-subtitle>{{ product.cod_item }} - {{ getProductType(product.tipo) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import isArray from 'lodash/fp/isArray';

export default {

  props: {
    value: String,
  },

  data() {
    return {
      input: null,
      loading: false,
      showProductMenu: false,
      products: [],
    }
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  created() {
    this.input = this.value;
  },

  methods: {
    onChange(event) {
      // atualiza o v-model do componente
      this.$emit('input', event);

      this.$emit('change', event);
    },

    onProductSelected(product) {
      this.$emit('productSelected', product);
    },

    async searchProduct() {
      if (this.loading) {
        return;
      }
      try {
        const busca = this.input;

        if (!busca) {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipos: ['PRODUTO_ACABADO', 'MATERIA_PRIMA'],
          busca
        } });

        if (!isArray(data)) {
          throw data;
        }

        this.products = data;
        this.showProductMenu = true;

      } catch (error) {
        console.error(error);

        this.$snotify.error('Oops, ocorreu um erro ao carregar os produtos!', 'Atenção');
      } finally {
        this.loading = false;
      }
    },

    getProductType(type) {
      const types =  {
        PRODUTO_ACABADO: 'PRODUTO ACABADO',
        MATERIA_PRIMA: 'MATÉRIA PRIMA',
      };

      return types[type] || ''
    },
  }
}
</script>
