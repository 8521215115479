<template>
  <v-card
    class="mx-auto"
    dark
    color="transparent"
    :loading="loading"
  >
    <v-row
      no-gutters
      style="overflow: hidden"
    >
      <v-col
        cols="8"
        class="py-0 pr-0"
        :style="{background: 'rgba(0, 0, 0, 0.4)'}"
      >
        <v-card-text class="white--text text-left">
          <span class="title font-weight-light">Produtores</span>
          <v-row>
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #80d8ff"
            >
              <span class="caption font-weight-light">Ganho</span>
              <span
                class="body-1 font-weight-light text-truncate"
              >
                {{ formatNumber(producers.gain) }}
              </span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #80d8ff"
            >
              <span class="caption font-weight-light">Perdido</span>
              <span
                class="body-1 font-weight-light text-truncate"
              >
                {{ formatNumber(producers.loss) }}
              </span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column py-0"
              style="border-left: 2px solid #80d8ff"
            >
              <span class="subtitle-2 font-weight-light">
                Saldo
              </span>
              <span
                :class="['title font-weight-regular text-truncate', {'green--text text--accent-2': producers.balance > 0, 'red--text text--accent-2': producers.balance < 0}]"
              >
                {{ formatNumber(producers.balance) }}
              </span>
            </v-col>
          </v-row>
          <span class="title font-weight-light">
            Volume
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon
                  small
                  color="rgba(255, 255, 255, 0.7)"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              Estimativa de volume através da média/dia informada no cadastro do produtor
            </v-tooltip>
          </span>
          <v-row>
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #80d8ff"
            >
              <span class="caption font-weight-light">Ganho</span>
              <span
                class="body-1 font-weight-light text-truncate"
              >
                {{ formatNumber(volume.gain) }} L
              </span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #80d8ff"
            >
              <span class="caption font-weight-light">Perdido</span>
              <span
                class="body-1 font-weight-light text-truncate"
              >
                {{ formatNumber(volume.loss) }} L
              </span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column py-0"
              style="border-left: 2px solid #80d8ff"
            >
              <span class="subtitle-2 font-weight-light">Saldo</span>
              <span
                :class="['title font-weight-regular text-truncate', {'green--text text--accent-2': volume.balance > 0, 'red--text text--accent-2': volume.balance < 0}]"
              >
                {{ formatNumber(volume.balance) }} L
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col
        cols="4"
        class="py-0 d-flex"
        :style="{ background: 'rgba(0, 0, 0, 0.5)' }"
      >
        <v-card-text class="white--text align-self-center">
          <div
            class="d-flex flex-column py-0 text-center"
          >
            <span class="title font-weight-light mb-2">Volume</span>
            <v-icon
              style="font-size: 2.8rem;"
            >
              icon-coleta_principal
            </v-icon>
            <span :class="['subtitle-1 mt-2', {'green--text text--accent-2': volumeByDay > 0, 'red--text text--accent-2': volumeByDay < 0}]">
              <b>{{ formatNumber(volumeByDay) }}</b> L/dia
            </span>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {

  props: {
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      producers: {
        gain: 0,
        loss: 0,
        balance: 0,
      },
      volume: {
        gain: 0,
        loss: 0,
        balance: 0,
      },
      volumeByDay: 0
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/relatorios/resumoVinculacaoProdutores`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.volumeByDay = (data.vinculados.volume - data.desvinculados.volume) || 0;

        this.producers = {
          gain: data.vinculados.total || 0,
          loss: data.desvinculados.total || 0,
          balance: (data.vinculados.total - data.desvinculados.total) || 0,
        };

        this.volume = {
          gain: data.vinculados.volume * 30,
          loss: data.desvinculados.volume * 30,
          balance: this.volumeByDay * 30,
        };

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o total de produtores!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
  
}
</script>