<template>
  <div class="mx-6 px-md-16">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Expedição
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#tab-waiting">
            Aguardando
          </v-tab>

          <v-tab href="#tab-finished">
            Finalizados
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          touchless
          style="background: transparent;"
        >
          <v-tab-item value="tab-waiting">
            <shipment-pending
              v-if="tab === 'tab-waiting'"
            />
          </v-tab-item>

          <v-tab-item value="tab-finished">
            <shipment-finished
              v-if="tab === 'tab-finished'"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import ShipmentPending from '@/Domains/Shipment/Shipment/Views/ShipmentPending.vue'
import ShipmentFinished from '@/Domains/Shipment/Shipment/Views/ShipmentFinished.vue'

const tab = ref('tab-waiting')
</script>
