var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"text-xs-center":"","grid-list-lg":""}},[_c('v-flex',{staticClass:"pt-4 mb-4 text-xs-center",attrs:{"xs12":""}},[_c('h2',{staticClass:"menu-header white--text"},[_vm._v(" Relatório Volume Rejeitado ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("arrow_left")]),_c('div',{staticClass:"pr-3"},[_vm._v(" Voltar ")])],1)],1),_c('v-container',[_c('volume-list',{attrs:{"expanded":_vm.produtores,"expanded-name":"Volume Rejeitado Detalhado"},on:{"onDate":_vm.onDate,"onRoute":_vm.onRoute,"onExport":_vm.exportExcel,"onChangeSearch":_vm.onChangeSearch},scopedSlots:_vm._u([{key:"expanded",fn:function({ item }){return [_c('td',{staticClass:"pa-0",attrs:{"colspan":"12"}},[_c('v-container',[_c('v-card',{staticClass:"mt-1 collecton-city",attrs:{"color":"transparent"}},[_c('v-data-table',{staticClass:"technical-visit-table",attrs:{"items":_vm.produtores.filter((x) => x.nome_produtor == item.nome_produtor),"headers":[
                  { text: 'Data', value: 'data', align: 'start', width:250 },
                  { text: 'Volume (Lts)', value: 'quantidade_coleta', width:200 },
                  { text: 'Alizarol', value: 'alizarol', width:200 },
                  { text: 'Temperatura', value: 'temperatura', width:200 },
                  { text: '', value: 'action', align: 'end',width: 5 },
                ],"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [(item.url_coleta && item.url_coleta.length > 0)?[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openPhotos(item);}}},[_c('v-icon',[_vm._v("photo")])],1)]:[_c('div',[_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":""}},[_vm._v(" - ")])],1)]]}}],null,true)})],1)],1)],1)]}}])})],1),_c('v-dialog',{attrs:{"width":"600","max-width":"650"},model:{value:(_vm.photoDialog.show),callback:function ($$v) {_vm.$set(_vm.photoDialog, "show", $$v)},expression:"photoDialog.show"}},[_c('v-card',[_c('v-card-title',[_vm._v("Fotos")]),_c('v-card-text',[_c('v-carousel',_vm._l((_vm.fotos),function(foto,i){return _c('v-carousel-item',{key:i},[_c('img',{attrs:{"src":foto,"width":"100%"}})])}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"blue--text",attrs:{"text":""},on:{"click":function($event){_vm.photoDialog.show = false;}}},[_vm._v(" Fechar ")])],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}},[_c('v-card-text',[_vm._v(" Carregando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }