<template>
  <v-dialog
    v-model="show"
    scrollable
    :max-width="600"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Resgatar Prêmio</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col cols="9">
                Produtor: {{ producer.nome_produtor }}
              </v-col>

              <v-col cols="3">
                <h2>Saldo: {{ formatNumber(current_points) }}</h2>
              </v-col>

              <v-col cols="4">
                <date-text-field
                  v-model="date"
                  label="Data"
                  :max="dateMax"
                />
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="awards"
                  label="Prêmio *"
                  placeholder=" "
                  :items="listAwards"
                  :rules="[(v) => !!v.id || 'Campo obrigatório!']"
                  item-value="id"
                  item-text="text"
                  return-object
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Resgatar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateTextField from "@/Support/Components/DateTextField.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    DateTextField,
  },

  props: {
    value: Boolean,
    idAssistencia: String,
    producer: Object,
    default: () => ({
      nome_produtor: '',
      id_produtor: '',
      saldo_total: '',
    }),
  },

  data() {
    return {
      awards: [],

      current_points: 0,

      date: moment().format('YYYY-MM-DD'),

      dateMax: moment().format('YYYY-MM-DD'),

      listAwards: [],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    producer(value) {
      if (value.id_produtor) {
        this.checkPoints();
      }
    },
    idAssistencia(value) {
      if (value) {
        this.loadAwards();
      }
    }
  },
  async mounted() {
    await this.loadAwards();
  },

  methods: {

    async loadAwards() {
      try {
        this.$root.$progressBar.loading();
        if (!this.idAssistencia) {
          return;
        }

        const { data } = await this.$axios.get(
          `/projects/awards`,
          {
            params: {
              id_assistencia: this.idAssistencia,
            }
          }
        );

        this.listAwards = data.map(item => {
          return {
            id: item.id_premiacao,
            text: `${item.descricao} - Pontos: ${this.formatNumber(item.valor)}`,
            value: item.valor,
            description: item.descricao,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar premios!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async checkPoints() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(
          `/projects/producer/check-points`,
          { params: {
            id_produtor: this.producer.id_produtor,
            id_assistencia: this.idAssistencia,
          } }
        );

        this.current_points = _.first(data).saldo_atual;

      } catch (err) {
        this.$snotify.error("Oops, erro ao carregar saldo produtor!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * resgata pontos
     */
    async save() {
      try {

        if (!await this.$refs.form.validate()) {
          return;
        }
        this.$root.$progressBar.saving();

        let saldo_produtor = this.current_points ? parseInt(this.current_points) : 0;
        let pontos_premio = this.awards.value ? parseInt(this.awards.value) : 0;

        if (saldo_produtor < pontos_premio) {
          this.$snotify.error("Saldo insuficiente para resgatar", "Atenção");
          return;
        }

        const payload = {
          data_classificacao: this.date ? this.date : moment().format('YYYY-MM-DD'),
          id_produtor: this.producer.id_produtor,
          nome_produtor: this.producer.nome_produtor,
          codigo_laticinio: this.producer.codigo_laticinio,
          valor: pontos_premio,
          entrada_saida: 0, //1 - entrada 0 - saida
          id_assistencia: this.idAssistencia,
          descricao: this.awards.description,
          id_premiacao: this.awards.id,
        };

        await this.store(payload);

        this.$snotify.success("Sucesso", "Sucesso");
        this.close();
      } catch (e) {
        this.$snotify.error("Erro ao resgatar prêmio", "Atenção");
        console.warn(e);
      } finally {
        this.$emit('load');
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/producer-points`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/producer-points/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  }


}
</script>
