var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-6 mb-6"},[_c('questions-card',{attrs:{"label":_vm.questionnaireName}},[_c('v-row',[_c('v-col',{staticClass:"py-0 white--text",attrs:{"cols":"12"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.filters.answered.date.range[0] ? _vm.formatDate(_vm.filters.answered.date.range[0], 'DD/MM/YYYY') + ' a ': '')+" "+_vm._s(_vm.filters.answered.date.range[1] ? _vm.formatDate(_vm.filters.answered.date.range[1], 'DD/MM/YYYY'): '')+" ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"producer-list",attrs:{"color":"transparent"}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-row',{staticClass:"pa-0",attrs:{"align-content":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"md":"2"}},[_c('date-range-picker',{on:{"change":_vm.onDateFilter},model:{value:(_vm.filters.answered.date.input),callback:function ($$v) {_vm.$set(_vm.filters.answered.date, "input", $$v)},expression:"filters.answered.date.input"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"md":"2"}},[_c('routes-autocomplete-filter',{attrs:{"label":"Rota","type":"visita","placeholder":" ","small-chips":"","multiple":"","dark":""},model:{value:(_vm.filters.answered.routes),callback:function ($$v) {_vm.$set(_vm.filters.answered, "routes", $$v)},expression:"filters.answered.routes"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"md":"2"}},[_c('person-autocomplete-filter',{attrs:{"label":"Técnico","type":"TECHNICAL","dark":""},on:{"change":_vm.loadProducerwithAnswer},model:{value:(_vm.filters.answered.technical),callback:function ($$v) {_vm.$set(_vm.filters.answered, "technical", $$v)},expression:"filters.answered.technical"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"md":"2"}},[_c('v-select',{attrs:{"dark":"","filled":"","item-text":"text","item-value":"value","return-object":"","label":"Status Produtor","items":[
                    {text:'Todos',value:'*'},
                    {text:'Ativos',value:'ativo'},
                    {text:'Suspenso',value:'suspenso'},
                    {text:'Prospectado',value:'prospectado'},
                    {text:'Desvinculado',value:'desvinculado'},
                  ],"multiple":""},on:{"change":_vm.loadProducerwithAnswer},model:{value:(_vm.producerStatus),callback:function ($$v) {_vm.producerStatus=$$v},expression:"producerStatus"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"filled":"","label":"Buscar","append-icon":"search","single-line":"","hide-details":"","clearable":"","dark":""},model:{value:(_vm.filters.answered.search),callback:function ($$v) {_vm.$set(_vm.filters.answered, "search", $$v)},expression:"filters.answered.search"}})],1)],1)],1),_c('v-data-table',{staticClass:"producer-list-table",attrs:{"dark":"","items":_vm.filteredAnsweredList,"headers":[
              { text: 'Código', value: 'producer.code', width: 100 },
              { text: 'Produtor', value: 'producer.name' },
              { text: 'Status', value: 'producer.status' },
              { text: 'Rotas', value: 'routes' },
              { text: 'Técnicos', value: 'technical.name' },
              { text: 'Preenchimento', value: 'responseAt', width: '150' },
              { text: 'Justificativas', value: 'justification'},
            ]},scopedSlots:_vm._u([{key:`item.responseAt`,fn:function({ value }){return [_c('v-chip',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.formatDate(value, 'DD/MM/YYYY'))+" ")])]}},{key:`item.routes`,fn:function({ item }){return _vm._l((item.routes),function(route){return _c('v-chip',{key:route.id,attrs:{"x-small":""}},[_vm._v(" "+_vm._s(route.description)+" ")])})}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"text-caption text-left ml-4",staticStyle:{"margin-top":"-58px","height":"58px","line-height":"58px"}},[_c('v-col',{staticClass:"event pa-0",attrs:{"md":"4","align-self":"center"}},[_c('transition',{attrs:{"name":"my-transition"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length > 0),expression:"selected.length > 0"}],staticClass:"pa-0 ma-0 btn-event",staticStyle:{"margin-left":"-16px !important"},attrs:{"transition":"my-transition","fab":"","text":"","dark":""},on:{"click":_vm.showPrintDialog}},[_c('v-icon',[_vm._v("get_app")])],1)],1)],1)],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}},[_vm._v(" Carregando... ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }