<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="quality-priority-ranking"
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Classificação dos Produtores (curva ABC)
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="6"
        md="3"
      >
        <v-menu
          ref="menu"
          transition="scale-transition"
          offset-y
          content-class="menu-select"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateRefFilter"
              label="Mês de Referência"
              prepend-inner-icon="event"
              readonly
              clearable
              filled
              dark
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="filter.date"
            :max="maxDateRef"
            type="month"
            no-title
            scrollable
            @change="loadData"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          hide-details
          @change="loadData"
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          hide-details
          @change="loadData"
        />
      </v-col>

      <v-col
        cols="6"
        md="3"
      >
        <v-select
          v-model="filter.ranges"
          :items="listRanges"
          item-text="nome_faixa"
          item-value="id_faixa"
          label="Faixa"
          hide-details
          clearable
          dark
          filled
          @change="loadData"
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-autocomplete
          v-model="filter.status"
          :items="[
            { text: 'Ativos', value: 'ATIVOS' },
            { text: 'Desvinculados', value: 'DESVINCULADOS' },
          ]"
          label="Exibir"
          placeholder=" "
          hide-details
          clearable
          filled
          dark
          @change="loadData"
        />
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#graphs">
        Gráficos
      </v-tab>

      <v-tab href="#reports">
        Relatórios
      </v-tab>

      <v-tab-item value="graphs">
        <v-row>
          <v-col
            md="12"
            sm="12"
            cols="12"
          >
            <v-card
              dark
              class="graph-card"
            >
              <v-card-title>
                <v-icon
                  large
                  left
                >
                  show_chart
                </v-icon>
                <span class="title font-weight-light">Produtor por Faixa (Mês) </span>
              </v-card-title>
              <v-chart
                :options="optionsMonth"
                :style="{height: `300px !important`}"
                autoresize
              />
            </v-card>
          </v-col>
          <v-col
            md="12"
            sm="12"
            cols="12"
          >
            <v-card
              class="graph-card"
              dark
            >
              <v-card-title>
                <v-icon
                  large
                  left
                >
                  show_chart
                </v-icon>
                <span class="title font-weight-light">Anual</span>
              </v-card-title>
              <v-chart
                :options="optionsYear"
                :style="{height: `300px !important`}"
                autoresize
              />
            </v-card>
          </v-col>
        </v-row>

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template v-slot:activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="gerarClassificacao()"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  refresh
                </v-icon>
              </template>
              Gerar Classificação
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
      </v-tab-item>

      <v-tab-item value="reports">
        <v-row>
          <v-col md="12">
            <v-card
              dark
              color="transparent diff-list"
            >
              <v-card-title>
                <v-spacer />
                <v-col
                  cols="4"
                  md-4
                  sm-4
                  class="pa-0 subtitle-1"
                >
                  {{ `Produtores: ${ producers.length}` }}
                </v-col>
              </v-card-title>
              <data-table
                ref="report"
                name="Classificação dos Produtores"
                :headers="headers"
                :items="producers"
                dark
                :items-per-page="producers.length"
                show-custom-group
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template v-slot:activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  print
                </v-icon>
              </template>

              Imprimir
            </v-tooltip>
          </v-btn>
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>
          <v-btn
            fab
            dark
            color="blue darken-1"
            @click="gerarClassificacao()"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  refresh
                </v-icon>
              </template>
              Gerar Classificação
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
      </v-tab-item>
    </v-tabs>
    <v-overlay
      absolute
      :value="loading"
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

export default {
  name: "producer-rating",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
  },

  data() {
    return {
      loading: true,

      tab: null,

      filter: {
        // Dialogs
        datePickerMenu: null,

        // Mês de referência para a bonificação
        date: moment().subtract(1, "month").format("YYYY-MM"),
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        ranges: [],
        status: 'ATIVOS',
      },

      listRanges: [],
      producers: [],
      totalProducers: 0,

      headers: [
        { text: 'Código', value: 'producer.code'  },
        { text: 'Produtor', value: 'producer.name' },
        { text: 'Rota', value: 'route.description' },
      ],

      rangesChart: [], //grafico mensal

      category: [], //datas do grafico anual

      seriesChartYear: [], //dados grafico anual

    };
  },

  computed: {

    maxDateRef() {
      return moment().subtract(1, "month").format("YYYY-MM");
    },

    dateRefFilter() {
      if (_.isEmpty(this.filter.date)) {
        return null;
      }

      return moment(this.filter.date, "YYYY-MM").format("MM/YYYY");
    },

    optionsMonth() {
      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(171, 71, 188, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [{
          name: 'Produtores por faixas',
          type: 'pie',
          radius: [20, 120],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 5
          },
          label: {
            color: '#fff',
            textBorderWidth: 0,
            textBorderColor: 'transparent',
            textShadowBlur: 0
          },
          data: this.rangesChart
        }]
      }
    },

    optionsYear() {
      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(171, 71, 188, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.category,
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: this.seriesChartYear,
      }
    }
  },

  mounted() {
    this.loadRanges();
    this.loadItensQuality();
    this.loadData();
  },

  methods: {

    loadData() {
      this.loadChartMonth();
      this.loadChartYear();
      this.loadProducerRating();
    },

    async loadChartMonth() {
      this.loading = true;
      try {

        let { data } = await this.$axios.post(
          `/relatorios/listaClassificacaoProdutoresFaixaMes`,
          {
            data_referencia: this.filter.date,
            id_usuario: this.filter.technician.id,
            rotas: this.filter.routes,
            id_faixa: this.filter.ranges,
            status: this.filter.status,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.rangesChart = data.map(item => ({ name: item.nome_faixa, value: item.total_produtor }));

        //console.log(this.rangesChart);
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao grafico faixas por mes!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async loadChartYear() {
      this.loading = true;
      try {

        let { data } = await this.$axios.post(
          `/relatorios/listaClassificacaoProdutoresFaixaAnual`,
          {
            data_referencia: this.filter.date,
            id_usuario: this.filter.technician.id,
            rotas: this.filter.routes,
            id_faixa: this.filter.ranges,
            status: this.filter.status,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }
        let dates = data.map(row => row.mes_referencia);
        this.category = [ ...new Set(dates) ]; //unique dates

        this.seriesChartYear = [];

        this.listRanges.forEach(range => {

          let rangeMonth = [];

          this.category.forEach(month => {
            let value = data.find(classificacao => classificacao.mes_referencia === month && classificacao.id_faixa == range.id_faixa);
            if (!_.isObject(value)) {
              value = 0;
            } else {
              value = value.total_produtor;
            }
            rangeMonth.push(value);
          });

          this.seriesChartYear.push({
            name: range.nome_faixa,
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: rangeMonth,
          });
        });

        console.log(this.seriesChartYear);

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao grafico faixas por mes!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async gerarClassificacao() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/classificarProdutor`,
          {
            data_referencia: this.filter.date
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro gerar classificação!", "Atenção");
      } finally {
        this.loadChartMonth();
        this.loadChartYear();
        this.loadProducerRating();
        this.loading = false;
      }

    },

    async loadRanges() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/configuracao/listaFaixasClassificacaoProdutor`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.listRanges = data;

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao listar faixas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadItensQuality() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/configuracao/listaItensClassificacaoProdutor`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        let resultado = '';
        data.forEach(y => {
          switch (y.id_qualidade_consulta) {
            case 1: resultado = 'item.ccs'; break;
            case 2: resultado = 'item.cbt'; break;
            case 3: resultado = 'item.ureia'; break;
            case 4: resultado = 'item.caseina'; break;
            case 5: resultado = 'item.gordura'; break;
            case 6: resultado = 'item.proteina'; break;
            case 7: resultado = 'item.esd'; break;
            case 8: resultado = 'item.solidos'; break;
            case 17: resultado = 'item.cbt_trimestral'; break;
            case 18: resultado = 'item.ccs_trimestral'; break;
            default: resultado = ''; break;
          }

          this.headers.push({ text: y.item, value: resultado, align: 'center' });
        });

        this.headers.push( { text: 'Faixa', value: 'range.name', align: 'center', width: 100 });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao listar itens!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadProducerRating() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/listaClassificacaoProdutoresFaixa`,
          {
            data_referencia: this.filter.date,
            id_usuario: this.filter.technician.id,
            rotas: this.filter.routes,
            id_faixa: this.filter.ranges,
            status: this.filter.status,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }
        this.producers = data.map(row => {
          return {
            producer: {
              id: row.id_produtor,
              name: row.nome_produtor,
              code: row.codigo_laticinio,
            },
            item: {
              ccs: parseInt(row.ccs_resultado),
              cbt: parseInt(row.cbt_resultado),
              ureia: parseInt(row.ureia_resultado),
              caseina: parseInt(row.caseina_resultado),
              gordura: parseInt(row.gordura_resultado),
              proteina: parseInt(row.proteina_resultado),
              esd: parseInt(row.esd_resultado),
              solidos: parseInt(row.solidos_resultado),
              ccs_trimestral: parseInt(row.ccs_trimestral_resultado),
              cbt_trimestral: parseInt(row.cbt_trimestral_resultado),
            },
            range: {
              id: row.id_faixa,
              name: row.nome_faixa
            },
            route: {
              id: row.id_rota || 0,
              description: row.rota || 'sem rota definida',
            },
          }

        });

      } catch (error) {
        console.log(error);
        this.loading = false;

        this.$snotify.error("Oops, ocorreu ao listar classificados!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    print() {
      const title = `Classificação Produtor - ${moment(this.filter.date || moment()).format('MM/YYYY')}`;

      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = `Classificação Produtor ${moment(this.filter.date || moment()).format('MM-YYYY')}`;

      this.$refs.report.exportExcel(null, filename);
    },
  },
};
</script>
