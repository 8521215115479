<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Mapa de Frete
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        color="transparent"
        dark
        class="mt-5"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="2"
            >
              <v-menu
                ref="menu"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="dateRefFilter"
                    label="Mês de Referência"
                    prepend-inner-icon="event"
                    readonly
                    filled
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filters.date"
                  :max="maxDateRef"
                  type="month"
                  no-title
                  scrollable
                  @change="loadReport"
                />
              </v-menu>
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
              />
            </v-col>
          </v-row>
        </v-card-title>
        <data-table
          ref="report"
          name="Mapa de Frete"
          :headers="headers"
          :items="items"
          :search="filters.search"
          :loading="loading"
          group-by="nome_prestador"
          :items-per-page="50"
        />
        <v-card-title>
          <v-spacer />
        </v-card-title>
      </v-card>
    </v-flex>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import ReportMixin from '@/Support/Mixins/ReportMixin.js';
import moment from 'moment-timezone';
import _ from 'lodash';
import { groupItems } from 'vuetify/lib/util/helpers'

export default {

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        date: moment().subtract(1, 'month').format('YYYY-MM'),
        search: '',
      },

      headers: [
        { text: 'Transportador', value: 'nome_prestador' },
        { text: 'Rota', value: 'nome_rota' },
        { text: 'Capacidade', value: 'capacidade' },
        { text: 'Total Viagens', value: 'total_viagens', align: 'end', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Total Produtores', value: 'total_produtores', align: 'end', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Tempo Total', value: 'data_hora_total', align: 'end', formatter: value => value ? value.replace(new RegExp('^[00:]+'), '') : '00:00' },
        { text: 'Frete Pago', value: 'total_frete', align: 'end', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.0000' },
        { text: 'Total Km', value: 'total_distancia', align: 'end', formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: 'Km/Viagem', value: 'media_km_viagem', align: 'end', formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: 'L/Km', value: 'media_litros_km', align: 'end', formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: 'L/Viagem', value: 'media_vol_viagem', align: 'end', formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: 'Total Vol.', value: 'total_volume', align: 'end', formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: 'Taxa Ocup. (%)', value: 'taxa_ocupacao', align: 'end', formatter: value => this.formatNumber(value) + '%', mask: '#,##0.00' },
        { text: 'R$/L', value: 'valor_litro', align: 'end', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.0000' },
      ],

      items: [],
    };
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format('YYYY-MM');
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, 'YYYY-MM').format('MM/YYYY');
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/freight/reports/route-freight`, { params: {
          month_reference: this.filters.date,
        } });

        this.items = data;
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error('Erro ao carregar o relatório', 'Atenção');
      }
      finally {
        this.loading = false;
      }
    },

    getReportJson(isExcel = false) {
      const json = this.$refs.report.getReportJson(isExcel);
      const groupBy = this.$refs.report.groupedColumns[0];

      const report = [];
      const headers = Object.keys(json[0]);

      groupItems(json, [groupBy.text])
        .forEach(({ items }) => {
          const totalViagens = _.sumBy(items, 'Total Viagens');
          const totalKm = _.sumBy(items, 'Total Km');
          const totalVol = _.sumBy(items, 'Total Vol.');
          const totalFrete = _.sumBy(items, 'Frete Pago');
          const ocupTotal = items.reduce((acc, cur) => acc + (cur['Taxa Ocup. (%)'] * cur['Total Viagens']), 0);

          const totals = headers.reduce((acc, cur) => {
            if (cur === 'Transportador') {
              acc[cur] = items[0][cur];
            }
            else if (cur === 'Taxa Ocup. (%)') {
              acc[cur] = ocupTotal / totalViagens;
            }
            else if (cur === 'Km/Viagem') {
              acc[cur] = totalKm / totalViagens;
            }
            else if (cur === 'L/Km') {
              acc[cur] = totalVol / totalKm;
            }
            else if (cur === 'L/Viagem') {
              acc[cur] = totalVol / totalViagens;
            }
            else if (cur === 'R$/L') {
              acc[cur] = totalFrete / totalVol;
            }
            else if (['Frete Pago', 'Total Km', 'Km/Viagem', 'Total Vol.', 'Total Viagens'].includes(cur)) {
              acc[cur] = _.sumBy(items, cur);
            }
            else {
              acc[cur] = null;
            }
            return acc;
          }, {});

          report.push(...items, totals);
        });
      return report;
    },

    print() {
      const title = `Mapa de Frete- ${moment(this.filters.date, 'YYYY-MM').format('MMM-YY').toUpperCase()}`;

      const json = this.getReportJson();

      this.$refs.report.print(json, title);
    },

    exportExcel() {
      const filename = `Mapa de Frete - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;

      const json = this.getReportJson(true);

      this.$refs.report.exportExcel(json, filename);
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format('MMM/YY')),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
