<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Relatório Produtor por Região
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-container>
      <v-card
        class="v-tamanho collecton-city"
        color="transparent"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="4"
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-icon="search"
            />
          </v-col>
        </v-card-title>
        <v-data-table
          class="collecton-city-table"
          dark
          :items="data"
          :search="filters.search"
          :headers="[
            { text:'Nome', value:'nome'},
            { text:'Código', value:'codigo_laticinio'},
            { text:'Região', value:'regiao'},
          ]"
        />
      </v-card>
    </v-container>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="#6776c7"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import XLSX from "xlsx-js-style";
import qs from "qs";
import moment from "moment-timezone";

export default {

  data() {
    return {
      filters: {
        date: {
          input: "today",
          range: [],
        },
        search: null,
      },
      data: [],
      loading: false,
    };
  },

  mounted() {
    this.loadProdutorRegiao();
  },

  methods: {
    async loadProdutorRegiao() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/produtorRegiao`,
          qs.stringify({
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.first(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) :  moment().format("YYYY-MM-DD"),
          })
        );

        this.data = data;
        console.log('RETORNO', this.data);
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event
      this.loadProdutorRegiao();
    },

    exportExcel() {
      let data = XLSX.utils.json_to_sheet(this.data.map((item) => {
        return {
          Nome: item.nome,
          Código: item.codigo_laticinio,
          Região: item.regiao,
        };
      }), { header: ['Nome', 'Código', 'Região'] });

      const workbook = XLSX.utils.book_new();

      const filename = "produtores_regiao";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },

}
</script>
