<template>
  <v-dialog
    v-model="show"
    width="30%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Edição/Cadastro</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                v-if="type==='TECNICO'"
                cols="12"
              >
                <v-switch
                  v-model="form.forAll"
                  label="Aplicar a Todos os Técnicos"
                  dense
                  hide-details
                  class="mt-0"
                  color="blue accent-3"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <person-autocomplete-filter
                  v-if="type==='TECNICO'"
                  v-model="form.people"
                  type="TECHNICAL"
                  prepend-inner-icon="person"
                  hide-details
                  label="Técnico"
                  :filled=false
                  :disabled="form.forAll"
                />
              </v-col>
              <v-col
                v-if="isServices"
                cols="12"
              >
                <v-autocomplete
                  v-model="form.groupService"
                  :items="groupList"
                  item-text="nome"
                  item-value="id_grupo_servico"
                  :label="'Grupo de '+wordSerialize(subtype)"
                  prepend-inner-icon="tour"
                  :loading="loading"
                  :rules="isRequired"
                  @change="loadServices"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="isServices"
                cols="12"
              >
                <v-autocomplete
                  v-model="form.service"
                  :items="services"
                  item-text="descricao"
                  item-value="id_servico"
                  :label="'Serviços '+wordSerialize(subtype)"
                  prepend-inner-icon="tour"
                  :loading="loadingServices"
                  :rules="isRequired"
                >
                </v-autocomplete>
              </v-col>

              <v-col
                v-if="isServices"
                cols="12"
              >
                <v-text-field
                  v-model="form.basePrice"
                  type="number"
                  label="Valor Base $"
                  placeholder="Valor Base $"
                  :rules="isRequired"
                ></v-text-field>
              </v-col>


              <v-col
                v-if="!isServices && type==='TECNICO'"
                cols="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-autocomplete
                      v-model="form.kmRange"
                      :items="kmRangeList"
                      item-text="faixa_quilometragem"
                      item-value="id_faixa_quilometragem"
                      label="Faixa de Quilometragem"
                      prepend-inner-icon="route"
                      :loading="loading"
                      :rules="isRequired"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="!isServices && type==='TECNICO'"
                cols="12"
              >
                <v-text-field
                  v-model="form.basePrice"
                  type="number"
                  label="Valor Base $"
                  placeholder="Valor Base $"
                  :rules="isRequired"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-if="type !== 'PRODUTOR'"
                  v-model="form.minimPrice"
                  type="number"
                  placeholder="Valor Mínimo$"
                ></v-text-field>
            </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-if="type !== 'PRODUTOR'"
                  v-model="form.maximPrice"
                  type="number"
                  placeholder="Valor Máximo $"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-row
                  v-if="type === 'PRODUTOR'"
                >
                  <v-col
                    v-if="!isServices"
                    cols="12"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <v-autocomplete
                          v-model="form.kmRange"
                          :items="kmRangeList"
                          item-text="faixa_quilometragem"
                          item-value="id_faixa_quilometragem"
                          label="Faixa de Quilometragem"
                          prepend-inner-icon="route"
                          :loading="loading"
                          :rules="isRequired"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        v-if="!isServices && type==='PRODUTOR'"
                        cols="12"
                      >
                        <v-text-field
                          v-model="form.basePrice"
                          type="number"
                          label="Valor Base $"
                          placeholder="Valor Base $"
                          :rules="isRequired"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <span>Associado</span>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="form.associatedMinPrice"
                      type="number"
                      label="Valor Mínimo $"
                      placeholder="Valor Mínimo $"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="form.associatedMaxPrice"
                      type="number"
                      label="Valor Máximo $"
                      placeholder="Valor Máximo $"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <span>Não Associado</span>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="form.unassociatedMinPrice"
                      type="number"
                      label="Valor Mínimo $"
                      placeholder="Valor Mínimo $"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="form.unassociatedMaxPrice"
                      type="number"
                      label="Valor Máximo $"
                      placeholder="Valor Máximo $"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import isEmpty from "lodash/isEmpty";

export default {

  components: {
    PersonAutocompleteFilter,
  },

  props: {
    value: Boolean,
    editId: String,
    type: String,
    subtype: String,
  },

  data() {
    return {
      loading: false,
      loadingServices: false,
      form: {
        people: null,
        service: null,
        basePrice: null,
        startKm: null,
        endKm: null,
        associatedMinPrice: null,
        associatedMaxPrice: null,
        unassociatedMinPrice: null,
        unassociatedMaxPrice: null,
        kmRange: null,
        forAll: false,
        groupService: null,
      },
      services: [],
      servicesList: ['VISITA', 'VETERINARIO', 'AGRONOMICO'],
      kmRangeList: [],
      groupList: [],
      isRequired: [(v) => !!v || 'Este campo é obrigatório'],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    isServices() {
        return this.servicesList.includes(this.subtype);
    }
  },

  async mounted() {
    this.loadGroupServices();
    this.loadKmRanges();
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          description: null,
        };

        if (this.editId) {
          this.loadPrice();
        }
      }
    }
  },

  methods: {

    async loadPrice() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/financial/price/${this.editId}`);

        this.form = {
          id: data.id_tabela_valor,
          people: data.id_pessoa,
          service: data.id_servico,
          groupService: data.id_grupo_servico,
          basePrice: data.valor,
          minimPrice: data.valor_minimo,
          maximPrice: data.valor_maximo,
          type: data.tipo,
          subtype: data.subtipo,
          startKm: data.km_inicial,
          endKm: data.km_final,
          associatedMinPrice: data.valor_min_associado,
          associatedMaxPrice: data.valor_max_associado,
          unassociatedMinPrice: data.valor_min_nao_associado,
          unassociatedMaxPrice: data.valor_max_nao_associado,
          kmRange: data.id_faixa_quilometragem,
          forAll: data.aplica_todos,
        };
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadServices() {

      try {
        this.loadingServices = true;

        const { data } = await this.$axios.get('registrations/service', {
          params: {
            tipo: this.subtype,
            group: this.form.groupService,
          }
        });

        this.services = data;
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.loadingServices = false;
      }
    },

    async loadGroupServices() {

      try {
        this.loading = true;

        const { data } = await this.$axios.get('registrations/service-group', {
          params: {
            tipo: this.subtype,
          }
        });

        console.log(data);

        this.groupList = data;
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.loading = false;
      }
    },

    async loadKmRanges() {

      try {
        this.loading = true;

        const { data } = await this.$axios.get('registrations/km-range');
        this.kmRangeList = data;
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        if (this.form.forAll) {
          this.form.people = null;
        }

        const payload = {
          id_id_tabela_valor: this.form.id,
          id_servico: this.form.service,
          id_pessoa: !isEmpty(this.form.people) ? this.form.people.id : '',
          tipo: this.type,
          subtipo: this.subtype,
          valor_minimo: this.form.minimPrice,
          valor_maximo: this.form.maximPrice,
          valor: this.form.basePrice,
          km_inicial: this.form.startKm,
          km_final: this.form.endKm,
          valor_min_associado: this.form.associatedMinPrice,
          valor_max_associado: this.form.associatedMaxPrice,
          valor_min_nao_associado: this.form.unassociatedMinPrice,
          valor_max_nao_associado: this.form.unassociatedMaxPrice,
          id_faixa_quilometragem: this.form.kmRange,
          aplica_todos: this.form.forAll,
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);

        this.$snotify.success("Valor cadastrado com sucesso", "Sucesso");

        this.close();

      } catch (e) {

        this.$snotify.error("Erro ao cadastrar serviço", "Atenção");

        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`financial/price`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`financial/price/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    },

    wordSerialize(word) {
      return word.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
    }
  }

}
</script>
