<template>
  <div>
    <v-row>
      <v-col>
        <v-chart
          ref="chartChecklistProducer"
          :options="generateGraphOptions(checklistProducer,checklist.text)"
          :style="{width: '100%', height: `350px !important`}"
          autoresize
        />
      </v-col>
    </v-row>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando checklists...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";

export default {
  name: "questionnaire-graphic",

  components: {
    
  },
  
  props: {
    checklist: Object,
    producers: Array,
  },

  data() {
    return {
      loading: false,
    };
  },
  computed: {
    checklistProducer() {
      let data = [];
      if (!_.isEmpty(this.checklist) && !_.isEmpty(this.producers)) {
        data = this.producers.map((p => {
          const checklistMonth = this.checklist.items.map(item => {
            if ( moment(item.mes) <= moment(p.mes)) {
              return item;
            }
          })
          let total_aplicado = _.sumBy(checklistMonth, 'ativos') || 0 ; //soma o acumulado dos meses anteriores
          let percentual_aplicado = Math.floor(100 * total_aplicado / p.ativos)
          return {
            mes: p.mes,
            percentual_ativos: 100,
            percentual_aplicado: percentual_aplicado,
            ativos: p.total_ativos,
            aplicado: total_aplicado,
          }
        }));
      }
      return data;
    },
  },
    
  methods: {
    
    generateGraphOptions(data, title) {

      let goal = ` Aplicar em ${this.checklist.producer}% dos produtores ${this.checklist.period}`;

      if (_.isEmpty(data)) {
        return;
      }
      
      return {
        color: [ 'rgba(229, 57, 53, 0.7)', 'rgba(38, 198, 218, 0.7)'],
        title: {
          text: `${title}`,
          subtext: `Checklist aplicado acumulado mês a mês | Meta: ${goal}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          padding: [0, 0, 0, 50],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
          formatter: items =>  [
            items[0].name,
            `Ativos: <b>${data[items[0].dataIndex].ativos} </b>`,
            `Total Acumulado Aplicado: <b>${data[items[0].dataIndex].aplicado} </b>`,
            `Percentual Aplicado: <b>${data[items[0].dataIndex].percentual_aplicado}% </b>`,
          ].join('<br>')
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {

                const image = this.$refs.chartChecklistProducer.getDataURL({ backgroundColor: '#0c3b69' });
                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.mes)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Produtores ativos',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: false
            },
            data: data.map(o => o.percentual_ativos),
          },
          {
            name: 'Aplicados',
            type: 'bar',
            barGap: '-100%',
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
              formatter: '  {c} % ',
            },
            data: data.map(o => o.percentual_aplicado),
          }
        ]
      };
    },
    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
  },
};
</script>