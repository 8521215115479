export default {
  namespaced: true,
  state: {
    inMaintenance: false,
  },
  mutations: {
    setMaintenanceMode(state) {
      state.inMaintenance = true;
    },
  },
};
