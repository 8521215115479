<template>
  <v-container
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filters.dateRange"
                  dark
                  @change="loadMessages()"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filters.search"
            dark
            show-expand
            single-expand
          >
            <template #[`item.data_hora_cadastro`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY — HH:mm') }}
            </template>

            <template #[`item.remetente.nome`]="{ value, item }">
              {{ value || item.id_pessoa_remetente || 'SISTEMA' }}
            </template>

            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pa-0 text-left"
              >
                <v-list
                  class="transparent"
                >
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip
                          v-for="destinatario in item.destinatarios"
                          :key="destinatario.id"
                          small
                        >
                          {{ destinatario.pessoa?.nome }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>message</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-for="(item, idx) in item.conteudo"
                        :key="idx"
                      >
                        <v-img
                          v-if="item.tipo == 'IMAGEM'"
                          max-height="200"
                          :src="item.url"
                          class="my-2"
                        />
                        <div
                          v-else
                          class="my-2"
                          v-html="item.texto"
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';

import moment from 'moment-timezone';

export default {
  name: 'notifications-tab',

  components: {
    VDateRangePicker,
  },

  data() {
    return {
      loading: false,

      filters: {
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        search: '',
      },

      headers: [
        { text: 'Titulo', value: 'notificacao.title' },
        { text: 'Data', value: 'data_hora_cadastro' },
        { text: 'Enviado por', value: 'remetente.nome' },
        { text: 'Notificação', value: 'notificacao.body' },
        { text: 'Destinatários', value: 'destinatarios.length' },
      ],

      items: [],
    };
  },

  methods: {
    /**
     * Recupera as mensagens do servidor
     */
    async loadMessages() {
      this.loading = true;
      try {

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/messaging`, { params: {
          data_inicio: startDate,
          data_fim: endDate,
        } });

        this.items = data;
      } catch (err) {
        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
