<template>
  <div
    class="ml-10 mr-10"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-autocomplete
          v-model="filter.typeExam"
          :items="typeExamFilter"
          label="Tipo Exame"
          prepend-inner-icon="biotech"
          dark
          filled
          clearable
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          hide-details
          @change="onFilter"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-autocomplete
          v-model="filter.exam"
          :items="exams"
          label="Exames"
          prepend-inner-icon="biotech"
          dark
          filled
          clearable
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg
      >
        <v-select
          v-model="filter.expiration.type"
          :items="expirationFilter"
          label="Vencimento"
          prepend-inner-icon="event"
          dark
          filled
          clearable
          hide-details
          @change="onExpirationFilter"
        >
          <template #item="{ item }">
            <template v-if="item.value === filter.expiration.type">
              <span class="grow"> {{ item.text }} </span>
              <v-btn
                icon
                light
                @click="onExpirationFilter"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
            <template v-else>
              {{ item.text }}
            </template>
          </template>

          <template #selection="{ item }">
            <span v-if="filter.expiration.date">
              {{ item.text }} {{ formatDate(filter.expiration.date, 'DD/MM/YYYY') }}
            </span>
            <span v-else>
              {{ item.text }}
            </span>
          </template>
        </v-select>
        <v-dialog
          v-model="filter.expiration.show"
          persistent
          width="290"
        >
          <v-date-picker
            v-model="filter.expiration.date"
            reactive
            @change="filter.expiration.show = false"
          />
        </v-dialog>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg
      >
        <v-autocomplete
          v-model="filter.typeComingDue"
          :items="typeComingDueFilter"
          label="À vencer"
          prepend-inner-icon="event"
          dark
          filled
          clearable
          hide-details
          @change="onFilter"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg
      >
        <v-autocomplete
          v-model="filter.vaccine"
          :items="vaccinesFilter"
          label="Vacinas"
          prepend-inner-icon="icon-sanidade"
          dark
          filled
          clearable
          hide-details
        />
      </v-col>

      <v-col
        v-if="cooperatives.length > 0"
        cols="12"
        sm="6"
        md="4"
        lg
      >
        <v-autocomplete
          v-model="filter.cooperatives"
          :items="cooperatives"
          label="Cooperativa"
          prepend-inner-icon="apartment"
          item-text="name"
          item-value="id"
          multiple
          dark
          filled
          clearable
          hide-details
          @change="onFilter"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>
    </v-row>

    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        {{ `Produtores: ${ dataLength }` }}
      </v-card-title>
        <data-table
          ref="report"
          :headers="filteredHeaders()"
          :items="filteredData()"
          :search="filter.search"
          dark
          show-custom-group
          >
        </data-table>
      <v-card-title>
        <v-spacer />
      </v-card-title>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import moment from "moment-timezone";
import _ from "lodash";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  name: "report-brucelose-tuberculose",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter
  },

  mixins: [ReportMixin],

  data() {
    return {
      filter: {
        technician: {
          id: "",
          description: ""
        },
        expiration: {
          show: false,
          type: null,
          date: null
        },
        routes: [],
        cooperatives: [],
        search: '',
        exam: null,
        vaccine: null,
        typeExam: null,
        typeComingDue: null
      },

      data: [],
      dataLength: 0,

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome' },
        { text: 'CPF', value: 'cnpj_cpf' },
        { text: 'Cidade', value: 'end_cidade' },
        { text: 'Região', value: 'regiao' },

        { text: 'Vac. (Data)', value: 'vacinas.data' },
        { text: 'Vac. (Qtd. Animais)', value: 'vacinas.quantidade_animais' },
        { text: 'Vac. (Veterinário)', value: 'vacinas.veterinarian.nome' },

        { text: 'Bruc. (Normativa)', value: 'brucelose_normativa' },
        { text: 'Bruc. (Data)', value: 'brucelose.data' },
        { text: 'Bruc. (Qtd. Animais)', value: 'brucelose.quantidade_animais' },
        { text: 'Bruc. (Veterinário)', value: 'brucelose.veterinarian.nome' },
        { text: 'Bruc. (Vencimento)', value: 'brucelose.data_vencimento' },

        { text: 'Tub. (Normativa)', value: 'tuberculose_normativa' },
        { text: 'Tub. (Data)', value: 'tuberculose.data' },
        { text: 'Tub. (Qtd. Animais)', value: 'tuberculose.quantidade_animais' },
        { text: 'Tub. (veterinário)', value: 'tuberculose.veterinarian.nome' },
        { text: 'Tub. (Vencimento)', value: 'tuberculose.data_vencimento' },
      ],

      exams: [
        { value: 'WITH_EXAM', text: 'Com Exame' },
        { value: 'WITHOUT_EXAM', text: 'Sem Exame' },
      ],

      typeExamFilter: [
        { value: 'BRUCELOSE', text: 'Brucelose' },
        { value: 'TUBERCULOSE', text: 'Tuberculose' },
      ],

      typeComingDueFilter: [
        { value: 15, text: '15 dias' },
        { value: 30, text: '30 dias' },
        { value: 60, text: '60 dias' },
        { value: 90, text: '90 dias' },
        { value: 120, text: '120 dias' },
      ],

      expirationFilter: [
        { value: 'EXPIRED', text: 'Vencido' },
        { value: 'UNEXPIRED', text: 'Não Vencido' },
        { value: 'EXPIRES_UNTIL', text: 'Vencimento até:' },
      ],

      vaccinesFilter: [
        { value: 'WITH_VACCINE', text: 'Com Vacina' },
        { value: 'WITHOUT_VACCINE', text: 'Sem Vacina' },
      ],

      cooperatives: [],
    };
  },

  created() {
    this.loadCooperatives();
    this.loadExam();
  },

  methods: {
    onFilter: _.debounce(function () {
      this.loadExam();
    }, 1000),

    filteredData() {
      let data = _.cloneDeep(this.data);
      const today = moment().format("YYYY-MM-DD");

      switch (this.filter.exam) {
        case 'WITH_EXAM':
          data = this.dataExams(data, this.filter.typeExam);
          break;
        case 'WITHOUT_EXAM':
          data = this.dataExams(data, this.filter.typeExam, false);
          break;
      }

      switch (this.filter.expiration.type) {
        case 'EXPIRED':
          data = this.expiredAt(data, this.filter.typeExam, today);
          break;
        case 'UNEXPIRED':
          data = this.regularExams(data, this.filter.typeExam, today);
          break;
        case 'EXPIRES_UNTIL':
          data = this.filter.expiration.date ? this.expiredAt(data, this.filter.typeExam, this.filter.expiration.date) : data;
          break;
      }

      switch (this.filter.vaccine) {
        case 'WITH_VACCINE':
          data = this.dataVaccines(data);
          break;
        case 'WITHOUT_VACCINE':
          data = this.dataVaccines(data, false);
          break;
      }

      if(this.filter.typeComingDue) {
        data = this.comingDueExams(data, this.filter.typeExam, today, this.filter.typeComingDue);
      }

      return this.search(this.formatExams(data));
    },

    onExpirationFilter(value) {
      this.filter.expiration.type = value;
      this.filter.expiration.date = null;
      this.filter.expiration.show = value === 'EXPIRES_UNTIL';
    },

    async loadExam() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
          tecnico: this.filter.technician.id,
          rotas: this.filter.routes.map(({ id }) => id),
          cooperativas: this.filter.cooperatives,
        } });

        this.data = data;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    print() {
      this.$refs.report.print(null, "Relatório de Exames Sanitários");
    },

    exportExcel() {
      this.$refs.report.exportExcel(null, "Relatório de Exames Sanitários");
    },

    filteredHeaders() {
      if(!this.filter.typeExam) {
        return this.headers;
      }

      return this.headers.filter(item => {
        let typeExam = item.value.split(".")[0].toUpperCase();

        typeExam = typeExam == 'BRUCELOSE_NORMATIVA' ? 'BRUCELOSE' : typeExam;
        typeExam = typeExam == 'TUBERCULOSE_NORMATIVA' ? 'TUBERCULOSE' : typeExam;

        const typeExamVerify = (typeExam != 'BRUCELOSE' && typeExam != 'TUBERCULOSE');
        const typeExamFilter = (this.filter.typeExam == typeExam);

        return typeExamFilter || typeExamVerify;
      });
    },

    dataExams(data, type, hasExam = true) {
      const exams = (exam = type.toLowerCase()) => hasExam ? data.filter(item => item[exam]) :  data.filter(item => !item[exam]);
      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    dataVaccines(data, hasVaccine = true) {
      return data.filter(item => hasVaccine ? item['vacinas'] : !item['vacinas']);
    },

    expiredAt(data, type, date) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento <= date));
      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    comingDueExams(data, type, today, days) {
      const coming = moment().add(days, "days").format("YYYY-MM-DD");
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento < coming && item[exam].data_vencimento >= today));

      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    regularExams(data, type, today) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento > today));
      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    formatExams(data) {
      const format = (item, isVaccine = false) => {
        const { data, data_vencimento, quantidade_animais, ...rest } = item || {};
        return {
          data: data ? this.formatDate(data, 'DD/MM/YYYY') : (isVaccine ? 'Sem Vacina' : 'Sem Exame'),
          quantidade_animais: quantidade_animais || 0,
          data_vencimento: !isVaccine && data_vencimento ? this.formatDate(data_vencimento, 'DD/MM/YYYY') : null,
          ...rest
        };
      };

      return data.map(({brucelose, tuberculose, vacinas, brucelose_normativa, tuberculose_normativa, ...rest}) => ({
        brucelose: format(brucelose),
        tuberculose: format(tuberculose),
        vacinas: format(vacinas, true),
        brucelose_normativa: this.formatDate(brucelose_normativa, 'DD/MM/YYYY'),
        tuberculose_normativa: this.formatDate(tuberculose_normativa, 'DD/MM/YYYY'),
        ...rest
      }));
    },

    formatDate(value, format) {
      if(!value) {
        return '';
      } else {
        return moment(value).format(format);
      }
    },

    search(data) {
      var filtered = this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
      this.dataLength = filtered.length;
      return filtered;
    },

    async loadCooperatives() {
      try {

        const { data } = await this.$axios.post(`/cooperativa/listaJson`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.cooperatives = data.map(item => ({
          id: item.id_cooperativa,
          name: item.nome_cooperativa,
        }));
      } catch (error) {
        console.warn(error);
      }
    },
  },


};
</script>
