<template>
  <div>
    <div
      class="d-flex justify-center"
    >
      <v-alert
        v-if="isAlertVisible"
        dense
        light
        prominent
        colored-border
        border="left"
        elevation="2"
        :type="alert.type"
        width="50vw"
        style="position: fixed; bottom: 0; left: 25%"
      >
        <v-row
          class="pa-0"
          align="center"
        >
          <v-col class="grow py-0">
            <div class="text-h6 text-left">
              {{ alert.title }}
            </div>
            <div
              class="body-1 text-left"
              v-html="alert.message"
            />
          </v-col>
          <v-col class="shrink py-0 ">
            <v-btn
              outlined
              color="primary"
              @click="closeAlert()"
            >
              Fechar Aviso
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-alert
        v-if="isInMaintenance"
        light
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
        width="50vw"
        style="position: fixed; top: 40%; left: 25%"
      >
        <div class="text-left">
          <div class="text-h6">
            Em manutenção
          </div>

          <v-divider
            class="my-4 warning"
            style="opacity: 0.22"
          />

          <span class="text-subtitle-1">Olá, nossos sistemas estão em manutenção!</span> <br>
          <span class="text-subtitle-1">Obrigado pela compreensão.</span>
        </div>
      </v-alert>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { useStore } from '@/Support/Resources/vuex.js';

const store = useStore();

const settings = computed(() => {
  return store.state.settings || {};
});

const maintenance = computed(() => {
  return store.state.maintenance || {};
});

const alert = computed(() => {
  return settings.value.alert || {};
});

const isInMaintenance = computed(() => {
  return maintenance.value.inMaintenance;
});

const storageKey = computed(() => {
  return `alert_hidden_at`;
});

const isAlertVisible = computed(() => {
  const alertHiddenAt = localStorage.getItem(storageKey.value);

  // Caso o alerta não esteja com flag para ser exibido
  if (!alert.value.show) {
    return false;
  }

  // Verifica se o front está em modo de manutenção (Remover quando migrar a tela de manut. para fora do app)
  if (maintenance.value.inMaintenance) {
    return false;
  }

  // Verifica se agora é maior que a data para exibição do alerta
  if (moment().isBefore(moment(alert.value.startAt))) {
    return false;
  }

  // Verifica se agora passou da hora fim de exibição do alerta
  if (moment().isAfter(moment(alert.value.endAt))) {
    return false;
  }

  // Caso o usuário ainda não tenha clicado para
  if (isEmpty(alertHiddenAt)) {
    return true;
  }

  // Caso já tenha passado do intervalo previsto para o usuário esconder o aviso
  return moment().isAfter(moment(alertHiddenAt).add(alert.value.showInterval, 'hours'));
});

function closeAlert() {
  localStorage.setItem(storageKey.value, moment().format('YYYY-MM-DD HH:mm:ss'));

  alert.value.show = false;
}
</script>
