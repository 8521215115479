<template>
  <div>
    <v-row>
      <v-col
        md="11"
        class="pb-12"
      >
        <v-chart
          ref="chart"
          type="bar"
          :options="Options"
          :style="{width: '100%', height: `300px !important`}"
          autoresize
        />
      </v-col>
      <v-col
        v-if="media.length > 0 && !goal.media"
        md="1"
        class="pt-12"
      >
        <p>Médias </p>
        <p>Meta: {{ parseInt(media[0].goals) }} </p>
        <p>Inicial: {{ parseInt(mediaFirst) }} </p>
        <p>
          Final: {{ parseInt(media[media.length-1].media) }}
          <v-icon
            right
            :color="media[media.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
          >
            verified
          </v-icon>
        </p>
      </v-col>
      <v-col
        v-else-if="media.length > 0 && goal.media"
        md="1"
        class="pt-12"
      >
        <p>Médias </p>
        <p>Meta: {{ parseInt(goal.percentual) }} {{ (goal.tipo == "PERCENT" ? "%" : "") }} </p>
        <p>
          <v-icon
            right
            :color="media[media.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
          >
            verified
          </v-icon>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="12"
        class="pb-12"
      >
        <v-card
          color="transparent"
          dark
          class="mt-5"
        >
          <v-card-title>
            <v-spacer />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="media"
            item-key="data"
            :loading="loading"
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
            show-expand
            single-expand
            @item-expanded="showDetails"
          >
            <template #[`item.data`]="{value}">
              {{ formatMonth(value) }}
            </template>
            <template #expanded-item="{headers}">
              <td
                :colspan="headers.length"
                class="pa-5 text-center"
              >
                <v-data-table
                  :headers="details.headers"
                  :items="details.items"
                  class="elevation-1"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template #[`item.status`]="{value}">
                    <v-chip
                      v-if="value == 'DESVINCULADO'"
                      color="red"
                      small
                    >
                      <v-avatar
                        left
                        small
                      >
                        <v-icon>cancel</v-icon>
                      </v-avatar>
                      D
                    </v-chip>
                    <v-chip
                      v-else-if="value == 'SUSPENSO'"
                      color="orange"
                      small
                    >
                      <v-avatar
                        left
                        small
                      >
                        <v-icon>remove_circle</v-icon>
                      </v-avatar>
                      S
                    </v-chip>
                    <v-chip
                      v-else
                      color="green"
                      small
                    >
                      <v-avatar
                        left
                        small
                      >
                        <v-icon>check_circle</v-icon>
                      </v-avatar>
                      A
                    </v-chip>
                  </template>
                  <template #[`item.saida`]="{value}">
                    {{ formatDate(value,'DD/MM/YY') }}
                  </template>
                  <template #[`item.media`]="{value}">
                    {{ formatNumber(value) }}
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";

export default {
  name: "quality-report",

  components: {
  },

  props: {
    goal: Object,

    filter: Object,

    typeProject: String,
  },

  data() {
    return {
      loading: false,
      media: [],
      mediaFirst: '',
      tituloGrafico: '',
      subtitle: '',

      headers: [
        { text: "Mês", value: "data" },
        { text: "Produtores", value: "totalProdutores", width: 120, align: "center" },
      ],

      details: {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: "Produtor", value: "nome_produtor" },
          { text: "Resultado", value: "media" },
          { text: "Status", value: "status", align: 'center' },
          { text: "Saída", value: "saida", align: 'center' },
          { text: "Laticinio", value: "nome_laticinio", align: 'center' },
        ],
        items: [],
      },
    };
  },

  computed: {
    Options() {
      return this.generateGraphOptions(this.media, this.tituloGrafico, this.subtitle);
    },
    indicador() {
      return this.goal.indicador;
    },
  },

  watch: {
    filter: {
      handler() {
        this.loadIndicators();
      },
      deep: true
    }

  },
  mounted() {
    this.loadIndicators();
  },

  methods: {
    async loadIndicators() {

      if (this.indicador == 'CCS' && !this.goal.media) {
        this.tituloGrafico = 'Média Geométrica mensal CCS * (1000) ' ;
        await this.loadCCS();
      }
      else if (this.indicador == 'CPP' && !this.goal.media) {
        this.tituloGrafico = 'Média Geométrica mensal CPP * (1000)' ;
        await this.loadCPP();
      }
      else if (this.indicador == 'gordura' && !this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Gordura';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'proteina' && !this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Proteína';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'caseina' && !this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Caseína';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'solidos_totais' && !this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Solídos Totais';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'volume' && !this.goal.media) {
        this.tituloGrafico = 'Volume de Coleta';
        await this.loadVolume();
      }
      else if (this.indicador == 'CPP' && this.goal.media ) {
        this.tituloGrafico = 'Média Geométrica CPP * (1000)' ;
        await this.loadProdutoresCPP();
      }
      else if (this.indicador == 'CCS' && this.goal.media ) {
        this.tituloGrafico = 'Média Geométrica CCS * (1000)' ;
        await this.loadProdutoresCCS();
      }
      else if (this.indicador == 'gordura' && this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Gordura';
        await this.loadProdutoresIndicador();
      }
      else if (this.indicador == 'proteina' && this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Proteína';
        await this.loadProdutoresIndicador();
      }
      else if (this.indicador == 'caseina' && this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Caseína';
        await this.loadProdutoresIndicador();
      }
      else if (this.indicador == 'solidos_totais' && this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Solídos Totais';
        await this.loadProdutoresIndicador();
      }
      else if (this.indicador == 'volume' && this.goal.media) {
        this.tituloGrafico = 'Volume de Coleta';
        await this.loadProdutoresVolume();
      }
      let metaTexto = '';
      let metaValor = '';
      if (!this.goal.media) {
        metaTexto =   `${this.goal.acao} média ${this.goal.indicador} ${this.goal.percentual}` + (this.goal.tipo == "PERCENT" ? "%" : "");
        metaValor =  ` ( Meta ${this.goal.indicador}: ${parseInt(this.media[0].goals)} )`;
      } else {
        metaTexto = `Adequar ${this.goal.percentual}` + (this.goal.tipo == "PERCENT" ? "% dos" : "") + ` produtores com ${this.goal.indicador} ${this.goal.acao} de ${this.goal.media} `;
      }

      this.subtitle =  metaTexto + metaValor;
    },

    async loadCCS() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaCcsPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: this.typeProject ? this.typeProject : "PQFL",
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        const media = data.map((o) => {
          return {
            media: o.ccs,
            data: o.data,
            produtores: o.produtores ?? [],
          }
        });

        this.parseData(media);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadCPP() {
      this.loading = true
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaCppPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: this.typeProject ? this.typeProject : "PQFL",
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        const media = data.map((o) => {
          return {
            media: o.cpp,
            data: o.data,
            produtores: o.produtores ?? [],
          }
        });

        this.parseData(media);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadMediaIndicador() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaAritmeticaIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: this.indicador,
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: this.typeProject ? this.typeProject : "PQFL",
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        this.parseData(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadVolume() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/relatorios/volumeMes`,
          {
            data_inicio: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: this.typeProject ? this.typeProject : "PQFL",
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          }
        );

        const volume = data.map((o) => {
          return {
            media: o.total,
            data: o.mes + "-01",
            produtores: o.produtores ?? [],
          }
        });

        this.parseData(volume);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProdutoresCPP() {
      this.loading = true
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/ProdutoresMediaCppPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            media: this.goal.media || 300,
            acao: this.goal.acao || "ABAIXO",
            tipo: this.typeProject ? this.typeProject : "PQFL",
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        const media = data.map((o) => {
          return {
            acima: (this.goal.acao === "ABAIXO") ? o.acima + o.igual : + o.acima,
            abaixo: (this.goal.acao === "ACIMA") ?  o.abaixo + o.igual : + o.abaixo,
            data: o.data,
            produtores: o.produtores,
          }
        });

        this.parseData(media);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProdutoresCCS() {
      this.loading = true
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/ProdutoresMediaCcsPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            media: this.goal.media || 500,
            acao: this.goal.acao || "ABAIXO",
            tipo: this.typeProject ? this.typeProject : "PQFL",
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        const media = data.map((o) => {
          return {
            acima: (this.goal.acao === "ABAIXO") ? o.acima + o.igual : + o.acima,
            abaixo: (this.goal.acao === "ACIMA") ?  o.abaixo + o.igual : + o.abaixo,
            data: o.data,
            produtores: o.produtores,
          }
        });

        this.parseData(media);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProdutoresIndicador() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/ProdutoresIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: this.indicador,
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            media: this.goal.media || 0,
            acao: this.goal.acao || "ABAIXO",
            tipo: this.typeProject ? this.typeProject : "PQFL",
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );
        this.parseData(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProdutoresVolume() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/volumeProdutorMes`,
          {
            data_inicio: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            media: this.goal.media || 500,
            acao: this.goal.acao || "ABAIXO",
            tipo: this.typeProject ? this.typeProject : "PQFL",
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          }
        );

        this.parseData(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    parseData(data) {
      this.loading = true;
      let tipo = 0;
      let meta = 0;
      let acao = '';

      if (!_.isEmpty(this.filter.project.quality) ) {
        this.filter.project.quality.map(q => {
          if ( q.indicador == this.indicador && q.media == this.goal.media) {
            meta = this.formatNumber(q.percentual);
            tipo = q.tipo;
            acao = q.acao;
          }
        })
      }

      //primeira media para ser utilizado como referencia
      if (_.isEmpty(this.mediaFirst)) {
        this.mediaFirst = parseInt(data[0].media);
      }

      this.media = data.filter(o => moment(o.data) >= moment(this.filter.range[0])).map(o => {
        let icone = 'cancel';
        let metaCalculada;

        if (tipo == "UN") {
          metaCalculada =  meta
        } else {
          if (acao == "ACIMA" || acao == "ABAIXO") {
            if (acao == "ACIMA") {
              metaCalculada = (o.acima * meta) / 100;
            } else if (acao == "ABAIXO") {
              metaCalculada = (o.abaixo * meta) / 100;
            }

          } else if (acao == 'REDUZIR') {
            metaCalculada = parseFloat(this.mediaFirst) - (this.mediaFirst / 100) * meta;
          } else {
            metaCalculada = parseFloat(this.mediaFirst) + (this.mediaFirst / 100) * meta;
          }
        }

        if ((acao == 'AUMENTAR' && parseFloat(o.media) > parseFloat(metaCalculada)) ||
            (acao == 'REDUZIR' && parseFloat(o.media) < parseFloat(metaCalculada)) ) {
          icone = 'verified';
        }

        let produtores = o.produtores ? o.produtores : [];
        let totalProdutores = produtores.length;

        return {
          media: o.media || 0,
          abaixo: o.abaixo || 0,
          acima: o.acima || 0,
          data: o.data,
          totalProdutores: totalProdutores,
          produtores: produtores,
          goals: parseInt(metaCalculada),
          goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta),
          icone: icone
        }
      });
      this.loading = false;
    },

    generateGraphOptions(data, title, subtitle) {
      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(140, 23, 23, 1)', 'rgba(140, 23, 23, 0.5)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtext: `${subtitle}`,
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 800,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 200, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });

                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.data)),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          },
        },
        series: [
          (this.goal.acao === 'ABAIXO' || this.goal.acao === 'ACIMA') ?
            {
              name: `Total de Produtores`,
              type: 'bar',
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.acima + o.abaixo).toFixed(0)),
            }
            : {
              name: 'Média',
              type: 'bar',
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.media).toFixed(0)),
            },
          {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: false,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o =>  Math.abs(o.goals).toFixed(0)),
          },
          (this.goal.acao === 'ABAIXO' || this.goal.acao === 'ACIMA') ?
            {
              name: `Produtores Acima de ${this.goal.media}`,
              type: 'bar',
              barGap: '-100%',
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.acima).toFixed(0)),
            }
            : null,
          (this.goal.acao === 'ABAIXO' || this.goal.acao === 'ACIMA') ?
            {
              name: `Produtores Abaixo de ${this.goal.media}`,
              type: 'bar',
              barGap: '-100%',
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.abaixo).toFixed(0)),
            }
            : null,
        ]
      };
    },

    showDetails({ item }) {
      this.details.items = item.produtores;
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatInt: (value) => !value ? '' : parseInt(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
