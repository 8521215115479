<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Ordens por status</span>
    </v-card-title>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import debounce from 'lodash/debounce';
import colors from 'vuetify/lib/util/colors'

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '500px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    products: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      loading: false,
      items: [],
    }
  },

  computed: {
    options() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: (param) => [
            param.name,
            `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b> (${param.percent}%)`,
          ].join('<br>')
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        series: [{
          name: 'Ordens por status',
          type: 'pie',
          radius: [20, 120],
          center: ['50%', '50%'],
          itemStyle: {
            borderRadius: 0
          },
          label: {
            position: 'inside',
            formatter: '{c}',
            textShadowColor: '#000',
            textShadowBlur: 0.5,
          },
          data: this.items,
          color: this.items.map(item => item.color),
        }]
      }
    }
  },

  watch: {
    period() {
      this.onFilter();
    },
    products() {
      this.onFilter();
    },
  },

  created() {
    this.loadReport()
  },

  methods: {
    onFilter: debounce(function () {
      this.loadReport()
    }, 500),

    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.get(`/production-order/status-report`, { params: {
          start_date: startDate, end_date: endDate, products: this.products,
        } });

        this.items = data.map(item => ({
          name: item.status,
          value: item.total,
          color: this.getStatusColor(item.status)
        }));
      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório de Ordens por status!', 'Atenção');
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    getStatusColor(value) {
      const statusColors = {
        'PLANEJADO': colors.orange.lighten1,
        'LIBERADO': colors.blue.lighten1,
        'FECHADO': colors.green.lighten1,
        'CANCELADO': colors.red.lighten1,
      }
      return statusColors[value]
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
