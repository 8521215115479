<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        local_shipping
      </v-icon>
      <span class="title font-weight-light">{{ title }}</span>
    </v-card-title>
    <v-chart
      class="flex-grow-1"
      :options="options"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
export default {
  name: 'card-gauge',

  props: {
    title: {
      type: String,
      default: 'Gauge'
    },
    showGraph: {
      type: Function,
      default: () => {}
    },
    color: {
      type: String,
      default: 'rgba(0, 53, 94, 0.9)'
    },
    height: {
      type: String,
      default: '200px',
    },
    value: Number,
    loading: Boolean,
  },

  computed: {
    options() {
      return {
        series: [
          {
            type: 'gauge',
            center: ["50%", "70%"],
            startAngle: 199,
            endAngle: -21,
            min: 0,
            max: 100,
            radius: '140%',
            splitNumber: 10,
            itemStyle: {
              color: 'rgba(255,255,255, 0.9)',
            },
            progress: { show: true, width: 8 },
            axisLine: {
              lineStyle: { width: 8, opacity: 0.1 }
            },
            axisTick: { show: false },
            splitLine: {
              distance: 3,
              length: 3,
              lineStyle: {
                width: 1,
                color: '#ECEFF1'
              }
            },
            axisLabel: {
              color: '#ECEFF1',
              fontSize: 9
            },
            anchor: { show: false },
            title: { show: false },
            detail: {
              valueAnimation: true,
              width: '40%',
              lineHeight: 40,
              height: '15%',
              borderRadius: 8,
              offsetCenter: [0, '35%'],
              fontSize: 20,
              fontWeight: 'bolder',
              formatter: '{value} %',
              color: 'auto'
            },
            data: [{
              value: this.value
            }]
          },
        ],
      };
    }
  }
}
</script>
