<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.project"
            :items="bpaList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRange"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="filter.project.protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <v-menu
            ref="menu"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRefFilter"
                label="Mês de Referência"
                prepend-inner-icon="event"
                readonly
                clearable
                filled
                dark
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="filter.date"
              :max="filter.max"
              :min="filter.min"
              type="month"
              no-title
              scrollable
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col
          v-for="curve in curveItem"
          :key="curve"
          cols="12"
          md="12"
        >
          <chart-rating
            :curve="curve"
            :filter="filter"
            :ranges="ranges"
          />
        </v-col>
      </v-row>
    </v-flex>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import ChartRating from "@/Domains/Projects/PQFL/BPA/Components/ChartRating.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "reportTab",

  components: {
    ChartRating,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {

      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
          id: '',
        },
        // Dialogs
        datePickerMenu: null,

        // Mês de referência para clasificacao
        date: moment().format("YYYY-MM"),

        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().format("YYYY-MM"),

        search: '',

        rangeBpa: [],

      },

      bpaList: [],
      curveItem: [],
      ranges: {},

      loading: false,

    };
  },

  computed: {

    dateRefFilter() {
      if (_.isEmpty(this.filter.date)) {
        return null;
      }

      return moment(this.filter.date, "YYYY-MM").format("MM/YYYY");
    },
  },

  created() {
    this.loadBpa();
  },

  methods: {
    onDateFilter([month]) {
      this.filter.range = [moment(month).format('YYYY-MM'), moment(month).format('YYYY-MM')];
    },

    async loadRange() {
      this.curveItem = [];
      this.ranges = {};
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/rangesBpa`,
          {
            id_assistencia: this.filter.project.id,
            visitas: true, //somente metas com visitas definidadas
          }
        );
        this.curveItem = [...new Set(data.filter(o => o.visitas).flatMap(o => o.tipo))];
        this.ranges = data.map(d => {
          return {
            indicador: d.indicador,
            tipo: d.tipo,
            metas_agrupadas: d.metas_agrupadas
          }
        });

        if (!_.isObject(data)) {
          throw data;
        }

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar faixas!", "Atenção");
        console.warn(err);

      } finally {
        this.loading = false;
      }
    },

    async loadBpa() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL"
          }
        );

        this.bpaList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            protocol: proj.protocolo,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.bpaList )) {
          this.filter.project = this.bpaList[0];
          this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
