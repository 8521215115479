<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="item-registration"
  >
    <v-row>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                filled
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter.search"
            :loading="loading"
            dark
            class="elevation-1 report-table"
            @click:row="edit"
          >
            <template #[`item.item`]="{ value }">
              <v-chip
                x-small
              >
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          v-on="on"
          @click="newMaintenance()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Novo</span>
    </v-tooltip>

    <maintenance-item-dialog
      v-model="showDialog"
      :edit-id="editId"
      @save="loadItens"
    />
  </v-container>
</template>

<script>
import isArray from "lodash/fp/isArray";

import MaintenanceItemDialog from "@/Domains/Fleet/Maintenance/Components/MaintenanceItemDialog.vue"

export default {

  components: {
    MaintenanceItemDialog,
  },

  data() {
    return {
      loading: false,
      showDialog: false,

      editId: null,

      filter: {
        search: '',
      },

      headers: [
        { text: 'Descrição', value: 'description' },
        { text: '', value: 'action', width: 30 }
      ],

      items: [],
    };
  },

  mounted() {
    this.loadItens();
  },

  methods: {
    async loadItens() {
      try {
        this.loading = true;
        let { data } = await this.$axios.post(`/manutencao/listaItens`);

        if (!isArray(data)) {
          throw data;
        }

        this.items = data.map(item => ({
          id: item.id_manutencao_item,
          description: item.item,
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },
    
    newMaintenance() {
      this.editId = null,
      this.showDialog = true;
    },
    
    edit(item) {
      this.editId = item.id,
      
      this.showDialog = true;
    },
    
    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/manutencao/inativaItem`,
          { id_manutencao_item: id }
        );
        
        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.loadItens();
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
