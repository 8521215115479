<template>
  <div>
    <v-select
      v-model="input"
      :items="suppliers"
      label="Fornecedor"
      prepend-inner-icon="business"
      v-bind="$attrs"
      @input="onItemSelected"
    >
      <template
        #append-item
      >
        <v-divider class="mt-2" />
        <v-row no-gutters>
          <v-col>
            <v-btn
              block
              outlined
              @click="addNew"
            >
              Cadastrar novo
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-select>

    <v-dialog
      v-model="showNewDialog"
      width="290"
    >
      <v-row
        no-gutters
        class="white"
      >
        <v-col>
          <v-text-field
            v-model="supplierName"
            label="Fornecedor"
            append-outer-icon="send"
            @click:append-outer="saveNew"
          />
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },

  data() {
    return {
      showNewDialog: false,
      input: null,
      suppliers: [],
      supplierName: ''
    };
  },

  watch: {
    value(value) {
      this.input = value;
    },
  },

  created() {
    this.getSuppliers();
  },

  methods: {
    onItemSelected() {
      return this.$emit('input', this.input);
    },

    async getSuppliers() {
      const { data } = await this.$axios.get(`/${this.$store.state.settings.sistema}/projetos/listaFornecedores`);

      this.suppliers = data.map(o => ({
        value: o.id_fornecedor,
        text: o.nome
      }));
    },

    addNew() {
      this.showNewDialog = true;
    },

    async saveNew() {
      const supplierName = this.supplierName.trim();
      if (supplierName == '') {
        this.showNewDialog = false;
        return;
      }

      try {
        const { data } = await this.$axios.post(`/${this.$store.state.settings.sistema}/projetos/salvaFornecedor`, { nome: supplierName });
        
        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.suppliers.push({
          text: supplierName,
          value: data.id
        });

        this.supplierName = '';
        this.showNewDialog = false;
        this.input = data.id;
        this.onItemSelected(data.id);

      } catch(error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar a categoria!", "Atenção");
        console.warn(error);
      }
    }
  },
};
</script>
