<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    >
      <v-flex
        xs12
        class="text-xs-center"
      >
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-flex xs12>
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
          @change="onTabChange"
        >
          <v-tab href="#tab-itineraries">
            Itinerários
          </v-tab>

          <v-tab
            href="#tab-report"
            :disabled="!show.id"
          >
            Volume / Rota
          </v-tab>

          <v-tab
            href="#tab-report-all"
          >
            Volume Geral por Rota
          </v-tab>

          <v-tab-item value="tab-itineraries">
            <itineraries
              @onItinerarySelect="onItinerarySelect"
              @onReportItineraryPrint="onReportItineraryPrint"
            />
          </v-tab-item>

          <v-tab-item value="tab-report">
            <report
              v-if="show.id"
              :itinerary="show"
              @onReportItineraryPrint="onReportItineraryPrint"
            />
          </v-tab-item>

          <v-tab-item value="tab-report-all">
            <all-vol-by-route />
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-container>

    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import Itineraries from "@/Domains/Collects/Reports/CollectionVolumeByRoute/Views/Itineraries.vue";
import Report from "@/Domains/Collects/Reports/CollectionVolumeByRoute/Views/Report.vue";
import AllVolByRoute from "@/Domains/Collects/Reports/CollectionVolumeByRoute/Views/AllVolByRoute.vue";
import printJS from "print-js";

export default {
  components: {
    AllVolByRoute,
    Itineraries,
    Report,
  },

  data() {
    return {
      tab: "tab-itineraries",

      show: {},

      loading: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0;
    });
  },

  methods: {

    /**
     * @event void
     *
     * Evento acionado ao mudar a aba no menu
     */
    onItinerarySelect(itinerary) {
      this.show = itinerary;
      this.tab = 'tab-report';
    },

    /**
     * @event void
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange() {
      this.show = {};
    },

    async onReportItineraryPrint(itinerary) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/rotaVolumeImpressao`,
          this.$qs.stringify({
            id_itinerario: itinerary.id,
            tipo: 'itinerario',
          })
        );

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir o itinerario!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
