<template>
  <div>
    <div class="d-flex pa-3">
      <v-tooltip
        v-if="hasEditAccess || (hasVisualizeAccess && !canEdit)"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            dark
            icon
            depressed
            :x-large="$vuetify.breakpoint.width < 1000"
            class="mr-3"
            v-on="on"
            @click.stop="showOrder()"
          >
            <v-icon>
              {{ canEdit ? 'edit' : 'visibility' }}
            </v-icon>
          </v-btn>
        </template>

        {{ canEdit ? 'Editar' : 'Visualizar' }}
      </v-tooltip>

      <v-tooltip
        v-if="item.status === 'LIBERADO' && (hasAppointmentAccess || hasByproductEntryAccess)"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            dark
            icon
            depressed
            :x-large="$vuetify.breakpoint.width < 1000"
            class="mr-3"
            v-on="on"
            @click.stop="onAddAppointment()"
          >
            <v-icon>
              queue
            </v-icon>
          </v-btn>
        </template>

        Apontamentos/Entradas
      </v-tooltip>

      <template v-if="item.status === 'FECHADO'">
        <v-tooltip
          v-if="hasAppointmentAccess || hasVisualizeAccess"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              :x-large="$vuetify.breakpoint.width < 1000"
              class="mr-3"
              v-on="on"
              @click.stop="onShowAppointment()"
            >
              <v-icon>
                conveyor_belt
              </v-icon>
            </v-btn>
          </template>

          Apontamentos
        </v-tooltip>

        <v-tooltip
          v-if="hasByproductEntryAccess || hasVisualizeAccess"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              :x-large="$vuetify.breakpoint.width < 1000"
              class="mr-3"
              v-on="on"
              @click.stop="onShowByproductEntry()"
            >
              <v-icon>
                repartition
              </v-icon>
            </v-btn>
          </template>

          Entradas
        </v-tooltip>
      </template>

      <v-badge
        v-if="['LIBERADO', 'FECHADO'].includes(item.status)"
        :value="false"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip
          v-if="hasConcludeAccess || (hasVisualizeAccess && item.status === 'FECHADO')"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              :disabled="!item.started"
              :x-large="$vuetify.breakpoint.width < 1000"
              v-on="on"
              @click.stop="onShowProductionDialog()"
            >
              <v-icon>
                system_update_alt
              </v-icon>
            </v-btn>
          </template>

          Entrada de Produção
        </v-tooltip>
      </v-badge>

      <v-badge
        v-if="['LIBERADO', 'FECHADO'].includes(item.status)"
        :value="item.status === 'FECHADO'"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip
          v-if="hasConcludeAccess || (hasVisualizeAccess && item.status === 'FECHADO')"
          bottom
        >
          <template #activator="{ on }">
            <span v-on="on">
              <v-btn
                dark
                icon
                depressed
                :disabled="!item.started || !item.tolerance.canBeFinished"
                :x-large="$vuetify.breakpoint.width < 1000"
                @click.stop="onShowConclusionDialog()"
              >
                <v-icon>
                  done_all
                </v-icon>
              </v-btn>
            </span>
          </template>

          {{ item.tolerance.canBeFinished ? 'Concluir' : `Quantidade mínima não produzida (min: ${formatNumber(item.tolerance.min)})` }}
        </v-tooltip>
      </v-badge>
    </div>

    <appointment-dialog
      v-model="appointmentDialog.show"
      :order-id="item.id_ordem_producao"
    />

    <byproduct-entry-dialog
      v-model="byproductEntryDialog.show"
      :order-id="item.id_ordem_producao"
    />

    <production-entry-dialog
      v-model="productionDialog.show"
      :order-id="item.id_ordem_producao"
      @save="reloadOrders"
    />

    <production-order-conclusion-dialog
      v-model="conclusionDialog.show"
      :order-id="item.id_ordem_producao"
      @save="reloadOrders"
    />
  </div>
</template>

<script>
import AppointmentDialog from '@/Domains/Industry/ProductionOrder/Components/AppointmentDialog.vue';
import ByproductEntryDialog from '@/Domains/Industry/ProductionOrder/Components/ByproductEntryDialog.vue';
import ProductionEntryDialog from '@/Domains/Industry/ProductionOrder/Components/ProductionEntryDialog.vue';
import ProductionOrderConclusionDialog from '@/Domains/Industry/ProductionOrder/Components/ProductionOrderConclusionDialog.vue';

export default {

  components: {
    AppointmentDialog,
    ByproductEntryDialog,
    ProductionEntryDialog,
    ProductionOrderConclusionDialog,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },

  },

  data() {
    return {
      appointmentDialog: {
        show: false,
      },

      byproductEntryDialog: {
        show: false,
      },

      productionDialog: {
        show: false,
      },

      conclusionDialog: {
        show: false,
      },
    }
  },

  computed: {
    canEdit() {
      return ['PLANEJADO', 'LIBERADO'].includes(this.item.status)
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasVisualizeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-visualizar' && o.tipo === 'COMPONENTE')
    },
    hasEditAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-editar' && o.tipo === 'COMPONENTE')
    },
    hasAppointmentAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-apontamentos' && o.tipo === 'COMPONENTE');
    },
    hasByproductEntryAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-entrada-subproduto' && o.tipo === 'COMPONENTE');
    },
    hasConcludeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-concluir' && o.tipo === 'COMPONENTE');
    },
  },

  methods: {
    showOrder() {
      this.$emit('showOrder', this.item.id_ordem_producao)
    },

    async onAddAppointment() {
      if (this.item.status !== 'LIBERADO') {
        return;
      }

      this.$emit('addAppointment', this.item);
    },

    async onShowAppointment() {
      if (this.item.status !== 'FECHADO') {
        return;
      }

      this.appointmentDialog.show = true;
    },

    onShowByproductEntry() {
      if (this.item.status !== 'FECHADO') {
        return;
      }

      this.byproductEntryDialog.show = true;
    },

    onShowProductionDialog() {
      if (!['LIBERADO', 'FECHADO'].includes(this.item.status)) {
        return;
      }

      this.productionDialog.show = true
    },

    onShowConclusionDialog() {
      if (this.item.status !== 'LIBERADO') {
        return;
      }

      this.conclusionDialog.show = true
    },

    reloadOrders() {
      this.$emit('reloadOrders');
    },

    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
