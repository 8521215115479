<template>
  <div>
    <v-row>
      <v-col
        md="12"
      >
        <v-card
          elevation="1"
          outlined
        >
          <v-card-title>Concentrado</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                md="2"
              >
                Ração
              </v-col>
              <v-col
                md="3"
              >
                Proteína %
              </v-col>
              <v-col
                md="3"
              >
                Tipo
              </v-col>
              <v-col
                md="3"
              >
                Valor médio por Kg
              </v-col>
            </v-row>
            
            <nutrition-concentrate
              v-for="concentrated in concentrateds"
              :key="concentrated.id"
              :concentrado="concentrated"
              @onConcentrateDelete="onConcentrateDelete"
            />
            
            <v-row>
              <v-col
                md="6"
              >
                <v-btn
                  v-if="editable"
                  icon
                  class="primary"
                  color="white mr-5"
                  @click="addConcentrate();"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col
                md="2"
                offset-md="2"
              >
                <h2>R$ {{ totalConcentratedVolume | formatCurrency() }}</h2>
              </v-col>
              <v-col
                md="5"
              >
                <v-text-field
                  v-model="diagnostic.fornecedor_concentrado"
                  label="Fornecedor de Concentrado"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
   
    <v-row>
      <v-col
        md="6"
      >
        <v-card
          elevation="1"
          outlined
        >
          <v-card-title>Volumoso</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                md="7"
              >
                Produto
              </v-col>
              <v-col
                md="5"
              >
                Valor médio por Kg
              </v-col>
            </v-row>
            <nutrition-voluminous
              v-for="vol in voluminous"
              :key="vol.id"
              :volumoso="vol"
              @onVoluminousDelete="onVoluminousDelete"
            />
            <v-row>
              <v-col
                md="7"
              >
                <v-btn
                  v-if="editable"
                  icon
                  class="primary"
                  color="white mr-5"
                  @click="addVoluminous();"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col
                md="5"
              >
                <h2>R$ {{ totalVoluminous | formatCurrency() }}</h2>
              </v-col>
              <v-col
                md="12"
              >
                <v-text-field
                  v-model="diagnostic.fornecedor_volumoso"
                  label="Fornecedor de Volumoso"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="6"
      >
        <v-card
          elevation="1"
          outlined
        >
          <v-card-title>Complementos</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                md="7"
              >
                Produto
              </v-col>
              <v-col
                md="5"
              >
                Valor médio por Kg
              </v-col>
            </v-row>
            <nutrition-complement
              v-for="comp in complements"
              :key="comp.id"
              :complemento="comp"
              @onComplementDelete="onComplementDelete"
            />
            <v-row>
              <v-col
                md="7"
              >
                <v-btn
                  v-if="editable"
                  icon
                  class="primary"
                  color="white mr-5"
                  @click="addComplement();"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col
                md="5"
              >
                <h2>R$ {{ totalComplement | formatCurrency() }}</h2>
              </v-col>
              <v-col
                md="12"
              >
                <v-text-field
                  v-model="diagnostic.fornecedor_complemento"
                  label="Fornecedor de Complemento"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card
      elevation="2"
      outlined
    >
      <v-card-title>
        Quantidade
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            class="pb-1"
          >
            <h4
              class="subtitle-2"
              style="color: black; font-size: 1.1rem !important"
            >
              Em Lactação - KG por Animal/Dia
            </h4>
            <v-divider />
          </v-col>
        </v-row>
        <custom-data-table
          :items="diagnostic.concentrado_lactacao_obj"
          type-table="lactation"
          type-data="concentrated"
          :standard-columns="lactation.concentrated.standardColumns"
          :custom-columns.sync="lactation.concentrated.customColumns"
          @onCreateNewColumn="createNewColumn('lactation','concentrated','concentrado_lactacao_obj')"
          @onDeleteColumn="deleteColumn"
        />
        <custom-data-table
          :items="diagnostic.volumoso_lactacao_obj"
          type-table="lactation"
          type-data="voluminous"
          :standard-columns="lactation.voluminous.standardColumns"
          :custom-columns.sync="lactation.voluminous.customColumns"
          @onCreateNewColumn="createNewColumn('lactation','voluminous','volumoso_lactacao_obj')"
          @onDeleteColumn="deleteColumn"
        />
        <custom-data-table
          :items="diagnostic.complemento_lactacao_obj"
          type-table="lactation"
          type-data="complements"
          :standard-columns="lactation.complements.standardColumns"
          :custom-columns.sync="lactation.complements.customColumns"
          @onCreateNewColumn="createNewColumn('lactation','complements','complemento_lactacao_obj')"
          @onDeleteColumn="deleteColumn"
        />
       
        <v-row align="center">
          <v-col
            class="subtitle-2 pb-1"
            style="color: black !important; font-size: 1.1rem !important"
          >
            <h4>Outros - KG por Animal/Dia</h4>
            <v-divider />
          </v-col>
        </v-row>
        <custom-data-table
          :items="diagnostic.concentrado_outros_obj"
          type-table="others"
          type-data="concentrated"
          :standard-columns="others.concentrated.standardColumns"
          :custom-columns.sync="others.concentrated.customColumns"
          @onCreateNewColumn="createNewColumn('others','concentrated','concentrado_outros_obj')"
          @onDeleteColumn="deleteColumn"
        />
        <custom-data-table
          :items="diagnostic.volumoso_outros_obj"
          type-table="others"
          type-data="voluminous"
          :standard-columns="others.voluminous.standardColumns"
          :custom-columns.sync="others.voluminous.customColumns"
          @onCreateNewColumn="createNewColumn('others','voluminous','volumoso_outros_obj')"
          @onDeleteColumn="deleteColumn"
        />
        <custom-data-table
          :items="diagnostic.complemento_outros_obj"
          type-table="others"
          type-data="complements"
          :standard-columns="others.complements.standardColumns"
          :custom-columns.sync="others.complements.customColumns"
          @onCreateNewColumn="createNewColumn('others','complements','complemento_outros_obj')"
          @onDeleteColumn="deleteColumn"
        />
      </v-card-text>
    </v-card>

    <v-row>
      <v-col
        md="12"
        justify-center
      >
        <v-card
          elevation="3"
          outlined
        >
          <v-card-title>
            <!-- <v-icon class="mr-2">
              receipt
            </v-icon> -->
            Registro
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.rg_planeja_volumoso"
                  label="Planejo volumoso"
                  :items="optionsPeriod"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.rg_ajusta_dieta"
                  label="Ajusta Dieta"
                  :items="optionsPeriodOther"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.rg_divide_lotes"
                  label="Divide em Lotes"
                  :items="batch"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.rg_armazenamento_alimento"
                  label="Armazenamento de alimentos"
                  :items="qualityOptions"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.rg_produto_quimico"
                  label="Utiliza produtos quimicos"
                  :items="Chemicals"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.rg_agua_fornecida"
                  label="Qualidade da agua fornecida"
                  :items="qualityOptions"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.rg_origem_animal"
                  label="Utiliza produtos de Origem Animal"
                  item-text="nome"
                  :items="optionsPeriodOther"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.rg_criterio_racao"
                  label="Critério de fornecimento de ração aos animais"
                  :items="[
                    'NENHUM',
                    'TODOS OS ANIMAIS ADULTOS COMEM JUNTOS',
                    'FORNCECE DE ACORDO COM A PRODUÇÃO DE CADA VACA',
                    'FORNCECE DE ACORDO COM AS EXIGÊNCIAS CALCULADAS POR UM TÉCNICO',
                    'OUTRO',
                  ]"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.adubacao_periodica"
                  label="Adubação Periodica"
                  :items="[
                    'NÃO REALIZA',
                    'UMA VEZ POR ANO',
                    'A CADA 2 ANOS',
                    'SOMENTE EM ÁREAS DE CULTURA',
                  ]"
                />
              </v-col>
              <v-col
                md="4"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="diagnostic.pasto_rotacionado"
                  label="Pasto Rotacionado"
                  :items="[
                    'NÃO POSSUI',
                    'SOMENTE CULTURA DE INVERNO',
                    'VOASIN',
                    'DIMENCIONAMENTO SIMPLES',
                  ]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as _ from "lodash";
import NutritionConcentrate from "@/Domains/Quality/PlanQualification/Components/NutritionConcentrate.vue";
import NutritionVoluminous from "@/Domains/Quality/PlanQualification/Components/NutritionVoluminous.vue";
import NutritionComplement from "@/Domains/Quality/PlanQualification/Components/NutritionComplement.vue";

import CustomDataTable from "@/Domains/Quality/PlanQualification/Components/CustomDataTable.vue";

export default {
  name: "NutritionStep",
  components: {
    NutritionConcentrate,
    NutritionVoluminous,
    NutritionComplement,
    CustomDataTable
  },

  filters: {
    formatCurrency: value => new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(value),
  },
  props: {
    diagnostic: {
      require: true,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: ".",
            min: -999999,
            max: 999999,
          },
        },
      },

      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      // Array contendo os con
      concentrateds: [],

      voluminous: [],

      complements: [],
      // Define os headers padrões das tabelas
      lactation: {
        concentrated: {
          standardColumns: [
            { value: "concentrado_descricao", text: "Concentrado" },
            { value: "concentrado_alta_producao", text: 'Alta Produção' },
            { value: "concentrado_media_producao", text: 'Média Produção' },
            { value: "concentrado_baixa_producao", text: 'Baixa Produção' },
          ],
          customColumns: [],
        },

        voluminous: {
          standardColumns: [
            { value: "volumoso_descricao", text: "Volumoso" },
            { value: "volumoso_alta_producao", text: 'Alta Produção' },
            { value: "volumoso_media_producao", text: 'Média Produção' },
            { value: "volumoso_baixa_producao", text: 'Baixa Produção' },
          ],
          customColumns: [],
        },

        complements: {
          standardColumns: [
            { value: "complemento_descricao", text: "Complemento" },
            { value: "complemento_alta_producao", text: 'Alta Produção' },
            { value: "complemento_media_producao", text: 'Média Produção' },
            { value: "complemento_baixa_producao", text: 'Baixa Produção' },
          ],
          customColumns: [],
        },
      },

      others: {
        concentrated: {
          standardColumns: [
            { value: "concentrado_descricao", text: "Concentrado" },
            { value: "concentrado_pre_parto", text: 'Pré Parto' },
            { value: "concentrado_vacas_secas", text: 'Vacas Secas' },
            { value: "concentrado_novilhas", text: 'Novilhas' },
            { value: "concentrado_bezerras", text: 'Bezerras' },
            { value: "concentrado_bezerras_aleitamento", text: 'Bezerras Aleitamento' },
          ],
          customColumns: [],
        },
        voluminous: {
          standardColumns: [
            { value: "volumoso_descricao", text: "Volumoso" },
            { value: "volumoso_pre_parto", text: 'Pré Parto' },
            { value: "volumoso_vacas_secas", text: 'Vacas Secas' },
            { value: "volumoso_novilhas", text: 'Novilhas' },
            { value: "volumoso_bezerras", text: 'Bezerras' },
            { value: "volumoso_bezerras_aleitamento", text: 'Bezerras Aleitamento' },
          ],
          customColumns: [],
        },
        complements: {
          standardColumns: [
            { value: "complemento_descricao", text: "Complemento" },
            { value: "complemento_pre_parto", text: 'Pré Parto' },
            { value: "complemento_vacas_secas", text: 'Vacas Secas' },
            { value: "complemento_novilhas", text: 'Novilhas' },
            { value: "complemento_bezerras", text: 'Bezerras' },
            { value: "complemento_bezerras_aleitamento", text: 'Bezerras Aleitamento' },
          ],
          customColumns: [],
        },
      },

      /**
       *  Tipos de tabelas
       */
      parseName: {
        concentrated: "concentrado",
        voluminous: "volumoso",
        complement: "complemento",
      },
  
      qualityOptions: [],
      optionsPeriod: [],
      optionsPeriodOther: [],
      batch: [],
      Chemicals: []
    };
  },
  computed: {
    totalConcentratedVolume() {
      return _.sumBy(this.concentrateds, vol => parseFloat(vol.concentrado_valor_medio))
    },

    totalVoluminous() {
      return _.sumBy(this.voluminous, vol => parseFloat(vol.volumoso_valor_medio))
    },

    totalComplement() {
      return _.sumBy(this.complements, compl => parseFloat(compl.complemento_valor_medio))
    },
  },
 
  watch: {
    diagnostic() {
      this.concentrateds = this.diagnostic.concentrado_obj;
      this.voluminous = this.diagnostic.volumoso_obj;
      this.complements = this.diagnostic.complemento_obj;
      
      console.log('this.concentrateds', this.concentrateds);
    },
    concentrateds: {
      handler: function (newValue) {
        _.set(this.diagnostic, 'concentrado_obj', newValue);
        this.$emit("update:diagnostic", this.diagnostic);
      },
    },
    voluminous: {
      handler: function (newValue) {
        _.set(this.diagnostic, 'volumoso_obj', newValue);
        this.$emit("update:diagnostic", this.diagnostic);
      },
    },
    complements: {
      handler: function (newValue) {
        _.set(this.diagnostic, 'complemento_obj', newValue);
        this.$emit("update:diagnostic", this.diagnostic);
      },
    },
  },
  mounted() {
    this.concentrateds = this.diagnostic.concentrado_obj;
    this.voluminous = this.diagnostic.volumoso_obj;
    this.complements = this.diagnostic.complemento_obj;
    this.initCustomTable();
    this.listQuality();
    this.listOptionsPeriod();
    this.listOptionsPeriodOther();
    this.listbatch();
    this.listChemical();
  },

  methods: {
    /**
     * @void
     * Adiciona novo concentrado
     */
    addConcentrate() {
      this.concentrateds.push({
        id: this.concentrateds.length,
        concentrado_racao: "",
        concentrado_tipo_racao: "",
        concentrado_porcentagem: 0,
        concentrado_valor_medio: 0,
      });
    },

    addVoluminous() {
      this.voluminous.push({
        id: this.voluminous.length,
        volumoso: "",
        volumoso_valor_medio: 0,
      });

    },

    addComplement() {
      this.complements.push({
        id: this.complements.length,
        complemento: "",
        complemento_valor_medio: 0,
      });

    },

    onConcentrateDelete(id) {
      this.concentrateds = _.remove(this.concentrateds, data => {
        return data.id !== id;
      });
    },

    onVoluminousDelete(id) {
      this.voluminous = _.remove(this.voluminous, data => {
        return data.id !== id;
      });
    },

    onComplementDelete(id) {
      this.complements = _.remove(this.complements, data => {
        return data.id !== id;
      });
    },
    /**
     * @void
     * Add nova coluna
     */
    createNewColumn(tableType, dataType, ObjName) {
      let customColumns = _.get(this, `${tableType}.${dataType}.customColumns`);

      const amountColumns =  customColumns.length;
      
      // Verifica a quantidade máxima de colunas
      if (amountColumns > 12 ) {
        this.$snotify.error("Oops, o número do colunas já chegou ao maximo!", "Atenção");
      }

      // Adiciona na flag de controle das colunas customizadas
      customColumns.push({ descricao: "", campo: `${this.parseName[dataType]}_item_adicional${amountColumns}` });

      // Busca informações correspondentes
      const data = _.get(this.diagnostic, ObjName);

      // set o objeto data com a nova coluna
      _.set(this.diagnostic, `${ObjName}`, data.map(item => {
        return {
          ...item,
          [`${this.parseName[dataType]}_item_adicional${amountColumns}`]: "",
        }
      }));

      // set uma nova columa customizada
      _.set(this, `${tableType}.${dataType}.customColumns`, customColumns)
    },

    /**
     * @void
     * Remove coluna customizada
     */
    deleteColumn(event) {
      let customColumns = _.get(this, `${event.tableType}.${event.dataType}.customColumns`);

      // remove coluna
      customColumns = _.remove(customColumns, column => column.campo != event.columnName);

      // set uma nova columa customizada
      _.set(this, `${event.tableType}.${event.dataType}.customColumns`, customColumns)
    },

    /**
     * @void
     * Inicializa os campos necessários as tabelas customiozadas
     */
    initCustomTable() {
      this.lactation.concentrated.customColumns = this.diagnostic.concentrado_lactacao_adicionais || [];
      this.lactation.voluminous.customColumns = this.diagnostic.volumoso_lactacao_adicionais || [];
      this.lactation.complements.customColumns = this.diagnostic.complemento_lactacao_adicionais || [];

      this.others.concentrated.customColumns = this.diagnostic.concentrado_outros_adicionais || [];
      this.others.voluminous.customColumns = this.diagnostic.volumoso_outros_adicionais || [];
      this.others.complements.customColumns = this.diagnostic.complemento_outros_adicionais || [];

    },

    async listQuality() {
      try {

        const { data } = await this.$queries.post("/configuracaoLaticinio/listaQualidadeJson");

        this.qualityOptions = data.map(o => o.nome);
        
      } catch (error) {
        console.log(error);
      }
    },
    async listOptionsPeriod() {
      try {

        const { data } = await this.$queries.post("/configuracaoLaticinio/listaOpcoesPeriodo");

        this.optionsPeriod = data.map(o => o.nome);
        
      } catch (error) {
        console.log(error);
      }
    },
    async listOptionsPeriodOther() {
      try {

        const { data } = await this.$queries.post("/configuracaoLaticinio/listaPeriodo2Json");

        this.optionsPeriodOther = data.map(o => o.nome);
        
      } catch (error) {
        console.log(error);
      }
    },
    async listbatch() {
      try {

        const { data } = await this.$queries.post("/configuracaoLaticinio/listaLotesJson");

        this.batch = data.map(o => o.nome);
        
      } catch (error) {
        console.log(error);
      }
    },
    async listChemical() {
      try {

        const { data } = await this.$queries.post("/configuracaoLaticinio/listaQuimicoJson");

        this.Chemicals = data.map(o => o.nome);
        
      } catch (error) {
        console.log(error);
      }
    }

  }
};
</script>
