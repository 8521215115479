<template>
  <v-row>
    <v-col
      v-for="g in goals"
      :key="g.indicator.value"
      md="12"
    >
      <v-card
        color="transparent"
        dark
      >
        <v-card-title class="text-subtitle-2">
          {{ g.indicator.text }}
          <v-spacer />
          {{ g.type }}
          <v-spacer />
          <v-btn
            icon
            color="white"
          >
            <v-icon
              @click="onGoalDelete(g.indicator)"
            >
              delete_outline
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="g.ranges"
          hide-default-footer
          disable-sort
        >
          <template #[`item.class`]="{ item }">
            <v-text-field
              v-model.number="item.class"
              placeholder="A"
              type="text"
              dense
              outlined
              hide-details
            />
          </template>
          <template #[`item.start`]="{ item }">
            <v-text-field
              v-if="g.indicator.text == 'CCS' || g.indicator.text == 'CPP'"
              v-model.number="item.start"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
            <masked-input
              v-else
              v-model.number="item.start"
              :mask="FloatNumberMask"
              currency
              dense
              outlined
              hide-details
              return-unmasked
            />
          </template>
          <template #[`item.end`]="{ item }">
            <v-text-field
              v-if="g.indicator.text == 'CCS' || g.indicator.text == 'CPP'"
              v-model.number="item.end"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
            <masked-input
              v-else
              v-model.number="item.end"
              :mask="FloatNumberMask"
              currency
              dense
              outlined
              hide-details
              return-unmasked
            />
          </template>
          <template #[`item.type`]="{ item }">
            <v-select
              v-model="item.type"
              label="Tipo"
              :items="g.conditional"
              item-value="value"
              item-text="text"
              outlined
              hide-details
              dense
            />
          </template>
          <template #[`item.peso`]="{ item }">
            <masked-input
              v-model.number="item.peso"
              :mask="FloatNumberMask"
              currency
              dense
              outlined
              hide-details
              return-unmasked
            />
          </template>
          
          <template #[`item.buttons`]="{ item }">
            <v-btn
              icon
              color="white"
            >
              <v-icon
                @click="onRatingDelete(g.ranges,item.index)"
              >
                delete_outline
              </v-icon>
            </v-btn>
          </template>
          <template #[`body.append`]="{}">
            <tr>
              <td
                :colspan="headers.length"
                align="left"
              >
                <v-btn
                  outlined
                  rounded
                  text
                  @click="onRatingInsert(g.ranges);"
                >
                  <v-icon>
                    add
                  </v-icon>
                  Faixa
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import _ from "lodash";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {
    MaskedInput
  },

  props: {
    value: Array,
    typeRange: String,
  },
  
  data() {
    return {
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },
      conditional: [],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    headers() {
      let header = [];
      if (this.typeRange == 'nonconformity') {
        header = [
          { text: "Classe", value: "class", width: 80 },
          { text: "De", value: "start", width: 100  },
          { text: "Até", value: "end", width: 100 },
          { text: "Tipo", value: "type", width: 100 },
          { text: "", align: "right", value: "buttons", width: 8 },
        ];
      } else {
        header = [
          { text: "Classe", value: "class", width: 80 },
          { text: "De", value: "start", width: 100  },
          { text: "Até", value: "end", width: 100 },
          { text: "Peso", value: "peso", width: 100 },
          { text: "", align: "right", value: "buttons", width: 8 },
        ];
      }
      return header;
    },
  },

  methods: {
    
    onRatingInsert(range) {
      
      let lastRange = range[0] ? _.last(range).end : 0;
      let index =  range[0] ? _.last(range).index + 1 : 1;

      if ((lastRange == null )) {
        this.$snotify.error("Informe o valor para o Fim da faixa anterior", "Atenção");
        return false;
      }
 
      range.push({
        id: uuidv4(),
        index: index,
        name: '',
        start: lastRange + 1,
        end: null,
        peso: null,
      })
    },

    onRatingDelete(range, index) {
      const idx = range.findIndex(o => o.index === index);
      range.splice(idx, 1);
    },

    onGoalDelete(indicator) {
      const idx = this.goals .findIndex(o => o.indicador === indicator);
      this.goals.splice(idx, 1);
    },
    
    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
}
</script>
