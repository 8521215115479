<template>
  <v-dialog
    v-model="showDialog"
    :persistent="editing"
    width="490px"
  >
    <v-card
      v-if="selectedEvent && selectedEvent.idx != null"
      color="grey lighten-4"
      flat
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-list>
          <v-list-item>
            <v-list-item-avatar :color="selectedEvent.color">
              <span class="white--text text-h5">{{ }}</span>
            </v-list-item-avatar>

            <v-list-item-content class="py-0">
              <v-list-item-title>
                <span
                  v-html="selectedEvent.name"
                />
              </v-list-item-title>
            </v-list-item-content>

            <v-btn
              v-if="!editing"
              fab
              icon
              @click.prevent="editEvent"
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              v-if="!editing"
              fab
              icon
              @click.prevent="deleteEvent"
            >
              <v-icon>delete_outline</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>

        <v-divider />
        <v-card-text
          class="px-0 py-0"
        >
          <v-list>
            <v-list-item>
              <v-list-item-action class="mr-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      schedule
                    </v-icon>
                  </template>
                  <span>Horário</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-title>
                <v-row>
                  <v-col class="d-flex py-0">
                    <span v-if="!editing">
                      {{ selectedEvent.start | dateFormat('DD/MM/YYYY') }}
                    </span>
                    <date-picker
                      v-else
                      v-model="selectedEvent.start"
                      label="Data Chegada *"
                      @input="onDateStartChange"
                    />
                  </v-col>
                  <v-col
                    v-if="selectedEvent.timed"
                    class="d-flex py-0"
                  >
                    <span
                      v-if="!editing"
                    >
                      {{ selectedEvent.start | dateFormat('HH:mm') }}
                    </span>
                    <time-picker
                      v-else
                      v-model="selectedEvent.start"
                      label="Hora Chegada *"
                      :max-time="selectedEvent.end"
                    />
                  </v-col>
                  <v-col
                    v-if="selectedEvent.timed"
                    class="d-flex py-0"
                  >
                    <span
                      v-if="!editing"
                    >
                      {{ selectedEvent.end | dateFormat('HH:mm') }}
                    </span>
                    <time-picker
                      v-else
                      v-model="selectedEvent.end"
                      label="Hora Saída *"
                      :min-time="selectedEvent.start"
                    />
                  </v-col>
                  <v-col class="d-flex py-0">
                    <span v-if="!editing">
                      {{ selectedEvent.end | dateFormat('DD/MM/YYYY') }}
                    </span>
                    <date-picker
                      v-else
                      v-model="selectedEvent.end"
                      label="Data Saída *"
                      @input="onDateEndChange"
                    />
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="editing"
            >
              <v-list-item-action class="mr-4" />
              <v-list-item-title>
                <v-checkbox
                  :input-value="!selectedEvent.timed"
                  label="Dia Inteiro"
                  hide-details
                  :ripple="false"
                  class="my-0"
                  @change="val => selectedEvent.timed = !val"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="editing"
            >
              <v-list-item-action class="mr-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      notes
                    </v-icon>
                  </template>
                  <span>Descrição</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-title>
                <v-text-field
                  ref="descInput"
                  v-model="selectedEvent.name"
                  label="Descrição *"
                  :rules="[v => !!v || 'Informe a descrição']"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action class="mr-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      pending_actions
                    </v-icon>
                  </template>
                  <span>Tipo de visita</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-title>
                <span
                  v-if="!editing"
                  v-html="selectedEvent.type ? selectedEvent.type.map(k => types[k]).join(', ') : ''"
                />
                <v-select
                  v-else
                  v-model="selectedEvent.type"
                  :items="visitTypes"
                  label="Tipo de visita*"
                  multiple
                  required
                  :rules="[v => !!v && v.length > 0 || 'Escolha o tipo de visita']"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action class="mr-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      person_outline
                    </v-icon>
                  </template>
                  <span>Técnico</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-title>
                <span
                  v-if="!editing"
                  v-html="selectedEvent.person.name"
                />
                <v-autocomplete
                  v-else
                  v-model="selectedEvent.person"
                  :items="technicians"
                  :disabled="selectedEvent.id !== null || disableTechnician"
                  label="Técnico *"
                  item-text="name"
                  item-value="id"
                  return-object
                  required
                  :rules="[v => !!v.id || 'Escolha o técnico']"
                  @change="loadProducers"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action class="mr-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      person_outline
                    </v-icon>
                  </template>
                  <span>Produtor</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-title style="overflow: unset;">
                <span
                  v-if="!editing"
                  v-html="selectedEvent.producer.name"
                />
                <v-autocomplete
                  v-else-if="selectedEvent.id !== null"
                  v-model="selectedEvent.producer"
                  :items="producers"
                  :disabled="!selectedEvent.person.id"
                  label="Produtor *"
                  item-text="name"
                  item-value="id"
                  return-object
                  required
                  :rules="[v => !!v.id || 'Escolha o produtor']"
                />
                <person-autocomplete-filter
                  v-else
                  v-model="selectedEvent.producers"
                  :disabled="!selectedEvent.person.id"
                  multiple
                  label="Produtores *"
                  type="PRODUCER"
                  required
                  :hide-details="false"
                  :filled="false"
                  :rules="[v => !!v && v.length > 0 || 'Escolha o produtor']"
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!!selectedEvent.note || editing"
            >
              <v-list-item-action class="mr-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      notes
                    </v-icon>
                  </template>
                  <span>Observação</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-title>
                <span
                  v-if="!editing"
                  v-html="selectedEvent.note"
                />
                <v-text-field
                  v-else
                  v-model="selectedEvent.note"
                  label="Observação"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-list-item
            v-if="editing"
          >
            <v-list-item-action class="mr-4" />
            <v-list-item-title>
              <small>* Campo obrigatório</small>
            </v-list-item-title>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!editing"
            text
            color="secondary"
            @click="closeEvent"
          >
            Fechar
          </v-btn>
          <template v-else>
            <v-btn
              text
              color="secondary"
              @click="cancelEdit"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveEvent"
            >
              Salvar
            </v-btn>
          </template>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "@/Domains/Visits/Schedule/Components/DatePicker.vue";
import TimePicker from "@/Domains/Visits/Schedule/Components/TimePicker.vue";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

import moment from "moment-timezone";

const service = new VisitsService();

export default {
  name: "event-viewer-dialog",

  components: {
    DatePicker,
    TimePicker,
    PersonAutocompleteFilter,
  },

  filters: {
    dateFormat: function(value, opts) {
      return moment(value).format(opts);
    },
  },

  props: {
    value: Object,
    show: Boolean,
    editing: Boolean,
    disableTechnician: Boolean,
    technicians: Array,
  },

  data() {
    return {
      showDialog: false,
      valid: true,
      selectedEvent: {},
      types: service.types,
      visitTypes: service.typesFilter,
      producers: [],
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.selectedEvent = this.value;
      }
    },
    selectedEvent: {
      deep: true,
      handler() {
        this.$emit("input", this.selectedEvent);
      }
    },
    show() {
      this.showDialog = this.show;
      if (this.show) {
        this.loadProducers();
        if (this.editing) {
          setTimeout(() => {
            if (this.$refs.descInput) this.$refs.descInput.focus();
          }, 100);
        }
      }
    },
    showDialog() {
      if (!this.showDialog && !this.editing) {
        this.$emit("click:close", this.showDialog);
        this.$refs.form.resetValidation();
      }
    }
  },

  created() {
    this.showDialog = this.show;
    this.selectedEvent = this.value;
  },

  methods: {
    /**
     * Método responsável por carregar os produtores
     * @returns {Promise<void>}
     */
    async loadProducers() {
      const person = this.selectedEvent.person;
      if (!person.id) {
        this.producers = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/produtores/listaJson`,
          this.$qs.stringify({ id_usuario: person.id })
        );

        this.producers = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
        console.log(err);
      }
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    editEvent() {
      this.$emit("click:edit");
      this.loadProducers();
      this.$nextTick(() => {
        if (this.$refs.descInput) this.$refs.descInput.focus();
      });
    },
    deleteEvent() {
      this.$emit("click:delete");
    },
    closeEvent() {
      this.$emit("click:close");
      this.$refs.form.resetValidation();
    },
    cancelEdit() {
      this.$emit("click:cancel");
      this.$refs.form.resetValidation();
    },
    saveEvent() {
      this.$emit("click:save");
    },

    onDateStartChange(date) {
      if (date > this.selectedEvent.end) {
        this.selectedEvent.end = moment(date).add(15, 'm').toDate();
      }
    },

    onDateEndChange(date) {
      if (date < this.selectedEvent.start) {
        this.selectedEvent.start = moment(date).add(-15, 'm').toDate();
      }
    },

  }
}
</script>
