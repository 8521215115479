<template>
  <div class="mx-6 px-16">
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Relatório de Suspensão
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#suspended">
            Suspensos
          </v-tab>

          <v-tab href="#reactive_suspended">
            Suspensos / Reativos
          </v-tab>

          <v-tab href="#unlinked_suspended">
            Suspensos / Desvinculados
          </v-tab>

          <v-tab-item value="suspended">
            <suspended-report
              ref="suspended"
              aba="suspended"
            />
          </v-tab-item>

          <v-tab-item value="reactive_suspended">
            <reactive-suspended-report
              ref="reactive_suspended"
              aba="reactive_suspended"
            />
          </v-tab-item>

          <v-tab-item value="unlinked_suspended">
            <unlinked-suspended-report
              ref="unlinked_suspended"
              aba="unlinked_suspended"
            />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SuspendedReport from "@/Domains/Quality/Indicators/SuspendedProducers/Views/SuspendedReport.vue";
import ReactiveSuspendedReport from "@/Domains/Quality/Indicators/SuspendedProducers/Views/ReactiveSuspendedReport.vue";
import UnlinkedSuspendedReport from "@/Domains/Quality/Indicators/SuspendedProducers/Views/UnlinkedSuspendedReport.vue";

export default {
  components: {
    SuspendedReport,
    ReactiveSuspendedReport,
    UnlinkedSuspendedReport
  },

  props: {
    value: String,
    aba: String,
  },

  data() {
    return {
      tab: null,
    };
  },

  methods: {
  }
}
</script>
