<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.project"
          :items="projectList"
          label="Descrição"
          item-value="id"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="loadDateRange"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.curve"
          dark
          filled
          return-object
          label="Classe"
          clearable
          :items="curveItem"
          @change="loadTabs"
        />
      </v-col>

      <v-col
        md="3"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onFilterDate"
        />
      </v-col>

      <v-col
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>
      <!-- <v-col
        cols="12"
        md="3"
      >
        <v-switch
          v-model="filter.volume"
          label="Exibir Volume Médio"
          dense
          dark
          hide-details
          class="mt-0"
          @change="loadTabs"
        />
      </v-col> -->
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="onLoad"
    >
      <v-tab
        v-for="tabCurve in tabs"
        :key="tabCurve.id_meta_bpa"
      >
        {{ tabCurve.title }}
      </v-tab>

      <v-tab-item
        v-for="tabCurve in tabs"
        :key="tabCurve.id_meta_bpa"
        background-color="transparent"
        dark
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            {{ `Produtores : ${ tabCurve.items.length}` }}
            <v-spacer />
            {{ `${ tabCurve.tipo}` }} {{ `${ tabCurve.indicadores}` }}
          </v-card-title>

          <v-chart
            :options="generateGraphOptions(tabCurve.items)"
            :style="{width: '100%', height: `${tabCurve.items.length * 30 }px !important`}"
            autoresize
          />

          <v-data-table
            :headers="tabCurve.headers"
            :items="tabCurve.items"
            :search="filter.search"
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
          >
            <template #[`item.media_ponderada`]="{value}">
              {{ formatNumber(value) }}
            </template>
            <template
              #[`item.ultima_visita`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template #[`item.status`]="{item}">
              <v-chip
                v-if="item.status.description == 'DESVINCULADO'"
                color="red"
                small
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      left
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </template>
                  <span>
                    Saída em {{ formatDate(item.data_saida, 'DD/MM/YYYY') }}
                  </span>
                </v-tooltip>
              </v-chip>
              <v-chip
                v-else-if="item.status.description == 'SUSPENSO'"
                color="orange"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>remove_circle</v-icon>
                </v-avatar>
                S
              </v-chip>
              <v-chip
                v-else
                color="green"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>check_circle</v-icon>
                </v-avatar>
                A
              </v-chip>
            </template>
          </v-data-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-overlay
      v-model="loading"
      relative
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

import moment from "moment-timezone";

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import RatingService from "@/Domains/Projects/PQFL/BPA/Services/RatingService.js";
import  _ from "lodash";

const ratingService = new RatingService();

export default {
  name: "producer-rating",

  components: {

    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    parseEmpty: (value) => !value ? '-' : value,
  },

  mixins: [ReportMixin],
  props: {
    view: String,
  },

  data() {
    return {
      loading: false,

      filter: {
        project: {
          description: null,
          id: '',
        },
        curve: {
          value: '',
          text: ''
        },
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],

        volume: false,

        search: null,
      },
      months: [],

      tab: 0,

      projectList: [],

      producers: [],

      tabs: [],

      curveItem: [],

      ranges: [],

    };
  },

  created() {
    this.loadProject();
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onFilterDate([month]) {
      this.filter.date.month = month;
      this.onLoad();
    },
    onLoad() {
      if (!_.isEmpty(this.tabs)) {
        if (this.tabs[this.tab].id_meta_bpa) {
          this.loadProducerRating();
        }
      }
    },

    async loadRanges() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/rangesBpa`,
          {
            id_assistencia: this.filter.project.id,
          }
        );

        let curve = [...new Set(data.flatMap(o => o.tipo))];
        this.curveItem = curve.map((c) => {
          return {
            text: ratingService.types[c],
            value: c,
          }
        });
        this.ranges = data;
        this.filter.curve = _.last(this.curveItem);
        this.loadTabs();

      } catch (error) {
        console.warn(error);
      }

    },

    async loadTabs() {
      this.tabs = [];
      if (!this.filter.curve) {
        return false;
      }
      try {
        //monta aba com as faixas da curva escolhida
        const data = this.ranges.filter(c => c.tipo === this.filter.curve.value);

        this.tabs = data.map((c) => {
          const h = [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            // { text: 'Última visita', value: 'ultima_visita' },
          ];

          if (this.filter.volume) {
            h.push({ text: 'Volume Médio', value: 'volume_media_diaria' });
          }

          let IndicadoresTitulo = '';
          if (c.indicador) {//curva simples
            h.push({ text: c.indicador, value: 'item.result0', align: 'center' });
            IndicadoresTitulo  = c.indicador;
          } else { //combinadas

            let metas_agrupadas = JSON.parse(c.metas_agrupadas || '') || [];
            let indicadores = [...new Set(metas_agrupadas.flatMap(o => o.indicator.indicator))];
            indicadores.forEach((i, k) => {
              h.push({ text: i, value: `item.result${k}`, align: 'center',  });
              // h.push({ text: `Data ${i}`, value: `item.resultDate${k}`, align: 'center',  });
              IndicadoresTitulo  = IndicadoresTitulo + ' [' + i + '] ';
            });
            h.push({ text: 'Média ponderada', value: 'media_ponderada', align: 'center'  });
          }

          h.push({ text: 'Status', value: 'status' });

          return {
            title: c.nome_faixa,
            id_meta_bpa: c.id_meta_bpa,
            tipo: ratingService.types[c.tipo],
            items: [],
            headers: h,
            indicadores: IndicadoresTitulo,
          }
        });

      } catch (error) {
        console.warn(error);
      }
    },

    async loadDateRange() {
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.max = moment(this.filter.project.dateEnd).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), moment().format('YYYY-MM')];
    },

    async loadProject() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy",
            view: 'rating',
          }
        );

        this.projectList = data.map((proj) => {
          return {
            text: proj.descricao,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadDateRange();
          this.loadRanges();
          this.tab = 0;
        }

      } catch (error) {
        console.warn(error);
      }
    },

    async loadProducerRating() {
      try {

        this.loading = true;
        let id_meta_bpa   = this.tabs[this.tab].id_meta_bpa;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/listaProdutoresClassificadosLaticinio`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            id_faixa: id_meta_bpa,
            id_pessoa: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            mostra_volume: this.filter.volume,
          }
        );

        const faixa = this.ranges.filter(c => c.tipo === this.filter.curve.value);
        let tipo = '';
        let tipoAgrupadas = [];
        if (_.first(faixa).condicional) {
          tipo =  _.first(faixa).condicional ;

        } else if (_.first(faixa).tipo == 'INCONFORME-PONTOS') {
          tipo = 'pontos';

        } else if (_.first(faixa).tipo == 'COMBINADAS') { //se combinada entao busco nas metas os tipos das combinadas e coloco no array de tipos
          const metas_agrupadas = JSON.parse(_.first(faixa).metas_agrupadas || '') || [];
          const ids = [...new Set(metas_agrupadas.flatMap(o => o.indicator))];
          const agrupadas = this.ranges.filter(array => ids.some(filter => filter.id === array.id_meta_bpa));
          tipoAgrupadas = agrupadas.map(a => {
            if (a.condicional) {
              return  a.condicional;
            } else if (a.tipo == 'INCONFORME-PONTOS') {
              return 'pontos';
            } else {
              return '';
            }
          });
        }

        this.producers = data.map(p => {
          let item = [];
          item.result0 = p.resultado ? this.formatNumber(p.resultado) + ' ' + tipo  : '';
          if (p.tipo_faixa == 'COMBINADAS') {
            p.classificacao_produtor_agrupador.map((a, k) => {
              const head = this.tabs[this.tab].headers.find((h) => h.text === a.indicador);
              eval(`${head.value}= '${(a.resultado)} ${tipoAgrupadas[k]}'`);

              // const head2 = this.tabs[this.tab].headers.find((h) =>  h.text === `Data ${a.indicador}`);
              // eval(`${head2.value}= '${moment(a.data_referencia).format("MM/YYYY")}'`);
            })
          }

          return {
            ...p,
            status: {
              description: p.status,
              date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
            },
            item: item,
          }
        });
        if (_.first(this.producers).media_ponderada > 0) {
          this.tabs[this.tab].items = _.orderBy( this.producers, 'media_ponderada', 'desc');
        } else {
          this.tabs[this.tab].items  = _.orderBy( this.producers, 'resultado', 'desc');
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtores!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    generateGraphOptions(data) {
      console.log('data',data);
      if (_.isEmpty(data)) {
        return;
      }
      let series = [];

      if (_.first(data).media_ponderada > 0) {
        series.push ({
          name: 'Média',
          type: 'bar',
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            lineHeight: 20,
            height: 20,
            backgroundColor: '#6a7985',
            color: '#fff',
            borderRadius: 5,
            position: 'top',
            distance: 1,
            formatter: ({ value }) => `${this.formatNumber(value)}`
          },

          data: data.map(o => o.media_ponderada ).reverse(),
        });
      } else if (_.first(data).resultado > 0) {
        series.push ({
          name: _.first(data).indicador,
          type: 'bar',
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            lineHeight: 20,
            height: 20,
            backgroundColor: '#6a7985',
            color: '#fff',
            borderRadius: 5,
            position: 'top',
            distance: 1,
            formatter: ({ value }) => `${this.formatNumber(value)}`
          },

          data: data.map(o => o.resultado ).reverse(),
        });
      }

      return {
        height: 50 + (data.length * 25),
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
        },
        yAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => (o.nome_produtor)).reverse(),
          axisLabel: {
            color: '#fff'
          }
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: series
      };
    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
