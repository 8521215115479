<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Análises De Tanque
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <analysis-import-result
        v-model="importResult.show"
        :successes="importResult.successes"
        :failures="importResult.failures"
      />

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <v-date-range-picker
                  v-model="filters.dateRange"
                  dark
                  @change="loadReport"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <v-text-field
                  v-model="filters.search"
                  append-icon="search"
                  label="Buscar"
                  filled
                  hide-details
                  dark
                />
              </v-col>
            </v-row>
          </v-card-title>

          <data-table
            ref="report"
            :headers="headers"
            :items="items"
            :search="filters.search"
            dark
            dense
          >
            <template #[`item.data`]="{ value }">
              {{ formatDate(value) }}
            </template>
            <template #[`item.ccs`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.cbt`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.caseina`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.gordura`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.ureia`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.lactose`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.proteina`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.esd`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.solidos_totais`]="{ value }">
              {{ formatNumber(value) }}
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <import-excel-dialog
      v-model="importAnalysis.show"
      title="Importar análises"
      :fields="importFields"
      show-model
      @import="onImportAnalysis"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="showImportAnalysis()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              system_update_alt
            </v-icon>
          </template>
          Importar Análises
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ImportExcelDialog from '@/Support/Components/ImportExcelDialog.vue';
import AnalysisImportResult from '@/Domains/Analysis/Upload/Components/AnalysisImportResult.vue';
import AnalysisImportMixin from '@/Domains/Analysis/Upload/Mixins/AnalysisImportMixin.js';

import moment from "moment-timezone";

export default {
  name: "TankAnalysis",

  components: {
    VDateRangePicker,
    AnalysisImportResult,
    ImportExcelDialog,
  },

  mixins: [AnalysisImportMixin],

  data() {
    return {
      filters: {
        search: '',
        dateRange: [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
      },

      headers: [
        { text: "Nome", value: "nome" },
        { text: "Data", value: "data", formatter: value => this.formatDate(value) },
        { text: "CPP", value: "cbt", formatter: value => this.parseNumber(value) },
        { text: "CCS", value: "ccs", formatter: value => this.parseNumber(value) },
        { text: "Uréia", value: "ureia", formatter: value => this.parseNumber(value) },
        { text: "Caseina", value: "caseina", formatter: value => this.parseNumber(value) },
        { text: "Gordura", value: "gordura", formatter: value => this.parseNumber(value) },
        { text: "Proteina", value: "proteina", formatter: value => this.parseNumber(value) },
        { text: "Lactose", value: "lactose", formatter: value => this.parseNumber(value) },
        { text: "ESD", value: "esd", formatter: value => this.parseNumber(value) },
        { text: "S.T.", value: "solidos_totais", formatter: value => this.parseNumber(value) },
      ],

      items: [],

      importType: 'ROTA',
    };
  },

  methods: {
    async loadReport() {
      try {
        this.$root.$progressBar.loading();

        const [dateStart, dateEnd] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/analysis`, { params: {
          type: 'ROTA',
          start_date: dateStart,
          end_date: dateEnd
        } });

        this.items = data;

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Análises de Tanque - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    formatDate: val => new Date(val).toLocaleDateString(),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    parseNumber: val => +String(val) || null,
  },
};
</script>
