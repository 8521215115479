<template>
  <div>
    <v-card
      dark
      color="transparent"
      class="rota-container"
    >
      <v-card-title
        class="justify-center text-body-2"
        style="background: rgba(0,0,0,.5);"
      >
        <span>{{ title }}</span>
      </v-card-title>

      <v-card-text class="pa-0 text-left">
        <draggable
          v-bind="{
            animation: 200,
            disabled: false,
            ghostClass: 'ghost',
            delay: 100,
            delayOnTouchOnly: true,
            handle: '.handle',
          }"
          class="produtores-grid drag-area noselect"
          :list="customers"
          :group="{
            name: 'clientes',
          }"
          @change="onCustomerPositionChange"
        >
          <transition-group type="transition">
            <template v-for="(customer, index) in customers">
              <v-hover
                :key="`${index}-${route.id}-${customer.id}`"
                v-slot:default="{ hover }"
                :class="{ remover: customer.remove }"
              >
                <v-list-item
                  dark
                  dense
                  class="list-item produtor-item"
                  :class="{ handle: customer.hasLocation }"
                >
                  <v-list-item-avatar size="16">
                    {{ listIndex(customer) }}
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ customer.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="customer.region.id">
                      {{ customer.region.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar size="18">
                    <v-btn
                      v-if="hover && !disableDelete"
                      x-small
                      icon
                      dark
                      color="white"
                      @click="onRemoveCustomer(customer)"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>

                    <v-btn
                      v-else
                      x-small
                      icon
                      dark
                      color="white"
                    >
                      <v-icon
                        v-if="!customer.hasLocation"
                        class="red--text"
                      >
                        gps_off
                      </v-icon>
                      <v-icon v-else>
                        place
                      </v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                </v-list-item>
              </v-hover>
            </template>
          </transition-group>
        </draggable>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },

  props: {
    route: {
      type: Object,
      default: () => ({ id: null, description: null }),
    },

    customers: Array,
    disableDelete: Boolean,

    title: {
      type: String,
      default: 'Entregas'
    }
  },

  methods: {

    /**
     * Recupera o indíce do cliente na lista
     */
    listIndex(customer) {
      let index = this.customers.findIndex(pr => {
        return pr.id === customer.id;
      });

      return ++index;
    },

    /**
     * @event void
     *
     * Evento acionado ao clicar para remover um cliente da rota
     * @returns {Promise<void>}
     */
    async onRemoveCustomer(customer) {
      if (!(await this.$root.$confirm('Atenção', `Deseja realmente remover o cliente <b>${customer.name}</b> da rota <b>${this.route.description}</b> ?`, { color: 'red' }))) {
        return;
      }
      const index = this.customers.findIndex(sp => sp.id === customer.id);

      if (index < 0) {
        return;
      }

      if (customer.pending) {
        return this.customers.splice(index, 1);
      }

      this.$set(this.customers, index, {
        ...customer,
        remove: true,
      });

      return this.$emit("onRemoveCustomer", customer);
    },

    /**
     * @event Object
     *
     * Evento emitido apenas para notificar o componente pai que houve mudança no formulário
     */
    onCustomerPositionChange() {
      return this.$emit("onCustomerPositionChange");
    },
  },
};
</script>
