<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        Pesagem
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formRef">
          <v-row class="mt-2">
            <v-col
              class="py-0"
              :cols="form.showQueijomatic && form.item.requiresLot ? '12' : undefined"
            >
              <v-text-field
                :value="form.item.description"
                label="Produto"
                disabled
              />
            </v-col>
            <v-col
              class="py-0"
            >
              <warehouse-select
                v-if="dialog"
                v-model="form.warehouseId"
                label="Depósito"
                auto-select-default
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="form.showQueijomatic"
              class="py-0"
            >
              <v-select
                v-model="form.tankId"
                :items="tinas"
                label="Queijomatic *"
                item-value="id_tina"
                item-text="descricao"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="form.item.requiresLot"
              class="py-0"
            >
              <v-text-field
                v-model="form.lote.numero"
                label="Lote *"
                :rules="[rules.required]"
                @blur="checkIfHasPersistedLots()"
              />
            </v-col>
            <v-col
              v-if="form.item.requiresLot"
              class="py-0"
            >
              <date-text-field
                v-model="form.lote.data_fabricacao"
                label="Fabricação"
                manual
                max=""
                :disabled="form.lote.lote_existente"
                :rules="[rules.date]"
              />
            </v-col>
            <v-col
              v-if="form.item.requiresLot"
              class="py-0"
            >
              <date-text-field
                v-model="form.lote.data_validade"
                label="Validade"
                manual
                max=""
                :disabled="form.lote.lote_existente"
                :rules="[rules.date]"
              />
            </v-col>
          </v-row>

          <scale-weighing
            v-if="dialog"
            v-model="form"
            settings-id="PRODUCT-WEIGHING"
            hide-tare
            @save="save"
          />
        </v-form>

        <div class="d-flex">
          <span class="flex-grow-1 text-h5 mt-4 black--text">Registros de Estoques</span>
        </div>

        <v-divider class="mb-4" />

        <v-data-table
          :headers="headers"
          :items="records"
          disable-pagination
          disable-sort
          disable-filtering
          hide-default-footer
        >
          <template #[`item.date`]="{ value }">
            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
          </template>
          <template #[`item.quantity`]="{ item }">
            {{ formatNumber(item.quantity) }} {{ item.measurement }}
          </template>

          <template #[`item.action`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="showPrint && item.product.id === form.item.id"
                  @click="printProduct(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      print
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Imprimir
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="hasChargebackAccess"
                  @click="chargeback(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      settings_backup_restore
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Estornar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click.native="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-menu
          v-model="settings.dialog"
          :close-on-content-click="false"
          top
          left
          content-class="settings-menu"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              small
              @click="openSettings"
            >
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Configurações</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="settings.computerId"
                    label="ID Computador"
                    outlined
                    small
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="showPrint"
                  cols="12"
                >
                  <v-select
                    v-model="settings.tagModel"
                    label="Modelo Etiqueta"
                    :items="[
                      { text: 'Produção (10x15)', value: 'PRODUCTION_10X15' },
                    ]"
                    outlined
                    small
                    clearable
                    hide-details
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="settings.autoWeighing"
                    label="Peso automático"
                    :items="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false }
                    ]"
                    outlined
                    small
                    hide-details
                    :disabled="!hasManualWeighingPermission"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="settings.packingScale"
                    label="Balança Embalagem"
                    :items="[
                      { text: 'Balança 1', value: 'weighingScale' },
                      { text: 'Balança 2', value: 'weighingScale2' }
                    ]"
                    outlined
                    small
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="settings.autoWeighing"
                  cols="6"
                >
                  <masked-text-field
                    v-model="settings.minBoxWeight"
                    label="Peso Bruto Mínimo Caixa"
                    suffix="Kg"
                    :mask="masks.float"
                    unmask
                    inputmode="numeric"
                    outlined
                    small
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="showPrint"
                  cols="6"
                >
                  <v-select
                    v-model="settings.autoPrinting"
                    label="Etiqueta automática"
                    :items="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false }
                    ]"
                    outlined
                    small
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="settings.scaleDelay"
                    label="Delay Balança"
                    suffix="seg"
                    :mask="{ mask: Number, min: 0.5, max: 3, scale: 1 }"
                    unmask="typed"
                    inputmode="numeric"
                    outlined
                    small
                    hint="Atraso de 0,5 a 3,0 segundos para leitura do peso"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="saveSettings"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.settings-menu {
  min-width: 350px !important;
  max-width: 350px !important;
}
</style>

<script setup>
import { reactive, ref, computed, watch } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { usePermissions } from '@/Support/Composables/permissions.js'
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js'
import axios from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'

import moment from 'moment'

import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import DateTextField from '@/Support/Components/DateTextField.vue'
import ScaleWeighing from '@/Domains/Shipment/StorageControl/Components/ScaleWeighing.vue'

import ZebraPrinterGenerator from '@/Domains/Shipment/Api/ZebraPrinterGenerator.js'

const { progressBar, notify } = useUtils()

const { packingSettings, savePackingSettings, printTag } = usePacking('PRODUCT-WEIGHING')

const { hasPermission } = usePermissions()

const rules = {
  required: (v) => !!v || 'Campo obrigatório!',
  date: (v) => !v || moment(v, 'YYYY-MM-DD').isValid() || 'Campo Inválido!',
}

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

// eslint-disable-next-line
const props = defineProps({
  warehouseId: String,
  records: {
    type: Array,
    default: () => [],
  },
  showPrint: Boolean,
})

const formRef = ref()

const form = reactive({
  id: null,
  item: {},
  showQueijomatic: false,
  warehouseId: null,
  tankId: null,
  lote: {
    numero: null,
    data_fabricacao: null,
    data_validade: null,
  },
  grossWeight: null,
  tareWeight1: null,
  tareWeight2: null,
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['close', 'chargeback'])

const dialog = ref(false)

useDialogHistory(dialog)

const persistedLots = ref([])

const show = async ({ id, item }) => {
  dialog.value = true

  formRef.value?.resetValidation()

  form.id = id
  form.showQueijomatic = item.showQueijomatic || false
  form.warehouseId = null
  form.tankId = null
  form.lote = {
    numero: null,
    data_fabricacao: null,
    data_validade: null,
    lote_existente: false,
  }
  form.grossWeight = null
  form.tareWeight1 = null
  form.tareWeight1 = null

  if (form.showQueijomatic) {
    loadQueijomatics()
  }

  try {
    progressBar.loading()

    const { data } = await axios.get(`/item/items-with-lots`, { params: {
      id_deposito: props.warehouseId,
      ids: [item.id],
    } })

    const [product] = data

    form.item = {
      id: product.id_item,
      code: product.cod_item,
      name: product.nome,
      description: `${product.cod_item} - ${product.nome}`,
      requiresLot: product.controlar_lotes,
      manualLot: product.manualLot,
      tareWeight: product.peso_tara,
      barcode: product.codigo_barras,
      sif: product.sif,
      sifDipoa: product.sif_dipoa,
    }

    persistedLots.value = product.stocks.map(lot => ({
      ...lot,
      text: lot.numero_lote || 'Sem Lote'
    }))
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoques'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar.hide()
  }
}

const tinas = ref([])

const loadQueijomatics = async () => {
  try {
    const { data } = await axios.get(`/silo/listaTina`)

    tinas.value = data
  } catch (e) {
    notify.error('Erro ao carregar as tinas', 'Atenção')
    console.warn(e)
  }
}

watch(dialog, (isOpen) => {
  if (!isOpen) { emit('close') }
})

const close = () => {
  dialog.value = false
}

const save = async () => {
  const isValidated = await formRef.value?.validate()

  if (!isValidated) {
    const errors = formRef.value.inputs
      .filter(item => item.hasError)
      .map(item => item.label ? `<span><b>${item.label.replace(' *', '')}</b>: ${item.errorBucket[0]}</span>` : `<b>${item.errorBucket[0]}</b>`)

    if (errors.length) {
      notify.error('Atenção', { html: `<div class="text-h4">Atenção</div>${errors.join('\n')}` })
    }

    return
  }

  try {
    progressBar.saving()

    const payload = {
      id_deposito: form.warehouseId,
      itens: [{
        id_ordem_producao_item: form.id,
        quantidade: form.grossWeight,
        id_tina: form.tankId,
        lotes: form.lote.numero
          ? [{ ...form.lote, quantidade: form.grossWeight }]
          : [],
      }],
    }

    const { records } = await api.storeWriteOff(payload)

    if (props.showPrint && packingSettings.value.autoPrinting) {
      print(form)
    }

    form.grossWeight = null

    notify.success('Baixa efetuada com sucesso', 'Sucesso')
    emit('save', { records })
  } catch (e) {
    notify.error('Erro ao efetuar baixa', 'Atenção')
    console.warn(e)
  } finally {
    progressBar.hide()
  }
}

const print = (product) => {
  const tagModel = packingSettings.value.tagModel

  if (tagModel === 'PRODUCTION_10X15') {
    const dairy = store.state.settings?.laticinio || {}

    const rawData = ZebraPrinterGenerator.productionTag10x15({
      dairyName: dairy.name,
      dairyDoc: dairy.doc,
      dairyCity: dairy.address?.city,
      dairyState: dairy.address?.state,
      dairyStreet: dairy.address?.street,
      dairyNumber: dairy.address?.number,

      grossWeight: product.grossWeight,
      tareWeight: product.item.tareWeight || 0,
      netWeight: product.grossWeight - (product.item.tareWeight || 0),

      productName: product.item.name,
      quantity: 1,
      expirationDate: product.lote?.data_validade,
      manufacturingDate: product.lote?.data_fabricacao,
      lotNumber: product.lote?.numero,

      gtin: product.item.barcode,
      sif: product.item.sifDipoa?.[dairy.id] || product.item.sif,
    })

    return printTag(rawData)
  }
}

const hasManualWeighingPermission = computed(() => hasPermission('manual-weighing'))
const hasChargebackAccess = computed(() => hasPermission('ordem-prod-exclusao-apontamento'))

const settings = reactive({
  dialog: false,
  computerId: null,
  tagModel: null,
  autoWeighing: false,
  autoPrinting: false,
  minBoxWeight: null,
  packingScale: null,
  scaleDelay: null,
})

const openSettings = () => {
  settings.dialog = true
  settings.computerId = packingSettings.value.computerId
  settings.tagModel = packingSettings.value.tagModel
  settings.autoWeighing = packingSettings.value.autoWeighing || !hasManualWeighingPermission.value
  settings.autoPrinting = packingSettings.value.autoPrinting
  settings.minBoxWeight = packingSettings.value.minBoxWeight
  settings.packingScale = packingSettings.value.packingScale
  settings.scaleDelay = packingSettings.value.scaleDelay
}

const saveSettings = async () => {
  try {
    progressBar?.saving()

    await savePackingSettings({
      computerId: settings.computerId,
      tagModel: settings.tagModel,
      autoWeighing: settings.autoWeighing,
      autoPrinting: settings.autoPrinting,
      minBoxWeight: settings.minBoxWeight,
      packingScale: settings.packingScale,
      scaleDelay: settings.scaleDelay,
    })

  } catch (error) {
    console.error(error)
    const message = error?.response?.data?.message || 'Erro ao salvar as configurações'
    notify.error(message, 'Atenção', { timeout: 6000 })
  } finally {
    progressBar?.hide()
    settings.dialog = false
  }
}

const checkIfHasPersistedLots = () => {
  const lotNumber = form.lote.numero

  const lot = persistedLots.value.find((item) => item.numero_lote === lotNumber)

  form.lote.data_fabricacao = lot?.data_fabricacao
  form.lote.data_validade = lot?.data_validade
  form.lote.lote_existente = !!lot

}

const headers = [
  { text: 'Produto', value: 'product.name' },
  { text: 'Data e Hora', value: 'date' },
  { text: 'Qtde.', value: 'quantity', align: 'center' },
  { text: 'Lote', value: 'lotNumber', align: 'center' },
  { text: 'Queijomatic', value: 'tank.name', align: 'center' },
  { text: '', value: 'action', width: 30, sortable: false },
]

const chargeback = (item) => emit('chargeback', item)

const printProduct = (item) => print({
  item: form.item,
  grossWeight: item.quantity,
  lote: {
    data_fabricacao: item.manufacturingDate,
    data_validade: item.expirationDate,
    numero: item.lotNumber,
  },
})

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
