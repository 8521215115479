<template>
  <div>
    <v-flex xs12>
      <v-card
        class="graph-card"
        color="transparent"
        dark
      >
        <v-card-title>
          {{ title }}
          <v-spacer />
          {{ indicators }}
          <v-spacer />
          {{ month }}
        </v-card-title>
        <v-card-text>
          <v-chart
            :options="Options"
            :style="{width: '100%', height: `350px !important`}"
            autoresize
          />
        </v-card-text>
      </v-card>
    </v-flex>
   
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import RatingService from "@/Domains/Projects/PQFL/BPA/Services/RatingService.js";
import _ from "lodash";

const ratingService = new RatingService();

export default {
  name: "report-table",

  components: {
  },
 
  mixins: [ReportMixin],

  props: {
    filter: Object,
    curve: String,
    ranges: Object,
  },

  data() {
    return {
      loading: false,
      
      items: [],
    };
  },

  computed: {

    title() {
      return ratingService.types[this.curve];
    },

    month() {
      return moment(this.filter.date).format('MM/YYYY');
    },
    indicators() {
      const faixa = this.ranges.filter(c => c.tipo === this.curve);
      let indicador = '';
      if (_.first(faixa).indicador) {
        indicador = _.first(faixa).indicador;
      } else if (_.first(faixa).metas_agrupadas) {
        const metas_agrupadas = JSON.parse(_.first(faixa).metas_agrupadas || '') || [];
        metas_agrupadas.forEach((meta) => {
          indicador = indicador + ' [' + meta.indicator.indicator + '] ' ;
        });
      }
      return indicador;
    },
    
    Options() {
      return this.generateGraphOptions(this.items);
    }
  
  },
  watch: {
    filter: {
      handler() {
        this.loadProducerRatings();
      },
      deep: true
    }
   
  },
  created() {
    this.loadProducerRatings();
  },

  methods: {

    async loadProducerRatings() {

      if (this.filter.project && this.filter.rangeBpa) {
        await this.loadReport();
      } else {
        this.items = [];
      }
    },

    async loadReport() {
      this.loading = true;
      this.items = [];
      try {

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/QuantidadeProdutoresBPAMes`,
          {
            mes_referencia: this.filter.date,
            tipo_faixa: this.curve,
          }
        );
        if (!_.isArray(data)) {
          throw new Error(data);
        }

        if (!_.isEmpty(data)) {
          this.items = data.map(o => {
            return {
              range: o.nome_faixa,
              producers: o.total_produtores,
              visits: o.total_visitado,
              meta: o.visitas + ' visita(s) ' + o.periodo,
            }
          })
          
        }
      }
      catch (err) {
        console.warn(err);
        this.items = [];
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    generateGraphOptions(data) {
      
      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(140, 23, 23, 1)'],
        
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
          formatter: items =>  [
            items[0].name,
            ...items.map(param => `${param.marker} ${param.seriesName} <b>${param.data}</b>`),
            `Meta: <b>${data[items[0].dataIndex].meta} </b><br>`
          ].join('<br>')
          
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => (o.range)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Produtores',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => o.producers),
          },
          {
            name: 'Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => o.visits),
          }
        ]
      };
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>