<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="space-around">
      <v-col
        cols="10"
      >
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#tab-beneficiaries">
            Beneficiados
          </v-tab>

          <v-tab href="#tab-non-beneficiaries">
            Não Beneficiados
          </v-tab>

          <v-tab href="#tab-settings">
            <v-icon>settings</v-icon>
          </v-tab>

          <v-tab-item value="tab-settings">
            <producer-end-bonus-settings />
          </v-tab-item>

          <v-tab-item value="tab-beneficiaries">
            <beneficiary-producers
              v-if="tab === 'tab-beneficiaries'"
              :beneficiaries="true"
            />
          </v-tab-item>

          <v-tab-item value="tab-non-beneficiaries">
            <beneficiary-producers
              v-if="tab === 'tab-non-beneficiaries'"
              :beneficiaries="false"
            />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProducerEndBonusSettings from "@/Domains/Financial/ProduerEndPeriodBonus/Views/BonusSettings.vue";
import BeneficiaryProducers from "@/Domains/Financial/ProduerEndPeriodBonus/Views/BeneficiaryProducers.vue";

export default {
  components: {
    ProducerEndBonusSettings,
    BeneficiaryProducers,
  },

  data() {
    return {
      tab: "tab-qualidade",
    };
  },
};
</script>
