<template>
  <div>
    <v-row
      class="grey lighten-5"
    >
      <v-col
        md="2"
        class="pt-2 pb-0 pl-6"
      >
        <v-select
          v-model="concentrado.concentrado_racao"
          :items="['Ração','Misturão']"
          placeholder="Selecionar"
          hide-details
          dense
        />
      </v-col>
      <v-col
        md="3"
        class="pt-2 pb-0 "
      >
        <masked-input
          v-model="concentrado.concentrado_porcentagem"
          placeholder="0%"
          dense
          :mask="FloatNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        :md="editable?3:4"
        class="pt-2 pb-0"
      >
        <v-select
          v-model="concentrado.concentrado_tipo_racao"
          :items="['Industrializada','Produção própria','Produção própria Tamponada','Industrializada Tamponada']"
          placeholder="Selecionar"
          hide-details
          dense
        />
      </v-col>
      <v-col
        md="3"
        class="pt-2 pb-0 "
      >
        <masked-input
          v-model="concentrado.concentrado_valor_medio"
          placeholder="0%"
          dense
          :mask="FloatNumberMask"
          currency
          return-unmasked
          @change="$emit('change', $event)"
        />
      </v-col>
      <v-col
        v-if="editable"
        md="1"
        class="pt-2 pb-0"
      >
        <v-btn
          icon
          @click="openAlert(concentrado), showDeleteDialog = true;"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <!-- Alert excluir linha -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false; concentrateDelete = null"
      @keydown.esc="showDeleteDialog = false; concentrateDelete = null"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-left text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-left black--text">
            Deseja realmente Executar a Ação ?
          </h3>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="ConcentrateDelete"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  name: "NutritionConcentrateTab",
  components: {
    MaskedInput,
  },

  filters: {
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
  props: {
    concentrado: {
      require: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
    
  data() {
    return {
      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: ".",
            min: -999999,
            max: 999999,
          },
        },
      },
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      showDeleteDialog: false,
      
    };
  },

  computed: {
      
  },
  mounted() {
  },
  methods: {
    /**
     * Abre dialog de confirmações de deleção
     */
    openAlert(item) {
      this.concentrateDelete = item;
      this.showDeleteDialog = true;
    },

    /**
     * @void
     */
    ConcentrateDelete() {
      this.$emit("onConcentrateDelete", this.concentrateDelete.id);
      this.showDeleteDialog = false;
    },
  },
}
</script>