<template>
  <v-container class="pa-0 ma-0">
    <v-row style="margin-bottom: 5px !important">
      <!-- Renderiza as  opções da questão -->
      <option-question-checkbox
        v-for="component in propOption.componentsList"
        :key="component.position"
        :description.sync="component.description"
        :position="component.position"
        :points.sync="component.point"
        :type="type"
        :questionnaire-disabled="questionnaireDisabled"
        :questionnaire-points="questionnairePoints"
        @onDeleteOption="handleDeleteOption"
        @onChange="onChangeOptions"
      />

      <v-col
        v-if="!questionnaireDisabled && !questionnairePoints"
        md="3"
        class="flex-option pb-0 pt-0"
      >
        <v-checkbox
          hide-details
          class="mr-0"
          disabled
        />
        <v-text-field
          hide-details
          dense
          label="Adicionar opção"
          class="pt-3"
          readonly
          close-on-click
          @click="addOption"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="type === 'MULTIPLE-SELECT'"
      no-gutters
    >
      <v-col
        align="center"
        md="2"
        class="mb-3 pb-2 pt-3 with-border"
      >
        <v-text-field
          v-model="localValue.maxAmountSelect"
          type="number"
          dense
          label="Qtd. max. de seleções"
          hide-details
          :readonly="questionnaireDisabled ? true : false"
          :disabled="questionnairePoints ? true : false"
          @change="changeMaxAmount"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OptionQuestionCheckbox from "@/Domains/Questionnaires/Components/OptionQuestionCheckbox.vue";
import _ from "lodash";

export default {
  components: {
    OptionQuestionCheckbox,
  },
  props: {
    type: {
      type: String,
      default: 'CHECKBOX',
    },

    options: {
      type: Array,
      default: () => ([]),
    },

    points: {
      type: Array,
      default: () => ([]),
    },

    questionnaireDisabled: {
      type: Boolean,
      default: false,
    },

    questionnairePoints: {
      type: Boolean,
      default: false,
    },

    maxAmountSelect: {
      type: [String, Number],
      default: 1,
    },
  },

  data() {
    return {
      propOption: {
        count: 1,
        componentsList: [],
      },

      localValue: {
        maxAmountSelect: 1
      }
    };
  },

  mounted() {
    this.initOptions();
  },

  methods: {
    initOptions() {
      /**
       * Caso não possar nem uma informações para o componentes não precisa fazer nada
       */
      if (_.isEmpty(this.options)) {

        this.propOption.componentsList = [
          {
            description: "",
            position: 1,
            point: 0,
          }
        ];

        return true;
      }

      this.propOption.count = 0;

      this.propOption.componentsList = this.options.map(option => {
        // Controle de quantidas de opções
        this.propOption.count++;

        return {
          description: option,
          position: this.propOption.count,
          point: this.points[this.propOption.count - 1],
        };
      });

      if (this.maxAmountSelect) {
        this.localValue.maxAmountSelect = this.maxAmountSelect;
      }
    },

    /**
     * Adiciona opção na questão
     */
    addOption() {
      // Controle de quantidas de opções
      this.propOption.count++;

      // Adicionar uma nova opção
      this.propOption.componentsList.push({
        description: null,
        position: this.propOption.count, //@todo passar a posição do objeto no array
        point: 0,
      });
    },

    /**
     * Deleta um opção
     */
    handleDeleteOption(event) {
      // Remove o componente da lista
      this.propOption.componentsList = this.propOption.componentsList.filter(valeu => valeu.position !== event.position);

      // Ajuste a flag que controla a quantidade de opções
      this.propOption.count = this.propOption.componentsList.length;

      // caso zerar as opções volta a render5izar uma opção nova '
      if (this.propOption.count === 0) {
        this.propOption.componentsList = [{ value: null, position: 1 }];
        this.propOptsion.count = 1;
      }

      this.onChangeOptions();
    },

    /**
     * trata as modificações ocorridas nas opções
     */
    onChangeOptions() {
      const options = this.propOption.componentsList.map(option => option.description).filter(i => i);

      const points = this.propOption.componentsList.map(option => option.point || 0);

      this.$emit("onChangeOptions", options, points);
    },

    /**
     * Trata change do número máximo de seleção
     */
    changeMaxAmount() {
      this.$emit("update:maxAmountSelect", this.localValue.maxAmountSelect);
    },

  },
};
</script>
