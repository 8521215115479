<template>
  <div>
    <v-card
      class="question-card pa-0"
      elevation="4"
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-container class="pt-0 pb-0">
        <v-row align="center">
          <v-col md="1">
            <v-chip
              label
              small
              color="blue"
              style="font-size: 0.9rem"
            >
              {{ ordem }}
            </v-chip>
          </v-col>
          <v-spacer />
          <v-col md="4">
            <v-autocomplete
              v-model="localValue.questionType"
              outlined
              hide-details
              chips
              label="Tipo de resposta"
              class="type-question"
              dense
              :readonly="questionnaireDisabled ? true : false"
              :disabled="questionnairePoints ? true : false"
              :items="[
                { text: 'Aberta', value: 'OPEN' },
                { text: 'Numérica', value: 'NUMBER' },
                { text: 'Caixa de Seleção', value: 'CHECKBOX' },
                { text: 'Múltipla escolha ', value: 'MULTIPLE-SELECT' },
                { text: 'Foto', value: 'PHOTOGRAPH' },
                { text: 'Data', value: 'DATE' },
                { text: 'Data e Hora', value: 'DATETIME' },
                { text: 'Horas', value: 'TIME' },
              ]"
            >
              <template #selection="{ item }">
                <v-icon
                  v-if="item.value == 'OPEN'"
                  small
                  class="pr-2"
                >
                  format_align_center
                </v-icon>

                <v-icon
                  v-if="item.value == 'NUMBER'"
                  small
                  class="pr-2"
                >
                  filter_1
                </v-icon>

                <v-icon
                  v-if="item.value == 'CHECKBOX'"
                  small
                  class="pr-2"
                >
                  radio_button_checked
                </v-icon>

                <v-icon
                  v-if="item.value == 'MULTIPLE-SELECT'"
                  small
                  class="pr-2"
                >
                  check_box
                </v-icon>

                <v-icon
                  v-if="item.value == 'PHOTOGRAPH'"
                  small
                  class="pr-2"
                >
                  insert_photo
                </v-icon>

                <v-icon
                  v-if="item.value == 'DATE'"
                  small
                  class="pr-2"
                >
                  today
                </v-icon>

                <v-icon
                  v-if="item.value == 'DATETIME'"
                  small
                  class="pr-2"
                >
                  more_time
                </v-icon>

                <v-icon
                  v-if="item.value == 'TIME'"
                  small
                  class="pr-2"
                >
                  schedule
                </v-icon>

                <span class="font-weight-medium">{{ item.text }}</span>
              </template>

              <template #item="{ item }">
                <v-list-item-avatar class="pr-0">
                  <v-icon v-if="item.value == 'OPEN'">
                    format_align_center
                  </v-icon>

                  <v-icon v-if="item.value == 'NUMBER'">
                    filter_1
                  </v-icon>

                  <v-icon v-if="item.value == 'CHECKBOX'">
                    radio_button_checked
                  </v-icon>

                  <v-icon v-if="item.value == 'MULTIPLE-SELECT'">
                    check_box
                  </v-icon>

                  <v-icon v-if="item.value == 'PHOTOGRAPH'">
                    insert_photo
                  </v-icon>

                  <v-icon v-if="item.value == 'DATE'">
                    today
                  </v-icon>

                  <v-icon v-if="item.value == 'DATETIME'">
                    more_time
                  </v-icon>

                  <v-icon v-if="item.value == 'TIME'">
                    schedule
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.text" />
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="12"
            class="pt-0 mt-0 mb-0"
          >
            <v-text-field
              v-model="localValue.label"
              label="Pergunta (Rotulo)"
              hide-details
              dense
              :readonly="questionnaireDisabled ? true : false"
              :disabled="questionnairePoints ? true : false"
              autofocus
            />
          </v-col>
        </v-row>

        <!--render das opções-->
        <option-question-body
          v-if="['CHECKBOX', 'MULTIPLE-SELECT'].includes(localValue.questionType)"
          :type="localValue.questionType"
          :options.sync="localValue.options"
          :points.sync="localValue.points"
          :max-amount-select.sync="localValue.maxAmountSelect"
          :questionnaire-disabled="questionnaireDisabled"
          :questionnaire-points="questionnairePoints"
          @onChangeOptions="onChangeOptions"
        />

        <conditional-fields
          v-if="['CHECKBOX', 'NUMBER'].includes(localValue.questionType)"
          :type="localValue.questionType"
          :options="localValue.options"
          :condition.sync="localValue.condition"
          :standard-value.sync="localValue.standardValue"
          :questionnaire-disabled="questionnaireDisabled"
          :questionnaire-points="questionnairePoints"
        />

        <v-row
          v-if="localValue.questionType === 'PHOTOGRAPH'"
          no-gutters
        >
          <v-col
            md="6"
            class="pa-1 mb-3 with-border"
          >
            <span class="span-obs">Obs: Questões do tipo "foto" aceitarão apenas imagem como resposta!</span>
          </v-col>
        </v-row>

        <v-row
          v-if="localValue.questionType === 'NUMBER'"
          no-gutters
        >
          <v-col
            md="9"
            class="pa-1 mb-3 with-border"
          >
            <span class="span-obs">Obs: Questões do tipo "Numéricas" aceitarão apenas números como resposta (Ex: 1,2,...)!</span>
          </v-col>
        </v-row>
      </v-container>

      <v-divider class="ma-0" />
      <v-card-actions style="padding: 5px !important">
        <v-spacer />

        <div class="switch-conditions">
          <span>Obrigatório</span>
          <v-switch
            v-model="localValue.required"
            hide-details
            :readonly="questionnaireDisabled ? true : false"
            :disabled="questionnairePoints ? true : false"
            class="ma-1 pb-1"
            color="teal accent-3"
          />
        </div>
        <v-divider
          vertical
          class="ml-1 mr-1 mb-0 mt-0"
        />
        <v-btn
          v-if="!questionnaireDisabled && !questionnairePoints"
          icon
          @click="deleteQuestionCard"
        >
          <v-icon dense>
            delete
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style lang="scss">
.question-card {
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 8px 8px 5px #fff;

  .type-question {
    > .v-list-item {
      height: 3rem !important;
      padding-left: 3px !important;
    }
  }

  .span-obs {
    font-family: Roboto, Arial, sans-serif;
    font-size: 1rem;
    color: #fff;
  }

  .with-border {
    border: 1px solid #ccc !important;
    border-radius: 10px;
  }

  .switch-conditions {
    display: flex;
    align-items: center;
    font-family: Roboto, Arial, sans-serif;
    font-size: 0.9rem;
    color: #fff;
  }

  .flex-option {
    display: flex;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
</style>

<script>
import OptionQuestionBody from "./OptionQuestionBody.vue";
import ConditionalFields from "./ConditionalFields.vue";

export default {
  name: "SelectTypeQuestionCard",
  components: {
    OptionQuestionBody,
    ConditionalFields,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        label: "",
        required: false,
        questionType: "OPEN",
        maxAmountSelect: 1,
        condition: {
          rotulosExtra: [],
        },
      }),
    },
    ordem: {
      type: [Number, String],
      default: 1,
    },
    questionnaireDisabled: {
      type: Boolean,
      default: false,
    },
    questionnairePoints: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

      // Variável de controle local
      localValue: {},
    };
  },

  watch: {
    localValue: {
      deep: true,
      handler: function(val) {
        this.$emit("input", val);
      },
    },
    value() {
      this.initQuestion();
    }
  },

  mounted() {
    this.initQuestion();
  },

  methods: {
    /**
     * Evento de input
     */
    inputEvent(values) {
      this.$emit("input", values);
    },

    /**
     * Evento de delete das questões
     */
    deleteQuestionCard() {
      this.$emit("onDeleteQuestion", {
        ordem: this.ordem,
        type: "QUESTION",
      });
    },

    onChangeOptions(options, points) {
      this.localValue = {
        ...this.localValue,
        options,
        points
      }
    },

    initQuestion() {
      this.localValue = this.value;
    },
  },
};
</script>
