<template>
  <div class="data-table">
    <draggable
      v-if="showCustomGroup"
      :list="groupedColumns"
      handle=".handle"
      :swap-threshold="0.9"
      class="group-drop-area noselect pa-2"
      :group="{ name: 'data-table' }"
      :move="({related}) => related.className.indexOf('disabled') === -1"
      @change="onGroupChange"
    >
      <th
        v-for="element in groupedColumns"
        :key="element.value"
        class="column"
      >
        <span>{{ element.text }}</span>
        <v-icon
          small
          @click="onGroupChange({ removed: { element } })"
        >
          cancel
        </v-icon>
      </th>
    </draggable>
    <v-data-table
      ref="data-table"
      :value="value"
      :headers="filteredHeaders"
      :items="items"
      :loading="loading"
      :dark="dark"
      :group-by="groupedColumns.length > 0 ? groupedColumns[0].value : null"
      show-expand
      :expanded="expanded"
      :show-select="showSelect"
      :background-color="dark ? 'transparent' : ''"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      v-bind="$attrs"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @click:row="$emit('click:row', $event)"
      @update:page="$emit('update:page', $event)"
      @item-expanded="$emit('item-expanded', $event)"
      @update:sort-by="$emit('update:sort-by', $event)"
      @update:sort-desc="$emit('update:sort-desc', $event)"
    >
      <template
        v-if="!$vuetify.breakpoint.mobile"
        #header="{props, on}"
      >
        <thead class="v-data-table-header">
          <draggable
            :list="props.headers"
            tag="tr"
            handle=".handle"
            :group="{ name: 'data-table' }"
            :swap-threshold="0.9"
            :move="({related}) => related.className.indexOf('disabled') === -1"
            @change="onColumnHeaderChange"
          >
            <th
              v-for="(header, idx) in props.headers"
              :key="header.text + '-' + idx"
              :class="[
                'column',
                header.align ? `text-${header.align}` : '',
                header.class || '',
                {
                  disabled: !selectedHeaders.includes(header.value),
                  active: props.options.sortBy.includes(header.value),
                  desc: props.options.sortBy.includes(header.value) && props.options.sortDesc[props.options.sortBy.findIndex(o => header.value)],
                }
              ]"
              :style="{
                ...(header.width ? { width: `${header.width}px`, minWidth: `${header.width}px` } : {}),
                ...(header.value === 'data-table-expand' ? { width: '50px' } : {}),
                ...(header.value === 'data-table-select' ? { width: '45px' } : {}),
              }"
              @click="!disableSort && header.sortable !== false ? on.sort(header.value) : null"
            >
              <template
                v-if="header.value === 'data-table-expand'"
              >
                <span
                  class="d-flex"
                  :style="`max-width: ${showSelect ? '25px' : '10px'};`"
                >
                  <v-menu open-on-hover>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                      >
                        <v-icon>view_column</v-icon>
                      </v-btn>
                    </template>

                    <v-list
                      style="max-height: 400px"
                      class="overflow-y-auto"
                    >
                      <v-list-item-group>
                        <span class="d-flex">
                          <v-list-item
                            selectable
                            class="flex-1"
                            @click.stop="onHeaderSelect('*')"
                          >
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="allSelected"
                                :indeterminate="!allSelected && selectedHeaders.length > 0"
                              />
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>Selecionar Todos</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <span
                            v-if="name !== 'Relatório'"
                            class="pa-2"
                          >
                            <v-tooltip top>
                              <template #activator="{ on }">
                                <v-btn
                                  icon
                                  @click.stop="resetSettings()"
                                  v-on="on"
                                >
                                  <v-icon>restart_alt</v-icon>
                                </v-btn>
                              </template>
                              <span>Restaurar configuração padrão</span>
                            </v-tooltip>
                          </span>
                        </span>

                        <v-divider class="my-2" />

                        <v-list-item
                          v-for="header in localHeaders"
                          :key="header.value"
                          selectable
                          @click.stop="onHeaderSelect(header.value)"
                        >
                          <v-list-item-action>
                            <v-checkbox :input-value="selectedHeaders.includes(header.value)" />
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>{{ header.text || header.altText }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </span>
              </template>

              <template v-else-if="header.value === 'data-table-select'">
                <v-simple-checkbox
                  v-if="!singleSelect"
                  :value="props.everyItem"
                  :indeterminate="!props.everyItem && props.someItems"
                  @input="v => toggleSelectAll(v)"
                />
              </template>

              <template v-else>
                <v-icon
                  v-if="!disableDrag && header.drag !== false"
                  class="handle"
                  small
                >
                  drag_indicator
                </v-icon>
                <span>
                  {{ header.text }}
                </span>
                <slot :name="`header.${header.value}`" />
                <v-icon
                  v-if="!disableSort && header.sortable !== false"
                  class="v-data-table-header__icon"
                  small
                >
                  arrow_upward
                </v-icon>
              </template>
            </th>
          </draggable>
        </thead>
      </template>

      <template
        #[`item.data-table-select`]="{ isSelected, select }"
      >
        <v-simple-checkbox
          v-if="showSelect"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template
        v-if="!showExpand"
        #[`item.data-table-expand`]="{}"
      />

      <template #[`group.header`]="{items, groupBy, isOpen, toggle}">
        <td
          class="text-start"
          :colspan="headers.length"
        >
          <v-btn
            icon
            @click="toggle"
          >
            <v-icon>
              {{ isOpen ? 'remove' : 'add' }}
            </v-icon>
          </v-btn>
          {{ get(items[0], groupBy[0]) }}
        </td>
      </template>

      <template
        v-for="h in headers.filter(h => h.hasOwnProperty('formatter'))"
        #[`item.${h.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>

      <template
        v-for="(_, slotName) in $slots"
        #[slotName]
      >
        <slot :name="slotName" />
      </template>

      <template
        v-for="(_, slotName) in $scopedSlots"
        #[slotName]="props"
      >
        <slot
          :name="slotName"
          v-bind="props"
        />
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss">
.data-table {
  .group-drop-area {
    cursor: default;
    height: auto;
    min-height: (55px * $scale);
    width: 100%;
    background-color: rgb(0, 0, 0, 0.61);

    &:empty:before {
      width: 100%;
      display: inline-block;
      color: rgba(255, 255, 255, 0.7);
      padding: (10px * $scale) (16px * $scale) 0 (16px * $scale);
      content: "Arraste uma coluna da tabela aqui para agrupá-la";
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 15px * $scale;
    }

    .column {
      margin: (8px * $scale) (16px * $scale);

      span {
        margin: 0 (10px * $scale);
      }
    }
  }

  .handle {
    cursor: move;
    visibility: hidden;
  }

  .group-drop-area .column, .sortable-chosen {
    float: left;
    border: 0;
    border-collapse: collapse;
    font-size: (13px * $scale);
    font-weight: normal;
    height: (25px * $scale);
    max-height: (25px * $scale);
    overflow: hidden;
    padding: 0 (6px * $scale) 0 (9px * $scale);
    vertical-align: middle;
    background-color: #3f51b5;
    border-color: #e0e0e0;
    border-radius: 12px;
    color: #fff;
  }

  .v-data-table-header {
    .column {
      max-width: 100%;
      white-space: nowrap;

      .v-data-table-header__icon {
        cursor: pointer;
      }

      &:hover .handle {
        visibility: visible;
      }
    }

    .sortable-chosen {
      margin-top: (12px * $scale);
      padding-top: (3px * $scale);
    }
  }
  .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 (5px * $scale) !important;
  }
}

</style>

<script>
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import Draggable from "vuedraggable";

import Ripple from 'vuetify/lib/directives/ripple';
import moment from "moment";
import _ from "lodash";

import { sortItems } from 'vuetify/lib/util/helpers';

export default {

  directives: { Ripple },

  components: { Draggable },

  mixins: [ReportMixin],

  props: {
    name: {
      type: String,
      default: 'Relatório'
    },
    value: Array,
    headers: Array,
    items: Array,
    loading: Boolean,
    dark: Boolean,
    showSelect: Boolean,
    singleSelect: Boolean,
    showExpand: Boolean,
    showCustomGroup: Boolean,
    disableDrag: Boolean,
    disableSort: Boolean,
    groupBy: [String, Array],
    expanded: Array,
  },

  data() {
    return {
      localHeaders: [],
      selectedHeaders: [],
      groupedColumns: [],
      sortBy: [],
      sortDesc: []
    }
  },

  computed: {
    headersName() {
      return this.headers.reduce((acc, cur) => ({ ...acc, [cur.value]: cur }), {});
    },

    allSelected() {
      return this.selectedHeaders.length === this.headers.length
    },

    filteredHeaders() {
      return this.localHeaders.filter(header => this.selectedHeaders.includes(header.value));
    },

    hiddenColumns() {
      let i = 1; // default show-expand
      if (this.showSelect)
        i++;
      return i;
    }
  },

  watch: {
    headers: {
      deep: true,
      immediate: true,

      handler() {
        this.setSelectedHeaders();
      },
    },
    groupBy: {
      deep: true,
      immediate: true,

      handler() {
        this.setSelectedHeaders();
      },
    }
  },

  methods: {
    onColumnHeaderChange({ moved }) {
      if (moved) {
        const realElement = this.filteredHeaders[moved.newIndex - this.hiddenColumns];
        this.$nextTick(() => {
          const oldIndex = this.localHeaders.findIndex(o => o.value === moved.element.value);
          const newIndex = this.localHeaders.findIndex(o => o.value === realElement.value);
          const [headerSelected] = this.localHeaders.splice(oldIndex, 1);
          this.localHeaders.splice(newIndex, 0, headerSelected);
          this.save();
        })
      }
    },

    onGroupChange({ added, removed }) {
      if (added) {
        this.groupedColumns = [added.element];
      }
      if (removed) {
        this.groupedColumns = [];
      }
    },

    setSelectedHeaders() {
      if (!_.isEmpty(this.groupBy)) {
        if (_.isArray(this.groupBy)) {
          this.groupedColumns = this.groupBy.map(group => this.headersName[group]);
        } else {
          this.groupedColumns = [this.headersName[this.groupBy]];
        }
      } else if (!this.showCustomGroup) {
        this.groupedColumns = [];
      }
      if (this.$store.state.settings.reports[this.name]) {
        const config = this.$store.state.settings.reports[this.name];
        this.localHeaders = config
          .map(item => this.headers.find(header => header.value === item.value))
          .filter(item => !!item); // Caso alguma coluna tenha sido removido do relatório deve ser removido quando vier do BD também
        for (let header of this.headers) {
          // Caso tenha sido adicionado uma nova coluna no relatório então não foi salvo na configuração
          // Então adiciona no fim da ordenação feita pelo usuário
          if (!this.localHeaders.some(item => item.value === header.value)) {
            this.localHeaders.push(header);
          }
        }
        if (this.groupBy) {
          this.localHeaders = this.localHeaders.filter(item => item.value != this.groupBy);
        }
        this.selectedHeaders = config.filter(item => item.show).map(({ value }) => value);
      }
      else {
        this.localHeaders = this.headers;
        if (this.groupBy) {
          this.localHeaders = this.localHeaders.filter(item => item.value != this.groupBy);
        }
        // eslint-disable-next-line no-prototype-builtins
        this.selectedHeaders = this.localHeaders.filter(item => item.hasOwnProperty('show') ? item.show : true).map(({ value }) => value);
      }
    },

    /**
     * @event string
     *
     * Evento acionado ao selecionar uma coluna para exibir na lista
     */
    onHeaderSelect(value) {
      if (value === '*') {
        if (this.allSelected) {
          this.selectedHeaders = [];
        }
        else {
          this.selectedHeaders = this.headers.map(({ value }) => value);
          this.save();
        }
        return;
      }

      const idx = this.selectedHeaders.findIndex(item => item === value);

      if (idx === -1) {
        this.selectedHeaders.push(value);
      }
      else {
        this.selectedHeaders.splice(idx, 1);
      }
      this.save();
    },

    save: _.debounce(async function() {
      if (this.name === 'Relatório') {
        return;
      }

      this.saving = true;
      try {
        const headers = this.localHeaders.map(header => ({
          value: header.value,
          show: this.selectedHeaders.includes(header.value)
        }))

        const { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoRelatorio`,
          {
            relatorio: this.name,
            configuracao: headers,
          }
        );

        if (!data.codigo) {
          throw data;
        }

        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.error(error);
      } finally {
        this.saving = false;
        this.$store.dispatch('updateReportsSettings');
      }
    }, 2000),

    async resetSettings() {
      if (this.name === 'Relatório') {
        return;
      }

      this.$root.$progressBar.saving();
      try {
        const { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoRelatorio`,
          {
            relatorio: this.name,
            configuracao: null,
          }
        );

        if (!data.codigo) {
          throw data;
        }

        await this.$store.dispatch('updateReportsSettings');
        this.setSelectedHeaders();

        this.$snotify.success("Configurações restaurada com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao restaurar configurações", "Atenção");
        console.error(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportJson(isExcel = false) {
      const groupBy = this.groupedColumns.length > 0 ? this.groupedColumns[0] : null;

      const items = sortItems(this.items, this.sortBy, this.sortDesc, 'pt-BR');

      return items.map(o => {
        let initial = {};

        if (groupBy) {
          initial[groupBy.text] = _.get(o, groupBy.value, "");
        }

        return this.filteredHeaders
          .filter(header => !!header.text)
          .reduce((acc, header) => ({
            ...acc,
            [header.text]: ('formatter' in header) && !(isExcel && ('mask' in header)) ? header.formatter(_.get(o, header.value, '')) : _.get(o, header.value, ''),
          }), initial);
      });
    },

    exportExcel(json = null, filename = null) {
      let worksheet = this.XLSX.utils.json_to_sheet([]);

      if (!json) {
        json = this.getReportJson(true);
      }

      if (json.length === 0) {
        return;
      }

      const headers = this.filteredHeaders.filter(header => !!header.text);

      const groupBy = this.groupedColumns.length > 0 ? this.groupedColumns[0] : null;

      if (groupBy) {
        worksheet["!merges"] = [];
        const totalCols = headers.length - 1;

        let currentRow = 0;
        const data = _.groupBy(json, groupBy.text)

        _.forEach(data, (items, name) => {
          // Delete Group Title from items
          items.forEach((item) => {
            delete item[groupBy.text];
          });

          // Group Title
          const addr = this.XLSX.utils.encode_cell({ r: currentRow, c: 0 });
          worksheet[addr] = { v: name };
          worksheet["!merges"].push({ s: { r: currentRow, c: 0 }, e: { r: currentRow, c: totalCols } });

          currentRow += 1;

          this.XLSX.utils.sheet_add_json(worksheet, items, { origin: currentRow });
          this.applyStyles(worksheet, items, null, false, currentRow);
          this.applyMasks(worksheet, headers, items.length, currentRow);

          currentRow += items.length + 2;
        });
      }
      else {
        this.XLSX.utils.sheet_add_json(worksheet, json, { origin: 0 });
        this.applyStyles(worksheet, json, null, false);
        this.applyMasks(worksheet, headers, json.length);
      }

      worksheet['!cols'] = this.getColsWidth(json);

      if (!filename) {
        filename = this.name;
      }

      const workbook = this.XLSX.utils.book_new();

      this.XLSX.utils.book_append_sheet(workbook, worksheet, this.truncate(filename, 31));
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    async print(jsonParam = null, title = null, landscape = false) {
      try {
        if (!title) {
          title = this.name;
        }

        let json = jsonParam;

        if (!jsonParam) {
          json = this.getReportJson();
        }

        if (json.length === 0) {
          return;
        }

        let body = [];

        const headersObject = this.headers.reduce((acc, cur) => ({ ...acc, [cur.text]: cur }), {});

        let headers = Object.keys(json[0])
          .filter(text => text)
          .map((text) => {
            if (jsonParam) {
              return {
                key: text,
                label: text,
                class: 'text-left',
                attributes: {
                  style: ''
                }
              }
            }

            const header = headersObject[text] || {};
            const width = header.width ? parseInt(header.width * 0.6) : null;

            return {
              key: header.text,
              label: header.text,
              class: 'text-left',
              attributes: {
                style: width ? `width: ${width}px;` : ''
              }
            }
          });

        const groupBy = this.groupedColumns.length > 0 ? this.groupedColumns[0] : null;

        if (groupBy) {
          // Delete Group Title from items
          headers = headers.filter(header => header.key !== groupBy.text);

          const data = _.groupBy(json, groupBy.text)
          _.forEach(data, (items, name) => {
            body.push({
              tag: 'section',
              class: 'row',
              children: [{
                tag: 'datatable',
                title: name,
                headers,
                items
              }]
            });
          });
        }
        else {
          body.push({
            tag: 'section',
            class: 'row',
            children: [{
              tag: 'datatable',
              rotateHeaders: headers.length > (landscape ? 20 : 12),
              headers,
              items: json
            }]
          });
        }

        const header = [{
          tag: 'div',
          class: 'row',
          children: [{
            tag: 'div',
            class: 'text-right',
            contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
          }]
        }];

        await this.printPdf({ pages: [{ title, header, body }], landscape });
      }
      catch (err) {
        console.warn(err);
      }
    },

    toggleSelectAll(v) {
      this.$refs['data-table'].toggleSelectAll(v);
    },

    get: (items, path) => _.get(items, path, ''),
    truncate: (value, length) => _.truncate(value, { length }),
  },
};
</script>
