<template>
  <v-dialog
    v-model="dialog"
    width="1200"
    persistent
    @click:outside="close"
  >
    <v-card
      v-if="itinerary"
      style="background: #f5f5f5"
    >
      <v-card-text class="pa-0">
        <v-row class="pa-0 ma-0">
          <v-col
            cols="7"
            class="pa-0 ma-0"
          >
            <v-card>
              <route-map
                ref="map"
                height="590px"
                :center="$store.state.settings.coordenadas"
                :markers="markers"
                :path="positions"
                :polyline="plannedRoute.polyline"
              >
                <template #controls>
                  <div
                    v-if="itinerary.route.polyline"
                    class="map-custom-points"
                  >
                    <v-checkbox
                      v-model="plannedRoute.show"
                      label="Rota planejada"
                      hide-details
                      class="mx-0 my-1"
                      @click="onShowPlannedRoute"
                    />
                  </div>
                </template>
              </route-map>

              <v-overlay
                :absolute="true"
                :opacity="0.5"
                :value="overlay"
              >
                <v-btn @click="traceItinerary">
                  VISUALIZAR NO MAPA
                </v-btn>
              </v-overlay>
            </v-card>
          </v-col>

          <v-col
            cols="5"
            class="pr-0 pt-0 pb-0"
          >
            <div style="height: 590px; overflow-y: scroll">
              <v-card class="elevation-2 mr-3 mt-3">
                <v-card-title
                  class="text-overline pa-0 pt-1 pl-2 pr-2 pb-2"
                  style="font-size: 12px !important;"
                >
                  <span>
                    <b>{{ itinerary.route.description }}</b> | {{ itinerary.time.startedAt | date('DD/MM HH:mm') }} - {{ itinerary.time.endedAt | date('DD/MM HH:mm') }}
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-row class="text-caption pl-2 pr-2">
                    <v-col
                      cols="6"
                      class="pa-0"
                    >
                      Motorista: {{ itinerary.driver.name }} <br>
                      Placa: {{ itinerary.vehicle.description }} <br>
                      <span v-if="itinerary.mobile.version">Mobile: 1.0.{{ itinerary.mobile.version }}v</span>
                    </v-col>
                    <v-col
                      cols="6"
                      class="pa-0 text-right"
                    >
                      Total: {{ itinerary.distance.total }} km<br>
                      <span v-if="itinerary.distance.gps">Gps: {{ itinerary.distance.gps }} km</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-timeline
                v-if="!loading"
                align-top
                reverse
                dense
                class="ml-4"
              >
                <template v-for="(item, idx) in steps">
                  <timeline-collect-item
                    v-if="item.type === 'COLLECT'"
                    :key="idx"
                    :item="item"
                    @viewOnMap="onViewOnMap"
                  />
                  <timeline-visit-item
                    v-else-if="item.type === 'VISIT'"
                    :key="idx"
                    :item="item"
                    @viewOnMap="onViewOnMap"
                  />
                  <timeline-obstacle-item
                    v-else-if="item.type === 'OBSTACLE'"
                    :key="idx"
                    :item="item"
                  />
                  <timeline-time-item
                    v-else-if="item.type === 'DURATION'"
                    :key="idx"
                    :item="item"
                    :timeStop="timeStop"
                  />
                  <timeline-step-item
                    v-else
                    :key="idx"
                    :item="item"
                  />
                </template>
              </v-timeline>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import * as _ from "lodash";

import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";

import TimelineCollectItem from "@/Domains/Itineraries/Components/TimelineCollectItem.vue";
import TimelineVisitItem from "@/Domains/Itineraries/Components/TimelineVisitItem.vue";
import TimelineObstacleItem from "@/Domains/Itineraries/Components/TimelineObstacleItem.vue";
import TimelineTimeItem from "@/Domains/Itineraries/Components/TimelineTimeItem.vue";
import TimelineStepItem from "@/Domains/Itineraries/Components/TimelineStepItem.vue";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

const visitsService = new VisitsService();

export default {

  components: {
    TimelineTimeItem,
    TimelineObstacleItem,
    TimelineCollectItem,
    TimelineVisitItem,
    TimelineStepItem,
    RouteMap,
  },

  filters: {

    date(value, opts) {
      return moment(value).format(opts);
    },

  },

  props: {
    type: {
      type: String,
      default: 'COLLECTS'
    },
  },

  data() {
    return {
      // Dialogs
      dialog: false,
      overlay: true,
      loading: false,
      stopTimeVehiclePosition: [],
      itinerary: null,
      positions: [],
      steps: [],
      plannedRoute: {
        show: false,
        polyline: null,
      },
      timeStop: [],
      idxTimeStop: 0,
    };
  },

  computed: {

    markers() {
      if (_.isEmpty(this.steps)) {
        return [];
      }

      const markers = this.steps
        .filter(step => {
          if (!step.producer) {
            return false;
          }

          if (!step.producer.pos) {
            return false;
          }

          return step.producer.pos.lat && step.producer.pos.lng;
        })
        .map((step, idx) => {

          let richMetaBytype;

          if (step.type === "OBSTACLE") {
            richMetaBytype = {
              Motivo: step.reason
            }
          }

          if (step.type === "COLLECT") {
            richMetaBytype = {
              Litros: `${step.vol} L`,
            };
          }

          return {
            key: idx,
            location: step.producer.pos,
            draggable: false,
            visible: true,
            color: step.type === "OBSTACLE" ? 'orange' : 'red',
            label: idx + 1,
            richMeta: {
              Produtor: step.producer.name,
              Código: step.producer.code,
              ...richMetaBytype
            }
          };
        });

      if (_.isEmpty(this.positions)) {
        return markers;
      }

      const startPosition = _.first(this.positions);
      const endPosition = _.last(this.positions);

      return [
        {
          key: 'START_POSITION',
          location: startPosition,
          draggable: false,
          visible: true,
          color: 'red',
          label: `PARTIDA`,
        },
        ...markers,
        {
          key: 'END_POSITION',
          location: endPosition,
          draggable: false,
          visible: true,
          color: 'red',
          label: `CHEGADA`,
        },
        ...this.stopTimeVehiclePosition
      ]
    },
  },

  methods: {

    async show(itinerary) {
      this.itinerary = itinerary;
      if (this.type == 'COLLECTS') {
        this.showCollects();
      }
      else if (this.type == 'VISITS') {
        this.showVisits();
      }
      this.timeStopVehicle();
    },
    async showCollects() {
      try {

        const itineraryCollect = await this.$axios.post(`/coleta/getItineraryCollect`, this.$qs.stringify({
          id_itinerario: this.itinerary.id,
        }));

        if (_.isEmpty(_.get(itineraryCollect, 'data.coletas')) || !_.isArray(_.get(itineraryCollect, 'data.coletas'))) {

          if (_.isEmpty(_.get(itineraryCollect, 'data.impedimento')) || !_.isArray(_.get(itineraryCollect, 'data.impedimento'))) {
            throw 'Erro ao carregar';
          }
        }

        let collects = itineraryCollect.data.coletas || [];
        let obstacles = itineraryCollect.data.impedimento || [];
        let samples = itineraryCollect.data.coletas_amostra || [];

        samples = samples.map(sample => {
          return {
            id: sample.id_coletas_amostra,
            collectedAt: sample.data,
            code: sample.numero_amostra,
            obs: sample.observacao,
            producer: {
              id: sample.id_produtor,
              code: sample.codigo_produtor,
              name: sample.nome_produtor,
            },
          };
        });

        const samplesByProducer = _.groupBy(samples, 'producer.id');

        collects = collects.map(collect => {

          const antibioticSamples = _.get(samplesByProducer, collect.id_produtor);
          const coolers = JSON.parse(collect.resfriadores) || [];

          const pictures = JSON.parse(collect.url_coleta) || [];

          return {
            id: collect.id_coleta,
            type: 'COLLECT',
            date: collect.data,
            vol: parseFloat(collect.quantidade_coleta),
            temp: parseFloat(collect.temperatura),
            sample: collect.numero_amostra,
            alizarol: collect.alizarol,
            rejected: collect.rejeitada,
            producer: {
              id: collect.id_produtor,
              code: collect.codigo_produtor,
              name: collect.nome_produtor,
              pos: {
                lat: parseFloat(collect.latitude) ? parseFloat(collect.latitude) : '',
                lng: parseFloat(collect.longitude) ? parseFloat(collect.longitude) : '',
              },
            },
            pictures: [
              ...coolers
                .filter(cooler => cooler.url_regua)
                .map(cooler => ({
                  type: 'RULE',
                  src: cooler.url_regua,
                  caption: 'Régua',
                })),
              ...coolers
                .filter(cooler => cooler.url_temperatura)
                .map(cooler => ({
                  type: 'TEMP',
                  src: cooler.url_temperatura,
                  caption: 'Temperatura',
                })),
              ...pictures
                .map(picture => ({
                  type: 'COLLECT',
                  src: picture,
                  caption: 'Coleta',
                }))
            ],
            antibioticSamples: antibioticSamples || [],
            distance: collect.km_total || 0,
            gpsDistance: collect.km_gps || 0.0,
          };
        });

        obstacles = obstacles.map(obstacle => {

          const pictures = JSON.parse(obstacle.url_anexos) || [];
          return {
            id: obstacle.id_impedimento_coletas,
            type: 'OBSTACLE',
            date: obstacle.data,
            producer: {
              id: obstacle.id_produtor,
              code: obstacle.codigo_produtor,
              name: obstacle.nome_produtor,
              pos: {
                lat: parseFloat(obstacle.latitude) ? parseFloat(obstacle.latitude) : '',
                lng: parseFloat(obstacle.longitude) ? parseFloat(obstacle.longitude) : '',
              },
            },
            reason: obstacle.motivo,
            pictures: pictures.map(picture => {
              return {
                src: picture,
                thumb: picture,
                caption: 'Impedimento',
              }
            })
          };
        });

        if (this.itinerary.time.arrivalAt) {
          collects.push({
            id: "CHEGADA",
            type: 'ITEM',
            date: this.itinerary.time.arrivalAt,
            title: "CHEGADA NO PÁTIO",
          });
        }

        if (this.itinerary.time.unloadedAt) {
          collects.push({
            id: "DESCARGA",
            type: 'ITEM',
            date: this.itinerary.time.unloadedAt,
            title: "DESCARGA",
          });
        }

        const events = [ ...collects, ...obstacles ].sort((a, b) => moment(a.date).diff(moment(b.date)));

        let markerId = 1;

        let gpsDistance = 0.0;

        this.steps = events.reduce((events, event) => {

          const lastEvent = _.last(events);

          if (event.producer && event.producer.pos.lat && event.producer.pos.lng) {
            event.markerId = markerId++;
          }

          let gpsDiff = 0.0;

          if (event.gpsDistance) {
            gpsDiff = Math.abs(event.gpsDistance - gpsDistance);

            gpsDistance = event.gpsDistance;
          }

          if (_.isEmpty(lastEvent)) {
            return [
              event,
            ];
          }

          const duration = moment.utc(moment(event.date).diff(lastEvent.date)).format('HH:mm:ss');

          const producerTotalTimeStop = this.timeStop.find((item) => {
            if(item.id === _.get(event, 'producer.id')) {
              return  item;
            }
          });

            const totalTimeStop = producerTotalTimeStop  ? producerTotalTimeStop : {'id': 0, 'local': '', 'total': "00:00:00"};

          return [
            ...events,
            {
              type: 'DURATION',
              duration,
              gpsDiff,
              totalTimeStop,
            },
            event,
          ];
        }, []);

        this.dialog = true;
      } catch (e) {
        console.error(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o mapa!",
          "Atenção"
        );
      }
    },

    async showVisits() {
      try {
        const { data } = await this.$axios.post(
          `/visita/getItineraryVisit`,
          { id_itinerario: this.itinerary.id }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        const events = data.map(visit => {

          const date = moment(visit.data_visita);
          const time = moment(visit.hora_chegada, 'HH:mm:ss');

          return {
            type: 'VISIT',
            date: date.hour(time.hour()).minute(time.minute()).second(time.minute()),
            startedAt: visit.hora_chegada,
            endedAt: visit.hora_saida,
            obs: visit.observacao,
            types: visit.tipo.map(tipo => visitsService.types[tipo] || null),
            pictures: _.map(visit.fotos, (value, key) => {
              return {
                src: key,
                thumb: key,
                caption: value,
              }
            }),
            producer: {
              name: visit.nome,
              code: visit.codigo_laticinio,
              pos: {
                lat: parseFloat(visit.end_lat_ini),
                lng: parseFloat(visit.end_lng_ini),
              },
            },
          };
        });

        if (this.itinerary.time.arrivalAt) {
          events.push({
            id: "CHEGADA",
            type: 'ITEM',
            date: this.itinerary.time.arrivalAt,
            title: "CHEGADA NO PÁTIO",
          });
        }

        let markerId = 1;

        this.steps = events
          .sort((a, b) => moment(a.date).diff(moment(b.date)))
          .reduce((events, event) => {

            const lastEvent = _.last(events);

            if (event.producer && event.producer.pos.lat && event.producer.pos.lng) {
              event.markerId = markerId++;
            }

            if (_.isEmpty(lastEvent)) {
              return [
                event,
              ];
            }

            const duration = moment.utc(moment(event.date).diff(lastEvent.date)).format('HH:mm:ss');

            return [
              ...events,
              {
                type: 'DURATION',
                duration
              },
              event,
            ];
          }, []);

        this.dialog = true;
      } catch (e) {
        console.error(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o mapa!",
          "Atenção"
        );
      }
    },
    async timeStopVehicle() {
      try {
        const { data } = await this.$axios.post(`/itinerario/getStopLocation`, this.$qs.stringify({
          id_itinerario: this.itinerary.id,
        }));

        this.timeStop = data[0];

        const stopTimeVehiclePositionMap  = data[1].map(function(step, indx) {
          if (step.visibleMap) {
            return {
              key: (indx+'_PARADA'),
              location: {
                lat: parseFloat(step.pos.lat) ? parseFloat(step.pos.lat) : '',
                lng: parseFloat(step.pos.lng) ? parseFloat(step.pos.lng) : '',
              },
              draggable: false,
              visible: true,
              color: 'black',
              label: `P`,
              richMeta: {
                Tempo: step.total,
                Data: step.data,
              }
            }
          }
        });

        this.stopTimeVehiclePosition = stopTimeVehiclePositionMap.filter(function(step) {
          if (step) {
            return step;
          }
        });

      } catch (e) {
        console.error(e);
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o tempo!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },
    async traceItinerary() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/itinerario/getItineraryLocation`, this.$qs.stringify({
          id_itinerario: this.itinerary.id,
        }));

        if (!_.isObject(data)) {
          throw 'Erro ao carregar';
        }

        if (_.isEmpty(data.posicoes)) {
          this.$snotify.warning(
            "Não há localizações nesse itinerário! Pois o app não foi sincronizado.",
            "Atenção"
          );
        }
        else {
          this.positions = data.posicoes.map(position => {

            const [ lat, lng ] = position.pos.split(',');

            return {
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            }
          });

          if (this.type == 'VISITS') {
            // Calcula a distância mais próxima em que a visita foi realizada
            this.steps = this.steps.map(step => {
              if (!!step.producer && !!step.producer.pos) {
                step.distance = this.$refs.map.getNearbyDistance(step.producer.pos, this.positions);
              }
              return step;
            });
          }
        }

        this.overlay = false;
      } catch (e) {
        console.error(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o mapa!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    onShowPlannedRoute() {
      this.plannedRoute.polyline = this.plannedRoute.show ? this.itinerary.route.polyline : null
    },

    onViewOnMap(markerId) {
      this.$refs.map.openMarkerInfo(markerId);
    },

    close() {
      this.itinerary = null;
      this.positions = [];
      this.steps = [];
      this.overlay = true;
      this.dialog = false;
    },

  },

}
</script>
