<template>
  <div>
    <v-autocomplete
      v-model="input"
      :loading="loading"
      :items="vehicles"
      item-text="description"
      item-value="id"
      prepend-inner-icon="local_shipping"
      :label="label"
      :return-object="returnObject"
      :hide-details="hideDetails"
      :filled="filled"
      clearable
      v-bind="$attrs"
      @change="onChange"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.description }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-chip
              x-small
              color="gray"
              text-color="#000000"
            >
              {{ item.tipo_carga.toUpperCase() }}
            </v-chip>
            <v-chip
              v-if="item.status === 'ATIVO'"
              x-small
              color="green darken-1"
              text-color="#FFFFFF"
            >
              {{ item.status }}
            </v-chip>
            <v-chip
              v-if="item.status === 'DESATIVADO'"
              x-small
              color="red darken-1"
              text-color="#FFFFFF"
            >
              {{ item.status }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  props: {
    value: {
      type: [Object, String],
    },

    carga: {
      type: [Array, String],
    },

    type: {
      type: [Array, String],
    },

    label: {
      type: String,
      default: 'Veículo',
    },

    filled: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // técnicos do Formulário (Autocomplete)
      vehicles: [],
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  async mounted() {
    this.input = this.value;

    await this.loadVehicles();
  },

  methods: {
    async loadVehicles() {
      try {
        this.loading = true;

        var tipoCarga = '';

        if (this.type) {
          switch (this.type) {
            case 'COLLECTS':
              tipoCarga = ['leite', 'reboque'].join(',');
              break;
            case 'VISITS':
              tipoCarga = ['consultoria'].join(',');
              break;
            default:
              tipoCarga = Array.isArray(this.carga) ? this.carga.join(',') : this.carga;
              break;
          }
        }
        else {
          tipoCarga = Array.isArray(this.carga) ? this.carga.join(',') : this.carga;
        }

        const { data } = await this.$axios.post(
          `/equipamento/listaJson`,
          this.$qs.stringify({
            tipo_carga: tipoCarga,
          }),
        );

        if (_.isString(data)) {
          throw data;
        }

        this.vehicles = data.map(eqp => {
          return {
            id: eqp.id_equipamento,
            description: (eqp.placa || "").toUpperCase(),
            capacity: parseInt(eqp.capacidade) || 0,
            tanks: JSON.parse(eqp.tanque || '[]'),
            plate: eqp.placa,
            status: (eqp.ativo == 1 ? 'ATIVO' : 'DESATIVADO'),
            tipo_carga: eqp.tipo_carga
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onChange(event) {

      const newValue = event || {};

      // atualiza o v-model do componente
      this.$emit("input", newValue);

      this.$emit("change");
    },
  },
};
</script>
