<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Custo do Leite
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab>
          Relatório
        </v-tab>

        <v-tab>
          Configurações
        </v-tab>

        <v-tab-item>
          <milk-cost />
        </v-tab-item>

        <v-tab-item>
          <milk-cost-taxes-settings />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import MilkCost from "@/Domains/Financial/Reports/MilkCost/Views/MilkCost.vue"
import MilkCostTaxesSettings from "@/Domains/Financial/Reports/MilkCost/Views/MilkCostTaxesSettings.vue"

export default {
  components: {
    MilkCost,
    MilkCostTaxesSettings,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>
