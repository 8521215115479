<template>
  <div
    class="chart-column-wrapper"
    :style="{height: altura}"
  >
    <v-chart :options="option" />
  </div>
</template>

<style lang="scss">
.chart-column-wrapper {
  width: 100%;
}

.echarts {
  width: 100% !important;
  height: 100% !important;
  align-content: center;
}
</style>

<script>
export default {
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    category: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Gráfico',
    },
  },

  data() {
    // Ajusta o título com quebra de linha
    function wrapText(text, maxWidth) {
      const words = text.split(' ');
      let currentLine = words[0];
      const lines = [];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const testLine = currentLine + ' ' + word;

        if (testLine.length <= maxWidth) {
          currentLine = testLine;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }

      lines.push(currentLine);
      return lines.join('\n');
    }

    let wrappedTitle = this.title;
    if (this.title.length >= 40 && this.title.length <= 89) {
      wrappedTitle = wrapText(this.title, 45);
    }

    return {
      altura: this.category[0].length <= 10 ? '250px' : '550px',

      option: {
        color: ["#5793f3"],
        tooltip: {},
        title: {
          left: 10,
          top: 10,
          text: wrappedTitle,
          textStyle: { fontSize: 12 },
        },
        toolbox: {
          top: 45,
          left: 10,
          feature: {
            saveAsImage: {
              title: '',
              backgroundColor: '#FFFF',
            }
          }
        },
        xAxis: {
          type: "category",
          data: this.category,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            rotate: 68,
            color: 'black',
            fontSize: 8.6
          }
        },
        yAxis: {
          type: "value",
          max: function (value) {
            return value.max + 5;
          },
        },
        grid: {
          left: "13%",
          right: "3%",
          height: "30%",
          bottom: 40,
          top: 80,
        },
        series: this.series,
      },
    };
  },
};
</script>