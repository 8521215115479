<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col>
          <h2>{{ Report.title }} </h2>
          <p> Meta: Visitar {{ goal.visits }} {{ (goal.type=="PERCENT")?"%":"" }} dos {{ goal.status }} no mês</p>
          <p>{{ Report.text }}</p>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="month"
        :search="filter.search"
        :loading="loading"
        class="elevation-1"
        :items-per-page="-1"
        hide-default-footer
        show-expand
        single-expand
        @item-expanded="showDetails"
      >
        <template #[`item.month`]="{value}">
          {{ formatMonth(value) }}
        </template>
        <template #[`item.visitsPerc`]="{value}">
          {{ formatNumber(value) }}%
        </template>
        <template #[`item.icone`]="{value}">
          <v-icon
            right
            :color="value == 'verified' ? 'green darken-1': 'red darken-1'"
          >
            {{ value }}
          </v-icon>
        </template>
          
        <template #expanded-item="{headers}">
          <td
            :colspan="headers.length"
            class="pa-5 text-center"
          >
            <v-data-table
              :headers="details.headers"
              :items="details.items"
              :search="details.search"
              show-expand
              single-expand
              class="elevation-1"
              :items-per-page="-1"
              hide-default-footer
              @item-expanded="showVisits"
            >
              <template #[`item.status`]="{value}">
                <v-chip
                  v-if="value == 'DESVINCULADO'"
                  color="red"
                  small
                >
                  <v-avatar
                    left
                    small
                  >
                    <v-icon>cancel</v-icon>
                  </v-avatar>
                  D
                </v-chip>
                <v-chip
                  v-else-if="value == 'SUSPENSO'"
                  color="orange"
                  small
                >
                  <v-avatar
                    left
                    small
                  >
                    <v-icon>remove_circle</v-icon>
                  </v-avatar>
                  S
                </v-chip>
                <v-chip
                  v-else
                  color="green"
                  small
                >
                  <v-avatar
                    left
                    small
                  >
                    <v-icon>check_circle</v-icon>
                  </v-avatar>
                  A
                </v-chip>
              </template>
              <template #expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0 text-center"
                >
                  <v-data-table
                    v-if="item.visits.length"
                    :headers="visits.headers"
                    :items="item.visits"
                    :items-per-page="item.visits.length"
                    dense
                    hide-default-footer
                    class="elevation-1 ma-3"
                  >
                    <template
                      #[`item.data_visita`]="{ value }"
                    >
                      <v-chip
                        x-small
                        label
                        color="blue"
                      >
                        {{ formatDate(value, "DD/MM/YYYY") }}
                      </v-chip>
                    </template>
                    <template
                      #[`item.questionarios`]="{ value }"
                    >
                      <v-tooltip
                        v-if="value.length > 0"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            class="pa-0 ma-0"
                            v-on="on"
                            @click.stop="printModel(item.id)"
                          >
                            <v-icon
                              color="orange lighten-3"
                              class="pa-0 ma-0"
                            >
                              receipt_long
                            </v-icon>
                          </v-btn>
                        </template>
                        Questionário Aplicado:
                        <p
                          v-for="q in value"
                          :key="q.id_formulario"
                        >
                          {{ q.titulo }}
                        </p>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                  <span v-else>
                    Nenhuma visita encontrada
                  </span>
                </td>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-flex>
  </div>
</template>

<script>

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "emergency-items",

  components: {
  },
 
  mixins: [ReportMixin],

  props: {
    type: String,
    filter: Object,
    data: Object,
    loading: Boolean,
  },

  data() {
    return {
      // Dialogo utilizado para a impressão das visitas
      printDialog: {
        show: false,
        item: {},
        params: {
          showABCCurve: true,
          showQualityResults: true,
          showQualityAvg: true,
          showOpenedRecommendations: true,
          showChecklists: true,
          showSampleCollects: true,
          showPriceNegotiation: true,
        },
      },

      details: {
        headers: [],
        items: [],
        type: '',
        month: '',
        search: ''
      },

      visits: {
        headers: [
          { text: 'Técnico', value: 'nome_tecnico' },
          { text: 'Data', value: 'data_visita' },
          { text: 'Início', value: 'hora_chegada' },
          { text: 'Fim', value: 'hora_saida' },
          { text: 'Observação', value: 'observacao' },
          { text: '', value: 'questionarios' },
        ]
      },

    };
  },

  computed: {
    Report() {
      let titulo = "";
      let texto = "";
      switch (this.type) {
        case 'priority' :
          titulo = 'Prioritários CPP';
          texto = 'Produtores com média geométrica trimestral acima de 300 no mês selecionado';
          break;
        case 'critical' :
          titulo = 'Críticos CPP';
          texto = 'Produtores com 2 médias trimestrais consecutivas acima de 300 no mês selecionado';
          break;
        case 'priority-ccs' :
          titulo = 'Prioritários CCS';
          texto = 'Produtores com média geométrica mensal acima de 500 no mês selecionado';
          break;
      }
      return { title: titulo, text: texto }
    },

    items() {
      return this.data.items;
    },

    itemsVisits() {
      return this.data.itemsVisits;
    },

    headers() {
      return this.data.headers;
    },

    goal() {
      return this.data.goal;
    }

  },
   
  methods: {
    
    showDetails({ item }) {
      this.loading = true;
      
      this.details = [];
      
      const months = item.details[0].historico_medias_json.map(({ data }) => data).reverse();

      if (this.type == 'critical') {
        this.details.headers = [
          { text: 'Código', value: 'code' },
          { text: "Produtor", value: "producer" },
          { text: 'Rota Principal', value: 'route' },
          { text: 'Salvo', value: 'save' },
          ...months.map(date => ({
            text: _.upperFirst(moment(date).format("MMM/YY")),
            value: date,
            align: 'center',
          })),
          { text: "Visitas", value: "visits.length", align: 'center' },
          { text: "Menor CPP mês", value: "smaller_cpp", align: 'center' },
          { text: "Status", value: "status", align: 'center' },
        ];
      } else {
        this.details.headers = [
          { text: 'Código', value: 'code' },
          { text: "Produtor", value: "producer" },
          { text: 'Rota Principal', value: 'route' },
          ...months.map(date => ({
            text: _.upperFirst(moment(date).format("MMM/YY")),
            value: date,
            align: 'center',
          })),
          { text: "Visitas", value: "visits.length", align: 'center' },
          { text: "Status", value: "status", align: 'center' },
        ];
      }

      this.details.items = item.details.map(o => {
        o.historico_medias_json = o.historico_medias_json || [];
        const dates = o.historico_medias_json.reduce(
          (acc, media) => ({
            ... acc,
            [media.data]: parseInt(this.type === 'priority-ccs' ? media.ccs_geometrica_mes : media.cbt_geometrica) || 0
          }),
          {}
        );

        const visits = o.visitas.map(v => {
          return {
            id: v.id_visita,
            data_visita: v.data_visita,
            nome_tecnico: v.nome_tecnico,
            hora_chegada: v.hora_chegada,
            hora_saida: v.hora_saida,
            observacao: v.observacao,
            questionarios: [], //vai carregar questionarios aplicados na visita somente quando clicar nos detalhes da visita
          }
        })

        return {
          id: o.id_produtor,
          code: o.codigo_laticinio,
          save: o.salvo,
          producer: o.nome_produtor,
          route: o.rota_principal,
          smaller_cpp: o.menor_cbt,
          visits: visits,
          status: o.status,
          ...dates,
        };
      });
      this.loading = false;
    },

    async showVisits({ item, value }) {
      this.loading = true;
      let id_visits = item.visits.map(({ id }) => id);
      let questionariosMeta = [];

      switch (this.type) {
        case 'priority': questionariosMeta = this.emergencyGoals.PRIORITY.questionnaires; break;
        case 'critical':  questionariosMeta = this.emergencyGoals.CRITICAL.questionnaires; break;
        case 'priority-ccs': questionariosMeta = this.emergencyGoals.PRIORITYCCS.questionnaires; break;
      }

      if (_.isEmpty(questionariosMeta) || !value) {
        return false;
      }

      try {
        this.loading = true;
        let { data } = await this.$axios.post(
          `/formularioCustomizado/formularioAplicadoVisita`,
          {
            id_visita: id_visits,
            id_formulario: questionariosMeta
          }
        );
        
        item.visits.map((v => {
          data.forEach(d => {
            if (v.id === d.id_visita) {
              v.questionarios.push({ ...d });
            }
          });
        }))
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar questionarios da visita", "Atenção");
      }
      finally {
        this.loading = false;
      }
      
    },

    getReportJson(items) {
      return items.map(o => {
        if (this.type === 'priority' || this.type === 'priority-ccs') {
          return {
            Mês: this.formatMonth(o.month),
            Produtores: o.producers,
            Visitados: o.visits,
            '% Visitados': `${this.formatNumber(o.visitsPerc)}%`,
            Meta: o.goalsPerc,
          }
        }
        if (this.type === 'critical') {
          return {
            Mês: this.formatMonth(o.month),
            Produtores: o.producers,
            IN59: o.producers_in59,
            IN76: o.producers_in76,
            Não_Salvos: o.producers_not_save,
            Visitados: o.visits,
            '% Visitados': `${this.formatNumber(o.visitsPerc)}%`,
            Meta: o.goalsPerc,
          }
        }
      });
    },

    exportExcel() {
      if (this.type === 'priority' || this.type === 'priority-ccs') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.items));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Visitas Prioritários';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else if (this.type === 'critical') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.items));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Visitas Críticos';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
    },

    showPrintDialog() {
      this.printDialog.show = true;
      this.printDialog.item = this.itemsVisits;
      this.printDialog.params = {
        showABCCurve: true,
        showQualityResults: true,
        showQualityAvg: true,
        showOpenedRecommendations: true,
        showChecklists: true,
        showSampleCollects: true,
        showPriceNegotiation: true,
      };
    },

    /**
     * Fecha todos os dialogos
     */
    closeDialogs() {
     
      this.printDialog.show = false;
      this.printDialog.item = {};
      this.printDialog.params = {
        showABCCurve: true,
        showQualityResults: true,
        showQualityAvg: true,
        showOpenedRecommendations: true,
        showChecklists: true,
        showSampleCollects: true,
        showPriceNegotiation: true,
      };
    },

    /**
     * @event void
     *
     * Evento acionado para imprimir a lista de visitas
     */
  
    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>