import { render, staticRenderFns } from "./WebSettings.vue?vue&type=template&id=2983dc58&scoped=true"
import script from "./WebSettings.vue?vue&type=script&setup=true&lang=js"
export * from "./WebSettings.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2983dc58",
  null
  
)

export default component.exports