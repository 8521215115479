<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :loading="loading"
    :style="{background: color, overflow: 'hidden', height: height}"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Média Preço Leite por Faixa</span>
    </v-card-title>
    <v-tabs
      v-model="tab"
      align-with-title
      background-color="transparent"
      class="transparent flex-grow-0"
      dark
    >
      <v-tabs-slider color="cyan" />

      <v-tab>Gráfico</v-tab>
      <v-tab>Relatório</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="flex-grow-1 average-milk-price-tabs-items"
    >
      <v-tab-item>
        <v-chart
          :options="options"
          :style="{width: '100%', height: '100% !important'}"
          autoresize
        />
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="items"
          dark
          dense
          class="elevation-1 report-table"
          hide-default-footer
          disable-pagination
        >
          <template #[`item.faixa`]="{ value, index }">
            <span
              class="d-inline-block text-end mr-2"
              style="width: 45px"
            >
              {{ value }}
            </span>
            -
            <span
              class="d-inline-block text-end"
              style="width: 45px"
            >
              {{ nextRange(index) }}
            </span>
          </template>
          <template #[`item.volume_total`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.valor_total`]="{ value }">
            {{ formatCurrency(value) }}
          </template>
          <template #[`item.valor_medio`]="{ value }">
            {{ formatCurrency(value) }}
          </template>
          <template #[`item.produtores`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.litros_dia`]="{ item }">
            {{ formatNumber(parseInt(item.volume_total / daysInMonth)) }}
          </template>
          <template #[`item.perc_produtores`]="{ item }">
            {{ formatNumber(parseInt((100 * item.produtores) / totalProducers)) }} %
          </template>
          <template #[`item.perc_volume`]="{ item }">
            {{ formatNumber(parseInt((100 * item.volume_total) / totalVol)) }} %
          </template>
          <template
            v-if="items.length > 0"
            #[`body.append`]="{}"
          >
            <tr class="display-2">
              <td class="text-center">
                <b>TOTAL</b>
              </td>
              <td class="text-center">
                <b>{{ formatNumber(totalVol) }}</b>
              </td>
              <td class="text-center">
                <b>{{ formatCurrency(totalValue) }}</b>
              </td>
              <td class="text-center">
                <b>{{ formatCurrency(valueAverage) }}</b>
              </td>
              <td class="text-center">
                <b>{{ formatNumber(totalProducers) }}</b>
              </td>
              <td class="text-center">
                <b>{{ formatNumber(volumeByDay) }}</b>
              </td>
              <td class="text-center">
                <b>100 %</b>
              </td>
              <td class="text-center">
                <b>100 %</b>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style lang="scss">
.average-milk-price-tabs-items {
  .v-window__container, .v-window-item {
    height: 100%;
  }

  > div {
    overflow-y: auto;

    .v-data-table {
      flex-grow: 1 !important;

      .v-data-table__wrapper {
        height: 100%;
        background: rgba(255, 255, 255, 0.08);
      }
    }
  }
}
</style>

<script>
import moment from 'moment';

export default {
  name: "average-milk-price-by-range-chart",

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '300px',
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
    referenceMonth: {
      type: String,
      default: () => moment().subtract(1, "month").format("YYYY-MM"),
    },
  },

  data() {
    return {
      loading: false,
      tab: null,
      headers: [
        { text: 'Faixa', value: 'faixa', align: 'end', width: 145 },
        { text: 'Volume Total', value: 'volume_total', align: 'center' },
        { text: 'Valor Total', value: 'valor_total', align: 'center' },
        { text: 'Valor Médio', value: 'valor_medio', align: 'center' },
        { text: 'Produtores', value: 'produtores', align: 'center' },
        { text: 'Litros/dia', value: 'litros_dia', align: 'center' },
        { text: '% Prod.', value: 'perc_produtores', align: 'center' },
        { text: '% Vol.', value: 'perc_volume', align: 'center' },
      ],
      items: [],
    }
  },

  computed: {

    daysInMonth() {
      return moment(this.referenceMonth).daysInMonth()
    },

    totalProducers() {
      return this.items.reduce((acc, cur) => acc + (cur.produtores || 0), 0)
    },

    totalVol() {
      return this.items.reduce((acc, cur) => acc + (cur.volume_total || 0), 0)
    },

    totalValue() {
      return this.items.reduce((acc, cur) => acc + (cur.valor_total || 0), 0)
    },

    valueAverage() {
      return this.totalValue / this.totalVol
    },

    volumeByDay() {
      return parseInt(this.totalVol / this.daysInMonth)
    },

    options() {
      const label = {
        show: true,
        lineHeight: 17,
        height: 17,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatCurrency(value)}  `
      };

      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => {
                const formatter = (value) => param.seriesName === 'Volume Total'
                  ? this.formatNumber(value) + ' L'
                  : this.formatCurrency(param.value)
                return `${param.marker} ${param.seriesName}: <b>${formatter(param.value)}</b>`
              }),
              `• Valor Total: <b>${this.formatCurrency(this.items[params[0].dataIndex].valor_total)}</b>`,
              `• Produtores: <b>${this.formatNumber(this.items[params[0].dataIndex].produtores)}</b>`
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.items.map((item) => item.faixa),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff',
              formatter: (value) => this.formatCurrency(value)
            }
          },
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff',
              formatter: (value) => `${this.formatNumber(value)} L`
            }
          }
        ],
        series: [
          {
            name: 'Preço Médio',
            type: 'bar',
            emphasis: { focus: 'series' },
            data: this.items.map((item) => item.valor_medio),
            label
          },
          {
            name: 'Volume Total',
            type: 'line',
            yAxisIndex: 1,
            lineStyle: { width: 2 },
            showSymbol: false,
            smooth: this.smooth,
            emphasis: { focus: 'series' },
            data: this.items.map((item) => item.volume_total),
          }
        ]
      }
    }
  },

  watch: {
    referenceMonth() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/tabelaPreco/mediaPrecoLeitePorFaixa`, { params: {
          mes_referencia: this.referenceMonth
        } });

        this.items = data.sort((a, b) => a.faixa - b.faixa);
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de taxa de ocupação!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    nextRange(index) {
      if (index < (this.items.length - 1)) {
        return this.items[index + 1].faixa
      }
      return '~'
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
  }
}
</script>
