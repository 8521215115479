<template>
  <div style="position: relative;">
    <v-sheet
      color="white"
      elevation="1"
      class="d-flex flex-column justify-space-between pa-2"
      style="cursor: pointer;"
      @click="onClick"
    >
      <div style="height: 70px;">
        <p
          class="text-overline text-left mb-0"
          style="line-height: 18px !important;"
        >
          {{ silo.label }}
        </p>
        <p
          class="text-overline text-left mb-0"
          style="line-height: 18px !important;"
        >
          {{ maskInt(silo.vol) }}L / {{ maskInt(silo.capacity) }}L
        </p>
        <p
          v-if="showAvailable"
          class="text-overline text-left mb-1"
          style="line-height: 18px !important;"
        >
          Disponível: {{ maskInt(available) }}L
        </p>

        <v-chip
          v-if="silo.rawProduct.id"
          x-small
        >
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="text-overline text-truncate"
                v-on="on"
              >
                {{ (silo.rawProduct.name || '').toUpperCase() }}
              </span>
            </template>

            {{ silo.rawProduct.name }}
          </v-tooltip>
        </v-chip>

        <p
          v-if="silo.batchNumber"
          class="text-overline text-left mb-0"
        >
          Lote: {{ silo.batchNumber }}
        </p>
      </div>

      <v-progress-linear
        :value="utilization"
        color="blue-grey"
        height="25"
        class="mt-3"
      >
        <template #default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>

      <slot />
    </v-sheet>

    <v-overlay
      :value="disabled"
      :opacity="0.2"
      absolute
    />
  </div>
</template>

<script>
export default {
  props: {
    silo: {
      type: Object,
    },

    disabled: {
      type: Boolean,
    },

    showAvailable: {
      type: Boolean,
    }
  },

  computed: {
    utilization() {
      if (!this.silo.vol || !this.silo.capacity) {
        return 0;
      }

      return  ((this.silo.vol / this.silo.capacity) * 100).toFixed(0) || 0;
    },

    available() {
      if (!this.silo.vol) {
        return this.silo.capacity;
      }

      return this.silo.capacity - this.silo.vol;
    }
  },

  methods: {
    onClick() {
      this.$emit('click')
    },

    maskInt(number) {
      return (parseInt(number) || 0).toLocaleString('pt-BR');
    },
  }
}
</script>
