<template>
  <v-dialog
    v-model="show"
    width="650"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Manutenção
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-stepper
        v-model="step"
        alt-labels
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="step > 1"
          >
            Dados
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="2"
          >
            Recibos
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text>
              <v-form
                ref="form"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <masked-text-field
                      v-model="form.date"
                      label="Data"
                      prepend-inner-icon="access_time"
                      placeholder="00/00/0000 00:00"
                      :mask="{ mask: '00/00/0000 00:00' }"
                      :rules="[rules.required, rules.dateTime]"
                      clearable
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <person-autocomplete-filter
                      v-model="form.person"
                      label="Motorista"
                      type="DRIVER"
                      :filled="false"
                      :hide-details="false"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <vehicle-autocomplete-filter
                      v-model="form.vehicle"
                      label="Veículo"
                      :filled="false"
                      :hide-details="false"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="8"
                    class="py-0"
                  >
                    <v-select
                      v-model="form.item"
                      :items="items"
                      label="Item"
                      item-text="description"
                      prepend-inner-icon="reorder"
                      item-value="id"
                      return-object
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    class="py-0"
                  >
                    <masked-text-field
                      v-model="form.quantity"
                      label="Quantidade"
                      prepend-inner-icon="iso"
                      :mask="masks.float"
                      unmask
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    class="py-0"
                  >
                    <masked-text-field
                      v-model="form.km"
                      label="Km"
                      prepend-inner-icon="place"
                      :mask="masks.integer"
                      unmask
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    class="py-0"
                  >
                    <money-input
                      v-model="form.price"
                      label="Valor total"
                      prefix="R$"
                      :rules="[() => !!form.price && form.price > 0 || 'Campo obrigatório!']"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-row>
              <v-col
                v-for="(image, idx) in form.receipts"
                :key="idx"
              >
                <file-viewer
                  :value="image"
                  @removeFile="onRemoveImage(idx)"
                />
              </v-col>
              <v-col
                v-if="form.receipts.length < 3"
                class="py-0 text-center align-self-center flex-column justify-center"
              >
                <v-btn
                  outlined
                  class="mb-5"
                  @click="showFileUpload = !showFileUpload"
                >
                  <v-icon>add_a_photo</v-icon>
                  <span class="ml-2">Adicionar</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="step == 1"
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="step = step - 1"
        >
          <v-icon
            left
            dark
          >
            chevron_left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="step == 1"
          color="green"
          outlined
          @click="nextStep()"
        >
          Continuar
          <v-icon
            right
            dark
          >
            chevron_right
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>

    <file-upload
      v-model="showFileUpload"
      :multiselect="false"
      @insertFiles="onSelectImages"
    />
  </v-dialog>
</template>

<script>

import VehicleAutocompleteFilter from "@/Support/Components/Filters/VehicleAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

import moment from "moment";
import isObject from "lodash/fp/isObject";
import isArray from "lodash/fp/isArray";

export default {

  components: {
    VehicleAutocompleteFilter,
    PersonAutocompleteFilter,
    MaskedTextField,
    MoneyInput,
    FileUpload,
    FileViewer,
  },

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      showFileUpload: false,
      step: 1,
      items: [],
      form: {
        receipts: []
      },
      masks: {
        float: { mask: Number, min: 0, scale: 4 },
        integer: { mask: Number, min: 0, scale: 0, signed: false },
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        dateTime: v =>  this.isDateValid(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!',
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.step = 1
        this.form = {
          receipts: []
        }

        if (this.editId) {
          this.load();
        }

        this.loadMaintenanceItems();
      }
    }
  },

  mounted() {
  },

  methods: {
    async load() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/manutencao/detalhe`, {
          id_manutencao: this.editId
        });

        if (!isObject(data)) {
          throw data;
        }

        this.form = {
          person: {
            id: data.id_pessoa,
            name: data.nome_pessoa,
          },
          vehicle: {
            id: data.id_equipamento,
            description: data.placa,
            plate: data.placa
          },
          item: {
            id: data.id_manutencao_item,
            description: data.item,
          },
          date: data.data ? moment(data.data).format("DD/MM/YYYY HH:mm:ss") : '',
          quantity: parseFloat(data.quantidade) || null,
          km: parseFloat(data.km) || null,
          price: parseFloat(data.preco) || null,
          receipts: (data.recibos || []).map(url => ({ url, success: true }))
        };
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async loadMaintenanceItems() {
      try {
        this.loading = true;
        let { data } = await this.$axios.post(`/manutencao/listaItens`);

        if (!isArray(data)) {
          throw data;
        }

        this.items = data.map(item => ({
          id: item.id_manutencao_item,
          description: item.item,
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;

        const payload = {
          id_pessoa: this.form.person.id,
          nome_pessoa: this.form.person.name,
          data: this.form.date ? moment(this.form.date, 'DD/MM/YYYY hh:mm:ss').format('YYYY-MM-DD hh:mm:ss') : null,
          id_equipamento: this.form.vehicle.id,
          placa: this.form.vehicle.plate,
          id_manutencao_item: this.form.item.id,
          nome_item: this.form.item.description,
          quantidade: parseFloat(this.form.quantity),
          km: parseFloat(this.form.km),
          preco: parseFloat(this.form.price),
          recibos: this.form.receipts,
        };

        if (this.editId) {
          payload.id_manutencao = this.editId;
        }

        const { data } = await this.$axios.post(`/manutencao/salva`, payload);

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Manutenção salva com sucesso!", "Sucesso");
        this.show = false;
        this.$emit('save', data.id);
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    cancel() {
      this.show = false;
    },

    async nextStep() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      this.step++;
    },

    onSelectImages(images) {
      for (let image of images) {
        if (!this.form.receipts.find(o => o.name == image.name)) {
          this.form.receipts.push(image);
        }
      }
    },

    async onRemoveImage(idx) {
      try {
        const fileUrl = this.form.receipts[idx].url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('manutencoes')) {
          if (!(await this.$root.$confirm('Remover recibo', 'Tem certeza que deseja excluir este recibo?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.receipts.splice(idx, 1);
          await this.save();
        }
        else {
          this.form.receipts.splice(idx, 1);
        }
      } catch (err) {
        console.warn(err);
      }
    },

    isDateValid(value, format) {
      if (!value || value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },
  }
}
</script>
