import Vue from "vue";
import XLSX from "xlsx-js-style";
import VueLazyLoad from 'vue-lazyload'
import VueRouter from 'vue-router';
import VueMoment from "vue-moment";
import pt from "vuetify/es5/locale/pt";
import Vuetify from "vuetify/lib";
import { vMaska } from "maska"

import * as GmapVue from "gmap-vue";

import PortalVue from "portal-vue";
import Snotify, { SnotifyPosition } from "vue-snotify";
import "@/Support/Resources/axios-instance.js";

import store from "@/Support/Resources/vuex.js";
import router from "@/Support/Resources/router.js";

import "@/Support/Resources/echarts.js";
import "@/Support/Resources/websocket.js";

import DataTable from "@/Support/Components/DataTable.vue";

import "./Styles/style.scss";
import "./Assets/icons.css";
import "./Styles/print.scss";

Vue.use(VueRouter);
Vue.use(XLSX);
Vue.use(VueLazyLoad);
Vue.directive("maska", vMaska);

Vue.config.productionTip = false;
Vue.use(VueMoment);

Vue.component('data-table', DataTable)

let vuetifyOptions = {
  icons: {
    iconfont: "md", // default
  },
  lang: {
    locales: {
      pt,
    },
    current: "pt",
  },
};
Vue.use(Vuetify);

Vue.use(GmapVue, {
  load: {
    key: "AIzaSyB8lYX5eAT3-aObP1gw1XvpXhGHx0EUes0",
    libraries: "geometry,places",
  },
});
Vue.use(PortalVue);

require("vue-snotify/styles/simple.css");

Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.centerBottom
  }
});

Vue.prototype.$qs = require("qs");
window.axios = require("axios");

new Vue({
  router,
  store,
  vuetify: new Vuetify(vuetifyOptions),
  render: h => h('router-view'),
}).$mount("#app");
