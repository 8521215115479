<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="form.description"
            placeholder="Digite o nome da tabela"
            prepend-inner-icon="subtitles"
            dark
            filled
            background-color="rgba(0,0,0,.5)"
            hide-details
            :readonly="isDefaultTable"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
        </v-col>
      </v-row>

      <v-row
        v-show="!isDefaultTable"
        class="my-0"
      >
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <date-picker
            v-model="form.startDate"
            label="Data de início"
            prepend-inner-icon="event"
            persistent-hint
            hide-details
            dark
            filled
            :rules="isDefaultTable ? [] : [v => !!v || 'Campo obrigatório!']"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <region-autocomplete-filter
            v-model="form.region"
            label="Região"
            prepend-inner-icon="map"
            type="COMERCIAL"
            dark
            clearable
            filled
            hide-details
            :rules="isDefaultTable ? [] : [v => !!v || 'Campo obrigatório!']"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <person-autocomplete-filter
            v-model="form.persons"
            label="Representantes"
            type="SELLER"
            prepend-inner-icon="person"
            dark
            persistent-hint
            clearable
            filled
            hide-details
            multiple
            chips
            :rules="isDefaultTable ? [] : [v => (!!v && v.length > 0) || 'Campo obrigatório!']"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title class="text-subtitle-1 py-2">
              Itens da tabela
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="form.items"
              :loading="loading"
              dark
              hide-default-footer
              disable-pagination
            >
              <template #[`item.price`]="{ item }">
                <money-input
                  v-model="item.price"
                  placeholder="Preço Unitário"
                  prefix="R$"
                  dense
                  hide-details
                  persistent-hint
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </template>

              <template #[`item.minQuantity`]="{ item }">
                <masked-text-field
                  v-model="item.minQuantity"
                  placeholder="Qtde Mínima"
                  :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                  unmask
                  dense
                  hide-details
                  :disabled="isDefaultTable"
                />
              </template>

              <template #[`item.percDiscountMax`]="{ item }">
                <masked-text-field
                  v-model="item.percDiscountMax"
                  placeholder="Desconto máximo"
                  prefix="%"
                  :mask="{ mask: Number, min: 0, max: 100, signed: false }"
                  unmask
                  dense
                  hide-details
                  :disabled="isDefaultTable"
                />
              </template>

              <template #[`item.minPrice`]="{ item }">
                {{ formatCurrency(item.price * (item.percDiscountMax ? 1 - (item.percDiscountMax / 100) : 1)) }}
              </template>

              <template #[`item.percCommission`]="{ item }">
                <masked-text-field
                  v-model="item.percCommission"
                  placeholder="Comissão"
                  prefix="%"
                  :mask="{ mask: Number, min: 0, max: 100, signed: false }"
                  unmask
                  dense
                  hide-details
                  :disabled="isDefaultTable"
                />
              </template>

              <template #[`item.action`]="{ index }">
                <v-menu
                  v-if="!isDefaultTable"
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="removeItem(index)">
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <v-card-title class="py-2">
              <div
                v-if="isDefaultTable"
                class="py-4"
              />
              <item-autocomplete-filter
                v-else
                v-model="selectedProduct"
                placeholder="Informe o nome do produto"
                hide-selected
                return-object
                @change="addItem"
              />
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green"
            v-on="on"
            @click="save"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </template>

        <span>Salvar</span>
      </v-tooltip>

      <v-tooltip
        v-if="tableId"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue-grey lighten-1"
            v-on="on"
            @click="onTableDuplicate"
          >
            <v-icon>content_copy</v-icon>
          </v-btn>
        </template>

        <span>Duplicar Tabela</span>
      </v-tooltip>
    </v-speed-dial>

    <v-overlay :value="loading || saving">
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import RegionAutocompleteFilter from "@/Support/Components/Filters/RegionAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import ItemAutocompleteFilter from "@/Support/Components/Filters/ItemAutocompleteFilter.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import DatePicker from "@/Domains/Visits/Schedule/Components/DatePicker.vue";

import moment from "moment";

export default {
  components: {
    RegionAutocompleteFilter,
    PersonAutocompleteFilter,
    ItemAutocompleteFilter,
    MaskedTextField,
    MoneyInput,
    DatePicker,
  },

  props: {
    tableId: String,
  },

  data() {
    return {
      loading: false,
      saving: false,

      selectedProduct: null,

      form: {
        startDate: new Date(),
        persons: [],
        items: []
      },

      headers: [
        { text: 'Código', value: 'code', width: 100 },
        { text: 'Nome', value: 'name' },
        { text: 'Unidade', value: 'measurement' },
        { text: 'Preço Unitário', value: 'price', sortable: false },
        { text: 'Qtde Mínima', value: 'minQuantity', sortable: false },
        { text: 'Desconto Máx. (%)', value: 'percDiscountMax', sortable: false },
        { text: 'Preço mínimo', value: 'minPrice', sortable: false },
        { text: 'Comissão (%)', value: 'percCommission', sortable: false },
        { text: '', value: 'action', width: 30, sortable: false }
      ],
    }
  },

  computed: {
    isDefaultTable() {
      return this.tableId === "DEFAULT"
    }
  },

  mounted() {
    if (this.isDefaultTable) {
      this.loadDefaultTable()
    }
    else if (this.tableId) {
      this.loadPriceTable()
    }

  },

  methods: {
    /**
     * Load do formulário
     */
    async loadPriceTable() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/item/detalheTabelaDePreco`, {
          id_tabela_preco: this.tableId
        });

        this.form = {
          id: this.tableId,
          description: data.nome,
          region: data.id_regiao ? {
            id: data.id_regiao,
            nome: data.nome_regiao,
          } : null,
          startDate: moment(data.data_inicio).toDate(),
          items: data.itens.map(item => ({
            id: item.id_tabela_preco_item,
            code: item.cod_item,
            itemId: item.id_item,
            measurement: item.unidade,
            name: item.nome,
            price: parseFloat(item.preco),
            minQuantity: parseFloat(item.qtde_minima) || null,
            percDiscountMax: parseFloat(item.desconto_maximo) || null,
            percCommission: parseFloat(item.percentual_comissao) || null,
          })),
          persons: data.vendedores.map(item => ({
            id: item.id_pessoa,
            name: item.nome_pessoa,
          })),
        };
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar a tabela!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },
    /**
     * Load de todos os itens
     */
    async loadDefaultTable() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipo: "PRODUTO_ACABADO"
        } });

        this.form = {
          id: this.tableId,
          description: "TABELA PADRÃO",
          region: null,
          startDate: null,
          items: data.map(item => ({
            id: null,
            code: item.cod_item,
            itemId: item.id_item,
            measurement: item.unidade,
            name: item.nome,
            price: parseFloat(item.valor) || null,
            minQuantity: 0,
            percDiscountMax: 0,
            percCommission: 0,
          })),
          persons: [],
        };
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar a tabela!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      if (this.isDefaultTable) {
        this.saveDefaultTable()
        return;
      }
      this.saving = true;
      try {

        const payload = {
          id_tabela_preco: this.tableId || null,
          nome: this.form.description,
          id_regiao: this.form.region ? this.form.region.id : null,
          data_inicio: moment(this.form.startDate).format('YYYY-MM-DD'),
          itens: this.form.items.map(item => ({
            id_tabela_preco_item: item.id || null,
            id_item: item.itemId,
            preco: parseFloat(item.price),
            qtde_minima: parseFloat(item.minQuantity) || null,
            desconto_maximo: parseFloat(item.percDiscountMax) || null,
            percentual_comissao: parseFloat(item.percCommission) || null,
          })),
          vendedores: this.form.persons.map(item => ({
            id_pessoa: item.id,
          }))
        }

        const { data } = await this.$axios.post(`/item/salvaTabelaPreco`, payload);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        const tableId = data.id_tabela_preco

        this.$emit("update:tableId", tableId);
        this.$emit('priceTableSaved', tableId);
        this.$snotify.success("Tabela de preço salva com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar a tabela!", "Atenção");
        this.saving = true;
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async saveDefaultTable() {
      this.saving = true;
      try {

        const payload = {
          itens: this.form.items.map(item => ({
            id_item: item.itemId,
            valor: parseFloat(item.price),
          }))
        }

        const { data } = await this.$axios.post(`/item/salvaTabelaPrecoPadrao`, payload);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$emit('priceTableSaved', this.tableId);
        this.$snotify.success("Tabela de preço salva com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar a tabela!", "Atenção");
        this.saving = true;
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    addItem({ id, ...rest }) {
      this.form.items.push({
        itemId: id,
        ...rest
      });
      this.$nextTick(() => {
        this.selectedProduct = null;
      })
    },

    removeItem(idx) {
      this.form.items.splice(idx, 1);
    },

    /**
     * @event void
     *
     * Evento acionado ao clicar para duplicar uma tabela
     */
    onTableDuplicate() {
      this.loading = true;

      this.$emit("update:tableId", null);

      return setTimeout(() => {
        if (!this.tableId) {
          this.form.startDate = new Date()
          this.form.description = `${this.form.description} - Cópia`
          this.form.items = this.form.items.map(item => ({
            ...item,
            id: null
          }))
          this.$refs.form.resetValidation()
        }
        else {
          this.$snotify.error("Oops, ocorreu um erro ao duplicar a tabela!", "Atenção")
        }
        this.loading = false
      }, 500);
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  }
}
</script>
