<template>
  <div>
    <v-data-table
      :headers="[
        { text: 'Produtor', value: 'producer.name' },
        { text: 'Data Visita', value: 'visitedAt' },
        { text: 'Hora Chegada', value: 'startedAt' },
        { text: 'Hora Saída', value: 'endedAt' },
        { text: 'Observação', value: 'obs' },
        { text: 'Tipos', value: 'types' },
        { text: 'Fotos', value: 'pictures', align: 'center' },
        { text: 'Preço/Negociação', value: 'historical', align: 'center' },
        { align: 'end', text: '', value: 'actions', width: 80 },
      ]"
      :items="filteredItems"
      item-key="id"
      class="elevation-1"
      disable-filtering
      disable-pagination
      hide-default-footer
      @click:row="$event => $emit('editVisit', $event)"
    >
      <template #[`item.visitedAt`]="{ value }">
        {{ dateFormat(value, 'DD/MM/YYYY') }}
      </template>

      <template
        #[`item.types`]="{ value }"
      >
        <v-chip
          v-for="(tipo, i) in value"
          :key="i"
          small
          color="blue"
        >
          {{ types[tipo] }}
        </v-chip>
      </template>
      <template #[`item.pictures`]="{ value }">
        <v-btn
          v-if="value.length"
          icon
          @click.stop="openGallery(value)"
        >
          <v-icon>image</v-icon>
        </v-btn>
      </template>

      <template #[`item.historical`]="{ value }">
        <v-btn
          v-if="value.length"
          icon
          @click.stop="openHistorical(value)"
        >
          <v-icon>handshake</v-icon>
        </v-btn>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$emit('editVisit', item)">
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Editar
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('deleteVisit', item)">
              <v-list-item-icon>
                <v-icon>delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Excluir
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-model="historical.show"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Preço/Negociação</span>
        </v-card-title>

        <v-card-text>
          <historical-tab :historical="historical.items" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <LightBox
      ref="lightbox"
      :media="pictures"
      :show-light-box="false"
      show-caption
    />
  </div>
</template>

<script>
import LightBox from "vue-image-lightbox";

import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

import HistoricalTab from "@/Domains/Producer/Management/Components/Form/HistoricalTab.vue";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

const visitsService = new VisitsService();

export default {
  components: {
    LightBox,
    HistoricalTab,
  },

  props: {
    visits: Array,
    search: String,
  },

  data() {
    return {
      types: visitsService.types,
      pictures: [],

      historical: {
        show: false,
        items: [],
      }
    }
  },

  computed: {
    filteredItems() {
      if (!this.search) {
        return this.visits;
      }

      const search = this.search?.toUpperCase()?.trim();

      return this.visits.filter(item => {
        return JSON.stringify(Object.values(item)).toUpperCase().includes(search);
      });
    }
  },

  methods: {
    /**
     * Abre o lightbox com a imagem selecionada sendo exibida
     */
    openGallery(pictures) {
      this.pictures = pictures;
      this.$refs.lightbox.showImage(0);
    },

    openHistorical(historical) {
      this.historical.show = true;
      this.historical.items = historical;
    },

    dateFormat: (value, format) => isEmpty(value) ? '' : moment(value).format(format)
  }
}
</script>
