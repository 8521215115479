<template>
  <v-chip small>
    <v-avatar left>
      <v-icon
        :color="item.inOut === 'OUT' ? 'red' : 'green'"
      >
        {{ item.inOut === 'OUT' ? 'arrow_circle_up' : 'arrow_circle_down' }}
      </v-icon>
    </v-avatar>

    <span v-if="item.transferredVol !== 0">
      {{ formatNumber(item.transferredVol) }}
    </span>

    <span v-else>
      ----
    </span>
  </v-chip>
</template>

<script>
export default {
  name: 'TransferredVolChip',
  props: {
    item: Object,
  },
  methods: {
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
  }
};
</script>
