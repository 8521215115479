var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
      { text: 'Cliente', value: 'customer.name' },
      { text: 'Data Visita', value: 'visitedAt' },
      { text: 'Hora Chegada', value: 'startedAt' },
      { text: 'Hora Saída', value: 'endedAt' },
      { text: 'Observação', value: 'obs' },
      { text: 'Tipos', value: 'types' },
      { text: 'Fotos', value: 'pictures' },
      { align: 'end', text: '', value: 'actions', width: 80 },
    ],"items":_vm.filteredItems,"item-key":"id","disable-filtering":"","disable-pagination":"","hide-default-footer":""},on:{"click:row":$event => _vm.$emit('editVisit', $event)},scopedSlots:_vm._u([{key:`item.visitedAt`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.dateFormat(value, 'DD/MM/YYYY'))+" ")]}},{key:`item.types`,fn:function({ value }){return _vm._l((value),function(tipo,i){return _c('v-chip',{key:i,attrs:{"small":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.types[tipo])+" ")])})}},{key:`item.pictures`,fn:function({ value }){return [(value.length)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openGallery(value)}}},[_c('v-icon',[_vm._v("image")])],1):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('editVisit', item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(" Editar ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deleteVisit', item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v(" Excluir ")])],1)],1)],1)]}}],null,true)}),_c('LightBox',{ref:"lightbox",attrs:{"media":_vm.pictures,"show-light-box":false,"show-caption":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }