<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">
        {{ title || `Spot - Planejado x Realizado` }}
      </span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="8">
          <v-tabs
            v-model="tab"
            align-with-title
            background-color="transparent"
            class="transparent flex-grow-0"
            dark
          >
            <v-tabs-slider color="cyan" />

            <v-tab
              v-for="(item, idx) in report"
              :key="idx"
              :tab-value="item.rawMaterial"
            >
              {{ item.rawMaterial }}
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col
          cols="4"
          class="d-flex"
        >
          <div
            class="d-flex flex-column justify-end px-14"
            style="border-left: 2px solid #fff"
          >
            <span class="caption font-weight-light">
              Realizado (Total)
            </span>
            <span
              class="headline font-weight-bold text--accent-3"
            >
              {{ achieved.total }} L
            </span>
          </div>
          <div
            class="d-flex flex-column justify-end px-14"
            style="border-left: 2px solid #fff"
          >
            <span class="caption font-weight-light">
              Realizado (Média dia)
            </span>
            <span
              class="headline font-weight-bold text--accent-3"
            >
              {{ achieved.mean }} L
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-tabs-items
      v-model="tab"
      class="flex-grow-1 spot-tabs-items"
    >
      <v-tab-item
        v-for="(option, idx) in options"
        :key="idx"
        :value="option.rawMaterial"
      >
        <v-chart
          :options="option"
          :style="{width: '100%', height: '100% !important'}"
          autoresize
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style lang="scss">
.spot-tabs-items {
  .v-window__container, .v-window-item {
    height: 100%;
  }
}
</style>

<script>
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '250px',
    },
    report: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    title: {
      type: String,
    }
  },

  data() {
    return {
      tab: null,
    }
  },

  computed: {
    achieved() {
      const rawMaterial = this.report.find(item => {
        return item.rawMaterial === this.tab;
      });

      if (!rawMaterial) {
        return {
          total: 0,
          mean: 0
        };
      }

      const total = _.sum(rawMaterial.achieved) || 0;
      const mean = _.mean(rawMaterial.achieved) || 0;

      return {
        total: this.formatInteger(total),
        mean: this.formatInteger(mean),
      };
    },
    options() {
      return this.report.map(item => ({
        rawMaterial: item.rawMaterial,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: item.category,
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [{
          name: 'Planejado',
          type: 'bar',
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
          data: item.planned
        }, {
          name: 'Realizado',
          type: 'bar',
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
          data: item.achieved
        }]
      }))
    }
  },

  methods: {
    formatInteger(number) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 0,  // Avoid showing decimal places
        maximumFractionDigits: 0   // Avoid showing decimal places
      });

      return formatter.format(number);
    }
  }
}
</script>
