<template>
  <v-dialog
    v-model="dialog.show"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Finalizar Descarga</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="unload.obs"
                filled
                label="Observação"
                auto-grow
                value=""
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="grey darken-1"
          text
          @click="hide()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="save()"
        >
          Finalizar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay v-model="loading">
      Carregando...
    </v-overlay>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {

  props: {

    unload: {
      type: Object,
      default: () => ({}),
    },

  },

  data() {
    return {
      loading: false,

      dialog: {
        show: false,
      },

    }
  },

  methods: {

    show() {
      this.dialog.show = true;
    },

    hide() {
      this.dialog.show = false;
    },

    async save() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/descargaColeta/finalizaDescarga`,
          {
            id_descarga_coletas: this.unload.id,
            observacao: this.unload.obs,
          },
        );

        if (!_.isObject(data) || !data.codigo) {
          throw new Error(data.mensagem);
        }

        this.hide();
        return this.$emit('onUnloadConclude');
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar a descarga!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

  },

}
</script>
