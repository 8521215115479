<template>
  <div>
    <v-select
      v-model="model.type"
      label="Filtrar"
      prepend-inner-icon="event"
      hide-details
      filled
      clearable
      dark
      :items="options"
      @change="onFilterType"
    >
      <template #item="{ item }">
        <template v-if="item.value === model.type">
          <span class="grow"> {{ item.text }} </span>
          <v-btn
            icon
            light
            @click="onFilterType"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>
        <template v-else>
          {{ item.text }}
        </template>
      </template>

      <template #selection="{ item }">
        <span v-if="model.date">
          {{ item.text }} {{ item.value === 'COLETADO' ? 'até dia' : 'a partir de' }} {{ formatDate(model.date, 'DD/MM/YYYY') }}
        </span>
        <span v-else>
          {{ item.text }}
        </span>
      </template>
    </v-select>
    <v-dialog
      v-model="showDatePicker"
      persistent
      width="290"
    >
      <v-date-picker
        v-model="model.date"
        reactive
        @change="showDatePicker = false"
      />
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import moment from 'moment'

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)

const options = [
  { text: 'Coletados', value: 'COLETADO' },
  { text: 'Sem coletas', value: 'NAO_COLETADO' },
  { text: 'Vinculados', value: 'VINCULADOS_A_PARTIR' },
]

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: Object,
    default: () => ({ type: null, date: null }),
  },
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['input'])

const showDatePicker = ref(false)

const model = computed({
  get: () => props.value,
  set: (value) => emit('input', value),
})

const onFilterType = (value) => {
  model.value.type = value
  model.value.date = null
  if (value) {
    showDatePicker.value = true
  }
}
</script>
