<template>
  <div>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-spacer />
        <v-col
          cols="12"
          md="4"
          class="pt-0"
        >
          <v-text-field
            v-model="filters.search"
            append-icon="search"
            label="Busca"
            single-line
            hide-details
            filled
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="prices"
        :search="filters.search"
        class="elevation-1"
        @click:row="showDialog"
      >
        <template #[`item.monetary`]="{ item }">
          <span>
            {{ formatNumber(item.monetary) }}
          </span>
        </template>
        <template #[`item.associatedMonetary`]="{ item }">
          <span>
            {{ formatNumber(item.associatedMonetary) }}
          </span>
        </template>
        <template #[`item.unassociatedMonetary`]="{ item }">
          <span>
            {{ formatNumber(item.unassociatedMonetary) }}
          </span>
        </template>

        <template #[`item.forAll`]="{ item }">
          <v-switch
            v-model="item.forAll"
            label=""
            dense
            hide-details
            class="mt-0"
            color="green accent-3"
            disabled
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="hasEditItemAccess"
                @click="showDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    edit
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasDeleteItemAccess"
                @click="deleteItem(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    delete
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasCreateItemAccess"
        fab
        color="purple"
        @click="showDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Cadastrar Item
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <price-dialog
      v-model="edit"
      :edit-id.sync="editId"
      :type="type"
      :subtype="subtype"
      @save="loadItems"
    />
  </div>
</template>

<script>
import _ from "lodash";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import PriceDialog from "@/Domains/Financial/Prices/Components/PriceDialog.vue";
export default {
  components: { PriceDialog },
  mixins: [ReportMixin],

  props: {
    type: String,
    subtype: String,
  },

  data() {

    return {

      // Dados brutos do backend
      prices: [],

      // Filtros da tabela
      filters: {
        search: "",
      },

      edit: false,
      editId: null,
    };
  },

  computed: {
    /**
     * Exibe as Causes filtradas
     */
    filteredVisits() {
      if (_.isEmpty(this.filters.search)) {
        return this.prices;
      }

      return this.prices.filter(price => {
        return (price.description || "").toUpperCase().includes((this.filters.search || "").toUpperCase());
      });
    },

    headers() {
      if (this.hasEditItemAccess || this.hasDeleteItemAccess) {

        if (this.type === 'TECNICO') {

          if (this.subtype === 'QUILOMETRAGEM') {
            return [
              { text: 'Técnico', value: 'people', align: 'start' },
              { text: 'Aplica a Todos', value: 'forAll', align: 'start' },
              { text: 'Km Inicial', value: 'startKm', align: 'start' },
              { text: 'Km Final', value: 'endKm', align: 'start' },
              { text: 'Valor Base $', value: 'basePrice', align: 'start' },
              { text: 'Valor Mínimo $', value: 'minimPrice', align: 'start' },
              { text: 'Valor Máximo $', value: 'maximPrice', align: 'start' },
              { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
            ];
          }

          return [
            { text: 'Técnico', value: 'people', align: 'start' },
            { text: 'Aplica a Todos', value: 'forAll', align: 'start' },
            { text: 'Descrição', value: 'description', align: 'start' },
            { text: 'Valor Base $', value: 'basePrice', align: 'start' },
            { text: 'Valor Mínimo $', value: 'minimPrice', align: 'start' },
            { text: 'Valor Máximo $', value: 'maximPrice', align: 'start' },
            { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
          ];
        }

        if (this.type === 'PRODUTOR') {
          if (this.subtype === 'QUILOMETRAGEM') {
            return [
              { text: 'Km Inicial', value: 'startKm', align: 'start' },
              { text: 'Km Final', value: 'endKm', align: 'start' },
              { text: 'Valor Base $', value: 'basePrice', align: 'start' },
              { text: 'Associado Valor Mínimo $', value: 'associatedMinPrice', align: 'start' },
              { text: 'Associado Valor Máximo $', value: 'associatedMaxPrice', align: 'start' },
              { text: 'Não Associado Valor Mínimo $', value: 'unassociatedMinPrice', align: 'start' },
              { text: 'Não Associado Valor Máximo $', value: 'unassociatedMaxPrice', align: 'start' },
              { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
            ];
          }

          return [
            { text: 'Descrição', value: 'description', align: 'start' },
            { text: 'Valor Base $', value: 'basePrice', align: 'start' },
            { text: 'Associado Valor Mínimo $', value: 'associatedMinPrice', align: 'start' },
            { text: 'Associado Valor Máximo $', value: 'associatedMaxPrice', align: 'start' },
            { text: 'Não Associado Valor Mínimo $', value: 'unassociatedMinPrice', align: 'start' },
            { text: 'Não Associado Valor Máximo $', value: 'unassociatedMaxPrice', align: 'start' },
            { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
          ];
        }

        return [];

      }

      if (this.type === 'TECNICO') {
        if (this.subtype === 'QUILOMETRAGEM') {
          return [
            { text: 'Técnico', value: 'people', align: 'start' },
            { text: 'Km Inicial', value: 'startKm', align: 'start' },
            { text: 'Km Final', value: 'endKm', align: 'start' },
            { text: 'Valor Base $', value: 'basePrice', align: 'start' },
            { text: 'Valor Mínimo $', value: 'minimPrice', align: 'start' },
            { text: 'Valor Máximo $', value: 'maximPrice', align: 'start' },
          ];
        }

        return [
          { text: 'Técnico', value: 'people', align: 'start' },
          { text: 'Descrição', value: 'description', align: 'start' },
          { text: 'Valor Base $', value: 'basePrice', align: 'start' },
          { text: 'Valor Mínimo $', value: 'minimPrice', align: 'start' },
          { text: 'Valor Máximo $', value: 'maximPrice', align: 'start' },
          { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
        ];
      }

      if (this.type === 'PRODUTOR') {
        if (this.subtype === 'QUILOMETRAGEM') {
          return [
            { text: 'Faixa', value: 'people', align: 'start' },
            { text: 'Valor Base $', value: 'basePrice', align: 'start' },
            { text: 'Associado Valor Mínimo $', value: 'associatedMinPrice', align: 'start' },
            { text: 'Associado Valor Máximo $', value: 'associatedMaxPrice', align: 'start' },
            { text: 'Não Associado Valor Mínimo $', value: 'unassociatedMinPrice', align: 'start' },
            { text: 'Não Associado Valor Máximo $', value: 'unassociatedMaxPrice', align: 'start' },
          ];
        }

        return [
          { text: 'Descrição', value: 'description', align: 'start' },
          { text: 'Valor Base $', value: 'basePrice', align: 'start' },
          { text: 'Associado Valor Mínimo $', value: 'associatedMinPrice', align: 'start' },
          { text: 'Associado Valor Máximo $', value: 'associatedMaxPrice', align: 'start' },
          { text: 'Não Associado Valor Mínimo $', value: 'unassociatedMinPrice', align: 'start' },
          { text: 'Não Associado Valor Máximo $', value: 'unassociatedMaxPrice', align: 'start' },
        ];
      }

      return []
    },

    //Definindo as Permissões de tela

    //Pega os dados de permissão do usúario.
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    //Se for admin ou ter o cargo Supervisor(Milkup) libera todos os acessos.
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    //Permissão para poder CRIAR uma Causa.
    hasCreateItemAccess() {
      return this.isAdmin || (this.type === 'TECNICO' && this.userResources.some(o => (o.recurso === "projects-finance-technical-price-create") && o.tipo === "COMPONENTE" )) || (this.type === 'PRODUTOR' && this.userResources.some(o => (o.recurso === "projects-finance-producer-price-create") && o.tipo === "COMPONENTE" ));
    },

    //Permissão para poder EDITAR uma Causa.
    hasEditItemAccess() {
      return this.isAdmin || (this.type === 'TECNICO' && this.userResources.some(o => (o.recurso === "projects-finance-technical-price-edit") && o.tipo === "COMPONENTE" )) || (this.type === 'PRODUTOR' && this.userResources.some(o => (o.recurso === "projects-finance-producer-price-edit") && o.tipo === "COMPONENTE" ));
    },

    //Permissão para poder DELETAR uma Causa.
    hasDeleteItemAccess() {
      return this.isAdmin || (this.type === 'TECNICO' && this.userResources.some(o => (o.recurso === "projects-finance-technical-price-delete") && o.tipo === "COMPONENTE" )) || (this.type === 'PRODUTOR' && this.userResources.some(o => (o.recurso === "projects-finance-producer-price-delete") && o.tipo === "COMPONENTE" ));
    },
  },

  async mounted() {
    await this.loadItems();
  },


  methods: {
    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('financial/prices', { params: {
            tipo: this.type,
            subtipo: this.subtype,
          }
        });

        this.prices = data.map(item => {
          return {
            id: item.id_tabela_valor,
            people: item.nome,
            description: item.descricao,
            basePrice: item.valor,
            minimPrice: item.valor_minimo,
            maximPrice: item.valor_maximo,
            startKm: item.km_inicial,
            endKm: item.km_final,
            associatedMinPrice: item.valor_min_associado,
            associatedMaxPrice: item.valor_max_associado,
            unassociatedMinPrice: item.valor_min_nao_associado,
            unassociatedMaxPrice: item.valor_max_nao_associado,
            forAll: item.aplica_todos,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar serviços!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showDialog({ id }) {
      if (!this.hasEditItemAccess) {
        return;
      }
      this.edit = true;
      this.editId = id;
    },

    async deleteItem(Item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir o valor para ${Item.nome} / ${Item.description} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`financial/price/${Item.id}`);

        this.$snotify.success("Valor excluído com sucesso", "Sucesso");
        return await this.loadItems();
      } catch (e) {
        this.$snotify.error("Erro ao excluir valor", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
  },
};
</script>

