<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.project"
            :items="goalList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRange"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="filter.project.description"
            label="Descricao"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <month-range-picker
            v-model="filter.range"
            :ranges.sync="filter.ranges"
            :min="filter.min"
            :max="filter.max"
            dark
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.id_project_group"
            :items="listGroup"
            :loading="loading"
            label="Grupo"
            item-value="id"
            item-text="description"
            dark
            filled
            hide-details
          />
        </v-col>
      </v-row>

      <average-graphic
        v-for="q in quality"
        :key="q.indicador+q.acao"
        :goal="q"
        type-project="PMLS"
        :filter="filter"
      />
    </v-flex>
  </div>
</template>

<script>
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import AverageGraphic from "@/Domains/Projects/PQFL/PAE/Components/AverageGraphic.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "quality-report",

  components: {
    MonthRangePicker,
    AverageGraphic,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {

      loading: false,

      filter: {
        project: {
          dateStart: null,
          dateEnd: null,
          protocol: '',
          id: null,
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        search: '',

        id_project_group: null,

      },

      listGroup: [],

      goalList: [],
      quality: [],

    };
  },

  computed: {
    ccsGraph() {
      if (this.ccsVisible) {
        return this.generateGraphOptions(this.ccs, 'Média Geométrica CCS * (1000)');
      } else {
        return [];
      }
    },
    cppGraph() {
      if (this.cppVisible) {
        return this.generateGraphOptions(this.cpp, 'Média Geométrica CPP * (1000)');
      } else {
        return [];
      }
    },
    gorduraGraph() {
      if (this.gorduraVisible) {
        return this.generateGraphOptions(this.gordura, 'Média Aritmética de Gordura');
      } else {
        return [];
      }
    },
    proteinaGraph() {
      if (this.proteinaVisible) {
        return this.generateGraphOptions(this.proteina, 'Média Aritmética de Proteína');
      } else {
        return [];
      }
    },
    caseinaGraph() {
      if (this.caseinaVisible) {
        return this.generateGraphOptions(this.caseina, 'Média Aritmética de Caseína');
      } else {
        return [];
      }
    },
  },

  created() {
    this.loadGoals();
  },

  methods: {

    async loadRange() {
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.max = moment(this.filter.project.dateEnd).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), moment(this.filter.project.dateEnd).format('YYYY-MM')];

      this.quality = (this.quality.lenght > 0) ? this.quality : this.filter.project.quality ;

    },

    async loadGoals() {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PMLS"
          }
        );

        this.goalList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: " De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            quality: proj.qualidade ? JSON.parse(proj.qualidade) : [],
            protocol: proj.protocolo,
            description: proj.descricao,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.goalList )) {
          this.filter.project = this.goalList[0];
          await this.loadRange();
          await this.loadGrupos();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadGrupos() {
      try {
        this.$root.$progressBar.loading();
        this.loading = true;

        const { data } = await this.$axios.get('/projects/project-group', {
          params: {
            id_assistencia: this.filter.project.id,
            tipo: 'PMLS',
          }
        });

        this.listGroup = data.map(item => {
          return {
            id: item.id_projeto_grupo,
            description: item.descricao,
          };
        });

        this.listGroup.push({
          id: null,
          description: 'Todos',
        })

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
        this.loading = false;
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
