var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"1","outlined":"","dark":"","color":"transparent"}},[_c('v-card-title',{staticClass:"d-flex flex-column"},[_vm._v(" Ações de Visitas ")]),_c('v-card-text',{staticStyle:{"background-color":"#4e2bb5"},attrs:{"dark":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"1"}},[_vm._v(" N. Visitas "),_c('v-text-field',{attrs:{"placeholder":"0","type":"number","dense":"","outlined":"","hide-details":""},model:{value:(_vm.goals.number_visit),callback:function ($$v) {_vm.$set(_vm.goals, "number_visit", _vm._n($$v))},expression:"goals.number_visit"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"2"}},[_vm._v(" Tipo "),_c('v-select',{attrs:{"items":_vm.items,"item-value":"value","item-text":"text","dense":"","outlined":"","hide-details":""},model:{value:(_vm.goals.type),callback:function ($$v) {_vm.$set(_vm.goals, "type", $$v)},expression:"goals.type"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"2"}},[_vm._v(" Período "),_c('v-select',{attrs:{"items":[
            { text: 'Mensal', value: 'mensal' },
            { text: 'Bimestral', value: 'bimestral' },
            { text: 'Trimestral', value: 'trimestral' },
            { text: 'Quinquenal' , value: 'quinquenal' },
            { text: 'Semestral', value: 'semestral' },
            { text: 'Anual', value: 'anual' },
          ],"placeholder":"Selecione um Período","dense":"","outlined":"","hide-details":""},model:{value:(_vm.goals.periodo),callback:function ($$v) {_vm.$set(_vm.goals, "periodo", $$v)},expression:"goals.periodo"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"2"}},[_vm._v(" Duração Mínima (Horas) "),_c('masked-input',{attrs:{"placeholder":"HH:MM","outlined":"","dense":"","prepend-inner-icon":"schedule","mask":_vm.TimeMask,"rules":[
            v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
          ],"max-length":5},model:{value:(_vm.goals.minimum_time),callback:function ($$v) {_vm.$set(_vm.goals, "minimum_time", $$v)},expression:"goals.minimum_time"}})],1),_c('v-col',{staticClass:"pb-0F",attrs:{"md":"3"}},[_vm._v(" Questionário "),_c('v-select',{attrs:{"label":"Questionários","items":_vm.questionnaires,"loading":_vm.loading,"prepend-inner-icon":"icon-cadastros","item-value":"id","item-text":"title","multiple":"","outlined":"","small-chips":"","hide-details":"","dense":""},model:{value:(_vm.goals.questionnaires),callback:function ($$v) {_vm.$set(_vm.goals, "questionnaires", $$v)},expression:"goals.questionnaires"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }