<template>
  <div class="mx-6 px-16 pt-0 pb-7">
    <v-flex
      class="text-xs-center mb-4"
      xs12
    >
      <h2 class="menu-header white--text">
        Visitas
      </h2>

      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-card
      class="visits-list"
      dark
    >
      <v-card-title class="pb-0">
        <v-layout row>
          <template v-if="onlyProject">
            <v-col
              class="pb-0"
              md-3
            >
              <v-select
                v-model="filters.project.validity"
                :items="filters.project.validityList"
                label="Vigência do Projeto"
                item-value="period"
                item-text="description"
                hide-details
                clearable
                dark
                filled
                :rules="[v => !!v || 'Campo obrigatório']"
                @change="onValidityFilter"
              />
            </v-col>
            <v-col
              class="pb-0"
              md-3
            >
              <month-range-picker
                v-model="filters.project.date.range"
                :ranges="{}"
                :min="filters.project.date.min"
                :max="filters.project.date.max"
                dark
                @change="onMonthFilter"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col
              class="pb-0"
              md-3
            >
              <v-date-range-picker
                v-model="filters.dateRange"
                :ranges="dateRanges"
                format="DD/MM/YYYY"
                @change="onDateFilter"
              />
            </v-col>
            <v-col
              class="pb-0"
              md-3
            >
              <v-autocomplete
                v-model="filters.type"
                :items="filters.types"
                clearable
                filled
                label="Tipo"
                multiple
                prepend-inner-icon="menu"
                @change="onFilterChange"
              />
            </v-col>
          </template>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.signature"
              :items="filters.signatures"
              clearable
              filled
              label="Assinatura"
              prepend-inner-icon="label"
              @change="onFilterChange"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.route"
              :items="filters.routes"
              clearable
              filled
              item-text="description"
              item-value="id"
              label="Rota"
              multiple
              prepend-inner-icon="map"
              @change="onFilterChange"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.person"
              :items="filters.persons"
              clearable
              filled
              item-text="name"
              item-value="id"
              label="Técnico"
              :disabled="!hasTechnicianFilterAccess"
              prepend-inner-icon="person"
              @change="onFilterChange"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <person-autocomplete-filter
              v-model="filters.producers"
              label="Produtor"
              type="PRODUCER"
              :project="project==='PMLS' ? project : ''"
              :show-producers-and-groups="1"
              multiple
              :return-object="false"
              @change="onFilterChange"
            />
          </v-col>
          <v-col
            class="pb-0"
            md-3
          >
            <v-autocomplete
              v-model="filters.validity"
              :items="filters.validities"
              clearable
              filled
              label="Válidas"
              prepend-inner-icon="menu"
              @change="onFilterChange"
            />
          </v-col>
        </v-layout>
      </v-card-title>

      <v-card-text v-if="filteredVisits.length">
        <v-card
          v-for="technicalVisits in filteredVisits"
          :key="technicalVisits.technician.id"
          class="mt-5 technical-visit-card"
          dark
        >
          <v-card-title>
            <v-layout
              class="align-center"
              row
            >
              <v-flex class="pa-0 pl-1 text-left">
                <span class="text-subtitle-2">{{ technicalVisits.technician }}</span>
              </v-flex>
              <v-flex class="pa-0 text-right">
                <span class="text-subtitle-2">
                  <v-chip
                    x-small
                    v-text="technicalVisits.items.length"
                  />
                </span>
              </v-flex>
            </v-layout>
          </v-card-title>

          <v-data-table
            :expanded="selectedProducer"
            :headers="headers"
            :items="technicalVisits.items"
            class="technical-visit-table"
            disable-pagination
            group-by="visit.date"
            hide-default-footer
            item-key="visit.id"
            show-expand
            single-expand
            @item-expanded="onVisitDetails"
          >
            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pa-0"
              >
                <v-container>
                  <v-layout
                    v-if="item.openRecommendations.length"
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-card
                        class="mt-3 technical-visit-card"
                        dark
                      >
                        <v-card-title>
                          <span class="text-subtitle-2">Recomendações Abertas</span>
                        </v-card-title>

                        <v-data-table
                          :headers="[
                            { text: 'Indicador', value: 'indicator' },
                            { text: 'Causa', value: 'cause' },
                            { text: 'Recomendação', value: 'recommendation' },
                            { text: 'Status', value: 'status' },
                            { text: 'Data Conclusão', value: 'statusDate' },
                            { text: 'Observações', value: 'obs' },
                          ]"
                          :items="item.openRecommendations"
                          class="technical-visit-table"
                          disable-sort
                          hide-default-footer
                        >
                          <template #[`item.recommendation`]="{ item }">
                            {{ item.recommendation | uppercase }}
                          </template>

                          <template #[`item.cause`]="{ item }">
                            {{ item.cause | uppercase }}
                          </template>

                          <template #[`item.indicator`]="{ item }">
                            {{ item.indicator | uppercase }}
                          </template>

                          <template #[`item.status`]="{ item }">
                            {{ item.status | uppercase }}
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="item.visit.observations.length"
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-card
                        class="mt-3 technical-visit-card"
                        dark
                      >
                        <v-card-title>
                          <span class="text-subtitle-2">Observações</span>
                        </v-card-title>

                        <v-card-text
                          class="pa-3 text-left"
                          style="background: rgba(255, 255, 255, 0.15)"
                        >
                          {{ item.visit.observations }}
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>

                <v-container v-if="item.details">
                  <v-layout
                    v-if="item.visit.invalid_cause && item.visit.invalid > 0 "
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-card
                        class="mt-3 technical-visit-card"
                        dark
                      >
                        <v-card-title>
                          <span class="text-subtitle-2">Motivo pelo qual foi invalidada visita </span>
                        </v-card-title>

                        <v-card-text
                          class="pt-6 pb-0"
                          style="background: rgba(255, 255, 255, 0.15)"
                        >
                          {{ item.visit.invalid_cause }}
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="item.details.visit.last_recomendation.length"
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-card
                        class="mt-3 technical-visit-card"
                        dark
                      >
                        <v-card-title>
                          <span class="text-subtitle-2">Recomendações feita em : {{ item.details.visit.last_visit_date }}</span>
                        </v-card-title>

                        <v-card-text
                          v-for="(r, idx) in item.details.visit.last_recomendation"
                          :key="idx"
                          class="pt-6 pb-0 text-left"
                          style="background: rgba(255, 255, 255, 0.15)"
                        >
                          <template v-if="r.recomendacao">
                            Recomendação: {{ r.recomendacao }}
                          </template>
                          <template v-if="r.causa">
                            <br>Causa: {{ r.causa }}
                          </template>
                          <template v-if="r.indicador_afetado">
                            <br>Indicador afetado: {{ r.indicador_afetado }}
                          </template>
                          <template v-if="r.status">
                            <br>Status: {{ r.status }}
                          </template>
                          <template v-if="r.observacao">
                            <br>Observações: {{ r.observacao }}
                          </template>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="item.details.visit.executed_recomendation.length"
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-card
                        class="mt-3 technical-visit-card"
                        dark
                      >
                        <v-card-title>
                          <span class="text-subtitle-2">Recomendações realizadas em visitas anteriores</span>
                        </v-card-title>

                        <v-card-text
                          v-for="(r, idx) in item.details.visit.executed_recomendation"
                          :key="idx"
                          class="pt-6 pb-0 text-left"
                          style="background: rgba(255, 255, 255, 0.15)"
                        >
                          <template v-if="r.data_hora_cadastro">
                            Data que foi recomendado: {{ formatDate(r.data_hora_cadastro, 'DD/MM/YYYY') }}
                          </template>
                          <template v-if="r.recomendacao">
                            <br>Recomendação: {{ r.recomendacao }}
                          </template>
                          <template v-if="r.causa">
                            <br>Causa: {{ r.causa }}
                          </template>
                          <template v-if="r.indicador_afetado">
                            <br>Indicador afetado: {{ r.indicador_afetado }}
                          </template>
                          <template v-if="r.status">
                            <br>Status: {{ r.status }}
                          </template>
                          <template v-if="r.observacao">
                            <br>Observações: {{ r.observacao }}
                          </template>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="item.questionnaires"
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-row
                        v-for="(questionnaire, index) in item.questionnaires"
                        :key="index"
                      >
                        <v-col
                          md="3"
                          class="text-left text-truncate link"
                          @click="showQuestionnaire(questionnaire)"
                        >
                          <v-icon>receipt_long</v-icon>
                          {{ questionnaire.titulo }}
                        </v-col>
                        <v-col
                          md="1"
                        >
                          <v-select
                            v-model="questionnaire.invalido"
                            label=""
                            :disabled="!hasChecklistValidityAccess"
                            :items=" [
                              { text:'Válido', value: 0 },
                              { text:'Inválido', value: 1 },
                              { text:'Válido parcialmente', value: 2 }
                            ]"
                            hide-details
                            dense
                          />
                        </v-col>
                        <v-col
                          md="6"
                        >
                          <v-text-field
                            v-model="questionnaire.motivo_invalido"
                            type="TEXT"
                            label="Motivo"
                            dense
                          />
                        </v-col>
                        <v-col
                          v-if="hasChecklistValidityAccess"
                          md="2"
                        >
                          <v-btn
                            class="ma-2"
                            color="green darken-1"
                            @click="onSaveInvalidQuestionnaire(questionnaire)"
                          >
                            <v-icon>
                              save
                            </v-icon>

                            <div class="pr-3">
                              Salvar
                            </div>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="item.details.visit.pictures.length"
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-card
                        class="mt-3 technical-visit-card"
                        dark
                      >
                        <v-card-title>
                          <span class="text-subtitle-2">Fotos</span>
                        </v-card-title>

                        <v-card-text
                          class="pt-6 pb-0"
                          style="background: rgba(255, 255, 255, 0.15)"
                        >
                          <v-layout
                            class="mb-0"
                            row
                            wrap
                          >
                            <v-img
                              v-for="(image, index) in item.details.visit.pictures"
                              :key="index"
                              :lazy-src="image.src"
                              class="mr-3 mb-3"
                              max-width="100"
                              min-height="133"
                              style="cursor: zoom-in !important;"
                              @click="openGallery(index)"
                            />
                          </v-layout>

                          <LightBox
                            ref="lightbox"
                            :media="item.details.visit.pictures"
                            :show-light-box="false"
                            show-caption
                          />
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="item.details.visit.historical.length"
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-card
                        class="mt-3 technical-visit-card"
                        dark
                      >
                        <v-card-title>
                          <span class="text-subtitle-2">Preço/Negociação</span>
                        </v-card-title>

                        <v-card-text
                          class="pt-6 pb-0"
                          style="background: rgba(255, 255, 255, 0.15)"
                        >
                          <v-layout
                            class="mb-0"
                            row
                            wrap
                          >
                            <historical-tab
                              :historical="item.details.visit.historical"
                              dark
                            />
                          </v-layout>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="item.visit.signature"
                    row
                  >
                    <v-flex
                      md12
                      sm12
                    >
                      <v-card
                        class="mt-3 technical-visit-card"
                        dark
                      >
                        <v-card-title>
                          <span class="text-subtitle-2">Assinatura do Produtor</span>
                        </v-card-title>

                        <v-card-text
                          class="pa-2 pt-3 align-center justify-center"
                          style="background: white;"
                        >
                          <img
                            :src="item.visit.signature"
                            alt="assinatura"
                            width="30%"
                          >
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
              </td>
            </template>

            <template #[`group.header`]="{ group, items, headers, isOpen, toggle }">
              <td :colspan="headers.length">
                <v-layout
                  class="pa-0 align-center"
                  row
                >
                  <v-flex class="pa-0 text-left">
                    <v-btn
                      dark
                      icon
                      @click="toggle"
                    >
                      <v-icon v-if="isOpen">
                        remove
                      </v-icon>
                      <v-icon v-else>
                        add
                      </v-icon>
                    </v-btn>
                    {{ group }}
                  </v-flex>
                  <v-flex class="pa-0 text-right">
                    <v-chip
                      x-small
                      v-text="items.length"
                    />
                  </v-flex>
                </v-layout>
              </td>
            </template>

            <template #[`item.visit.location`]="{ item }">
              <v-btn
                v-if="item.visit.location.lat_ini"
                color="green lighten-2"
                dark
                icon
                @click="openLocationDialog(item)"
              >
                <v-icon>location_on</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="red lighten-3"
                dark
                icon
              >
                <v-icon>location_off</v-icon>
              </v-btn>
            </template>

            <template #[`item.visit.return`]="{ item }">
              <v-btn
                v-if="item.visit.return"
                color="green lighten-2"
                dark
                icon
                @click="openReturnDialog(item)"
              >
                <v-icon>event_repeat</v-icon>
              </v-btn>
            </template>

            <template #[`item.visit.invalid`]="{ item }">
              <v-select
                v-model="item.visit.invalid"
                label=""
                :disabled="!hasVisitValidityAccess"
                :items=" [
                  { text:'Válida', value: 0 },
                  { text:'Inválida', value: 1 },
                  { text:'Válida parcialmente', value: 2 }
                ]"
                dense
                hide-details
                @change="openInvalidDialog(item)"
              />
            </template>

            <template #[`item.questionnaires`]="{ value }">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn
                    v-if="value.length > 0"
                    color="orange lighten-3"
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon>receipt_long</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    color="gray lighten-3"
                    dark
                    icon
                    disabled
                  >
                    <v-icon>receipt_long</v-icon>
                  </v-btn>
                </template>
                <v-list
                  v-for="(questionnaire, index) in value"
                  :key="index"
                >
                  <v-list-item @click="showQuestionnaire(questionnaire)">
                    <v-list-item-icon>
                      <v-icon>receipt_long</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ questionnaire.titulo }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template #[`item.visit.types`]="{ item }">
              <div
                v-for="(type, i) in item.visit.types"
                :key="i"
              >
                <v-chip
                  v-if="type.text"
                  x-small
                >
                  {{ type.text }}
                </v-chip>
              </div>
            </template>

            <template #[`item.visit.model`]="{ item }">
              <v-chip
                v-if="item.visit.model"
                x-small
              >
                {{ item.visit.model.text }}
              </v-chip>
            </template>

            <template #[`item.signature`]="{ item }">
              <template v-if="item.visit.model.value != 'ATENDIMENTO'">
                <v-btn
                  v-if="!item.visit.signature"
                  color="red lighten-2"
                  dark
                  icon
                >
                  <v-icon>assignment_late</v-icon>
                </v-btn>

                <v-btn
                  v-else
                  color="green lighten-2"
                  dark
                  icon
                  @click="openSignatureDialog(item)"
                >
                  <v-icon>assignment_turned_in</v-icon>
                </v-btn>
              </template>
            </template>

            <template #[`item.photos`]="{ item }">
              <v-btn
                v-if="Object.keys(item.photos).length > 0"
                color="green lighten-2"
                dark
                icon
              >
                <v-icon>image</v-icon>
              </v-btn>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="showPrintDialog(item)">
                    <v-list-item-icon>
                      <v-icon>print</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Imprimir
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="item.openRecommendations.length > 0"
                    @click="toExcelRecommendations(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>backup_table</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Download Recomendações (Excel)
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasDeleteAccess"
                    @click="onVisitExcludeClick(item.visit.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Excluir
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>

      <v-card-text
        v-else
        style="height: 300px"
      >
        <v-container fill-height>
          <v-row class="justify-center align-center">
            Nenhuma visita encontrada
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-speed-dial
      bottom
      class="mr-5"
      dark
      direction="top"
      fixed
      open-on-hover
      right
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          fab
          large
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        color="orange darken-1"
        dark
        fab
        @click="showPrintDialog()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        color="green darken-1"
        dark
        fab
        @click="toExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>

          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="#556B2F"
        @click="toExcelRecommendations()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download Recomendações Abertas (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="signatureDialog.show"
      max-width="400"
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title class="text-subtitle-2">
          Assinatura do Produtor
        </v-card-title>
        <v-divider />
        <v-card-text>
          <img
            :src="signatureDialog.signature"
            alt="assinatura"
            width="100%"
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmationDialog.show"
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ confirmationDialog.title }}
        </v-card-title>
        <v-card-text>
          <v-form
            ref="confirmationDialogForm"
            lazy-validation
            @submit.prevent=""
          >
            <div v-html="confirmationDialog.content" />

            <v-text-field
              v-model.number="confirmationDialog.typedToken"
              :rules="[v => (v && v === confirmationDialog.confirmationToken) || 'Token de confirmação incorreto!']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="onConfirmationDialogSubmit()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="invalidDialog.show"
      max-width="400"
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title
          v-if="invalidDialog.invalid == 1"
        >
          Invalidar visita {{ invalidDialog.producer }}
        </v-card-title>
        <v-card-title
          v-if="invalidDialog.invalid == 2"
        >
          Invalidar parciamente visita {{ invalidDialog.producer }}
        </v-card-title>
        <v-card-title
          v-if="invalidDialog.invalid == 0"
        >
          Visita válida {{ invalidDialog.producer }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-textarea
            v-model="invalidDialog.cause"
            label="Motivo"
          />
        </v-card-text>
        <v-flex
          xs12
          class="text-xs-center"
        >
          <v-btn
            text
            color="green darken-1"
            @click="onSaveInvalidVisit()"
          >
            <v-icon>
              save
            </v-icon>

            <div class="pr-3">
              Salvar
            </div>
          </v-btn>
        </v-flex>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="locationDialog.show"
      max-width="400"
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title class="text-subtitle-2">
          Localização {{ locationDialog.producer_name }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          Latitude {{ locationDialog.lat_ini }} <br>
          Longitude {{ locationDialog.lng_ini }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="returnDialog.show"
      max-width="400"
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title class="text-subtitle-2">
          Retorno da visita realizada em  {{ returnDialog.date }} as {{ returnDialog.time }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          {{ returnDialog.invalid ? `A visita foi considerada invalida `: `` }}
          {{ returnDialog.invalidCause ? `, motivo: ${returnDialog.invalidCause}`: `` }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <questionnaire-answers-dialog
      ref="questionnaireAnswersDialog"
    />

    <print-settings-dialog
      ref="print-settings"
      @print="print"
    />

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          class="mb-0"
          color="white"
          indeterminate
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<style lang="scss">
.visits-list {
  background: rgba(0, 0, 0, 0.3) !important;
}

.technical-visit-card {
  background: transparent !important;

  .v-card__title {
    background: rgba(0, 0, 0, .3);
  }
}

.technical-visit-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.2);
    }

    tbody {
      background: rgba(255, 255, 255, 0.15);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }

      .v-row-group__header {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}

</style>

<script>
import _ from "lodash";
import moment from "moment";
import MomentDuration from "moment-duration-format";
import LightBox from 'vue-image-lightbox'
import XLSX from "xlsx-js-style";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

import HistoricalTab from "@/Domains/Producer/Management/Components/Form/HistoricalTab.vue";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";
import QuestionnaireAnswersDialog from '@/Domains/Questionnaires/Components/QuestionnaireAnswersDialog.vue';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

MomentDuration(moment);

export default {
  name: "quality-indicators-visits",

  components: {
    MonthRangePicker,
    HistoricalTab,
    LightBox,
    PrintSettingsDialog,
    QuestionnaireAnswersDialog,
    VDateRangePicker,
    PersonAutocompleteFilter
  },

  filters: {
    uppercase(value) {
      return (value || "").toUpperCase();
    },

    date(value) {
      if (_.isEmpty(value)) {
        return;
      }

      return moment(value).format('DD/MM/YYYY');
    }
  },

  mixins: [ReportMixin],

  props: {
    onlyProject: Boolean,
    project: String,
  },

  data() {
    return {
      // Loaders
      loading: false,

      // Dialogo de confirmação
      confirmationDialog: {
        show: false,
        callback: () => {
        },
        title: '',
        content: '',
        item: {},
        typedToken: '',
        confirmationToken: '',
      },

      dateRanges: {
        'Hoje': [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD")
        ],
        'Ontem': [
          moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
          moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD")
        ],
        'Este Mês': [
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD")
        ],
        'Mês Anterior': [
          moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
        ],
        'Últimas 100 Visitas': [null, null]
      },

      // Dialogo utilizado para exibir a assinatura do produtor
      signatureDialog: {
        show: false,
        signature: '',
      },

      invalidDialog: {
        id: '',
        show: false,
        invalid: '',
        cause: '',
        producer: '',
      },

      locationDialog:{
        show: false,
        lat_ini: '',
        lng_ini: '',
        producer_name: '',
      },

      returnDialog:{
        date: '',
        time: '',
        invalid: '',
        invalidCause: '',
        show: false,
      },

      // Filtros usados para buscar a rota
      filters: {
        // Range de referência para a bonificação
        date: {
          input: "today",
          range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        },
        dateRange: [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD")
        ],

        validity: null,

        validities: [
          { text: 'Todas', value: null },
          { text: 'Válidas', value: 0 },
          { text: 'Válidas Parcialmente', value: 2 },
          { text: 'Inválidas', value: 1 },
        ],

        // Tipo de visita
        type: null,

        types: [],

        // Modelo de visita
        model: null,

        // Modelos de visita disponíveis no formulário
        models: [
          { text: 'Padrão', value: 'PADRAO' },
          { text: 'Prospecção', value: 'PROSPECCAO' },
          { text: 'Atendimento', value: 'ATENDIMENTO' },
          { text: 'Outros', value: 'OUTROS' },
        ],

        // Assinatura
        signature: null,

        // Assinaturas disponíveis
        signatures: [
          { text: 'Com Assinatura', value: '1' },
          { text: 'Sem Assinatura', value: '2' },
        ],

        // Rota filtrada para busca
        route: [],

        // Lista de rotas disponíveis no formulário
        routes: [],

        // Pessoa filtrada para a busca
        person: null,

        // Lista de pessoas disponíveis no formulário
        persons: [],

        // Campo para buscar pelo produtor
        producers: [],

        /**
         * Filtros do projeto
         */
        project: {
          // Lista de vigências
          validityList: [],

          // Vigência selecionada
          validity: [],

          // Filtro de data
          date: {
            range: [],

            min: moment().startOf('year').format('YYYY-MM'),
            max: moment().endOf('year').format('YYYY-MM'),
          }
        }
      },

      // Registro selecionado na lista
      selectedProducer: [],

      // Lista de produtores para geração da bonificação
      visits: [],

      allTypes: [
        { text: "Meta Laticinio", value: "PADRAO", type: "visits" },
        { text: "CPP", value: "CPP", type: "visits" },
        { text: "CCS", value: "CCS", type: "visits" },
        { text: "BPA", value: "BPA", type: "visits" },
        { text: "PROSPECÇÃO", value: "PROSPECCAO", type: "visits" },
        { text: "COLETA DE AMOSTRA", value: "COLETA_AMOSTRA", type: "visits" },
        { text: "VETERINÁRIO", value: "VETERINARIO", type: "visits" },
        { text: "ZOOTÉCNICO", value: "ZOO_TECNICO", type: "visits" },
        { text: "VENDA", value: "VENDER_PRODUTOS", type: "visits" },
        { text: "ACOMPANHAMENTO DE ORDENHA", value: "ACOMPANHAMENTO_DE_ORDENHA", type: "visits" },
        { text: "CRIOSCOPIA", value: "CRIOSCOPIA", type: "visits" },
        { text: "ANTIBIOTICO", value: "ANTIBIOTICO", type: "visits" },
        { text: "ALTERAÇÕES NO LEITE", value: "ALTERACOES_NO_LEITE", type: "visits" },
        { text: "ROTINA", value: "ROTINA", type: "visits" },
        { text: "PMLS", value: "PMLS", type: "visits" },
        { text: "Checklist Inicial", value: "CHECKLIST_INICIAL", type: "visits" },
        { text: "Brucelose / Tuberculose", value: "BRUCELOSE_TUBERCULOSE", type: "visits" },
        { text: "IN77", value: "IN77", type: "visits" },
        { text: "IN59", value: "IN59", type: "visits" },
        { text: "PQFL", value: "PQFL", type: "visits" },
      ],
    };
  },

  computed: {

    headers() {
      const headers =  [
        { align: 'start', text: 'Nome', value: 'producer.name', sortable: true },
        { text: 'Início', value: 'visit.startedAt', width: 80, sortable: true },
        { text: 'Fim', value: 'visit.endedAt', width: 80, sortable: true },
        { text: 'Duração', value: 'visit.duration', width: 80, sortable: true },
      ];

      if (!this.onlyProject) {
        headers.push(
          { text: 'Tipos', value: 'visit.types', width: 120, sortable: true },
          { text: 'Modelo', value: 'visit.model', width: 100, sortable: true },
        );
      }

      headers.push(
        { text: 'Retorno', value: 'visit.return', width: 80, sortable: true },
        { text: 'Visita Válida', value: 'visit.invalid', width: 120, sortable: true },
        { aling: 'end', text: '', value: 'visit.location', width: 80, sortable: true },
        { align: 'end', text: 'Questionários', value: 'questionnaires', width: 90, sortable: false },
        { align: 'end', text: 'Ass.', value: 'signature', width: 50, sortable: true },
        { align: 'end', text: 'Fotos', value: 'photos', width: 50, sortable: true },
        { align: 'end', text: '', value: 'actions', width: 60, sortable: true },
      );

      return headers;
    },

    filteredVisits() {
      const search = (this.filters.search || '').toUpperCase();

      let visits = this.visits;

      if (!_.isEmpty(search)) {
        visits = visits.filter(({ technical, producer }) => {
          let hasQuery = false;

          if (this.filters.search && !_.isEmpty(this.filters.search)) {
            hasQuery = (
              technical.name.includes(search) ||
                producer.name.includes(search)
            )
          }

          return hasQuery;
        });
      }

      return Object.values(_(visits)
        .groupBy('technical.name')
        .map((items, technician) => {
          return {
            technician,
            items
          }
        }).value());
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    isTechnician() {
      return [3, 4, 15, 16].includes(this.$store.state.settings.user.id_cargo);
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'visits-delete' && o.tipo === 'COMPONENTE');
    },

    hasVisitValidityAccess() {
      return this.userResources.some(o => o.recurso === 'visits-validity' && o.tipo === 'COMPONENTE');
    },
    hasChecklistValidityAccess() {
      return this.userResources.some(o => o.recurso === 'checklists-validity' && o.tipo === 'COMPONENTE');
    },
    hasTechnicianFilterAccess() {
      return this.isAdmin || !this.isTechnician || this.userResources.some(o => o.recurso === 'visits-see-all' && o.tipo === 'COMPONENTE')
    },
  },

  async mounted() {

    if (this.project == 'PMLS') { //se for o menu de pmls filtrar somente esse tipo na listagem
      // Metas de laticinio - Visita Padrão (Tipo principal)
      this.filters.types = [
        // PMLS (Tipo principal)
        { text: "PMLS", value: "PMLS", type: "visits" },
      ];
      this.filters.type = ["PMLS"];
    } else if (this.project == 'Dairy') {
      this.filters.types = [
        { text: "Meta Laticinio", value: "PADRAO", type: "visits" },
        { text: "PAE", value: "PAE" },
        { text: "BPA", value: "BPA", type: "visits" },
        { text: "PROSPECÇÃO", value: "PROSPECCAO", type: "visits" },
        { text: "COLETA DE AMOSTRA", value: "COLETA_AMOSTRA", type: "visits" },
        { text: "VETERINÁRIO", value: "VETERINARIO", type: "visits" },
        { text: "ZOOTÉCNICO", value: "ZOO_TECNICO", type: "visits" },
        { text: "VENDA", value: "VENDER_PRODUTOS", type: "visits" },
        { text: "ACOMPANHAMENTO DE ORDENHA", value: "ACOMPANHAMENTO_DE_ORDENHA", type: "visits" },
        { text: "CRIOSCOPIA", value: "CRIOSCOPIA", type: "visits" },
        { text: "ANTIBIOTICO", value: "ANTIBIOTICO", type: "visits" },
        { text: "ALTERAÇÕES NO LEITE", value: "ALTERACOES_NO_LEITE", type: "visits" },
        { text: "ROTINA", value: "ROTINA", type: "visits" },
      ];
      this.filters.type = [];
    } else if (this.project == 'PQFL') {
      this.filters.types = [
        // Subtipos de PQFL
        { text: "PAE", value: "PAE" },
        { text: "BPA", value: "BPA", type: "visits" },
        { text: "Checklist Inicial", value: "CHECKLIST_INICIAL", type: "visits" },
        { text: "Brucelose / Tuberculose", value: "BRUCELOSE_TUBERCULOSE", type: "visits" },
      ];
    }

    if (this.onlyProject) {
      await this.loadValidityList();
    }
    else {
      await this.loadVisitsList();
    }
    await this.loadRoutesFilter();
    await this.loadPersonsFilter();
  },

  methods: {

    /**
     * Recupera a lista de visitas
     * @returns {Promise<void>}
     */
    async loadVisitsList() {
      try {

        this.$root.$progressBar.loading();

        let [startDate, endDate] = this.filters.dateRange;

        let { data } = await this.$axios.post(
          `/visita/listaVisitas`,
          {
            data_ini: startDate,
            data_fim: endDate,
            rotas: this.filters.route.map(id_rota => id_rota),
            assinatura: this.filters.signature,
            id_pessoa: this.filters.person,
            modelos: this.filters.model,
            tipos: this.filters.type ? this.filters.type : [],
            produtor: this.filters.producers,
            exibeFormulariosRespondidos: true,
            tipo_projeto: this.project,
            validade: this.filters.validity,
          }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        const visits = data.map(visit => {
          return {
            technical: {
              id: visit.id_tecnico,
              name: (visit.nome || '').toUpperCase(),
            },
            producer: {
              id: visit.id_produtor,
              name: (visit.nome_produtor || '').toUpperCase(),
            },
            visit: {
              id: visit.id_visita,
              date: moment(visit.data_visita).format('DD/MM/YYYY'),
              startedAt: moment(visit.hora_chegada, 'HH:mm:ss').format('HH:mm'),
              endedAt: moment(visit.hora_saida, 'HH:mm:ss').format('HH:mm'),
              duration: moment.utc(moment(visit.hora_saida, 'HH:mm:ss').diff(moment(visit.hora_chegada, 'HH:mm:ss'))).format('HH:mm'),
              observations: visit.observacao || '',
              signature: _.isEmpty(visit.assinatura) ? null : `data:image/png;base64,${visit.assinatura}`,
              model: this.filters.models.find(model => model.value === visit.modelo),
              types: this.allTypes.filter(type => visit.tipo.some(visitType => visitType === type.value)),
              invalid: visit.invalido,
              invalid_cause: visit.motivo_invalido,
              return: _.isEmpty(visit.visita_anterior_data) ? false : true,
              location: {
                lat_ini: visit.end_lat_ini || '',
                lng_ini: visit.end_lng_ini || '',
              },
              preview: {
                date: visit.visita_anterior_data ? moment(visit.visita_anterior_data).format('DD/MM/YYYY') : '',
                time: visit.visita_anterior_hora_chegada ? moment(visit.visita_anterior_hora_chegada, 'HH:mm:ss').format('HH:mm') : '',
                invalid: visit.visita_anterior_invalido,
                invalidCause: visit.visita_anterior_motivo_invalido,
              },
            },
            photos: visit.fotos,
            questionnaires: visit.formularios || [],
            openRecommendations: visit.recomendacoes_abertas.map(recommendation => ({
              recommendation: recommendation.recomendacao,
              indicator: recommendation.indicador_afetado,
              cause: recommendation.causa,
              status: recommendation.status,
              obs: recommendation.observacao,
              statusDate: recommendation.data_conclusao ?  moment(recommendation.data_conclusao).format('DD/MM/YYYY') : null
            }))
          }
        });

        this.visits = _.sortBy(visits, ({ visit: { date, startedAt } }) => {
          return moment(`${date} ${startedAt}`, 'DD/MM/YYYY HH:mm').toDate();
        });

        console.log(this.visits);

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as visitas!", "Atenção");

        console.error(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Recupera as rotas disponíveis para filtro
     * @returns {Promise<void>}
     */
    async loadRoutesFilter() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/rota/listaRotaResumidaJson`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.filters.routes = data.map(route => {
          return {
            id: route.id_rota,
            description: route.descricao,
          }
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async loadValidityList() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          { tipo: "PMLS" }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.filters.project.validityList = data.map(item => ({
          period: [moment(item.data).format('YYYY-MM'), moment(item.data_fim).format('YYYY-MM')],
          description: "De " + moment(item.data).format('MM/YYYY') + " até " + moment(item.data_fim).format('MM/YYYY')
        }));

        if (this.filters.project.validityList.length > 0) {
          this.onValidityFilter(this.filters.project.validityList[0].period);
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as vigências do projeto!", "Atenção");

        console.warn(error);
      }
    },

    /**
     * Recupera os técnicos disponíveis para filtro
     * @returns {Promise<void>}
     */
    async loadPersonsFilter() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaTecnicos`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.filters.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * @event Object
     *
     * Evento acionado ao detalhar um registro na lista
     */
    async onVisitDetails({ item, value }) {
      try {
        this.$root.$progressBar.loading();
        // Verifica se na visita selecionada já foi carregado os dados do servidor
        if (item.details) {
          return;
        }

        // Não faz nada quando fechar
        if (!value) {
          return;
        }

        const { data } = await this.$axios.post(
          `/visita/detalhaVisita`,
          {
            id_visita: item.visit.id,
          }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.visits = this.visits.map(visit => {
          if (visit.visit.id !== item.visit.id) {
            return visit;
          }

          return {
            details: {
              visit: {
                pictures: _.map(data.fotos, (caption, src) => {
                  return {
                    src,
                    thumb: src,
                    caption
                  }
                }),
                historical: data.historico,
                last_recomendation: data.recomendacoes_anteriores,
                executed_recomendation: data.recomendacoes_realizadas,
                last_visit_date: data.data_visita_anterior ?  moment(data.data_visita_anterior).format('DD/MM/YYYY') : null,
              },
            },
            ...visit,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os detalhes da visita!", "Atenção");

        console.error(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * @event void
     *
     * Evento acionado ao clicar no meu para excluir uma visita
     * @returns {Promise<void>}
     */
    onVisitExcludeClick(id) {
      if (this.hasDeleteAccess) {
        this.confirmationDialog.show = true;
        this.confirmationDialog.title = 'Atenção';
        this.confirmationDialog.item.id = id;
        this.confirmationDialog.content = `
          Deseja realmente excluir esta visita?
            <br />
          Para continuar, por favor digite <b>EXCLUIR</b> no campo abaixo:
          `;
        this.confirmationDialog.confirmationToken = 'EXCLUIR';
        this.confirmationDialog.callback = this.exclude;
      } else {
        this.$snotify.error("Usuário não tem permissão para excluir essa visita!", "Atenção");
      }
    },

    /**
     * @event void
     *
     * Evento acionado ao submeter o dialogo de confirmação
     */
    onConfirmationDialogSubmit() {
      const valid = this.$refs.confirmationDialogForm.validate();

      if (!valid) {
        return;
      }

      return this.confirmationDialog.callback(this.confirmationDialog.item);
    },

    /**
     * Abre o dialogo para conferência da assinatura do produtor
     */
    openSignatureDialog(item) {
      this.signatureDialog.signature = item.visit.signature;
      this.signatureDialog.show = true;
    },

    openInvalidDialog(item) {
      this.invalidDialog.id = item.visit.id;
      this.invalidDialog.invalid = item.visit.invalid;
      this.invalidDialog.cause = item.visit.invalid == 0 ? '' : item.visit.invalid_cause;
      this.invalidDialog.producer = item.producer.name;
      this.invalidDialog.show = true;
    },

    openLocationDialog(item) {
      this.locationDialog.producer_name = item.producer.name;
      this.locationDialog.lat_ini = item.visit.location.lat_ini;
      this.locationDialog.lng_ini = item.visit.location.lng_ini;
      this.locationDialog.show = true;
    },

    openReturnDialog(item) {
      this.returnDialog.date = item.visit.preview.date;
      this.returnDialog.time = item.visit.preview.time;
      this.returnDialog.invalid = item.visit.preview.invalid;
      this.returnDialog.invalidCause = item.visit.preview.invalidCause;
      this.returnDialog.show = true;
    },

    async onSaveInvalidVisit() {
      try {
        this.$root.$progressBar.loading();
        if (_.isEmpty(this.invalidDialog.id)) {
          return false;
        }

        let { data } = await this.$axios.post(
          `/visita/invalidar`,
          {
            id: this.invalidDialog.id,
            cause: this.invalidDialog.cause,
            invalid: this.invalidDialog.invalid,
          }
        );

        if (!_.isObject(data)) {
          this.$snotify.error("Oops, ocorreu um erro ao salvar motivo!", "Atenção");
        }

        this.invalidDialog.id = '';
        this.invalidDialog.cause = '';
        this.invalidDialog.invalid = '';
        this.invalidDialog.show = false;

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar motivo!", "Atenção");

        console.error(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async onSaveInvalidQuestionnaire(questionnaire) {

      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(
          `/formularioCustomizado/invalidar`,
          {
            id_formulario_cabecalho: questionnaire.id_formulario_customizado_cabecalho,
            motivo_invalido: questionnaire.motivo_invalido,
            invalido: questionnaire.invalido,
          }
        );

        if (!_.isObject(data)) {
          this.$snotify.error("Oops, ocorreu um erro ao salvar motivo!", "Atenção");
        }

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar motivo!", "Atenção");

        console.error(err);
      } finally {
        this.$root.$progressBar.hide();
      }

    },

    /**
     * Abre o lightbox com a imagem selecionada sendo exibida
     */
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },

    /**
     * Fecha todos os dialogos
     */
    closeDialogs() {
      this.confirmationDialog.show = false;
      this.confirmationDialog.title = '';
      this.confirmationDialog.item = {};
      this.confirmationDialog.content = '';
      this.confirmationDialog.typedToken = '';
      this.confirmationDialog.confirmationToken = '';
      this.confirmationDialog.callback = () => {};

      this.signatureDialog.show = false;
      this.locationDialog.show = false;
    },

    /**
     * Exclui um registro de visita
     */
    async exclude({ id }) {
      try {
        this.$root.$progressBar.loading();

        this.closeDialogs();

        const { data } = await this.$axios.post(
          `/visita/excluir`,
          this.$qs.stringify({
            id_visita: id,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        const respose = data[0];

        if (respose.codigo !== 1) {
          throw data;
        }

        return this.loadVisitsList();
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao excluir a visita!", "Atenção");

        console.log(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter(event) {
      this.filters.date.range = event;

      return this.loadVisitsList();
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de vigência do projeto
     */
    onValidityFilter(event) {
      this.filters.project.validity = event;
      if (!event) {
        return;
      }
      const [startDate, endDate] = event;
      this.filters.project.date.min = startDate;
      this.filters.project.date.max = endDate;
      this.onMonthFilter([startDate, endDate]);
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data do projeto
     */
    onMonthFilter([startDate, endDate]) {
      this.filters.project.date.range = [startDate, endDate];
      this.filters.date.range = [
        moment(startDate).startOf('month').format('YYYY-MM-DD'),
        moment(endDate).endOf('month').format('YYYY-MM-DD'),
      ];

      return this.loadVisitsList();
    },

    /**
     * @event void
     *
     * Evento acionado para refazer a lista com base nos novos filtros
     */
    onFilterChange: _.debounce(function () {
      this.loadVisitsList();
    }, 1000),

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;

      if (startDate == null && endDate == null) {
        return `Ultimas 100 visitas`;
      } else {
        return `Visitas - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
      }
    },

    /**
     * @event void
     *
     * Evento acionado para exportar a lista de visitar para Excel
     */
    toExcel() {
      try {
        this.onLoading = true;

        const reportData = this.visits.map(({ producer, technical, visit }) => ({
          "Data Visita": visit.date,
          "Produtor": producer.name,
          "Técnico": technical.name,
          "Tipos": visit.types.map(visit => visit.text).join(', '),
          "Modelo": visit.model.text,
          "Duração": visit.duration,
          "Início": visit.startedAt,
          "Fim": visit.endedAt,
          "Observações": visit.observations,
        }));

        let data = XLSX.utils.json_to_sheet(reportData);
        const workbook = XLSX.utils.book_new();
        const filename = this.getReportTitle();
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      } catch (e) {
        console.log(e);
      } finally {
        this.onLoading = false;

        this.closeDialogs();
      }
    },

    toExcelRecommendations(item = null) {
      try {
        this.onLoading = true;

        const [startDate, endDate] = this.filters.dateRange;

        let title = "";

        if (startDate == null && endDate == null) {
          title = `Últimas 100 recomendações`;
        } else {
          title = `Recomendações de - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
        }

        let report = [];

        if (!item) {
          report = this.visits
            .filter(visit => visit.openRecommendations.length > 0)
            .reduce((acc, visit) => {
              for (let recommendation of visit.openRecommendations) {
                acc.push({
                  "Data Visita": visit.visit.date,
                  "Produtor": visit.producer.name,
                  "Técnico": visit.technical.name,
                  "Duração": visit.visit.duration,
                  "Início": visit.visit.startedAt,
                  "Fim": visit.visit.endedAt,
                  "Observações": visit.visit.observations,
                  "Indicador": recommendation.indicator,
                  "Causa": recommendation.cause,
                  "Recomendação": recommendation.recommendation,
                  "Status": recommendation.status,
                });
              }
              return acc;
            }, []);
        } else {
          if (item.openRecommendations.length > 0) {
            report = item.openRecommendations.map(recommendation => ({
              "Data Visita": item.visit.date,
              "Produtor": item.producer.name,
              "Técnico": item.technical.name,
              "Duração": item.visit.duration,
              "Início": item.visit.startedAt,
              "Fim": item.visit.endedAt,
              "Observações": item.visit.observations,
              "Indicador": recommendation.indicator,
              "Causa": recommendation.cause,
              "Recomendação": recommendation.recommendation,
              "Status": recommendation.status,
            }));
          } else {
            this.$snotify.error("Oops, não existem recomendações no periodo selecionado!", "Atenção");
            return;
          }
        }

        this.exportToFile({ report, title });
      } catch (e) {
        this.$snotify.error("Oops, não existem recomendações no periodo selecionado!", "Atenção");
      } finally {
        this.onLoading = false;
      }
    },

    showPrintDialog(item = null) {
      let params =  [
        { title: 'Faixa (Curva ABC)', key: 'mostra_curva_abc', value: true },
        { title: 'Resultados Qualidade', key: 'mostra_resultados_qualidade', value: true },
        { title: 'Médias Qualidade', key: 'mostra_medias_qualidade', value: true },
        { title: 'Recomendações abertas', key: 'mostra_recomendacoes_abertas', value: true },
        { title: 'Checklists', key: 'mostra_checklist', value: true },
        { title: 'Coletas de amostras', key: 'mostra_coleta_amostras', value: true },
        { title: 'Preço/Negociação', key: 'mostra_negociacoes_preco', value: true },
        { title: 'Documentos assinados', key: 'mostra_documentos_assinados', value: false },
        { title: 'Localização', key: 'mostra_localizacao', value: false },
        { title: 'Volume', key: 'mostra_volume', value: false },
        { title: 'Rota do produtor', key: 'mostra_rota', value: false },
      ]
      if (this.project === 'PMLS') {
        params.push({ title: 'Logo PMLS', key: 'mostra_logo_pmls', value: true },);
      }

      this.$refs['print-settings'].show({
        item,
        params: params,
      });
    },

    /**
     * @event void
     *
     * Evento acionado para imprimir a lista de visitas
     */
    async print({ item,  params }) {
      try {
        this.$root.$progressBar.loading();

        let ids = [];

        if (!item) {
          ids = this.visits.map(({ visit: { id } }) => id)
        } else {
          ids = [
            item.visit.id,
          ];
        }

        if (_.isEmpty(ids)) {
          return;
        }

        const { data } = await this.$axios.post(`/visita/imprime`, {
          ids,
          params
        });

        let fileName = '';

        if (item) {
          fileName = `Visita - ${item.producer.name} - ${this.getReportTitle()}.pdf`;
        } else {
          fileName = `Visitas - ${this.getReportTitle()}.pdf`;
        }

        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();
      } catch (e) {
        console.log(e);
      } finally {
        this.$root.$progressBar.hide();

        this.closeDialogs();
      }
    },

    showQuestionnaire(item) {
      return this.$refs.questionnaireAnswersDialog.show({
        id: item.id_formulario_customizado_cabecalho,
      });
    },
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
