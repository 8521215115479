<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex xs12>
      <v-card
        class="graph-card"
        color="transparent"
        dark
      >
        <v-card-text>
          <v-chart
            ref="chart"
            :options="Options"
            :style="{width: '100%', height: `350px !important`}"
            autoresize
          />
        </v-card-text>
      </v-card>

      <v-card
        color="transparent"
        dark
        class="mt-5"
      >
        <v-card-title>
          <v-spacer />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="month"
          :search="filter.search"
          :loading="loading"
          class="elevation-1"
          :items-per-page="-1"
          hide-default-footer
          show-expand
          single-expand
          @item-expanded="showDetails"
        >
          <template #[`item.month`]="{value}">
            {{ formatMonth(value) }}
          </template>
          <template #[`item.visitsPerc`]="{value}">
            {{ formatNumber(value) }}%
          </template>
          <template #[`item.icone`]="{value}">
            <v-icon
              right
              :color="value == 'verified' ? 'green darken-1': 'red darken-1'"
            >
              {{ value }}
            </v-icon>
          </template>

          <template #expanded-item="{headers}">
            <td
              :colspan="headers.length"
              class="pa-5 text-center"
            >
              <v-data-table
                :headers="details.headers"
                :items="details.items"
                :search="details.search"
                show-expand
                single-expand
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
                @item-expanded="showVisits"
              >
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
                <template #expanded-item="{ headers, item }">
                  <td
                    :colspan="headers.length"
                    class="pa-0 text-center"
                  >
                    <v-data-table
                      v-if="item.visits.length"
                      :headers="visits.headers"
                      :items="item.visits"
                      :items-per-page="item.visits.length"
                      dense
                      hide-default-footer
                      class="elevation-1 ma-3"
                    >
                      <template
                        #[`item.data_visita`]="{ value }"
                      >
                        <v-chip
                          x-small
                          label
                          color="blue"
                        >
                          {{ formatDate(value, "DD/MM/YYYY") }}
                        </v-chip>
                      </template>
                      <template
                        #[`item.questionarios`]="{ value }"
                      >
                        <v-tooltip
                          v-if="value.length > 0"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              class="pa-0 ma-0"
                              v-on="on"
                              @click.stop="printModel(item.id)"
                            >
                              <v-icon
                                color="orange lighten-3"
                                class="pa-0 ma-0"
                              >
                                receipt_long
                              </v-icon>
                            </v-btn>
                          </template>
                          Questionário Aplicado:
                          <p
                            v-for="q in value"
                            :key="q.id_formulario"
                          >
                            {{ q.titulo }}
                          </p>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                    <span v-else>
                      Nenhuma visita encontrada
                    </span>
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <v-card-title>
          <v-spacer />
        </v-card-title>
      </v-card>
    </v-flex>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="showPrintDialog()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir Visitas
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-dialog
      v-model="printDialog.show"
      max-width="600"
      persistent
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title class="text-subtitle-2">
          Configuração de Impressão
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-0">
          <v-row
            dense
            no-gutters
            style="margin: 8px -4px;"
          >
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Faixa (Curva ABC)
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showABCCurve"
                        :label="printDialog.params.showABCCurve ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Resultados Qualidade
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showQualityResults"
                        :label="printDialog.params.showQualityResults ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Médias Qualidade
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showQualityAvg"
                        :label="printDialog.params.showQualityAvg ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>

          <v-row
            dense
            no-gutters
            style="margin: 8px -4px;"
          >
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Recomendações abertas
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showOpenedRecommendations"
                        :label="printDialog.params.showOpenedRecommendations ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Checklists
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showChecklists"
                        :label="printDialog.params.showChecklists ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Coletas de amostras
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showSampleCollects"
                        :label="printDialog.params.showSampleCollects ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="v-btt2 black--text pa-0"
            text
            color="red darken-1"
            @click="closeDialogs"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="print(printDialog.item)"
          >
            Imprimir
          </v-btn>
        </v-card-actions>

        <v-overlay
          :value="loading"
          absolute
        >
          <v-card-text>
            Carregando...
            <v-progress-linear
              class="mb-0"
              color="white"
              indeterminate
            />
          </v-card-text>
        </v-overlay>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";

export default {
  name: "emergency-report",

  components: {
  },

  mixins: [ReportMixin],

  props: {
    type: String,
    filter: Object,
  },

  data() {
    return {
      loading: false,
      // Dialogo utilizado para a impressão das visitas
      printDialog: {
        show: false,
        item: {},
        params: {
          showABCCurve: true,
          showQualityResults: true,
          showQualityAvg: true,
          showOpenedRecommendations: true,
          showChecklists: true,
          showSampleCollects: true,
          showPriceNegotiation: true,
        },
      },

      items: [],

      itemsVisits: [],

      headers: [],

      details: {
        headers: [],
        items: [],
        type: '',
        month: '',
        search: ''
      },

      visits: {
        headers: [
          { text: 'Técnico', value: 'nome_tecnico' },
          { text: 'Data', value: 'data_visita' },
          { text: 'Início', value: 'hora_chegada' },
          { text: 'Fim', value: 'hora_saida' },
          { text: 'Observação', value: 'observacao' },
          { text: '', value: 'questionarios' },
        ]
      },

      emergencyGoals: {
        CRITICAL: { status: 'Produtores Críticos', visits: 0, questionnaires: [], type: 'PERCENT' },
        PRIORITY: { status: 'Produtores Prioritários', visits: 0, questionnaires: [], type: 'UN' },
        PRIORITYCCS: { status: 'Produtores Prioritários CCS', visits: 0, questionnaires: [], type: 'UN' },
      },
    };
  },

  computed: {
    Options() {
      let showGoals = true;
      return this.generateGraphOptions(this.items,  showGoals);
    }
  },

  methods: {

    async loadReport() {

      this.loading = true;
      this.itemsVisits = [];
      try {
        let { data } = await this.$axios.post(
          `/relatorioIn/visitasCriticosPrioritarios`,
          {
            data_inicio: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            id_tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            meta_emergencial: this.filter.project.emergency,
          }
        );

        if (!_.isEmpty(this.filter.project.emergency) ) {
          const { CRITICO, PRIORITARIO } = JSON.parse(this.filter.project.emergency);
          this.emergencyGoals = {
            CRITICAL: { status: 'Produtores Críticos', visits: CRITICO.visitas, questionnaires: CRITICO.questionarios, type: !_.isEmpty(CRITICO.tipo) ? CRITICO.tipo : "PERCENT" },
            PRIORITY: { status: 'Produtores Prioritários', visits: PRIORITARIO.visitas, questionnaires: PRIORITARIO.questionarios, type: !_.isEmpty(PRIORITARIO.tipo) ? PRIORITARIO.tipo : "UN" },
          };

        }
        //prioritarios
        if (this.type == 'priority') {
          this.headers = [
            { text: "Mês", value: "month" },
            { text: "Produtores", value: "producers", width: 120, align: "center" },
            { text: "Visitados", value: "visits", width: 150, align: "center"  },
            { text: "% Visitados", value: "visitsPerc", width: 120 },
            { text: "Meta", value: "goalsPerc", width: 100 },
            { text: "", value: "icone", width: 30 },
          ];

          this.items = data.map(o => {

            let percentualMeta = (this.emergencyGoals.PRIORITY.type == "UN") ?  ((100 * o.total_prioritarios_visitados) / this.emergencyGoals.PRIORITY.visits  ) : this.emergencyGoals.PRIORITY.visits ;
            let icone = 'cancel';

            if (this.emergencyGoals.PRIORITY.type == "UN"
              && (o.total_prioritarios_visitados >= this.emergencyGoals.PRIORITY.visits
              || o.total_prioritarios_visitados == o.total_prioritarios) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.PRIORITY.type == "PERCENT"
              &&  (o.perc_prioritarios_visitados == 100 || o.perc_prioritarios_visitados > percentualMeta )) {
              icone = 'verified';
            }
            if (!_.isEmpty(o.produtores_prioritarios)) {
              o.produtores_prioritarios.map(p => {
                p.visitas.map(v => {
                  this.itemsVisits.push(v.id_visita)
                })
              });
            }

            return {
              month: o.mes,
              producers: o.total_prioritarios,
              visits: o.total_prioritarios_visitados,
              visitsPerc: o.perc_prioritarios_visitados,
              details: o.produtores_prioritarios,
              goals: (this.emergencyGoals.PRIORITY.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.PRIORITY.visits / 100) : this.emergencyGoals.PRIORITY.visits,
              goalsPerc: this.emergencyGoals.PRIORITY.visits + " " + (this.emergencyGoals.PRIORITY.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.PRIORITY.type,
              icone: icone,
            }
          })
        }

        //criticos
        if (this.type == 'critical') {

          this.headers = [
            { text: "Mês", value: "month", width: 100 },
            { text: "Produtores", value: "producers", width: 120, align: "center" },
            { text: "IN 59", value: "producers_in59", width: 100, align: "center"  },
            { text: "IN 76/77", value: "producers_in76", width: 100, align: "center"  },
            { text: "Não Salvos", value: "producers_not_save", width: 120, align: "center"  },
            { text: "Visitados", value: "visits", width: 150, align: "center"  },
            { text: "% Visitados", value: "visitsPerc", width: 120 },
            { text: "Meta", value: "goalsPerc", width: 100 },
            { text: "", value: "icone", width: 30 },
          ];

          this.items = data.map(o => {

            let quantidadeSalvo59 = 0;
            let quantidadeSalvo76 = 0;
            let quantidadeDesvinculado = 0;
            let totalVisitado = 0;

            if (!_.isEmpty(o.produtores_criticos)) {

              o.produtores_criticos = o.produtores_criticos.map(p => {
                let cbt_trimestral = p.cbt_geometrica_trimestral_posterior;
                let menor_cbt = p.menor_cbt;
                let salvo = 'Não salvo';
                let visit = _.isEmpty(p.visitas) ? 0 : (p.visitas).length ;

                p.visitas.map(v => {
                  this.itemsVisits.push(v.id_visita)
                })

                if ((p.status == 'DESVINCULADO' || p.status == 'SUSPENSO')  && !_.isEmpty(p.visitas)) {
                  quantidadeDesvinculado++;
                }

                if (cbt_trimestral > 0  &&  cbt_trimestral <= 300) {
                  //76/77
                  salvo = '76/77';
                  totalVisitado++;
                  quantidadeSalvo76++;
                } else if (menor_cbt > 0 && menor_cbt <= 300 && visit > 0 ) {
                  // 59
                  salvo = '59';
                  totalVisitado++;
                  quantidadeSalvo59++;
                } else if (!_.isEmpty(p.visitas))  {
                  totalVisitado++;
                }
                return {
                  ...p,
                  salvo: salvo
                }
              });
            }

            let totalNaoSalvo = o.total_criticos - quantidadeSalvo59 - quantidadeSalvo76;
            let totalAtivoVisitado = totalVisitado - quantidadeDesvinculado;
            totalAtivoVisitado = (totalAtivoVisitado < 0) ? 0 : totalAtivoVisitado;
            let percentualVisitado = totalAtivoVisitado > 0 ? (totalAtivoVisitado / o.total_criticos )  * 100 : 0;

            let percentualMeta = (this.emergencyGoals.PRIORITY.type == "UN") ?  ( 100 * this.emergencyGoals.CRITICAL.visits / o.total_criticos ) : this.emergencyGoals.PRIORITY.visits ;

            let icone = 'cancel';

            if (this.emergencyGoals.CRITICAL.type == "UN"
              && (totalAtivoVisitado >= this.emergencyGoals.CRITICAL.visits
              || totalAtivoVisitado == o.total_criticos) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.CRITICAL.type == "PERCENT"
              &&  ( percentualVisitado == 100 || percentualVisitado > percentualMeta )) {
              icone = 'verified';
            }

            return {
              month: o.mes,
              producers: o.total_criticos,
              producers_not_save: totalNaoSalvo,
              producers_in59: quantidadeSalvo59,
              producers_in76: quantidadeSalvo76,
              visits: o.total_criticos_visitados,
              visitsPerc: Math.abs(percentualVisitado).toFixed(2),
              details: o.produtores_criticos,
              goals: (this.emergencyGoals.CRITICAL.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.CRITICAL.visits / 100) : this.emergencyGoals.CRITICAL.visits,
              goalsPerc: this.emergencyGoals.CRITICAL.visits + " " + (this.emergencyGoals.CRITICAL.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.CRITICAL.type,
              icone: icone,
            }
          })
        }

      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    async loadPriorityCCSReport() {

      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/relatorioIn/visitasPrioritariosCCS`,
          {
            data_inicio: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            id_tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            meta_emergencial: this.filter.project.emergency,
          }
        );
        if (!_.isEmpty(this.filter.project.emergency) ) {
          const { PRIORITARIOCCS } = JSON.parse(this.filter.project.emergency);
          if (PRIORITARIOCCS) {
            this.emergencyGoals = {
              PRIORITYCCS: { status: 'Produtores Prioritários CCS', visits: PRIORITARIOCCS.visitas, questionnaires: PRIORITARIOCCS.questionarios, type: !_.isEmpty(PRIORITARIOCCS.tipo) ? PRIORITARIOCCS.tipo : "UN" },
            };
          }
        }
        //prioritarios
        if (this.type == 'priority-ccs') {
          this.headers = [
            { text: "Mês", value: "month" },
            { text: "Produtores", value: "producers", width: 120, align: "center" },
            { text: "Visitados", value: "visits", width: 150, align: "center"  },
            { text: "% Visitados", value: "visitsPerc", width: 120 },
            { text: "Meta", value: "goalsPerc", width: 100 },
            { text: "", value: "icone", width: 30 },
          ];

          this.items = data.map(o => {

            let percentualMeta = (this.emergencyGoals.PRIORITYCCS.type == "UN") ?  ((100 * o.total_prioritarios_visitados) / this.emergencyGoals.PRIORITYCCS.visits  ) : this.emergencyGoals.PRIORITYCCS.visits ;
            let icone = 'cancel';

            if (this.emergencyGoals.PRIORITYCCS.type == "UN"
              && (o.total_prioritarios_visitados >= this.emergencyGoals.PRIORITYCCS.visits
              || o.total_prioritarios_visitados == o.total_prioritarios) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.PRIORITYCCS.type == "PERCENT"
              &&  (o.perc_prioritarios_visitados == 100 || o.perc_prioritarios_visitados > percentualMeta )) {
              icone = 'verified';
            }
            if (!_.isEmpty(o.produtores_prioritarios)) {
              o.produtores_prioritarios.map(p => {
                if (!_.isEmpty(p.visitas)) {
                  p.visitas.map(v => {
                    this.itemsVisits.push(v.id_visita)
                  })
                }
              });
            }

            return {
              month: o.mes,
              producers: o.total_prioritarios,
              visits: o.total_prioritarios_visitados,
              visitsPerc: o.perc_prioritarios_visitados,
              details: o.produtores_prioritarios,
              goals: (this.emergencyGoals.PRIORITYCCS.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.PRIORITYCCS.visits / 100) : this.emergencyGoals.PRIORITYCCS.visits,
              goalsPerc: this.emergencyGoals.PRIORITYCCS.visits + " " + (this.emergencyGoals.PRIORITYCCS.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.PRIORITYCCS.type,
              icone: icone,
            }
          })
        }
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de PRIORITARIOS CCS", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    generateGraphOptions(data,  showGoals = true) {
      if (!this.filter.project.emergency) {
        return false;
      }

      let subtitle;
      let title;

      const { PRIORITARIO, PRIORITARIOCCS } = JSON.parse(this.filter.project.emergency);
      let campocpp = 'Geometrica trimestral';
      let campoccs = 'Geometrica mensal';
      switch (PRIORITARIO.campo_media) {
        case "cbt_geometrica": campocpp = 'Geometrica trimestral'; break;
        case "cbt_geometrica_mes": campocpp = 'Geometrica mensal'; break;
        case "cbt_aritmetica": campocpp = 'Aritmética mensal'; break;
      }

      switch (PRIORITARIOCCS.campo_media) {
        case "ccs_geometrica": campoccs = 'Geometrica trimestral'; break;
        case "ccs_geometrica_mes": campoccs = 'Geometrica mensal'; break;
        case "ccs_aritmetica": campoccs = 'Aritmética mensal'; break;
      }

      switch (this.type) {
        case 'critical' :
          subtitle = `Meta: Visitar ${this.emergencyGoals.CRITICAL.visits} ${(this.emergencyGoals.CRITICAL.type == 'PERCENT' ? '% dos produtores' : 'produtores')} por mês`;
          title = 'Críticos CPP - Produtores com 2 médias trimestrais consecutivas acima de 300 no mês';
          break;
        case 'priority' :
          subtitle = `Meta: Visitar ${this.emergencyGoals.PRIORITY.visits} ${(this.emergencyGoals.PRIORITY.type == 'PERCENT' ? '% dos produtores' : 'produtores')} por mês`;
          title = `Prioritários CPP - Produtores com média ${campocpp} acima de 300 no mês`;
          break;
        case 'priority-ccs' :
          subtitle = `Meta: Visitar ${this.emergencyGoals.PRIORITYCCS.visits} ${(this.emergencyGoals.PRIORITYCCS.type == 'PERCENT' ? '% dos produtores' : 'produtores')} por mês`;
          title = `Prioritários CCS - Produtores com média ${campoccs} acima de 500 no mês`;
          break;
      }
      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(140, 23, 23, 1)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          subtext: `${subtitle}`,
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 500,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
          formatter: items =>  [
            items[0].name,
            ...items.map(param => `${param.marker} ${param.seriesName} <b>${param.data}</b>`),
            this.type == 'critical'
              ? `Salvos 59: <b>${data[items[0].dataIndex].producers_in59} </b><br>` +
              `Salvos 76: <b>${data[items[0].dataIndex].producers_in76} </b>`
              : ''
          ].join('<br>')
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });
                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.month)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Produtores',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => o.producers),
          },

          {
            name: 'Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              // formatter: item => `  ${item.value} - ${data[item.dataIndex].visitsPerc}%  `,
            },
            data: data.map(o => o.visits),
          },

          showGoals ? {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: o => '  ' + data[o.dataIndex].goals,
              }
            },
            data: data.map(o => o.goals),
          } : null,

          this.type == 'critical' ? {
            name: 'Não Salvos',
            type: 'bar',
            barGap: '-100%',
            data: data.map(o => o.producers_not_save),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          } : null,
        ]
      };
    },

    showDetails({ item }) {

      this.details = [];

      const months = item.details[0].historico_medias_json.map(({ data }) => data).reverse();

      if (this.type == 'critical') {
        this.details.headers = [
          { text: 'Código', value: 'code' },
          { text: "Produtor", value: "producer" },
          { text: 'Rota Principal', value: 'route' },
          { text: 'Salvo', value: 'save' },
          ...months.map(date => ({
            text: _.upperFirst(moment(date).format("MMM/YY")),
            value: date,
            align: 'center',
          })),
          { text: "Visitas", value: "visits.length", align: 'center' },
          { text: "Menor CPP mês", value: "smaller_cpp", align: 'center' },
          { text: "Status", value: "status", align: 'center' },
        ];
      } else {
        this.details.headers = [
          { text: 'Código', value: 'code' },
          { text: "Produtor", value: "producer" },
          { text: 'Rota Principal', value: 'route' },
          ...months.map(date => ({
            text: _.upperFirst(moment(date).format("MMM/YY")),
            value: date,
            align: 'center',
          })),
          { text: "Visitas", value: "visits.length", align: 'center' },
          { text: "Status", value: "status", align: 'center' },
        ];
      }

      const { PRIORITARIO, PRIORITARIOCCS } = JSON.parse(this.filter.project.emergency);

      this.details.items = item.details.map(o => {
        o.historico_medias_json = o.historico_medias_json || [];
        const dates = o.historico_medias_json.reduce(
          (acc, media) => {
            let m = media.cbt_geometrica; //default cpp trimestral usada para criticos
            if (this.type == 'priority') {
              m =  PRIORITARIO.campo_media ? eval(`media.${PRIORITARIO.campo_media}`) : media.cbt_geometrica;
            } else if (this.type === 'priority-ccs') {
              m =  PRIORITARIOCCS.campo_media ? eval(`media.${PRIORITARIOCCS.campo_media}`) : media.ccs_geometrica_mes;
            }
            return {
              ... acc,
              [media.data]: parseInt(m) || 0
            }
          },
          {}
        );

        const visits = o.visitas.map(v => {
          return {
            id: v.id_visita,
            data_visita: v.data_visita,
            nome_tecnico: v.nome_tecnico,
            hora_chegada: v.hora_chegada,
            hora_saida: v.hora_saida,
            observacao: v.observacao,
            questionarios: [], //vai carregar questionarios aplicados na visita somente quando clicar nos detalhes da visita
          }
        })

        return {
          id: o.id_produtor,
          code: o.codigo_laticinio,
          save: o.salvo,
          producer: o.nome_produtor,
          route: o.rota_principal,
          smaller_cpp: o.menor_cbt,
          visits: visits,
          status: o.status,
          ...dates,
        };
      });

    },

    async showVisits({ item, value }) {

      let id_visits = item.visits.map(({ id }) => id);
      let questionariosMeta = [];

      switch (this.type) {
        case 'priority': questionariosMeta = this.emergencyGoals.PRIORITY.questionnaires; break;
        case 'critical':  questionariosMeta = this.emergencyGoals.CRITICAL.questionnaires; break;
        case 'priority-ccs': questionariosMeta = this.emergencyGoals.PRIORITYCCS.questionnaires; break;
      }

      if (_.isEmpty(questionariosMeta) || !value) {
        return false;
      }

      try {
        this.loading = true;
        let { data } = await this.$axios.post(
          `/formularioCustomizado/formularioAplicadoVisita`,
          {
            id_visita: id_visits,
            id_formulario: questionariosMeta
          }
        );

        item.visits.map((v => {
          data.forEach(d => {
            if (v.id === d.id_visita) {
              v.questionarios.push({ ...d });
            }
          });
        }))
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar questionarios da visita", "Atenção");
      }
      finally {
        this.loading = false;
      }

    },

    getReportJson(items) {
      return items.map(o => {
        if (this.type === 'priority' || this.type === 'priority-ccs') {
          return {
            Mês: this.formatMonth(o.month),
            Produtores: o.producers,
            Visitados: o.visits,
            '% Visitados': `${this.formatNumber(o.visitsPerc)}%`,
            Meta: o.goalsPerc,
          }
        }
        if (this.type === 'critical') {
          return {
            Mês: this.formatMonth(o.month),
            Produtores: o.producers,
            IN59: o.producers_in59,
            IN76: o.producers_in76,
            Não_Salvos: o.producers_not_save,
            Visitados: o.visits,
            '% Visitados': `${this.formatNumber(o.visitsPerc)}%`,
            Meta: o.goalsPerc,
          }
        }
      });
    },

    exportExcel() {
      if (this.type === 'priority' || this.type === 'priority-ccs') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.items));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Visitas Prioritários';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else if (this.type === 'critical') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.items));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Visitas Críticos';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
    },

    showPrintDialog() {
      this.printDialog.show = true;
      this.printDialog.item = this.itemsVisits;
      this.printDialog.params = {
        showABCCurve: true,
        showQualityResults: true,
        showQualityAvg: true,
        showOpenedRecommendations: true,
        showChecklists: true,
        showSampleCollects: true,
        showPriceNegotiation: false,
      };
    },

    /**
     * Fecha todos os dialogos
     */
    closeDialogs() {

      this.printDialog.show = false;
      this.printDialog.item = {};
      this.printDialog.params = {
        showABCCurve: true,
        showQualityResults: true,
        showQualityAvg: true,
        showOpenedRecommendations: true,
        showChecklists: true,
        showSampleCollects: true,
        showPriceNegotiation: true,
      };
    },

    /**
     * @event void
     *
     * Evento acionado para imprimir a lista de visitas
     */
    async print(ids) {
      try {
        this.loading = true;

        let dataIni = _.isEmpty(this.filter.range) ? moment() : moment(_.first(this.filter.range));
        let dataFim = _.isEmpty(this.filter.range) ? moment() : moment(_.last(this.filter.range));

        if (_.isEmpty(ids)) {
          return;
        }

        const { data } = await this.$axios.post(`/visita/imprime`, {
          ids,
          params: {
            mostra_curva_abc: this.printDialog.params.showABCCurve,
            mostra_resultados_qualidade: this.printDialog.params.showQualityResults,
            mostra_medias_qualidade: this.printDialog.params.showQualityAvg,
            mostra_recomendacoes_abertas: this.printDialog.params.showOpenedRecommendations,
            mostra_checklist: this.printDialog.params.showChecklists,
            mostra_coleta_amostras: this.printDialog.params.showSampleCollects,
            mostra_negociacoes_preco: this.printDialog.params.showPriceNegotiation,
          }
        });

        let fileName = '';
        let tipo = this.type == 'priority' ? "Prioritarios" : 'Criticos';

        fileName = tipo + ` - visitados - ${dataIni.format('MM.YYYY')} - ${dataFim.format('MM.YYYY')}.pdf`;

        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;

        this.closeDialogs();
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
