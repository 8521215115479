<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <!-- Lista modulos -->
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="4"
            md-4
            sm-4
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
            />
          </v-col>
        </v-card-title>
        <v-data-table
          dark
          :headers="headersMenu"
          :items="list"
          maxlength="19"
          class="diff-list-table"
          :search="filters.search"
          @click:row="handleClick"
        >
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="openEdit(item);">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item
                  small
                  @click="openAlert(item), showDeleteDialog = true;"
                >
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Alert excluir -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false"
      @keydown.esc="showDeleteDialog = false"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-center text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-center black--text">
            Deseja realmente Executar a Ação ?
          </h3>
          <v-flex
            xs12
            class="font-weight-black"
          >
            <br>
          </v-flex>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="openDelete(); showDeleteDialog = false;"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Botão Add nova menu -->
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="openInsert(); cadastroSelecionado;"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Inserir Menu</span>
    </v-tooltip>

    <!-- Pagina de Cadastro de menu -->
    <v-dialog
      width="1060"
      max-width="1060"
      :value="showCadastro"
      @input="showCadastro = false"
      @keydown.esc="showCadastro = false"
    >
      <v-card>
        <v-card-title>Cadastro de Menu</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="rules.isValid"
          >
            <input
              v-model="menu.id_menu"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="menu.descricao"
                  type="text"
                  label="Descrição"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="menu.icon"
                  type="text"
                  label="Icone"
                />
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-text-field
                  v-model="menu.ordem"
                  type="text"
                  label="Ordem"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="menu.componente"
                  type="text"
                  label="Componente"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="menu.nome"
                  type="text"
                  label="Nome"
                  hint="usar nome do componente sem espaços e acentos"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-autocomplete
                  v-model="menu.id_submenu"
                  label="Menu pai"
                  placeholder=" "
                  :items="listSubmenu"
                  item-value="id"
                  item-text="descricao"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="menu.requer_laticinio"
                  label="Requer Laticinio?"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="menu.requer_produtor"
                  label="Requer Produtor?"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="menu.view"
                  type="text"
                  label="View"
                  hint="Campo utilizado somente no bootstrap"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="menu.parametros"
                  type="text"
                  label="Parametros"
                  hint="Campo utilizado somente no bootstrap"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-color-picker
                  v-model="menu.background"
                  label="Background"
                  class="ma-2"
                  hide-canvas
                  elevation="2"
                />
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-text-field
                  v-model="menu.descricao_permissao"
                  type="text"
                  :disabled="!isNew"
                  label="Permissão vinculada"
                  hint="Descricção a permissão para quem pode visulizar esse menu."
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="black--text"
            text
            @click="showCadastro = false;"
          >
            cancelar
          </v-btn>
          <v-btn
            class="blue--text"
            text
            @click="
              validateForm();
              onMenuSave();"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import IMask from "imask";
import _ from "lodash";

export default {
  name: "Menu",

  components: {
  },

  filters: {
    //Filtro usado em todas as mascaras da view
    Mask(value, mask) {
      if (_.isEmpty(value)) {
        return value;
      }
      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  },
    
  data() {
    return {
      filters: {
        search: "",
      },
      loading: false,

      isNew: true,

      showCadastro: false,

      showDeleteDialog: false,

      cadastroSelecionado: {},

      rules: {
        isValid: false,
        nome_required: [v => !!v || "Campo obrigatorio"],
        codigo_required: [v => !!v || "Campo obrigatorio"],
      },

      menu: [],
            
      objetcToDelete: "",

      headersMenu: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Menu",
          value: "submenu",
        },
        {
          text: "Componente",
          value: "componente",
        },
        {
          text: "Ordem",
          value: "ordem",
        },
        {   value: "actions",
          sortable: false,
          align: "end",
          width: 20,
        },
      ],

      list: [],

      listSubmenu: [],

      CNPJMask: {
        mask: "00.000.0000/0000-00",
        maxlength: 19,
      },

      listModule: [],

      moduleSelected: [],
           
    };
  },
    
  mounted() {
    this.loadMenus();
  },

  methods: {

    openInsert() {
      this.showCadastro = true;
      this.menu = { id_menu: "", descricao: "", background: "#1976D2", id_submenu: 0, };
      this.moduleSelected = [];
      this.isNew = true;
    },

    async openEdit(menu) {
      this.showCadastro = true;
      this.menu = menu;
      this.isNew = false;
    },

    openAlert(menu) {
      this.objetcToDelete = menu.id_menu;
    },

    openDelete() {
      this.onMenuDelete(this.objetcToDelete);
    },

    validateForm() {
      this.$refs.form.validate();
    },

    async onMenuSave() {
      let menus = this.menu;
          
      try {
        this.showCadastro = false;
        this.loading = true;

        const request = {
          id_menu: menus.id_menu,
          id_submenu: menus.id_submenu ? menus.id_submenu : 0,
          descricao: menus.descricao,
          ordem: menus.ordem,
          icon: menus.icon,
          componente: menus.componente,
          nome: menus.nome,
          background: menus.background.hex ? menus.background.hex : menus.background,
          requer_laticinio: menus.requer_laticinio ? menus.requer_laticinio : 0,
          requer_produtor: menus.requer_produtor ? menus.requer_produtor : 0,
          view: menus.view,
          parametros: menus.parametros,
          descricao_permissao: menus.descricao_permissao,
        };
            
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/salvaMenu`,
          this.$qs.stringify({
            ...request,
          }),
        );

        console.log(data);

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }
            
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar !", "Atenção");
      } finally {
        await this.loadMenus();
        this.loading = false;
      }
    },

    async onMenuDelete(toDelete) {
      try {

        const { data } = await this.$axios.post(
          `/configuracaoMilkup/deleteMenu`,
          this.$qs.stringify({
            id_menu: toDelete,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw "PHP Error";
        }

        return this.loadMenus();

      } catch (error) {
        console.log(error);
      }
    },

    async loadMenus() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaMenu`);
                
        this.list = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
        this.loadSubMenus();
      }
    },
    async loadSubMenus() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaMenuPai`);
            
        this.listSubmenu =  data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
         * Trata evento de clique na linha..
         */
    handleClick(item) {
      this.openEdit(item);
    },

    async loadModules() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaModulo`);
                
        this.listModule = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
        
  },
}
</script>

