<template>
  <div>
    <v-flex xs12>
      <v-card
        class="graph-card"
        color="transparent"
        dark
      >
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              {{ indicators }}
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              {{ month }}
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            :loading="loading"
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
            show-expand
            single-expand
            @item-expanded="showDetails"
          >
            <template #[`item.icone`]="{value}">
              <v-icon
                right
                :color="value == 'verified' ? 'green darken-1': 'red darken-1'"
              >
                {{ value }}
              </v-icon>
            </template>
            <template #expanded-item="{headers}">
              <td
                :colspan="headers.length"
                class="pa-5 text-center"
              >
                <v-data-table
                  :headers="details.headers"
                  :items="details.items"
                  :search="details.search"
                  show-expand
                  single-expand
                  class="elevation-1"
                  :items-per-page="-1"
                  hide-default-footer
                  @item-expanded="showVisits"
                >
                  <template #[`item.status`]="{value}">
                    <v-chip
                      v-if="value == 'DESVINCULADO'"
                      color="red"
                      small
                    >
                      <v-avatar
                        left
                        small
                      >
                        <v-icon>cancel</v-icon>
                      </v-avatar>
                      D
                    </v-chip>
                    <v-chip
                      v-else-if="value == 'SUSPENSO'"
                      color="orange"
                      small
                    >
                      <v-avatar
                        left
                        small
                      >
                        <v-icon>remove_circle</v-icon>
                      </v-avatar>
                      S
                    </v-chip>
                    <v-chip
                      v-else
                      color="green"
                      small
                    >
                      <v-avatar
                        left
                        small
                      >
                        <v-icon>check_circle</v-icon>
                      </v-avatar>
                      A
                    </v-chip>
                  </template>
                  <template #expanded-item="{ headers, item }">
                    <td
                      :colspan="headers.length"
                      class="pa-0 text-center"
                    >
                      <v-data-table
                        v-if="item.visits.length"
                        :headers="visits.headers"
                        :items="item.visits"
                        :items-per-page="item.visits.length"
                        dense
                        hide-default-footer
                        class="elevation-1 ma-3"
                      >
                        <template
                          #[`item.data_visita`]="{ value }"
                        >
                          <v-chip
                            x-small
                            label
                            color="blue"
                          >
                            {{ formatDate(value, "DD/MM/YYYY") }}
                          </v-chip>
                        </template>
                        <template
                          #[`item.questionarios`]="{ value }"
                        >
                          <v-tooltip
                            v-if="value.length > 0"
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                class="pa-0 ma-0"
                                v-on="on"
                                @click.stop="printModel(item.id)"
                              >
                                <v-icon
                                  color="orange lighten-3"
                                  class="pa-0 ma-0"
                                >
                                  receipt_long
                                </v-icon>
                              </v-btn>
                            </template>
                            Questionário Aplicado:
                            <p
                              v-for="q in value"
                              :key="q.id_formulario"
                            >
                              {{ q.titulo }}
                            </p>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                      <span v-else>
                        Nenhuma visita encontrada
                      </span>
                    </td>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
          <v-overlay
            v-model="loading"
            absolute
          >
            <v-card-text>
              Carregando Curva ABC...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              />
            </v-card-text>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-flex>
  </div>
</template>

<script>

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import RatingService from "@/Domains/Projects/PQFL/BPA/Services/RatingService.js";
import _ from "lodash";

const ratingService = new RatingService();

export default {
  name: "report-table",

  components: {
  },
 
  mixins: [ReportMixin],

  props: {
    filter: Object,
    curve: String,
    ranges: Array,
  },

  data() {
    return {
      loading: false,
      
      items: [],

      headers: [
        { text: 'Classificação', value: 'range' },
        { text: 'Produtores', value: 'producers' },
        { text: 'Visitados', value: 'visits' },
        { text: 'Meta de visita', value: 'goal' },
        { text: "", value: "icone", width: 30 },
      ],

      details: {
        headers: [],
        items: [],
        type: '',
        month: '',
        search: ''
      },
      visits: {
        headers: [
          { text: 'Técnico', value: 'nome_tecnico' },
          { text: 'Data', value: 'data_visita' },
          { text: 'Início', value: 'hora_chegada' },
          { text: 'Fim', value: 'hora_saida' },
          { text: 'Observação', value: 'observacao' },
          { text: '', value: 'questionarios' },
        ]
      },
    };
  },

  computed: {

    title() {
      return ratingService.types[this.curve];
    },

    month() {
      return moment(this.filter.date.month).format("MM/YYYY");
    },
    indicators() {
      const faixa = this.ranges.filter(c => c.tipo === this.curve);
      let indicador = '';
      if (_.first(faixa).indicador) {
        indicador = _.first(faixa).indicador;
      } else if (_.first(faixa).metas_agrupadas) {
        const metas_agrupadas = JSON.parse(_.first(faixa).metas_agrupadas || '') || [];
        metas_agrupadas.forEach((meta) => {
          indicador = indicador + ' [' + meta.indicator.indicator + '] ' ;
        });
      }
      return indicador;
    },
  
  },
  watch: {
    filter: {
      handler() {
        this.loadProducerRatings();
      },
      deep: true
    }
   
  },
  created() {
    this.loadProducerRatings();
  },

  methods: {

    async loadProducerRatings() {
      if (this.filter.project) {
        await this.loadReport();
      } else {
        this.items = [];
      }
    },

    async loadReport() {
      this.loading = true;
      this.items = [];
      try {

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/ListaProdutoresClassificadosBPAFaixas`,
          {
            mes_referencia: moment(this.filter.date.month).format("YYYY-MM"),
            tipo_faixa: this.curve,
          }
        );
        if (!_.isArray(data)) {
          throw new Error(data);
        }

        if (!_.isEmpty(data)) {
          this.items = data.map(o => {
            return {
              id: o.id_meta_bpa,
              range: o.nome_faixa,
              producers: o.total_produtores,
              visits: o.total_visitados,
              goal: o.meta_visitas,
              icone: (o.total_produtores == o.total_visitados) ? 'verified' : 'cancel',
              details: o.produtores,
            }
          });

          this.emitBpa();
        }
      }
      catch (err) {
        console.warn(err);
        this.items = [];
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
        
      }
    },

    showDetails({ item }) {
      
      this.details = [];
      
      this.details.headers = [
        { text: 'Código', value: 'code' },
        { text: "Produtor", value: "producer" },
        { text: 'Rota Principal', value: 'route' },
        { text: "visitas", value: "visits.length", align: 'center' },
        { text: "Status", value: "status", align: 'center' },
      ];

      this.details.items = item.details.map(o => {

        const visits = o.visitas.map(v => {
          return {
            id: v.id_visita,
            data_visita: v.data_visita,
            nome_tecnico: v.nome_tecnico,
            hora_chegada: v.hora_chegada,
            hora_saida: v.hora_saida,
            observacao: v.observacao,
            questionarios: [], //vai carregar questionarios aplicados na visita somente quando clicar nos detalhes da visita
          }
        })

        return {
          id: o.id_produtor,
          code: o.codigo_laticinio,
          producer: o.nome_produtor,
          route: o.rota_principal,
          status: o.status,
          visits: visits,
        };
      });
      
    },

    async showVisits({ item }) {
      
      let questionariosMeta = this.ranges.questionarios_aplicar ? JSON.parse(this.ranges.questionarios_aplicar) : [];
      if (_.isEmpty(questionariosMeta) ) {
        return false;
      }

      let id_visits = item.visits.map(({ id }) => id);

      try {
        this.loading = true;
        let { data } = await this.$axios.post(
          `/formularioCustomizado/formularioAplicadoVisita`,
          {
            id_visita: id_visits,
            id_formulario: questionariosMeta
          }
        );
        
        item.visits.map((v => {
          data.forEach(d => {
            if (v.id === d.id_visita) {
              v.questionarios.push({ ...d });
            }
          });
        }));
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar questionarios da visita", "Atenção");
      }
      finally {
        this.loading = false;
      }
      
    },

    emitBpa() {
      let data = {
        title: this.title,
        indicator: this.indicators,
        items: this.items
      }

      return this.$emit("onLoadBpa", data);
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>