<template>
  <v-row>
    <v-col
      md="12"
    >
      <v-card
        color="transparent"
        dark
      >
        <v-card-title class="text-subtitle-2">
          <div
            v-for="i in goals.ranges.rangesCombined"
            :key="i.key"
            small
            close
            color="primary"
          >
            <span class="mr-3">
              [ {{ i.indicator.description }} ]
            </span>
          </div>

          <v-spacer />
          <v-btn
            icon
            color="white"
          >
            <v-icon
              @click="onRangeDelete()"
            >
              delete_outline
            </v-icon>
          </v-btn>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="itemTipo"
                :items="entries1"
                item-value="value"
                item-text="text"
                dark
                hide-selected
                hide-details
                label="Tipo"
                prepend-inner-icon="search"
                filled
                return-object
                @change="onChangeTipo"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="itemFaixa"
                :items="entries2"
                item-value="value"
                item-text="text"
                dark
                hide-selected
                hide-details
                label="Faixa"
                prepend-inner-icon="search"
                filled
                return-object
                @change="onChangeFaixa"
              />
            </v-col>
            <v-col
              v-if="showIndicator"
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="itemIndicador"
                :items="indicator"
                item-value="value"
                item-text="text"
                dark
                hide-selected
                hide-details
                label="Indicador"
                prepend-inner-icon="search"
                filled
                return-object
                @change="onChangeIndicador"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="itemClassificacao"
                :items="classe"
                item-value="value"
                item-text="text"
                dark
                hide-selected
                hide-details
                label="Classificação"
                prepend-inner-icon="search"
                filled
                return-object
                @change="onChangeClassificacao"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-simple-table>
          <thead>
            <tr>
              <th
                class="text-left title"
              >
                Combinações
              </th>
              <th
                class="text-left range"
              >
                Faixa
              </th>
              <th
                class="text-left"
                width="80"
              >
                Visitas
              </th>
              <th
                class="text-left"
                width="80"
              >
                Período
              </th>
              <th
                class="text-left"
                width="150"
              >
                Aplicar Questionários
              </th>
              <th
                class="text-left"
                width="300"
              >
                Ações
              </th>
              <th
                class="text-left"
                width="15"
              >
                Aplicar em campo
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-chip
                  v-for="i in goals.ranges.rangesCombined"
                  :key="i.key"
                  small
                  close
                  color="primary"
                  @click:close="onItemRemove(i)"
                >
                  {{ i.indicator.description }}
                </v-chip>
              </td>
              <td>
                <v-text-field
                  v-model.number="goals.ranges.class"
                  placeholder="0"
                  type="text"
                  dense
                  full-width
                  outlined
                  hide-details
                />
              </td>
              <td>
                <v-text-field
                  v-model.number="goals.ranges.visits"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  :rules="[v => !v || v >= 0 || 'obrigatório']"
                  @keypress="disableDotAndComma"
                />
              </td>
              <td>
                <v-select
                  v-model="goals.ranges.period"
                  :items="periods"
                  item-value="value"
                  item-text="text"
                  dense
                  outlined
                  hide-details
                />
              </td>
              <td>
                <v-select
                  v-model="goals.ranges.questionnaires"
                  label="Questionários"
                  :items="questionnaires"
                  prepend-inner-icon="icon-cadastros"
                  item-value="id"
                  item-text="title"
                  multiple
                  outlined
                  small-chips
                  hide-details
                  dense
                />
              </td>
              <td>
                <v-text-field
                  v-model.number="goals.ranges.actions"
                  placeholder=""
                  type="text"
                  dense
                  outlined
                  hide-details
                />
              </td>
              <td>
                <v-switch
                  v-model="goals.ranges.show_app"
                  hide-details
                  class="ma-1 pb-1"
                  color="teal accent-3"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<style lang="scss">
  .title{
    min-width: 150px !important;
    max-width: 300px !important;
  }

  .range {
    min-width: 80px !important;
  }

</style>
<script>

import _ from "lodash";

export default {

  components: {
  },

  props: {
    value: Object,
    quality: Object,
    nonconformity: Object,
  },

  data() {
    return {
      entries1: [
        { text: 'Qualidade', value: 'quality' },
        { text: 'Inconformes', value: 'nonconformity' },
      ],

      entries2: [
        { text: 'Faixa Simples', value: 'simple' },
        { text: 'Pontuação', value: 'points' },
      ],

      showIndicator: true,

      indicator: [],
      classe: [],

      itemTipo: '',
      itemFaixa: '',
      itemIndicador: '',
      itemClassificacao: '',

      periods: [
        { text: "Semanal", value: "semanal" },
        { text: "Quinzenal", value: "quinzenal" },
        { text: "Mensal", value: "mensal" },
        { text: "Bimestral", value: "bimestral" },
        { text: "Trimestral", value: "trimestral" },
        { text: "Quadrimestral", value: "quadrimestral" },
        { text: "Quinquenal", value: "quinquenal" },
        { text: "Semestral", value: "semestral" },
        { text: "Anual", value: "anual" },
      ],
      questionnaires: [],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  created() {
    this.loadQuestionnaires();
  },
  methods: {
    async loadQuestionnaires() {

      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_BPA'],
            ativo: 1,
          }
        );

        this.questionnaires = data
          //.filter(item => !item.tipo.includes('VISITA_PADRAO'))
          .map(item => {
            let text = item.titulo ? item.titulo : ``;
            text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
            text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;
            return {
              id: item.id,
              title: text,
              types: item.tipo,
            }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    onChangeTipo() {
      this.itemFaixa = [];
      this.itemIndicador = [];
      this.itemClassificacao = [];
    },
    onChangeFaixa() {
      this.itemIndicador = [];
      this.itemClassificacao = [];

      //qualidade por pontuacao nao tem indicador pois é geral
      if (this.itemTipo.value == 'quality' && this.itemFaixa.value == 'points') {
        this.showIndicator = false;
        //seta classificacao direto pois so tem uma
        this.classe = _.head(this.quality.points).ranges.map( i => {

          let text = i.class + " ( de " + i.start + " até " + i.end + " )";
          let description = "Qualidade: " + i.class + " ( de " + i.start + " até " + i.end + " pontos )";
          if (!_.isEmpty(i.end) || i.end === null) {
            text = i.class + " ( acima de  " + i.start + " )";
            description = "Qualidade: "  + i.class + " ( acima de  " + i.start + " pontos )";
          }
          return {
            value: i.index,
            text: text,
            id: i.id,
            class: i.class,
            indicator: this.itemIndicador.text,
            faixa: this.itemFaixa.value == "simple" ? "simples" : "pontuação",
            description: description,
            show_app: true,
          }
        });

      } else {
        this.showIndicator = true;
        this.indicator = this[this.itemTipo.value][this.itemFaixa.value].map( i => {
          return i.indicator
        });
      }

    },
    onChangeIndicador() {
      this.itemClassificacao = [];

      let rangesIndicator = _.filter(this[this.itemTipo.value][this.itemFaixa.value], (data) => {
        return data.indicator.value == this.itemIndicador.value;
      })

      this.classe = _.head(rangesIndicator).ranges.map( i => {

        let pontos = this.itemFaixa.value === 'points' ? 'pontos' : '';
        let text = i.class + " ( de " + i.start + " até " + i.end + " )";
        let description = this.itemIndicador.text + ' - ' + i.class + " ( de " + i.start + " até " + i.end + " "  + pontos + " )";
        if (!_.isEmpty(i.end) || i.end === null) {
          text = i.class + " ( acima de  " + i.start + " )";
          description = this.itemIndicador.text + ' - ' + i.class + " ( acima de  " + i.start + " "  + pontos + " )";
        }

        return {
          value: i.index,
          text: text,
          id: i.id,
          class: i.class,
          indicator: this.itemIndicador.text,
          faixa: this.itemFaixa.value == "simple" ? "simples" : "pontuação",
          description: description,
          show_app: true,
        }
      });

    },

    onChangeClassificacao() {
      let indicator = this.itemClassificacao;
      this.goals.ranges.class = this.className;
      this.goals.ranges.rangesCombined.push({ indicator })
      this.itemTipo = [];
      this.itemFaixa = [];
      this.itemIndicador = [];
      this.itemClassificacao = [];
    },

    onRangeDelete() {
      return this.$emit("onRangeDelete", this.goals);
    },

    onItemRemove(indicator) {
      this.goals.ranges.rangesCombined = _.remove(this.goals.ranges.rangesCombined, (data) => {
        return data.indicator.id !== indicator.indicator.id;
      });
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
}
</script>
