<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    />

    <v-row
      xs12
      justify="center"
    >
      <v-col
        cols="12"
      >
        <h2 class="menu-header white--text">
          Com/Sem PQFL
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab>
        PQFL aberto
      </v-tab>
      <v-tab>
        PQFL Padrão
      </v-tab>

      <v-tab-item>
        <open-pqfl-tab />
      </v-tab-item>
      <v-tab-item>
        <standard-pqfl />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import OpenPqflTab from "@/Domains/Quality/Qualification/CollectionsPQFL/Views/OpenPQFLTab.vue";
import StandardPqfl from "@/Domains/Quality/Qualification/CollectionsPQFL/Views/StandardPQFL .vue";

export default {
  components: {
    OpenPqflTab,
    StandardPqfl
  },
}
</script>