<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
          offset-md="2"
        >
          <v-menu
            v-model="monthRef.picker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="monthRef.value | dateFormat('MM/YYYY')"
                label="Mês de início"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="monthRef.value"
              :max="monthRef.value_fim"
              type="month"
              no-title
              scrollable
              @input="monthRef.picker = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="month_endRef.picker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="month_endRef.value_fim | dateFormat('MM/YYYY')"
                label="Mês de fim"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="month_endRef.value_fim"
              :min="monthRef.value"
              type="month"
              no-title
              scrollable
              @input="month_endRef.picker = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <template>
            <v-text-field
              v-model="protocolo"
              label="Protocolo"
              persistent-hint
              dark
              filled
              hide-selected
              hide-details
              :rules="[v => !!v || 'Campo obrigatório!']"
            />
          </template>
        </v-col>
        <v-col
          cols="12"
          md="5"
          offset-md="2"
        >
          <v-autocomplete
            v-model="dairySelected"
            outlined
            chips
            small-chips
            dark
            filled
            multiple
            hide-details
            label="Unidades "
            :items="ListDairy"
            item-text="nome"
            item-value="id_pessoa"
          >
            <template #selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                color="primary"
                @click="data.select"
                @click:close="removeChipDairy(data.item)"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>
                {{ data.item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="groupSelected"
            outlined
            chips
            small-chips
            dark
            filled
            multiple
            hide-details
            label="Grupos "
            :items="listGroup"
            item-text="description"
            item-value="id"
          >
            <template #selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                color="primary"
                @click="data.select"
                @click:close="removeChipGroup(data.item)"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>
                {{ data.item.description }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="9"
          offset-md="2"
        >
          <v-text-field
            v-model="descricao"
            label="Descrição"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>

        <v-col cols="12">
          <v-flex>
            <v-tabs
              v-model="tab"
              dark
              background-color="transparent"
              class="transparent"
              centered
            >
              <v-tab href="#aba-qualidade">
                Qualidade
              </v-tab>
              <v-tab href="#aba-visita">
                Visitas
              </v-tab>
              <v-tab href="#aba-classificacao">
                Curva ABC
              </v-tab>
              <v-tab-item value="aba-qualidade">
                <quality-actions v-model="qualidadeGoals" />
              </v-tab-item>
              <v-tab-item value="aba-visita">
                <visit-actions v-model="visitaGoals" />
              </v-tab-item>
              <v-tab-item value="aba-classificacao">
                <v-col cols="12">
                  <v-flex>
                    <v-tabs
                      v-model="tab2"
                      vertical
                      dark
                      background-color="transparent"
                      class="transparent"
                    >
                      <v-tab href="#aba-qualidade-classificacao">
                        Qualidade
                      </v-tab>
                      <v-tab href="#aba-inconformes-classificacao">
                        Inconformes
                      </v-tab>
                      <v-tab href="#aba-combinada-classificacao">
                        Combinada
                      </v-tab>
                      <v-tab-item value="aba-qualidade-classificacao">
                        <quality-classification-actions v-model="qualityGoals" />
                      </v-tab-item>
                      <v-tab-item value="aba-inconformes-classificacao">
                        <conformed-classification-actions
                          ref="conformedActions"
                          :value="nonConformityGoals"
                        />
                      </v-tab-item>
                      <v-tab-item value="aba-combinada-classificacao">
                        <combined-classification-actions
                          v-model="combinedGoals"
                          :quality="qualityGoals"
                          :nonconformity="nonConformityGoals"
                        />
                      </v-tab-item>
                    </v-tabs>
                  </v-flex>
                </v-col>
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-col>
      </v-row>
    </v-form>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click.stop="save"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar configuração
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay v-model="loading">
      <v-card-text>
        Carregando
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import QualityActions from "@/Domains/Projects/PMLS/Components/QualityActions.vue";
import VisitActions from "@/Domains/Projects/PMLS/Components/VisitActions.vue";
import QualityClassificationActions from "@/Domains/Projects/PQFL/BPA/Components/QualityActions.vue";
import ConformedClassificationActions from "@/Domains/Projects/PQFL/BPA/Components/ConformedActions.vue";
import CombinedClassificationActions from "@/Domains/Projects/PQFL/BPA/Components/CombinedActions.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    QualityActions,
    VisitActions,
    QualityClassificationActions,
    ConformedClassificationActions,
    CombinedClassificationActions,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },

  props: {
    value: String
  },

  data() {
    return {
      id: null,
      loading: false,

      tab: "aba-qualidade",

      tab2: "aba-qualidade-classificacao",

      maxDateRef: moment().format("YYYY-MM"),

      monthRef: {
        picker: false,
        value: null,
      },

      month_endRef: {
        picker: false,
        value_fim: null,
      },

      protocolo: null,

      listGroup: [],

      ListDairy: [],

      dairySelected: [],

      groupSelected: [],

      descricao: null,

      grupos: [],

      qualidadeGoals: [],

      visitaGoals: [],

      //-------------------curva
      nonConformityGoals: {
        simple: [],
        points: [],
      },

      qualityGoals: {
        simple: [],
        points: [{
          ranges: [],
        }],
        settingPoints: [],
      },

      combinedGoals: {
        combined: []
      },
    };
  },

  watch: {
    value() {
      this.id = this.value;
      this.load();
    }
  },

  mounted() {
    this.id = this.value;
    this.load();
    this.getDairies();
    this.loadGrupos();
  },

  methods: {

    async getDairies() {
      if (this.ListDairy.length > 0) {
        return this.ListDairy;
      }
      const { data } = await this.$axios.post(`/pessoa/listaLaticinioJson`);
      this.ListDairy = data;
    },

    async loadGrupos() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get('/projects/project-group', {
          params: {
            tipo: 'PMLS',
          }
        });

        this.listGroup = data.map(item => {
          return {
            id: item.id_projeto_grupo,
            description: item.descricao,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    removeChipDairy(lat) {
      this.dairySelected = this.dairySelected.filter((dairyId) => dairyId !== lat.id_pessoa);
    },
    removeChipGroup(group) {
      this.groupSelected = this.groupSelected.filter((groupId) => groupId !== group.id);
    },

    async load() {
      console.log('load',this.id );
      this.monthRef.value = '';
      this.month_endRef.value_fim = '';
      this.dairySelected = '';
      this.groupSelected = '';
      this.descricao = '';
      this.protocolo = '';
      this.qualidadeGoals = [];
      this.visitaGoals = [];
      this.questionnairesGoals =  [];
      this.emergencyGoals      =  {};
      this.qualityGoalsPae     =  [];
      this.qualityGoals.simple = [];
      this.qualityGoals.points[0].ranges = [];
      this.qualityGoals.settingPoints = [];
      this.nonConformityGoals.simple = [];
      this.nonConformityGoals.points = [];
      this.combinedGoals.combined = [];

      console.log('qualidadeGoals',this.qualidadeGoals );

      if (this.id) {
        this.loadPlanning();
      }
    },

    async loadPlanning() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/recomendacoesQualidade/detalhe`, { id: this.id });

        if (!_.isObject(data)) {
          throw data;
        }

        this.monthRef.value = !_.isEmpty(data.data) ? moment(data.data).format("YYYY-MM") : moment().format("YYYY-MM");
        this.month_endRef.value_fim = !_.isEmpty(data.data_fim) ? moment(data.data_fim).format("YYYY-MM") : moment().format("YYYY-MM");
        this.protocolo = !_.isEmpty(data.protocolo) ? data.protocolo : '';
        this.descricao = !_.isEmpty(data.descricao) ? data.descricao : '';

        this.setState(data);

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas!", "Atenção");
        console.warn(err)
      } finally {
        this.loading = false;
      }
    },

    async setState(data = null) {
      this.loading = true;

      /**
       * Metas de qualidade pae
       */
      this.qualidadeGoals = [];

      if (data && !_.isEmpty(data.qualidade)) {
        this.qualidadeGoals = data.qualidade.map(goal => ({
          indicator: goal.indicador,
          action: goal.acao,
          percent: goal.percentual,
          media: goal.media,
          type: !_.isEmpty(goal.tipo) ? goal.tipo : 'PERCENT',
        }));

      }

      this.visitaGoals = [];

      if (data && !_.isEmpty(data.visita)) {
        this.visitaGoals.number_visit = data.visita[0].numero_visita;
        this.visitaGoals.periodo = data.visita[0].periodo;
        this.visitaGoals.type = data.visita[0].tipo;
        this.visitaGoals.minimum_time = data.visita[0].tempo_minimo;
        this.visitaGoals.questionnaires = data.visita[0].questionario;
      }

      this.questionnairesGoals = !_.isEmpty(data.questionario) ? data.questionario : [];
      this.emergencyGoals      = !_.isEmpty(data.emergencial) ? data.emergencial : {};
      this.qualityGoalsPae     = !_.isEmpty(data.qualidade) ? data.qualidade : [];

      this.dairySelected = data.laticinios;
      this.groupSelected = data.grupos;

      await this.loadRanges();
      await this.loadQualityPoints();
      this.loading = false;
    },

    async loadRanges() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/recomendacoesQualidade/rangesBpa`, { id_assistencia: this.id });

        if (!_.isObject(data)) {
          throw data;
        }
        this.handleRanges(data);

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas!", "Atenção");
        console.warn(err)
      } finally {
        this.loading = false;
      }
    },
    //handle ranges
    handleRanges(data) {
      data.forEach(d => {
        let ranges =  {
          id: d.id_meta_bpa,
          index: d.ordem,
          start: d.inicio_faixa,
          end: d.fim_faixa,
          class: d.nome_faixa,
          visits: d.visitas,
          period: d.periodo,
          questionnaires: JSON.parse(d.questionarios_aplicar),
          actions: d.acoes,
          show_app: d.aplicar_campo,
        };
        //-------------------------------qualidade simples
        if (d.tipo == 'QUALIDADE-SIMPLES') {
          let index = this.qualityGoals.simple.findIndex(data => {
            return data.indicator.value == d.id_qualidade_consulta;
          });

          if (index > 0 || index === 0) {
            this.qualityGoals.simple[index].ranges.push(ranges);
          } else {
            let indicator = {
              text: d.indicador,
              value: d.id_qualidade_consulta
            };
            this.qualityGoals.simple.push({ indicator, ranges: [ranges] });
          }
        //----------------------------------qualidade pontos
        } else if (d.tipo == 'QUALIDADE-PONTOS') {
          this.qualityGoals.points[0].ranges.push( ranges );

        //-------------------------------inconformes simples
        } else if (d.tipo == 'INCONFORME-SIMPLES') {
          ranges = {
            ...ranges,
            type: d.condicional,
          }
          let index = this.nonConformityGoals.simple.findIndex(data => {
            return data.indicator.value == d.id_questionario;
          });

          if (index > 0 || index === 0) {
            this.nonConformityGoals.simple[index].ranges.push(ranges);
          } else {
            let indicator = {
              text: d.indicador,
              value: d.id_questionario
            };
            this.nonConformityGoals.simple.push({ indicator, ranges: [ranges] });
          }

          //-------------------------------inconformes pontos
        } else if (d.tipo == 'INCONFORME-PONTOS') {
          let index = this.nonConformityGoals.points.findIndex(data => {
            return data.indicator.value == d.id_questionario;
          });

          if (index > 0 || index === 0) {
            this.nonConformityGoals.points[index].ranges.push(ranges);
          } else {
            let indicator = {
              text: d.indicador,
              value: d.id_questionario
            };
            this.nonConformityGoals.points.push({ indicator, ranges: [ranges] });
          }
        //-------------------------------combinadas
        } else if (d.tipo == 'COMBINADAS') {
          ranges = {
            ...ranges,
            rangesCombined: JSON.parse(d.metas_agrupadas),
          }
          this.combinedGoals.combined.push({  ranges: ranges });
        }

      });

    },

    async loadQualityPoints() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/recomendacoesQualidade/qualityPoints`, { id_assistencia: this.id });

        if (!_.isObject(data)) {
          throw data;
        }

        data.forEach(d => {
          let ranges =  {
            id: d.id_qualidade_pontuacao,
            index: d.ordem,
            start: d.inicio_faixa,
            end: d.fim_faixa,
            point: d.pontos
          };
          let index = this.qualityGoals.settingPoints.findIndex(data => {
            return data.indicator.value == d.id_qualidade_consulta;
          });

          if (index > 0 || index === 0) {
            this.qualityGoals.settingPoints[index].qualityPoints.push(ranges);
          } else {
            let indicator = {
              text: d.indicador,
              value: d.id_qualidade_consulta
            };
            this.qualityGoals.settingPoints.push({ indicator, qualityPoints: [ranges] });
          }
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas!", "Atenção");
        console.warn(err)
      } finally {
        this.loading = false;
      }
    },

    async handleSave() {

      let metas = [];
      //----------------------------------------qualidade simples
      this.qualityGoals.simple.forEach(q => {
        let m = {
          type: 'QUALIDADE-SIMPLES',
          indicator: q.indicator.text,
          id_qualidade_consulta: q.indicator.value,
        };
        q.ranges.forEach(range => {
          if (!_.isEmpty(range.id)) {
            metas.push({
              ...m,
              id: range.id,
              index: range.index,
              class: range.class,
              start: range.start,
              end: range.end,
              actions: range.actions,
              show_app: range.show_app,
              visits: range.visits,
              period: range.period,
              questionnaires: range.questionnaires,
            })
          }
        })
      });
      //-------------------------------------qualidade pontos
      this.qualityGoals.points.forEach(q => {
        let m = {
          type: 'QUALIDADE-PONTOS',
        };
        q.ranges.forEach(range => {
          if (!_.isEmpty(range.id)) {
            metas.push({
              ...m,
              id: range.id,
              index: range.index,
              class: range.class,
              start: range.start,
              end: range.end,
              actions: range.actions,
              show_app: range.show_app,
              visits: range.visits,
              period: range.period,
              questionnaires: range.questionnaires,
            })
          }
        })
      });
      //----------------------------------------inconformes simples
      this.nonConformityGoals.simple.forEach(q => {
        let m = {
          type: 'INCONFORME-SIMPLES',
          indicator: q.indicator.text,
          id_questionnaire: q.indicator.value,
        };
        q.ranges.forEach(range => {
          if (!_.isEmpty(range.id)) {
            metas.push({
              ...m,
              id: range.id,
              index: range.index,
              class: range.class,
              start: range.start,
              end: range.end,
              actions: range.actions,
              show_app: range.show_app,
              visits: range.visits,
              period: range.period,
              questionnaires: range.questionnaires,
              conditional: range.type,
            })
          }
        })
      });
      //----------------------------------------inconformes pontuacao
      this.nonConformityGoals.points.forEach(q => {
        let m = {
          type: 'INCONFORME-PONTOS',
          indicator: q.indicator.text,
          id_questionnaire: q.indicator.value,
        };
        q.ranges.forEach(range => {
          if (!_.isEmpty(range.id)) {
            metas.push({
              ...m,
              id: range.id,
              index: range.index,
              class: range.class,
              start: range.start,
              end: range.end,
              actions: range.actions,
              show_app: range.show_app,
              visits: range.visits,
              period: range.period,
              questionnaires: range.questionnaires,
            })
          }
        })
      });
      //----------------------------------------combinadas
      this.combinedGoals.combined.forEach(q => {
        let m = {
          type: 'COMBINADAS',
        };
        let range = q.ranges;
        if (!_.isEmpty(range.id)) {
          metas.push({
            ...m,
            id: range.id,
            class: range.class,
            index: range.index,
            actions: range.actions,
            show_app: range.show_app,
            visits: range.visits,
            period: range.period,
            questionnaires: range.questionnaires,
            rangesCombined: range.rangesCombined,
            id_combined: _.map(range.rangesCombined, (data) => { return data.indicator.id; })
          })
        }
      });
      return metas;
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        this.$snotify.warning("Campos inválidos", "Atenção");
        return false;
      }
      try {
        this.loading = true;

        let metas = await this.handleSave();

        let pontuacaoQualidade = [];
        this.qualityGoals.settingPoints.forEach(q => {
          let m = {
            indicator: q.indicator.text,
            id_qualidade_consulta: q.indicator.value,
          };
          q.qualityPoints.forEach(range => {
            if (!_.isEmpty(range.id)) {
              pontuacaoQualidade.push({
                ...m,
                id: range.id,
                index: range.index,
                start: range.start,
                end: range.end,
                point: range.point,
              })
            }
          })
        });

        const quantity = this.qualidadeGoals.map(goal => ({
          indicador: goal.indicator,
          acao: goal.action,
          percentual: goal.percent,
          tipo: !_.isEmpty(goal.type) ? goal.type : null,
          media: goal.media,
        }));

        let visit = [];

        let visits = { numero_visita: "", periodo: "", tipo: "", tempo_minimo: "" };

        this.visitaGoals.number_visit ? visits.numero_visita = this.visitaGoals.number_visit : "";
        this.visitaGoals.periodo ? visits.periodo = this.visitaGoals.periodo : "";
        this.visitaGoals.type ? visits.tipo = this.visitaGoals.type : "";
        this.visitaGoals.minimum_time ? visits.tempo_minimo = this.visitaGoals.minimum_time : "";
        this.visitaGoals.questionnaires ? visits.questionario = this.visitaGoals.questionnaires : "" ;

        visit.push(visits);

        const payload = {
          id_assistencia: this.id,
          qualidade: quantity,
          visita: visit,
          data: moment(this.monthRef.value).startOf("month").format('YYYY-MM-DD'),
          data_fim: moment(this.month_endRef.value_fim).endOf("month").format('YYYY-MM-DD'),
          protocolo: this.protocolo,
          descricao: this.descricao,
          tipo: 'PMLS',
          metas: metas,
          pontuacao: pontuacaoQualidade,
          questionario: this.questionnairesGoals,
          laticinios: this.dairySelected,
          grupos: this.groupSelected,
        };

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/salvaMetas`,
          payload
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.id = data.id;
        this.$emit('input', data.id);
        // this.loadPlanning();
        this.$snotify.success("Planejamento salvo com sucesso", "Sucesso");

      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao salvar planejamento!", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
