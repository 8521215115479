<template>
  <v-card>
    <v-card-title>
      Infraestrutura
    </v-card-title>
    <v-card-text>
      <v-data-table
        class="infrastructure-table"
        :items="infrastructureItems"
        dense
        hide-default-footer
        :items-per-page="infrastructureItems.length"
        :headers="[
          { text: 'Elementos', value: 'element' },
          { text: 'Situação', value: 'situation', width: 230 },
        ]"
      >
        <template #[`item.element`]="{ item }">
          <v-chip
            color="#E6E6FA"
            label
            small
            class="mt-1"
          >
            <span class="element-name">{{ item.element }}</span>
          </v-chip>
        </template>
        <template #[`item.situation`]="{ item }">
          <v-text-field
            v-if="item.situation.type === 'string'"
            v-model="item.situation.value"
            class="mb-2"
            :placeholder="item.element"
            dense
            hide-details
            @change="onChangeSituation(item)"
          />

          <v-autocomplete
            v-else
            v-model="item.situation.value"
            class="mb-2"
            :placeholder="item.element"
            :items="item.situation.items"
            dense
            hide-details
            clearable
            @change="onChangeSituation(item)"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
 .infrastructure-table{
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
     table {
      thead > tr {
        span {
          font-family: Roboto, Arial, sans-serif;
          font-size: 0.9rem;
          font-weight: 700;
        }
        background: rgba(226, 225, 225, 0.6);
      }
    }

   .element-name {
    font-family: Roboto, Arial, sans-serif !important;
    font-size: 0.9rem !important;
    font-weight: 500;
  }
 }
</style>
<script>
import _ from "lodash";

const inFraDictionary = {
  possui_internet: "hasInternet",
  transferidor_leite: "milkProtractor",
  tipo_caminhao: "truckAccess",
  numero_conjuntos: "numberOfSets",
  qualidade_energia_eletrica: "electricity",
  qualidade_acesso_propiedade: "propertyAccess",
  mao_de_obra: "labor",
  destinacao_carcacas: "destinationOfCarcasses",
  origem_da_agua: "originOfWater",
  capacidade_resfriador: "coolerCapacity",
  tanque_resfriador: "coolerTank",
  secagem_tetos: "teatDrying",
  destinacao_dejetos: "destinationOfWaste",
  curral_espera: "waitingCorral",
  ordenha: "milking",
  acondicionamento: "typeOfAnimalPackaging",
  sistema_utilizado: "systemUsed",
  analise_solo: "soilAnalysis",
  irrigacao: "irrigationModel",
}

export default {
  props: {
    diagnostic: {
      type: Object,
    },
  },
  data() {
    return {
      infrastructureItems: [
        {
          param: "hasInternet",
          element: "Possui Internet",
          situation: {
            value: null,
            type: "selection",
            items: ["Sim", "Não"],
          },
        },
        {
          param: "milkProtractor",
          element: "Transferidor de leite",
          situation: {
            value: null,
            type: "selection",
            items: ["Possui", "Não possui"],
          },
        },
        {
          param: "truckAccess",
          element: "Acesso para caminhão",
          situation: {
            value: null,
            type: "selection",
            items: ['Truck', 'Toco'],
          },
        },
        {
          param: "numberOfSets",
          element: "Número de conjuntos",
          situation: {
            value: null,
            type: "string",
          },
        },
        {
          param: "electricity",
          element: "Energia elétrica",
          situation: {
            value: null,
            type: "selection",
            items: ["Boa", "Regular", "Ruim"],
          },
        },
        {
          param: "propertyAccess",
          element: "Acesso a propriedade",
          situation: {
            value: null,
            type: "selection",
            items: ["Bom", "Ruim"],
          },
        },
        {
          param: "labor",
          element: "Mão de obra",
          situation: {
            value: null,
            type: "selection",
            items: ["Familiar", "Contratada", "Família e contratada"],
          },
        },
        {
          param: "destinationOfCarcasses",
          element: "Destino das carcaças",
          situation: {
            value: null,
            type: "selection",
            items: ["Ao tempo", "Enterra", "Queima todos", "Queima alguns"],
          },
        },
        {
          param: "originOfWater",
          element: "Origem da água",
          situation: {
            value: null,
            type: "selection",
            items: ["Mina ou nascente", "Poço comum", "Poço semi-artesiano", "Poço artesiano", 'Rio', "Saneamento publico"],
          },
        },
        {
          param: "coolerCapacity",
          element: "Capacidade do resfriador",
          situation: {
            value: null,
            type: "string",
          },
        },
        {
          param: "coolerTank",
          element: "Tanque resfriador",
          situation: {
            value: null,
            type: "selection",
            items: ['Não possui', 'Tarro', 'Imersão', 'Expansão'],
          },
        },
        {
          param: "teatDrying",
          element: "Método de secagem de tetos",
          situation: {
            value: null,
            type: "selection",
            items: ["Não realiza", 'Pano', 'Papel toalha'],
          },
        },
        {
          param: "destinationOfWaste",
          element: "Destino dos Dejetos",
          situation: {
            value: null,
            type: "selection",
            items: ['Não realiza', 'Fossa septica', 'Amontoa', 'Esterqueira'],
          },
        },
        {
          param: "waitingCorral",
          element: "Curral de espera",
          situation: {
            value: null,
            type: "selection",
            items: ['Não possui', 'Somente cerca', 'Cercado e coberto', 'Completo'],
          },
        },
        {
          param: "milking",
          element: "Ordenha",
          situation: {
            value: null,
            type: "selection",
            items: ['Não possui', 'Manual', 'Balde ao pé', 'Canalizada por transferidor', 'Espinha de peixe', 'Em linha indiana (TANDEM)', 'Lado a lado', 'Carrocel', 'Robô'],
          },
        },
        {
          param: "typeOfAnimalPackaging",
          element: "Tipo de acondicionamento dos animais",
          situation: {
            value: null,
            type: "selection",
            items: ['A pasto', 'A pasto com pista para trato', 'Semi confinado', 'Free stall', 'Compost barn'],
          },
        },
        {
          param: "systemUsed",
          element: "Sistema utilizado",
          situation: {
            value: null,
            type: "selection",
            items: ['Não possui', 'Intensivo', 'Semi-intensivo', 'Extensivo'],
          },
        },
        {
          param: "soilAnalysis",
          element: "Análise de solo",
          situation: {
            value: null,
            type: "selection",
            items: ['Não realiza', 'Uma vez por ano', 'A cada 2 anos', 'Somente em áreas de cultura'],
          },
        },
        {
          param: "irrigationModel",
          element: "Modelo de irrigação",
          situation: {
            value: null,
            type: "selection",
            items: ['Pivo', 'Asperção', 'Não possui'],
          },
        },
      ],
    };
  },
  mounted() {
    this.initStep();
  },
  methods: {
    /**
       * Método de inicialização dos dados do step
       */
    initStep() {
      _.forEach(inFraDictionary, (localName, bdname) => {
            
        // busca a posição no objeto local
        let infraIndex = _.findIndex(this.infrastructureItems, h => h.param === localName);
            
        const situationValue = _.lowerCase(_.get(this.diagnostic, bdname));

        _.set(this.infrastructureItems, `${infraIndex}.situation.value`, _.upperFirst(situationValue));
      });
    },
    /**
       * @void
       * Trata modificação da situação e já emite de update
       * @param {Object} infra
       */
    onChangeSituation(infra) {
      // Busca o nome da categoria semelhante ao BD
      const bdName = _.findKey(inFraDictionary, i => i === infra.param);
        
      // Set com a nova situação
      _.set(this.diagnostic, bdName, infra.situation.value);
        
      // Atualiza a PROP
      this.$emit("update:diagnostic", this.diagnostic);
    }
  }
};
</script>
