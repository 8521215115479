<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        <span class="text-h6">Apontamentos de Ordem de Produção nº {{ order.code }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="formRef"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
              class="pt-2"
            >
              <warehouse-select
                v-model="warehouseId"
                label="Depósito"
                outlined
                dense
                hide-details
                auto-select-default
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(data, idx) of items"
            :key="idx"
            style="border-bottom: 1px #eee solid;"
          >
            <v-col class="pt-1 pb-0">
              <v-text-field
                :value="data.item?.description"
                label="Produto"
                disabled
              />
            </v-col>
            <v-col
              v-if="hasPlannedQuantityAccess && data.plannedQuantity > 0"
              class="pt-1 pb-0"
              cols="6"
              sm="3"
              md
            >
              <masked-text-field
                :value="data.plannedQuantity"
                label="Qtde. Planejada"
                unmask
                :mask="masks.float"
                :suffix="data.item?.measurement"
                disabled
              />
            </v-col>
            <v-col
              v-if="hasInputsAccess"
              class="pt-1 pb-0"
              cols="6"
              sm="3"
              md
            >
              <masked-text-field
                v-model="data.accomplishedQuantity"
                label="Qtde. Realizada"
                persistent-hint
                unmask
                :mask="masks.float"
                :suffix="data.item?.measurement"
                disabled
              />
            </v-col>
            <v-col
              v-if="data.showQueijomatic && !data.item?.scaleItem"
              class="pt-1 pb-0"
              cols="6"
              sm="3"
              md
            >
              <v-select
                v-model="data.tankId"
                :items="tinas"
                label="Queijomatic *"
                item-value="id_tina"
                item-text="descricao"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              class="pt-1 pb-0"
            >
              <masked-text-field
                v-model="data.writeOffQuantity"
                label="Qtde. *"
                persistent-hint
                unmask
                :mask="masks.float"
                inputmode="numeric"
                :suffix="data.item?.measurement"
                :rules="[
                  v => !!v || 'Campo obrigatório!',
                  validateRange(data),
                ]"
                validate-on-blur
              />
            </v-col>
            <v-col
              v-if="items.length > 1"
              class="pl-0 d-flex flex-column justify-center"
              style="max-width: 30px;"
            >
              <v-btn
                icon
                @click.stop="removeComponent(idx)"
              >
                <v-icon>cancel</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <item-lot-dialog
      v-model="lotDialog.show"
      type="SAIDA"
      :items="lotDialog.items"
      :warehouse-id="warehouseId"
      @save="onLotSaved"
    />
  </v-dialog>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { usePermissions } from '@/Support/Composables/permissions.js'

import ItemLotDialog from '@/Domains/Registrations/Item/Components/ItemLotDialog.vue'
import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import { useRangeValidator } from '@/Support/Composables/validator.js'

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js'

const { progressBar, notify } = useUtils()

const { hasPermission } = usePermissions()

const { validateRule } = useRangeValidator()

// eslint-disable-next-line
const emit = defineEmits(['save'])

const dialog = ref(false)
const warehouseId = ref(null)
const order = ref({})
const tinas = ref([])
const items = ref([])

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
}

const hasPlannedQuantityAccess = computed(() => hasPermission('ordem-prod-qtde-planejada'))
const hasInputsAccess = computed(() => hasPermission('ordem-prod-inputs-apontamento'))

const show = (data) => {
  dialog.value = true
  order.value = data.order
  items.value = data.items
  tinas.value = data.tinas || []
}

const close = () => {
  dialog.value = false
}

const removeComponent = (idx) => items.value.splice(idx, 1)

const lotDialog =  reactive({
  show: false,
  items: [],
})

const openLotDialog = (lots) => {
  lotDialog.show = true
  lotDialog.items = lots.map(item => ({
    id_item: item.item.id,
    quantidade: item.writeOffQuantity,
  }))
}

const onLotSaved = (lots) => {
  for (let lot of lots) {
    const idx = items.value.findIndex(item => item.item.id === lot.id_item)
    items.value[idx].lots = lot.lotes
  }
  save(false)
}

const formRef = ref()

const save = async (showLotDialog = true) => {
  try {
    if (!await formRef.value?.validate()) {
      return
    }

    const itemsWithLotControl = items.value.filter(item => item.item.requiresLot)

    if (showLotDialog && itemsWithLotControl.length > 0) {
      openLotDialog(itemsWithLotControl)
      return
    }

    progressBar.saving()

    const payload = {
      id_deposito: warehouseId.value,
      itens: items.value.map((item) => ({
        id_ordem_producao_item: item.id || null,
        quantidade: item.writeOffQuantity,
        id_tina: item.tankId,
        lotes: item.lots,
      })),
    }

    await api.storeWriteOff(payload)

    notify.success('Baixa efetuada com sucesso', 'Sucesso')

    emit('save')
    close()
  } catch (e) {
    notify.error('Erro ao efetuar baixa', 'Atenção')
    console.warn(e)
  } finally {
    progressBar.hide()
  }
}

const validateRange = (item) => {
  const value = item.writeOffQuantity
  const rule = item.range

  return validateRule(value, rule)
}

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
