import Vue from "vue";
import VueRouter from 'vue-router'

import store from "./vuex.js";

import LocalLogin from "@/Units/Auth/LocalLogin.vue";
import MainLayout from "@/MainLayout.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/local/login',
      component: LocalLogin
    },
    {
      path: '/',
      component: MainLayout
    }
  ],
});

const SITE_TITLE = 'MilkUp';

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next({ path: '/', query: { redirect: to.fullPath } });
  }
  else {
    document.title = SITE_TITLE + (to.meta.title ? ` - ${to.meta.title}` : '');

    if (to.meta.isDairyRequired && !store.state.settings.laticinio) {
      if (!from.name) {
        next({ name: 'Index' });
      }
      return;
    }
    next()
  }
});

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transitionName = (toDepth < fromDepth || to.path === '/index') ? 'slide-right' : 'slide-left'
})

export default router;
