<template>
  <div class="discounts-tax">
    <v-row no-gutters>
      <v-col
        class="py-2"
        cols="12"
      >
        <card-title-settings class="mb-3">
          Taxas
        </card-title-settings>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pa-3">
            <v-form ref="form" lazy-validation>
              <v-row
                align="center"
                justify="space-around"
              >
                <v-col cols="2">
                  <v-card
                    color="transparent"
                    dark
                  >
                    <v-card-title class="text-subtitle-2 py-2">
                      Funrural
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            small
                            class="ml-1"
                            v-on="on"
                          >
                            <v-icon>report_gmailerrorred</v-icon>
                          </v-btn>
                        </template>
                        Funrural
                        <b>(INSS+SENAR+RAT)</b>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text class="pb-0">
                      <v-row>
                        <v-col cols="12">
                          <masked-input
                            v-model="funruralPhysicalPerson"
                            label="Pessoa fisica (%)"
                            type="number"
                            :mask="masktaxes"
                            :hide-details="true"
                            :disabled="true"
                            :rules="[maxLength(5)]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <masked-input
                            v-model="funruraljuridicalPerson"
                            label="Pessoa Juridica (%)"
                            type="number"
                            :mask="masktaxes"
                            :disabled="true"
                            :hide-details="true"
                            :rules="[maxLength(5)]"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  class="py-1 col-tax"
                >
                  <div class="overline text-left">
                    Senar
                  </div>
                  <v-divider class="pb-2" />
                  <masked-input
                    v-model="funrural.senar.physicalPerson"
                    label="Pessoa fisica (%)"
                    type="number"
                    :mask="masktaxes"
                    :rules="[maxLength(5)]"
                  />
                  <masked-input
                    v-model="funrural.senar.juridicalPerson"
                    label="Pessoa Juridica (%)"
                    type="number"
                    :mask="masktaxes"
                    :rules="[maxLength(5)]"
                  />
                </v-col>
                <v-col
                  class="col-tax"
                >
                  <div class="overline text-left">
                    INSS
                  </div>
                  <v-divider class="pb-2" />
                  <masked-input
                    v-model="funrural.inss.physicalPerson"
                    label="Pessoa fisica (%)"
                    type="number"
                    :mask="masktaxes"
                    :rules="[maxLength(5)]"
                  />
                  <masked-input
                    v-model="funrural.inss.juridicalPerson"
                    label="Pessoa Juridica (%)"
                    type="number"
                    :mask="masktaxes"
                    :rules="[maxLength(5)]"
                  />
                </v-col>
                <v-col
                  class="col-tax"
                >
                  <div class="overline text-left">
                    Rat
                  </div>
                  <v-divider class="pb-2" />
                  <masked-input
                    v-model="funrural.rat.physicalPerson"
                    label="Pessoa fisica (%)"
                    type="number"
                    :mask="masktaxes"
                    :rules="[maxLength(5)]"
                  />
                  <masked-input
                    v-model="funrural.rat.juridicalPerson"
                    label="Pessoa Juridica (%)"
                    type="number"
                    :mask="masktaxes"
                    :rules="[maxLength(5)]"
                  />
                </v-col>
                <v-col>
                  <div class="overline text-left">
                    Fundesa
                  </div>
                  <v-divider class="pb-2" />
                  <masked-input
                    v-model="fundesa"
                    label="Aliquota (L)"
                    type="number"
                    :mask="maskFundesa"
                    style="width:200px !important"
                    class="mb-11 pb-7"
                    :rules="[maxLength(10)]"
                  />
                </v-col>
                <v-col>
                  <div class="overline text-left">
                    Administrativa
                  </div>
                  <v-divider class="pb-2" />
                  <masked-input
                    v-model="administrativa"
                    label="Aliquota (%)"
                    type="number"
                    :mask="maskFundesa"
                    style="width:200px !important"
                    class="mb-11 pb-7"
                    :rules="[maxLength(10)]"
                  />
                </v-col>
                <v-col>
                  <div class="overline text-left">
                    Frete
                  </div>
                  <v-divider class="pb-2" />
                  <masked-input
                    v-model="frete"
                    label="Preço (L)"
                    type="number"
                    :mask="masktaxes"
                    style="width:200px !important"
                    class="mb-11 pb-7"
                    :rules="[maxLength(5)]"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="onTaxSave()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>
  </div>
</template>

<style lang="scss">
.discounts-tax {
  .col-tax{
      border-right: 1px solid #cccc
  }
}
</style>

<script>
import _ from "lodash";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

export default {
  components: {
    MaskedInput,
    CardTitleSettings,
  },
  data() {
    return {

      administrativa: 0.0,
      frete: 0.0,
      fundesa: 0.00063,
      funrural: {
        rat: {
          physicalPerson: 0.1,
          juridicalPerson: 0.1,
        },
        senar: {
          physicalPerson: 0.2,
          juridicalPerson: 0.25,
        },
        inss: {
          physicalPerson: 1.2,
          juridicalPerson: 1.7,
        },
      },

      masktaxes: {
        mask: Number,
        scale: 2,
        maxlength: 5,
        radix: ".",
        mapToRadix: [","],
      },

      maskFundesa: {
        mask: Number,
        scale: 8,
        maxlength: 10,
        radix: ".",
        mapToRadix: [","],
      },
    };
  },
  computed: {
    funruralPhysicalPerson() {
      return  _.reduce(this.funrural, function(acc, n) {
        return  acc + parseFloat(n.physicalPerson);
      }, 0).toFixed(2);
    },
    funruraljuridicalPerson() {
      return  _.reduce(this.funrural, function(acc, n) {

        return acc + parseFloat(n.juridicalPerson);
      }, 0).toFixed(2);
    }
  },

  mounted() {
    this.loadDiscountsFees();
  },

  methods: {
    async onTaxSave() {
      try {
        if (!(await this.$refs.form.validate())) {
          return;
        }

        const { data }  = await  this.$axios.post(`/configuracao/salvaConfiguracaoTaxasDescontos`, {
          fundesa: this.fundesa,
          administrativa: this.administrativa,
          frete: this.frete,
          senar_pessoa_juridica: this.funrural.senar.juridicalPerson,
          senar_pessoa_fisica: this.funrural.senar.physicalPerson,
          rat_pessoa_juridica: this.funrural.rat.juridicalPerson,
          rat_pessoa_fisica: this.funrural.rat.physicalPerson,
          inss_pessoa_juridica: this.funrural.inss.juridicalPerson,
          inss_pessoa_fisica: this.funrural.inss.physicalPerson,
          funrural_pessoa_juridica: this.funruraljuridicalPerson,
          funrural_pessoa_fisica: this.funruralPhysicalPerson,
        });

        if (!data.codigo) {
          throw data;
        }

        this.$snotify.success("Taxas salvas com sucesso", "Sucesso");
        this.updateStoreSetting();
      } catch (error) {
        console.log(error);
        this.$snotify.error("Erro ao salvar Taxas".error, "Atenção");
      }
    },

    async updateStoreSetting() {
      try {
        const { data } = await this.$axios.get(`/configuracao/listaConfiguracoesLaticinio`);
        return this.$store.commit("setGeneralSettings", data);
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as configurações!", "Atenção");
        console.error(error);
      }
    },

    loadDiscountsFees() {
      const general = this.$store.state.settings.gerais;

      this.fundesa = _.get(general, "fundesa_aliquota", 0.00063);
      this.administrativa = _.get(general, "administrativa_aliquota", 0.0);
      this.frete = _.get(general, "frete_aliquota", 0.0);

      //rat
      this.funrural.rat.physicalPerson = parseFloat(_.get(general, "rat_pessoa_fisica_aliquota", 0.1));
      this.funrural.rat.juridicalPerson = parseFloat(_.get(general, "rat_pessoa_juridica_aliquota", 0.1));
      //senar
      this.funrural.senar.physicalPerson = parseFloat(_.get(general, "senar_pessoa_fisica_aliquota", 0.2));
      this.funrural.senar.juridicalPerson = parseFloat(_.get(general, "senar_pessoa_juridica_aliquota", 0.25));
      //inss
      this.funrural.inss.physicalPerson = parseFloat(_.get(general, "inss_pessoa_fisica_aliquota", 1.2));
      this.funrural.inss.juridicalPerson = parseFloat(_.get(general, "inss_pessoa_juridica_aliquota", 1.7));
    },

    maxLength(size) {
      return v => !v || v.trim().length <= size || 'Valor inválido';
    }
  },
};
</script>
