<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Crioscopia
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-spacer />
        <v-col
          cols="12"
          md="4"
          class="pt-0"
        >
          <v-text-field
            v-model="filters.search"
            append-icon="search"
            label="Busca"
            single-line
            hide-details
            filled
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="cryoscopys"
        :search="filters.search"
        class="elevation-1"
        @click:row="showDialog"
      >
        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasEditCryoscopyAccess"
                @click="showDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    edit
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasDeleteCryoscopyAccess"
                @click="excludeMilkCan(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    delete
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasCreateCryoscopyAccess"
        fab
        color="purple"
        @click="showDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Cadastrar Crioscopia
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <cryoscopy-dialog
      v-model="edit"
      :edit-id.sync="editId"
      :cryoscopys="cryoscopys"
      @save="loadCryoscopys"
    />

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import CryoscopyDialog from "@/Domains/Registrations/Cryoscopy/Components/CryoscopyDialog.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    CryoscopyDialog
  },

  mixins: [ReportMixin],

  data() {
    
    return {
      // Loaders
      loading: true,

      // Dados brutos do backend
      cryoscopys: [],

      // Filtros da tabela
      filters: {
        search: "",
      },

      edit: false,
      editId: null,

    };
  },

  computed: {
    /**
     * Exibe as Crioscopias filtradas
     */
    filteredCryoscopy() {
      if (_.isEmpty(this.filters.search)) {
        return this.cryoscopys;
      }

      return this.cryoscopys.filter(cryoscopy => {
        return (cryoscopy.valor_crioscopia || "").toUpperCase().includes((this.filters.search || "").toUpperCase());
      });
    },

    headers() {
      if (this.hasEditCryoscopyAccess || this.hasDeleteCryoscopyAccess) {
        return [
          { text: 'Crioscopia', value: 'valor_crioscopia', align: 'start' },
          { text: '(%) Água', value: 'porcentagem_agua', align: 'start' },
          { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' }
        ];
      }
      return [
        { text: 'Crioscopia', value: 'valor_crioscopia', align: 'start' },
        { text: '(%) Água', value: 'porcentagem_agua', align: 'start' },
      ]
    },
    
    //Definindo as Permissões de tela

    //Pega os dados de permissão do usúario.
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    //Se for admin ou ter o cargo Supervisor(Milkup) libera todos os acessos.
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    //Permissão para poder CRIAR uma Crioscopia.
    hasCreateCryoscopyAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "create-cryoscopy" && o.tipo === "COMPONENTE");
    },

    //Permissão para poder EDITAR uma Crioscopia.
    hasEditCryoscopyAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "edit-cryoscopy" && o.tipo === "COMPONENTE");
    },
    
    //Permissão para poder DELETAR uma Crioscopia.
    hasDeleteCryoscopyAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "delete-cryoscopy" && o.tipo === "COMPONENTE");
    },
  },

  async mounted() {
    await this.loadCryoscopys();
  },

  methods: {
    async loadCryoscopys() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get('registrations/cryoscopy');

        this.cryoscopys = data.map(item => {
          return {
            id: item.id_crioscopia,
            valor_crioscopia: item.valor_crioscopia,
            porcentagem_agua: item.porcentagem_agua,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Crioscopias!", "Atenção");

        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Mostra o dialog para a criação/edição de uma nova crioscopia
     */
    showDialog({ id }) {
      if (!this.hasEditCryoscopyAccess) {
        return;
      }

      this.edit = true;
      this.editId = id;
    },

    async excludeMilkCan(cryoscopy) {
      
      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir a crioscopia ${cryoscopy.valor_crioscopia} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.loading = true;

        await this.$axios.delete(`registrations/cryoscopy/${cryoscopy.id}`);
       
        this.$snotify.success("Crioscopia excluída com sucesso", "Sucesso");
        return await this.loadCryoscopys();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Crioscopia", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    getReportJson() {
      return this.cryoscopys.map(cryoscopy => ({
        Crioscopia: cryoscopy.valor_crioscopia,
        "(%) Água": cryoscopy.porcentagem_agua,
      }));
    },

    exportExcel() {
      this.exportToFile({ report: this.getReportJson(), title: "Crioscopia" });
    },
  }
}
</script>