<template>
  <v-select
    v-model="time"
    :items="range"
    :label="label"
    menu-props="auto"
    style="max-width:85px"
    @change="onSelect"
  />
</template>

<script>
import moment from "moment-timezone";

export default {
  name: 'time-select',

  props: {
    value: Date,
    label: String,
    stepInMin: {
      type: Number,
      default: 15,
    },
    minTime: {
      type: Date,
    },
    maxTime: {
      type: Date,
    }
  },

  data () {
    return {
      show: false,
      date: null,
      time: null,
      range: []
    }
  },

  watch: {
    value() {
      return this.$nextTick(() => {
        this.setDateTime();
      });
    },
    minTime() {
      return this.$nextTick(() => {
        this.setDateTime();
      });
    },
    maxTime() {
      return this.$nextTick(() => {
        this.setDateTime();
      });
    }
  },

  created() {
    this.setDateTime();
  },

  methods: {
    setDateTime() {
      const datetime = moment(this.value);
      this.date = datetime.format('YYYY-MM-DD');
      this.time = datetime.format('HH:mm');
      this.setStartTimeSelect();
    },
    onSelect() {
      this.show = false;
      this.$emit("input", new Date(`${this.date}T${this.time}:00`));
    },
    setStartTimeSelect() {
      let min = this.getMin();
      let max = this.getMax();

      let range = [];
      const step = this.stepInMin * 60 * 1000;
      while (max > min) {
        range.push(moment(min).format('HH:mm'));
        min = new Date(min.getTime() + step);
      }
      this.range = range;
    },

    getMin() {
      return this.minTime && moment(this.minTime).isSame(this.value, 'day')
        ? moment(this.minTime).add(this.stepInMin, 'm').toDate()
        : moment(this.value).startOf('day').toDate();
    },

    getMax() {
      return this.maxTime && moment(this.maxTime).isSame(this.value, 'day')
        ? this.maxTime
        : moment(this.value).endOf('day').toDate();
    },
    
  }
}
</script>
