<template>
  <v-dialog
    v-model="show"
    width="30%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Transferência de Estoque</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <v-autocomplete
                  v-model="form.from"
                  :items="stockTypes"
                  label="De *"
                  item-text="type"
                  item-value="id"
                  :rules="isRequired"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-autocomplete
                  v-model="form.to"
                  :items="stockTypes"
                  label="Para *"
                  item-text="type"
                  item-value="id"
                  :rules="isRequired"
                />
              </v-col>
              <v-col
                v-if="isConsigned"
                cols="12"
              >
                <person-autocomplete-filter
                  v-model="form.people"
                  type="TECHNICAL"
                  prepend-inner-icon="person"
                  hide-details
                  label="Técnico / Veterinário / Agronomo"
                  :filled=false
                  :disabled="form.forAll"
                  :rules="isRequired"
                  @change="clearStockFields"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="form.group"
                  label="Grupo"
                  placeholder="Selecione um Grupo"
                  :items="groupList"
                  item-text="group"
                  item-value="id"
                  hide-details
                  :rules="isRequired"
                  @change="defaultLoading"
                />
              </v-col>
              <v-col
                cols="8"
                >
                <v-autocomplete
                  v-model="form.product"
                  label="Produto"
                  placeholder="Selecione um Produto"
                  :items="productsList"
                  item-text="product"
                  item-value="id"
                  hide-details
                  :rules="isRequired"
                  @change="loadStock"
                />
              </v-col>
              <v-col
                cols="4"
              >
                <v-autocomplete
                  v-model="form.unit"
                  label="Unidade Medida"
                  placeholder="Selecione uma Unidade de Medida"
                  :items="unitList"
                  hide-details
                  :rules="isRequired"
                  @change="selectedUnit"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="form.inStock"
                  label="Quantidade em Estoque"
                  type="number"
                  readonly
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="form.quantity"
                  label="Quantidade à Transferir"
                  type="number"
                  :rules="isRequired"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
export default {
  components: {
    PersonAutocompleteFilter,
  },

  props: {
    value: Boolean,
    editId: String,
    type: String,
    groupList: Array,
  },

  data() {
    return {
      form: {
        from: null,
        to: null,
        people: null,
        product: null,
        quantity: 0,
        inStock: 0,
        unit: null,
        unitPrice: 0,
      },

      stockOut: [],

      productsList: [],
      unitList: [],

      stockTypes: [
        {
          id: 'E',
          type: 'Empresa'
        },
        {
          id: 'C',
          type: 'Consignado'
        },
      ],

      isRequired: [(v) => !!v || 'Este campo é obrigatório'],
      loading: false,
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    isConsigned() {
      return (this.form.from === 'C' || this.form.to === 'C');
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          from: null,
          to: null,
          people: null,
          quantity: 0,
          inStock: 0,
          unit: null,
        };

      }
    }
  },

  methods: {
    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        if (parseFloat(this.form.inStock) < parseFloat(this.form.quantity)) {
          this.$snotify.error("A quantidade transferida é maior do que a quantidade em estoque", "Atenção");
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          from: this.form.from,
          to: this.form.to,
          people: this.form.people.id ?? '',
          quantity: this.form.quantity,
          product: this.form.product,
          unit: this.form.unit,
          unitPrice: this.form.unitPrice,
        }

        const { data } = await this.$axios.post('registrations/stockTransfer', payload);

        if (!data) {
          this.$snotify.error("Erro ao realizar a transferência", "Atenção");
          return;
        }

        this.$snotify.success("Transferencia realizada com sucesso", "Sucesso");
        this.close();
        this.$emit('save');

      } catch (e) {
        this.$snotify.error("Erro ao realizar a transferência", "Atenção");
        console.warn(e);
    } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.show = false;
    },

    async defaultLoading() {
      this.getProductsByGroups();
    },

    async getProductsByGroups() {
      try {
        this.$root.$progressBar.loading();

        this.form.inStock = 0;
        this.form.quantity = 0;
        this.form.product = null;

        const { data } = await this.$axios.get('registrations/product', { params: {
            id_grupo_produto: this.form.group,
          }
        });

        this.productsList = data.map(item => {
          return {
            id: item.id_produto,
            product: item.nome,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");
        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async selectedUnit() {
      try {
          this.$root.$progressBar.loading();

          const stock = this.stockOut.find(objeto => objeto.unidade_medida === this.form.unit);
          const quantity = stock.quantidade ? stock.quantidade : 0;

          this.form.inStock = quantity;
          this.form.unitPrice = stock.valor_unit;
          return;

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar estoque!", "Atenção");
        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async clearStockFields() {
        this.form.group = null;
        this.form.product = null;
        this.form.inStock = 0;
        this.form.quantity = 0;
        this.form.unit = null;
    },

    async loadStock() {
      try {
        this.$root.$progressBar.loading();

        if (this.form.from === 'E') {
          const { data } = await this.$axios.get('registrations/stockInCompany', { params: {
              id_grupo_produto: this.form.group,
              id_produto: this.form.product,
            }
          });

          this.unitList = data.map(objeto => objeto.unidade_medida);

          this.stockOut = data;
          return;
        }

        if (this.form.from === 'C') {
          const { data } = await this.$axios.get('registrations/stockOnConsignment', { params: {
              id_grupo_produto: this.form.group,
              id_produto: this.form.product,
              people: this.form.people,
            }
          });

          this.unitList = data.map(objeto => objeto.unidade_medida);

          this.stockOut = data;
          return;
        }
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar estoque!", "Atenção");
        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
  }

}
</script>
