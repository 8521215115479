<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        local_shipping
      </v-icon>
      <span class="title font-weight-light">Diferença de volume por Freteiro</span>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            small
            right
            color="rgba(255, 255, 255, 0.7)"
            v-on="on"
          >
            info
          </v-icon>
        </template>
        Soma total da diferença levando em consideração a tolerância
      </v-tooltip>
    </v-card-title>

    <v-chart
      :options="options"
      :style="{width: '100%', height: `${height}px !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    graphColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      graph: [],
    }
  },

  computed: {
    height() {
      return 70 + (this.graph.length * 40)
    },
    options() {
      const label = {
        show: true,
        lineHeight: 15,
        height: 15,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'right',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      };
      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '0%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.graph.map(o => o.nome_prestador),
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Sobras',
            type: 'bar',
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.graph.map(o => o.sobras),
            label
          },
          {
            name: 'Faltas',
            type: 'bar',
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.graph.map(o => o.faltas),
            label
          },
          {
            name: 'Diferença',
            type: 'bar',
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.graph.map(o => o.total_diferenca),
            label
          }
        ]
      }
    },
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/descargaColeta/diferencaPorFreteiro`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.graph = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de diferença de volume por freteiro!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
