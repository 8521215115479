<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Programa Mais Leite Saudável - PMLS
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
        @change="onTabChange"
      >
        <v-tab href="#producers">
          Produtores
        </v-tab>
        <v-tab href="#groups">
          Grupos
        </v-tab>
        <v-tab href="#history">
          Configuração
        </v-tab>
        <v-tab
          href="#planning"
          :disabled="!planning.selected"
        >
          <v-icon>settings</v-icon>
        </v-tab>
        <v-tab-item value="producers">
          <producers
            ref="producers"
            aba="producers"
          />
        </v-tab-item>

        <v-tab-item value="groups">
          <producer-groups />
        </v-tab-item>

        <v-tab-item value="history">
          <list-goals
            ref="history"
            aba="history"
            project="PMLS"
            @newPlanning="onNewPlanning"
            @selectPlanning="onSelectPlanning"
          />
        </v-tab-item>

        <v-tab-item value="planning">
          <goals-PMLS
            ref="planning"
            v-model="planning.id"
            aba="planning"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.project-pmls {

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import Producers from '@/Domains/Projects/PMLS/Views/Producers.vue';
import ListGoals from "@/Domains/Projects/ListGoals.vue";
import ProducerGroups from "@/Domains/Projects/PMLS/Views/ProducerGroups.vue";
import GoalsPMLS from '@/Domains/Projects/PMLS/Views/GoalsPMLS.vue';

export default {

  components: {
    Producers,
    ListGoals,
    GoalsPMLS,
    ProducerGroups,
  },

  data() {
    return {
      tab: null,

      planning: {
        selected: false,
        id: null
      },
    };
  },

  methods: {
    onTabChange() {
      if (this.tab == 'history' && this.$refs.history) {
        this.$refs.history.loadHistory()
      }
      this.planning.selected = false;
      this.planning.id = null;
    },

    onNewPlanning() {
      this.planning.selected = true;
      this.planning.id = null;
      this.tab = 'planning';
    },

    onSelectPlanning(id) {
      this.planning.selected = true;
      this.planning.id = id;
      this.tab = 'planning';
    },

  },
};
</script>
