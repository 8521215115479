<template>
  <v-dialog
    v-model="show"
    scrollable
    width="800"
    persistent
  >
    <v-card>
      <v-card-title>
        Exceções do IPI
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <exceptions-form
            :data.sync="form"
          />

          <span class="text-h6 d-block mt-4 black--text">Imposto</span>

          <v-divider class="mb-4" />

          <ipi-form
            :data.sync="form.ipi"
            :in-out="inOut"
          />
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ExceptionsForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/ExceptionsForm.vue';
import IpiForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/IpiForm.vue';

import { ipi } from '@/Domains/Settings/Views/Fiscal/Components/Operation/Services/FiscalOperationDefault.js'

export default {

  components: {
    ExceptionsForm,
    IpiForm,
  },

  props: {
    value: Boolean,
    inOut: {
      type: String,
      default: 'SAIDA',
      validator: (value) => ['ENTRADA', 'SAIDA'].includes(value)
    },
    data: Object,
  },

  data() {
    return {
      form: {
        estados: [],
        produtos: [],
        ncms: [],

        ipi: {},
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.reset();

        this.form = {
          ...this.form,
          ...this.data
        };
      }
    }
  },

  methods: {

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.show = false;
        this.$emit('save', this.form);
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      }
    },

    cancel() {
      this.show = false;
    },

    reset() {
      this.$refs.form && this.$refs.form.resetValidation();
      this.form = {
        estados: [],
        produtos: [],
        ncms: [],

        ipi: { ...ipi },
      };
    },
  }
}
</script>
