const QuestionnaireTypes = [
  // Metas - Atendimento
  { text: "Atendimento", value: "ATENDIMENTO", type: "visits" },

  // Metas - Visita Prospecção (Tipo principal)
  { text: "Visita Prospecção", value: "VISITA_PROSPECCAO", type: "visits" },

  // Metas de laticinio - Visita Padrão (Tipo principal)
  { text: "Meta Laticinio", value: "VISITA_PADRAO", type: "visits" },

  // Subtipos de Visita Padrão
  { text: "Suspensão", value: "VISITA_SUSPENSAO", type: "visits" },
  { text: "Veterinario", value: "VISITA_VETERINARIO", type: "visits" },
  { text: "Zootécnico", value: "VISITA_ZOO_TECNICO", type: "visits" },
  { text: "Alterações no Leite", value: "VISITA_ALTERACOES_NO_LEITE", type: "visits" },
  { text: "Acompanhamento de Ordenha", value: "VISITA_ACOMPANHAMENTO_DE_ORDENHA", type: "visits" },
  { text: "Documentos", value: "VISITA_DOCUMENTOS", type: "visits" },
  { text: "Supervisão", value: "VISITA_SUPERVISAO", type: "visits" },

  // Subtipos de Visita Padrão e Visita Prospecção
  { text: "Negociação", value: "VISITA_NEGOCIACAO", type: "visits" },
  { text: "Vender Produtos", value: "VISITA_VENDER_PRODUTOS", type: "visits" },
  { text: "Coleta de Amostra", value: "VISITA_COLETA", type: "visits" },

  // PQFL (Tipo principal)
  { text: "PQFL", value: "VISITA_PQFL", type: "visits" },

  // Subtipos de PQFL
  { text: "IN77", value: "VISITA_IN77", type: "visits" },
  { text: "IN59", value: "VISITA_IN59", type: "visits" },
  { text: "BPA", value: "VISITA_BPA", type: "visits" },
  { text: "CPP", value: "VISITA_CPP", type: "visits" },
  { text: "CCS", value: "VISITA_CCS", type: "visits" },
  { text: "Checklist Inicial", value: "VISITA_CHECKLIST_INICIAL", type: "visits" },
  { text: "Brucelose / Tuberculose", value: "VISITA_BRUCELOSE_TUBERCULOSE", type: "visits" },
  // PMLS (Tipo principal)
  { text: "PMLS", value: "VISITA_PMLS", type: "visits" },

  // Subtipos de PADRÃO, PROSPECÇÃO e PQFL
  { text: "Rotina", value: "VISITA_ROTINA", type: "visits" },

  // Outros
  { text: "Coleta", value: "COLETA", type: "others" },
  { text: "Plataforma", value: "PLATAFORMA", type: "others" },
  { text: "Visita", value: "VISITA", type: "others" },
  { text: "Outras Atividades", value: "OUTRAS_ATIVIDADES", type: "others" },
];

export default QuestionnaireTypes;
