<template>
  <div>
    <v-row>
      <v-col
        md="12"
        class="pb-12"
      >
        <v-chart
          ref="chart"
          type="bar"
          :options="Options"
          :style="{width: '100%', height: `300px !important`}"
          autoresize
        />
      </v-col>
    </v-row>

    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando metas de visita...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";

export default {
  name: "visits-graphic",

  components: {
  },

  props: {
    goal: Object,

    loading: Boolean,
  },

  data() {
    return {

    };
  },

  computed: {
    Options() {
      return this.generateGraphOptions(this.goal.visitados, this.goal.nome);
    },

  },

  mounted() {

  },

  methods: {

    generateGraphOptions(data, title ) {

      this.loading = false;

      let metaTexto = '';

      if (this.goal.emergencial === true) {
        metaTexto =   `Visitar em ${this.goal.prazoQuantidade} ${this.goal.prazoDT} produtores com ${this.goal.recorrencia} ${this.goal.nome} ${this.goal.periodo} ` ;
      } else if (this.goal.visitas === 0) {
        metaTexto =   `Visitar em ${this.goal.prazoQuantidade} ${this.goal.prazoDT} ` ;
      } else {
        metaTexto = `${this.goal.visitas} ${this.goal.tipo === 'PERCENT' ? '%' : ''} produtores ${this.goal.periodo}`;
      }

      let subtitle  =  metaTexto ;

      return {
        color: ['rgba(229, 57, 53, 0.7)',  'rgba(140, 23, 23, 1)', 'rgba(255, 255, 0, 0.7)', 'rgba(140, 23, 23, 0.5)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtext: `${subtitle}`,
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 800,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 200, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });

                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.mes)),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          },
        },
        series: [
          this.goal.emergencial != true
            ?
            {
              name: `Produtores Ativos`,
              type: 'bar',
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => Math.abs(o.produtores_ativos).toFixed(0)),
            } :
            null,
          {
            name: `Produtores Visitados`,
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => Math.abs(o.total_visita).toFixed(0)),
          },
          this.goal.periodo === 'mensal' && this.goal.emergencial != true
            ?
            {
              name: 'Meta',
              type: 'line',
              lineStyle: { width: 2 },
              emphasis: {
                focus: 'series'
              },
              label: {
                show: false,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o =>  Math.abs(o.meta).toFixed(0)),
            } :
            null,
        ]
      };
      this.loading = true;
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
