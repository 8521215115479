<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="700px"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card>
      <v-card-title class="text-h5">
        Reboque
      </v-card-title>
      <v-card-text>
        <template v-if="trailerTransfers.length > 0">
          <span class="flex-grow-1 text-h5 mt-4 black--text">Acoplados</span>
          <v-divider class="mb-4" />
          <v-data-table
            :headers="headers"
            :items="trailerTransfers"
            group-by="groupId"
            disable-pagination
            disable-sort
            disable-filtering
            hide-default-footer
            class="mb-2"
          >
            <template #[`group.header`]="{ group, items }">
              <td
                class="text-start"
                :colspan="headers.length - 1"
              >
                <b>Transferências Grupo:</b> {{ items.length }}
              </td>
              <td>
                <v-btn
                  small
                  color="error"
                  @click="onUnattachGroup(group)"
                >
                  Desacoplar
                </v-btn>
              </td>
            </template>
            <template #[`item.volume`]="{ value }">
              {{ formatNumber(value) }} L
            </template>
            <template #[`item.date`]="{ value }">
              {{ formatDate(value, 'DD/MM HH:mm') }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                small
                color="error"
                @click="onUnattach(item)"
              >
                Desacoplar
              </v-btn>
            </template>
          </v-data-table>
        </template>
        <span class="flex-grow-1 text-h5 mt-4 black--text">Disponíveis</span>
        <v-divider class="mb-4" />
        <v-data-table
          :headers="headers"
          :items="transfers"
          group-by="groupId"
          disable-pagination
          disable-sort
          disable-filtering
          hide-default-footer
        >
          <template #[`group.header`]="{ group, items }">
            <td
              class="text-start"
              :colspan="headers.length - 1"
            >
              <b>Transferências Grupo:</b> {{ items.length }}
            </td>
            <td>
              <v-btn
                small
                color="success"
                @click="onAttachGroup(group)"
              >
                Acoplar
              </v-btn>
            </td>
          </template>
          <template #[`item.volume`]="{ value }">
            {{ formatNumber(value) }} L
          </template>
          <template #[`item.date`]="{ value }">
            {{ formatDate(value, 'DD/MM HH:mm') }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              small
              color="success"
              @click="onAttach(item)"
            >
              Acoplar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey darken-1"
          outlined
          @click="close"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";
import isArray from "lodash/fp/isArray"
import isObject from "lodash/fp/isObject"

export default {

  data() {
    return {
      dialog: false,

      itineraryId: null,

      vehicle: null,

      trailerTransfers: [],

      transfers: [],

      headers: [
        { text: 'Origem', value: 'equipmentOrigin.plate' },
        { text: 'Reboque', value: 'equipment.plate' },
        { text: 'Volume', value: 'volume' },
        { text: 'Data', value: 'date', width: 100 },
        { text: '', value: 'actions',  width: 100 },
      ],
    }
  },

  methods: {

    show({ itineraryId, vehicle, trailerTransfers }) {
      this.itineraryId = itineraryId;
      this.trailerTransfers = trailerTransfers;
      this.vehicle = vehicle;

      this.dialog = true;

      this.loadOpenTransfers();
    },

    async loadOpenTransfers() {
      try {
        this.$root.$progressBar.loading()

        const { data } = await this.$axios.get(`/itinerario/transferenciasSemAcoplamento`);

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.transfers = data.map(transfer => ({
          id: transfer.id,
          groupId: transfer.id_agrupamento,
          itineraryId: transfer.id_itinerario,
          date: transfer.data_hora_atualizacao,
          equipmentOrigin: {
            id: transfer.id_equipamento_origem,
            plate: transfer.placa_origem,
          },
          equipment: {
            id: transfer.id_equipamento_destino,
            plate: transfer.placa_destino,
          },
          volume: transfer.volume,
          transfers: transfer.transferencias
        }));
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as transferências em aberto!",
          "Atenção"
        );
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    async onAttachGroup(groupId) {
      const transfers = this.transfers.filter(t => t.groupId == groupId);
      const volume = transfers.reduce((acc, t) => acc + t.volume, 0);

      if (!(await this.$root.$confirm(
        `Deseja realmente acoplar?`,
        `<span class="body-1">Transferências: <b>${transfers.length}</b><br>Reboque: <b>${transfers[0].equipment.plate}</b><br>Volume: <b>${volume} L</div>`,
        { color: 'success' }
      ))
      ) {
        return;
      }

      this.changeAttach(transfers, 'ACOPLAR');
    },

    async onAttach(transfer) {
      if (!(await this.$root.$confirm(
        `Deseja realmente acoplar?`,
        `<span class="body-1">Reboque: <b>${transfer.equipment.plate}</b><br>Volume: <b>${transfer.volume} L</div>`,
        { color: 'success' }
      ))
      ) {
        return;
      }

      this.changeAttach([transfer], 'ACOPLAR');
    },

    async onUnattachGroup(groupId) {
      const transfers = this.trailerTransfers.filter(t => t.groupId == groupId);
      const volume = transfers.reduce((acc, t) => acc + t.volume, 0);

      if (!(await this.$root.$confirm(
        `Deseja realmente desacoplar?`,
        `<span class="body-1">Transferências: <b>${transfers.length}</b><br>Reboque: <b>${transfers[0].equipment.plate}</b><br>Volume: <b>${volume} L</div>`,
        { color: 'error' }
      ))
      ) {
        return;
      }

      this.changeAttach(transfers, 'DESACOPLAR');
    },

    async onUnattach(transfer) {
      if (!(await this.$root.$confirm(
        `Deseja realmente desacoplar?`,
        `<span class="body-1">Reboque: <b>${transfer.equipment.plate}</b><br>Volume: <b>${transfer.volume} L</div>`,
        { color: 'error' }
      ))
      ) {
        return;
      }

      this.changeAttach([transfer], 'DESACOPLAR');
    },

    async changeAttach(transfers, type) {
      try {
        this.$root.$progressBar.loading()

        const payload = {
          id_itinerario_destino: this.itineraryId,
          tipo: type,
          transferencias: transfers.map(t => ({
            id: t.id,
            id_itinerario: t.itineraryId,
            id_equipamento_destino: t.equipment.id,
            placa_destino: t.equipment.plate,
          })),
        };

        const { data } = await this.$axios.post(
          `/itinerario/acoplarReboque`,
          payload
        );

        if (!isObject(data) || !data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success('Transferência salva com sucesso', 'Sucesso');

        this.$emit('save');
        this.close();

      } catch (error) {
        this.$snotify.error(`Erro ao alterar acoplamento: ${error}`, "Atenção");
        console.error(error);
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    close() {
      this.transfers = [];
      this.dialog = false;
    },

    formatDate: (value, format) => moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
  },
}
</script>
