<template>
  <v-row>
    <template
      v-for="dairy of dairies"
    >
      <v-col
        :key="`chart-${dairy.name}`"
        cols="12"
      >
        <SpotPlannedAchievedChart
          :color="color"
          :height="height"
          :dairy="dairy.name"
          :title="`Spot - Planejado x Realizado - ${dairy.name}`"
          :loading="loading"
          :report="dairy.report"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

import SpotPlannedAchievedChart from "@/Domains/BI/Views/Platform/Components/SpotPlannedAchievedChart.vue";

export default {
  components: {
    SpotPlannedAchievedChart,
  },
  props: {
    color: {
      type: String,
      default: '#26c6da'
    },
    height: {
      type: String,
      default: '250px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      dairies: [],
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/spot/planejadoRealizadoDiario`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isArray(data)) {
          throw "PHP Error";
        }

        this.dairies = data.map(spotsByDairy => {
          return {
            name: spotsByDairy.nome_laticinio,
            report: spotsByDairy.materia_prima.map((rawMaterial) => {
              const plannedByDate = _.groupBy(rawMaterial.planejado, 'data');
              const realizedByDate = _.groupBy(rawMaterial.realizado, 'data');

              const plannedVolByDate = _.mapValues(plannedByDate, (volumes) => _.sumBy(volumes, 'volume'));
              const realizedVolByDate = _.mapValues(realizedByDate, (volumes) => _.sumBy(volumes, 'volume'));

              const dates = _.uniq([
                ...Object.keys(plannedByDate),
                ...Object.keys(realizedByDate)
              ]);

              return {
                rawMaterial: rawMaterial.nome_materia_prima,
                category: dates.map(date => moment(date).format('DD/MM/YYYY')),
                planned: dates.map(date => plannedVolByDate[date] || 0),
                achieved: dates.map(date => realizedVolByDate[date] || 0),
              }
            }),
          }
        });

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de spot!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
