<template>
  <div>
    <v-select
      v-model="selected"
      :items="items"
      prepend-inner-icon="today"
      dark
      :label="label"
      hide-details
      filled
      class="text-capitalize"
      :clearable="!notClearable"
      @change="onMonthFilterSelect"
    >
      <template v-slot:item="{ item }">
        <template v-if="selected === 'customRange' && item.value === 'customRange' && hasMonth">
          <span class="grow text-capitalize"> {{ showMonth }} </span>
          <v-btn
            icon
            light
            @click.stop="resetCustomFilter"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>

        <template v-else>
          {{ item.text }}
        </template>
      </template>

      <template v-slot:selection="{ item }">
        <span v-if="selected === 'customRange' && hasMonth"> {{ showMonth }} </span>

        <span v-else>{{ item.text }}</span>
      </template>
    </v-select>

    <v-dialog
      :value="showSelectMonth"
      persistent
      width="290"
    >
      <v-date-picker
        v-model="month"
        :max="maxMonth"
        :min="minMonth"
        type="month"
        @change="onCustomMonthSelect"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  props: {
    value: {
      type: String,
    },

    label: {
      type: String,
      default: 'Data',
    },

    maxMonth: {
      type: String,
      default: moment().format("YYYY-MM"),
    },

    minMonth: {
      type: String,
      default: null,
    },

    disableLastThirty: Boolean,

    notClearable: Boolean,

    returnMonth: Boolean,

  },

  data() {
    return {
      // Dialogs
      showSelectMonth: false,

      // Models

      selected: null,

      month: "",
    };
  },

  computed: {
    items() {
      let items = [
        { text: 'Este mês', value: 'thisMonth' },
        { text: 'Mês anterior', value: 'lastMonth' },
      ]
      if (!this.disableLastThirty) {
        items.push({ text: 'Últimos 30 dias', value: 'lastThirtydays' });
      }
      items.push({ text: 'Definir data', value: 'customRange' });
      return items;
    },
    // maxMonth() {
    //   return moment().format("YYYY-MM");
    // },
    DateMonthSelected() {
      const firstMonthDay = moment(this.month)
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment(this.month)
        .endOf("month")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    showMonth() {
      return this.month ? moment(this.month).format('MMM/YYYY') : moment().format('MMM/YYYY');
    },

    today() {
      return moment().format("YYYY-MM");
    },

    thisMonth() {
      const firstMonthDay = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    lastMonth() {
      const firstMonthDay = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    parsedDateRange() {
      switch (this.selected) {
        case "thisMonth":
          return this.thisMonth;
        case "lastMonth":
          return this.lastMonth;
        case "lastThirtydays":
          return this.lastThirtydays;
        case "customRange":
          return this.DateMonthSelected;
      }

      return "";
    },

    lastThirtydays() {
      const firstMonthDay = moment()
        .subtract(30, 'day')
        .format("YYYY-MM-DD");
      const lastMonthDay = moment()
        .format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },
  },

  mounted() {
    // Seta o local para formatar o mês corretamente
    moment.locale('pt');
    // Atualiza o model local com o do componente pai
    this.selected = this.value;

    if (this.returnMonth) {
      this.month = this.value;
      this.selected = 'customRange';
    }
    this.$emit("change", this.parsedDateRange);
  },

  methods: {

    onMonthFilterSelect(event) {
      // Atualiza o v-model com o input selecionado
      this.$emit("input", event);

      // Somente exibir o modal caso seja selecionado o range customizado no select e caso não exista range selecionado
      if (this.selected === "customRange" && (_.isEmpty(this.month) || this.notClearable)) {
        this.showSelectMonth = true;

        return;
      }

      // Limpa o range customizado quando o model é limpo
      if (_.isEmpty(this.selected)) {
        this.month = "";
      }

      return this.$emit("change", this.parsedDateRange);
    },

    hasMonth() {
      return !_.isEmpty(this.month);
    },

    onCustomMonthSelect() {
      this.showSelectMonth = false;

      if (this.returnMonth) {
        let month = this.parsedDateRange[0] ?  moment(this.parsedDateRange[0]).format('YYYY-MM') : moment().format('YYYY-MM');
        this.$emit("input", month );
      }

      return this.$emit("change", this.parsedDateRange);
    },

    resetCustomFilter() {
      if (this.notClearable) {
        this.showSelectMonth = true;
        return;
      }
      this.showSelectMonth = false;
      this.selected = null;
      this.month = "";
    },
  },
};
</script>

