<template>
  <div>
    <collect-app-settings
      ref="collectAppSettings"
      is-dairy-settings
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="saveQualitySettings();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações do Aplicativo</span>
    </v-tooltip>
  </div>
</template>

<script>
import CollectAppSettings from '@/Domains/Settings/Components/CollectAppSettings.vue'

import _ from 'lodash'

export default {
  components: {
    CollectAppSettings
  },

  mounted() {
    this.loadQualitySettings();
  },

  methods: {
    async loadQualitySettings() {
      try {
        const { data } = await this.$axios.post(`/configuracao/listaConfiguracoesLaticinio`);

        this.$refs.collectAppSettings.setData(data);

      } catch (e) {
        console.error(e);
      }
    },

    async saveQualitySettings() {
      try {
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.post(`/configuracao/salvaConfiguracaoAppQualidade`, {
          configuracaoQualidade: JSON.stringify([
            this.$refs.collectAppSettings.getData()
          ]),
        });

        if (!_.head(data)['codigo']) {
          throw new Error(data);
        }

        this.$snotify.success('Configurações salvas com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error('Erro ao salvar as configurações', 'Atenção');
        console.warn(error);
      } finally {
        this.$store.dispatch('updateStoreSetting');
        this.$root.$progressBar.hide();
      }
    },
  },
};
</script>
