<template>
  <div>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-spacer />
        <v-col
          cols="2"
          md="2"
          class="pt-0"
        >
          <v-autocomplete
            v-model="filters.group"
            label="Grupo"
            placeholder="Selecione um Grupo"
            :items="groupList"
            item-text="group"
            item-value="id"
            hide-details
            filled
            clearable
            @change="defaultLoading"
          />
        </v-col>
        <v-col
          cols="2"
          md="2"
          class="pt-0"
        >
          <v-autocomplete
            v-model="filters.product"
            label="Produto"
            placeholder="Selecione um Produto"
            :items="productsList"
            item-text="product"
            item-value="id"
            hide-details
            filled
            clearable
            @change="loadItems"
          />
        </v-col>
        <v-col
          cols="2"
          md="2"
          class="pt-0"
        >
          <v-autocomplete
            v-model="filters.type"
            label="Tipo"
            placeholder="Selecione um tipo"
            :items="typeList"
            item-text="type"
            item-value="id"
            hide-details
            filled
            clearable
            @change="loadItems"
          />
        </v-col>
        <v-col
          cols="3"
          md="3"
          class="pt-0"
        >
          <person-autocomplete-filter
            v-model="filters.technical"
            type="TECHNICAL"
            prepend-inner-icon="person"
            label="Técnico / Veterinário / Agronomo"
            hide-details
            filled
            clearable
            @change="loadItems"
          />
        </v-col>
        <v-col
          cols="3"
          md="3"
          class="pt-0"
        >
          <v-text-field
            v-model="filters.search"
            append-icon="search"
            label="Busca"
            single-line
            hide-details
            filled
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search="filters.search"
        :loading="loading"
        style="white-space: nowrap"
        show-expand
        item-key="code"
      >

        <template #expanded-item="{ headers, item }"  >
          <td
            :colspan="headers.length"
            class="pa-0 text-center"
          >
            <v-data-table
              :headers="headersTechnical"
              :items="item.technicals"
              :search="filters.search"
              :loading="loading"
              :items-per-page="item.technicals.length"
              hide-default-footer
              class="elevation-1 ma-3"
              style="white-space: nowrap"
            >
            </v-data-table>
          </td>
        </template>

        <template #[`item.unitPrice`]="{ item }">
          <span>
            {{ formatNumber(item.unitPrice) }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import { isNull } from "lodash";
export default {
  components: {
    PersonAutocompleteFilter,
  },

  props: {
    groupList: [],

  },

  data() {
    return {
      items: [],
      productsList: [],

      typeList: [
        {
        id: 'Técnico',
        type: 'Técnico'
        },
        {
          id: 'Veterinário',
          type: 'Veterinário'
        },
        {
          id: 'Agronomo',
          type: 'Agronomo'
        },
      ],

      filters: {
        search: "",
        group: null,
        product: null,
        type: null,
        technical: {},
      },
      loading: false,
      edit: false,
      editId: null,
    };
  },

  computed: {
    headers() {
      return [
        { text: 'Código', value: 'code', align: 'start' },
        { text: 'Produto', value: 'product', align: 'start' },
        { text: 'Descrição', value: 'description', align: 'start' },
        { text: 'Grupo', value: 'group', align: 'start' },
        { text: 'Unidade', value: 'unit', align: 'start' },
        { text: 'Valor Unit R$.', value: 'unitPrice', align: 'start' },
        { text: 'Em Consignação', value: 'onConsignment', align: 'start' },
        { text: 'Na Empresa', value: 'inCompany', align: 'start' },
        { text: 'Total', value: 'total', align: 'start' },
      ];
    },

    headersTechnical() {
      return [
        { text: 'Técnico / Veterinário / Agronomo', value: 'technical', align: 'start' },
        { text: 'Unidade', value: 'unity', align: 'start' },
        { text: 'Estoque', value: 'stock', align: 'start' },
        { text: 'Utilizado', value: 'used', align: 'start' },
        { text: 'Total', value: 'total', align: 'start' },
      ];
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async defaultLoading() {
      this.getProductsByGroups();
      this.loadItems();
    },

    async getProductsByGroups() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/product', { params: {
            id_grupo_produto: this.filters.group,
          }
        });

        this.productsList = data.map(item => {
          return {
            id: item.id_produto,
            product: item.nome,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/stockOnConsignment', { params: {
            id_grupo_produto: this.filters.group,
            id_produto: this.filters.product,
            people: isNull(this.filters.technical.id) ?  '' : this.filters.technical.id,
          }
        });

        this.items = data.map(item => {
          return {
            code: item.produto_codigo,
            product: item.produto_nome,
            description: item.produto_descricao,
            group: item.grupo,
            unit: item.unidade_medida,
            unitPrice: item.valor_unit,
            onConsignment: item.quantidade,
            inCompany: item.estoque_complementar,
            total: parseFloat(item.quantidade) + parseFloat(item.estoque_complementar),
            technicals: item.technicals.map(technical => {
              return {
                technical: technical.nome,
                unity: technical.unidade_medida,
                stock: technical.quantidade,
                used: 0,
                total: technical.quantidade,
              }
            }),
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
  },

};
</script>

<style scoped>

</style>
