<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Recomendações
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filter.dateRange"
                  dark
                  @change="load"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            ref="report"
            name="Recomendações"
            :headers="headers"
            :items="items"
            :search="filter.search"
            :loading="loading"
            dark
            @click:row="edit"
          >
            <template #[`item.types`]="{ value }">
              <v-chip
                v-for="(type, i) in value"
                :key="i"
                x-small
              >
                {{ type }}
              </v-chip>
            </template>

            <template #[`item.status`]="{ value }">
              <v-chip
                small
                :color="value === 'CONCLUIDO' ? 'green' : 'red'"
              >
                {{ value === 'CONCLUIDO' ? 'Concluído' : 'Não concluído' }}
              </v-chip>
            </template>

            <template #[`item.recommendation`]="{ value }">
              {{ truncate(value, 55) }}
            </template>

            <template #[`item.actions`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <recommendation-dialog
      v-model="dialog.show"
      :edit-id="dialog.id"
      @save="load"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="add()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import moment from 'moment-timezone';
import truncate from 'lodash/truncate';

import RecommendationDialog from "@/Domains/Visits/ScheduledRecommendations/Components/RecommendationDialog.vue";
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';

const types = {
  IN77: 'IN77',
  IN59: 'IN59',
  PMLS: 'PMLS',
  BPA: 'BPA',
  VETERINARIO: 'VETERINÁRIO',
  ZOO_TECNICO: 'ZOOTÉCNICO',
  ALTERACOES_NO_LEITE: 'ALTERAÇÕES NO LEITE',
  QUALIDADE: 'QUALIDADE',
}

export default {
  name: "scheduled-recommendations",

  components: {
    RecommendationDialog,
    VDateRangePicker,
  },

  data() {
    return {
      loading: false,

      filter: {
        search: '',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      headers: [
        { text: 'Data', value: 'createdAt', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY')  },
        { text: 'Prazo', value: 'concludedAt', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY')  },
        { text: 'Tipos', value: 'types', width: 150, formatter: value => value.map(type => type).join(', ')  },
        { text: 'Técnico', value: 'technician.name', align: 'start'  },
        { text: 'Produtor', value: 'producer.name', align: 'start'  },
        { text: 'Concluído', value: 'status', align: 'center', formatter: value => value === 'CONCLUIDO' ? 'Concluído' : 'Não concluído'  },
        { text: 'Indicador', value: 'indicator', align: 'center', show: false },
        { text: 'Causa', value: 'cause', align: 'center', show: false },
        { text: 'Recomendação', value: 'recommendation', align: 'center' },
        { text: '', altText: 'Opções', value: 'actions', align: 'end', width: 30, sortable: false, drag: false }
      ],

      items: [],

      dialog: {
        show: false,
        id: null,
      },
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.dateRange;

        const { data } = await this.$axios.get(`/visit/recommendation`, { params: {
          date_start: startDate,
          date_end: endDate,
        } });

        this.items = data.map(item => ({
          id: item.id_recomendacao,
          createdAt: item.data_hora_cadastro,
          concludedAt: item.data_conclusao,
          status: item.status,
          types: (item.tipos || []).map(t => types[t]),
          technician: item.tecnico ? {
            id: item.tecnico.id_pessoa,
            name: item.tecnico.nome,
          } : null,
          producer: item.produtor ? {
            id: item.produtor.id_pessoa,
            name: item.produtor.nome,
          } : null,
          indicator: item.indicador,
          cause: item.causa,
          recommendation: item.recomendacao,
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    add() {
      this.dialog.id = null;
      this.dialog.show = true;
    },

    edit(item) {
      this.dialog.id = item.id;
      this.dialog.show = true;
    },

    async remove(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }

      try {
        this.loading = true;

        await this.$axios.delete(`/visit/recommendation/${id}`);

        this.load();
        this.$snotify.success('Recomendação excluído com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filter.dateRange;
      return `Recomendações - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      this.$refs.report.exportExcel(null, this.getReportTitle());
    },

    print() {
      this.$refs.report.print(null, this.getReportTitle());
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    truncate: (value, length) => truncate(value, { length }),
  },
};
</script>
