<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-card
      dark
      class="v-tamanho regions-list"
      color="transparent"
    >
      <v-card-title>
        <v-col
          md-6
          offset-md="6"
          class="pa-0"
        >
          <v-text-field
            v-model="filters.search"
            label="Pesquisar"
            single-line
            hide-details
            clearable
            @keydown="onSearchChange"
            @click:clear="onSearchChange"
          />
        </v-col>
      </v-card-title>

      <v-data-table
        :headers="[
          { align: 'start', text: 'Descrição', value: 'description' },
          { text: 'Clientes', value: 'customers' },
          { align: 'end', text: '', value: 'actions', sortable: false },
        ]"
        :items="filteredRegions"
        class="elevation-1 lista-regioes-table"
        show-expand
        single-expand
        :expanded.sync="selectedRegion"
        @item-expanded="onRegionDetails"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-0"
          >
            <v-container v-if="item.details">
              <v-layout
                row
                wrap
              >
                <v-flex
                  sm12
                  md5
                >
                  <v-layout
                    row
                    class="rota-container"
                  >
                    <v-flex class="list-item rota-item text-left">
                      <v-sheet
                        color="transparent"
                        class="cabecalho-produtores"
                      >
                        <v-list
                          dark
                          color="rgba(0,0,0,.5)"
                          class="py-1"
                          dense
                        >
                          <v-list-item>
                            <v-list-item-content class="text-center">
                              <span>Clientes</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-sheet>
                      <draggable
                        v-bind="{ disabled: true }"
                        class="produtores-grid drag-area overflow-y-auto"
                        style="max-height: 400px"
                      >
                        <transition-group
                          type="transition"
                          name="list-item"
                        >
                          <v-list-item
                            v-for="cliente in item.details.customers"
                            :key="cliente.id_pessoa"
                            dark
                            dense
                            class="list-item produtor-item"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ cliente.nome }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </transition-group>
                      </draggable>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  sm12
                  md7
                >
                  <v-layout
                    row
                    class="rota-container"
                  >
                    <v-flex
                      sm12
                      md6
                      class="list-item rota-item text-left"
                    >
                      <v-sheet
                        color="transparent"
                        class="cabecalho-produtores"
                      >
                        <v-list
                          dark
                          color="rgba(0,0,0,.5)"
                          class="py-1"
                          dense
                        >
                          <v-list-item>
                            <v-list-item-content class="text-center">
                              <span>Veiculos</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-sheet>
                      <draggable
                        v-bind="{ disabled: true }"
                        class="produtores-grid drag-area overflow-y-auto"
                        style="max-height: 170px"
                      >
                        <transition-group
                          type="transition"
                          name="list-item"
                        >
                          <v-list-item
                            v-for="veiculo in item.details.vehicles"
                            :key="veiculo.id_equipamento"
                            dark
                            dense
                            class="list-item produtor-item"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ (veiculo.nome || "").toUpperCase() }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Capacidade: {{ parseInt(veiculo.capacidade) || 0 }} L
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </transition-group>
                      </draggable>
                    </v-flex>

                    <v-flex
                      sm12
                      md6
                      class="list-item rota-item text-left"
                    >
                      <v-sheet
                        color="transparent"
                        class="cabecalho-produtores"
                      >
                        <v-list
                          dark
                          color="rgba(0,0,0,.5)"
                          class="py-1"
                          dense
                        >
                          <v-list-item>
                            <v-list-item-content class="text-center">
                              <span>Equipe</span>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-sheet>
                      <draggable
                        v-bind="{ disabled: true }"
                        class="produtores-grid drag-area overflow-y-auto"
                        style="max-height: 170px"
                      >
                        <transition-group
                          type="transition"
                          name="list-item"
                        >
                          <v-list-item
                            v-for="equipe in item.details.team"
                            :key="equipe.id_pessoa"
                            dark
                            dense
                            class="list-item produtor-item"
                          >
                            <v-list-item-title>
                              {{ equipe.nome }}
                            </v-list-item-title>
                          </v-list-item>
                        </transition-group>
                      </draggable>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.regions-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.lista-regioes-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import _ from "lodash";
import qs from "qs";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },

  props: ["selected"],

  data() {
    return {
      // Loaders
      loading: true,

      // Dados brutos do backend
      regions: [],

      // Filtros da tabela
      filters: {
        search: "",
      },

      selectedRegion: [],
    };
  },

  computed: {
    /**
     * Exibe as regiões filtradas
     */
    filteredRegions() {
      if (_.isEmpty(this.filters.search)) {
        return this.regions;
      }

      return this.regions.filter(region => {
        return (region.description || "").toUpperCase().includes((this.filters.search || "").toUpperCase());
      });
    },
  },

  async mounted() {
    await this.loadRegions();

    // Verifica se ao montar foi passado o parametro com os dados da região selecionada
    if (this.selected) {
      this.selectRegion();
    }
  },

  methods: {
    /**
     * Método responsável por carregar todas as regiões disponíveis para o laticínio
     * @returns {Promise<void>}
     */
    async loadRegions() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/registrations/region`, { params: {
          tipo: 'COMERCIAL',
          exibir_totais_pessoas: true
        } });

        this.regions = data.map(item => {
          const customers = parseInt(item.total_pessoas) || null;

          return {
            id: item.id_regiao,
            description: item.descricao,
            customers,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as regiões!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Ao montar o componente, verifica se o componente pai passou uma região para exibir os detalhes
     * Este método adiciona ao filtro o nome da região passada e exibe os detalhes dela ao carregar a tela
     */
    selectRegion() {
      if (_.isEmpty(this.regions) || _.isEmpty(this.selected)) {
        return [];
      }

      this.selectedRegion = this.regions.filter(region => region.id === this.selected.id_regiao);

      if (_.isEmpty(this.selectedRegion)) {
        return;
      }

      this.filters.search = this.selected.descricao;

      return this.onRegionDetails({
        item: _.first(this.selectedRegion),
        value: true,
      });
    },

    /**
     * Evento acionado ao detalhar uma linha da tabela
     * @param item{object}
     * @param value{boolean} True = Abrindo; False = Fechando
     */
    async onRegionDetails({ item, value }) {
      try {
        // Emite evento para limpar a região selecionada no componente pai
        if (!value) {
          return this.onDetailsClosed();
        }

        // Verifica se na região selecionada já foi carregado os dados do servidor
        if (item.details) {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/listaRegiaoDetalheJson`,
          qs.stringify({
            id_regiao: item.id,
          })
        );

        const response = data[0];

        const { clientes, veiculos, equipe } = response;

        this.regions = this.regions.map(region => {
          if (region.id !== item.id) {
            return region;
          }

          return {
            details: {
              customers: clientes,
              vehicles: veiculos,
              team: equipe,
            },
            ...region,
          };
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Caso o componente pai passe uma região para o detalhamento, ao alterar a busca, limpa a região detalhada
     */
    onSearchChange() {
      // Ao refazer a busca, fecha a região expandida
      this.selectedRegion = [];

      return this.onDetailsClosed();
    },

    /**
     * @event void
     *
     * Evento acionado ao fechar o detalhamento de uma região
     */
    onDetailsClosed() {
      return this.$emit("onDetailsClosed");
    },

    /**
     * @event object
     *
     * Evento acionado ao selecionar uma rota na lista
     */
    onRouteSelect(route) {
      this.loading = true;

      return this.$emit("onRouteSelect", route);
    },
  },
};
</script>
