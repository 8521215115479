<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Novo perfil de aprovação
          </span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="saveNew"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0 d-flex"
              >
                <v-text-field
                  v-model="form.name"
                  prepend-inner-icon="person"
                  label="Nome do perfil *"
                  placeholder="Digite o nome do perfil"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-subheader class="pl-0">
                  Aprova pedidos com descontos até *
                </v-subheader>
                <v-slider
                  v-model="form.percent"
                  :thumb-size="24"
                  label="%"
                  thumb-label="always"
                />
              </v-col>
            </v-row>
          </v-form>
          <small>* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="() => show = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="loading || saving"
            :loading="saving"
            @click="saveNew"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        v-model="loading"
        absolute
      >
        Carregando...
      </v-overlay>

      <v-overlay
        v-model="saving"
        absolute
      >
        Salvando...
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      input: null,

      form: {
        name: '',
        percent: 5,
      },

      rules: {
        required: v => !!v || !_.isEmpty(v) || 'Campo obrigatório!',
      },
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$refs.form && this.$refs.form.resetValidation();
          this.form = {
            name: '',
            percent: 5,
          };
        }
        this.$emit("input", value);
      },
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.form.percent = 5;
      }
    }
  },

  methods: {
    async saveNew() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      try {

        const { data } = await this.$axios.post(
          `/comercial/salvaPerfilComercial`,
          {
            nome: this.form.name,
            percentual_maximo: this.form.percent,
          },
        );

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$emit("newProfile", { id: data.id_perfil_comercial, ...this.form });

        this.show = false;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar o perfil!", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
