export default {
  namespaced: true,
  state: {
    dialogs: [],
  },
  mutations: {
    add(state, id) {
      state.dialogs.push(id);
    },
    remove(state, id) {
      state.dialogs = state.dialogs.filter((dialog) => dialog !== id);
    },
  },
};
