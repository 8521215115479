<template>
  <v-dialog
    v-model="show"
    width="600"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Etapa de Produção
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row v-if="show">
            <v-col
              cols="8"
              class="pb-0"
            >
              <v-text-field
                v-model="form.description"
                label="Descrição *"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="4"
              class="pb-0"
            >
              <v-text-field
                v-model="form.duration"
                v-maska
                data-maska="##:M#"
                data-maska-tokens="M:[0-5]"
                label="Duração"
                persistent-hint
                placeholder="hh:mm"
                :rules="[v => !v || (!!v && v.length === 5) || 'Duração inválida!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-textarea
                v-model="form.notes"
                label="Observações"
                rows="2"
                auto-grow
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-autocomplete
                v-model="form.access"
                :items="users"
                label="Acessos"
                item-key="id"
                item-text="nome"
                prepend-inner-icon="person"
                return-object
                multiple
                chips
                small-chips
                deletable-chips
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
export default {

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      form: {
        access: [],
      },
      users: [],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      this.$refs.form?.resetValidation();
      this.form = {}
      if (value && this.editId) {
        this.load();
      }
    }
  },

  created() {
    this.loadUsers();
  },

  methods: {
    async loadUsers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaJson`);

        this.users = data.map(person => ({
          id: person.id_pessoa,
          nome: person.nome,
        }));
      } catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os usuários!', 'Atenção');
      }
    },

    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/production-step/${ this.editId }`);

        this.form = {
          description: data.descricao,
          duration: data.duracao?.substring(0, 5),
          notes: data.observacao,
          access: data.acessos || [],
        };
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;

        const payload = {
          descricao: this.form.description,
          duracao: this.form.duration ? this.form.duration + ':00' : null,
          observacao: this.form.notes,
          acessos: this.form.access,
        };

        const data = this.editId ? await this.update(payload) : await this.store(payload);

        this.close();
        this.$emit('save', data.id);
        this.$snotify.success('Item salvo com sucesso!', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/production-step`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/production-step/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    }
  }
}
</script>
