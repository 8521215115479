<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col
        md="4"
      >
        <date-range-picker
          v-model="filter.date.input"
          @change="onDateFilter"
        />
      </v-col>
    </v-row>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-chart
          :options="options"
          :style="{width: '100%', height: `450px !important`}"
          autoresize
        />
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";

export default {
  name: "scheduled-visited-graph",

  components: {
    DateRangePicker,
  },

  data() {
    return {
      loading: false,

      filter: {
        date: {
          input: "thisMonth",
          range: [],
        },
      },
      items: [],
    };
  },

  computed: {
    options() {
      return {
        colors: ['#00B0FF', '#00E676'],
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {
              backgroundColor: '#193D5B'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b>`)
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.items.map((item) => item.technician),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)',
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)',
            formatter: (value) => this.formatNumber(value)
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          }
        },
        series: [
          {
            name: 'Agendados',
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 17,
              height: 17,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              formatter: ({ value }) => `  ${this.formatNumber(value)}  `
            },
            data: this.items.map((item) => item.scheduled)
          },
          {
            name: 'Visitados',
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 17,
              height: 17,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              formatter: ({ value }) => `  ${this.formatNumber(value)}  `
            },
            data: this.items.map((item) => item.visited)
          }
        ]
      }
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;

        const [start_date, end_date] = this.filter.date.range;

        const { data } = await this.$axios.post(`/agenda/listaAgendamentosVisitados`, { start_date, end_date });

        /**
         * Cria um objeto para agrupar por técnico
         */
        const reduced = data.reduce((report, item) => {
          const { id_tecnico, nome_tecnico, hora_chegada } = item;
          if (!(id_tecnico in report)) {
            report[id_tecnico] = {
              technician_id: id_tecnico,
              technician: nome_tecnico,
              scheduled: 0,
              visited: 0,
            };
          }
          report[id_tecnico].scheduled++;
          if (hora_chegada) {
            report[id_tecnico].visited++;
          }
          return report;
        }, {});

        this.items = Object.values(reduced);
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter(range) {
      this.filter.date.range = range;
      this.loadReport();
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },
};
</script>
