<template>
  <div>
    <v-autocomplete
      v-model="input"
      :loading="loading"
      :items="region"
      item-text="description"
      item-value="id"
      prepend-inner-icon="map"
      :label="label"
      :dark="dark"
      :return-object="returnObject"
      :hide-details="hideDetails"
      :filled="filled"
      clearable
      v-bind="$attrs"
      :multiple="multiple"
      @change="onChange"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {

  props: {

    value: {
      type: [Object, Array, String],
    },

    type: {
      type: String,
      default: 'CAPTACAO'
    },

    label: {
      type: String,
    },

    filled: {
      type: Boolean,
      default: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // Região (Autocomplete)
      region: [],
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  async mounted() {
    await this.loadRegion();
  },

  methods: {

    async loadRegion() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/regiao/listaJson`, this.$qs.stringify({
          tipo: this.type
        }));

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.region = data.map(region => ({
          id: region.id_regiao,
          description: region.nome,
        }));
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as regiões!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onChange(event) {

      const newValue = event || {};

      // atualiza o v-model do componente
      this.$emit("input", newValue);

      this.$emit("change");
    },
  },
};
</script>
