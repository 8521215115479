<template>
  <div>
    <v-card
      v-if="goal.type"
      class="graph-card"
      color="transparent"
      dark
    >
      <v-card-title>
        {{ goal.type.nome }} {{ periodVisitText }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            md="4"
          >
            Meta: {{ goal.type.visitas }} {{ goal.type.tipo==='PERCENT'?'% de ':'' }} {{ goal.type.nome }} {{ goal.type.periodo }}
            <v-icon
              right
              :color="icone == 'verified' ? 'green darken-1': 'red darken-1'"
            >
              {{ icone }}
            </v-icon>
          </v-col>
          <v-col
            md="4"
          >
            Produtores ativos/supensos em  {{ today }} : <b> {{ totalProducer }} </b>
          </v-col>
        </v-row>
        <v-expansion-panels dark>
          <v-expansion-panel
            style="background: none"
          >
            <v-expansion-panel-header>Visitado (dentro da meta): {{ totalVisited }} produtores ({{ percentVisited }}%) (Ativos/Suspensos)</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  align="right"
                >
                  <v-btn
                    fab
                    dark
                    color="green darken-1"
                    @click="exportExcelVisited()"
                  >
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          backup_table
                        </v-icon>
                      </template>
                      Download (Excel)
                    </v-tooltip>
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    color="orange darken-1"
                    @click="printVisited"
                  >
                    <v-tooltip left>
                      <template #activator="{ on }">
                        <v-icon v-on="on">
                          print
                        </v-icon>
                      </template>

                      Imprimir
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="visited"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template
                  #[`item.data_visita`]="{ value }"
                >
                  {{ formatDate(value, "DD/MM/YYYY") }}
                </template>
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            style="background: none"
          >
            <v-expansion-panel-header>Não Visitado (fora da meta): {{ totalNotVisited }} produtores ({{ percentNotVisited }}%) </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  align="right"
                >
                  <v-btn
                    fab
                    dark
                    color="green darken-1"
                    @click="exportExcelNotVisited()"
                  >
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          backup_table
                        </v-icon>
                      </template>
                      Download (Excel)
                    </v-tooltip>
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    color="orange darken-1"
                    @click="printNotVisited"
                  >
                    <v-tooltip left>
                      <template #activator="{ on }">
                        <v-icon v-on="on">
                          print
                        </v-icon>
                      </template>

                      Imprimir
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headersNotVisited"
                :items="notvisited"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template
                  #[`item.data_ultima_visita`]="{ value }"
                >
                  {{ formatDate(value, "DD/MM/YYYY") }}
                </template>
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>

import _ from "lodash";
import moment from "moment-timezone";
import { round, percentageBy } from "@/Support/Resources/utils";
import XLSX from "xlsx-js-style";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {

  components: {

  },
  mixins: [ReportMixin],
  props: {
    goal: Object,
  },

  data() {
    return {

      loading: false,

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Nome', value: 'nome_produtor' },
        { text: 'Data visita', value: 'data_visita' },
        { text: 'Dias da ultima visita', value: 'dias_visita' },
        { text: 'Status', value: 'status' },
      ],
      headersNotVisited: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Nome', value: 'nome_produtor' },
        { text: 'Ultima visita', value: 'data_ultima_visita' },
        { text: 'Dias da ultima visita', value: 'dias_visita' },
        { text: 'Status', value: 'status' },
      ],

      visited: [],
      notvisited: [],

      today: moment().format("DD/MM/YYYY"),

      totalProducer: 0,

      percentVisited: 0,
      totalVisited: 0,

      percentNotVisited: 0,
      totalNotVisited: 0,

      periodVisitText: '',

      icone: 'cancel',

    };
  },

  watch: {
    goal: {
      handler() {
        this.loadVisits();
      },
      deep: true
    }

  },
  mounted() {
    this.loadVisits();
  },

  methods: {

    async loadVisits() {

      if (_.isEmpty(this.goal.type)) {
        return false;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorioIn/loadVisitsGoalsDetails`,
          {
            meta: this.goal.type,
            id_pessoa: this.goal.technician.id,
            rotas: this.goal.routes.map(({ id }) => id),
            data: this.goal.dateFilter
          }
        );

        this.periodVisitText = `Período Atual: ${this.goal.dateFilter.text} `;

        this.visited =  data.filter(item => !!item.data_visita  )
          .map(item => ({
            ...item
          }));

        this.totalVisited = this.visited.length;

        this.notvisited =  data.filter(item => _.isNull(item.data_visita))
          .map(item => ({
            ...item
          }));
        this.totalNotVisited = this.notvisited.length;

        let active =  data.map(item => ({
          ...item
        }));

        this.totalProducer = active.length;

        this.percentVisited = round(percentageBy(this.totalProducer, this.totalVisited), 2);
        this.percentNotVisited = round(percentageBy(this.totalProducer, this.totalNotVisited), 2);

        if (parseFloat(this.goal.type.visita) <= parseFloat(this.percentVisited )) {
          this.icone = 'verified';
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar visitas!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    getReportJson(items, header) {
      return items.map(o => {
        let initial = {};
        // o.status = o.status.description;
        return header.reduce((acc, header) => ({
          ...acc,
          [header.text]: 'formatter' in header ? header.formatter(_.get(o, header.value, '')) : _.get(o, header.value, ''),
        }), initial);
      });

    },

    printVisited() {
      const title = 'Visitados';

      return this.printFromJson(this.getReportJson(this.visited, this.headers ), title, true);
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcelVisited() {
      let data = XLSX.utils.json_to_sheet(this.getReportJson(this.visited, this.headers ));
      const filename = (`Visitados`);

      const workbook = this.XLSX.utils.book_new();
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    printNotVisited() {
      const title = 'Não Visitados';

      return this.printFromJson(this.getReportJson(this.notvisited, this.headersNotVisited ), title, true);
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcelNotVisited() {
      let data = XLSX.utils.json_to_sheet(this.getReportJson(this.notvisited, this.headersNotVisited ));
      const filename = (`Não Visitados`);

      const workbook = this.XLSX.utils.book_new();
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },


    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
}
</script>
