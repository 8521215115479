<template>
  <v-autocomplete
    v-model="input"
    :loading="loading"
    :items="items"
    :label="label"
    item-text="name"
    item-value="id"
    return-object
    :filled="filled"
    :clearable="clearable"
    :prepend-inner-icon="prependInnerIcon"
    v-bind="$attrs"
    @change="onChange"
  />
</template>

<script>
import { isArray } from 'lodash';

export default {

  props: {

    value: {
      type: [ Object, Array ],
    },

    label: {
      type: String,
      default: 'Laticínio'
    },

    filled: {
      type: Boolean,
      default: true,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    prependInnerIcon: {
      type: String,
      default: 'business',
    },

    hideCurrentDairy: Boolean
  },
  data() {
    return {
      input: null,
      loading: false,
      items: [],
      search: ''
    }
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  created() {
    this.loadDairies();
  },

  methods: {
    async loadDairies() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/empresa/listaJson`);

        if (!isArray(data)) {
          throw 'Error';
        }

        let dairies = data.map(o => ({
          id: o.id_laticinio,
          name: o.nome,
        }));

        if (this.hideCurrentDairy) {
          dairies = dairies.filter(o => o.id != this.$store.state.settings.laticinio.id);
        }

        this.items = dairies;

      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os laticínios!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    onChange(event) {
      // atualiza o v-model do componente
      this.$emit("input", event || {});

      this.$emit("change");
    },
  }
}
</script>
