<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="text-xs-center"
      >
        <h2 class="menu-header white--text mt-0 mb-2">
          Falhas de Integração
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <v-date-range-picker
                v-model="filters.dateRange"
                dark
                @change="loadReport"
              />
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <v-text-field
                v-model="filters.search"
                filled
                prepend-inner-icon="search"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <data-table
          :headers="headers"
          :items="items"
          :search="filters.search"
          dark
          show-expand
          single-expand
          show-custom-group
        >
          <template #[`item.data_hora_registro`]="{ value }">
            {{ formatDate(value) }}
          </template>
          
          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-row no-gutters>
                <v-col
                  v-for="(value, title) of item.analise"
                  :key="title"
                  cols="12"
                  sm="4"
                  md="3"
                  lg="2"
                  class="text-left"
                >
                  <div class="font-weight-bold grey--text text--lighten-1">
                    {{ title }}
                  </div>
                  <div>
                    {{ value }}
                  </div>
                </v-col>
              </v-row>
            </td>
          </template>
        </data-table>
      </v-card>
    </v-flex>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";

export default {
  components: {
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  data() {
    return {

      filters: {
        search: '',
        analysis: '',
        routes: [],
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      headers: [
        { text: "Amostra", value: "numero_amostra" },
        { text: "Problema", value: "problema" },
        { text: "Data", value: "data_hora_registro" },
      ],

      items: [],
    };
  },

  methods: {
    async loadReport() {
      try {
        this.$root.$progressBar.loading();

        const [dateStart, dateEnd] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/analysis/logs`, {
          params: {
            date_start: dateStart,
            date_end: dateEnd,
          }
        });

        this.items = data;

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportJson() {
      return this.items.map(item => ({
        ...item.analise
      }));
    },

    exportExcel() {
      this.exportToFile({ report: this.getReportJson(), title: 'Falhas de Integração' });
    },

    formatDate: val => new Date(val).toLocaleString()
  }
}
</script>
