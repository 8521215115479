<template>
  <v-container
    class="pt-0"
    grid-list-lg
    text-xs-center
  >
    <v-card
      class="graph-card"
      color="transparent"
      dark
    >
      <v-data-table
        :headers="[
          { text: 'Código', value: 'codigo', width: 100 },
          { text: 'Produtor', value: 'nome'},
          { text: 'Rotas', value: 'rota' },
          { text: 'Última Visita', value: 'ultima_visita' },
        ]"
        :items="listNotVisited"
        disable-pagination
        dark
      >
        <template #[`item.ultima_visita`]="{ value }">
          {{ formatDate(value, 'DD/MM/YYYY') }}
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          class="mb-0"
          color="white"
          indeterminate
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.visits-list {
  background: rgba(0, 0, 0, 0.3) !important;
}

.technical-visit-card {
  background: transparent !important;

  .v-card__title {
    background: rgba(0, 0, 0, .3);
  }
}

.technical-visit-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.2);
    }

    tbody {
      background: rgba(255, 255, 255, 0.15);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }

      .v-row-group__header {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}

</style>

<script>
import _ from "lodash";
import moment from "moment";
import MomentDuration from "moment-duration-format";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import XLSX from "xlsx-js-style";

//
/**
 * Para resolver um pouco a lentidão do carregamento da página
 * devido a quantidade de componentes sendo renderizados
 * Adicionei um virtual-scroller, o vuetify tem um build-in, mas tem que ter o tamanho fixo dos items,
 * por isso adicionei essa lib que detecta o tamnho automaticamente
 * https://github.com/Akryum/vue-virtual-scroller
 */
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

MomentDuration(moment);

export default {
  name: "quality-indicators-visits",

  components: {

  },

  mixins: [ReportMixin],

  props: {
    parent: Object,
    hideHeader: Boolean,
    aba: String,
    filter: Object,
  },

  data() {
    return {
      // Loaders
      loading: false,

      visitpmls: [],

      visitGoals: {},

      listNotVisited: [],
    };
  },

  watch: {
    filter: {
      handler() {
        this.loadVisits();
      },
      deep: true
    }
  },

  async mounted() {
    this.loadVisits();
  },

  methods: {
    async loadVisits() {
      if (this.filter.project) {
        if (this.aba == 'nao_visitados') {
          await this.loadMetas();
        }
      } else {
        this.listNotVisited = [];
      }
    },

    async loadMetas() {
      await this.loadListProducer();
    },

    async loadListProducer() {
      try {
        this.loading = true;


        let { data } = await this.$axios.post(
          `/produtores/listProducerNotVisitedPMLS`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM'),
            id_rota: this.filter.route ? this.filter.route.id : null,
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        this.listNotVisited = data;

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os não visitados!", "Atenção");

        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    onFilterChange() {
      this.loadListProducer();
    },

    getReportJson() {
      return this.listNotVisited.map(item => ({
        "Código": item.codigo,
        "Produtor": item.nome,
        "Rota": item.rota,
        "Última Visita": moment(item.ultima_visita).format('DD/MM/YYYY'),
      }));
    },

    exportExcel() {
      const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.listNotVisited));
      const workbook = XLSX.utils.book_new();
      const filename = 'Não Visitados';
      XLSX.utils.book_append_sheet(workbook, data, _.truncate(filename, { length: 31 }));
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    print() {
      return this.printFromJson(this.getReportJson(), 'Não Visitados');
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
