<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Frota
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-autocomplete
          v-model="filter.vehicle"
          :items="vehicles"
          item-text="name"
          item-value="id"
          prepend-inner-icon="local_shipping"
          label="Veículo"
          return-object
          hide-details
          filled
          clearable
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="filter.type"
          :items="typesSelect"
          prepend-inner-icon="reorder"
          label="Tipo de veículo"
          hide-details
          filled
          clearable
        />
      </v-col>
      <v-col cols="4">
        <person-autocomplete-filter
          v-model="filter.supplier"
          type="SUPPLIER"
          prepend-inner-icon="business"
          label="Fornecedor/Prestador"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <data-table
            ref="report"
            :headers="headers"
            :items="filteredVehicles"
            :search="filter.search"
            :loading="loading"
            show-custom-group
            dark
            class="elevation-1 report-table"
            @click:row="editVehicle"
          >
            <template #[`item.description`]="{ value }">
              {{ value.toUpperCase() }}
            </template>
            <template #[`item.plate`]="{ value }">
              {{ value.toUpperCase() }}
            </template>
            <template #[`item.aquisitionDate`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.type`]="{ value }">
              {{ types[value] }}
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="hasDeleteFleet"
                    @click="removeVehicle(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="printQRCode(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>qr_code_2</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>QR Code</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            {{ form.id ? 'Editar veículo' : 'Novo veículo' }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="save"
            >
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.description"
                    label="Descrição *"
                    prepend-inner-icon="commute"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="form.invoice"
                    label="N° Nota Fiscal"
                    prepend-inner-icon="receipt"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="3">
                  <money-input
                    v-model="form.value"
                    label="Valor"
                    prefix="R$"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="form.brand"
                    label="Marca"
                    prepend-inner-icon="branding_watermark"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="form.model"
                    label="Modelo"
                    prepend-inner-icon="commute"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="form.manufacturedYear"
                    :items="years"
                    label="Ano de fabricação"
                    prepend-inner-icon="event"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="3">
                  <masked-text-field
                    v-model="form.lifespan"
                    label="Vida útil (anos)"
                    prepend-inner-icon="date_range"
                    persistent-hint
                    :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                    unmask
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.plate"
                    label="Placa *"
                    prepend-inner-icon="qr_code"
                    persistent-hint
                    :rules="[v => !!v && v.length >= 7 || 'Campo obrigatório!']"
                    @input="value => form.plate = value.toUpperCase()"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="form.type"
                    :items="typesSelect"
                    label="Tipo *"
                    prepend-inner-icon="reorder"
                    persistent-hint
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    @change="onChangeType"
                  />
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="form.aquisitionDate.picker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="form.aquisitionDate.value | dateFormat('DD/MM/YYYY')"
                        label="Data de aquisição *"
                        prepend-inner-icon="event"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="form.aquisitionDate.value"
                      no-title
                      @input="form.aquisitionDate.picker = false"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <masked-text-field
                    v-model="form.consumptionPerKm"
                    label="Km/Litros"
                    prepend-inner-icon="local_gas_station"
                    persistent-hint
                    :mask="{ mask: Number, min: 0, signed: false }"
                    unmask
                  />
                </v-col>
                <v-col cols="4">
                  <money-input
                    v-model="form.dailyCost"
                    label="Custo diário de manutenção"
                    prefix="R$"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="4">
                  <masked-text-field
                    v-model="form.percentageOfUsage"
                    label="Percentual de uso"
                    prefix="%"
                    persistent-hint
                    :mask="{ mask: Number, min: 0, signed: false }"
                    unmask
                  />
                </v-col>
                <v-col cols="12">
                  <person-autocomplete-filter
                    v-model="form.dairies"
                    type="DAIRY"
                    label="Unidades de Negócios *"
                    prepend-inner-icon="account_balance"
                    persistent-hint
                    multiple
                    chips
                    small-chips
                    :return-object="false"
                    :clearable="false"
                    :filled="false"
                    :hide-details="false"
                    :rules="[v => !!v && v.length > 0 || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col cols="3">
                  <span class="caption">Terceiro</span>
                  <v-switch
                    v-model="form.third"
                    :label="form.third ? 'Ativado' : 'Desativado'"
                    persistent-hint
                    class="mt-0"
                  />
                </v-col>
                <v-col
                  v-if="form.third"
                  cols="9"
                >
                  <person-autocomplete-filter
                    v-model="form.supplier"
                    type="SUPPLIER_ALL"
                    label="Fornecedor/Prestador *"
                    prepend-inner-icon="business"
                    persistent-hint
                    :clearable="false"
                    :filled="false"
                    :hide-details="false"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  v-if="['leite', 'reboque'].includes(form.type)"
                  cols="12"
                >
                  <v-divider />
                  <span
                    class="text-h6 mt-4"
                    style="display: inline-flex;"
                  >
                    Tanques
                    <v-spacer />
                    <v-btn
                      v-if="form.tanks.length > 1"
                      icon
                      color="red"
                    >
                      <v-icon
                        @click="changeTank('remove')"
                      >
                        remove
                      </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="blue"
                    >
                      <v-icon
                        @click="changeTank('add')"
                      >
                        add
                      </v-icon>
                    </v-btn>
                  </span>
                  <v-row>
                    <v-col
                      v-for="(tank, idx) of form.tanks"
                      :key="idx"
                      cols="3"
                    >
                      <v-text-field
                        v-model.number="tank.value"
                        :label="`Tanque ${idx + 1} *`"
                        prepend-inner-icon="opacity"
                        persistent-hint
                        type="number"
                        :rules="[() => totalVol > 0 || 'Campo obrigatório!']"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="['leite', 'reboque'].includes(form.type)"
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Capacidade Total (L)"
                    :value="totalVol"
                    prepend-inner-icon="opacity"
                    placeholder=" "
                    disabled
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="showDialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="hasEditFleet ? false : true"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasEditFleet"
        fab
        dark
        color="blue"
        @click="newVehicle()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Novo veículo
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="grey darken-4"
        @click="printQrCodeFleet()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              qr_code_2
            </v-icon>
          </template>

          Imprimir QrCodes
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="qrCodeDialog.isOpen"
      width="380"
    >
      <v-card>
        <v-card-title class="text-h5">
          Tamanho da impressão
        </v-card-title>
        <v-card-text>
          <v-btn-toggle
            v-if="qrCodeDialog.isOpen"
            class="d-flex justify-center"
          >
            <v-btn @click="printQrCode('100mm')">
              Etiqueta

              <v-icon
                right
                color="purple darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>

            <v-btn @click="printQrCode('A4_Alt')">
              Grande

              <v-icon
                right
                color="green darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>

            <v-btn @click="printQrCode('A4')">
              Padrão

              <v-icon
                right
                color="blue darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

import moment from "moment-timezone";
import _, { isNull } from "lodash";
import printJS from "print-js";
import { sortItems } from 'vuetify/lib/util/helpers'

export default {
  name: "fleet-registration",

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },

  components: {
    MoneyInput,
    MaskedTextField,
    PersonAutocompleteFilter,
  },

  data() {
    return {
      loading: false,
      showDialog: false,

      filter: {
        vehicle: null,
        type: '',
        supplier: '',
        search: '',
        sort: { by: [], desc: [] }
      },

      vehicles: [],

      form: {
        aquisitionDate: {
          picker: false,
          value: moment().format('YYYY-MM-DD'),
        },
        tanks: [],
        dairies: [],
      },

      suppliers: [],

      types: {
        animal: 'Animal',
        nutricao: 'Nutrição',
        sanidade: 'Sanidade',
        agrotoxicos: 'Agrotóxicos',
        consultoria: 'Consultoria',
        apoio: 'Apoio',
        leite: 'Leite',
        reboque: 'Reboque',
      },

      typesSelect: [
        { value: 'animal', text: 'Animal' },
        { value: 'nutricao', text: 'Nutrição' },
        { value: 'sanidade', text: 'Sanidade' },
        { value: 'agrotoxicos', text: 'Agrotóxicos' },
        { value: 'consultoria', text: 'Consultoria' },
        { value: 'apoio', text: 'Apoio' },
        { value: 'leite', text: 'Leite' },
        { value: 'reboque', text: 'Reboque' },
      ],

      plateMask: {
        mask: 'aaa0#00', // Placas Brasileiras (incluindo Mercosul)
        prepare: (str) => str.toUpperCase(),
        definitions: {
          '#': /[0-9a-zA-Z]/
        }
      },

      qrCodeDialog: {
        isOpen: false,
        ids: []
      }
    };
  },

  computed: {
    totalVol() {
      if (_.isEmpty(this.form)) {
        return 0;
      }

      return _.sumBy(this.form.tanks, tank => parseInt(tank.value) || 0);
    },

    headers() {
      const headers = [
        { text: 'Nome', value: 'description' },
        { text: 'Placa', value: 'plate', align: 'center' },
        { text: 'Prestador', value: 'supplier.name', align: 'center' },
        { text: 'Aquisição', value: 'aquisitionDate', align: 'center' },
        { text: 'Marca', value: 'brand', align: 'center' },
        { text: 'Tipo', value: 'type', align: 'center' },
        { text: 'Total de tanques', value: 'totalTanks', align: 'center' },
        ...this.tankHeaders,
        { text: 'Capacidade', value: 'capacity', align: 'center' },
        { text: '', value: 'action', width: 30 },
      ];

      return headers;
    },

    tankHeaders() {
      let header = [];

      const maxTanks = this.vehicles.reduce((max, vehicle) => {
        return Math.max(max, vehicle.tanque.length);
      }, 0);

      for (let i = 0; i < maxTanks; i++) {
        header.push({
          text: `Tanque${i + 1}`,
          value: `tanks[${i}].value`,
          width: 110,
          align: "center",
        });
      }

      return header;
    },

    filteredVehicles() {
      if (!this.filter.vehicle && !this.filter.type && !this.filter.supplier) {
        return this.vehicles;
      }

      return this.vehicles.filter(vehicle => {
        const filterVehicle = this.filter.vehicle ? vehicle.id == this.filter.vehicle.id : true;
        const filterType = this.filter.type ? vehicle.type == this.filter.type : true;
        const filterSupplier = this.filter.supplier ? (vehicle.supplier.id == this.filter.supplier.id || vehicle.supplier.name == this.filter.supplier.name) : true;

        return filterVehicle && filterType && filterSupplier;
      });
    },

    years() {
      const currentYear = parseInt(moment().format('YYYY'));
      let years = [];

      for (let year = currentYear; year >= currentYear - 30; year--) {
        years.push(year);
      }

      return years;
    },

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasEditFleet() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "fleet-insert-edit" && o.tipo === "COMPONENTE");
    },

    hasDeleteFleet() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "fleet-delete" && o.tipo === "COMPONENTE");
    }
  },

  mounted() {
    this.loadFleet();
  },

  methods: {

    async loadFleet() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/equipamento/listaJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.vehicles = data.map(eqp => {
          return {
            id: eqp.id_equipamento,
            name: eqp.nome.trim(),
            description: eqp.descricao.trim(),
            invoice: eqp.numero_nota,
            value: parseFloat(eqp.valor) || null,
            brand: eqp.marca,
            model: eqp.modelo,
            aquisitionDate: eqp.data_aquisicao,
            manufacturedYear: parseInt(eqp.ano_fabricacao) || null,
            lifespan: parseInt(eqp.vida_util) || null,
            plate: eqp.placa.toUpperCase(),
            type: eqp.tipo_carga,
            consumptionPerKm: parseFloat(eqp.km_litros) || null,
            dailyCost: parseFloat(eqp.custo_diario_manutencao) || null,
            capacity: parseInt(eqp.capacidade) || null,
            percentageOfUsage: parseFloat(eqp.percentual_uso) || null,
            third: eqp.terceiro,
            supplier: {
              id: eqp.id_prestador,
              name: eqp.prestador
            },
            tanks: JSON.parse(eqp.tanque || '[]').map(value => ({ value })),
            tanque: JSON.parse(eqp.tanque),
            totalTanks: JSON.parse(eqp.tanque || '[]').length,
            dairies: JSON.parse(eqp.laticinios) || [ eqp.id_laticinio ],
          };
        });
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    onChangeType(value) {
      if (['leite', 'reboque'].includes(value) && this.form.tanks.length == 0)
      {
        this.changeTank('add')
      }
    },

    changeTank(type) {
      if (type == 'add') {
        this.form.tanks.push({ value: 0 });
      }
      else if (type == 'remove') {
        this.form.tanks.splice(-1, 1);
      }
    },

    newVehicle() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.form = {
        manufacturedYear: null,
        aquisitionDate: {
          picker: false,
          value: moment().format('YYYY-MM-DD'),
        },
        tanks: [],
        dairies: []
      };

      this.showDialog = true;
    },

    editVehicle(vehicle) {

      for (let indx in vehicle) {
        vehicle['aquisitionDate'] = {
          picker: false,
          value: isNull(moment(vehicle.aquisitionDate).format('YYYY-MM-DD')) ? moment().format('YYYY-MM-DD') : moment(vehicle.aquisitionDate).format('YYYY-MM-DD'),
        }
      }

      this.form = {
        ...vehicle
      };

      this.showDialog = true;
    },

    async removeVehicle({ id }) {
      if (!(await this.$root.$confirm('Remover veículo', 'Tem certeza que deseja remover este veículo?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/equipamento/inativaVeiculo`,
          {
            id_equipamento: id
          }
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.loadFleet();
        this.$snotify.success("Veículo excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir veículo", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const action = this.form.id ? 'atualizaVeiculo' : 'insereVeiculo';
        const { data } = await this.$axios.post(
          `/equipamento/${action}`,
          {
            id_equipamento: this.form.id,
            nome: this.form.description,
            numero_nota: this.form.invoice,
            valor: parseFloat(this.form.value) || null,
            marca: this.form.brand,
            modelo: this.form.model,
            data_aquisicao: this.form.aquisitionDate.value,
            ano_fabricacao: this.form.manufacturedYear,
            vida_util: parseInt(this.form.lifespan) || null,
            placa: this.form.plate,
            tipo_carga: this.form.type,
            km_litros: parseFloat(this.form.consumptionPerKm) || null,
            custo_diario_manutencao: parseFloat(this.form.dailyCost) || 0,
            capacidade: parseFloat(this.totalVol) || 0,
            percentual_uso: parseFloat(this.form.percentageOfUsage),
            terceiro: this.form.third ? 1 : 0,
            id_prestador: this.form.third ? this.form.supplier.id : null,
            prestador: this.form.third ? this.form.supplier.name : '',
            tanque: ['leite', 'reboque'].includes(this.form.type) ? this.form.tanks.map(({ value }) => parseFloat(value)) : [],
            laticinios: this.form.dairies
          }
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.showDialog = false;
        this.loadFleet();
        this.$snotify.success("Veículo salvo com sucesso", "Sucesso");
      } catch (e) {
        console.log(e);
        const message = _.get(e, 'response.data.message', e);
        console.log(message);
        this.$root.$progressBar.hide();
        if (message == 'Equipamento já inserido') {
          if (!(await this.$root.$confirm(
            'ATENÇÃO!',
            'PLACA JÁ CADASTRADA NO SISTEMA.',
            { color: 'red', noText: 'voltar a editar', yesText: 'Ok' },
          ))) {
            return;
          }
          this.showDialog = false;
          this.loadFleet();
          console.warn(`Erro ao salvar veículo: ${e}`);
        } else {
          this.$snotify.error(message, "Atenção");
        }
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async printQRCode({ id }) {
      try {

        const { data } = await this.$axios.post(
          `/equipamento/gerarQrcode`,
          { id_equipamento: id }
        );

        if (data.codigo === 0) {
          throw new Error(data.mensagem);
        }

        return printJS({
          printable: data,
          type: 'raw-html',
          css: [
            'https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css',
            'https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap-theme.min.css',
          ]
        });
      } catch (e) {
        console.log(e);
        this.$snotify.error(
          "Oops, erro ao gerar QR code",
          "Atenção"
        );
      }
    },

    printQrCodeFleet() {
      this.qrCodeDialog.isOpen = true;
      this.qrCodeDialog.ids = sortItems(this.filteredVehicles, this.filter.sort.by, this.filter.sort.desc, 'pt-BR').map(fleet => fleet.id);
    },

    async printQrCode(size) {
      try {
        this.qrCodeDialog.isOpen = false;
        this.$root.$progressBar.loading();

        const ids = this.qrCodeDialog.ids;

        const { data } = await this.$axios.post('/producer/frotaQrCode', { ids, size })

        return printJS({
          printable: data,
          type: 'raw-html'
        })
      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao imprimir as etiquetas!', 'Atenção');
        console.error(e)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    exportExcel() {
      this.$refs.report.exportExcel(null, 'Frota');
    },

    print() {
      this.$refs.report.print(null, 'Frota');
    },
  },
};
</script>
