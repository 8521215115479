<template>
  <div>
    <v-dialog
      v-model="visible"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-if="visit"
              ref="form"
              lazy-validation
              @submit.prevent="save()"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-menu
                    v-model="visitedAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="visitedAt"
                        label="Data *"
                        placeholder=" "
                        prepend-inner-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="visit.visitedAt"
                      @input="visitedAtMenu = false"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <masked-input
                    v-model="visit.startedAt"
                    label="Hora Chegada *"
                    placeholder=" "
                    prepend-inner-icon="schedule"
                    :mask="{
                      mask: '00:00',
                    }"
                    :rules="[
                      v => !!v || 'Campo obrigatório!',
                      v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
                    ]"
                    :max-length="16"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <masked-input
                    v-model="visit.endedAt"
                    label="Hora Saída *"
                    placeholder=" "
                    prepend-inner-icon="schedule"
                    :mask="{
                      mask: '00:00',
                    }"
                    :rules="[
                      v => !!v || 'Campo obrigatório!',
                      v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
                    ]"
                    :max-length="16"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="6"
                >
                  <v-autocomplete
                    v-model="visit.producer"
                    label="Produtor *"
                    prepend-inner-icon="person_outline"
                    placeholder=" "
                    :items="producers"
                    :rules="[v => !!v.id || 'Campo obrigatório!']"
                    item-value="id"
                    item-text="name"
                    return-object
                    required
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-select
                    v-model="visit.types"
                    :items="visitTypes"
                    label="Tipo de visita *"
                    multiple
                    required
                    :rules="[v => !!v && v.length > 0 || 'Escolha o tipo de visita']"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="visit.obs"
                    label="Observação"
                    placeholder=" "
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import MaskedInput from "@/Support/Components/MaskedInput.vue";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

const service = new VisitsService();

export default {

  components: {
    MaskedInput,
  },

  data() {
    return {
      loading: false,

      // Date Picker
      visitedAtMenu: false,

      visible: false,
      title: '',
      visit: null,

      producers: [],

      visitTypes: service.typesFilter,
    };
  },

  computed: {

    visitedAt() {
      if (_.isEmpty(this.visit)) {
        return moment().format('DD/MM/YYYY');
      }

      return moment(this.visit.visitedAt).format('DD/MM/YYYY');
    },

  },

  async mounted() {
    await this.loadProducers();
  },

  methods: {
    disableDotAndComma(e) {
      if (e.charCode === 46 || e.charCode === 44) {
        e.preventDefault()
      }
    },

    async loadProducers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/produtores`);

        if (_.isString(data)) {
          throw data;
        }

        this.producers = data.map(producer => {
          return {
            id: producer.id_pessoa,
            code: producer.codigo_laticinio,
            name: producer.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      }
    },

    show(visit) {
      // Verifica se já existe um formulário carregado em memória e o reseta
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      if (!visit.id) {
        this.title = `Nova Visita`;
      } else {
        this.title = `Editando Visita`;
      }

      this.visit = visit;
      this.visible = true;
    },

    close() {
      this.loading = false;
      this.title = ``;
      this.visible = false;
      this.visit = null;
    },

    save() {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return;
      }

      this.loading = true;

      return this.$emit('onSave', this.visit);
    },
  },

}
</script>
