<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color, overflow: 'hidden'}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        engineering
      </v-icon>
      <span class="title font-weight-light">
        Ganhos e Perdas por Técnico
      </span>
    </v-card-title>
    <v-tabs
      v-model="tab"
      align-with-title
      background-color="transparent"
      class="transparent"
      dark
    >
      <v-tabs-slider color="cyan" />

      <v-tab>
        <v-icon class="mr-2">
          analytics
        </v-icon>
        Volume
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          analytics
        </v-icon>
        Produtores
      </v-tab>
      <v-tab><v-icon>list</v-icon></v-tab>

      <v-tab-item>
        <v-chart
          :options="optionsVolume"
          :style="{width: '100%', height: `${height}px !important`}"
          autoresize
        />
      </v-tab-item>

      <v-tab-item>
        <v-chart
          :options="optionsProducers"
          :style="{width: '100%', height: `${height}px !important`}"
          autoresize
        />
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="itemsComputed"
          dark
          class="elevation-1 report-table"
          dense
          hide-default-footer
          disable-pagination
          show-expand
          single-expand
          item-key="tecnico.id"
          @item-expanded="onDetails"
        >
          <template #[`item.vinculados.volume`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.desvinculados.volume`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.saldo.volume`]="{ value }">
            <v-chip
              small
              :color="getChipColor(value)"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.vinculados.total`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.desvinculados.total`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.saldo.total`]="{ value }">
            <v-chip
              small
              :color="getChipColor(value)"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.vinculados_comp.volume`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.desvinculados_comp.volume`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.saldo_comp.volume`]="{ value }">
            <v-chip
              small
              :color="getChipColor(value)"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.vinculados_comp.total`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.desvinculados_comp.total`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.saldo_comp.total`]="{ value }">
            <v-chip
              small
              :color="getChipColor(value)"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-row no-gutters>
                <v-col cols="12">
                  <div v-if="!item.details">
                    Carregando...
                  </div>
                  <v-card
                    v-else
                    class="mt-3 technical-visit-card"
                    dark
                  >
                    <v-card-title>
                      <span class="text-subtitle-2">{{ item.tecnico.nome }}</span>
                    </v-card-title>

                    <v-data-table
                      :headers="headersDetails"
                      :items="item.details.items"
                      class="technical-visit-table"
                      dark
                      dense
                      hide-default-footer
                      disable-pagination
                    >
                      <template #[`item.vinculados.volume`]="{ value }">
                        {{ formatNumber(value) }}
                      </template>
                      <template #[`item.desvinculados.volume`]="{ value }">
                        {{ formatNumber(value) }}
                      </template>

                      <template #[`item.saldo.volume`]="{ value }">
                        <v-chip
                          small
                          :color="getChipColor(value)"
                        >
                          {{ formatNumber(value) }}
                        </v-chip>
                      </template>

                      <template #[`item.vinculados.total`]="{ value }">
                        {{ formatNumber(value) }}
                      </template>
                      <template #[`item.desvinculados.total`]="{ value }">
                        {{ formatNumber(value) }}
                      </template>

                      <template #[`item.saldo.total`]="{ value }">
                        <v-chip
                          small
                          :color="getChipColor(value)"
                        >
                          {{ formatNumber(value) }}
                        </v-chip>
                      </template>

                      <template #[`item.vinculados_comp.volume`]="{ value }">
                        {{ formatNumber(value) }}
                      </template>
                      <template #[`item.desvinculados_comp.volume`]="{ value }">
                        {{ formatNumber(value) }}
                      </template>

                      <template #[`item.saldo_comp.volume`]="{ value }">
                        <v-chip
                          small
                          :color="getChipColor(value)"
                        >
                          {{ formatNumber(value) }}
                        </v-chip>
                      </template>

                      <template #[`item.vinculados_comp.total`]="{ value }">
                        {{ formatNumber(value) }}
                      </template>
                      <template #[`item.desvinculados_comp.total`]="{ value }">
                        {{ formatNumber(value) }}
                      </template>

                      <template #[`item.saldo_comp.total`]="{ value }">
                        <v-chip
                          small
                          :color="getChipColor(value)"
                        >
                          {{ formatNumber(value) }}
                        </v-chip>
                      </template>

                      <template
                        #[`body.append`]="{}"
                      >
                        <tr
                          class="hidden-xs-only"
                        >
                          <td class="text-start cell-border">
                            <b>Total</b>
                          </td>

                          <td class="text-center subtitle-1">
                            <b>{{ formatNumber(item.details.total.linked.volume) }}</b>
                          </td>
                          <td class="text-center subtitle-1">
                            <b>{{ formatNumber(item.details.total.unlinked.volume) }}</b>
                          </td>
                          <td class="text-center cell-border">
                            <v-chip
                              small
                              :color="getChipColor(item.details.total.balance.volume)"
                            >
                              <b>{{ formatNumber(item.details.total.balance.volume) }}</b>
                            </v-chip>
                          </td>

                          <template v-if="hasComparison">
                            <td class="text-center subtitle-1">
                              <b>{{ formatNumber(item.details.total.linked_comp.volume) }}</b>
                            </td>
                            <td class="text-center subtitle-1">
                              <b>{{ formatNumber(item.details.total.unlinked_comp.volume) }}</b>
                            </td>
                            <td class="text-center cell-border">
                              <v-chip
                                small
                                :color="getChipColor(item.details.total.balance_comp.volume)"
                              >
                                <b>{{ formatNumber(item.details.total.balance_comp.volume) }}</b>
                              </v-chip>
                            </td>
                          </template>

                          <td class="text-center subtitle-1">
                            <b>{{ formatNumber(item.details.total.linked.producers) }}</b>
                          </td>
                          <td class="text-center subtitle-1">
                            <b>{{ formatNumber(item.details.total.unlinked.producers) }}</b>
                          </td>
                          <td class="text-center">
                            <v-chip
                              small
                              :color="getChipColor(item.details.total.balance.producers)"
                            >
                              <b>{{ formatNumber(item.details.total.balance.producers) }}</b>
                            </v-chip>
                          </td>

                          <template v-if="hasComparison">
                            <td class="text-center subtitle-1">
                              <b>{{ formatNumber(item.details.total.linked_comp.producers) }}</b>
                            </td>
                            <td class="text-center subtitle-1">
                              <b>{{ formatNumber(item.details.total.unlinked_comp.producers) }}</b>
                            </td>
                            <td class="text-center">
                              <v-chip
                                small
                                :color="getChipColor(item.details.total.balance_comp.producers)"
                              >
                                <b>{{ formatNumber(item.details.total.balance_comp.producers) }}</b>
                              </v-chip>
                            </td>
                          </template>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<style lang="scss">
.cell-border {
  border-right: thin solid rgba(255, 255, 255, 0.12);
}
</style>

<script>
import moment from 'moment';
import isArray from "lodash/fp/isArray"
import upperFirst from "lodash/fp/upperFirst"

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    comparisonPeriod: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      loading: false,
      tab: null,

      items: [],
      itemsComparison: [],
    }
  },

  computed: {
    hasComparison() {
      return this.itemsComparison.length > 0
    },
    headers() {
      if (!this.hasComparison) {
        return [
          { text: 'Técnico', value: 'tecnico.nome', cellClass: 'cell-border' },
          { text: 'Ganho (Volume)', value: 'vinculados.volume', align: 'center' },
          { text: 'Perdido (Volume)', value: 'desvinculados.volume', align: 'center' },
          { text: 'Saldo (Volume)', value: 'saldo.volume', align: 'center', cellClass: 'cell-border' },
          { text: 'Ganho (Produtores)', value: 'vinculados.total', align: 'center' },
          { text: 'Perdido (Produtores)', value: 'desvinculados.total', align: 'center' },
          { text: 'Saldo (Produtores)', value: 'saldo.total', align: 'center' }
        ];
      }
      return [
        { text: 'Técnico', value: 'tecnico.nome', cellClass: 'cell-border' },

        { text: `Ganho (L) ${this.titleExtended}`, value: 'vinculados.volume', align: 'center' },
        { text: `Perdido (L) ${this.titleExtended}`, value: 'desvinculados.volume', align: 'center' },
        { text: `Saldo (L) ${this.titleExtended}`, value: 'saldo.volume', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (L) ${this.titleComparisonExtended}`, value: 'vinculados_comp.volume', align: 'center' },
        { text: `Perdido (L) ${this.titleComparisonExtended}`, value: 'desvinculados_comp.volume', align: 'center' },
        { text: `Saldo (L) ${this.titleComparisonExtended}`, value: 'saldo_comp.volume', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (Pr) ${this.titleExtended}`, value: 'vinculados.total', align: 'center' },
        { text: `Perdido (Pr) ${this.titleExtended}`, value: 'desvinculados.total', align: 'center' },
        { text: `Saldo (Pr) ${this.titleExtended}`, value: 'saldo.total', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (Pr) ${this.titleComparisonExtended}`, value: 'vinculados_comp.total', align: 'center' },
        { text: `Perdido (Pr) ${this.titleComparisonExtended}`, value: 'desvinculados_comp.total', align: 'center' },
        { text: `Saldo (Pr) ${this.titleComparisonExtended}`, value: 'saldo_comp.total', align: 'center' }
      ]
    },

    headersDetails() {
      if (!this.hasComparison) {
        return [
          { text: 'Laticínio Concorrente', value: 'laticinio_concorrente.nome', cellClass: 'cell-border' },
          { text: 'Ganho (Volume)', value: 'vinculados.volume', align: 'center' },
          { text: 'Perdido (Volume)', value: 'desvinculados.volume', align: 'center' },
          { text: 'Saldo (Volume)', value: 'saldo.volume', align: 'center', cellClass: 'cell-border' },
          { text: 'Ganho (Produtores)', value: 'vinculados.total', align: 'center' },
          { text: 'Perdido (Produtores)', value: 'desvinculados.total', align: 'center' },
          { text: 'Saldo (Produtores)', value: 'saldo.total', align: 'center' }
        ];
      }
      return [
        { text: 'Laticínio Concorrente', value: 'laticinio_concorrente.nome', cellClass: 'cell-border' },

        { text: `Ganho (L) ${this.titleExtended}`, value: 'vinculados.volume', align: 'center' },
        { text: `Perdido (L) ${this.titleExtended}`, value: 'desvinculados.volume', align: 'center' },
        { text: `Saldo (L) ${this.titleExtended}`, value: 'saldo.volume', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (L) ${this.titleComparisonExtended}`, value: 'vinculados_comp.volume', align: 'center' },
        { text: `Perdido (L) ${this.titleComparisonExtended}`, value: 'desvinculados_comp.volume', align: 'center' },
        { text: `Saldo (L) ${this.titleComparisonExtended}`, value: 'saldo_comp.volume', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (Pr) ${this.titleExtended}`, value: 'vinculados.total', align: 'center' },
        { text: `Perdido (Pr) ${this.titleExtended}`, value: 'desvinculados.total', align: 'center' },
        { text: `Saldo (Pr) ${this.titleExtended}`, value: 'saldo.total', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (Pr) ${this.titleComparisonExtended}`, value: 'vinculados_comp.total', align: 'center' },
        { text: `Perdido (Pr) ${this.titleComparisonExtended}`, value: 'desvinculados_comp.total', align: 'center' },
        { text: `Saldo (Pr) ${this.titleComparisonExtended}`, value: 'saldo_comp.total', align: 'center' }
      ]
    },
    itemsComputed() {
      if (!this.hasComparison) {
        return this.items;
      }

      const current = this.items.reduce((acc, item) => {
        acc[item.tecnico.id] = {
          desvinculados_comp: { total: 0, volume: 0 },
          vinculados_comp: { total: 0, volume: 0 },
          saldo_comp: { total: 0, volume: 0 },
          ...item
        };
        return acc;
      }, {})

      const items = this.itemsComparison.reduce((acc, item) => {
        const id = item.tecnico.id;
        if (!(id in acc)) {
          acc[id] = {
            tecnico: item.tecnico,
            desvinculados: { total: 0, volume: 0 },
            vinculados: { total: 0, volume: 0 },
            saldo: { total: 0, volume: 0 },
          };
        }

        acc[id] = {
          ...acc[id],
          desvinculados_comp: item.desvinculados,
          vinculados_comp: item.vinculados,
          saldo_comp: item.saldo,
        }
        return acc;
      }, current);

      return Object.values(items);
    },
    height() {
      return 350
    },
    optionsVolume() {
      const label = {
        show: true,
        lineHeight: 17,
        height: 17,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      };

      const series = [
        {
          name: `Perdas (L) ${this.titleExtended}`,
          type: 'bar',
          areaStyle: {},
          showSymbol: true,
          emphasis: {
            focus: 'series'
          },
          data: this.itemsComputed.map((item) => item.desvinculados.volume),
          label
        },
        {
          name: `Ganhos (L) ${this.titleExtended}`,
          type: 'bar',
          areaStyle: {},
          showSymbol: true,
          emphasis: {
            focus: 'series'
          },
          data: this.itemsComputed.map((item) => item.vinculados.volume),
          label
        }
      ];

      if (this.hasComparison) {
        series.push(
          {
            name: `Perdas (L) ${this.titleComparisonExtended}`,
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.itemsComputed.map((item) => item.desvinculados_comp.volume),
            label
          },
          {
            name: `Ganhos (L) ${this.titleComparisonExtended}`,
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.itemsComputed.map((item) => item.vinculados_comp.volume),
            label
          }
        );
      }

      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(108, 25, 25, 0.7)', 'rgba(15, 86, 94, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b>`)
            ].join('<br>')
          }
        },
        toolbox: {
          show: this.hasComparison,
          top: '0%',
          right: '4%',
          iconStyle: {
            borderColor: '#fff'
          },
          feature: {
            magicType: {
              title: '',
              type: ['line', 'bar']
            },
          }
        },
        legend: {
          show: this.hasComparison,
          textStyle: {
            color: '#ddd',
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.itemsComputed.map(item => item.tecnico.nome),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
        },
        series
      }
    },
    optionsProducers() {
      const label = {
        show: true,
        lineHeight: 17,
        height: 17,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      };

      const series = [
        {
          name: `Perdas ${this.titleExtended}`,
          type: 'bar',
          areaStyle: {},
          showSymbol: true,
          emphasis: {
            focus: 'series'
          },
          data: this.itemsComputed.map((item) => item.desvinculados.total),
          label
        },
        {
          name: `Ganhos ${this.titleExtended}`,
          type: 'bar',
          areaStyle: {},
          showSymbol: true,
          emphasis: {
            focus: 'series'
          },
          data: this.itemsComputed.map((item) => item.vinculados.total),
          label
        }
      ];

      if (this.hasComparison) {
        series.push(
          {
            name: `Perdas ${this.titleComparisonExtended}`,
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.itemsComputed.map((item) => item.desvinculados_comp.total),
            label
          },
          {
            name: `Ganhos ${this.titleComparisonExtended}`,
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.itemsComputed.map((item) => item.vinculados_comp.total),
            label
          }
        );
      }

      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(108, 25, 25, 0.7)', 'rgba(15, 86, 94, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b>`)
            ].join('<br>')
          }
        },
        toolbox: {
          show: this.hasComparison,
          top: '0%',
          right: '4%',
          iconStyle: {
            borderColor: '#fff'
          },
          feature: {
            magicType: {
              title: '',
              type: ['line', 'bar']
            },
          }
        },
        legend: {
          show: this.hasComparison,
          textStyle: {
            color: '#ddd',
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.itemsComputed.map(item => item.tecnico.nome),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
        },
        series
      }
    },

    comparisonType() {
      if (this.comparisonPeriod.length < 2) {
        return 'NONE';
      }

      const [startDate] = this.period;

      const [startDateComp] = this.comparisonPeriod;

      if (moment(startDate).isSame(startDateComp, 'month') && !moment(startDate).isSame(startDateComp, 'year')) {
        return 'YEAR';
      }

      return 'MONTH';
    },

    titleExtended() {
      const [date] = this.period;
      if (this.comparisonType === 'MONTH') {
        return `- ${upperFirst(moment(date).format('MMM/YYYY'))}`;
      }
      if (this.comparisonType === 'YEAR') {
        return `- ${upperFirst(moment(date).format('YYYY'))}`;
      }
      return '';
    },

    titleComparisonExtended() {
      const [date] = this.comparisonPeriod;
      if (this.comparisonType === 'MONTH') {
        return `- ${upperFirst(moment(date).format('MMM/YYYY'))}`;
      }
      if (this.comparisonType === 'YEAR') {
        return `- ${upperFirst(moment(date).format('YYYY'))}`;
      }
      return '';
    },
  },

  watch: {
    period() {
      this.loadReport();
    },
    comparisonPeriod() {
      this.loadComparisonReport();
    }
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(
          `/relatorios/resumoVinculacaoPorTecnico`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.items = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async loadComparisonReport() {
      try {
        if (this.comparisonPeriod.length < 2) {
          this.itemsComparison = [];
          return;
        }
        this.loading = true;

        const [startDate, endDate] = this.comparisonPeriod;

        const { data } = await this.$axios.post(
          `/relatorios/resumoVinculacaoPorTecnico`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.itemsComparison = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async onDetails({ item, value }) {
      try {
        // Verifica se os dados já foram carregados do servidor
        if (item.details) {
          return;
        }

        // Não faz nada quando fechar
        if (!value) {
          return;
        }

        this.loading = true;

        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/relatorios/detalheVinculacaoPorTecnico`,
          { data_inicio: startDate, data_fim: endDate, id_tecnico: item.tecnico.id }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        const initial = {
          linked: {
            producers: 0,
            volume: 0,
          },
          unlinked: {
            producers: 0,
            volume: 0,
          },
          balance: {
            producers: 0,
            volume: 0,
          },
          linked_comp: {
            producers: 0,
            volume: 0,
          },
          unlinked_comp: {
            producers: 0,
            volume: 0,
          },
          balance_comp: {
            producers: 0,
            volume: 0,
          },
        }

        let total = data.reduce((acc, cur) => {
          acc.linked.producers += cur.vinculados.total;
          acc.linked.volume += cur.vinculados.volume;
          acc.unlinked.producers += cur.desvinculados.total;
          acc.unlinked.volume += cur.desvinculados.volume;
          acc.balance.producers += cur.saldo.total;
          acc.balance.volume += cur.saldo.volume;
          return acc;
        }, initial);

        if (this.comparisonPeriod.length) {
          const [startDateComp, endDateComp] = this.comparisonPeriod;

          const { data: dataComp } = await this.$axios.post(
            `/relatorios/detalheVinculacaoPorTecnico`,
            { data_inicio: startDateComp, data_fim: endDateComp, id_tecnico: item.tecnico.id }
          );

          if (!isArray(dataComp)) {
            throw new Error(dataComp);
          }

          const current = data.reduce((acc, item) => {
            acc[item.laticinio_concorrente.id] = {
              desvinculados_comp: { total: 0, volume: 0 },
              vinculados_comp: { total: 0, volume: 0 },
              saldo_comp: { total: 0, volume: 0 },
              ...item
            };
            return acc;
          }, {})

          const items = dataComp.reduce((acc, item) => {
            const id = item.laticinio_concorrente.id;
            if (!(id in acc)) {
              acc[id] = {
                laticinio_concorrente: item.laticinio_concorrente,
                desvinculados: { total: 0, volume: 0 },
                vinculados: { total: 0, volume: 0 },
                saldo: { total: 0, volume: 0 },
              };
            }

            acc[id] = {
              ...acc[id],
              desvinculados_comp: item.desvinculados,
              vinculados_comp: item.vinculados,
              saldo_comp: item.saldo,
            }
            return acc;
          }, current);

          data = Object.values(items);

          total = dataComp.reduce((acc, cur) => {
            acc.linked_comp.producers += cur.vinculados.total;
            acc.linked_comp.volume += cur.vinculados.volume;
            acc.unlinked_comp.producers += cur.desvinculados.total;
            acc.unlinked_comp.volume += cur.desvinculados.volume;
            acc.balance_comp.producers += cur.saldo.total;
            acc.balance_comp.volume += cur.saldo.volume;
            return acc;
          }, total);
        }

        this.items = this.items.map(visit => {
          if (visit.tecnico.id !== item.tecnico.id) {
            return visit;
          }

          return {
            details: {
              items: data,
              total
            },
            ...visit,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os detalhes!", "Atenção");

        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    getChipColor(value) {
      if (value > 0) {
        return 'blue accent-3';
      }

      if (value < 0) {
        return 'red accent-3';
      }

      return null;
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
