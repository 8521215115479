<template>
  <v-text-field
    ref="input"
    v-model="localValue"
    :prefix="prefix"
    :label="label"
    :placeholder="placeholder"
    :maxlength="maxlength"
    :rules="rules"
    v-bind="$attrs"
  />
</template>

<script>
import IMask from "imask";
import _ from "lodash";

export default {
  props: {
    prefix: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
    },
    rules: {
      type: Array
    },
    mask: {
      type: Object
    },
    placeholder: {
      type: String
    },
    maxlength: {
      type: String
    }
  },

  computed: {
    localValue: {
      get() {
        
        if (this.value === undefined || _.isEmpty(this.value)) {
          return this.value;
        }

        return IMask.pipe(this.value.toString(), this.mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
      },
      set(newValue) {
        const value = IMask.pipe(newValue, this.mask, IMask.PIPE_TYPE.MASKED, IMask.PIPE_TYPE.UNMASKED);

        this.$emit('input', value);
      },
    }
  },

  methods: {
    onKeyUpEnter(event) {
      return this.$emit('onEnter', event);
    },

    focus() {
      this.$refs.input.focus();
    }
  },

}
</script>