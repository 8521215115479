<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="text-xs-center"
      >
        <h2 class="menu-header white--text mt-0 mb-2">
          Descontos (Produtor)
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <discounts />
  </div>
</template>

<script>
import Discounts from "@/Domains/Financial/ProducerDiscounts/Views/Discounts.vue";

export default {
  name: "producer-total-discounts",

  components: {
    Discounts,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>