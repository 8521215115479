<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        icon-antibioticos
      </v-icon>
      <span class="title font-weight-light">Total de Antibióticos por Princípio Ativo</span>
    </v-card-title>

    <v-chart
      :options="options"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '188px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      graph: {},
      totalPrincipios: 0,
      colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384'],
    };
  },

  computed: {
    options() {
      const data = Object.values(this.graph);
      const labels = Object.keys(this.graph);

      return {
        tooltip: {
          trigger: 'item',
          formatter: function (param) {
            const name = param.name === 'S/PA' ? 'Sem Princípio Ativo' : param.name;
            return `${name}<br>${param.marker} ${param.seriesName}: <b>${param.value}</b> (${param.percent}%)`;
          }
        },
        legend: {
          orient: 'horizontal',
          bottom: 0,
          textStyle: {
            color: '#ffffff',
          },
        },
        series: [
          {
            name: 'Princípios Ativos',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['50%', '45%'],
            itemStyle: {
              borderRadius: 0
            },
            label: {
              show: true,
              color: '#fff',
              textBorderWidth: 0,
              textBorderColor: 'transparent',
              textShadowBlur: 0
            },
            data: labels.map((label, index) => ({
              value: data[index],
              name: label,
              itemStyle: {
                color: this.colors[index % this.colors.length],
              },
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              }
            }
          }
        ]
      };
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          '/descargaColeta/coletasPorPrincipioAtivo',
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.graph = data.reduce((acc, cur) => {
          const key = cur.principio_ativo ? cur.principio_ativo : 'S/PA';
          acc[key] = cur.total_registros;
          return acc;
        }, {});

        this.totalPrincipios = data.reduce((acc, cur) => acc + cur.total_registros, 0);

      } catch (e) {
        this.$snotify.error("Erro ao carregar os dados de antibióticos!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
