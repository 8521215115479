<template>
  <div>
    <v-row
      v-if="data"
    >
      <v-col
        md="4"
      >
        {{ data.goalText }}
      </v-col>
      <v-col
        md="3"
      >
        {{ data.textIni }}
      </v-col>
      <v-col
        md="3"
      >
        {{ data.goalIndicator }}
      </v-col>
      <v-col
        md="2"
      >
        {{ data.goalResult }}
        <v-icon
          right
          :color="data.icone=='verified' ? 'green darken-1' : 'red darken-1' "
        >
          verified
        </v-icon>
      </v-col>
    </v-row>
    <v-overlay
      v-model="loading"
      absolute
    >
      <v-card-text>
        Carregando Qualidade...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "quality-report",

  components: {
  },

  props: {
    goal: Object,

    filter: Object,
  },

  data() {
    return {
      loading: false,
      media: [],
      valueFirst: '',
      data: {},
    };
  },

  computed: {
    indicador() {
      return this.goal.indicador;
    },
  },

  watch: {
    filter: {
      handler() {
        this.loadIndicators();
      },
      deep: true
    }

  },
  mounted() {
    this.loadIndicators();
  },

  methods: {
    async loadIndicators() {
      this.loading = true;

      if (this.indicador == 'CCS' && !this.goal.media) {
        this.tituloGrafico = 'Média Geométrica CCS * (1000) ' ;
        await this.loadCCS();
      }
      else if (this.indicador == 'CPP' && !this.goal.media) {
        this.tituloGrafico = 'Média Geométrica CPP * (1000)' ;
        await this.loadCPP();
      }
      else if (this.indicador == 'gordura' && !this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Gordura';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'proteina' && !this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Proteína';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'caseina' && !this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Caseína';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'solidos_totais' && !this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Solídos Totais';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'volume' && !this.goal.media) {
        this.tituloGrafico = 'Volume de Coleta';
        await this.loadVolume();
      }
      else if (this.indicador == 'CPP' && this.goal.media ) {
        this.tituloGrafico = 'Média Geométrica CPP * (1000)' ;
        await this.loadProdutoresCPP();
      }
      else if (this.indicador == 'CCS' && this.goal.media ) {
        this.tituloGrafico = 'Média Geométrica CCS * (1000)' ;
        await this.loadProdutoresCCS();
      }
      else if (this.indicador == 'gordura' && this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Gordura';
        await this.loadProdutoresIndicador();
      }
      else if (this.indicador == 'proteina' && this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Proteína';
        await this.loadProdutoresIndicador();
      }
      else if (this.indicador == 'caseina' && this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Caseína';
        await this.loadProdutoresIndicador();
      }
      else if (this.indicador == 'solidos_totais' && this.goal.media) {
        this.tituloGrafico = 'Média Aritmética Solídos Totais';
        await this.loadProdutoresIndicador();
      }
      else if (this.indicador == 'volume' && this.goal.media) {
        this.tituloGrafico = 'Volume de Coleta';
        await this.loadProdutoresVolume();
      }

      this.loading = false;
    },

    async loadCCS() {

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaCcsPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'), //pega inicio do projeto para ter a media de referencia
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
          })
        );

        const media = data.map((o) => {
          return {
            media: o.ccs,
            data: o.data,
          }
        });

        this.parseData(media);

      } catch (error) {
        console.log(error);
      }
    },

    async loadCPP() {

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaCppPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'), //pega inicio do projeto para ter a media de referencia
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
          })
        );

        const media = data.map((o) => {
          return {
            media: o.cpp,
            data: o.data,
          }
        });

        this.parseData(media);

      } catch (error) {
        console.log(error);
      }
    },

    async loadMediaIndicador() {

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaAritmeticaIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: this.indicador,
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
          })
        );

        this.parseData(data);

      } catch (error) {
        console.log(error);
      }
    },

    async loadVolume() {
      try {
        const { data } = await this.$axios.post(
          `/relatorios/volumeMes`,
          {
            data_inicio: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
          }
        );

        const volume = data.map((o) => {
          return {
            media: o.total,
            data: o.mes + "-01",
          }
        });

        this.parseData(volume);

      } catch (error) {
        console.log(error);
      }
    },

    async loadProdutoresCPP() {
      this.loading = true
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/ProdutoresMediaCppPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            media: this.goal.media || 300,
            acao: this.goal.acao || "ABAIXO",
          })
        );

        const media = data.map((o) => {
          return {
            acima: (this.goal.acao === "ABAIXO") ? o.acima + o.igual : + o.acima,
            abaixo: (this.goal.acao === "ACIMA") ?  o.abaixo + o.igual : + o.abaixo,
            data: o.data,
          }
        });

        this.parseData(media);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProdutoresCCS() {
      this.loading = true
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/ProdutoresMediaCcsPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            media: this.goal.media || 500,
            acao: this.goal.acao || "ABAIXO",
          })
        );

        const media = data.map((o) => {
          return {
            acima: (this.goal.acao === "ABAIXO") ? o.acima + o.igual : + o.acima,
            abaixo: (this.goal.acao === "ACIMA") ?  o.abaixo + o.igual : + o.abaixo,
            data: o.data,
          }
        });

        this.parseData(media);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProdutoresIndicador() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/ProdutoresIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: this.indicador,
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            media: this.goal.media || 0,
            acao: this.goal.acao || "ABAIXO",
          })
        );
        this.parseData(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProdutoresVolume() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/volumeProdutorMes`,
          {
            data_inicio: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            media: this.goal.media || 500,
            acao: this.goal.acao || "ABAIXO",
          }
        );

        this.parseData(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    parseData(data) {
      let tipo = 0;
      let meta = 0;
      let acao = '';

      if (!_.isEmpty(this.filter.project.quality) ) {
        this.filter.project.quality.map(q => {
          if ( q.indicador == this.indicador  && q.media == this.goal.media) {
            meta = this.formatNumber(q.percentual);
            tipo = q.tipo;
            acao = q.acao;
          }
        })
      }

      //primeira media para ser utilizado como referencia
      if (_.isEmpty(this.valueFirst) && (acao == 'REDUZIR' || acao == 'AUMENTAR')) {
        this.valueFirst = data[0].media ;
      } else  if (_.isEmpty(this.valueFirst) && acao == 'ACIMA' ) {
        this.valueFirst = data[0].acima ;
      } else  if (_.isEmpty(this.valueFirst) && acao == 'ABAIXO' ) {
        this.valueFirst = data[0].abaixo ;
      }

      this.media = data.map(o => {
        let icone = 'cancel';
        let metaCalculada;

        if (tipo == "UN") {
          metaCalculada =  meta
        } else {
          if (acao == "ACIMA" || acao == "ABAIXO") {
            if (acao == "ACIMA") {
              metaCalculada = (o.acima * meta) / 100;
            } else if (acao == "ABAIXO") {
              metaCalculada = (o.abaixo * meta) / 100;
            }

          } else if (acao == 'REDUZIR') {
            metaCalculada = parseFloat(this.valueFirst) - (this.valueFirst / 100) * meta;
          } else {
            metaCalculada = parseFloat(this.valueFirst) + (this.valueFirst / 100) * meta;
          }
        }

        if ((acao == 'AUMENTAR' && parseFloat(o.media) > parseFloat(metaCalculada)) ||
            (acao == 'REDUZIR' && parseFloat(o.media) < parseFloat(metaCalculada)) ) {
          icone = 'verified';
        } else if (acao == 'ACIMA'  && parseFloat(o.acima) > parseFloat(metaCalculada)) {
          icone = 'verified';
        } else if (acao == 'ABAIXO' && parseFloat(o.abaixo) < parseFloat(metaCalculada))  {
          icone = 'verified';
        }

        return {
          media: o.media || 0,
          data: o.data,
          abaixo: o.abaixo || 0,
          acima: o.acima || 0,
          goals: metaCalculada,
          goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta),
          icone: icone
        }
      });

      if (!_.isEmpty(this.media)) {
        let metaTexto = '';
        let metaValor = '';
        let lastValue;
        let textIni = '';
        let goalResult = '';
        let goalIndicator = '';

        if (!this.goal.media) {
          metaTexto =   `${this.goal.acao} média ${this.goal.indicador} ${this.goal.percentual}` + (this.goal.tipo == "PERCENT" ? "%" : "");
          metaValor =  ` ( Meta ${this.goal.indicador}: ${parseInt(this.media[0].goals)} )`;
        } else {
          metaTexto = `Adequar ${this.goal.percentual}` + (this.goal.tipo == "PERCENT" ? "% dos" : "") + ` produtores com ${this.goal.indicador} ${this.goal.acao} de ${this.goal.media} `;
        }
        if (acao == 'ACIMA') {
          lastValue = parseInt(this.media[this.media.length - 1].acima);
        } else if (acao == 'ABAIXO') {
          lastValue = parseInt(this.media[this.media.length - 1].abaixo);
        } else {
          lastValue = parseInt(this.media[this.media.length - 1].media);
        }

        if (acao == 'REDUZIR' || acao == 'AUMENTAR') {
          textIni = `Média em ${ moment(this.media[0].data).format("MM/YYYY") }: ${parseInt(this.valueFirst) }`;
          goalResult = `Média em ${moment(this.media[this.media.length - 1].data).format("MM/YYYY")}: ${lastValue}`;
          goalIndicator = `Meta ${this.goal.indicador}: ${parseInt(this.media[0].goals)} `;
        } else {
          textIni = `Quantidade de produtores em ${ moment(this.media[this.media.length - 1].data).format("MM/YYYY") }: ${lastValue}`;
        }

        this.data = {
          goalText: 'Meta: ' + metaTexto + metaValor,
          textIni: textIni,
          goalIndicator: goalIndicator,
          goalResult: goalResult,
          icone: this.media[this.media.length - 1].icone,
        }
        //retorna dados para impressao
        return this.$emit("onLoadQuality", this.data);
      }

    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
