<template>
  <v-dialog
    v-model="show"
    width="500"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Tipo de Análise de Produto
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-autocomplete
                v-model="form.item"
                :items="items"
                label="Produto *"
                item-value="id"
                item-text="description"
                return-object
                prepend-inner-icon="inventory"
                :rules="[rules.requiredObject]"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-autocomplete
                v-model="form.analysis"
                :items="analysisTypes"
                label="Análise *"
                item-value="id"
                item-text="description"
                return-object
                prepend-inner-icon="biotech"
                :rules="[rules.requiredObject]"
              >
                <template
                  #selection="{ item }"
                >
                  {{ item.description }}
                  <div
                    class="mx-1 caption font-weight-light"
                  >
                    ({{ getType(item.type) }})
                  </div>
                </template>

                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.description" />
                    <v-list-item-subtitle>
                      <v-chip
                        x-small
                        color="orange darken-1"
                        text-color="white"
                      >
                        {{ getType(item.type) }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-select
                v-model="form.periodicity"
                :items="periodicities"
                label="Periodicidade *"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.quantity"
                label="Qtde amostras período *"
                :mask="masks.integer"
                unmask
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

export default {

  components: {
    MaskedTextField,
  },

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      form: {},

      items: [],
      analysisTypes: [],
      periodicities: [
        { value: 'ANUAL', text: 'Anual' },
        { value: 'MENSAL', text: 'Mensal' },
      ],

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        requiredObject: v => !!v && !!v.id || 'Campo obrigatório!',
      },

      masks: {
        integer: { mask: Number, min: 0, scale: 0, signed: false },
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = {}

        if (this.editId) {
          this.load();
        }
      }
    }
  },

  created() {
    this.getItems();
    this.getAnalysisTypes();
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/registrations/analysis/product/${ this.editId }`);

        this.form = {
          item: {
            id: data.produto?.id_item,
            name: data.produto?.nome,
            code: data.produto?.cod_item,
            description: data.produto?.cod_item ? `${data.produto?.cod_item} - ${data.produto?.nome}` : data.produto?.nome,
          },
          analysis: {
            id: data.analise?.id_tipo_analise,
            description: data.analise?.descricao,
            type: data.analise?.natureza,
          },
          itemId: data.id_item,
          analysisId: data.id_tipo_analise,
          periodicity: data.periodicidade,
          quantity: data.quantidade_periodo,
        };
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;

        const payload = {
          id_item: this.form.item?.id,
          id_tipo_analise: this.form.analysis?.id,
          periodicidade: this.form.periodicity,
          quantidade_periodo: this.form.quantity,
        };

        const data = this.editId ? await this.update(payload) : await this.store(payload);

        this.close();
        this.$emit('save', data.id);
        this.$snotify.success('Item salvo com sucesso!', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/analysis/product`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/analysis/product/${this.editId}`, payload);
      return data;
    },

    async getItems() {
      try {
        const { data } = await this.$axios.get(`/item`, { params: {
          tipos: ['PRODUTO_ACABADO', 'INSUMO_PRODUCAO', 'MATERIA_PRIMA']
        } });

        this.items = data
          .map(item => ({
            id: item.id_item,
            name: item.nome,
            code: item.cod_item,
            description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
          }));
      } catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os produtos!', 'Atenção');
      }
    },

    async getAnalysisTypes() {
      try {
        const { data } = await this.$axios.get(`/registrations/analysis/type`);

        this.analysisTypes = data.map(item => ({
          id: item.id_tipo_analise,
          description: item.descricao,
          type: item.natureza,
        }));
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os tipos de análises!', 'Atenção');
      }
    },

    close() {
      this.show = false;
    },

    getType(value) {
      const types = {
        'FISICO-QUMICA': 'Físico-química',
        'MICROBIOLOGICA': 'Microbiológica',
      }
      return types[value] || '-'
    },
  }
}
</script>
