<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                filled
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter.search"
            :loading="loading"
            dark
            @click:row="edit"
          >
            <template #[`item.created_at`]="{ value }">
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>

            <template #[`item.dairies`]="{ value }">
              <template v-for="(dairy, index) of value">
                <v-chip
                  v-if="index < 5"
                  :key="dairy.id"
                  x-small
                >
                  {{ dairy.name }}
                </v-chip>
                <v-chip
                  v-else-if="index === 6"
                  :key="dairy.id"
                  x-small
                >
                  (+{{ value.length - 6 }})
                </v-chip>
              </template>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="newApp()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Novo</span>
    </v-tooltip>

    <app-versions-dialog
      v-model="showDialog"
      :edit-id="editId"
      @save="loadApp"
    />
  </v-container>
</template>

<script>
import isArray from "lodash/fp/isArray";
import moment from "moment-timezone";

import AppVersionsDialog from "@/Domains/SettingsMilkup/Components/AppVersionsDialog.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {

  components: {
    AppVersionsDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,
      showDialog: false,

      editId: null,

      filter: {
        search: '',
      },

      headers: [
        { text: 'Lançamento', value: 'created_at' },
        { text: 'Versão', value: 'version' },
        { text: 'Cód. da Versão', value: 'versionCode' },
        { text: 'Tipo', value: 'type', align: 'center' },
        { text: 'Laticínios', value: 'dairies', align: 'center' },
        { text: '', value: 'action', width: 30 }
      ],

      items: [],
    };
  },

  mounted() {
    this.loadApp();
  },

  methods: {
    async loadApp() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/quality/app`);

        if (!isArray(data.data)) {
          throw data;
        }

        this.items = data.data;
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },
    
    newApp() {
      this.editId = null,
      this.showDialog = true;
    },
    
    edit(item) {
      this.editId = item.id,
      
      this.showDialog = true;
    },
    
    async remove({ id }) {
      try {
        if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
          return;
        }

        this.loading = true;

        await this.$axios.delete(`/quality/app/${id}`);

        this.loadApp();
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
