import colors from 'vuetify/lib/util/colors'

export default {
  methods: {

    async getQualityPriorityReport(month, technicianId = null, routes = null, status = null) {
      const { data } = await this.$axios.get(
        `/priority/list-ccp-ccs`,
        { params: {
          data_referencia: month,
          id_pessoa: technicianId,
          rotas: routes,
          status: status,
        } }
      );

      const report = data.map(item => ({
        ...item,
        ccs: parseInt(item.ccs) || 0,
        cbt: parseInt(item.cbt) || 0
      }));

      return {
        totalProducers: report.length,
        ccs: [...report].sort((a, b) => b.ccs - a.ccs),
        cbt: [...report].sort((a, b) => b.cbt - a.cbt),
        cbtccs: report,
      }
    },

    getTotalOutOfNormativeGraph(ccsPriority, cbtPriority, cbtCcsPriority, limitCCS, limitCBT) {
      const totalProducers = cbtCcsPriority.length;
      return {
        height: 400,
        options: {
          title: {
            text: 'Acimas da Norma',
            subtext: `Produtores: ${totalProducers}`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}'
          },
          legend: {
            bottom: '0%',
            left: 'center',
            icon: 'circle',
            textStyle: {
              color: '#ddd',
            }
          },
          series: [
            {
              type: 'pie',
              data: [
                {
                  name: `CCS acima de ${limitCCS}`,
                  value: ccsPriority.length,
                  itemStyle: { color: colors.red.darken1 }
                },
                {
                  name: `CPP acima de ${limitCBT}`,
                  value: cbtPriority.length,
                  itemStyle: { color: colors.red.lighten1 }
                },
                {
                  name: 'CPP e CCS acima da norma',
                  value: cbtCcsPriority.length,
                  itemStyle: { color: colors.red.darken4 }
                },
              ],
              labelLine: { show: false },
              avoidLabelOverlap: false,
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
              },
            }
          ]
        },
      }
    },

    generateOutOfNormativeGraphPie(title, totalBelow, totalAbove, limit = null) {
      const totalProducers = totalBelow + totalAbove;
      return {
        height: 300,
        options: {
          title: {
            text: title,
            subtext: `Produtores: ${totalProducers}`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}'
          },
          legend: {
            orient: 'horizontal',
            bottom: '0%',
            left: 'center',
            textStyle: { color: '#ddd' }
          },
          series: [
            {
              name: title,
              type: 'pie',
              labelLine: { show: false },
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
              },
              data: [
                {
                  value: totalBelow,
                  name: limit ? `Abaixo de ${limit}` : 'Abaixo da Norma',
                  itemStyle: { color: colors.blue.accent3 }
                },
                {
                  value: totalAbove,
                  name: limit ? `Acima de ${limit}` : 'Acima da Norma',
                  itemStyle: { color: colors.red.accent3 }
                },
              ],
            }
          ],
        }
      }
    },

    generateOutOfNormativeGraphPiePercent(title, totalBelow, totalAbove, limit = null) {
      const totalProducers = totalBelow + totalAbove;
      return {
        height: 300,
        options: {
          title: {
            text: title,
            subtext: `Produtores: ${totalProducers}`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)'
          },
          legend: {
            orient: 'horizontal',
            bottom: '0%',
            left: 'center',
            textStyle: { color: '#ddd' }
          },
          series: [
            {
              name: title,
              type: 'pie',
              labelLine: { show: false },
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
              },
              data: [
                {
                  value: totalBelow,
                  name: limit ? `Abaixo de ${limit}` : 'Abaixo da Norma',
                  itemStyle: { color: colors.blue.accent3 }
                },
                {
                  value: totalAbove,
                  name: limit ? `Acima de ${limit}` : 'Acima da Norma',
                  itemStyle: { color: colors.red.accent3 }
                },
              ],
            }
          ],
        }
      }
    },

    getTotalOutOfNormativeGraphPercent(ccsPriority, cbtPriority, cbtCcsPriority, limitCCS, limitCBT) {
      const totalProducers = cbtCcsPriority.length;
      return {
        height: 400,
        options: {
          title: {
            text: 'Acimas da Norma',
            subtext: `Produtores: ${totalProducers}`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)'
          },
          legend: {
            bottom: '0%',
            left: 'center',
            icon: 'circle',
            textStyle: {
              color: '#ddd',
            }
          },
          series: [
            {
              type: 'pie',
              data: [
                {
                  name: `CCS acima de ${limitCCS}`,
                  value: ccsPriority.length,
                  itemStyle: { color: colors.red.darken1 }
                },
                {
                  name: `CPP acima de ${limitCBT}`,
                  value: cbtPriority.length,
                  itemStyle: { color: colors.red.lighten1 }
                },
                {
                  name: 'CPP e CCS acima da norma',
                  value: cbtCcsPriority.length,
                  itemStyle: { color: colors.red.darken4 }
                },
              ],
              labelLine: { show: false },
              avoidLabelOverlap: false,
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
              },
            }
          ]
        },
      }
    },
  }
}
