<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(table, idx) in tables"
        :key="table.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <price-range-settings
          :id.sync="table.id"
          :title.sync="table.title"
          :ranges.sync="table.ranges"
          :saving.sync="table.saving"
          :editing.sync="table.editing"
          :stored.sync="table.stored"
          dark
          @remove="remove(idx)"
          @cancel="cancel(idx)"
          @save="save"
        />
      </v-col>
      <v-col
        v-if="tables.length === 0"
        cols="12"
        class="d-flex justify-center text-left"
      >
        <v-alert
          dense
          light
          prominent
          colored-border
          border="left"
          elevation="2"
          type="info"
          style="max-width: 900px"
        >
          <div>
            Nenhuma faixa configurada. Adicione através do botão
            <v-btn
              icon
              disabled
            >
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-5"
          v-on="on"
          @click="addRange()"
        >
          <v-icon>
            add
          </v-icon>
        </v-btn>
      </template>
      Adicionar tabela
    </v-tooltip>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import PriceRangeSettings from '@/Domains/Collects/Management/FreightValueTable/Components/PriceRangeSettings.vue'

export default {

  components: {
    PriceRangeSettings
  },

  data() {
    return {

      loading: false,
      saving: false,

      filter: {
        search: '',
      },

      tables: [],
    }
  },

  created() {
    this.loadRanges();
  },

  methods: {
    async loadRanges() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/settings/freight-ranges`);

        this.tables = data.map(table => ({
          id: table.id_faixa_frete,
          title: table.titulo,
          ranges: table.faixas,
          editing: false,
          stored: true
        }));

      }
      catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as faixas!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    addRange() {
      this.tables.push({
        id: uuidv4(),
        title: '',
        ranges: [{ km: 0, valor: null }],
        editing: true,
        stored: false
      })
    },

    async save(form) {
      const idx = this.tables.findIndex(table => table.id === form.id);
      try {

        this.tables.splice(idx, 1, { ...this.tables[idx], saving: true });

        const payload = {
          id_faixa_frete: form.id,
          titulo: form.title,
          faixas: form.ranges,
        };

        await this.$axios.post(`/settings/freight-ranges`, payload);

        this.tables.splice(idx, 1, ({
          id: form.id,
          title: form.title,
          ranges: form.ranges,
          editing: false,
          stored: true
        }));

        this.$snotify.success("Configuração salva com sucesso!", "Sucesso");
      } catch (error) {
        this.tables.splice(idx, 1, { ...this.tables[idx], saving: false });
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      }
    },

    async remove(idx) {
      try {
        const { id, title } = this.tables[idx];

        if (!(await this.$root.$confirm(
          'Confirmar remoção?',
          [
            `Deseja realmente excluir a tabela <b>${title}</b>?`,
            `Esta ação também irá <b>excluir as tabelas</b> que utilizam estas faixas.`,
          ].join('<br><br>'),
          { color: 'red' }
        ))) {
          return;
        }

        this.$root.$progressBar.loading();

        await this.$axios.delete(`/settings/freight-ranges/${id}`);

        this.tables.splice(idx, 1);

        this.$snotify.success("Configuração salva com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    cancel(idx) {
      this.tables.splice(idx, 1);
    }
  }
}
</script>
