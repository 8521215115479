<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        icon-tipo_operacao
      </v-icon>
      <span class="title font-weight-light">{{ title }}</span>
    </v-card-title>
    <v-chart
      :options="options"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  name: "average-milk-price-chart",

  props: {
    title: {
      type: String,
      default: 'Custo Leite por Mês'
    },
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '400px',
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
    referenceMonth: {
      type: String,
      default: () => moment().subtract(1, "month").format("YYYY-MM"),
    },
  },

  data() {
    return {
      loading: false,
      ownMilk: [],
      spots: [],
      totals: [],
    }
  },

  computed: {
    options() {
      const label = show => ({
        show,
        lineHeight: 20,
        height: 20,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        distance: 1,
        formatter: ({ value }) => `  ${this.formatCurrency(value)}  `
      });

      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(0, 150, 136, 0.7)', 'rgba(156, 39, 176, 0.7)', 'rgba(255, 152, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => {
                const formatter = (value) => param.seriesName.includes('Volume')
                  ? this.formatNumber(value) + ' L'
                  : this.formatCurrency(param.value)
                return `${param.marker} ${param.seriesName}: <b>${formatter(param.value)}</b>`
              }),
            ].join('<br>')
          }
        },
        legend: {
          top: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: { color: '#ddd' }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.category,
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff',
              formatter: (value) => this.formatCurrency(value)
            }
          },
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff',
              formatter: (value) => `${this.formatNumber(value)} L`
            }
          }
        ],
        series: [
          {
            name: 'Custo Leite Próprio',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: this.ownMilk.map((item) => ({
              value: item.finalPrice,
              label: label(!!item.finalPrice)
            }))
          },
          {
            name: 'Custo Leite Spot',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: this.spots.map((item) => ({
              value: item.finalPrice,
              label: label(!!item.finalPrice)
            })),
          },
          {
            name: 'Custo Leite Total',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            data: this.totals.map((item) => item.finalPrice),
          },
          {
            name: 'Volume Leite Próprio',
            type: 'line',
            yAxisIndex: 1,
            lineStyle: { width: 2 },
            showSymbol: false,
            smooth: this.smooth,
            emphasis: { focus: 'series' },
            data: this.ownMilk.map((item) => item.totalVol),
          },
          {
            name: 'Volume Leite Spot',
            type: 'line',
            yAxisIndex: 1,
            lineStyle: { width: 2 },
            showSymbol: false,
            smooth: this.smooth,
            emphasis: { focus: 'series' },
            data: this.spots.map((item) => item.totalVol),
          },
          {
            name: 'Volume Leite Total',
            type: 'line',
            yAxisIndex: 1,
            lineStyle: { width: 2 },
            showSymbol: false,
            smooth: this.smooth,
            emphasis: { focus: 'series' },
            data: this.totals.map((item) => item.totalVol),
          }
        ]
      }
    }
  },

  watch: {
    referenceMonth() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const startDate = moment(this.referenceMonth, 'YYYY-MM').startOf("month").subtract(6, "month").format('YYYY-MM');
        const endDate = moment(this.referenceMonth, 'YYYY-MM').endOf("month").format('YYYY-MM');

        const [{ data }, { data: spot }] = await Promise.all([
          this.$axios.get(`/financial/reports/milk-cost`, { params: { start_date: startDate, end_date: endDate } }),
          this.$axios.get(`/financial/reports/spot-milk-cost`, { params: { start_date: startDate, end_date: endDate } })
        ]);

        const dates = _.uniq([...data, ...spot].map(item => item.mes));

        // Acumula as informações de todas as unidades para calcular o preço final
        const ownMilk = data.reduce((acc, cur) => {
          if (!(cur.mes in acc)) {
            acc[cur.mes] = { totalPrice: 0, totalVol: 0 };
          }

          acc[cur.mes].totalPrice += cur.valor_leite_liquido;
          acc[cur.mes].totalVol += cur.volume_total;

          return acc;
        }, {});

        // Acumula as informações de todos os spots para calcular o preço final
        const spots = spot.reduce((acc, cur) => {
          if (!(cur.mes in acc)) {
            acc[cur.mes] = { totalPrice: 0, totalVol: 0 };
          }

          acc[cur.mes].totalPrice += cur.valor_leite_liquido;
          acc[cur.mes].totalVol += cur.volume_total;

          return acc;
        }, {});

        this.category = dates.map(date => moment(date).format('MM/YYYY'));

        this.ownMilk = dates.map(date => {
          const item = ownMilk[date];

          if (!item) {
            return { totalPrice: 0, totalVol: 0, finalPrice: 0 };
          }

          item.finalPrice = item.totalPrice / item.totalVol;

          return item;
        });

        this.spots = dates.map(date => {
          const item = spots[date];

          if (!item) {
            return { totalPrice: 0, totalVol: 0, finalPrice: 0 };
          }

          item.finalPrice = item.totalPrice / item.totalVol;

          return item;
        });

        this.totals = dates.map(date => {
          const own = ownMilk[date];
          const spot = spots[date];

          const totalPrice = (own?.totalPrice || 0) + (spot?.totalPrice || 0);
          const totalVol = (own?.totalVol || 0) + (spot?.totalVol || 0);
          const finalPrice = totalPrice / totalVol;

          return { totalPrice, totalVol, finalPrice };
        });

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de taxa de ocupação!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
  }
}
</script>
