<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.aliquota"
        label="Alíquota"
        :mask="masks.percentual"
        unmask
        prepend-inner-icon="percent"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <v-select
        v-model="form.descontar_do_total_faturado"
        :items="[
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ]"
        label="Descontar do total faturado"
        prepend-inner-icon="attach_money"
      />
    </v-col>
    <v-col
      v-if="showValores"
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <money-input
        v-model="form.valor"
        label="Valor"
        prefix="R$"
        :disabled="autocalculation"
      />
    </v-col>
  </v-row>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

export default {

  components: {
    MaskedTextField,
    MoneyInput,
  },

  props: {
    data: Object,
    showValores: Boolean,
    autocalculation: Boolean,
  },

  data() {
    return {
      masks: {
        percentual: { mask: Number, min: 0, max: 100, scale: 4 }
      },
    }
  },

  computed: {
    form: {
      get() {
        return this.data;
      },

      set(newValue) {
        return this.$emit("update:data", newValue);
      },
    },
  },
}
</script>
