<template>
  <div
    :style="{overflow: 'hidden', height: height}"
    class="d-flex flex-column"
  >
    <v-row class="flex-grow-1">
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Cotação"
          icon="icon-bonificacao"
          height="100%"
          :value="formatCurrency(baseValue, 4)"
          :color="color"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Volume Total"
          icon="icon-coleta_principal"
          height="100%"
          :value="`${ formatNumber(totalVol) } L`"
          :color="color"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Preço Médio / Litro"
          icon="icon-tipo_operacao"
          height="100%"
          :value="formatCurrency(milkPriceAvg, 4)"
          :color="color"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Total à Pagar"
          icon="icon-quitacao_contas"
          height="100%"
          :value="formatCurrency(totalPaidAmount)"
          :color="color"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Preço Médio Litro c/ Frete"
          icon="icon-tipo_operacao"
          height="100%"
          :value="formatCurrency(milkPriceFreightAvg, 4)"
          :color="color"
          :loading="loading"
        >
          <template #title>
            <v-tooltip
              v-if="milkPriceAvg && freightAvg && freightTotalVol !== totalVol"
              top
            >
              <template #activator="{ on }">
                <v-icon
                  small
                  color="rgba(255, 152, 0, 0.7)"
                  v-on="on"
                >
                  report
                </v-icon>
              </template>
              Volume calculado no Fechamento de Frete ({{ formatNumber(freightTotalVol) }} L) diferente do volume de Fechamento do Produtores
            </v-tooltip>
          </template>
        </CardInfo>
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Preço Médio do Frete / Litro"
          icon="icon-tipo_operacao"
          height="100%"
          :value="formatCurrency(freightAvg, 4)"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardInfo from "@/Domains/BI/Components/CardInfo.vue";

import moment from 'moment';
import _ from 'lodash';

export default {

  components: {
    CardInfo
  },

  filters: {
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    referenceMonth: {
      type: String,
      default: () => moment().subtract(1, "month").format("YYYY-MM"),
    },
    height: {
      type: String,
      default: '65px'
    },
  },

  data() {
    return {
      loading: false,
      baseValue: 0,
      totalVol: 0,
      milkPriceAvg: 0,
      milkPriceFreightAvg: 0,
      freightTotalVol: 0,
      freightAvg: 0,
      totalPaidAmount: 0,
    }
  },

  watch: {
    referenceMonth() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/tabelaPreco/precoLeite`,
          { mes_referencia: this.referenceMonth }
        );

        this.baseValue = data.valor_base || 0;
        this.totalVol = data.volume_total || 0;
        this.milkPriceAvg = data.valor_medio || 0;
        this.milkPriceFreightAvg = data.valor_medio_com_frete || 0;
        this.freightTotalVol = data.volume_total_frete || 0;
        this.freightAvg = data.valor_medio_frete || 0;
        this.totalPaidAmount = data.valor_total || 0;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro os totais de Preço do Leite!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value, decimals = 2) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: decimals }).format(value),
    formatMonth: (value) => value ? _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")) : '',
  }
}
</script>
