<template>
  <div ref="flyaway">
    <div
      class="label_content"
      :style="`--marker-color: ${color || 'red'};`"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
  .label_content {
    position: relative;
    border-radius: 5px;
    padding: 5px;
    color: #ffffff;
    background-color: var(--marker-color);
    font-size: 16px;
    width: 100%;
    min-width: 40px;
    line-height: 20px;
    text-align: center;
  }

  .label_content:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 10px var(--marker-color);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
</style>

<script>
import RichMarker from "googlemaps-rich-marker-web";
import { gmapApi } from "gmap-vue";

export default {
  props: {
    location: Object,
    draggable: Boolean,
    visible: Boolean,
    color: String,
    meta: Object,
    richMeta: Object,
    markerData: Object,
  },
  
  data() {
    return {
      marker: null
    }
  },

  computed: {
    google: gmapApi,
  },

  watch: {
    visible() {
      return this.createMarker();
    },
  },

  mounted() {
    this.createMarker();
  },

  beforeDestroy() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  },

  methods: {
    async createMarker() {
      if (!this.visible) {
        return;
      }

      const key = this.$vnode.key;
      const map = await this.$parent.$mapPromise;

      const marker = new RichMarker.RichMarker({
        position: new this.google.maps.LatLng(
          this.location.lat,
          this.location.lng
        ),
        map,
        draggable: this.draggable,
        content: this.$refs.flyaway,
      });

      if (this.meta) {
        const infoWindow = new this.google.maps.InfoWindow({
          content: `
          <div class="text-left black--text">
            <p><b>Produtor:</b> #${this.meta.code || ''} - ${this.meta.name || ''}</p>
            <p><b>Volume:</b> ${this.meta.vol || 0} L</p>
          </div>
        `
        });

        this.google.maps.event.addListener(marker, "click", function() {
          infoWindow.open(map, marker);
        });
      }
      else if (this.richMeta) {
        const richMeta = Object.entries(this.richMeta)
          .map(([key, value]) => value ? [`<b>${key}:</b> ${value}<br>`] : [])
          .join('\n');
        const infoWindow = new this.google.maps.InfoWindow({
          content: `<div class="text-left black--text">${richMeta}</div>`
        });

        this.google.maps.event.addListener(marker, "click", function() {
          infoWindow.open(map, marker);
        });
      }
      else {
        this.google.maps.event.addListener(marker, "click", () => {
          this.$emit("click:marker", { marker, data: this.markerData });
        });
      }

      const self = this;

      this.google.maps.event.addListener(marker, "dragend", function() {
        const position = this.getPosition();

        // Usa-se o event-bus devido ao contexto desta função
        self.$emit(`onPositionChange`, {
          lat: position.lat(),
          lng: position.lng(),
        }, key);
      });
      this.marker = marker;
    },
  },
};
</script>
