<template>
  <v-form
    ref="form"
  >
    <v-row>
      <v-col
        cols="4"
        offset="2"
      >
        <v-col
          cols="12"
          class="px-0 pt-0"
        >
          <card-title-settings>
            Período de referência para somatório de volume
          </card-title-settings>
        </v-col>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="6"
              >
                <masked-text-field
                  v-model="form.volSumStartAt"
                  label="Data Inicial"
                  prepend-inner-icon="event"
                  placeholder="00/00"
                  :mask="{ mask: '00/00' }"
                  :rules="[rules.required, rules.date]"
                  hint="Dia/Mês"
                  :loading="loading"
                  clearable
                  dense
                />
              </v-col>

              <v-col
                cols="6"
              >
                <masked-text-field
                  v-model="form.volSumEndAt"
                  label="Data Final"
                  prepend-inner-icon="event"
                  placeholder="00/00"
                  :mask="{ mask: '00/00' }"
                  :rules="[rules.required, rules.date]"
                  :loading="loading"
                  clearable
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-col
          cols="12"
          class="px-0 pt-0"
        >
          <card-title-settings>
            Período entrega ininterrupta
          </card-title-settings>
        </v-col>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="6"
              >
                <masked-text-field
                  v-model="form.producerActiveStartAt"
                  label="Data Inicial"
                  prepend-inner-icon="event"
                  placeholder="00/00"
                  :mask="{ mask: '00/00' }"
                  :rules="[rules.required, rules.date]"
                  :loading="loading"
                  clearable
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="4"
        offset="2"
      >
        <v-col
          cols="12"
          class="px-0 pt-0"
        >
          <card-title-settings>
            Pagamento
          </card-title-settings>
        </v-col>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
          :height="installmentsHeight"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="6"
              >
                <masked-input
                  v-model.number="form.perLiterAmount"
                  label="Valor Total por Litro"
                  :mask="FloatNumberMask"
                  tabindex="9"
                  prepend-inner-icon="event"
                  :rules="[rules.required]"
                  :loading="loading"
                  return-unmasked
                  dense
                />
              </v-col>

              <v-col
                cols="6"
              >
                <v-select
                  v-model="form.installmentsAmount"
                  label="Parcelamento"
                  :items="installments"
                  prepend-inner-icon="event"
                  :rules="[rules.required]"
                  :loading="loading"
                  dense
                  @change="onInstallmentsChange"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-col
          cols="12"
          class="px-0 pt-0"
        >
          <card-title-settings>
            Parcelamento
          </card-title-settings>
        </v-col>

        <v-card
          ref="installmentsCard"
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row
              v-for="installment in form.installments"
              :key="installment.index"
            >
              <v-col
                cols="@"
              >
                <v-text-field
                  label="Parcela"
                  placeholder=" "
                  :prefix="`#${installment.index}`"
                  :loading="loading"
                  value=" "
                  dense
                  disabled
                />
              </v-col>

              <v-col
                cols="5"
              >
                <v-text-field
                  v-model.number="installment.percentage"
                  label="Percentual (%)"
                  type="number"
                  :rules="[rules.required, rules.percentageAmount]"
                  dense
                />
              </v-col>

              <v-col
                cols="5"
              >
                <masked-text-field
                  v-model="installment.date"
                  label="Vencimento"
                  prepend-inner-icon="event"
                  placeholder="00/00"
                  :mask="{ mask: '00/00' }"
                  :rules="[rules.required, rules.date]"
                  :loading="loading"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="4"
        offset="2"
      >
        <v-col
          cols="12"
          class="px-0 pt-0"
        >
          <card-title-settings>
            Parâmetros Gerais
          </card-title-settings>
        </v-col>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
          :height="installmentsHeight"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="form.considerDownloaded"
                  label="Considerar Descarregados"
                  :items="considerDownloaded"
                  prepend-inner-icon="local_shipping"
                  :loading="loading"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="saveSettings();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações do Aplicativo</span>
    </v-tooltip>

    <v-overlay v-model="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-form>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue";

import _ from "lodash";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import moment from "moment/moment";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    MaskedInput,
    MaskedTextField,
    CardTitleSettings
  },

  data() {
    return {
      loading: false,

      installmentsHeight: null,

      form: {
        volSumStartAt: '',
        volSumEndAt: '',
        producerActiveStartAt: '',
        perLiterAmount: '',
        installmentsAmount: 2,

        installments: [
          { index: 1, date: '', percentage: 0.0 },
          { index: 2, date: '', percentage: 0.0 }
        ],

        considerDownloaded: [
          { value: 1, text: 'Sim'},
          { value: 0, text: 'Não'},
        ]
      },

      considerDownloaded: [
        { value: 1, text: 'Sim'},
        { value: 0, text: 'Não'},
      ],

      installments: [
        {
          text: 'Uma Parcela',
          value: 1,
        },
        {
          text: 'Duas Parcelas',
          value: 2,
        },
        {
          text: 'Três Parcelas',
          value: 3,
        },
        {
          text: 'Quatro Parcelas',
          value: 4,
        },
      ],

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        date: v =>  this.isDateValid(v, 'DD/MM') || 'Data Inválida!',
        percentageAmount: () =>  this.validateTotalPercentage() || 'Somatório não pode ultrapassar 100%',
      },

      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },
    };
  },

  async mounted() {
    await this.loadSettings();
    await this.calculateInstallmentHeight();
  },

  methods: {
    onInstallmentsChange() {
      const installmentsIdx = Array.from(
        { length: (this.form.installmentsAmount - 1) + 1 },
        (value, index) => 1 + index
      );

      this.form.installments = installmentsIdx.map(index => {
        return {
          index,
          date: '',
          percentage: 0.0,
        }
      });

      return this.$nextTick(this.calculateInstallmentHeight);
    },

    calculateInstallmentHeight() {
      if (_.isEmpty(this.form.installments)) {
        return;
      }

      this.installmentsHeight = this.$refs.installmentsCard.$el.clientHeight;
    },

    async loadSettings() {
      try {
        this.loading = true;

        const { data: { configuracao } } = await this.$axios.get(`/bonus/end-period/settings`,);

        if (_.isEmpty(configuracao)) {
          return false;
        }

        this.form = configuracao;
      } catch (e) {
        console.error(e);

      } finally {
        this.loading = false;
      }
    },

    async saveSettings() {

      this.loading = true;

      try {
        let { data } = await this.$axios.post(
          `/bonus/end-period/settings`,
          this.form
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.warn(error);

      } finally {
        this.$store.dispatch('updateStoreSetting');
        this.loading = false;
      }
    },

    isDateValid(value, format) {
      if (!value || value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },

    validateTotalPercentage() {
      return _.sumBy(this.form.installments, 'percentage') <= 100;
    }
  },
};
</script>
