<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Volume total por ciclo</span>
    </v-card-title>

    <v-card-text class="pb-0 flex-grow-0">
      <div class="d-flex white--text justify-space-between">
        <div class="d-flex flex-column text-left white--text">
          <span class="subtitle-2 font-weight-light">Coletado</span>
          <span class="display-1">{{ formatNumber(report.volume) }}</span>
        </div>
        <div
          v-if="measureds.length > 0"
          class="d-flex flex-column text-left white--text"
        >
          <span class="subtitle-2 font-weight-light">Recebido</span>
          <span class="display-1">{{ formatNumber(report.measured) }}</span>
        </div>
        <div class="d-flex flex-column text-center white--text">
          <span class="subtitle-2 font-weight-light">Coletas</span>
          <span class="display-1">{{ formatNumber(report.collections) }}</span>
        </div>
        <div class="d-flex flex-column text-right white--text">
          <span class="subtitle-2 font-weight-light">Produtores</span>
          <span class="display-1">{{ formatNumber(report.producers) }}</span>
        </div>
      </div>
    </v-card-text>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';

export default {

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '287px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      collected: [],
      measureds: [],
      report: { volume: 0, measured: 0, collections: 0, producers: 0 }
    }
  },

  computed: {
    options() {
      const getLabel = (length, index) => ({
        show: true,
        lineHeight: 20,
        height: 20,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: index === 0 ? 'right' : index === length - 1 ? 'left' : 'inside',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      });

      let series = [{
        name: 'Coletado',
        type: 'line',
        areaStyle: {},
        lineStyle: { width: 2 },
        showSymbol: true,
        smooth: false,
        emphasis: {
          focus: 'series'
        },
        data: this.collected.map(({ volume }, index) => ({
          value: parseInt(volume) || 0,
          label: getLabel(this.collected.length, index),
        }))
      }];

      if (this.measureds.length > 0) {
        series.push({
          name: 'Recebido',
          type: 'line',
          areaStyle: {},
          lineStyle: { width: 2 },
          showSymbol: true,
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          data: this.measureds.map(({ peso_medido }, index) => ({
            value: parseFloat(peso_medido),
            label: getLabel(this.measureds.length, index),
          }))
        });

        series.push({
          name: 'Diferença',
          type: 'line',
          areaStyle: {},
          lineStyle: { width: 2 },
          showSymbol: true,
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          data: this.measureds.map(({ peso_medido }, index) => ({
            value: parseFloat(peso_medido) - parseFloat(this.collected[index].volume),
            label: getLabel(this.measureds.length, index),
          }))
        });
      }

      return {
        color: ['rgba(29, 233, 182, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(38, 198, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.collected.map(({ data }) => moment(data).format('DD/MM/YYYY')),
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
        },
        series
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let [{ data }, { data: measureds }, { data: report }] = await Promise.all([
          this.$axios.post(
            `/coleta/volumeColetaGeral`,
            { data_inicio: startDate, data_fim: endDate }
          ),
          this.$axios.post(
            `/coleta/volumeMedidoGeral`,
            { data_inicio: startDate, data_fim: endDate }
          ),
          this.$axios.post(
            `/coleta/volumeColeta`,
            { data_inicio: startDate, data_fim: endDate }
          ),
        ]);

        if (!data || !report) {
          throw new Error(data);
        }

        data = data.sort((a, b) => moment(a.data).diff(b.data));

        this.collected = data;
        this.measureds = [];

        this.report = report.reduce((acc, cur) => {
          acc.volume += parseInt(cur.total_volume) || 0;
          acc.collections += parseInt(cur.qtde_coletas) || 0;
          acc.producers++;
          return acc;
        }, { volume: 0, measured: 0, collections: 0, producers: 0 });

        /**
         * Alguns laticinios não usam a plataforma, por isso só adiciona se vier algo do BD
         */
        if (measureds.length > 0) {
          this.measureds = data.map(({ data }) => {
            const measured = measureds.find(o => o.data == data);
            return {
              data,
              peso_medido: measured ? measured.peso_medido : 0
            }
          })
          this.report.measured = this.measureds.reduce((acc, cur) => (acc + (parseInt(cur.peso_medido) || 0)), 0);
        }

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o volume total por ciclo!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
