<template>
  <v-simple-table>
    <template #default>
      <thead>
        <tr>
          <th>Chave</th>
          <th>Anterior</th>
          <th>Atual</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(item, key) in value"
        >
          <template
            v-if="isArray(value[key])"
          >
            <tr
              :key="`${itemKey}-${key}`"
            >
              <td
                colspan="3"
                class="text-left"
              >
                {{ startCase(key) }} [{{ value[key].length }}]
              </td>
            </tr>
            <tr
              v-if="isSimpleList(value[key])"
              :key="`simplelist-${key}`"
            >
              <td
                colspan="3"
                class="text-left"
              >
                <audit-item
                  :value="value[key]"
                  :item-key="key"
                />
              </td>
            </tr>
            <template v-else>
              <tr
                v-for="(innerItem, idx) in value[key]"
                :key="`${itemKey}-${key}-${idx}`"
              >
                <template v-if="!isEmpty(innerItem)">
                  <td
                    colspan="3"
                    class="text-left"
                  >
                    <audit-item
                      :value="innerItem"
                      :item-key="`${itemKey}-${key}-${idx}`"
                    />
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <template
            v-else-if="isSimpleObject(item)"
          >
            <tr :key="`key-${itemKey}-${key}`">
              <td
                colspan="3"
                class="text-left"
              >
                {{ startCase(key) }}
              </td>
            </tr>
            <tr :key="`${itemKey}-${key}`">
              <td
                colspan="3"
                class="text-left"
              >
                <audit-item
                  :value="item"
                  :item-key="itemKey"
                />
              </td>
            </tr>
          </template>
          <tr
            v-else
            :key="`${itemKey}-${key}`"
          >
            <td class="text-left">
              {{ startCase(key) }}
            </td>
            <td class="text-left red--text text--accent-3">
              {{ parseField(item.previous) }}
            </td>
            <td class="text-left green--text text--accent-3">
              {{ parseField(item.current) }}
            </td>
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';
import isObject from 'lodash/fp/isObject';
import startCase from 'lodash/fp/startCase';
import moment from 'moment';

export default {
  name: 'audit-item',

  props: {
    value: [Object, Array],
    itemKey: String,
  },

  methods: {
    isArray,
    isEmpty,
    startCase,
    isSimpleList(item) {
      if (item.length === 0) {
        true;
      }
      return item[0] && ('previous' in item[0])
    },
    isSimpleObject(item) {
      if (!isObject(item)) {
        false;
      }
      return !('previous' in item || 'current' in item)
    },
    parseField(field) {
      // Check if field is datetime with regex [ex: 2024-05-06T12:23:21 or 	2024-05-06 12:23:21 or 2024-07-05T16:34:32.410000Z]
      if (/^\d{4}-\d{2}-\d{2}([T ]{1}?)\d{2}:\d{2}:\d{2}(.*?)$/.test(field)) {
        return moment(field).format('DD/MM/YYYY HH:mm:ss')
      }
      if (/^\d{4}-\d{2}-\d{2}$/.test(field)) {
        return moment(field).format('DD/MM/YYYY')
      }
      return field
    }
  }
}
</script>
