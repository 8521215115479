<template>
  <div
    :id="widget.component"
    :gs-x="widget.x"
    :gs-y="widget.y"
    :gs-w="widget.w"
    :gs-h="widget.h"
    :gs-id="widget.component"
    class="grid-stack-item"
  >
    <div class="grid-stack-item-content">
      <v-btn
        v-if="!isLocked"
        class="close-btn"
        icon
        dark
        @click="$emit('remove', widget)"
      >
        <v-icon>close</v-icon>
      </v-btn>
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    widget: Object,
    isLocked: Boolean,
  },
}
</script>
