<template>
  <div class="mx-6 px-16">
    <v-row
      xs12
      justify="center"
    >
      <v-col
        cols="12"
      >
        <h2 class="menu-header white--text">
          Tabela de Preços
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#tab-freight-collect">
        Tabela T1
      </v-tab>
      <v-tab href="#tab-freight-route">
        Configuração por Rota
      </v-tab>
      <v-tab href="#tab-freight-additional">
        Adicionais T1
      </v-tab>
      <v-tab href="#tab-freight-transfer">
        Tabela T2
      </v-tab>
      <v-tab href="#tab-freight-range">
        Tabela de Faixas
      </v-tab>

      <v-tab-item value="tab-freight-collect">
        <freight-value-freighter-tab
          v-if="tab === 'tab-freight-collect'"
          type="COLETA"
        />
      </v-tab-item>
      <v-tab-item value="tab-freight-route">
        <freight-value-route-tab
          v-if="tab === 'tab-freight-route'"
        />
      </v-tab-item>
      <v-tab-item value="tab-freight-additional">
        <freight-additional-tab
          v-if="tab === 'tab-freight-additional'"
        />
      </v-tab-item>
      <v-tab-item value="tab-freight-transfer">
        <freight-value-freighter-tab
          v-if="tab === 'tab-freight-transfer'"
          type="TRANSFERENCIA"
        />
      </v-tab-item>
      <v-tab-item value="tab-freight-range">
        <freight-range-table-tab
          v-if="tab === 'tab-freight-range'"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import FreightValueFreighterTab from "@/Domains/Collects/Management/FreightValueTable/Views/FreightValueFreighterTab.vue";
import FreightValueRouteTab from "@/Domains/Collects/Management/FreightValueTable/Views/FreightValueRouteTab.vue";
import FreightRangeTableTab from "@/Domains/Collects/Management/FreightValueTable/Views/FreightRangeTableTab.vue";
import FreightAdditionalTab from "@/Domains/Collects/Management/FreightValueTable/Views/FreightAdditionalTab.vue";

export default {
  components: {
    FreightValueFreighterTab,
    FreightValueRouteTab,
    FreightRangeTableTab,
    FreightAdditionalTab,
  },

  data() {
    return {
      tab: null
    }
  }
}
</script>
