<template>
  <v-container
    :grid-list-md="!$vuetify.breakpoint.smAndDown"
    fluid
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-expand-transition v-if="isIndex">
          <div class="search-menu">
            <div
              class="d-flex pa-5"
              style="justify-content: center;"
            >
              <div>
                <v-text-field
                  v-model="searchMenu"
                  clearable
                  hide-details
                  light
                  placeholder="Procurar..."
                  solo
                />
              </div>
            </div>
          </div>
        </v-expand-transition>
        <template v-else>
          <h2 class="pt-4 menu-header white--text">
            {{ title || '' }}
          </h2>
          <v-btn
            text
            @click="$router.back()"
          >
            <v-icon>arrow_left</v-icon>
            <div class="pr-3">
              Voltar
            </div>
          </v-btn>
        </template>
      </v-flex>

      <div
        id="tamanhograde"
        class="grid fullanimation"
      >
        <draggable
          :list="localItens"
          animation="200"
          delay="100"
          delay-on-touch-only
          handle=".grid-item"
          :group="{
            name: 'favorites',
            pull: 'clone',
            put: false
          }"
          :sort="false"
        >
          <transition-group
            type="transition"
            name="menu-item"
          >
            <div
              v-for="menu in localItens"
              :key="menu.fullPath"
              v-ripple
              :class="['grid-item', { xs: $vuetify.breakpoint.xsOnly }]"
              @click="navigateTo(menu)"
            >
              <v-btn
                :ripple="false"
                outlined
                class="giant"
                icon
              >
                <span
                  v-if="menu.icon"
                  :class="`icon-${menu.icon}`"
                />
              </v-btn>
              <div
                color="transparent"
                class="menu-title"
              >
                {{ menu.title }}
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </v-layout>

    <v-tooltip
      v-if="showNotificationBtn"
      left
    >
      <template v-slot:activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="orange darken-1 mr-5"
          v-on="on"
          @click="$router.push({ name: 'notifications' })"
        >
          <v-icon class="material-icons-outlined">
            notifications_active
          </v-icon>
        </v-btn>
      </template>

      <span>Notificações</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import Ripple from 'vuetify/lib/directives/ripple';
import Draggable from 'vuedraggable'

export default {

  directives: { Ripple },

  components: {
    Draggable,
  },

  props: {
    isIndex: Boolean,
    title: String,
    itens: Array,
  },

  computed: {
    searchMenu: {
      get() {
        return this.$store.state.settings.searchMenu;
      },
      set(value) {
        this.$store.commit("setSearchMenu", value);
      },
    },

    localItens() {
      if (this.isIndex && this.searchMenu) {
        const search = this.searchMenu
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, '');

        const flatten = (itens) => {
          return itens.reduce((acc, element) => {
            if (Array.isArray(element.children)) {
              return acc.concat(flatten(element.children));
            } else if (
              element.meta?.title && element.meta.title
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, '')
                .includes(search)
            ) {
              return acc.concat(element.meta);
            }
            return acc;
          }, []);
        };

        return flatten(this.$router.options.routes);
      }

      return this.itens
    },

    showNotificationBtn() {
      return this.isIndex && this.$router.options.routes
        .some(route => route.children && route.children.some(route => route.name === 'notifications'))
    }
  },

  methods: {
    navigateTo(menu) {
      if (menu.isDairyRequired && !this.$store.state.settings.laticinio) {
        return this.$snotify.info("Você precisa selecionar um laticínio para acessar esse item!", "Atenção");
      }
      this.$router.push(menu.fullPath);
    },
  }
}
</script>
