<template>
  <v-dialog
    v-model="show"
    width="800"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">
          Upload de Lançamentos
        </span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-stepper
        v-model="step"
        alt-labels
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="step > 1"
          >
            Upload
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="2">
            Validar Importação
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            step="1"
            class="pa-0"
          >
            <v-card-text>
              <v-form
                ref="form"
              >
                <v-row>
                  <v-col
                    v-for="(attachment, idx) in form.attachments"
                    :key="idx"
                  >
                    <file-viewer
                      :value="attachment"
                      @removeFile="onRemoveFile(idx)"
                    />
                  </v-col>
                  <v-col
                    v-if="form.attachments.length < 3"
                    class="py-0 text-center align-self-center flex-column justify-center"
                  >
                    <v-btn
                      outlined
                      class="mb-5"
                      :disabled="form.attachments.length > 0"
                      @click="showFileUpload = !showFileUpload"
                    >
                      <v-icon>attach_file</v-icon>
                      <span class="ml-2">Adicionar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider></v-divider>
              <v-row
                class="justify-center align-center"
              >
                <v-btn
                  color="primary"
                  class="ma-6 white--text"
                  @click="downloadModel"
                >
                  Baixar Modelo
                  <v-icon
                    right
                    dark
                  >
                    cloud_download
                  </v-icon>
                </v-btn>
              </v-row>
            </v-card-text>
          </v-stepper-content>

          <v-stepper-content
            step="2"
            class="pa-0"
          >
            <v-card-text>
              <v-row
                v-if="(releases.brucelose ?? []).length > 0"
              >
                <v-col>
                  <span class="text-h6">
                    <v-icon
                      right
                      dark
                      color="blue"
                    >
                      event_note
                    </v-icon>
                    Brucelose
                  </span>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                    <v-row>
                      <v-col>
                        <data-table
                          ref="report"
                          :headers="headers"
                          :items="releases.brucelose ?? []"
                        >
                        </data-table>
                      </v-col>
                    </v-row>
                </v-col>
              </v-row>
              <v-row
                v-if="(releases.tuberculose ?? []).length > 0"
              >
                <v-col>
                  <span class="text-h6">
                    <v-icon
                      right
                      dark
                      color="blue"
                    >
                      event_note
                    </v-icon>
                    Tuberculose
                  </span>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <data-table
                        ref="report"
                        :headers="headers"
                        :items="releases.tuberculose ?? []"
                      >
                      </data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row
                v-if="(releases.vacina ?? []).length > 0"
              >
                <v-col>
                  <span class="text-h6">
                    <v-icon
                      right
                      dark
                      color="blue"
                    >
                      vaccines
                    </v-icon>
                    Vacinas
                  </span>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <data-table
                        ref="report"
                        :headers="headers"
                        :items="releases.vacina ?? []"
                      >
                      </data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row
                v-if="(releases.atestado_conformidade ?? []).length > 0"
              >
                <v-col>
                  <span class="text-h6">
                    <v-icon
                      right
                      dark
                      color="blue"
                    >
                      vaccines
                    </v-icon>
                    Atestado de Conformidade
                  </span>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <data-table
                        ref="report"
                        :headers="headers"
                        :items="releases.atestado_conformidade ?? []"
                      >
                      </data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="step == 1"
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="step = step - 1"
        >
          <v-icon
            left
            dark
          >
            chevron_left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="step == 1"
          color="green"
          outlined
          :disabled="!form.attachments.length > 0"
          @click="nextStep()"
        >
          Continuar
          <v-icon
            right
            dark
          >
            chevron_right
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <file-upload
      v-model="showFileUpload"
      extensions="csv"
      accept="text/csv"
      :multiselect="false"
      @insertFiles="onSelectFiles"
    />
  </v-dialog>
</template>

<script>

import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment";

export default {
  components: {
    FileUpload,
    FileViewer,
  },

  mixins: [ReportMixin],

  props: {
    value: Boolean,
  },

  data() {
    return {
      showFileUpload: false,
      step: 1,
      form: {
        attachments: [],
      },
      releases: [],

      fields: [
        { text: 'cpf', keys: ['cpf'] },
        { text: 'produtor', keys: ['produtor'] },
        { text: 'veterinario', keys: ['veterinario'] },
        { text: 'tipo do exame', keys: ['tipo do exame'] },
        { text: 'data vacina', keys: ['data vacina'] },
        { text: 'qtd de animais', keys: ['qtd de animais'] },
        { text: 'vacina', keys: ['vacina'] },
      ],

      headers: [
        { text: 'CPF', value: 'cpf', align: 'center'  },
        { text: 'Produtor', value: 'produtor' },
        { text: 'Veterinário', value: 'veterinario' },
        { text: 'Data', value: 'data_vacina', align: 'center'  },
        { text: 'Qtd Animais', value: 'qtd_de_animais', align: 'center'  },
        { text: 'Vacina', value: 'vacina' },
      ],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

  },

  methods: {
    close() {
      this.releases = [];
      this.show = false;
    },

    async nextStep() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      this.step++;

      if(this.step === 2) {
        await this.filevalidate();
      }
    },

    onSelectFiles(files) {
      for (let file of files) {
        if (!this.form.attachments.find(o => o.name == file.name)) {
          this.form.attachments.push(file);
        }
      }
    },

    async onRemoveFile(idx) {
      try {
        const fileUrl = this.form.attachments[idx].url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('exames')) {
          if (!(await this.$root.$confirm('Remover anexo', 'Tem certeza que deseja excluir este anexo?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.attachments.splice(idx, 1);
          await this.save();
        }
        else {
          this.form.attachments.splice(idx, 1);
        }
      } catch (err) {
        console.warn(err);
      }
    },

    async save() {
      try {
        this.$root.$progressBar.saving();

        const payload = {};

        // Itera sobre as chaves do objeto releases
        for (const key in this.releases) {
          if (this.releases.hasOwnProperty(key)) {
            payload[key] = this.releases[key].map(exame => ({
              ...exame,
              data_vacina: moment(exame.data_vacina, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            }));
          }
        }

        const { data } = await this.$axios.post(`/projects/releases-save`, {data: payload});

        this.$emit('save');
        this.close();

      } catch (error) {
        this.$snotify.error("Erro ao salvar registro", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async filevalidate() {
      try {
        const fileUrl = this.form.attachments[0].url;

        const payload = {
          file: fileUrl,
        };

        const { data } = await this.$axios.post(`/projects/releases-upload`, payload);

        this.releases = data;

      } catch (err) {
        console.warn(err);
      }
    },

    downloadModel() {
      let model = this.fields.reduce((acc, cur) => ({ ...acc, [cur.keys[0]]: cur.example || '' }), {});
      const title = "Modelo Importação";
      this.exportToFile({ report: [ model ], title, format: 'csv' });
    }
  }
}
</script>
