<template>
  <div class="pt-0">
    <v-card
      dark
      class="transparent"
    >
      <v-card-title class="pb-0">
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-date-range-picker
              v-model="filters.date.range"
              :ranges="filters.date.ranges"
              prepend-inner-icon="today"
              dark
              label="Data"
              hide-details
              filled
              :max-days="30"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <routes-autocomplete-filter
              v-model="filters.routeId"
              label="Rota"
              dark
              :return-object="false"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              label="Busca"
              filled
              single-line
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-select
              v-model="filters.hasDiscount"
              :items="hasDiscountOptions"
              prepend-inner-icon="reorder"
              dark
              label="Desconto"
              hide-details
              filled
              clearable
              @change="onFilter"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        light
        dark
      >
        <template
          v-for="column in dateHeaders"
          #[`item.${column.value}`]="{ item }"
        >
          <div :key="column.value">
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  class="mt-1 mb-1"
                  style="cursor: default"
                  v-on="on"
                >
                  {{ getDailyValue(item["date" + column.value.replaceAll('-', '')])['vale'] }}
                </div>
              </template>
              Vale
            </v-tooltip>
            <br>
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  class="mt-1 mb-1"
                  style="cursor: default"
                  v-on="on"
                >
                  {{ getDailyValue(item['date' + column.value.replaceAll('-', '')])['medido'] }}
                </div>
              </template>
              Medido
            </v-tooltip>
            <br>
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  v-if="getDailyValue(item['date' + column.value.replaceAll('-', '')])['diferenca'] < 0"
                  small
                  v-bind="attrs"
                  color="red darken-1"
                  v-on="on"
                >
                  {{ getDailyValue(item['date' + column.value.replaceAll('-', '')])['diferenca'] }}
                </v-chip>
                <v-chip
                  v-else
                  small
                  v-bind="attrs"
                  color="teal darken-1"
                  v-on="on"
                >
                  {{ getDailyValue(item['date' + column.value.replaceAll('-', '')])['diferenca'] }}
                </v-chip>
              </template>
              Diferença = Vale -  Medidos
            </v-tooltip>
            <br><br>
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  v-if="getDailyValue(item['date' + column.value.replaceAll('-', '')])['desconto'] < 0"
                  small
                  v-bind="attrs"
                  color="red darken-1"
                  v-on="on"
                >
                  {{ getDailyValue(item['date' + column.value.replaceAll('-', '')])['desconto'] }}
                </v-chip>
                <v-chip
                  v-else
                  small
                  v-bind="attrs"
                  color="teal darken-1"
                  v-on="on"
                >
                  {{ getDailyValue(item['date' + column.value.replaceAll('-', '')])['desconto'] }}
                </v-chip>
              </template>
              Desconto
            </v-tooltip>
            <br><br>
          </div>
        </template>
        <template #[`item.vertical`]="{ }">
          <div
            class="mb-6"
            style="cursor: default"
          >
            Vale
          </div>
          <div
            class="mb-7"
            style="cursor: default"
          >
            Medido
          </div>
          <div
            class="mb-7"
            style="cursor: default"
          >
            Diferença
          </div>
          <div
            class="mb-4"
            style="cursor: default"
          >
            Desconto
          </div>
        </template>
        <template #[`footer.prepend`]>
          <div class="text-caption text-left ml-4">
            Total Vale:
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatNumber(totalVale) }}
            </v-chip>
          </div>
          <div class="text-caption text-left ml-4">
            Total Medidos:
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatNumber(totalMedido) }}
            </v-chip>
          </div>
          <div class="text-caption text-left ml-4">
            Total Diferença:
            <v-chip
              small
              class="mx-2 mb-0"
              :color="totalDiferenca < 0 ? 'red darken-1' : 'teal darken-1'"
            >
              {{ formatNumber(totalDiferenca) }}
            </v-chip>
          </div>
          <div class="text-caption text-left ml-4">
            Total Desconto:
            <v-chip
              small
              class="mx-2 mb-0"
              :color="totalDesconto < 0 ? 'red darken-1' : 'teal darken-1'"
            >
              {{ formatNumber(totalDesconto) }}
            </v-chip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">
  .tr-tot-day{
    span{
      font-family: Roboto, Arial, sans-serif !important;
      font-size: 1rem !important;
    }
  }
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    RoutesAutocompleteFilter,
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        search: '',
        routeId: null,
        date: {
          ranges: {
            'Hoje': [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            'Ontem': [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
            'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
            'Mês anterior': [moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")],
          },
          range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        },
      },
      items: [],
      hasDiscountOptions: [
        { value: false, text: 'Sem Desconto' },
        { value: true, text: 'Com Desconto' }
      ],
    };
  },
  computed: {
    thisMonth() {
      const firstMonthDay = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment().format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    headers() {
      return [
        { text: "Data", value: "vertical", width: 80 },
        ...this.dateHeaders
      ];
    },

    dateHeaders() {
      const [startDate, endDate] = this.filters.date.range;

      let header = [];
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        header.push({
          text: currentDate.format('DD/MM'),
          value: currentDate.format('YYYY-MM-DD'),
          width: 110,
          align: "center",
        });
        currentDate = moment(currentDate).add(1, 'days');
      }
      return header;
    },

    totalMedido() {
      return this.filteredItems.reduce((acc, item) => (acc + item.medido_total), 0)
    },

    totalVale() {
      return this.filteredItems.reduce((acc, item) => (acc + item.vale_total), 0)
    },

    totalDiferenca() {
      return this.filteredItems.reduce((acc, item) => (acc + item.diferenca), 0)
    },

    totalDesconto() {
      return this.filteredItems.reduce((acc, item) => (acc + item.desconto), 0)
    },

    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.items.filter(item => {
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return searchFilter
      });
    },
  },

  mounted() {
    this.onFilter();
  },

  methods: {
    async loadDifferenceDaily() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filters.date.range;

        const [{ data: volumeColetaGeral }, { data: volumeMedidoGeral }] = await Promise.all([
          this.$axios.post(`/coleta/volumeColetaGeral`, {
            data_inicio: startDate,
            data_fim: endDate,
            id_rota: this.filters.routeId
          }),
          this.$axios.post(`/coleta/volumeMedidoGeral`, {
            data_inicio: startDate,
            data_fim: endDate,
            id_rota: this.filters.routeId,
            com_desconto: this.filters.hasDiscount,
          })
        ]);

        if (!_.isArray(volumeColetaGeral)) {
          throw new Error(volumeColetaGeral);

        } else if (!_.isArray(volumeMedidoGeral)) {
          throw new Error(volumeMedidoGeral);
        }

        let data = _.groupBy(_.concat(volumeColetaGeral, volumeMedidoGeral), "data");

        data = _.map(data, item => {
          return Object.assign({}, ...item);
        });

        this.items = this.handleData(data);

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onFilter() {
      return this.loadDifferenceDaily();
    },

    getDailyValue(item) {
      let value = [];

      value['vale'] = '-';
      value['medido'] = '-';
      value['diferenca'] = '-';
      value['desconto'] = '-';

      if (item) {
        item = item.split('/');
        value['vale'] = _.isEmpty(item[0]) || item[0] == "undefined" ? '-' : this.formatNumber(item[0]);
        value['medido'] = _.isEmpty(item[1]) || item[1] == "undefined" ? '-' : this.formatNumber(item[1]);
        value['diferenca'] = _.isEmpty(item[2]) || item[2] == "undefined" ? 0 : this.formatNumber(item[2]);
        value['desconto'] = _.isEmpty(item[3]) || item[3] == "undefined" ? 0 : this.formatNumber(item[3]);
      }

      return value;
    },

    handleData(response) {
      if (response.length > 0) {
        const fillDate = this.dateHeaders.map(({ value }) => ({ data: value }));
        const concat = _.concat(response, fillDate);
        const group = _.uniqBy(concat, "data");

        let totalVale = 0;
        let totalMedido = 0;
        let totalDiferenca = 0;
        let totalDesconto = 0;

        let items = _.map(group, item => {
          let diferenca = 0;
          let desconto = item.desconto != null ? parseFloat(item.desconto) : 0;

          if (item.volume != null && item.peso_medido != null) {
            diferenca = (parseFloat(item.peso_medido) - parseFloat(item.volume));
          }

          totalVale += item.volume != null ? parseFloat(item.volume) : 0;
          totalMedido += item.peso_medido != null ? parseFloat(item.peso_medido) : 0;
          totalDiferenca += diferenca;
          totalDesconto += desconto;

          return {
            ["date" + item.data.replaceAll('-', '')]: item.volume + '/' + item.peso_medido + '/' + diferenca + '/' + desconto,
            ["date" + item.data.replaceAll('-', '') + "diferenca"]: diferenca,
            ["date" + item.data.replaceAll('-', '') + "desconto"]: desconto,
            vale_total: totalVale,
            medido_total: totalMedido,
            diferenca: totalDiferenca,
            desconto: totalDesconto
          }
        });
        return [Object.assign({}, ...items)];
      } else {
        return response;
      }
    },

    exportExcel() {
      var ws = this.XLSX.utils.json_to_sheet([
        {
          A: "Data",
          ...this.dateHeaders.reduce((acc, header) => ({
            ...acc, [header.text]: header.text
          }), {})
        }
      ], { skipHeader: true });

      this.XLSX.utils.sheet_add_json(ws, this.items.map((item) => {
        return { A: "Vale",
          ...this.dateHeaders.reduce((acc, header) => ({
            ...acc, [header.text]: this.getDailyValue(item['date' + header.value.replaceAll('-', '')])['vale']
          }), {})
        }
      }), { skipHeader: true, origin: "A2" });

      this.XLSX.utils.sheet_add_json(ws, this.items.map((item) => {
        return { A: "Medidos",
          ...this.dateHeaders.reduce((acc, header) => ({
            ...acc, [header.text]: this.getDailyValue(item['date' + header.value.replaceAll('-', '')])['medido']
          }), {})
        }
      }), { skipHeader: true, origin: "A3" });

      this.XLSX.utils.sheet_add_json(ws, this.items.map((item) => {
        return { A: "Diferença",
          ...this.dateHeaders.reduce((acc, header) => ({
            ...acc, [header.text]: item['date' + header.value.replaceAll('-', '') + 'diferenca'],
          }), {})
        }
      }), { skipHeader: true, origin: "A4" });

      this.XLSX.utils.sheet_add_json(ws, this.items.map((item) => {
        return { A: "Desconto",
          ...this.dateHeaders.reduce((acc, header) => ({
            ...acc, [header.text]: item['date' + header.value.replaceAll('-', '') + 'desconto'],
          }), {})
        }
      }), { skipHeader: true, origin: "A5" });

      this.XLSX.utils.sheet_add_json(ws, [
        { A: "Total Vale", B: this.formatNumber(this.totalVale)
        }, {
          A: "Total Medidos", B: this.formatNumber(this.totalMedido)
        }, {
          A: "Total Diferença", B: this.formatNumber(this.totalDiferenca)
        }, {
          A: "Total Desconto", B: this.formatNumber(this.totalDesconto)
        },
      ], { skipHeader: true, origin: "A7" });

      ws['!cols'] = [
        { wch: 15 },
      ];

      const workbook = this.XLSX.utils.book_new();
      const filename = "diferenca_vale_medidos_diario";

      this.XLSX.utils.book_append_sheet(workbook, ws, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
