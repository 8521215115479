<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="3"
          offset="5"
        >
          <v-select
            v-model="filter.project"
            :items="projectList"
            :loading="loading"
            label="Metas"
            item-value="id_assistencia"
            item-text="descricao"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadGoals"
          />
        </v-col>
        <!-- <v-col
          cols="12"
          md="3"
        >
          <month-range-picker
            v-model="filter.range"
            :ranges.sync="filter.ranges"
            :min="filter.min"
            :max="filter.max"
            dark
            @change="loadRange"
          />
        </v-col> -->
      </v-row>

      <visits-graphic
        v-for="q in visits"
        :key="q.nome+q.periodo"
        :goal="q"
      />
      <v-card
        v-for="e in emergency"
        :key="e.tipo_visita"
        color="transparent"
        dark
        class="mt-5"
      >
        <v-card-title>
          {{ e.descricao }}
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="e.produtores"
          class="elevation-1"
          :items-per-page="-1"
          hide-default-footer
        >
          <template
            #[`item.data_limite_visita`]="{ value }"
          >
            {{ formatDate(value, "DD/MM/YYYY HH:mm") }}
          </template>
          <template
            #[`item.data_ultima_inconformidade`]="{ value }"
          >
            {{ formatDate(value, "DD/MM/YYYY HH:mm") }}
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>

import VisitsGraphic from "@/Domains/Projects/Visits/VisitsGraphic.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
// import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "visits-report",

  components: {
    VisitsGraphic,
    // MonthRangePicker,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {

      loading: false,

      filter: {
        project: {
          id_assistencia: null,
          descricao: '',
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [moment().format('YYYY-MM'),  moment().format('YYYY-MM')],

        min: moment().subtract(5, 'years').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),
      },

      projectList: [],
      visits: [],
      emergency: [],

      headers: [
        { text: "Código", value: "codigo_laticinio" },
        { text: "Produtor", value: "nome_produtor" },
        { text: "Última ocorrência", value: "data_ultima_inconformidade" },
        { text: "Prazo para visitar", value: "data_limite_visita" },
        { text: "Qte ocorrência", value: "quantidade_inconformidades" }
      ],

    };
  },

  created() {
    this.loadGoals();
  },

  methods: {

    async loadRange() {
      try {
        this.loading = true;
        let visits = this.filter.project.visits ;

        let { data } = await this.$axios.post(
          `/relatorioIn/loadVisitsGoals`,
          {
            meta: visits,
            data_inicio: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
          }
        );
        this.visits = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadGoals() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy",
            view: 'visits',
          }
        );

        this.projectList = data.map((proj) => {
          return {
            visits: proj.visita ? JSON.parse(proj.visita) : [],
            descricao: proj.descricao,
            id_assistencia: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadRange();
          await this.loadEmergency();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadEmergency() {
      try {
        this.loading = true;
        let visits = this.filter.project.visits ;

        let { data } = await this.$axios.post(
          `/relatorioIn/listaMetaVisitaInconformes`,
          {
            meta: visits,
          }
        );
        this.emergency = data;
        console.log(this.emergency);
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
