<template>
  <div>
    <v-autocomplete
      v-model="computedInput"
      :loading="loading"
      :items="items"
      item-text="name"
      item-value="id"
      multiple
      dark
      prepend-inner-icon="build"
      :label="type === 'CAPTACAO' ? 'Técnico/Motorista' : 'Vendedor'"
      return-object
      hide-details
      filled
      :rules="rules"
    >
      <template #selection="{ }" />

      <template #item="{ item }">
        <v-list-item-content @click.stop="onAddItem(item)">
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-list
      v-if="computedInput.length > 0"
      flat
      dark
      dense
      class="text-left overflow-y-auto"
      style="background-color: rgba(255, 255, 255, 0.08); max-height: 190px;"
    >
      <v-list-item-group>
        <template v-for="(item, index) in input">
          <v-hover
            v-if="!item.remove"
            :key="index"
            v-slot:default="{ hover }"
          >
            <v-list-item class="pl-3">
              <v-list-item-icon class="ml-0 mr-3">
                <v-icon>
                  build
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name | uppercase }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  x-small
                  icon
                  dark
                  color="white"
                  @click="onRemoveItem(item)"
                >
                  <v-icon v-if="hover">
                    close
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-hover>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  filters: {
    uppercase(value) {
      return (value || "").toUpperCase();
    },
  },
  props: {
    value: Array,
    type: {
      type: String,
      default: 'CAPTACAO',
      validator: (value) => ['CAPTACAO', 'COMERCIAL'].indexOf(value) !== -1
    },
    rules: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // Items do Formulário (Autocomplete)
      items: [],
    };
  },

  computed: {

    computedInput() {
      return this.input.filter(item => !item.remove);
    },

  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.loading = true;

        const actions = {
          'CAPTACAO': 'listaMotorista',
          'COMERCIAL': 'listaVendedoresJson',
        };

        const { data } = await this.$axios.post(`/pessoa/${actions[this.type]}`);

        if (_.isString(data)) {
          throw data;
        }

        this.items = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
            accessAll: person.acesso_todos,
            cooperative: person.id_cooperativa,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    /**
     * @event Object
     *
     * Evento acionado ao adicionar um item
     */
    onAddItem(newItem) {

      // primeiro verificamos se o produtor já está na lista
      let already = this.input.find(item => {
        return item.id === newItem.id;
      });

      // Se já existir e estiver marcado para exclusão, removemos a flag
      if (already && already.remove) {
        this.input = this.input.map(item => {
          if (item.id !== newItem.id) {
            return item;
          }

          delete item.remove;

          return item;
        });

        return this.$emit("input", this.input);
      }

      // Se já existir e não estiver com a flag para exclusão, fazemos a remoção dele
      if (already && !already.remove) {
        return this.onRemoveItem(already);
      }

      this.input.push({
        ...newItem,
        pending: true,
      });

      return this.$emit("input", this.input);
    },

    /**
     * @event Object
     *
     * Evento acionado ao remover um item da lista
     */
    onRemoveItem(deletedItem) {
      const index = this.input.findIndex(item => {
        return item.id === deletedItem.id;
      });

      if (index < 0) {
        return;
      }

      if (deletedItem.pending) {
        this.input.splice(index, 1);

        return this.$emit("input", this.input);
      }

      this.$set(this.input, index, {
        ...deletedItem,
        remove: true,
      });

      return this.$emit("input", this.input);
    },

    onChange(event) {
      return this.$emit("input", event);
    },
  },
};
</script>
