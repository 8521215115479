<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="item-registration"
  >
    <v-row>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            <v-col
              cols="12"
              md="3"
              class="pa-0"
            >
              <v-date-range-picker
                v-model="filter.dateRange"
                dark
                @change="loadMaintenances"
              />
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                filled
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter.search"
            :loading="loading"
            dark
            class="elevation-1 report-table"
            @click:row="edit"
          >
            <template #[`item.receipts`]="{ value }">
              <div v-if="value.length > 0">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="showReceipts(value)"
                >
                  attach_file
                </v-icon>
              </div>
              <div v-else>
                <v-chip
                  pill
                  outlined
                  x-small
                >
                  -
                </v-chip>
              </div>
            </template>

            <template #[`item.location`]="{ value }">
              <div v-if="value">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="showLocation(value)"
                >
                  place
                </v-icon>
              </div>
              <div v-else>
                <v-chip
                  pill
                  outlined
                  x-small
                >
                  -
                </v-chip>
              </div>
            </template>

            <template #[`item.date`]="{ value }">
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>

            <template #[`item.price`]="{ value }">
              {{ formatCurrency(value) }}
            </template>

            <template #[`item.quantity`]="{ value }">
              {{ formatNumber(value) }}
            </template>

            <template #[`item.item`]="{ value }">
              <v-chip
                x-small
              >
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="newMaintenance()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="receiptsDialog.show"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Recibos</span>
        </v-card-title>

        <v-card-text>
          <attachments :attachments="receiptsDialog.receipts" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="locationDialog.show"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Localização</span>
        </v-card-title>

        <v-card-text>
          <route-map
            v-if="locationDialog.show"
            :center="$store.state.settings.coordenadas"
            clickable
            :markers="locationDialog.marker"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <maintenance-dialog
      v-model="showDialog"
      :edit-id="editId"
      @save="loadMaintenances"
    />
  </v-container>
</template>

<script>
import isArray from "lodash/fp/isArray";
import moment from "moment-timezone";

import MaintenanceDialog from "@/Domains/Fleet/Maintenance/Components/MaintenanceDialog.vue"
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import Attachments from "@/Domains/Registrations/Person/Components/AttachmentsTab.vue";
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {

  components: {
    MaintenanceDialog,
    VDateRangePicker,
    Attachments,
    RouteMap,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,
      showDialog: false,

      editId: null,

      filter: {
        search: '',
        dateRange: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },

      headers: [
        { text: 'Data', value: 'date' },
        { text: 'Placa', value: 'plate' },
        { text: 'Motorista', value: 'driver' },
        { text: 'Km', value: 'km', align: 'center' },
        { text: 'Quantidade', value: 'quantity', align: 'center' },
        { text: 'Preço', value: 'price', align: 'center' },
        { text: 'Item', value: 'item' },
        { text: 'Recibos', value: 'receipts', align: 'center' },
        { text: 'Localização', value: 'location', align: 'center' },
        { text: '', value: 'action', width: 30 }
      ],

      items: [],

      receiptsDialog: {
        show: false,
        receipts: []
      },

      locationDialog: {
        show: false,
        marker: []
      }
    };
  },

  mounted() {
    this.loadMaintenances();
  },

  methods: {
    async loadMaintenances() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filter.dateRange;

        const { data } = await this.$axios.post(`/manutencao/listaJson`, {
          data_inicio: startDate,
          data_fim: endDate
        });

        if (!isArray(data)) {
          throw data;
        }

        this.items = data.map(item => ({
          id: item.id_manutencao,
          date: item.data,
          plate: item.placa,
          driver: item.nome_pessoa,
          km: parseFloat(item.km) || null,
          quantity: item.quantidade,
          price: item.preco,
          receipts: item.recibos || [],
          location: !!item.latitude && !!item.longitude ? {
            lat: item.latitude,
            lng: item.longitude,
          } : null,
          item: item.item,
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    newMaintenance() {
      this.editId = null,
      this.showDialog = true;
    },

    edit(item) {
      this.editId = item.id,

      this.showDialog = true;
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/manutencao/inativa`,
          { id_manutencao: id }
        );

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.loadMaintenances();
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    showReceipts(receipts) {
      this.receiptsDialog = {
        show: true,
        receipts: receipts.map(uri => ({
          tipo: 'RECIBO',
          uri
        }))
      }
    },

    showLocation(location) {
      this.locationDialog = {
        show: true,
        marker: [{
          location,
          visible: true,
          color: "#00838F",
          draggable: false,
          icon: "directions_car",
        }]
      }
    },

    getReportJson(isExcel = false) {
      return this.items.map(item => ({
        'Data': this.formatDate(item.date, "DD/MM/YYYY"),
        'Placa': item.plate,
        'Motorista': item.driver,
        'Km': item.km,
        'Quantidade': isExcel ? parseFloat(item.quantity) : this.formatNumber(item.quantity),
        'Preço': isExcel ? parseFloat(item.price) : this.formatCurrency(item.price),
        'Item': item.item,
      }));
    },

    getReportTitle() {
      const [startDate, endDate] = this.filter.dateRange;
      return `Manutenções - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      const headers = [
        { text: 'Data', value: 'Data' },
        { text: 'Placa', value: 'Placa' },
        { text: 'Motorista', value: 'Motorista' },
        { text: 'Km', value: 'Km' },
        { text: 'Quantidade', value: 'Quantidade', align: 'center' },
        { text: 'Preço', value: 'Preço', align: 'center', mask: 'R$ #,##0.00' },
        { text: 'Item', value: 'Item' },
      ];
      const report = this.getReportJson(true);
      const title = this.getReportTitle();

      this.exportToFile({ report, title, headers });
    },

    async print() {
      await this.printFromJson(this.getReportJson(), this.getReportTitle(), true);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  },
};
</script>
