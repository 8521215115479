<template>
  <div>
    <v-autocomplete
      ref="autocomplete"
      v-model="input"
      :items="vets"
      :label="label"
      item-value="id"
      item-text="name"
      :loading="loading"
      :return-object="returnObject"
      v-bind="$attrs"
      @input="onItemSelected"
    >
      <template
        #prepend-item
      >
        <v-container
          fill-height fluid
          class="white"
        >
          <v-row justify="center">
            <v-col>
              <v-btn
                block
                outlined
                :disabled="loading"
                @click="addNew"
              >
                Cadastrar novo
                <v-icon>add</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider class="mt-2" />
      </template>

      <template #item="{ item }">
        <v-row>
            <v-col
              cols="10"
            >
              {{ item.name}}
            </v-col>
          <v-col
            class="flex-grow-0 align-self-center text-center pl-0 d-flex"
          >
            <v-btn
              icon
              color="red"
              @click="deleteVeterinarian(item)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>

    <v-dialog
      v-model="showNewDialog"
      width="290"
    >
      <v-container
        fill-height fluid
        class="white"
      >
        <v-row justify="center">
          <v-col>
            <v-text-field
              v-model="vetName"
              label="Nome"
              append-outer-icon="send"
              autofocus
              @click:append-outer="saveNew"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Object],
    },
    label: {
      type: String,
      default: 'Veterinário'
    },
    returnObject: Boolean,
  },

  data() {
    return {
      showNewDialog: false,
      input: null,
      vets: [],
      vetName: '',
      loading: false,
    };
  },

  watch: {
    value(value) {
      this.input = value;
    },
  },

  created() {
    this.getVets();
  },

  methods: {
    onItemSelected() {
      return this.$emit('input', this.input);
    },

    async getVets() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(`/people/veterinarian`);

        this.vets = data.map(o => ({
          id: o.id_pessoa,
          name: o.nome
        }));
      } catch (err) {
        console.warn(err);
        this.close();
      } finally {
        this.loading = false;
      }
    },

    addNew() {
      this.$refs.autocomplete.blur();
      this.showNewDialog = true;
    },

    async saveNew() {
      try {
        this.$root.$progressBar.loading();

        const vetName = this.vetName.trim();
        if (vetName === '') {
          this.showNewDialog = false;
          return;
        } else if(this.vets.find(e => e.name.toLowerCase().trim() == vetName.toLowerCase())) {
          this.$snotify.error("Veterinário já cadastrado!", "Atenção");
          this.showNewDialog = false;
          return;
        }

        const  { data } = await this.$axios.post(`/people/veterinarian`, { nome: vetName });

        const newVet = {
          id: data.id,
          name: vetName,
        };

        this.vets.push(newVet);

        this.input = this.returnObject ? newVet : data.id;
        this.onItemSelected(this.input);

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");
        console.warn(error);
      } finally {
        this.vetName = '';
        this.showNewDialog = false;
        this.$root.$progressBar.hide();
      }
    },

    async deleteVeterinarian(item) {
      try {
        if (!(await this.$root.$confirm('Excluir Veterinário', `Tem certeza que deseja excluir este(a) Veterinário(a) ${item.name}`, { color: 'red' }))) {
          return;
        }

        await this.$axios.delete(`/people/veterinarian/${item.id}`);

        this.input = null;
        this.getVets();

        this.$root.$progressBar.loading();
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao excluir!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    }
  },
};
</script>
