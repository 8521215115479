<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title class="mb-3">
            <v-col>
              <person-autocomplete-filter
                v-model="filter.technician"
                type="TECHNICAL"
                label="Técnico"
                dark
                @change="loadExams"
              />
            </v-col>
            <v-col>
              <routes-autocomplete-filter
                v-model="filter.routes"
                label="Rota"
                dark
                multiple
                @change="loadExams"
              />
            </v-col>
          </v-card-title>
          <exam-details
            v-if="sanity === 'BRUCELOSE'"
            title="Brucelose"
            type="brucelose"
            :data="data"
          />
          <exam-details
            v-if="sanity === 'TUBERCULOSE'"
            title="Tuberculose"
            type="tuberculose"
            :data="data"
          />
          <exam-details
            v-if="sanity === 'VACINAS'"
            title="Vacinas"
            type="vacinas"
            :data="data"
          />
          <exam-details
            v-if="sanity === 'INVENTARIO'"
            title="Inventário"
            type="inventario"
            :data="data"
          />
          <exam-graphic
            v-if="sanity === 'BRUCELOSE'"
            title="Brucelose"
            type="brucelose"
            :data="data"
          />
          <exam-graphic
            v-if="sanity === 'TUBERCULOSE'"
            title="Tuberculose"
            type="tuberculose"
            :data="data"
          />
          <exam-graphic
            v-if="sanity === 'VACINAS'"
            title="Vacinas"
            type="vacinas"
            :data="data"
          />
          <exam-graphic
            v-if="sanity === 'INVENTARIO'"
            title="Inventário"
            type="inventario"
            :data="data"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExamGraphic from "@/Domains/Projects/HealthExamination/Components/ExamGraphic.vue";
import ExamDetails from "@/Domains/Projects/HealthExamination/Components/ExamDetails.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

export default {
  components: {
    ExamGraphic,
    ExamDetails,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter
  },

  props: {
    sanity: String,
  },

  data() {
    return {
      tab: "tab-exams",
      filter: {
        technician: {
          id: "",
          description: ""
        },
        routes: [],
      },
      data: [],
      states: []
    };
  },

  mounted() {
    this.getStates();
    this.loadExams();
  },

  methods: {
    async loadExams() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
            tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id)
          }});

        this.data = data;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getStates() {
      try {
        const { data } = await this.$queries.get(`/estados/listaJson`);

        this.states = data.reduce((result, item) => {
          result[item.uf] = item.end_estado;
          return result;
        }, {});
      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os estados!", "Atenção");
      }
    },
  }
};
</script>
