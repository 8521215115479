<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        Lançamento de estoque
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="pt-5"
      >
        <v-form
          ref="formRef"
          lazy-validation
          @submit="save"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
              class="pb-0"
            >
              <v-select
                v-model="form.tipo"
                label="Tipo *"
                :items="stockTypes"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.quantidade"
                label="Quantidade *"
                :mask="masks.float"
                unmask
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pb-0"
            >
              <money-input
                v-model="form.valor"
                label="Preço Unitário"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-textarea
                v-model="form.observacao"
                label="Observações"
                rows="3"
                auto-grow
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <item-lot-dialog
      v-model="lotDialog.show"
      :items="lotDialog.items"
      :type="form.tipo"
      :warehouse-id="warehouseId"
      @save="onLotSaved"
    />
  </v-dialog>
</template>

<script setup>
import { ref, computed, reactive } from 'vue'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import MoneyInput from '@/Support/Components/MoneyInput.vue'

import ItemLotDialog from '@/Domains/Registrations/Item/Components/ItemLotDialog.vue'

const { progressBar, notify } = useUtils()

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
}

// eslint-disable-next-line
const emit = defineEmits([
  'save',
])

const dialog =  ref(false)

const formRef = ref()

const form = reactive({
  tipo: null,
  quantidade: null,
  observacao: null,
  valor: null,
})

const warehouseId = ref(null)
const product = ref({})

const requiresLot = computed(() => product.value.requiresLot)

const stockTypes = computed(() => {
  if (requiresLot) {
    return [
      { text: 'Entrada', value: 'ENTRADA' },
      { text: 'Saída', value: 'SAIDA' },
    ]
  }

  return [
    { text: 'Entrada', value: 'ENTRADA' },
    { text: 'Saída', value: 'SAIDA' },
    { text: 'Balanço', value: 'BALANCO' },
  ]
})

const close = () => {
  dialog.value = false
}

const show = (data) => {
  dialog.value = true

  warehouseId.value = data.warehouseId
  product.value = data.product

  form.tipo = 'ENTRADA'
  form.quantidade = null
  form.observacao = null
  form.valor = data.product.value

  formRef.value?.resetValidation()
}

const lotDialog =  reactive({
  show: false,
  items: [],
})

const openLotDialog = () => {
  lotDialog.show = true
  lotDialog.items = [
    {
      id_item: product.value.id,
      quantidade: form.quantidade,
    },
  ]
}

const onLotSaved = ([item]) => {
  save(false, item.lotes)
}

const save = async (showLotDialog = true, lots = []) => {
  if (!await formRef.value?.validate()) {
    return
  }

  if (showLotDialog && requiresLot) {
    openLotDialog()
    return
  }

  try {
    progressBar?.saving()

    const payload = {
      id_item: product.value.id,
      id_deposito: warehouseId.value,
      ...form,
      lotes: lots,
    }

    await axios.post(`/stock`, payload)

    close()

    emit('save')

    notify.success('Estoque salvo com sucesso', 'Sucesso')
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao salvar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

// eslint-disable-next-line
defineExpose({
  show
})
</script>
