<template>
  <div
    class="mx-6"
  >
    <questions-card :label="questionnaireName">
      <v-tabs
        v-model="tab"
        dark
        background-color="transparent"
        class="transparent"
        style="margin-bottom: 15px"
      >
        <v-tab href="#tab-report">
          <v-icon class="ma-1">
            list
          </v-icon>
          Relatório
        </v-tab>

        <v-tab
          href="#tab-abc"
          :disabled="abcAnalysis.length === 0"
        >
          <v-icon class="ma-1">
            show_chart
          </v-icon>
          Curva ABC
        </v-tab>

        <v-tab
          href="#tab-abc-visits"
          :disabled="abcAnalysis.length === 0"
        >
          <v-icon class="ma-1">
            show_chart
          </v-icon>
          Visitas
        </v-tab>

        <v-tab
          :href="'#' + tab"
          @click="showConfig = true"
        >
          <v-icon>settings</v-icon>
        </v-tab>

        <v-tab-item value="tab-report">
          <v-row
            v-for="(item, answer) in report"
            :key="answer"
          >
            <v-col cols="12">
              <v-card
                elevation-1
                dark
                color="transparent"
              >
                <v-card-title>
                  {{ answer }}
                </v-card-title>
                <v-data-table
                  dense
                  :headers="[
                    { text: 'Produtor', value: 'name' },
                    { text: answer, value: 'total', align: 'center', width: 150 },
                  ]"
                  hide-default-footer
                  :items="Object.values(item.producers)"
                  disable-pagination
                >
                  <template
                    #[`item.total`]="{ value }"
                  >
                    {{ Math.trunc(value * 100 / item.total) }}%
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="tab-abc">
          <v-row
            v-for="(graph, idx) in abcAnalysis"
            :key="idx"
          >
            <v-col cols="12">
              <v-card
                dark
                :style="{background: 'rgba(0, 0, 0, 0.5)'}"
                class="pa-3"
              >
                <v-chart
                  :options="graph.percentRange"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                dark
                :style="{background: 'rgba(0, 0, 0, 0.5)'}"
                class="pa-3"
              >
                <v-chart
                  :options="graph.percentProducer"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="tab-abc-visits">
          <abc-visits-report
            v-for="(item, idx) in abcAnalysis"
            :key="idx + '-' + item.answer"
            :questionnaire-id="questionnaireId"
            :answer="item.answer"
          />
        </v-tab-item>
      </v-tabs>
    </questions-card>

    <settings-abc-goals-dialog
      ref="settingsDialog"
      v-model="showConfig"
      :questionnaire-id="questionnaireId"
      @labels="onChangeRanges"
    />

    <v-overlay v-model="loading">
      Carregando ...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import QuestionsCard from "@/Domains/Questionnaires/Components/QuestionsCard.vue";
import SettingsAbcGoalsDialog from "@/Domains/Questionnaires/Components/SettingsAbcGoalsDialog.vue";
import AbcVisitsReport from "@/Domains/Questionnaires/Components/AbcVisitsReport.vue";

import _ from "lodash";

export default {

  components: {
    QuestionsCard,
    SettingsAbcGoalsDialog,
    AbcVisitsReport,
  },

  props: {
    questionnaireId: {
      type: String,
    },
    questionnaireName: {
      type: String,
      default: "Resposta Questionário",
    },
  },

  data() {
    return {
      loading: false,

      tab: null,

      report: [],

      abcAnalysis: [],

      showConfig: false,
      configs: [],
    };
  },

  mounted() {
    this.loadAnswers();
  },

  methods: {
    async loadAnswers() {
      try {
        this.loading = true;

        let [{ data: [questionario] }, { data }] = await Promise.all([
          this.$axios.post(
            `/formularioCustomizado/listaFormularioId`,
            this.$qs.stringify({ id_formulario: this.questionnaireId })
          ),
          this.$axios.post(
            `/formularioCustomizado/listaRespostasFormulario`,
            {
              id_formulario: this.questionnaireId,
              agrupa_respostas: true,
            }
          )
        ]);

        if (!data) {
          this.report = [];
          throw "PHP Error";
        }

        /**
         * Verifica se passou
         */
        if (_.has(_.head(data), "codigo")) {
          this.report = [];
          throw data.mensagem;
        }

        this.setRanges(questionario);

        let valores = questionario.formulario.filter(o => o.tipo === 'CHECKBOX' && o.valores).flatMap(o => o.valores);
        
        const producers = data.reduce((acc, cur) => {
          if (!(cur.id_produtor in acc)) {
            acc[cur.id_produtor] = {
              name: cur.nome_produtor,
              total: 0
            };
          }
          return acc;
        }, {});

        const baseReport = valores.reduce((acc, cur) => {
          if (!(cur in acc)) {
            acc[cur] = {
              total: 0,
              producers: _.cloneDeep(producers)
            };
          }
          acc[cur].total += 1;
          return acc;
        }, {});

        const report = data.reduce((acc, cur) => {
          acc[cur.resposta].producers[cur.id_produtor].total += 1;

          return acc;
        }, baseReport);

        this.report = report;

        this.generateAbcCharts();

      } catch (err) {
        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    setRanges(questionario) {
      this.configs = this.$refs.settingsDialog.getLabels(questionario);
    },

    onChangeRanges(configs) {
      this.configs = configs;
      this.generateAbcCharts();
    },

    /**
     * @void
     * Evento de geração da Curva ABC
     */
    generateAbcCharts() {
      const labels = this.configs.filter(({ label }) => label.enabled).map(({ label }) => label.name);
      try {
        const analysis = labels.map(title => {
          const data = this.report[title];
          return {
            title: title,
            config: this.configs.find(({ label }) => label.name === title).label,
            data: Object.values(data.producers).map(producer => ({
              producer: producer.name,
              quantity: producer.total,
              percent: producer.total * 100 / data.total
            })).sort((a, b) => a.percent - b.percent)
          }
        })

        this.abcAnalysis = analysis.map(data => this.generateAbcGraph(data));
      } catch (error) {

        this.$snotify.info(`${error}`, "Atenção");
      }
    },

    generateAbcGraph({ title, config, data }) {
      let prev = 0;
      let ranges = config.ranges.map(range => {
        const name = `${prev}% a ${range.value}%`;
        prev = range.value;
        return {
          name,
          limit: parseFloat(range.value),
          numProducers: 0
        };
      })
      
      const pieRange = data.reduce((acc, item) => {
        for (let idx in ranges) {
          if (item.percent <= ranges[idx].limit) {
            acc[idx].value++;
            break;
          }
        }
        return acc;
      }, ranges.map(({ name }) => ({ name, value: 0 })));
      
      const dataRange = data.reduce((acc, item) => {
        for (let idx in ranges) {
          if (item.percent <= ranges[idx].limit) {
            acc[idx].numProducers++;
            break;
          }
        }
        return acc;
      }, ranges);

      return {
        answer: title,
        percentRange: {
          title: {
            text: `Faixa: ${title}`,
            textStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            show: true
          },
          legend: [
            { show: false },
            {
              orient: 'vertical',
              bottom: 'center',
              left: 'right',
              icon: 'circle',
              textStyle: {
                color: '#ddd',
              }
            }
          ],
          grid: [
            {
              left: '1%',
              top: '20%',
              bottom: '10%',
              width: '60%',
              containLabel: true,
              tooltip: {
                trigger: 'item',
                formatter: (item) => `${item.data.name}: ${item.data.numProducers} produtor(es)`
              },
            },
            { right: '2%', width: '40%' },
          ],
          xAxis: [{
            type: 'category',
            data: dataRange.map(({ numProducers }) => `${+(numProducers * 100 / data.length).toFixed(2)}% dos produtores`),
            axisLabel: {
              fontSize: 10,
              textStyle: { color: '#ddd' }
            },
          }],
          yAxis: [{
            type: 'value',
            boundaryGap: false,
            max: 100,
            axisLabel: {
              textStyle: { color: '#ddd' }
            },
          }],
          series: [
            {
              type: 'line',
              smooth: 0.5,
              symbol: 'none',
              lineStyle: {
                color: '#FFB300',
                width: 5
              },
              data: dataRange.map(o => ({ ...o, value: o.limit })),
              markLine: {
                symbol: ['none', 'none'],
                label: { show: false },
                data: dataRange.map((_, idx) => ({ xAxis: idx })),
                lineStyle: { width: 2, color: '#80D8FF' }
              },
            },
            {
              type: 'pie',
              labelLine: { show: false },
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
              },
              top: -50,
              radius: '65%',
              center: ['75%', '50%'],
              data: pieRange
            }
          ]
        },
        percentProducer: {
          title: {
            text: `Produtores`,
            textStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line'
            }
          },
          grid:
          {
            left: '1%',
            top: '20%',
            right: '2%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: data.map(({ producer }) => producer),
            axisLabel: {
              fontSize: 10,
              textStyle: { color: '#ddd' }
            },
          },
          yAxis: {
            type: 'value',
            boundaryGap: false,
            max: 100,
            axisLabel: {
              textStyle: { color: '#ddd' }
            },
          },
          series: [
            {
              type: 'line',
              smooth: 0.5,
              symbol: 'none',
              lineStyle: {
                color: '#5470C6',
                width: 5
              },
              data: data.map(item => item.percent)
            },
          ]
        },
      }
    },
  },
};
</script>
