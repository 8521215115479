<template>
  <v-timeline-item
    v-if="item.type === 'DURATION'"
    :key="item.id"
    fill-dot
    icon="alarm"
    icon-color="white"
    color="grey"
    right
    small
  >
    <div class="text-right text-overline mt-1">
      <v-tooltip
        v-if="item.gpsDiff"
        top
      >
        <template #activator="{ on }">
          <span
            class="mr-4"
            v-on="on"
          >
            {{ formatNumber(item.gpsDiff) }} Km
          </span>
        </template>
        Distância entre produtores
      </v-tooltip>
      {{ item.duration }}
      <div>
        Tempo Veículo Parado: <b>{{ item.totalTimeStop.total }}</b>
      </div>
    </div>
  </v-timeline-item>
</template>

<script>
export default {

  props: {
    item: {
      type: Object,
      default: () => ({
        type: 'DURATION',
        duration: '',
        diffGps: 0.0,
      }),
    },
  },

  methods: {
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
}
</script>
