<template>
  <v-timeline
    reverse
    align-top
  >
    <v-timeline-item
      v-for="(row, index) in history"
      :key="index"
      :color="rowStyle(row.status).color"
      fill-dot
    >
      <template #icon>
        <v-icon
          color="white"
        >
          {{ rowStyle(row.status).icon }}
        </v-icon>
      </template>

      <template #opposite>
        <span
          :class="`headline font-weight-bold ${rowStyle(row.status).color}--text`"
        >
          {{ row.date ? dateFormat(row.date, 'DD/MM/YYYY') : 'Data não informada' }}
        </span>
      </template>

      <v-card
        :color="rowStyle(row.status).color"
        dark
      >
        <v-card-title class="text-h6 py-2">
          {{ StatusEnum[row.status] }}

          <v-spacer />

          <v-menu
            v-if="!listOnly"
            bottom
            right
            small
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="onEdit(row)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="row.params.showExcludeBtn"
                @click="onExclude(row)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>

        <v-card-text
          v-if="row.status !== 'CADASTRO'"
          class="white text--primary"
        >
          <div class="pt-2">
            <div v-if="row.reason || row.otherReasons">
              <p class="mb-0">
                <b v-if="!['VINCULADO_GRUPO', 'DESVINCULADO_GRUPO'].includes(row.status)">
                  Motivo:
                </b>
              </p>

              <v-chip
                v-if="!row.otherReasons"
                small
              >
                {{ ReasonsEnum[row.reason] }}
              </v-chip>

              <v-chip
                v-else
                small
              >
                {{ row.otherReasons || 'Motivo não informado.' }}
              </v-chip>
            </div>

            <div
              v-if="(row.competitor || {}).id"
              class="mt-2"
            >
              <p class="mb-0">
                <b>Laticínio Concorrente: </b>
              </p>

              <v-chip small>
                {{ row.competitor.name }}
              </v-chip>
            </div>

            <div
              v-if="showUsername && row.userName"
              class="mt-2"
            >
              <p class="mb-0">
                <b>Usuário:</b> {{ row.userName }}
              </p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { ReasonsEnum } from "@/Domains/Producer/Management/Enums/ReasonsEnum.js";
import { StatusEnum } from "@/Domains/Producer/Management/Enums/StatusEnum.js";

import moment from "moment/moment";

export default {

  props: {
    listOnly: Boolean,

    showUsername: Boolean,

    history: {
      type: Array,
      default: () => ([]),
    }
  },

  data() {
    return {
      StatusEnum,
      ReasonsEnum,
    };
  },

  methods: {
    rowStyle(status) {
      if (status === 'CADASTRO') {
        return {
          icon: 'add_circle',
          color: 'deep-purple'
        };
      }

      if (status === 'SUSPENSO') {
        return {
          icon: 'remove_circle_outline',
          color: 'orange'
        };
      }

      if (status === 'DESVINCULADO') {
        return {
          icon: 'person_add_disabled',
          color: 'red'
        };
      }

      if (status === 'NEGOCIACAO') {
        return {
          icon: 'paid',
          color: 'blue'
        };
      }

      if (status === 'LEAD') {
        return {
          icon: 'visibility',
          color: 'orange'
        };
      }

      if (status === 'APROVACAO') {
        return {
          icon: 'verified',
          color: 'teal'
        };
      }

      if (status === 'APROVADO_CAPTACAO') {
        return {
          icon: 'verified',
          color: 'green'
        };
      }

      if (status === 'REPROVADO') {
        return {
          icon: 'cancel',
          color: 'red'
        };
      }

      if (status === 'APROVADO_QUALIDADE') {
        return {
          icon: 'verified',
          color: 'green'
        };
      }

      if (status === 'REPROVADO_QUALIDADE') {
        return {
          icon: 'cancel',
          color: 'red'
        };
      }

      if (status === 'VINCULADO_GRUPO') {
        return {
          icon: 'verified',
          color: 'green'
        };
      }

      if (status === 'DESVINCULADO_GRUPO') {
        return {
          icon: 'cancel',
          color: 'red'
        };
      }

      return {
        icon: 'done_all',
        color: 'blue'
      };
    },

    onEdit(row) {
      return this.$emit('edit', row);
    },

    onExclude(row) {
      return this.$emit('exclude', row);
    },

    dateFormat(date, format) {
      return moment(date).format(format);
    },
  }
};
</script>
