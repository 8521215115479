<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <!-- <v-row>
      <v-spacer />
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          :ranges="filter.ranges"
          dark
          @change="getPayments"
        />
      </v-col>
    </v-row> -->

    <v-card
      class="report-card"
      color="transparent"
      dark
    >
      <v-card-title>
        <v-spacer />
        <v-col
          cols="4"
          class="pa-0"
        >
          <v-text-field
            v-model="filter.search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            dark
            class="pt-0 my-2"
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="filter.search"
        :loading="loading"
        dark
        dense
        disable-sort
        class="elevation-1 report-table" 
        @click:row="showPayment"
      >
        <template #[`item.date`]="{ value }">
          {{ value | dateFormat('DD/MM/YYYY') }}
        </template>
        <template #[`item.total`]="{ value }">
          R$ {{ value | formatCurrency }}
        </template>
        <template #[`item.invoices_qty`]="{ item, value }">
          <v-btn
            icon
            small
            @click.stop="downloadInvoices(item)"
          >
            <v-icon>{{ `filter_${(10 > value) ? value : '9_plus'}` }}</v-icon>
          </v-btn>
        </template>
        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="remove(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <payment-dialog
      v-model="paymentDialog.show"
      :id-payment="paymentDialog.id"
      :show-details="paymentDialog.showDetails"

      @newPayment="onNewPayment"
    />

    <confirm ref="confirm" />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="red darken-1"
        @click="newPayment"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Novo pagamento
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
//import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import PaymentDialog from "@/Domains/Projects/PMLS/Components/PaymentDialog.vue";
import Confirm from "@/Support/Components/Confirm.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";

moment.locale('pt'); 

export default {
  name: "pmls-payments",

  components: {
    //VDateRangePicker,
    PaymentDialog,
    Confirm,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  mixins: [ReportMixin],

  props: ["parent"],

  data() {
    return {
      loading: false,

      paymentDialog: {
        show: false,
        id: null,
        showDetails: false,
      },

      filter: {
        ranges: {
          'Este ano': [moment().startOf("year").format("YYYY-MM-DD"), moment().endOf("year").format("YYYY-MM-DD")],
          'Ano anterior': [moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"), moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")],
        },

        range: [],

        search: '',
      },

      headers: [
        { text: 'Data de pagamento', value: 'date' },
        { text: 'Descrição', value: 'description' },
        { text: 'Fornecedor', value: 'supplier' },
        { text: 'Categoria', value: 'category' },
        { text: 'Nota', value: 'invoice' },
        { text: 'Valor', value: 'total' },
        { text: 'Notas', value: 'invoices_qty' },
        { text: '', value: 'action', width: 30 },
      ],

      items: [],
    };
  },

  created() {
    this.getPayments();
  },

  methods: {
    newPayment() {
      this.paymentDialog = {
        show: true,
        id: null,
        showDetails: false
      };
    },
    onNewPayment() {
      this.getPayments();
    },
    async getPayments() {
      const [startDate, endDate] = this.filter.range;

      const {data} = await this.$axios.post(`/projetos/listaPagamentos`, {
        data_inicio: startDate,
        data_fim: endDate
      });

      this.items = data.map(p => ({
        id: p.id_pagamento,
        date: p.data_pagamento,
        description: p.descricao,
        supplier: p.fornecedor,
        category: p.categoria,
        invoice: p.numero_nota,
        total: p.valor_total,
        invoices_qty: JSON.parse(p.anexos).length,
        invoices: JSON.parse(p.anexos),
      }))
    },
    async remove({id}) {
      if (!(await this.$refs.confirm.open('Remover registro', 'Tem certeza que deseja remover este pagamento?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/projetos/inativaPagamento`,
          {
            id_pagamento: id
          }
        );
        
        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.getPayments();
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch(error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    downloadInvoices({invoices}) {
      invoices.forEach(({url, name}) => {
        let f = document.createElement("a");
        f.setAttribute("href", url);
        f.setAttribute("download", name);
        f.click();
      })
    },
    showPayment({id}) {
      this.paymentDialog = {
        show: true,
        id,
        showDetails: true
      };
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      let data = this.XLSX.utils.json_to_sheet(this.items.map(item => ({
          'Data de pagamento': this.$options.filters.dateFormat(item.date, 'DD/MM/YYYY'),
          Descrição: item.description,
          Fornecedor: item.supplier,
          Categoria: item.category,
          Nota: item.invoice,
          Valor: `R$ ${this.$options.filters.formatCurrency(item.total)}`,
          Notas: item.invoices_qty,
      })), {origin: 'A3'});

      data["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }];
      
      data['A1'] = { v: 'Pagamentos' };

      data['!cols'] = [
        { wch: 16 },
        { wch: 22 },
        { wch: 20 },
        { wch: 20 },
        { wch: 14 },
        { wch: 14 },
        { wch: 5 },
      ];

      const workbook = this.XLSX.utils.book_new();

      const filename = "pmls_pagamentos";
      
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /**
     * Imprime o relatório
     */
    print() {
      return this.printFromJson(this.items.map(item => ({
          'Data de pagamento': this.$options.filters.dateFormat(item.date, 'DD/MM/YYYY'),
          Descrição: item.description,
          Fornecedor: item.supplier,
          Categoria: item.category,
          Nota: item.invoice,
          Valor: `R$ ${this.$options.filters.formatCurrency(item.total)}`,
          Notas: item.invoices_qty,
      })), "Pagamentos");
    },
  },
};
</script>