<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-icon
        :color="statusList[value.status].color"
        v-on="on"
        v-text="statusList[value.status].icon"
      />
    </template>

    <span class="d-flex flex-column justify-center text-center">
      {{ value.status }}
      <template v-if="value.date">
        <br> Data: {{ dateFormat(value.date, 'DD/MM - HH:mm') }}
      </template>
    </span>
  </v-tooltip>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import moment from "moment-timezone";

export default {
  props: {
    value: Object
  },

  data() {
    return {
      statusList: {
        'PENDENTE': { color: colors.orange.darken1, icon: 'pending_actions' },
        'APROVADO': { color: colors.green.darken1, icon: 'verified' },
        'REJEITADO': { color: colors.red.darken1, icon: 'cancel' },
      }
    }
  },

  methods: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
</script>