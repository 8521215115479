<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Controle de pedidos de análises
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="load"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pt-0"
          >
            <v-select
              v-model="filters.products"
              :items="products"
              label="Produto"
              item-value="id"
              item-text="description"
              multiple
              clearable
              hide-details
              dark
              filled
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
              dark
              filled
            />
          </v-col>
        </v-row>

        <v-card
          dark
          color="transparent"
        >
          <v-tabs
            ref="tabs"
            v-model="tab"
            background-color="rgba(0, 0, 0, 0.5)"
            class="transparent"
            dark
            right
            icons-and-text
          >
            <v-tab
              v-for="status in tabs"
              :key="status"
              :href="'#tab-' + status"
            >
              <span class="ma-0">
                <v-chip
                  :color="getStatusColor(status)"
                  small
                />
                {{ status }}
                <template v-if="status === 'TODOS'">
                  <span v-if="filteredItems.length > 0">({{ filteredItems.length }})</span>
                </template>
                <template v-else>
                  <span v-if="totalByStatus[status] > 0">({{ totalByStatus[status] }})</span>
                </template>
              </span>
            </v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="tab"
            class="transparent"
          >
            <v-tab-item
              v-for="status in tabs"
              :key="status"
              :value="'tab-' + status"
            >
              <v-card-title class="pt-0" />

              <data-table
                :ref="'report-' + status"
                name="Controle de pedidos de análises"
                :headers="headers"
                :items="getItems(status)"
                dark
                @click:row="edit"
              >
                <template #[`item.productAnalysis.analysis.type`]="{ value }">
                  <v-chip
                    small
                    :color="getTypeColor(value)"
                  >
                    {{ getType(value) }}
                  </v-chip>
                </template>

                <template #[`item.status.value`]="{ value, item }">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        small
                        rounded
                        :color="getStatusColor(value)"
                        elevation="0"
                        v-on="on"
                        @click.stop="onApproval(item)"
                      >
                        <v-icon
                          left
                          dark
                          v-text="getStatusIcon(value)"
                        />
                        {{ value }}
                      </v-btn>
                    </template>

                    <span class="d-flex flex-column justify-center text-center">
                      {{ value }}
                      <template v-if="item.status.approver">
                        <br> Supervisor: {{ item.status.approver.name }}
                      </template>
                      <template v-if="item.status.justification">
                        <br> Justificativa: {{ item.status.justification }}
                      </template>
                      <template v-if="item.status.date">
                        <br> Data: {{ formatDate(item.status.date, 'DD/MM/YYYY HH:mm') }}
                      </template>
                    </span>
                  </v-tooltip>
                </template>

                <template #[`item.action`]="{ item }">
                  <v-menu
                    bottom
                    right
                  >
                    <template
                      #activator="{ on }"
                    >
                      <v-btn
                        icon
                        v-on="on"
                      >
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-if="hasApprovalAccess"
                        @click="onApproval(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>pending_actions</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Aprovação</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="remove(item.id)"
                      >
                        <v-list-item-icon>
                          <v-icon>delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <sample-order-control-dialog
      v-model="dialog.show"
      :edit-id="dialog.id"
      @save="load"
    />

    <approval-dialog
      v-model="approvalDialog.show"
      :order-id="approvalDialog.id"
      @save="load"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="add()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import SampleOrderControlDialog from '@/Domains/Analysis/SampleOrderControl/Components/SampleOrderControlDialog.vue'
import ApprovalDialog from '@/Domains/Analysis/SampleOrderControl/Components/ApprovalDialog.vue'

import moment from 'moment';

export default {

  components: {
    VDateRangePicker,
    SampleOrderControlDialog,
    ApprovalDialog,
  },

  data() {
    return {
      filters: {
        dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        products: [],
        type: null,
        search: '',
      },

      headers: [
        { text: 'Produto', value: 'productAnalysis.item.description' },
        { text: 'Natureza', value: 'productAnalysis.analysis.type', formatter: value => this.getType(value) },
        { text: 'Análise', value: 'productAnalysis.analysis.description' },
        { text: 'Solicitante', value: 'requester.name' },
        { text: 'Data', value: 'date', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Projeto', value: 'project', formatter: value => this.getProject(value) },
        { text: 'Status Aprovação', value: 'status.value' },
        { text: '', altText: 'Ações', value: 'action', width: 30, sortable: false },
      ],

      items: [],

      types: [
        { value: 'FISICO-QUMICA', text: 'Físico-química' },
        { value: 'MICROBIOLOGICA', text: 'Microbiológica' },
      ],

      products: [],

      tabs: ['TODOS', 'PENDENTE', 'APROVADO', 'REPROVADO'],

      tab: null,

      dialog: {
        show: false,
        id: null,
      },

      approvalDialog: {
        show: false,
        id: null,
      },
    };
  },

  computed: {
    totalByStatus() {
      return this.filteredItems.reduce((acc, cur) => {
        if (!(cur.status.value in acc)) {
          acc[cur.status.value] = 0;
        }
        acc[cur.status.value]++;
        return acc;
      }, {})
    },

    filteredItems() {
      if (this.filters.products.length === 0 && !this.filters.search) {
        return this.items;
      }

      const products = this.filters.products;
      const search = String(this.filters.search).toUpperCase().trim();

      return this.items.filter(item => {
        const hasProducts = products.length === 0 || products.includes(item.productAnalysis.item.id);
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasProducts && hasSearch;
      });
    },

    /**
     * Permissões de tela
     */
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasApprovalAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "sample-order-control-approval" && o.tipo === "COMPONENTE");
    },
  },

  created() {
    this.getProducts();
    this.load();
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/analysis/order-control`, { params: {
          start_date: startDate,
          end_date: endDate,
        } });

        this.items = data.map(item => ({
          id: item.id_controle_analise,
          productAnalysis: {
            id: item.id_produto_analise,
            item: {
              id: item.produto_analise?.produto?.id_item,
              name: item.produto_analise?.produto?.nome,
              code: item.produto_analise?.produto?.cod_item,
              description: item.produto_analise?.produto?.cod_item ? `${item.produto_analise?.produto?.cod_item} - ${item.produto_analise?.produto?.nome}` : item.produto_analise?.produto?.nome,
            },
            analysis: {
              id: item.produto_analise?.analise?.id_tipo_analise,
              description: item.produto_analise?.analise?.descricao,
              type: item.produto_analise?.analise?.natureza,
            },
            periodicity: item.produto_analise?.periodicidade,
            quantity: item.produto_analise?.quantidade_periodo,
          },
          laboratory: {
            id: item.laboratorio?.id_laboratorio,
            name: item.laboratorio?.nome,
          },
          date: item.data_hora_cadastro,
          quantity: item.quantidade,
          project: item.projeto,
          description: item.descricao,
          requester: {
            id: item.id_solicitante,
            name: item.nome_solicitante,
          },
          status: {
            value: item.status,
            date: item.data_hora_status,
            justification: item.justificativa,
            approver: item.id_supervisor ? {
              id: item.id_supervisor,
              name: item.nome_supervisor,
            } : null,
          }

        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
        this.$refs.tabs && this.$refs.tabs.onResize();
      }
    },

    add() {
      this.dialog.id = null;
      this.dialog.show = true;
    },

    edit(item) {
      this.dialog.id = item.id;
      this.dialog.show = true;
    },

    async remove(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.delete(`/analysis/order-control/${id}`);

        this.load();
        this.$snotify.success('Item excluído com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getProducts() {
      try {
        const { data } = await this.$axios.get(`/item`, { params: {
          tipos: ['PRODUTO_ACABADO', 'INSUMO_PRODUCAO', 'MATERIA_PRIMA']
        } });

        this.products = data
          .map(item => ({
            id: item.id_item,
            name: item.nome,
            code: item.cod_item,
            description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
          }));
      } catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os produtos!', 'Atenção');
      }
    },

    getItems(status) {
      if (status === 'TODOS') {
        return this.filteredItems;
      }

      return this.filteredItems.filter(item => item.status.value === status);
    },

    onApproval(item) {
      if (!this.hasApprovalAccess) {
        return;
      }

      this.approvalDialog.id = item.id;
      this.approvalDialog.show = true;
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Controle de pedidos de análises - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const report = this.tab.replace('tab', 'report');
      const title = this.getReportTitle();
      this.$refs[report][0].print(null, title);
    },

    exportExcel() {
      const report = this.tab.replace('tab', 'report');
      const filename = this.getReportTitle();
      this.$refs[report][0].exportExcel(null, filename);
    },

    getType(value) {
      const types = {
        'FISICO-QUMICA': 'Físico-química',
        'MICROBIOLOGICA': 'Microbiológica',
      }
      return types[value] || '-'
    },

    getTypeColor(value) {
      const types = {
        'FISICO-QUMICA': 'orange',
        'MICROBIOLOGICA': 'yellow darken-1',
      }
      return types[value] || 'grey'
    },

    getStatusColor(value) {
      const types = {
        'PENDENTE': 'orange',
        'APROVADO': 'green',
        'REPROVADO': 'red',
      }
      return types[value] || 'grey'
    },

    getStatusIcon(value) {
      const types = {
        'PENDENTE': 'pending_actions',
        'APROVADO': 'verified',
        'REPROVADO': 'cancel',
      }
      return types[value] || 'pending_actions'
    },

    getProject(value) {
      const types = {
        'ROTINA': 'Rotina',
        'ESPECIAL': 'Especial',
      }
      return types[value] || '-'
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
