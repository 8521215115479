<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :loading="loading"
    :style="{background: color, overflow: 'hidden', height: height}"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        icon-tipo_operacao
      </v-icon>
      <span class="title font-weight-light">{{ title }}</span>
    </v-card-title>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: "average-milk-price-chart",

  props: {
    title: {
      type: String,
      default: 'Média Preço Leite por Mês'
    },
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '300px',
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
    referenceMonth: {
      type: String,
      default: () => moment().subtract(1, "month").format("YYYY-MM"),
    },
  },

  data() {
    return {
      loading: false,
      items: [],
    }
  },

  computed: {
    options() {
      const label = {
        show: true,
        lineHeight: 17,
        height: 17,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatCurrency(value)}  `
      };

      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => {
                const formatter = (value) => param.seriesName === 'Volume Total'
                  ? this.formatNumber(value) + ' L'
                  : this.formatCurrency(param.value)
                return `${param.marker} ${param.seriesName}: <b>${formatter(param.value)}</b>`
              }),
              `• Preço Médio Frete: <b>${this.formatCurrency(this.items[params[0].dataIndex].valor_medio_frete)}</b>`
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.items.map((item) => moment(item.mes, 'YYYY-MM').format('MM/YYYY')),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff',
              formatter: (value) => this.formatCurrency(value)
            }
          },
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff',
              formatter: (value) => `${this.formatNumber(value)} L`
            }
          }
        ],
        series: [{
          name: 'Preço Médio',
          type: 'bar',
          emphasis: { focus: 'series' },
          data: this.items.map((item) => item.valor_medio),
          // Atualizar para echarts >= 5.3
          // tooltip: {
          //   valueFormatter: (value) => this.formatCurrency(value)
          // },
          label
        }, {
          name: 'Preço Médio c/ Frete',
          type: 'bar',
          emphasis: { focus: 'series' },
          data: this.items.map((item) => item.valor_medio_com_frete),
          label
        }, {
          name: 'Volume Total',
          type: 'line',
          yAxisIndex: 1,
          lineStyle: { width: 2 },
          showSymbol: false,
          smooth: this.smooth,
          emphasis: { focus: 'series' },
          data: this.items.map((item) => item.volume_total),
        }]
      }
    }
  },

  watch: {
    referenceMonth() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const startDate = moment(this.referenceMonth, 'YYYY-MM').startOf("month").subtract(6, "month").format('YYYY-MM-DD');
        const endDate = moment(this.referenceMonth, 'YYYY-MM').endOf("month").format('YYYY-MM-DD');

        const { data } = await this.$axios.get(`/tabelaPreco/mediaPrecoLeiteMes`, { params: {
          data_inicio: startDate,
          data_fim: endDate
        } });

        this.items = data;
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de taxa de ocupação!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
  }
}
</script>
