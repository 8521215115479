<template>
  <v-dialog
    v-if="dialog.show"
    v-model="dialog.show"
    width="1500"
    persistent
    transition="dialog-bottom-transition"
  >
    <div
      class="d-flex"
    >
      <v-card
        min-height="825"
        width="250"
        class="mx-auto"
        tile
      >
        <v-navigation-drawer
          permanent
          width="250"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ settings.system.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-weight-bold">
                {{ settings.dairy.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Integração com Laboratório
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list
            dense
            nav
          >
            <v-list-item-group :value="selectedMenuIndex">
              <v-list-item
                v-for="menuItem of menu"
                :key="menuItem.view"
                link
                @click="setView(menuItem.view)"
              >
                <v-list-item-icon>
                  <v-icon v-text="menuItem.icon" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="menuItem.description" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>

      <lab-dairy-settings
        v-if="selectedMenu === 'settings'"
        :labs="labs"
        :settings="settings"
        :loading.sync="loading"
        :dialog="dialogSettings"
        @onSave="onSave"
        @close="close"
      />

      <lab-dairy-history
        v-if="selectedMenu === 'history'"
        :settings="settings"
        :loading.sync="loading"
        :dialog="dialogSettings"
        @close="close"
      />

      <lab-dairy-errors
        v-if="selectedMenu === 'errors'"
        :settings="settings"
        :loading.sync="loading"
        :dialog="dialogSettings"
        @close="close"
      />

      <lab-dairy-manual-run
        v-if="selectedMenu === 'run'"
        :settings="settings"
        :loading.sync="loading"
        :dialog="dialogSettings"
        @close="close"
      />

      <v-overlay
        v-model="loading"
        absolute
      >
        Carregando...
      </v-overlay>
    </div>
  </v-dialog>
</template>

<style>

</style>

<script>
import moment from "moment";

import LabDairySettings from "@/Domains/SettingsMilkup/Labs/Components/LabDairyDialog/Settings.vue"
import LabDairyHistory from "@/Domains/SettingsMilkup/Labs/Components/LabDairyDialog/History.vue"
import LabDairyErrors from "@/Domains/SettingsMilkup/Labs/Components/LabDairyDialog/Errors.vue"
import LabDairyManualRun from "@/Domains/SettingsMilkup/Labs/Components/LabDairyDialog/ManualRun.vue"

export default {

  components: {
    LabDairySettings,
    LabDairyHistory,
    LabDairyErrors,
    LabDairyManualRun,
  },

  props: {
    labs: {
      type: Array,
      default: () => ([]),
    }
  },

  data() {
    return {
      // Exibe ou não o dialog
      dialog: {
        show: false,
      },

      // loader da aplicação
      loading: false,

      settings: {},

      dialogSettings: {
        width: 1250,
        height: 825,
      },

      // Menu selecionado
      menu: [
        {
          icon: 'settings',
          description: 'Parâmetros',
          view: 'settings',
        },
        {
          icon: 'event_note',
          description: 'Histórico',
          view: 'history',
        },
        {
          icon: 'bug_report',
          description: 'Erros de Execução',
          view: 'errors',
        },
        {
          icon: 'wifi_protected_setup',
          description: 'Execução Manual',
          view: 'run',
        }
      ],

      // Menu selecionado
      selectedMenu: 'settings',
    }
  },

  computed: {

    selectedMenuIndex() {
      return this.menu.findIndex(menu => menu.view === this.selectedMenu);
    },

  },

  methods: {

    async show(settings) {
      this.dialog.show = true;
      this.settings = settings;
    },

    close() {
      this.dialog.show = false;
      this.selectedMenu = 'settings';
      this.settings = null;
    },

    setView(view) {
      this.selectedMenu = view;
    },

    onSave() {
      return this.$emit('reload');
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),

  }

}
</script>
