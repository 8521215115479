<template>
  <v-dialog
    v-model="show"
    width="450"
    persistent
  >
    <v-card class="discharge-dialog">
      <v-card-title>
        Quitação
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="show = false"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="pb-0">
        {{ form.descricao }}
      </v-card-subtitle>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            cols="3"
            class="text-center d-flex flex-column justify-center pa-0"
          >
            <span class="body-1">{{ form.saldo | formatCurrency }}</span>
            <span class="caption text-uppercase">Saldo</span>
          </v-col>
          <v-col
            cols="3"
            class="text-center d-flex flex-column justify-center pa-0"
          >
            <span class="body-1">{{ form.data_vencimento | dateFormat('DD/MM/YYYY') }}</span>
            <span class="caption text-uppercase">Vencimento</span>
          </v-col>
          <v-col
            cols="6"
            class="text-center"
          >
            <span class="display-1 font-weight-light d-block">{{ totalDischarge | formatCurrency }}</span>
            <span
              class="overline d-block"
              :class="`${color}--text`"
            >{{ form.debito_credito == 'D' ? 'Pagamento' : 'Recebimento' }}</span>
          </v-col>
        </v-row>
        <div class="discharge-content">
          <div>
            <v-expansion-panels
              v-model="panel"
            >
              <v-expansion-panel
                v-for="(pagamento, i) in form.pagamentos"
                :key="i"
              >
                <v-expansion-panel-header
                  v-slot="{ open }"
                  class="px-1"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="3"
                      class="py-0"
                    >
                      # {{ i + 1 }}
                    </v-col>
                    <v-col
                      cols="9"
                      class="py-0"
                    >
                      <v-fade-transition leave-absolute>
                        <v-row
                          v-if="!open"
                          no-gutters
                          style="width: 100%"
                        >
                          <v-col
                            cols="6"
                            class="py-0"
                          >
                            {{ pagamento.forma_pagamento && pagamento.forma_pagamento.nome }}
                          </v-col>
                          <v-col
                            cols="6"
                            class="py-0 font-weight-medium text-right"
                          >
                            {{ pagamento.valor | formatCurrency }}
                          </v-col>
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form
                    :ref="`form-${i}`"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <MoneyInput
                          v-model="pagamento.valor"
                          label="Valor *"
                          prefix="R$"
                          persistent-hint
                          :rules="getRules(i)"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-select
                          v-model="pagamento.forma_pagamento"
                          :items="paymentMethods"
                          label="Forma de Pagamento *"
                          item-value="id_forma_pagamento"
                          item-text="nome"
                          persistent-hint
                          return-object
                          :rules="[v => !!v || 'Campo obrigatório!']"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <v-select
                          v-model="pagamento.caixa_banco"
                          :items="moneyCarriers"
                          label="Caixa/Banco *"
                          item-value="id_caixa_banco"
                          item-text="nome"
                          persistent-hint
                          return-object
                          :rules="[v => !!v || 'Campo obrigatório!']"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <date-picker
                          v-model="pagamento.data_lancamento"
                          label="Data de emissão *"
                          prepend-inner-icon="event"
                          persistent-hint
                          readonly
                          :rules="[v => !!v || 'Campo obrigatório!']"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        class="py-0"
                      >
                        <date-picker
                          v-model="pagamento.data_credito"
                          label="Data de compensação *"
                          prepend-inner-icon="event"
                          persistent-hint
                          readonly
                          :disabled="pagamento.caixa_banco && pagamento.caixa_banco.tipo == 0"
                          :rules="[v => !!v || 'Campo obrigatório!']"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-text-field
                          v-model="pagamento.observacao"
                          label="Observação"
                          prepend-inner-icon="description"
                          persistent-hint
                        />
                      </v-col>
                      <v-col
                        v-if="form.pagamentos.length > 1"
                        cols="12"
                        class="py-0 d-flex"
                      >
                        <v-spacer />
                        <v-btn
                          color="red"
                          outlined
                          small
                          @click="removePayment(i)"
                        >
                          Remover
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card
              class="my-3"
              elevation="0"
            >
              <v-btn
                block
                outlined
                color="primary"
                @click="addPayment"
              >
                <v-icon>
                  add
                </v-icon>
                Forma de Pagamento
              </v-btn>
            </v-card>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="grey darken-1"
          outlined
          @click="show = false"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          :color="color"
          outlined
          @click="save"
        >
          {{ form.debito_credito == 'D' ? 'Pagar' : 'Receber' }}
        </v-btn>
      </v-card-actions>

      <v-overlay
        :value="loading || saving"
        absolute
      >
        <v-card-text>
          {{ loading ? 'Carregando...' : 'Salvando...' }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.discharge-dialog {
  .discharge-content {
    max-height: calc(100vh - 350px);
    overflow: auto;
    > div {
      margin: 1px;
    }
  }
}
</style>

<script>
import DatePicker from "@/Domains/Visits/Schedule/Components/DatePicker.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

import moment from "moment-timezone";
import _ from 'lodash';

export default {

  components: {
    DatePicker,
    MoneyInput,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value || 0),
  },

  props: {
    value: Boolean,
    installmentId: String,
    recurrenceId: String,
    recurrenceDueDate: String,
  },

  data() {
    return {
      loading: false,
      saving: false,

      panel: 0,

      paymentMethods: [],
      moneyCarriers: [],

      form: { pagamentos: [{}] },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          for (let i in this.form.pagamentos) {
            const formId = `form-${i}`;
            if (this.$refs[formId]) {
              this.$refs[formId][0].resetValidation();
            }
          }
          this.form = { pagamentos: [{}] };
        }
        this.$emit("input", value);
      },
    },
    totalDischarge() {
      return this.form.pagamentos.reduce((acc, cur) => acc + (parseFloat(cur.valor) || 0), 0)
    },
    color() {
      return this.form.debito_credito == 'D' ? 'red' : 'green';
    }
  },

  watch: {
    value(value) {
      if (value) {
        // O painel deve ser pré carregado para validar o formulário
        this.panel = 0;
        // E depois esconde o painel de pagamento
        setTimeout(() => { this.panel = null; }, 10);

        if (this.installmentId) {
          this.loadInstallment();
        }
        else if (this.recurrenceId) {
          this.loadRecurrence();
        }
      }
    }
  },

  created() {
    this.panel = 0;
    this.getPaymentMethods();
    this.getMoneyCarriers();
  },

  methods: {

    async loadInstallment() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(`/financeiro/detalheParcela`, { id_parcela: this.installmentId });

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        let forma_pagamento = this.paymentMethods[0];

        if (data.id_forma_pagamento) {
          forma_pagamento = this.paymentMethods.find(item => item.id_forma_pagamento === data.id_forma_pagamento);
        }

        this.form = {
          id_parcela: data.id_parcela,
          descricao: data.descricao,
          nome_pessoa: data.nome_pessoa,
          nome_produtor: data.nome_produtor,
          debito_credito: data.debito_credito,
          data_vencimento: data.data_vencimento,
          valor: parseFloat(data.valor) * (data.debito_credito == "D" ? -1 : 1),
          saldo: parseFloat(data.saldo) * (data.debito_credito == "D" && parseFloat(data.saldo) ? -1 : 1),
          pagamentos: [{
            valor: parseFloat(data.saldo) * (data.debito_credito == "D" ? -1 : 1),
            forma_pagamento,
            caixa_banco: this.moneyCarriers[0],
          }]
        }
        if (this.form.saldo == 0) {
          this.$emit('newPayment', []);
          this.show = false;
        }
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a parcela!", "Atenção");
        this.show = false;
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadRecurrence() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/financeiro/detalheRecorrencia`,
          { id_titulo_recorrencia: this.recurrenceId, data_vencimento: this.recurrenceDueDate }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.form = {
          id_parcela: data.id_parcela || null,
          id_titulo_recorrencia: data.id_titulo_recorrencia,
          descricao: data.descricao,
          nome_pessoa: data.nome_pessoa,
          nome_produtor: data.nome_produtor,
          debito_credito: data.debito_credito,
          data_vencimento: this.recurrenceDueDate || data.data_vencimento,
          valor: parseFloat(data.valor) * (data.debito_credito == "D" ? -1 : 1),
          saldo: parseFloat(data.saldo) * (data.debito_credito == "D" && parseFloat(data.saldo) ? -1 : 1),
          pagamentos: [{
            valor: parseFloat(data.saldo) * (data.debito_credito == "D" ? -1 : 1),
            forma_pagamento: this.paymentMethods[0],
            caixa_banco: this.moneyCarriers[0],
          }]
        }
        if (this.form.saldo == 0) {
          this.$emit('newPayment', []);
          this.show = false;
        }
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a parcela!", "Atenção");
        this.show = false;
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async getPaymentMethods() {
      try {
        let { data } = await this.$axios.get(`/formaPagamento/listaJson`);

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.paymentMethods = data.map(p => ({
          nome: p.nome,
          id_forma_pagamento: p.id_forma_pagamento
        }))
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as formas de pagamento!", "Atenção");
        console.warn(error);
      }
    },

    async getMoneyCarriers() {
      try {
        let { data } = await this.$axios.get(`/caixaBanco/listaJson`);

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.moneyCarriers = data.map(p => ({
          nome: p.nome,
          id_caixa_banco: p.id_caixa_banco,
          tipo: p.tipo
        }))
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as os portadores de pagamento!", "Atenção");
        console.warn(error);
      }
    },

    addPayment() {
      this.form.pagamentos.push({
        forma_pagamento: this.paymentMethods[0],
        caixa_banco: this.moneyCarriers[0],
      });
      this.panel = this.form.pagamentos.length - 1;
    },

    removePayment(idx) {
      this.form.pagamentos.splice(idx, 1);
    },

    async validateForm() {
      for (let i in this.form.pagamentos) {
        const formId = `form-${i}`;
        if (this.$refs[formId]) {
          if (!await this.$refs[formId][0].validate()) {
            this.panel = parseInt(i);
            return false;
          }
        }
      }

      return true;
    },

    async save() {
      // Verifica se todos os campos foram preenchidos corretamente
      if (!await this.validateForm()) {
        return;
      }
      this.saving = true;
      try {
        let payload = {
          id_parcela: this.form.id_parcela,
          id_titulo_recorrencia: this.form.id_titulo_recorrencia,
          data_vencimento: this.form.data_vencimento,
          pagamentos: this.form.pagamentos.map(pagamento => ({
            id_forma_pagamento: pagamento.forma_pagamento.id_forma_pagamento,
            id_caixa_banco: pagamento.caixa_banco.id_caixa_banco,
            observacao: pagamento.observacao || '',
            valor: parseFloat(pagamento.valor),
            data_lancamento: moment(pagamento.data_lancamento).format('YYYY-MM-DD'),
            debito_credito: moment(pagamento.debito_credito).format('YYYY-MM-DD'),
          }))
        };

        const { data } = await this.$axios.post(`/financeiro/quitarParcela`, payload);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$emit('newPayment', data.lancamentos);
        this.$snotify.success("Quitação efetuada!", "Sucesso");
        this.show = false;
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    /**
     * Retorna as regras de validações de cada parcela
     */
    getRules(idx) {
      // Saldo da parcela
      const amount = parseFloat(this.form.saldo);
      // Soma esse pagamento com os outros
      const currAmount = this.form.pagamentos.slice(0, idx + 1).reduce((acc, cur) => acc + (parseFloat(cur.valor) || 0), 0);
      // Valor do pagamento
      const paymentAmount = parseFloat(this.form.pagamentos[idx].valor);
      return [
        () => !!paymentAmount || 'Informe o valor',
        () => paymentAmount > 0 || 'O valor não pode ser negativo',
        () => currAmount <= amount || 'O valor deve ser menor ou igual ao valor do saldo',
      ]
    },
  }
}
</script>
