<template>
  <CardChart
    title="Média de Litros por Km"
    :color="color"
    :graph-color="graphColor"
    :data="graph"
    :height="height"
    :loading="loading"
    :smooth="false"
    show-labels
  />
</template>

<script>
import CardChart from "@/Domains/BI/Components/CardChart.vue";

import moment from 'moment';
import _ from 'lodash';

export default {

  components: {
    CardChart
  },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    graphColor: {
      type: String,
      default: 'rgba(38, 198, 218, 0.7)'
    },
    height: {
      type: String,
      default: '100px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      graph: {},
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/relatorios/mediaLitrosPorKm`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.graph = data.reduce((acc, cur) => {
          acc[moment(cur.data).format('DD/MM/YYYY')] = +parseFloat(cur.media_litros_km).toFixed(2);
          return acc;
        }, {});

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de litros por km!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
