<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col md="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title
            class="text-subtitle-2 pt-2 pb-2"
            style="background: rgba(0, 0, 0, 0.5);"
          >
            Silos

            <v-spacer />

            <v-btn
              v-if="hasCreateSiloAccess"
              icon
              dark
              @click="onSiloCreatingClick"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text
            class="pa-2 pt-3 pb-3"
            style="background: rgba(0, 0, 0, 0.3)"
          >
            <v-row class="text-body-2 ml-1 mr-1 silos-grid">
              <v-col
                v-for="silo in silos"
                :key="silo.label"
              >
                <silo-info
                  :label="silo.label"
                  :silo="silo"
                  @onSiloProductionTransferring="onSiloProductionTransferring"
                  @onSiloToSiloTransferring="onSiloToSiloTransferring"
                  @onSiloClearing="onSiloClearing"
                  @onUnitTransferring="onUnitTransferring"
                  @onSpotTransferring="onSpotTransferring"
                  @onSiloPeriodicAnalysis="onSiloPeriodicAnalysis"
                  @onSiloEditing="onSiloEditingClick"
                  @onSiloExcluding="onSiloExcludingClick"
                  @onSiloAnalysisStandard="onSiloAnalysisStandard"
                  @onSiloTraceability="onSiloTraceability"
                  @click="onShowSiloAverage"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      v-if="hasCreateSiloAccess"
      bottom
      class="mr-5"
      dark
      direction="top"
      fixed
      open-on-hover
      right
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          fab
          large
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        color="blue"
        dark
        fab
        @click="onUnitTransferring({})"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              multiple_stop
            </v-icon>
          </template>
          Transferência entre Unidades
        </v-tooltip>
      </v-btn>

      <v-btn
        color="purple"
        dark
        fab
        @click="onSpotTransferring({})"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              business
            </v-icon>
          </template>
          Spot
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <silo-form-dialog
      ref="formDialog"
      @onSave="onSave"
      @onExcluding="onExcluding"
    />

    <silo-analysis-standard-dialog
      ref="analysisStandardDialog"
      @onSaved="loadList"
    />

    <production-transfer-dialog
      ref="transferringDialog"
      @onSiloTransferred="onSiloTransferred"
    />

    <silo-average-info-dialog
      ref="siloAverageInfoDialog"
      @onSiloClearing="onSiloClearing"
      @onSiloProductionTransferring="onSiloProductionTransferring"
      @onSiloToSiloTransferring="onSiloToSiloTransferring"
      @onUnitTransferring="onUnitTransferring"
      @onSpotTransferring="onSpotTransferring"
      @onSiloPeriodicAnalysis="onSiloPeriodicAnalysis"
    />

    <itinerary-transfer-dialog
      v-model="unitTransferDialog.show"
      :silo="unitTransferDialog.silo"
      type="unidade"
      @onSiloTransferred="onSiloTransferred"
    />

    <itinerary-transfer-dialog
      v-model="spotTransferDialog.show"
      :silo="spotTransferDialog.silo"
      type="spot"
      @onSiloTransferred="onSiloTransferred"
    />

    <silo-to-silo-transfer-dialog
      ref="siloToSiloTransferDialog"
      @onSiloTransferred="onSiloTransferred"
    />

    <silo-periodic-analysis
      ref="siloPeriodicAnalysis"
    />

    <silo-traceability-dialog
      ref="siloTraceabilityDialog"
    />

    <questionnaire-form-dialog
      ref="questionnaireFormDialog"
      object="CIP_SILO"
    />

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.silos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

</style>

<script>
import SiloInfo from "@/Domains/Platform/Silos/Components/SiloInfo.vue";
import SiloFormDialog from "@/Domains/Platform/Silos/Components/SiloFormDialog.vue";
import SiloAnalysisStandardDialog from "@/Domains/Platform/Silos/Components/SiloAnalysisStandardDialog.vue";
import ProductionTransferDialog from "@/Domains/Platform/Silos/Components/ProductionTransferDialog.vue";
import SiloAverageInfoDialog from "@/Domains/Platform/Silos/Components/SiloAverageInfoDialog.vue";
import SiloToSiloTransferDialog from "@/Domains/Platform/Silos/Components/SiloToSiloTransferDialog.vue";
import ItineraryTransferDialog from "@/Domains/Platform/Silos/Components/ItineraryTransferDialog.vue";
import SiloPeriodicAnalysis from "@/Domains/Platform/PeriodicSiloAnalysis/Components/SiloPeriodicAnalysis.vue";
import SiloTraceabilityDialog from "@/Domains/Platform/Silos/Components/SiloTraceabilityDialog.vue";
import QuestionnaireFormDialog from '@/Domains/Questionnaires/Components/QuestionnaireFormDialog.vue';

export default {

  components: {
    SiloPeriodicAnalysis,
    SiloToSiloTransferDialog,
    SiloAverageInfoDialog,
    ProductionTransferDialog,
    SiloInfo,
    SiloFormDialog,
    SiloAnalysisStandardDialog,
    SiloTraceabilityDialog,
    ItineraryTransferDialog,
    QuestionnaireFormDialog,
  },

  data() {
    return {
      // Loader
      loading: false,

      // Array com os dados de todos os silos
      silos: [],

      unitTransferDialog: {
        show: false,
        silo: {}
      },

      spotTransferDialog: {
        show: false,
        silo: {}
      }
    };
  },

  computed: {
    cipOverbalanceLimit() {
      const settings = this.$store.state.settings.plataforma || {};
      return parseInt(settings.volume_maximo_cipagem) || 0;
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasCreateSiloAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-insert-edit' && o.tipo === 'COMPONENTE');
    },

    hasCipOverbalanceAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "silo-cip-overbalance" && o.tipo === 'COMPONENTE');
    },
  },

  async mounted() {
    await this.loadList();
    await this.loadMilkTime();
  },

  methods: {

    async loadList() {
      try {
        const { data } = await this.$axios.post(`/silo/principal`);

        if (!data.silo) {
          throw "PHP Error";
        }

        this.silos = data.silo.map(silo => {
          return {
            index: (silo.descricao || '').replace(/\D/g, ''),
            id: silo.id_silo,
            label: silo.descricao,
            vol: parseInt(silo.volume_atual),
            capacity: parseInt(silo.volume_total),
            lotNumber: silo.numero_lote,
            rawProduct: {
              id: silo.id_materia_prima,
              name: silo.nome_materia_prima,
            },
            analysisStandards: JSON.parse(silo.padroes_analise) || []
          }
        }).sort((a, b) => a.index - b.index);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      }
    },

    async loadMilkTime() {
      if (!this.$store.state.settings.gerais.validar_saldo_silo) {
        return;
      }

      try {
        const { data } =  await this.$axios.get(`/industry/silo/milktime`);

        this.silos = this.silos.map(silo => ({
          ...silo,
          milktime: data[silo.id]
        }));
      } catch (e) {
        console.log(e);
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os tempos do silo!",
          "Atenção"
        );
      }
    },

    async onShowSiloAverage(silo) {
      if (this.hasCreateSiloAccess) {
        await this.$refs.siloAverageInfoDialog.show(silo);
      }
    },

    onSiloCreatingClick() {
      const lastIndex = this.silos.length;

      return this.$refs.formDialog.showCreatingDialog(lastIndex);
    },

    onSiloEditingClick(silo) {
      return this.$refs.formDialog.showEditingDialog(silo);
    },

    onSiloExcludingClick(silo) {
      return this.$refs.formDialog.showConfirmationDialog(silo);
    },

    onSiloAnalysisStandard(silo) {
      return this.$refs.analysisStandardDialog.show(silo);
    },

    onSiloTraceability(silo) {
      return this.$refs.siloTraceabilityDialog.show(silo);
    },

    onSiloProductionTransferring(silo) {
      return this.$refs.transferringDialog.show({
        silo: {
          id: silo.id,
          description: silo.description,
        },
      });
    },

    onSiloToSiloTransferring(silo) {
      return this.$refs.siloToSiloTransferDialog.show({
        silo: {
          id: silo.id,
          description: silo.description,
        },
      });
    },

    onUnitTransferring(silo) {
      this.unitTransferDialog = {
        show: true,
        silo,
      }
    },

    onSpotTransferring(silo) {
      this.spotTransferDialog = {
        show: true,
        silo,
      }
    },

    onSiloPeriodicAnalysis(silo) {
      return this.$refs.siloPeriodicAnalysis.show({
        silo,
        rawMaterial: silo.rawProduct,
      });
    },

    async onSave(silo) {
      try {

        this.loading = true;

        const { data } = await this.$axios.post(
          `/silo/salva`,
          {
            silo: {
              id_silo: silo.id,
              descricao: silo.label,
              volume_total: silo.capacity,
              volume_atual: silo.vol,
              id_materia_prima: silo.rawProduct.id,
              nome_materia_prima: silo.rawProduct.name,
            },
          },
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw "PHP Error";
        }

        this.$refs.formDialog.closeDialogs();

        return this.loadList();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async onExcluding(silo) {
      try {

        this.loading = true;

        const { data } = await this.$axios.post(
          `/silo/excluir`,
          this.$qs.stringify({
            id_silo: silo.id,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw "PHP Error";
        }

        this.$refs.formDialog.closeDialogs();

        return this.loadList();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir o silo!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async onSiloTransferred() {
      return this.loadList();
    },

    async getNextBatchNumber(id) {
      const { data } = await this.$axios.get(`/industry/silo/${id}/next-batch`);

      return data.prox_lote;
    },

    async onSiloClearing(silo) {
      try {
        const { data: updatedSilo } = await this.$axios.get(`/silo/getSiloSaldo/${silo.id}`);
        const currentVolume = parseFloat(updatedSilo.volume_atual);

        if (currentVolume > this.cipOverbalanceLimit && !this.hasCipOverbalanceAccess) {
          this.$snotify.warning(`Sem permissão para CIP com volume acima do limite configurado.`);
          return;
        }

        const nextBatchNumber = await this.getNextBatchNumber(silo.id);
        if (
          !(await this.$root.$confirm(
            'Atenção',
            `Deseja realmente lançar um evento de limpeza para o <b>Silo ${silo.index}</b>?<br><br>
        <div class="v-alert v-sheet v-sheet--outlined theme--light elevation-0 v-alert--dense v-alert--outlined blue--text body-2">
          Próximo lote: <b>${nextBatchNumber}</b>
        </div>
        Esta operação não poderá ser revertida!<br><br>
        `, { color: "red" }
          ))
        ) {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.post(`/silo/lancaCIP`, { id_silo: silo.id });

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.openCipQuestionnaire(data.id_silo_movimento);

      } catch (e) {
        const message = e?.message || e;
        console.log(message);

        this.$snotify.error(
          message,
          "Atenção"
        );
      } finally {
        await this.loadList();
        this.loading = false;
      }
    },

    async openCipQuestionnaire(objectId) {
      try {
        const { data } = await this.$axios.get(`/questionnaires/forms`, { params: {
          objeto: 'CIP_SILO',
        } });

        const id = data?.[0]?.id_formulario;

        if (id) {
          this.$refs.questionnaireFormDialog.show({ id, objectId });
        }
      } catch (e) {
        console.log(e);
        this.$snotify.error('Oops, ocorreu um erro ao abrir o checklist de CIP!', 'Atenção');
      }
    },
  },
};
</script>
