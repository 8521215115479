<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Visitas de Qualidade
      <span class="body-1 font-weight-light">
        Meta / Mês
      </span>
    </v-card-title>

    <v-card-text class="white--text">
      <v-row no-gutters>
        <v-col md="2">
          Situação
        </v-col>
        <v-col md="4">
          % Min. Visitas / Mês
        </v-col>
        <v-col md="6">
          Questionários aplicáveis
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                color="rgba(255, 255, 255, 0.7)"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            Se informado será adicionado os tipos de visitas IN77 e IN76 na configuração do questionário
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row
        v-for="(goal, id) in goals"
        :key="id"
        no-gutters
      >
        <v-col
          cols="12"
          class="py-1"
        >
          <v-divider class="mx-1" />
        </v-col>
        <v-col
          md="2"
          class="py-1"
        >
          <span class="d-block body-1 mt-2">{{ goal.status }}</span>
        </v-col>
        <v-col
          md="4"
          class="py-1"
        >
          <v-text-field
            v-model="goal.visits"
            label="% Visitas"
            prepend-inner-icon="trending_up"
            type="number"
            max="100"
            outlined
            hide-details
            dense
            :rules="[v => !v || Number(v) <= 100 || 'Valor inválido!']"
            @keypress="disableDotAndComma"
          />
        </v-col>
        <v-col
          md="6"
          class="py-1"
        >
          <v-select
            v-model="goal.questionnaires"
            label="Questionários"
            :items="questionnaires"
            prepend-inner-icon="icon-cadastros"
            item-value="id"
            item-text="title"
            multiple
            outlined
            small-chips
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Object
  },

  data() {
    return {
      questionnaires: [],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  created() {
    this.loadQuestionnaires();
  },

  methods: {

    async loadQuestionnaires() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/formularioCustomizado/listaFormulario`);

        this.questionnaires = data
          .filter(item => !item.tipo.includes('VISITA_PADRAO'))
          .map(item => ({
            id: item.id,
            title: item.titulo,
            types: item.tipo,
          }));
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
