<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-col
          cols="4"
          class="pa-0"
        >
          <date-range-picker
            v-model="filters.date.input"
            class="pa-0"
            @change="onDateFilter"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="4"
          class="pa-0"
        >
          <v-text-field
            v-model="filters.search"
            prepend-inner-icon="search"
            label="Buscar"
            dark
            hide-details
            filled
            clearable
          />
        </v-col>
      </v-card-title>
      <data-table
        ref="report"
        :loading="loading"
        :headers="headers"
        :items="filteredItems"
        show-custom-group
        name="Justificativa de coleta"
        item-key="id"
        class="elevation-1"
        disable-filtering
        multi-sort
      >
        <template v-slot:[`item.action`]="{ item }">
          <template v-if="item.url_coleta && item.url_coleta.length > 0">
            <v-btn
              icon
              @click="openPhotos(item);"
            >
              <v-icon>photo</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <div>
              <v-chip
                class="ma-2"
                x-small
              >
                -
              </v-chip>
            </div>
          </template>
        </template>
        <template #[`item.edit`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                small
                @click="editJustification(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>

              <v-list-item
                small
                @click="deleteJustification(item.id_coleta)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>
    
    <v-dialog
      v-model="photoDialog.show"
      width="600"
      max-width="650"
    >
      <v-card>
        <v-card-title>Fotos</v-card-title>
        <v-card-text>
          <v-carousel>
            <v-carousel-item
              v-for="(foto, i) in fotos"
              :key="i"
            >
              <img
                :src="foto"
                width="100%"
              >
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="blue--text"
            text
            @click="photoDialog.show = false;"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="justificationDialog.show"
      width="600"
      height="250"
    >
      <v-card>
        <v-card-title>
          {{ justificationDialog.form.id_coleta ? 'Editar Justificativa de Coleta' : 'Cadastrar Justificativa de Coleta' }}
        </v-card-title>

        <v-divider />

        <v-card-text
          class="pt-5"
          style="max-height: calc(100vh - 250px);"
        >
          <v-tabs
            v-model="tab"
            fixed-tabs
          >
            <v-tab
              href="#justification-tab"
            >
              Justificativa de Coleta
            </v-tab>

            <v-tab
              href="#anexos-tab"
            >
              Anexos
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              value="justification-tab"
              justify-center
              class="pa-6"
            >
              <v-row>
                <v-col cols="2">
                  <masked-input
                    v-model="justificationDialog.form.data"
                    label="Data"
                    :mask="DateMask"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    :disabled="justificationDialog.form.id_coleta ? true : false"
                  />
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="produtor"
                    label="Produtor"
                    :items="produtores"
                    item-text="concat"
                    item-value="id"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    :disabled="justificationDialog.form.id_coleta ? true : false"
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="motorista"
                    label="Motorista"
                    :items="motoristas"
                    item-text="motorista"
                    item-value="id_motorista"
                    :loading="loadingMotorista"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    :disabled="justificationDialog.form.id_coleta ? true : false"
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="rotaItinerario"
                    label="Rota"
                    :items="rotaItinerarios"
                    item-text="description"
                    item-value="id_rota"
                    :loading="loadingRoutes"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    :disabled="justificationDialog.form.id_coleta ? true : false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="mt-6"
                >
                  <v-textarea
                    v-model="justificationDialog.form.observacao"
                    label="Observação"
                    height="5px"
                    class="mt-6"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item
              value="anexos-tab"
              justify-center
              class="pa-6"
            >
              <v-col
                cols="12"
              >
                <attachments
                  :attached.sync="attached"
                  show-upload
                  @attachment-selected="handleAttachmentSelected"
                />
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
          
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="close()"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click.stop="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <template>
        <v-btn
          fab
          dark
          color="green darken-1"
          @click="exportExcel()"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                backup_table
              </v-icon>
            </template>
            Download (Excel)
          </v-tooltip>
        </v-btn>
      </template>

      <template>
        <v-btn
          fab
          dark
          color="orange darken-1"
          @click="print"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                print
              </v-icon>
            </template>

            Imprimir
          </v-tooltip>
        </v-btn>
      </template>

      <template>
        <v-btn
          fab
          dark
          color="blue darken-1"
          @click="addJustification"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                add
              </v-icon>
            </template>

            Inserir Justificativa
          </v-tooltip>
        </v-btn>
      </template>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import qs from "qs";
import moment from "moment-timezone";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import Attachments from "@/Domains/Collects/Reports/CollectionImpediment/Components/Attachments.vue";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    DateRangePicker,
    Attachments,
    MaskedInput
  },

  data() {
    return {
      loading: false,
      filters: {
        date: {
          input: "today",
          range: [],
        },
        search: null,
      },
      justificativa: [],
      fotos: [],
      showPhoto: false,

      photoDialog: {
        show: false,
      },

      loadingRoutes: false,

      loadingMotorista: false,

      tab: null,

      justificationDialog: {
        show: false,
        form: {}
      },

      motoristas: [],

      motorista: [],

      produtores: [],

      produtor: [],

      rotaItinerarios: [],

      rotaItinerario: [],

      filterMotorista: null,

      attached: [],

      DateMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.justificativa;
      }
      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;
      return this.justificativa.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },
    headers() {
      let headers = [
        { text: 'Data', value: 'data', align: 'center'  },
        { text: 'Produtor', value: 'nome_produtor', align: 'start'  },
        { text: 'Rota', value: 'nome_rota', align: 'center' },
        { text: 'Motorista', value: 'motorista', align: 'start' },
        { text: 'Observação', value: 'observacao', align: 'start'  },
        { text: 'Fotos', value: 'action', align: 'center' },
        { text: '', value: 'edit', align: 'center' },
      ];
      return headers;
    },
  },

  mounted() {
    this.loadJustificativa();
    this.loadProducers();
    this.listaMotorista();
    this.listaRota();
  },

  methods: {
    onDateFilter(event) {
      this.filters.date.range = event
      this.loadJustificativa();
    },

    async loadJustificativa() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/justificativaColeta`,
          qs.stringify({
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.first(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) :  moment().format("YYYY-MM-DD"),
          })
        );

        this.justificativa = data.map((item) => {
          return {
            ...item,
            url_coleta: JSON.parse(item.url_coleta)
          }
        })
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Justificativa de coleta - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `Justificativa de coleta - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.print(null, title);
    },

    openPhotos(item) {
      this.fotos = item.url_coleta;
      this.photoDialog.show = true;
    },

    async loadProducers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/produtores/listaJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.produtores = data.map(person => {
          return {
            id: person.id_pessoa,
            codigo: person.codigo_laticinio,
            name: person.nome,
            concat: person.codigo_laticinio + ' - ' + person.nome
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async listaMotorista() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaMotorista`);

        if (_.isString(data)) {
          throw data;
        }

        this.motoristas = data.map(person => {
          return {
            id_motorista: person.id_pessoa,
            motorista: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
        this.loadingMotorista = false;
      }
    },

    async listaRota() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/listaRotaResumidaJson`,
          {
            tipo: this.type,
            verifica_acesso_usuario: this.filterByUserAccess,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.rotaItinerarios = data.map(route => {
          const types = !_.isEmpty(route.tipos) ? _.uniq(route.tipos.map(type => _.startCase(type.descricao))) : [];
          return {
            id_rota: route.id_rota,
            description: route.descricao,
            types,
          }
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    handleAttachmentSelected(url) {
      this.attached = url;
    },

    addJustification() {
      this.justificationDialog.show = true;
      this.justificationDialog.form = {};
      this.produtor = null;
      this.motorista = null;
      this.rotaItinerario = null;
      this.tab = null;

      this.clear();
    },

    editJustification(item) {
      this.justificationDialog.show = true;
      this.justificationDialog.form = { ...item };
      this.attached = this.justificationDialog.form.url_anexos;
      this.tab = "justification-tab";

      const produtor = this.produtores.find(x => x.id === item.id_produtor);
      if (produtor) {
        this.produtor = produtor.id;
      }

      const motorista = this.motoristas.find(x => x.id_motorista === item.id_motorista);
      if (motorista) {
        this.motorista = motorista.id_motorista;
      }

      const rota = this.rotaItinerarios.find(x => x.id_rota === item.id_rota);
      if (rota) {
        this.rotaItinerario = rota.id_rota;
      }
    },

    async deleteJustification(id) {
      try {
        if (!(await this.$root.$confirm("Atenção", "Deseja realmente Inativar/Excluir esta justificativa de coleta?", { color: "red" }))) {
          return;
        }
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.post(`/coleta/inativaJustificativa`, this.$qs.stringify({
          id_coleta: id,
        }));

        if (!data.codigo) {
          throw data.mensagem || data;
        }

      } catch (error) {
        console.log(error);
        this.$snotify.error(`Oops, ocorreu um erro ao excluir!\r\n${error}`, "Atenção");
      } finally {
        this.$root.$progressBar.hide();
        this.loadJustificativa();
      }
    },

    clear() {
      this.justificationDialog.form = {};
      this.produtor = null;
      this.motorista = null;
      this.rotaItinerario = null;
      this.attached = [];
    },

    close() {
      this.justificationDialog.show = false;
      this.tab = null;
      this.clear();
    },

    async save() {
      try {
        this.loading = true;

        const action = this.justificationDialog.form.id_coleta ? 'atualizaJustificativa' : 'insereJustificativa';

        const selectedProdutor     = this.produtores.find(produtor => produtor.id === this.produtor);
        const selectedMotorista    = this.motoristas.find(motorista => motorista.id_motorista === this.motorista);
        const selectedIdItinerario = this.rotaItinerarios.find(x => x.id_rota === this.rotaItinerario);

        var urls = [];
        if (Array.isArray(this.attached)) {
          urls = this.attached.map(function(anexo) {
            if (typeof anexo === 'string') {
              return anexo;
            } else if (anexo.url) {
              return anexo.url;
            } else {
              return null;
            }
          }).filter(function(url) {
            return url !== null;
          });
        }

        let payload = {
          id_coleta: this.justificationDialog.form.id_coleta ? this.justificationDialog.form.id_coleta : '',
          data: moment(this.justificationDialog.form.data, 'DD/MM/YYYY').format('YYYY-MM-DD hh:mm:ss'),
          codigo_produtor: action === 'insereJustificativa' ? selectedProdutor.codigo : '',
          id_produtor: action === 'insereJustificativa' ? selectedProdutor.id : '',
          nome_produtor: action === 'insereJustificativa' ? selectedProdutor.name : '',
          id_usuario_coleta: action === 'insereJustificativa' ? selectedMotorista.id_motorista : this.justificationDialog.form.id_motorista,
          nome_usuario_coleta: action === 'insereJustificativa' ? selectedMotorista.motorista : this.justificationDialog.form.motorista,
          id_rota: action === 'insereJustificativa' ? selectedIdItinerario.id_rota : this.justificationDialog.form.id_rota,
          nome_rota: action === 'insereJustificativa' ? selectedIdItinerario.description : this.justificationDialog.form.nome_rota,
          observacao: this.justificationDialog.form.observacao,
          url_anexos: urls,
        };

        const { data } = await this.$axios.post(
          `/coleta/${action}`,
          payload
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.justificationDialog.show = false;
        this.$snotify.success("Registro salvo com sucesso", "Sucesso");
        this.$emit("save");
        this.loadJustificativa();
        this.clear();
        this.close();
      } catch (error) {
        this.$snotify.error("Erro ao salvar", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    }
  },

}
</script>
