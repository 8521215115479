<template>
  <v-text-field
    ref="input"
    v-model="localValue"
    :label="label"
    :prepend-inner-icon="prependInnerIcon"
    :append-icon="appendIcon"
    :background-color="backgroundColor"
    :prefix="prefix"
    :suffix="suffix"
    :reverse="reverse"
    :disabled="disabled"
    :filled="filled"
    :dark="dark"
    :hide-details="hideDetails"
    :maxlength="maxLength"
    :placeholder="placeholder"
    :rules="rules"
    :error="error"
    :error-messages="errorMessages"
    :hint="hint"
    :prepend-icon="prependIcon"
    :persistent-hint="persistentHint"
    :dense="dense"
    :autofocus="autofocus"
    :append-outer-icon="appendOuterIcon"
    :tabindex="tabindex"
    :type="type"
    :outlined="outlined"
    @change="onChange"
    @keyup.enter="onKeyUpEnter"
    @click:append="onClickAppend"
  >
    <template
      v-for="(_, slotName) in $slots"
      v-slot:[slotName]
    >
      <slot :name="slotName" />
    </template>
  </v-text-field>
</template>

<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<script>
import IMask from "imask";

export default {

  props: {
    tabindex: {
      type: String,
    },
    label: {
      type: String,
    },
    prependIcon: {
      type: String,
    },
    prependInnerIcon: {
      type: String,
    },
    appendIcon: {
      type: String,
    },
    appendOuterIcon: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    value: {
      type: [ Number, String ],
    },
    rules: {
      type: Array,
    },
    error: {
      type: Boolean,
    },
    errorMessages: {
      type: String,
    },
    mask: {
      type: Object,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: " ",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Boolean,
    },
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
    reverse: {
      type: Boolean,
    },
    returnUnmasked: {
      type: Boolean,
    },
    maxLength: {
      type: [Number, String],
      default: 255,
    },
    disabled: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    outlined: Boolean,
  },

  data () {
    return {
    }
  },

  computed: {
    localValue: {
      get() {
        if (this.value === 0 && this.returnUnmasked && this.disabled) {
          return '0';
        }

        return IMask.pipe((this.value || '').toString(), this.mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
      },
      set(newValue) {
        let value = null;

        if (this.returnUnmasked) {
          value = IMask.pipe((newValue || ''), this.mask, IMask.PIPE_TYPE.MASKED, IMask.PIPE_TYPE.UNMASKED);
        } else {
          value = IMask.pipe((newValue || ''), this.mask, IMask.PIPE_TYPE.MASKED, IMask.PIPE_TYPE.MASKED);
        }

        if (this.currency) {
          return this.$emit('input', parseFloat(value) || 0);
        }

        return this.$emit('input', value);
      },
    }
  },

  methods: {
    onKeyUpEnter(event) {
      return this.$emit('onEnter', event);
    },

    onChange(event) {
      this.$emit('change', event);
      return this.$emit('onChange', event);
    },

    onClickAppend(event) {
      return this.$emit('click:append', event);
    },

    focus() {
      return this.$refs.input.focus();
    },

    setSelectionRange(start, end) {
      return this.$refs.input.setSelectionRange(start, end);
    }
  }
}
</script>
