<template>
  <v-dialog
    v-model="show"
    scrollable
    :max-width="1200"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Região/Localidade</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.description"
                  label="Descrição *"
                  required
                  autofocus
                  :rules="[v => (v && v.length >= 5) || 'A descrição deve conter no mínimo 5 caracteres']"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="form.type"
                  label="Tipo *"
                  :items="types"
                  required
                  autofocus
                  :rules="[v => !!v || 'Informe o tipo']"
                />
              </v-col>
              <v-col
                md="6"
                class="pb-1"
              >
                <states-select
                  v-model="form.state"
                  prepend-inner-icon="villa"
                  label="Estado"
                />
              </v-col>
              <v-col
                md="6"
                class="pb-1"
              >
                <cities-select
                  v-model="form.city"
                  :uf="form.state"
                  prepend-inner-icon="domain"
                  label="Cidade"
                />
              </v-col>
              <v-col
                md="12"
                class="pb-1"
              >
                <v-combobox
                  v-model="form.locality"
                  label="Localidades"
                  prepend-inner-icon="domain"
                  multiple
                  chips
                  deletable-chips
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StatesSelect from "@/Support/Components/StatesSelect.vue";
import CitiesSelect from "@/Support/Components/CitiesSelect.vue";

export default {
  components: {
    CitiesSelect,
    StatesSelect,
  },

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      types: [
        { value: 'CAPTACAO', text: 'Captação' },
        { value: 'COMERCIAL', text: 'Comercial' },
      ],

      dueTypes: [
        { value: 'MES', text: 'Mês' },
        { value: 'PERIODO', text: 'Período' },
      ],

      form: {
        type: 'CAPTACAO',
        description: null,
        state: null,
        city: null,
        locality: null,
      }
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          type: 'CAPTACAO',
          description: null,
          state: null,
          city: null,
          locality: null,
        };
        
        if (this.editId) {
          this.loadRegion();
        }
      }
    }
  },

  methods: {

    async loadRegion() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/registrations/region/${this.editId}`);

        this.form = {
          type: data.tipo,
          description: data.descricao,
          state: data.estado,
          city: data.cidade,
          locality: data.localidades,
        };
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita uma região no banco de dados
     */
    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_regiao: this.form.id,
          descricao: this.form.description,
          tipo: this.form.type,
          estado: this.form.state,
          cidade: this.form.city,
          localidades: this.form.locality,
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);
        this.$snotify.success("Região cadastrada com sucesso", "Sucesso");
        this.close();
      } catch (e) {
        this.$snotify.error("Erro ao cadastrar região", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/region`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/region/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    }
  }

}
</script>
