<template>
  <v-dialog
    v-model="dialog"
    max-width="350px"
    hide-overlay
  >
    <v-card
      light
      elevation="1"
    >
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          ref="form"
        >
          <v-col
            v-if="form.status.value === 'DESVINCULADO' && !historyOnly"
          >
            <span
              :class="`${outstandingBalanceText.color}--text`"
              v-html="outstandingBalanceText.summary"
            />
          </v-col>

          <v-col
            v-if="form.params.showStatusSelect"
          >
            <v-select
              v-model="form.status"
              label="Status"
              placeholder="Status"
              :items="filteredStatusList"
              prepend-icon="swap_vert"
              :rules="[ v => !!v.value || 'Campo obrigatório!' ]"
              :loading="loading"
              :disabled="isEntranceDateStatus"
              return-object
              dense
              @change="onStatusChange"
            />
          </v-col>

          <v-col
            v-if="!form.params.onlyReasonEdit"
          >
            <date-picker
              v-model="form.date"
              label="Data"
              placeholder="Data"
              prepend-icon="event"
              :min-date="form.params.minInputDateLimit"
              :max-date="form.params.maxInputDateLimit"
              :loading="loading"
              :rules="[ v => !!v || 'Campo obrigatório!' ]"
              no-title
              dense
            />
          </v-col>

          <v-col
            v-if="form.status.reasons && !isEntranceDateStatus"
          >
            <v-select
              v-model="form.reason"
              :items="form.status.reasons"
              label="Motivo"
              placeholder="Motivo"
              prepend-icon="list"
              :loading="loading"
              :rules="[ v => !!v.value || 'Campo obrigatório!' ]"
              return-object
              dense
              @change="onReasonChange"
            />
          </v-col>

          <v-col
            v-if="form.status.showOtherReasons || form.reason.showOtherReasons || form.params.showOtherReasons"
          >
            <v-text-field
              v-model="form.otherReasons"
              :label="form.params.showOtherReasons ? 'Motivo' : 'Outros Motivos'"
              :placeholder="form.params.showOtherReasons ? 'Motivo' : 'Outros Motivos'"
              prepend-icon="edit"
              :loading="loading"
              :rules="[ v => !!v || 'Campo obrigatório!' ]"
              dense
            />
          </v-col>

          <v-col
            v-if="form.reason.showDairyCompetitors"
          >
            <v-autocomplete
              v-model="form.competitor"
              :items="competitors"
              item-text="name"
              item-value="id"
              prepend-icon="supervised_user_circle"
              return-object
              label="Laticínio concorrente"
              placeholder="Laticínio concorrente"
              :loading="loading"
              clearable
              dense
            />
          </v-col>

          <v-col>
            <v-alert
              v-if="historyOnly"
              dense
              type="info"
              class="text-caption"
            >
              As alterações aqui realizadas refletem apenas no <b>histórico</b> do produtor, <b>não alteram seu status atual</b>.
            </v-alert>
          </v-col>
        </v-form>
      </v-card-text>

      <v-divider class="pa-0 ma-0" />

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="v-btt2 black--text pa-0"
          text
          color="blue-grey"
          @click.stop="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="v-btt2 black--text pa-0"
          text
          color="blue"
          @click.stop="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay v-model="loading">
      Salvando...
    </v-overlay>
  </v-dialog>
</template>

<script>
import moment from "moment";
import _ from "lodash";

import { ReasonsEnum } from "@/Domains/Producer/Management/Enums/ReasonsEnum.js";

import DatePicker from "@/Domains/Visits/Schedule/Components/DatePicker.vue";

const emptyForm = {
  id: null,
  date: new Date(),
  status: {
    text: '',
    value: '',
    reasons: [],
  },
  competitor: {
    id: '',
    name: '',
  },
  reason: {},
  otherReasons: '',
  params: {
    showStatusSelect: null,
    minInputDateLimit: null,
    maxInputDateLimit: null,
    onlyReasonEdit: null,
  },
};

export default {
  components: {
    DatePicker,
  },

  props: {
    historyOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,

      loading: false,

      title: '',

      form: { ...emptyForm },

      outstandingBalance: 0,

      statusList: [
        {
          text: 'Data de Cadastro',
          value: 'CADASTRO',
        },
        {
          text: 'Aprovado',
          value: 'APROVADO',
          reasons: [
            {
              text: ReasonsEnum['IN_76_77'],
              value: 'IN_76_77',
            },
            {
              text: ReasonsEnum['INICIO_ATIVIDADES'],
              value: 'INICIO_ATIVIDADES',
            },
            {
              text: ReasonsEnum['TROCA_CADASTRO'],
              value: 'TROCA_CADASTRO',
            },
            {
              text: ReasonsEnum['PROSPECCAO_CONCORRENTE'],
              value: 'PROSPECCAO_CONCORRENTE',
              showDairyCompetitors: true,
            },
            {
              text: ReasonsEnum['DIVISAO_LITRAGEM'],
              value: 'DIVISAO_LITRAGEM',
            },
            {
              text: ReasonsEnum['OUTROS'],
              value: 'OUTROS',
              showOtherReasons: true,
            },
          ],
        },
        {
          text: 'Suspenso',
          value: 'SUSPENSO',
          showOtherReasons: true,
        },
        {
          text: 'Desvinculado',
          value: 'DESVINCULADO',
          reasons: [
            {
              text: ReasonsEnum['PAROU_ATIVIDADE'],
              value: 'PAROU_ATIVIDADE',
            },
            {
              text: ReasonsEnum['TROCOU_EMPRESA'],
              value: 'TROCOU_EMPRESA',
              showDairyCompetitors: true,
            },
            {
              text: ReasonsEnum['CORTADO_FALTA_VOLUME'],
              value: 'CORTADO_FALTA_VOLUME',
            },
            {
              text: ReasonsEnum['TROCA_TITULATIDADE'],
              value: 'TROCA_TITULATIDADE',
            },
            {
              text: ReasonsEnum['FORA_DE_ROTA'],
              value: 'FORA_DE_ROTA',
            },
            {
              text: ReasonsEnum['OBITO'],
              value: 'OBITO',
            },
            {
              text: ReasonsEnum['QUALIDADE'],
              value: 'QUALIDADE',
            },
            {
              text: ReasonsEnum['OUTROS'],
              value: 'OUTROS',
              showOtherReasons: true,
            },
          ],
        },
      ],

      competitors: [],

      producer: {},
    }
  },

  computed: {
    isEntranceDateStatus() {
      return this.form.status.value === 'CADASTRO';
    },

    filteredStatusList() {
      if (this.isEntranceDateStatus) {
        return this.statusList;
      }

      return this.statusList.filter(({ value }) => value !== 'CADASTRO');
    },

    outstandingBalanceText() {
      if (this.outstandingBalance > 0) {
        return {
          summary: `Existe o valor de <b>${this.outstandingBalance}</b> em aberto para o produtor: <b>${this.producer.name}</b>`,
          color: 'red'
        }
      }

      return  {
        summary: `Não existe valor em aberto para o produtor: <b>${this.producer.name}</b>`,
        color: 'green',
      }
    },
  },

  async mounted() {
    await this.loadCompetitors();
  },

  methods: {
    async show({ title, producer, form }) {
      this.dialog = true;

      const status = this.statusList.find(status => status.value === form.status) || {};
      const reason = (status.reasons || []).find(reason => reason.value === form.reason) || {};
      const date = form.date ? moment(form.date).toDate() : new Date();

      if (!form.params.showStatusSelect && !status.value) {
        status.value = form.status;
      }

      this.title = title;
      this.producer = producer;

      this.form = {
        ...this.form,
        ...form,
        status,
        reason,
        date,
      };

      // Aguarda atualização do DOM
      await this.$nextTick();

      await this.checkOutstandingBalance();
      await this.onStatusChange();
    },

    close() {
      this.dialog = false;

      this.outstandingBalance = 0;
      this.form = { ...emptyForm }
    },

    async onStatusChange() {
      const status = this.statusList.find(status => status.value === this.form.status.value) || {};

      if (status.value === 'APROVADO' && !this.form.reason) {
        this.form.reason = status.reasons.find(reason => reason.value === 'IN_76_77');
      }

      if (status.value === 'SUSPENSO' && !this.form.otherReasons) {
        this.form.otherReasons = 'IN 76';
      }

      if (status.value === 'DESVINCULADO') {
        await this.checkOutstandingBalance();
      }
    },

    onReasonChange() {
      this.form = {
        ...this.form,
        otherReasons: '',
        competitor: {},
      };
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      if (this.isEntranceDateStatus) {
        return this.saveEnteredDate();
      }

      // Caso esteja desvinculando o produtor, solicitar confirmação
      if (this.form.status.value === 'DESVINCULADO' && !(this.historyOnly || this.form.params.onlyReasonEdit)) {
        const unlinkConfirmation = await this.$root.$confirm(
          'Atenção',
          `Esta ação irá desvincular o produtor <b>${this.producer.name}</b> .<br>`,
          { color: 'red', token: 'DESVINCULAR' }
        );

        if (!unlinkConfirmation) {
          return;
        }
      }

      await this.saveStatus() ;
    },

    async saveEnteredDate() {
      try {
        this.loading = true;

        await this.$axios.put(`/producer/${this.producer.id}`, {
          data_entrada_laticinio: this.form.date,
        });

        this.$snotify.success("Data de cadastro alterada com sucesso", "Sucesso");

        this.$emit('save');

        return this.close();

      } catch (error) {
        this.$snotify.error("Erro ao alterar status do produtor", "Atenção");

        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async saveStatus() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/atualizaStatusProdutor`,
          {
            id_vinculacao: this.form.id,
            id_produtor: this.producer.id,
            nome_produtor: this.producer.name,
            status: this.form.status.value,
            motivo: this.form.reason.value,
            outros_motivos: this.form.otherReasons,
            data_status: moment(this.form.date).format("YYYY-MM-DD"),
            id_laticinio_concorrente: this.form.competitor.id || '',
            laticinio_concorrente: this.form.competitor.name || '',
            altera_status_atual_produtor: this.form.params.onlyReasonEdit || !this.historyOnly,
          }
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Status do produtor alterado com sucesso", "Sucesso");

        this.$emit('save');

        return this.close();
      } catch (error) {
        this.$snotify.error("Erro ao alterar status do produtor", "Atenção");

        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Checa o saldo pendente do produtor no financeiro
     *
     * @returns {Promise<void>}
     */
    async checkOutstandingBalance() {
      try {
        // Verifica se existe o id do produtor nas propriedades do componente
        if (!this.producer.id) {
          return;
        }

        // Apenas consultar o saldo na operação de desvincular o produtor
        if (this.form.status.value !== 'DESVINCULADO') {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.get(
          `/financial/reports/producer-statement/balance`,
          {
            params: {
              month_reference: moment().startOf("month").format("YYYY-MM"),
              productor: this.producer.id,
            }
          }
        );

        if (_.isEmpty(data) || !_.isArray(data)) {
          return;
        }

        const response = _.first(data);

        this.outstandingBalance = parseFloat(_.get(response, 'saldo_final', 0));
      } catch (e) {

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async loadCompetitors() {
      try {
        const { data } = await this.$axios.get(`/laticinio/listaJson`);

        this.competitors = data.map(item => ({
          id: item.id_laticinio,
          name: item.nome,
        }));
      } catch (error) {
        console.warn(error);
      }
    },
  }
};
</script>
