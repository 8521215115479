<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="filter.project"
          :items="goalList"
          label="Vigência do Projeto"
          item-value="dateStart"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="loadRange"
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <month-range-picker
          v-model="filter.range"
          :ranges.sync="filter.ranges"
          :min="filter.min"
          :max="filter.max"
          dark
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="filter.id_project_group"
          :items="listGroup"
          :loading="loading"
          label="Grupo"
          item-value="id"
          item-text="description"
          dark
          filled
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.route"
          label="Rota"
          dark
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-autocomplete
          v-model="filter.producer"
          :items="listProducer"
          :loading="loading"
          clearable
          dark
          filled
          label="Produtor"
          prepend-inner-icon="person_outline"
          item-text="name"
          item-value="id_produtor"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="12"
        cols="12"
      >
        <v-flex>
          <evolution-graphic
            v-for="q in quality"
            :key="q.indicador"
            :goal="q"
            :filter="filter"
            type-project="PMLS"
          />
        </v-flex>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EvolutionGraphic from "@/Domains/Projects/PMLS/Components/EvolutionGraphic.vue";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    EvolutionGraphic,
    MonthRangePicker,
    RoutesAutocompleteFilter,
  },

  data() {
    return {
      loading: false,

      filter: {
        project: {
          dateStart: null,
          dateEnd: null,
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        route: '',

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        producer: [],
      },

      goalList: [],

      listGroup: [],

      quality:
        [
          { "indicador": "CPP" },
          { "indicador": "CCS" },
          { "indicador": "gordura" },
          { "indicador": "proteina" },
          { "indicador": "caseina" },
          { "indicador": "solidos_totais" },
          { "indicador": "esd" },
          { "indicador": "lactose" },
          { "indicador": "ureia" },
          { "indicador": "volume" },
        ],
      listProducer: [],
    };
  },
  async mounted() {
    await this.loadGoals();
    await this.loadProducer();
  },

  methods: {

    async loadRange() {
      let dateEnd = (moment() < moment(this.filter.project.dateEnd)) ? moment().format('YYYY-MM') : moment(this.filter.project.dateEnd).format('YYYY-MM');

      this.filter.max = dateEnd;
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), dateEnd];
    },

    async loadGoals() {
      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PMLS",
          }
        );

        this.goalList = data.map((x) => {
          return {
            dateStart: x.data,
            dateEnd: x.data_fim,
            text: "De " + moment(x.data).format('MM/YYYY') + " até " + moment(x.data_fim).format('MM/YYYY'),
            quality: x.qualidade,
            visit: x.visita,
            id: x.id_assistencia,
          }
        });

        if (!_.isEmpty(this.goalList)) {
          this.filter.project = this.goalList[0];
          await this.loadRange();
          await this.loadGrupos();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
    async loadGrupos() {
      try {
        if (_.isEmpty(this.filter.project.id )) {
          return false
        }
        this.$root.$progressBar.loading();
        this.loading = true;

        const { data } = await this.$axios.get('/projects/project-group', {
          params: {
            id_assistencia: this.filter.project.id,
            tipo: 'PMLS',
          }
        });

        this.listGroup = data.map(item => {
          return {
            id: item.id_projeto_grupo,
            description: item.descricao,
          };
        });

        this.listGroup.push({
          id: null,
          description: 'Todos',
        })

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
        this.loading = false;
      }
    },

    async loadProducer() {
      try {
        if (_.isEmpty(this.filter.project.id )) {
          return false
        }
        this.loading = true;
        const { data } = await this.$axios.post(
          `/produtores/listaProdutorPMLS`,
          this.$qs.stringify({
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM'),
            id_rota: this.filter.route ? this.filter.route.id : null,
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        this.listProducer = data.map((producer) => ({
          id_produtor: producer.id_produtor,
          name: producer.nome_produtor
        }));
        console.log(this.listProducer);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
