<template>
  <div>
    <v-row>
      <v-col
        v-for="g in goals"
        :key="g.indicator.value"
        md="12"
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title class="text-subtitle-2">
            {{ g.indicator.text }}
            <v-spacer />
            <v-btn
              icon
              color="white"
            >
              <v-icon
                @click="onGoalDelete(g.indicator)"
              >
                delete_outline
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="g.groupQuestions"
            item-key="index"
            :items-per-page="-1"
            hide-default-footer
            show-expand
            single-expand
            disable-sort
          >
            <template #[`item.pontos_conforme`]="{ item }">
              <v-text-field
                v-model.number="item.pontos_conforme"
                placeholder="0"
                type="number"
                dense
                outlined
                hide-details
                :rules="[v => !v || v >= 0 || 'obrigatório']"
                @change="rangesPointsGroup(item,'pontos_conforme')"
              />
            </template>
            <template #[`item.pontos_nao_conforme`]="{ item }">
              <v-text-field
                v-model.number="item.pontos_nao_conforme"
                placeholder="0"
                type="number"
                dense
                outlined
                hide-details
                :rules="[v => !v || v >= 0 || 'obrigatório']"
                @change="rangesPointsGroup(item,'pontos_nao_conforme')"
              />
            </template>
            <template #[`item.pontos_na`]="{ item }">
              <v-text-field
                v-model.number="item.pontos_na"
                placeholder="0"
                type="number"
                dense
                outlined
                hide-details
                :rules="[v => !v || v >= 0 || 'obrigatório']"
                @change="rangesPointsGroup(item,'pontos_na')"
              />
            </template>

            <template #[`body.append`]="{}">
              <tr>
                <td
                  align="left"
                  colspan="2"
                >
                  Totais
                </td>
                <td
                  align="left"
                >
                  {{ totalQuestion }}
                </td>
                <td
                  align="left"
                >
                  {{ totalPoints }}
                </td>
                <td
                  align="left"
                  colspan="3"
                >
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="onSaveQuestionnaire(g, g.indicator.value);"
                  >
                    <v-icon>
                      save
                    </v-icon>
                    Salvar Pontução
                  </v-btn>
                </td>
              </tr>
            </template>
            <template #expanded-item="{headers, item}">
              <td
                :colspan="headers.length"
                class="pa-5 text-center"
              >
                <v-data-table
                  :headers="headerQuestions"
                  :items="item.questions"
                  class="elevation-1"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template #[`item.pontos[0]`]="{ item }">
                    <v-text-field
                      v-model.number="item.pontos[0]"
                      placeholder="0"
                      type="number"
                      dense
                      outlined
                      hide-details
                      :rules="[v => !v || v >= 0 || 'obrigatório']"
                      @change="sumPointsGroup('pontos_conforme',item, g)"
                    />
                  </template>
                  <template #[`item.pontos[1]`]="{ item }">
                    <v-text-field
                      v-model.number="item.pontos[1]"
                      placeholder="0"
                      type="number"
                      dense
                      outlined
                      hide-details
                      :rules="[v => !v || v >= 0 || 'obrigatório']"
                      @change="sumPointsGroup('pontos_nao_conforme',item, g)"
                    />
                  </template>
                  <template #[`item.pontos[2]`]="{ item }">
                    <v-text-field
                      v-model.number="item.pontos[2]"
                      placeholder="0"
                      type="number"
                      dense
                      outlined
                      hide-details
                      :rules="[v => !v || v >= 0 || 'obrigatório']"
                      @change="sumPointsGroup('pontos_na',item, g)"
                    />
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card
          dark
          elevation="1"
          outlined
          color="transparent"
        >
          <v-card-title
            class="text-subtitle-2 pt-2 pb-2"
            style="background: rgba(0, 0, 0, 0.5);"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on }">
                <span
                  class="subtitle-2 font-weight-light text-truncate"
                  v-on="on"
                >
                  Visitas por Pontução
                  <v-icon v-on="on">
                    help_outline
                  </v-icon>
                </span>
              </template>
              Defina  as visitas de acordo com o somatório obtido no questionário.
            </v-tooltip>
          </v-card-title>
          <v-card-text
            class="pa-2 pt-3 pb-3"
            style="background: rgba(0, 0, 0, 0.3)"
          >
            <ranges-points
              :value="g.ranges"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>

import _ from "lodash";
import RangesPoints from "@/Domains/Projects/PQFL/BPA/Components/RangesPoints.vue";

export default {

  components: {
    RangesPoints,
  },

  props: {
    value: Array,
    typeRange: String,
  },

  data() {
    return {

      loading: false,

      headers: [
        { text: "Grupo", value: "grupos", width: 380  },
        { text: "Nº Questões do grupo", width: 100, value: "questoes_grupos" },
        { text: "Total de pontos grupo", width: 100, value: "pontos_grupo" },
        { text: "Total 1º opção: Conforme", value: "pontos_conforme" },
        { text: "Total 2º opção: Não conforme", value: "pontos_nao_conforme" },
        { text: "Total 3º opção: NA", value: "pontos_na" },
      ],
      headerQuestions: [
        { text: "Questão", value: "rotulo", width: 630  },
        { text: "1º opção: Conforme", value: "pontos[0]" },
        { text: "2º opção: Não conforme", value: "pontos[1]" },
        { text: "3º opção: NA", value: "pontos[2]" },
      ],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    totalQuestion() {
      let total = 0
      total =  _.head(this.goals).groupQuestions?.reduce((acc, cur) => acc + (parseFloat(cur.questoes_grupos) || 0), 0)
      return total;
    },
    totalPoints() {
      let total = 0
      total =  _.head(this.goals).groupQuestions?.reduce((acc, cur) => acc + (parseFloat(cur.pontos_grupo) || 0), 0)
      return total;
    },
  },

  methods: {

    onGoalDelete(indicator) {
      const idx = this.goals .findIndex(o => o.indicador === indicator);
      this.goals.splice(idx, 1);
    },

    async onSaveQuestionnaire(goal, id) {

      const questionnaire = goal.questionnaire;
      this.loading = true;

      _.forEach(goal.groupQuestions, (g) => {
        _.forEach(g.questions, (q) => {

          let questionFound = _.find(questionnaire.formulario, o => {
            return o.id === q.id;
          });

          let len = _.isObject(questionFound.valores) ? Object.keys(questionFound.valores).length : 0 ;
          _.set(questionFound, "pontos", q.pontos.slice(0, len)); //valida para gravar o campo pontos do mesmo tamanho do campo valores no FS
        });
      });

      delete questionnaire.id;
      delete questionnaire.system;
      delete questionnaire.id_laticinio;

      const { data } = await this.$axios.post(
        `/formularioCustomizado/updateFormularioCustomizado`,
        {
          questionario: questionnaire,
          id_formulario: id,
        }
      );

      if (!_.isObject(data)) {
        this.loading = false;
        throw `PHP error ${data}`;
      }

      if (_.get(data, "codigo") === 0) {
        this.loading = false;
        throw _.get(data, "mensagem");
      }

      this.$snotify.success("Questionário atualizado com Sucesso !", "Sucesso");
      this.loading = false;

    },

    rangesPointsGroup(item, opc) {
      if (opc == 'pontos_conforme') {
        let pointsRange = parseFloat(item.pontos_conforme) / parseFloat(item.questoes_grupos);
        item.questions = item.questions.map(q => {
          return {
            id: q.id,
            rotulo: q.rotulo,
            pontos: [pointsRange, q.pontos[1], q.pontos[2]],
            id_group: q.id_group,
          }
        });
      }
      if (opc == 'pontos_nao_conforme') {
        let pointsRange = parseFloat(item.pontos_nao_conforme) / parseFloat(item.questoes_grupos);
        item.questions = item.questions.map(q => {
          return {
            id: q.id,
            rotulo: q.rotulo,
            pontos: [q.pontos[0], pointsRange, q.pontos[2]],
            id_group: q.id_group,
          }
        });
      }
      if (opc == 'pontos_na') {
        let pointsRange = parseFloat(item.pontos_na) / parseFloat(item.questoes_grupos);
        item.questions = item.questions.map(q => {
          return {
            id: q.id,
            rotulo: q.rotulo,
            pontos: [q.pontos[0],  q.pontos[1], pointsRange],
            id_group: q.id_group,
          }
        });
      }

      let array = [item.pontos_conforme, item.pontos_nao_conforme, item.pontos_na];
      let maxPoint = _.max(array) || 0;
      item.pontos_grupo = parseFloat(maxPoint);
    },
    /* se alterar valores das questoes individualmente tem que recalcular o total do grupo*/
    sumPointsGroup(opc, item, goal) {

      goal.groupQuestions = goal.groupQuestions.map( g => {
        if (g.index === item.id_group) {
          if (opc === 'pontos_conforme') {
            g.pontos_conforme = 0;
            _.forEach(g.questions, (question) => {
              g.pontos_conforme +=  parseFloat(question.pontos[0] ? question.pontos[0] : 0);
            });
          }
          if (opc === 'pontos_nao_conforme') {
            g.pontos_nao_conforme = 0;
            _.forEach(g.questions, (question) => {
              g.pontos_nao_conforme +=  parseFloat(question.pontos[1] ? question.pontos[1] : 0);
            });
          }
          if (opc === 'pontos_na') {
            g.pontos_na = 0;
            _.forEach(g.questions, (question) => {
              g.pontos_na +=  parseFloat(question.pontos[2] ? question.pontos[2] : 0);
            });
          }
          //verifica qual a maior pontuacao para gravar no total de pontos do grupo
          let array = [g.pontos_conforme, g.pontos_nao_conforme, g.pontos_na];
          let maxPoint = _.max(array) || 0;
          g.pontos_grupo = parseFloat(maxPoint);
        }
        return {
          ...g
        }
      });
      this.$forceUpdate();
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
}
</script>
