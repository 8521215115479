<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="quality-cbt-average"
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          CPP Médias
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="3"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onDateFilter"
        />
      </v-col>
      <v-col
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="loadReport"
        />
      </v-col>
      <v-col
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="loadReport"
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-autocomplete
          v-model="filter.status"
          :items="[
            { text: 'Ativos', value: 'ATIVOS' },
            { text: 'Desvinculados', value: 'DESVINCULADOS' },
          ]"
          label="Exibir"
          placeholder=" "
          hide-details
          dark
          filled
          @change="loadReport"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="filter.search = ''"
    >
      <v-tab href="#minTwoConsecutiveMeans">
        Últimas duas médias geométricas consecutivas acima de 300
      </v-tab>

      <v-tab href="#oneMean">
        Última média geométrica acima de 300
      </v-tab>

      <v-tab href="#lastMean">
        Última média geométrica abaixo de 300
      </v-tab>

      <v-tab-item value="minTwoConsecutiveMeans">
        <v-card
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            {{ `Produtores: ${ minTwoConsecutiveMeans.length / 2}` }}
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-simple-table
            dense
            class="elevation-1 report-table"
            dark
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :width="header.width"
                    :class="{[`text-${header.align}`]: header.align}"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, row) in minTwoConsecutiveMeans"
                  :key="row"
                >
                  <td
                    v-for="(header, col) in headers"
                    :key="col"
                    :class="{[`text-${header.align}`]: header.align}"
                    v-bind="handleSpan(row, col)"
                  >
                    <template
                      v-if="lastThreeMonths.includes(header.value) && (row % 2 == 1)"
                    >
                      <span class="red--text font-weight-bold">
                        {{ item[header.value] }}
                      </span>
                    </template>
                    <template v-else>
                      {{ item[header.value] }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-tab-item>

      <v-tab-item value="oneMean">
        <v-card
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            {{ `Produtores: ${ oneMean.length / 2}` }}
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-simple-table
            dense
            class="elevation-1 report-table"
            dark
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :width="header.width"
                    :class="{[`text-${header.align}`]: header.align}"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, row) in oneMean"
                  :key="row"
                >
                  <td
                    v-for="(header, col) in headers"
                    :key="col"
                    :class="{[`text-${header.align}`]: header.align}"
                    v-bind="handleSpan(row, col)"
                  >
                    <template
                      v-if="lastThreeMonths.includes(header.value) && (row % 2 == 1)"
                    >
                      <span class="red--text font-weight-bold">
                        {{ item[header.value] }}
                      </span>
                    </template>
                    <template v-else>
                      {{ item[header.value] }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-tab-item>

      <v-tab-item value="lastMean">
        <v-card
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            {{ `Produtores: ${ lastMean.length / 2}` }}
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-simple-table
            dense
            class="elevation-1 report-table"
            dark
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :width="header.width"
                    :class="{[`text-${header.align}`]: header.align}"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, row) in lastMean"
                  :key="row"
                >
                  <td
                    v-for="(header, col) in headers"
                    :key="col"
                    :class="{[`text-${header.align}`]: header.align}"
                    v-bind="handleSpan(row, col)"
                  >
                    <template
                      v-if="lastThreeMonths.includes(header.value) && (row % 2 == 1)"
                    >
                      <span class="red--text font-weight-bold">
                        {{ item[header.value] }}
                      </span>
                    </template>
                    <template v-else>
                      {{ item[header.value] }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<style lang="scss" scoped>
.quality-cbt-average {

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

import moment from "moment-timezone";
import qs from "qs";
import XLSX from "xlsx-js-style";
import printJS from 'print-js';
import _ from "lodash";

moment.locale('pt');

export default {
  name: "quality-cbt-average",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  filters: {
    dateFormat: (value, format) => {
      return !value ? "-" : moment(value).format(format);
    }
  },

  data() {
    return {
      loading: false,

      tab: null,

      filter: {
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        search: '',
        status: 'ATIVOS',
      },

      curMonth: moment().format('YYYY-MM-DD'),
      months: [],
      items: [],
    };
  },

  computed: {
    headers() {
      const headers = [
        { text: 'Código', value: 'codigo_laticinio', align: 'start' },
        { text: 'Produtor', value: 'nome_produtor', align: 'start', width: 220 },
        { text: 'Rota Principal', value: 'rota_principal', align: 'start' },
        { text: '', value: 'tipo', align: 'start' },
      ];

      this.months.forEach(month => {
        headers.push({
          text: _.upperFirst(moment(month).format("MMM/YY")),
          value: month,
          align: 'center',
        });
      })

      headers.push({
        text: `CPP Limite`,
        value: 'cbt_limit',
        align: 'center',
      });

      return headers;
    },

    minTwoConsecutiveMeans() {
      return this.items.filter(item => item.cbt_media_quant >= 2 && item.nome_produtor.toLowerCase().includes(this.filter.search.toLowerCase()))
    },

    oneMean() {
      return this.items.filter(item => item.cbt_media_quant == 1 && item.nome_produtor.toLowerCase().includes(this.filter.search.toLowerCase()))
    },

    lastMean() {
      return this.items.filter(item => item.cbt_media_quant == 0 && item.nome_produtor.toLowerCase().includes(this.filter.search.toLowerCase()))
    },

    lastThreeMonths() {
      return [...this.months].slice(2, 5).map(month => month);
    },

  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter([month]) {
      this.filter.date.month = month;
      this.curMonth = moment(month).format('YYYY-MM-DD');
      this.months = this.getLastMonths(this.curMonth);
      this.loadReport();
    },

    async loadReport() {
      try {
        this.loading = true;

        const routes = this.filter.routes.map(({ id }) => ({ id_rota: id }))

        let { data: { medias } } = await this.$axios.post(
          `/relatorioIn/listaMediaCBT`,
          qs.stringify({
            id_pessoa: this.filter.technician.id,
            rotas: JSON.stringify(routes),
            data_inicio: moment(this.curMonth).startOf('month').format('DD/MM/YYYY'),
            status: this.filter.status,
          })
        );

        let items = [];
        medias.forEach(produtor => {
          const historico = JSON.parse(produtor.historico_medias_json);

          let mensal = { codigo_laticinio: produtor.codigo_laticinio, nome_produtor: produtor.nome, rota_principal: produtor.rota_principal, tipo: "Média Geométrica Mensal" };
          let trimestral = { codigo_laticinio: produtor.codigo_laticinio, nome_produtor: produtor.nome, rota_principal: produtor.rota_principal, tipo: "Média Geométrica Trimestral" };

          historico.forEach(item => {
            mensal[item.data] = parseInt(item.cbt_geometrica_mes) || 0;
            trimestral[item.data] = parseInt(item.cbt_geometrica) || 0;
          })

          mensal['cbt_limit'] = parseInt(produtor.cbt_limite_aritmetico) || 0;
          trimestral['cbt_limit'] = parseInt(produtor.cbt_limite) || 0;

          const ultima_cbt_geometrica = parseInt(produtor.ultima_cbt_geometrica) || 0;
          /**
            * Se a última média for menor que 300 então mostrar no último quadro
            */
          const cbt_media_quant = ultima_cbt_geometrica < 300 ? 0 : parseInt(produtor.cbt_geo_2_consecutivas_acima) || 0;

          mensal['cbt_media_quant'] = cbt_media_quant;
          trimestral['cbt_media_quant'] = cbt_media_quant;

          items.push(mensal, trimestral);
        });

        this.items = items;
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    /**
     * Retorna os últimos 5 meses a partir da data enviada
     */
    getLastMonths(month) {
      let currentMonth = moment(month);

      let monthArray = [currentMonth.startOf('month').format("YYYY-MM-DD")];
      for (let i = 1; i < 5; i++) {
        monthArray.push(currentMonth.subtract(1, "month").startOf('month').format("YYYY-MM-DD"));
      }

      return monthArray.reverse();
    },

    formatExportReport(data) {
      return data.map(item => {
        let report = {
          Código: item.codigo_laticinio,
          Produtor: item.nome_produtor,
          'Rota Principal': item.rota_principal,
          Tipo: item.tipo,
        };

        this.months.forEach(month => {
          const title = _.upperFirst(moment(month).format("MMM/YY"));
          report[title] = item[month] || '-';
        });

        report['CPP Limite'] = item.cbt_limit;

        return report;
      })
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.formatExportReport(this.minTwoConsecutiveMeans), { origin: 2 });

      const oneMeanStartRow = this.minTwoConsecutiveMeans.length + 4;
      XLSX.utils.sheet_add_json(data, this.formatExportReport(this.oneMean), { skipHeader: true, origin: oneMeanStartRow + 2 });

      const lastMeanStartRow = this.minTwoConsecutiveMeans.length + this.oneMean.length + 7;
      XLSX.utils.sheet_add_json(data, this.formatExportReport(this.lastMean), { skipHeader: true, origin: lastMeanStartRow + 2 });

      data["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
        { s: { r: oneMeanStartRow, c: 0 }, e: { r: oneMeanStartRow, c: 7 } },
        { s: { r: lastMeanStartRow, c: 0 }, e: { r: lastMeanStartRow, c: 7 } },
      ];
      data['A1'] = { v: 'Últimas duas médias geométricas consecutivas acima de 300' };
      data[`A${oneMeanStartRow + 1}`] = { v: 'Última média geométrica acima de 300' };
      data[`A${lastMeanStartRow + 1}`] = { v: 'Última média geométrica abaixo de 300' };

      data['!cols'] = [
        { wch: 8 },
        { wch: 30 },
        { wch: 30 },
        { wch: 25 },
      ];

      const workbook = XLSX.utils.book_new();

      const filename = "cpp_medias";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /**
     * Cria uma tabela em HTML a partir de um JSON
     */
    htmlTableFromJson(json, title = null) {

      const properties = Object.keys(json[0]);

      /**
       * Criar o cabeçalho
       */
      const header = properties
        .map(property => `<td><b>${ property }</b></td>`)
        .join('');

      /**
       * Cria as linhas
       */
      const lines = json.map(line => {
        return properties
          .map(property => `<td>${ line[property] }</td>`)
          .join('');
      });

      /**
       * Monta o cabeçalho e as linhas
       */
      const content = [header, ...lines]
        .map(property => `<tr>${ property }</tr>`)
        .join('');

      let html = '';

      if (title) {
        html += `
          <table class="table table-condensed">
            <tbody>
              <tr><td><center><h5>${ title }</h5></center></td></tr>
            </tbody>
          </table>`;
      }

      html += `
      <table class="table table-condensed">
        <tbody>
          ${ content }
        </tbody>
      </table>`;

      return html;
    },

    /**
     * Imprime o relatório com as três tabelas
     */
    print() {
      const minTwoConsecutiveMeans = this.formatExportReport(this.minTwoConsecutiveMeans);
      const oneMean = this.formatExportReport(this.oneMean);
      const lastMean = this.formatExportReport(this.lastMean);

      const minTwoConsecutiveMeansTable = this.htmlTableFromJson(minTwoConsecutiveMeans, 'Últimas duas médias geométricas consecutivas acima de 300');
      const oneMeanTable = this.htmlTableFromJson(oneMean, 'Última média geométrica acima de 300');
      const lastMeanTable = this.htmlTableFromJson(lastMean, 'Última média geométrica abaixo de 300');

      const html = `
      <div>
        ${ minTwoConsecutiveMeansTable }
        ${ oneMeanTable }
        ${ lastMeanTable }
      </div>`;

      return printJS({
        documentTitle: 'CPP Médias',
        printable: html,
        type: 'raw-html',
        css: ['https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css'],
        style: `
          table, tr, td { page-break-inside: auto; border: 1px solid lightgray !important; border-collapse: collapse; }
          td, th, tr { font-size: 7pt; color: black; }
        `
      });
    },

    handleSpan(row, col) {

      const cols = [0, 1, 2];

      if (cols.includes(col)) {
        if (row % 2 == 0) {
          return { rowspan: 2 };
        }
        else {
          return { style: 'display:none;' };
        }
      }

    },
  },
};
</script>
