<template>
  <div class="mx-6 px-16 pt-0">
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
      >
        <h2 class="menu-header white--text">
          Volume / Frota
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#carrier-volume-tab">
        Volume por Transportador
      </v-tab>
      <v-tab href="#plate-volume-tab">
        Volume por Placa
      </v-tab>

      <v-tab-item value="carrier-volume-tab">
        <carrier-volume-tab />
      </v-tab-item>
      <v-tab-item value="plate-volume-tab">
        <plate-volume-tab />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import CarrierVolumeTab from "@/Domains/Collects/Reports/FleetVolume/Views/CarrierVolumeTab.vue";
import PlateVolumeTab from "@/Domains/Collects/Reports/FleetVolume/Views/PlateVolumeTab.vue";

export default {
  components: {
    CarrierVolumeTab,
    PlateVolumeTab,
  },

  data() {
    return {
      tab: null,
    };
  },
}
</script>