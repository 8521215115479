<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Laboratórios
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filters.search"
            dark
            @click:row="edit"
          >
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template
                  #activator="{ on }"
                >
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template
        #activator="{ on }"
      >
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          v-on="on"
          @click="add()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Novo</span>
    </v-tooltip>

    <laboratory-dialog
      v-model="dialog.show"
      :edit-id="dialog.id"
      @save="load"
    />
  </v-container>
</template>

<script>
import LaboratoryDialog from '@/Domains/Registrations/Analysis/Laboratory/Components/LaboratoryDialog.vue'

export default {

  components: {
    LaboratoryDialog,
  },

  data() {
    return {
      filters: {
        search: '',
      },

      headers: [
        { text: 'Nome', value: 'name' },
        { text: '', value: 'action', width: 30, sortable: false }
      ],

      items: [],

      dialog: {
        show: false,
        id: null,
      },
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        this.items = [];

        const { data } = await this.$axios.get(`/registrations/laboratory`);

        this.items = data.map(item => ({
          id: item.id_laboratorio,
          name: item.nome,
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.dialog.id = null;
      this.dialog.show = true;
    },

    edit(item) {
      this.dialog.id = item.id;
      this.dialog.show = true;
    },

    async remove(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.delete(`/registrations/laboratory/${id}`);

        this.load();
        this.$snotify.success('Laboratório excluído com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
  },
};
</script>
