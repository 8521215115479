<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Ações Combinadas de BPA
    </v-card-title>
    
    <v-row class="justify-center">
      <v-col
        md="12"
        align="center"
      >
        <v-btn
          outlined
          rounded
          text
          @click="onCombinedInsert();"
        >
          <v-icon>
            add
          </v-icon>
          Combinada
        </v-btn>
      </v-col>
      <v-col
        v-for="g in goals.combined"
        :key="g.key"
        md="12"
      >
        <ranges-combined
          :value="g"
          :quality="quality"
          :nonconformity="nonconformity"
          @onRangeDelete="onCombinedDelete"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import RangesCombined from "@/Domains/Projects/Dairy/ProducerRating/Components/RangesCombined.vue";
import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {
    RangesCombined,
  },

  props: {
    value: {
      type: Object,
    },
    quality: Object,
    nonconformity: Object,
  },

  data() {
    return {
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

  },

  methods: {
    onCombinedInsert() {
      let ranges =  {
        id: uuidv4(),
        index: 1,
        visits: null,
        period: 'mensal',
        questionnaires: [],
        actions: '',
        rangesCombined: [],
        class: '',
      }
      this.goals.combined.push({ ranges })
    },

    onCombinedDelete(range) {
      this.goals.combined = _.remove(this.goals.combined, (data) => {
        return data.ranges.id !== range.ranges.id;
      });

    },

    removeItem(item) {
      const idx = this.goals.findIndex(o => o.indicator === item.indicator);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>