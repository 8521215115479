<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Coleta de Leite
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-container>
      <v-card
        class="v-tamanho"
        color="transparent"
        dark
      >
        <v-card-title class="py-1 d-block">
          <v-row>
            <v-col>
              <v-select
                v-model="filters.referenceDate"
                :items="['DESCARGA', 'COLETA']"
                label="Data de referência"
                prepend-inner-icon="local_shipping"
                dark
                filled
                hide-details
                style="min-width:200px"
                @change="onFilter"
              />
            </v-col>
            <v-col>
              <v-date-range-picker
                v-model="filters.dateRange"
                :ranges="dateRanges"
                dark
                style="min-width:200px"
                @change="onFilter"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
                style="min-width:200px"
              />
            </v-col>

            <filter-menu
              :options="filters.options"
              :customs="headers"
              :selected-options.sync="filters.selectedOptions"
              :selected-customs.sync="filters.selectedCustoms"
              @removed="onFilterRemoved"
            />
          </v-row>

          <filter-items
            :selected-options.sync="filters.selectedOptions"
            :selected-customs.sync="filters.selectedCustoms"
            @removed="onFilterRemoved"
          >
            <filter-item value="routes">
              <routes-autocomplete-filter
                v-model="filters.routes"
                label="Rota Principal"
                multiple
                dark
                :return-object="false"
                @change="onFilter"
              />
            </filter-item>
            <filter-item value="collectRoutes">
              <routes-autocomplete-filter
                v-model="filters.collectRoutes"
                label="Rota de Coleta"
                multiple
                dark
                :return-object="false"
                @change="onFilter"
              />
            </filter-item>
            <filter-item value="regions">
              <region-autocomplete-filter
                v-model="filters.regions"
                label="Região"
                multiple
                dark
                :return-object="false"
                @change="onFilter"
              />
            </filter-item>
            <filter-item value="driver">
              <person-autocomplete-filter
                v-model="filters.driver"
                label="Motorista"
                type="DRIVER"
                :return-object="false"
                @change="onFilter"
              />
            </filter-item>
            <filter-item value="vehicle">
              <vehicle-autocomplete-filter
                v-model="filters.vehicle"
                :return-object="false"
                @change="onFilter"
              />
            </filter-item>
            <filter-item value="producers">
              <person-autocomplete-filter
                v-model="filters.producers"
                label="Produtor"
                type="PRODUCER"
                multiple
                show-groupings
                class="flex-grow-1"
                @change="onFilter"
              />
            </filter-item>
            <filter-item value="logs">
              <v-select
                v-model="filters.logs"
                :items="['SIM', 'NÃO']"
                label="Coletas Editadas"
                prepend-inner-icon="info"
                dark
                filled
                hide-details
                @change="onFilter"
              />
            </filter-item>
          </filter-items>
        </v-card-title>

        <data-table
          ref="report"
          dark
          :items="filteredItems"
          :headers="headers"
          show-custom-group
          name="Coleta de Leite"
          item-key="id_coleta"
          :show-expand="showExpand"
          class="elevation-1"
        >
          <template #[`item.nome_produtor`]="{ item }">
            {{ item.nome_produtor }}
            <v-icon
              v-if="item.id_condominio || item.tipo_condominio"
              color="rgba(255, 255, 255, 0.7)"
            >
              groups
            </v-icon>
          </template>

          <template #[`item.distancia_km`]="{ value, item }">
            <v-chip
              v-if="value"
              class="mt-1"
              small
              color="blue"
              text-color="white"
              @click="openMaps(item)"
            >
              {{ value }}
            </v-chip>
            <v-chip
              v-else
              class="mt-1"
              small
              color="blue"
              text-color="white"
            >
              -
            </v-chip>
          </template>

          <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
            <v-btn
              v-if="item.condominio?.length > 1"
              icon
              link
              transition="fab-transition"
              @click="expand(!isExpanded)"
            >
              <v-icon>
                {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </v-icon>
            </v-btn>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0 text-center"
            >
              <v-data-table
                :headers="headersGrouping"
                :items="item.condominio"
                :items-per-page="-1"
                dense
                hide-default-footer
                class="elevation-1 ma-3"
              />
            </td>
          </template>

          <template #[`footer.prepend`]>
            <div class="text-caption text-left ml-4">
              Volume Total (L)
              <v-chip
                small
                class="mx-2 mb-0"
              >
                {{ formatNumber(volumeTotal) }}
              </v-chip>
            </div>
          </template>
          <template #[`item.fotos`]="{ value }">
            <v-btn
              v-if="value.length"
              icon
              @click="openGallery(value)"
            >
              <v-icon>image</v-icon>
            </v-btn>
          </template>
          <template #[`item.logs`]="{ item }">
            <v-btn
              v-if="item.logs.length > 0"
              icon
              @click.stop="showCollectLog(item)"
            >
              <v-icon>
                info
              </v-icon>
            </v-btn>
          </template>
        </data-table>
      </v-card>
    </v-container>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="photoDialog.show"
      height="600"
      max-width="650"
    >
      <v-carousel style="text-align: center;">
        <v-carousel-item
          v-for="(foto, i) in pictures"
          :key="i"
        >
          <img
            :src="foto"
            height="100%"
          >
        </v-carousel-item>
      </v-carousel>
    </v-dialog>

    <v-dialog
      v-model="MapDialog.show"
      width="1000"
      persistent
      @click:outside="close"
    >
      <v-card
        style="background: #f5f5f5"
      >
        <v-card-text class="pa-0">
          <v-row class="pa-0 ma-0">
            <v-col
              cols="12"
              class="pa-0 ma-0"
            >
              <v-card>
                <gmap-map
                  :center="mapCenterArray[0]"
                  :zoom="mapZoom"
                  style="width: 100%; height: 400px;"
                >
                  <gmap-marker
                    v-for="(point, index) in mapCenterArray"
                    :key="index"
                    :position="point"
                    :icon="markerIcons[index === 0 ? 'A' : 'B']"
                    @mouseover="openInfoWindow(index)"
                    @mouseout="closeInfoWindow(index)"
                  >
                    <gmap-info-window
                      :position="point"
                      :opened="markerStates[index === 0 ? 'A' : 'B']"
                    >
                      <div :style="{ backgroundColor: point.color, padding: '10px' }">
                        {{ point.title }}
                        <br>
                        Latitude: {{ point.lat }}
                        <br>
                        Longitude: {{ point.lng }}
                      </div>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <collect-log-dialog
      ref="collectLogDialog"
    />

    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import isArray from "lodash/fp/isArray";
import isEmpty from "lodash/fp/isEmpty";
import debounce from "lodash/debounce";
import moment from "moment-timezone";
import VehicleAutocompleteFilter from "@/Support/Components/Filters/VehicleAutocompleteFilter.vue";
import RegionAutocompleteFilter from "@/Support/Components/Filters/RegionAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import { gmapApi  } from "gmap-vue";
import IconProducer from "@/Assets/point-producer.png";
import IconColect from "@/Assets/point-colect.png";
import CollectLogDialog from "@/Domains/Itineraries/Components/CollectLogDialog.vue";
import FilterMenu from "@/Support/Components/FilterMenu/FilterMenu.vue";
import FilterItems from "@/Support/Components/FilterMenu/FilterItems.vue";
import FilterItem from "@/Support/Components/FilterMenu/FilterItem.vue";

export default {
  components: {
    VehicleAutocompleteFilter,
    RegionAutocompleteFilter,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
    VDateRangePicker,
    CollectLogDialog,
    FilterMenu,
    FilterItems,
    FilterItem,
  },

  data() {
    return {
      loading: false,

      dateRanges: {
        'Esta semana': [
          moment().subtract(6, "days").startOf("days").format("YYYY-MM-DD"),
          moment().subtract("days").endOf("days").format("YYYY-MM-DD")
        ],
        'Últimas 100 coletas': [null, null]
      },

      filters: {
        options: [
          { text: 'Rota Principal', value: 'routes', icon: 'icon-rota' },
          { text: 'Rota de Coleta', value: 'collectRoutes', icon: 'icon-rota' },
          { text: 'Região', value: 'regions', icon: 'map' },
          { text: 'Motorista', value: 'driver', icon: 'person' },
          { text: 'Veículo', value: 'vehicle', icon: 'local_shipping' },
          { text: 'Produtor', value: 'producers', icon: 'person' },
          { text: 'Coletas Editadas', value: 'logs', icon: 'info' },
        ],
        selectedOptions: [],
        selectedCustoms: [],
        search: '',
        regions: [],
        vehicle: null,
        routes: [],
        collectRoutes: [],
        driver: null,
        producers: [],
        referenceDate: 'DESCARGA',
        dateRange: [
          moment().subtract(6, "days").startOf("days").format("YYYY-MM-DD"),
          moment().subtract("days").endOf("days").format("YYYY-MM-DD")
        ],
        logs: null,
      },

      headers: [
        { text: 'Código', value: 'codigo_produtor', align: 'center' },
        { text: 'Produtor', value: 'nome_produtor', align: 'start'  },
        { text: 'Rota Principal', value: 'nome_rota_principal', align: 'center' },
        { text: 'Rota de Coleta', value: 'nome_rota_coleta', align: 'center' },
        { text: 'Data Coleta', value: 'data_coleta', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY'), filterable: false },
        { text: 'Efetivação', value: 'data_efetivacao', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm'), filterable: false },
        { text: 'Hora', value: 'hora_saida', align: 'center', filterable: false },
        { text: 'Região', value: 'regiao', align: 'center' },
        { text: 'Motorista', value: 'nome_usuario_coleta', align: 'center' },
        { text: 'Placa', value: 'placa', align: 'center' },
        { text: 'Temperatura', value: 'temperatura', align: 'center' },
        { text: 'Tanque 1', value: 'tanque1', align: 'center' },
        { text: 'Tanque 2', value: 'tanque2', align: 'center' },
        { text: 'Tanque 3', value: 'tanque3', align: 'center' },
        { text: 'Tanque 4', value: 'tanque4', align: 'center' },
        { text: 'Tanque 5', value: 'tanque5', align: 'center' },
        { text: 'Tanque 6', value: 'tanque6', align: 'center' },
        { text: 'Tanque 7', value: 'tanque7', align: 'center' },
        { text: 'Total (L)', value: 'quantidade_coleta', align: 'center' },
        { text: 'Km', value: 'distancia_km', align: 'center', filterable: false },
        { text: 'Número Amostra', value: 'numero_amostra', align: 'center' },
        { text: 'Alizarol', value: 'alizarol', align: 'center' },
        { text: 'Observações', value: 'observacao', align: 'start' },
        { text: "Latitude", value: "latitude", align: "center", width: 30, show: false, filterable: false },
        { text: "Longitude", value: "longitude", align: "center", width: 30, show: false, filterable: false },
        { text: 'Fotos', value: 'fotos', align: 'end', formatter: value =>  value.length > 0 ? 'Sim' : 'Não', filterable: false },
        { text: 'Logs', value: 'logs', width: 50, sortable: false, filterable: false },
      ],

      items: [],

      pictures: [],

      photoDialog: {
        show: false,
      },

      MapDialog: {
        show: false,
      },

      map: null,

      mapZoom: 10,
      mapCenterArray: [
        { lat: -25.4336971, lng: -49.2797516, title: "" },
        { lat: -25.4336971, lng: -49.2797516, title: "" },
      ],

      markerIcons: {
        A: IconProducer,
        B: IconColect,
      },

      markerStates: {
        A: false,
        B: false,
      },

      headersGrouping: [
        { text: 'Código', value: 'codigo_laticinio', align: 'center' },
        { text: 'Produtor', value: 'nome', align: 'start'  },
        { text: 'Volume (L)', value: 'volume', align: 'center' },
      ],

      showExpand: false,
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search && !this.filters.selectedCustoms.some(filter => filter.search)) {
        return this.items;
      }

      const search = this.filters.search.toUpperCase().trim()

      const filterableColumns = this.headers.filter(header => header.filterable !== false).map(header => header.value)

      const searchFilter = (item) => {
        const searchTerm = search.toUpperCase()
        return filterableColumns.some(column => item[column]?.toString()?.toUpperCase()?.includes(searchTerm))
      }

      const customs = this.filters.selectedCustoms
        .filter(filter => filter.search)
        .map(filter => ({ value: filter.value, search: filter.search.toUpperCase().trim() }))

      const customsFilter = (item) => customs.every(filter => item[filter.value]?.toString()?.toUpperCase()?.includes(filter.search))

      return this.items.filter(item => {
        const matchSearch = !search || searchFilter(item)
        const matchCustoms = customs.length === 0 || customsFilter(item);

        return matchSearch && matchCustoms
      });
    },

    volumeTotal() {
      return this.filteredItems.reduce((acc, item) => (acc + parseInt(item.quantidade_coleta)), 0);
    },

    google: gmapApi
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = this.$options._componentTag;
        this.$store.commit("setFilter", { [filterId]: value });
      }
    }
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.loadCollects();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = this.$options._componentTag;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = {
          ...this.filters,
          ...this.$store.state.settings.filters[filterId],
        };
      }
    },

    async loadCollects() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.post(
          `/coleta/listaColeta`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            id_rota_principal: this.filters.routes,
            id_rota_coleta: this.filters.collectRoutes,
            id_regiao: this.filters.regions,
            id_usuario_coleta: this.filters.driver,
            id_equipamento: this.filters.vehicle,
            produtores: this.filters.producers.flatMap(p => {
              const producers = [p.id];
              if (p.grouping) {
                producers.push(...p.grouping.map(p => p.id_pessoa))
              }
              return producers;
            }),
            filtro_data_referencia: this.filters.referenceDate,
            logs: this.filters.logs,
          }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.items = data.map((item) => {
          const fotos =  JSON.parse(item.url_coleta) || [];
          const resfriadores = JSON.parse(item.resfriadores) || [];
          const condominio = JSON.parse(item.condominio) || [];

          resfriadores.forEach(e => {
            if (e.url_regua) {
              fotos.push(e.url_regua);
            }
            if (e.url_temperatura) {
              fotos.push(e.url_temperatura);
            }
          });

          return {
            ...item,
            fotos: fotos,
            condominio,
            logs: (item.logs ? JSON.parse(item.logs) : []).map(log => ({
              type: log.tipo,
              previous: log.anterior,
              new: log.novo,
              userName: log.nome_pessoa,
              updatedAt: log.data_hora,
            })),
          }
        })

        this.showExpand = this.items.some(item => item.condominio?.length > 1);
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    openGallery(picture) {
      this.pictures = picture;
      this.photoDialog.show = true;
    },

    openMaps(item) {
      this.MapDialog.show = true;
      this.mapZoom = this.getZoom(item.distancia_km);
      this.mapCenterArray = [
        { lat: parseFloat(item.end_latitude), lng: parseFloat(item.end_longitude), title: "Produtor" },
        { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude), title: "Coleta" }
      ]
    },

    getZoom(distancia_km) {
      const zoomLevels = [
        { maxDistance: 3, zoom: 20 },
        { maxDistance: 4, zoom: 15 },
        { maxDistance: 9, zoom: 12 },
        { maxDistance: 20, zoom: 10 },
        { maxDistance: Infinity, zoom: 8 }
      ];

      const matchingLevel = zoomLevels.find(level => distancia_km < level.maxDistance);
      return matchingLevel.zoom;
    },

    close() {
      this.MapDialog.show = false;
    },

    openInfoWindow(index) {
      this.$set(this.markerStates, index === 0 ? 'A' : 'B', true);
    },

    closeInfoWindow(index) {
      this.$set(this.markerStates, index === 0 ? 'A' : 'B', false);
    },

    onFilterRemoved(value) {
      if (!isEmpty(this.filters[value])) {
        if (isArray(this.filters[value])) {
          this.filters[value] = []
        } else {
          this.filters[value] = null
        }
        this.onFilter()
      }
    },

    onFilter: debounce(function () {
      this.loadCollects();
    }, 1000),

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Coleta de Leite - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? '' : moment(value).format(format),

    showCollectLog(collect) {
      return this.$refs.collectLogDialog.show(collect);
    },
  },
}
</script>
