<template>
  <div>
    <v-autocomplete
      ref="autocomplete"
      v-model="input"
      :items="localities"
      :label="label"
      item-value="id"
      :loading="loading"
      item-text="name"
      :return-object="returnObject"
      v-bind="$attrs"
      :multiple="multiple"
      deletable-chips
      @input="onItemSelected($event)"
    >
      <template
        #prepend-item
      >
        <v-container
          v-if="isConfig"
          fill-height fluid 
          class="white"
        >
          <v-row justify="center">
            <v-col>
              <v-btn
                block
                outlined
                :disabled="loading || (!uf || !city)"
                @click="addNew()"
              >
                Cadastrar novo
                <v-icon>add</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="isConfig" class="mt-2" />
      </template>
      <template #item="{ item }">
        <v-list-item-content>
          <div class="d-flex align-center">
            <span v-text="item.name"></span>
            <v-spacer v-if="isConfig"/>
            <v-btn
              v-if="isConfig"
              icon
              small
              class="ml-2"
              :disabled="item.id=='TODAS'"
              @click.stop="addNew(item.id)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </template>
      <template v-if="chip" #selection="{ item }">
        <v-chip x-small>
        <span>{{ item.name }}</span>
        </v-chip>
      </template>
    </v-autocomplete>

    <v-dialog
      v-model="showNewDialog"
      width="290"
    >
      <v-container 
        fill-height fluid 
        class="white"
      >
        <v-row justify="center">
          <v-col>
            <v-text-field
                v-model="localityName"
                label="Localidade"
                append-outer-icon="send"
                autofocus
                @click:append-outer="saveNew"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array],
    },
    label: {
      type: String,
      default: 'Localidade'
    },
    multiple: Boolean,
    todas: Boolean,
    chip: Boolean,
    returnObject: Boolean,
    uf: String,
    city: String,
    isConfig: Boolean
  },

  data() {
    return {
      showNewDialog: false,
      input: [],
      localities: [],
      localityName: '',
      loading: false,
      id: null
    };
  },

  watch: {
    value(value) {
      this.input = value;
    },
    uf: {
      handler: function() {
        this.getLocalities();
      }
    },
    city: {
      handler: function() {
        this.getLocalities();
      }
    }
  },

  created() {
    this.getLocalities();
  },

  methods: {
    onItemSelected(value) {

      if(this.todas) {
        var item = this.input[this.input.length - 1];
        if(item == "TODAS") {
          this.input = ['TODAS'];

        } else {
          if(this.input[0] == "TODAS") {
            this.input.shift();
          }
        }
      }
      return this.$emit('input', this.input);
    },

    async getLocalities() {
      try {
        this.loading = true;

        if (!this.uf || !this.city) {
          return;
        }

        const { data } = await this.$axios.get(`/projects/localities`, { params: {
            estado: this.uf,
            cidade: this.city
        }});
        
        this.localities = data.map(o => ({
          id: o.id,
          name: o.nome
        }));

        if(this.todas) {
          const newLocality = {
            id: 'TODAS',
            name: 'Todas',
          };
          this.localities.unshift(newLocality);
        }
      } catch (err) {
        console.warn(err);
        this.close();
      } finally {
        this.loading = false;
      }
    },

    addNew(id) {
      this.id = id;
      this.$refs.autocomplete.blur();
      this.showNewDialog = true;
    },

    async saveNew() {
      try {
        this.$root.$progressBar.loading();

        const localityName = this.localityName.trim();
        if (localityName === '') {
          this.showNewDialog = false;
          return;
        } else if(this.localities.find(e => e.name.toLowerCase().trim() == localityName.toLowerCase())) {
          this.$snotify.error("Localidade já cadastrada!", "Atenção");
          this.showNewDialog = false;
          return;
        }

        const payload = {
          nome: localityName, 
          estado: this.uf, 
          cidade: this.city
        };

        const data = this.id ? await this.update(payload) : await this.store(payload);

        if (this.id) {
          const existingLocality = this.localities.find(item => item.id === data.id);
          if (existingLocality) {
            existingLocality.name = localityName;
          }
        } else {
          const newLocality = {
            id: data.id,
            name: localityName,
          };
          this.localities.push(newLocality);
        }
        
        if(!this.id) {
          if(this.multiple) {
            this.input.push(data.id);
          } else {
            this.input = data.id;
          }
        }
        this.onItemSelected(this.input);

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");
        console.warn(error);
      } finally {
        this.localityName = '';
        this.showNewDialog = false;
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/localities`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/localities/${this.id}`, payload);
      return data;
    },
  },
};
</script>
