<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-spacer />
        <v-col
          cols="12"
          md="4"
          class="pt-0"
        >
          <v-text-field
            v-model="filters.search"
            append-icon="search"
            label="Busca"
            single-line
            hide-details
            filled
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Grupos"
        :search="filters.search"
        class="elevation-1"
        @click:row="showDialog"
      >
        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasEditGrupoAccess"
                @click="showDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    edit
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasDeleteGrupoAccess"
                @click="excludeMilkCan(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    delete
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasCreateGrupoAccess"
        fab
        color="purple"
        @click="showDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Cadastrar Grupo
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <grupo-dialog
      v-model="edit"
      :edit-id.sync="editId"
      @save="loadGrupos"
    />

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import GrupoDialog from "@/Domains/Projects/PMLS/Components/ProducerGroupsDialog.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    GrupoDialog
  },

  mixins: [ReportMixin],

  data() {

    return {
      // Loaders
      loading: true,

      // Dados brutos do backend
      Grupos: [],

      // Filtros da tabela
      filters: {
        search: "",
      },

      edit: false,
      editId: null,

    };
  },

  computed: {
    /**
     * Exibe as Grupos filtradas
     */
    filteredGrupos() {
      if (_.isEmpty(this.filters.search)) {
        return this.Grupos;
      }

      return this.Grupos.filter(Grupo => {
        return (Grupo.description || "").toUpperCase().includes((this.filters.search || "").toUpperCase());
      });
    },

    headers() {
      if (this.hasEditGrupoAccess || this.hasDeleteGrupoAccess) {
        return [
          { text: 'Descrição', value: 'description', align: 'start' },
          { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' }
        ];
      }
      return [
        { text: 'Descrição', value: 'description', align: 'start' },
      ]
    },

    //Definindo as Permissões de tela

    //Pega os dados de permissão do usúario.
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    //Se for admin ou ter o cargo Supervisor(Milkup) libera todos os acessos.
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    //Permissão para poder CRIAR uma Grupo.
    hasCreateGrupoAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "create-Grupo" && o.tipo === "COMPONENTE");
    },

    //Permissão para poder EDITAR uma Grupo.
    hasEditGrupoAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "edit-Grupo" && o.tipo === "COMPONENTE");
    },

    //Permissão para poder DELETAR uma Grupo.
    hasDeleteGrupoAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "delete-Grupo" && o.tipo === "COMPONENTE");
    },
  },

  async mounted() {
    await this.loadGrupos();
  },

  methods: {
    async loadGrupos() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get('/projects/project-group', {
          params: {
            tipo: 'PMLS',
          }
        });

        this.Grupos = data.map(item => {
          return {
            id: item.id_projeto_grupo,
            description: item.descricao,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Mostra o dialog para a criação/edição de uma nova Grupo
     */
    showDialog({ id }) {
      if (!this.hasEditGrupoAccess) {
        return;
      }

      this.edit = true;
      this.editId = id;
    },

    async excludeMilkCan(Grupo) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir ${Grupo.description} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.loading = true;

        await this.$axios.delete(`/projects/project-group/${Grupo.id}`);

        this.$snotify.success("Grupo excluída com sucesso", "Sucesso");
        return await this.loadGrupos();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Grupo", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    getReportJson() {
      return this.Grupos.map(Grupo => ({
        Descrição: Grupo.description,
      }));
    },

    exportExcel() {
      this.exportToFile({ report: this.getReportJson(), title: "Grupo" });
    },
  }
}
</script>
