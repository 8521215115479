<template>
  <v-col
    v-if="isSelected"
    class="d-flex align-center"
    :style="{ 'min-width': minWidth, 'max-width': maxWidth }"
  >
    <div class="flex-grow-1">
      <slot />
    </div>
    <v-btn
      v-if="false"
      icon
      class="close-btn"
      @click="onToggleFilter"
    >
      <v-icon>clear</v-icon>
    </v-btn>
  </v-col>
</template>

<script setup>
import { inject, computed } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  value: String,
  minWidth: {
    type: String,
    default: '250px',
  },
  maxWidth: {
    type: String,
    default: '300px',
  },
})

// Injeta as variáveis do componente pai
const selectedOptions = inject('selectedOptions')
const toggleFilter = inject('toggleFilter')

const isSelected = computed(() => selectedOptions.value.includes(props.value))

const onToggleFilter = () => toggleFilter(props.value)
</script>
