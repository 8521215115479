<template>
  <v-dialog
    v-model="isVisible"
    :max-width="600"
    persistent
  >
    <v-card>
      <v-card-title>
        Parâmetros de Configuração Medidor de Vazão (Integração)

        <v-spacer />

        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.descricao"
              label="Descrição *"
              :rules="[v => !!v || 'Campo obrigatório!']"
              prepend-inner-icon="subtitles"
              filled
              hide-details
            />
          </v-col>

          <v-col cols="6">
            <person-autocomplete-filter
              v-model="form.laticinio"
              label="Unidade"
              type="DAIRY"
              prepend-inner-icon="factory"
              filled
              hide-details
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.identificador_integracao"
              label="Identificador Integração *"
              :rules="[v => !!v || 'Campo obrigatório!']"
              prepend-inner-icon="qr_code"
              filled
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue'

const emptyForm = {
  id: null,
  descricao: null,
  laticinio: {},
  identificador_integracao: null,
}

export default {
  components: {
    PersonAutocompleteFilter,
  },

  data() {
    return {
      isVisible: false,

      form: {
        ...emptyForm,
      }
    }
  },

  methods: {
    show(item) {
      this.isVisible = true;

      this.form = {
        ...this.form,
        ...item,
        laticinio: {
          name: item.laticinio?.nome || null,
          id: item.laticinio?.id || null,
        }
      };
    },

    close() {
      this.isVisible = false;

      this.form = emptyForm;
    },

    async save() {
      this.$emit('save', {
        ...this.form,
        laticinio: {
          id: this.form.laticinio?.id || null,
          nome: this.form.laticinio?.name || null,
        },
      });

      return this.close();
    }
  }
}
</script>
