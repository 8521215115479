<template>
  <div>
    <v-card
      class="graph-card"
      color="transparent"
      dark
    >
      <v-card-title>
        {{ questionnaire.questionnaire.text }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            md="4"
          >
            Meta: {{ questionnaire.producer }} % dos Produtores {{ questionnaire.period }}
            <v-icon
              right
              :color="icone == 'verified' ? 'green darken-1': 'red darken-1'"
            >
              {{ icone }}
            </v-icon>
          </v-col>
          <v-col
            md="4"
          >
            Reaplicar: {{ questionnaire.producer_reapply }} % dos Produtores {{ questionnaire.period_reapply }}
            <v-icon
              right
              :color="icone_reapply == 'verified' ? 'green darken-1': 'red darken-1'"
            >
              {{ icone_reapply }}
            </v-icon>
          </v-col>
          <v-col
            md="4"
          >
            Produtores {{ status_produtor }} em  {{ today }} : <b> {{ totalProducer }} </b>
          </v-col>
        </v-row>
        <v-expansion-panels dark>
          <v-expansion-panel
            style="background: none"
          >
            <v-expansion-panel-header>Aplicado : {{ totalResponse }} produtores ({{ percentResponse }}%) {{ status_produtor }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="response"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template
                  #[`item.data_resposta`]="{ value }"
                >
                  {{ formatDate(value, "DD/MM/YYYY") }}
                </template>
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'LEAD' || value == 'NEGOCIACAO' || value == 'APROVACAO' || value == 'REPROVADO'"
                    color="blue"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    P
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            style="background: none"
          >
            <v-expansion-panel-header>Não Aplicado : {{ totalNotResponse }} produtores ({{ percentNotResponse }}%) </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="notResponse"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'LEAD' || value == 'NEGOCIACAO' || value == 'APROVACAO' || value == 'REPROVADO'"
                    color="blue"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    P
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            style="background: none"
          >
            <v-expansion-panel-header>Reaplicado : {{ totalReapply }} produtores ({{ percentReapply }}%) </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="reapply"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template
                  #[`item.data_resposta`]="{ value }"
                >
                  {{ formatDate(value, "DD/MM/YYYY") }}
                </template>
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'LEAD' || value == 'NEGOCIACAO' || value == 'APROVACAO' || value == 'REPROVADO'"
                    color="blue"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    P
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            style="background: none"
          >
            <v-expansion-panel-header>Vencido : {{ totalExpired }} produtores ({{ percentExpired }}%) </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headersExpired"
                :items="expired"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template
                  #[`item.data_resposta`]="{ value }"
                >
                  {{ formatDate(value, "DD/MM/YYYY") }}
                </template>
                <template
                  #[`item.data_vencimento`]="{ value }"
                >
                  {{ formatDate(value, "DD/MM/YYYY") }}
                </template>
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>

import _ from "lodash";
import moment from "moment-timezone";
import { round, percentageBy } from "@/Support/Resources/utils";

export default {

  components: {

  },

  props: {
    questionnaire: Object,
    filter: Object,
    project: String,
  },

  data() {
    return {

      loading: false,

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Nome', value: 'nome_produtor' },
        { text: 'Rota', value: 'rota_principal' },
        { text: 'Data resposta', value: 'data_resposta' },
        { text: 'Status', value: 'status' },
      ],
      headersExpired: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Nome', value: 'nome_produtor' },
        { text: 'Rota', value: 'rota_principal' },
        { text: 'Data resposta', value: 'data_resposta' },
        { text: 'Data vencimento', value: 'data_vencimento' },
        { text: 'Status', value: 'status' },
      ],

      response: [],
      notResponse: [],
      reapply: [],
      expired: [],

      today: moment().format("DD/MM/YYYY"),

      totalProducer: 0,

      percentResponse: 0,
      totalResponse: 0,

      percentNotResponse: 0,
      totalNotResponse: 0,

      percentReapply: 0,
      totalReapply: 0,

      percentExpired: 0,
      totalExpired: 0,

      icone: 'cancel',
      icone_reapply: 'cancel',

      status_produtor: 'Ativos / Suspensos'

    };
  },

  watch: {
    filter: {
      handler() {
        this.loadResponses();
      },
      deep: true
    }

  },
  mounted() {
    this.loadResponses();
  },

  methods: {

    async loadResponses() {

      this.status_produtor = this.filter.prospected ? 'Prospectados' : 'Ativos / Suspensos';
      let id = this.questionnaire.id_formulario;
      if (_.isEmpty(id)) {
        return false;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaVencimentosChecklist`,
          this.$qs.stringify({
            id_formulario: id,
            id_assistencia: this.filter.project.id,
            data_inicio_projeto: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim_projeto: moment(this.filter.project.dateEnd).endOf('month').format('YYYY-MM-DD'),
            periodo_reaplicar: this.questionnaire.period_reapply || '',
            periodo_projeto: this.questionnaire.period,
            projeto: this.project,
            prospectado: this.filter.prospected,
          })
        );

        this.response =  data.filter(item => !!item.data_resposta  )
          .map(item => ({
            ...item
          }));

        this.totalResponse = this.response.length;

        this.notResponse =  data.filter(item => _.isNull(item.data_resposta))
          .map(item => ({
            ...item
          }));
        this.totalNotResponse = this.notResponse.length;

        let active =  data.map(item => ({
          ...item
        }));

        this.totalProducer = active.length;

        this.percentResponse = round(percentageBy(this.totalProducer, this.totalResponse), 2);
        this.percentNotResponse = round(percentageBy(this.totalProducer, this.totalNotResponse), 2);

        if (parseFloat(this.questionnaire.producer) <= parseFloat(this.percentResponse )) {
          this.icone = 'verified';
        }

        //-------------reaplicados
        this.reapply =  data.filter(item => item.respostas.length > 1)
          .map(item => ({
            ...item
          }));

        this.totalReapply = this.reapply.length;
        this.percentReapply = round(percentageBy(this.totalProducer, this.totalReapply), 2);
        if (parseFloat(this.questionnaire.producer_reapply) <= parseFloat(this.percentReapply )) {
          this.icone_reapply = 'verified';
        }

        this.expired =  data.filter(item => item.vencido  && !!item.data_resposta )
          .map(item => ({
            ...item
          }));

        this.totalExpired = this.expired.length;
        this.percentExpired = round(percentageBy(this.totalProducer, this.totalExpired), 2);

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
}
</script>
