<template>
  <v-dialog
    v-model="show"
    width="30%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Edição/Cadastro de Grupo de Serviços</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.name"
                  label="Nome *"
                  :rules="isRequired"
                />
              </v-col>
            </v-row>
            <v-row>
                <v-col
                cols="4"
              >
                <v-switch
                  v-model="form.viewPqfl"
                  label="Módulo PQFL"
                  dense
                  hide-details
                  class="mt-0"
                  color="blue accent-3"
                />
              </v-col>
              <v-col
                cols="4"
              >
                <v-switch
                  v-model="form.viewPmls"
                  label="Módulo PMLS"
                  dense
                  hide-details
                  class="mt-0"
                  color="blue accent-3"
                />
              </v-col>
              <v-col
                cols="4"
              >
                <v-switch
                  v-model="form.viewCompany"
                  label="Módulo Empresa"
                  dense
                  hide-details
                  class="mt-0"
                  color="blue accent-3"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="form.allTechnicians"
                  label="Aplicar para todos os Técnicos"
                  dense
                  hide-details
                  class="mt-0"
                  color="blue accent-3"
                  @change="clearTechnicians"
                />
              </v-col>

              <v-col
                cols="12"
              >
                <person-autocomplete-filter
                  v-model="form.technicians"
                  type="TECHNICAL"
                  prepend-inner-icon="person"
                  hide-details
                  label="Técnico"
                  :filled=false
                  :disabled="form.allTechnicians == true"
                  multiple
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
  export default {

    components: {
      PersonAutocompleteFilter,
    },

    props: {
      value: Boolean,
      editId: String,
      type: String,
    },

    data() {
      return {
        form: {
          name: null,
          viewPqfl: 0,
          viewPmls: 0,
          viewCompany: 0,
          allTechnicians: false,
          technicians: [],
        },

        isRequired: [(v) => !!v || 'Este campo é obrigatório'],
      }
    },

    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
    },

    watch: {
      value(value) {
        if (value) {
          this.$refs.form && this.$refs.form.resetValidation();

          this.form = {
            name: null,
            viewPqfl: 0,
            viewPmls: 0,
            viewCompany: 0,
            allTechnicians: 0,
            technicians: null,
            type: null,
          };

          if (this.editId) {
            this.loadPan();
          }
        }
      }
    },

    methods: {

      async clearTechnicians() {
        if(this.form.allTechnicians) {
          this.form.technicians = [];
        }
      },

      async loadPan() {
        try {
          this.$root.$progressBar.loading();

          const { data } = await this.$axios.get(`/registrations/service-group/${this.editId}`);

          this.form = {
            name: data.nome,
            viewPqfl: data.exibe_pqfl,
            viewPmls: data.exibe_pmls,
            viewCompany: data.exibe_empresa,
            allTechnicians: data.aplica_todos_tecnicos,
            technicians: data.tecnicos,
            type: data.tipo,
          };
        }
        catch (err) {
          console.warn(err);
          this.close();
        }
        finally {
          this.$root.$progressBar.hide();
        }
      },

      /**
       * Cria ou edita uma causa no banco de dados
       */
      async save() {
        try {
          if (!await this.$refs.form.validate()) {
            return;
          }

          this.clearTechnicians();

          if (!this.form.allTechnicians && this.form.technicians.length === 0) {
            this.$snotify.error("Erro ao cadastrar serviço! Selecione um Técnico ou selecione a opeção para aplicar a todos.", "Atenção");
            return;
          }

          this.$root.$progressBar.saving();

          const payload = {
            id_grupo_servico: this.form.id,
            nome: this.form.name,
            exibe_pqfl: this.form.viewPqfl,
            exibe_pmls: this.form.viewPmls,
            exibe_empresa: this.form.viewCompany,
            aplica_todos_tecnicos: this.form.allTechnicians,
            tecnicos: JSON.stringify(this.form.technicians),
            tipo: this.type,
          };

          const response = this.editId ? await this.update(payload) : await this.store(payload);

          const editId = this.editId || response.id;

          this.$emit("update:editId", editId);
          this.$emit('save', editId);

          this.$snotify.success("Serviço cadastrado com sucesso", "Sucesso");

          this.close();

        } catch (e) {

          this.$snotify.error("Erro ao cadastrar serviço", "Atenção");

          console.warn(e);

        } finally {
          this.$root.$progressBar.hide();
        }
      },

      async store(payload) {
        const { data } = await this.$axios.post(`/registrations/service-group`, payload);
        return data;
      },

      async update(payload) {
        const { data } = await this.$axios.put(`/registrations/service-group/${this.editId}`, payload);
        return data;
      },

      close() {
        this.show = false;
      }
    }

  }
</script>
