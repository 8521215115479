<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.project"
          :items="projectList"
          label="Vigência do Projeto"
          item-value="dateStart"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="changeProject"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="filter.project.protocol"
          label="Protocolo do projeto"
          type="text"
          persistent-hint
          dark
          filled
          disabled
          hide-selected
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.type"
          dark
          filled
          return-object
          label="Situação"
          clearable
          :items="[
            {text:'Visitados',value:'visited'},
            {text:'A visitar',value:'notVisited'},
            {text:'Todos',value:'all'},
          ]"
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onFilterDate"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.curve"
          dark
          filled
          return-object
          label="Curva"
          clearable
          :items="curveItem"
          @change="loadTabs"
        />
      </v-col>
      <v-col
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="onLoad"
        />
      </v-col>

      <v-col
        md="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>
      <v-col
        v-if="view=='goal-pmls'"
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.id_project_group"
          :items="listGroup"
          :loading="loading"
          label="Grupo"
          item-value="id"
          item-text="description"
          dark
          filled
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-switch
          v-model="filter.volume"
          label="Exibir Volume Médio"
          dense
          dark
          hide-details
          class="mt-0"
          @change="loadTabs"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="onLoad"
    >
      <v-tab
        v-for="tabCurve in tabs"
        :key="tabCurve.id_meta_bpa"
      >
        {{ tabCurve.title }}
      </v-tab>

      <v-tab-item
        v-for="tabCurve in tabs"
        :key="tabCurve.id_meta_bpa"
        background-color="transparent"
        dark
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title
            v-if="tabCurve.tipo != 'sem_classificar'"
          >
            {{ `Produtores ${filter.type.text}: ${ tabCurve.items.length}` }}
            <v-spacer />
            {{ `${ tabCurve.tipo}` }} {{ `${ tabCurve.indicadores}` }}
            <v-spacer />
            {{ `Meta: ${ tabCurve.meta}` }}
          </v-card-title>
          <v-card-title
            v-else
          >
            {{ `Produtores ${filter.type.text}: ${ tabCurve.items.length}` }}
          </v-card-title>
          <v-card-subtitle
            v-for="q in tabCurve.questionarios"
            :key="q.id"
          >
            Aplicar: {{ q.title }}
          </v-card-subtitle>
          <v-card-subtitle
            v-if="volumeMedioTotal > 0"
          >
            volume médio: {{ volumeMedioTotal }}
          </v-card-subtitle>
          <v-data-table
            :headers="tabCurve.headers"
            :items="tabCurve.items"
            :search="filter.search"
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
          >
            <template
              #[`item.ultima_visita`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template
              #[`item.data_limite`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template
              #[`item.data_entrada_laticinio`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template #[`item.status`]="{item}">
              <v-chip
                v-if="item.status.description == 'DESVINCULADO'"
                color="red"
                small
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      left
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </template>
                  <span>
                    Saída em {{ formatDate(item.data_saida, 'DD/MM/YYYY') }}
                  </span>
                </v-tooltip>
              </v-chip>
              <v-chip
                v-else-if="item.status.description == 'SUSPENSO'"
                color="orange"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>remove_circle</v-icon>
                </v-avatar>
                S
              </v-chip>
              <v-chip
                v-else
                color="green"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>check_circle</v-icon>
                </v-avatar>
                A
              </v-chip>
            </template>
          </v-data-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="blue darken-1"
        @click="gerarClassificacao()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>
          Gerar Classificação
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay
      v-model="loading"
      relative
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

import moment from "moment-timezone";
import XLSX from "xlsx-js-style";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import RatingService from "@/Domains/Projects/PQFL/BPA/Services/RatingService.js";
import  _ from "lodash";

const ratingService = new RatingService();

export default {
  name: "producer-rating",

  components: {

    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    parseEmpty: (value) => !value ? '-' : value,
  },

  mixins: [ReportMixin],
  props: {
    view: String,

  },

  data() {
    return {
      loading: false,

      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
          id: '',
        },
        curve: {
          value: '',
          text: ''
        },
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],

        type: {
          text: 'a visitar',
          value: 'notVisited'
        },

        volume: false,

        id_project_group: null,

        search: null,
      },
      months: [],

      tab: 0,

      projectList: [],

      producers: [],

      tabs: null,

      curveItem: [],

      ranges: [],

      questionnaires: [],

      listGroup:[],

    };
  },

  computed: {
    volumeMedioTotal() {
      if (this.tabs[this.tab].items.length === 0) {
        return 0;
      }

      return parseInt(_.sumBy(this.tabs[this.tab].items, 'volume_media_diaria') / this.tabs[this.tab].items.length) || '';
    },
  },
  created() {
    this.loadQuestionnaires();
    this.loadProject();
    if (this.view === 'goal' || this.view === 'goal-pmls') {
      this.filter.type.value = 'all';
    }
  },

  methods: {
    async loadQuestionnaires() {

      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_BPA'],
            ativo: 1,
          }
        );

        this.questionnaires = data
          .map(item => {
            let text = item.titulo ? item.titulo : ``;
            text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
            text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;
            return {
              id: item.id,
              title: text,
              types: item.tipo,
            }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async changeProject() {
      await this.loadDateRange();
      this.loadRanges();
      this.tab = 0;
    },

    onFilterDate([month]) {
      this.filter.date.month = month;
      this.onLoad();
    },
    onLoad() {
      if (this.tabs) {
        if (this.tabs[this.tab].tipo === 'sem_classificar') {
          this.loadProducerNotRating();

        } else if (this.tabs[this.tab].id_meta_bpa) {
          if (_.isNull(this.filter.type)) {
            this.filter.type = {
              text: 'a visitar',
              value: 'notVisited'
            }
          }
          this.loadProducerRating();
        }
      }
    },

    async loadRanges() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/rangesBpa`,
          {
            id_assistencia: this.filter.project.id,
            aplicar_campo: this.view === 'qualidade_visita' ? true  : '',
          }
        );

        let curve = [...new Set(data.filter(o => o.visitas).flatMap(o => o.tipo))];
        this.curveItem = curve.map((c) => {
          return {
            text: ratingService.types[c],
            value: c,
          }
        });
        this.ranges = data;
        this.filter.curve = this.curveItem[0];
        this.loadTabs();

      } catch (error) {
        console.warn(error);
      }

    },

    async loadTabs() {
      this.tabs = [];
      if (!this.filter.curve) {
        return false;
      }
      try {
        //monta aba com as faixas da curva escolhida
        const data = this.ranges.filter(c => c.tipo === this.filter.curve.value);

        this.tabs = data.filter(c => !_.isNull(c.visitas)).map((c) => {
          let IndicadoresTitulo = '';
          let questionnairesFiltered = [];
          const h = [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            { text: 'Última visita', value: 'ultima_visita' },
            { text: 'visitar até', value: 'data_limite' },
          ];

          if (this.filter.volume) {
            h.push({ text: 'Volume Médio', value: 'volume_media_diaria' });
          }

          if (c) {
            if (c.indicador) { // curva simples (sem combinacao)
              let text = ` ( de  ${this.formatNumber(c.inicio_faixa)} até ${this.formatNumber(c.fim_faixa)} )`;
              if (_.isEmpty(c.fim_faixa) ||  c.fim_faixa === null) {
                text = ` ( acima de  ${this.formatNumber(c.inicio_faixa)})`;
              }
              h.push({ text: c.indicador, value: 'item.result0', align: 'center' });
              IndicadoresTitulo  = c.indicador + text;

            } else { //combinadas

              let metas_agrupadas = JSON.parse(c.metas_agrupadas || '') || [];
              let indicadores = [...new Set(metas_agrupadas.flatMap(o => o.indicator))];

              indicadores.forEach((i, k) => {
                h.push({ text: i.indicator, value: `item.result${k}`, align: 'center',  });
                h.push({ text: `Data ${i.indicator}`, value: `item.resultDate${k}`, align: 'center',  });
                IndicadoresTitulo  = IndicadoresTitulo + ` [${i.indicator} ${i.text}] `;
              });
            }
            let questionnairesApply = JSON.parse(c.questionarios_aplicar || '') || [];
            questionnairesFiltered = this.questionnaires.filter(function(item) {
              return questionnairesApply.indexOf(item.id) !== -1 ;
            });
          }
          if (!( h.find((head) => head.text === "CCS") ) ) {
            h.push({ text: 'CCS', value: 'ccs_geometrica' });
          }
          if (!( h.find((head) => head.text === "CPP") ) ) {
            h.push({ text: 'CPP', value: 'cpp_geometrica' });
          }

          h.push({ text: 'Status', value: 'status' });

          return {
            title: c.nome_faixa,
            id_meta_bpa: c.id_meta_bpa,
            periodo: c.periodo,
            visitasMeta: c.visitas,
            meta: c.visitas + ' visita(s) ' + c.periodo,
            tipo: ratingService.types[c.tipo],
            items: [],
            headers: h,
            indicadores: IndicadoresTitulo,
            questionarios: questionnairesFiltered,
          }
        });

        this.tabs.push({
          title: 'Não Classificados',
          id_meta_bpa: Math.random(),
          periodo: '',
          visitasMeta: '',
          meta: '',
          tipo: 'sem_classificar',
          items: [],
          headers: [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            { text: 'Data entrada', value: 'data_entrada_laticinio' },
            { text: 'CCS', value: 'ccs_geometrica' },
            { text: 'CPP', value: 'cpp_geometrica' },
            { text: 'Status', value: 'status' },
          ],
        })

      } catch (error) {
        console.warn(error);
      } finally {
        this.onLoad();
      }

    },

    async loadDateRange() {
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.max = moment(this.filter.project.dateEnd).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), moment().format('YYYY-MM')];
    },

    async loadProject() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: this.view === 'goal-pmls' ? "PMLS"  : "PQFL",
          }
        );

        this.projectList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            protocol: proj.protocolo,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          if (this.view == 'goal-pmls') { //carrega grupos so se for meta de PMLS
            await this.loadGrupos();

          }

          await this.loadDateRange();
          this.loadRanges();
          this.tab = 0;

        }

      } catch (error) {
        console.warn(error);
      }
    },

    async loadGrupos() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('/projects/project-group', {
          params: {
            id_assistencia: this.filter.project.id,
            tipo: this.view === 'goal-pmls' ? "PMLS"  : "PQFL",
          }
        });

        this.listGroup = data.map(item => {
          return {
            id: item.id_projeto_grupo,
            description: item.descricao,
          };
        });

        this.listGroup.push({
          id: null,
          description: 'Todos',
        })

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadProducerRating() {
      try {
        this.loading = true;
        let id_meta_bpa   = this.tabs[this.tab].id_meta_bpa;
        let periodo_meta  = this.tabs[this.tab].periodo;
        let visitas_meta  = this.tabs[this.tab].visitasMeta || 0;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/listaProdutoresClassificadosBPA`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            id_faixa: id_meta_bpa,
            periodo_meta: periodo_meta,
            data_inicio_projeto: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim_projeto: moment(this.filter.project.dateEnd).endOf('month').format('YYYY-MM-DD'),
            id_pessoa: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            mostra_volume: this.filter.volume,
            projeto: this.view === 'goal-pmls' ? "PMLS"  : "PQFL",
            id_projeto_grupo: this.filter.id_project_group ?? null,
            id_assistencia: this.filter.project.id,
          }
        );

        const faixa = this.ranges.filter(c => c.tipo === this.filter.curve.value);
        let tipo = '';
        let tipoAgrupadas = [];
        if (_.first(faixa).condicional) {
          tipo =  _.first(faixa).condicional ;

        } else if (_.first(faixa).tipo == 'INCONFORME-PONTOS') {
          tipo = 'pontos';

        } else if (_.first(faixa).tipo == 'COMBINADAS') { //se combinada entao busco nas metas os tipos das combinadas e coloco no array de tipos
          const metas_agrupadas = JSON.parse(_.first(faixa).metas_agrupadas || '') || [];
          const ids = [...new Set(metas_agrupadas.flatMap(o => o.indicator))];
          const agrupadas = this.ranges.filter(array => ids.some(filter => filter.id === array.id_meta_bpa));
          tipoAgrupadas = agrupadas.map(a => {
            if (a.condicional) {
              return  a.condicional;
            } else if (a.tipo == 'INCONFORME-PONTOS') {
              return 'pontos';
            } else {
              return '';
            }
          });
        }

        if (this.filter.type.value === 'notVisited' ) {

          this.producerNotVisited = data.filter(p => _.isEmpty(p.visitas_realizadas)  || p.visitas_realizadas.lenght < visitas_meta)
            .map(p => {
              let item = [];
              item.result0 = p.resultado ? parseInt(p.resultado) + ' ' + tipo : '';
              if (p.tipo_faixa == 'COMBINADAS') {
                p.classificacao_produtor_agrupador.map((a, k ) => {
                  const head = this.tabs[this.tab].headers.find((h) => h.text === a.indicador);
                  eval(`${head.value}= '${parseInt(a.resultado)} ${!_.isEmpty( tipoAgrupadas[k]) ? tipoAgrupadas[k] : ''}'`); //item.result0, item.result1 etc
                })
              }

              return {
                ...p,
                status: {
                  description: p.status ? p.status : 'APROVADO',
                  date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
                },
                item: item,
                cpp_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).cbt_geometrica_mes) : "-",
                ccs_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).ccs_geometrica_mes) : "-",
              }
            });

          this.tabs[this.tab].items = this.producerNotVisited;
        } else if (this.filter.type.value === 'visited' ) {
          this.producers = data.filter(item => !_.isEmpty(item.visitas_realizadas) )
            .map(p => {
              let item = [];
              item.result0 = p.resultado ? parseInt(p.resultado) + ' ' + tipo  : '';
              if (p.tipo_faixa == 'COMBINADAS') {
                p.classificacao_produtor_agrupador.map((a, k) => {
                  const head = this.tabs[this.tab].headers.find((h) => h.text === a.indicador);
                  eval(`${head.value}= '${parseInt(a.resultado)} '`);
                })
              }

              return {
                ...p,
                status: {
                  description: p.status ? p.status : 'APROVADO',
                  date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
                },
                item: item,
                cpp_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).cbt_geometrica_mes) : "-",
                ccs_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).ccs_geometrica_mes) : "-",
              }
            });
          this.tabs[this.tab].items = this.producers;

        } else if (this.filter.type.value === 'all' ) {

          this.producers = data.map(p => {
            let item = [];
            item.result0 = p.resultado ? parseInt(p.resultado) + ' ' + tipo  : '';
            if (p.tipo_faixa == 'COMBINADAS') {
              p.classificacao_produtor_agrupador.map((a, k) => {
                const head = this.tabs[this.tab].headers.find((h) => h.text === a.indicador);
                eval(`${head.value}= '${parseInt(a.resultado)}' `);

                const head2 = this.tabs[this.tab].headers.find((h) =>  h.text === `Data ${a.indicador}`);
                eval(`${head2.value}= '${moment(a.data_referencia).format("MM/YYYY")}'`);
              })
            }

            return {
              ...p,
              status: {
                description: p.status,
                date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
              },
              item: item,
              cpp_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).cbt_geometrica_mes) : "-",
              ccs_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).ccs_geometrica_mes) : "-",
            }
          });
          this.tabs[this.tab].items = this.producers;
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtores!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProducerNotRating() {

      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/listaProdutoresNaoClassificadosBPA`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            tipo_faixa: this.filter.curve.value,
            data_inicio_projeto: this.filter.project.dateStart,
            projeto: this.view === 'goal-pmls' ? "PMLS"  : "PQFL",
            id_assistencia: this.filter.project.id,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }
        this.tabs[this.tab].items = data.map(p => {
          return {
            id_produtor: p.id_produtor,
            nome_produtor: p.nome,
            codigo_laticinio: p.codigo_laticinio,
            data_entrada_laticinio: p.data_entrada_laticinio,
            rota_principal: p.rota_principal || 'sem rota definida',
            status: {
              description: p.status ? p.status : 'APROVADO',
              date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
            },
            cpp_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).cbt_geometrica_mes) : "-",
            ccs_geometrica: !_.isEmpty(p.medias) ? parseInt(_.first(p.medias).ccs_geometrica_mes) : "-",
          }
        });

      } catch (error) {
        console.log(error);
        this.loading = false;

        this.$snotify.error("Oops, ocorreu ao listar não classificados!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async gerarClassificacao() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/classificarProdutorBPA`,
          {
            mes_referencia: moment(this.filter.date.month).format('YYYY-MM'),
            id_assistencia: this.filter.project.id,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }
        if (_.get(data, 'codigo') !== 1) {
          this.$snotify.error("Não foi possivel classificar produtores. Dados insuficientes!", "Atenção");
        }
        await this.onLoad();

      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$snotify.error("Oops, ocorreu um erro gerar classificação!", "Atenção");
      }
    },

    getReportJson() {
      const items = this.tabs[this.tab].items;
      const header = this.tabs[this.tab].headers;

      return items.map(o => {
        o.status = o.status.description;
        let initial = {};
        return header.reduce((acc, header) => ({
          ...acc,
          [header.text]: 'formatter' in header ? header.formatter(_.get(o, header.value, '')) : _.get(o, header.value, ''),
        }), initial);
      });

    },

    print() {
      const title = this.tabs[this.tab].title;

      return this.printFromJson(this.getReportJson(), title, true);
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const title = this.tabs[this.tab].title;

      let data = XLSX.utils.json_to_sheet(this.getReportJson());
      const filename = `Visitas BPA`;

      const workbook = this.XLSX.utils.book_new();
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
    formatNumber: (value) => !value ? '-' : new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
