<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Lançamento de Estoque</span>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="4"
              class="mb-0"
            >
              <warehouse-select
                v-model="warehouseId"
                label="Depósito"
                outlined
                dense
                hide-details
                auto-select-default
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filters.search"
            item-key="id"
            hide-default-footer
            :items-per-page="-1"
          >
            <template #[`item.quantidade`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.quantidade_estoque`]="{ item }">
              <masked-text-field
                v-model="item.quantidade_estoque"
                label="Qtde. para estoque"
                :mask="masks.float"
                unmask
                outlined
                dense
                hide-details
                :rules="[rules.required]"
              />
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="save()"
        >
          Lançar estoque
        </v-btn>
      </v-card-actions>
    </v-card>

    <item-lot-dialog
      v-model="itemLotDialog.show"
      :type="itemLotDialog.type"
      :items="itemLotDialog.items"
      :warehouse-id="warehouseId"
      @save="onLotSaved"
    />
  </v-dialog>
</template>

<script>
import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import ItemLotDialog from '@/Domains/Registrations/Item/Components/ItemLotDialog.vue';

import _ from 'lodash'

export default {

  components: {
    WarehouseSelect,
    MaskedTextField,
    ItemLotDialog,
  },

  data() {
    return {
      dialog: false,

      filters: {
        search: ''
      },

      id: null,
      warehouseId: null,

      headers: [
        { text: 'Produto na nota', value: 'descricao', sortable: false },
        { text: 'Qtde.', value: 'quantidade', sortable: false },
        { text: 'Produto no sistema', value: 'descricao_sistema', sortable: false },
        { text: 'Qtde. para estoque', value: 'quantidade_estoque', width: 180, sortable: false },
      ],
      items: [],

      rules: {
        required: v => !!v || 'Campo obrigatório!',
      },

      itemLotDialog: {
        show: false,
        type: 'ENTRADA',
        items: [],
      },

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      }
    }
  },

  methods: {
    show(id) {
      this.reset();
      this.dialog = true;
      this.id = id;
      this.load();
    },

    reset() {
      this.items = [];
    },

    close() {
      this.$emit('close');
      this.dialog = false;
    },

    async load() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/fiscal/invoice/${this.id}/items`);

        this.items = data;

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save(showLotDialog = true) {
      if (!await this.$refs.form.validate()) {
        return;
      }

      const itemsWithLotControl = this.items.filter(item => item.controlar_lotes && (item.lotes || []).length === 0);

      if (showLotDialog && itemsWithLotControl.length > 0) {
        this.showLotDialog(itemsWithLotControl);
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.post(`/fiscal/invoice/${this.id}/stock-entry`, {
          warehouse_id: this.warehouseId,
          items: this.items
        });

        this.$snotify.success('Estoque lançado com sucesso', 'Sucesso');
        this.$emit('save');
        this.close();
      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showLotDialog(items) {
      this.itemLotDialog.show = true;
      this.itemLotDialog.type = 'ENTRADA';
      this.itemLotDialog.items = items.map(item => ({
        id_item: item.id,
        quantidade: item.quantidade_estoque,
      }));
    },

    onLotSaved(items) {
      for (let lot of items) {
        const idx = this.items.findIndex(item => item.id === lot.id_item);
        this.items[idx].lotes = lot.lotes;
      }
      this.save(false);
    },

    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),

  },

}
</script>
