<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Pagamento de frete
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
    </v-container>

    <v-row
      justify="end"
      class="row-filters"
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-date-range-picker
          v-model="filters.range"
          dark
          @change="onDateFilter"
        />
      </v-col>
    </v-row>

    <v-container>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent text-left"
        @change="onTabChange"
      >
        <v-tab
          href="#tab-totals-km"
        >
          Pagamento por Km
        </v-tab>

        <v-tab
          href="#tab-totals-volume"
        >
          Pagamento por Volume
        </v-tab>

        <v-tab
          href="#tab-details"
          :disabled="tab != 'tab-details'"
        >
          Detalhes de viagens
        </v-tab>

        <v-tab
          :href="`#${tab}`"
          @click.stop="openSettings"
        >
          <v-icon>settings</v-icon>
        </v-tab>

        <v-tab-item value="tab-totals-km">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span class="caption font-weight-light">
                    Valor Total
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3 cyan--text"
                  >R$ {{ totalAmountKm | formatCurrency }}</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span class="caption font-weight-light">
                    Distância Total
                    ({{ frete_coleta.tipo_km == "ODOMETRO" ? 'Odômetro' : 'Planejado' }})
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3 teal--text"
                  >{{ totalKm | formatNumber }} Km</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  offset-md="2"
                  md="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="freightsKm.search"
                    append-icon="search"
                    label="Busca"
                    single-line
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="freightsKm.headers"
              :search="freightsKm.search"
              :items="items"
              :loading="loading"
              dark
              @click:row="showDetails"
            >
              <template
                #[`header.valor_km`]="{ header }"
              >
                {{ `${header.text} (${(frete_coleta.tipo_km == "ODOMETRO" ? 'Odômetro' : 'Planejado')})` }}
              </template>
              <template #[`item.volume`]="{value}">
                <span>{{ value | formatNumber }} L</span>
              </template>
              <template #[`item.distancia`]="{value}">
                <span>{{ value | formatNumber }} Km</span>
              </template>
              <template #[`item.distancia_rota`]="{value}">
                <span>{{ value | formatNumber }} Km</span>
              </template>
              <template #[`item.valor_km`]="{value}">
                <span>R$ {{ value | formatCurrency }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-totals-volume">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span class="caption font-weight-light">
                    Valor Total
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3 cyan--text"
                  >R$ {{ totalAmountVolume | formatCurrency }}</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span class="caption font-weight-light">
                    Volume Total
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3 teal--text"
                  >{{ totalVolume | formatNumber }} L</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  offset-md="2"
                  class="py-0"
                >
                  <v-text-field
                    v-model="freightsVolume.search"
                    append-icon="search"
                    label="Busca"
                    single-line
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="freightsVolume.headers"
              :search="freightsVolume.search"
              :items="items"
              :loading="loading"
              dark
              @click:row="showDetails"
            >
              <template
                #[`header.valor_km`]="{ header }"
              >
                {{ `${header.text} (${(frete_coleta.tipo_km == "ODOMETRO" ? 'Odômetro' : 'Planejado')})` }}
              </template>
              <template #[`item.volume`]="{value}">
                <span>{{ value | formatNumber }} L</span>
              </template>
              <template #[`item.distancia`]="{value}">
                <span>{{ value | formatNumber }} Km</span>
              </template>
              <template #[`item.distancia_rota`]="{value}">
                <span>{{ value | formatNumber }} Km</span>
              </template>
              <template #[`item.valor_volume`]="{value}">
                <span>R$ {{ value | formatCurrency }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-details">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title>
              <v-col
                md-3
                class="pa-0"
              />
              <v-col
                md-6
                offset-md="3"
                class="pa-0"
              >
                <v-text-field
                  v-model="details.search"
                  append-icon="search"
                  label="Busca"
                  single-line
                  hide-details
                />
              </v-col>
            </v-card-title>
            <v-data-table
              :headers="details.headers"
              :items="details.items"
              :search="details.search"
              dark
            >
              <template #[`item.data`]="{value}">
                <span>{{ value | dateFormat("DD/MM/YYYY") }}</span>
              </template>
              <template #[`item.volume`]="{value}">
                <span>{{ value | formatNumber }} L</span>
              </template>
              <template #[`item.distancia`]="{value}">
                <span>{{ value | formatNumber }} Km</span>
              </template>
              <template #[`item.distancia_rota`]="{value}">
                <span>{{ value | formatNumber }} Km</span>
              </template>
              <template #[`item.taxa_ocupacao`]="{value}">
                <span>{{ value | formatNumber }} %</span>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-container>

    <v-dialog
      v-model="showConfig"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title>
          Configuração de valores
        </v-card-title>
        <v-divider />
        <v-card-text
          class="pt-5"
          style="max-height: calc(100vh - 250px);"
        >
          <v-form
            ref="form"
            lazy-validation
            @submit="savePrices"
          >
            <v-text-field
              v-model="frete_coleta.preco_km"
              label="Preço por Km"
              type="number"
              @keypress="disableDot"
            />
            <v-text-field
              v-model="frete_coleta.preco_litro"
              label="Preço por Litro"
              type="number"
              @keypress="disableDot"
            />
            <v-radio-group
              v-model="frete_coleta.tipo_km"
              label="Tipo de Km"
            >
              <v-radio
                label="Planejado"
                value="PLANEJADO"
              />
              <v-radio
                label="Odômetro"
                value="ODOMETRO"
              />
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="closeSettings"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :loading="saving"
            @click="savePrices"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import qs from "qs";
import _ from "lodash";
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {
  components: {
    VDateRangePicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  data() {
    return {
      tab: "tab-totals-km",

      filters: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },

      freightsKm: {
        search: "",
        headers: [
          { text: "Freteiro", value: "freteiro" },
          { text: "Volume", value: "volume", width: 180 },
          { text: "Distância (Odômetro)", value: "distancia", width: 180 },
          { text: "Distância (Rota)", value: "distancia_rota", width: 180 },
          { text: "Valor (Km)", value: "valor_km", width: 180 },
        ],
      },

      freightsVolume: {
        search: "",
        headers: [
          { text: "Freteiro", value: "freteiro" },
          { text: "Volume", value: "volume", width: 180 },
          { text: "Distância (Odômetro)", value: "distancia", width: 180 },
          { text: "Distância (Rota)", value: "distancia_rota", width: 180 },
          { text: "Valor (Volume)", value: "valor_volume", width: 180 },
        ],
      },

      items: [],

      details: {
        search: "",
        headers: [
          { text: "Rota", value: "nome_rota" },
          { text: "Freteiro", value: "nome" },
          { text: "Motorista", value: "motorista" },
          { text: "Data", value: "data" },
          { text: "Volume", value: "volume" },
          { text: "Distância (Odômetro)", value: "distancia" },
          { text: "Distância (Rota)", value: "distancia_rota" },
          { text: "Taxa de ocupação", value: "taxa_ocupacao" },
        ],

        items: [],
      },

      loading: false,
      saving: false,
      showConfig: false,

      frete_coleta: {
        tipo_km: "ODOMETRO",
        preco_km: 0,
        preco_litro: 0,
      },
    };
  },

  computed: {
    totalAmountKm() {
      return this.items.reduce((acc, cur) => acc + cur.valor_km, 0)
    },
    totalKm() {
      if (this.frete_coleta.tipo_km == "ODOMETRO") {
        return this.items.reduce((acc, cur) => acc + cur.distancia, 0)
      }
      return this.items.reduce((acc, cur) => acc + cur.distancia_rota, 0)
    },
    totalAmountVolume() {
      return this.items.reduce((acc, cur) => acc + cur.valor_volume, 0)
    },
    totalVolume() {
      return this.items.reduce((acc, cur) => acc + cur.volume, 0)
    }
  },

  mounted() {
    this.loadFreightPayment();
  },

  methods: {
    onTabChange(id) {
      if (id == 'tab-totals-km' || id == 'tab-totals-volume') {
        this.details.items = [];
      }
    },
    showDetails({ items }) {
      this.details.items = items;
      this.tab = 'tab-details';
    },
    async loadFreightPayment() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.filters.range;

        let { data } = await this.$axios.post(
          `/relatorios/volumeRotasPrestador`,
          {
            data_inicio: startDate,
            data_fim: endDate,
          }
        );

        this.items = _(data)
          .groupBy('nome')
          .map((items, freteiro) => {
            return {
              freteiro: freteiro != "null" ? freteiro : "Outros",
              ...items.reduce((acc, cur) => ({
                distancia: acc.distancia + parseFloat(cur.distancia),
                distancia_rota: acc.distancia_rota + parseFloat(cur.distancia_rota),
                volume: acc.volume + parseFloat(cur.volume),
              }), { distancia: 0, distancia_rota: 0, volume: 0 }),
              items
            }
          }).value();

        this.getPrices();
        this.calculatePrices();

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    calculatePrices() {
      this.items = this.items.map(item => {
        const valor_km = (this.frete_coleta.tipo_km == "ODOMETRO" ? item.distancia : item.distancia_rota) * parseFloat(this.frete_coleta.preco_km);
        const valor_volume = item.volume * parseFloat(this.frete_coleta.preco_litro);
        return {
          ...item,
          valor_km,
          valor_volume
        }
      })
    },

    onDateFilter() {
      this.loadFreightPayment();
    },

    closeSettings() {
      this.getPrices();
      this.showConfig = false;
    },

    openSettings() {
      this.getPrices();
      this.showConfig = true;
    },

    getPrices() {
      if (this.$store.state.settings.gerais.frete_coleta) {
        this.frete_coleta = { ...this.$store.state.settings.gerais.frete_coleta };
      }
    },

    async savePrices() {
      this.saving = true;
      try {
        const payLoad = {
          configuracaoQualidade: JSON.stringify([{
            frete_coleta: this.frete_coleta,
          }]),
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          qs.stringify(payLoad)
        );

        [data] = data;

        if (data.codigo !== 1) {
          throw data;
        }

        this.$store.dispatch('updateStoreSetting');
        this.calculatePrices();
        this.showConfig = false;
        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.error(error);
      } finally {
        this.saving = false;
      }
    },

    disableDot(e) {
      if (e.charCode === 46) {
        e.preventDefault()
      }
    },

    exportExcel() {
      if (this.tab == 'tab-totals-km') {
        const data = XLSX.utils.json_to_sheet(this.items.map(o => ({
          Freteiro: o.freteiro,
          Volume: this.$options.filters.formatNumber(o.volume) + ' L',
          'Distância (Odômetro)': this.$options.filters.formatNumber(o.distancia) + ' Km',
          'Distância (Rota)': this.$options.filters.formatNumber(o.distancia_rota) + ' Km',
          [`Valor (Km) (${(this.frete_coleta.tipo_km == "ODOMETRO" ? 'Odômetro' : 'Planejado')})`]: 'R$ ' + this.$options.filters.formatCurrency(o.valor_km),
        })));

        data['!cols'] = [
          { wch: 40 },
          { wch: 12 },
          { wch: 18 },
          { wch: 15 },
          { wch: 18 }
        ];

        const workbook = XLSX.utils.book_new();
        const filename = "pagamento_frete_km";
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else if (this.tab == 'tab-totals-volume') {
        const data = XLSX.utils.json_to_sheet(this.items.map(o => ({
          Freteiro: o.freteiro,
          Volume: this.$options.filters.formatNumber(o.volume) + ' L',
          'Distância (Odômetro)': this.$options.filters.formatNumber(o.distancia) + ' Km',
          'Distância (Rota)': this.$options.filters.formatNumber(o.distancia_rota) + ' Km',
          'Valor (Volume)': 'R$ ' + this.$options.filters.formatCurrency(o.valor_volume),
        })));

        data['!cols'] = [
          { wch: 40 },
          { wch: 12 },
          { wch: 18 },
          { wch: 15 },
          { wch: 15 }
        ];

        const workbook = XLSX.utils.book_new();
        const filename = "pagamento_frete_volume";
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else {
        const data = XLSX.utils.json_to_sheet(this.details.items.map(o => ({
          Rota: o.nome_rota,
          Freteiro: o.nome,
          Motorista: o.motorista,
          Volume: this.$options.filters.formatNumber(o.volume) + ' L',
          'Distância (Odômetro)': this.$options.filters.formatNumber(o.distancia) + ' Km',
          'Distância (Rota)': this.$options.filters.formatNumber(o.distancia_rota) + ' Km',
          'Taxa de ocupação': this.$options.filters.formatNumber(o.taxa_ocupacao) + ' %',
        })));

        data['!cols'] = [
          { wch: 40 },
          { wch: 40 },
          { wch: 40 },
          { wch: 12 },
          { wch: 18 },
          { wch: 15 },
          { wch: 15 }
        ];

        const workbook = XLSX.utils.book_new();
        const filename = "pagamento_frete_detalhes";
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
    },
  },
};
</script>
