<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="filters.search"
        dark
        dense
        @click:row="showPriceTable"
      >
        <template #[`item.startDate`]="{ value }">
          {{ dateFormat(value, 'DD/MM/YYYY') }}
        </template>

        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="showPriceTable(item)">
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.id !== 'DEFAULT'"
                @click="removePriceTable(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="addPriceTable()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Nova tabela de preço</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      loading: false,

      filters: {
        search: null
      },

      headers: [
        { text: 'Código', value: 'code', width: 100 },
        { text: 'Nome', value: 'name' },
        { text: 'Região', value: 'region' },
        { text: 'Data início', value: 'startDate' },
        { text: '', value: 'action', width: 30, sortable: false }
      ],

      items: [],
    }
  },

  created() {
    this.getPriceTables()
  },

  methods: {
    showPriceTable({ id }) {
      this.$emit("showPriceTable", id);
    },

    /**
     * @event void
     *
     * Evento de criação de uma nova tabela
     */
    addPriceTable() {
      return this.$emit('addPriceTable')
    },

    async getPriceTables() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item/tabelasDePreco`);

        this.items = data.map(p => ({
          id: p.id_tabela_preco,
          code: p.codigo_tabela_preco,
          name: p.nome,
          region: p.nome_regiao,
          startDate: p.data_inicio,
        }))

        this.items.push({
          id: "DEFAULT",
          name: "TABELA PADRÃO"
        })
      } catch (error) {
        this.$snotify.error("Erro ao carregar a tabela de preços", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async removePriceTable({ id }) {
      if (!(await this.$root.$confirm('Remover tabela', 'Tem certeza que deseja remover esta tabela?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/item/removerTabelaDePreco`, {
          id_tabela_preco: id
        });
        
        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.items.splice(this.items.findIndex(item => item.id === id), 1);
        this.$snotify.success("Tabela removida com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error(`Erro ao remover a tabela de preço. ${error}`, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
</script>