<template>
  <v-autocomplete
    v-model="input"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    item-value="id"
    item-text="description"
    :return-object="returnObject"
    :filter="() => true"
    hide-no-data
    v-bind="$attrs"
    @change="onChange"
  />
</template>

<script>
import { debounce, isString } from 'lodash';

export default {
  name: 'item-autocomplete-filter',

  props: {
    value: {
      type: [ String, Object, Array ],
    },

    types: {
      type: Array,
      default: () => [ 'PRODUTO_ACABADO' ]
    },

    itemTypes: Array,

    showOtherMeasurements: Boolean,
    returnObject: Boolean,
  },

  data() {
    return {
      input: null,
      loading: false,
      items: [],
      search: ''
    }
  },

  watch: {
    value() {
      this.input = this.value;
      if (this.value && this.value.name) {
        this.queryProducts(this.value.name);
      }
    },

    search(val) {
      if (val && val !== this.input) {
        this.loading = true;
        this.queryProducts(val);
      }
    },
  },

  created() {
    this.input = this.value;
  },

  mounted() {
    if (this.value) {
      if (isString(this.value)) {
        this.loadProductById(this.value);
      } else if (this.value.name) {
        this.queryProducts(this.value.name);
      }
    }
  },

  methods: {
    queryProducts: debounce(async function(busca) {
      try {
        if (!busca || this.items.find(item => item.description.includes(busca))) {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          exibir_outras_unidades: this.showOtherMeasurements ? 1 : 0,
          tipos: this.types,
          tipos_item: this.itemTypes,
          busca
        } });

        this.items = data.map(o => ({
          id: o.id_unidade_medida || o.id_item,
          itemId: o.id_item,
          measurementId: o.id_unidade_medida,
          tareWeight1: parseFloat(o.peso_tara_1) || 0,
          grossWeight: parseFloat(o.peso_bruto) || 0,
          tareWeight2: parseFloat(o.peso_tara) || 0,
          code: o.cod_item,
          name: o.nome.trim(),
          sif: o.sif,
          sifDipoa: o.sif_dipoa,
          barcode: o.codigo_barras,
          defaultBarcode: o.cod_barras_padrao,
          description: `${o.cod_item} - ${o.nome.trim()}` + (o.unidade_descricao ? ` (${o.unidade_descricao})` : ''),
          type: o.tipo,
          measurement: o.unidade,
          measurementDescription: o.unidade_descricao,
          defaultMeasurement: o.unidade_padrao,
          conversionFactor: parseFloat(o.fator_conversao) || 1,
          price: o.valor ? parseFloat(o.valor) : null,
          requiresLot: o.controlar_lotes || false,
          manualLot: o.lote_manual || false,
          stockControl: o.controle_estoque,
          group: {
            value: o.id_item_grupo,
            text: o.nome_item_grupo,
          },
        }));

      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os produtos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    }, 1000),

    async loadProductById(id) {
      try {
        const { data } = await this.$axios.get(`/item/${id}`);

        this.items = [
          {
            id: data.id_item,
            code: data.cod_item,
            name: data.nome.trim(),
            description: `${data.cod_item} - ${data.nome.trim()}`,
            type: data.tipo,
            measurement: data.unidade,
            price: data.valor ? parseFloat(data.valor) : null,
            group: {
              value: data.id_item_grupo,
              text: data.nome_item_grupo,
            },
          }
        ];
      } catch (error) {
        console.warn(error);
      }
    },

    onChange(event) {
      // atualiza o v-model do componente
      this.$emit("input", event || {});

      this.$emit("change", event);
    },
  }
}
</script>
