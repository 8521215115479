<template>
  <div
    class="ml-10 mr-10"
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Relatório Brucelose / Tuberculose
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="filter.typeExam"
          :items="typeExamFilter"
          label="Tipo Exame"
          prepend-inner-icon="biotech"
          dark
          filled
          clearable
          hide-details
          @change="onLoad"
        />
      </v-col>

      <v-col
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="onLoad"
        />
      </v-col>

      <v-col
        md="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-if="tab == 2"
          v-model="filter.typeComingDue"
          :items="typeComingDueFilter"
          label="À vencer"
          prepend-inner-icon="event"
          dark
          filled
          clearable
          hide-details
          @change="onLoad"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        {{ tab.title }}
      </v-tab>

      <v-tab-item
        v-for="tab in tabs"
        :key="tab.title"
        background-color="transparent"
        dark
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            {{ `Produtores: ${ tab.items.length}` }}
          </v-card-title>
          <data-table
            ref="report"
            :headers="tab.headers"
            :items="tab.items"
            :search="filter.search"
            dark
            show-custom-group
          />
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import moment from "moment-timezone";
import _ from "lodash";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  name: "report-brucelose-tuberculose",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter
  },

  mixins: [ReportMixin],

  data() {
    return {
      filter: {
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        search: '',
        typeExam: null,
        typeComingDue: 30,
      },

      tab: null,

      notExam: [],
      expired: [],
      comingDue: [],
      regular: [],

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome' },

        { text: 'Vac. (Data)', value: 'vacinas.data' },
        { text: 'Vac. (Qtd. Animais)', value: 'vacinas.quantidade_animais' },
        { text: 'Vac. (Veterinário)', value: 'vacinas.veterinarian.nome' },

        { text: 'Bruc. (Data)', value: 'brucelose.data' },
        { text: 'Bruc. (Qtd. Animais)', value: 'brucelose.quantidade_animais' },
        { text: 'Bruc. (Veterinário)', value: 'brucelose.veterinarian.nome' },
        { text: 'Bruc. (Vencimento)', value: 'brucelose.data_vencimento' },

        { text: 'Tub. (Data)', value: 'tuberculose.data' },
        { text: 'Tub. (Qtd. Animais)', value: 'tuberculose.quantidade_animais' },
        { text: 'Tub. (Veterinário)', value: 'tuberculose.veterinarian.nome' },
        { text: 'Tub. (Vencimento)', value: 'tuberculose.data_vencimento' },
      ],

      typeExamFilter: [
        { value: 'BRUCELOSE', text: 'Brucelose' },
        { value: 'TUBERCULOSE', text: 'Tuberculose' },
      ],

      typeComingDueFilter: [
        { value: 15, text: '15 dias' },
        { value: 30, text: '30 dias' },
        { value: 60, text: '60 dias' },
        { value: 90, text: '90 dias' },
        { value: 120, text: '120 dias' },
      ],
    };
  },

  computed: {

    filteredItems() {
      return this.search(this.notExam);
    },

    filteredItems2() {
      return this.search(this.expired);
    },

    filteredItems3() {
      return this.search(this.comingDue);
    },

    filteredItems4() {
      return this.search(this.regular);
    },

    tabs() {
      return [
        {
          title: 'Sem Exames',
          items: this.filteredItems,
          headers: this.filteredHeaders(),
        },
        {
          title: 'Vencidos',
          items: this.filteredItems2,
          headers: this.filteredHeaders(),
        },
        {
          title: 'À vencer',
          items: this.filteredItems3,
          headers: this.filteredHeaders(),
        },
        {
          title: 'Regular',
          items: this.filteredItems4,
          headers: this.filteredHeaders(),
        }
      ]
    },
  },

  created() {
    this.loadExam();
  },

  methods: {
    onLoad() {
      this.notExam = [],
      this.expired = [],
      this.comingDue = [],
      this.regular = [],

      this.loadExam();
    },

    async loadExam() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
          tecnico: this.filter.technician.id,
          rotas: this.filter.routes.map(({ id }) => id)
        }});

        const today = moment().format("YYYY-MM-DD");

        this.notExam = this.notHaveExam(data, this.filter.typeExam);
        this.expired = this.expiredExam(data, this.filter.typeExam, today);
        this.comingDue = this.comingDueExam(data, this.filter.typeExam, today);
        this.regular = this.regularExam(data, this.filter.typeExam, today);

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    print() {
      const title = this.tabs[this.tab].title;
      this.$refs.report[this.tab].print(null, title);
    },

    exportExcel() {
      const title = this.tabs[this.tab].title;
      this.$refs.report[this.tab].exportExcel(null, title);
    },

    filteredHeaders() {
      if(!this.filter.typeExam) {
        return this.headers;
      }

      return this.headers.filter(item => {
        const typeExam = item.value.split(".")[0].toUpperCase();
        const typeExamVerify = (typeExam != 'BRUCELOSE' && typeExam != 'TUBERCULOSE');
        const typeExamFilter = (this.filter.typeExam == typeExam);

        return typeExamFilter || typeExamVerify;
      });
    },

    notHaveExam(data, type) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => !item[exam]);
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    expiredExam(data, type, today) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento < today));
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    comingDueExam(data, type, today) {
      const coming = moment().add(30, "days").format("YYYY-MM-DD");
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento < coming && item[exam].data_vencimento >= today));

      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    regularExam(data, type, today) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento > today));
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    formatExams(data) {
      const format = (item, isVaccine = false) => {
        const { data, data_vencimento, quantidade_animais, ...rest } = item || {};
        return {
          data: data ? this.formatDate(data, 'DD/MM/YYYY') : (isVaccine ? 'Sem Vacina' : 'Sem Exame'),
          quantidade_animais: quantidade_animais || 0,
          data_vencimento: !isVaccine && data_vencimento ? this.formatDate(data_vencimento, 'DD/MM/YYYY') : null,
          ...rest
        };
      };

      return data.map(({brucelose, tuberculose, vacinas, ...rest}) => ({
        brucelose: format(brucelose),
        tuberculose: format(tuberculose),
        vacinas: format(vacinas, true),
        ...rest
      }));
    },

    formatDate(value, format) {
      if (!value) {
        return '';
      } else {
        return moment(value).format(format)
      }
    },

    search(data) {
      return this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
    }
  },
};
</script>
