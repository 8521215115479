<template>
  <div class="pt-0">
    <v-card
      dark
      class="transparent"
    >
      <v-card-title class="pb-0">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pb-0"
          >
            <v-date-range-picker
              v-model="filters.date.range"
              :ranges="filters.date.ranges"
              prepend-inner-icon="today"
              dark
              label="Data"
              hide-details
              filled
              :max-days="30"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="2"
            class="pb-0"
          >
            <v-select
              v-model="filters.groupBy"
              :items="[
                { value: 'MOTORISTA', text: 'Motorista' },
                { value: 'ROTA', text: 'Rota' }
              ]"
              prepend-inner-icon="workspaces"
              dark
              label="Agrupar por"
              hide-details
              filled
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="2"
            class="pb-0"
          >
            <routes-autocomplete-filter
              v-model="filters.routeId"
              label="Rota"
              dark
              :return-object="false"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="2"
            class="pb-0"
          >
            <v-select
              v-model="filters.hasDiscount"
              :items="hasDiscountOptions"
              prepend-inner-icon="reorder"
              dark
              label="Desconto"
              hide-details
              filled
              clearable
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
            class="pb-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              label="Busca"
              filled
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        light
        dark
      >
        <template
          v-for="column in dateHeaders"
          #[`item.${column.value}`]="{ item }"
        >
          <div :key="column.value">
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  class="mt-2 mb-2"
                  style="cursor: default"
                  v-on="on"
                >
                  {{ getDailyValue(item[column.value])['vale'] }}
                </div>
              </template>
              Vale
            </v-tooltip>
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  class="mt-1 mb-1"
                  style="cursor: default"
                  v-on="on"
                >
                  {{ getDailyValue(item[column.value])['medido'] }}
                </div>
              </template>
              Medido
            </v-tooltip>
          </div>
        </template>
        <template #[`item.diferenca`]="{ item }">
          <v-tooltip
            top
          >
            <template #activator="{ on, attrs }">
              <v-chip
                v-if="item.diferenca < 0"
                small
                v-bind="attrs"
                color="red darken-1"
                v-on="on"
              >
                {{ formatNumber(item.diferenca) }}
              </v-chip>
              <v-chip
                v-else
                small
                v-bind="attrs"
                color="teal darken-1"
                v-on="on"
              >
                {{ formatNumber(item.diferenca) }}
              </v-chip>
            </template>
            Diferença = Vale -  Medidos
          </v-tooltip>
        </template>
        <template #[`item.desconto`]="{ item }">
          <v-tooltip
            top
          >
            <template #activator="{ on, attrs }">
              <v-chip
                v-if="item.desconto < 0"
                small
                v-bind="attrs"
                color="red darken-1"
                v-on="on"
              >
                {{ formatNumber(item.desconto) }}
              </v-chip>
              <v-chip
                v-else
                small
                v-bind="attrs"
                color="teal darken-1"
                v-on="on"
              >
                {{ formatNumber(item.desconto) }}
              </v-chip>
            </template>
            (Diferença - tolerância) > 0
          </v-tooltip>
        </template>

        <template #[`item.vale_total`]="{ item }">
          <div style="cursor: default">
            {{ formatNumber(item.vale_total) }}
          </div>
        </template>

        <template #[`item.medido_total`]="{ item }">
          <div style="cursor: default">
            {{ formatNumber(item.medido_total) }}
          </div>
        </template>

        <template #[`body.append`]="{ headers }">
          <tr class="tr-tot-day">
            <td
              v-for="(header,index) in headers"
              :key="index"
            >
              <v-chip
                v-if="header.value ==='vale_total'"
                color="blue darken-4"
                label
              >
                {{ formatNumber(totalVale) }}
              </v-chip>
              <v-chip
                v-else-if="header.value ==='medido_total'"
                color="blue darken-4"
                label
              >
                {{ formatNumber(totalMedido) }}
              </v-chip>
              <v-chip
                v-else-if="header.value ==='diferenca'"
                color="blue darken-4"
                label
              >
                {{ formatNumber(totalDiferenca) }}
              </v-chip>
              <v-chip
                v-else-if="header.value ==='desconto'"
                color="blue darken-4"
                label
              >
                {{ formatNumber(totalDesconto) }}
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">
  .tr-tot-day{
    span{
      font-family: Roboto, Arial, sans-serif !important;
      font-size: 1rem !important;
    }
  }
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    RoutesAutocompleteFilter,
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        search: '',
        groupBy: 'MOTORISTA',
        routeId: null,
        date: {
          ranges: {
            'Hoje': [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            'Ontem': [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
            'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
            'Mês anterior': [moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")],
          },
          range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        },
      },
      items: [],
      hasDiscountOptions: [
        { value: false, text: 'Sem Desconto' },
        { value: true, text: 'Com Desconto' }
      ],
    };
  },
  computed: {
    thisMonth() {
      const firstMonthDay = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment().format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    headers() {
      const headers = [
        { text: "Vale", value: "vale_total", width: 120, align: "center" },
        { text: "Medidos", value: "medido_total", width: 120, align: "center" },
        { text: "Diferença", value: "diferenca", width: 120, align: "center" },
        { text: "Desconto", value: "desconto", width: 120, align: "center" },
        ...this.dateHeaders
      ];

      if (this.filters.groupBy === 'ROTA') {
        headers.unshift({ text: "Rota", value: "nome_rota", width: 250 });
      } else {
        headers.unshift({ text: "Motorista", value: "nome_motorista", width: 250 });
      }

      return headers;
    },

    dateHeaders() {
      const [startDate, endDate] = this.filters.date.range;

      let header = [];
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        header.push({
          text: currentDate.format('DD/MM'),
          value: currentDate.format('YYYY-MM-DD'),
          width: 110,
          align: "center",
        });
        currentDate = moment(currentDate).add(1, 'days');
      }
      return header;
    },

    totalMedido() {
      return this.filteredItems.reduce((acc, item) => (acc + item.medido_total), 0)
    },

    totalVale() {
      return this.filteredItems.reduce((acc, item) => (acc + item.vale_total), 0)
    },

    totalDiferenca() {
      return this.filteredItems.reduce((acc, item) => (acc + item.diferenca), 0)
    },

    totalDesconto() {
      return this.filteredItems.reduce((acc, item) => (acc + item.desconto), 0)
    },

    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.items.filter(item => {
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return searchFilter
      });
    },
  },

  mounted() {
    this.onFilter();
  },

  methods: {
    async loadDifferenceDaily() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filters.date.range;

        const { data } = await this.$axios.post(
          `/descargaColeta/diferencaMedidosValePorDia`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            id_rota: this.filters.routeId,
            agrupar_por: this.filters.groupBy,
            com_desconto: this.filters.hasDiscount,
          }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.items = this.handleData(data);
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onFilter() {
      this.items = [];
      return this.loadDifferenceDaily();
    },

    getDailyValue(item) {
      let value = [];

      value['vale'] = '-';
      value['medido'] = '-';

      if (item) {
        item = item.split('/');
        value['vale'] = _.isEmpty(item[0]) || item[0] == "undefined" ? '-' : this.formatNumber(item[0]);
        value['medido'] = _.isEmpty(item[1]) || item[1] == "undefined" ? '-' : this.formatNumber(item[1]);
      }
      return value;
    },

    handleData(response) {
      const groupByValue = this.filters.groupBy === 'ROTA' ? 'nome_rota' : 'nome_motorista';
      //Agrupa por motorista
      const group = _.groupBy(response, groupByValue);

      //Preenche com todo o range date
      const fillDate = this.dateHeaders.map(({ value }) => ({ data: value }));

      //Preenche os valores correspondente a coleta de cada dia
      return _.map(group, item => {
        let vale_total = 0;
        let medido_total = 0;
        let diferenca = 0;
        let desconto = 0;

        const concat = _.concat(item, fillDate);

        item = _.uniqBy(concat, "data");

        const accumulator = _.reduce(
          item,
          function(result, value) {
            const { data, medido, vale } = value;

            return {
              ...result,
              ...value,
              [data]: vale + '/' + medido,
            };
          },
          {}
        );

        item.forEach(e => {
          if (e.vale > 0 && e.vale != null) {
            vale_total += parseFloat(e.vale);
          }

          if (e.medido > 0 && e.medido != null) {
            medido_total += parseFloat(e.medido);
          }

          if (e.diferenca != null) {
            diferenca += parseFloat(e.diferenca);
          }

          if (e.desconto != null) {
            desconto += parseFloat(e.desconto);
          }
        });

        _.unset(accumulator, "medido");
        _.set(accumulator, "vale_total");
        _.set(accumulator, "medido_total");
        _.set(accumulator, "diferenca");
        _.set(accumulator, "desconto");
        _.unset(accumulator, "data");

        accumulator.vale_total = vale_total;
        accumulator.medido_total = medido_total;
        accumulator.diferenca = diferenca;
        accumulator.desconto = desconto;
        return accumulator;
      });
    },

    exportExcel() {
      const groupByText = this.filters.groupBy === 'ROTA' ? 'Rota' : 'Motorista';
      const groupByValue = this.filters.groupBy === 'ROTA' ? 'nome_rota' : 'nome_motorista';
      let data = this.XLSX.utils.json_to_sheet(this.items.map((item) => {
        return {
          [groupByText]: item[groupByValue],
          Vale: _.isEmpty(String(item.vale_total)) ? 0 : this.formatNumber(parseFloat(item.vale_total)),
          Medidos: _.isEmpty(String(item.medido_total)) ? 0 : this.formatNumber(parseFloat(item.medido_total)),
          Diferença: _.isEmpty(String(item.diferenca)) ? 0 : this.formatNumber(parseFloat(item.diferenca)),
          Desconto: _.isEmpty(String(item.desconto)) ? 0 : this.formatNumber(parseFloat(item.desconto)),
          ...this.dateHeaders.reduce((acc, header) => ({
            ...acc, [header.text]: "Vale: " + this.getDailyValue(item[header.value])['vale'] + "  Medido: " + this.getDailyValue(item[header.value])['medido']
          }), {})
        };
      }), { WrapText: true });

      const workbook = this.XLSX.utils.book_new();

      const filename = "diferenca_vale_medidos_diario";

      data['!cols'] = [
        { wch: 30 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      this.dateHeaders.map(() => {
        data['!cols'].push({ wch: 30 });
      });

      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
