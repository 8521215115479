<template>
  <v-row>
    <v-col
      cols="12"
      sm="4"
    >
      <CardInfo
        title="Volume Total"
        icon="icon-coleta_principal"
        :value="`${ formatNumber(totalVol) } L`"
        :color="color"
        :loading="loading"
      >
        <template #footer>
          <span class="caption font-weight-light">
            {{ dateFormat(referenceMonth, 'MMM/YYYY') }}
          </span>
        </template>
      </CardInfo>
    </v-col>
    <v-col
      cols="12"
      sm="4"
    >
      <CardInfo
        title="Média Frete / Litro"
        icon="icon-tipo_operacao"
        :value="formatCurrency(milkPriceAvg, 4)"
        :color="color"
        :loading="loading"
      >
        <template #footer>
          <span class="caption font-weight-light">
            {{ dateFormat(referenceMonth, 'MMM/YYYY') }}
          </span>
        </template>
      </CardInfo>
    </v-col>
    <v-col
      cols="12"
      sm="4"
    >
      <CardInfo
        title="Total à Pagar"
        icon="icon-quitacao_contas"
        :value="formatCurrency(totalPaidAmount)"
        :color="color"
        :loading="loading"
      >
        <template #footer>
          <span class="caption font-weight-light">
            {{ dateFormat(referenceMonth, 'MMM/YYYY') }}
          </span>
        </template>
      </CardInfo>
    </v-col>
  </v-row>
</template>

<script>
import CardInfo from "@/Domains/BI/Components/CardInfo.vue";

import moment from 'moment';
import _ from 'lodash';

export default {

  components: {
    CardInfo
  },

  filters: {
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    referenceMonth: {
      type: String,
      default: () => moment().subtract(1, "month").format("YYYY-MM"),
    },
  },

  data() {
    return {
      loading: false,
      totalVol: 0,
      milkPriceAvg: 0,
      totalPaidAmount: 0,
    }
  },

  watch: {
    referenceMonth() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/freight/reports/price-average`, { params: {
          month_reference: this.referenceMonth
        } });

        this.totalVol = data.total_volume || 0;
        this.milkPriceAvg = data.media_frete || 0;
        this.totalPaidAmount = data.total_frete || 0;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os totais do frete!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value, decimals = 2) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: decimals }).format(value),
    dateFormat: (value, format) => !value ? '-' : _.upperFirst(moment(value).format(format)),
  }
}
</script>
