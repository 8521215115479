<template>
  <div>
    <v-card
      outlined
      elevation="3"
      class="mb-4"
    >
      <v-card-title class="py-3">
        Produtor
      </v-card-title>
      <v-card-text class="py-2">
        <v-form>
          <v-row>
            <v-col
              cols="3"
              class="py-2"
            >
              <v-text-field
                v-model="localData.codigo_produtor"
                label="Código do produtor"
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
              class="py-2"
            >
              <v-text-field
                v-model="localData.idade_produtor"
                type="number"
                hide-details
                label="Idade do produtor"
              />
            </v-col>
            <v-col
              cols="3"
              class="py-2"
            >
              <v-text-field
                v-model="localData.cidade"
                label="Cidade"
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-autocomplete
                v-model="technicals.selected"
                label="Técnico responsável"
                :items="technicals.list"
                item-text="name"
                item-value="id"
                hide-details
                multiple
                clearable
                item-color="#25937D"
                deletable-chips
                chips
                small-chips
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.grau_escolaridade"
                label="Grau de escolaridade"
                hide-details
                :items="[
                  'NÃO POSSUI',
                  'FUNDAMENTAL INCOMPLETO',
                  'FUNDAMENTAL COMPLETO',
                  'MÉDIO INCOMPLETO',
                  'MÉDIO COMPLETO',
                  'SUPERIOR INCOMPLETO',
                  'SUPERIOR COMPLETO',
                ]"
              />
            </v-col>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.objetivos_produtor"
                label="Objetivo do produtor"
                hide-details
                :items="['MANTER COMO ESTÁ', 'AUMENTAR A PRODUÇÃO', 'DIMINUIR A PRODUÇÃO']"
              />
            </v-col>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-menu
                v-model="inputAt.picker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="localData.entrada_laticinio"
                    label="Entrada no lacticínio"
                    persistent-hint
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="inputAt.date"
                  no-title
                  @input="inputAt.picker = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.frequencia_assistencia_tecnica"
                label="Com que freqüência o produtor recebe assistência técnica?"
                hide-details
                :items="['NUNCA', 'SOMENTE QUANDO O ANIMAL ESTÁ EM RISCO DE VIDA', 'FREQUENTEMENTE', 'RARAMENTE']"
              />
            </v-col>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.tipo_assistencia_tecnica"
                label="Qual o tipo de assistência técnica mais utilizada?"
                hide-details
                :items="[
                  'NENHUMA',
                  'VETERINÁRIO',
                  'TÉCNICO DE EMPRESA COMERCIAL',
                  'VENDEDORES',
                  'ÓRGÃO DE ASSISTÊNCIA DO GOVERNO',
                  'OUTRO',
                ]"
              />
            </v-col>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.modo_planejamento_produtor"
                label="O que o produtor observa para fazer seus planos futuros?"
                hide-details
                :items="[
                  'O PREÇO DO LEITE E DO ANIMAL',
                  'PREÇO E ANÁLISE DE MERCADO',
                  'COMPARATIVO DE OUTROS PRODUTOS AGRÍCOLAS',
                  'FAZ CALCULO DO CUSTO DE PRODUÇÃO',
                  'OUTRO',
                ]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col />
            <v-col

              cols="4"
              class="py-2"
            >
              <v-text-field
                v-if="localData.tipo_assistencia_tecnica === 'OUTRO'"
                v-model="localData.tipo_assistencia_tecnica_descricao"
                label="Outro (Tipo de assistência técnica)"
                hide-details
              />
            </v-col>
            <v-col

              cols="4"
              class="py-2"
            >
              <v-text-field
                v-if="localData.modo_planejamento_produtor === 'OUTRO'"
                v-model="localData.modo_planejamento_produtor_descricao"

                label="Outro (Planos futuros)"
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- ATIVIDADES -->
    <v-card
      outlined
      elevation="3"
      class="mb-4"
    >
      <v-card-title class="pb-0">
        Atividades
      </v-card-title>
      <v-card-text class="py-2">
        <v-form>
          <v-row align="center">
            <v-col
              cols="6"
              class="text-center pa-0"
            >
              <h3>Desenvolvidas</h3>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
            >
              <h3>Principais</h3>
            </v-col>
          </v-row>
          <v-row align="center">
            <!-- Desenvolvidas -->
            <v-col
              cols="6"
              class="py-2"
              style="border-right: 1px solid #cccc !important"
            >
              <v-row align="center">
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_desenv_leite"
                    label="Leite"
                    hide-details
                    top
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_desenv_aves"
                    label="Aves"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_desenv_suinos"
                    label="Suínos"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_desenv_soja"
                    label="Soja"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_desenv_milho"
                    label="Milho"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_desenv_trigo"
                    label="Trigo"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_desenv_bov_corte"
                    label="Bovino corte"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_desenv_outras"
                    label="Outro"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-col>
            <!-- Principais -->
            <v-col
              cols="6"
              class="py-2"
            >
              <v-row align="center">
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_principal_leite"
                    label="Leite"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_principal_aves"
                    label="Aves"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_principal_suinos"
                    label="Suínos"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_principal_soja"
                    label="Soja"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_principal_milho"
                    label="Milho"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_principal_trigo"
                    label="Trigo"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_principal_bov_corte"
                    label="Bovino corte"
                    hide-details
                  />
                </v-col>
                <v-col
                  md="2"
                  class="py-2"
                >
                  <v-checkbox
                    v-model="localData.ativ_principal_outra"
                    label="Outro"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card
      outlined
      elevation="3"
      class="mb-3"
    >
      <v-card-title>Produção</v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col
              cols="3"
              class="py-2"
            >
              <v-text-field
                label="Produção de leite por dia"
                type="number"
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
              class="py-2"
            >
              <v-text-field
                label="Quantas pessoas trabalham no leite"
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.mao_de_obra_recebe_trainamentos_periodicos"
                label="Esta mão de obra recebe treinamento periódico?"
                hide-details
                :items="['SiM', 'NÃO']"
              />
            </v-col>
            <v-col
              cols="3"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.atividade_realiza_coleta_registro_de_dados"
                label="É feito coleta e registro de dados da atividade"
                hide-details
                :items="[
                  'NÃO',
                  'PRODUÇÃO DE LEITE',
                  'PRODUÇÃO POR VACA',
                  'COMPRA DE INSUMOS',
                  'VENDAS DE LEITES',
                  'CONTROLE DE CUSTOS',
                  'CONTROLE DE ESTOQUE',
                  'DATA DE COBERTURA',
                  'DATA DE NASCIMENTO',
                  'OUTRO',
                ]"
              />
            </v-col>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.tipo_dados_coletados"
                label="Quais são os dados coletados e registrados?"
                hide-details
                :items="[
                  'NENHUM',
                  'DATA DE COBERTURA',
                  'DATA DE NASCIMENTO',
                  'PRODUÇÃO DE LEITE TOTAL',
                  'PRODUÇÃO DE LEITE POR VACA',
                  'CONTROLE DE CUSTOS',
                  'CONTROLE DE ESTOQUE',
                  'IDADE DO PRIMEIRO PARTO',
                  'OUTRO',
                ]"
              />
            </v-col>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.meio_para_coletar_dados"
                label="Quais os meios utilizados para registrar os dados?"
                hide-details=""
                :items="[
                  'NENHUM',
                  'CADERNO DE ANOTAÇÕES',
                  'COMPUDADOR',
                  'OUTRO',
                ]"
              />
            </v-col>
            <v-col
              cols="4"
              class="py-2"
            >
              <v-autocomplete
                v-model="localData.estrategia_quantidade_adubo"
                label="Como é definida a quantidade de adubo utilizado na pastagem?"
                hide-details
                :items="[
                  'NENHUM',
                  'NÃO ADUBA',
                  'PELA ANÁLISE DO SOLO',
                  'DE ACORDE COM O QUE DÁ PRA COMPRAR',
                  'COM O QUE SOBRA  DE OUTRAS CULTURAS',
                  'OUTRO',
                ]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-if="localData.tipo_dados_coletados === 'OUTRO'"
                v-model="localData.tipo_dados_coletados_descricao"
                label="Outro (Dados coletados e registrados)"
                hide-details
              />
            </v-col>
            <v-col

              cols="4"
              class="py-2"
            >
              <v-text-field
                v-if="localData.meio_para_coletar_dados === 'OUTRO'"
                v-model="localData.meio_para_coletar_dados_descricao"
                label="Outro (Meios utilizados para registrar dados)"
                hide-details
              />
            </v-col>
            <v-col

              cols="4"
              class="py-2"
            >
              <v-text-field
                v-if="localData.estrategia_quantidade_adubo === 'OUTRO'"
                v-model="localData.estrategia_quantidade_adubo_descricao"
                label="Outro (Quantidade de adubo utilizado na pastagem)"
                hide-details
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col class="py-2">
        <v-card
          outlined
          elevation="3"
          class="mb-4"
        >
          <v-card-title>
            Propriedade
          </v-card-title>
          <v-card-text class="pb-2">
            <v-form>
              <v-row>
                <v-col
                  cols="12"
                  class="py-2"
                >
                  <v-text-field
                    v-model="localData.area_total"
                    label="Área Total (Ha)"
                    type="number"
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-2"
                >
                  <v-text-field
                    v-model="localData.area_atividade"
                    label="Área para Atividade (Ha)"
                    type="number"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="py-2">
        <v-card
          outlined
          elevation="3"
          class="mb-4"
        >
          <v-card-title>
            Registro
          </v-card-title>
          <v-card-text class="pb-2">
            <v-form>
              <v-row>
                <v-col
                  cols="12"
                  class="py-2"
                >
                  <v-autocomplete
                    v-model="localData.rg_armazenamento_produtos_quimicos"
                    label="Armazenamento de produtos quimicos agricolas"
                    :items="['NÃO POSSUI', 'LOCAL ESPECÍFICO', 'QUALQUER LUGAR']"
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-2"
                >
                  <v-autocomplete
                    v-model="localData.rg_controle_pragas"
                    label="Controle de pragas"
                    hide-details
                    :items="['SEMPRE', 'AS VEZES', 'NUNCA', 'QUANDO NECESSÁRIO']"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-2"
                >
                  <v-autocomplete
                    v-model="localData.rg_treinamento_funcionarios"
                    label="Treinamento ao funcionário"
                    hide-details
                    :items="['SEMPRE', 'AS VEZES', 'NUNCA', 'QUANDO NECESSÁRIO']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  filters: {
    dateFormat: (value, format) => (!value ? "-" : moment(value).format(format)),
  },
  props: {
    diagnostic: {
      type: Object,
    },
  },
  data() {
    return {
      technicals: {
        list: [],
        selected: [],
      },

      inputAt: {
        picker: false,
        date: null,
      },
    };
  },
  computed: {
    localData: {
      get() {
        return this.diagnostic;
      },
      set(newValue) {
        return this.$emit("update:diagnostic", {
          ...newValue,
        });
      },
    },
  },
  watch: {
    inputAt: {
      deep: true,
      handler: function() {
        this.localData.entrada_laticinio = moment(this.inputAt.date, "YYYY-MM-DD").format("DD/MM/YYYY");
      },
    },
    technicals: {
      deep: true,
      handler: function() {
        this.localData.tecnicos_responsaveis_json = JSON.stringify(this.technicals.selected);
      },
    },
  },
  async mounted() {
    this.loadtechnicals();
    this.initStep();
  },
  methods: {
    initStep() {
      this.inputAt.date = moment(this.diagnostic.entrada_laticinio, "DD/MM/YYYY").format("YYYY-MM-DD");
      this.technicals.selected = JSON.parse(this.diagnostic.tecnicos_responsaveis_json || "[]");
    },
    async loadtechnicals() {
      try {
        const { data } = await this.$axios.get(`/pessoa/listaTecnicos`);

        this.technicals.list = data.map(t => ({ id: t.id_pessoa, name: t.nome }));
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
