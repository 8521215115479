<template>
  <div class="mx-6 px-16">
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Médias Qualidade/Visitados
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-tabs
          v-model="aba"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#monthly">
            Média Geométrica Mensal
          </v-tab>

          <v-tab href="#quarterly">
            Média Geométrica Trimestral
          </v-tab>

          <v-tab-item value="monthly">
            <monthly-average />
          </v-tab-item>

          <v-tab-item value="quarterly">
            <quarterly-average />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MonthlyAverage from "@/Domains/Quality/Indicators/AverageOfQuality/Views/MonthlyAverage.vue";
import QuarterlyAverage from "@/Domains/Quality/Indicators/AverageOfQuality/Views/QuarterlyAverage.vue";

export default {
  components: {
    MonthlyAverage,
    QuarterlyAverage
  },

  data() {
    return {
      aba: null,
    };
  },
}
</script>
