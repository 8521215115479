<template>
  <v-row>
    <v-col
      v-for="g in goals"
      :key="g.indicator.value"
      md="12"
    >
      <v-card
        color="transparent"
        dark
      >
        <v-card-title class="text-subtitle-2">
          {{ g.indicator.text }}
          <v-spacer />
          {{ g.type }}
          <v-spacer />
          <v-btn
            icon
            color="white"
          >
            <v-icon
              @click="onGoalDelete(g.indicator)"
            >
              delete_outline
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="g.qualityPoints"
          hide-default-footer
          disable-sort
        >
          <template #[`item.class`]="{ item }">
            <v-text-field
              v-model.number="item.class"
              placeholder="A"
              type="text"
              dense
              outlined
              hide-details
            />
          </template>
          <template #[`item.start`]="{ item }">
            <v-text-field
              v-model.number="item.start"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.end`]="{ item }">
            <v-text-field
              v-model.number="item.end"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.point`]="{ item }">
            <v-text-field
              v-model.number="item.point"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>

          <template #[`item.buttons`]="{ item }">
            <v-btn
              icon
              color="white"
            >
              <v-icon
                @click="onPointsDelete(g.qualityPoints,item.index)"
              >
                delete_outline
              </v-icon>
            </v-btn>
          </template>
          <template #[`body.append`]="{}">
            <tr>
              <td
                :colspan="headers.length"
                align="left"
              >
                <v-btn
                  outlined
                  rounded
                  text
                  @click="onPointsInsert(g.qualityPoints);"
                >
                  <v-icon>
                    add
                  </v-icon>
                  Faixa
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {

  },

  props: {
    value: Array,
  },

  data() {
    return {

      periods: [
        { text: "Quinzenal", value: "quinzenal" },
        { text: "Mensal", value: "mensal" },
        { text: "Bimestral", value: "bimestral" },
        { text: "Trimestral", value: "trimestral" },
        { text: "Quinquenal", value: "quinquenal" },
        { text: "Semestral", value: "semestral" },
        { text: "Anual", value: "anual" },
      ],
      questionnaires: [],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    headers() {
      let header = [];
      header = [
        { text: "De", value: "start", width: 100  },
        { text: "Até", value: "end", width: 100 },
        { text: "Pontos", value: "point", width: 100 },
        { text: "", align: "right", value: "buttons", width: 8 },
      ];
      return header;
    },
  },

  created() {
    this.loadQuestionnaires();
  },
  methods: {
    async loadQuestionnaires() {

      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_BPA'],
            ativo: 1,
          }
        );

        this.questionnaires = data
          //.filter(item => !item.tipo.includes('VISITA_PADRAO'))
          .map(item => {
            let text = item.titulo ? item.titulo : ``;
            text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
            text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;
            return {
              id: item.id,
              title: text,
              types: item.tipo,
            }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onPointsInsert(qualityPoint) {

      let last = qualityPoint[0] ? _.last(qualityPoint).end : 0;
      let index =  qualityPoint[0] ? _.last(qualityPoint).index + 1 : 1;

      if ((last == null )) {
        this.$snotify.error("Informe o valor para o Fim da faixa anterior", "Atenção");
        return false;
      }

      qualityPoint.push({
        id: uuidv4(),
        index: index,
        start: last + 1,
        end: null,
        point: null,
      })
    },

    onPointsDelete(qualityPoint, index) {
      const idx = qualityPoint.findIndex(o => o.index === index);
      qualityPoint.splice(idx, 1);
    },

    onGoalDelete(indicator) {
      const idx = this.goals .findIndex(o => o.indicador === indicator);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
}
</script>
