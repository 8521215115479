<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col
        cols="12"
        class="text-xs-center"
      >
        <h2 class="menu-header white--text mt-0 mb-2">
          Transferências
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <v-date-range-picker
          v-model="filters.dateRange"
          @change="onFilterChange"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <person-autocomplete-filter
          v-model="filters.driver"
          label="Motorista"
          type="DRIVER"
          filled
          @change="onFilterChange"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <person-autocomplete-filter
          v-model="filters.dairyOrigins"
          label="Origem"
          type="DAIRY"
          prepend-inner-icon="factory"
          filled
          multiple
          chips
          @change="onFilterChange"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
      >
        <person-autocomplete-filter
          v-model="filters.dairyDestinations"
          label="Destino"
          type="DAIRY"
          prepend-inner-icon="factory"
          filled
          multiple
          chips
          @change="onFilterChange"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <v-text-field
          v-model="filters.search"
          hide-details
          filled
          clearable
          label="Buscar"
          prepend-inner-icon="search"
        />
      </v-col>
    </v-row>

    <v-tabs
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tabs-slider color="white" />

      <v-tab href="#reports">
        Relatório
      </v-tab>

      <v-tab href="#graphs">
        Gráfico
      </v-tab>

      <v-tab-item value="reports">
        <v-card
          dark
          class="report-card"
          color="transparent"
        >
          <data-table
            ref="report"
            dark
            :headers="headers"
            :items="filteredItems"
            show-custom-group
          />
        </v-card>
      </v-tab-item>

      <v-tab-item value="graphs">
        <v-card
          dark
          color="transparent"
        >
          <v-card-text>
            <v-chart
              :options="graph.options"
              :style="{width: '100%', height: `${graph.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import moment from 'moment';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import orderBy from 'lodash/fp/orderBy';
import groupBy from 'lodash/fp/groupBy';
import sumBy from 'lodash/sumBy';
import debounce from 'lodash/debounce';

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue';

export default {
  name: 'unit-transfers-report',

  components: {
    VDateRangePicker,
    PersonAutocompleteFilter
  },

  data() {
    return {
      filters: {
        dateRange: [
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
        ],
        search: '',
        driver: {},
        dairyOrigins: [],
        dairyDestinations: [],
      },

      headers: [
        { text: 'Itinerário', value: 'itinerary.code', align: 'start' },
        { text: 'Origem', value: 'origin.name', align: 'start' },
        { text: 'Destino', value: 'destination.name', align: 'start' },
        { text: 'Motorista', value: 'driver.name', align: 'start' },
        { text: 'Veículo', value: 'equipment.name', align: 'start' },
        { text: 'Data', value: 'itinerary.date', align: 'start', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Carregado (L)', value: 'volume.loaded', align: 'center', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
        { text: 'Descarregado (L)', value: 'volume.unloaded', align: 'center', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
        { text: 'Diferença (L)', value: 'volume.diff', align: 'center', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
      ],

      items: [],

      graphItems: [],
    };
  },
  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = (this.filters.search || '').toUpperCase().trim();

      return this.items.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return hasSearch;
      });
    },

    graph() {
      const getLabel = (length, index) => ({
        show: true,
        lineHeight: 20,
        height: 20,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: index === 0 ? 'right' : index === length - 1 ? 'left' : 'inside',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      });

      return  {
        height: 500,
        options: {
          color: ['rgba(29, 233, 182, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(38, 198, 0, 0.7)'],
          title: {
            text: 'Transferências',
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            iconStyle: {
              borderColor: '#ddd'
            },
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: { backgroundColor: '#6a7985' }
            }
          },
          grid: {
            left: '1%',
            right: '4%',
            top: '10%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.graphItems.map(({ date }) => moment(date).format('DD/MM/YYYY')),
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.8)'
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.8)'
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
          },
          series: [
            {
              name: 'Carregado',
              type: 'line',
              areaStyle: {},
              lineStyle: { width: 2 },
              showSymbol: true,
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              data: this.graphItems.map(({ loaded }, index) => ({
                value: loaded,
                label: getLabel(this.graphItems.length, index),
              }))
            },
            {
              name: 'Descarregado',
              type: 'line',
              areaStyle: {},
              lineStyle: { width: 2 },
              showSymbol: true,
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              data: this.graphItems.map(({ unloaded }, index) => ({
                value: unloaded,
                label: getLabel(this.graphItems.length, index),
              }))
            },
            {
              name: 'Diferença',
              type: 'line',
              areaStyle: {},
              lineStyle: { width: 2 },
              showSymbol: true,
              smooth: true,
              emphasis: {
                focus: 'series'
              },
              data: this.graphItems.map(({ diff }, index) => ({
                value: diff,
                label: getLabel(this.graphItems.length, index),
              }))
            }
          ]
        }
      }
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {

    onFilterChange: debounce(function() {
      this.loadReport();
    }, 1000),

    async loadReport() {
      try {
        this.$root.$progressBar.loading();
        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/platform/reports/transfers`, { params: {
          start_date: startDate,
          end_date: endDate,
          driver_id: this.filters.driver.id,
          origin_ids: this.filters.dairyOrigins.map(o => o.id),
          destination_ids: this.filters.dairyDestinations.map(o => o.id),
        } });

        this.items = data.map(row => {
          return {
            id: row.id_transferencia_unidade,
            itinerary: {
              code: row.cod_itinerario,
              date: row.data_hora_descarga,
            },
            origin: {
              id: row.id_laticinio_origem,
              name: row.nome_laticinio_origem,
            },
            destination: {
              id: row.id_laticinio_destino,
              name: row.nome_laticinio_destino,
            },
            driver: {
              id: row.id_motorista,
              name: row.nome_motorista,
            },
            equipment: {
              id: row.id_equipamento,
              name: row.placa,
            },
            volume: {
              loaded: row.volume_carregado,
              unloaded: row.volume_descarregado,
              diff: row.diferenca,
            }
          };
        });

        this.graphItems = flow(
          orderBy(['data'], ['asc']),
          groupBy('data'),
          map(items => ({
            date: items[0].data,
            loaded: sumBy(items, 'volume_carregado'),
            unloaded: sumBy(items, 'volume_descarregado'),
            diff: sumBy(items, 'diferenca') * -1,
          }))
        )(data);

      } catch (error) {
        console.warn(error);
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Transferências - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const title = this.getReportTitle();
      this.$refs.report.exportExcel(null, title);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },

}
</script>
