<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="90%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="card-dialog">
      <v-card-title>
        <span class="text-h6">Expedição</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="formRef">
          <v-row>
            <v-col
              cols="6"
              sm="4"
              md="2"
              lg
              class="py-0"
            >
              <v-text-field
                v-model="form.shipment.code"
                label="Código Carga *"
                icon="subtitles"
                filled
                dense
                :rules="[v => !!v || 'Campo Obrigatório']"
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
              lg
              class="py-0 d-flex"
            >
              <div class="is-third-switch">
                <v-switch
                  v-model="form.shipment.isThird"
                  label="Terceiro"
                  persistent-hint
                  inset
                  dense
                />
              </div>
              <vehicle-autocomplete-filter
                v-if="!form.shipment.isThird"
                v-model="form.shipment.vehicle"
                label="Veículo"
                :hide-details="false"
                class="flex-grow-1"
                dense
              />
              <v-text-field
                v-else
                v-model="form.shipment.vehicle.plate"
                prepend-inner-icon="local_shipping"
                label="Veículo"
                filled
                placeholder="Digite a placa do veículo"
                dense
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
              lg
              class="py-0"
            >
              <person-autocomplete-filter
                v-if="!form.shipment.isThird"
                v-model="form.shipment.driver"
                label="Motorista"
                type="DRIVER"
                :hide-details="false"
                dense
                class="text-truncate"
              />
              <v-text-field
                v-else
                v-model="form.shipment.driver.name"
                label="Motorista"
                prepend-inner-icon="person"
                filled
                placeholder="Digite o nome do motorista"
                dense
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
              lg
              class="py-0"
            >
              <person-autocomplete-filter
                v-if="!form.shipment.isThird"
                v-model="form.shipment.carrier"
                label="Transportadora"
                type="CARRIER"
                prepend-inner-icon="local_shipping"
                :hide-details="false"
                dense
                class="text-truncate"
              />
              <v-text-field
                v-else
                v-model="form.shipment.carrier.name"
                label="Transportadora"
                prepend-inner-icon="local_shipping"
                filled
                placeholder="Digite o nome da transportadora"
                dense
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
              lg
              class="py-0"
            >
              <masked-text-field
                v-model="form.shipment.temperature"
                label="Temperatura"
                prepend-inner-icon="thermostat"
                :mask="masks.temperature"
                unmask="typed"
                filled
                dense
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
              lg
              class="py-0"
            >
              <masked-text-field
                v-model="form.shipment.cargoSeal"
                label="Lacre da Carga"
                prepend-inner-icon="lock"
                :mask="masks.integer"
                unmask
                filled
                dense
              />
            </v-col>
            <v-col
              cols="6"
              sm="4"
              md="2"
              lg
              class="py-0"
            >
              <date-time-text-field
                v-model="form.shipment.operationTime"
                label="Data Carregamento"
                icon="access_time"
                input-format="YYYY-MM-DD HH:mm"
                manual
                filled
                dense
              />
            </v-col>
          </v-row>
          <v-divider
            v-if="form.shipment.shippings.length > 0"
            class="py-4"
          />
          <v-row
            v-if="form.shipment.shippings.length > 0"
            justify="center"
          >
            <v-col
              cols="6"
              sm="3"
              md="2"
              xl="1"
              class="py-0"
            >
              <v-select
                v-model="form.groupBy"
                :items="['PRODUTO', 'PEDIDO']"
                label="Agrupar por"
                outlined
                x-large
              />
            </v-col>
            <v-col
              v-if="form.groupBy === 'PEDIDO'"
              cols="6"
              sm="3"
              md="2"
              class="py-0"
            >
              <v-select
                v-model="form.shippingId"
                label="Expedir Pedido"
                :items="shippingOptions"
                outlined
                x-large
              />
            </v-col>
            <v-col
              cols="8"
              sm="4"
              class="py-0"
            >
              <v-text-field
                ref="codeRef"
                v-model="form.code"
                label="Produto"
                placeholder="Código Item, Código Pallet, Código Barras, SSCC"
                persistent-placeholder
                icon="access_time"
                hint="Aperte 'enter' para confirmar"
                outlined
                x-large
                @keyup.enter="addItem"
              />
            </v-col>
            <v-col
              cols="4"
              sm="2"
              lg="1"
              class="py-0"
            >
              <masked-text-field
                v-model="form.quantity"
                label="Quantidade"
                placeholder="0"
                persistent-placeholder
                :mask="masks.float"
                unmask
                outlined
                x-large
                validate-on-blur
                @keyup.enter="addItem"
              />
            </v-col>
          </v-row>
          <div class="d-flex align-center">
            <span class="flex-grow-1 text-h5 black--text">
              {{ form.groupBy === 'PRODUTO' ? 'Produtos' : 'Pedidos' }}
            </span>
            <v-btn
              v-if="form.groupBy === 'PEDIDO'"
              outlined
              color="blue"
              class="mb-1"
              @click="onAddShipping()"
            >
              <v-icon left>
                add
              </v-icon>
              Adicionar
            </v-btn>
          </div>
          <v-divider class="mb-4" />

          <v-data-table
            :headers="headers"
            :items="form.groupBy === 'PEDIDO' ? shippings : products"
            :group-by="form.groupBy === 'PEDIDO' ? 'shippingId' : undefined"
            hide-default-footer
            disable-pagination
            disable-sort
            disable-filtering
            :item-class="(item) => form.groupBy === 'PEDIDO' && !item.id ? 'd-none' : ''"
          >
            <template #[`group.header`]="{ group, headers, items }">
              <td class="text-start">
                {{ items[0].code }}
              </td>
              <td
                class="text-start"
                :colspan="headers.length - 2"
              >
                <b>{{ items[0].customer.name }}</b>
              </td>
              <td
                class="text-start"
              >
                <v-menu
                  v-if="form.groupBy === 'PEDIDO'"
                  offset-y
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="onEditShipping(group)"
                    >
                      <v-list-item-icon>
                        <v-icon>edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="items.some(p => p.shipped)"
                      @click="chargebackShipping(group)"
                    >
                      <v-list-item-icon>
                        <v-icon>settings_backup_restore</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Estornar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </template>
            <template #[`item.quantity`]="{ value, item }">
              {{ formatNumber(value) }} {{ item.item.measurement }}
            </template>
            <template #[`item.shipped`]="{ item }">
              {{ formatNumber(item.shipped + item.remaining.shipped) }} {{ item.item.measurement }}
              <v-input
                v-if="false"
                :value="item.shipped + item.remaining.shipped"
                required
                :rules="[() => !item.remaining.shipped || (item.remaining.quantity == item.remaining.shipped) || 'Obrigatório!']"
              />
            </template>
            <template #[`item.progress`]="{ item }">
              <v-progress-circular
                :rotate="-90"
                :size="24"
                :width="12"
                :value="((item.shipped + item.remaining.shipped) / item.quantity) * 100"
                :color="((item.shipped + item.remaining.shipped) / item.quantity) < 1 ? 'orange' : 'green'"
                class="ma-1"
              />
            </template>
            <template #[`item.actions`]="{ item }">
              <v-menu
                v-if="form.groupBy === 'PEDIDO' && item.shipped"
                offset-y
                transition="scale-transition"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="item.shipped"
                    @click="chargebackShippingItem(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>settings_backup_restore</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Estornar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-column">
        <v-progress-linear
          v-show="progress"
          v-model="progress"
          color="primary"
          rounded
          height="6"
          class="mb-6"
        />
        <div class="full-width d-flex flex-row">
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-actions>

      <editing-shipping-dialog
        ref="shippingDialog"
        hide-delivery-date
        @save="onShippingSave"
      />
    </v-card>

    <item-lot-dialog
      v-model="lotDialog.show"
      type="SAIDA"
      :items="lotDialog.items"
      @save="onLotSaved"
    />
  </v-dialog>
</template>

<style lang="scss">
.is-third-switch {
  margin-right: 8px;
  .v-input {
    margin-top: 0;
    .v-input__slot {
      flex-direction: column-reverse;
      align-items: center;
      > div {
        margin: 0;
      }
      .v-label {
        font-size: 13px;
      }
    }
  }
}
</style>

<script setup>
import { ref, reactive, computed, watch } from 'vue'
import cloneDeep from 'lodash/fp/cloneDeep';
import moment from 'moment'
import axios from '@/Support/Resources/axios-instance.js';
import { useUtils } from '@/Support/Composables/utils.js'
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js'
import api from '@/Domains/Shipment/Api/Shipment.js'

import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue'
import VehicleAutocompleteFilter from '@/Support/Components/Filters/VehicleAutocompleteFilter.vue'
import DateTimeTextField from '@/Support/Components/DateTimeTextField.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import ItemLotDialog from '@/Domains/Registrations/Item/Components/ItemLotDialog.vue'
import EditingShippingDialog from '@/Domains/Itineraries/Components/Form/EditingShippingDialog.vue'

import { useShipment } from '@/Domains/Shipment/Shipment/Composables/Shipment.js'

const { progressBar, notify, confirm } = useUtils()

const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
  temperature: { mask: Number, scale: 4, min: -99, max: 99 },
}

// eslint-disable-next-line no-undef
const emit = defineEmits(['save', 'close'])

const dialog = ref(false)

useDialogHistory(dialog)

const formRef = ref()

const form = reactive({
  shipment: {
    code: null,
    operationTime: null,
    temperature: null,
    cargoSeal: null,
    isThird: true,
    driver: {},
    vehicle: {},
    carrier: {},
    route: {},
    itinerary: {},
    shippings: [],
  },
  groupBy: 'PEDIDO',
  shippingId: null,
  code: '',
  quantity: 1,
})

const headers = [
  { text: 'Código', value: 'item.code', width: 50 },
  { text: 'Produto', value: 'item.description' },
  { text: 'Quantidade', value: 'quantity' },
  { text: 'Quantidade Expedida', value: 'shipped' },
  { text: '', value: 'progress', width: 60 },
  { text: '', value: 'actions', width: 30 },
]

const { products, shippingOptions, findPacking } = useShipment(form)

const shippings = computed(() => {
  if (!form.shipment?.shippings) {
    return []
  }

  return form.shipment.shippings.flatMap((shipping) => {
    // Se estiver vazio, cria um item apenas para exibir na tabela de agrupamento
    if (shipping.products.length === 0) {
      return [{
        id: null,
        shippingId: shipping.id,
        code: shipping.code,
        customer: shipping.customer,
        item: {},
        remaining: {},
      }]
    }
    return shipping.products
      .map((product) => ({
        shippingId: shipping.id,
        code: shipping.code,
        customer: shipping.customer,
        ...product,
      }))
  })
})

const progress = computed(() => {
  const total = products.value.reduce((total, product) => total + product.quantity, 0)
  const shipped = products.value.reduce((total, product) => total + (product.shipped + product.remaining.shipped), 0)

  return (shipped / total) * 100
})

const show = async (id = null) => {
  dialog.value = true
  if (id) {
    load(id)
  } else {
    form.shipment.operationTime = moment().format('YYYY-MM-DD HH:mm')
  }
}

watch(dialog, (isOpen) => {
  if (!isOpen) { emit('close') }
})

const close = () => {
  dialog.value = false
  form.shipment = {
    code: null,
    operationTime: null,
    temperature: null,
    cargoSeal: null,
    isThird: true,
    driver: {},
    carrier: {},
    vehicle: {},
    route: {},
    itinerary: {},
    shippings: [],
  }
  form.groupBy = 'PEDIDO'
  form.shippingId = null
  form.code = ''
  form.quantity = 1
}

const load = async (id) => {
  try {
    progressBar?.loading()

    form.shipment = await api.show(id)

  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao carregar'
    notify.error(message, 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const save = async () => {
  if (!await formRef.value?.validate()) {
    return
  }

  if (!form.shipment.id) {
    await store()
  } else {
    await update()
  }
}

const store = async () => {
  try {
    progressBar?.saving()

    const { shipment } = form

    const payload = {
      codigo: shipment.code,
      descricao_rota: shipment.route.description,
      id_equipamento: !shipment.isThird ? shipment.vehicle.id : null,
      equipamento: shipment.vehicle.description,
      placa: shipment.vehicle.plate,
      id_pessoa: !shipment.isThird ? shipment.driver.id : null,
      nome_pessoa: shipment.driver.name,
      id_transportadora: !shipment.isThird ? shipment.carrier.id : null,
      nome_transportadora: shipment.carrier.name,
      data_hora_descarga: shipment.operationTime,
      temperatura: shipment.temperature,
      lacre_carga: shipment.cargoSeal,
    }

    const { id } = await api.store(payload)

    notify.success('Salvo com sucesso')

    await load(id)
  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao salvar'
    notify.error(message, 'Atenção')
    progressBar?.hide()
  }
}

const update = async (showDialog = true) => {
  const itemsWithLotControl = form.shipment.shippings
    .flatMap((shipment) => shipment.products.filter(product => (product.remaining.shipped - product.remaining.quantityLot) > 0 && product.item.requiresLot));

  if (showDialog && itemsWithLotControl.length > 0) {
    showLotDialog(itemsWithLotControl);
    return;
  }

  try {
    progressBar?.saving()

    const { shipment } = form

    const payload = {
      codigo: shipment.code,
      descricao_rota: shipment.route.description,
      id_equipamento: !shipment.isThird ? shipment.vehicle.id : null,
      equipamento: shipment.vehicle.description,
      placa: shipment.vehicle.plate,
      id_pessoa: !shipment.isThird ? shipment.driver.id : null,
      nome_pessoa: shipment.driver.name,
      id_transportadora: !shipment.isThird ? shipment.carrier.id : null,
      nome_transportadora: shipment.carrier.name,
      data_hora_descarga: shipment.operationTime,
      temperatura: shipment.temperature,
      lacre_carga: shipment.cargoSeal,
      entregas: shipment.shippings.map(shipment => ({
        id: shipment.id,
        pallets: shipment.pendingPalletCodes,
        produtos: shipment.products
          .filter(p => p.remaining.shipped > 0)
          .map(p => ({
            id: p.id,
            id_item: p.item.itemId,
            quantidade: p.remaining.shipped * p.item.conversionFactor,
            lotes: p.remaining.lots,
            embalagens: p.remaining.packingCodes,
          })),
      })),
    }

    await api.update(shipment.id, payload)

    notify.success('Salvo com sucesso')
  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao salvar'
    notify.error(message, 'Atenção')
  } finally {
    progressBar?.hide()

    load(form.shipment.id)
  }
}

const shippingDialog = ref()

const onAddShipping = async () => {
  if (!form.shipment.id && (!await formRef.value?.validate())) {
    return
  }

  return shippingDialog.value.show({
    customer: {},
    dateTime: null,
  })
}

const onEditShipping = (id) => {
  const shipping = form.shipment.shippings.find(s => s.id === id)
  return shippingDialog.value.show(cloneDeep(shipping))
}

const onShippingSave = async (shipping) => {
  if (!form.shipment.id) {
    await store()
  }
  try {
    progressBar.saving()

    const payload = {
      id_itinerario: form.shipment.itinerary.id,
      data_hora: shipping.dateTime,
      codigo: shipping.code,
      id_cliente: shipping.customer.id,
      observacao: shipping.notes,
      numero_nota: shipping.invoiceNumber,
      produtos: shipping.products.map(product => ({
        id: product.id,
        id_item: product.item.itemId,
        quantidade: product.quantity,
        unidade_medida: product.item.measurement,
        id_unidade_medida: product.item.measurementId,
        unidade_descricao: product.item.measurementDescription,
        quantidade_real: product.quantity * (product.item.conversionFactor || 1),
        unidade_real: product.item.defaultMeasurement,
      }))
    }

    const { data } = !shipping.id
      ? await axios.post(`/shipping`, payload)
      : await axios.put(`/shipping/${shipping.id}`, payload)

    if (!shipping.id) {
      shipping.id = data.id
    }

    setShipping(shipping)
  } catch (e) {
    console.warn(e)
    const message = e?.response?.data?.message || 'Erro ao salvar a entrega'
    notify.error(message, 'Atenção')
  } finally {
    progressBar.hide()

    shippingDialog.value.close()
  }
}

const setShipping = (shipping) => {
  shipping = {
    pallets: [],
    palletCodes: [],
    pendingPalletCodes: [],
    ...shipping,
    products: shipping.products.map(product => {
      const quantity = parseFloat(product.quantity)
      const realQuantity = quantity * (product.item.conversionFactor || 1)

      // Recupera os dados atuais
      const shipped = product.shipped || 0
      const remaining = product.remaining || {
        lots: [],
        packingCodes: [],
        quantityLot: 0,
        quantity: 0,
        shipped: 0,
      }

      return {
        packingCodes: [],
        stocks: [],
        packings: [],
        ...product,
        remaining: {
          ...remaining,
          quantity: quantity - shipped,
        },
        quantity,
        shipped,
        realQuantity,
      }
    })
  }

  const index = form.shipment.shippings.findIndex(s => s.id === shipping.id)

  if (index > -1) {
    form.shipment.shippings.splice(index, 1, shipping)
  } else {
    form.shipment.shippings.push(shipping)
  }
}

const lotDialog = reactive({
  show: false,
  items: [],
})

const showLotDialog = (items) => {
  lotDialog.show = true;
  lotDialog.items = items.map(p => ({
    id: p.id,
    id_item: p.item.itemId,
    quantidade: (p.remaining.shipped - p.remaining.quantityLot) * p.item.conversionFactor,
  }));
}

const onLotSaved = (items) => {
  const productsById = items.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})

  for (const shipping of form.shipment.shippings) {
    for (const p of shipping.products) {
      if (productsById[p.id]) {
        p.remaining.lots.push(...productsById[p.id].lotes)
        p.remaining.quantityLot += productsById[p.id].quantidade / p.item.conversionFactor
      }
    }
  }

  update(false);
}

const codeRef = ref()

const addItem = async () => {
  let quantity = parseFloat(form.quantity) || 0
  const code = form.code?.trim()

  if (form.groupBy === 'PEDIDO' && !form.shippingId) {
    return notify.warning('Selecione o pedido', 'Atenção')
  }

  if (code && quantity) {

    // Verifica se há produtos com código ou código de barras compatíveis
    const product = products.value.find(p => p.item?.code === code || p.item?.barcode === code)

    if (product) {
      let shippings = form.shipment.shippings

      // Se houver seleção de pedido, a expedição será feita no pedido selecionado
      if (form.shippingId !== 'SEQUENCIALMENTE') {
        shippings = shippings.filter(s => s.id === form.shippingId)

        // Verifica se o pedido selecionado possui o produto a ser expedido e a quantidade disponível
        if (!shippings[0].products.some(p => p.item.id === product.item.id && (p.remaining.quantity - p.remaining.shipped) >= quantity)) {
          notify.warning('Quantidade indisponível', 'Atenção')
          return
        }
      }

      // Se não houver filtro de pedido, a expedição será feita sequencialmente
      const availableQuantityTotal = product.remaining.quantity - product.remaining.shipped

      if (quantity <= availableQuantityTotal) {
        for (const shipping of shippings) {
          for (const p of shipping.products) {
            if (p.item.id === product.item.id) {
              const availableQuantity = p.remaining.quantity - p.remaining.shipped
              const usedQuantity = Math.min(availableQuantity, quantity)
              quantity -= usedQuantity
              p.remaining.shipped += usedQuantity
            }
          }
        }
      } else {
        notify.warning('Quantidade indisponível', 'Atenção')
      }
    } else {
      // Caso contrário, verifica se o código é de um pallet ou caixa previamente cadastrado
      const result = await findPacking(code, form.shippingId)

      if (result === 'SUCCESS') {
        save()
      }
    }
  }
  form.quantity = 1
  codeRef.value?.reset()
  codeRef.value?.focus()
}

const chargebackShipping = async (id) => {
  if (!(await confirm('Estornar itens expedidos?', 'Esta ação não poderá ser desfeita.', { color: 'red' }))) {
    return
  }

  try {
    progressBar?.loading()

    await axios.put(`/shipping/${id}/chargeback`);

  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao estornar'
    notify.error(message, 'Atenção')
  } finally {
    progressBar?.hide()

    load(form.shipment.id)
  }
}

const chargebackShippingItem = async ({ id }) => {
  if (!(await confirm('Estornar itens expedidos?', 'Esta ação não poderá ser desfeita.', { color: 'red' }))) {
    return
  }

  try {
    progressBar?.loading()

    await axios.put(`/shipping/chargeback-item`, { id });

  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao estornar'
    notify.error(message, 'Atenção')
  } finally {
    progressBar?.hide()

    load(form.shipment.id)
  }
}

// eslint-disable-next-line no-undef
defineExpose({ show, close })
</script>
