<template>
  <div>
    <v-row
      class="grey lighten-5"
    >
      <v-col
        class="pt-2 pb-0 "
        :md="editable?7:8"
      >
        <v-autocomplete
          v-model="complemento.complemento"
          :items="complementList"
          hide-details
          class="pa-0"
        />
      </v-col>
      <v-col
        md="4"
        class="pt-2 pb-0 "
      >
        <masked-input
          v-model="complemento.complemento_valor_medio"
          placeholder="0"
          dense
          :mask="FloatNumberMask"
          currency
          return-unmasked
          @change="$emit('change', $event)"
        />
      </v-col>
      <v-col
        v-if="editable"
        md="1"
        class="pt-2 pb-0 "
      >
        <v-btn
          icon
          @click="openAlert(complemento), showDeleteDialog = true;"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <!-- Alert excluir linha -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false; complementoDelete = null"
      @keydown.esc="showDeleteDialog = false; complementoDelete = null"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-left text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-left black--text">
            Deseja realmente Executar a Ação ?
          </h3>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="ComplementoDelete"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as _ from "lodash";

import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  name: "NutritionComplement",
  components: {
    MaskedInput,
  },

  filters: {
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
  props: {
    complemento: {
      require: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
    
  data() {
    return {
      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: ".",
            min: -999999,
            max: 999999,
          },
        },
      },
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      showDeleteDialog: false,

      complementList: [],
      
    };
  },

  computed: {
      
  },
  mounted() {
    this.loadComplements();
  },
  methods: {

    async loadComplements() {
      try {
        const { data } = await this.$queries.post(`/rebanhoNutricao/listaComplementoJson`);

        if (!_.isObject(data)) {
          throw "PHP error: consulta complementos"
        }

        this.complementList = data.map(complement => complement.nome);
      } catch (e) {
        console.log(e);
      }
    },
    /**
         * Abre dialog de confirmações de deleção
         */
    openAlert(item) {
      this.complementoDelete = item;
      this.showDeleteDialog = true;
    },

    /**
         * @void
         */
    ComplementoDelete() {
      this.$emit("onComplementDelete", this.complementoDelete.id);
      this.showDeleteDialog = false;
    },
  },
}
</script>
