import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '@/Support/Resources/vuex.js'

/**
 * Composable para usar estado de navegação para fechar dialogs abertos
 * @param {ref<Boolean>} dialogRef
 */
export function useDialogHistory(dialogRef) {
  /**
   * Usa o store para permitir usar dialogs aninhados
   */
  const lastId = computed(() => store.state.dialogHistory.dialogs[store.state.dialogHistory.dialogs.length - 1])

  const dialogId = ref(null)

  const handlePopState = () => {
    if (lastId.value === dialogId.value && dialogRef.value) {
      dialogRef.value = false
    }
  }

  watch(dialogRef, (isOpen) => {
    if (isOpen) {
      dialogId.value = Math.random().toString(36).substring(2, 15)
      window.history.pushState({ dialogId: dialogId.value }, '')
      store.commit('dialogHistory/add', dialogId.value)
    } else {
      store.commit('dialogHistory/remove', dialogId.value)
      dialogId.value = null
    }
  })

  onMounted(() => {
    window.addEventListener('popstate', handlePopState)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('popstate', handlePopState)
  })
}
