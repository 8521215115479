<template>
  <v-autocomplete
    v-model="input"
    :items="states"
    :loading="loading"
    v-bind="$attrs"
    @input="onChange"
  />
</template>

<script>
export default {
  name: 'states-select',

  props: {
    value: String,
  },

  data() {
    return {
      loading: false,

      input: null,

      states: [],
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  created() {
    this.input = this.value;

    this.getStates();
  },

  methods: {
    async getStates() {
      try {
        this.loading = true;

        const { data } = await this.$queries.get(`/estados/listaJson`);

        this.states = data.map(o => ({
          value: o.uf,
          text: o.end_estado,
        }));

      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os estados!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onChange(value) {
      this.$emit("input", value);
      this.$emit("change");
    },
  },
};
</script>
