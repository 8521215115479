<template>
  <div class="camera-container">
    <video
      ref="video"
      class="video"
      autoplay
    />

    <div class="slot-container">
      <slot />
    </div>
    <canvas
      ref="canvas"
      class="canvas"
    />
  </div>
</template>

<script setup>
// eslint-disable-next-line
import { onMounted, onUnmounted, ref } from 'vue'

// eslint-disable-next-line
const emit = defineEmits([
  'started',
  'stopped',
  'paused',
  'resumed',
  'snapshot',
  'error',
  'flash',
])

// eslint-disable-next-line
const props = defineProps({
  resolution: {
    type: Object,
    default: () => ({
      // width: 1920,
      // height: 1080
    }),
  },
  facingMode: {
    type: String,
    default: 'environment',
  },
  fillLightMode: {
    type: String,
    default: 'off', //['auto', 'off', 'flash']
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  playsinline: {
    type: Boolean,
    default: true,
  },
})

const video = ref()
const canvas = ref()
const stream = ref()
const width = ref()
const height = ref()
const facingMode = ref()
const fillLightMode = ref()

onMounted(() => {
  screen.orientation.addEventListener('change', onOrientationChange)
  init()
})

onUnmounted(() => {
  screen.orientation.removeEventListener('change', onOrientationChange)
  stop()
})

const isCameraEnabled = () => 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices

const devices = async (kinds = ['audioinput', 'videoinput']) => {
  const devices = await navigator.mediaDevices.enumerateDevices()
  return devices.filter((device) => kinds.includes(device.kind))
}

const currentDeviceID = () => {
  if (!stream.value) {
    return
  }

  const tracks = stream.value
    .getVideoTracks()
    .map((track) => track.getSettings().deviceId)

  if (tracks.length > 0) {
    return tracks[0]
  }
}

const onOrientationChange = () => {
  stop()
  init()
}

const init = () => {
  if (!navigator.mediaDevices) {
    return
  }

  const videoElement = document.getElementById('video')

  if (props.playsinline && videoElement) {
    videoElement.setAttribute('playsinline', '')
  }

  const isLandscape = screen.orientation.type.startsWith('landscape')

  width.value = props.resolution.width || (isLandscape ? window.screen.width : window.screen.height)
  height.value = props.resolution.height || (isLandscape ? window.screen.height : window.screen.width)

  facingMode.value = props.facingMode
  fillLightMode.value = props.facingMode

  if (props.autoplay) {
    start()
  }
}

const start = async () => {
  try {
    const constraints = {
      video: {
        width: width.value,
        height: height.value,
        facingMode: facingMode.value,
        deviceId: {},
      },
      audio: false,
    }

    stream.value = await navigator.mediaDevices.getUserMedia(constraints)

    if (!video.value) {
      throw new Error('Video ref is null')
    }

    video.value.srcObject = stream.value

    emit('started')
  } catch (err) {
    emit('error', err)
  }
}

const snapshot = async() => {

  const imageBlob = await takePhoto()

  if (imageBlob) {
    emit('snapshot', imageBlob)

    return imageBlob
  }

  if (!video.value) {
    throw new Error('Video ref is null')
  }

  if (!canvas.value) {
    throw new Error('Canvas ref is null')
  }

  const width = video.value.videoWidth
  const height = video.value.videoHeight

  canvas.value.width = width
  canvas.value.height = height

  const context = canvas.value.getContext('2d')
  context?.clearRect(0, 0, width, height)
  context?.drawImage(video.value, 0, 0, width, height)

  return new Promise((resolve) => {
    canvas.value?.toBlob(
      (blob) => {
        emit('snapshot', blob)
        resolve(blob)
      },
      'image/png',
      null
    )
  })
}

const takePhoto = async () => {
  try {
    const track = stream.value.getVideoTracks()[0]

    const imageCapture = new ImageCapture(track)

    return await imageCapture.takePhoto({ fillLightMode: fillLightMode.value })
  } catch {
    return undefined
  }
}

const flipCamera = async () => {
  stop()

  if (facingMode.value === 'user') {
    facingMode.value = 'environment'
  } else {
    facingMode.value = 'user'
  }

  await start()
}

const toggleFlash = async () => {
  if (fillLightMode.value === 'auto') {
    fillLightMode.value = 'off'
  } else {
    fillLightMode.value = 'auto'
  }
  emit('flash', fillLightMode.value)
}

const resume = () => {
  video.value?.play()
  emit('resumed')
}

const pause = () => {
  video.value?.pause()
  emit('paused')
}

const stop = () => {
  stream.value?.getTracks().forEach((track) => track.stop())
  emit('stopped')
}

// eslint-disable-next-line
defineExpose({
  start,
  stop,
  video,
  snapshot,
  canvas,
  devices,
  currentDeviceID,
  pause,
  resume,
  flipCamera,
  stream,
  toggleFlash,
  isCameraEnabled,
})
</script>

<style lang="scss">
.camera-container {
  position: relative;
  width: 100%;
  height: 100%;

  .slot-container {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .video {
    width: 100%;
    height: 100%;
  }

  .canvas {
    display: none;
  }
}
</style>
