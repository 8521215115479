<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Relatório de controle de pedidos de análises
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filters.dateRange"
                  dark
                  @change="load"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-select
                  v-model="filters.products"
                  :items="products"
                  label="Produto"
                  item-value="id"
                  item-text="description"
                  multiple
                  clearable
                  hide-details
                  dark
                  filled
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            ref="report"
            name="Relatório de controle de pedidos de análises"
            :headers="headers"
            :items="filteredItems"
            dark
          >
            <template #[`item.analysis.type`]="{ value }">
              <v-chip
                small
                :color="getTypeColor(value)"
              >
                {{ getType(value) }}
              </v-chip>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';

import moment from 'moment';
import uniqBy from 'lodash/uniqBy';

export default {

  components: {
    VDateRangePicker,
  },

  data() {
    return {
      filters: {
        dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        products: [],
        type: null,
        search: '',
      },

      headers: [
        { text: 'Produto', value: 'item.description' },
        { text: 'Natureza', value: 'analysis.type', formatter: value => this.getType(value) },
        { text: 'Análise', value: 'analysis.description' },
        { text: 'Periodicidade', value: 'periodicity', formatter: value => this.getPeriodicity(value) },
        { text: 'Amostras Mês', value: 'monthlyQuantity' },
        { text: 'Amostras Ano', value: 'annualQuantity' },
        { text: 'Laboratório', value: 'laboratory.name' },
        { text: 'Quantidade Solicitada', value: 'requestedQuantity' },
      ],

      items: [],

      types: [
        { value: 'FISICO-QUMICA', text: 'Físico-química' },
        { value: 'MICROBIOLOGICA', text: 'Microbiológica' },
      ],

      products: [],
    };
  },

  computed: {
    filteredItems() {
      if (this.filters.products.length === 0 && !this.filters.search) {
        return this.items;
      }

      const products = this.filters.products;
      const search = String(this.filters.search).toUpperCase().trim();

      return this.items.filter(item => {
        const hasProducts = products.length === 0 || products.includes(item.item.id);
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasProducts && hasSearch;
      });
    },
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/analysis/order-control/report`, { params: {
          start_date: startDate,
          end_date: endDate,
        } });

        this.items = data.map(item => ({
          id: item.id_produto_analise,
          item: {
            id: item.id_item,
            name: item.nome_item,
            code: item.cod_item,
            description: item.cod_item ? `${item.cod_item} - ${item.nome_item}` : item.nome_item,
          },
          analysis: {
            id: item.id_tipo_analise,
            description: item.descricao_analise,
            type: item.natureza,
          },
          periodicity: item.periodicidade,
          monthlyQuantity: item.periodicidade === 'MENSAL' ? item.quantidade_periodo : +parseFloat(item.quantidade_periodo / 12).toFixed(1),
          annualQuantity: item.periodicidade === 'ANUAL' ? item.quantidade_periodo : item.quantidade_periodo * 12,
          laboratory: {
            id: item.id_laboratorio,
            name: item.nome_laboratorio,
          },
          requestedQuantity: item.quantidade_solicitacoes,
        }));

        this.products = uniqBy(this.items.map(data => data.item), 'id');
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
        this.$refs.tabs && this.$refs.tabs.onResize();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Relatório de controle de pedidos de análises - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs['report'].print(null, title, true);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs['report'].exportExcel(null, filename);
    },

    getType(value) {
      const types = {
        'FISICO-QUMICA': 'Físico-química',
        'MICROBIOLOGICA': 'Microbiológica',
      }
      return types[value] || '-'
    },

    getTypeColor(value) {
      const types = {
        'FISICO-QUMICA': 'orange',
        'MICROBIOLOGICA': 'yellow darken-1',
      }
      return types[value] || 'grey'
    },

    getPeriodicity(value) {
      const types = {
        'ANUAL': 'Anual',
        'MENSAL': 'Mensal',
      }
      return types[value] || '-'
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
