<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Acerto de Estoque
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="pt-0"
              >
                <warehouse-select
                  v-model="filter.warehouseId"
                  label="Depósito"
                  filled
                  hide-details
                  auto-select-default
                  @change="loadProducts"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  filled
                  single-line
                  hide-details
                  dark
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-data-table
              :headers="headers"
              :items="items"
              :search="filter.search"
              dark
              class="elevation-1 report-table"
            >
              <template #[`item.type`]="{ value }">
                {{ types[value] || '' }}
              </template>
              <template #[`item.price`]="{ value }">
                R$ {{ formatCurrency(value) }}
              </template>
              <template #[`item.quantity`]="{ value }">
                <v-chip
                  dark
                  small
                >
                  {{ value }}
                </v-chip>
              </template>
              <template #[`item.newQuantity`]="{ item }">
                <v-text-field
                  v-model="item.newQuantity"
                  label="Novo estoque"
                  type="number"
                  outlined
                  dense
                  hide-details
                  :rules="[v => !v || v >= 0 || 'Quantidade não pode ser negativa']"
                  @keypress="onlyPositiveValues"
                />
              </template>
            </v-data-table>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip
      v-if="showSaveBtn"
      left
    >
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green"
          v-on="on"
          @click="save()"
        >
          <v-icon>send</v-icon>
        </v-btn>
      </template>

      <span>Salvar em lote</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue';

export default {

  components: {
    WarehouseSelect,
  },

  data() {
    return {

      filter: {
        warehouseId: null,
        search: '',
      },

      headers: [
        { text: 'Código', value: 'code', width: 100 },
        { text: 'Nome', value: 'name' },
        { text: 'Tipo', value: 'type' },
        { text: 'Grupo', value: 'groupName' },
        { text: 'Preço', value: 'price' },
        { text: 'Estoque', value: 'quantity' },
        { text: '', value: 'newQuantity', width: 250, sortable: false },
      ],
      items: [],

      types: {
        INSUMO_AGRICOLA: 'Insumo Agrícola',
        INSUMO_PRODUCAO: 'Insumo Produção',
        PRODUTO_ACABADO: 'Produto Acabado',
      }
    };
  },

  computed: {
    showSaveBtn() {
      return this.items.some(o => !!o.newQuantity && o.newQuantity >= 0)
    }
  },

  mounted() {
    this.loadProducts();
  },

  methods: {

    async loadProducts() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/item`, { params: {
          id_deposito: this.filter.warehouseId,
          exibir_quantidade: true,
        } });

        this.items = data.map(item => {
          return {
            id: item.id_item,
            code: item.cod_item,
            name: item.nome.trim(),
            type: item.tipo,
            measurement: item.unidade,
            quantity: parseFloat(item.quantidade || '0'),
            price: parseFloat(item.valor) || null,
            groupName: item.nome_item_grupo,
            images: JSON.parse(item.imagens || '[]'),
          };
        });
      } catch (err) {
        console.warn(err)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        this.tab = 0;
        return;
      }

      const items = this.items
        .flatMap(({ id, newQuantity }) => newQuantity && newQuantity >= 0 ? [{ id_item: id, quantidade: newQuantity }] : []);

      try {
        this.$root.$progressBar.saving();

        let { data } = await this.$axios.post(`/item/salvaAcertoEstoque`, { items });

        if (!data || data.codigo != 1) {
          throw data;
        }
        this.$snotify.success("Estoque atualizado com sucesso", "Sucesso");
        this.loadProducts();
      } catch (err) {
        this.$snotify.error("Erro ao atualizar estoque", "Atenção");
        console.warn(err)
        this.$root.$progressBar.hide();
      }
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    onlyPositiveValues: (e) => [45, 46].includes(e.charCode) &&  e.preventDefault(),
  },
};
</script>
