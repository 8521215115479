<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <!-- Lista laticinios -->
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="4"
            md-4
            sm-4
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
            />
          </v-col>
        </v-card-title>
        <v-data-table
          dark
          :headers="headersLaticinio"
          :items="list"
          maxlength="19"
          class="diff-list-table"
          :search="filters.search"
          @click:row="handleClick"
        >
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="openEdit(item);">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar Módulos</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    
    <!-- Pagina de Cadastro de module -->
    <v-dialog
      width="800"
      max-width="800"
      :value="showCadastro"
      @input="showCadastro = false"
      @keydown.esc="showCadastro = false"
    >
      <v-card>
        <v-card-title>Cadastro de Módulo</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="rules.isValid"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <input
                  v-model="laticinio.id_pessoa"
                  type="hidden"
                >
                <v-text-field
                  v-model="laticinio.nome"
                  type="text"
                  label="Nome"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="moduleSelected"
                  outlined
                  chips
                  small-chips
                  multiple
                  hide-details
                  label="Modulos"
                  :items="listModule"
                  return-object
                  item-text="descricao"
                  item-value="id_modulo"
                >
                  <template #selection="data">
                    <v-chip
                      small
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      color="primary"
                      @click="data.select"
                      @click:close="removeChip(data.item)"
                    >
                      <v-icon
                        left
                        small
                      >
                        person
                      </v-icon>
                      {{ data.item.descricao }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="black--text"
            text
            @click="showCadastro = false;"
          >
            cancelar
          </v-btn>
          <v-btn
            class="blue--text"
            text
            @click="
              onLaticinioSave(laticinio);"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import IMask from "imask";
import _ from "lodash";

export default {
  name: "Laticinio",

  components: {
  },

  filters: {
    //Filtro usado em todas as mascaras da view
    Mask(value, mask) {
      if (_.isEmpty(value)) {
        return value;
      }
      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  },

  data() {
    return {
      filters: {
        search: "",
      },
      loading: false,

      showCadastro: false,

      showDeleteDialog: false,

      cadastroSelecionado: {},
            
      rules: {
        isValid: false,
        nome_required: [v => !!v || "Campo obrigatorio"],
        codigo_required: [v => !!v || "Campo obrigatorio"],
      },
      laticinio: {
        id_pessoa: "",
        nome: "",
      },

      headersLaticinio: [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "System",
          value: "system",
        },
        {   value: "actions",
          sortable: false,
          align: "end",
          width: 20,
        },
      ],

      list: [],

      listModule: [],

      moduleSelected: [],
          
    };
  },

  mounted() {
    this.loadModules();
    this.loadLaticinio();
  },

  methods: {

    async openEdit(laticinio) {
      this.showCadastro = true;
      this.laticinio = laticinio;

      try {
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/listaModuloPorLaticinio`,
          this.$qs.stringify({
            id_laticinio: laticinio.id_pessoa,
          })
        );
                
        this.moduleSelected = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async onLaticinioSave(laticinio) {
          
      try {
        this.showCadastro = false;
        this.loading = true;

        const request = {
          id_laticinio: laticinio.id_pessoa,
          modulos: JSON.stringify(this.moduleSelected),
        };

        const { data } = await this.$axios.post(
          `/configuracaoMilkup/salvaLaticinioModulo`,
          this.$qs.stringify({
            ...request,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }
            
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a analise!", "Atenção");
      } finally {
        await this.loadLaticinio();
        this.loading = false;

        //this.$refs.dialog.close();
      }
    },
       
    async loadModules() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaModulo`);
                
        this.listModule = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadLaticinio() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaLaticinio`);
                
        this.list = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    //remove chip do autocomplete
    removeChip(module) {
          
      this.moduleSelected = _.remove(this.moduleSelected, (data) => {
        return data.id_modulo !== module.id_modulo;
      });
         
    },

    /**
         * Trata evento de clique na linha..
         */
    handleClick(item) {
      this.openEdit(item);
    }
        
  },
}
</script>

