<template>
  <v-container class="pr-0 pl-0 pb-0">
    <v-card
      class="question-card pa-0"
      elevation="4"
      dark
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-container class="pt-0 pb-0">
        <v-row align="center">
          <v-col md="1">
            <v-chip
              label
              small
              color="indigo lighten-2"
              style="font-size: 0.9rem"
            >
              {{ ordem }}
            </v-chip>
          </v-col>
          <v-spacer />
          <v-col md="4">
            <v-autocomplete
              v-model="value.questionType"
              outlined
              disabled
              hide-details
              chips
              label="Tipo"
              class="type-question"
              dense
              :items="[
                { text: 'título/Grupo', value: 'SUBTITLE' },
              ]"
            >
              <template #selection="{ item }">
                <v-icon
                  v-if="item.value == 'SUBTITLE'"
                  small
                  class="pr-2"
                >
                  list_alt
                </v-icon>
                {{ item.text }}
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="12"
            class="pt-0 mt-0 mb-0"
          >
            <v-text-field
              v-model="value.label"
              label="Pergunta (Rotulo)"
              hide-details
              dense
              autofocus
              :disabled="questionnairePoints ? true : false"
              :readonly="questionnaireDisabled ? true : false"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-card-actions style="padding: 5px !important">
        <v-spacer />
        <v-divider
          vertical
          class="ml-1 mr-1"
        />
        <v-btn
          v-if="!questionnaireDisabled && !questionnairePoints"
          icon
          @click="deleteQuestionCard"
        >
          <v-icon dense>
            delete
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<style lang="scss">
.question-card {
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 8px 8px 5px #888888;

  .type-question {
    > .v-list-item {
      height: 3rem !important;
      padding-left: 3px !important;
    }
  }

  .flex-option {
    display: flex;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
</style>

<script>
import * as _ from "lodash";

export default {
  name: "subtitleCard",
  props: {
    value: {
      default: () => {
        return {
          label: "",
        };
      }
    },
    ordem: {
      default: 1,
    },
    questionnaireDisabled: {
      type: Boolean,
      default: false,
    },

    questionnairePoints: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questionType: "SUBTITLE",

      /**
       * Descrição da questão(rotulo)
       */
      labelQuestion: "",
    };
  },

  methods: {
  
    /**
     * Trata a modificação na opção, e pega as informçães alteradas
     */
    handleChanged(event) {
      _.set(this.propOption.options, `option${event.positon++}`, event);
    },

    /**
     * Evento de delete das questões
     */
    deleteQuestionCard() {
      this.$emit("onDeleteQuestion", {
        ordem: this.ordem,
        type: "SUBTITLE"
      });
    },
  },
};
</script>
