<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filter.dateRange"
                  dark
                  @change="load"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            ref="report"
            :headers="headers"
            :items="items"
            :search="filter.search"
            :loading="loading"
            dark
            @click:row="edit"
          >
            <template #[`item.receipts`]="{ value }">
              <div v-if="value.length > 0">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="showReceipts(value)"
                >
                  attach_file
                </v-icon>
              </div>
              <div v-else>
                <v-chip
                  pill
                  outlined
                  x-small
                >
                  -
                </v-chip>
              </div>
            </template>

            <template #[`item.location`]="{ value }">
              <div v-if="value">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="showLocation(value)"
                >
                  place
                </v-icon>
              </div>
              <div v-else>
                <v-chip
                  pill
                  outlined
                  x-small
                >
                  -
                </v-chip>
              </div>
            </template>

            <template #[`item.item.name`]="{ value }">
              <v-chip
                x-small
              >
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="add()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="receiptsDialog.show"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Recibos</span>
        </v-card-title>

        <v-card-text>
          <attachments :attachments="receiptsDialog.receipts" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="locationDialog.show"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Localização</span>
        </v-card-title>

        <v-card-text>
          <route-map
            v-if="locationDialog.show"
            :center="$store.state.settings.coordenadas"
            clickable
            :markers="locationDialog.marker"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <expenses-dialog
      v-model="dialog.show"
      :edit-id="dialog.id"
      @save="load"
    />
  </v-container>
</template>

<script>
import moment from 'moment-timezone';

import ExpensesDialog from '@/Domains/Financial/ControlExpenses/Components/ExpensesDialog.vue'
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import Attachments from '@/Domains/Registrations/Person/Components/AttachmentsTab.vue';
import RouteMap from '@/Domains/Routes/Components/Maps/RouteMap.vue';

export default {

  components: {
    ExpensesDialog,
    VDateRangePicker,
    Attachments,
    RouteMap,
  },

  data() {
    return {
      loading: false,

      filter: {
        search: '',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      headers: [
        { text: 'Data', value: 'date', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY')  },
        { text: 'Placa', value: 'vehicle.plate', align: 'center'  },
        { text: 'Motorista', value: 'driver.name', align: 'start'  },
        { text: 'Preço', value: 'price', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.0000' },
        { text: 'Item', value: 'item.name', align: 'center'  },
        { text: 'Recibos', value: 'receipts', align: 'center' },
        { text: 'Localização', value: 'location', align: 'center', formatter: value => value ? `${value.lat} - ${value.lng}` : '' },
        { text: 'Litros', value: 'litres', align: 'center' },
        { text: 'Km', value: 'km', align: 'center' },
        { text: 'Média Km/L', value: 'average', align: 'center' },
        { text: 'opções', value: 'action', width: 30, align: 'center' }
      ],

      items: [],

      dialog: {
        show: false,
        id: null,
      },

      receiptsDialog: {
        show: false,
        receipts: []
      },

      locationDialog: {
        show: false,
        marker: []
      }
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.dateRange;

        const { data } = await this.$axios.get(`/financial/expense`, { params: {
          date_start: startDate,
          date_end: endDate,
        } });

        this.items = data.map(item => ({
          id: item.id_despesa,
          date: item.data,
          vehicle: item.equipamento ? {
            id: item.equipamento.id_equipamento,
            plate: item.equipamento.placa,
          } : null,
          driver: item.pessoa ? {
            id: item.pessoa.id_pessoa,
            name: item.pessoa.nome,
          } : null,
          price: item.preco,
          receipts: item.recibos || [],
          location: !!item.latitude && !!item.longitude ? {
            lat: item.latitude,
            lng: item.longitude,
          } : null,
          item: item.item ? {
            id: item.item.id_item_despesa,
            name: item.item.item,
          } : null,
          litres: item.litros,
          km: item.km,
          average: parseFloat(item.km / item.litros) || ''
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    add() {
      this.dialog.id = null;
      this.dialog.show = true;
    },

    edit(item) {
      this.dialog.id = item.id;
      this.dialog.show = true;
    },

    async remove(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }

      try {
        this.loading = true;

        await this.$axios.delete(`/financial/expense/${id}`);

        this.load();
        this.$snotify.success('Item excluído com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    showReceipts(receipts) {
      this.receiptsDialog = {
        show: true,
        receipts: receipts.map((file) => ({
          tipo: file.name,
          formato: file.type,
          url: file.url,
        }))
      }
    },

    showLocation(location) {
      this.locationDialog = {
        show: true,
        marker: [{
          location,
          visible: true,
          color: '#00838F',
          draggable: false,
          icon: 'directions_car',
        }]
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filter.dateRange;
      return `Despesas - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      this.$refs.report.exportExcel(null, this.getReportTitle());
    },

    print() {
      this.$refs.report.print(null, this.getReportTitle());
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  },
};
</script>
