<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Total por matérias-primas</span>
    </v-card-title>
    <v-row class="flex-grow-1">
      <template
        v-for="dairy of dairies"
      >
        <v-col
          :key="`chart-${dairy.name}`"
        >
          <div class="d-flex flex-column mx-4">
            <span class="subtitle font-weight-light">
              {{ dairy.name }}
            </span>
          </div>
          <v-chart
            :options="getOptions(dairy.data)"
            :style="{width: '100%', height: '100% !important'}"
            autoresize
          />
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment/moment';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '250px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      dairies: [],
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.get(`/platform/reports/raw-material-total`, { params: {
          date_start: startDate,
          date_end: endDate,
        } });

        this.dairies = Object.keys(data).map(dairy => ({
          name: dairy,
          data: Object.keys(data[dairy]).flatMap(rawMaterial => {
            const items = data[dairy][rawMaterial];
            return Object.keys(items).map(type => ({ name: `${rawMaterial} - ${type}`, value: items[type] }));
          }),
        }));

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de total por matérias-primas!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    getOptions(data) {
      return {
        tooltip: {
          trigger: 'item',
          formatter: (param) => [
            param.name,
            `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b> (${param.percent}%)`,
          ].join('<br>')
        },
        series: [{
          name: 'Total',
          type: 'pie',
          radius: '60%',
          top: -50,
          roseType: 'area',
          itemStyle: {
            borderRadius: 5
          },
          label: {
            color: '#fff',
            textBorderWidth: 0,
            textBorderColor: 'transparent',
            textShadowBlur: 0
          },
          data
        }]
      }
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
