<template>
  <div>
    <data-table
      ref="report"
      dense
      :headers="[
        { text: 'Questão', value: 'rotulo', sortable: false, show: true, deafult: true },
        ...headers
      ]"
      hide-default-footer
      :items="questions"
      dark
      disable-pagination
    >
      <template #[`body.prepend`]>
        <tr>
          <td />
          <td />
          <td>
            <tr>
              <td
                v-for="(val, id) in valores"
                :key="id"
                class="text-left"
                style="padding:0 20px;"
              >
                {{ val }}
              </td>
            </tr>
          </td>
          <td>
            <tr>
              <td
                v-for="(val, id) in valores"
                :key="id"
                class="text-left"
                style="padding:0 20px;"
              >
                {{ val }}
              </td>
            </tr>
          </td>
        </tr>
      </template>
      <template
        #[`item.quantidade_respostas1`]="{ value }"
      >
        <tr>
          <td
            v-for="(val, id) in value"
            :key="id"
            class="text-center"
            style="padding:0 20px; "
          >
            {{ val }}
          </td>
        </tr>
      </template>
      <template
        #[`item.quantidade_respostas2`]="{ value }"
      >
        <tr>
          <td
            v-for="(val, id) in value"
            :key="id"
            class="text-center"
            style="padding:0 20px; "
          >
            {{ val }}
          </td>
        </tr>
      </template>

      <template #[`body.append`]>
        <tr>
          <td>
            <v-btn
              icon
              @click="print"
            >
              <v-icon> print</v-icon>
            </v-btn>
          </td>
          <td class="text-start">
            Total
          </td>
          <td>
            <tr>
              <td
                v-for="(val, id) in valores"
                :key="id"
                class="text-left"
                style="padding:0 20px; "
              >
                {{ pecentByAnswers(val) }}%
              </td>
            </tr>
          </td>
          <td>
            <tr>
              <td
                v-for="(val, id) in valores"
                :key="id"
                class="text-left"
                style="padding:0 20px; "
              >
                {{ pecentByAnswers2(val) }}%
              </td>
            </tr>
          </td>
        </tr>
      </template>
    </data-table>
    <!-- <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial> -->
  </div>
</template>

<script>
import * as _ from "lodash";
import XLSX from "xlsx-js-style";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {

  mixins: [ReportMixin],

  props: {
    questions: {
      default: () => [],
    },

  },

  data() {
    return {
      headers: [],

      valores: [],
    };
  },
  computed: {

  },

  watch: {
    questions() {
      this.mountedHeader();
    }

  },
  mounted() {
    this.mountedHeader();
  },
  methods: {

    /**
     * Monta o header da table
     * @returns {Array}
     */
    mountedHeader() {
      if (_.isEmpty(this.questions)) {
        return [{}];
      }

      this.valores = this.questions[0].valores;

      let headers =  [
        {
          text: this.questions[0].periodo1,
          value: `quantidade_respostas1`,
          width: 150,
          sortable: true,
          show: true,
          default: true,
        },
        {
          text: this.questions[0].periodo2,
          value: `quantidade_respostas2`,
          width: 150,
          sortable: true,
          show: true,
          default: true,
        },
      ];

      this.headers = headers;

      // console.log(this.headers);
    },

    pecentByAnswers( alternative) {
      const totalProdutor = _.head(this.questions).total_produtores1;

      const total = _.sumBy(this.questions, `quantidade_respostas1.${alternative}`);

      const percent = (total * 100) / (totalProdutor * this.questions.length);

      return this.formatNumber(percent || 0);
    },

    pecentByAnswers2( alternative) {
      const totalProdutor = _.head(this.questions).total_produtores2;

      const total = _.sumBy(this.questions, `quantidade_respostas2.${alternative}`);

      const percent = (total * 100) / (totalProdutor * this.questions.length);

      return this.formatNumber(percent || 0);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),

    getReportTitle() {
      return `Checklists produtor `;
    },

    getReportJson() {

      let questoes = this.questions.map(o => {
        let initial = {};
        initial = this.headers.reduce((acc, header) => {
          let obj_valores =  _.get(o, header.value, '');

          const res = Object.entries(obj_valores)
            .map(([key, value]) => value ? [`<b>${key}:</b> ${value}`] : [])
            .join('\n');

          return {
            ...acc,
            [header.text]: res,
          };
        }, initial);
        return {
          'Questão': o.rotulo,
          ...initial,
        }

      });
      // console.log('questoes',questoes);
      return questoes;
    },

    async print() {
      await this.printFromJson(this.getReportJson(), this.getReportTitle());
    },

    exportExcel() {

      let codigo_laticinio = '';

      let data = this.questions.map((item) => {

        codigo_laticinio = item.answered_questions[0].codigo_laticinio;

        const dates = item.answered_questions.reduce((acc, current) => {
          return { ...acc, [`${current.data_hora_registro}`]: `${current.resposta}` };
        }, {});

        return {
          Questão: item.label,
          ...dates,
        };
      });

      data = XLSX.utils.json_to_sheet(data);

      const workbook = XLSX.utils.book_new();

      const filename = `respostas_produtor_${codigo_laticinio}`;

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
  },
};
</script>
