<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            Configuração de tributos
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="items"
            dark
            class="elevation-1 report-table"
          >
            <template #[`item.date`]="{ value }">
              {{ formatDate(value, 'MM/YYYY') }}
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="onEdit(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="remove(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            {{ form.id ? 'Editar' : 'Nova' }} configuração
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="save"
            >
              <v-row>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-select
                    v-model="form.type"
                    label="Tipo"
                    :items="['PROPRIO', 'SPOT']"
                    unmask
                    prepend-inner-icon="local_shipping"
                    @change="form.company = {}"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <v-menu
                    ref="menu"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        :value="form.date ? formatDate(form.date, 'MM/YYYY') : null"
                        label="Vigência"
                        prepend-inner-icon="event"
                        hide-details
                        readonly
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="form.date"
                      type="month"
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <dairy-autocomplete-filter
                    v-if="form.type === 'PROPRIO'"
                    v-model="form.company"
                    label="Unidade *"
                    prepend-inner-icon="factory"
                    :filled="false"
                    :hide-details="false"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                  <company-autocomplete-filter
                    v-else
                    v-model="form.company"
                    label="Empresa *"
                    prepend-inner-icon="business"
                    :filled="false"
                    :hide-details="false"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="form.icms"
                    label="ICMS"
                    :mask="masks.percentual"
                    unmask
                    prepend-inner-icon="percent"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model="form.pis_cofins"
                    label="PIS/COFINS"
                    :mask="masks.percentual"
                    unmask
                    prepend-inner-icon="percent"
                  />
                </v-col>
                <template v-if="form.type === 'PROPRIO'">
                  <v-col
                    cols="6"
                    class="pb-0"
                  >
                    <masked-text-field
                      v-model="form.icms_frete"
                      label="ICMS Frete"
                      :mask="masks.percentual"
                      unmask
                      prepend-inner-icon="percent"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    class="pb-0"
                  >
                    <masked-text-field
                      v-model="form.pis_cofins_frete"
                      label="PIS/COFINS Frete"
                      :mask="masks.percentual"
                      unmask
                      prepend-inner-icon="percent"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            outlined
            color="secondary"
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            outlined
            color="primary"
            :loading="saving"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-5"
          v-on="on"
          @click="addNew()"
        >
          <v-icon>
            add
          </v-icon>
        </v-btn>
      </template>
      Adicionar
    </v-tooltip>
  </v-container>
</template>

<script>
import DairyAutocompleteFilter from '@/Domains/Itineraries/Components/DairyAutocompleteFilter.vue';
import CompanyAutocompleteFilter from '@/Domains/Itineraries/Components/CompanyAutocompleteFilter.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

import moment from 'moment';

export default {
  components: {
    DairyAutocompleteFilter,
    CompanyAutocompleteFilter,
    MaskedTextField,
  },

  data() {
    return {
      headers: [
        { text: 'Vigência', value: 'date' },
        { text: 'Tipo', value: 'type' },
        { text: 'Unidade/Empresa', value: 'company.name' },
        { text: '', value: 'action', width: 30 },
      ],

      items: [],

      dialog: false,
      saving: false,

      form: {
        id: null,
      },

      masks: {
        percentual: { mask: Number, min: 0, max: 100, scale: 4 },
      },
    };
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      try {
        const { data } = await this.$axios.get(`/settings/milk-cost-taxes`);

        this.items = data.map(item => ({
          id: item.id,
          type: item.tipo,
          date: moment(item.data).format('YYYY-MM'),
          company: {
            id: item.id_empresa,
            name: item.nome_empresa,
          },
          icms: item.icms,
          pis_cofins: item.pis_cofins,
          icms_frete: item.icms_frete,
          pis_cofins_frete: item.pis_cofins_frete,
        }));
      }
      catch (err) {
        console.warn(err)
      }
    },

    onEdit(item) {
      this.dialog = true;

      this.form = { ...item };
    },

    addNew() {
      this.dialog = true;

      this.form = {
        id: null,
        type: 'PROPRIO',
        date: moment().format('YYYY-MM'),
        company: {},
      };
    },

    async save() {
      if (this.saving) {
        return;
      }
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.saving = true;

        const payload = {
          tipo: this.form.type,
          data: `${this.form.date}-01`,
          id_empresa: this.form.company.id,
          nome_empresa: this.form.company.name,
          icms: parseFloat(this.form.icms) || null,
          pis_cofins: parseFloat(this.form.pis_cofins) || null,
          icms_frete: parseFloat(this.form.icms_frete) || null,
          pis_cofins_frete: parseFloat(this.form.pis_cofins_frete) || null,
        };

        this.form.id ? await this.update(this.form.id, payload) : await this.store(payload);

        this.close();
        this.load();
        this.$snotify.success('Configuração salva com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error('Erro ao salvar configuração', 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/settings/milk-cost-taxes`, payload);
      return data;
    },

    async update(id, payload) {
      const { data } = await this.$axios.put(`/settings/milk-cost-taxes/${id}`, payload);
      return data;
    },

    async remove(id) {
      try {
        if (!(await this.$root.$confirm('Excluir configuração?', 'Esta ação não poderá ser revertida', { color: 'red' }))) {
          return;
        }

        await this.$axios.delete(`/settings/milk-cost-taxes/${id}`);

        this.load();
        this.$snotify.success('Configuração excluída', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      }
    },

    close() {
      this.dialog = false;
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
};
</script>
