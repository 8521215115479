<template>
  <v-dialog
    v-model="show"
    width="650"
    persistent
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Despesas
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-0">
        <v-stepper
          v-model="step"
          alt-labels
          class="elevation-0"
        >
          <v-stepper-header>
            <v-stepper-step
              step="1"
              :complete="step > 1"
            >
              Dados
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              step="2"
            >
              Recibos
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card-text>
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <masked-text-field
                        v-model="form.date"
                        label="Data"
                        prepend-inner-icon="access_time"
                        placeholder="00/00/0000"
                        :mask="{ mask: '00/00/0000' }"
                        :rules="[rules.required, rules.date]"
                        clearable
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <person-autocomplete-filter
                        v-model="form.person"
                        label="Motorista"
                        type="DRIVER"
                        :filled="false"
                        :hide-details="false"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <vehicle-autocomplete-filter
                        v-model="form.vehicle"
                        label="Veículo"
                        :filled="false"
                        :hide-details="false"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-select
                        v-model="form.item"
                        :items="items"
                        label="Item"
                        item-text="description"
                        prepend-inner-icon="reorder"
                        item-value="id"
                        return-object
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                    <v-text-field
                      v-if="form.item && form.item.description == 'COMBUSTÍVEL'"
                      v-model="form.litres"
                      label="Litros"
                      prepend-inner-icon="local_gas_station"
                      type="number"
                      :rules="[rules.required]"
                    />
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                    <v-text-field
                      v-if="form.item && form.item.description == 'COMBUSTÍVEL'"
                      v-model="form.km"
                      label="Km"
                      prepend-inner-icon="map"
                      type="number"
                      :rules="[rules.required]"
                    />
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <money-input
                        v-model="form.price"
                        label="Valor total"
                        prefix="R$"
                        :rules="[() => !!form.price && form.price > 0 || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-textarea
                        v-model="form.obs"
                        label="Observação"
                        placeholder=" "
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row>
                <v-col
                  v-for="(image, idx) in form.receipts"
                  :key="idx"
                >
                  <file-viewer
                    :value="image"
                    @removeFile="onRemoveImage(idx)"
                  />
                </v-col>
                <v-col
                  class="py-0 text-center align-self-center flex-column justify-center"
                >
                  <v-btn
                    outlined
                    class="mb-5"
                    @click="showFileUpload = !showFileUpload"
                  >
                    <v-icon>add_a_photo</v-icon>
                    <span class="ml-2">Adicionar</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="step == 1"
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="step = step - 1"
        >
          <v-icon
            left
            dark
          >
            chevron_left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="step == 1"
          color="green"
          outlined
          @click="nextStep()"
        >
          Continuar
          <v-icon
            right
            dark
          >
            chevron_right
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>

    <file-upload
      v-model="showFileUpload"
      :multiselect="false"
      @insertFiles="onSelectImages"
    />
  </v-dialog>
</template>

<script>

import VehicleAutocompleteFilter from '@/Support/Components/Filters/VehicleAutocompleteFilter.vue';
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue';
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from '@/Support/Components/MoneyInput.vue';
import FileUpload from '@/Support/Components/FileUpload.vue';
import FileViewer from '@/Support/Components/FileViewer.vue';

import moment from 'moment';

export default {

  components: {
    VehicleAutocompleteFilter,
    PersonAutocompleteFilter,
    MaskedTextField,
    MoneyInput,
    FileUpload,
    FileViewer,
  },

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      showFileUpload: false,
      step: 1,
      items: [],
      form: {
        receipts: []
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        date: v => this.isDateValid(v, 'DD/MM/YYYY') || 'Data Inválida!',
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.step = 1
        this.form = {
          receipts: []
        }

        if (this.editId) {
          this.load();
        }
      }
    }
  },

  mounted() {
    this.loadExpensesItems()
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/financial/expense/${ this.editId }`);

        this.form = {
          person: data.pessoa ? {
            id: data.pessoa.id_pessoa,
            name: data.pessoa.nome,
          } : null,
          vehicle: data.equipamento ? {
            id: data.equipamento.id_equipamento,
            description: data.equipamento.placa,
          } : null,
          item: data.item ? {
            id: data.item.id_item_despesa,
            description: data.item.item,
          } : null,
          date: data.data ? moment(data.data).format('DD/MM/YYYY') : '',
          price: parseFloat(data.preco) || null,
          receipts: data.recibos || [],
          obs: data.observacao,
          litres: data.litros,
          km: data.km
        }
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async loadExpensesItems() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/financial/expense/item`);

        this.items = data.map(item => ({
          id: item.id_item_despesa,
          description: item.item,
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        this.saving = true;

        const payload = {
          item: this.form.item.description,
          id_pessoa: this.form.person.id,
          data: this.form.date ? moment(this.form.date, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
          id_equipamento: this.form.vehicle ? this.form.vehicle.id : null,
          id_item_despesa: this.form.item.id,
          preco: parseFloat(this.form.price),
          recibos: this.form.receipts,
          observacao: this.form.obs,
          litros: parseFloat(this.form.litres),
          km: parseFloat(this.form.km)
        };

        const data = this.editId ? await this.update(payload) : await this.store(payload);

        this.close();
        this.$emit('save', data.id);
        this.$snotify.success('Despesa salva com sucesso!', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/financial/expense`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/financial/expense/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    },

    async nextStep() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      this.step++;
    },

    onSelectImages(images) {
      for (let image of images) {
        if (!this.form.receipts.find(o => o.name == image.name)) {
          this.form.receipts.push(image);
        }
      }
    },

    async onRemoveImage(idx) {
      try {
        const fileUrl = this.form.receipts[idx].url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('despesas')) {
          if (!(await this.$root.$confirm('Remover recibo', 'Tem certeza que deseja excluir este recibo?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.receipts.splice(idx, 1);
          await this.save();
        }
        else {
          this.form.receipts.splice(idx, 1);
        }
      } catch (err) {
        console.warn(err);
      }
    },

    isDateValid(value, format) {
      if (!value || value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },
  }
}
</script>
