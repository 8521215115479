<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Despesas por Item
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filter.dateRange"
                  dark
                  @change="load"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter.search"
            :loading="loading"
            dark
          >
            <template
              v-for="driver in drivers"
              #[`item.${driver}`]="{ value, item }"
            >
              <v-tooltip v-if="item.item == 'COMBUSTÍVEL'" :key="driver" left>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ formatCurrency(value.preco) }}
                  </span>
                </template>
                <p v-if="item.item == 'COMBUSTÍVEL'">
                  Litros: {{ value.km }}<br>
                  Km: {{ value.litros }}<br>
                  Média Km/L: {{ value.media }}
                </p>
              </v-tooltip>
              <span v-else>
                {{ formatCurrency(value.preco) }}
              </span>
            </template>

            <template #[`body.append`]="{ headers }">
              <tr class="tr-tot-day">
                <td
                  v-for="(header,index) in headers"
                  :key="index"
                >
                  <div
                    v-if="header.value ==='item'"
                    class="text-left"
                  >
                    <b>TOTAL</b>
                  </div>
                  <div
                    v-else
                    class="text-center"
                  >
                    <v-chip
                      small
                      color="blue darken-4"
                    >
                      {{ formatCurrency(totalsByDriver[header.value]) }}
                    </v-chip>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'
import map from 'lodash/map'
import get from 'lodash/get'

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {

  components: {
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        search: '',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      headers1: [
        { text: 'Data', value: 'date', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY')  },
        { text: 'Placa', value: 'vehicle.plate', align: 'center'  },
        { text: 'Motorista', value: 'driver.name', align: 'start'  },
        { text: 'Preço', value: 'price', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.0000' },
        { text: 'Item', value: 'item.name', align: 'center'  },
        { text: 'Recibos', value: 'receipts', align: 'center' },
        { text: 'Localização', value: 'location', align: 'center', formatter: value => value ? `${value.lat} - ${value.lng}` : '' },
        { text: 'opções', value: 'action', width: 30, align: 'center' }
      ],

      items: [],

      drivers: [],
    };
  },

  computed: {

    headers() {
      const headers = [{ text: 'Item', value: 'item', align: 'start'  }];

      headers.push(...this.drivers.map(driver => ({ text: driver, value: driver, align: 'center' })));

      return headers;
    },

    totalsByDriver() {
      return this.drivers.reduce((acc, driver) => ({
        ...acc,
        [driver]: sumBy(this.items.map(item => item[driver].preco), preco => parseFloat(preco))
      }), {});
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.dateRange;

        const { data } = await this.$axios.get(`/financial/expense`, { params: {
          date_start: startDate,
          date_end: endDate,
        } });

        this.drivers = Object.keys(groupBy(data, 'pessoa.nome'))

        this.items = map(groupBy(data, 'item.item'), (items, item) => {
          const res = { item }

          for (let driver of this.drivers) {
            const data = items.find(o => o.pessoa && o.pessoa.nome === driver) || {};
            res[driver] = { preco: parseFloat(data.preco || 0), litros: (data.litros || 0), km: (data.km || 0), media: ((data.km/data.litros) || 0) }
          }

          return res;
        });
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filter.dateRange;
      return `Despesas - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    getReportJson(isExcel = false) {
      const report = this.items.map(item => {
        const res = { 'Item': item.item };

        for (let driver of this.drivers) {
          const value = get(item, driver, 0);
          res[driver] = !isExcel ? this.formatCurrency(value.preco) : value.preco;
        }

        return res;
      });

      const totals = { 'Item': 'Total' };
      for (let driver of this.drivers) {
        const value = this.totalsByDriver[driver];
        totals[driver] = !isExcel ? this.formatCurrency(value) : value;
      }
      report.push(totals);

      return report;
    },

    exportExcel() {
      this.exportToFile({ report: this.getReportJson(true), title: this.getReportTitle() });
    },

    print() {
      this.printFromJson(this.getReportJson(), this.getReportTitle(), true);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  },
};
</script>
