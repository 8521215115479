var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","width":"500","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Numeração da NF-e "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":"","depressed":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" close ")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
                { text: 'Produção', value: 1 },
                { text: 'Homologação', value: 2 },
              ],"label":"Ambiente *","disabled":!!_vm.form.id,"rules":[v => !!v || 'Campo obrigatório!']},model:{value:(_vm.form.ambiente),callback:function ($$v) {_vm.$set(_vm.form, "ambiente", $$v)},expression:"form.ambiente"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('masked-text-field',{attrs:{"label":"Série *","placeholder":_vm.isNfpe ? '920-969' : '1',"mask":_vm.isNfpe ? _vm.masks.nfpeSeries : _vm.masks.integer,"unmask":"","prepend-inner-icon":"tag","disabled":!!_vm.form.id,"rules":[
                v => !!v || 'Campo obrigatório!',
                v => _vm.isNfpe ? (v >= 920 && v <= 969) || 'Série deve estar entre 920 e 969' : true,
              ]},model:{value:(_vm.form.serie),callback:function ($$v) {_vm.$set(_vm.form, "serie", $$v)},expression:"form.serie"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('masked-text-field',{attrs:{"label":"Próximo número da Nota *","placeholder":"1","mask":_vm.masks.integer,"unmask":"","prepend-inner-icon":"tag","rules":[v => !!v || 'Campo obrigatório!']},model:{value:(_vm.form.prox_numero),callback:function ($$v) {_vm.$set(_vm.form, "prox_numero", $$v)},expression:"form.prox_numero"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"black--text",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" cancelar ")]),_c('v-spacer'),_c('v-btn',{staticClass:"blue--text",attrs:{"outlined":"","loading":_vm.saving},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading || _vm.saving,"absolute":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loading ? 'Carregando...' : 'Salvando...')+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }