<template>
  <v-dialog
    v-model="show"
    scrollable
    width="800"
    persistent
  >
    <v-card>
      <v-card-title>
        Exceções do ICMS
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <exceptions-form
            :data.sync="form"
          />

          <span class="text-h6 d-block mt-4 black--text">Imposto</span>

          <v-divider class="mb-4" />

          <icms-form
            :icms.sync="form.icms"
            :icms-uf-dest.sync="form.icms_uf_dest"
            :tax-regime="taxRegime"
            :final-consumer="finalConsumer"
            :in-out="inOut"
          />
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ExceptionsForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/ExceptionsForm.vue';
import IcmsForm from '@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/IcmsForm.vue';

import { icms, icms_uf_dest } from '@/Domains/Settings/Views/Fiscal/Components/Operation/Services/FiscalOperationDefault.js'

export default {

  components: {
    ExceptionsForm,
    IcmsForm,
  },

  props: {
    value: Boolean,
    data: Object,
    taxRegime: {
      type: String,
      default: 'NORMAL',
      validator: (value) => ['NORMAL', 'SIMPLES'].includes(value)
    },
    inOut: {
      type: String,
      default: 'SAIDA',
      validator: (value) => ['ENTRADA', 'SAIDA'].includes(value)
    },
    finalConsumer: Boolean,
  },

  data() {
    return {
      form: {
        estados: [],
        produtos: [],
        ncms: [],

        icms: {},
        icms_uf_dest: {},
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.reset();

        this.form = {
          ...this.form,
          ...this.data
        };
      }
    }
  },

  methods: {

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$emit('save', this.form);
        this.show = false;
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      }
    },

    cancel() {
      this.show = false;
    },

    reset() {
      this.$refs.form && this.$refs.form.resetValidation();
      this.form = {
        estados: [],
        produtos: [],
        ncms: [],

        icms: {
          ...icms,
          cfop: null,
        },
        icms_uf_dest: { ...icms_uf_dest },
      };
    },
  }
}
</script>
