
import XLSX from "xlsx-js-style";
import printJS from 'print-js';
import _, { isNull } from "lodash";
import moment from "moment";
import { groupItems } from 'vuetify/lib/util/helpers'

const BORDER_ALL = {
  border: {
    top: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } }
  }
};
const HEADER_DEFAULT = { font: { bold: true }, ...BORDER_ALL };

export default {
  data: () => ({
    XLSX,
    printJS,
  }),

  methods: {

    /**
     * Cria uma tabela em HTML a partir de um JSON
     */
    htmlTableFromJson(json, title = null, config = []) {

      if (json.length === 0) {
        return '';
      }

      const properties = Object.keys(json[0]);

      /**
       * Cria o cabeçalho
       */
      const header = properties
        .map((property, index) => {
          return `<td ${ config[index] || '' }><b>${ property }</b></td>`;
        })
        .join('');

      /**
       * Cria as linhas
       */
      const lines = json.map(line => {
        return properties
          .map(property => `<td class="td-content">${ line[property] || '' }</td>`)
          .join('');
      });

      /**
       * Monta o cabeçalho e as linhas
       */
      const content = [header, ...lines]
        .map(property => `<tr>${ property }</tr>`)
        .join('');

      let html = '';

      if (title) {
        html += `
          <table class="table table-condensed">
            <tbody>
              <tr><td><center><h5>${ title }</h5></center></td></tr>
            </tbody>
          </table>`;
      }

      html += `
      <table class="table table-condensed">
        <tbody>
          ${ content }
        </tbody>
      </table>`;

      return html;
    },

    /**
     * Imprime o relatório com as três tabelas
     */
    async printFromJson(json, title, landscape = false) {
      if (json.length === 0) {
        return '';
      }

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      let body = [{
        tag: 'section',
        class: 'row',
        children: [{
          tag: 'datatable',
          headers: Object.keys(json[0])
            .map(header => ({
              key: header,
              label: header,
              class: 'text-left'
            })),
          items: json
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }], landscape });
    },

    async printPdf(payload) {
      try {
        this.$root.$progressBar.loading()

        let { data } = await this.$axios.post(
          `/relatorios/impressao`,
          payload
        );

        if (_.isObject(data) && !data.codigo) {
          throw new Error(data.mensagem);
        }

        return this.printJS({
          printable: data,
          type: 'pdf',
          base64: true
        });

      } catch (error) {
        this.$snotify.error(`Erro ao imprimir: ${error}`, "Atenção");
        console.error(error);
      } finally {
        this.$root.$progressBar.hide()
      }
    },

    exportToFile({ report, headers = null, title = 'Relatório', format = 'xlsx', skipHeader = false, styles = null,  groupBy = null, sheetName = null }) {
      if (!['txt', 'csv', 'xlsx', 'html'].includes(format)) {
        throw new Error('Invalid file format');
      }

      if (headers) {
        report = report
          .map(item => headers.reduce((acc, header) => ({
            ...acc,
            [header.text]: ('formatter' in header) && !('mask' in header)
              ? header.formatter(_.get(item, header.value, ''))
              : _.get(item, header.value, ''),
          }), {}))
      }

      let worksheet = this.XLSX.utils.json_to_sheet([]);

      if (groupBy) {
        worksheet["!merges"] = [];
        const totalCols = (Object.keys(report[0] || {})).length - 1;

        if (headers) {
          headers = headers.filter(header => header.text !== groupBy)
        }

        let currentRow = 0;
        groupItems(report, [groupBy])
          .forEach(({ items, name }) => {
            // Delete Group Title from items
            items.forEach((item) => {
              delete item[groupBy];
            });

            // Group Title
            const addr = this.XLSX.utils.encode_cell({ r: currentRow, c: 0 });
            worksheet[addr] = { v: name };
            worksheet["!merges"].push({ s: { r: currentRow, c: 0 }, e: { r: currentRow, c: totalCols } });

            currentRow += 1;

            this.XLSX.utils.sheet_add_json(worksheet, items, { origin: currentRow });
            this.applyStyles(worksheet, items, null, false, currentRow);
            if (headers) {
              this.applyMasks(worksheet, headers, items.length, currentRow);
            }

            currentRow += items.length + 2;
          });
      }
      else {
        this.XLSX.utils.sheet_add_json(worksheet, report, { skipHeader });
        this.applyStyles(worksheet, report, styles, skipHeader);
        if (headers) {
          this.applyMasks(worksheet, headers, report.length);
        }
      }

      worksheet['!cols'] = this.getColsWidth(report, headers);

      const workbook = this.XLSX.utils.book_new();

      sheetName = isNull(sheetName) ? title : sheetName;

      this.XLSX.utils.book_append_sheet(workbook, worksheet, _.truncate(sheetName, { length: 31 }));

      if (format === 'xlsx') {
        this.XLSX.writeFile(workbook, `${title}.xlsx`);
      }
      else {
        let ws = workbook.Sheets[workbook.SheetNames[0]];
        let contents;
        if (format === 'csv') {
          contents = this.XLSX.utils.sheet_to_csv(ws, {
            dateNF: "yyyy-mm-dd",
            strip: true,
            blankrows: true,
            skipHidden: true,
            FS: ';'
          });
        }
        else if (format === 'txt') {
          contents = this.XLSX.utils.sheet_to_txt(ws, { type: "string" });
        }
        else if (format === 'html') {
          contents = this.XLSX.utils.sheet_to_html(ws, { editable: true });
        }

        let pp = document.createElement('a');
        pp.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(contents));
        pp.setAttribute('download', `${title}.${format}`);
        pp.click();
      }
    },

    calcLength(value) {
      const multiplier = 1.2;
      if (!value) {
        return 5;
      }
      if (typeof value === 'number') {
        return Math.trunc(value.toString().length * multiplier);
      }
      return Math.trunc(value.length * multiplier);
    },

    getColsWidth(json, headers) {
      let result = [];

      headers = headers ? headers.map(h => h.text) : Object.keys(json[0]);

      for (let col in headers) {
        const curLength = this.calcLength(headers[col]);
        result[col] = result[col] >= curLength ? result[col] : curLength
      }

      for (let row in json) {
        let values = Object.values(json[row]);
        for (let col in values) {
          const curLength = this.calcLength(values[col]);
          result[col] = result[col] >= curLength ? result[col] : curLength
        }
      }
      return result.map(width => ({ width }));
    },

    applyColsWidth(worksheet, report) {
      const headers = Object.keys(report[0]);
      worksheet['!cols'] = this.getColsWidth(report, headers);
    },

    applyStyles(worksheet, report, styles, skipHeader, origin = 0) {

      if (!skipHeader) {
        const length = Math.max(...report.map(r => Object.keys(r).length));

        for (let idx = 0; idx < length; idx++) {
          const addr = this.XLSX.utils.encode_cell({ r: origin, c: idx });

          if (addr in worksheet) {
            let style = HEADER_DEFAULT;

            if (styles && styles.headers[idx]) {
              style = { ...HEADER_DEFAULT, ...styles.headers[idx] };

              if (styles.headers[idx].font) {
                style.font = { bold: true, ...(styles.headers[idx].font) }
              }
            }

            worksheet[addr].s = style;
          }
        }
      }

      const rowStart = skipHeader ? 0 : 1;

      report.forEach((row, rowIdx) => {
        Object.keys(row).forEach((col, colIdx) => {
          const addr = this.XLSX.utils.encode_cell({ r: rowIdx + rowStart + origin, c: colIdx });

          if (addr in worksheet) {
            if (!('v' in worksheet[addr]) || _.isNull(worksheet[addr].v)) {
              worksheet[addr] = { v: '' };
            }
            worksheet[addr].s = { ...BORDER_ALL, ...(_.get(styles, `rows.${rowIdx}.${colIdx}`, {})) };
          }
        });
      });
    },

    /**
     * Aplica máscaras para formatação de dados e números no excel
     * Ex:
     * Monetário: 'R$ #,##0.000' (três casas decimais)
     * Numérico: '#,##0.00' (duas casas decimais)
     * Inteiro: '#,##0'
     * @doc https://docs.sheetjs.com/docs/csf/features#number-formats
     */
    applyMasks(worksheet, headers, length, origin = 0) {
      headers.forEach((header, colIdx) => {
        if (header.mask) {
          for (let rowIdx = 0; rowIdx < length; rowIdx++) {
            const addr = this.XLSX.utils.encode_cell({ r: rowIdx + origin + 1, c: colIdx });
            if (addr in worksheet) {
              if (this.XLSX.SSF.is_date(header.mask)) {
                worksheet[addr].t = 'd';
                worksheet[addr].z = header.mask;
              }
              else {
                worksheet[addr].z = header.mask;
              }
            }
          }
        }
      })
    },

    /**
     * Imprime relatório com header, footer
     */
    async printReport(html = [], title, date, range) {

      this.$root.$progressBar.loading();

      try {
        let { data } = await this.$axios.post(`/relatorios/getLogo`);

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        var report = [];
        report = `
        <table>
            <thead>
                <tr>
                    <td>
                        <div class="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class="content">${ html }</div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div class="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>
        <div class="header border-outline u-full-width">
          <div class="row">
              <div class="four columns">
                  <div style="margin-left: 20px;" class="logo-container">
                      <img src="${ data.laticinio.logotipo }"
                          width="140" height="40" alt="logo" />
                  </div>
              </div>
              <div class="four columns">
                  <div class="row">
                      <div class="text-center text-bold">
                          ${ title } - ${ range }
                      </div>
                  </div>
              </div>
              <div class="four columns text-right">
              </div>
          </div>
          <div class="spacer">
          </div>
          <div class="row">
              <div class="text-right">
                  <b>Data/Hora Impressão: </b> ${ date }
              </div>
          </div>
        </div>
        <div class="footer">
            <div class="row">
                <div class="twelve columns justify-center">
                    <div class="text-center watermark align-bottom" style="max-width: 95px;">
                        <img src="${ data.default }" width="90"
                            height="17" alt="milkup">
                        www.milkup.com.br
                        Gestão de Leite
                    </div>
                </div>
            </div>
        </div>
        `;

        return this.printJS({
          documentTitle: title,
          printable: report,
          type: 'raw-html',
          css: ['https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css'],
          style: `

          * { font-size: 9px;-webkit-print-color-adjust: exact !important; font-size: 9px; color: #1b1b1b; }

          table,
          tr,
          td { border: none !important; }

          table,
          .header,
          .footer { width: 100% !important; }

          .header-space,
          .footer-space { height: 120px; }

          .header { position: fixed; top: 0; }
          .header { padding: 5px; }

          .footer { height: 45px; }
          .footer .row { margin-top: 5px; }
          .footer .watermark { line-height: 10px; }
          .footer .watermark>img { margin-bottom: 2px; }
          .footer { position: fixed; bottom: 0; }

          .td-content { border-bottom: 1px solid #dddddd !important; }

          .content * { page-break-inside: avoid; }

          .border-outline { border: 1px solid #ccc; }

          b { font-weight: 700; }

          .text-bold { font-weight: 700; }

          .text-center { text-align: center; }

          .text-right { text-align: right; margin-right: 20px; }

          .spacer { height: 10px; content: ' '; }

          .justify-center { display: flex; flex-direction: column; align-items: center; }

          img { vertical-align: middle; border-style: none; object-fit: contain; }

          @page { margin: 5mm; }
          `
        });
      } catch (error) {
        this.$snotify.error(`Erro ao imprimir: ${error}`, "Atenção");
        console.error(error);
      } finally {
        this.$root.$progressBar.hide()
      }
    }
  }
}
