<template>
  <v-form
    ref="form"
    v-model="validation.valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="12">
        <h4>Dados Para Pagamento</h4>
      </v-col>
      <v-col cols="12">
        <span>
          Conta Principal:
        </span>
        <v-radio-group
          v-model="main_account"
          mandatory
          hide-details
          row
        >
          <v-radio
            v-for="(alternative_bank, idx) in dataAlternativeBank"
            :key="idx"
            :label="`Conta ${idx+1}`"
            :value="idx"
          />
        </v-radio-group>
      </v-col>
      <v-col
        v-if="isVisible('dia_pagamento')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-select
          v-model="selectedPayDay"
          :items="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']"
          :label="`Dia do Pagamento:  ${isRequired('dia_pagamento') ? '*' : ''}`"
          :rules="getRules('dia_pagamento')"
          dense
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(alternative_bank, idx) in dataAlternativeBank"
        :key="idx"
        cols="12"
        md="6"
      >
        <h3>Conta {{ idx+1 }} </h3>

        <payment-form-data
          :data-alternative-bank="alternative_bank"
        />
      </v-col>
    </v-row>
    <v-divider />

    <v-row>
      <v-col cols="12">
        <h4>Tributação</h4>
      </v-col>

      <v-col
        v-if="isVisible('tributacao')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-select
          v-model="tributacoes.tributacao"
          :items="['ISENTO', 'TRIBUTADO', 'DIFERIDO']"
          :label="`Tributação ${isRequired('tributacao') ? '*' : ''}`"
          :rules="getRules('tributacao')"
          dense
        />
      </v-col>

      <v-col
        v-if="isVisible('desconta_senar')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-select
          v-model="tributacoes.desconta_senar"
          :items="['SIM', 'NÃO']"
          :label="`Desconta SENAR ${isRequired('desconta_senar') ? '*' : ''}`"
          :rules="getRules('desconta_senar')"
          dense
        />
      </v-col>

      <v-col
        v-if="isVisible('debita_inss')"
        cols="12"
        sm="6"
        md="3"
      >
        <v-select
          v-model="tributacoes.debita_inss"
          :items="['SIM', 'NÃO']"
          :label="`Debita INSS ${isRequired('debita_inss') ? '*' : ''}`"
          :rules="getRules('debita_inss')"
          dense
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row>
      <v-col cols="12">
        <h4>Descontos</h4>
      </v-col>

      <v-col
        cols="6"
        sm="4"
        md
      >
        <v-select
          v-model="taxes.local"
          :items="[
            { value: true, text: 'Local' },
            { value: false, text: 'Global' },
          ]"
          label="Configuração de descontos"
          dense
        />
      </v-col>

      <v-col
        v-if="isVisible('fundesa')"
        cols="6"
        sm="4"
        md
      >
        <masked-input-form
          v-model="taxes.fundesa"
          :label="`Fundesa (Aliquota (L)) ${isRequired('fundesa') ? '*' : ''}`"
          :rules="getRules('fundesa', 10)"
          type="number"
          dense
          :mask="FundesaTaxMask"
          return-unmasked
          :disabled="!taxes.local"
        />
      </v-col>
      <v-col
        v-if="isVisible('rat')"
        cols="6"
        sm="4"
        md
      >
        <masked-input-form
          v-model="taxes.rat"
          :label="`Rat (%) ${isRequired('rat') ? '*' : ''}`"
          :rules="getRules('rat', 5)"
          type="number"
          dense
          :mask="TaxMask"
          :disabled="!taxes.local"
        />
      </v-col>
      <v-col
        v-if="isVisible('senar')"
        cols="6"
        sm="4"
        md
      >
        <masked-input-form
          v-model="taxes.senar"
          :label="`Senar (%) ${isRequired('senar') ? '*' : ''}`"
          :rules="getRules('senar', 5)"
          type="number"
          dense
          :mask="TaxMask"
          :disabled="!taxes.local"
        />
      </v-col>
      <v-col
        v-if="isVisible('funrural')"
        cols="6"
        sm="4"
        md
      >
        <masked-input-form
          v-model="taxes.funrural"
          :label="`Funrural (%) ${isRequired('funrural') ? '*' : ''}`"
          :rules="getRules('funrural', 3)"
          type="number"
          dense
          :mask="TaxMask"
          :disabled="!taxes.local"
        />
      </v-col>
      <v-col
        v-if="isVisible('administrativa')"
        cols="6"
        sm="4"
        md
      >
        <masked-input-form
          v-model="taxes.administrativa"
          :label="`Administrativa (%) ${isRequired('administrativa') ? '*' : ''}`"
          :rules="getRules('administrativa', 10)"
          type="number"
          dense
          :mask="FundesaTaxMask"
          return-unmasked
          :disabled="!taxes.local"
        />
      </v-col>
      <v-col
        v-if="isVisible('frete')"
        cols="6"
        sm="4"
        md
      >
        <masked-input-form
          v-model="taxes.frete"
          :label="`Frete (Preço (L)) ${isRequired('frete') ? '*' : ''}`"
          :rules="getRules('frete', 10)"
          type="number"
          dense
          :mask="TaxMask"
          return-unmasked
          :disabled="!taxes.local"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import * as _ from 'lodash';

import MaskedInputForm from "@/Domains/Producer/Management/Components/MaskedInput.vue";
import PaymentFormData from "@/Domains/Producer/Management/Components/Form/PaymentFormData.vue";

export default {
  components: {
    MaskedInputForm,
    PaymentFormData
  },
  props: {
    dataTaxes: {
      type: Object,
      required: true,
    },
    dataAlternativeBank: {
      type: Array,
      default: () => []
    },
    dataMainAccount: {
      type: Number,
      default: 0
    },
    dataTributacoes: {
      type: Object,
      required: false
    },

    payDay: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      validation: {
        valid: true,
        rules: {
          required: [v => !!v || "Campo obrigatório"],
        },
      },

      TaxMask: {
        mask: Number,
        scale: 2,
        maxlength: 5,
        radix: '.',
        mapToRadix: [','],
      },

      FundesaTaxMask: {
        mask: Number,
        scale: 8,
        maxlength: 10,
        radix: '.',
        mapToRadix: [','],
      },

      defaultRequiredFields: [],

    };
  },
  computed: {
    selectedPayDay: {
      get() {
        return this.payDay;
      },

      set(newValue) {
        return this.$emit("update:payDay", newValue);
      },
    },
    taxes: {
      get() {
        return this.dataTaxes;
      },

      set(newValue) {
        return this.$emit("update:dataTaxes", newValue);
      },
    },
    tributacoes: {
      get() {
        return this.dataTributacoes;
      },

      set(newValue) {
        return this.$emit("update:dataTributacoes", newValue);
      }
    },

    settings() {
      if (_.isEmpty(this.$store.state.settings.gerais.cadastro_produtor)) {
        return {}
      }
      return this.$store.state.settings.gerais.cadastro_produtor.pagamento;
    },

    alternative_bank: {
      get() {
        return this.dataAlternativeBank;
      },

      set(newValue) {
        return this.$emit("update:dataAlternativeBank", newValue);
      },
    },

    main_account: {
      get() {
        return this.dataMainAccount;
      },

      set(newValue) {
        return this.$emit("update:dataMainAccount", newValue);
      },
    },
  },
  watch: {
    // Monitora a validação do form
    validation: {
      handler: function ({ valid }) {
        this.$emit("onValidation", {
          valid: valid,
          tab: "financial",
        });
      },
      deep: true,
    },
  },

  mounted() {
    // Valida campos do formulário
    this.$refs.form.validate();
  },

  methods: {
    isVisible(field) {
      if (!this.settings[field]) {
        return true;
      }

      return this.settings[field].show;
    },

    isRequired(field) {

      if (this.isGlobalTax(field)) {
        return false;
      }

      if (!this.settings[field]) {
        return this.defaultRequiredFields.includes(field);
      }

      return this.settings[field].required;
    },

    getRules(field, maxLength) {
      const rules = [];

      if (this.isGlobalTax(field)) {
        return rules;
      }

      if (maxLength) {
        rules.push(v => !v || String(v).trim().length <= maxLength || 'Valor inválido');
      }

      if (this.isRequired(field)) {
        rules.push(v => !!v || "Campo obrigatório");
      }

      return rules;
    },

    isGlobalTax(field) {
      return !this.taxes.local && ['fundesa', 'rat', 'senar', 'funrural', 'administrativa'].includes(field);
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /**
     * @void
     * Valida os campos do formulários
     */
    formValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    formResetValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta o formulário
     */
    formReset() {
      this.$refs.form.reset()
    },
  },
};
</script>
