<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
        mobile-breakpoint="1000"
      >
        <v-tab href="#production-order-tab">
          Ordens de Produção
        </v-tab>

        <v-tab
          href="#production-appointment-tab"
          :disabled="tab !== 'production-appointment-tab'"
        >
          Apontamentos
        </v-tab>

        <v-tabs-items
          v-model="tab"
          touchless
        >
          <v-tab-item value="production-order-tab">
            <production-order-list-tab
              v-if="tab === 'production-order-tab'"
              @addAppointment="onAddAppointment"
            />
          </v-tab-item>

          <v-tab-item value="production-appointment-tab">
            <production-appointment-tab
              v-if="tab === 'production-appointment-tab'"
              :item-id="appointment.itemId"
              :order-id="appointment.orderId"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.order-dialog {
  .theme--light.v-label--is-disabled, .theme--light.v-input--is-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .theme--light.v-select.v-input--is-disabled .v-select__selections {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .theme--light.v-select .v-select__selection--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .v-input__icon--append .theme--light.v-icon.v-icon.v-icon--disabled {
    color: transparent !important;
  }
}
</style>

<script>
import ProductionOrderListTab from "@/Domains/Industry/ProductionOrder/Views/ProductionOrderListTab.vue";
import ProductionAppointmentTab from "@/Domains/Industry/ProductionOrder/Views/ProductionAppointmentTab.vue";

export default {
  name: "ProductionOrder",

  components: {
    ProductionOrderListTab,
    ProductionAppointmentTab,
  },

  data() {
    return {
      tab: null,

      appointment: {
        itemId: null,
        orderId: null,
      }
    };
  },

  methods: {
    onAddAppointment(item) {
      this.appointment.itemId = item.id_item;
      this.appointment.orderId = item.id_ordem_producao;
      this.tab = 'production-appointment-tab';
    },
  }
};
</script>
