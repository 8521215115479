<template>
  <div>
    <v-dialog
      v-model="editing.show"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ editing.title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="onSave(editing.silo)"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editing.silo.label"
                    label="Descrição *"
                    :rules="[v => (v && v.length >= 5) || 'A descrição deve conter no mínimo 5 caracteres']"
                    required
                    autofocus
                    :disabled="editing.silo.id && !hasChangeDescription ? true : false"
                  />
                </v-col>
                <v-col cols="12">
                  <raw-material-autocomplete
                    v-model="editing.silo.rawProduct"
                    label="Matéria Prima *"
                    placeholder=" "
                    return-object
                    only-parents
                    :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    :disabled="editingDesabledRawMateria"
                  />
                </v-col>
                <template v-if="hasEditSiloAccess">
                  <v-col cols="6">
                    <masked-input
                      v-model.number="editing.silo.vol"
                      label="Volume Atual"
                      :mask="NumberMask"
                      :rules="[editing.silo.vol <= editing.silo.capacity || 'O Volume não pode ser maior que a capacide do silo']"
                      return-unmasked
                      :disabled="editing.silo.id && !hasEditCurrentBalance ? true : false"
                    />
                  </v-col>
                  <v-col cols="6">
                    <masked-input
                      v-model.number="editing.silo.capacity"
                      label="Capacidade Total *"
                      :mask="NumberMask"
                      :rules="[v => (v && v > 0) || 'Um valor deve ser informado!']"
                      return-unmasked
                      :disabled="editing.silo.id && !hasEditTotalCapacity ? true : false"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onSave(editing.silo)"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmationDialog.show"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ confirmationDialog.title }}
        </v-card-title>
        <v-card-text>
          <v-form
            ref="confirmationDialogForm"
            lazy-validation
            @submit.prevent=""
          >
            <div v-html="confirmationDialog.content" />

            <v-text-field
              v-model.number="confirmationDialog.typedToken"
              :rules="[v => (v && v === confirmationDialog.confirmationToken) || 'Token de confirmação incorreto!']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onExcluding()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {

  components: {
    RawMaterialAutocomplete,
    MaskedInput,
  },

  data() {
    return {
      // Usado no dialogo de criação / edição de um silo
      editing: {
        show: false,
        title: '',
        currentVol: 0,
        silo: {
          label: '',
          vol: 0,
          capacity: 0,
        },
      },

      // Dialogo de confirmação
      confirmationDialog: {
        show: false,
        title: '',
        content: '',
        silo: {},
        typedToken: '',
        confirmationToken: '',
      },

      // Mascara usada nos demais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: '.',
            min: -999999,
            max: 999999,
          },
        },
      },
    };
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasEditSiloAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-insert-edit' && o.tipo === 'COMPONENTE');
    },

    hasEditCurrentBalance() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-edit-current-balance' && o.tipo === 'COMPONENTE');
    },

    hasEditTotalCapacity() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-edit-total-capacity' && o.tipo === 'COMPONENTE');
    },

    hasEditRawMateria() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-edit-raw-materia' && o.tipo === 'COMPONENTE');
    },

    hasChangeDescription() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'silo-change-description' && o.tipo === 'COMPONENTE');
    },

    editingDesabledRawMateria() {
      if (this.editing.silo.id && this.hasEditRawMateria) {
        return !!this.editing.silo.vol && !!this.editing.currentVol;
      } else {
        return false;
      }
    },
  },

  methods: {

    /**
     * Mostra o dialogo para a criação de uma nova região
     */
    showCreatingDialog(lastIndex) {
      // Verifica se já existe um formulário carregado em memória e o reseta
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.editing.title  = "Novo Silo";
      this.editing.show = true;
      this.editing.silo = {
        label: `Silo ${++lastIndex}`,
      };
      this.editing.currentVol = 0;
    },

    /**
     * Mostra o dialogo para a edição de um silo
     */
    showEditingDialog(silo) {
      // Verifica se já existe um formulário carregado em memória e o reseta
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.editing.show = true;
      this.editing.title = silo.label;
      this.editing.silo = Object.assign({}, silo);
      this.editing.currentVol = silo.vol;
    },

    /**
     * Mostra o dialogo de confirmação de exclusão
     */
    showConfirmationDialog(silo) {
      this.confirmationDialog.show = true;
      this.confirmationDialog.title = 'Atenção';
      this.confirmationDialog.silo.id = silo.id;
      this.confirmationDialog.content = `
      Deseja realmente excluir este silo?
        <br />
      Para continuar, por favor digite <b>EXCLUIR</b> no campo abaixo:
      `;
      this.confirmationDialog.confirmationToken = 'EXCLUIR';
    },

    /**
     * Fecha todos os dialogos abertos na tela
     */
    closeDialogs() {
      this.editing = {
        show: false,
        title: '',
        silo: {},
      };

      this.confirmationDialog = {
        show: false,
        title: '',
        content: '',
        silo: {},
        typedToken: '',
        confirmationToken: '',
      };
    },

    /**
     * Método responsável por validar o formulário e enviar o evento para salvar a criação / edição de un silo
     *
     * * Debounce necessário para evitar duplo click
     */
    onSave: _.debounce(function (silo) {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return;
      }

      return this.$emit('onSave', silo);
    }, 500),

    /**
     * Método responsável por validar o formulário e enviar o evento para a exclusão de um silo
     *
     * * Debounce necessário para evitar duplo click
     */
    onExcluding: _.debounce(function () {
      const valid = this.$refs.confirmationDialogForm.validate();

      if (!valid) {
        return;
      }

      return this.$emit('onExcluding', this.confirmationDialog.silo);
    }, 500),

  },

}
</script>

