<template>
  <v-container xs>
    <v-card
      dark
      color="transparent"
    >
      <v-col
        class="px-0"
      >
        <card-title-settings>
          Conexão do Swagger
        </card-title-settings>
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-row>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  ref="swaggerUrlProd"
                  v-model="swaggerUrlProd"
                  label="Swagger Produção"
                  append-icon="content_copy"
                  readonly
                  @click:append="copyText('swaggerUrlProd')"
                />
              </v-col>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  ref="swaggerUrlDev"
                  v-model="swaggerUrlDev"
                  label="Swagger Desenvolvimento"
                  append-icon="content_copy"
                  readonly
                  @click:append="copyText('swaggerUrlDev')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                md="6"
                sm="12"
              >
                <v-text-field
                  ref="token"
                  v-model="token"
                  label="Token"
                  append-icon="content_copy"
                  readonly
                  @click:append="copyText('token')"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-overlay :value="loading">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue";

export default {
  components: {
    CardTitleSettings
  },

  data() {
    return {
      loading: true,
      token: '',
      swaggerUrlProd: 'https://api.milkup.com.br/sync/swagger',
      swaggerUrlDev: 'https://api.dev.milkup.com.br/sync/swagger',
    }
  },

  created() {
    this.loadToken();
  },

  methods: {
    async loadToken() {
      try {
        this.loading = true;
        
        const { data } = await this.$axios.get(`/settings/systems`);

        this.token = data.api_token;
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o Token!", "Atenção");
        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    copyText(refName) {
      const fieldCopied = this.$refs[refName];

      if (fieldCopied) {
        fieldCopied.$refs.input.select();
  
        document.execCommand('copy');
  
        window.getSelection().removeAllRanges();
      } else {
        console.error('$refs.swaggerUrlProd não está definido');
      }
    }
  }
};
</script>
