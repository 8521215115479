<template>
  <v-dialog
    v-model="show"
    width="650"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Recomendação
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="6"
              class="py-0"
            >
              <masked-text-field
                v-model="form.createdAt"
                label="Data cadastro"
                prepend-inner-icon="access_time"
                placeholder="00/00/0000"
                :mask="{ mask: '00/00/0000' }"
                disabled
              />
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <masked-text-field
                v-model="form.concludedAt"
                label="Data conclusão"
                prepend-inner-icon="access_time"
                placeholder="00/00/0000"
                :mask="{ mask: '00/00/0000' }"
                clearable
                :disabled="!!form.formId"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <person-autocomplete-filter
                v-model="form.technician"
                label="Técnico"
                type="TECHNICIAN"
                :filled="false"
                :hide-details="false"
                :rules="[rules.required]"
                :disabled="isEditing"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <person-autocomplete-filter
                v-model="form.producer"
                label="Produtor"
                type="PRODUCER"
                :filled="false"
                :hide-details="false"
                :rules="[rules.required]"
                :disabled="isEditing"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-select
                v-model="form.types"
                :items="types"
                label="Tipo"
                clearable
                multiple
                :disabled="!!form.formId"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-select
                v-model="form.status"
                :items="[
                  { value: 'CONCLUIDO', text: 'Concluído'},
                  { value: 'ABERTO', text: 'Não concluído'},
                ]"
                label="Status"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-textarea
                v-model="form.indicator"
                type="text"
                label="Indicador"
                rows="1"
                auto-grow
                :disabled="!!form.formId"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-textarea
                v-model="form.cause"
                type="text"
                label="Causa"
                rows="1"
                auto-grow
                :disabled="!!form.formId"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-textarea
                v-model="form.recommendation"
                type="text"
                label="Recomendação"
                rows="3"
                auto-grow
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>

import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue';
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

import moment from 'moment';

const types = {
  IN77: 'IN77',
  IN59: 'IN59',
  PMLS: 'PMLS',
  BPA: 'BPA',
  VETERINARIO: 'VETERINÁRIO',
  ZOO_TECNICO: 'ZOOTÉCNICO',
  ALTERACOES_NO_LEITE: 'ALTERAÇÕES NO LEITE',
  QUALIDADE: 'QUALIDADE',
}

export default {

  components: {
    PersonAutocompleteFilter,
    MaskedTextField,
  },

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,

      types: Object.keys(types).map(value => ({
        value,
        text: types[value]
      })),

      form: {
        createdAt: moment().format('DD/MM/YYYY'),
        types: [],
        status: 'ABERTO',
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        date: v => this.isDateValid(v, 'DD/MM/YYYY') || 'Data Inválida!',
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    isEditing() {
      return !!this.editId
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = {
          createdAt: moment().format('DD/MM/YYYY'),
          types: [],
          status: 'ABERTO',
        }

        if (this.editId) {
          this.load();
        }
      }
    }
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/visit/recommendation/${ this.editId }`);

        this.form = {
          createdAt: data.data_hora_cadastro ? moment(data.data_hora_cadastro).format('DD/MM/YYYY') : '',
          concludedAt: data.data_conclusao ? moment(data.data_conclusao).format('DD/MM/YYYY') : '',
          status: data.status,
          types: data.tipos,
          technician: data.tecnico ? {
            id: data.tecnico.id_pessoa,
            name: data.tecnico.nome,
          } : null,
          producer: data.produtor ? {
            id: data.produtor.id_pessoa,
            name: data.produtor.nome,
          } : null,
          indicator: data.indicador,
          cause: data.causa,
          recommendation: data.recomendacao,
          formId: data.id_formulario_resposta,
        };
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;

        const payload = {
          id_tecnico: this.form.technician.id,
          id_produtor: this.form.producer.id,
          data_conclusao: this.form.concludedAt ? moment(this.form.concludedAt, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
          status: this.form.status,
          tipos: this.form.types,
          indicador: this.form.indicator,
          cause: this.form.causa,
          recomendacao: this.form.recommendation,
        };

        const data = this.editId ? await this.update(payload) : await this.store(payload);

        this.close();
        this.$emit('save', data.id);
        this.$snotify.success('Recomendação salva com sucesso!', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/visit/recommendation`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/visit/recommendation/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    },

    isDateValid(value, format) {
      if (!value || value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },
  }
}
</script>
