<template>
  <div class="dashboard">
    <v-row class="mx-2">
      <v-col cols="4">
        <v-spacer />
      </v-col>
      <v-col cols="4">
        <VDateRangePicker
          v-model="filter.comparisonDate"
          :ranges="comparisonDateRanges"
          label="Comparar"
          dark
          clearable
          disable-custom-range
        />
      </v-col>
      <v-col cols="4">
        <VDateRangePicker
          v-model="filter.date"
          :ranges="filter.dateRanges"
          dark
          @change="onDateChange"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="3"
      >
        <ProducersCard
          :period="filter.date"
          :comparison-period="filter.comparisonDate"
          show-approved
          height="153px"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="5"
      >
        <ProducersByMonthChart
          :date="filter.date[1]"
          height="153px"
        />
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <ProducersResume
          :period="filter.date"
        />
      </v-col>

      <v-col cols="12">
        <LinkingProducersChart
          :period="filter.date"
          :comparison-period="filter.comparisonDate"
          smooth
          hide-labels
          height="200px"
        />
      </v-col>

      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <DairiesLinkingResume
          :period="filter.date"
          :comparison-period="filter.comparisonDate"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <UnlinkedMotivesChart
          :period="filter.date"
          height="401px"
        />
      </v-col>

      <v-col cols="12">
        <TechniciansLinkingResume
          :period="filter.date"
          :comparison-period="filter.comparisonDate"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <UnlinkedLinkedChart
          :period="filter.date"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProducersCard from "@/Domains/BI/Views/Producers/Components/ProducersCard.vue";
import ProducersResume from "@/Domains/BI/Views/Producers/Components/ProducersResume.vue";
import UnlinkedLinkedChart from  "@/Domains/BI/Views/Producers/Components/UnlinkedLinkedChart.vue";
import LinkingProducersChart from "@/Domains/BI/Views/Producers/Components/LinkingProducersChart.vue";
import DairiesLinkingResume from "@/Domains/BI/Views/Producers/Components/DairiesLinkingResume.vue";
import TechniciansLinkingResume from "@/Domains/BI/Views/Producers/Components/TechniciansLinkingResume.vue";
import UnlinkedMotivesChart from "@/Domains/BI/Views/Producers/Components/UnlinkedMotivesChart.vue";
import ProducersByMonthChart from "@/Domains/BI/Views/Producers/Components/ProducersByMonthChart.vue";

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

import moment from "moment";
import upperFirst from "lodash/fp/upperFirst"

export default {
  components: {
    VDateRangePicker,
    ProducersCard,
    ProducersResume,
    UnlinkedLinkedChart,
    LinkingProducersChart,
    DairiesLinkingResume,
    TechniciansLinkingResume,
    UnlinkedMotivesChart,
    ProducersByMonthChart,
  },

  data() {
    return {
      filter: {
        dateRanges: {
          'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
          'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
          'Este ano': [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
        },
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        comparisonDate: []
      },
    }
  },

  computed: {
    comparisonDateRanges() {
      const [startDate, endDate] = this.filter.date;
      if (startDate == this.filter.dateRanges['Este ano'][0] && endDate == this.filter.dateRanges['Este ano'][1] ) {
        return {
          'Ano anterior': [moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')],
        }
      }
      if (startDate == this.filter.dateRanges['Este mês'][0] && endDate == this.filter.dateRanges['Este mês'][1] ) {
        return {
          'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
          [upperFirst(moment().subtract(1, 'year').format('MMM/YY'))]: [moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'year').endOf('month').format('YYYY-MM-DD')],
        }
      }

      const range = {};

      /**
       * Mês anterior ao selecionado
       */
      const startComp = moment(startDate).subtract(1, 'month');
      const endComp = moment(endDate).subtract(1, 'month');

      if (endComp.isBefore(startDate)) {

        const title = (
          startComp.isSame(endComp, 'month')
          && startComp.format('DD') == '01'
          && endComp.endOf('month').format('DD') == endComp.format('DD')
        )
          ? startComp.format('MMM/YYYY')
          : `${startComp.format('DD/MM/YYYY')} - ${endComp.format('DD/MM/YYYY')}`;

        range[upperFirst(title)] = [startComp.format('YYYY-MM-DD'), endComp.format('YYYY-MM-DD')]
      }

      const startYearComp = moment(startDate).subtract(1, 'year');
      const endYearComp = moment(endDate).subtract(1, 'year');

      /**
       * Mesmo período selecionado do ano anterior
       */
      if (endYearComp.isBefore(startDate)) {
        const title = (
          startYearComp.isSame(endYearComp, 'month')
          && startYearComp.format('DD') == '01'
          && endYearComp.endOf('month').format('DD') == endYearComp.format('DD')
        )
          ? startYearComp.format('MMM/YYYY')
          : `${startYearComp.format('DD/MM/YYYY')} - ${endYearComp.format('DD/MM/YYYY')}`;

        range[upperFirst(title)] = [startYearComp.format('YYYY-MM-DD'), endYearComp.format('YYYY-MM-DD')]
      }

      return range;
    }
  },

  methods: {
    onDateChange() {
      this.filter.comparisonDate = []
    }
  }
};
</script>
