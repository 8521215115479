<template>
  <div
    class="pa-0"
    style="margin-bottom: 10px"
  >
    <v-fade-transition>
      <v-checkbox
        v-if="showCheck"
        v-model="ChartSelected"
        dark
        :disabled="listSelected && !ChartSelected"
        @change="onselectable"
      />
    </v-fade-transition>
    <v-card
      class="rotulo-list pa-0t"
      style="background: transparent !important"
      elevation-1
      rounded="rounded-lg elevator-1"
    >
      <v-data-table
        dense
        :headers="[
          { text: '', value: 'customization', width: 50, sortable: false, show: true, deafult: true },
          { text: 'Questão', value: 'label', sortable: false, show: true, deafult: true },
          ...selectedHeaders,
        ]"
        hide-default-footer
        :items="items"
        dark
        :custom-sort="customSort"
        disable-pagination
        :sort-by.sync="sortBy"
        class="rotulo-list-table"
        :sort-desc.sync="sortDesc"
        item-key="label"
        show-expand
        :expanded.sync="expanded"
        @item-expanded="onitemExpanded"
      >
        <template #[`header.customization`]>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                class="pa-0 ma-0"
                v-on="on"
              >
                <v-icon
                  color="#FFFF"
                  class="pa-0"
                >
                  view_week
                </v-icon>
              </v-btn>
            </template>
            <v-list
              dense
              style="max-height: 400px"
              class="overflow-y-auto"
            >
              <v-list-item-group>
                <v-list-item
                  v-for="header in headers"
                  :key="header.value"
                  selectable
                  @click.stop="onHeaderSelect(header.value)"
                >
                  <v-list-item-action>
                    <v-checkbox
                      color="#1F496E"
                      :input-value="header.show"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-text="header.text" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>

        <template #[`body.append`]>
          <tr>
            <td class="pl-2 pr-2">
              <v-btn
                icon
                @click="print"
              >
                <v-icon> download</v-icon>
              </v-btn>
            </td>
            <td />
            <td />
            <td
              v-for="header in selectedHeaders"
              :key="header.value"
              class="text-center"
            >
              <b v-if="header.value !== 'customization' && header.value !== 'question'">
                {{ pecentByAnswers(header.value) }} %</b>
            </td>
          </tr>
        </template>

        <template #[`item.label`]="{ item }">
          <b>{{ item.label }}</b>
        </template>

        <template v-slot:expanded-item="{ headers, item: { detail } }">
          <td
            :colspan="headers.length"
            style="background: transparent !important; background: rgba(0, 0, 0, 0.2)"
            class="px-0"
          >
            <v-card
              class="transparent"
              style=""
              flat
            >
              <v-card-text>
                <v-data-table
                  dense
                  :headers="[
                    {
                      align: 'start',
                      text: 'Cód. Produtor',
                      value: 'code',
                      width: 130,
                    },
                    {
                      text: 'Produtor',
                      value: 'name',
                    },
                    {
                      text: 'Data Resposta',
                      value: 'date',
                    },
                    {
                      text: 'Status do produtor',
                      value: 'status',
                    },
                    {
                      text: 'Resposta',
                      value: 'answer',
                      width: '180',
                      align: 'center',
                    },
                  ]"
                  hide-default-footer
                  :items="detail"
                  :items-per-page="-1"
                  item-key="id_rotulo"
                >
                  <template #[`item.answer`]="{ item }">
                    <span
                      style="color:#00E5FF;font-weight: bold;font-size:15px"
                    >{{ item.answer }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-overlay v-model="loading">
      Carregando ...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">
.rotulo-list-table {
  background: transparent !important;
  font-family: Roboto, Arial, sans-serif;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      // color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-table__selected {
    background: rgba(0, 0, 0, 0) !important;
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import * as _ from "lodash";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  filters: {
    /**
     * Retorna o valor corresponde ao total de resposta de uma determinada alternativa da questão;
     * @returns {integer}
     */
    findSumAnswers: function (value, question) {
      if (_.isEmpty(question)) {
        return "-";
      }

      return value !== 0 ? value : "-";
    },
  },

  mixins: [ReportMixin],

  props: {
    questions: {
      default: () => [],
    },
    showCheck: {
      type: Boolean,
      default: false,
    },
    listSelected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    reportColumns: {
      default: () => [],
    },
  },

  data() {
    return {
      headers: [],

      tableModel: [],

      ChartSelected: false,

      sortBy: [],

      sortDesc: [],

      expanded: [],

      labelDetail: [],

      loading: false,
    };
  },
  computed: {
    /**
     * Monta o header desejada da tabela
     * @returns {Array}
     */
    selectedHeaders() {
      return this.headers.filter((header) => header.show);
    },

    /**
     *  Retornar um array com as possiveis alternativa de respostas
     * @returns {array}
     */
    answersAlternative() {
      if (_.isEmpty(this.questions)) {
        return [];
      }

      return _.head(this.questions).answers;
    },

    /**
     * Organiza as infomações que devem ser exibidos na tabela
     *  @returns {array}
     */
    items() {
      return (this.questions || []).map((question) => {
        const answers = question.answers.reduce((acc, current) => {
          return { ...acc, [`${current}`]: _.get(question.count_by_answers, `${current}`, 0) };
        }, {});

        return _.merge(
          {
            label: question.label,
            rotuloId: _.get(question.answered_questions, "0.id_rotulo"),
            detail: _.get(question, "detail", []),
          },
          answers
        );
      });
    },

    aboutList() {
      return {
        headers: this.selectedHeaders,
        data: this.customSort(this.items, this.sortBy, this.sortDesc),
        sort: _.get(this.sortBy, 0),
      };
    },
    /**
     * Total de produtores
     */
    totalProducer() {
      const { answered_questions } = _.head(this.questions);

      return (answered_questions || []).length;
    },
  },

  watch: {
    showCheck: function () {
      if (!this.showCheck) {
        this.ChartSelected = false;
      }
    },
    // Atualiza o objeto de geração do gráfico
    selectedHeaders: {
      handler: function () {
        //Só atualiza se esta lista estiver selecionada
        if (this.ChartSelected) {
          this.onselectable();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.mountedHeader();
  },
  methods: {
    /**
     * Método custom do filtro da tabela.
     * @todo Verificar se é mesmo necessário criar um método custom para o filtro.
     * @returns {Array}
     */
    customSort: function (items, sortBy, isDesc) {
      if (_.isEmpty(sortBy)) {
        return items;
      }

      isDesc = _.get(isDesc, "0");
      const order = isDesc ? "desc" : "asc";

      return _.orderBy(items, _.get(sortBy, "0"), order);
    },

    /**
     * Monta o header da table
     * @returns {Array}
     */
    mountedHeader() {
      if (_.isEmpty(this.questions)) {
        return [{}];
      }

      let headers = _.map(this.questions[0].answers, (value) => {
        return {
          text: value,
          value: value,
          width: 80,
          sortable: true,
          show: true,
          default: true,
        };
      });

      this.headers = headers;
    },
    /**
     * Calculo o total de respostas de um determinada alternativa
     * @param {string} Alternativa a ser contada
     *
     * @returns {integer}
     */
    pecentByAnswers(alternative) {
      if (_.isEmpty(this.questions) || _.isEmpty(alternative)) {
        return 0;
      }

      const total = _.sumBy(this.questions, `count_by_answers.${alternative}`);

      const percent = (total * 100) / (this.totalProducer * this.questions.length);

      return Math.ceil(percent || 0);
    },

    /**
     * @event string
     *
     * Evento acionado ao selecionar uma coluna para exibir na lista
     */
    onHeaderSelect(value) {
      this.headers = this.headers.map((header) => {
        if (header.value === value || value === "*") {
          return {
            ...header,
            show: !header.show,
          };
        }

        return header;
      });
    },

    /**
     * Evento de change do checkbox
     */
    onselectable() {
      if (!this.ChartSelected) {
        this.$emit("selectable", {
          headers: [],
          data: [],
          sort: null,
        });

        return false;
      }

      this.$emit("selectable", {
        headers: this.selectedHeaders,
        data: this.customSort(this.items, this.sortBy, this.sortDesc),
        sort: _.get(this.sortBy, 0),
      });
    },

    async onitemExpanded({ value }) {
      if (!value) {
        return false;
      }
    },

    async print() {
      try {
        this.loading = true;

        const title = this.title;

        const header = [];

        const body = [
          {
            tag: "section",
            class: "row",
            children: [
              {
                tag: "div",
                class: "spacer",
              },
              {
                tag: "div",
                class: "twelve columns",

                children: [
                  {
                    tag: "table",
                    class: "u-full-width condensed bordered",
                    children: [
                      {
                        tag: "thead",
                        children: [
                          {
                            tag: "tr",
                            attributes: {
                              style: "height: 10px;",
                            },
                          },
                          {
                            tag: "tr",
                            attributes: {
                              style: "background: #dddddd;",
                            },
                            children: [
                              {
                                tag: "th",
                                class: "text-bold",
                                contents: "Questão",
                                attributes: {
                                  style: "width:80%;",
                                },
                              },
                              ...this.selectedHeaders.map((m) => ({
                                tag: "th",
                                class: "text-bold",
                                contents: m.text,
                                key: m.value,
                                width: "30%",
                                attributes: {
                                  style: "width:10%;",
                                },
                              })),
                            ],
                          },
                        ],
                      },
                      {
                        tag: "tbody",
                        attributes: {
                          style:
                            "border-left: solid 1px #dddddd;border-right: solid 1px #dddddd;padding:0px: margin:px",
                        },
                        children: [
                          ...this.items.reduce((acc, curr) => {
                            acc.push({
                              tag: "tr",
                              children: [
                                {
                                  tag: "td",
                                  class: "text-bold",
                                  contents: curr.label,
                                  // attributes: {
                                  //   style: "border-bottom: 1px dashed black;",
                                  // }
                                },
                                ...this.selectedHeaders.map((m) => ({
                                  tag: "td",
                                  class: "text-bold",
                                  contents: _.get(curr, `${m.text}`, 1),
                                })),
                              ],
                            });

                            if (!_.isEmpty(curr.detail)) {
                              // expanda
                              // exibição do produtor por rotulo
                              acc.push({
                                tag: "tr",
                                // attributes: {
                                //   style: "border: solid 1px #dddddd; margin:0px;padding",
                                // },
                                children: [
                                  {
                                    tag: "td",
                                    attributes: {
                                      colspan: 12,
                                    },
                                    children: [
                                      {
                                        tag: "div",
                                        class: "row",
                                        attributes: {
                                          style: "padding-left:20px; padding-right:20px;",
                                        },
                                        children: [
                                          {
                                            tag: "div",
                                            class: "twelve columns",
                                            children: [
                                              {
                                                tag: "datatable",
                                                headers: [
                                                  {
                                                    key: "code",
                                                    class: "text-left",
                                                    label: "Cod. Produtor",
                                                    attributes: {
                                                      style: "width:100px",
                                                    },
                                                  },
                                                  {
                                                    key: "name",
                                                    class: "text-left",
                                                    label: "Produtor",
                                                  },
                                                  {
                                                    key: "answer",
                                                    class: "text-center",
                                                    label: "Resposta",
                                                  },
                                                ],
                                                items: curr.detail,
                                              },
                                              {
                                                tag: "div",
                                                class: "spacer",
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              });
                            }

                            return acc;
                          }, []),
                          {
                            tag: "tr", // foooter
                            attributes: {
                              style: "background: #dddddd;",
                            },
                            children: [
                              {
                                tag: "td",

                                contents: "",
                              },
                              ...this.selectedHeaders.map((m) => ({
                                tag: "td",
                                class: "text-bold",
                                contents: `${this.pecentByAnswers(m.value)}%`,
                              })),
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ];

        await this.printPdf({ pages: [{ title, header, body }] });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
