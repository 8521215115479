<template>
  <v-row
    v-if="selectedOptions.length > 0 || selectedCustoms.length > 0"
    class="filter-row"
  >
    <slot />
    <v-col
      v-for="(customFilter, idx) in selectedCustoms"
      :key="idx"
      class="d-flex align-center"
      :style="{ 'min-width': minWidth, 'max-width': maxWidth }"
    >
      <v-text-field
        v-model="customFilter.search"
        :label="customFilter.text"
        prepend-inner-icon="search"
        hide-details
        clearable
        filled
        class="flex-grow-1"
      />
      <v-btn
        v-if="false"
        icon
        class="close-btn"
        @click.stop="onRemoveCustomFilter(customFilter)"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss">
  .filter-row {
    margin-right: 0 !important;

    .close-btn {
      background-color: rgba(255, 255, 255, 0.1);
      margin-right: -10px;
      margin-left: 6px;
    }
  }
</style>

<script setup>
import { computed, provide } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  selectedOptions: {
    type: Array,
    default: () => [],
  },
  selectedCustoms: {
    type: Array,
    default: () => [],
  },
  minWidth: {
    type: String,
    default: '250px',
  },
  maxWidth: {
    type: String,
    default: '300px',
  },
})

// eslint-disable-next-line no-undef
const emit = defineEmits([
  'update:selectedOptions',
  'update:selectedCustoms',
  'removed',
])

const selectedOptionsLocal = computed({
  get: () => props.selectedOptions,
  set: (value) => emit('update:selectedOptions', value)
})

const onToggleFilter = (value) => {
  let updatedSelection = [...selectedOptionsLocal.value]

  if (updatedSelection.includes(value)) {
    updatedSelection = updatedSelection.filter(option => option !== value)
    emit('removed', value)
  } else {
    updatedSelection.push(value)
  }

  selectedOptionsLocal.value = updatedSelection
}

const selectedCustomsLocal = computed({
  get: () => props.selectedCustoms,
  set: (value) => emit('update:selectedCustoms', value)
})

const onRemoveCustomFilter = (item) => {
  selectedCustomsLocal.value = selectedCustomsLocal.value.filter(filter => filter.value !== item.value);
}

// Provide as variáveis para os filhos
provide('selectedOptions', selectedOptionsLocal)
provide('toggleFilter', onToggleFilter)
</script>
