<template>
  <v-dialog
    v-model="show"
    width="60%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Edição/Cadastro de Produtos</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <v-autocomplete
                  v-model="form.group"
                  label="Grupo"
                  placeholder="Selecione um Grupo"
                  :items="groupList"
                  item-text="group"
                  item-value="id"
                  :rules="isRequired"
                  @change="selectGroup"
                />
              </v-col>
              <v-col
                cols="6"
                >
                <v-text-field
                  v-model="form.name"
                  label="Nome do Produto"
                  :rules="isRequired"
                />
              </v-col>
              <v-col
                v-for="group of selectedGroup"
                :key="group.id"
                cols="12"
                >
                <v-row>
                  <v-col
                    v-for="field of group.fields"
                    :key="field.id"
                    cols="4">
                    <v-text-field
                      v-if="field.type === 'text'"
                      :value="formData[field.id] || ''"
                      :label="field.description"
                      :rules="rules(field.rules)"
                      @input="updateFormData(field.id, $event)"
                    />
                    <v-text-field
                      v-if="field.type === 'number'"
                      :value="formData[field.id] || ''"
                      :label="field.description"
                      type="number"
                      :rules="rules(field.rules)"
                      @input="updateFormData(field.id, $event)"
                    />
                    <v-autocomplete
                      v-if="field.type === 'select'"
                      :value="formData[field.id] || ''"
                      :label="field.description"
                      :items="field.values"
                      item-text="value"
                      item-value="id"
                      :rules="rules(field.rules)"
                      @input="updateFormData(field.id, $event)"
                    />

                    <masked-text-field
                      v-if="field.type === 'date'"
                      :value="formData[field.id] || ''"
                      prepend-inner-icon="event"
                      :label="field.description"
                      :mask="{ mask: '00/00/0000' }"
                      :rules="rules(field.rules)"
                      @input="updateFormData(field.id, $event)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isNull } from "lodash";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

export default {
  components: {
    MaskedTextField,
  },

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      form: {
        group: null,
        name: null,
      },

      formData: {},

      groupList: [],
      selectedGroup: null,
      isRequired: [(v) => !!v || 'Este campo é obrigatório'],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          group: null,
          field: null,
          type: null,
        };

        if (this.editId) {
          this.loadPan();
        }
      }
    }
  },

  async mounted() {
    await this.getGroups();
  },

  methods: {

    rules(rules) {
      const rulesMap = rules.map(function(rule) {
        return (rule.id === 'required') ? (v) => !!v || "Campo obrigatório" : false;
      });

      return rulesMap;
    },

    async selectGroup() {
      if (!isNull(this.form.group)) {
        this.selectedGroup = this.groupList.filter(group => group.id === this.form.group);
      }
    },

    async getGroups() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/product-group');

        this.groupList = data.map(item => {
          return {
            id: item.id_grupo_produto,
            group: item.grupo,
            fields: item.campos,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produto!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadPan() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/registrations/product/${this.editId}`);

        this.form = {
          id: data.id_produto,
          name: data.nome,
          group: data.id_grupo_produto,
        };

        this.formData = data.campos;

        this.selectGroup();

      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita uma causa no banco de dados
     */
    async save() {
      try {
        if (!await this.$refs.form.validate() && !await this.$refs.formData.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_produto: this.form.id,
          id_grupo_produto: this.form.group,
          nome: this.form.name,
          campos: JSON.stringify(this.formData),
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);

        this.$snotify.success("Serviço cadastrado com sucesso", "Sucesso");

        this.form.id = null;
        this.form.group = null;
        this.selectedGroup = null;

        for (const key in this.formData) {
          if (Object.hasOwnProperty.call(this.formData, key)) {
            this.formData[key] = null;
          }
        }

        this.close();

      } catch (e) {

        this.$snotify.error("Erro ao cadastrar serviço", "Atenção");

        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/product`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/product/${this.editId}`, payload);
      return data;
    },


    close() {
      this.show = false;
    },

    slugGenerate(str) {
      let slug = str.trim().toLowerCase();

      // Substitui acentuações por caracteres correspondentes
      slug = slug.replace(/[áàâãä]/g, 'a');
      slug = slug.replace(/[éèêë]/g, 'e');
      slug = slug.replace(/[íìîï]/g, 'i');
      slug = slug.replace(/[óòôõö]/g, 'o');
      slug = slug.replace(/[úùûü]/g, 'u');
      slug = slug.replace(/[ç]/g, 'c');

      // Substitui caracteres especiais por hífens
      slug = slug.replace(/[^\w\s-]/g, '');

      // Substitui espaços por hífens
      slug = slug.replace(/\s+/g, '-');

      return slug;
    },

    deleteField(field) {
      this.customFields = this.customFields.filter(fields => fields !== field);
    },

    getTypeById(id) {
      for (const type of this.typesList) {
        if (type.id === id) {
          return type.type;
        }
      }

      return ' - ';
    },

    idExists(array, id) {
      return array.some(item => item.id === id);
    },

    updateFormData(fieldId, event) {
      this.$set(this.formData, fieldId, event);
    }
  },
}
</script>
