<template>
  <v-menu
    offset-y
    left
    :close-on-content-click="false"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        class="ma-3"
        x-large
        outlined
        style="height: 42px;"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>
          filter_alt
        </v-icon>
        Filtros
      </v-btn>
    </template>

    <v-list nav>
      <v-list-item
        v-for="option in options"
        :key="option.value"
        link
        :class="{
          'v-list-item--active': selectedOptions.includes(option.value),
          'primary--text': selectedOptions.includes(option.value),
        }"
        @click.stop="onToggleFilter(option.value)"
      >
        <v-list-item-icon>
          <v-icon>{{ option.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ option.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="options.length > 0 && customFilters.length > 0" />

      <v-list-item
        v-for="customFilter in selectedCustoms"
        :key="customFilter.value"
      >
        <v-list-item-icon>
          <v-icon>search</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ customFilter.text }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click.stop="onRemoveCustomFilter(customFilter)"
          >
            <v-icon>clear</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-autocomplete
            v-model="customFilter"
            :items="customFilters"
            label="Personalizado"
            prepend-inner-icon="add"
            hide-no-data
            hide-selected
            hide-details
            return-object
            filled
            @change="onAddCustomFilter"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  customs: {
    type: Array,
    default: () => [],
  },
  selectedOptions: {
    type: Array,
    default: () => [],
  },
  selectedCustoms: {
    type: Array,
    default: () => [],
  },
})

// eslint-disable-next-line no-undef
const emit = defineEmits([
  'update:selectedOptions',
  'update:selectedCustoms',
  'removed',
])

const customFilters = computed(() => props.customs.filter(header => header.filterable !== false && !props.selectedCustoms.some(filter => filter.value === header.value)))

const selectedOptionsLocal = computed({
  get: () => props.selectedOptions,
  set: (value) => emit('update:selectedOptions', value)
})

const onToggleFilter = (value) => {
  let updatedSelection = [...selectedOptionsLocal.value]

  if (updatedSelection.includes(value)) {
    updatedSelection = updatedSelection.filter(option => option !== value)
    emit('removed', value)
  } else {
    updatedSelection.push(value)
  }

  selectedOptionsLocal.value = updatedSelection
}

const selectedCustomsLocal = computed({
  get: () => props.selectedCustoms,
  set: (value) => emit('update:selectedCustoms', value)
})

const customFilter = ref(null)

const onAddCustomFilter = (item) => {
  nextTick(() => {
    customFilter.value = null;
  });

  selectedCustomsLocal.value.push({ ...item, search: '' });
}

const onRemoveCustomFilter = (item) => {
  selectedCustomsLocal.value = selectedCustomsLocal.value.filter(filter => filter.value !== item.value);
}

</script>
