<template>
  <div>
    <v-autocomplete
      v-model="input"
      :loading="loading"
      :items="items"
      prepend-inner-icon="menu"
      multiple
      dark
      label="Tipos"
      item-text="description"
      item-value="id"
      return-object
      hide-details
      filled
      chips
      small-chips
      deletable-chips
      :rules="rules"
      @change="onChange"
    >
      <template
        v-if="!chips"
        v-slot:selection="{ attr, on, item, selected }"
      />

      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.description }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-list
      v-if="!chips && input.length > 0"
      dark
      dense
      class="text-left overflow-y-auto"
      style="background-color: rgba(255, 255, 255, 0.08); max-height: 190px;"
    >
      <v-list-item-group>
        <template v-for="(item, index) in input">
          <v-hover
            :key="index"
            v-slot:default="{ hover }"
          >
            <v-list-item class="pl-3">
              <v-list-item-icon class="ml-0 mr-3">
                <v-icon>
                  menu
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.description | uppercase }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  x-small
                  icon
                  dark
                  color="white"
                  @click="onRemoveItem(item)"
                >
                  <v-icon v-if="hover">
                    close
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-hover>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  filters: {
    uppercase(value) {
      return (value || "").toUpperCase();
    },
  },
  props: {
    value: Array,
    chips: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      default: () => ['COLETA_LEITE', 'VISITA_TECNICA', 'ASSISTENCIA_TECNICA']
    },
    rules: {
      type: Array,
      default: () => []
    },
    tab: String,
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // Items do Formulário (Autocomplete)
      items: [],
    };
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasCollectionRouteAccess() {
      return this.isAdmin || this.userResources.some(o => ['route-insert-edit', 'collection-route-insert-edit'].includes(o.recurso) && o.tipo === "COMPONENTE");
    },

    hasVisitsRouteAccess() {
      return this.isAdmin || this.userResources.some(o => ['route-insert-edit', 'visit-route-insert-edit'].includes(o.recurso) && o.tipo === "COMPONENTE");
    },

    hasCommercialRouteAccess() {
      return this.isAdmin || this.userResources.some(o => ['commercial-insert-edit', 'commercial-route-insert-edit'].includes(o.recurso) && o.tipo === "COMPONENTE");
    },
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/rota/listaTipoRotaJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.items = data.map(type => {
          return {
            id: type.id_tipo,
            description: (type.descricao_tipo || "").toUpperCase(),
            type: type.descricao_tipo == "Assistência Técnica" ? 'ASSISTENCIA_TECNICA' : type.tipo,
            allowedCargo: type.id_tipo === 3 ? "milk" : "general",
          };
        }).filter(({ type }) =>
          this.types.includes(type) &&
            (type == 'COLETA_LEITE' && this.hasCollectionRouteAccess ||
            type == 'ASSISTENCIA_TECNICA' && this.hasCollectionRouteAccess ||
            type == 'VISITA_TECNICA' && this.hasVisitsRouteAccess ||
            type == 'VISITA_COMERCIAL' && this.hasCommercialRouteAccess));

        if (this.input.length === 0 && this.tab === 'aba-rotas') {
          /**
           * Seleciona todos para garantir a visualização de acordo com as permissões ou tela (comercial ou captação)
           */
          this.input = this.items.filter(x => x.type == 'ASSISTENCIA_TECNICA' || x.type == 'COLETA_LEITE');
          this.$emit("input", this.items.filter(x => x.type == 'ASSISTENCIA_TECNICA' || x.type == 'COLETA_LEITE'));
        }
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os tipos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onRemoveItem(item) {
      const newList = this.input.filter(input => input.id !== item.id);

      return this.onChange(newList);
    },

    onChange(event) {
      return this.$emit("input", event);
    },
  },
};
</script>
