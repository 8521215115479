<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Cancelamento de nota</span>
        <v-spacer />
        <v-btn
          v-if="!started.show"
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="6">
            <div class="font-weight-bold">
              Número da Nota
            </div>
            <div class="text-h6 secondary--text">
              {{ number }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="font-weight-bold">
              Cliente
            </div>
            <div class="text-h6 text-truncate secondary--text">
              {{ customer }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-timeline dense>
              <v-slide-x-reverse-transition
                group
                hide-on-leave
              >
                <v-timeline-item
                  v-if="isHomolog"
                  key="ambiente"
                  color="warning"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    type="warning"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          Ambiente de Homologação
                        </div>
                        <div class="body-1">
                          Documento sem valor fiscal
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
                <v-timeline-item
                  v-if="started.show"
                  key="auth"
                  :color="started.type"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    :type="started.type"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <template #prepend>
                      <v-progress-circular
                        v-if="started.loading"
                        indeterminate
                        color="info"
                      />
                    </template>
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          {{ started.text }}
                        </div>
                        <div
                          v-if="started.detail"
                          class="body-1"
                        >
                          {{ started.detail }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
                <v-timeline-item
                  v-else
                  key="form"
                  :color="started.type"
                  small
                  fill-dot
                >
                  <v-form
                    ref="form"
                    lazy-validation
                    @submit.prevent="save()"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="justification"
                          filled
                          label="Motivo do cancelamento"
                          auto-grow
                          counter="15"
                          :rules="[
                            rules.required,
                            rules.length(15),
                          ]"
                          class="counter-success"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!started.show"
          outlined
          color="primary"
          @click="save()"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.counter-success {
  .v-counter.error--text {
    color: #43A047 !important;
    caret-color: #43A047 !important;
  }
}
</style>

<script>
import _ from 'lodash'

export default {

  data() {
    return {
      dialog: false,

      id: null,
      number: null,
      customer: null,

      justification: null,

      isHomolog: false,
      started: {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        length: len => v => (v || '').length >= len || 'Justificativa muito curta',
      },
    }
  },

  methods: {
    show({ id, env, number, customer }) {
      this.reset();
      this.isHomolog = env === 2;
      this.id = id;
      this.number = number;
      this.customer = customer;
      this.dialog = true;
    },

    reset() {
      this.isHomolog = false;
      this.started = {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      };
      this.justification = null;
    },

    close() {
      this.$emit('close');
      this.dialog = false;
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      if (this.started.show) {
        return;
      }

      this.started.show = true;
      this.started.loading = true;
      this.started.type = 'info';
      this.started.text = 'Enviando para Sefaz';

      try {

        const { data } = await this.$axios.put(`/fiscal/invoice/cancel`, {
          ids: [this.id],
          justificativa: this.justification
        });

        const { result, invoice } = data.responses[0];

        if (!result.code) {
          throw result.message;
        }

        if (invoice.status === 'CANCELADA') {
          this.started.type = 'success';
          this.started.text = 'Nota cancelada com sucesso';
        } else {
          this.started.type = 'warning';
          this.started.text = 'Rejeitado';
          this.started.detail = invoice.motivo;
        }

        this.$emit('save');
      } catch (error) {
        const message = _.get(error, 'response.data.message', error);

        this.started.type = 'error';
        this.started.text = 'Erro ao cancelar nota';
        this.started.detail = message;
      } finally {
        this.started.loading = false;
      }
    },

  },

}
</script>
