<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Tabela de Valores / {{type}}
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-tabs
      v-model="tab"
      centered
      dark
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#tab-visits">
        Visitas Definidas
      </v-tab>

      <v-tab
        href="#tab-veterinary-services"
      >
        Serviços Veterinários
      </v-tab>
      <v-tab
        href="#tab-agronomic-services"
      >
        Serviços Agronômicos
      </v-tab>
      <v-tab
        href="#tab-kilometers"
      >
        Valores Quilometragem
      </v-tab>

      <v-tab-item value="tab-visits">
        <technical-visits
          v-if="type === 'TECNICO'"
          :type="type"
        />

        <producer-visits
          v-if="type === 'PRODUTOR'"
          :type="type"
        />
      </v-tab-item>

      <v-tab-item value="tab-veterinary-services">
        <technical-veterinary-services
          v-if="type === 'TECNICO'"
          :type="type"
        />

        <producer-veterinary-services
          v-if="type === 'PRODUTOR'"
          :type="type"
        />
      </v-tab-item>

      <v-tab-item value="tab-agronomic-services">
        <technical-agronomic-services
          v-if="type === 'TECNICO'"
          :type="type"
        />

        <producer-agronomic-services
          v-if="type === 'PRODUTOR'"
          :type="type"
        />
      </v-tab-item>

      <v-tab-item value="tab-kilometers">
        <technical-kilometers
          v-if="type === 'TECNICO'"
          :type="type"
        />

        <producer-kilometers
          v-if="type === 'PRODUTOR'"
          :type="type"
        />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>

import TechnicalVisits from "@/Domains/Financial/Prices/Visits/TechnicalVisits.vue";
import TechnicalVeterinaryServices from "@/Domains/Financial/Prices/VeterinaryServices/TechnicalVeterinaryServices.vue";
import TechnicalAgronomicServices from "@/Domains/Financial/Prices/AgronomicServices/TechnicalAgronomicServices.vue";
import TechnicalKilometers from "@/Domains/Financial/Prices/Kilometers/TechnicalKilometers.vue";

import ProducerVisits from "@/Domains/Financial/Prices/Visits/ProducerVisits.vue";
import ProducerVeterinaryServices from "@/Domains/Financial/Prices/VeterinaryServices/ProducerVeterinaryServices.vue";
import ProducerAgronomicServices from "@/Domains/Financial/Prices/AgronomicServices/ProducerAgronomicServices.vue";
import ProducerKilometers from "@/Domains/Financial/Prices/Kilometers/ProducerKilometers.vue";
export default {
  components: {
    TechnicalVisits,
    TechnicalVeterinaryServices,
    TechnicalKilometers,
    TechnicalAgronomicServices,
    ProducerVisits,
    ProducerVeterinaryServices,
    ProducerAgronomicServices,
    ProducerKilometers,
  },

  props: {
    type: String,
  },

  data() {

    return {
      tab: 'tab-visits',
    };
  },
}
</script>
