
export default {
  methods: {
    async getQualityOutOfStandardReport(month, technicianId = null, routes = null, status = null) {

      let { data } = await this.$axios.post(
        `/relatorioIn/listaForaPadrao`,
        {
          id_pessoa: technicianId,
          mes: month,
          rotas: routes,
          status: status,
        }
      );

      data = data.map(item => ({
        ...item,
        cbt: parseInt(item.cbt) || 0,
        ccs: parseInt(item.ccs) || 0,
        gordura: parseFloat(item.gordura) || 0,
        proteina: parseFloat(item.proteina) || 0,
        caseina: parseFloat(item.caseina) || 0,
        solidosTotais: parseFloat(item.solidos_totais) || 0,
        coleta: parseFloat(parseFloat(item.coleta).toFixed(2)) || 0,
        distancia: parseFloat(parseFloat(item.distancia).toFixed(2)) || 0,
      }));

      return {
        cbt: [...data].sort((a, b) => b.cbt - a.cbt),
        ccs: [...data].sort((a, b) => b.ccs - a.ccs),
        gordura: [...data].sort((a, b) => a.gordura - b.gordura), // Do menor para o maior
        proteina: [...data].sort((a, b) => a.proteina - b.proteina), // Do menor para o maior
        caseina: [...data].sort((a, b) => a.caseina - b.caseina), // Do menor para o maior
        solidosTotais: [...data].sort((a, b) => a.solidosTotais - b.solidosTotais), // Do menor para o maior
        distancia: [...data].sort((a, b) => b.distancia - a.distancia),
        coleta: [...data].sort((a, b) => a.coleta - b.coleta), // Do menor para o maior
      }
    },

    generateWithinTheStandardGraph(ranges, ccs, cbt, gordura, proteina, caseina, solidosTotais) {
      const labelOption = {
        show: true,
        formatter: '{name|{a}} {c}',
        fontSize: 16,
        rich: { name: { } }
      };

      let series = [];

      if (ranges['CCS'] && ranges['CCS'].list.length > 0) {
        const ccsList = ranges['CCS'].list;
        const ccsLimitIndex = ranges['CCS'].limit;
        const ccsLimit = ccsList[ccsLimitIndex].value;

        let seriesCcs;

        if (ccsLimitIndex === ccsList.length - 1) {
          seriesCcs = [ccs.filter(item => item.ccs >= ccsLimit).length];
        } else {
          seriesCcs = [ccs.filter(item => item.ccs <= ccsLimit).length];
        }

        series.push({
          name: 'CCS',
          type: 'bar',
          barGap: 0,
          label: labelOption,
          emphasis: { focus: 'series' },
          data: seriesCcs
        })
      }
      if (ranges['CPP'] && ranges['CPP'].list.length > 0) {
        const cppList = ranges['CPP'].list;
        const cppLimitIndex = ranges['CPP'].limit;
        const cppLimit = cppList[cppLimitIndex].value;

        let seriesCpp;

        if (cppLimitIndex === cppList.length - 1) {
          seriesCpp = [cbt.filter(item => item.cbt >= cppLimit).length];
        } else {
          seriesCpp = [cbt.filter(item => item.cbt <= cppLimit).length];
        }

        series.push({
          name: 'CPP',
          type: 'bar',
          label: labelOption,
          emphasis: { focus: 'series' },
          data: seriesCpp
        })
      }
      if (ranges['Gordura'] && ranges['Gordura'].list.length > 0) {
        const gorduraList = ranges['Gordura'].list;
        const gorduraLimitIndex = ranges['Gordura'].limit;
        const gorduraLimit = ranges['Gordura'] ? gorduraList[gorduraLimitIndex].value : 0;

        let seriesGordura;

        if (gorduraLimitIndex === gorduraList.length - 1) {
          seriesGordura = [gordura.filter(item => item.gordura > gorduraLimit).length];
        } else {
          seriesGordura = [gordura.filter(item => item.gordura <= gorduraLimit).length];
        }

        series.push({
          name: 'Gordura',
          type: 'bar',
          label: labelOption,
          emphasis: { focus: 'series' },
          data: seriesGordura
        })
      }
      if (ranges['Proteína'] && ranges['Proteína'].list.length > 0) {
        const proteinaList = ranges['Proteína'].list;
        const proteinaLimitIndex = ranges['Proteína'].limit;
        const proteinaLimit = proteinaList[proteinaLimitIndex].value;

        let seriesProteina;

        if (proteinaLimitIndex === proteinaList.length - 1) {
          seriesProteina = [proteina.filter(item => item.proteina > proteinaLimit).length];
        } else {
          seriesProteina = [proteina.filter(item => item.proteina <= proteinaLimit).length];
        }

        series.push({
          name: 'Proteína',
          type: 'bar',
          label: labelOption,
          emphasis: { focus: 'series' },
          data: seriesProteina
        })
      }
      if (ranges['Caseína'] && ranges['Caseína'].list.length > 0) {
        const caseinaList = ranges['Caseína'].list;
        const caseinaIndex = ranges['Caseína'].limit;
        const caseinaLimit = caseinaList[caseinaIndex].value;

        let seriesCaseina;

        if (caseinaIndex === caseinaList.length - 1) {
          seriesCaseina = [caseina.filter(item => item.caseina > caseinaLimit).length];
        } else {
          seriesCaseina = [caseina.filter(item => item.caseina <= caseinaLimit).length];
        }

        series.push({
          name: 'Caseína',
          type: 'bar',
          label: labelOption,
          emphasis: { focus: 'series' },
          data: seriesCaseina
        })
      }
      if (ranges['Sólidos Totais'] && ranges['Sólidos Totais'].list.length > 0) {
        const solidosTotaisList = ranges['Sólidos Totais'].list;
        const solidosTotaisLimitIndex = ranges['Sólidos Totais'].limit;
        const solidosTotaisLimit = solidosTotaisList[solidosTotaisLimitIndex].value;

        let seriesSolidos;

        if (solidosTotaisLimitIndex === solidosTotaisList.length - 1) {
          seriesSolidos = [solidosTotais.filter(item => item.solidosTotais > solidosTotaisLimit).length];
        } else {
          seriesSolidos = [solidosTotais.filter(item => item.solidosTotais <= solidosTotaisLimit).length];
        }

        series.push({
          name: 'Sólidos Totais',
          type: 'bar',
          label: labelOption,
          emphasis: { focus: 'series' },
          data: seriesSolidos
        })
      }

      return {
        height: 400,
        options: {
          title: {
            text: 'Dentro do padrão',
            subtext: 'Fornecedores que atingiram os padrões mínimos',
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: ['']
          },
          series
        }
      }
    }
  }
}
