<template>
  <div>
    <v-row>
      <v-col
        md="11"
        class="pb-12"
      >
        <v-chart
          ref="chart"
          type="bar"
          :options="Options"
          :style="{width: '100%', height: `300px !important`}"
          autoresize
        />
      </v-col>
      <v-col
        v-if="media[0].goals > 0"
        md="1"
        class="pt-12"
      >
        <p>Médias </p>
        <p>Meta: {{ parseInt(media[0].goals) }} </p>
        <p>Inicial: {{ parseInt(mediaFirst) }} </p>
        <p>
          Final: {{ parseInt(media[media.length-1].media) }}
          <v-icon
            right
            :color="media[media.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
          >
            verified
          </v-icon>
        </p>
      </v-col>
      <v-col
        v-else-if="media.length > 0 && goal.media"
        md="1"
        class="pt-12"
      >
        <p>Médias </p>
        <p>Meta: {{ parseInt(goal.percentual) }} {{ (goal.tipo == "PERCENT" ? "%" : "") }} </p>
        <p>
          <v-icon
            right
            :color="media[media.length-1].icone=='verified' ? 'green darken-1' : 'red darken-1' "
          >
            verified
          </v-icon>
        </p>
      </v-col>
    </v-row>

    <!-- <v-overlay
      v-model="loading"
      absolute
    >
      Carregando criticos e prioritarios...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay> -->
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";
import isEmpty from 'lodash/isEmpty';

export default {
  name: "quality-report",

  components: {
  },

  props: {
    goal: Object,

    filter: Object,

    typeProject: String,
  },

  data() {
    return {
      // loading: false,
      media: [ { media: 0, data: "", goals: 0, goalsPerc: "", icone: "" }],
      mediaFirst: '',
      tituloGrafico: '',
      subtitle: '',
    };
  },

  computed: {
    Options() {
      return this.generateGraphOptions(this.media, this.tituloGrafico, this.subtitle);
    },
    indicador() {
      return this.goal.indicador;
    },
  },

  watch: {
    filter: {
      handler() {
        this.loadIndicators();
      },
      deep: true
    }

  },
  mounted() {
    this.loadIndicators();
  },

  methods: {
    async loadIndicators() {
      if (_.isEmpty(this.filter.project.id )) {
        return false
      }

      if (this.indicador == 'volume' ) {
        this.tituloGrafico = 'Volume de Coleta';
        await this.loadVolume();
      }
      else if (this.indicador == 'CCS') {
        this.tituloGrafico = 'Média Geométrica CCS * (1000) ' ;
        await this.loadCCS();
      }
      else if (this.indicador == 'CPP') {
        this.tituloGrafico = 'Média Geométrica CPP * (1000)' ;
        await this.loadCPP();
      }
      else if (this.indicador == 'gordura') {
        this.tituloGrafico = 'Média Aritmética Gordura';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'proteina') {
        this.tituloGrafico = 'Média Aritmética Proteína';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'caseina' ) {
        this.tituloGrafico = 'Média Aritmética Caseína';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'solidos_totais') {
        this.tituloGrafico = 'Média Aritmética Solídos Totais';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'esd' ) {
        this.tituloGrafico = 'ESD';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'lactose' ) {
        this.tituloGrafico = 'Lactose';
        await this.loadMediaIndicador();
      }
      else if (this.indicador == 'ureia' ) {
        this.tituloGrafico = 'Uréia';
        await this.loadMediaIndicador();
      }
    },

    async loadCCS() {
      this.$root.$progressBar.loading();
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaCcsPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: this.typeProject ? this.typeProject : '',
            id_produtor: this.filter.producer,
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        const media = data.map((o) => {
          return {
            media: o.ccs,
            data: o.data,
          }
        });

        await this.parseData(media);

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadCPP() {
      this.$root.$progressBar.loading();
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaCppPeriodo`,
          this.$qs.stringify({
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: this.typeProject ? this.typeProject : '',
            id_produtor: this.filter.producer,
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        const media = data.map((o) => {
          return {
            media: o.cpp,
            data: o.data,
          }
        });

        await this.parseData(media);

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadMediaIndicador() {
      this.$root.$progressBar.loading();
      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/MediaAritmeticaIndicadoresPeriodo`,
          this.$qs.stringify({
            indicador: this.indicador,
            data_ini: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: this.typeProject ? this.typeProject : '',
            id_produtor: this.filter.producer,
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          })
        );

        await this.parseData(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadVolume() {
      this.$root.$progressBar.loading();
      try {
        const { data } = await this.$axios.post(
          `/relatorios/volumeMes`,
          {
            data_inicio: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            tipo: this.typeProject ? this.typeProject : '',
            id_produtor: this.filter.producer,
            id_assistencia: this.filter.project.id ?? null,
            id_projeto_grupo: this.filter.id_project_group ?? null,
          }
        );

        const volume = data.map((o) => {
          return {
            media: o.total,
            data: o.mes + "-01",
          }
        });

        await this.parseData(volume);

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async parseData(data) {
      let tipo = 0;
      let meta = 0;
      let acao = '';

      if (_.isEmpty(data)) {
        return false;
      }

      if (!_.isEmpty(this.filter.project.quality) ) {
        JSON.parse(this.filter.project.quality).map(q => {
          if ( q.indicador == this.indicador) {
            let metaTexto = '';
            if (this.goal) {
              metaTexto =   `${q.acao} média ${q.indicador} ${q.percentual}` + (q.tipo == "PERCENT" ? "%" : "");

            }
            this.subtitle =  metaTexto ;

            meta = this.formatNumber(q.percentual);
            tipo = q.tipo;
            acao = q.acao;
          }
        })
      }

      //primeira media para ser utilizado como referencia
      if (_.isEmpty(this.mediaFirst && meta != 0)) {
        this.mediaFirst = parseInt(data[0].media);
      }

      this.media = data.filter(o => moment(o.data) >= moment(this.filter.range[0])).map(o => {
        let icone = 'cancel';
        let metaCalculada;

        if (meta != 0) {

          if (tipo == "UN") {
            metaCalculada =  meta
          } else {
            if (acao == 'REDUZIR') {
              metaCalculada = parseFloat(this.mediaFirst) - (this.mediaFirst / 100) * meta;
            } else {
              metaCalculada = parseFloat(this.mediaFirst) + (this.mediaFirst / 100) * meta;
            }
          }

          if ((acao == 'AUMENTAR' && parseFloat(o.media) > parseFloat(metaCalculada)) ||
              (acao == 'REDUZIR' && parseFloat(o.media) < parseFloat(metaCalculada)) ) {
            icone = 'verified';
          }
        } else {
          icone = '';
          metaCalculada = 0;
        }

        return {
          media: o.media || 0,
          data: o.data,
          goals: parseInt(metaCalculada),
          goalsPerc: (tipo == "PERCENT") ? this.formatNumber(meta) + ' %' : this.formatNumber(meta),
          icone: icone
        }
      });
    },

    generateGraphOptions(data, title, subtitle) {

      if (isEmpty(data)) {
        return false;
      }

      let exibeMeta = data[0].goals > 0 ?  true : false;

      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(140, 23, 23, 1)', 'rgba(140, 23, 23, 0.5)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtext: `${subtitle}`,
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 800,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 200, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });

                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.data)),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          },
        },
        series: [
          {
            name: 'Média',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => Math.abs(o.media).toFixed(0)),
          },
          exibeMeta ?
            {
              name: 'Meta',
              type: 'line',
              lineStyle: { width: 2 },
              emphasis: {
                focus: 'series'
              },
              label: {
                show: false,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o =>  Math.abs(o.goals).toFixed(0)),
            }
            : null,
        ]
      };
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
