<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Spots - Controle Matéria Prima
        </h2>

        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      dark
      color="transparent diff-list"
      style="margin-top:20px"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="4"
            class="py-0"
          >
            <date-range-picker
              v-model="filters.date.input"
              @change="onDateFilter"
            />
          </v-col>

          <v-col
            cols="4"
            class="py-0"
          >
            <v-autocomplete
              v-model="filters.companies"
              :items="dairies"
              clearable
              filled
              hide-details
              prepend-inner-icon="how_to_reg"
              label="Fornecedor"
              multiple
              small-chips
              item-text="name"
              item-value="id"
              return-object
            />
          </v-col>

          <v-col
            cols="4"
            class="py-0"
          >
            <v-autocomplete
              v-model="filters.rawMaterials"
              :items="rawMaterials"
              clearable
              filled
              hide-details
              prepend-inner-icon="fact_check"
              label="Matéria Prima"
              multiple
              small-chips
              item-text="description"
              item-value="id"
              return-object
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        item-key="id"
        name="Spots - Controle Matéria Prima"
        dark
        :loading="loading"
        :headers="headers"
        :items="filteredList"
        :search="filters.search"
        show-custom-group
        editing
      >
        <template #[`item.company.name`]="{ item }">
          {{ item.company.name }} &nbsp;

          <v-tooltip
            v-if="!item.spot.id"
            top
          >
            <template #activator="{ on }">
              <v-icon
                small
                v-on="on"
              >
                info
              </v-icon>
            </template>

            Spot não vinculado a nenhum planejamento
          </v-tooltip>
        </template>

        <template #[`item.type`]="{ value }">
          <v-chip
            x-small
            :color="value === 'ENTRADA' ? 'green' : 'red'"
          >
            {{ value }}
          </v-chip>
        </template>

        <template #[`item.rawMaterial.description`]="{ value }">
          <v-chip x-small>
            {{ value }}
          </v-chip>
        </template>

        <template #[`footer.prepend`]>
          <div class="text-caption text-left ml-4">
            Volume Total Realizado (L)
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatInt(totalRealizedVol) }}
            </v-chip>
          </div>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </div>
</template>

<style lang="scss">
.diff-list {
  .financial-badge {
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 0px;
  }
}
</style>

<script>
import moment from "moment-timezone";
import * as _ from "lodash";

import DateRangePicker from "@/Support/Components/DateRangePicker.vue";

export default {
  components: {
    DateRangePicker,
  },

  data() {
    return {
      loading: false,

      filters: {
        date: {
          input: 'thisMonth',
          range: [
            moment().startOf('month').format('YYYY-MM-DD'),
            moment().endOf('month').format('YYYY-MM-DD'),
          ],
        },
        search: '',

        companies: [],

        rawMaterials: [],
      },

      dairies: [],

      rawMaterials: [],

      headers: [
        { text: 'Fornecedor', value: 'company.name', width: 200, align: 'center' },
        { text: 'Unidade', value: 'dairy.name', width: 200, align: 'center' },
        { text: 'Código', value: 'unload.code', width: 200, align: 'center', show: false },
        { text: 'Nº Nota', value: 'unload.invoiceNumber', width: 200, align: 'center' },
        { text: 'Nome Motorista', value: 'unload.driverName', width: 200, align: 'center', show: false },
        { text: 'Placa', value: 'unload.vehiclePlate', width: 200, align: 'center', show: false },
        { text: 'Entrada/Saída', value: 'type', width: 150, align: 'center' },
        { text: 'Matéria Prima', value: 'rawMaterial.description', width: 200, align: 'center' },
        { text: 'Data/Hora', value: 'unloadedAt', width: 150, formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm'), align: 'center' },
        { text: 'Volume Planejado (L)', value: 'planned.vol', width: 80, align: 'center', formatter: value => this.formatInt(value) },
        { text: 'Preço Unit.', value: 'planned.unitAmount', width: 80, align: 'center', formatter: value => this.formatCurrency(value) },
        { text: 'Valor Carga (R$)', value: 'planned.amount', width: 80, align: 'center', formatter: value => this.formatCurrency(value) },
        { text: 'Volume Realizado (L)', value: 'realized.vol', width: 80, align: 'center', formatter: value => this.formatInt(value) },
        { text: 'Valor Real (R$)', value: 'realized.amount', width: 80, align: 'center', formatter: value => this.formatCurrency(value) },
        { text: 'Temperatura (Méd.)', value: 'averages.temp', width: 80, align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'PH (Méd.)', value: 'averages.ph', width: 80, align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Gordura (Méd.)', value: 'averages.fat', width: 80, align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Sólidos Totais (Méd.)', value: 'averages.totalSolids', width: 80, align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Acidez (Méd.)', value: 'averages.acidity', width: 80, align: 'center', formatter: value => this.formatNumber(value) },
      ],

      items: [],
    };
  },

  computed: {

    filteredList() {
      return this.items.filter(item => {
        const hasCompany = this.filters.companies.some(company => item.company.id === company.id);
        const hasRawMaterial = this.filters.rawMaterials.some(rawMaterial => item.rawMaterial.id === rawMaterial.id);

        if (_.isEmpty(this.filters.companies) && _.isEmpty(this.filters.rawMaterials)) {
          return true;
        }

        if (!_.isEmpty(this.filters.companies) && !_.isEmpty(this.filters.rawMaterials)) {
          return hasCompany && hasRawMaterial;
        }

        if (!_.isEmpty(this.filters.companies)) {
          return hasCompany;
        }

        if (!_.isEmpty(this.filters.rawMaterials)) {
          return hasRawMaterial;
        }

        return true;
      });
    },

    totalRealizedVol() {
      return _.sumBy(this.filteredList, (row) => row.realized.vol);
    }
  },

  async mounted() {
    await this.loadDairies();
    await this.loadRawMaterials();
    await this.loadReport();
  },

  methods: {
    onDateFilter(event) {
      this.filters.date.range = event;

      return this.loadReport();
    },

    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.date.range;

        const { data } = await this.$axios.post(
          `/spot/planejadoRealizado`,
          this.$qs.stringify({
            data_inicio: startDate || moment().format('YYYY-MM-DD'),
            data_fim: endDate || moment().format('YYYY-MM-DD'),
          })
        );

        this.items = data.map(row => {
          return {
            id: row.id_itinerario,
            type: row.tipo,
            spot: {
              id: row.id_spot,
            },
            dairy: {
              id: row.id_laticinio,
              name: row.nome_laticinio,
            },
            company: {
              id: row.id_empresa,
              name: row.nome_empresa,
            },
            unload: {
              code: row.cod_itinerario,
              invoiceNumber: row.numero_nota,
              driverName: row.nome_motorista,
              vehiclePlate: row.placa_veiculo,
            },
            unloadedAt: row.data_hora_descarga,
            rawMaterial: {
              id: row.id_materia_prima,
              description: row.nome_materia_prima,
            },
            planned: {
              vol: row.volume_planejado,
              amount: row.valor_total,
              unitAmount: row.preco_unitario_planejado,
            },
            realized: {
              vol: row.volume_realizado,
              amount: row.valor_realizado,
            },
            averages: {
              temp: row.temperatura_media,
              ph: row.ph_medio,
              fat: row.gordura_media,
              totalSolids: row.solidos_medio,
              acidity: row.acidez_media,
            }
          }
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadDairies() {
      try {
        const { data } = await this.$axios.get(`/laticinio/listaJson`);

        this.dairies = data.map((dairy) => {
          return {
            id: dairy.id_laticinio,
            name: dairy.nome,
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadRawMaterials() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(
          `/item`,
          {
            params: {
              tipo_materia_prima: 'TODOS'
            }
          }
        );

        this.rawMaterials = data.map(materiaPrima => {
          return {
            id: materiaPrima.id_item,
            description: materiaPrima.nome,
            type: materiaPrima.tipo,
            rawMaterialGroupId: materiaPrima.id_agrupador_materia_prima,
          }
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as matérias primas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Spots - Controle de Matéria Prima - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `Spots - Controle de Matéria Prima - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
      this.$refs.report.print(null, title);
    },

    formatCurrency: (value) => _.isNull(value) ? '-' : new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    formatNumber: (value) => _.isNull(value) ? '-' : new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 4 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatInt: (value) => _.isNull(value) ? '-' : new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0 }).format(value),
    truncate: (value, length) => _.truncate(value, { length }),
  },

}
</script>
