<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Cadastrar Tipo de Animal
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
          </v-col>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          @click:row="showDialog"
        >

          <template #[`item.fields`]="{ value }">
            <v-row
              v-for="field of value"
              :key="field.id"
            >
              <v-col
                cols="4"
              >
                Campo:
                <v-chip
                  small
                  color="green darken-1"
                >
                  {{ field.description }}
                </v-chip>
              </v-col>
              <v-col
                cols="3"
              >
                Tipo:
                <v-chip
                  small
                  color="gray darken-1"
                >
                  {{ getTypeById(field.type) }}
                </v-chip>
                <v-chip
                  v-if="field.type === 'select'"
                  small
                  color="gray darken-2"
                >
                  {{ formatValues(field.values) }}
                </v-chip>
              </v-col>
              <v-col
                cols="4"
              >
                Regras:
                <v-chip
                  v-for="rule of field.rules"
                  :key="rule.id"
                  small
                  color="red darken-1"
                >
                  {{ rule.rule }}
                </v-chip>
              </v-col>
            </v-row>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="showDialog(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      edit
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Editar
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="deleteItem(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Excluir
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        color="purple"
        @click="showDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Cadastrar Grupo
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <add-inventory-animal-type-dialog
      v-model="edit"
      :edit-id.sync="editId"
      @save="loadItems"
    />
  </div>
</template>

<script>
import AddInventoryAnimalTypeDialog from "@/Domains/Projects/HealthExamination/Components/AddInventoryAnimalTypeDialog.vue";
export default {

  components: {
    AddInventoryAnimalTypeDialog,
  },

  data() {
    return {
      items: [],

      filters: {
        search: "",
      },

      edit: false,
      editId: null,
    };
  },

  computed: {
    headers() {
      return [
        { text: 'Tipo de Animal', value: 'description', align: 'start'},
        { text: '', value: '', align: 'end' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
      ];
    },

    //Pega os dados de permissão do usúario.
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('/projects/animals-type');

        this.items = data.map(item => {
          return {
            id: item.id_tipo_animal,
            description: item.descricao,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar tipos de animal!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showDialog({ id }) {
      this.edit = true;
      this.editId = id;
    },

    async deleteItem(Item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir o Tipo ${Item.description} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/projects/animals-type/${Item.id}`);

        this.$snotify.success("Tipo de animal excluído com sucesso", "Sucesso");
        return await this.loadItems();
      } catch (e) {
        this.$snotify.error("Erro ao excluir tipo de animal", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
  },
}
</script>
