<template>
  <div
    class="mx-6 px-16 pt-0 pb-7"
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Prospectados
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-select
          v-model="filters.type"
          :items="[
            { text: 'Data de cadastro', value: 'dataCadastro' },
            { text: 'Data de desvinculação', value: 'dataDesvinculacao' },
          ]"
          prepend-inner-icon="today"
          label="Tipo Data"
          dark
          hide-details
          filled
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-date-range-picker
          v-model="filters.dateRange"
          label="Período"
          clearable
          :ranges="dateRanges"
        />
      </v-col>

      <v-spacer />

      <v-col
        cols="12"
        sm="6"
        md="5"
        class="d-flex"
      >
        <v-text-field
          v-model="filters.search"
          label="Buscar código, cidade ou nome"
          prepend-inner-icon="search"
          maxlength="50"
          dark
          filled
          hide-details
          clearable
        />
        <v-btn
          x-large
          outlined
          class="ml-4"
          style="height: 42px;"
          @click="onClickFilter"
        >
          Buscar Dados
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      dark
      color="transparent"
    >
      <v-tabs
        ref="tabs"
        v-model="tab"
        background-color="rgba(0, 0, 0, 0.5)"
        class="transparent"
        dark
        right
        icons-and-text
      >
        <v-tab
          v-for="status in tabs"
          :key="status"
          :href="'#tab-' + status"
        >
          <span class="ma-0">
            <v-chip
              :color="getStatusColor(status)"
              small
            />
            {{ prospectionStatus(status) }}
            <template v-if="status === 'TODOS'">
              <span v-if="producers.length > 0">({{ producers.length }})</span>
            </template>
            <template v-else>
              <span v-if="totalByStatus[status] > 0">({{ totalByStatus[status] }})</span>
            </template>
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="transparent"
      >
        <v-tab-item
          v-for="status in tabs"
          :key="status"
          :value="'tab-' + status"
        >
          <v-card-title class="pt-0" />

          <data-table
            :ref="'report-' + status"
            name="Prospectados"
            :headers="headers"
            :items="getProducers(status)"
            :loading="loading"
            dark
            show-custom-group
            @click:row="onProducerClick"
          >
            <template #[`item.anexos`]="{ value }">
              <div v-if="value.length > 0">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="showAttachments(value)"
                >
                  attach_file
                </v-icon>
              </div>
              <div v-else>
                <v-chip
                  pill
                  outlined
                  x-small
                >
                  -
                </v-chip>
              </div>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="hasInsertUpdateAccess"
                    @click.stop="onEditProducer(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click.stop="showProducerVisits(item.id_pessoa)"
                  >
                    <v-list-item-icon>
                      <v-icon>event</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Visitas</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasPrintAccess"
                    @click.stop="printProducer(item.id_pessoa)"
                  >
                    <v-list-item-icon>
                      <v-icon>print</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Imprimir</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasDeleteAccess"
                    @click="deleteProducer(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir produtor</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <dialog-producer
      v-if="producerDialog.show"
      :visible="producerDialog.show"
      :producer-id.sync="producerDialog.id"
      type="PROSPECTION"
      link-producer
      :open-link="producerDialog.openLink"
      @close="onCloseProducer"
      @onSave="onSaveProducer"
    />

    <prospection-approval-dialog
      v-model="approvalDialog.show"
      :producer-id="approvalDialog.id"
      @close="onCloseApproval()"
      @save="onSaveApproval()"
      @editProducer="onEditProducer"
      @showProducerVisits="showProducerVisits"
      @printProducer="printProducer"
    />

    <v-dialog
      v-model="attachmentsDialog.show"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Anexos</span>
        </v-card-title>

        <v-card-text>
          <attachments-tab :attachments="attachmentsDialog.attachments" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="visitsDialog.show"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Visitas</span>
        </v-card-title>

        <v-timeline
          align-top
          reverse
          dense
          class="ml-4"
        >
          <timeline-visit-item
            v-for="(item, idx) in visitsDialog.timeline"
            :key="idx"
            :item="item"
            show-date
          />
        </v-timeline>
      </v-card>
    </v-dialog>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <print-settings-dialog
      ref="print-settings"
      @print="onPrintProducer"
    />

    <v-overlay
      v-model="loading"
      absolute
    >
      <v-card-text>
        Carregando
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from 'print-js';

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import DialogProducer from "@/Domains/Producer/Management/Components/Dialogs/InfoProducer.vue";
import ProspectionApprovalDialog from "@/Domains/Producer/Management/Components/Dialogs/ProspectionApprovalDialog.vue";
import AttachmentsTab from "@/Domains/Registrations/Person/Components/AttachmentsTab.vue";
import TimelineVisitItem from "@/Domains/Itineraries/Components/TimelineVisitItem.vue";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";

const visitsService = new VisitsService();

export default {
  name: "ProducerProspections",

  components: {
    DialogProducer,
    ProspectionApprovalDialog,
    AttachmentsTab,
    VDateRangePicker,
    TimelineVisitItem,
    PrintSettingsDialog
  },

  data() {
    return {
      loading: true,
      tab: null,

      dateRanges: {
        'Última Semana': [
          moment().subtract(6, "days").startOf("days").format("YYYY-MM-DD"),
          moment().subtract("days").endOf("days").format("YYYY-MM-DD")
        ],
        'Trimestral': [
          moment().subtract(2, "month").startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD")
        ],
        'Semestral': [
          moment().subtract(5, "month").startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD")
        ],
        'Este Ano': [
          moment().startOf("year").format("YYYY-MM-DD"),
          moment().endOf("year").format("YYYY-MM-DD")
        ],
        'Ano Anterior': [
          moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
          moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
        ],
      },

      filters: {
        search: '',
        type: 'dataCadastro',
        dateRange: [],
      },

      headers: [
        { text: "C. Laticínio", value: "codigo_laticinio", align: "center" },
        { text: "Produtor", value: "nome", align: "center" },
        { text: "Data de cadastro", value: "data_cadastro_app", align: "center"  },
        { text: "CCS", value: "ccs", align: "center" },
        { text: "CPP", value: "cbt", align: "center" },
        { text: "Vol.Leite", value: "litros", align: "center" },
        { text: "Técnico", value: "tecnico", align: "center" },
        { text: "Anexos", value: "anexos", align: "center", formatter: value => value.length },
        { text: "Sif", value: "sif", align: "center" },
        { text: "CPF/CNPJ do Titular", value: "cnpj_cpf", align: "center" },
        { text: "Nº de Identidade (RG)", value: "rg", align: "center" },
        { text: "Nº Nirf", value: "nirf", align: "center" },
        { text: "Código NRP", value: "nrp", align: "center" },
        { text: "Inscrição Estadual", value: "inscricao_estadual", align: "center" },
        { text: "Data de Nascimento", value: "data_nascimento", align: "center", formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: "Número de Ordenhas", value: "numero_ordenha", align: "center" },
        { text: "Capacidade dos Resfriadores (L)", value: "capacidade_resf_inte", align: "center" },
        { text: "Janela de Coleta - Inicio", value: "hora_inicio_coleta", align: "center" },
        { text: "Janela de Coleta - Final", value: "hora_fim_coleta", align: "center" },
        { text: "Realizado Coleta à Cada (H)", value: "frequencia_coleta", align: "center" },
        { text: "Cadastro de Produtores rurais", value: "cad_pro", align: "center" },
        { text: "Cooperativa", value: "cooperativa", align: "center" },
        { text: "Celular", value: "celular", align: "center" },
        { text: "Telefone", value: "telefone", align: "center" },
        { text: "Região", value: "regiao", align: "center" },
        { text: "Rota Principal de Coleta", value: "rota", align: "center" },
        { text: "CEP", value: "end_cep", align: "center" },
        { text: "Estado", value: "end_estado", align: "center" },
        { text: "Cod. Cidade", value: "end_cidade_codigo", align: "center" },
        { text: "Cidade", value: "end_cidade", align: "center" },
        { text: "Rua", value: "end_rua", align: "center" },
        { text: "Número", value: "end_numero", align: "center" },
        { text: "Bairro", value: "end_bairro", align: "center" },
        { text: "Latitude", value: "end_latitude", align: "center" },
        { text: "Longitude", value: "end_longitude", align: "center" },
        { text: "Status", value: "status", align: "center", formatter: value => this.prospectionStatus(value) },
        { text: "Dia do Pagamento", value: "dia_pagamento", align: "center" },
        { text: "Banco", value: "banco", align: "center" },
        { text: "Forma de pagamento", value: "forma_pagamento", align: "center" },
        { text: "Configuração de Taxas", value: "config_taxas_local", align: "center" },
        { text: "E-mail", value: "email", align: "center" },
        { text: "Tipo de Ordenha", value: "tipo_ordenha", align: "center" },
        { text: "Código SDA", value: "codigo_sda", align: "center" },
        { text: "Preço oferecido", value: "preco_leite", align: "center", formatter: value => this.formatCurrency(value) },
        { text: "Preço Concorrência", value: "preco_concorrencia", align: "center", formatter: value => this.formatCurrency(value) },
        { text: "Laticínio Concorrente", value: "laticinio_origem", align: "center" },
        { text: "Observações de Negociações", value: "observacao", align: "center" },
        { text: "Opções", altText: "Opções", value: "action", sortable: false, align: "center" }
      ],

      producers: [],

      approvalDialog: {
        show: false,
        id: null,
      },

      visitsDialog: {
        show: false,
        timeline: [],
      },

      producerDialog: {
        show: false,
        openLink: false,
        id: null
      },

      attachmentsDialog: {
        show: false,
        attachments: []
      }
    };
  },

  computed: {
    totalByStatus() {
      return this.producers.reduce((acc, cur) => {
        if (!(cur.status in acc)) {
          acc[cur.status] = 0;
        }
        acc[cur.status]++;
        return acc;
      }, {})
    },

    tabs() {
      // Pode ver apenas abas de aprovação de captação?
      if (this.hasCaptationTabsOnlyAccess) {
        return ['APROVACAO', 'APROVADO_CAPTACAO', 'APROVADO_QUALIDADE', 'REPROVADO'];
      }

      // Pode ver apenas abas de aprovação de qualidade?
      if (this.hasQualityTabsOnlyAccess) {
        return ['APROVACAO', 'APROVADO_CAPTACAO', 'APROVADO_QUALIDADE', 'REPROVADO_QUALIDADE'];
      }

      const tabs = ['TODOS', 'NEGOCIACAO', 'LEAD', 'APROVACAO'];

      // Pode ver abas de aprovação de captação?
      // Pode ver abas de aprovação de qualidade?
      if (this.hasCaptationTabsAccess || this.hasQualityTabsAccess) {
        tabs.push('APROVADO_CAPTACAO', 'APROVADO_QUALIDADE');
      }

      if (this.hasQualityTabsAccess) {
        tabs.push('REPROVADO_QUALIDADE');
      }

      if (this.hasCaptationTabsAccess || (!this.hasCaptationTabsAccess && !this.hasQualityTabsAccess)) {
        tabs.push('REPROVADO');
      }

      tabs.push('DESVINCULADO');

      return tabs;
    },

    /**
     * Permissões de tela
     */
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasInsertUpdateAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-insert-edit" && o.tipo === "COMPONENTE");
    },

    hasDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-delete" && o.tipo === "COMPONENTE");
    },

    hasPrintAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-print" && o.tipo === "COMPONENTE");
    },

    hasCaptationTabsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-prospection-captation-tabs" && o.tipo === "COMPONENTE");
    },

    hasQualityTabsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-prospection-quality-tabs" && o.tipo === "COMPONENTE");
    },

    hasCaptationTabsOnlyAccess() {
      return !this.isAdmin && this.userResources.some(o => o.recurso === "producer-prospection-captation-tabs-only" && o.tipo === "COMPONENTE");
    },

    hasQualityTabsOnlyAccess() {
      return !this.isAdmin && this.userResources.some(o => o.recurso === "producer-prospection-quality-tabs-only" && o.tipo === "COMPONENTE");
    },

    hasApprovalAccess() {
      return this.isAdmin || this.userResources
        .some(o => ["producer-prospection-quality-approval", "producer-prospection-captation-approval"].includes(o.recurso) && o.tipo === "COMPONENTE");
    },
  },

  mounted() {
    this.loading = false;
  },

  methods: {
    async loadProducers() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.dateRange || [];

        const { data } = await this.$axios.post(
          `/produtores/listaProdutoresProspectados`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            type_date: this.filters.type,
            busca: this.filters.search,
          }
        );

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.producers = await data.map(data => {
          return {
            ...data,
            anexos: JSON.parse(data.anexos) || [],
          };
        });
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
        this.$refs.tabs && this.$refs.tabs.onResize();
      }
    },

    getProducers(status) {
      if (status === 'TODOS') {
        return this.producers;
      }

      return this.producers.filter(item => item.status === status);
    },

    onClickFilter() {
      if (_.isEmpty(this.filters.search) && (_.isEmpty(this.filters.dateRange) || !this.filters.dateRange[0])) {
        this.$snotify.info("Preencha a data ou realize uma busca.", "Aviso");
        return;
      }

      this.loadProducers();
    },

    /**
     * Abre o dialog de edição de produtor
     */
    onEditProducer(producer) {
      if (!this.hasInsertUpdateAccess) {
        return;
      }

      this.producerDialog = {
        show: true,
        openLink: producer.openLink || false,
        id: producer.id_pessoa
      };
    },

    /**
     * Fecha dialog de edição de produtor
     */
    onCloseProducer() {
      this.producerDialog = {
        show: false,
        openLink: false,
        id: null
      };
    },

    /**
     * Fecha dialog de edição e carrega os produtores
     */
    onSaveProducer() {
      this.producerDialog = {
        show: false,
        openLink: false,
        id: null
      };
      this.loadProducers();
    },

    showAttachments(attachments) {
      this.attachmentsDialog = {
        show: true,
        attachments
      }
    },

    onProducerClick(producer) {
      if (this.hasApprovalAccess && ['APROVACAO', 'APROVADO_QUALIDADE', 'REPROVADO_QUALIDADE', 'APROVADO_CAPTACAO', 'REPROVADO'].includes(producer.status)) {
        return this.onApproval(producer)
      }

      return this.onEditProducer(producer);
    },

    onApproval(producer) {
      this.approvalDialog.id = producer.id_pessoa;
      this.approvalDialog.show = true;
    },

    onSaveApproval() {
      this.onCloseApproval();
      this.loadProducers();
    },

    onCloseApproval() {
      this.approvalDialog.show = false;
      this.approvalDialog.id = null;
    },

    async showProducerVisits(id_pessoa) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/listaVisitaProdutor`,
          { id_pessoa }
        );

        if (data.length === 0) {
          this.$snotify.info("Não foram encontradas visitas para este produtor!", "Aviso");
          return;
        }

        this.visitsDialog.timeline = data.map(visit => {
          const date = moment(visit.data_visita);
          const time = moment(visit.hora_chegada, 'HH:mm:ss');

          return {
            date: date.hour(time.hour()).minute(time.minute()).second(time.minute()),
            startedAt: visit.hora_chegada,
            endedAt: visit.hora_saida,
            obs: visit.observacao,
            types: visit.tipo.map(tipo => visitsService.types[tipo] || null),
            pictures: _.map(visit.fotos, (value, key) => ({
              src: key,
              thumb: key,
              caption: value,
            })),
            producer: {
              name: visit.nome,
              code: visit.codigo_laticinio
            },
          };
        });

        this.visitsDialog.show = true;

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as visitas do produtor!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Produtores Prospectados - ${moment(startDate || moment()).format('DD.MM.YY')} - ${moment(endDate || moment()).format('DD.MM.YY')}`;
    },

    exportExcel() {
      const report = this.tab.replace('tab', 'report');
      const filename = this.getReportTitle();
      this.$refs[report][0].exportExcel(null, filename);
    },

    print() {
      const report = this.tab.replace('tab', 'report');
      const title = this.getReportTitle();
      this.$refs[report][0].print(null, title, true);
    },

    printProducer(id) {
      this.$refs['print-settings'].show({
        item: {
          id
        },
        params: [
          { title: 'Endereço', key: 'mostra_endereco', value: true },
          { title: 'Dados de Pagamento', key: 'mostra_dados_pagamento', value: true },
          { title: 'Dados de Produção', key: 'mostra_dados_producao', value: true },
          { title: 'Checklist Prospecção', key: 'mostra_checklist', value: false },
          { title: 'Documentos assinados', key: 'mostra_documentos_assinados', value: false },
          { title: 'Preço/Negociação', key: 'mostra_negociacoes_preco', value: false },
          { title: 'Visita Prospecção', key: 'mostra_ultima_visita', value: false },
          { title: 'Dados de cônjuges', key: 'mostra_dados_conjuges', value: false },
        ]
      });
    },

    async onPrintProducer({ item,  params }) {
      try {
        this.loading = true;

        if (_.isEmpty(item.id)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/produtores/printDetalheProdutor`,
          {
            id_produtor: item.id,
            params
          }
        );

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Método para exclusão do produtor
     * @param {Object} producer
     */
    async deleteProducer(producer) {
      try {
        if (!(await this.$root.$confirm('Atenção', `Realmente deseja <b> Excluir </b>o(a) produtor(a) <b>${producer.nome}</b> ?`, { color: 'red' }))) {
          return;
        }

        this.loading = true;

        if (!producer.id_pessoa) {
          throw "Produtor não informado";
        }

        const data = await this.$axios.post(
          `/produtores/deleteProdutor`,
          this.$qs.stringify({
            id_produtor: producer.id_pessoa,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        if (data.codigo === 0) {
          throw "Erro ao deletar produtor";
        }

        this.$snotify.success("Produtor deletado com sucesso!", "Sucesso");
        this.loadProducers();
      } catch (error) {
        console.log(error);

        this.$snotify.error("Erro ao deletar produtor ", "Atenção!!");
      } finally {
        this.loading = false;
      }
    },

    prospectionStatus(value) {
      const status = {
        TODOS: "Todos",
        NEGOCIACAO: "Negociação",
        APROVACAO: "Aprovação",
        LEAD: "Observação",
        APROVADO_CAPTACAO: "Aprovado (Captação)",
        REPROVADO: !this.hasCaptationTabsAccess ? "Reprovado" : "Reprovado (Captação)",
        APROVADO_QUALIDADE: "Aprovado (Qualidade)",
        REPROVADO_QUALIDADE: "Reprovado (Qualidade)",
        DESVINCULADO: "Desvinculado",
      };

      return status[value] || '-';
    },

    getStatusColor(value) {
      const status = {
        TODOS: "light-blue",
        NEGOCIACAO: "blue darken-2",
        APROVACAO: "teal",
        LEAD: "amber",
        APROVADO_CAPTACAO: "green darken-2",
        REPROVADO: "red darken-4",
        APROVADO_QUALIDADE: "green",
        REPROVADO_QUALIDADE: "red",
        DESVINCULADO: "grey",
      };

      return status[value] || 'blue';
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
