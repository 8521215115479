<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Fora da In
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="4"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onDateFilter"
        />
      </v-col>
      <v-col
        md="4"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="loadReport"
        />
      </v-col>
      <v-col
        md="4"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="loadReport"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="4"
      >
        <v-autocomplete
          v-model="filter.cbt"
          :items="cbtList"
          label="CPP mês"
          prepend-inner-icon="opacity"
          dark="dark"
          filled
          clearable
        />
      </v-col>
      <v-col
        md="4"
      >
        <v-autocomplete
          v-model="filter.visit"
          :items="visitList"
          label="Visitas"
          prepend-inner-icon="icon-visitas"
          dark
          filled
          clearable
        />
      </v-col>
      <v-col
        md="4"
      >
        <v-autocomplete
          v-model="filter.status"
          :items="[
            { text: 'Ativos', value: 'ATIVOS' },
            { text: 'Desvinculados', value: 'DESVINCULADOS' },
          ]"
          label="Exibir"
          placeholder=" "
          hide-details
          dark
          filled
          @change="loadReport"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="selectAllProducers = ''"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        {{ tab.title }}
      </v-tab>

      <v-tab-item
        v-for="tab in tabs"
        :key="tab.title"
        background-color="transparent"
        dark
      >
        <v-card
          class="v-tamanho mt-2"
          color="transparent"
          dark
        >
          <v-card-title>
            {{ `Produtores: ${ tab.items.length / 2}` }}
            <v-spacer />
            <v-col
              md="3"
              md-3
              sm-3
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
              />
            </v-col>
          </v-card-title>
          <v-simple-table
            dense
            class="elevation-1"
            dark
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :width="header.width"
                    :class="{[`text-${header.align}`]: header.align}"
                  >
                    <template
                      v-if="header.value == 'checkbox'"
                    >
                      <v-tooltip
                        top
                        :disabled="!!filter.technician.id"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-checkbox
                              v-model="selectAllProducers"
                              :disabled="!filter.technician.id"
                            />
                          </span>
                        </template>
                        Escolha o técnico para habilitar
                      </v-tooltip>
                    </template>
                    <template v-else>
                      {{ header.text }}
                    </template>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, row) in tab.items"
                  :key="row"
                >
                  <td
                    v-for="(header, col) in headers"
                    :key="col"
                    :class="{[`text-${header.align}`]: header.align}"
                    v-bind="handleSpan(row, col)"
                  >
                    <template
                      v-if="header.value == 'checkbox'"
                    >
                      <v-tooltip
                        top
                        :disabled="!!filter.technician.id"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-checkbox
                              v-model="selectedProducers"
                              :value="row"
                              :disabled="!filter.technician.id"
                            />
                          </span>
                        </template>
                        Escolha o técnico para habilitar
                      </v-tooltip>
                    </template>

                    <template
                      v-if="months.includes(header.value) && (row % 2 == 1)"
                    >
                      <span
                        :class="{'red--text font-weight-bold': item[header.value] >= 300 }"
                      >
                        {{ item[header.value] | parseEmpty }}
                      </span>
                    </template>

                    <template
                      v-else-if="header.value == 'last_visit'"
                    >
                      {{ item[header.value] | dateFormat('DD/MM/YYYY') }}
                    </template>

                    <template
                      v-else-if="header.value == 'last_analysis'"
                    >
                      <v-tooltip
                        color="#fff"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <span
                            :class="{'red--text font-weight-bold': item.last_analysis > 300 }"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item[header.value] | parseEmpty }}
                          </span>
                        </template>
                        <div
                          v-for="(cbt, i) in item.cbt_month_list"
                          :key="i"
                          class="black--text"
                        >
                          {{ cbt.data | dateFormat('DD/MM/YYYY') }}: {{ cbt.cbt }}
                        </div>
                      </v-tooltip>
                    </template>

                    <template
                      v-else-if="header.value == 'status'"
                    >
                      <v-chip
                        v-if="item[header.value] == 'DESVINCULADO'"
                        color="red"
                        small
                      >
                        <v-avatar
                          left
                          small
                        >
                          <v-icon>cancel</v-icon>
                        </v-avatar>
                        Desvinculado
                      </v-chip>
                      <v-chip
                        v-else-if="item[header.value] == 'SUSPENSO'"
                        color="orange"
                        small
                      >
                        <v-avatar
                          left
                          small
                        >
                          <v-icon>remove_circle</v-icon>
                        </v-avatar>
                        Suspenso
                      </v-chip>
                      <v-chip
                        v-else
                        color="green"
                        small
                      >
                        <v-avatar
                          left
                          small
                        >
                          <v-icon>check_circle</v-icon>
                        </v-avatar>
                        Ativo
                      </v-chip>
                    </template>

                    <template
                      v-else-if="header.value == 'action'"
                    >
                      <v-menu
                        bottom
                        right
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            v-if="item.status == 'DESVINCULADO'"
                            @click="linkProducer(item)"
                          >
                            <v-list-item-icon>
                              <v-icon>person_add</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Vincular</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-else-if="item.status == 'SUSPENSO'"
                            @click="activateProducer(item)"
                          >
                            <v-list-item-icon>
                              <v-icon>person_add</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Reativar</v-list-item-title>
                          </v-list-item>
                          <template v-else>
                            <v-list-item
                              v-if="item.status != 'DESVINCULADO'"
                              @click="unlinkProducer(item)"
                            >
                              <v-list-item-icon>
                                <v-icon>person_add_disabled</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Desvincular</v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              v-if="item.status != 'SUSPENSO'"
                              @click="suspendProducer(item)"
                            >
                              <v-list-item-icon>
                                <v-icon>remove_circle_outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Suspender</v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-else-if="header.value !== 'checkbox'">
                      {{ item[header.value] | parseEmpty }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <status-producer-dialog
      ref="statusProducerDialog"
      @save="loadReport"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        v-show="!!filter.technician.id"
        fab
        dark
        color="purple"
        @click="scheduleVisit"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              schedule
            </v-icon>
          </template>
          Agendar visita
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <event-viewer-dialog
      ref="eventViewer"
      v-model="selectedEvent"
      :show="selectedOpen && !saving"
      :technicians="technicians"
      editing
      disable-technician
      @click:close="closeEvent"
      @click:cancel="closeEvent"
      @click:save="saveEvent"
    />
  </v-container>
</template>

<script>
import StatusProducerDialog from "@/Domains/Producer/Management/Components/Dialogs/StatusProducerDialog.vue";
import EventViewerDialog from "@/Domains/Visits/Schedule/Components/EventViewerDialog.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

import moment from "moment-timezone";
import XLSX from "xlsx-js-style";
import _ from "lodash";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  name: "quality-out-of-normative",

  components: {
    StatusProducerDialog,
    EventViewerDialog,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    parseEmpty: (value) => !value ? '-' : value,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        visit: null,
        cbt: null,
        search: null,
        status: 'ATIVOS',
      },
      months: [],

      tab: null,

      items: [],
      items2: [],
      items3: [],

      producerSelected: {
        type: 'activate',
      },

      // Agendamento
      selectedProducers: [],
      technicians: [],
      selectedEvent: {},
      selectedOpen: false,
      saving: false,

      visitList: [
        { value: 'VISITED', text: 'Visitados' },
        { value: 'NOT_VISITED', text: 'Não Visitados' },
      ],

      cbtList: [
        { value: 'LESS_OR_EQUAL_THAN', text: 'Menor CPP mês (> 0 E <= 300)' },
        { value: 'BIGGER_THAN', text: 'Maior CPP mês (> 300)' },
      ],

    };
  },

  computed: {
    headers() {
      const headers = [
        {
          text: '',
          value: 'checkbox',
          width: 20,
          align: 'start',
        },
        { text: 'Código', value: 'code', align: 'start' },
        { text: 'Produtor', value: 'name', align: 'start', width: 150 },
        { text: 'Rota Principal', value: 'route', align: 'start' },
        {
          text: '',
          value: 'type',
          width: 220,
        },
      ];

      this.months.forEach(month => {
        headers.push({
          text: _.upperFirst(moment(month).format("MMM/YY")),
          value: month,
          align: 'center',
        });
      })

      let curMonth = this.filter.date.month;
      if (moment(curMonth).isSame(moment(), 'month'))
      {
        headers.push({
          text: `Limite (${_.upperFirst(moment(curMonth).format("MMM/YY"))})`,
          value: 'limit',
          align: 'center',
        });
      }
      headers.push(
        { text: 'Menor CPP Mês', value: 'last_analysis', align: 'center' },
        { text: 'Última Visita com Assinatura', value: 'last_visit', align: 'center' },
        { text: 'Situação', value: 'status', align: 'center' },
        { value: 'action', sortable: false, width: 50, align: 'end' },
      );

      return headers;
    },

    lastThreeMonths() {
      return [...this.months].slice(2, 5).map(month => month);
    },

    filteredItems() {
      if (!this.filter.cbt && !this.filter.visit && !this.filter.search) {
        return this.items
      }

      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;

      return this.items.filter(item => {
        const cbtFilter = !this.filter.cbt || (this.filter.cbt === "LESS_OR_EQUAL_THAN" ? item.last_analysis > 0 && item.last_analysis <= '300' : item.last_analysis > '300');
        const visitFilter = !this.filter.visit || (this.filter.visit === "VISITED" ? moment(item.last_visit).format('YYYY-MM-01') === this.filter.date.month : moment(item.last_visit).format('YYYY-MM-01') !== this.filter.date.month);
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return cbtFilter && visitFilter && searchFilter;
      })
    },

    filteredItems2() {
      if (!this.filter.cbt && !this.filter.visit && !this.filter.search) {
        return this.items2
      }

      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;

      return this.items2.filter(item => {
        const cbtFilter = !this.filter.cbt || (this.filter.cbt === "LESS_OR_EQUAL_THAN" ? item.last_analysis > 0 && item.last_analysis <= '300' : item.last_analysis > '300');
        const visitFilter = !this.filter.visit || (this.filter.visit === "VISITED" ? moment(item.last_visit).format('YYYY-MM-01') === this.filter.date.month : moment(item.last_visit).format('YYYY-MM-01') !== this.filter.date.month);
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return cbtFilter && visitFilter && searchFilter;
      })
    },

    filteredItems3() {
      if (!this.filter.cbt && !this.filter.visit && !this.filter.search) {
        return this.items3
      }

      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;

      return this.items3.filter(item => {
        const cbtFilter = !this.filter.cbt || (this.filter.cbt === "LESS_OR_EQUAL_THAN" ? item.last_analysis > 0 && item.last_analysis <= '300' : item.last_analysis > '300');
        const visitFilter = !this.filter.visit || (this.filter.visit === "VISITED" ? moment(item.last_visit).format('YYYY-MM-01') === this.filter.date.month : moment(item.last_visit).format('YYYY-MM-01') !== this.filter.date.month);
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return cbtFilter && visitFilter && searchFilter;
      })
    },

    tabs() {
      return [
        {
          title: 'Não salvos pela IN\'s ',
          items: this.filteredItems
        },
        {
          title: 'Salvos pela IN 59',
          items: this.filteredItems2
        },
        {
          title: 'Salvos pela IN 76/77 ',
          items: this.filteredItems3
        }
      ]
    },

    selectAllProducers: {
      set(val) {
        const items = this.tabs[this.tab].items;
        this.selectedProducers = [];
        if (val) {
          for (let i = 0; i < items.length; i++) {
            this.selectedProducers.push(i);
          }
        }
      },
      get() {
        const items = this.tabs[this.tab].items;
        return this.selectedProducers.length === items.length;
      }
    }
  },

  methods: {
    async loadReport() {
      try {
        this.selectAllProducers = '';
        this.loading = true;

        let curMonth = this.filter.date.month;
        let { data: medias } = await this.$axios.post(
          `/relatorioIn/listaForaIn`,
          {
            data_inicio: curMonth,
            id_pessoa: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            status: this.filter.status,
          }
        );

        this.months = this.getLastMonths(curMonth);

        let items = [];
        let items2 = [];
        let items3 = [];
        medias.forEach(produtor => {
          const historico = JSON.parse(produtor.historico_medias_json || produtor.historico_medias_json_anterior);

          let mensal = { code: produtor.codigo_laticinio, name: produtor.nome, producer_id: produtor.id_pessoa, route: produtor.rota_principal, type: "Média Geométrica Mensal" };
          let trimestral = { code: produtor.codigo_laticinio, name: produtor.nome, producer_id: produtor.id_pessoa, route: produtor.rota_principal, type: "Média Geométrica Trimestral" };

          historico.forEach(item => {
            mensal[item.data] = parseInt(item.cbt_geometrica_mes) || '-';
            trimestral[item.data] = parseInt(item.cbt_geometrica) || '-';
          })

          if (moment(curMonth).isSame(moment(), 'month'))
          {
            /**
             * Dados do Penúltimo mês
             */
            if (moment(curMonth).isSame( moment(historico[0].data), 'month')) {
              mensal['limit'] = parseInt(historico[1].cbt_limite_aritmetico) || '-';
              trimestral['limit'] = parseInt(historico[1].cbt_limite) || '-';
            /**
             * Dados do Ultimo mês se nao tiver analise ainda no mes corrente
             */
            } else {
              mensal['limit'] = parseInt(historico[0].cbt_limite_aritmetico) || '-';
              trimestral['limit'] = parseInt(historico[0].cbt_limite) || '-';
            }
          }

          const lista_cbt_mes = JSON.parse(produtor.lista_cbt_mes || '[]');

          const menor_cbt_simples = _.isEmpty(lista_cbt_mes) ? null : Math.min(...lista_cbt_mes.map(({ cbt }) => cbt));

          mensal['cbt_month_list'] = lista_cbt_mes;

          mensal['last_analysis'] = menor_cbt_simples;

          mensal['last_visit'] = produtor.data_ultima_visita;

          mensal['status'] =  produtor.status;

          trimestral['last_visit'] = produtor.data_ultima_visita;

          trimestral['last_analysis'] = menor_cbt_simples;

          if (trimestral[curMonth] !== '-' && trimestral[curMonth] > 0 && trimestral[curMonth] <= 300) {
            //76/77
            items3.push(mensal, trimestral);
          } else if (mensal[curMonth] !== '-' && mensal[curMonth] > 0 && mensal['last_analysis'] <= 300 && moment(mensal['last_visit']).isSame(curMonth, 'month')) {
            // 59
            items2.push(mensal, trimestral);
          } else {
            items.push(mensal, trimestral);
          }
        });

        this.items = _.sortBy(items, 'last_analysis');
        this.items2 = _.sortBy(items2, 'last_analysis');
        this.items3 = _.sortBy(items3, 'last_analysis');
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    /**
     * Retorna os últimos 5 meses a partir da data enviada
     */
    getLastMonths(month) {
      let currentMonth = moment(month);

      let monthArray = [currentMonth.startOf('month').format("YYYY-MM-DD")];
      for (let i = 1; i < 5; i++) {
        monthArray.push(currentMonth.subtract(1, "month").startOf('month').format("YYYY-MM-DD"));
      }

      return monthArray.reverse();
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter([month]) {
      this.filter.date.month = month;
      this.loadReport();
    },

    handleSpan(row, col) {
      const curMonth = this.filter.date.month;

      const cols = moment(curMonth).isSame(moment(), 'month')
        ? [0, 1, 2, 3, 11, 12, 13, 14, 15]
        : [0, 1, 2, 3, 10, 11, 12, 13, 14];

      if (cols.includes(col)) {
        if (row % 2 == 0) {
          return { rowspan: 2 };
        }
        else {
          return { style: 'display:none;' };
        }
      }

    },

    onChangeProducerStatus(item, status, title) {
      return this.$refs.statusProducerDialog.show({
        title,
        producer: {
          id: item.producer_id,
          name: item.name,
        },
        form: {
          status: status,
          date: moment().toDate(),
          params: {
            showStatusSelect: false,
          },
        },
      });
    },

    unlinkProducer(item) {
      return this.onChangeProducerStatus(item, 'DESVINCULADO', 'Desvincular produtor');
    },

    linkProducer(item) {
      return this.onChangeProducerStatus(item, 'APROVADO', 'Vincular produtor');
    },

    suspendProducer(item) {
      return this.onChangeProducerStatus(item, 'SUSPENSO', 'Suspender produtor');
    },

    activateProducer(item) {
      return this.onChangeProducerStatus(item, 'APROVADO', 'Reativar produtor');
    },

    getReportJson() {
      const items = this.tabs[this.tab].items;

      return items.map(item => {
        let report = {
          Código: item.code,
          Produtor: item.name,
          'Rota Principal': item.route,
          Tipo: item.type,
        };

        this.months.forEach(month => {
          const title = _.upperFirst(moment(month).format("MMM/YY"));
          report[title] = item[month] || '-';
        });

        const curMonth = this.filter.date.month;
        if (moment(curMonth).isSame(moment(), 'month')) {
          const title = `Limite (${_.upperFirst(moment(curMonth).format("MMM/YY"))})`;
          report[title] = item.limit;
        }

        report['Menor CPP Mês'] = item.last_analysis;
        report['Última Visita com Assinatura'] = item.last_visit ? moment(item.last_visit).format("DD/MM/YYYY") : '';
        report['Situação'] = item.status;

        return report;
      })
    },

    print() {
      const title = this.tabs[this.tab].title;

      return this.printFromJson(this.getReportJson(), title, true);
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const title = this.tabs[this.tab].title;
      const items = this.tabs[this.tab].items;
      const headers = this.headers;

      let data = XLSX.utils.json_to_sheet(this.getReportJson(), { origin: 'A3' });

      let merges = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];
      for (let row = 0; row < items.length; row++) {
        for (let col = 0; col < headers.length; col++) {
          const span = this.handleSpan(row, col + 1)
          if (span && span.rowspan != undefined) {
            merges.push({ s: { r: row + 3, c: col }, e: { r: row + span.rowspan + 2, c: col } });
          }
        }
      }
      data["!merges"] = merges;

      data['A1'] = { v: title };

      data['!cols'] = [
        { wch: 7 },
        { wch: 30 },
        { wch: 25 },
        { wch: 25 },
        { wch: 7 },
        { wch: 7 },
        { wch: 7 },
        { wch: 7 },
        { wch: 7 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
      ];

      const workbook = XLSX.utils.book_new();

      const filename = "fora_da_in";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    scheduleVisit() {
      let start = new Date();
      start.setHours(7);
      let end = new Date();
      end.setHours(17);

      this.technicians = [
        this.filter.technician
      ];
      const items = this.tabs[this.tab].items;
      const title = this.tabs[this.tab].title;
      /**
       * Produtores pré-selecionados
       */
      let producers = this.selectedProducers.map(idx => ({
        id: items[idx].producer_id,
        name: items[idx].name,
      }));

      producers = _.uniqBy(producers, 'id');

      this.selectedEvent = {
        idx: 0,
        id: null,
        name: '',
        start,
        end,
        color: 'grey darken-1',
        timed: false,
        type: [],
        person: this.filter.technician,
        producers,
        note: title
      };
      this.selectedOpen = true;
    },

    closeEvent() {
      this.selectedOpen = false;
    },

    /**
     * Salva um agendamento
     * @returns {Promise<void>}
     */
    async saveEvent() {
      this.saving = true;
      try {
        // Valida o formulário
        if (!this.$refs.eventViewer.validateForm()) {
          return;
        }

        /**
         * Usando destructor para evitar o data biding
         */
        let selectedEvent = {
          ...this.selectedEvent,
          start: moment(this.selectedEvent.start).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(this.selectedEvent.end).format("YYYY-MM-DD HH:mm:ss"),
        };

        /**
         * Se não tiver id então é um cadastro novo
         */
        if (selectedEvent.id == null) {
          /**
           * Em novos eventos é possível selecionar multiplos produtores
           * Mas vamos replicar esses eventos para o técnico poder
           * ajustar o horário de cada visita individualmente no app
           */
          let forms = selectedEvent.producers.map(producer => ({
            ...selectedEvent,
            producer,
          }));

          await Promise.all(forms.map(async form => {
            return await this.$axios.post(`/agenda/salvaAgendamento`, form);
          }));

          /**
           * Adiciona os eventos no calendário
           */
          this.selectedEvent = {};
          this.selectedOpen = false;
        }

        this.selectedProducers = [];

        this.$snotify.success("Agendamento efetuado!", "Sucesso");

      } catch (e) {
        this.$snotify.error("Ocorreu um erro ao salvar o agendamento!", "Atenção");
        console.warn(e);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
