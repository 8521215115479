<template>
  <div class="pt-0">
    <v-row>
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          dark
          hide-details
          @change="getBills"
        />
      </v-col>
      <v-spacer />
      <v-col md="4">
        <v-text-field
          v-model="filter.search"
          label="Pesquisar"
          prepend-inner-icon="search"
          dark
          filled
          hide-details
          clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Descontos
            <v-spacer />
            <div class="d-flex">
              <data-info
                title="Total Valor"
                icon="account_balance_wallet"
                :value="`R$ ${ formatCurrency(totalAmount) }`"
              />
              <data-info
                title="Total Saldo"
                icon="payments"
                :value="`R$ ${ formatCurrency(totalBalance) }`"
              />
            </div>
          </v-card-title>
          <v-data-table
            :headers="bills.headers"
            :items="filteredBills"
            :loading="loading"
            :sort-by.sync="filter.sort.by"
            :sort-desc.sync="filter.sort.desc"
            dark
            dense
            group-by="producer"
            class="elevation-1 report-table"
          >
            <template #[`group.header`]="{group, isOpen, toggle}">
              <td
                class="text-start"
                :colspan="bills.headers.length"
              >
                <v-btn
                  icon
                  @click="toggle"
                >
                  <v-icon>
                    {{ isOpen ? 'remove' : 'add' }}
                  </v-icon>
                </v-btn>
                {{ group }}
              </td>
            </template>
            <template #[`item.type`]="{ value }">
              <v-chip
                x-small
                color="primary"
              >
                {{ value }}
              </v-chip>
            </template>
            <template #[`item.issueDate`]="{ value }">
              {{ dateFormat(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.dueDate`]="{ value }">
              {{ dateFormat(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.amount`]="{ value }">
              R$ {{ formatCurrency(value) }}
            </template>
            <template #[`item.balance`]="{ value }">
              R$ {{ formatCurrency(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="teal darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import DataInfo from '@/Domains/Financial/Components/DataInfo.vue';
import sumBy from "lodash/fp/sumBy";
import moment from "moment-timezone";

moment.locale('pt');

export default {
  name: "producer-total-discounts-tab",

  components: {
    VDateRangePicker,
    DataInfo,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        sort: { by: 'issueDate', desc: false },
        search: ''
      },

      bills: {
        headers: [
          { text: '#', value: 'code' },
          { text: 'Tipo', value: 'type' },
          { text: 'Produtor', value: 'producer' },
          { text: 'Descrição', value: 'description' },
          { text: 'Emissão', value: 'issueDate' },
          { text: 'Vencimento', value: 'dueDate' },
          { text: 'Valor', value: 'amount' },
          { text: 'Saldo', value: 'balance' },
        ],
        items: []
      },
    };
  },

  computed: {
    filteredBills() {
      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;
      if (!search) {
        return this.bills.items
      }
      return this.bills.items.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(search))
    },
    totalAmount() {
      return sumBy(x => parseFloat(x.amount) || 0, this.filteredBills)
    },
    totalBalance() {
      return sumBy(x => parseFloat(x.balance) || 0, this.filteredBills)
    },
  },

  methods: {
    async getBills() {

      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.range;

        const types = ['DESCONTO', 'ANTIBIOTICO', 'NORMATIVA', 'FINANCEIRO'];

        let [{ data: bills }, { data: recurrences }] = await Promise.all([
          await this.$axios.post(
            `/financeiro/listaParcelas`,
            { tipos: types, data_inicio: startDate, data_fim: endDate }
          ),
          await this.$axios.post(
            `/financeiro/listaRecorrencias`,
            { tipos: types, data_inicio: startDate, data_fim: endDate }
          ),
        ]);
        
        var accumulator = [];
        let group = _.groupBy(bills, "id_titulo");
        _.map(group, item => {
          let nParcel = 1;
          item.forEach(e => {
            e.nParcel = nParcel;
            e.type = "PARCELA " + nParcel + "/" + e.parcela_quantidade;
            nParcel++;
            accumulator.push(e);
          })
        })
        bills = accumulator;

        bills = bills.map(p => ({
          id_titulo: p.id_titulo,
          nParcel: p.nParcel,
          type: p.type,
          recurrenceId: null,
          installmentId: p.id_parcela,
          accountId: p.id_titulo,
          code: p.cod_parcela,
          producer: `${p.codigo_laticinio || ''} - ${p.nome_produtor}`,
          description: p.descricao || p.observacao,
          issueDate: p.data_emissao,
          dueDate: p.data_vencimento,
          amount: p.valor,
          balance: p.saldo,
          cashInmilk: p.descontar_no_leite,
        }))
        
        recurrences = recurrences.map(o => ({
          type: 'RECORRENTE',
          recurrenceId: o.id_titulo_recorrencia,
          installmentId: o.id_parcela || null,
          accountId: o.id_titulo,
          code: o.cod_titulo_recorrencia,
          producer: `${o.codigo_laticinio || ''} - ${o.nome_produtor}`,
          description: o.descricao || o.observacao,
          issueDate: o.data_emissao,
          dueDate: o.data_vencimento,
          amount: o.valor,
          balance: o.saldo,
        }))

        this.bills.items = [...bills, ...recurrences];
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getBillsJson() {
      return this.bills.items.map(item => ({
        '#': item.code,
        Tipo: item.type,
        Produtor: item.producer,
        Descrição: item.description,
        Emissão: this.dateFormat(item.issueDate, 'DD/MM/YYYY'),
        Vencimento: this.dateFormat(item.dueDate, 'DD/MM/YYYY'),
        Valor: 'R$ ' + this.formatCurrency(item.amount),
        Saldo: 'R$ ' + this.formatCurrency(item.balance),
      }));
    },

    exportExcel() {
      const bills = this.getBillsJson();

      let data = this.XLSX.utils.json_to_sheet(bills, { origin: 2 });

      data["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
      ];
      data['A1'] = { v: 'Descontos' };

      data['!cols'] = [
        { wch: 10 },
        { wch: 30 },
        { wch: 40 },
        { wch: 14 },
        { wch: 14 },
        { wch: 14 },
        { wch: 14 }
      ];

      const workbook = this.XLSX.utils.book_new();

      const filename = "descontos";

      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    print() {
      const bills = this.htmlTableFromJson(this.getBillsJson(), 'Descontos');

      const html = `
      <div>
        ${ bills }
      </div>`;

      return this.printJS({
        documentTitle: 'Descontos',
        printable: html,
        type: 'raw-html',
        css: ['https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css'],
        style: `
          table, tr, td { page-break-inside: auto; border: 1px solid lightgray !important; border-collapse: collapse; }
          td, th, tr { font-size: 7pt; color: black; }
        `
      });
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
};
</script>