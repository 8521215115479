<template>
  <div>
    <v-dialog
      v-if="dialog.show"
      v-model="dialog.show"
      width="80%"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <producer-analysis
        :unload="unload"
        :tanks="tanks"
        :visible-analysis-params="visibleAnalysisParams"
        :loading.sync="loading"
        @onTankUpdated="onTankUpdated"
        @close="hide"
      />

      <v-overlay :value="loading">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import ProducerAnalysis from "@/Domains/Platform/Unload/Components/UnloadSteps/Steps/UnloadStep2.vue";

export default {

  components: {
    ProducerAnalysis,
  },

  props: {
    visibleAnalysisParams: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      loading: false,

      // Dados referentes ao Dialog
      dialog: {
        show: false,
      },

      unload: {},

    };
  },

  computed: {
    tanks() {
      return this.unload.tanks.filter(tank => tank.vol > 0);
    },
  },

  methods: {

    show(unload) {
      this.dialog.show = true;
      this.unload = unload;
    },

    hide() {
      this.dialog.show = false;
      this.unload = {};
    },

    onTankUpdated(updatedTank) {
      this.unload.tanks = this.unload.tanks.map(tank => {

        if (tank.index === updatedTank.index) {
          return updatedTank;
        }

        return tank;
      });
    },

    onTanksDiscarded(discardedTanks) {
      this.hide();

      return this.$emit('onTanksDiscarded', discardedTanks);
    },

  },

}
</script>
