<template>
  <div>
    <v-col
      class="px-0"
    >
      <card-title-settings>
        Avisos
      </card-title-settings>
    </v-col>

    <v-card
      dark
      outlined
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-select
              v-model="alert.type"
              prepend-inner-icon="api"
              :items="[
                { 'text': 'success', value: 'success' },
                { 'text': 'info', value: 'info' },
                { 'text': 'warning', value: 'warning' },
                { 'text': 'error', value: 'error' },
              ]"
              label="Tipo:"
              placeholder=" "
              :loading="isLoading"
              dense
              filled
              dark
            />
          </v-col>

          <v-col cols="2">
            <v-text-field
              v-model.number="alert.showInterval"
              prepend-inner-icon="alarm"
              label="Intervalo de Exibição (Horas):"
              placeholder=" "
              :loading="isLoading"
              dense
              filled
              dark
            >
            </v-text-field>
          </v-col>

          <v-col cols="2">
            <masked-text-field
              v-model="alert.startAt"
              label="Data/Hora início exibição"
              prepend-inner-icon="event"
              placeholder="00/00/0000 00:00"
              :mask="{ mask: '00/00/0000 00:00' }"
              :rules="[ rules.dateTime ]"
              :loading="isLoading"
              :max-length="16"
              clearable
              filled
              dense
            />
          </v-col>

          <v-col cols="2">
            <masked-text-field
              v-model="alert.endAt"
              label="Data/Hora fim exibição"
              prepend-inner-icon="event"
              placeholder="00/00/0000 00:00"
              :mask="{ mask: '00/00/0000 00:00' }"
              :rules="[ rules.dateTime ]"
              :loading="isLoading"
              :max-length="16"
              clearable
              filled
              dense
            />
          </v-col>

          <v-spacer />

          <v-switch
            v-model="alert.show"
            :loading="isLoading"
            inset
          />
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="alert.title"
              label="Título"
              prepend-inner-icon="last_page"
              :loading="isLoading"
              clearable
              filled
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="alert.message"
              label="Mensagem"
              prepend-inner-icon="last_page"
              clearable
              filled
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="indigo darken-3 mr-5"
          v-on="on"
          @click.stop="save()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>

    <v-overlay v-model="isLoading">
      Carregando ...
    </v-overlay>
  </div>
</template>

<style scoped lang="scss">

</style>

<script setup>
import { ref, reactive, onMounted, getCurrentInstance  } from "vue";

import moment from "moment";
import isEmpty from "lodash/isEmpty";

import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import { useAxios } from "@/Support/Resources/axios-instance.js";

const $axios = useAxios();

const { proxy: { $snotify } } = getCurrentInstance();

const isLoading = ref(false);

const alert = ref({
  show: false,
  type: '',
  showInterval: '',
  startAt: '',
  endAt: '',
  title: '',
  message: '',
});

const rules = reactive({
  dateTime: v =>  !v || !!v && v.length === 16 && dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!',
});

onMounted(async () => {
  await load();
});

async function load() {
  try {
    isLoading.value = true;

    const { data }  = await $axios.get(`/settings/web`);

    if (isEmpty(data)) {
      return;
    }

    const alertData = data.aviso || {};

    alert.value = {
      ...alert.value,
      ...alertData,
      startAt: !isEmpty(alertData.startAt) ? moment(alertData.startAt, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : null,
      endAt: !isEmpty(alertData.endAt) ? moment(alertData.endAt, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : null,
    };

  } catch (e) {
    console.error(e);

    $snotify.error("Erro ao buscar as configurações", "Atenção");
  } finally {
    isLoading.value = false;
  }
}

async function save() {
  try {
    isLoading.value = true;

    await $axios.post(`/settings/web`, {
      aviso: {
        ...alert.value,
        startAt: moment(alert.value.startAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
        endAt: moment(alert.value.endAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
      },
    });

    $snotify.success("Configurações salvas com sucesso", "Sucesso");
  } catch (e) {
    console.error(e);

    $snotify.error("Erro ao salvar as configurações", "Atenção");
  } finally {
    isLoading.value = false;
  }
}

function dateValidate(value, format) {
  if (isEmpty(value)) {
    return '';
  }

  return moment(value, format).isValid();
}
</script>
