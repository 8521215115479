<template>
  <v-dialog
    v-model="dialog.show"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Log de Alterações
      </v-card-title>

      <v-card-text>
        <v-container style="max-width: 600px;">
          <v-timeline
            dense
            clipped
          >
            <v-timeline-item
              v-for="(log, idx) in logs"
              :key="idx"
              :color="getItemColor(log)"
            >
              <v-row justify="space-between">
                <v-col cols="9">
                  <b>{{ getItemMessage(log) }}</b>
                  <br>
                  <template v-if="log.motivo">
                    Motivo: {{ log.motivo }}
                    <br>
                  </template>
                  <template v-if="log.tipo === 'DIFERENCA'">
                    Diferença: <b>{{ log.diferenca_peso }}L</b>
                    <br>
                  </template>
                  Usuário: {{ log.nome_pessoa }}
                  <br>
                  <v-row>
                    <v-col
                      v-for="(value, key) of (log.alteracoes || [])"
                      :key="key"
                      cols="12"
                      :sm="isArray(value) ? 6 : 12"
                      class="pb-0"
                    >
                      <v-sheet
                        color="white"
                        elevation="1"
                        class="d-flex flex-column justify-space-between pa-2"
                      >
                        <div class="font-weight-bold">
                          {{ startCase(key) }}
                        </div>
                        <v-row v-if="!isArray(value)">
                          <v-col
                            v-for="(value2, key2) of value"
                            :key="`${key}-${key2}`"
                            cols="12"
                            sm="6"
                            class="pb-0"
                          >
                            <v-sheet
                              color="white"
                              elevation="1"
                              class="d-flex flex-column justify-space-between pa-2"
                            >
                              <div class="font-weight-bold">
                                {{ startCase(key2) }}
                              </div>
                              <div>
                                {{ value2[0] || '-' }}
                                <v-icon
                                  small
                                  class="mx-2 mb-1"
                                  color="orange"
                                >
                                  keyboard_double_arrow_right
                                </v-icon>
                                {{ value2[1] || '-' }}
                              </div>
                            </v-sheet>
                          </v-col>
                        </v-row>
                        <div v-else>
                          {{ value[0] || '-' }}
                          <v-icon
                            small
                            class="mx-2 mb-1"
                            color="orange"
                          >
                            keyboard_double_arrow_right
                          </v-icon>
                          {{ value[1] || '-' }}
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  class="text-right"
                  cols="3"
                >
                  {{ formatDate(log.data_hora, 'DD/MM HH:mm:ss') }}
                </v-col>
              </v-row>

              <template #icon>
                <v-icon
                  color="white"
                  small
                  v-text="getItemIcon(log)"
                />
              </template>
            </v-timeline-item>
          </v-timeline>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import startCase from 'lodash/startCase';
import isArray from 'lodash/isArray';

export default {

  data() {
    return {
      loading: false,

      dialog: {
        show: false,
      },

      logs: [],
    }
  },

  methods: {
    async show(logs) {
      this.dialog.show = true;

      this.logs = [...logs.sort((a, b) => b.data_hora.localeCompare(a.data_hora))];
    },

    getItemColor(log) {
      if (log.tipo === 'REVERSAO') {
        return 'red'
      }

      if (log.tipo === 'DIFERENCA') {
        return 'blue'
      }

      return 'grey';
    },

    getItemIcon(log) {
      if (log.tipo === 'REVERSAO') {
        return 'settings_backup_restore'
      }

      if (log.tipo === 'DIFERENCA') {
        return 'local_shipping'
      }

      return 'biotech';
    },

    getItemMessage(log) {
      if (log.tipo === 'REVERSAO') {
        return 'Retornada para fila!'
      }

      if (log.tipo === 'DIFERENCA') {
        return 'Gerada movimentação de diferença de peso!'
      }

      return 'Edição de análises!';
    },

    formatDate: (date, format) => moment(date).format(format),
    startCase,
    isArray,
  },

}
</script>

