<template>
  <v-dialog
    v-model="show"
    width="450"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Forma de Pagamento
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="isValid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.nome"
                type="text"
                label="Descrição"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="form.tipo"
                :items="[
                  { value: 'DINHEIRO', text: 'DINHEIRO' },
                  { value: 'BOLETO', text: 'BOLETO' },
                  { value: 'CHEQUE', text: 'CHEQUE' },
                  { value: 'CARTAO_DEBITO', text: 'CARTAO DÉBITO' },
                  { value: 'CARTAO_CREDITO', text: 'CARTAO CRÉDITO' },
                ]"
                type="text"
                label="Tipo"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import isObject from "lodash/fp/isObject";

export default {

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      isValid: true,
      form: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = {}

        if (this.editId) {
          this.loadPaymentMethod();
        }
      }
    }
  },

  methods: {
    async loadPaymentMethod() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/formaPagamento/detalhe`, {
          id_forma_pagamento: this.editId
        });

        if (!isObject(data)) {
          throw data;
        }

        this.form = data;
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;
        const action = this.form.id_forma_pagamento ? 'atualiza' : 'insere';
        
        const { data } = await this.$axios.post(`/formaPagamento/${action}`, this.form);
        
        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Forma de pagamento salva com sucesso!", "Sucesso");
        this.show = false;
        this.$emit('save', data.id_forma_pagamento);
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    cancel() {
      this.show = false;
    }
  }
}
</script>