<template>
  <v-dialog
    v-model="show"
    width="350"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Laboratório
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model="form.name"
                label="Nome"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
export default {

  props: {
    value: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      form: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = {}

        if (this.editId) {
          this.load();
        }
      }
    }
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/registrations/laboratory/${ this.editId }`);

        this.form = {
          name: data.nome,
        };
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        this.saving = true;

        const payload = {
          nome: this.form.name,
        };

        const data = this.editId ? await this.update(payload) : await this.store(payload);

        this.close();
        this.$emit('save', data.id);
        this.$snotify.success('Laboratório salvo com sucesso!', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/laboratory`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/laboratory/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    }
  }
}
</script>
