<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Cancelamento de Notas Fiscais Eletrônicas</span>
        <v-spacer />
        <v-btn
          v-if="!started.show"
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="6">
            <div class="font-weight-bold">
              Quantidade de Notas
            </div>
            <div class="text-h6 secondary--text">
              {{ invoices.length }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-timeline dense>
              <v-slide-x-reverse-transition
                group
                hide-on-leave
              >
                <v-timeline-item
                  v-if="isHomolog"
                  key="ambiente"
                  color="warning"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    type="warning"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div class="text-h6">
                          Ambiente de Homologação
                        </div>
                        <div class="body-1">
                          Documento sem valor fiscal
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
                <v-timeline-item
                  v-if="started.show"
                  key="auth"
                  :color="started.type"
                  small
                  fill-dot
                >
                  <v-alert
                    :value="true"
                    :type="started.type"
                    border="left"
                    colored-border
                    elevation="2"
                    dense
                    class="mb-0"
                  >
                    <template #prepend>
                      <v-progress-circular
                        v-if="started.loading"
                        indeterminate
                        color="info"
                      />
                    </template>
                    <v-row class="pa-0 align-center">
                      <v-col class="grow py-0 text-left">
                        <div
                          class="text-h6"
                          v-html="started.text"
                        />
                        <div
                          v-if="started.detail"
                          class="body-1"
                          v-html="started.detail"
                        />
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-timeline-item>
                <v-timeline-item
                  v-else
                  key="form"
                  :color="started.type"
                  small
                  fill-dot
                >
                  <v-form
                    ref="form"
                    lazy-validation
                    @submit.prevent="save()"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="justification"
                          filled
                          label="Motivo do cancelamento"
                          auto-grow
                          counter="15"
                          :rules="[
                            rules.required,
                            rules.length(15),
                          ]"
                          class="counter-success"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-timeline-item>
              </v-slide-x-reverse-transition>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!started.show"
          outlined
          color="primary"
          @click="save()"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.counter-success {
  .v-counter.error--text {
    color: #43A047 !important;
    caret-color: #43A047 !important;
  }
}
</style>

<script>
import _ from 'lodash'

export default {

  data() {
    return {
      dialog: false,

      invoices: [],

      justification: null,

      isHomolog: false,
      started: {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        length: len => v => (v || '').length >= len || 'Justificativa muito curta',
      },
    }
  },

  methods: {
    show(invoices) {
      this.reset();
      this.isHomolog = invoices[0].ambiente === 2;
      this.invoices = invoices;
      this.dialog = true;
    },

    reset() {
      this.isHomolog = false;
      this.started = {
        show: false,
        loading: false,
        type: 'info',
        text: '',
        detail: '',
      };
      this.justification = null;
    },

    close() {
      this.$emit('close');
      this.dialog = false;
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      if (this.started.show) {
        return;
      }

      const ids = this.invoices
        .filter(item => item.status === 'EMITIDA')
        .map(item => item.id);

      if (ids.length === 0) {
        return;
      }

      this.started.show = true;
      this.started.loading = true;
      this.started.type = 'info';
      this.started.text = `Enviando ${ids.length} notas para a SEFAZ`;

      try {

        const { data } = await this.$axios.put(`/fiscal/invoice/cancel`, {
          ids,
          justificativa: this.justification
        });

        const canceladas = data.responses.filter(({ result, invoice }) => result.code === 1 && invoice.status === 'CANCELADA');
        const rejeitadas = data.responses.filter(({ result, invoice }) => result.code === 1 && invoice.status !== 'CANCELADA' && !!invoice.motivo);
        const invalidas = data.responses.filter(({ result }) => result.code !== 1);

        let text = '';
        let detail = '';

        if (canceladas.length > 0) {
          text += `<span class='d-block green--text'>${canceladas.length} notas canceladas</span>`;
        }

        if (rejeitadas.length > 0) {
          text += `<span class='d-block red--text'>${rejeitadas.length} notas rejeitadas</span>`;
          detail += rejeitadas
            .map(({ invoice }) => (`${invoice.numero}: ${invoice.motivo}`))
            .join('<br>')
        }

        if (invalidas.length > 0) {
          text += `<span class='d-block red--text'>${invalidas.length} notas inválidas</span>`;
          detail += invalidas
            .map(({ result, invoice }) => (`${invoice.numero}: ${result.message}`))
            .join('<br>')
        }

        this.started.type = (rejeitadas.length > 0 || invalidas.length > 0) ? 'warning' : 'success';
        this.started.text = text;
        this.started.detail = detail;

        this.$emit('save');
      } catch (error) {
        const message = _.get(error, 'response.data.message', error);

        this.started.type = 'error';
        this.started.text = 'Erro ao cancelar nota';
        this.started.detail = message;
      } finally {
        this.started.loading = false;
      }
    },

  },

}
</script>
