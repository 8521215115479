
export const useRangeValidator = () => {

  /**
   * Validates the given value based on the provided rule.
   * @param {number} value - The value to be validated.
   * @param {object} rule - The rule object containing max and min properties.
   * @param {string | number} rule.max - The maximum value in the range.
   * @param {string | number} rule.min - The minimum value in the range.
   * @returns {boolean|string} - Returns true if the value is valid, otherwise returns an error message.
   */
  const validateRule = (value, rule) => {
    // If no rule is provided, the value is considered valid
    if (!rule) {
      return true;
    }

    // Check if the value falls within the valid range
    if (isValidRange(value, rule)) {
      return true;
    }

    // Convert max and min to strings and replace '.' with ','
    const max = (rule.max || '').toString().replace('.', ',');
    const min = (rule.min || '').toString().replace('.', ',');

    // Return error messages based on the rule
    if (rule.max && rule.min) {
      return `Entre ${min} e ${max}`;
    } else if (rule.max) {
      return `Máximo ${max}`;
    } else if (rule.min) {
      return `Mínimo ${min}`;
    }

    // If no specific error message is required, return true
    return true;
  };

  /**
   * Check if the value falls within the specified range.
   * @param {string | number} value - The value to be checked.
   * @param {Object} rule - The range rules (optional).
   * @param {string | number} rule.max - The maximum value in the range.
   * @param {string | number} rule.min - The minimum value in the range.
   * @returns {boolean} - Returns true if the value is within the range, or if value is null or empty string.
   */
  const isValidRange = (value, rule = {}) => {
    // If the value is null or empty, it's considered valid
    if (value === null || value === '') {
      return true;
    }

    // If both max and min rules are provided
    if (rule.max && rule.min) {
      const _value = parseFloat(value);
      const max = parseFloat(rule.max);
      const min = parseFloat(rule.min);

      return _value >= min && _value <= max;
    }

    // If only max rule is provided
    if (rule.max) {
      const _value = parseFloat(value);
      const max = parseFloat(rule.max);

      return _value <= max;
    }

    // If only min rule is provided
    if (rule.min) {
      const _value = parseFloat(value);
      const min = parseFloat(rule.min);

      return _value >= min;
    }

    // If no rules are provided, the value is considered valid
    return true;
  };

  return {
    validateRule,
    isValidRange,
  }
}
