<template>
  <v-dialog
    v-model="show"
    width="60%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Edição/Cadastro de Grupos de Produtos</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="8"
              >
                <v-text-field
                  v-model="form.group"
                  label="Grupo"
                  :rules="isRequired"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-row>
                  <v-col
                    cols="3"
                  >
                    <v-text-field
                      v-model="form.field"
                      label="Campo"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-autocomplete
                      v-model="form.type"
                      label="Tipo"
                      :items="typesList"
                      item-text="type"
                      item-value="id"
                    />
                  </v-col>
                  <v-col
                    v-if="isSelect"
                    cols="6"
                  >
                    <v-text-field
                      v-model="form.selectValues"
                      label="Valores"
                      placeholder="Para criar valores coloque separado por vígula, exemplo M=Macho,F=Fêmea"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-autocomplete
                      v-model="form.rules"
                      label="Regras"
                      :items="rulesList"
                      item-text="rule"
                      item-value="id"
                      multiple
                      return-object
                    />
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <v-btn
                      class="blue--text"
                      @click="setCustomFields"
                    >
                      Adicionar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
              >
              <v-card
                dark
                color="transparent"
                class="blue"
              >
                <v-card-title>
                  <v-spacer />
                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    Campos Personalizados
                  </v-col>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="customFields"
                  class="elevation-1"
                >
                  <template #[`item.type`]="{ value }">
                    <v-chip
                      small
                      color="green darken-1"
                    >
                      {{ getTypeById(value) }}
                    </v-chip>
                  </template>

                  <template #[`item.values`]="{ value }">
                    <v-chip
                      small
                      color="green darken-1"
                    >
                      {{ formatValues(value) }}
                    </v-chip>
                  </template>

                  <template #[`item.rules`]="{ value }">
                    <v-chip
                      v-for="rule of value"
                      :key="rule.id"
                      small
                      color="red darken-1"
                    >
                      {{ rule.rule }}
                    </v-chip>
                  </template>

                  <template #[`item.actions`]="{ item }">
                    <v-menu>
                      <template #activator="{ on }">
                        <v-btn
                          dark
                          icon
                          v-on="on"
                        >
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>
                      <v-list>

                        <v-list-item
                          @click="deleteField(item)"
                        >
                          <v-list-item-icon>
                            <v-icon>
                              delete
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            Excluir
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isArray, isNull } from "lodash";
export default {

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      form: {
        group: null,
        field: null,
        type: null,
        rules: null,
        selectValues: null,
      },
      fields: null,
      typesList: [
        {
          id: 'number',
          type: 'Numérico'
        },
        {
          id: 'text',
          type: 'Texto'
        },
        {
          id: 'select',
          type: 'Seleção'
        },
        {
          id: 'date',
          type: 'Data'
        },
      ],
      rulesList: [
        {
          id: 'required',
          rule: 'Obrigatório'
        },
      ],
      customFields: [],
      isRequired: [(v) => !!v || 'Este campo é obrigatório'],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    headers() {
      return [
        { text: 'Id', value: 'id', align: 'start'},
        { text: 'Descrição', value: 'description', align: 'start' },
        { text: 'Tipo', value: 'type', align: 'start' },
        { text: 'Valores', value: 'values', align: 'start' },
        { text: 'Regras', value: 'rules', align: 'start' },
        { text: '', value: 'fields', align: 'end' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
      ];
    },

    isSelect() {
      return this.form.type === 'select';
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          group: null,
          field: null,
          type: null,
        };

        if (this.editId) {
          this.loadPan();
        }
      }
    }
  },

  methods: {

    async loadPan() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/registrations/product-group/${this.editId}`);

        this.form = {
          id: data.id_grupo_produto,
          group: data.grupo,
        };

        this.customFields = data.campos;
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita uma causa no banco de dados
     */
    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_grupo_produto: this.form.id,
          grupo: this.form.group,
          campos: JSON.stringify(this.customFields),
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);

        this.$snotify.success("Serviço cadastrado com sucesso", "Sucesso");

        this.form.id = null;
        this.form.group = null;
        this.customFields = [];

        this.close();

      } catch (e) {

        this.$snotify.error("Erro ao cadastrar serviço", "Atenção");

        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/product-group`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/product-group/${this.editId}`, payload);
      return data;
    },

    setCustomFields() {

      if (isNull(this.form.field)) {
        this.$snotify.error("O Campo é obrigatório", "Atenção");
        return false;
      }

      if (isNull(this.form.type)) {
        this.$snotify.error("O Tipo é obrigatório", "Atenção");
        return false;
      }

      if (this.idExists(this.customFields, this.slugGenerate(this.form.field))) {
        this.$snotify.error("O campo informado já existe.", "Atenção");
        return false;
      }

      const selectedValues = this.form.selectValues ? this.form.selectValues.split(',') : [];

      const valuesMap = selectedValues.map(function(options) {
        const option = options.split('=');

        return {
          'id': option[0],
          'value': option[1],
        }
      });

      const field = {
        id: this.slugGenerate(this.form.field),
        description: this.form.field,
        type: this.form.type,
        rules: this.form.rules,
        values: valuesMap,
      };

      this.customFields.push(field);

      this.form.field = null;
      this.form.type = null;
      this.form.rules = null;

    },

    close() {
      this.show = false;
    },

    slugGenerate(str) {
      let slug = str.trim().toLowerCase();

      // Substitui acentuações por caracteres correspondentes
      slug = slug.replace(/[áàâãä]/g, 'a');
      slug = slug.replace(/[éèêë]/g, 'e');
      slug = slug.replace(/[íìîï]/g, 'i');
      slug = slug.replace(/[óòôõö]/g, 'o');
      slug = slug.replace(/[úùûü]/g, 'u');
      slug = slug.replace(/[ç]/g, 'c');

      // Substitui caracteres especiais por hífens
      slug = slug.replace(/[^\w\s-]/g, '');

      // Substitui espaços por hífens
      slug = slug.replace(/\s+/g, '-');

      return slug;
    },

    deleteField(field) {
      this.customFields = this.customFields.filter(fields => fields !== field);
    },

    getTypeById(id) {
      for (const type of this.typesList) {
        if (type.id === id) {
          return type.type;
        }
      }

      return ' - ';
    },

    idExists(array, id) {
      return array.some(item => item.id === id);
    },

    formatValues(objectsArray) {

      if (!isArray(objectsArray)) {
        return;
      }

      return objectsArray.map(object => `${object.id}=${object.value}`).join(', ');
    }
  },
}
</script>
