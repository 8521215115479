<template>
  <v-data-table
    :headers="[
      { align: 'start', text: 'Silo', value: 'silo.description' },
      { text: 'Movimentado Em', value: 'transferredAt' },
      { text: 'Volume', value: 'transferredVol' },
      { text: 'Observação', value: 'obs' },
    ]"
    :items="filteredItems"
    class="elevation-1"
  >
    <template #[`item.transferredAt`]="{ value }">
      {{ dateFormat(value, 'DD/MM/YYYY') }}
    </template>

    <template #[`item.transferredVol`]="{ item }">
      <v-chip small>
        <v-avatar left>
          <v-icon
            v-if="item.inOut === 'OUT'"
            color="red"
            v-text="'arrow_circle_up'"
          />
          <v-icon
            v-else-if="item.inOut === 'IN'"
            color="green"
            v-text="'arrow_circle_down'"
          />
        </v-avatar>
        <span v-if="item.transferredVol !== 0">
          {{ formatNumber(Math.abs(item.transferredVol)) }}
        </span>
        <span v-else>
          ----
        </span>
      </v-chip>
    </template>

    <template #[`item.type`]="{ value }">
      <v-chip small>
        {{ value }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

export default {
  props: {
    transfers: Array,
    search: String,
  },

  computed: {
    filteredItems() {
      if (!this.search) {
        return this.transfers;
      }

      const search = this.search?.toUpperCase()?.trim();

      return this.transfers.filter(item => {
        return JSON.stringify(Object.values(item)).toUpperCase().includes(search);
      });
    }
  },

  methods: {
    dateFormat: (value, format) => isEmpty(value) ? '' : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
