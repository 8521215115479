<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Manutenções
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab>
            Manutenções
          </v-tab>
          <v-tab>
            Itens
          </v-tab>

          <v-tab-item>
            <maintenance-list />
          </v-tab-item>
          <v-tab-item>
            <maintenance-item-list />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MaintenanceList from "@/Domains/Fleet/Maintenance/Views/MaintenanceList.vue"
import MaintenanceItemList from "@/Domains/Fleet/Maintenance/Views/MaintenanceItemList.vue"

export default {
  name: "dashboard",

  components: {
    MaintenanceList,
    MaintenanceItemList,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>
