<template>
  <v-select
    v-model="input"
    :items="items"
    :label="label"
    :loading="loading"
    item-value="id"
    item-text="description"
    v-bind="$attrs"
    @change="$emit('change', $event)"
  >
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.description }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.dairy }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'warehouse-select',

  props: {
    value: String,
    label: {
      type: String,
      default: 'Depósito'
    },
    autoSelectDefault: Boolean
  },

  data() {
    return {
      loading: false,
      items: [],
    }
  },

  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  created() {
    this.loadWarehouses();

    if (this.autoSelectDefault) {
      if (this.$store.state.settings.laticinio) {
        this.input = this.$store.state.settings.laticinio.id;
      } else if (this.$store.state.settings.laticinios.length > 0) {
        this.input = this.$store.state.settings.laticinios[0].id;
      }
    }
  },

  methods: {
    async loadWarehouses() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/warehouse`);

        this.items = data.map(item => ({
          id: item.id_deposito,
          description: item.descricao,
          dairy: item.nome_laticinio,
        }));
      } catch (error) {
        const message = get(error, 'response.data.message', 'Erro ao carregar depósitos');
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
  }

}
</script>
