<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    >
      <v-flex
        xs12
        class="text-xs-center"
      >
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-flex xs12>
        <v-tabs
          v-model="aba"
          dark
          centered
          background-color="transparent"
          class="transparent"
          @change="onTabChange"
        >
          <v-tab href="#aba-rotas">
            Rotas/Linha
          </v-tab>

          <v-tab href="#aba-regiao">
            Região/Município
          </v-tab>

          <v-tab
            href="#aba-cadastro"
            :disabled="!editing"
          >
            Editar/Inserir Rota
          </v-tab>

          <v-tab href="#aba-comparacao">
            Previsto/Realizado
          </v-tab>

          <v-tab href="#aba-mapa">
            Mapa
          </v-tab>

          <v-tab
            href="#aba-simulacoes"
            :disabled="simulating.length < 2"
          >
            Simulações
          </v-tab>

          <v-tab-item value="aba-rotas">
            <list-routes-tab
              v-if="aba === 'aba-rotas'"
              type="COMERCIAL"
              @onRouteCreating="onRouteCreating"
              @onRouteSimulation="onRouteSimulation"
              @onRouteEditing="onRouteEditing"
              @onRegionSelect="onRegionSelect"
            />
          </v-tab-item>

          <v-tab-item value="aba-regiao">
            <list-regions-tab
              v-if="aba === 'aba-regiao'"
              :selected="selectedRegion"
              @onRouteSelect="onRouteSelect"
              @onDetailsClosed="onDetailsClosed"
            />
          </v-tab-item>

          <v-tab-item value="aba-cadastro">
            <route-editing-tab
              v-if="aba === 'aba-cadastro'"
              :form="editing"
              @onRouteSaved="onRouteSaved"
            />
          </v-tab-item>

          <v-tab-item value="aba-comparacao">
            <route-comparison-tab
              v-if="aba === 'aba-comparacao'"
              type="COMERCIAL"
            />
          </v-tab-item>

          <v-tab-item value="aba-mapa">
            <route-map-tab
              v-if="aba === 'aba-mapa'"
              type="COMERCIAL"
            />
          </v-tab-item>

          <v-tab-item value="aba-simulacoes">
            <route-simulations-tab
              v-if="aba === 'aba-simulacoes' && simulating.length > 1"
              :ids="simulating"
              @onRoutesSaved="onRoutesSaved"
            />
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-container>
  </v-container>
</template>

<style lang="scss">
.rota-lista > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.lista-rotas-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import ListRoutesTab from "@/Domains/Routes/Views/ListRoutesTab.vue";
import ListRegionsTab from "@/Domains/Commercial/Routes/Views/ListRegionsTab.vue";
import RouteEditingTab from "@/Domains/Commercial/Routes/Views/RouteEditingTab.vue";
import RouteComparisonTab from "@/Domains/Routes/Views/RouteComparisonTab.vue";
import RouteMapTab from "@/Domains/Routes/Views/RouteMapTab.vue";
import RouteSimulationsTab from "@/Domains/Commercial/Routes/Views/RouteSimulationsTab.vue";

export default {
  name: "commercial-routes",

  components: {
    RouteEditingTab,
    ListRoutesTab,
    ListRegionsTab,
    RouteComparisonTab,
    RouteMapTab,
    RouteSimulationsTab,
  },

  data() {
    return {
      aba: "aba-rotas",

      editing: {},

      simulating: [],

      selectedRegion: null,
    };
  },

  methods: {

    /**
     * @event object
     *
     * Evento acionado no fab da aba "aba-rotas"
     */
    onRouteCreating() {
      this.aba = "aba-cadastro";
    },

    /**
     * @event Array
     *
     * Evento acionado quando o usuário escolhe duas rotas e clica em no botão para simular
     */
    onRouteSimulation(ids) {
      this.aba = "aba-simulacoes";
      this.simulating = ids;
    },

    /**
     * @event object
     *
     * Evento acionado na lista de rotas da aba "aba-rotas"
     */
    async onRouteEditing(route) {
      this.aba = "aba-cadastro";
      this.editing.id = route.id_rota;
    },

    /**
     * @event Object
     *
     * Evento acionado ao selecionar uma rota no detalhe da região na aba "aba-regioes"
     */
    onRouteSelect(route) {
      return this.onRouteEditing(route);
    },

    /**
     * @event void
     *
     * Evento acionado ao selecionar uma região na lista de rotas (aba "aba-rotas")
     */
    onRegionSelect(region) {
      this.selectedRegion = region;
      this.aba = "aba-regiao";
    },

    /**
     * @event void
     *
     * Evento acionado ao salvar uma rota na aba "aba-rotas"
     */
    onRouteSaved() {
      this.editing = {};
      this.aba = "aba-rotas";
    },

    /**
     * @event void
     *
     * Evento acionado ao salvar as rotas que estão sendo simuladas
     */
    onRoutesSaved() {
      this.simulating = [];
      this.aba = "aba-rotas";
    },

    /**
     * @event void
     *
     * Evento acionado ao fechar os detalhes da região na aba "aba-regiao"
     */
    onDetailsClosed() {
      this.selectedRegion = null;
    },

    /**
     * @event void
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange() {
      this.editing = {};
      this.simulating = [];
    },
  },
};
</script>
