var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tabs',{attrs:{"grow":"","color":"#01579B"}},[_c('v-tab',{attrs:{"href":"#inventory"}},[_vm._v(" Inventário ")]),_c('v-tab',{attrs:{"href":"#recipes"}},[_vm._v(" Receitas ")]),_c('v-tab',{attrs:{"href":"#expenses"}},[_vm._v(" Despesas ")]),_c('v-tab-item',{staticClass:"pt-3",attrs:{"value":"inventory"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('FinancialTable',{attrs:{"settings":[
              { text: 'Estoque', value: 'inventory', width: 250, item: { type: 'TEXTINPUT' } },
              { text: 'Quantidade', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
              { text: 'Valor', value: 'value', width: 200, item: { type: 'MONEY' } },
              { text: '% de uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
            ],"row-remove":"","row-add":"","value":_vm.inventory.inventory}})],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-col',{attrs:{"cols":"12"}},[_c('FinancialTable',{attrs:{"settings":[
              { text: 'Máquinas e Equipamentos', value: 'equipment', width: 250, item: { type: 'TEXTINPUT' } },
              { text: 'Quantidade', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
              { text: 'Valor', value: 'value', width: 200, item: { type: 'MONEY' } },
              { text: '% de uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
            ],"row-remove":"","row-add":"","value":_vm.inventory.equipment}})],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-col',{attrs:{"cols":"12"}},[_c('FinancialTable',{attrs:{"settings":[
              { text: 'Área', value: 'area', width: 250, item: { type: 'TEXTINPUT' } },
              { text: 'Quantidade (HÁ)', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
              { text: 'Valor de formação', value: 'value', width: 200, item: { type: 'MONEY' } },
              { text: '% uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
            ],"row-remove":"","row-add":"","value":_vm.inventory.area}})],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('FinancialTable',{attrs:{"settings":[
              { text: 'Benfeitoria', value: 'improvement', width: 250, item: { type: 'TEXTINPUT' } },
              { text: 'Quantidade', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
              { text: 'Valor', value: 'value', width: 200, item: { type: 'MONEY' } },
              { text: '% uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
            ],"row-remove":"","row-add":"","value":_vm.inventory.improvement}})],1)],1)],1),_c('v-tab-item',{staticClass:"pt-3",attrs:{"value":"recipes"}},[_c('v-row',[_c('v-col',{staticClass:"ma-2"},[_c('v-data-table',{staticClass:"recipe-table",attrs:{"headers":[
              { text: 'Item', value: 'item', width: 300 },
              { text: 'Quantidade', value: 'quantity', width: 250 },
              { text: 'Valor', value: 'value', width: 250 },
            ],"items":_vm.recipe,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.item`,fn:function({ item }){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.item))])]}},{key:`item.quantity`,fn:function({item}){return [_c('v-text-field',{attrs:{"hide-details":"","placeholder":"Quantidade"},model:{value:(_vm.localData[item.qtdField]),callback:function ($$v) {_vm.$set(_vm.localData, item.qtdField, $$v)},expression:"localData[item.qtdField]"}})]}},{key:`item.value`,fn:function({item}){return [_c('money-input',{attrs:{"hide-details":"","placeholder":"Valor","prefix":"R$"},model:{value:(_vm.localData[item.valueField]),callback:function ($$v) {_vm.$set(_vm.localData, item.valueField, $$v)},expression:"localData[item.valueField]"}})]}}],null,true)})],1)],1)],1),_c('v-tab-item',{staticClass:"pt-3",attrs:{"value":"expenses"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('FinancialTable',{attrs:{"settings":[
              { text: 'Item', value: 'item', width: 250, item: { type: 'TEXTINPUT' } },
              { text: 'Quantidade', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
              { text: 'Valor', value: 'value', width: 200, item: { type: 'MONEY' } },
              { text: '% de uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
            ],"row-remove":"","row-add":"","value":_vm.expenses}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }