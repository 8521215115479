<template>
  <div>
    <v-row
      no-gutters
      class="text-xs-center"
    >
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>

        <v-row class="mx-2">
          <v-col>
            <v-autocomplete
              v-model="filters.regions"
              :loading="loading"
              :items="filters.regionsList"
              prepend-inner-icon="map"
              multiple
              dark
              label="Regiões"
              item-text="description"
              item-value="id"
              hide-details
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              @input="onRegionFilterSelect"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.status"
              :loading="loading"
              :items="filters.statusList"
              prepend-inner-icon="group"
              multiple
              dark
              label="Status"
              item-text="description"
              item-value="id"
              hide-details
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              @input="onFilter"
            >
              <template #item="{ item }">
                <v-list-item-action>
                  <v-checkbox :value="filters.status.includes(item.id)" />
                </v-list-item-action>
                <v-list-item-content :style="{color: statusColors[item.id]}">
                  {{ item.description }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.salesPeople"
              :loading="loading"
              :items="filters.salesPeopleList"
              prepend-inner-icon="supervisor_account"
              multiple
              dark
              label="Vendedores"
              item-text="name"
              item-value="id"
              hide-details
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              @input="onSalesPeopleFilter"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.customers"
              :loading="loading"
              :items="filters.customersList"
              prepend-inner-icon="person"
              multiple
              dark
              label="Cliente"
              item-text="name"
              item-value="id"
              hide-details
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              :filter="(person, queryText, itemText) => (`${person.code || ''} - ${person.name.toLocaleLowerCase()}`).indexOf(queryText.toLocaleLowerCase()) > -1"
              @input="onFilter"
            >
              <template #selection="{ item }">
                <div
                  v-if="item.code"
                  class="mx-1 font-weight-light"
                >
                  {{ item.code }} -
                </div>
                {{ item.name }}
                <div
                  v-if="item.status && !['APROVADO', 'ATIVO'].includes(item.status)"
                  class="mx-1 caption font-weight-light"
                >
                  ({{ item.status }})
                </div>
              </template>
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name" />
                  <v-list-item-subtitle
                    v-if="item.code"
                    class="mx-1"
                    v-text="item.code"
                  />
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row
          class="mx-2"
          wrap
        >
          <v-col cols="12">
            <v-card dark>
              <v-card-text class="pa-0">
                <route-map
                  ref="map"
                  :center="$store.state.settings.coordenadas"
                  :markers="markers"
                  :polygons="polygons"
                  height="700px"
                  disable-recenter
                  use-simple-marker
                  @onPolygonMarkers="onPolygonMarkers"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mx-2">
          <template v-for="region in regions">
            <v-col
              :key="region.id"
              :cols="columnSize"
            >
              <region-resume
                :customers="region.customers"
                text-left
              >
                <template slot="title">
                  <v-avatar
                    :color="region.color"
                    size="25"
                    class="mr-3"
                  />
                  {{ region.description }}
                </template>
              </region-resume>
            </v-col>
          </template>
        </v-row>

        <v-overlay :value="loading">
          <v-card-text>
            Carregando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-overlay>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import RegionResume from "@/Domains/Commercial/Map/Components/RegionResume.vue";

import { debounce } from "lodash";

const regionColors = [
  '#FF5722',
  '#512DA8',
  '#8BC34A',
  '#FFA000',
];

const statusColors = {
  ATIVO: '#4CAF50',
  PROSPECTADO: '#2196F3',
  INATIVO: '#f44336',
  LEAD: '#FFEB3B',
};
const salesPeopleColors = [
  '#f50057',
  '#d500f9',
  '#651fff',
  '#3d5afe',
  '#2979ff',
  '#00b0ff',
  '#00e5ff',
  '#1de9b6',
  '#00e676',
  '#76ff03',
  '#c6ff00',
  '#ffea00',
  '#ffc400',
  '#ff9100',
  '#ff3d00',
];

export default {
  name: "commercial-map",

  components: {
    RouteMap,
    RegionResume,
  },

  data() {
    return {
      // Loader
      loading: false,

      // Informações das regiões
      regions: [],

      // Polígonos das regiões
      polygons: [],

      // Localização dos clientes
      markers: [],

      filters: {
        regionsList: [],
        regions: [],

        statusList: [
          { id: 'ATIVO', description: "Ativo" },
          { id: 'PROSPECTADO', description: "Prospectado" },
          { id: 'INATIVO', description: "Inativo" },
          { id: 'LEAD', description: "Observação" },
        ],
        status: [],

        customersList: [],
        customers: [],

        salesPeopleList: [],
        salesPeople: [],
      },

      statusColors,
    };
  },

  computed: {
    columnSize() {
      return 12 / this.regions.length;
    },
  },

  async mounted() {
    await this.loadRegionsFilter();
    await this.loadCustomers();
    await this.loadSalesPeople();
  },

  methods: {
    /**
     * Método responsável por carregar todas as regiões disponíveis para o laticínio
     * @returns {Promise<void>}
     */
    async loadRegionsFilter() {
      try {
        const { data } = await this.$axios.post(`/regiao/listaJson`, this.$qs.stringify({
          tipo: 'COMERCIAL'
        }));

        this.filters.regionsList = data.map((item, idx) => {
          return {
            id: idx,
            description: item.nome,
            coordinates: JSON.parse(item.coordenadas || '[]')
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as regiões!", "Atenção");

        console.log(err);
      }
    },

    /**
     * Trata os dados dos clientes para ser utilizado pelos componentes
     * @param customers
     * @returns {Array}
     */
    customersParser(customers) {
      return customers
        .filter(customer =>
          !!customer.nome && customer.end_latitude && customer.end_longitude)
        .map((customer) => {
          const access = JSON.parse(customer.pessoa_acesso || '[]');
          const lastId = access.pop() || null;
          let index = this.filters.salesPeopleList.findIndex(o => o.id == lastId);
          return {
            id: customer.id_pessoa,
            code: customer.codigo_laticinio,
            name: customer.nome,
            status: customer.status || 'ATIVO',
            location: {
              lat: parseFloat(customer.end_latitude.replace(/^0+/, '')),
              lng: parseFloat(customer.end_longitude.replace(/^0+/, ''))
            },
            color: index > -1 ? (salesPeopleColors[index] || null) : null,
          };
        })
        .filter(customer => customer.location.lat && customer.location.lng);
    },

    /**
     * Método responsável por carregar os clientes
     * @returns {Promise<void>}
     */
    async loadCustomers() {
      this.loading = true;
      try {

        const salesPeople = this.filters.salesPeople;

        let { data } = await this.$axios.post(
          `/pessoa/listaClientes`,
          this.$qs.stringify({
            vendedores: JSON.stringify(salesPeople)
          })
        );

        this.filters.customersList = this.customersParser(data);

        this.onFilter();
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o mapa!", "Atenção");

        console.log(err);
      }
    },

    /**
     * Método responsável por carregar as regiões
     * @returns {Promise<void>}
     */
    async loadRegions() {
      try {
        this.loading = true;

        this.regions = this.filters.regions
          .map(id => this.filters.regionsList[id]);

        this.polygons = this.regions.map(({ coordinates, description }, index) =>
          ({ coordinates, title: description, editable: false, color: regionColors[index] }));

        this.regions = this.regions.map((region, index) => {
          return {
            ...region,
            customers: [],
            color: regionColors[index]
          }
        })

        setTimeout(() => {
          this.$refs.map.setMapPolygons();
        }, 300);

      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Método responsável por carregar os vendedores
     * @returns {Promise<void>}
     */
    async loadSalesPeople() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaVendedoresJson`);

        this.filters.salesPeopleList = data.map(o => ({ id: o.id_pessoa, name: o.nome }));

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os vendedores!", "Atenção");

        console.log(err);
      }
    },

    /**
     * @event array
     * Filtro por região
     */
    onRegionFilterSelect(e) {
      if (e.length > 4) {
        e.pop();
        return;
      }
      this.onFilter();

      return this.loadRegions();
    },

    /**
     * @event array
     * Filtro por técnico
     */
    onSalesPeopleFilter(e) {
      if (e.length > 4) {
        e.pop();
        return;
      }
      this.onApiFilter();
    },

    onApiFilter: debounce(function () {
      this.loadCustomers();
    }, 1000),

    /**
     * Utilizado por multiplos filtros, para exibir as informações dos clientes
     * através dos marcadores nos mapas
     */
    onFilter: debounce(function () {
      this.loading = true;
      let status = this.filters.status;
      let customers = this.filters.customers;

      const onCustomersType = customerType => (status.length == 0) || status.includes(customerType);
      const onCustomers = customer => (customers.length == 0) || customers.includes(customer);

      let markers = [...this.filters.customersList];

      if (status.length > 0 || customers.length > 0) {
        markers = markers.filter(marker => {
          return onCustomersType(marker.status) && onCustomers(marker.id);
        })
      }

      markers = markers
        .map((customer, idx) => ({
          ...customer,
          label: idx + 1,
          draggable: false,
          visible: true,
          color: customer.color || statusColors[customer.status],
          title: customer.name,
          richMeta: {
            "Cliente": customer.name,
          }
        }));

      // Adiciona o laticínio
      if (this.$store.state.settings.laticinio) {
        markers.push({
          key: `dairy`,
          location: {
            lat: this.$store.state.settings.coordenadas.lat,
            lng: this.$store.state.settings.coordenadas.lng,
          },
          draggable: false,
          label: this.$store.state.settings.laticinio.name,
          color: 'red',
          visible: true,
        });
      }
      else {
        for (const dairy of this.$store.state.settings.laticinios) {
          markers.push({
            key: `dairy-${dairy.id}`,
            location: dairy.location,
            draggable: false,
            label: dairy.name,
            color: 'red',
            visible: true,
          });
        }
      }
      this.markers = markers;
      this.loading = false;
    }, 1000),

    /**
     * @event array
     * Evento acionado pelo mapa, onde exibe os marcadores que estão dentro de cada polígono
     */
    onPolygonMarkers(polygonMarkers) {
      const regions = [];
      for (let idx in polygonMarkers) {
        const markers = polygonMarkers[idx];
        regions.push({
          ...this.regions[idx],
          customers: markers
        })
      }

      this.regions = regions;
    },
    
  },
};
</script>
