<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        Bloqueio de edição/inserção de coletas
      </v-card-title>
      <v-divider />
      <v-card-text class="pb-0">
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <date-text-field
                v-model="dateBlock"
                label="Data do bloqueio *"
                prepend-inner-icon="event"
                no-title
                :max="dateMax"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="v-btt2 black--text pa-0"
          text
          color="red darken-1"
          @click="close"
        >
          Fechar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            class="mb-0"
            color="white"
            indeterminate
          />
        </v-card-text>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import get from "lodash/get";
import moment from "moment";
import DateTextField from "@/Support/Components/DateTextField.vue";

export default {

  components: {
    DateTextField
  },

  data() {
    return {
      loading: false,
      dialog: false,

      dateMax: moment().add(-1, 'days').format("YYYY-MM-DD"),

      dateBlock: null,

    };
  },
  methods: {

    async show() {
      this.dialog = true;
      this.dateBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta || this.dateMax;
    },
    close() {
      this.dialog = false;
    },

    async save() {
      try {
        this.loading = true;

        const dairyId = get(this.$store.state.settings, 'laticinio.id');

        if (!dairyId) {
          this.$snotify.error("Escolha o laticinio!", "Atenção");
          return;
        }

        await this.$axios.put(`/settings/dairy/${dairyId}`, {
          data_bloqueio_edicao_coleta: this.dateBlock,
          data_hora_alteracao_bloqueio: moment().format('YYYY-MM-DD HH:mm:ss')
        });

        this.$store.dispatch('updateStoreSetting');
      } catch (err) {
        this.$snotify.error("Oops, erro ao salvar!", "Atenção");
        console.warn(err);

      } finally {
        this.loading = false;
        this.dialog = false;
      }

    },
  }
}
</script>
