<template>
  <v-row>
    <v-col
      cols="12"
      md="8"
    >
      <v-card
        class="mx-auto"
        dark
        :style="{background: color, height: `${140 + height}px !important`}"
        :loading="loading"
      >
        <v-card-title>
          <v-icon
            large
            left
          >
            show_chart
          </v-icon>
          <span class="title font-weight-light">Comparativo de Visitas Realizadas </span>
        </v-card-title>

        <v-tabs
          v-model="general.tab"
          align-with-title
          background-color="transparent"
          class="transparent"
          dark
        >
          <v-tabs-slider color="cyan" />

          <v-tab>Gráfico</v-tab>
          <v-tab>Relatório</v-tab>

          <v-tab-item>
            <v-chart
              :options="generalGraph.options"
              :style="{width: '100%', height: `${height}px !important`}"
              autoresize
            />
          </v-tab-item>

          <v-tab-item>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="general.headers"
                  :items="general.report"
                  dark
                  dense
                  class="elevation-1 report-table"
                  hide-default-footer
                  disable-pagination
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="4"
    >
      <v-card
        class="mx-auto"
        dark
        :style="{background: color, height: `${140 + height}px !important`}"
        :loading="loading"
      >
        <v-card-title>
          <v-icon
            large
            left
          >
            show_chart
          </v-icon>
          <span class="title font-weight-light">Visitas Realizadas</span>
        </v-card-title>

        <v-chart
          :options="pieGraph.options"
          :style="{width: '100%', height: `${pieGraph.height}px !important`}"
          autoresize
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import moment from 'moment';
import _ from 'lodash';

export default {

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      general: {
        tab: null,
        headers: [
          { text: 'Tipo', value: 'Tipo' },
          { text: 'Nº de Visitas', value: 'Nº de Visitas' },
          { text: 'Meta', value: 'Meta' },
        ],
        category: [],
        visits: [],
        goals: [],
        report: [],
      }
    }
  },

  computed: {
    height() {
      return Math.max(this.generalGraph.height, 300)
    },
    generalGraph() {
      return {
        height: 70 + (this.general.visits.length * 30),
        options: {
          color: ['rgba(38, 198, 218, 0.7)', 'rgba(38, 198, 218, 0.2)'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: { backgroundColor: '#6a7985' }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            top: '0%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff'
            }
          },
          yAxis: {
            type: 'category',
            boundaryGap: true,
            data: this.general.category,
            axisLabel: {
              color: '#fff'
            }
          },
          series: [
            {
              name: 'Visitas',
              type: 'bar',
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              data: this.general.visits,
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'right',
                formatter: `  {c}  `
              }
            },
            {
              name: 'Meta',
              type: 'bar',
              barGap: '-100%',
              data: this.general.goals,
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#48535c',
                color: '#fff',
                borderRadius: 5,
                position: 'right',
                formatter: ({ value }) => value ? `  ${value}  ` : ''
              }
            }
          ]
        }
      }
    },
    pieGraph() {
      return {
        height: 350,
        options: {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}'
          },
          legend: {
            bottom: '0%',
            left: 'center',
            icon: 'circle',
            textStyle: {
              color: '#ddd',
            }
          },
          series: [
            {
              type: 'pie',
              data: this.general.report.map(o => ({
                name: o['Tipo'],
                value: o['Nº de Visitas'],
              })),
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
              },
            }
          ]
        },
      }
    },
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(
          `/relatorioVisitaMeta/relatorio_geral`,
          this.$qs.stringify({
            data_ini: moment(startDate).format('DD/MM/YYYY'),
            data_fim: moment(endDate).format('DD/MM/YYYY'),
          })
        );

        if (!_.isObject(data) ) {
          throw "PHP Errors";
        }

        this.general.category = data.meta_relatorio_geral.map(o => o['Tipo']);
        this.general.visits = data.meta_relatorio_geral.map(o => o['Nº de Visitas']);
        this.general.goals = data.meta_relatorio_geral.map(o => o['Meta']);

        this.general.report = data.meta_relatorio_geral;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de visitas Zootécnico!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
