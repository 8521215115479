<template>
  <div>
    <v-dialog
      v-model="visible"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-if="collect"
              ref="form"
              lazy-validation
              @submit.prevent="save()"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-menu
                    v-model="collectedAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="collectedAt"
                        label="Data *"
                        placeholder=" "
                        prepend-inner-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="collect.collectedAt"
                      :min="dateMin"
                      @input="collectedAtMenu = false"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <masked-input
                    v-model="collect.collectStartedAt"
                    label="Hora Chegada *"
                    placeholder=" "
                    prepend-inner-icon="schedule"
                    :mask="{
                      mask: '00:00',
                    }"
                    :rules="[
                      v => !!v || 'Campo obrigatório!',
                      v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
                    ]"
                    :max-length="16"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <masked-input
                    v-model="collect.collectEndedAt"
                    label="Hora Saída *"
                    placeholder=" "
                    prepend-inner-icon="schedule"
                    :mask="{
                      mask: '00:00',
                    }"
                    :rules="[
                      v => !!v || 'Campo obrigatório!',
                      v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
                    ]"
                    :max-length="16"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                >
                  <v-autocomplete
                    v-model="collect.producer"
                    label="Produtor *"
                    prepend-inner-icon="person_outline"
                    placeholder=" "
                    :items="producers"
                    :rules="[v => !!v.id || 'Campo obrigatório!']"
                    item-value="id"
                    item-text="code_name"
                    return-object
                    required
                    :change="changeCondominium()"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="!collect.rejected"
              >
                <v-col
                  v-for="tank in collect.tanks"
                  :key="tank.key"
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model.number="tank.vol"
                    :label="tank.label"
                    prepend-inner-icon="opacity"
                    placeholder=" "
                    :rules="[() => totalVol > 0 || '']"
                    required
                    @keypress="disableDotAndComma"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  v-if="!collect.rejected"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    label="Volume (L)"
                    :value="totalVol"
                    prepend-inner-icon="opacity"
                    placeholder=" "
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="collect.temp"
                    label="Temperatura *"
                    type="number"
                    :rules="[
                      v => !!v || 'Campo obrigatório!',
                      v => v <= 16 || 'Temperatura Inválida!',
                    ]"
                    prepend-inner-icon="ac_unit"
                    placeholder=" "
                    @keypress="disableDot"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="collect.sample"
                    label="Amostra"
                    prepend-inner-icon="qr_code"
                    placeholder=" "
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="collect.alizarol"
                    label="Alizarol *"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    prepend-inner-icon="biotech"
                    placeholder=" "
                    :items="alizarolOptions"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-checkbox
                    v-model="collect.rejected"
                    color="red"
                    label="Coleta Rejeitada?"
                    @change="onRejectedChange"
                  />
                </v-col>
                <v-col
                  v-if="collect.rejected"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model.number="collect.rejectedVol"
                    label="Volume Rejeitado (L) *"
                    prepend-inner-icon="opacity"
                    placeholder=" "
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    @keypress="disableDotAndComma"
                  />
                </v-col>
              </v-row>

              <v-row v-if="collect.condominium.length>0">
                <v-col md="12">
                  Volume dos produtores (condomínio)
                </v-col>
                <v-col
                  v-for="cond in collect.condominium "
                  :key="cond.id_pessoa"
                  md="4"
                >
                  <v-text-field
                    v-model="cond.volume"
                    prepend-inner-icon="opacity"
                    :label="cond.nome"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="collect.obs"
                    label="Observação"
                    placeholder=" "
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {

  components: {
    MaskedInput,
  },

  data() {
    return {
      loading: false,

      // Date Picker
      collectedAtMenu: false,

      visible: false,
      title: '',
      collect: null,
      producers: [],
      dateMin: null,
    };
  },

  computed: {

    totalVol() {
      if (_.isEmpty(this.collect)) {
        return 0;
      }

      return _.sumBy(this.collect.tanks, tank => tank.vol || 0);
    },

    collectedAt() {
      if (_.isEmpty(this.collect)) {
        return moment().format('DD/MM/YYYY');
      }

      return moment(this.collect.collectedAt).format('DD/MM/YYYY');
    },

    collectStartedAt() {
      return moment(this.collect.collectStartedAt).format('HH:mm');
    },

    usesAlizarolGrade() {
      return this.$store.state.settings.gerais.utiliza_graduacao_de_alizarol || false
    },

    alizarolOptions() {
      if (this.usesAlizarolGrade) {
        const grades = this.$store.state.settings.gerais.graduacoes_alizarol || []

        if (grades.length > 0) {
          return [
            { value: '72', text: 'Graduação 72 (Rejeitado)' },
            ...grades.map(value => ({ value, text: `Graduação ${value}` }))
          ]
        }

        return [
          { value: '72', text: 'Graduação 72 (Rejeitado)' },
          { value: '74', text: 'Graduação 74' },
          { value: '76', text: 'Graduação 76' },
          { value: '78', text: 'Graduação 78' },
          { value: '80', text: 'Graduação 80' },
        ]
      }

      return [
        { value: 'Positivo', text: 'Positivo' },
        { value: 'Negativo', text: 'Negativo' },
      ]
    }

  },

  async mounted() {
    await this.loadProducers();
  },

  methods: {

    async loadProducers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/allProdutores`);

        if (_.isString(data)) {
          throw data;
        }

        this.producers = data.map(producer => {
          return {
            id: producer.id_pessoa,
            code: producer.codigo_laticinio,
            name: producer.nome,
            code_name: producer.codigo_laticinio + ' - ' + producer.nome,
            ccs: parseInt(producer.ccs) || 0,
            cbt: parseInt(producer.cbt) || 0,
            vol: parseInt(producer.litros) || 0,
            condominium: producer.condominio ? JSON.parse(producer.condominio) : [],
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      }
    },

    show(collect, dataInicio) {
      // Verifica se já existe um formulário carregado em memória e o reseta
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      if (!collect.id) {
        this.title = `Nova Coleta`;
      } else {
        this.title = `Editando Coleta`;
      }

      this.collect = collect;
      this.visible = true;

      this.dateMin = moment(dataInicio).format("YYYY-MM-DD");
    },

    onRejectedChange(value) {
      if (value && this.totalVol) {
        this.collect.rejectedVol = this.totalVol;
      }
    },

    close() {
      this.loading = false;
      this.title = ``;
      this.visible = false;
      this.collect = null;
    },

    save() {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return;
      }

      if (this.collect.condominium.length > 0) { //se o volume nao bater mostrar na tela
        const vol_condominium =  _.sumBy(this.collect.condominium, condominio => parseFloat(condominio.volume) || 0);
        if (vol_condominium != this.totalVol && !this.collect.rejected) {
          this.$snotify.error(
            "Volume total diferente da soma dos condôminos",
            "Atenção"
          );
        }
      }

      this.loading = true;

      return this.$emit('onSave', this.collect);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),

    //quando altera o produtor recarrega os condominos
    changeCondominium() {
      if (this.collect.producer.condominium) {
        this.collect.condominium = this.collect.producer.condominium;
      }
    }
  },

}
</script>
