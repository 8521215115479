<template>
  <div>
    <v-menu
      offset-y
      @input="onDialogChanged"
    >
      <template #activator="{ on, attrs }">
        <v-badge
          :value="unreadNotifications > 0"
          :content="unreadNotifications < 10 ? unreadNotifications : '9+'"
          color="error"
          overlap
        >
          <v-btn
            :elevation="0"
            large
            icon
            class="mx-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="rgba(255, 255, 255, 0.7)">
              notifications
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Notificações
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-chip
              v-if="unreadNotifications > 0"
              color="info"
            >
              {{ unreadNotifications }} não lidas
            </v-chip>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </v-list>
      <v-list
        class="overflow-x-auto"
        style="max-height: calc(100vh - 150px)"
      >
        <v-list-item
          v-for="(notification, idx) of notifications"
          :key="idx"
        >
          <v-list-item-icon>
            <v-icon :color="getNotificationColor(notification)">
              {{ getNotificationIcon(notification) }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :class="{ 'font-weight-medium': !notification.read }">
              {{ notification.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="notification.content"
              v-html="notification.content"
            />
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text>{{ formatDate(notification.time, 'DD/MM HH:mm:ss') }}</v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-btn
              outlined
              block
              color="primary"
              @click="showNotifications"
            >
              Ver todas as notificações
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="dialog"
      width="600"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-title>
          Notificações
          <v-spacer />
          <v-chip
            v-if="unreadNotifications > 0"
            color="info"
          >
            {{ unreadNotifications }} não lidas
          </v-chip>
        </v-card-title>

        <v-card-text>
          <transition name="slide">
            <v-list>
              <v-list-item
                v-for="notification of pagination.notifications"
                :key="notification.id"
              >
                <v-list-item-icon>
                  <v-icon :color="getNotificationColor(notification)">
                    {{ getNotificationIcon(notification) }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="{ 'font-weight-medium': !notification.read }">
                    {{ notification.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="notification.content"
                    v-html="notification.content.replace('\r\n', '<br>')"
                  />
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>{{ formatDate(notification.time, 'DD/MM/YYYY HH:mm:ss') }}</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </transition>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-pagination
            v-model="pagination.currentPage"
            :length="pagination.lastPage"
            @input="loadNotifications"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import moment from 'moment'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

const { progressBar, notify } = useUtils()

const dialog = ref(false)
const notifications = ref([])
const unreadNotifications = ref(0)
const markAsReadTimeout = ref(undefined)
const pagination = ref({
  notifications: [],
  currentPage: 1,
  lastPage: 0
})

const showNotifications = () => {
  dialog.value = true
}

const loadNotifications = async (page = 1) => {
  try {
    if (dialog.value) {
      progressBar?.loading()

      cancelMarkAsRead()
    }

    const [{ data }, { data: unread }] = await Promise.all([
      axios.get(`/notifications?page=${page}`),
      axios.get(`/notifications/unread`),
    ])

    const items = data.data.map(item => ({
      id: item.id,
      title: item.notificacao?.title,
      content: item.conteudo[0]?.texto,
      level: item.notificacao.level,
      type: item.tipo,
      time: item.data_hora_cadastro,
      read: !!item.data_hora_visualizacao
    }))

    pagination.value = {
      currentPage: data.current_page,
      lastPage: data.last_page,
      notifications: items,
    }

    if (page === 1) {
      notifications.value = [...items]
    }
    unreadNotifications.value = unread

    if (dialog.value) {
      markAsRead([...items])
    }
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar as notificações!', 'Atenção')

  } finally {
    progressBar?.hide()
  }
}

const onDialogChanged = (isOpen) => {
  cancelMarkAsRead()

  if (isOpen) {
    markAsRead([...pagination.value.notifications])
  }
}

const cancelMarkAsRead = () => {
  if (markAsReadTimeout.value !== undefined) {
    clearTimeout(markAsReadTimeout.value)
    markAsReadTimeout.value = undefined
  }
}

const markAsRead = (notifications) => {
  markAsReadTimeout.value = setTimeout(() => {
    markNotificationsAsRead(notifications)

    markAsReadTimeout.value = undefined
  }, 3000)
}

const markNotificationsAsRead = async (items) => {
  try {
    const ids = items
      .filter(item => !item.read)
      .map(item => item.id)

    if (ids.length === 0) {
      return
    }

    await axios.put(`/notifications/read`, { ids })

    const updateNotification = item => ({ ...item, read: item.read || ids.includes(item.id) })

    notifications.value = notifications.value.map(updateNotification)

    pagination.value.notifications = pagination.value.notifications.map(updateNotification)

    unreadNotifications.value -= ids.length

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao marcar as notificações!', 'Atenção')
  }
}

const getNotificationColor = (notitication) => {
  const colors = {
    SUCCESS: 'green',
    DANGER: 'red',
    WARN: 'orange',
    INFO: 'grey',
  }

  return colors[notitication.level] || 'grey'
}

const getNotificationIcon = (notitication) => {
  const icons = {
    SUCCESS: 'check_circle',
    DANGER: 'report',
    WARN: 'report',
    INFO: 'info',
  }

  return icons[notitication.level] || 'notifications'
}

const add = (notification) => {
  unreadNotifications.value++

  notifications.value.unshift(notification)

  if (pagination.value.currentPage === 1) {
    pagination.value.notifications.unshift(notification)
  }

  if (notifications.value.length > 10) {
    notifications.value.pop()

    if (pagination.value.currentPage === 1) {
      pagination.value.notifications.pop()
    }
  }
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)

loadNotifications()

// eslint-disable-next-line no-undef
defineExpose({
  add
})
</script>
