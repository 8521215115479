<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent="save()"
    >
      <v-row
        v-if="form.length > 0"
        style="border-bottom: 1px #eee solid;"
        :class="{ 'yellow lighten-5': !!editId }"
        no-gutters
      >
        <v-col
          v-if="editId"
          cols="12"
          class="font-italic black--text pb-0 pt-2 d-flex"
        >
          <div class="mt-1 mr-2">
            Editando
          </div>

          <v-btn
            icon
            small
          >
            <v-icon
              color="red"
              small
              @click="reset()"
            >
              close
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-for="(field, idx) of form"
          :key="idx"
          class="pt-1 pb-0"
          cols="6"
          sm="3"
          md="2"
        >
          <v-select
            v-if="field.type === 'TANK'"
            v-model="field.value"
            :items="tinas"
            label="Queijomatic"
            item-value="id_tina"
            item-text="descricao"
            return-object
            :disabled="!canEdit"
            :rules="[field.required ? rules.required : true]"
          />
          <v-select
            v-else-if="field.type === 'SELECT'"
            v-model="field.value"
            :items="field.options"
            :label="field.label"
            :disabled="!canEdit"
            :rules="[field.required ? rules.required : true]"
          />
          <v-text-field
            v-else-if="field.type === 'NUMBER'"
            v-model.number="field.value"
            :label="field.label"
            type="number"
            inputmode="numeric"
            :disabled="!canEdit"
            :rules="[field.required ? rules.required : true]"
            @keypress="disableDot"
          />
          <masked-text-field
            v-else-if="field.type === 'DATE'"
            v-model="field.value"
            :label="field.label"
            prepend-inner-icon="event"
            placeholder="00/00/0000"
            :mask="{ mask: '00/00/0000' }"
            :max-length="10"
            inputmode="numeric"
            clearable
            :rules="[rules.date, field.required ? rules.required : true]"
            validate-on-blur
          />
          <masked-text-field
            v-else-if="field.type === 'TIME'"
            v-model="field.value"
            :label="field.label"
            prepend-inner-icon="event"
            placeholder="00:00"
            :mask="{ mask: '00:00' }"
            :max-length="5"
            inputmode="numeric"
            clearable
            :rules="[rules.time, field.required ? rules.required : true]"
            validate-on-blur
          />
          <v-text-field
            v-else
            v-model="field.value"
            :label="field.label"
            :disabled="!canEdit"
            :rules="[field.required ? rules.required : true]"
          />
        </v-col>
      </v-row>
      <v-row v-if="canEdit && form.length === 0">
        <v-col
          cols="12"
          class="text-center"
          :class="{ 'has-error': hasError }"
        >
          Nenhum campo cadastrado
        </v-col>
      </v-row>
    </v-form>

    <span class="text-h6 d-block mt-4">Registros</span>

    <v-divider class="mb-4" />

    <v-data-table
      :headers="headers"
      :items="records"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
    >
      <template #[`item.date`]="{ value }">
        {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-menu
          v-if="item.id !== editId"
          bottom
          right
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-if="hasEditAccess"
              @click="edit(item)"
            >
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Editar
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="hasDeleteAccess"
              @click="remove(item)"
            >
              <v-list-item-icon>
                <v-icon>delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Excluir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
.has-error {
  animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: red;
}
</style>

<script>
import moment from 'moment';

import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

export default {

  components: {
    MaskedTextField
  },

  props: {
    canEdit: Boolean,
    custom: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Object,
      default: () => ({
        items: [],
        records: [],
      })
    },
    step: Object,
  },

  data() {
    return {

      editId: null,
      form: [],

      headers: [],

      records: [],

      tinas: [],

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
      },

      hasError: false,

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        dateTime: v => this.isDateValid(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!',
        date: v => this.isDateValid(v, 'DD/MM/YYYY') || 'Data Inválida!',
        time: v => this.isDateValid(v, 'HH:mm') || 'Hora Inválida!',
      },
    }
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasEditAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-edicao-apontamento' && o.tipo === 'COMPONENTE');
    },
    hasDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-exclusao-apontamento' && o.tipo === 'COMPONENTE');
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      this.form = [ ...this.custom.fields ];

      this.records = this.order.appointments
        .filter(item => item.id_apontamento === this.custom.id)
        .map(item => {
          const row = {
            'id': item.id,
            'date': item.data_hora_cadastro,
            'person': item.nome_pessoa_registro,
          };

          return item.apontamentos.reduce((acc, cur) => {
            acc[cur.id_rotulo] = cur.tipo === 'TANK' ? cur.resposta.descricao : cur.resposta;
            return acc;
          }, row)
        })

      this.headers = [
        ...this.custom.fields.map(field => ({
          text: field.label,
          value: field.id
        })),
        { text: 'Data e Hora', value: 'date' },
        { text: 'Pessoa', value: 'person' },
        { text: '', value: 'actions', align: 'end', width: 30, sortable: false },
      ];

      try {
        const { data } = await this.$axios.get(`/silo/listaTina`);

        this.tinas = data;
      } catch (e) {
        this.$snotify.error('Erro ao carregar as tinas', 'Atenção');
        console.warn(e);
      }
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.hasError = this.form.length === 0;

        if (this.hasError) {
          setTimeout(() => {
            this.hasError = false;
          }, 2000);

          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_item: this.order.items[0].itemId,
          id_apontamento: this.custom.id,
          nome_apontamento: this.custom.title,
          id_ordem_producao: this.order.id,
          id_etapa: this.step?.id,
          etapa: this.step?.description,
          apontamentos: this.form
            .filter(field => !!field.value)
            .map((item) => ({
              id_rotulo: item.id,
              rotulo: item.label,
              resposta: item.value,
              opcoes: item.options,
              tipo: item.type,
            })),
        };

        if (this.editId) {
          await api.updateAppointment(this.editId, payload);
        } else {
          await api.storeAppointment(payload);
        }

        this.$snotify.success('Apontamento efetuado com sucesso', 'Sucesso');
        this.reload();
      } catch (e) {
        this.$snotify.error('Erro ao efetuar apontamento', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
        this.reset();
      }
    },

    reset() {
      this.editId = null;
      this.form = this.form.map(field => ({
        ...field,
        value: null
      }));

      this.$refs.form?.resetValidation();
    },

    edit(item) {
      this.editId = item.id;
      this.form = this.form.map(field => ({
        ...field,
        value: item[field.id]
      }));
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm(`Remover apontamento?`, `Esta ação não pode ser desfeita.`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await api.destroyAppointment(id);

        const index = this.records.findIndex(item => item.id === id);
        this.records.splice(index, 1);

        this.$snotify.success('Apontamento removido com sucesso');
      } catch (error) {
        this.$snotify.error(`Erro ao remover apontamento.`, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    reload() {
      this.$emit('reload');
    },

    isDateValid(value, format) {
      if (!value) {
        return true;
      }

      if (value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
