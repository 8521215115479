<template>
  <div>
    <v-row>
      <v-col>
        <v-chart
          ref="chart"
          :options="generateGraphOptions(typeChecklist.items,typeChecklist.text)"
          :style="{width: '100%', height: `350px !important`}"
          autoresize
        />
      </v-col>
    </v-row>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando checklists...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";

export default {
  name: "questionnaire-graphic",

  components: {
    
  },
  
  props: {
    goal: Object,
    filter: Object,
  },

  data() {
    return {
      loading: false,

      typeChecklist:[],
    };
  },
  
  watch: {
    filter: {
      handler() {
        this.loadChecklistReport();
      },
      deep: true
    }
   
  },

  mounted() {
    this.loadChecklistReport();
  },
   
  methods: {

    async loadChecklistReport() {
      let id_formulario = this.goal.id_formulario;
      this.loading = true;
      try {
            
        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaRespondidosMes`,
          this.$qs.stringify({
            id_formulario: id_formulario,
            data_inicio: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.project.dateEnd).endOf('month').format('YYYY-MM-DD'),
          })
        );
        
        this.typeChecklist =  {
          id_formulario: id_formulario,
          text: this.goal.questionnaire.text,
          period: this.goal.period,
          period_reapply: this.goal.period_reapply,
          producer: this.goal.producer,
          producer_reapply: this.goal.producer_reapply,
          items: data,
        }
        return this.$emit("onLoadChecklist", this.typeChecklist);
       
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar checklist", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    generateGraphOptions(data, title) {
      if (_.isEmpty(data)) {
        return;
      }
      
      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(229, 57, 53, 0.7)',   'rgba(140, 23, 23, 1)',  'rgba(255, 255, 0, 0.7)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 500,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {

                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });
                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.mes)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Produtores aplicados',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => o.total_produtores),
          },
          {
            name: 'Ativos',
            type: 'bar',
            barGap: '-100%',
            data: data.map(o => o.ativos),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },

          {
            name: 'Desvinculados',
            type: 'bar',
            barGap: '-100%',
            data: data.map(o => o.desvinculados),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },
          {
            name: 'Suspensos',
            type: 'bar',
            barGap: '-100%',
            data: data.map(o => o.suspensos),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },
          // {
          //   name: 'Produtores desvinculados',
          //   type: 'bar',
          //   showSymbol: false,
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   label: {
          //     show: true,
          //     lineHeight: 20,
          //     height: 20,
          //     backgroundColor: '#6a7985',
          //     color: '#fff',
          //     borderRadius: 5,
          //     position: 'top',
          //     distance: 1,
          //     formatter: '  {c}  ',
          //   },
          //   data: data.map(o => o.desvinculados),
          // },
          // {
          //   name: 'Produtores suspensos',
          //   type: 'bar',
          //   showSymbol: false,
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   label: {
          //     show: true,
          //     lineHeight: 20,
          //     height: 20,
          //     backgroundColor: '#6a7985',
          //     color: '#fff',
          //     borderRadius: 5,
          //     position: 'top',
          //     distance: 1,
          //     formatter: '  {c}  ',
          //   },
          //   data: data.map(o => o.suspensos),
          // },
         
        ]
      };
    },
    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
  },
};
</script>