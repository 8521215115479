<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="service-index pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Serviços - {{this.type}}
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <div>
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-autocomplete
              v-model="filters.group"
              label="Grupo"
              placeholder="Selecione um Grupo"
              :items="groupsListSelect"
              item-text="group"
              item-value="id"
              hide-details
              filled
              clearable
              @change="loadGroups"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
              filled
            />
          </v-col>
        </v-card-title>
        <v-data-table
          ref="report"
          :headers="headers"
          :items="groupsList"
          :search="filters.search"
          show-expand
          item-key="id"
          class="elevation-1"

        >
          <template #header.actions>
            <span>
              <v-btn
                x-small
                color="green"
                @click="showDialogGroup" >
                <v-icon x-small>
                  add
                </v-icon>
              </v-btn>
            </span>
          </template>

          <template #expanded-item="{ headers, item }"  >
            <td
              :colspan="headers.length"
              class="pa-0 text-center"
            >
              <v-data-table
                :headers="headersService"
                :items="item.services"
                :search="filters.search"
                :loading="loading"
                :items-per-page="item.services.length"
                hide-default-footer
                class="elevation-1 ma-3"
                style="white-space: nowrap"
                show-expand
                item-key="idService"
              >
                <template #header.actions>
                  <span>
                    <v-btn
                      x-small
                      color="green"
                      @click="showDialogService('', item.id)"
                    >
                      <v-icon x-small>
                        add
                      </v-icon>
                    </v-btn>
                  </span>
                </template>

                <template #[`item.actions`]="{ item }">
                  <v-menu>
                    <template #activator="{ on }">
                      <v-btn
                        dark
                        icon
                        v-on="on"
                      >
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="showDialogService(item.idService, item.id)"
                      >
                        <v-list-item-icon>
                          <v-icon>
                            edit
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Editar
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="deleteItemService(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>
                            delete
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          Excluir
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <template #[`item.associatedSubsidyValue`]="{ item }">
                  <span>
                    {{ formatNumber(item.associatedSubsidyValue) }}
                  </span>
                </template>

                <template #[`item.nonAssociatedSubsidyValue`]="{ item }">
                  <span>
                    {{ formatNumber(item.nonAssociatedSubsidyValue) }}
                  </span>
                </template>

                <template #[`item.viewApp`]="{ item }">
                  <v-switch
                    v-model="item.viewApp"
                    label=""
                    dense
                    hide-details
                    class="mt-0"
                    color="green accent-3"
                    disabled
                  />
                </template>

                <template #expanded-item="{ headers, item }"  >
                  <td
                    :colspan="headers.length"
                    class="pa-0 text-center"
                  >
                    <v-row>
                        <v-col
                          cols="4"
                        >
                          <v-data-table
                            :headers="headerCauses"
                            :items="item.causes"
                            :search="filters.search"
                            :loading="loadingCause"
                            :items-per-page="item.causes.length"
                            hide-default-footer
                            class="elevation-1 ma-3"
                            style="white-space: nowrap"
                          >
                            <template #header.actions>
                              <span>
                                <v-btn
                                  x-small
                                  color="green"
                                  @click="showDialogCause('', item)">
                                  <v-icon x-small>
                                    add
                                  </v-icon>
                                </v-btn>
                              </span>
                            </template>

                            <template #[`item.actions`]="{ item }">
                              <v-menu>
                                <template #activator="{ on }">
                                  <v-btn
                                    dark
                                    icon
                                    v-on="on"
                                  >
                                    <v-icon>more_vert</v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    @click="showDialogCause(item.idCause, item)"
                                  >
                                    <v-list-item-icon>
                                      <v-icon>
                                        edit
                                      </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                      Editar
                                    </v-list-item-title>
                                  </v-list-item>

                                  <v-list-item
                                    @click="deleteItemCause(item)"
                                  >
                                    <v-list-item-icon>
                                      <v-icon>
                                        delete
                                      </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                      Excluir
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </template>
                          </v-data-table>
                        </v-col>
                      <v-col
                        cols="4"
                      >
                        <v-data-table
                          :headers="headerIndicators"
                          :items="item.indicators"
                          :search="filters.search"
                          :loading="loadingIndicator"
                          :items-per-page="item.indicators.length"
                          hide-default-footer
                          class="elevation-1 ma-3"
                          style="white-space: nowrap"
                        >
                          <template #header.actions>
                              <span>
                                <v-btn
                                  x-small
                                  color="green"
                                  @click="showDialogIndicator('', item)">
                                  <v-icon x-small>
                                    add
                                  </v-icon>
                                </v-btn>
                              </span>
                          </template>

                          <template #[`item.actions`]="{ item }">
                            <v-menu>
                              <template #activator="{ on }">
                                <v-btn
                                  dark
                                  icon
                                  v-on="on"
                                >
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  @click="showDialogIndicator(item.idIndicator, item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      edit
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Editar
                                  </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                  @click="deleteItemIndicator(item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      delete
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Excluir
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col
                        cols="4"
                      >
                        <v-data-table
                          :headers="headerRecommendations"
                          :items="item.recommendations"
                          :search="filters.search"
                          :loading="loadingRecommendation"
                          :items-per-page="item.recommendations.length"
                          hide-default-footer
                          class="elevation-1 ma-3"
                          style="white-space: nowrap"
                        >
                          <template #header.actions>
                              <span>
                                <v-btn
                                  x-small
                                  color="green"
                                  @click="showDialogRecommendation('', item)">
                                  <v-icon x-small>
                                    add
                                  </v-icon>
                                </v-btn>
                              </span>
                          </template>

                          <template #[`item.actions`]="{ item }">
                            <v-menu>
                              <template #activator="{ on }">
                                <v-btn
                                  dark
                                  icon
                                  v-on="on"
                                >
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  @click="showDialogRecommendation(item.idRecommendation, item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      edit
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Editar
                                  </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                  @click="deleteItemRecommendation(item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      delete
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Excluir
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col
                        cols="8"
                      >
                        <v-data-table
                          :headers="headerChecklists"
                          :items="item.checklists"
                          :search="filters.search"
                          :loading="loadingChecklist"
                          :items-per-page="item.checklists.length"
                          hide-default-footer
                          class="elevation-1 ma-3"
                          style="white-space: nowrap"
                        >
                          <template #header.actions>
                              <span>
                                <v-btn
                                  x-small
                                  color="green"
                                  @click="showDialogChecklist('', item)">
                                  <v-icon x-small>
                                    add
                                  </v-icon>
                                </v-btn>
                              </span>
                          </template>

                          <template #[`item.actions`]="{ item }">
                            <v-menu>
                              <template #activator="{ on }">
                                <v-btn
                                  dark
                                  icon
                                  v-on="on"
                                >
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  @click="showDialogChecklist(item.idChecklistServico, item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      edit
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Editar
                                  </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                  @click="deleteItemChecklist(item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      delete
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Excluir
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>

          <template #[`item.viewPqfl`]="{ item }">
            <v-switch
              v-model="item.viewPqfl"
              label=""
              dense
              hide-details
              class="mt-0"
              color="green accent-3"
              disabled
            />
          </template>

          <template #[`item.viewPmls`]="{ item }">
            <v-switch
              v-model="item.viewPmls"
              label=""
              dense
              hide-details
              class="mt-0"
              color="green accent-3"
              disabled
            />
          </template>

          <template #[`item.viewCompany`]="{ item }">
            <v-switch
              v-model="item.viewCompany"
              label=""
              dense
              hide-details
              class="mt-0"
              color="green accent-3"
              disabled
            />
          </template>

          <template #[`item.allTechnicians`]="{ item }">
            <v-switch
              v-model="item.allTechnicians"
              label=""
              dense
              hide-details
              class="mt-0"
              color="red accent-3"
              disabled
            />
          </template>

          <template #[`item.technicals`]="{ item }">
            <v-chip
              v-for="technical of item.technicals"
              :key="technical.id"
              small
              color="gray"
            >
              {{ technical.name }}
            </v-chip>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="showDialog(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      edit
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Editar
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="deleteItem(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Excluir
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="showDialogGroup(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      edit
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Editar
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="deleteItemGroup(item)"
                >
                  <v-list-item-icon>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Excluir
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>

      <service-group-dialog
        v-model="editGroup"
        :edit-id.sync="editId"
        :type="type"
        @save="loadGroups"
      />

      <service-dialog
        v-model="editService"
        :edit-id.sync="editId"
        :type="type"
        :group-id="groupId"
        @save="loadGroups"
      />

      <cause-dialog
        v-model="editCause"
        :edit-id.sync="editId"
        :service-id="serviceId"
        :service-name="serviceName"
        :type="type"
        @save="loadGroups('CAUSES')"
      />

      <indicator-dialog
        v-model="editIndicator"
        :edit-id.sync="editId"
        :service-id="serviceId"
        :service-name="serviceName"
        :type="type"
        @save="loadGroups('INDICATORS')"
      />

      <recommendation-dialog
        v-model="editRecommendation"
        :edit-id.sync="editId"
        :service-id="serviceId"
        :service-name="serviceName"
        :type="type"
        @save="loadGroups('RECOMMENDATIONS')"
      />

      <checklist-service-dialog
        v-model="editChecklist"
        :edit-id.sync="editId"
        :type="type"
        :service-id="serviceId"
        :service-name="serviceName"
        :clone="clone"
        :checklistTypes="checklistType"
        @save="loadGroups('CHECKLISTS')"
      />
    </div>
  </v-container>
</template>

<script>
import ServiceGroupDialog from "@/Domains/Registrations/Cares/ServiceGroup/ServiceGroupDialog.vue";
import ServiceDialog from "@/Domains/Registrations/Cares/Service/Components/ServiceDialog.vue";
import CauseDialog from "@/Domains/Registrations/Cares/Cause/Components/CauseDialog.vue";
import IndicatorDialog from "@/Domains/Registrations/Cares/Indicator/Components/IndicatorDialog.vue";
import RecommendationDialog from "@/Domains/Registrations/Cares/Recommendation/Components/RecommendationDialog.vue";
import ChecklistServiceDialog from "@/Domains/Registrations/Cares/Checklist/ChecklistServiceDialog.vue";
export default {

  components: {
    ServiceGroupDialog,
    ServiceDialog,
    CauseDialog,
    IndicatorDialog,
    RecommendationDialog,
    ChecklistServiceDialog,
  },

  props: {
    type: String,
  },

  data() {

    return {

      loading: false,
      loadingCause: false,
      loadingIndicator: false,
      loadingRecommendation: false,
      loadingChecklist: false,

      editGroup: false,
      editService: false,
      editCause: false,
      editIndicator: false,
      editRecommendation: false,
      editChecklist: false,
      editId: null,
      groupId: null,
      serviceId: null,
      serviceName: null,
      checklistType: null,
      clone: null,

      filters: {
        search: null,
        group: null
      },

      groupsList: [],
      groupsListSelect: [],

      service: {
        id: null,
        name: null,
      },

    };
  },

  computed: {
      headers() {
        return [
          { text: 'Grupo', value: 'group', align: 'start' },
          { text: 'Módulo PQFL', value: 'viewPqfl', align: 'start' },
          { text: 'Módulo PMLS', value: 'viewPmls', align: 'start' },
          { text: 'Módulo Empresa', value: 'viewCompany', align: 'start' },
          { text: 'Aplica a todos os Técnicos', value: 'allTechnicians', align: 'start' },
          { text: 'Técnicos', value: 'technicals', align: 'start' },
          { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' , sortable: false},
        ];
      },

    headersService() {
      return [
        { text: 'Serviço', value: 'service', align: 'start' },
        { text: 'Subsídio Associado', value: 'associatedSubsidyValue', align: 'start' },
        { text: 'Tipo', value: 'associatedSubsidyTypeValue', align: 'start' },
        { text: 'Subsídio Não Associado', value: 'nonAssociatedSubsidyValue', align: 'start' },
        { text: 'Tipo', value: 'nonAssociatedSubsidyTypeValue', align: 'start' },
        { text: 'Mostrar no App', value: 'viewApp', align: 'start' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end', sortable: false},
      ];
    },

    headerCauses() {
      return [
          { text: 'Causa', value: 'cause', align: 'start' },
          { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end', sortable: false},
        ];
    },
    headerRecommendations() {
      return [
        { text: 'Recomendação', value: 'recommendation', align: 'start' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end', sortable: false },
      ];
    },
    headerIndicators() {
      return [
        { text: 'Indicador', value: 'indicator', align: 'start' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end', sortable: false },
      ];
    },
    headerChecklists() {
      return [
        { text: 'Checklist', value: 'checklist', align: 'start' },
        { text: 'Descrição', value: 'description', align: 'start' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end', sortable: false },
      ];
    },
  },

  async mounted() {
    this.loadGroups();
    this.loadGroupsList();
  },

  methods: {
    async loadGroups(actionType) {
      try {

        switch (actionType) {
          case 'CAUSES':
            this.loadingCause = true;
            break;
          case 'INDICATORS':
            this.loadingIndicator = true;
            break;
          case 'RECOMMENDATIONS':
            this.loadingRecommendation = true;
            break;
          case 'CHECKLISTS':
            this.loadingChecklist = true;
            break;
          default:
            this.$root.$progressBar.loading();
            break;
        }

        const { data } = await this.$axios.get('registrations/service-group', { params: {
            tipo: this.type,
            grupo: this.filters.group,
          }
        });

        this.groupsList = data.map(group => {

          let checklistTypes = [];

          if (group.exibe_pqfl) {
            checklistTypes.push("VISITA_PQFL");
          } else {
            checklistTypes = checklistTypes.filter((item) => item !== "VISITA_PQFL");
          }

          if (group.exibe_pmls) {
            checklistTypes.push("VISITA_PMLS");
          } else {
            checklistTypes = checklistTypes.filter((item) => item !== "VISITA_PMLS");
          }

          if (group.exibe_empresa) {
            checklistTypes.push("VISITA_PADRAO");
          } else {
            checklistTypes = checklistTypes.filter((item) => item !== "VISITA_PADRAO");
          }

          return {
            id: group.id_grupo_servico,
            group: group.nome,
            viewPqfl: group.exibe_pqfl,
            viewPmls: group.exibe_pmls,
            viewCompany: group.exibe_empresa,
            allTechnicians:group.aplica_todos_tecnicos,
            technicals: group.tecnicos,
            services: group.services.map(service => {
              return {
                idService: service.id_servico,
                checklistTypes: checklistTypes,
                service: service.descricao,
                associatedSubsidyValue: service.associado_valor,
                associatedSubsidyTypeValue: service.associado_tipo_valor,
                nonAssociatedSubsidyValue: service.nao_associado_valor,
                nonAssociatedSubsidyTypeValue: service.nao_associado_tipo_valor,
                viewApp: service.mostrar_no_app,
                causes: service.causas.map( cause => {
                  return {
                    idCause: cause.id_causa,
                    cause: cause.descricao,
                    idService: cause.id_servico,
                    service: service.descricao,
                  }
                }),
                recommendations: service.recomendacoes.map( recommendation => {
                  return {
                    idRecommendation: recommendation.id_recomendacao,
                    recommendation: recommendation.descricao,
                    idService: recommendation.id_servico,
                    service: service.descricao,
                  }
                }),
                indicators: service.indicadores.map( indicator => {
                  return {
                    idIndicator: indicator.id_indicador,
                    indicator: indicator.descricao,
                    idService: indicator.id_servico,
                    service: service.descricao,
                  }
                }),
                checklists: service.checklists.map( checklist => {
                  return {
                    idChecklistServico: checklist.id_checklist_servico,
                    description: checklist.descricao,
                    idService: checklist.id_servico,
                    checklist: checklist.checklist ? checklist.checklist.titulo : ' - ',
                    service: service.descricao,
                  }
                }),
              }
            }),
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar Grupo de Serviço!", "Atenção");

        console.warn(err);
      } finally {
        this.loadingCause = false;
        this.loadingIndicator = false;
        this.loadingRecommendation = false;
        this.loadingChecklist = false;
        this.$root.$progressBar.hide();
      }
    },

    showDialogGroup({ id }) {
      this.editGroup = true;
      this.editId = id;
    },

    showDialogService(id, groupId) {
      this.editService = true;
      this.editId = id;
      this.groupId = groupId;
    },

    showDialogCause(id, service) {
      this.editCause = true;
      this.editId = id;
      this.serviceId = service.idService;
      this.serviceName = service.service;
    },

    showDialogIndicator(id, service) {
      this.editIndicator = true;
      this.editId = id;
      this.serviceId = service.idService;
      this.serviceName = service.service;
    },

    showDialogRecommendation(id , service) {
      this.editRecommendation = true;
      this.editId = id;
      this.serviceId = service.idService;
      this.serviceName = service.service;
    },

    showDialogChecklist(id, service) {
      this.editChecklist = true;
      this.editId = id;
      this.serviceId = service.idService;
      this.serviceName = service.service;
      this.checklistType = service.checklistTypes;
    },

    async deleteItemGroup(item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir ${item.group} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/service-group/${item.id}`);

        this.$snotify.success("Grupo de Serviço excluída com sucesso", "Sucesso");
        return await this.loadGroups();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Grupo de Serviço", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async deleteItemService(item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir ${item.service} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/service/${item.idService}`);

        this.$snotify.success("Serviço excluída com sucesso", "Sucesso");
        return await this.loadGroups();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Serviço", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async deleteItemCause(item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir ${item.cause} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/cause/${item.idCause}`);

        this.$snotify.success("Causa excluída com sucesso", "Sucesso");
        return await this.loadGroups();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Causa", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async deleteItemIndicator(item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir ${item.indicator} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/indicator/${item.idIndicator}`);

        this.$snotify.success("Indicador excluído com sucesso", "Sucesso");
        return await this.loadTinas();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Indicador", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async deleteItemRecommendation(item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir ${item.recommendation} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/Recommendation/${item.idRecommendation}`);

        this.$snotify.success("Recommendation excluída com sucesso", "Sucesso");
        return await this.loadRecommendations();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Recommendation", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async deleteItemChecklist(item) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir ${item.description} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/checklist/${item.idChecklistServico}`);

        this.$snotify.success("Checklist excluído com sucesso", "Sucesso");
        return await this.loadGroups();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Checklist", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadGroupsList() {
      const { data } = await this.$axios.get('registrations/service-group', { params: {
          tipo: this.type,
          grupo: '',
        }
      });

      this.groupsListSelect = data.map(group => {
        return {
          id: group.id_grupo_servico,
          group: group.nome,
        }
      });
    },

    async changeModules(viewPqfl, viewPmls, viewCompany) {

      let checklistTypes = [];

      if (viewPqfl) {
        checklistTypes.push("VISITA_PQFL");
      } else {
        checklistTypes = checklistTypes.filter((item) => item !== "VISITA_PQFL");
      }

      if (viewPmls) {
        checklistTypes.push("VISITA_PMLS");
      } else {
        checklistTypes = checklistTypes.filter((item) => item !== "VISITA_PMLS");
      }

      if (viewCompany) {
        checklistTypes.push("VISITA_PADRAO");
      } else {
        checklistTypes = checklistTypes.filter((item) => item !== "VISITA_PADRAO");
      }
      return checklistTypes;
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
  }
}
</script>
<style >
.service-index .v-data-table__wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
