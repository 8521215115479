<template>
  <div>
    <v-flex>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.project"
            :items="goalList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :loading="loading"
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRange"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <month-range-picker
            v-model="filter.range"
            :ranges.sync="filter.ranges"
            :min="filter.min"
            :max="filter.max"
            dark
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.id_project_group"
            :items="listGroup"
            :loading="loading"
            label="Grupo"
            item-value="id"
            item-text="description"
            dark
            filled
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <routes-autocomplete-filter
            v-model="filter.route"
            label="Rota"
            dark
          />
        </v-col>
      </v-row>
      <v-row>
        <v-flex>
          <v-tabs
            v-model="tab"
            dark
            vertical
            background-color="transparent"
            class="transparent"
            @change="onTabChange"
          >
            <v-tab href="#grafico">
              Gráfico
            </v-tab>
            <!-- <v-tab href="#visitados">
              Visitados
            </v-tab> -->
            <v-tab href="#nao_visitados">
              Não Visitados
            </v-tab>

            <v-tab-item value="grafico">
              <visit-grafico
                ref="grafico"
                aba="grafico"
                :filter="filter"
              />
            </v-tab-item>

            <!-- <v-tab-item value="visitados">
              <reports-visitados
                ref="visitados"
                aba="visitados"
                :filter="filter"
              />
            </v-tab-item> -->

            <v-tab-item value="nao_visitados">
              <reports-nao-visitados
                ref="nao_visitados"
                aba="nao_visitados"
                :filter="filter"
              />
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-row>
    </v-flex>
  </div>
</template>

<style lang="scss" scoped>
.project-pmls {

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import VisitGrafico from '@/Domains/Projects/PMLS/Components/VisitGrafico.vue';
// import ReportsVisitados from '@/Domains/Projects/PMLS/Components/ReportsVisitados.vue';
import ReportsNaoVisitados from '@/Domains/Projects/PMLS/Components/ReportsNaoVisitados.vue';
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    MonthRangePicker,
    // ReportsVisitados,
    ReportsNaoVisitados,
    VisitGrafico,
    RoutesAutocompleteFilter
  },

  data() {
    return {
      loading: false,

      filter: {
        project: {
          dateStart: null,
          dateEnd: null,
        },
        route: '',
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        id_project_group: null,
      },

      goalList: [],

      listGroup: [],

      tab: null,
    };
  },

  mounted() {
    this.loadGoals();
  },

  methods: {
    async loadRange() {
      let dateEnd = (moment() < moment(this.filter.project.dateEnd)) ? moment().format('YYYY-MM') : moment(this.filter.project.dateEnd).format('YYYY-MM');

      this.filter.max = dateEnd;
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), dateEnd];
    },

    async loadGoals() {
      try {
        this.$root.$progressBar.loading();
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PMLS",
          }
        );

        this.goalList = data.map((x) => {
          return {
            dateStart: x.data,
            dateEnd: x.data_fim,
            text: "De " + moment(x.data).format('MM/YYYY') + " até " + moment(x.data_fim).format('MM/YYYY'),
            quality: x.qualidade,
            visit: x.visita,
            id: x.id_assistencia,
          }
        });

        if (!_.isEmpty(this.goalList)) {
          this.filter.project = this.goalList[0];
          await this.loadRange();
          await this.loadGrupos();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loading = false;
      }
    },

    async loadGrupos() {
      try {
        this.$root.$progressBar.loading();
        this.loading = true;

        const { data } = await this.$axios.get('/projects/project-group', {
          params: {
            id_assistencia: this.filter.project.id,
            tipo: 'PMLS',
          }
        });

        this.listGroup = data.map(item => {
          return {
            id: item.id_projeto_grupo,
            description: item.descricao,
          };
        });

        this.listGroup.push({
          id: null,
          description: 'Todos',
        })

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
        this.loading = false;
      }
    },

    onTabChange() {
      if (this.tab == 'grafico' && this.$refs.grafico) {
        setTimeout(() => {
          this.$refs.grafico.loadVisits();
        }, 1000);
      }

      if (this.tab == 'visitados' && this.$refs.visitados) {
        this.$refs.visitados.loadVisits();
      }

      if (this.tab == "nao_visitados" && this.$refs.nao_visitados) {
        this.$refs.nao_visitados.loadVisits();
      }
    },

    navigateBack() {
      this.$store.commit("setCurrentMenu", this.parent.name);
      this.$store.commit("setCurrentMenuBackground", this.parent.background);
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
