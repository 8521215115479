import Vue from "vue";
import { io } from 'socket.io-client';

import store from "../Resources/vuex.js";

class Websocket {

  connect() {
    this.socket = io(
      process.env.VUE_APP_WS_URL,
      {
        withCredentials: true,
      }
    );

    this.socket.on('connect', this.onWSConnect);
    this.socket.on('disconnect', this.onWSDisconnect);
  }

  onWSConnect() {
    store.commit('socket/setStatus', true);

    console.log('WS connected');
  }

  onWSDisconnect(reason) {
    store.commit('socket/setStatus', false);

    console.log('WS disconnected', { reason });

    if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, we need to reconnect manually
      this.socket.connect();
    }
    // else the socket will automatically try to reconnect
  }

  on(event, listener) {
    return this.socket.on(event, listener);
  }

  off(event, listener) {
    return this.socket.off(event, listener);
  }

  emit(event, params) {
    return this.socket.emit(event, params);
  }

  async proxy(request) {
    return new Promise((resolve, reject) => {
      this.socket.emit('proxy', request, ({ status, response }) => {
        if (status === 'ERROR') {
          return reject(response);
        }

        return resolve(response);
      });
    });
  }
}

const instance = new Websocket();

Vue.prototype.$socket = instance;

export default instance;
