<template>
  <div
    v-show="favorites.length > 0 || $route.meta?.isMenu"
    class="favorites-top-bar"
  >
    <draggable
      :list="favorites"
      animation="200"
      delay="100"
      delay-on-touch-only
      :class="['favorites-area', { 'empty': favorites.length === 0 && $route.meta?.isMenu }]"
      handle=".fav-btn"
      :group="{ name: 'favorites' }"
      @change="onChange"
      @start="dragging = true"
      @end="dragging = false"
    >
      <v-btn
        v-for="(favorite, idx) of favorites"
        :key="favorite.fullPath + idx"
        draggable
        outlined
        rounded
        dark
        class="fav-btn"
        @click="navigateTo(favorite)"
      >
        <v-icon left>
          {{ `icon-${favorite.icon}` }}
        </v-icon>
        <span class="btn-title">
          {{ favorite.title }}
        </span>
      </v-btn>
    </draggable>
    <draggable
      v-if="dragging && favorites.length > 0"
      animation="200"
      delay="100"
      delay-on-touch-only
      class="trash-area"
      :group="{ name: 'favorites' }"
    />
  </div>
</template>

<style lang="scss">
.favorites-top-bar {
  display: flex;
  padding: 5px 10px 0;
  width: 100%;

  .favorites-area {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    flex: 1 1 auto;
    position: relative;
    min-height: 33px;

    &.empty:before {
      position: absolute;
      width: 100%;
      display: inline-block;
      padding: 7px;
      color: rgba(255, 255, 255, 0.5);
      border: 1px dotted rgba(255, 255, 255, 0.3);
      border-radius: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      content: 'Arraste um menu aqui para favoritá-lo';
    }

    .fav-btn {
      margin: 1px 6px 6px 0;
    }

    .sortable-ghost {
      margin-right: 6px;

      .menu-title {
        display: none;
      }

      .giant {
        max-width: 28px;
        max-height: 28px;
      }
    }
  }

  .trash-area {
    flex-shrink: 0;
    border: 1px dotted rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 10px;
    position: relative;
    font-family: 'Material Icons';

    .sortable-ghost {
      background-color: #F44336 !important;
      border-color: #F44336 !important;
      float: right;

      .grid-item, .giant, .menu-title, .btn-title {
        display: none;
      }
    }

    &:before {
      position: absolute;
      font-size: 20px;
      padding: 3px;
      color: rgba(255, 255, 255, 0.3);
      content: 'delete';
    }
  }
}
</style>

<script setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue'
import Draggable from 'vuedraggable'
import { debounce } from 'lodash'

import axios from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'
import { useUtils } from '@/Support/Composables/utils.js'

const { notify } = useUtils()

const { proxy } = getCurrentInstance()

const FAVORITES = 'favoritos'

const dragging = ref(false)

const favorites = computed({
  get() {
    return store.state.settings.favorites
  },
  set(value) {
    store.commit('setFavorites', value)
  },
})

const navigateTo = (favorite) => {
  if (favorite.fullPath === proxy.$route.path) {
    return
  }
  if (favorite.isDairyRequired && !store.state.settings.laticinio) {
    return notify.info('Você precisa selecionar um laticínio para acessar esse item!', 'Atenção')
  }
  proxy.$router.push(favorite.fullPath)
}

const onChange = ({ added }) => {
  if (added) {
    const favorite = added.element
    const newIndex = added.newIndex

    const alreadyAdded = favorites.value.find((fav, idx) => fav.fullPath === favorite.fullPath && idx !== newIndex)

    if (alreadyAdded) {
      favorites.value.splice(newIndex, 1)
      return
    }
  }

  saveFavorites()
}

const saveFavorites = debounce(async () => {
  try {
    const { data } = await axios.post(`/configuracao/salvaConfiguracaoRelatorio`, {
      relatorio: FAVORITES,
      configuracao: favorites.value.map((favorite) => favorite.fullPath),
    })

    if (!data.codigo) {
      throw data
    }
  } catch (error) {
    notify.error('Erro ao salvar as configurações', 'Atenção')
    console.error(error)
  }
}, 3000)

const getRoutes = () => {
  const flatten = (itens) => {
    return itens.reduce((acc, element) => {
      if (Array.isArray(element.children)) {
        return flatten(element.children);
      }

      if (element.meta?.fullPath)
        acc[element.meta.fullPath] = element.meta;

      return acc;
    }, {});
  };

  return flatten(proxy.$router.options.routes);
}

const loadFavorites = async () => {
  try {
    const { data } = await axios.get('/settings/reports/user', { params: { relatorio: FAVORITES } })

    if (data) {
      const routes = getRoutes()

      favorites.value = data.configuracao
        .map((fullPath) => routes[fullPath])
        .filter(favorite => !!favorite)
    }

  } catch (error) {
    notify.error('Erro ao buscar menu de favoritos', 'Atenção')
    console.error(error)
  }
}

onMounted(() => {
  loadFavorites()
})
</script>

