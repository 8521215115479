<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Depósitos de estoque
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>

        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
              filled
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :search="filters.search"
        dark
        @click:row="edit"
      >
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasInsertAccess"
                small
                @click="edit(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasDeleteAccess"
                small
                @click="remove(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-overlay :value="loading">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-card>

    <warehouse-dialog
      v-model="dialog.show"
      :edit-id="dialog.id"
      @save="load()"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          v-if="hasInsertAccess"
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="add()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Novo</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import WarehouseDialog from '@/Domains/Registrations/Warehouse/Components/WarehouseDialog.vue';

import { get } from 'lodash';

export default {
  name: 'Warehouse',

  components: {
    WarehouseDialog,
  },

  data() {
    return {
      loading: false,

      filters: {
        search: '',
      },

      headers: [
        { text: 'Descrição', value: 'description' },
        { text: 'Unidade', value: 'dairy' },
        { text: '', value: 'actions', width: 30 },
      ],

      items: [],

      dialog: {
        show: false,
        id: null,
      },
    };
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasInsertAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'warehouse-insert-edit' && o.tipo === 'COMPONENTE');
    },

    hasDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'warehouse-delete' && o.tipo === 'COMPONENTE');
    },
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/warehouse`);

        this.items = data.map(item => ({
          id: item.id_deposito,
          description: item.descricao,
          dairy: item.nome_laticinio,
        }));
      } catch (error) {
        const message = get(error, 'response.data.message', 'Erro ao carregar depósitos');
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    add() {
      this.dialog.id = null;
      this.dialog.show = true;
    },

    edit(item) {
      this.dialog.id = item.id;
      this.dialog.show = true;
    },

    async remove(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }

      try {
        this.loading = true;

        await this.$axios.delete(`/warehouse/${id}`);

        this.load();
        this.$snotify.success('Item excluído com sucesso', 'Sucesso');
      } catch (error) {
        const message = get(error, 'response.data.message', 'Erro ao remover depósito');
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

