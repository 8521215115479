<template>
  <kilometers-index
    :type="type"
    subtype="QUILOMETRAGEM"
  />
</template>

<script>
import KilometersIndex from "@/Domains/Financial/Prices/Kilometers/KilometersIndex.vue";

export default {
  components: {
    KilometersIndex
  },

  props: {
    type: String,
  },
}

</script>
