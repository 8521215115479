<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-col cols="12">
      <h2 class="menu-header white--text">
        Região/Localidade
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>

      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-col
            md-6
            offset-md="6"
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              single-line
              hide-details
              clearable
            />
          </v-col>
        </v-card-title>

        <v-data-table
          :headers="[
            { align: 'start', text: 'Descrição', value: 'description' },
            { text: 'Tipo', value: 'type' },
            { text: 'Volume', value: 'vol' },
            { text: 'Produtores', value: 'producers' },
            { text: 'Assistencia Tecnica', value: 'routeTechnicalAssist' },
            { text: 'Coleta de Leite', value: 'routeMilkCollect' },
            { align: 'end', text: '', value: 'actions', sortable: false },
          ]"
          :items="filteredRegions"
          class="elevation-1"
        >
          <template #[`item.type`]="{ value }">
            {{ types[value] }}
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="showEditingDialog(item)">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Editar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="excludeRegion(item)">
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Excluir
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            fixed
            fab
            large
            dark
            bottom
            right
            color="purple mr-5"
            v-on="on"
            @click="showCreatingDialog()"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </template>

        <span>Nova região</span>
      </v-tooltip>

      <region-dialog
        v-model="edit"
        :edit-id.sync="editId"
        @save="loadRegions"
      />

      <v-overlay :value="loading">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-col>
  </v-container>
</template>

<script>
import _ from "lodash";

import RegionDialog from "@/Domains/Registrations/Region/Components/RegionDialog.vue";

export default {
  components: {
    RegionDialog
  },

  data() {
    return {
      // Loaders
      loading: true,

      // Dados brutos do backend
      regions: [],

      types: {
        CAPTACAO: 'Captação',
        COMERCIAL: 'Comercial'
      },

      // Filtros da tabela
      filters: {
        search: "",
      },

      // Usado no dialog de criação / edição
      edit: false,
      editId: null,
    };
  },

  computed: {
    /**
     * Exibe as regiões filtradas
     */
    filteredRegions() {
      if (_.isEmpty(this.filters.search)) {
        return this.regions;
      }

      return this.regions.filter(region => {
        return (region.description || "").toUpperCase().includes((this.filters.search || "").toUpperCase());
      });
    },
  },

  async mounted() {
    await this.loadRegions();
  },

  methods: {
    /**
     * Método responsável por carregar todas as regiões disponíveis para o laticínio
     * @returns {Promise<void>}
     */
    async loadRegions() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/registrations/region`, { params: {
          exibir_totais_por_rota: true,
          exibir_totais_pessoas: true
        } });

        this.regions = data.map(item => {
          const vol = parseInt(item.total_producao);
          const producers = parseInt(item.total_pessoas) || null;
          const routeTechnicalAssist = parseInt(item.total_rota_assistencia) || null;
          const routeMilkCollect = parseInt(item.total_rota_coleta_leite) || null;

          return {
            id: item.id_regiao,
            description: item.descricao,
            vol: vol ? `${vol} L` : null,
            producers,
            routeTechnicalAssist,
            routeMilkCollect,
            type: item.tipo,
            polygon: {
              coordinates: item.coordenadas || [],
              editable: true
            }
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as regiões!", "Atenção");

        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Mostra o dialog para a criação de uma nova região
     */
    showCreatingDialog() {
      this.edit = true;
      this.editId = null;
    },

    /**
     * Mostra o dialog para a edição de uma região da lista
     */
    showEditingDialog({ id }) {
      this.edit = true;
      this.editId = id;
    },

    /**
     * Apaga uma região
     * @returns {Promise<void>}
     */
    async excludeRegion(region) {
      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir a região ${region.description} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.loading = true;

        await this.$axios.delete(`/registrations/region/${region.id}`);

        this.$snotify.success("Região excluída com sucesso", "Sucesso");
        return await this.loadRegions();
      } catch (e) {
        this.$snotify.error("Erro ao excluir região", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

  },
};
</script>
