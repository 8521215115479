<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col
          md="12"
        >
          <v-chart
            ref="chart"
            type="bar"
            :options="Options"
            :style="{width: '100%', height: `300px !important`}"
            autoresize
          />
        </v-col>
      </v-row>
    </v-card-text>
      
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando curva ABC...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import printJS from "print-js";
import RatingService from "@/Domains/Projects/PQFL/BPA/Services/RatingService.js";
import  _ from "lodash";

const ratingService = new RatingService();

export default {
  name: "bpa-graphic",

  components: {
  },
 
  props: {
    range: Object,

    filter: Object,
  },

  data() {
    return {
      loading: false,
      items: [],
      tituloGrafico: '',
      subtitle: '',
    };
  },

  computed: {
    Options() {
      return this.generateGraphOptions(this.items, this.tituloGrafico, this.subtitle);
    },
  },
 
  watch: {
    filter: {
      handler() {
        this.loadIndicators();
      },
      deep: true
    }
   
  },
  mounted() {
    this.loadIndicators();
  },
  
  methods: {
    async loadIndicators() {
      let intervalo = '';
      let tipo = '';
      this.tituloGrafico = ratingService.types[this.range.tipo] + ' Faixa: ' + this.range.nome_faixa + ' Meta: ' + this.range.visitas + ' visita(s) ' + this.range.periodo;

      if (_.isEmpty(this.range.indicador)) {
        const metas_agrupadas = JSON.parse(this.range.metas_agrupadas || '') || [];
        metas_agrupadas.forEach((meta) => {
          this.subtitle = this.subtitle + ' [' + meta.indicator.description + '] ' ;
        });
       
      } else {
        if (this.range.tipo == 'INCONFORME-PONTOS') {
          tipo = 'pontos';
        }
        intervalo = 'de ' + this.range.inicio_faixa + ' até ' + this.range.fim_faixa + ' ' + tipo;
        if (this.range.fim_faixa === null) {
          intervalo = ' acima de ' + this.range.inicio_faixa ;
        }
        this.subtitle = this.range.indicador + ' ' + intervalo ;
      }
      this.loadData();
      
    },

    async loadData() {
      this.loading = true;
      this.items = [];
      try {

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/QuantidadeProdutoresBPAFaixas`,
          {
            id_meta_bpa: this.range.id_meta_bpa,
          }
        );
        if (!_.isArray(data)) {
          throw new Error(data);
        }

        if (!_.isEmpty(data)) {
          this.items = data.map(o => {
            return {
              media: o.media,
              data: o.data_classificacao,
              goals: o.visitas,
              goalsPerc: 0,
              range: o.nome_faixa,
              producers: o.total_produtores,
              visits: o.total_visitado,
              meta: o.visitas + ' visita(s) ' + o.periodo,
            }
          })
          
        }
      }
      catch (err) {
        console.warn(err);
        this.items = [];
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    generateGraphOptions(data, title, subtitle) {
      return {
        color: ['rgba(229, 57, 53, 0.7)',  'rgba(38, 198, 218, 0.7)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtext: `${subtitle}`,
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 500,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });

                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.data)),
          axisLabel: {
            color: '#fff'
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          },
        },
        series: [
          {
            name: 'Produtores',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => o.producers),
          },
          {
            name: 'Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
            },
            data: data.map(o => o.visits),
          },
        ]
      };
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>