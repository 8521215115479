import moment from "moment";
import colors from 'vuetify/lib/util/colors'

export default {
  methods: {
    async getQualityCbtCriticalReport(month, technicianId = null, routes = null, status = null) {

      let { data: medias } = await this.$axios.post(
        `/relatorioIn/listaCriticos`,
        {
          id_pessoa: technicianId,
          rotas: routes,
          data_inicio: moment(month).startOf('month').format('YYYY-MM-DD'),
          status: status,
        }
      );

      let items = [];
      let itemsGrafico = [];
      medias.forEach(produtor => {
        const historico = JSON.parse(produtor.historico_medias_json);

        let mensal = { codigo_laticinio: produtor.codigo_laticinio, nome_produtor: produtor.nome, rota_principal: produtor.rota_principal, tipo: "Média Geométrica Mensal" };
        let trimestral = { codigo_laticinio: produtor.codigo_laticinio, nome_produtor: produtor.nome, rota_principal: produtor.rota_principal, tipo: "Média Geométrica Trimestral" };

        historico.forEach(item => {
          mensal[item.data] = parseInt(item.cbt_geometrica_mes) || 0;
          trimestral[item.data] = parseInt(item.cbt_geometrica) || 0;
        })

        const cbt_limite = parseInt(produtor.cbt_limite) || 0;

        const lista_cbt_mes = JSON.parse(produtor.lista_cbt_mes);

        const menor_cbt_simples = Math.min(...lista_cbt_mes.map(({ cbt }) => cbt));

        mensal['lista_cbt_mes'] = lista_cbt_mes;

        mensal['cbt_limite'] = cbt_limite;

        mensal['menor_cbt_simples'] = menor_cbt_simples;

        mensal['ultima_visita'] = produtor.data_ultima_visita;

        mensal['geometrica_trimestral_mes'] = historico[0]['cbt_geometrica'] ? `${parseInt(historico[0]['cbt_geometrica'])} (${moment(historico[0]['data']).format('MM/YYYY')})` : '-';

        mensal['salvo_pela_in'] = this.savedByIN(menor_cbt_simples, trimestral[month], month, produtor.data_ultima_visita);

        items.push(mensal, trimestral);

        itemsGrafico.push(mensal);
      });

      return { items, itemsGrafico }
    },

    generateCbtCriticalGraph(items) {
      return {
        height: 70 + (items.length * 25),
        options: {
          title: {
            text: 'CPP CRÍTICOS',
            subtext: `Produtores: ${items.length}`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'item',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '1%',
            right: '5%',
            top: '50px',
            bottom: '0%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            scale: true,
            min: 0,
            boundaryGap: false,
            splitLine: { show: false },
            axisLine: { show: true },
            axisTick: { show: false },
            axisLabel: {
              textStyle: { color: 'transparent' }
            },
          },
          yAxis: {
            axisPointer: {
              show: true,
              type: 'shadow',
              triggerTooltip: false,
            },
            type: 'category',
            inverse: true,
            data: items.map(o => [o.codigo_laticinio, o.nome_produtor, o.rota_principal].filter(o => o).join(' - ')),
            axisLabel: {
              fontSize: 10,
              textStyle: { color: '#ddd' }
            },
          },
          series: [{
            name: 'CPP CRÍTICOS',
            type: 'bar',
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              distance: -1,
              padding: [2.5, 10],
              borderRadius: [0, 30, 30, 0],
              textShadowColor: '#000',
              textShadowBlur: 3,
              backgroundColor: colors.red.darken3
            },
            itemStyle: {
              normal: { color: colors.red.darken3 }
            },
            data: items.map(item => ({
              value: item.cbt_limite,
              itemStyle: {
                normal: { color: item.salvo_pela_in !== 'NÃO SALVO PELA IN'  ? colors.yellow.darken3 : colors.red.darken3 }
              },
              label: { backgroundColor: item.salvo_pela_in !== 'NÃO SALVO PELA IN' ? colors.yellow.darken3 : colors.red.darken3 },
              tooltip: {
                trigger: 'item',
                axisPointer: {
                  type: 'shadow'
                },
                formatter: [
                  '<b>{b}</b>',
                  'CPP LIMITE: <b>{c}</b>',
                  `MENOR CPP MÊS: <b>${ item.menor_cbt_simples }</b>`,
                  `CPP GEOMÉTRICA TRIMESTRAL: <b>${ item.geometrica_trimestral_mes }</b>`,
                  `ÚLTIMA VISITA: <b>${ this.dateFormat(item.ultima_visita, 'DD/MM/YYYY') }</b>`,
                  `${ item.salvo_pela_in }`
                ].join('<br>'),
                borderColor: 'transparent',
                textStyle: {
                  fontSize: 12
                }
              },
            })),
          }],
        }
      };
    },

    savedByIN(minCBTMes, geoCBTtrimestal, month, lastVisit) {

      if (geoCBTtrimestal <= 300 && lastVisit && moment(lastVisit).isSame(month, 'month')) {
        return 'SALVO PELA IN 76/77';
      }

      if (minCBTMes <= 300 && lastVisit && moment(lastVisit).isSame(month, 'month')) {
        return 'SALVO PELA IN 59';
      }

      return 'NÃO SALVO PELA IN';
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
