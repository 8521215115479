<template>
  <div>
    <v-autocomplete
      v-model="input"
      :loading="loading"
      :items="routes"
      prepend-inner-icon="icon-rota"
      dark
      label="Rotas"
      item-text="description"
      item-value="id"
      return-object
      hide-details
      multiple
      filled
    >
      <template v-slot:selection />

      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.description }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="item.types.length">
            <v-chip
              v-for="type in item.types"
              :key="type"
              x-small
            >
              {{ type }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-list
      v-if="input.length > 0"
      flat
      dark
      dense
      style="background-color: rgba(255, 255, 255, 0.08); max-height: 130px;"
      class="text-left overflow-y-auto"
    >
      <v-list-item-group>
        <v-list-item
          v-for="item in input"
          :key="item.id"
          class="pl-3"
        >
          <v-list-item-icon class="ml-0 mr-3">
            <v-icon>
              map
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.description | uppercase }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn
              icon
              small
              @click="onRemoveItem(item)"
            >
              <v-icon small>
                close
              </v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  filters: {
    uppercase(value) {
      return (value || "").toUpperCase();
    },
  },
  props: {
    value: Array,
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // Items do Formulário (Autocomplete)
      routes: [],
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
    input() {
      this.$emit('input', this.input);

      this.$emit("change");
    },
  },

  async mounted() {
    await this.loadRoutes();
  },

  methods: {
    async loadRoutes() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/rota/listaRotaResumidaJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.routes = data.map(route => {

          const types = !_.isEmpty(route.tipos) ? _.uniq(route.tipos.map(type => _.startCase(type.descricao))) : [];

          return {
            id: route.id_rota,
            description: route.descricao,
            types,
          }
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    /**
     * @void
     * Evento de remoção de item
     */
    onRemoveItem(item) {
      // Irá remover o item seleciona
      this.input = this.input.filter(f => f.id !== item.id);
    }
  },
};
</script>
