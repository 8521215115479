<template>
  <CardChart
    title="Média CCS (Mês)"
    :color="color"
    :graph-color="graphColor"
    :data="graph"
    :loading="loading"
    :height="height"
    show-symbol
  >
    <div class="d-flex flex-column text-right white--text">
      <span class="caption font-weight-light">{{ report.month }}</span>
      <span class="headline">{{ report.mean | formatNumber }}</span>
    </div>
  </CardChart>
</template>

<script>
import CardChart from "@/Domains/BI/Components/CardChart.vue";

import _ from 'lodash';
import moment from 'moment';

export default {

  components: {
    CardChart
  },

  filters: {
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    graphColor: {
      type: String,
      default: 'rgba(29, 233, 182, 0.7)'
    },
    height: {
      type: String,
      default: '100px',
    },
    date: {
      type: String,
      default: () => moment().endOf('month').format('YYYY-MM-DD')
    },
  },

  data() {
    return {
      loading: false,
      graph: {},
      report: { month: '', mean: 0 },
    }
  },

  watch: {
    date() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/relatorioIn/mediaGeometricaCCS`,
          { date_ref: this.date }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.graph = data.reduce((acc, cur) => {
          acc[moment(cur.mes, 'YYYY-MM').format('MM/YYYY')] = +parseFloat(cur.media_ccs).toFixed(2) || 0;
          return acc;
        }, {});

        if (data.length > 0) {
          const last = data[data.length - 1];
          this.report = {
            month: moment(last.mes, 'YYYY-MM').format('MM/YYYY'),
            mean: +parseFloat(last.media_ccs).toFixed(2) || 0
          }
        }
        else {
          this.report = { month: '', mean: 0 }
        }

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de CCS!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
