<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Ficha de Diagnóstico
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>

          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
      <!-- Lista usuarios -->
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="4"
            md-4
            sm-4
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
            />
          </v-col>
        </v-card-title>
        <v-data-table
          dark
          :headers="headersDiagnostic"
          :items="list"
          maxlength="19"
          class="diff-list-table"
          :search="filters.search"
          @click:row="handleClick"
        >
          <template v-slot:item.actions="{ item }">
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="openEdit(item);">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="resourceDelete"
                  small
                  @click="openAlert(item), showDeleteDialog = true;"
                >
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-overlay :value="loading">
          <v-card-text>
            Carregando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-overlay>
      </v-card>
    </v-container>

    <!-- Alert excluir -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false"
      @keydown.esc="showDeleteDialog = false"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-center text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-center black--text">
            Deseja realmente Executar a Ação ?
          </h3>
          <v-flex
            xs12
            class="font-weight-black"
          >
            <br>
          </v-flex>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="openDelete(); showDeleteDialog = false;"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-diagnostic-record
      v-if="showCadastro"
      :visible="showCadastro"
      :id-diagnostic="idDiagnosticEdit"
      @close="closeEdit()"
    />
  </div>
</template>
<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import IMask from "imask";
import _ from "lodash";
import moment from "moment";
import DialogDiagnosticRecord from "@/Domains/Quality/PlanQualification/Components/DialogDiagnosticRecord.vue";

export default {
  name: "DiagnosticRecord",

  components: {
    DialogDiagnosticRecord
  },

  filters: {
    //Filtro usado em todas as mascaras da view
    Mask(value, mask) {
      if (_.isEmpty(value)) {
        return value;
      }
      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  },

  data() {
    return {
      filters: {
        search: "",
      },
      loading: false,

      showCadastro: false,

      showDeleteDialog: false,

      cadastroSelecionado: {},

      rules: {
        isValid: false,
        nome_required: [v => !!v || "Campo obrigatorio"],
        codigo_required: [v => !!v || "Campo obrigatorio"],
      },

      objetcToDelete: "",

      headersDiagnostic: [
        {
          text: "Data Emissão",
          value: "data_emissao",
        },
        {
          text: "Produtor",
          value: "produtor",
        },
        {
          text: "Técnico",
          value: "tecnico",
        },
        {
          text: "Latícinio",
          value: "laticinio",
        },
        {   value: "actions",
          sortable: false,
          align: "end",
          width: 20,
        },
      ],

      list: [],

      idDiagnosticEdit: '',

      CNPJMask: {
        mask: "00.000.0000/0000-00",
        maxlength: 19,
      },

      resourceInsertEdit: false,

      resourceDelete: false,

    };
  },

  mounted() {
    this.loadDiagnostic();
    this.loadResourcesUser();
  },

  methods: {
    /**
          * carrega os recursos que usuario tem permissao para acessar
          *
          */
    loadResourcesUser() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      const resourceDelete = recursosUsuario.find(recurso => recurso.recurso === "user-delete" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(resourceDelete)) {
        this.resourceDelete = false;
      } else {
        this.resourceDelete = true;
      }

      const resourceInsertEdit = recursosUsuario.find(recurso => recurso.recurso === "user-insert-edit" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(resourceInsertEdit)) {
        this.resourceInsertEdit = false;
      } else {
        this.resourceInsertEdit = true;
      }

    },

    openEdit(diagnostic) {
      this.showCadastro = true;
      this.idDiagnosticEdit = diagnostic.id_ficha_tecnica_produtor;
    },
    closeEdit() {
      this.showCadastro = false;
    },

    openAlert(pessoa) {
      this.objetcToDelete = pessoa.id_pessoa;
    },

    openDelete() {
      this.onDiagnosticDelete(this.objetcToDelete);
    },

    async onDiagnosticDelete(toDelete) {
      try {

        const { data } = await this.$axios.post(
          `/fichaTecnicaProdutor/inativa`,
          this.$qs.stringify({
            id_ficha_tecnica_produtor: toDelete,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw "PHP Error";
        }

        this.loadDiagnostic();

      } catch (error) {
        console.log(error);
      }
    },

    async loadDiagnostic() {

      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/fichaTecnicaProdutor/listaFichaTecnica`);

        this.list = data.map(row => {
          return {
            data_emissao: !_.isEmpty(row.data_emissao) ? moment(row.data_emissao).format('DD/MM/YYYY' ) : '',
            produtor: row.produtor,
            tecnico: !_.isEmpty(row.tecnico) ? row.tecnico : 'sem técnico',
            laticinio: row.laticinio,
            id_ficha_tecnica_produtor: row.id_ficha_tecnica_produtor,
          }
        });

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
         * Trata evento de clique na linha..
         */
    handleClick(item) {
      this.openEdit(item);
    },

  },
}
</script>

