<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Investimento
          </v-card-title>
          <v-data-table
            :headers="investment.headers"
            :items="investment.items"
            :loading="investment.loading"
            dark
            dense
            disable-sort
            hide-default-footer
            class="elevation-1 report-table"
          >
            <template #[`item.predicted`]="{ value }">
              R$ {{ value | formatCurrency }}
            </template>
            <template #[`item.accomplished`]="{ value }">
              R$ {{ value | formatCurrency }}
            </template>
            <template #[`item.balance`]="{ value }">
              R$ {{ value | formatCurrency }}
            </template>
            <template
              v-if="investment.items.length > 0"
              #[`body.append`]="{}"
            >
              <tr>
                <td class="text-start">
                  <b>Total</b>
                </td>
                <td class="text-start">
                  <b>R$ {{ totalInvestment.predicted | formatCurrency }}</b>
                </td>
                <td class="text-start">
                  <b>R$ {{ totalInvestment.accomplished | formatCurrency }}</b>
                </td>
                <td class="text-end">
                  <b>R$ {{ totalInvestment.balance | formatCurrency }}</b>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-card-title />
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Visitas
          </v-card-title>
          <v-data-table
            :headers="visits.headers"
            :items="visits.items"
            :loading="visits.loading"
            dark
            dense
            disable-sort
            hide-default-footer
            class="elevation-1 report-table" 
          >
            <template
              v-if="visits.items.length > 0"
              #[`body.append`]="{}"
            >
              <tr>
                <td class="text-start">
                  <b>Total</b>
                </td>
                <td class="text-start">
                  <b>{{ totalVisits.predicted }}</b>
                </td>
                <td class="text-start">
                  <b>{{ totalVisits.accomplished }}</b>
                </td>
                <td class="text-end">
                  <b>{{ totalVisits.balance }}</b>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-card-title />
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";

moment.locale('pt'); 

export default {
  name: "pmls-panorama",

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  mixins: [ReportMixin],

  props: ["parent"],

  data() {
    return {
      investment: {
        loading: false,

        headers: [
          { text: 'Ano', value: 'year' },
          { text: 'Previsto', value: 'predicted' },
          { text: 'Realizado', value: 'accomplished' },
          { text: 'Saldo', value: 'balance', align: 'end' },
        ],

        items: [],
      },
      visits: {
        loading: false,

        headers: [
          { text: 'Ano', value: 'year' },
          { text: 'Previsto', value: 'predicted' },
          { text: 'Realizado', value: 'accomplished' },
          { text: 'Saldo', value: 'balance', align: 'end' },
        ],

        items: [],
      }
    };
  },

  computed: {
    totalInvestment() {
      return this.investment.items.reduce((acc, cur) => ({
        predicted: acc.predicted + cur.predicted,
        accomplished: acc.accomplished + cur.accomplished,
        balance: acc.balance + cur.balance,
      }), { predicted: 0, accomplished: 0, balance: 0 })
    },

    totalVisits() {
      return this.visits.items.reduce((acc, cur) => ({
        predicted: acc.predicted + cur.predicted,
        accomplished: acc.accomplished + cur.accomplished,
        balance: acc.balance + cur.balance,
      }), { predicted: 0, accomplished: 0, balance: 0 })
    },
  },

  created() {
    this.getPanoramaInvestment();
    this.getPanoramaVisits();
  },

  methods: {
    async getPanoramaInvestment() {
      this.investment.loading = true;
      const {data} = await this.$axios.get(`/projetos/panoramaInvestimento`);

      this.investment.items = data.map(p => ({
        year: p.ano,
        predicted: p.previsto,
        accomplished: p.realizado,
        balance: p.saldo,
      }))
      this.investment.loading = false;
    },
    async getPanoramaVisits() {
      this.visits.loading = true;
      const {data} = await this.$axios.get(`/projetos/panoramaVisitas`);

      this.visits.items = data.map(p => ({
        year: p.ano,
        predicted: p.previsto,
        accomplished: p.realizado,
        balance: p.saldo,
      }))
      this.visits.loading = false;
    },

    getInvestmentReportJson() {
      return [
          ...this.investment.items.map(item => ({
            Ano: item.year,
            Previsto: `R$ ${this.$options.filters.formatCurrency(item.predicted)}`,
            Realizado: `R$ ${this.$options.filters.formatCurrency(item.accomplished)}`,
            Saldo: `R$ ${this.$options.filters.formatCurrency(item.balance)}`,
        })),
        {
            Ano: 'Total',
            Previsto: `R$ ${this.$options.filters.formatCurrency(this.totalInvestment.predicted)}`,
            Realizado: `R$ ${this.$options.filters.formatCurrency(this.totalInvestment.accomplished)}`,
            Saldo: `R$ ${this.$options.filters.formatCurrency(this.totalInvestment.balance)}`,
        }
      ];
    },

    getVisitsReportJson() {
      return [
          ...this.visits.items.map(item => ({
            Ano: item.year,
            Previsto: item.predicted,
            Realizado: item.accomplished,
            Saldo: item.balance,
        })),
        {
            Ano: 'Total',
            Previsto: this.totalVisits.predicted,
            Realizado: this.totalVisits.accomplished,
            Saldo: this.totalVisits.balance,
        }
      ];
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const investmentReport = this.getInvestmentReportJson();
      const visitsReport = this.getVisitsReportJson();

      let data = this.XLSX.utils.json_to_sheet(investmentReport, {origin: 2});

      const visitsStartRow = investmentReport.length + 4;
      this.XLSX.utils.sheet_add_json(data, visitsReport, {skipHeader: true, origin: visitsStartRow + 2});

      data["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
        { s: { r: visitsStartRow, c: 0 }, e: { r: visitsStartRow, c: 3 } },
      ];
      data['A1'] = { v: 'Investimento' };
      data[`A${visitsStartRow + 1}`] = { v: 'Visitas' };

      data['!cols'] = [
        { wch: 10 },
        { wch: 14 },
        { wch: 14 },
        { wch: 14 }
      ];

      const workbook = this.XLSX.utils.book_new();

      const filename = "pmls_panorama";
      
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /**
     * Imprime o relatório
     */
    print() {
      const investmentReport = this.htmlTableFromJson(this.getInvestmentReportJson(), 'Investimento');
      const visitsReport = this.htmlTableFromJson(this.getVisitsReportJson(), 'Visitas');
      
      const html = `
      <div>
        ${ investmentReport }
        ${ visitsReport }
      </div>`;

      return this.printJS({
        documentTitle: 'Panorama',
        printable: html,
        type: 'raw-html',
        css: ['https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css'],
        style: `
          table, tr, td { page-break-inside: auto; border: 1px solid lightgray !important; border-collapse: collapse; }
          td, th, tr { font-size: 7pt; color: black; }
        `
      });
    },
  },
};
</script>