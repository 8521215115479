<template>
  <div>
    <v-card
      class="graph-card"
      color="transparent"
      dark
    >
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text class="mb-3">
        <v-chart ref="chart" :options="options" :style="{ height: `300px !important` }" autoresize />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import printJS from "print-js";

export default {
  name: "exam-graphic",

  props: {
    title: String,
    type: String,
    data: {
      type: Array,
      deep: true
    }
  },

  data() {
    return {
      notExam: [],
      expired: [],
      comingDue: [],
      regular: [],
    };
  },

  created() {
    this.loadExams();
  },

  computed: {
    options() {
      var bars = [];
      var data = [
        {name: 'Sem Exames', value: this.notExam},
        {name: 'Vencidos', value: this.expired},
        {name: 'À vencer', value: this.comingDue},
        {name: 'Regular', value: this.regular},
      ]

      data.forEach(e => {
        bars.push({
          name: e.name,
          type: 'bar',
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            lineHeight: 20,
            height: 20,
            backgroundColor: '#6a7985',
            color: '#fff',
            borderRadius: 5,
            position: 'top',
            distance: 1,
            formatter: '  {c}  ',
          },
          data: [e.value],
        });
      });

      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(140, 23, 23, 1)', 'rgba(251, 84, 37, 0.8)','rgba(37, 251, 39, 0.8)'],
        legend: {
          width: 500,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {

                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });
                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: ['Exames'],
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: bars
      };
    },
  },

  watch: {
    data: {
      deep: true,
      handler: function() {
        this.loadExams();
      }
    }
  },

  methods: {
    async loadExams() {
      const today = moment().format("YYYY-MM-DD");
      this.notExam = this.countNotExam(this.data, this.type);
      this.expired = this.countExpired(this.data, this.type, today);
      this.comingDue = this.countComingDue(this.data, this.type, today);
      this.regular = this.countRegular(this.data, this.type, today);
    },

    countNotExam(data, type) {
      const exams = (exam = type) => data.filter(item => !item[exam]).length;
      return type ? exams() : (exams('brucelose') + exams('tuberculose'));
    },

    countExpired(data, type, today) {
      const exams = (exam = type) => data.filter(item => item[exam] && (item[exam].data_vencimento < today)).length;
      return type ? exams() : (exams('brucelose') + exams('tuberculose'));
    },

    countComingDue(data, type, today) {
      const coming = moment().add(30, "days").format("YYYY-MM-DD");
      const exams = (exam = type) => data.filter(item => item[exam] && (item[exam].data_vencimento < coming && item[exam].data_vencimento >= today)).length;

      return type ? exams() : (exams('brucelose') + exams('tuberculose'));
    },

    countRegular(data, type, today) {
      const exams = (exam = type) => data.filter(item => item[exam] && (item[exam].data_vencimento > today)).length;
      return type ? exams() : (exams('brucelose') + exams('tuberculose'));
    }
  },
};
</script>
