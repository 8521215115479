<template>
  <v-dialog
    v-model="show"
    width="700"
    persistent
  >
    <v-card class="bill-dialog">
      <v-card-title>
        {{ title }}
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="show = false"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-stepper
        v-model="step"
        alt-labels
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="step > 1"
            :color="color"
          >
            Dados da conta
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="2"
            :complete="step > 2"
            :color="color"
          >
            Parcelamento
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="3"
            :complete="step > 3"
            :color="color"
          >
            {{ form.debito_credito == 'D' ? 'Pagamento' : 'Recebimento' }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="form-1"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="8"
                  class="py-0"
                >
                  <template v-if="['VALE', 'DESCONTO', 'CONVENIO', 'ANTIBIOTICO', 'NORMATIVA', 'FINANCEIRO'].includes(localType)">
                    <person-autocomplete-filter
                      v-model="form.produtor"
                      label="Produtor *"
                      type="PRODUCER"
                      persistent-hint
                      show-groupings
                      show-bonus-producers
                      :disabled="hasDischarge || !!form.id_movimento || form.bloqueado"
                      :hide-details="false"
                      :filled="false"
                      :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    />
                  </template>
                  <template v-if="['VALE_PRESTADOR', 'DESCONTO_PRESTADOR'].includes(localType)">
                    <person-autocomplete-filter
                      v-model="form.pessoa"
                      label="Prestador *"
                      type="SUPPLIER"
                      persistent-hint
                      :disabled="hasDischarge || !!form.id_movimento || form.bloqueado"
                      :hide-details="false"
                      :filled="false"
                      :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    />
                  </template>
                  <template v-else-if="localType == 'CONTA_A_PAGAR'">
                    <person-autocomplete-filter
                      v-model="form.pessoa"
                      label="Fornecedor *"
                      type="SUPPLIER"
                      persistent-hint
                      :disabled="hasDischarge"
                      :hide-details="false"
                      :filled="false"
                      :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    />
                  </template>
                  <template v-else-if="localType == 'CONTA_A_RECEBER'">
                    <person-autocomplete-filter
                      v-model="form.pessoa"
                      label="Cliente *"
                      type="CUSTOMER"
                      persistent-hint
                      :disabled="hasDischarge"
                      :hide-details="false"
                      :filled="false"
                      :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    />
                  </template>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <category-select
                    v-model="form.categoria"
                    :type="form.debito_credito"
                    label="Categoria"
                    persistent-hint
                    :disabled="hasDischarge || form.bloqueado"
                  />
                </v-col>
                <template v-if="localType == 'CONVENIO'">
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <person-autocomplete-filter
                      v-model="form.pessoa"
                      label="Credor/Agropecuária *"
                      type="SUPPLIER"
                      prepend-inner-icon="add_business"
                      persistent-hint
                      :disabled="hasDischarge || !!form.id_movimento"
                      :hide-details="false"
                      :filled="false"
                      :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    />
                  </v-col>
                </template>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model="form.observacao"
                    label="Observação"
                    prepend-inner-icon="description"
                    persistent-hint
                    :disabled="hasDischarge || form.bloqueado"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="form.numero"
                    label="Número"
                    prepend-inner-icon="receipt"
                    persistent-hint
                    :disabled="hasDischarge || !!form.id_movimento || form.bloqueado"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <date-picker
                    v-model="form.data_emissao"
                    label="Data de emissão *"
                    prepend-inner-icon="event"
                    persistent-hint
                    readonly
                    :disabled="hasDischarge || !!form.id_titulo || !!form.id_movimento || form.bloqueado"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <money-input
                    v-model="form.valor"
                    label="Valor *"
                    prefix="R$"
                    persistent-hint
                    :disabled="hasDischarge || !!form.id_movimento || !!form.id_formacao_valor_leite || form.bloqueado"
                    :rules="[() => !!form.valor && form.valor > 0 || 'Campo obrigatório!']"
                    @input="onAmountChange"
                  />
                </v-col>
                <v-col
                  v-if="showCashInMilk"
                  cols="6"
                  class="py-0"
                >
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="caption">
                        Descontar no leite
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-switch
                          v-model="form.descontar_no_leite"
                          :label="form.descontar_no_leite ? 'Ativo' : 'Inativo'"
                          prepend-inner-icon="receipt"
                          persistent-hint
                          :disabled="form.valor !== form.saldo"
                          class="mt-0"
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  v-if="['DESCONTO', 'FINANCEIRO'].includes(localType)"
                  cols="6"
                  class="py-0"
                >
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="caption">
                        Financeiro
                        <v-tooltip top>
                          <template #activator="{ on }">
                            <v-icon
                              small
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          Utilizado para integração com ERP
                        </v-tooltip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-switch
                          v-model="form.tipo"
                          :label="form.tipo === 'FINANCEIRO' ? 'Ativo' : 'Inativo'"
                          prepend-inner-icon="receipt"
                          true-value="FINANCEIRO"
                          false-value="DESCONTO"
                          :disabled="form.valor !== form.saldo"
                          class="mt-0"
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form
              ref="form-2"
              lazy-validation
            >
              <v-row>
                <v-col v-if="isRecurrencyEnabled">
                  <v-select
                    v-model="form.periodo_recorrencia"
                    :items="recurrences"
                    label="Recorrente *"
                    prepend-inner-icon="reorder"
                    persistent-hint
                    :disabled="hasDischarge || form.bloqueado"
                    @change="onChangeRecurrency"
                  />
                </v-col>
                <v-col v-if="form.periodo_recorrencia == 0">
                  <v-text-field
                    v-model="form.parcela_quantidade"
                    label="Parcelas *"
                    prepend-inner-icon="iso"
                    persistent-hint
                    :disabled="hasDischarge || form.bloqueado"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    @keypress="disableDotAndComma"
                    @input="onNoInstallments"
                  />
                </v-col>
                <v-col>
                  <date-picker
                    v-model="form.data_vencimento"
                    label="Vencimento *"
                    prepend-inner-icon="event"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    persistent-hint
                    readonly
                    :disabled="hasDischarge || form.bloqueado"
                    @input="onNoInstallments"
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-2" />
              <template v-if="form.periodo_recorrencia == 0">
                <v-row
                  v-for="(item, idx) of form.parcelas"
                  :key="idx"
                >
                  <v-col
                    cols="4"
                    class="py-0"
                  >
                    <v-text-field
                      :value="idx + 1"
                      label="Parcela"
                      prefix="#"
                      readonly
                      persistent-hint
                      :rules="[v => !!v || 'Campo obrigatório!']"
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    class="py-0"
                  >
                    <money-input
                      v-model="item.valor"
                      label="Valor *"
                      prefix="R$"
                      persistent-hint
                      :disabled="idx == (form.parcelas.length - 1) || hasDischarge || form.bloqueado"
                      :rules="idx != (form.parcelas.length - 1) ? getRules(idx) : []"
                      @input="onChangeAmount($event, idx)"
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    class="py-0"
                  >
                    <date-picker
                      v-model="item.data_vencimento"
                      label="Vencimento *"
                      prepend-inner-icon="event"
                      :rules="[v => !!v || 'Campo obrigatório!']"
                      persistent-hint
                      readonly
                      :disabled="hasDischarge || form.bloqueado"
                      @input="onChangeDateParcel($event, idx)"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-row
              v-for="(item, idx) of form.parcelas"
              :key="idx"
            >
              <v-col
                cols="1"
              >
                <v-text-field
                  :value="idx + 1"
                  prefix="#"
                  readonly
                  persistent-hint
                  hide-details
                />
              </v-col>
              <v-col
                cols="3"
              >
                <v-text-field
                  :value="item.valor"
                  label="Valor"
                  prefix="R$"
                  persistent-hint
                  hide-details
                  type="number"
                  min="0"
                  readonly
                />
              </v-col>
              <v-col
                cols="3"
              >
                <v-text-field
                  :value="item.saldo"
                  label="Saldo"
                  prefix="R$"
                  persistent-hint
                  hide-details
                  type="number"
                  min="0"
                  readonly
                />
              </v-col>
              <v-col class="flex-grow-1 flex-shrink-0">
                <v-text-field
                  :value="item.data_vencimento | dateFormat('DD/MM/YYYY')"
                  label="Vencimento"
                  prepend-inner-icon="event"
                  :rules="[v => !!v || 'Campo obrigatório!']"
                  persistent-hint
                  hide-details
                  readonly
                />
              </v-col>
              <v-col
                cols="2"
                class="pl-0 align-self-end"
              >
                <v-btn
                  v-if="item.saldo != 0"
                  outlined
                  block
                  small
                  :disabled="!!form.descontar_no_leite"
                  :color="color"
                  @click="discharge(item.id_parcela)"
                >
                  {{ form.debito_credito == 'D' ? 'Pagar' : 'Receber' }}
                </v-btn>
                <v-btn
                  v-else
                  outlined
                  block
                  small
                  color="orange"
                  @click="chargeback(item.id_parcela)"
                >
                  Estornar
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card-actions>
        <v-btn
          v-if="step == 1"
          color="grey darken-1"
          outlined
          @click="show = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="step = step - 1"
        >
          <v-icon
            left
            dark
          >
            chevron_left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <template v-if="step == 1">
          <v-tooltip
            top
            :disabled="!hasDischarge"
            :activator="$refs.deleteBtn"
          >
            Estorne os recebimentos para poder excluir
          </v-tooltip>
          <v-btn
            v-if="form.id_titulo"
            ref="deleteBtn"
            :color="!hasDischarge ? 'red darken-1' : 'grey'"
            outlined
            @click="remove"
          >
            Excluir
          </v-btn>
        </template>
        <v-btn
          v-if="step == 1 || (step == 2 && hasDischarge) || (step == 2 && !hasChanges)"
          color="green"
          outlined
          @click="nextStep"
        >
          Continuar
          <v-icon
            right
            dark
          >
            chevron_right
          </v-icon>
        </v-btn>
        <template v-else-if="step == 2">
          <v-btn
            v-if="form.id_titulo && hasChanges"
            color="orange darken-1"
            outlined
            @click="loadBill(), step = 1"
          >
            Descartar alterações
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            @click="save"
          >
            Salvar e Continuar
          </v-btn>
        </template>
        <v-btn
          v-else-if="step == 3"
          color="blue darken-1"
          outlined
          @click="show = false"
        >
          Fechar
        </v-btn>
      </v-card-actions>

      <v-overlay
        :value="loading || saving"
        absolute
      >
        <v-card-text>
          {{ loading ? 'Carregando...' : 'Salvando...' }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-card>

    <discharge-dialog
      v-model="dischargeDialog.show"
      :installment-id="dischargeDialog.id"
      @input="dischargeDialog.id = null"
      @newPayment="onPayment"
    />
  </v-dialog>
</template>

<style lang="scss">
.bill-dialog {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: unset;
  }
  .v-stepper__content {
    max-height: calc(100vh - 350px);
    overflow: auto;
  }
}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import CategorySelect from "@/Domains/Financial/Components/CategorySelect.vue";
import DatePicker from "@/Domains/Visits/Schedule/Components/DatePicker.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import DischargeDialog from '@/Domains/Financial/Components/DischargeDialog.vue';

import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    PersonAutocompleteFilter,
    CategorySelect,
    DatePicker,
    MoneyInput,
    DischargeDialog,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  props: {
    value: Boolean,
    type: {
      type: String,
      default: 'CONTA_A_RECEBER',
      validator: (value) => ['CONTA_A_RECEBER', 'CONTA_A_PAGAR', 'VALE', 'DESCONTO', 'CONVENIO', 'ANTIBIOTICO', 'NORMATIVA', 'DESCONTO_PRESTADOR', 'VALE_PRESTADOR', 'FINANCEIRO'].indexOf(value) !== -1
    },
    billId: String,
  },

  data() {
    return {
      loading: false,
      saving: false,
      step: 1,

      hasChanges: false,

      form: {
        tipo: null,
        debito_credito: '',
        data_emissao: moment().toDate(),
        data_vencimento: moment().toDate(),
        periodo_recorrencia: 0,
        parcela_quantidade: 1,
        parcelas: []
      },

      recurrences: [
        { value: 0, text: 'Não' },
        { value: 1, text: 'Mensal' },
        { value: 2, text: 'Bimestral' },
        { value: 3, text: 'Trimestral' },
        { value: 6, text: 'Semestral' },
        { value: 12, text: 'Anual' },
      ],

      dischargeDialog: {
        show: false,
        id: null,
      },
    }
  },

  computed: {
    /**
     * Ao passar o id da conta, deve ser obtido o tipo que foi salvo no BD,
     * pois são contas que são geradas pela API do financeiro
     * como por exemplo o ANTIBIOTICO e o FINANCEIRO
     */
    localType() {
      return this.form.tipo || this.type;
    },
    hasDischarge() {
      return this.form.id_titulo && this.form.valor != this.form.saldo;
    },
    showCashInMilk() {
      return ['VALE', 'DESCONTO', 'CONVENIO', 'ANTIBIOTICO', 'NORMATIVA', 'FINANCEIRO'].includes(this.localType) && !this.form.id_formacao_valor_leite;
    },
    title() {
      if (this.localType == 'CONTA_A_PAGAR') {
        return 'Conta a Pagar'
      }
      if (this.localType == 'CONTA_A_RECEBER') {
        return 'Conta a Receber'
      }
      if (['VALE', 'VALE_PRESTADOR'].includes(this.localType)) {
        return 'Vale'
      }
      if (['DESCONTO', 'DESCONTO_PRESTADOR'].includes(this.localType)) {
        return 'Desconto'
      }
      if (this.localType == 'CONVENIO') {
        return 'Convênio'
      }
      if (this.localType == 'ANTIBIOTICO') {
        return 'Desconto (Antibiótico)'
      }
      if (this.localType == 'NORMATIVA') {
        return 'Desconto (Amostra)'
      }
      if (this.localType == 'FINANCEIRO') {
        return 'Desconto (Financeiro)'
      }
      return ''
    },
    color() {
      if (this.localType == 'CONTA_A_PAGAR') {
        return 'red'
      }
      if (this.localType == 'CONTA_A_RECEBER') {
        return 'blue'
      }
      if (['VALE', 'DESCONTO', 'ANTIBIOTICO', 'NORMATIVA', 'FINANCEIRO'].includes(this.localType)) {
        return 'deep-orange'
      }
      if (['VALE_PRESTADOR', 'DESCONTO_PRESTADOR'].includes(this.localType)) {
        return 'teal'
      }
      if (this.localType == 'CONVENIO') {
        return 'purple'
      }
      return 'orange'
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$refs['form-1'] && this.$refs['form-1'].resetValidation();
          this.$refs['form-2'] && this.$refs['form-2'].resetValidation();
          this.form = {
            tipo: this.type,
            data_emissao: moment().toDate(),
            data_vencimento: moment().toDate(),
            periodo_recorrencia: 0,
            parcela_quantidade: 1,
            parcelas: []
          };

          if (this.showCashInMilk) {
            this.form.descontar_no_leite = 1;
          }
          this.checkType();
          this.step = 1;
        }
        this.$emit("input", value);
      },
    },

    isRecurrencyEnabled() {
      // return this.localType != 'CONVENIO';
      /**
       * Recorrencia desabilitada, pois nos fechamentos (produtor e prestador) não está incluso esse tipo de conta
       * Quando for necessário reabilitar, deve ser alterado o fechamento e o extrato (produtor e prestador)
       * para incluir os valores de recorrência
       */
      return false;
    },
  },

  watch: {
    value(value) {
      if (value) {
        if (this.showCashInMilk) {
          this.form.descontar_no_leite = 1;
        }
        this.getDataFromStore();
      }
    },
    type(value) {
      this.form.tipo = value;
      this.checkType();
    },
    billId(value) {
      if (value) {
        this.form.id_titulo = this.billId;
        this.loadBill();
      }
    },
    form: {
      deep: true,
      handler() {
        this.hasChanges = true;
      }
    },

    'form.data_vencimento'(value) {
      if (this.show && moment().isBefore(value, 'day')) {
        this.$store.commit("setBill", { data_vencimento: value });
      }
    },

    'form.pessoa'(value) {
      if (this.show) {
        this.$store.commit("setBill", { pessoa: value });
      }
    }
  },

  created() {
    this.checkType();
    if (this.billId) {
      this.form.id_titulo = this.billId;
      this.loadBill();
    }
  },

  mounted() {
    this.$refs['form-1'] && this.$refs['form-1'].resetValidation();
    this.$refs['form-2'] && this.$refs['form-2'].resetValidation();
  },

  methods: {
    getDataFromStore() {
      if (!_.isEmpty(this.$store.state.settings.bill)) {
        this.form = {
          ...this.form,
          ...this.$store.state.settings.bill,
        };
      }
    },
    checkType() {
      this.form.tipo = this.localType;
      switch (this.localType) {
        case 'CONTA_A_RECEBER':
        case 'DESCONTO':
        case 'ANTIBIOTICO':
        case 'NORMATIVA':
        case 'FINANCEIRO':
        case 'CONVENIO':
        case 'DESCONTO_PRESTADOR':
          this.form.debito_credito = 'C';
          break;
        case 'CONTA_A_PAGAR':
        case 'VALE':
        case 'VALE_PRESTADOR':
          this.form.debito_credito = 'D';
          break;
        default: throw "Tipo não suportado";
      }
    },

    async nextStep() {
      if (!await this.$refs[`form-${this.step}`].validate() && !this.hasDischarge) {
        return;
      }
      this.step++;
    },

    async validateForm() {
      if (!await this.$refs['form-1'].validate()) {
        this.step = 1;
        return false;
      }
      if (!await this.$refs['form-2'].validate()) {
        this.step = 2;
        return false;
      }
      return true;
    },

    async save() {
      if (!await this.validateForm()) {
        return;
      }
      this.saving = true;
      try {

        let payload = {
          ...this.form,
          valor: parseFloat(this.form.valor),
          data_emissao: moment(this.form.data_emissao).format('YYYY-MM-DD'),
          data_vencimento: moment(this.form.data_vencimento).format('YYYY-MM-DD'),
          parcelas: this.form.parcelas.map(parcela => ({
            valor: parseFloat(parcela.valor),
            data_vencimento: moment(parcela.data_vencimento).format('YYYY-MM-DD'),
          }))
        };

        if (payload.pessoa) {
          payload.id_pessoa = payload.pessoa.id;
          payload.nome_pessoa = payload.pessoa.name;
          delete payload.pessoa;
        }

        if (payload.produtor) {
          payload.id_produtor = payload.produtor.id;
          payload.nome_produtor = payload.produtor.name;
          delete payload.produtor;
        }

        if (payload.categoria) {
          payload.id_categoria = payload.categoria.id;
          payload.categoria_nome = payload.categoria.name;
          delete payload.categoria;
        }

        const action = payload.id_titulo ? 'atualizaConta' : 'insereConta';

        const { data } = await this.$axios.post(`/financeiro/${action}`, payload);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.form.id_titulo = data.id_titulo;
        for (let i = 0; i < this.form.parcela_quantidade; i++) {
          this.form.parcelas[i].id_parcela = data.parcelas[i];
        }
        this.nextStep();
        this.$emit('newBill', data.id_titulo);
        this.$snotify.success("Conta salva com sucesso!", "Sucesso");
        // Delay para evitar que as parcelas sejam alteradas.
        setTimeout(() => {
          this.hasChanges = false;
        }, 100)
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async remove() {
      if (this.form.valor != this.form.saldo) {
        return;
      }
      if (!(await this.$root.$confirm('Excluir Conta', 'Tem certeza que deseja excluir esta conta?', { color: 'red' }))) {
        return;
      }
      this.loading = true;
      try {

        const { data } = await this.$axios.post(`/financeiro/inativaConta`, { id_titulo: this.form.id_titulo });

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$emit('removeBill');
        this.$snotify.success("Conta excluída com sucesso!", "Sucesso");
        this.show = false;
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadBill() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(`/financeiro/detalheConta`, { id_titulo: this.form.id_titulo });

        if (!data || !data.id_titulo) {
          return;
        }

        if (data.id_pessoa) {
          data.pessoa = {
            id: data.id_pessoa,
            name: data.nome_pessoa,
          };
          delete data.id_pessoa;
          delete data.nome_pessoa;
        }

        if (data.id_produtor) {
          data.produtor = {
            id: data.id_produtor,
            name: data.nome_produtor,
          };
          delete data.id_produtor;
          delete data.nome_produtor;
        }

        if (data.id_categoria) {
          data.categoria = {
            id: data.id_categoria,
            name: data.categoria_nome,
          };
          delete data.id_categoria;
          delete data.categoria_nome;
        }

        this.form = {
          ...data,
          data_emissao: moment(data.data_emissao).toDate(),
          data_vencimento: moment(data.data_vencimento).toDate(),
          valor: parseFloat(data.valor) * (data.debito_credito == "D" ? -1 : 1),
          saldo: parseFloat(data.saldo) * (data.debito_credito == "D" ? -1 : 1),
          parcelas: data.parcelas.map(parcela => ({
            ...parcela,
            data_vencimento: moment(parcela.data_vencimento).toDate(),
            valor: parseFloat(parcela.valor) * (data.debito_credito == "D" ? -1 : 1),
            saldo: parseFloat(parcela.saldo) * (data.debito_credito == "D" && parseFloat(parcela.saldo) ? -1 : 1),
          }))
        };
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a conta!", "Atenção");
        this.show = false;
        console.error(e);
      } finally {
        // Delay para evitar que as parcelas sejam alteradas.
        setTimeout(() => {
          this.loading = false;
          this.hasChanges = false;
        }, 100)
      }
    },

    onAmountChange(valor) {
      if (this.loading) {
        return;
      }
      this.form.saldo = valor;
      this.onNoInstallments();
    },

    onChangeRecurrency() {
      if (this.loading) {
        return;
      }
      this.form.parcela_quantidade = 1;
      this.onNoInstallments();
    },

    onNoInstallments: _.debounce(function() {
      if (this.loading || this.form.saldo != this.form.valor) {
        return;
      }
      // Quantidade de parcelas
      const noInstallments = parseInt(this.form.parcela_quantidade) || 0;
      // Valor da conta
      const accountAmount = parseFloat(this.form.valor);
      // Valor de cada parcela, com arredondamento para 2 casas decimais (para baixo)
      const installmentAmount = Math.floor((accountAmount / noInstallments) * 100) / 100;
      // Diferença em centavos das parcelas
      const rest = accountAmount - (installmentAmount * noInstallments);
      // Na última parcela deve ser adicionado a diferença dos centavos
      const lastAmount = +(installmentAmount + rest).toFixed(2);

      this.form.parcelas = [];

      let lastDueDate = this.form.data_vencimento;
      for (let i = 1; i <= noInstallments; i++) {
        const amount = i < noInstallments ? installmentAmount : lastAmount;
        this.form.parcelas.push({
          data_vencimento: moment(lastDueDate).toDate(),
          valor: amount,
          saldo: amount,
        });
        lastDueDate = moment(lastDueDate).add(1, 'M').format('YYYY-MM-DD');
      }
    }, 500),

    /**
     * Verifica o valor da parcela atual e rateia o resto entre as parcelas posteriores
     */
    onChangeAmount(amount, idx) {
      if (this.loading || (idx == this.form.parcelas.length - 1)) {
        return;
      }
      // Valor desta parcela
      amount = parseFloat(amount) || 0;
      // Índice para dividir esta parcela das posteriores
      const splitInstallment = idx + 1;
      // Quantidade de parcelas
      const noInstallments = this.form.parcelas.length;
      // Valor da conta
      const accountAmount = parseFloat(this.form.valor);
      // Soma essa parcela com as parcelas atuais
      const currAmount = this.form.parcelas.slice(0, splitInstallment).reduce((acc, cur) => acc + (parseFloat(cur.valor) || 0), 0);
      // A diferença será rateada nas parcelas posteriores
      const restAmmount = accountAmount - currAmount;
      // Quantidade de parcelas restantes
      const restNoInstallments = noInstallments - splitInstallment;
      // Valor de cada parcela, com arredondamento para 2 casas decimais (para baixo)
      const installmentAmount = Math.floor((restAmmount / restNoInstallments) * 100) / 100;
      // Diferença em centavos das parcelas
      const rest = restAmmount - (installmentAmount * restNoInstallments);
      // Na última parcela deve ser adicionado a diferença dos centavos
      const lastAmount = +(installmentAmount + rest).toFixed(2);

      this.form.parcelas[idx].saldo =  amount;

      for (let i = splitInstallment; i < noInstallments; i++) {
        const amount = i < (noInstallments - 1) ? installmentAmount : lastAmount;
        this.form.parcelas[i].valor =  amount;
        this.form.parcelas[i].saldo =  amount;
      }
    },

    /**
     * Retorna as regras de validações de cada parcela
     */
    getRules(idx) {
      // Valor da conta
      const accountAmount = parseFloat(this.form.valor);
      // Soma essa parcela com as parcelas atuais
      const currAmount = this.form.parcelas.slice(0, idx + 1).reduce((acc, cur) => acc + cur.valor, 0);
      // Valor da parcela
      const installmentAmount = parseFloat(this.form.parcelas[idx].valor);
      return [
        () => !!installmentAmount || 'Informe o valor',
        () => installmentAmount > 0 || 'O valor não pode ser negativo',
        () => currAmount <= accountAmount || 'O valor deve ser menor ou igual ao valor da conta',
      ]
    },

    /**
     * Se a data de vencimento da primeira parcela for alterada
     * Altera também a data de vencimento da conta
     */
    onChangeDateParcel(value, idx) {
      if (idx == 0) {
        this.form.data_vencimento = value;
        this.onNoInstallments();
      }
    },

    discharge(id) {
      this.dischargeDialog.id = id;
      this.dischargeDialog.show = true;
    },

    onPayment() {
      this.loadBill();
      this.step = 3;
    },

    async chargeback(id) {
      if (!(await this.$root.$confirm('Estornar Parcela', 'Tem certeza que deseja estornar esta parcela?', { color: 'orange' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/financeiro/estorno`, { id_parcela: id });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Estorno efetuado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao estornar a parcela", "Atenção");
        console.warn(error);
      } finally {
        this.loadBill();
        this.step = 3;
      }
    },

    disableDotAndComma(e) {
      if ([44, 46, 101].includes(e.charCode)) {
        e.preventDefault()
      }
    },
  }
}
</script>
