<template>
  <div class="ma-5 pt-0">
    <v-form
      ref="formAnalysis"
      v-model="validForm"
      lazy-validation
    >
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title class="pt-1 pb-1">
          <v-spacer />
          <v-col
            md="4"
          >
            <v-select
              v-model="filters.route"
              :items="routes"
              item-text="descricao"
              item-value="id_rota"
              prepend-inner-icon="icon-rota"
              label="Rotas"
              hide-details
              @change="loadPending"
            />
          </v-col>
          <v-col
            cols="4"
            md-4
            sm-4
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
            />
          </v-col>
        </v-card-title>
      </v-card>
      <v-data-table
        :height="heightTable"
        dark
        dense
        fixed-header
        :search="filters.search"
        :headers="selectedHeaders"
        :loading="loading"
        :items="sortProducers"
        class="diff-list-table"
        show-expand
        :items-per-page="-1"
      >
        <template v-slot:header.data-table-expand="{ item }">
          <v-menu open-on-hover>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>view_column</v-icon>
              </v-btn>
            </template>

            <v-list
              style="max-height: 400px"
              class="overflow-y-auto"
            >
              <v-list-item-group>
                <v-list-item
                  selectable
                  @click.stop="onHeaderSelect('*')"
                >
                  <v-list-item-icon>
                    <v-icon v-text="'done_all'" />
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="'Selecionar Todos'" />
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-for="header in headersProducer"
                  :key="header.value"
                  selectable
                  @click.stop="onHeaderSelect(header.value)"
                >
                  <v-list-item-action>
                    <v-checkbox :input-value="header.show" />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-text="header.text" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.data="{ item }">
          <masked-input
            v-model="item.data "
            class="text-body-2"
            :mask="DateMask"
            :return-unmasked="false"
          />
        </template>
        <template v-slot:item.temperatura="{ item }">
          <masked-input
            v-model="item.temperatura"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.acidez="{ item }">
          <masked-input
            v-model="item.acidez"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.alizarol="{ item }">
          <masked-input
            v-model="item.alizarol"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.crioscopia="{ item }">
          <masked-input
            v-model="item.crioscopia"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.porcentagem_agua="{ item }">
          <masked-input
            v-model="item.porcentagem_agua"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.densidade="{ item }">
          <masked-input
            v-model="item.densidade"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.esd="{ item }">
          <masked-input
            v-model="item.esd"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.est="{ item }">
          <masked-input
            v-model="item.est"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.gordura="{ item }">
          <masked-input
            v-model="item.gordura"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.proteina="{ item }">
          <masked-input
            v-model="item.proteina"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.ph="{ item }">
          <masked-input
            v-model="item.ph"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.lactose="{ item }">
          <masked-input
            v-model="item.lactose"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.redutase="{ item }">
          <masked-input
            v-model="item.redutase"
            class="text-body-2"
            :mask="FloatNumberMask"
            currency
            return-unmasked
          />
        </template>
        <template v-slot:item.antibiotico="{ item }">
          <v-checkbox
            v-model="item.antibiotico"
          />
        </template>
        <template v-slot:item.cloretos="{ item }">
          <v-checkbox
            v-model="item.cloretos"
          />
        </template>
        <template v-slot:item.mastite="{ item }">
          <v-checkbox
            v-model="item.mastite"
          />
        </template>
        <template v-slot:item.soda="{ item }">
          <v-checkbox
            v-model="item.soda"
          />
        </template>
        <template v-slot:item.formol="{ item }">
          <v-checkbox
            v-model="item.formol"
          />
        </template>
        <template v-slot:item.peroxido="{ item }">
          <v-checkbox
            v-model="item.peroxido"
          />
        </template>
        <template v-slot:item.cloro="{ item }">
          <v-checkbox
            v-model="item.cloro"
          />
        </template>
        <template v-slot:item.amido="{ item }">
          <v-checkbox
            v-model="item.amido"
          />
        </template>
        <template v-slot:item.sacarose="{ item }">
          <v-checkbox
            v-model="item.sacarose"
          />
        </template>
        <template v-slot:item.neutralizantes="{ item }">
          <v-checkbox
            v-model="item.neutralizantes"
          />
        </template>
        <template v-slot:item.reconstituintes="{ item }">
          <v-checkbox
            v-model="item.reconstituintes"
          />
        </template>
        <template v-slot:item.conservantes="{ item }">
          <v-checkbox
            v-model="item.conservantes"
          />
        </template>
        <template v-slot:item.bicarbonato="{ item }">
          <v-checkbox
            v-model="item.bicarbonato"
          />
        </template>
        <template v-slot:item.betalactamico="{ item }">
          <v-checkbox
            v-model="item.betalactamico"
          />
        </template>
        <template v-slot:item.tetraciclina="{ item }">
          <v-checkbox
            v-model="item.tetraciclina"
          />
        </template>
        <template v-slot:item.sulfonamida="{ item }">
          <v-checkbox
            v-model="item.sulfonamida"
          />
        </template>
        <template v-slot:item.alcool_etilico="{ item }">
          <v-checkbox
            v-model="item.alcool_etilico"
          />
        </template>
        <template v-slot:item.alcalino="{ item }">
          <v-checkbox
            v-model="item.alcool_etilico"
          />
        </template>
      </v-data-table>
    </v-form>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="blue"
        @click="loadRoutes(); loadPending();"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>
          Recarregar
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="onSaveAnalysisPending()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="purple darken-1"
        @click="onSaveAnalysisFinish()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              lock
            </v-icon>
          </template>
          Salvar e Finalizar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
    margin: 0 !important;
  }
}

</style>

<script>
import _ from "lodash";
import moment from "moment";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    MaskedInput,
  },
  filters: {

  },

  data() {
    return {

      filters: {
        search: "",
        route: [],
      },

      DateMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      validForm: true,

      loading: false,

      status: 'PENDENTE',

      sortProducers: [],

      routes: [],

      headersProducer: [
        {
          align: 'start',
          text: "codigo",
          value: "codigo_laticinio",
          width: 10,
          default: true,
          show: true,
        },
        {
          text: "Nome",
          value: "nome",
          default: true,
          show: true,
        },
        {
          text: "Data",
          value: "data",
          default: true,
          show: false,

        },
        {
          text: "Temp",
          value: "temperatura",
          default: false,
          show: true,
        },
        {
          text: "Acidez",
          value: "acidez",
          default: false,
          show: true,
        },
        {
          text: "Alizarol",
          value: "alizarol",
          default: false,
          show: true,
        },
        {
          text: "Crioscopia",
          value: "crioscopia",
          default: false,
          show: true,
        },
        {
          text: "% água",
          value: "porcentagem_agua",
          default: false,
          show: true,
        },
        {
          text: "Densidade",
          value: "densidade",
          default: false,
          show: true,
        },
        {
          text: "ESD",
          value: "esd",
          default: false,
          show: true,
        },
        {
          text: "EST",
          value: "est",
          default: false,
          show: true,
        },
        {
          text: "Gordura",
          value: "gordura",
          default: false,
          show: true,
        },
        {
          text: "Proteina",
          value: "proteina",
          default: false,
          show: true,
        },
        {
          text: "PH",
          value: "ph",
          default: false,
          show: true,
        },
        {
          text: "Antibiotico",
          value: "antibiotico",
          default: false,
          show: true,
        },
        {
          text: "Cloretos",
          value: "cloretos",
          default: false,
          show: true,
        },
        {
          text: "Mastite",
          value: "mastite",
          default: false,
          show: true,
        },
        {
          text: "Soda",
          value: "soda",
          default: false,
          show: true,
        },
        {
          text: "Formaldeido",
          value: "formol",
          default: false,
          show: true,
        },
        {
          text: "Peroxido de hidrogenio",
          value: "peroxido",
          default: false,
          show: true,
        },
        {
          text: "Cloro/Hipoclorito",
          value: "cloro",
          default: false,
          show: true,
        },
        {
          text: "Amido",
          value: "amido",
          default: false,
          show: true,
        },
        {
          text: "Sacarose",
          value: "sacarose",
          default: false,
          show: true,
        },
        {
          text: "Neutralizantes",
          value: "neutralizantes",
          default: false,
          show: true,
        },
        {
          text: "reconstituintes",
          value: "reconstituintes",
          default: false,
          show: true,
        },
        {
          text: "conservantes",
          value: "conservantes",
          default: false,
          show: true,
        },
        {
          text: "bicarbonato",
          value: "bicarbonato",
          default: false,
          show: true,
        },
        {
          text: "betalactamico",
          value: "betalactamico",
          default: false,
          show: true,
        },
        {
          text: "tetraciclina",
          value: "tetraciclina",
          default: false,
          show: true,
        },
        {
          text: "sulfonamida",
          value: "sulfonamida",
          default: false,
          show: true,
        },
        {
          text: "Alcool Etilico",
          value: "alcool_etilico",
          default: false,
          show: true,
        },
        {
          text: "Lactose",
          value: "lactose",
          default: false,
          show: true,
        },
        {
          text: "Redutase",
          value: "redutase",
          default: false,
          show: true,
        },
        {
          text: "Alcalino",
          value: "alcalino",
          default: false,
          show: true,
        },

      ],

    };

  },
  computed: {
    /**
         * Lista com todas as headers visiveis na tabela
         * @returns {Array}
         */
    selectedHeaders() {
      return this.headersProducer.filter(header => header.show);
    },

    heightTable() {
      return window.innerHeight - 340 ;
    }

  },
  mounted() {
    this.loadRoutes();
    this.loadPending();
  },
  methods: {

    async loadPending() {
      try {
        this.loading = true;

        const request = {
          id_rota: this.filters.route,
        };

        const { data } = await this.$axios.post(
          `/analiseSorteio/listaPendenteColeta`,
          this.$qs.stringify({
            ...request,
          }),
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.sortProducers = data.analises.map(producer => {
          return {
            codigo_laticinio: producer.codigo_laticinio,
            id_pessoa: producer.id_pessoa,
            id_analise: producer.id_analise,
            nome: producer.nome,
            data: moment().format("DD/MM/YYYY"),
            temperatura: producer.temperatura,
            acidez: producer.acidez,
            alizarol: producer.alizarol,
            crioscopia: producer.crioscopia,
            porcentagem_agua: producer.porcentagem_agua,
            densidade: producer.densidade,
            esd: producer.esd,
            est: producer.est,
            gordura: producer.gordura_inicial,
            proteina: producer.proteina,
            ph: producer.ph,
            antibiotico: producer.antibiotico,
            cloretos: producer.cloretos,
            mastite: producer.mastite,
            soda: producer.soda,
            formol: producer.formol,
            peroxido: producer.peroxido,
            cloro: producer.cloro,
            amido: producer.amido,
            sacarose: producer.sacarose,
            neutralizantes: producer.neutralizantes,
            reconstituintes: producer.reconstituintes,
            conservantes: producer.conservantes,
            bicarbonato: producer.bicarbonato,
            betalactamico: producer.betalactamico,
            tetraciclina: producer.tetraciclina,
            sulfonamida: producer.sulfonamida,
            alcool_etilico: producer.alcool_etilico,
            lactose: producer.lactose,
            redutase: producer.redutase,
            alcalino: producer.alcalino,
          }
        });

        if (!_.isEmpty(data.grupos)) {
          let itens = JSON.parse(data.grupos[0].itens);
          await this.loadItemHeader(itens);
        }

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao sortear!", "Atenção");
      } finally {
        this.loading = false;
      }
    },
    /*
        refaz header conforme grupos sorteados
        */
    async loadItemHeader(itens) {
      const headers = [];

      this.headersProducer.map(header => {

        let showHeader = itens.find(i => i === header.value  || header.default);

        if (!_.isEmpty(showHeader)) {
          headers.push(header);
        }

      });

      this.headersProducer = headers;
    },

    async onSaveAnalysisPending() {
      this.status = 'PENDENTE';
      await this.handleSaveAnalysis();
    },

    async onSaveAnalysisFinish() {
      this.status = 'PROCESSADA';
      await this.handleSaveAnalysis();
      this.loadPending();
    },

    async handleSaveAnalysis() {

      const data = this.sortProducers.map(producer => {
        return {
          id_pessoa: producer.id_pessoa,
          id_analise: producer.id_analise,
          data: producer.data ? moment(producer.data, 'DD/MM/YYYY').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
          temperatura: parseFloat(producer.temperatura),
          acidez: parseFloat(producer.acidez),
          alizarol: parseFloat(producer.alizarol),
          crioscopia: parseFloat(producer.crioscopia),
          porcentagem_agua: parseFloat(producer.porcentagem_agua),
          densidade: parseFloat(producer.densidade),
          esd: parseFloat(producer.esd),
          est: parseFloat(producer.est),
          gordura: parseFloat(producer.gordura),
          proteina: parseFloat(producer.proteina),
          ph: parseFloat(producer.ph),
          antibiotico: producer.antibiotico,
          cloretos: producer.cloretos,
          mastite: producer.mastite,
          soda: producer.soda,
          formol: producer.formol,
          peroxido: producer.peroxido,
          cloro: producer.cloro,
          amido: producer.amido,
          sacarose: producer.sacarose,
          neutralizantes: producer.neutralizantes,
          reconstituintes: producer.reconstituintes,
          conservantes: producer.conservantes,
          bicarbonato: producer.bicarbonato,
          betalactamico: producer.betalactamico,
          tetraciclina: producer.tetraciclina,
          sulfonamida: producer.sulfonamida,
          alcool_etilico: parseFloat(producer.alcool_etilico),
          lactose: parseFloat(producer.lactose),
          redutase: parseFloat(producer.redutase),
          alcalino: producer.alcalino,
        }
      });

      await this.onSaveAnalysis(data);
    },

    async onSaveAnalysis(dataHandle) {
      try {
        this.loading = true;

        const request = {
          analises: JSON.stringify(dataHandle),
          status: this.status,
        };

        const { data } = await this.$axios.post(
          `/analiseSorteio/salvarAnalises`,
          this.$qs.stringify({
            ...request,
          }),
        );

        if (!_.isObject(data)) {
          this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");
        }

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    /**
       * @event string
       *
       * Evento acionado ao selecionar uma coluna para exibir na lista
       */
    onHeaderSelect(value) {
      this.headersProducer = this.headersProducer.map(header => {
        if (header.value === value || value === '*') {
          return {
            ...header,
            show: !header.show,
          }
        }

        return header;
      })
    },

    async loadRoutes() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/analiseSorteio/listaRotaColetaPendente`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.routes = data;

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

  },

}
</script>
