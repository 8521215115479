<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col
        v-for="(item, idx) in additionals"
        :key="idx"
        cols="12"
      >
        <v-form :ref="`form-${idx}`">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title class="pt-2 pb-0">
              <v-row
                class="py-0"
              >
                <v-col
                  cols="9"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="item.descricao"
                    label="Descrição"
                    prepend-inner-icon="compare"
                    dark
                    filled
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <masked-text-field
                    v-model.number="item.valor"
                    label="Valor"
                    prefix="R$"
                    persistent-hint
                    unmask
                    :mask="{ mask: Number, min: 0, scale: 4 }"
                    dark
                    filled
                    :rules="[v => !!v || 'Informe o valor']"
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="item.prestadores"
                    outlined
                    multiple
                    label="Prestadores"
                    :items="suppliers"
                    return-object
                    item-value="id_pessoa"
                    item-text="nome"
                    counter
                    chip
                    small-chips
                    deletable-chips
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex py-0"
                >
                  <v-btn
                    color="white"
                    outlined
                    large
                    @click="removeAdditional(idx)"
                  >
                    <v-icon left>
                      delete
                    </v-icon>
                    Remover
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="white"
                    outlined
                    large
                    @click="save(idx)"
                  >
                    <v-icon left>
                      send
                    </v-icon>
                    Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
      <v-col
        v-if="additionals.length === 0"
        cols="12"
        class="d-flex justify-center text-left"
      >
        <v-alert
          dense
          light
          prominent
          colored-border
          border="left"
          elevation="2"
          type="info"
          style="max-width: 900px"
        >
          <div>
            Nenhum adicional configurado. Adicione através do botão
            <v-btn
              icon
              disabled
            >
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-5"
          v-on="on"
          @click="addAdditional()"
        >
          <v-icon>
            add
          </v-icon>
        </v-btn>
      </template>
      Inserir Adicional
    </v-tooltip>
  </v-container>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

export default {
  name: 'freight-additional-tab',

  components: {
    MaskedTextField,
  },

  data() {
    return {
      suppliers: [],

      additionals: [],
    };
  },

  mounted() {
    this.loadSuppliers();
    this.load();
  },

  methods: {

    async loadSuppliers() {
      try {
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.get(`/fornecedor/listaJson`);

        this.suppliers = data.map(o => ({
          id_pessoa: o.id_pessoa,
          nome: o.nome,
        })).sort((a, b) => a.nome.localeCompare(b.nome));

      } catch (error) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar os produtores!', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async load() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/settings/freight-additional`);

        this.additionals = data;
      }
      catch (err) {
        this.$snotify.error('Erro ao carregar adicionais', 'Atenção');
        console.warn(err)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    addAdditional() {
      this.additionals.push({
        descricao: '',
        valor: '',
        prestadores: [],
      })
    },

    async removeAdditional(idx) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Esta ação irá impactar em <b>TODOS</b> os prestadores escolhidos para receber o valor adicional.<br>`
      ))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const { id } = this.additionals[idx];

        if (id) {
          await this.$axios.delete(`/settings/freight-additional/${id}`);
        }

        this.$snotify.success('Configuração removida com sucesso', 'Sucesso');
        this.additionals.splice(idx, 1);
      } catch (error) {
        this.$snotify.error('Erro ao remover Configuração', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save(idx) {
      if (!await this.$refs[`form-${idx}`][0].validate()) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const payload = { ...this.additionals[idx] };

        const data = payload.id ? await this.update(payload.id, payload) : await this.store(payload);

        this.additionals[idx].id = data.id;

        this.$snotify.success('Configuração salva com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error('Erro ao salvar Configuração', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/settings/freight-additional`, payload);
      return data;
    },

    async update(id, payload) {
      const { data } = await this.$axios.put(`/settings/freight-additional/${id}`, payload);
      return data;
    },

    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
};
</script>
