<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h3 class="menu-header white--text">
          Médias de Qualidade
        </h3>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <!-- Ao retornar o menu continue da mesma cor -->
      <template #activator>
        <v-btn
          color="light brilliant red-orange/light brilliant vermilion"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-container>
        <v-row
          justify="end"
          class="pa-0"
        >
          <v-col
            offset="8"
            cols="4"
            md="4"
          >
            <v-select
              v-model="dataSelecionada"
              :items="datas"
              light
              hide-details
              label="Este ano"
              solo
              clearable
              @input="setDates()"
            >
              <template #datas="{ item }">
                <template v-if="item.value === dataSelecionada && item.value === 'intervalo' && dataInicio && dataFim && !showSelectDate">
                  <span class="grow">
                    {{ dataInicio | dateFormat("DD/MM/YYYY") }} — {{ dataFim | dateFormat("DD/MM/YYYY") }}
                  </span>
                  <v-icon>refresh</v-icon>
                </template>

                <template v-else>
                  {{ item.text }}
                </template>
              </template>
              <template #selection="{ item }">
                <span v-if="item.value === dataSelecionada && item.value === 'intervalo' && dataInicio && dataFim && !showSelectDate">
                  {{ dataInicio | dateFormat("DD/MM/YYYY") }} — {{ dataFim | dateFormat("DD/MM/YYYY") }}
                </span>
                <span v-else>
                  {{ item.text }}
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <div
          id="print"
          ref="print"
        >
          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="ccsGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <br>

          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="cbtGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <br>

          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="gorduraGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <br>

          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="proteinaGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <br>

          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="ureiaGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <br>

          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="lactoseGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <br>

          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="esdGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <br>

          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="solidosGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <br>

          <v-card
            light
            dense
            elevation="2"
          >
            <v-card-text>
              <v-layout>
                <v-flex xs12>
                  <v-chart
                    type="bar"
                    :options="caseinaGraph.options"
                    :style="{width: '100%', height: `300px !important`}"
                    autoresize
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </div>
      </v-container>

      <v-dialog
        v-model="showSelectDate"
        :value="showSelectDate"
        :width="$vuetify.breakpoint.smAndUp ? '290px' : '350px'"
        @input="showSelectDate = false"
        @keydown.esc="showSelectDate = false"
      >
        <v-window
          v-model="currentDateSelector"
          touchless
        >
          <v-window-item value="dataInicio">
            <v-card-text class="white text-xs-center text-h5">
              Data inicial
            </v-card-text>
            <v-date-picker
              v-model="dataInicio"
              :max="hoje"
              :scrollable="false"
              header-color="white"
              class="header-date-picker"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="currentDateSelector = 'dataFim'; dataFim = dataInicio;"
              >
                Próximo
              </v-btn>
            </v-date-picker>
          </v-window-item>
          <v-window-item value="dataFim">
            <v-card-text class="white text-xs-center text-h5">
              Data final
            </v-card-text>
            <v-date-picker
              v-model="dataFim"
              :min="dataInicio"
              :max="hoje"
              :scrollable="false"
              header-color="white"
              class="header-date-picker"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="currentDateSelector = 'dataInicio'"
              >
                Voltar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="showSelectDate = false; listaQualidadeCcs();"
              >
                Concluir
              </v-btn>
            </v-date-picker>
          </v-window-item>
        </v-window>
      </v-dialog>

      <v-speed-dial
        fixed
        dark
        bottom
        right
        open-on-hover
        direction="top"
        transition="slide-y-reverse-transition"
        class="mr-5"
      >
        <template #activator>
          <v-btn
            color="blue darken-2"
            dark
            large
            fab
          >
            <v-icon>menu</v-icon>
          </v-btn>
        </template>

        <v-btn
          fab
          dark
          color="green darken-1"
          @click="handleExport()"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                backup_table
              </v-icon>
            </template>
            Download (Excel)
          </v-tooltip>
        </v-btn>
      </v-speed-dial>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import XLSX from 'xlsx-js-style';
import _ from "lodash";
import colors from 'vuetify/lib/util/colors'

export default {
  name: "media-analise",

  filters: {
    dateFormat: (value, format) => moment(value).format(format)
  },

  data() {
    return {
      ccs: [],
      cbt: [],
      gordura: [],
      proteina: [],
      ureia: [],
      lactose: [],
      esd: [],
      solidos: [],
      caseina: [],

      checkExport: [
        {
          path: 'ccs',
          reportName: "CCS Geométrica "
        },
        {
          path: 'cbt',
          reportName: "CPP Geométrica "
        },
        {
          path: 'gordura',
          reportName: "Gordura Aritmética "
        },
        {
          path: 'proteina',
          reportName: "Proteína Aritmética "
        },
        {
          path: 'ureia',
          reportName: "Uréia Aritmética "
        },
        {
          path: 'lactose',
          reportName: "Lactose Aritmética "
        },
        {
          path: 'esd',
          reportName: "ESD Aritmética "
        },
        {
          path: 'solidos',
          reportName: "Sólidos Totais Aritmética "
        },
        {
          path: 'caseina',
          reportName: "Caseína Aritmética "
        },
      ],

      hoje: moment().format("YYYY-MM-DD"),
      currentDateSelector: "dataInicio",
      showSelectDate: false,
      datas: [
        { text: "Este ano", value: "este_ano" },
        { text: "Ano Anterior", value: "ano_anterior" },
        { text: "Definir data", value: "intervalo" },
      ],
      dataSelecionada: "este_ano",
      dataInicio: null,
      dataFim: null,

      showcheckBox: false,
    };
  },

  computed: {
    ccsGraph() {
      const data = this.parseReport(this.ccs);
      return this.generateGraphBarCBTorCSS('Média Geométrica CCS * (1000)', data, 500);
    },

    cbtGraph() {
      const data = this.parseReport(this.cbt);
      return this.generateGraphBarCBTorCSS('Média Geométrica CPP * (1000)', data, 300);
    },

    gorduraGraph() {
      const data = this.parseReport(this.gordura);
      return this.generateGraphBar('Média Aritmética de Gordura', data, 3);
    },

    proteinaGraph() {
      const data = this.parseReport(this.proteina);
      return this.generateGraphBar('Média Aritmética de Proteína', data, 2.9);
    },

    ureiaGraph() {
      const data = this.parseReport(this.ureia);
      return this.generateGraphBar('Média Aritmética de Uréia', data, 7);
    },

    lactoseGraph() {
      const data = this.parseReport(this.lactose);
      return this.generateGraphBar('Média Aritmética de Lactose', data, 4.3);
    },

    esdGraph() {
      const data = this.parseReport(this.esd);
      return this.generateGraphBar('Média Aritmética de ESD', data, 8.4);
    },

    solidosGraph() {
      const data = this.parseReport(this.solidos);
      return this.generateGraphBar('Média Aritmética de Sólidos Totais', data, 11.4);
    },

    caseinaGraph() {
      const data = this.parseReport(this.caseina);
      return this.generateGraphBar('Média Aritmética de Caseína', data, 2.6);
    },
  },

  mounted() {
    this.setDates();
  },

  methods: {
    generateGraphBar(title, data, meta) {
      return {
        options: {
          title: {
            text: title,
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '1%',
            right: '2%',
            top: '50px',
            bottom: '0%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            scale: true,
            position: 'top',
            min: 0,
            boundaryGap: false,
            // Caso os valores sejam menores que a meta, altera o max para exibir a meta
            ...(meta > Math.max(...data) ? { max: Math.ceil(meta) } : {})
            // max: Math.ceil(Math.max(meta, ...data)),
          },
          xAxis: {
            type: 'category',
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
          },
          series: [{
            type: 'bar',
            name: 'Média',
            data: data.map(value => ({
              value,
              itemStyle: { color: value < meta ? colors.red.darken1 : colors.blue.darken1 }
            })),
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              textShadowColor: '#000',
              textShadowBlur: 3,
            },
            markLine: {
              data: [
                {
                  name: 'Meta',
                  yAxis: meta,
                  lineStyle: { width: 2, color: colors.yellow.darken2 }
                }
              ]
            }
          }]
        }
      };
    },

    generateGraphBarCBTorCSS(title, data, meta) {
      return {
        options: {
          title: {
            text: title,
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '1%',
            right: '2%',
            top: '50px',
            bottom: '0%',
            containLabel: true
          },
          yAxis: {
            type: 'value',
            scale: true,
            position: 'top',
            min: 0,
            boundaryGap: false,
            // Caso os valores sejam menores que a meta, altera o max para exibir a meta
            ...(meta > Math.max(...data) ? { max: Math.ceil(meta) } : {})
            // max: Math.ceil(Math.max(meta, ...data)),
          },
          xAxis: {
            type: 'category',
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
          },
          series: [{
            type: 'bar',
            name: 'Média',
            data: data.map(value => ({
              value,
              itemStyle: { color: value > meta ? colors.red.darken1 : colors.blue.darken1 }
            })),
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              textShadowColor: '#000',
              textShadowBlur: 3,
            },
            markLine: {
              data: [
                {
                  name: 'Meta',
                  yAxis: meta,
                  lineStyle: { width: 2, color: colors.yellow.darken2 }
                }
              ]
            }
          }]
        }
      };
    },

    parseReport(data) {
      return [
        Math.abs(data.jan).toFixed(2),
        Math.abs(data.fev).toFixed(2),
        Math.abs(data.mar).toFixed(2),
        Math.abs(data.abr).toFixed(2),
        Math.abs(data.mai).toFixed(2),
        Math.abs(data.jun).toFixed(2),
        Math.abs(data.jul).toFixed(2),
        Math.abs(data.ago).toFixed(2),
        Math.abs(data.set).toFixed(2),
        Math.abs(data.out).toFixed(2),
        Math.abs(data.nov).toFixed(2),
        Math.abs(data.dez).toFixed(2),
      ]
    },

    cancelSelectDate() {
      this.showSelectDate = false;
      this.dataSelecionada = "hoje";
    },

    setDates() {
      switch (this.dataSelecionada) {
        case "este_ano":
          this.dataInicio = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
          this.dataFim = moment()
            .endOf("year")
            .format("YYYY-MM-DD");
          break;
        case "ano_anterior":
          this.dataInicio = moment()
            .subtract(1, "year")
            .format("YYYY-01-01");
          this.dataFim = moment()
            .subtract(1, "year")
            .format("YYYY-12-31");
          break;
        case "intervalo":
          this.currentDateSelector = "dataInicio";
          this.dataInicio = moment().format("YYYY-MM-DD");
          this.dataFim = null;
          this.showSelectDate = true;
          break;
      }
      this.listaQualidadeCcs();
      this.listaQualidadeCbt();
      this.listaQualidadeGordura();
      this.listaQualidadeProteina();
      this.listaQualidadeUreia();
      this.listaQualidadeLactose();
      this.listaQualidadeEsd();
      this.listaQualidadeSolidos();
      this.listaQualidadeCaseina();
    },

    async listaQualidadeCcs() {
      if (!this.dataInicio || !this.dataFim) {
        this.qualidade = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/Mediaccs`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        const [ccs] = data.ccsMedia;
        this.ccs = ccs;
      } catch (error) {
        console.log(error);
      }
    },

    async listaQualidadeCbt() {
      if (!this.dataInicio || !this.dataFim) {
        this.cbt = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/Mediacbt`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        const [cbt] = data.cbtMedia;
        this.cbt = cbt;
      } catch (error) {
        console.log(error);
      }
    },

    async listaQualidadeGordura() {
      if (!this.dataInicio || !this.dataFim) {
        this.gordura = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/Mediagordura`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        const [gordura] = data.gorduraMedia;
        this.gordura = gordura;
      } catch (error) {
        console.log(error);
      }
    },

    async listaQualidadeProteina() {
      if (!this.dataInicio || !this.dataFim) {
        this.proteina = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/Mediaproteina`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        const [proteina] = data.proteinaMedia;
        this.proteina = proteina;
      } catch (error) {
        console.log(error);
      }
    },

    async listaQualidadeUreia() {
      if (!this.dataInicio || !this.dataFim) {
        this.ureia = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/Mediaureia`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        const [ureia] = data.ureiaMedia;
        this.ureia = ureia;
      } catch (error) {
        console.log(error);
      }
    },

    async listaQualidadeLactose() {
      if (!this.dataInicio || !this.dataFim) {
        this.lactose = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/Medialactose`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        const [lactose] = data.lactoseMedia;
        this.lactose = lactose;
      } catch (error) {
        console.log(error);
      }
    },

    async listaQualidadeEsd() {
      if (!this.dataInicio || !this.dataFim) {
        this.esd = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/Mediaesd`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        const [esd] = data.esdMedia;
        this.esd = esd;
      } catch (error) {
        console.log(error);
      }
    },

    async listaQualidadeSolidos() {
      if (!this.dataInicio || !this.dataFim) {
        this.solidos = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/solidosTotais`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        const [solidos] = data.solidosTotaisMedia;
        this.solidos = solidos;
      } catch (error) {
        console.log(error);
      }
    },

    async listaQualidadeCaseina() {
      if (!this.dataInicio || !this.dataFim) {
        this.caseina = [];
        return;
      }

      try {
        const { data } = await this.$axios.post(
          `/relatorioIn/caseina`,
          this.$qs.stringify({
            data_ini: moment(this.dataInicio).format('DD/MM/YYYY'),
            data_fim: moment(this.dataFim).format('DD/MM/YYYY'),
          })
        );

        this.caseina = data;
      } catch (error) {
        console.log(error);
      }
    },

    handleExport() {
      let exportData = this.checkExport.map(item => {
        let data = _.get(this, item.path);
        return {
          Gráfico: item.reportName,
          ...data,
        };
      })

      this.exportExcel(exportData, 'media_qualidade');
    },

    exportExcel(itemList, fileName) {
      const data = XLSX.utils.json_to_sheet(itemList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, data, fileName);
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    },
  },

};
</script>
