<template>
  <div>
    <v-card
      class="v-tamanho collecton-city"
      color="transparent"
    >
      <v-card-title style="padding-bottom: 0px">
        <v-row class="pa-0">
          <v-col
            cols="4"
            class="pa-1"
          >
            <date-range-picker
              v-model="filters.date.input"
              @change="onDateFilter"
            />
          </v-col>
          <v-col
            cols="4"
            class="pa-1"
          >
            <routes-autocomplete-filter
              v-model="filters.route"
              dark
              label="Rota"
              @change="onFilterRoute"
            />
          </v-col>
          <v-col
            cols="4"
            class="pa-1"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              clearable
              filled
              dark
              @change="onChangeSearch"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="[
          { align: 'start', text: 'Código', value: 'codigo_produtor'},
          { text: 'Produtor', value: 'nome_produtor'},
          { text: 'Volume (Lts)', value: 'quantidade_coleta', align: 'end'},
        ]"
        :items="filteredItems"
        :expanded="expanded"
        class="elevation-1 bonus-list-table"
        :page.sync="pagination.page"
        item-key="nome_produtor"
        :show-expand="showExpand"
        :single-expand="singleExpand"
        darkd
      >
        <template v-slot:expanded-item="{ item }">
          <!--Slot para expand da table-->
          <slot
            name="expanded"
            :item="item"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="#556B2F"
        @click="exportExpanded()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download {{ expandedName }} (Excel)
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download Volume Rejeitado (Excel)
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import * as _ from "lodash";
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    DateRangePicker,
    RoutesAutocompleteFilter,
  },

  mixins: [ReportMixin],

  props: {
    showExpand: {
      type: Boolean,
      default: true,
    },
    singleExpand: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Array,
      default: () => [],
    },
    expandedName: {
      type: String,
      default: "Detalhado",
    },
  },

  data() {
    return {
      filters: {
        date: {
          input: "today",
          range: [],
        },
        search: null,
      },

      route: {},

      loading: true,

      list: [],

      pagination: {
        page: 1,
      },

    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.list;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.list.filter(item => {
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return searchFilter
      });
    },
  },

  mounted() {
    this.loadVolumeList();
  },

  methods: {
    async loadVolumeList() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/produtoresRejeitados`,
          this.$qs.stringify({
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.first(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            id_rota: this.filters.route ? this.filters.route.id : null,
          })
        );

        this.list = data;
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    onFilterRoute() {
      this.loadVolumeList();

      this.$emit("onRoute", this.filters.route);
    },

    onDateFilter(event) {
      this.filters.date.range = event;

      this.$emit("onDate", event);

      this.loadVolumeList();
    },

    exportExcel() {
      let data = XLSX.utils.json_to_sheet(
        this.filteredItems.map((item) => {
          return {
            Codigo_Produtor: item.codigo_produtor,
            Produtor: item.nome_produtor,
            Volume_lts: item.quantidade_coleta,
          };
        }),
        { header: ["Codigo_Produtor", "Produtor", "Volume_lts"] }
      );

      const workbook = XLSX.utils.book_new();

      const filename = "volume_rejeitado";

      data['!cols'] = [
        { wch: 15 },
        { wch: 50 },
        { wch: 15 },
      ];

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    exportExpanded() {
      this.$emit('onExport');
    },

    getProducerJson(producer) {
      return [{
        'Código': producer.codigo_produtor,
        'Produtor': producer.nome_produtor,
        'Volume (Lts)': producer.quantidade_coleta
      }];
    },

    getVolumeJson(id_produtor) {
      var volume = [];

      this.expanded.forEach(e => {
        if(e.id_produtor == id_produtor) {
          volume.push({
            'Data': e.data,
            'Volume (Lts)': e.quantidade_coleta,
            'Alizarol': e.alizarol,
            'Temperatura': e.temperatura
          });
        }
      });

      return volume;
    },

    print() {
      const configProducer = [
        'style = "width: 15%; background-color: #dddddd !important"',
        'style = "width: 70%; background-color: #dddddd !important"' ,
        'style = "width: 15%; background-color: #dddddd !important"' ,
      ];

      const configVolume = [
        'style = "width: 25%; background-color: #dddddd !important"',
        'style = "width: 25%; background-color: #dddddd !important"' ,
        'style = "width: 25%; background-color: #dddddd !important"',
        'style = "width: 25%; background-color: #dddddd !important"',
      ];

      var html = [];
      this.filteredItems.forEach(e => {
        html += `
          <div style="border: 1px solid #dddddd; margin-bottom: 15px;">
            ${ this.htmlTableFromJson(this.getProducerJson(e), null, configProducer) }
              <div style="padding: 10px">
                ${ this.htmlTableFromJson(this.getVolumeJson(e.id_produtor), null, configVolume) }
              </div>
          </div>`;
      });

      var range  =  `${moment(_.first(this.filters.date.range)).format("DD/MM/YYYY")} - ${moment(_.last(this.filters.date.range)).format("DD/MM/YYYY")}`;
      this.printReport(html, 'Relatório volume rejeitado', moment().format("DD/MM/YYYY HH:mm:ss"), range);
    },

    onChangeSearch() {
      this.$emit('onChangeSearch', this.filters.search);
    }
  },
};
</script>
