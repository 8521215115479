
import { getCurrentInstance } from 'vue'

export const useUtils = () => {
  const app = getCurrentInstance();

  return {
    confirm: app.proxy.$root.$confirm,
    progressBar: app.proxy.$root.$progressBar,
    notify: app.proxy.$snotify,
  }
}
