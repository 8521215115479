<template>
  <v-dialog
    v-model="dialog.show"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Log de Alterações
      </v-card-title>

      <v-card-text>
        <v-container style="max-width: 600px;">
          <v-timeline
            dense
            clipped
          >
            <v-timeline-item
              v-for="(log, idx) in logs"
              :key="idx"
              :color="getItemColor(log)"
              :small="isItemSmall(log)"
            >
              <v-row justify="space-between">
                <v-col cols="9">
                  {{ getItemMessage(log) }}
                  <br>
                  <div
                    v-if="['VOLUME', 'PRODUTOR', 'DATA', 'TEMPERATURA', 'AMOSTRA', 'ALIZAROL'].includes(log.type)"
                    class="d-flex"
                  >
                    <b>{{ getItemChange(log, 'previous') }}</b>
                    <v-icon
                      small
                      class="mx-2 mb-1"
                      color="orange"
                    >
                      keyboard_double_arrow_right
                    </v-icon>
                    <b>{{ getItemChange(log, 'new') }}</b>
                  </div>
                  <div v-else-if="log.type === 'ORIGINAL'">
                    <b>{{ log.originalVol }}L</b>
                  </div>
                  <template v-if="log.userName">
                    Usuário: {{ log.userName }}
                  </template>
                  <template v-if="log.lastEdit">
                    Última alteração feita no conjunto de itinerário por <b>{{ log.lastEdit.userName }}</b> em <b>{{ formatDate(log.lastEdit.updatedAt, 'DD/MM HH:mm:ss') }}</b>
                  </template>
                </v-col>
                <v-col
                  v-if="log.updatedAt"
                  class="text-right"
                  cols="3"
                >
                  {{ formatDate(log.updatedAt, 'DD/MM HH:mm:ss') }}
                </v-col>
              </v-row>

              <template #icon>
                <v-icon
                  color="white"
                  small
                  v-html="getItemIcon(log)"
                />
              </template>
            </v-timeline-item>
          </v-timeline>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';

export default {

  data() {
    return {
      loading: false,

      dialog: {
        show: false,
      },

      logs: [],
    }
  },

  methods: {

    async show(collect) {
      this.dialog.show = true;

      this.logs = [...collect.logs.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))];

      this.logs.push({
        type: 'ORIGINAL',
        originalVol: collect.originalVol,
        lastEdit: collect.logs.length === 0 ? collect.lastEdit : null,
      })
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },

    getItemColor(log) {
      switch (log.type) {
        case 'VOLUME':
          return 'deep-purple';
        case 'PRODUTOR':
          return 'orange';
        case 'DATA':
          return 'teal';
        case 'TEMPERATURA':
          return 'blue';
        case 'AMOSTRA':
          return 'cyan';
        case 'ALIZAROL':
          return 'blue-grey';
        default:
          return 'grey';
      }
    },

    isItemSmall(log) {
      return !['VOLUME', 'PRODUTOR', 'DATA', 'TEMPERATURA', 'AMOSTRA', 'ALIZAROL'].includes(log.type)
    },

    getItemIcon(log) {
      switch (log.type) {
        case 'VOLUME':
          return 'water_drop';
        case 'PRODUTOR':
          return 'person';
        case 'DATA':
          return 'event';
        case 'TEMPERATURA':
          return 'ac_unit';
        case 'AMOSTRA':
          return 'qr_code';
        case 'ALIZAROL':
          return 'biotech';
        default:
          return '';
      }
    },

    getItemMessage(log) {
      switch (log.type) {
        case 'VOLUME':
          return 'Volume';
        case 'PRODUTOR':
          return 'Produtor';
        case 'DATA':
          return 'Data de Coleta';
        case 'TEMPERATURA':
          return 'Temperatura';
        case 'AMOSTRA':
          return 'Amostra';
        case 'ALIZAROL':
          return 'Alizarol';
        case 'ORIGINAL':
          return 'Volume original';
        default:
          return log.type;
      }
    },

    getItemChange(log, field) {
      const types = {
        'VOLUME': 'quantidade_coleta',
        'PRODUTOR': 'nome_produtor',
        'DATA': 'data',
        'TEMPERATURA': 'temperatura',
        'AMOSTRA': 'numero_amostra',
        'ALIZAROL': 'alizarol',
      }

      const slug = types[log.type]

      const value = log?.[field]?.[slug];

      if (log.type === 'VOLUME') {
        return `${value}L`;
      }

      if (log.type === 'DATA') {
        return moment(value).format('DD/MM/YYYY');
      }

      return value;
    },
  },

}
</script>

