<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-card dark>
          <v-card-text class="pa-0">
            <route-map
              ref="map"
              :center="$store.state.settings.coordenadas"
              :value="routes"
              :markers="markers"
              use-simple-marker
              @onRouteTraced="onRouteTraced"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="4"
        md="3"
        lg="2"
      >
        <route-persons-form-item
          :route="{ id: 1, description: 'Pendentes' }"
          :customers.sync="pendingDeliveries"
          title="Entregas pendentes"
          disable-delete
        />
      </v-col>

      <v-col
        ref="routes-container"
        v-resize="onResize"
        cols="8"
        md="9"
        lg="10"
      >
        <div
          :style="{overflow: 'auto'}"
          class="pb-15"
        >
          <div
            :style="{width: containerWidth + 'px'}"
          >
            <div
              v-for="(route, idx) in routes"
              :key="route.id"
              :style="{width: routeWidth + 'px !important', float: 'left'}"
              :class="idx < (routes.length - 1) ? 'mr-5' : ''"
            >
              <route-resume
                :customers="route.mapPersons"
                :stats="route.stats"
                :params="route.params"
                :equipments="route.equipments"
                class="mb-3"
              >
                <template slot="title">
                  <v-avatar
                    :color="route.color"
                    size="25"
                    class="mr-3"
                  />
                  {{ route.description }}
                </template>

                <template slot="actions">
                  <v-btn
                    icon
                    @click="editRoute(route)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
              </route-resume>
              <route-persons-form-item
                :route="route"
                :customers.sync="route.mapPersons"
                @onRemoveCustomer="onRemoveCustomer"
                @onCustomerPositionChange="onCustomerPositionChange"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="routeDialog.show"
      width="600"
      class="rota-parametros"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Rota de entrega</span>
        </v-card-title>

        <v-divider />

        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="routeDialog.form.description"
                  prepend-inner-icon="compare"
                  label="Nome da rota"
                  class="rota-field"
                  placeholder="Digite o nome da rota"
                  filled
                  hide-details
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
              <v-col cols="12">
                <person-autocomplete-filter
                  v-model="routeDialog.form.driver"
                  label="Motorista"
                  type="DRIVER"
                />
              </v-col>
              <v-col cols="12">
                <vehicle-autocomplete-filter
                  v-model="routeDialog.form.vehicle"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey"
            outlined
            @click="routeDialog.show = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="saveRouteInfo"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green"
            v-on="on"
            @click="saveRoutes('PUBLISH')"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </template>

        <span>Salvar e publicar</span>
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            v-on="on"
            @click="saveRoutes('SAVE')"
          >
            <v-icon>receipt_long</v-icon>
          </v-btn>
        </template>

        <span>Salvar sem publicar</span>
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue darken-1"
            v-on="on"
            @click="addRoute"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </template>

        <span>Adicionar rota</span>
      </v-tooltip>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import * as _ from "lodash";

// import RouteService from "@/Domains/Routes/Services/RouteService.js";
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import RouteResume from "@/Domains/Commercial/Routes/Components/Form/RouteResume.vue";
import RoutePersonsFormItem from "@/Domains/Commercial/Delivery/Components/RoutePersonsFormItem.vue";
import VehicleAutocompleteFilter from "@/Support/Components/Filters/VehicleAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

// const service = new RouteService();

const colors = [
  '#FF5722',
  '#512DA8',
  '#8BC34A',
  '#FFA000',
];

export default {
  components: {
    RouteMap,
    RouteResume,
    RoutePersonsFormItem,
    VehicleAutocompleteFilter,
    PersonAutocompleteFilter,
  },

  data() {
    return {
      // Loader
      loading: false,

      pendingDeliveries: [],

      // Lista de rotas
      routes: [],

      routeDialog: {
        show: false,
        form: {}
      },

      containerParentWidth: 1,
    };
  },

  computed: {
    routeWidth() {
      const capacityWidth = this.containerParentWidth / this.routes.length;
      return capacityWidth > 340 ? Math.trunc(capacityWidth - 23) : 320
    },

    containerWidth() {
      return Math.max(this.containerParentWidth - 5, this.routes.length * 340) - 20;
    },

    markers() {
      return this.pendingDeliveries.filter(o => o.hasLocation).map((o, idx) => ({
        label: idx + 1,
        ...o
      }));
    }
  },

  async mounted() {
    await this.loadPendingDeliveries();
    await this.loadRoutes();

    this.$nextTick(function () {
      this.$refs.map.trace();
    });

    setTimeout(() => {
      this.onResize();
    }, 200)
  },

  methods: {
    async loadPendingDeliveries() {
      try {
        const { data } = await this.$axios.post(`/movimento/entregasPendentes`);

        this.pendingDeliveries = _(data)
          .groupBy('id_cliente')
          .map((items, id_cliente) => {
            const [first] = items;
            return {
              id: id_cliente,
              name: first.nome_cliente,
              draggable: false,
              visible: true,
              color: 'grey',
              region: {
                id: first.id_regiao,
                description: first.regiao,
              },
              address: {
                city: first.end_cidade || '',
                state: first.end_estado || '',
                street: first.end_rua || '',
                number: first.end_numero || '',
                neighborhood: first.end_bairro || '',
              },
              location: {
                lat: parseFloat(first.end_latitude),
                lng: parseFloat(first.end_longitude),
              },
              hasLocation:
                !_.isEmpty(first.end_latitude) &&
                !_.isEmpty(first.end_longitude),
              richMeta: {
                Cliente: first.nome_cliente,
              },
              sales: items.map(sale => ({
                id: sale.id_movimento,
                code: sale.cod_movimento,
                type: sale.tipo,
                status: sale.status,
                price: sale.valor,
                date: sale.data_hora_cadastro,
              }))
            }
          }).value();

      } catch (err) {
        console.warn(err);
      }
    },

    async loadRoutes() {
      try {
        this.loading = true;

        for (let i = 0; i < 2; i++) {
          this.addRoute();
        }

      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    addRoute() {
      const id = this.routes.length + 1;
      this.routes.push({
        id: id, // @todo uuid
        description: `Entrega ${id}`,
        color: colors[this.routes.length % colors.length],
        published: false,
        stats: { distance: null, duration: null },
        params: {
          routeStartPosition: { lat: null, lng: null },
          routeEndPosition: { lat: null, lng: null },
        },
        driver: {},
        vehicle: {},
        mapPersons: [],
      })
    },

    /**
     * Cria/Edita uma rota
     */
    async saveRoutes(action) {
      try {
        this.loading = true;

        console.log('todo', { action });

        // const published = action !== 'SAVE';

        // await Promise.all(
        //   this.routes.map(async route => {
        //     return await service
        //       .fromSystem(this.$store.state.settings.sistema)
        //       .saveRoute({
        //         ...route,
        //         published,
        //       });
        //   })
        // );

        this.$emit("onRoutesSaved");
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar a rota!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    onRouteTraced(event) {
      this.routes = this.routes.map(route => {
        if (route.id !== event.route.id) {
          return route;
        }

        return {
          ...route,
          stats: {
            duration: event.duration,
            distance: event.distance,
          },
          params: {
            ...route.params,
            routePolyline: event.polyline,
          },
        };
      });
    },

    onRemoveCustomer(customer) {
      this.pendingDeliveries.push(customer);
      this.onCustomerPositionChange();
    },

    onCustomerPositionChange: _.debounce(async function () {
      this.routes = this.routes.map(route => {
        return {
          ...route,
          params: {
            ...route.params,
            routePolyline: null,
          },
        };
      });

      this.$refs.map.clearRoute();

      return this.$nextTick(async function() {
        return this.$refs.map.trace();
      });
    }, 500),

    editRoute(route) {
      this.routeDialog.show = true;
      this.routeDialog.form = { ...route };
    },

    saveRouteInfo() {
      const routeId = this.routeDialog.form.id;
      const idx = this.routes.findIndex(r => r.id === routeId);
      this.routeDialog.show = false;
      this.routes[idx] = {
        ...this.routes[idx],
        ...this.routeDialog.form
      }
      this.routeDialog.form = {};
    },

    onResize() {
      const { clientWidth } = this.$refs['routes-container'];
      this.containerParentWidth = clientWidth;
    }
  },
};
</script>
