<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="filters.search"
        dark
        dense
        @click:row="show"
      >
        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="show(item)">
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.id !== 'DEFAULT'"
                @click="remove(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="add()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Novo documento</span>
    </v-tooltip>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,

      filters: {
        search: null
      },

      headers: [
        { text: 'Ttulo', value: 'titulo' },
        { text: 'Tipo', value: 'tipo' },
        { text: '', value: 'action', width: 30, sortable: false }
      ],

      items: [],
    }
  },

  created() {
    this.load()
  },

  methods: {
    show({ id }) {
      this.$emit("show", id);
    },

    /**
     * @event void
     *
     * Evento de criação de um novo documento
     */
    add() {
      return this.$emit('add')
    },

    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/registrations/document`);

        this.items = data

      } catch (error) {
        this.$snotify.error("Erro ao carregar os documentos", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover documento', 'Tem certeza que deseja remover este documento?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        await this.$axios.delete(`/registrations/document/${id}`);

        this.items.splice(this.items.findIndex(item => item.id === id), 1);
        this.$snotify.success("Documento removido com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error(`Erro ao remover o documento. ${error}`, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>