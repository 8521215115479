<template>
  <v-form
    ref="form"
    class="quality-settings"
  >
    <v-row class="justify-center">
      <v-col
        v-if="items.length > 0"
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="itemSelected"
          :items="items"
          dark
          hide-selected
          hide-details
          label="Inserir item"
          prepend-inner-icon="search"
          filled
          return-object
          @change="addItem"
        />
      </v-col>
    </v-row>
    <v-row>
      <template
        v-for="(range, idx) of ranges"
      >
        <v-col
          v-if="range.list.length > 0"
          :key="idx"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-card
            class="graph-card"
            color="transparent"
          >
            <v-card-title>
              <v-tooltip top>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ range.name | ellipse(11) }}
                  </span>
                </template>
                {{ range.name }}
              </v-tooltip>
              <v-spacer />
              <v-btn
                icon
                color="red"
              >
                <v-icon
                  @click="changeRange(idx, 'remove')"
                >
                  remove
                </v-icon>
              </v-btn>
              <v-btn
                icon
                color="blue"
              >
                <v-icon
                  @click="changeRange(idx, 'add')"
                >
                  add
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="px-0">
              <v-list
                dark
                color="transparent"
                class="pt-0"
              >
                <v-radio-group v-model="range.limit">
                  <v-list-item
                    v-for="(item, i) in range.list"
                    :key="`${idx}.${i}`"
                  >
                    <v-list-item-action class="mr-1 mt-4 mb-0">
                      {{ (i == range.list.length - 1) ? '>' : 'Até' }}
                    </v-list-item-action>
                    <v-list-item-title class="px-1 py-1">
                      <v-text-field
                        v-model="item.value"
                        type="number"
                        label="Valor"
                        hide-selected
                        hide-details
                        :rules="getRules(range.list, i)"
                        @keypress="disableDot"
                      />
                    </v-list-item-title>
                    <v-list-item-icon class="ml-1 mt-6 mb-0">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-radio
                            :value="i"
                            v-on="on"
                          />
                        </template>
                        Limite
                      </v-tooltip>
                    </v-list-item-icon>
                  </v-list-item>
                </v-radio-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="save();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>
  </v-form>
</template>

<style lang="scss">
.quality-settings {
  .graph-card {
    margin-top: 5px !important;
    background: rgba(0, 0, 0, 0.5) !important;

    > .v-card__title {
      color: #fff;
      background: unset;
    }

    > .v-card__text {
      color: #fff !important;
    }
  }
  .report-card {
    > .v-card__title {
      color: #fff;
    }
  }
}
</style>
<script>
import QualityMixins from "@/Domains/Quality/Mixins/QualityMixins.js";

export default {
  name: 'quality-settings',

  filters: {
    ellipse(string, length) {
      return string.length > length ? string.substring(0, length) + "..." : string;
    },
  },

  mixins: [QualityMixins],

  data() {
    return {
      valid: true,
      ranges: [],
      entries: [],
      itemSelected: ''
    }
  },

  computed: {
    items() {
      const ranges = this.ranges.filter(({ list }) => list.length > 0).map(({ item }) => item);
      return this.entries.filter((item) => !ranges.includes(item.value));
    },
  },

  created() {
    this.loadRanges();
  },

  methods: {

    addItem({ value }) {
      const idx = this.ranges.findIndex(({ item }) => item == value);
      this.ranges[idx].list.push({
        quantity: null, price: null
      })
      setTimeout(() => {
        this.itemSelected = "";
      }, 10);
    },

    changeRange(idx, type) {
      if (type == 'add') {
        this.ranges[idx].list.push({ value: 0 });
      }
      if (type == 'remove') {
        if (this.ranges[idx].limit === this.ranges[idx].list.length - 1 && this.ranges[idx].list.length > 1) {
          this.ranges[idx].limit--;
        }
        this.ranges[idx].list.splice(-1, 1);
      }
    },

    disableDot (e) {
      if (e.charCode === 46) {
        e.preventDefault()
      }
    },

    getRules(items, idx) {
      return [
        v => !!v || 'Informe o valor',
        v => idx == 0 || parseFloat(v) > parseFloat(items[idx - 1].value) || 'Valor deve ser maior que o anterior',
        v => idx == items.length - 1 || parseFloat(v) < parseFloat(items[idx + 1].value) || 'Valor deve ser menor que o próximo',
      ]
    },

    async loadRanges() {

      try {
        this.loading = true;

        this.ranges = Object.values(await this.getRanges());
        
        this.entries = [...this.ranges].map((item) => ({ value: item.item, text: item.name }));

      } catch (error) {
        this.ranges = [];
        this.$snotify.error("Erro ao carregar as configurações", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.loading = true;
        let { data } = await this.$axios.post(
          `/configuracao/salvaFaixasQualidade`,
          {
            tabelas: this.ranges.map(item => ({
              id: item.id,
              item: item.item,
              limite: item.limit,
              faixas: item.list.map(({ value }) => value)
            }))
          }
        );
        
        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }
        else {
          data.ids.forEach((id, idx) => {
            this.ranges[idx].id = id;
          });
        }
        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar configurações", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
