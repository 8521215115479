<template>
  <v-dialog
    v-model="show"
    width="650"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>Curva ABC e Metas</v-card-title>
      <v-card-text class="pt-6">
        <v-form
          ref="form"
          lazy-validation
        >
          <abc-goal-card
            v-for="(item, idx) in labels"
            :key="idx"
            v-model="item.label"
            @input="$refs.form.validate()"
          />
        </v-form>
      </v-card-text>
      <v-divider class="ma-0" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="pa-1"
          text
          color="blue-grey darken-1"
          dark
          @click="show = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="pa-1"
          text
          color="green darken-2 "
          dark
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </v-dialog>
</template>

<script>
import AbcGoalCard from "@/Domains/Questionnaires/Components/AbcGoalCard.vue"

import _ from "lodash";

export default {

  components: {
    AbcGoalCard
  },

  props: {
    value: Boolean,
    questionnaireId: String,
  },
  
  data() {
    return {
      loading: false,
      
      labels: [],
    };
  },
  computed: {
    
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    }
  },

  watch: {
    value(value) {
      value && this.loadQuestionnaire();
    }
  },

  methods: {
    /**
     * Carrega informações do questionário
     */
    async loadQuestionnaire() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioId`,
          this.$qs.stringify({
            id_formulario: this.questionnaireId
          })
        );
        
        if (!_.isObject(data)) {
          throw new Error(data);
        }

        const [questionnaire] = data;

        let labels = this.getLabels(questionnaire);

        if (labels.length === 0) {
          this.$snotify.info("Não foi encontrado questões de múltipla escolha.", "Atenção");
          this.show = false;
        }

        this.labels = labels;

        this.$emit("labels", this.labels);
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as configurações!", "Atenção");
        this.show = false;
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    getLabels(questionario) {
      let labels = [...new Set(questionario.formulario.filter(o => o.tipo === 'CHECKBOX' && o.valores).flatMap(o => o.valores))];

      if (labels.length === 0) {
        return [];
      }

      labels = labels.map(name => ({
        label: {
          name,
          enabled: false,
          ranges: [
            { value: 30, visits: null },
            { value: 65, visits: null },
            { value: 100, visits: null }
          ],
        }
      }));

      if (questionario.curva_abc) {
        Object.keys(questionario.curva_abc).forEach(name => {
          const idx = labels.findIndex(({ label }) => label.name === name);
          if (idx > -1) {
            labels[idx] = {
              label: {
                name,
                enabled: true,
                ranges: questionario.curva_abc[name].map(item => ({
                  value: item.faixa,
                  visits: item.meta_visitas
                })),
              }
            }
          }
        })
      }

      return labels;
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/formularioCustomizado/salvaConfiguracaoABC`, {
          id_questionario: this.questionnaireId,
          curva_abc: this.labels
            .filter(({ label }) => label.enabled)
            .reduce((acc, { label }) => ({
              ...acc,
              [label.name]: label.ranges
                .map(item => ({
                  faixa: parseFloat(item.value),
                  meta_visitas: parseFloat(item.visits) || null
                }))
            }), {})
        });
        
        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Configuração salva com sucesso!", "Sucesso");
        this.show = false;
        this.$emit("labels", this.labels);
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
      
    }
  },
};
</script>
