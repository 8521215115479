<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="filter.project"
            :items="projectList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="filter.project.protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
      </v-row>
      <v-card
        class="graph-card mb-12"
        color="transparent"
        dark
      >
        <v-card-title>
          PAE: Qualidade
        </v-card-title>
        <v-card-text>
          <average-graphic
            v-for="q in quality"
            :key="q.indicador"
            :goal="q"
            :filter="filter"
          />
        </v-card-text>
      </v-card>
      <v-card
        class="graph-card mb-12"
        color="transparent"
        dark
      >
        <v-card-title>
          PAE: Emergenciais
        </v-card-title>
        <v-card-text>
          <emergency-graphic
            :filter="filter"
          />
        </v-card-text>
      </v-card>
      <v-card
        class="graph-card mb-12"
        color="transparent"
        dark
      >
        <v-card-title>
          Produtores
        </v-card-title>
        <producersStatus
          :filter="filter"
          @onLoadProducer="onLoadProducer"
        />
      </v-card>
      <v-card
        class="graph-card mb-12"
        color="transparent"
        dark
      >
        <v-card-title>
          Checklists Aplicados
        </v-card-title>
        <v-card-text>
          <questionnaire-graphic
            v-for="q in questionnairesGoal"
            :key="q.id_formulario"
            :goal="q"
            :filter="filter"
            @onLoadChecklist="onLoadChecklist"
          />
        </v-card-text>
      </v-card>
      <v-card
        class="graph-card mb-12"
        color="transparent"
        dark
      >
        <v-card-title>
          Meta dos Checklists
        </v-card-title>
        <v-card-text>
          <questionnaire-goal-graphic
            v-for="q in typeChecklist"
            :key="q.id_formulario"
            :checklist="q"
            :producers="producers"
          />
        </v-card-text>
      </v-card>
      <v-card
        class="graph-card mb-12"
        color="transparent"
        dark
      >
        <v-card-title>
          BPA: curva ABC
        </v-card-title>
        <bpa-graphic
          v-for="r in ranges"
          :key="r.id_meta_bpa"
          :range="r"
          :filter="filter"
        />
      </v-card>
    </v-flex>
    <v-overlay
      v-model="loading"
    >
      Carregando projeto...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>
<script>

import AverageGraphic from "@/Domains/Projects/PQFL/PAE/Components/AverageGraphic.vue";
import EmergencyGraphic from "@/Domains/Projects/PQFL/Components/EmergencyGraphic.vue";
import QuestionnaireGraphic from "@/Domains/Projects/PQFL/Components/QuestionnaireGraphic.vue";
import QuestionnaireGoalGraphic from "@/Domains/Projects/PQFL/Components/QuestionnaireGoalGraphic.vue";
import ProducersStatus from "@/Domains/Projects/PQFL/Components/ProducersStatus.vue";
import BpaGraphic from "@/Domains/Projects/PQFL/Components/BpaGraphic.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  components: {
    AverageGraphic,
    EmergencyGraphic,
    QuestionnaireGraphic,
    QuestionnaireGoalGraphic,
    BpaGraphic,
    ProducersStatus,
  },

  mixins: [ReportMixin],

  props: ["parent"],

  data() {
    return {
      loading: false,
      // Dialogo utilizado para a impressão das visitas

      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
        },
        range: [],
      },
      
      projectList: [],

      //metas de PAE qualidade
      quality: [],

      //metas de questionario
      questionnairesGoal: [],

      //metas de BPA
      ranges: [],

      //dados do checklist para montar grafico de metas do checklist
      typeChecklist: [],

      //quantidade de produtores ativos/desvinculados/suspensos mes a mes para usar no grafico de metas de checklist
      producers:[],
     
    };
  },

  created() {
    this.loadProject();
  },

  methods: {

    async loadProject() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL"
          }
        );

        this.projectList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            quality: proj.qualidade ? JSON.parse(proj.qualidade) : [],
            protocol: proj.protocolo,
            emergency: proj.emergencial,
            questionnaire: proj.questionario,
            id: proj.id_assistencia,
          }
        });
        
        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          this.filter.range = [moment(this.projectList[0].dateStart).format('YYYY-MM'), moment(this.projectList[0].dateEnd).format('YYYY-MM')];
          
          this.quality = (this.quality.lenght > 0) ? this.quality : this.filter.project.quality ;
          this.questionnairesGoal = (this.questionnairesGoal.lenght > 0) ? this.questionnairesGoal : JSON.parse(this.filter.project.questionnaire) ;

          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    //faixas das metas de BPA
    async loadRange() {
      
      this.ranges = [];
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/rangesBpa`,
          {
            id_assistencia: this.filter.project.id,
            visitas: true, //somente metas com visitas definidadas
          }
        );
        
        this.ranges = data.map(d => {
          return {
            indicador: d.indicador,
            nome_faixa: d.nome_faixa,
            id_meta_bpa: d.id_meta_bpa,
            tipo: d.tipo,
            inicio_faixa: d.inicio_faixa,
            fim_faixa: d.fim_faixa,
            visitas: d.visitas,
            periodo: d.periodo,
            metas_agrupadas: d.metas_agrupadas,
            questionarios_aplicar: d.questionarios_aplicar
          }
        });
        
        if (!_.isObject(data)) {
          throw data;
        }
        
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar faixas!", "Atenção");
        console.warn(err);

      } finally {
        this.loading = false;
      }
    },

    async onLoadChecklist(data) {
      this.typeChecklist = _.concat(data);
    },

    async onLoadProducer(data) {
      this.producers = data;
    }

  },
};
</script>