<template>
  <div class="mx-6 mb-6">
    <questions-card
      :label="questionnaireName"
    >
      <v-row>
        <v-col
          cols="12"
          class="py-0 white--text"
        >
          <h2>
            {{ filters.answered.date.range[0] ? formatDate(filters.answered.date.range[0], 'DD/MM/YYYY') + ' a ': '' }}
            {{ filters.answered.date.range[1] ? formatDate(filters.answered.date.range[1], 'DD/MM/YYYY'): '' }}
          </h2>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <v-card
            class="producer-list"
            color="transparent"
          >
            <v-card-title class="pb-0">
              <v-row
                class="pa-0"
                align-content="center"
              >
                <v-col
                  md="2"
                  class="pb-0"
                >
                  <date-range-picker
                    v-model="filters.answered.date.input"
                    @change="onDateFilter"
                  />
                </v-col>
                <v-col
                  md="2"
                  class="pb-0"
                >
                  <routes-autocomplete-filter
                    v-model="filters.answered.routes"
                    label="Rota"
                    type="visita"
                    placeholder=" "
                    small-chips
                    multiple
                    dark
                  />
                </v-col>
                <v-col
                  md="2"
                  class="pb-0"
                >
                  <person-autocomplete-filter
                    v-model="filters.answered.technical"
                    label="Técnico"
                    type="TECHNICAL"
                    dark
                    @change="loadProducerwithAnswer"
                  />
                </v-col>
                <v-col
                  md="2"
                  class="pb-0"
                >
                  <v-select
                    v-model="producerStatus"
                    dark
                    filled
                    item-text="text"
                    item-value="value"
                    return-object
                    label="Status Produtor"
                    :items="[
                      {text:'Todos',value:'*'},
                      {text:'Ativos',value:'ativo'},
                      {text:'Suspenso',value:'suspenso'},
                      {text:'Prospectado',value:'prospectado'},
                      {text:'Desvinculado',value:'desvinculado'},
                    ]"
                    multiple
                    @change="loadProducerwithAnswer"
                  />
                </v-col>
                <v-col
                  md="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="filters.answered.search"
                    filled
                    label="Buscar"
                    append-icon="search"
                    single-line
                    hide-details
                    clearable
                    dark
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              v-model="selected"
              class="producer-list-table"
              dark
              :items="filteredAnsweredList"
              :headers="[
                { text: 'Código', value: 'producer.code', width: 100 },
                { text: 'Produtor', value: 'producer.name' },
                { text: 'Status', value: 'producer.status' },
                { text: 'Rotas', value: 'routes' },
                { text: 'Técnicos', value: 'technical.name' },
                { text: 'Preenchimento', value: 'responseAt', width: '150' },
                { text: 'Justificativas', value: 'justification'},
              ]"
            >
              <template #[`item.responseAt`]="{ value }">
                <v-chip x-small>
                  {{ formatDate(value, 'DD/MM/YYYY') }}
                </v-chip>
              </template>

              <template #[`item.routes`]="{ item }">
                <v-chip
                  v-for="route in item.routes"
                  :key="route.id"
                  x-small
                >
                  {{ route.description }}
                </v-chip>
              </template>
            </v-data-table>
            <div
              class="text-caption text-left ml-4"
              style="margin-top: -58px; height: 58px; line-height: 58px;"
            >
              <v-col
                md="4"
                align-self="center"
                class="event pa-0"
              >
                <transition name="my-transition">
                  <v-btn
                    v-show="selected.length > 0"
                    transition="my-transition"
                    fab
                    text
                    class="pa-0 ma-0 btn-event"
                    style="margin-left: -16px !important;"
                    dark
                    @click="showPrintDialog"
                  >
                    <v-icon>get_app</v-icon>
                  </v-btn>
                </transition>
              </v-col>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-overlay
        :value="loading"
        absolute
      >
        Carregando...
      </v-overlay>
    </questions-card>
  </div>
</template>

<style lang="scss">

.my-transition-enter {
  opacity: 0;
}

.my-transition-enter-active {
  transition: opacity 0.1s;
}

.my-transition-leave-active {
  transition: opacity 0.1s;
  opacity: 0;
}

.event {
  display: flex;
  justify-content: flex-start;
  transition: opacity 0.5s linear;
}

.btn-event {
  transition: opacity 0.5s linear;
}

.producer-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
  padding-top: 0px;
}

.producer-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-table__selected {
    background: rgba(0, 0, 0, 0) !important;
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

.v-data-footer {
  padding: 0px !important;
}

.questionnaire-tabs {
  width: auto !important;
}
</style>

<script>
import moment from "moment-timezone";
import * as _ from "lodash";

import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import QuestionsCard from "@/Domains/Questionnaires/Components/QuestionsCard.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    DateRangePicker,
    QuestionsCard,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
  },

  mixins: [ReportMixin],

  props: {
    project: {
      type: String,
    },
    questionnaireId: {
      type: String,
    },
    questionnaireName: {
      type: String,
    },

    questionnaireDateStart: {
      type: String,
      default: null
    },

    questionnaireDateEnd: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      filters: {
        answered: {
          search: "",

          date: {
            input: "",
            range: [],
          },

          routes: [],

          technical: {},
        },

        notAnswered: {
          search: "",
          date: {
            input: "",
            range: [],
          },
          producer: {
            type: '*',
          },
          routes: []
        },
      },

      producers: {
        answered: [],
        notAnswered: [],
      },

      loading: false,

      selected: [],

      producerStatus: [],

      answeredFilter: 'all',

      tab: null,

      deleteAnswersDialog: {
        show: false,
        motivo: '',
      },
      rules: {
        required: v => !!v || 'Campo obrigatório!',
        dateTime: v =>  !v || !!v && v.length == 16 && this.dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!',
        length: len => v => (v || '').length >= len || 'Justificativa muito curta',
      },

      itemDeleted: [],
    };
  },

  computed: {

    filteredAnsweredList() {
      if (_.isEmpty(this.filters.answered.routes) && _.isEmpty(this.filters.answered.search)) {
        return this.producers.answered;
      }

      const search = typeof this.filters.answered.search === 'string' ? this.filters.answered.search.toUpperCase().trim() : null;

      return this.producers.answered.filter(producer => {
        const hasRoute = _.isEmpty(this.filters.answered.routes) || producer.routes.some(producerRoute => {
          return this.filters.answered.routes.some(filterRoute => filterRoute.id === producerRoute.id)
        });
        const hasSearch = !search || JSON.stringify(Object.values(producer)).toUpperCase().includes(search);

        return hasRoute && hasSearch;
      });
    },

    filteredNotAnsweredList() {
      if (_.isEmpty(this.filters.notAnswered.routes) && _.isEmpty(this.filters.notAnswered.search)) {
        return this.producers.notAnswered;
      }

      const search = typeof this.filters.notAnswered.search === 'string' ? this.filters.notAnswered.search.toUpperCase().trim() : null;

      return this.producers.notAnswered.filter(producer => {
        const hasRoute = _.isEmpty(this.filters.notAnswered.routes) || producer.routes.some(producerRoute => {
          return this.filters.notAnswered.routes.some(filterRoute => filterRoute.id === producerRoute.id)
        });
        const hasSearch = !search || JSON.stringify(Object.values(producer)).toUpperCase().includes(search);

        return hasRoute && hasSearch;
      });
    },
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasDeleteAnswersAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'delete-answers-report' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    async answeredFilter() {
      await this.loadProducerwithAnswer();
    }
  },

  mounted() {
    if (this.questionnaireDateStart) {
      this.filters.answered.date.range = [this.questionnaireDateStart, this.questionnaireDateEnd];
      this.producerStatus = [
        { text: 'Ativos', value: 'ativo' },
        { text: 'Suspenso', value: 'suspenso' },
      ];
      this.answeredFilter = 'last-producer-answer';
    } else {
      this.loadProducerwithAnswer();
    }
    this.loadProducerNotAnswer();
  },

  methods: {
    /**
     * Carrega os produtores que respoderam o questionário
     */
    async loadProducerwithAnswer() {
      try {
        this.loading = true;

        let dateFirst = null;
        let dateEnd = null;
        if (this.questionnaireDateStart && _.isEmpty(this.filters.answered.date.range)) {
          dateFirst = this.questionnaireDateStart;
          dateEnd = this.questionnaireDateEnd;
        } else if (!_.isEmpty(this.filters.answered.date.range)) {
          dateFirst = _.head(this.filters.answered.date.range);
          dateEnd = _.last(this.filters.answered.date.range) ;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaProduresFormulario`,
          {
            id_formulario: this.questionnaireId,
            data_inicio: dateFirst || null,
            data_fim: dateEnd || null,
            produtor_status: this.producerStatus ? _.mapValues(this.producerStatus, 'value') : '*',
            id_tecnico: this.filters.answered.technical ? this.filters.answered.technical.id : null,
            agrupa_respostas: this.answeredFilter !== 'all',
            projeto: this.project || null,
            respostas_deletadas: true,
          }
        );

        this.producers.answered = data.map(item => {
          return {
            id: item.id_formulario_customizado_cabecalho,
            justification: item.justificativa_exclusao,
            producer: {
              id: item.id_produtor,
              code: item.codigo_laticinio,
              name: item.nome_produtor,
              status: item.status === 'APROVADO' ? 'ATIVO' : item.status,
            },
            responseAt: item.data_cadastro_app,
            routes: (item.rotas || []).map(route => ({
              id: route.id_rota,
              description: route.descricao,
            })),
            technical: {
              id: item.id_tecnico,
              name: item.nome_tecnico
            },
          };
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    onDateFilter(event) {
      this.filters.answered.date.range = event;

      this.loadProducerwithAnswer();
    },
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
