<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-col
          cols="4"
          class="pa-0"
        >
          <date-range-picker
            v-model="filters.date.input"
            class="pa-0"
            @change="onDateFilter"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="4"
          class="pa-0"
        >
          <v-text-field
            v-model="filters.search"
            prepend-inner-icon="search"
            label="Buscar"
            dark
            hide-details
            filled
            clearable
          />
        </v-col>
      </v-card-title>
      <data-table
        ref="report"
        :loading="loading"
        :headers="headers"
        :items="filteredItems"
        show-custom-group
        name="Impedimentos de coleta"
        item-key="id"
        class="elevation-1"
        disable-filtering
        multi-sort
      >
        <template v-slot:[`item.action`]="{ item }">
          <template v-if="item.url_anexos && item.url_anexos.length > 0">
            <v-btn
              icon
              @click="openPhotos(item);"
            >
              <v-icon>photo</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <div>
              <v-chip
                class="ma-2"
                x-small
              >
                -
              </v-chip>
            </div>
          </template>
        </template>
        <template #[`item.edit`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                small
                @click="editImpedimento(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>

              <v-list-item
                small
                @click="deleteImpedimento(item.id_impedimento_coletas)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <v-dialog
      v-model="photoDialog.show"
      width="600"
      max-width="650"
    >
      <v-card>
        <v-card-title>Fotos</v-card-title>
        <v-card-text>
          <v-carousel>
            <v-carousel-item
              v-for="(foto, i) in fotos"
              :key="i"
            >
              <img
                :src="foto"
                width="100%"
              >
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="blue--text"
            text
            @click="photoDialog.show = false;"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="impedimentDialog.show"
      width="600"
      height="250"
    >
      <v-card>
        <v-card-title>
          {{ impedimentDialog.form.id_impedimento_coletas ? 'Editar Impedimento de Coleta' : 'Cadastrar Impedimento de Coleta' }}
        </v-card-title>

        <v-divider />

        <v-card-text
          class="pt-5"
          style="max-height: calc(100vh - 250px);"
        >
          <v-tabs
            v-model="tab"
            fixed-tabs
          >
            <v-tab
              v-if="!impedimentDialog.form.id_impedimento_coletas"
              href="#itinerario-tab"
            >
              Itinerario de Coleta
            </v-tab>

            <v-tab
              href="#impedimento-tab"
              :disabled="rotaItinerario ? false : impedimentDialog.form.id_itinerario ? false : true"
            >
              Impedimento de Coleta
            </v-tab>

            <v-tab
              href="#anexos-tab"
              :disabled="rotaItinerario ? false : impedimentDialog.form.id_itinerario ? false : true"
            >
              Anexos
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              value="itinerario-tab"
              justify-center
              class="pa-6"
            >
              <v-row>
                <v-col cols="3">
                  <masked-input
                    v-model="dateItinerary.input"
                    label="Data"
                    :mask="DateMask"
                    @change="onDateFilterItinerary"
                  />
                </v-col>
                <v-col cols="9">
                  <v-autocomplete
                    v-model="motorista"
                    label="Motorista"
                    :items="motoristas"
                    item-text="motorista"
                    item-value="id_motorista"
                    :disabled="dateItinerary.input ? false : true"
                    :loading="loadingMotorista"
                    @input="onRoutes"
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="rotaItinerario"
                    label="Rota"
                    :items="rotaItinerarios"
                    item-text="concat_descricao_rota"
                    item-value="id_rota"
                    :disabled="motorista ? false : true"
                    :loading="loadingRoutes"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item
              value="impedimento-tab"
              justify-center
              class="pa-6"
            >
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="produtor"
                    label="Produtor"
                    :items="produtores"
                    item-text="concat"
                    item-value="id"
                    :disabled="impedimentDialog.form.id_impedimento_coletas ? true : false"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-if="impedimentDialog.form.id_impedimento_coletas"
                    v-model="impedimentDialog.form.data"
                    label="Data"
                    :disabled="impedimentDialog.form.id_impedimento_coletas ? true : false"
                  />
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-if="impedimentDialog.form.id_impedimento_coletas"
                    v-model="impedimentDialog.form.motorista"
                    label="Motorista"
                    :disabled="impedimentDialog.form.id_impedimento_coletas ? true : false"
                  />
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-if="impedimentDialog.form.id_impedimento_coletas"
                    v-model="impedimentDialog.form.nome_rota"
                    label="Rota"
                    :disabled="impedimentDialog.form.id_impedimento_coletas ? true : false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="mt-6"
                >
                  <v-textarea
                    v-model="impedimentDialog.form.motivo"
                    label="Motivo"
                    height="5px"
                    class="mt-6"
                    :disabled="!produtor"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item
              value="anexos-tab"
              justify-center
              class="pa-6"
            >
              <v-col
                cols="12"
              >
                <attachments
                  :attached.sync="attached"
                  show-upload
                  @attachment-selected="handleAttachmentSelected"
                />
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="close()"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="!impedimentDialog.form.motivo"
            @click.stop="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <template>
        <v-btn
          fab
          dark
          color="green darken-1"
          @click="exportExcel()"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                backup_table
              </v-icon>
            </template>
            Download (Excel)
          </v-tooltip>
        </v-btn>
      </template>
      <template>
        <v-btn
          fab
          dark
          color="orange darken-1"
          @click="print"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                print
              </v-icon>
            </template>

            Imprimir
          </v-tooltip>
        </v-btn>
      </template>
      <template>
        <v-btn
          fab
          dark
          color="blue darken-1"
          @click="addImpedimento"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                add
              </v-icon>
            </template>

            Inserir Impedimento
          </v-tooltip>
        </v-btn>
      </template>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import qs from "qs";
import moment from "moment-timezone";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import Attachments from "@/Domains/Collects/Reports/CollectionImpediment/Components/Attachments.vue";

export default {
  components: {
    DateRangePicker,
    MaskedInput,
    Attachments
  },

  data() {
    return {
      loading: false,

      loadingRoutes: false,

      loadingMotorista: false,

      tab: null,

      filters: {
        search: null,
        date: {
          input: "today",
          range: [],
        },
      },
      relatorios: [],
      fotos: [],
      showPhoto: false,

      photoDialog: {
        show: false,
      },

      impedimentDialog: {
        show: false,
        form: {}
      },

      validation: {
        valid: true,
        rules: {
          required: [v => !!v || "Campo obrigatório"],
        },
      },

      motoristas: [],

      motorista: [],

      produtores: [],

      produtor: [],

      dateItinerary: {
        input: null,
        range: [],
      },

      rotaItinerarios: [],

      rotaItinerario: [],

      filterMotorista: null,

      id_itinerario: null,

      DateMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },

      attached: [],
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.relatorios;
      }
      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;
      return this.relatorios.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },

    headers() {
      let headers = [
        { text: 'Data', value: 'data', align: 'center' },
        { text: 'Código Produtor', value: 'codigo_produtor', align: 'center' },
        { text: 'Produtor', value: 'nome_produtor', align: 'start' },
        { text: 'Código Itinerário', value: 'cod_itinerario', align: 'center' },
        { text: 'Rota', value: 'nome_rota', align: 'center' },
        { text: 'Motivo', value: 'motivo', align: 'start' },
        { text: 'Motorista', value: 'motorista', align: 'start' },
        { text: 'Fotos', value: 'action', align: 'center' },
        { text: '', value: 'edit', align: 'center' },
      ];
      return headers;
    },
  },

  mounted() {
    this.loadRelatorio();
    this.loadProducers();
    this.listaItinerarioColeta();
  },

  methods: {
    onDateFilter(event) {
      this.filters.date.range = event
      this.loadRelatorio();
    },

    clear() {
      this.impedimentDialog.form = {};
      this.date = null;
      this.rotaItinerario = null;
      this.id_itinerario =  null;
      this.motorista = null;
      this.produtor = null;
      this.attached = [];
    },

    async loadRelatorio() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/ImpedimentoColeta`,
          qs.stringify({
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.first(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) :  moment().format("YYYY-MM-DD"),
          })
        );

        this.relatorios = data.map((item) => {
          return {
            ...item,
            url_anexos: JSON.parse(item.url_anexos)
          }
        });

      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Impedimentos de coleta - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `Impedimentos de coleta - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.print(null, title);
    },

    openPhotos(item) {
      this.fotos = item.url_anexos;
      this.photoDialog.show = true;
    },

    close() {
      this.impedimentDialog.show = false;
      this.dateItinerary.input = null;
      this.tab = null;
      this.clear();
    },

    handleAttachmentSelected(url) {
      this.attached = url;
    },

    addImpedimento() {
      this.impedimentDialog.show = true;
      this.impedimentDialog.form = {};
      this.rotaItinerario = null;
      this.id_itinerario =  null;
      this.motorista = null;
      this.produtor = null;
      this.tab = null;

      this.clear();
    },

    editImpedimento(item) {
      this.impedimentDialog.show = true;
      this.impedimentDialog.form = { ...item };
      this.date = this.impedimentDialog.form.data;
      this.attached = this.impedimentDialog.form.url_anexos;
      this.tab = "impedimento-tab";

      const produtor = this.produtores.find(x => x.id === item.id_produtor);
      if (produtor) {
        this.produtor = produtor.id;
      }
    },

    async save() {
      try {
        this.loading = true;

        const action = this.impedimentDialog.form.id_impedimento_coletas ? 'atualizaImpedimento' : 'insereImpedimento';

        const selectedProdutor     = this.produtores.find(produtor => produtor.id === this.produtor);
        const selectedMotorista    = this.motoristas.find(motorista => motorista.id_motorista === this.motorista);
        const selectedIdItinerario = this.rotaItinerarios.find(x => x.id_rota === this.rotaItinerario);

        for (const key in selectedIdItinerario) {
          if (key === 'id_rota') {
            const rotaSplit = selectedIdItinerario[key].split("|");
            selectedIdItinerario[key] =  rotaSplit[0];
          }
        }

        var urls = [];
        if (Array.isArray(this.attached)) {
          urls = this.attached.map(function(anexo) {
            if (typeof anexo === 'string') {
              return anexo;
            } else if (anexo.url) {
              return anexo.url;
            } else {
              return null;
            }
          }).filter(function(url) {
            return url !== null;
          });
        }

        let payload = {
          id_impedimento_coletas: this.impedimentDialog.form.id_impedimento_coletas ? this.impedimentDialog.form.id_impedimento_coletas : '',
          codigo_produtor: action === 'insereImpedimento' ? selectedProdutor.codigo : '',
          id_produtor: action === 'insereImpedimento' ? selectedProdutor.id : '',
          nome_produtor: action === 'insereImpedimento' ? selectedProdutor.name : '',
          data: action === 'insereImpedimento' ? selectedIdItinerario.data_hora_inicio : moment(this.impedimentDialog.form.data, 'DD/MM/YYYY').format('YYYY-MM-DD hh:mm:ss'),
          id_rota: action === 'insereImpedimento' ? selectedIdItinerario.id_rota : this.impedimentDialog.form.id_rota,
          nome_rota: action === 'insereImpedimento' ? selectedIdItinerario.name : this.impedimentDialog.form.nome_rota,
          id_motorista: action === 'insereImpedimento' ? selectedMotorista.id_motorista : this.impedimentDialog.form.id_motorista,
          motorista: action === 'insereImpedimento' ? selectedMotorista.motorista : this.impedimentDialog.form.motorista,
          motivo: this.impedimentDialog.form.motivo,
          id_itinerario: action === 'insereImpedimento' ? selectedIdItinerario.id_itinerario : this.impedimentDialog.form.id_itinerario,
          url_anexos: urls,
        };

        const { data } = await this.$axios.post(
          `/coleta/${action}`,
          payload
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.impedimentDialog.show = false;
        this.$snotify.success("Registro salvo com sucesso", "Sucesso");
        this.$emit("save");
        this.loadRelatorio();
        this.clear();
        this.close();
      } catch (error) {
        this.$snotify.error("Erro ao salvar", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    async loadProducers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/produtores/listaJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.produtores = data.map(person => {
          return {
            id: person.id_pessoa,
            codigo: person.codigo_laticinio,
            name: person.nome,
            concat: person.codigo_laticinio + ' - ' + person.nome
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onDateFilterItinerary(event) {
      this.loadingMotorista = true;
      const formattedDate = moment(event, 'DD/MM/YYYY').format('YYYY-MM-DD');
      this.dateItinerary.range = formattedDate;

      this.listaItinerarioColeta();
    },

    async listaItinerarioColeta() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/listaItinerarioColeta`,
          qs.stringify({
            data_inicio: this.dateItinerary.range,
            id_motorista: this.filterMotorista
          })
        );

        if (_.isString(data)) {
          throw data;
        }

        this.motoristas = data.map(x => {
          return {
            id_itinerario: x.id_itinerario,
            id_motorista: x.id_motorista,
            motorista: x.motorista,
            id_rota: x.id_rota,
            name: x.descricao_rota,
            concat_descricao_rota: x.data_hora_inicio + ' - ' + x.descricao_rota,
            data_hora_inicio: x.data_hora_inicio
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
        this.loadingMotorista = false;
      }
    },

    onRoutes(event) {
      this.loadingRoutes = true;
      this.filterMotorista = event;
      this.listaItinerario();
    },

    async listaItinerario() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/listaItinerarioColeta`,
          qs.stringify({
            data_inicio: this.dateItinerary.range,
            id_motorista: this.filterMotorista
          })
        );

        if (_.isString(data)) {
          throw data;
        }

        this.rotaItinerarios = data.map(x => {
          return {
            id_itinerario: x.id_itinerario,
            id_rota: x.id_rota+'|'+x.data_hora_inicio,
            name: x.descricao_rota,
            data_hora_inicio: x.data_hora_inicio,
            concat_descricao_rota: ' Data/Hora início: ' + moment(x.data_hora_inicio).format('DD/MM/YYYY HH:mm') + ' | Rota: ' + x.descricao_rota,
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
        this.loadingRoutes = false;
      }
    },

    async deleteImpedimento(id) {
      try {
        if (
          !(await this.$root.$confirm("Atenção", "Deseja realmente Inativar/Excluir esta impedimento de coleta?", { color: "red" }))
        ) {
          return;
        }
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.post(`/coleta/inativaImpedimentoColeta`, this.$qs.stringify({
          id_impedimento_coletas: id,
        }));

        if (!data.codigo) {
          throw data.mensagem || data;
        }

      } catch (error) {
        console.log(error);
        this.$snotify.error(`Oops, ocorreu um erro ao excluir!\r\n${error}`, "Atenção");
      } finally {
        this.$root.$progressBar.hide();
        this.loadRelatorio();
      }

    }
  },

}
</script>
