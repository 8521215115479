<template>
  <v-form
    ref="form"
    v-model="validation.valid"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-card class="transparent">
          <v-card-text>
            <v-row>
              <v-col
                v-if="isVisible('nome_titular')"
              >
                <v-text-field
                  v-model="alternative_bank.nome_titular"
                  :label="`Nome titular da conta ${isRequired('nome_titular') ? '*' : ''}`"
                  :rules="getRules('nome_titular')"
                  prepend-inner-icon="person"
                  dense
                  hide-details
                />
              </v-col>
              <v-col
                v-if="isVisible('cnpj_cpf_titular')"
              >
                <masked-text-field
                  v-model="alternative_bank.cnpj_cpf"
                  :label="`CPF/CNPJ do titular da conta ${isRequired('cnpj_cpf_titular') ? '*' : ''}`"
                  :rules="getRules('cnpj_cpf_titular')"
                  :mask="CpfCnpjMask"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-if="isVisible('banco')"
                md="6"
              >
                <banks-autocomplete
                  v-model="alternative_bank.banco"
                  :label="`Banco ${isRequired('banco') ? '*' : ''}`"
                  :rules="getRules('banco')"
                  hide-details
                  dense
                  clearable
                />
              </v-col>

              <v-col
                v-if="isVisible('agencia')"
                md="6"
              >
                <v-text-field
                  v-model="alternative_bank.agencia"
                  prepend-inner-icon="subtitles"
                  :label="`Agência ${isRequired('agencia') ? '*' : ''}`"
                  :rules="getRules('agencia')"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-if="isVisible('conta')"
                md="6"
              >
                <v-text-field
                  v-model="alternative_bank.conta"
                  prepend-inner-icon="subtitles"
                  :label="`N° da conta ${isRequired('conta') ? '*' : ''}`"
                  :rules="getRules('conta')"
                  dense
                  hide-details
                />
              </v-col>

              <v-col
                v-if="isVisible('tipo_conta')"
                md="6"
              >
                <v-select
                  v-model="alternative_bank.tipo_conta"
                  :items="tipo_conta_options"
                  :label="`Tipo de Conta ${isRequired('tipo_conta') ? '*' : ''}`"
                  :rules="getRules('tipo_conta')"
                  dense
                  clearable
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-if="isVisible('forma_pagamento')"
                md="12"
              >
                <span>
                  Forma de pagamento{{ isRequired('forma_pagamento') ? ' *' : '' }}:
                </span>
                <v-row style="padding-left: 15px">
                  <v-radio-group
                    v-model="alternative_bank.forma_pagamento"
                    row
                  >
                    <v-radio
                      label="Cheque"
                      value="Cheque"
                    />
                    <v-radio
                      label="Depósito bancário"
                      value="Deposito"
                    />
                    <v-radio
                      label="PIX"
                      value="Pix"
                    />
                  </v-radio-group>
                </v-row>

                <v-row>
                  <v-col
                    v-if="isVisible('tipo_chave') && alternative_bank.forma_pagamento === 'Pix'"
                    cols="2"
                  >
                    <v-select
                      v-model="alternative_bank.tipo_chave"
                      :items="['CPF', 'CNPJ', 'Celular', 'E-mail', 'Aleatória']"
                      :label="`Tipo de Chave ${isRequired('tipo_chave') ? '*' : ''}`"
                      dense
                    />
                  </v-col>

                  <v-col
                    v-if="isVisible('chave') && alternative_bank.forma_pagamento == 'Pix'"
                    cols="6"
                  >
                    <v-text-field
                      v-model="alternative_bank.chave"
                      prepend-inner-icon="subtitles"
                      :label="`Digite a Chave * ${isRequired('chave') ? '*' : ''}`"
                      dense
                      :disabled="!alternative_bank.tipo_chave"
                      :rules="validation.rules.required"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import * as _ from 'lodash';

import BanksAutocomplete from "@/Support/Components/BanksAutocomplete.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

export default {
  components: {
    BanksAutocomplete,
    MaskedTextField,
  },
  props: {
    dataAlternativeBank: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      validation: {
        valid: true,
        rules: {
          required: [v => !!v || "Campo obrigatório"],
        },
      },

      tipo_conta_options: [
        { text: 'CORRENTE', value: 'CONTA_CORRENTE' },
        { text: 'POUPANÇA', value: 'CONTA_POUPANCA' },
        { text: 'SALÁRIO', value: 'CONTA_SALARIO' }],

      TaxMask: {
        mask: Number,
        scale: 2,
        maxlength: 5,
        radix: '.',
        mapToRadix: [','],
      },

      FundesaTaxMask: {
        mask: Number,
        scale: 5,
        maxlength: 7,
        radix: '.',
        mapToRadix: [','],
      },

      CpfCnpjMask: {
        mask: [
          {
            mask: "000.000.000-00",
            type: "CPF",
          },
          {
            mask: "00.000.000/0000-00",
            type: "CNPJ",
          },
          {
            mask: "000000000000000000",
            startsWith: "",
            type: "unknown",
          },
        ],
        dispatch: function(appended, dynamicMasked) {
          var number = (dynamicMasked.value + appended).replace(/\D/g, "");

          //seleciona a masca correspondente
          return dynamicMasked.compiledMasks.find(function(m) {
            if (number.length > 11) {
              return m.type === "CNPJ";
            }
            return m.type === "CPF";
          });
        },
      },

      defaultRequiredFields: [],
    };
  },
  computed: {
    alternative_bank: {
      get() {
        return this.dataAlternativeBank;
      },

      set(newValue) {
        return this.$emit("update:dataAlternativeBank", newValue);
      },
    },

    settings() {
      if (_.isEmpty(this.$store.state.settings.gerais.cadastro_produtor)) {
        return {}
      }
      return this.$store.state.settings.gerais.cadastro_produtor.pagamento;
    },
  },
  watch: {
    // Monitora a validação do form
    validation: {
      handler: function ({ valid }) {
        this.$emit("onValidation", {
          valid: valid,
          tab: "financial",
        });
      },
      deep: true,
    },
  },

  mounted() {
    // Valida campos do formulário
    this.$refs.form.validate();
  },

  methods: {
    isVisible(field) {
      if (!this.settings[field]) {
        return true;
      }

      return this.settings[field].show;
    },

    isRequired(field) {
      if (!this.settings[field]) {
        return this.defaultRequiredFields.includes(field);
      }

      return this.settings[field].required;
    },

    getRules(field) {
      const rules = [];

      if (this.isRequired(field)) {
        rules.push(v => !!v || "Campo obrigatório");
      }

      return rules;
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /**
     * @void
     * Valida os campos do formulários
     */
    formValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    formResetValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta o formulário
     */
    formReset() {
      this.$refs.form.reset()
    },
  },
};
</script>
