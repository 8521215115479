<template>
  <div>
    <v-timeline-item
      :color="item.rejected?'deep-orange lighten-1':'blue darken-1'"
      fill-dot
      right
      large
    >
      <template #icon>
        <span
          class="text-overline text-center"
          style="color: white; font-size: 12px !important;"
        >
          <v-icon
            small
            color="white"
          > {{ item.rejected?'remove_done':'done_all' }}</v-icon>
          {{ item.date | date('HH:mm') }}
        </span>
      </template>

      <v-card class="elevation-2">
        <v-card-title
          class="text-overline pa-0 pt-1 pl-2 pr-2 pb-2"
          style="font-size: 12px !important;"
        >
          <span>
            <b>{{ item.producer.code }} | {{ item.producer.name }}</b>  |  {{ item.date | date('DD/MM HH:mm') }}
          </span>
        </v-card-title>

        <v-card-text class="pb-2">
          <div>
            <v-row class="text-caption pl-2 pr-2">
              <v-col
                cols="6"
                class="text-left pa-0"
              >
                Litros: {{ item.vol }} L
              </v-col>

              <v-col
                cols="6"
                class="text-right pa-0"
              >
                Temperatura: {{ item.temp }} ºC
              </v-col>
            </v-row>

            <v-row class="text-caption pl-2 pr-2 pb-1">
              <v-col
                cols="6"
                class="text-left pa-0"
              >
                Amostra: {{ item.sample }}
              </v-col>

              <v-col
                cols="6"
                class="text-right pa-0"
              >
                Alizarol: {{ item.alizarol }}
              </v-col>
            </v-row>

            <v-row class="text-caption pl-2 pr-2 pb-1">
              <v-col
                cols="6"
                class="text-left pa-0"
              >
                Latitude: {{ item.producer.pos.lat }}
              </v-col>

              <v-col
                cols="6"
                class="text-right pa-0"
              >
                Longitude: {{ item.producer.pos.lng }}
              </v-col>
            </v-row>

            <v-row
              v-if="item.gpsDistance"
              class="text-caption pl-2 pr-2"
            >
              <v-col
                cols="12"
                class="text-left pa-0"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">
                      Gps: {{ formatNumber(item.gpsDistance) }} Km
                    </span>
                  </template>
                  Distância desde o inicío do itinerário
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="text-right pa-0"
              >
                <v-btn
                  v-if="item.markerId"
                  icon
                  @click="$emit('viewOnMap', item.markerId)"
                >
                  <v-icon>place</v-icon>
                </v-btn>

                <v-btn
                  v-if="item.antibioticSamples.length"
                  icon
                  @click="openAntibioticSamplesDialog()"
                >
                  <v-icon>biotech</v-icon>
                </v-btn>

                <template v-for="(type) in picturesIcons">
                  <v-btn
                    v-if="type === 'RULE'"
                    :key="type"
                    icon
                    @click="openGallery('RULE')"
                  >
                    <v-icon>straighten</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="type === 'TEMP'"
                    :key="type"
                    icon
                    @click="openGallery('TEMP')"
                  >
                    <v-icon>ac_unit</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="type === 'COLLECT'"
                    :key="type"
                    icon
                    @click="openGallery('COLLECT')"
                  >
                    <v-icon>image</v-icon>
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>

    <v-dialog
      v-model="antibioticSamplesDialog"
      width="400"
      @close="closeAntibioticSamplesDialog()"
    >
      <v-data-table
        light
        :headers="[
          {
            text: 'Coletado Em',
            value: 'collectedAt',
          },
          {
            text: 'Amostra',
            value: 'code',
          },
          {
            text: 'Observacao',
            value: 'obs',
          },
        ]"
        :items="item.antibioticSamples"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
      >
        <template #[`item.collectedAt`]="{ item }">
          {{ item.collectedAt | date('DD/MM HH:mm') }}
        </template>
      </v-data-table>
    </v-dialog>

    <LightBox
      ref="lightbox"
      :media="pictures"
      :show-light-box="false"
      show-caption
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import LightBox from 'vue-image-lightbox';

export default {

  components: {
    LightBox,
  },

  filters: {

    date(value, opts) {
      return moment(value).format(opts);
    },

  },

  props: {
    item: {
      type: Object,
      default: () => ({
        id: '',
        type: 'COLLECT',
        date: '',
        producer: {
          id: null,
          code: '',
          name: '',
          pos: {},
        },
        pictures: [],
        antibioticSamples: [],
        rejected: false,
      }),
    },
  },

  data() {
    return {
      // Dialogs
      antibioticSamplesDialog: false,

      pictures: []

    }
  },

  computed: {
    picturesIcons() {
      return [...new Set(this.item.pictures.reduce((acc, picture) => ([...acc, picture.type]), []))];
    },

  },

  methods: {

    /**
     * Abre o lightbox com as imagems do tipo selecionado sendo exibidas
     */
    openGallery(type) {
      this.pictures = this.item.pictures.filter(picture => picture.type == type);

      this.$refs.lightbox.showImage(0);
    },

    openAntibioticSamplesDialog() {
      this.antibioticSamplesDialog = true;
    },

    closeAntibioticSamplesDialog() {
      this.antibioticSamplesDialog = false;
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),

  },

}
</script>
