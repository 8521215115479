<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Despesas Analítico
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        dark
        class="expenses-list"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="2"
              class="pt-0"
            >
              <v-menu
                ref="menu"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="dateRefFilter"
                    label="Mês de Referência"
                    prepend-inner-icon="event"
                    hide-details
                    readonly
                    filled
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filters.date"
                  :max="maxDateRef"
                  type="month"
                  no-title
                  scrollable
                  @change="onFilter"
                />
              </v-menu>
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
              class="pt-0"
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-card
            v-for="(driver, index) of items"
            :key="index"
            class="mb-3 driver-expenses-card"
            dark
          >
            <v-card-title class="pa-1">
              <v-list-item>
                <v-list-item-action>
                  <v-checkbox
                    v-model="selectedDrivers"
                    :value="driver.id_pessoa"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle class="text-left white--text">
                    {{ driver.nome }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-chip
                    outlined
                    color="green lighten-2"
                  >
                    <v-icon left>
                      map
                    </v-icon>
                    {{ formatNumber(driver.km_total) }} km
                  </v-chip>
                </v-list-item-action>

                <v-list-item-action>
                  <v-chip
                    outlined
                    color="orange"
                  >
                    <v-icon left>
                      paid
                    </v-icon>
                    {{ formatCurrency(driver.valor_total) }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="driver.despesas"
              :search="filters.search"
              :loading="loading"
              item-key="id_despesa"
              group-by="item.item"
              disable-pagination
              hide-default-footer
              class="driver-expenses-table"
            >
              <template #[`group.header`]="{ group, items, headers, isOpen, toggle }">
                <td :colspan="headers.length">
                  <v-layout
                    class="pa-0 align-center"
                    row
                  >
                    <v-flex class="pa-0 text-left">
                      <v-btn
                        dark
                        icon
                        @click="toggle"
                      >
                        <v-icon v-if="isOpen">
                          remove
                        </v-icon>
                        <v-icon v-else>
                          add
                        </v-icon>
                      </v-btn>
                      {{ group }}
                    </v-flex>
                    <v-flex class="pa-0 text-right">
                      <v-chip
                        small
                        v-text="items.length"
                      />
                    </v-flex>
                  </v-layout>
                </td>
              </template>

              <template #[`item.data`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>

              <template #[`item.preco`]="{ value }">
                {{ formatCurrency(value) }}
              </template>

              <template #[`item.recibos`]="{ value }">
                <div v-if="value.length > 0">
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="showReceipts(value)"
                  >
                    attach_file
                  </v-icon>
                </div>
                <div v-else>
                  <v-chip
                    pill
                    outlined
                    x-small
                  >
                    -
                  </v-chip>
                </div>
              </template>
              <template #[`item.average`]="{ item }">
                {{ (item.km / item.litros) || '' }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-dialog
      v-model="receiptsDialog.show"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Recibos</span>
        </v-card-title>

        <v-card-text>
          <attachments :attachments="receiptsDialog.receipts" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-speed-dial
      v-if="selectedDrivers.length"
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<style lang="scss">
.expenses-list {
  background: rgba(0, 0, 0, 0.3) !important;
}

.driver-expenses-card {
  background: transparent !important;

  .v-card__title {
    background: rgba(0, 0, 0, .3);
  }
}

.driver-expenses-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.2);
    }

    tbody {
      background: rgba(255, 255, 255, 0.15);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }

      .v-row-group__header {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}

</style>

<script>
import Attachments from '@/Domains/Registrations/Person/Components/AttachmentsTab.vue';
import ReportMixin from '@/Support/Mixins/ReportMixin.js';
import moment from 'moment-timezone';
import _ from 'lodash';

export default {

  components: {
    Attachments,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        date: moment().subtract(1, 'month').format('YYYY-MM'),
        search: '',
      },

      headers: [
        { text: 'Data', value: 'data', align: 'center' },
        { text: 'Placa', value: 'equipamento.placa', align: 'center'  },
        { text: 'Preço', value: 'preco', align: 'center' },
        { text: 'Litros', value: 'litros', align: 'center' },
        { text: 'Km', value: 'km', align: 'center' },
        { text: 'Média Km/L', value: 'average', align: 'center' },
        { text: 'Recibos', value: 'recibos', align: 'center' },
      ],

      items: [],

      selectedDrivers: [],

      receiptsDialog: {
        show: false,
        receipts: []
      },
    };
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format('YYYY-MM');
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, 'YYYY-MM').format('MM/YYYY');
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    onFilter() {
      this.selectedDrivers = [];
      this.loadReport();
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/financial/reports/driver-expenses`, { params: {
          month_reference: this.filters.date,
        } });

        this.items = data;
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error('Erro ao carregar o relatório', 'Atenção');
      }
      finally {
        this.loading = false;
      }
    },

    showReceipts(receipts) {
      this.receiptsDialog = {
        show: true,
        receipts: receipts.map((file) => ({
          tipo: file.name,
          formato: file.type,
          url: file.url,
        }))
      }
    },

    getSelectedItems() {
      return this.items.filter(item => this.selectedDrivers.includes(item.id_pessoa))
    },

    print() {
      const reference = moment(this.filters.date, 'YYYY-MM').format('MMM-YY').toUpperCase()
      const pages = this.getSelectedItems().map(data => {
        const title = `Despesas - ${data.nome} - ${reference}`;

        const report = data.despesas.map(item => ({
          'Item': item.item.item,
          'Data': this.formatDate(item.data, 'DD/MM/YYYY'),
          'Placa': _.get(item, 'equipamento.placa', '-'),
          'Preço': this.formatCurrency(item.preco),
          'Litros': parseFloat(item.litros),
          'Km': parseFloat(item.km),
          'Média Km/L': parseFloat(item.km / item.litros) || '',
        }));

        const body = [];

        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            tag: 'datatable',
            headers: Object.keys(report[0] || {})
              .map(header => ({
                key: header,
                label: header,
                class: 'text-left'
              })),
            items: report,
          }]
        });

        const totals = {
          '': '<b>Total:</b>',
          'Km': this.formatNumber(data.km_total),
          'Despesas': this.formatCurrency(data.valor_total),
        };

        body.push({
          tag: 'section',
          class: 'row',
          children: [
            {
              tag: 'div',
              class: 'six columns',
              contents: '&nbsp;'
            },
            {
              tag: 'div',
              class: 'six columns',
              children: [{
                tag: 'datatable',
                headers: Object.keys(totals)
                  .map(header => ({
                    key: header,
                    label: header,
                    class: 'text-left'
                  })),
                items: [totals],
              }]
            },
          ]
        });

        const header = [{
          tag: 'div',
          class: 'row',
          children: [{
            tag: 'div',
            class: 'text-right',
            contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
          }]
        }];

        return { title, header, body };
      });

      this.printPdf({ pages });
    },

    exportExcel() {
      const selectedItems = this.getSelectedItems();
      const filename = `Despesas - ${moment(this.filters.date, 'YYYY-MM').format('MMM-YY').toUpperCase()}`;
      const worksheet = this.XLSX.utils.json_to_sheet([]);

      const workbook = this.XLSX.utils.book_new();

      worksheet["!merges"] = [];
      const totalCols = 3;

      let currentRow = 0;
      selectedItems.forEach(data => {
        const items = data.despesas.map(item => ({
          'Item': item.item.item,
          'Data': this.formatDate(item.data, 'DD/MM/YYYY'),
          'Placa': _.get(item, 'equipamento.placa', '-'),
          'Preço': parseFloat(item.preco),
          'Litros': parseFloat(item.litros),
          'Km': parseFloat(item.km),
          'Média Km/L': parseFloat(item.km / item.litros) || '',
        }));

        // Group Title
        const addr = this.XLSX.utils.encode_cell({ r: currentRow, c: 0 });
        worksheet[addr] = { v: data.nome };
        worksheet["!merges"].push({ s: { r: currentRow, c: 0 }, e: { r: currentRow, c: totalCols } });

        currentRow += 1;

        this.XLSX.utils.sheet_add_json(worksheet, items, { origin: currentRow });
        this.applyStyles(worksheet, items, null, false, currentRow);

        currentRow += items.length + 1;

        const totals = [{
          'placeholder-01': 'Km Total',
          'placeholder-02': data.km_total,
          'placeholder-03': 'Valor Total',
          'placeholder-04': data.valor_total,
        }];

        this.XLSX.utils.sheet_add_json(worksheet, totals, { origin: currentRow, skipHeader: true });
        this.applyStyles(worksheet, totals, null, true, currentRow);

        currentRow += 3;
      });

      this.XLSX.utils.book_append_sheet(workbook, worksheet, _.truncate(filename, 31));
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format('MMM/YY')),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
