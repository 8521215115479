<template>
  <v-row>
    <template
      v-for="dairy of dairies"
    >
      <v-col
        :key="`chart-${dairy.name}`"
        cols="12"
      >
        <RawMaterialUnitChart
          :color="color"
          :height="height"
          :dairy="dairy.name"
          :category="dairy.category"
          :data="dairy.data"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import moment from 'moment/moment';
import RawMaterialUnitChart from "@/Domains/BI/Views/Platform/Components/RawMaterialUnitChart.vue";

export default {
  components: { RawMaterialUnitChart },
  props: {
    color: {
      type: String,
      default: '#26c6da'
    },
    height: {
      type: String,
      default: '250px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      dairies: [],
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.get(`/platform/reports/raw-material-volume`, { params: {
          date_start: startDate,
          date_end: endDate,
        } });

        this.dairies = Object.keys(data).map(name => {
          const rawMaterialItems = Object.values(data[name]);
          const dates = [...new Set(rawMaterialItems.flatMap(items => items.map(item => item.data)).sort())];
          return {
            name,
            category: dates.map(date => moment(date).format('DD/MM/YYYY')),
            data: rawMaterialItems.flatMap(items => {
              const inbound = Array.from({ length: dates.length }, () => 0);
              const outbound = Array.from({ length: dates.length }, () => 0);

              items.forEach(item => {
                const index = dates.findIndex(date => date === item.data);
                if (item.tipo === 'ENTRADA') {
                  inbound[index] = item.volume
                } else {
                  outbound[index] = item.volume
                }
              })

              return [{
                name: `${items[0].nome_materia_prima} - ENTRADA`,
                items: inbound
              }, {
                name: `${items[0].nome_materia_prima} - SAÍDA`,
                items: outbound
              }];
            }),
          }
        })

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
