<template>
  <v-dialog
    v-model="visible"
    persistent
    max-width="400px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">{{ title }}</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="category.name"
                label="Categoria *"
                append-outer-icon="send"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey darken-1"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

const emptyForm = {
  id: null,
  name: '',
  default: false,
}

export default {
  data() {
    return {
      visible: false,
      title: '',
      category: { ...emptyForm },
    }
  },

  methods: {
    show(category = {}) {
      this.visible = true;
      this.category = {
        ...this.category,
        ...category,
      };

      this.title = isEmpty(category.id) ? 'Nova Categoria' : 'Alterando Categoria';
    },

    close() {
      this.visible = false;
      this.category = { ...emptyForm };
    },

    save() {
      this.$emit('save', this.category);

      return this.close();
    }
  },

}
</script>
