<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-spacer />
          <v-col
            cols="6"
            sm="6"
            md="4"
            class="py-0"
          >
            <v-select
              v-model="filters.dateType"
              :items="dateTypes"
              label="Data de referência"
              prepend-inner-icon="assignment"
              clearable
              dark
              filled
              @change="load"
            />
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="4"
            class="py-0"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              hide-details
              :disabled="!filters.dateType"
              @change="load"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="producerDocuments"
        :loading="loading"
        :search="filters.search"
        dark
        dense
        @click:row="show"
      >
        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :disabled="!canApproval(item)"
                @click="onApproval(item)"
              >
                <v-list-item-icon>
                  <v-icon>pending_actions</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Aprovação</v-list-item-title>
              </v-list-item>
              <v-list-item @click="show(item)">
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="print(item)">
                <v-list-item-icon>
                  <v-icon>print</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Imprimir</v-list-item-title>
              </v-list-item>
              <v-list-item @click="remove(item)">
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template #[`item.approval.status`]="{ item }">
          <v-btn
            v-if="canApproval(item)"
            icon
            @click.stop="onApproval(item)"
          >
            <approval-chip :value="item.approval" />
          </v-btn>
          <approval-chip
            v-else
            :value="item.approval"
          />
        </template>

        <template #[`item.status`]="{ value }">
          <v-chip
            v-if="value"
            class="mt-1"
            small
            :color="getStatusColor(value)"
            text-color="black"
          >
            {{ value }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <approval-dialog
      :id="approvalDialog.id"
      v-model="approvalDialog.show"
      :producer-documents="producerDocuments"
      @close="onCloseApproval()"
      @save="onSaveApproval()"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="add()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Novo Documento</span>
    </v-tooltip>

    <producer-document-dialog
      v-model="showDialog"
      :base-documents="documents"
      :edit="edit"
      @save="load"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import ProducerDocumentDialog from "@/Domains/Registrations/Documents/Views/ProducerDocumentDialog.vue";
import ApprovalChip from '@/Domains/Orders/Components/ApprovalChip.vue';
import ApprovalDialog from '@/Domains/Registrations/Documents/Views/ApprovalDialog.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {
  components: {
    ProducerDocumentDialog,
    ApprovalChip,
    ApprovalDialog,
    VDateRangePicker
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,
      showDialog: false,

      filters: {
        search: null,
        dateType: 'ASSINATURA',
      },
      dateTypes: ['ASSINATURA', 'ENVIO'],

      headers: [
        { text: "Produtor", value: "producer.name" },
        { text: "Documento", value: "title" },
        { text: "Data Envio", value: "createdAt" },
        { text: "Status", value: "status" },
        { text: "Data ass. produtor", value: "signatureDate" },
        { text: 'Aprovação', value: 'approval.status', align: 'center', width: 150,  },
        { text: "", value: "action", width: 30, sortable: false },
      ],

      approvalDialog: {
        show: false,
        id: null,
      },

      edit: {},
      producerDocuments: [],
      documents: [],
    };
  },

  computed: {
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasApproveOrSignDocumentsAccess() {
      return this.userResources.some(o => o.recurso === "approve-or-sign-documents" && o.tipo === "COMPONENTE");
    },
  },

  created() {
    this.load();
  },

  mounted() {
    this.load();
  },

  methods: {
    add() {
      this.edit = null;
      this.showDialog = true;
    },

    show(item) {

      this.edit = item;
      this.showDialog = true;
    },

    async load() {
      try {
        this.loading = true;

        const payload = {
          search: this.filters.search,
          dateType: this.filters.dateType,
          startDate: this.filters.dateRange ? moment(this.filters.dateRange[0]).format("YYYY-MM-DD") : null,
          endDate: this.filters.dateRange ? moment(this.filters.dateRange[1]).format("YYYY-MM-DD") : null,
        };

        const [documents, producerDocuments] = await Promise.all([
          this.$axios.get(`/registrations/document`),
          this.$axios.get(`/registrations/producerDocument`, { params: payload }),
        ]);

        this.documents = documents.data.map((item) => {
          return {
            id: item.id,
            title: item.titulo,
            text: item.texto,
            emails: item.emails
          };
        });

        this.producerDocuments = producerDocuments.data.map((item) => {
          let approvalLine = 'PENDENTE';
          let approvalDateLine = '';

          if (item.approval != null) {
            approvalLine = item.approval.aprovacao;
            approvalDateLine = item.approval.data_hora_aprovacao
          }

          const signatureDate = item.data_assinatura ? moment(item.data_assinatura) : null;

          return {
            id: item.id,
            title: item.titulo,
            text: item.texto_base,
            replacedText: item.texto.replace(/\n/g, '<br>'),
            status: item.assinatura ? "Assinado" : "Aguardando Assinatura",
            createdAt: item.data_hora_cadastro ? moment(item.data_hora_cadastro).format("DD/MM/YYYY HH:mm") : "",
            producer: {
              id: item.producer.id_pessoa,
              name: item.producer.nome,
            },
            approval: {
              status: approvalLine,
              date: approvalDateLine,
            },
            signatureDate: signatureDate ? signatureDate.format("DD/MM/YYYY") : "",
          };
        });
      } catch (error) {
        this.$snotify.error("Erro ao carregar documentos.", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async remove({ id }) {
      if (
        !(await this.$root.$confirm("Remover Documento", "Tem certeza que deseja remover este documento?", {
          color: "red",
        }))
      ) {
        return;
      }

      try {
        this.loading = true;
        await this.$axios.delete(`/registrations/producerDocument/${id}`);
        this.producerDocuments.splice(
          this.producerDocuments.findIndex((item) => item.id === id),
          1
        );
        this.$snotify.success("Documento removido com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao remover documento.", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async print({ id }) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/registrations/producerDocumentPrint`, {
          id_documento: id,
        });

        return this.printJS({
          printable: data,
          type: "pdf",
          base64: true,
        });
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao imprimir o documento!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    canApproval() {
      if (!this.hasApproveOrSignDocumentsAccess) {
        return false;
      }

      return true;
    },

    onApproval(item) {
      this.approvalDialog.id = item.id;
      this.approvalDialog.show = true;
    },

    onSaveApproval() {
      this.onCloseApproval();
      this.load();
    },

    onCloseApproval() {
      this.approvalDialog.show = false;
      this.approvalDialog.id = null;
    },

    getStatusColor(value) {
      const types = {
        'Assinado': 'green',
        'Aguardando Assinatura': '#FB8C00',
      }
      return types[value] || '#FB8C00'
    },
  },
};
</script>
