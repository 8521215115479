<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        engineering
      </v-icon>
      <span class="title font-weight-light">
        Média Preço Leite por Técnicos
      </span>
    </v-card-title>
    <v-tabs
      v-model="tab"
      align-with-title
      background-color="transparent"
      class="transparent flex-grow-0"
      dark
    >
      <v-tabs-slider color="cyan" />

      <v-tab>Gráfico</v-tab>
      <v-tab>Relatório</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="flex-grow-1 technician-tabs-items"
    >
      <v-tab-item>
        <v-chart
          :options="options"
          :style="{width: '100%', height: '100% !important'}"
          autoresize
        />
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="technicians"
          dark
          dense
          class="elevation-1 report-table"
          hide-default-footer
          disable-pagination
        >
          <template #[`item.totalVol`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.avgPrice`]="{ value }">
            {{ formatCurrency(value) }}
          </template>
          <template #[`item.totalPrice`]="{ value }">
            {{ formatCurrency(value) }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style lang="scss">
.technician-tabs-items {
  .v-window__container, .v-window-item {
    height: 100%;
  }

  > div {
    overflow-y: auto;

    .v-data-table {
      flex-grow: 1 !important;

      .v-data-table__wrapper {
        height: 100%;
        background: rgba(255, 255, 255, 0.08);
      }
    }
  }
}
</style>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '500px',
    },
    referenceMonth: {
      type: String,
      default: () => moment().subtract(1, "month").format("YYYY-MM"),
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
  },

  data() {
    return {
      loading: false,
      tab: null,

      headers: [
        { text: 'Técnico', value: 'technician' },
        { text: 'Volume Total', value: 'totalVol', align: 'center' },
        { text: 'Valor Médio', value: 'avgPrice', align: 'center' },
        { text: 'Valor Total', value: 'totalPrice', align: 'right' },
      ],
      technicians: [],
      average: 0
    }
  },

  computed: {
    options() {
      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => {
                const formatter = (value) => param.seriesName === 'Volume Total'
                  ? this.formatNumber(value) + ' L'
                  : this.formatCurrency(param.value)
                return `${param.marker} ${param.seriesName}: <b>${formatter(param.value)}</b>`
              })
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.technicians.map(route => route.technician),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.8)',
              formatter: (value) => this.formatCurrency(value)
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            }
          },
          {
            type: 'value',
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.8)',
              formatter: (value) => `${this.formatNumber(value)} L`
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            }
          }
        ],
        series: [
          {
            name: 'Preço Médio',
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 17,
              height: 17,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              formatter: ({ value }) => `  ${this.formatCurrency(value)}  `
            },
            data: this.technicians.map((route) => route.avgPrice)
          },
          {
            name: 'Volume Total',
            type: 'line',
            yAxisIndex: 1,
            lineStyle: { width: 2 },
            showSymbol: false,
            smooth: this.smooth,
            emphasis: { focus: 'series' },
            label: {
              show: true,
              lineHeight: 17,
              height: 17,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              formatter: ({ value }) => `  ${this.formatCurrency(value)}  `
            },
            data: this.technicians.map((route) => route.totalVol)
          }
        ]
      }
    }
  },

  watch: {
    referenceMonth() {
      this.loadReport();
    }
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {

        let { data } = await this.$axios.post(
          `/tabelaPreco/precoLeitePorTecnico`,
          { mes_referencia: this.referenceMonth }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.technicians = data.map(item => ({
          id: item.id_tecnico,
          technician: item.nome_tecnico,
          totalVol: parseFloat(item.volume_total),
          avgPrice: parseFloat(item.valor_medio),
          totalPrice: parseFloat(item.valor_total),
        }));

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de Preço Leite por Técnicos!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
  }
}
</script>
