<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color, overflow: 'hidden'}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        icon-frota
      </v-icon>
      <span class="title font-weight-light">Tempo médio de pátio (Horas)</span>
    </v-card-title>

    <v-card-text class="pb-0">
      <div class="d-flex white--text justify-space-between">
        <div class="d-flex flex-column text-left white--text">
          <span class="subtitle-2 font-weight-light">Tempo médio</span>
          <span class="display-1">{{ formatTime(average) }}</span>
        </div>
      </div>
    </v-card-text>
    <v-chart
      :options="options"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import MomentDuration from "moment-duration-format";
import _ from 'lodash';

MomentDuration(moment);

export default {
  props: {
    color: {
      type: String,
      default: '#26c6da'
    },
    graphColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    height: {
      type: String,
      default: '100px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      graph: {},
      average: 0
    }
  },

  computed: {
    options() {
      const values = Object.values(this.graph);
      return {
        color: [this.graphColor],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: ([param]) => `${param.name}<br>${param.marker} ${param.seriesName} <b>${this.formatTime(param.value)}</b>`
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Object.keys(this.graph),
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'time',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
        },
        series: [{
          name: 'Tempo médio',
          type: 'line',
          areaStyle: {},
          lineStyle: { width: 2 },
          showSymbol: true,
          smooth: false,
          emphasis: {
            focus: 'series'
          },
          data: values.map((value, index) => ({
            value,
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: index === 0 ? 'right' : index === values.length - 1 ? 'left' : 'inside',
              formatter: ({ value }) => `  ${this.formatTime(value)}  `
            }
          }))
        }]
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/descargaColeta/tempoMedioPatio`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.graph = data.reduce((acc, cur) => {
          acc[moment(cur.data).format('DD/MM/YYYY')] = moment(cur.tempo_medio, 'HH:mm').toDate();
          return acc;
        }, {});

        const averageSeconds = data.reduce((acc, cur) => (acc + moment.duration(cur.tempo_medio).asSeconds()), 0);
        this.average = moment().startOf('day').seconds(averageSeconds);

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de tempo médio de pátio!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatTime: value => moment(value).format('HH:mm')
  }
}
</script>
