<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Taxa de conversão por Litro de Leite</span>
    </v-card-title>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import debounce from 'lodash/debounce';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '500px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    products: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      loading: false,
      items: [],
    }
  },

  computed: {
    options() {
      return {
        color: ['rgba(171, 71, 188, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => {
                const units = {
                  'Volume': 'L',
                  'Taxa de conversão': '%',
                }
                return `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)} ${units[param.seriesName] || ''}</b>`
              }),
              `⚈ Produzido: <b>${this.formatNumber(this.items[params[0].dataIndex].total)}</b>`
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: this.items.map(item => item.nome),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.8)',
              formatter: (value) => `${this.formatNumber(value)}%`
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
          },
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff',
              formatter: (value) => `${this.formatNumber(value)} L`
            }
          }
        ],
        series: [
          {
            name: 'Taxa de conversão',
            type: 'bar',

            itemStyle: {
              borderRadius: 0
            },

            label: {
              show: true,
              position: 'inside',
              formatter: ({ value }) => `${this.formatNumber(value)}%`,
              textShadowColor: '#000',
              textShadowBlur: 0.5,
              textBorderWidth: 0,
              textBorderColor: 'transparent'
            },
            data: this.items.map(item => item.taxa_conversao)
          },
          {
            name: 'Volume',
            type: 'line',
            yAxisIndex: 1,
            lineStyle: { width: 2 },
            showSymbol: false,
            smooth: this.smooth,
            emphasis: { focus: 'series' },
            data: this.items.map((item) => item.base),
          }
        ]
      }
    }
  },

  watch: {
    period() {
      this.onFilter();
    },
    products() {
      this.onFilter();
    },
  },

  created() {
    this.loadReport()
  },

  methods: {
    onFilter: debounce(function () {
      this.loadReport()
    }, 500),

    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.get(`/production-order/conversion-rate`, { params: {
          start_date: startDate, end_date: endDate, products: this.products,
        } });

        this.items = data;
      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório de Taxa de conversão!', 'Atenção');
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
