<template>
  <div
    v-if="item.value"
    class="d-flex flex-column align-center"
  >
    <span>
      {{ formatNumber(item.value) }}
    </span>
    <v-chip x-small>
      <v-avatar left>
        <v-icon small>
          biotech
        </v-icon>
      </v-avatar>
      {{ item.sample }}
    </v-chip>
    <v-chip x-small>
      <v-avatar left>
        <v-icon small>
          today
        </v-icon>
      </v-avatar>
      {{ formatDate(item.date, 'DD/MM/YYYY') }}
    </v-chip>
  </div>
  <span v-else>-</span>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    item: Object
  },

  methods: {
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? '-' : new Intl.NumberFormat('pt-BR').format(value),
  }
}

</script>