<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <card-title-settings class="mb-3">
      <span
        v-if="!!product.name"
        class="text-h6"
      >
        Produção de {{ product.name }}
      </span>
      <span
        v-if="!!product.duration"
        class="ml-2 text-h7"
      >
        ({{ product.duration }})
      </span>
      <v-spacer />
      <v-switch
        v-if="!!orderId"
        v-model="showAll"
        label="Exibir todas"
        hide-details
        inset
        dense
        class="ma-0"
      />
    </card-title-settings>

    <div class="appointment-grid">
      <template v-for="(step, idx) in steps">
        <div
          v-if="!step.id || stepsAccess.includes(step.id)"
          :key="idx"
          class="grid-row"
        >
          <v-card
            color="transparent"
            dark
            class="mb-4"
          >
            <v-card-title
              class="text-subtitle-2 pt-2 pb-2"
              style="background: rgba(0, 0, 0, 0.5);"
            >
              <span>
                {{ step.description }}
              </span>

              <span
                v-if="!!step.duration"
                class="ml-2 text-overline"
              >
                ({{ step.duration }})
              </span>

              <v-spacer />

              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    :disabled="step.orders.length === 0"
                    @click="printOrders(step)"
                  >
                    <v-list-item-icon>
                      <v-icon>print</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Imprimir itens</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>

            <v-card-text
              class="pa-0"
              style="background: rgba(0, 0, 0, 0.3);"
            >
              <draggable
                class="appointment-orders noselect px-2 pt-2"
                v-bind="{
                  animation: 200,
                  disabled: false,
                  ghostClass: 'ghost',
                  delay: 100,
                  delayOnTouchOnly: true,
                  handle: '.handle',
                }"
                :list="step.orders"
                :group="{ name: 'step' }"
                @change="onStepChange($event, idx)"
              >
                <transition-group type="transition">
                  <template v-for="(order, index) in step.orders">
                    <v-card
                      v-if="showAll || order.id === orderId || !orderId"
                      :key="`${index}-${order.id}`"
                      dark
                      color="transparent"
                      class="mb-2"
                      :outlined="order.id === orderId"
                      :style="order.id === orderId ? 'border-color: rgba(255,255,255,.4) !important;' : ''"
                    >
                      <v-card-title
                        class="handle text-subtitle-2 pt-2 pb-2"
                        style="background: rgba(0,0,0,.2);"
                      >
                        <span>
                          {{ order.description }}
                        </span>

                        <v-spacer />

                        <v-menu
                          bottom
                          right
                        >
                          <template #activator="{ on }">
                            <v-btn
                              icon
                              v-on="on"
                            >
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                              @click="printProducts(order, step)"
                            >
                              <v-list-item-icon>
                                <v-icon>print</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Imprimir itens</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-card-title>
                      <v-divider />
                      <v-card-text
                        class="pa-0 text-left"
                      >
                        <v-list color="transparent">
                          <v-list-item>
                            <v-list-item-content>
                              Código: {{ order.code }}
                            </v-list-item-content>
                            <v-list-item-content>
                              Lote: {{ order.lotNumber }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              Quant. plan.: {{ formatNumber(order.quantity) }}
                            </v-list-item-content>
                            <v-list-item-content>
                              Pedido: {{ formatDate(order.date, 'DD/MM/YYYY') }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          v-if="idx > 0"
                          dark
                          outlined
                          icon
                          @click="onManualStepChange(index, idx, idx - 1)"
                        >
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-icon v-on="on">
                                arrow_back
                              </v-icon>
                            </template>
                            <span>Etapa Anterior</span>
                          </v-tooltip>
                        </v-btn>

                        <v-btn
                          v-if="idx < steps.length - 1"
                          dark
                          outlined
                          icon
                          @click="onManualStepChange(index, idx, idx + 1)"
                        >
                          <v-tooltip top>
                            <template #activator="{ on }">
                              <v-icon v-on="on">
                                arrow_forward
                              </v-icon>
                            </template>
                            <span>Próxima Etapa</span>
                          </v-tooltip>
                        </v-btn>

                        <v-spacer />

                        <v-btn
                          v-if="hasAppointmentAccess"
                          dark
                          outlined
                          @click="addAppointment(order, step)"
                        >
                          APONTAMENTO
                        </v-btn>

                        <v-btn
                          v-if="hasByproductEntryAccess && order.stepByproducts.length > 0"
                          dark
                          outlined
                          :disabled="!order.isByproductEnabled"
                          @click="onByproductEntry(order, step)"
                        >
                          ENTRADA
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </transition-group>
              </draggable>
            </v-card-text>
          </v-card>
        </div>
      </template>
    </div>

    <appointment-dialog
      v-model="appointmentDialog.show"
      :order-id="appointmentDialog.orderId"
      :step="appointmentDialog.step"
      @input="updateAppointmentOrder"
    />

    <byproduct-entry-dialog
      v-model="byproductEntryDialog.show"
      :order-id="byproductEntryDialog.orderId"
      :step="byproductEntryDialog.step"
      @input="updateEntryOrder"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          class="mr-2"
          color="blue"
          v-on="on"
          @click="loadAppointment"
        >
          <v-icon>refresh</v-icon>
        </v-btn>
      </template>

      <span>Recarregar</span>
    </v-tooltip>
  </v-container>
</template>

<style lang="scss">
.appointment-grid {
  display: grid;
  grid-column-gap: 12px;
  overflow: auto;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  .grid-row {
    grid-row: 1;
    min-width: 280px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: table-cell;
    margin: 0;
    position: relative;
    vertical-align: top;
  }

  .appointment-orders {
    display: flex;

    .handle {
      cursor: grab;
    }

    &>span {
      min-height: 220px;
    }

    .step-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &>span {
      display: inline-block;
      width: 100%;
      min-height: 100px * $scale;

      &:empty:before {
        width: 100%;
        display: inline-block;
        padding: (35px * $scale) (16px * $scale);
        content: "Vazio";
        text-overflow: ellipsis;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    > .ghost {
      opacity: 0.3;
    }
  }
}
</style>

<script>
import AppointmentDialog from '@/Domains/Industry/ProductionOrder/Components/AppointmentDialog.vue';
import ByproductEntryDialog from '@/Domains/Industry/ProductionOrder/Components/ByproductEntryDialog.vue';
import CardTitleSettings from '@/Domains/Settings/Components/General/CardTitleSettings.vue'

import Draggable from 'vuedraggable';
import printJS from 'print-js';
import moment from 'moment';
import MomentDuration from 'moment-duration-format';
import get from 'lodash/get';

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

MomentDuration(moment);

export default {

  components: {
    AppointmentDialog,
    ByproductEntryDialog,
    CardTitleSettings,
    Draggable,
  },

  props: {
    itemId: String,
    orderId: String,
  },

  data() {
    return {
      loading: false,

      showAll: false,

      product: {},

      steps: [],

      appointmentDialog: {
        show: false,
        orderId: null,
        step: null,
      },

      byproductEntryDialog: {
        show: false,
        orderId: null,
        step: null,
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        positive: v => v > 0 || 'Valor inválido',
      },
    };
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    userId() {
      return this.$store.state.settings.user.id;
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasAppointmentAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-apontamentos' && o.tipo === 'COMPONENTE');
    },
    hasByproductEntryAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-entrada-subproduto' && o.tipo === 'COMPONENTE');
    },
    hasFullStepsAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-acesso-etapas' && o.tipo === 'COMPONENTE');
    },
    stepsAccess() {
      if (this.hasFullStepsAccess) {
        return this.steps.map(step => step.id);
      }

      return this.steps
        .filter(step => step.access.length === 0 || step.access.includes(this.userId))
        .map(step => step.id);
    }
  },

  mounted() {
    this.loadAppointment();
  },

  methods: {
    async loadAppointment() {
      try {
        this.$root.$progressBar.loading();

        const data = await api.getAppointments(this.itemId);

        this.product = {
          id: data.produto.id_item,
          code: data.produto.cod_item,
          name: data.produto.nome,
          measurement: data.produto.unidade,
          isByproductEnabled: data.produto.parametros_producao?.subproduto_habilitado,
        };

        this.steps = (data.produto.etapas_producao || [])
          .map(step => ({
            id: step.id,
            description: step.descricao,
            duration: step.duracao?.substring(0, 5),
            access: step.acessos || [],
            orders: [],
          }));

        const totalMinutes = this.steps.reduce((acc, step) => {
          if (step.duration) {
            acc += moment.duration(step.duration).asMinutes();
          }
          return acc;
        }, 0);

        if (totalMinutes) {
          this.product.duration = moment.duration(totalMinutes, 'minutes').format('HH:mm');
        }

        if (this.steps.length === 0) {
          this.steps = [{
            id: null,
            description: 'Apontamento',
            duration: null,
            orders: [],
          }];
        }

        for (const ordem of data.ordens) {
          const order = {
            id: ordem.id_ordem_producao,
            code: ordem.codigo,
            status: ordem.status,
            description: ordem.descricao,
            quantity: parseFloat(ordem.quantidade_planejada),
            lotNumber: ordem.numero_lote,
            date: ordem.data_pedido,
            items: ordem.itens
              .map((item) => ({
                id: item.id_ordem_producao_item,
                type: item.tipo,
                itemId: item.id_item,
                quantity: parseFloat(item.quantidade_base),
                plannedQuantity: parseFloat(item.quantidade_base) * parseFloat(ordem.quantidade_planejada),
                accomplishedQuantity: parseFloat(item.quantidade_realizada),
                writeOffType: item.tipo_baixa,
                step: item.id_etapa ? { id: item.id_etapa, description: item.etapa } : undefined,
                active: 1,
              })),
            byproducts: ordem.subprodutos
              .map((item) => ({
                id: item.id_ordem_producao_subproduto,
                type: item.tipo,
                quantity: parseFloat(item.quantidade_base),
                plannedQuantity: parseFloat(item.quantidade_base) * parseFloat(ordem.quantidade_planejada),
                accomplishedQuantity: parseFloat(item.quantidade_realizada),
                entryType: item.tipo_entrada,
                step: item.id_etapa ? { id: item.id_etapa, description: item.etapa } : undefined,
                active: 1,
              })),
            stepItems: [],
            stepByproducts: [],
            isByproductEnabled: false,
          };

          let index = this.steps.findIndex(step => step.id === ordem.id_etapa);

          if (index === -1) {
            index = 0;
          }

          this.steps[index].orders.push(order);

          this.updateOrderStep(index, this.steps[index].orders.length - 1);
        }
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao carregar ordens');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    updateOrderStep(index, orderIndex) {
      const order = this.steps[index].orders[orderIndex];
      const step = this.steps[index];

      order.stepItems = order.items.filter(item => item.step?.id == step.id);
      order.stepByproducts = order.byproducts.filter(item => item.step?.id == step.id);
      order.isByproductEnabled = this.product.isByproductEnabled || order.stepItems.length === 0 || order.stepItems.some(item => item.accomplishedQuantity > 0);
    },

    addAppointment({ id }, step) {
      this.appointmentDialog = {
        show: true,
        orderId: id,
        step: step,
      };
    },

    onByproductEntry({ id }, step) {
      this.byproductEntryDialog = {
        show: true,
        orderId: id,
        step: step,
      };
    },

    printOrders({ orders, description: step }) {
      const ids = orders.map(order => order.id);
      this.print(ids, step);
    },

    printProducts({ id }, step) {
      this.print([id], step);
    },

    async print(ids, step) {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/production-order/print-needs`, { params: {
          ids: ids,
          id_etapa: step.id,
          etapa: step.description,
          params: {
            mostra_quantidade_planejada: this.hasPlannedQuantityAccess
          }
        } });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.warn(e);
        this.$snotify.error('Oops, ocorreu um erro ao imprimir a Lista de Necessidades!');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onStepChange({ added }, stepIdx) {
      if (!added) return;

      const order = added.element;

      const step = this.steps[stepIdx];

      const orderIndex = step.orders.findIndex(item => item.id === order.id);

      this.updateOrderStep(stepIdx, orderIndex);

      this.changeStep(order, step);
    },

    /**
     * Move order from current step to new step
     * @param int orderIndex Index of order
     * @param int currentStepIndex Index of current step
     * @param int newStepIndex Index of new step
     */
    async onManualStepChange(orderIndex, currentStepIndex, newStepIndex) {
      const order = this.steps[currentStepIndex].orders[orderIndex];
      const step = this.steps[newStepIndex];

      const pendingItems = order.stepItems.some(item => item.accomplishedQuantity == 0);
      const pendingByproducts = order.stepByproducts.some(item => item.accomplishedQuantity == 0);

      if (pendingItems || pendingByproducts) {
        if (!(await this.$root.$confirm(
          `Alterar etapa para ${step.description}?`,
          `Ordem de Produção nº ${order.code}<br><b>Há itens pendentes à serem concluídos nesta etapa!</b>`,
          { color: 'orange' }
        ))) {
          return;
        }
      } else {
        if (!(await this.$root.$confirm(
          `Alterar etapa para ${step.description}?`,
          `Ordem de Produção nº ${order.code}`,
          { color: 'blue' }
        ))) {
          return;
        }
      }

      /** Remove order from current step */
      this.steps[currentStepIndex].orders.splice(orderIndex, 1);

      /** Add order to new step */
      this.steps[newStepIndex].orders.unshift(order);

      this.changeStep(order, step);

      this.updateOrderStep(newStepIndex, 0);
    },

    async changeStep(order, step) {
      try {
        this.$root.$progressBar.saving();

        const payload = {
          id_etapa: step.id,
          etapa: step.description,
        };

        await api.update(order.id, payload);

        this.$snotify.success('Etapa de produção alterada com sucesso');
      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao alterar etapa de produção');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    updateAppointmentOrder() {
      const stepIndex = this.steps.findIndex(step => step.id === this.appointmentDialog.step.id);
      const orderIndex = this.steps[stepIndex].orders.findIndex(order => order.id === this.appointmentDialog.orderId);
      this.updateOrder(stepIndex, orderIndex);

    },

    updateEntryOrder() {
      const stepIndex = this.steps.findIndex(step => step.id === this.byproductEntryDialog.step.id);
      const orderIndex = this.steps[stepIndex].orders.findIndex(order => order.id === this.byproductEntryDialog.orderId);
      this.updateOrder(stepIndex, orderIndex);
    },

    async updateOrder(stepIndex, orderIndex) {
      try {
        this.$root.$progressBar.loading();

        const order = this.steps[stepIndex].orders[orderIndex];

        const data = await api.show(order.id);

        order.items = data.items;
        order.byproducts = data.byproducts;

        this.updateOrderStep(stepIndex, orderIndex);

      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao atualizar ordem de produção');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? '-' : new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>

