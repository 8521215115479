<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="orders"
  >
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent text-left"
        @change="onTabChange"
      >
        <v-tab>Pedidos de produtores</v-tab>
        <v-tab
          :disabled="!order.show"
        >
          Pedido
        </v-tab>

        <v-tab-item>
          <OrdersList
            ref="ordersList"
            type="PEDIDO_PRODUTOR"
            @showOrder="onShowOrder"
          />
        </v-tab-item>
        <v-tab-item>
          <Order
            v-if="order.show"
            type="PEDIDO_PRODUTOR"
            :order-id="order.id"
            @newOrder="onNewOrder"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import Order from '@/Domains/Orders/Components/Order.vue'
import OrdersList from '@/Domains/Orders/Components/OrdersList.vue'

export default {
  name: "producers-orders",

  components: {
    Order,
    OrdersList,
  },

  data() {
    return {
      tab: null,
      orderId: null,
      order: {
        id: null,
        show: false,
      }
    };
  },

  methods: {
    /**
     * @event void
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange(idx) {
      if (idx == 0) {
        this.order = {
          id: null,
          show: false,
        };
        setTimeout(() => {
          // Atraso de atualização para dar tempo de replicar no Postgres
          this.$refs.ordersList.getOrders();
        }, 1000);
      }
    },
    onShowOrder(order) {
      this.order = {
        id: order.id,
        show: true,
      };
      this.tab = 1;
    },
    onNewOrder(id) {
      if (!id) {
        this.order = {
          id: null,
          show: false,
        };
        this.tab = 0;
      }
    },
  },
};
</script>