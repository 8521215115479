<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <!-- <v-row>
      <v-spacer />
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          :ranges="filter.ranges"
          dark
          @change="getFinancialSchedule"
        />
      </v-col>
    </v-row> -->

    <v-card
      class="report-card"
      color="transparent"
      dark
    >
      <v-card-title>
        Cronograma Financeiro
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        dark
        dense
        disable-sort
        hide-default-footer
        class="elevation-1 report-table"
      >
        <template #[`item.monthYear`]="{ value }">
          {{ value | dateFormat('MMM/YY') | capitalize }}
        </template>
        <template #[`item.predicted`]="{ value }">
          R$ {{ value | formatCurrency }}
        </template>
        <template #[`item.accomplished`]="{ value }">
          R$ {{ value | formatCurrency }}
        </template>
        <template #[`item.balance`]="{ value }">
          R$ {{ value | formatCurrency }}
        </template>
        <template
          v-if="items.length > 0"
          #[`body.append`]="{}"
        >
          <tr>
            <td class="text-start">
              <b>Total</b>
            </td>
            <td class="text-start">
              <b>R$ {{ total.predicted | formatCurrency }}</b>
            </td>
            <td class="text-start">
              <b>R$ {{ total.accomplished | formatCurrency }}</b>
            </td>
            <td class="text-end">
              <b>R$ {{ total.balance | formatCurrency }}</b>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-title />
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue darken-1"
        @click="getFinancialSchedule()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>

          Recarregar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
//import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";

moment.locale('pt'); 

export default {
  name: "pmls-financial",

  components: {
    //VDateRangePicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    capitalize: (value) => (typeof value !== 'string') ? '' : value.charAt(0).toUpperCase() + value.slice(1)
  },

  mixins: [ReportMixin],

  props: ["parent"],

  data() {
    return {
      loading: false,

      filter: {
        ranges: {
          'Este ano': [moment().startOf("year").format("YYYY-MM-DD"), moment().endOf("year").format("YYYY-MM-DD")],
          'Ano anterior': [moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"), moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD")],
        },

        range: [],
      },

      headers: [
        { text: 'Mês/Ano', value: 'monthYear' },
        { text: 'Previsto', value: 'predicted' },
        { text: 'Realizado', value: 'accomplished' },
        { text: 'Saldo', value: 'balance', align: 'end' },
      ],

      items: [],
    };
  },

  computed: {
    total() {
      return this.items.reduce((acc, cur) => ({
        predicted: acc.predicted + cur.predicted,
        accomplished: acc.accomplished + cur.accomplished,
        balance: acc.balance + cur.balance,
      }), { predicted: 0, accomplished: 0, balance: 0 })
    }
  },

  created() {
    this.getFinancialSchedule();
  },

  methods: {
    async getFinancialSchedule() {
      const [startDate, endDate] = this.filter.range;

      const { data } = await this.$axios.post(`/projetos/cronogramaFinanceiro`, {
        data_inicio: startDate,
        data_fim: endDate
      });

      this.items = data.map(p => ({
        monthYear: p.mes_ano,
        predicted: p.previsto,
        accomplished: p.realizado,
        balance: p.saldo,
      }))
    },

    getReportJson() {
      return [
          ...this.items.map(item => ({
            'Mês/Ano': this.$options.filters.dateFormat(item.monthYear, 'MMM/YY'),
            Previsto: `R$ ${this.$options.filters.formatCurrency(item.predicted)}`,
            Realizado: `R$ ${this.$options.filters.formatCurrency(item.accomplished)}`,
            Saldo: `R$ ${this.$options.filters.formatCurrency(item.balance)}`,
        })),
        {
            'Mês/Ano': 'Total',
            Previsto: `R$ ${this.$options.filters.formatCurrency(this.total.predicted)}`,
            Realizado: `R$ ${this.$options.filters.formatCurrency(this.total.accomplished)}`,
            Saldo: `R$ ${this.$options.filters.formatCurrency(this.total.balance)}`,
        }
      ];
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      let data = this.XLSX.utils.json_to_sheet(this.getReportJson(), {origin: 'A3'});

      data["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }];
      
      data['A1'] = { v: 'Cronograma financeiro' };

      data['!cols'] = [
        { wch: 10 },
        { wch: 14 },
        { wch: 14 },
        { wch: 14 }
      ];

      const workbook = this.XLSX.utils.book_new();

      const filename = "cronograma_financeiro";
      
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /**
     * Imprime o relatório
     */
    print() {
      return this.printFromJson(this.getReportJson(), "Cronograma financeiro");
    },
  },
};
</script>