<template>
  <v-card
    class="mx-auto"
    dark
    color="transparent"
    :loading="loading"
    :style="{height: height}"
  >
    <v-row
      no-gutters
      style="overflow: hidden; height: 100%;"
    >
      <v-col
        cols="8"
        class="d-flex py-0 pr-0"
        :style="{background: 'rgba(0, 0, 0, 0.4)'}"
      >
        <v-card-text class="white--text text-left flex-grow-1 d-flex flex-column">
          <span class="title font-weight-light flex-grow-0">{{ title }}</span>
          <v-row class="flex-grow-1">
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="subtitle-2 font-weight-light">
                Litros/Km
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      small
                      color="rgba(255, 255, 255, 0.7)"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>
                  Média do período selecionado do total litros/total km
                </v-tooltip>
              </span>
              <span
                class="headline text-truncate"
              >{{ avg_liters_per_km | formatNumber }}</span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="caption font-weight-light">Tempo Total</span>
              <span
                class="body-1 font-weight-light text-truncate"
              >{{ total_time }}</span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="caption font-weight-light">Tempo Médio</span>
              <span
                class="body-1 font-weight-light text-truncate"
              >{{ avg_time }}</span>
            </v-col>
          </v-row>
          <span class="subtitle-2 font-weight-light d-block mt-2 flex-grow-0">Quilômetros rodados</span>
          <v-row class="flex-grow-1">
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="subtitle-2 font-weight-light">Total</span>
              <span
                class="title font-weight-regular text-truncate"
              >{{ total_km | formatNumber }} km</span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="caption font-weight-light">Diário</span>
              <span
                class="body-1 font-weight-light text-truncate"
              >{{ avg_km_day | formatNumber }}</span>
            </v-col>
            <v-col
              cols="4"
              class="d-flex flex-column justify-end py-0"
              style="border-left: 2px solid #4DB6AC"
            >
              <span class="caption font-weight-light">Mensal</span>
              <span
                class="body-1 font-weight-light text-truncate"
              >{{ avg_km_month | formatNumber }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col
        cols="4"
        class="d-flex py-0"
        :style="{background: 'rgba(0, 0, 0, 0.5)'}"
      >
        <v-card-text class="py-0 white--text flex-grow-1 d-flex flex-column justify-center">
          <div
            class="d-flex flex-column py-0 text-center full-width"
          >
            <span class="subtitle-2">Frota</span>
            <v-icon
              large
              color="#1DE9B6"
            >
              local_shipping
            </v-icon>
            <span class="subtitle-1 mb-2">
              Total <b>{{ fleet.total }}</b>
            </span>
            <v-row>
              <v-col
                cols="2"
                class="pa-0"
              >
                <v-icon
                  small
                  color="#1DE9B6"
                >
                  local_shipping
                </v-icon>
              </v-col>
              <v-col
                cols="8"
                class="caption font-weight-light pa-0"
              >
                Coleta de leite
              </v-col>
              <v-col
                cols="2"
                class="caption pa-0"
              >
                {{ fleet.milk }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="pa-0"
              >
                <v-icon
                  small
                  color="#FDD835"
                >
                  engineering
                </v-icon>
              </v-col>
              <v-col
                cols="8"
                class="caption font-weight-light pa-0"
              >
                Assistência
              </v-col>
              <v-col
                cols="2"
                class="caption pa-0"
              >
                {{ fleet.assist }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="pa-0"
              >
                <v-icon
                  small
                  color="#64B5F6"
                >
                  adjust
                </v-icon>
              </v-col>
              <v-col
                cols="8"
                class="caption font-weight-light pa-0"
              >
                Outros
              </v-col>
              <v-col
                cols="2"
                class="caption pa-0"
              >
                {{ fleet.others }}
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {

  filters: {
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },

  props: {
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    fleetType: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: 'Coletas'
    },
    height: {
      type: String,
      default: '153px',
    },
  },

  data() {
    return {
      loading: false,
      total_time: "",
      avg_time: "",
      avg_liters_per_km: 0,
      total_km: 0,
      avg_km_day: 0,
      avg_km_month: 0,
      fleet: { milk: 0, assist: 0, others: 0, total: 0 }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {

        if (this.fleetType != "" && this.fleetType == 'internal') {
          var terceiro = 'nao'
        }
        if (this.fleetType != "" && this.fleetType == 'external') {
          terceiro = 'sim'
        }
        const [startDate, endDate] = this.period;

        let [{ data }, { data: fleet }] = await Promise.all([
          await this.$axios.post(
            `/itinerario/relatorio`,
            { data_inicio: startDate, data_fim: endDate, terceiro: terceiro }
          ),
          await this.$axios.post(
            `/equipamento/frota`,
            { terceiro: terceiro }
          ),
        ]);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.total_time = data.tempo_total ? data.tempo_total.replace(new RegExp("^[00:]+"), "") : "00:00";
        this.avg_time = data.tempo_medio ? data.tempo_medio.replace(new RegExp("^[00:]+"), "") : "00:00";
        this.avg_liters_per_km = data.litros_km_media || 0;
        this.total_km = data.km_total || 0;
        this.avg_km_day = data.km_dia_media || 0;
        this.avg_km_month = data.km_mes_media || 0;

        this.fleet = fleet.reduce((acc, cur) => {
          if (cur.tipo_carga == 'leite') {
            acc.milk += cur.total;
          }
          else if (['consultoria', 'apoio'].includes(cur.tipo_carga)) {
            acc.assist += cur.total;
          }
          else {
            acc.others += cur.total;
          }
          acc.total += cur.total;
          return acc;
        }, { milk: 0, assist: 0, others: 0, total: 0 });

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os dados da frota!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    }
  }

}
</script>
