<template>
  <price-index
    type="TECNICO"
  />
</template>

<script>
import PriceIndex from "@/Domains/Financial/Prices/PriceIndex.vue";

export default {
  components: {
    PriceIndex
  },
}
</script>
