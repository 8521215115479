var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0",attrs:{"text-xs-center":"","grid-list-lg":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"menu-header white--text"},[_vm._v(" Região/Localidade ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("arrow_left")]),_c('div',{staticClass:"pr-3"},[_vm._v(" Voltar ")])],1),_c('v-card',{attrs:{"dark":"","color":"transparent"}},[_c('v-card-title',[_c('v-col',{staticClass:"pa-0",attrs:{"md-6":"","offset-md":"6"}},[_c('v-text-field',{attrs:{"label":"Pesquisar","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
          { align: 'start', text: 'Descrição', value: 'description' },
          { text: 'Tipo', value: 'type' },
          { text: 'Volume', value: 'vol' },
          { text: 'Produtores', value: 'producers' },
          { text: 'Assistencia Tecnica', value: 'routeTechnicalAssist' },
          { text: 'Coleta de Leite', value: 'routeMilkCollect' },
          { align: 'end', text: '', value: 'actions', sortable: false },
        ],"items":_vm.filteredRegions},scopedSlots:_vm._u([{key:`item.type`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.types[value])+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.showEditingDialog(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(" Editar ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.excludeRegion(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v(" Excluir ")])],1)],1)],1)]}}],null,true)})],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"fixed":"","fab":"","large":"","dark":"","bottom":"","right":"","color":"purple mr-5"},on:{"click":function($event){return _vm.showCreatingDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Nova região")])]),_c('region-dialog',{attrs:{"edit-id":_vm.editId},on:{"update:editId":function($event){_vm.editId=$event},"update:edit-id":function($event){_vm.editId=$event},"save":_vm.loadRegions},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-card-text',[_vm._v(" Carregando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }