<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <v-card
        dark
        :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        :loading="loading"
      >
        <v-card-text>
          <v-chart
            :options="criticalOptions"
            :style="{width: '100%', height: `350px !important`}"
            autoresize
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-card
        dark
        :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        :loading="loading"
      >
        <v-card-text>
          <v-chart
            :options="priorityOptions"
            :style="{width: '100%', height: `350px !important`}"
            autoresize
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    month: {
      type: String,
      default: () => moment().endOf('month').format('YYYY-MM-DD')
    },
  },

  data() {
    return {
      loading: false,
      items: [],
    }
  },

  computed: {
    criticalOptions() {
      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(38, 198, 218, 0.7)'],
        title: {
          text: 'Críticos (Produtores com 2 médias trimestrais consecutivas acima de 300)',
          textStyle: { color: '#ddd' },
          subtextStyle: { color: '#ddd' }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
          formatter: items =>  [
            items[0].name,
            ...items.map(param => `${param.marker} ${param.seriesName} <b>${param.data}</b>`),
            `Visitados: <b>${this.items[items[0].dataIndex].perc_criticos_visitados} %</b>`
          ].join('<br>')
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.items.map(o => this.formatMonth(o.mes)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Críticos',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: this.items.map(o => o.total_criticos),
          },
          {
            name: 'Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            smooth: this.smooth,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: data => {
                return '  ' + data.value + ' - ' + this.items[data.dataIndex].perc_criticos_visitados + '%  ';
              },
            },
            data: this.items.map(o => o.total_criticos_visitados),
          },
          {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            smooth: this.smooth,
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: data => '  ' + this.items[data.dataIndex].meta_perc_visitas_criticos + '%  ',
              }
            },
            data: this.items.map(o => o.total_criticos * o.meta_perc_visitas_criticos / 100),
          }
        ]
      }
    },

    priorityOptions() {
      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(38, 198, 218, 0.7)'],
        title: {
          text: 'Prioritários (Produtores com média geométrica trimestral acima de 300 no mês selecionado)',
          textStyle: { color: '#ddd' },
          subtextStyle: { color: '#ddd' }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
          formatter: items =>  [
            items[0].name,
            ...items.map(param => `${param.marker} ${param.seriesName} <b>${param.data}</b>`),
            `Visitados: <b>${this.items[items[0].dataIndex].perc_prioritarios_visitados} %</b>`
          ].join('<br>')
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.items.map(o => this.formatMonth(o.mes)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Prioritários',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              }
            },
            data: this.items.map(o => o.total_prioritarios),
          },
          {
            name: 'Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            smooth: this.smooth,
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: data => {
                  return '  ' + data.value + ' - ' + this.items[data.dataIndex].perc_prioritarios_visitados + '%  ';
                },
              }
            },
            data: this.items.map(o => o.total_prioritarios_visitados),
          },
          {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            smooth: this.smooth,
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: data => '  ' + this.items[data.dataIndex].meta_perc_visitas_prioritarios + '%  ',
              }
            },
            data: this.items.map(o => o.total_prioritarios * o.meta_perc_visitas_prioritarios / 100),
          }
        ]
      }
    }
  },

  watch: {
    month() {
      this.loadReport();
    }
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/relatorioIn/visitasCriticosPrioritarios`,
          { date_ref: this.month }
        );

        if (!_.isArray(data)) {
          throw "PHP Error";
        }

        this.items = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de visitas", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
  }
}
</script>
