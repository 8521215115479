<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-flex xs12>
      <v-row
        v-if="view != 'goal'"
      >
        <v-col cols="12">
          <h2
            class="menu-header white--text"
          >
            Metas de Visita
          </h2>
          <v-btn
            text
            @click="$router.back()"
          >
            <v-icon>arrow_left</v-icon>
            <div class="pr-3">
              Voltar
            </div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.project"
            :items="projectList"
            label="Metas"
            item-value="id_assistencia"
            item-text="descricao"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadGoals"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.type"
            dark
            item-value="tipo_visita"
            item-text="nome"
            filled
            return-object
            label="Tipo de Visita"
            clearable
            hide-details
            :items="typeItems"
          />
        </v-col>
        <v-col
          md="3"
        >
          <person-autocomplete-filter
            v-model="filter.technician"
            type="TECHNICAL"
            label="Técnico"
            dark
          />
        </v-col>
        <v-col
          md="3"
        >
          <routes-autocomplete-filter
            v-model="filter.routes"
            label="Rota"
            dark
            multiple
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.dateFilter"
            dark
            item-text="text"
            filled
            return-object
            label="Data"
            prepend-inner-icon="event"
            hide-details
            :items="itemsDate"
          />
        </v-col>
      </v-row>

      <visits-report-tables
        :goal="filter"
      />
    </v-flex>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import VisitsReportTables from "@/Domains/Projects/Visits/VisitsReportTables.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "visits-report",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    VisitsReportTables,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
    view: String,
    project: String,
  },

  data() {
    return {

      loading: false,

      filter: {
        project: {
          id_assistencia: null,
          descricao: '',
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        search: null,

        type: null,

        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [moment().format('YYYY-MM'),  moment().format('YYYY-MM')],

        min: moment().subtract(5, 'years').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        dateFilter: []
      },

      projectList: [],
      visits: [],
      typeItems: [],

      periodMonths: {
        mensal: 1,
        bimestral: 2,
        trimestral: 3,
        quadrimestral: 4,
        semestral: 6,
        anual: 12,
        bianual: 24,
        trianual: 36
      },
      itemsDate: [],
    };
  },

  created() {
    this.loadGoals();
  },

  methods: {
    async loadGoals() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: this.project,
            view: 'visits',
          }
        );

        this.projectList = data.map((proj) => {
          return {
            visits: proj.visita ? JSON.parse(proj.visita) : [],
            descricao: this.project === 'PQFL' ? "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY') : proj.descricao,
            id_assistencia: proj.id_assistencia,
            data_inicio: proj.data,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          this.typeItems = this.filter.project.visits.filter(goal => goal.emergencial != true);
          this.filter.type = this.typeItems[0];
          if (this.project === 'PQFL') {
            this.filter.type.mes_inicio =  moment(this.filter.project.data_inicio).format('YYYY-MM')  ;
          }

          this.dateFilterItems();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    dateFilterItems() {
      this.itemsDate = [];
      if (this.filter.type) {

        var start = '';
        if (this.filter.type.mes_inicio ) {// metas de laticinio tem data por indicador
          start = moment(`${this.filter.type.mes_inicio}-01`, 'YYYY-MM-DD');
        }

        const now = moment().startOf('month');
        var index = 0;
        var items = [];

        while (start <= now) {
          let deadline = moment(start).add(this.periodMonths[this.filter.type.periodo], 'months');

          items.push({
            idx: index,
            text: `${start.format('DD/MM/YYYY')} à ${deadline.clone().subtract(1, "days").format('DD/MM/YYYY')}`,
            start: start.format('YYYY-MM'),
            end: deadline.format('YYYY-MM')
          });
          start = deadline;
          index++;
        }
        this.itemsDate = items[index - 1]; // mostra so o ultimo periodo pois nos anteriores precisamos rever o endpoint quanto aos produtores ativos de periodos anteriores
        this.filter.dateFilter = this.itemsDate; //preenche com o ultimo periodo
      }

    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
