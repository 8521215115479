<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color, height: height}"
    :loading="loading"
  >
    <v-card-text class="d-flex white--text text-center">
      <v-icon
        left
        style="font-size: 2.8rem;"
        :color="iconColor"
      >
        {{ icon }}
      </v-icon>
      <div
        class="d-flex flex-column text-left"
        style="overflow: hidden;"
      >
        <v-tooltip
          v-if="tooltip"
          top
        >
          <template #activator="{ on }">
            <span
              class="subtitle-2 font-weight-light text-truncate"
              v-on="on"
            >
              {{ title }}
            </span>
          </template>
          {{ tooltip }}
        </v-tooltip>
        <span
          v-else
          class="subtitle-2 font-weight-light text-truncate"
        >
          {{ title }}
          <slot name="title" />
        </span>

        <slot name="value">
          <span
            class="headline"
          >
            {{ value }}
          </span>
        </slot>

        <slot name="footer" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "card-info",

  props: {
    title: {
      type: String,
      default: 'Titulo'
    },
    value: {
      type: String,
      default: '-'
    },
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    iconColor: String,
    height: String,
    icon: {
      type: String,
      default: 'icon-informativo'
    },
    tooltip: String,
    loading: Boolean,
    footer: {
      type: String,
      default: null,
    }
  }
}
</script>
