<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="3"
          offset="3"
        >
          <v-select
            v-model="filter.project"
            :items="projectList"
            label="Metas"
            item-value="id_assistencia"
            item-text="descricao"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadGoals"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <month-range-picker
            v-model="filter.range"
            :ranges.sync="filter.ranges"
            :min="filter.min"
            :max="filter.max"
            dark
            @change="loadTecnical"
          />
        </v-col>
      </v-row>
     
      <tecnical-graphic
        v-for="q in technical"
        :key="q.id_pessoa+q.tipo_visita"
        :tecnical="q"
        :filter="filter"
      />
    </v-flex>
  </div>
</template>

<script>

import TecnicalGraphic from "@/Domains/Projects/Dairy/QualityGoals/Components/TecnicalGraphic.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "tecnical-report",

  components: {
    TecnicalGraphic,
    MonthRangePicker,
  },
 
  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {

      filter: {
        project: {
          id_assistencia: null,
          descricao: '',
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [moment().format('YYYY-MM'),  moment().format('YYYY-MM')],
        
        min: moment().subtract(5, 'years').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),
      },

      projectList: [],
      technical: [], //metas dos tecnicos

    };
  },
  
  created() {
    this.loadGoals();
  },

  methods: {

    async loadRange() {
      await this.loadTecnical();
    },

    async loadGoals() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy"
          }
        );

        this.projectList = data.map((proj) => {
          return {
            tecnico: proj.tecnico ? proj.tecnico : '',
            descricao: proj.descricao,
            id_assistencia: proj.id_assistencia,
          }
        });
        
        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      }
    },

    async loadTecnical() {
      try {
        if (!this.filter.project.tecnico) {
          return false;
        }
        const { data } = await this.$axios.get(
          `/visits/technical-to-do-list`,
          { params: {
            data_inicio: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            meta_laticinio: this.filter.project.tecnico,
          } }
        );

        this.parseData(data) ;

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

    },

    parseData(data) {
     
      this.technical  =   data.map(o => {
        return {
          ...o,
         
        }
      });

      console.log('this.technical',this.technical);
     
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>