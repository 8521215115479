<template>
  <v-form
    ref="form"
    v-model="validation.valid"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="pb-0"
      >
        <div class="d-flex">
          <span class="flex-grow-1 text-h5 mt-4 black--text">Configuração da NFP-e</span>
        </div>
        <v-divider class="mb-4" />
        <v-row>
          <v-col
            cols="6"
            sm="4"
            class="pb-0"
          >
            <v-select
              v-model="settings.environment"
              :items="[
                { text: 'Produção', value: 1 },
                { text: 'Homologação', value: 2 },
              ]"
              label="Ambiente"
              :rules="[v => !!v || 'Campo obrigatório']"
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            class="pb-0"
          >
            <masked-text-field
              v-model="settings.series"
              label="Série NFP-e *"
              placeholder="920-969"
              :mask="{ mask: '000' }"
              unmask
              prepend-inner-icon="tag"
              :rules="[
                v => !!v || 'Campo obrigatório!',
                v => (v >= 920 && v <= 969) || 'Série deve estar entre 920 e 969',
              ]"
            />
          </v-col>

          <v-col
            cols="6"
            sm="4"
            class="pb-0"
          >
            <v-select
              v-model="settings.operationType"
              :items="[
                { value: 'LOCAL', text: 'Local' },
                { value: 'GLOBAL', text: 'Global' },
              ]"
              label="Natureza de Operação"
              :rules="[v => !!v || 'Campo obrigatório']"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="pb-0"
      >
        <div class="d-flex">
          <span class="flex-grow-1 text-h5 mt-4 black--text">Certificado Digital (A1)</span>
        </div>
        <v-divider class="mb-4" />
        <v-row>
          <v-col
            cols="6"
            class="py-0"
          >
            <file-viewer
              v-if="settings.certificate"
              :value="settings.certificate"
              :preview-height="58"
              @removeFile="onRemoveFile()"
            />

            <v-card
              v-else
              class="text-center"
              outlined
            >
              <v-card-text>
                <v-btn
                  outlined
                  class="mb-5 mt-5"
                  @click="showFileUpload = !showFileUpload"
                >
                  <v-icon>attach_file</v-icon> Adicionar
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="6"
            class="py-0"
          >
            <v-text-field
              v-model="settings.password"
              label="Senha"
              type="password"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="pb-0"
      >
        <div class="d-flex">
          <span class="flex-grow-1 text-h5 mt-4 black--text">Controle de Numeração da NFP-e</span>
          <div class="d-flex justify-end">
            <v-btn
              outlined
              color="blue"
              class="my-1"
              @click="addNumeration()"
            >
              <v-icon>add</v-icon> Adicionar Numeração
            </v-btn>
          </div>
        </div>
        <v-divider class="mb-4" />
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-data-table
              :headers="[
                { text: 'Série', value: 'series' },
                { text: 'Próximo número', value: 'nextNumber' },
                { text: 'Ambiente', value: 'environment' },
                { text: 'Opções', value: 'actions', sortable: false, align: 'center' },
              ]"
              :items="numerations"
              :items-per-page="-1"
              hide-default-footer
              @click:row="editNumeration"
            >
              <template #[`item.environment`]="{ value }">
                {{ getEnvironment(value) }}
              </template>
              <template #[`item.actions`]="{ item }">
                <v-menu
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      small
                      @click="removeNumeration(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        v-if="settings.operationType === 'LOCAL'"
        cols="12"
        md="6"
        class="pb-0"
      >
        <div class="d-flex">
          <span class="flex-grow-1 text-h5 mt-4 black--text">Natureza de Operação</span>
          <div class="d-flex justify-end">
            <v-btn
              v-if="operations.length === 0"
              outlined
              color="blue"
              class="my-1"
              @click="addOperation()"
            >
              <v-icon>add</v-icon> Adicionar Natureza
            </v-btn>
          </div>
        </div>
        <v-divider class="mb-4" />
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-data-table
              :headers="[
                { text: 'Descrição', value: 'description' },
                { text: 'CFOP', value: 'icms.cfop' },
                { text: 'ICMS', value: 'icms.tributacao' },
                { text: 'PIS', value: 'pis.tributacao' },
                { text: 'COFINS', value: 'cofins.tributacao' },
                { text: '', value: 'actions', width: 10, align: 'end'},
              ]"
              :items="operations"
              :items-per-page="-1"
              hide-default-footer
              @click:row="editOperation"
            >
              <template #[`item.actions`]="{ item }">
                <v-menu
                  bottom
                  right
                >
                  <template
                    #activator="{ on }"
                  >
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="removeOperation(item.id)"
                    >
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <file-upload
      v-model="showFileUpload"
      :multiselect="false"
      extensions="p12,pfx"
      accept=".p12,.pfx"
      @insertFiles="onSelectFile"
    />

    <numeration-dialog
      v-model="numerationDialog.show"
      :edit-id="numerationDialog.id"
      :company-id="producerId"
      is-nfpe
      @save="loadNumerations"
    />

    <operation-dialog
      v-model="operationDialog.show"
      :edit-id="operationDialog.id"
      :company-id="producerId"
      default-type="NFPE"
      @save="loadOperations"
    />
  </v-form>
</template>

<script>
import NumerationDialog from '@/Domains/Settings/Views/Fiscal/Components/NumerationDialog.vue';
import OperationDialog from '@/Domains/Settings/Views/Fiscal/Components/OperationDialog.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import FileUpload from '@/Support/Components/FileUpload.vue';
import FileViewer from '@/Support/Components/FileViewer.vue';

export default {

  components: {
    NumerationDialog,
    OperationDialog,
    MaskedTextField,
    FileUpload,
    FileViewer,
  },

  props: {
    dataFiscal: Object,
    producerId: String,
  },

  data() {
    return {
      validation: {
        valid: true,
        rules: {
          required: [v => !!v || 'Campo obrigatório'],
        },
      },

      showFileUpload: false,

      numerations: [],
      operations: [],

      operationDialog: {
        show: false,
        id: null
      },

      numerationDialog: {
        show: false,
        id: null
      },
    };
  },

  computed: {
    settings: {
      get() {
        return this.dataFiscal;
      },

      set(newValue) {
        return this.$emit('update:dataFiscal', newValue);
      },
    },
  },

  watch: {
    validation: {
      handler: function ({ valid }) {
        this.$emit('onValidation', {
          valid: valid,
          tab: 'fiscal',
        });
      },
      deep: true,
    },
  },

  mounted() {
    this.$refs.form.validate();
    this.loadSettings();
    this.loadNumerations();
    this.loadOperations();
  },

  methods: {
    async loadSettings() {
      if (!this.producerId) {
        return;
      }

      try {
        this.$root.$progressBar.loading();
        const { data } = await this.$axios.get('/fiscal/settings', { params: { id_empresa: this.producerId } });

        this.settings = {
          ...this.settings,
          environment: data.ambiente,
          certificate: data.certificado_digital,
        };

      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar as Configurações Fiscais', 'Atenção');
        console.error(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onSelectFile([file]) {
      this.settings.certificate = {
        success: true,
        name: file.name,
        size: file.size,
        url: file.url
      }
    },

    async onRemoveFile() {
      try {
        const fileUrl = this.settings.certificate.url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('certificado_digital') && (fileUrl.includes('pfx') || fileUrl.includes('p12'))) {
          if (!(await this.$root.$confirm('Remover certificado', 'Tem certeza que deseja excluir este certificado?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post('/arquivos/storageDelete', { fileUrl });
        }
        this.settings.certificate = null;
      } catch (err) {
        console.warn(err);
      }
    },

    async loadNumerations() {
      if (!this.producerId) {
        return;
      }

      try {
        const { data } = await this.$axios.get('/fiscal/numeration', { params: { id_empresa: this.producerId } });

        this.numerations = data.map(o => ({ id: o.id, series: o.serie, nextNumber: o.prox_numero, environment: o.ambiente }));

      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar as Numerações', 'Atenção');
        console.error(e);
      }
    },

    addNumeration() {
      this.numerationDialog = {
        show: true,
        id: null,
      }
    },

    editNumeration(item) {
      this.numerationDialog = {
        show: true,
        id: item.id,
      }
    },

    async removeNumeration(o) {
      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir a Numeração NFP-e ${o.series}?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/fiscal/numeration/${o.id}`);

        this.$snotify.success('Numeração de NFP-e excluída com sucesso', 'Sucesso');
        return await this.loadNumerations();
      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao excluir a Numeração de NFP-e', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadOperations() {
      if (!this.producerId) {
        return;
      }

      try {
        const { data } = await this.$axios.get('/fiscal/operation', { params: { id_empresa: this.producerId } });

        this.operations = data.map(o => ({
          id: o.id,
          description: o.descricao,
          icms: o.icms,
          pis: o.pis,
          cofins: o.cofins,
          default: o.padrao
        }));

      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar as Naturezas de Operação', 'Atenção');
        console.error(e);
      }
    },

    addOperation() {
      this.operationDialog = {
        show: true,
        id: null,
      }
    },

    editOperation(item) {
      this.operationDialog = {
        show: true,
        id: item.id,
      }
    },

    async removeOperation(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover esta natureza de operação?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/fiscal/operation/${id}`);

        this.loadOperations();
        this.$snotify.success('Natureza de Operação excluída com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getEnvironment(value) {
      const envs = {
        1: 'Produção',
        2: 'Homologação',
      };
      return envs[value]
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /**
     * @void
     * Valida os campos do formulários
     */
    formValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    formResetValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta o formulário
     */
    formReset() {
      this.$refs.form.reset()
    },
  },
};
</script>
