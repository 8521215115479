<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="pt-0 mb-4 pb-0 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Itinerário de Visitas Comerciais
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
          @change="onTabChange"
        >
          <v-tab href="#tab-itineraries">
            Itinerários
          </v-tab>

          <v-tab
            href="#tab-editing"
            :disabled="!editing.id"
          >
            Editar Itinerário
          </v-tab>

          <v-tab-item value="tab-itineraries">
            <list-itineraries-tab
              v-if="tab === 'tab-itineraries'"
              ref="itineraries"
              type="COMMERCIAL"
              @onItineraryNew="onItineraryNew"
              @onItineraryEditing="onItineraryEditing"
              @onItineraryPrint="onItineraryPrint"
            />
          </v-tab-item>

          <v-tab-item value="tab-editing">
            <editing-itinerary-tab
              v-if="tab === 'tab-editing'"
              :itinerary="editing"
              type="comercial"
              @onItinerarySave="onItinerarySave"
              @onItineraryPrint="onItineraryPrint"
            />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListItinerariesTab from "@/Domains/Itineraries/Views/ListItinerariesTab.vue";
import EditingItineraryTab from "@/Domains/Itineraries/Views/EditingItineraryTab.vue";
import printJS from 'print-js';

export default {

  components: {
    ListItinerariesTab,
    EditingItineraryTab,
  },

  data() {
    return {
      tab: 'tab-itineraries',
      editing: {},
    };
  },

  methods: {

    onTabChange() {
      this.editing = {};
    },

    onItineraryNew() {
      this.editing = {};
      this.tab = 'tab-editing'
    },

    onItineraryEditing(itinerary) {
      this.editing = itinerary;
      this.tab = 'tab-editing'
    },

    onItinerarySave() {
      this.editing = {};
      this.tab = 'tab-itineraries'
    },

    async onItineraryPrint(itinerary) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/itinerario/imprime`, {
          id_itinerario: itinerary.id,
        });

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir o itinerario!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
