<template>
  <div>
    <div style="max-width: 110px;">
      <v-chip
        pill
        x-small
        @click.stop="showOdometerImage('START')"
      >
        <v-avatar
          left
          color="green"
          class="white--text"
        >
          I
        </v-avatar>
        {{ startedAt }}
        <v-avatar
          v-if="hasStartOdometerImage"
          right
        >
          <v-icon
            color="grey lighten-1"
            size="15px"
          >
            visibility
          </v-icon>
        </v-avatar>
      </v-chip>
      <div
        v-if="endedAt"
      >
        <v-chip
          pill
          x-small
          @click.stop="showOdometerImage('END')"
        >
          <v-avatar
            left
            color="green"
            class="white--text"
          >
            F
          </v-avatar>
          {{ endedAt }}
          <v-avatar
            v-if="hasEndOdometerImage"
            right
          >
            <v-icon
              color="grey lighten-1"
              size="15px"
            >
              visibility
            </v-icon>
          </v-avatar>
        </v-chip>
        <v-chip
          pill
          x-small
          @click.stop=""
        >
          <v-avatar
            left
            color="blue"
            class="white--text"
          >
            =
          </v-avatar>
          {{ total }}
        </v-chip>
      </div>
      <v-chip
        v-else
        pill
        x-small
      >
        Em Andamento
      </v-chip>
    </div>

    <LightBox
      v-if="pictures.length > 0"
      ref="lightbox"
      :media="pictures"
      :show-light-box="false"
      show-caption
    />
  </div>
</template>

<script>
import _ from "lodash"
import LightBox from "vue-image-lightbox";

export default {

  components: {
    LightBox,
  },

  props: {
    startedAt: {
      type: Number,
    },
    endedAt: {
      type: Number,
    },
    total: {
      type: Number,
    },
    odometer: {
      type: Array,
    },
  },

  computed: {

    pictures() {
      return this.odometer.filter(picture => picture.src);
    },

    hasStartOdometerImage() {
      return this.pictures.findIndex(odometer => odometer.type === 'START') !== -1;
    },

    hasEndOdometerImage() {
      return this.pictures.findIndex(odometer => odometer.type === 'END') !== -1;
    },

  },

  methods: {
    showOdometerImage(type) {

      if (_.isEmpty(this.pictures)) {
        return;
      }

      const index = this.pictures.findIndex(odometer => odometer.type === type);

      if (index < 0) {
        return;
      }

      this.$refs.lightbox.showImage(index);
    },
  },
}
</script>
