<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col
        v-for="route in routes"
        :key="route.id"
        :cols="columnSize"
      >
        <route-resume
          :producers="route.mapPersons"
          :stats="route.stats"
          :params="route.params"
          :equipments="route.equipments"
          :last-itinerary="route.lastItinerary"
          text-left
        >
          <template slot="title">
            <v-avatar
              :color="route.color"
              size="25"
              class="mr-3"
            />
            {{ route.description }}
          </template>

          <template slot="actions">
            <v-icon
              v-if="route.published || route.published === null"
              color="green darken-1"
            >
              verified
            </v-icon>
            <v-icon
              v-if="!route.published"
              color="orange darken-1"
            >
              pending_actions
            </v-icon>
          </template>
        </route-resume>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card dark>
          <v-card-text class="pa-0">
            <route-map
              ref="map"
              :center="$store.state.settings.coordenadas"
              :value="routes"
              @onRouteTraced="onRouteTraced"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="route in routes"
        :key="route.id"
        :cols="columnSize"
      >
        <route-points-form-item
          :route="route"
          :waypoints.sync="route.mapPersons"
          :all-producers="true"
          @onAddWaypoint="onProducerPositionChange"
          @onRemoveWaypoint="onProducerPositionChange"
          @onWaypointPositionChange="onProducerPositionChange"
          @onAddProducerProesct="onAddProducerProesct"
        />
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template
        v-slot:activator
      >
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            fab
            dark
            color="green"
            :disabled="disable"
            v-on="on"
            @click="saveRoutes('PUBLISH')"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </template>

        <span>Salvar e publicar</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            v-on="on"
            @click="saveRoutes('SAVE')"
          >
            <v-icon>receipt_long</v-icon>
          </v-btn>
        </template>

        <span>Salvar sem publicar</span>
      </v-tooltip>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import * as _ from "lodash";

import RouteService from "@/Domains/Routes/Services/RouteService.js";
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import RouteResume from "@/Domains/Routes/Components/Form/RouteResume.vue";
import RoutePointsFormItem from "@/Domains/Routes/Components/Form/RoutePointsFormItem.vue";

const service = new RouteService();

const colors = [
  '#FF5722',
  '#512DA8',
  '#8BC34A',
  '#FFA000',
];

export default {
  components: {
    RouteMap,
    RouteResume,
    RoutePointsFormItem,
  },

  props: {
    ids: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      // Loader
      loading: false,

      // Lista de rotas
      routes: [],

      disable: false,
    };
  },

  computed: {
    columnSize() {
      return 12 / this.routes.length;
    },

  },

  async mounted() {
    await this.loadRoutes();

    this.$nextTick(function () {
      this.$refs.map.trace();
    });
  },

  methods: {
    async loadRoutes() {
      try {
        this.loading = true;

        this.routes = await Promise.all(this.ids.map(async (routeId, index) => {
          const route = await service
            .fromSystem(this.$store.state.settings.sistema)
            .getRoute(routeId, true);

          return {
            ...route,
            color: colors[index],
          }
        }));
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Cria/Edita uma rota
     */
    async saveRoutes(action) {
      try {
        this.loading = true;

        const published = action !== 'SAVE';

        await Promise.all(this.routes.map(async route => {
          return await service
            .fromSystem(this.$store.state.settings.sistema)
            .saveRoute({
              ...route,
              published,
            });
        }));

        this.$emit("onRoutesSaved");
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar a rota!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    onAddProducerProesct(event) {
      console.log(event);
      if (event == true) {
        this.disable = true
      } else {
        this.disable = false
      }
    },

    onRouteTraced(event) {
      this.routes = this.routes.map(route => {
        if (route.id !== event.route.id) {
          return route;
        }

        return {
          ...route,
          stats: {
            duration: event.duration,
            distance: event.distance,
          },
          params: {
            ...route.params,
            routePolyline: event.polyline,
          },
        };
      });
    },

    onProducerPositionChange: _.debounce(async function () {
      this.routes = this.routes.map(route => {
        return {
          ...route,
          params: {
            ...route.params,
            routePolyline: null,
          },
        };
      });
      this.$refs.map.clearRoute();

      return this.$nextTick(async function() {
        return this.$refs.map.trace();
      });
    }, 500),
  },
};
</script>
