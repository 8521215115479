<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="item-registration"
  >
    <v-row>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter.search"
            :loading="loading"
            dark
            class="elevation-1 report-table"
            @click:row="edit"
          >
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          v-on="on"
          @click="newInstallmentMethod()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Novo</span>
    </v-tooltip>
    <confirm ref="confirm" />

    <installment-method-dialog
      v-model="showDialog"
      :edit-id="editId"
      @save="loadInstallmentMethods"
    />
  </v-container>
</template>

<script>
import InstallmentMethodDialog from "@/Domains/Financial/Settings/Components/InstallmentMethodDialog.vue";
import Confirm from "@/Support/Components/Confirm.vue";

import _ from "lodash";

export default {

  components: {
    InstallmentMethodDialog,
    Confirm,
  },

  data() {
    return {
      loading: false,
      showDialog: false,

      editId: null,

      filter: {
        search: '',
      },

      headers: [
        { text: 'Código', value: 'code' },
        { text: 'Descrição', value: 'description' },
        { text: '', value: 'action', width: 30 }
      ],

      items: [],
    };
  },

  mounted() {
    this.loadInstallmentMethods();
  },

  methods: {
    async loadInstallmentMethods() {
      try {
        this.loading = true;
        let { data } = await this.$axios.post(`/formaParcelamento/listaJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.items = data.map(item => {
          return {
            id: item.id_forma_parcelamento,
            code: item.cod_forma_parcelamento,
            description: item.descricao,
            gracePeriod: item.carencia_primeira_parcela,
            numberOfInstallments: item.numero_parcelas,
            daysBetween: item.dias_entre_parcelas,
          };
        });
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },
    
    newInstallmentMethod() {
      this.editId = null,
      this.showDialog = true;
    },
    
    edit(item) {
      this.editId = item.id,
      
      this.showDialog = true;
    },
    
    async remove({ id }) {
      if (!(await this.$refs.confirm.open('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formaParcelamento/inativa`,
          { id_forma_parcelamento: id }
        );
        
        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.loadInstallmentMethods();
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>