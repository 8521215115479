<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Troca de Titularidade
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#history-tab">
          Histórico
        </v-tab>

        <v-tab
          href="#transfer-tab"
          :disabled="tab !== 'transfer-tab'"
        >
          Transferir
        </v-tab>

        <v-tab-item value="history-tab">
          <ownership-change-history-tab
            v-if="tab === 'history-tab'"
            @transfer="onTransfer"
          />
        </v-tab-item>

        <v-tab-item value="transfer-tab">
          <ownership-change-tab
            v-if="tab === 'transfer-tab'"
            :transfer-id="transferId"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import OwnershipChangeHistoryTab from "@/Domains/Producer/OwnershipChange/Views/OwnershipChangeHistory.vue";
import OwnershipChangeTab from "@/Domains/Producer/OwnershipChange/Views/OwnershipChange.vue";

export default {
  name: "ProductionOrder",

  components: {
    OwnershipChangeHistoryTab,
    OwnershipChangeTab,
  },

  data() {
    return {
      tab: null,
      transferId: null,
    };
  },

  methods: {
    onTransfer(id) {
      this.transferId = id
      this.tab = 'transfer-tab'
    }
  }
};
</script>
