<template>
  <v-dialog
    v-model="dialog"
    :max-width="500"
    scrollable
    persistent
  >
    <v-card class="order-dialog">
      <v-card-title class="text-h6">
        {{ form.canEdit ? 'Adicionar Perda' : 'Perdas' }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formRef"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              class="pt-1 pb-0"
              cols="12"
            >
              <v-text-field
                :value="form.data?.item?.description"
                label="Item"
                disabled
              />
            </v-col>
            <v-col
              v-if="form.canEdit"
              class="pt-1 pb-0"
            >
              <masked-text-field
                v-model="form.quantity"
                label="Perdas"
                persistent-hint
                unmask
                :mask="masks.float"
                :suffix="form.data?.item?.measurement"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              v-if="form.canEdit && form.data?.showQueijomatic"
              class="pt-1 pb-0"
              cols="6"
            >
              <v-select
                v-model="form.tankId"
                :items="form.tinas"
                label="Queijomatic *"
                item-value="id_tina"
                item-text="descricao"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              v-if="form.canEdit"
              class="pt-1 pb-0"
              cols="12"
            >
              <v-text-field
                v-model="form.motive"
                label="Motivo"
              />
            </v-col>
          </v-row>
        </v-form>

        <v-data-table
          v-if="records.length > 0"
          :headers="headers"
          :items="records"
          disable-sort
          disable-filtering
          hide-default-footer
        >
          <template #[`item.data_hora_registro`]="{ value }">
            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
          </template>
          <template #[`item.quantidade`]="{ value }">
            {{ formatNumber(value) }} {{ form.data?.item?.measurement }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click.native="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="form.canEdit"
          color="primary"
          outlined
          @click.native="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import moment from 'moment'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'

const { progressBar, notify } = useUtils()

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const dialog = ref(false)

const formRef = ref()

const form = reactive({
  canEdit: true,
  order: {},
  tinas: [],
  data: {},
})

const show = (item) => {
  dialog.value = true

  form.canEdit = item.canEdit
  form.order = item.order
  form.type = item.type
  form.tinas = item.tinas
  form.data = item.data
  form.quantity = undefined
  form.tankId = undefined
  form.motive = undefined

  records.value = item.order.losses
    .filter(record => record.id_ordem_producao_item === form.data.id || record.id_ordem_producao_subproduto === form.data.id)
    .map(record => ({
      tina: item.tinas.find(tina => tina.id_tina === record.id_tina),
      ...record,
    }))
}

const close = () => {
  dialog.value = false
}

const save = async () => {
  try {
    if (!await formRef.value?.validate()) {
      return
    }

    progressBar?.loading()

    await axios.post(`/production-order/add-loss`, {
      id_ordem_producao: form.order.id,
      id_ordem_producao_item: form.type === 'ITEM' ? form.data.id : null,
      id_ordem_producao_subproduto: form.type === 'SUBPRODUTO' ? form.data.id : null,
      id_item: form.data.item.id,
      quantidade: form.quantity,
      id_tina: form.tankId,
      motivo: form.motive,
    })

    emit('save')
    close()
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const headers = [
  { text: 'Qtde.', value: 'quantidade' },
  { text: 'Queijomatic', value: 'tina.descricao', align: 'center' },
  { text: 'Motivo', value: 'motivo' },
  { text: 'Pessoa', value: 'nome_pessoa_registro' },
  { text: 'Data e Hora', value: 'data_hora_registro' },
];

const records = ref([])

// eslint-disable-next-line no-undef
defineExpose({ show, close })
</script>
