
export default class VisitsService {
  constructor() {
    this.standardVisitTypes = {
      IN59: 'IN59',
      IN77: 'IN77',
      PMLS: 'PMLS',
      BPA: 'BPA',
      COLETA_AMOSTRA: 'COLETA DE AMOSTRA',
      SUSPENSAO: 'SUSPENSÃO',
      VETERINARIO: 'VETERINÁRIO',
      ZOO_TECNICO: 'ZOOTÉCNICO',
      VENDER_PRODUTOS: 'VENDER PRODUTOS',
      ROTINA: 'ROTINA',
      CPP: 'CPP',
      CCS: 'CCS',
      ALTERACOES_NO_LEITE: 'ALTERAÇÕES NO LEITE',
      NEGOCIACAO: 'NEGOCIAÇÃO',
      ACOMPANHAMENTO_DE_ORDENHA: 'ACOMPANHAMENTO DE ORDENHA',
      DOCUMENTOS: 'DOCUMENTOS',
      OUTROS: 'OUTROS',
    }

    this.prospectionVisitTypes = {
      PROSPECCAO: 'PROSPECÇÃO',
      COMPRAR_LEITE: 'COMPRA DE LEITE',
      VENDER_PRODUTOS: 'VENDER PRODUTOS',
      COLETA_AMOSTRA: 'COLETA DE AMOSTRA',
      OUTROS: 'OUTROS',
    }

    this.dairyGoalsVisitTypes = {
      CPP: 'CPP',
      CCS: 'CCS',
      BPA: 'BPA',
      PROSPECCAO: 'PROSPECÇÃO',
      COLETA_AMOSTRA: 'COLETA DE AMOSTRA',
      VETERINARIO: 'RECOMENDAÇÕES VETERINÁRIAS',
      AGRONOMICO: 'RECOMENDAÇÕES AGRONÔMICAS',
      ZOO_TECNICO: 'ZOOTÉCNICO',
      VENDER_PRODUTOS: 'VENDA',
      ACOMPANHAMENTO_DE_ORDENHA: 'ACOMPANHAMENTO DE ORDENHA',
      ACOMPANHAMENTO_DE_ROTA: 'ACOMPANHAMENTO DE ROTA',
      PRODUTORES: 'PRODUTORES', //QUALQUER TIPOS DE VISITA COM ASSINATURA
      ROTINA: 'ROTINA',
      ATENDIMENTO_VETERINARIO: 'ATENDIMENTOS VETERINÁRIOS',
      ATENDIMENTO_AGRONOMICO: 'ATENDIMENTOS AGRONÔMICOS',
    }

    this.dairyEmergencyVisitTypes = {
      CRIOSCOPIA: 'CRIOSCOPIA',
      ANTIBIOTICO: 'ANTIBIOTICO',
      ALTERACOES_NO_LEITE: 'ALTERAÇÕES NO LEITE', //OUTROS TIPOS DE IRREGULARIOES NA PLATAFORMA
      DESCARTE: 'CONDENAÇÃO / DESCARTE',
    }

    this.types = {
      ...this.standardVisitTypes,
      ...this.prospectionVisitTypes,
    }

    this.standardTypesFilter = this.getTypesFilter(this.standardVisitTypes);
    this.prospectionTypesFilter = this.getTypesFilter(this.prospectionVisitTypes);
    this.dairyGoalsTypesFilter = this.getTypesFilter(this.dairyGoalsVisitTypes);
    this.dairyEmergencyVisitTypes = this.getTypesFilter(this.dairyEmergencyVisitTypes);
    this.typesFilter = this.getTypesFilter(this.types);
  }

  getTypesFilter(types) {
    const values = Object.values(types);
    return Object.keys(types)
      .reduce((prev, key, i) => ([...prev, { value: key, text: values[i] }]), []);
  }
}
