<template>
  <v-row>
    <v-col
      md="3"
      class="pb-1"
    >
      <zip-code-input
        v-model="form.end_cep"
        prepend-inner-icon="subtitles"
        dense
        class="pa-0"
        @address="onAddress"
      />
    </v-col>
    <v-col
      cols="9"
      class="pb-1"
    >
      <v-text-field
        v-model="form.end_rua"
        prepend-inner-icon="subtitles"
        label="Endereço"
        dense
      />
    </v-col>
    <v-col
      cols="3"
      class="pb-1"
    >
      <v-text-field
        v-model="form.end_numero"
        prepend-inner-icon="subtitles"
        label="Número"
        dense
      />
    </v-col>
    <v-col
      md="9"
      class="pb-1"
    >
      <v-text-field
        v-model="form.end_bairro"
        prepend-inner-icon="subtitles"
        label="Bairro"
        dense
      />
    </v-col>
    <v-col
      md="6"
      class="pb-1"
    >
      <states-select
        v-model="form.end_estado"
        prepend-inner-icon="villa"
        label="Estado"
        dense
      />
    </v-col>
    <v-col
      md="6"
      class="pb-1"
    >
      <cities-select
        v-model="form.end_cidade"
        :uf="form.end_estado"
        :code="form.end_cidade_codigo"
        prepend-inner-icon="domain"
        label="Cidade"
        dense
        @change="onCity"
      />
    </v-col>
    <v-col
      v-if="showRegion"
      cols="12"
      class="pb-1"
    >
      <v-autocomplete
        v-model="form.id_regiao"
        prepend-inner-icon="icon-rota"
        label="Rota região"
        :items="regionItems"
        item-text="nome"
        item-value="id_regiao"
        dense
        auto-select-first
        @change="onRegion"
      />
    </v-col>
    <v-col
      cols="12"
      class="pb-1"
    >
      <v-expansion-panels dark>
        <v-expansion-panel style="background: #0277bd">
          <v-expansion-panel-header>
            <v-row>
              <v-col
                sm="6"
                md="4"
                class="py-0"
              >
                <v-text-field
                  v-model="form.end_latitude"
                  label="Latitude"
                  prepend-inner-icon="map"
                  dark
                  filled
                  rounded
                  dense
                  hide-details
                  @click.native.stop
                />
              </v-col>
              <v-col
                sm="6"
                md="4"
                class="py-0"
              >
                <v-text-field
                  v-model="form.end_longitude"
                  label="Longitude"
                  prepend-inner-icon="map"
                  dark
                  filled
                  rounded
                  dense
                  hide-details
                  @click.native.stop
                />
              </v-col>
            </v-row>
            <template #actions>
              <v-icon>my_location</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-0">
            <route-map
              ref="map"
              :center="$store.state.settings.coordenadas"
              clickable
              :value="[]"
              :markers="marker"
              :draggable="true"
              @onSelectPosition="handleSelectPosition"
              @onPositionChange="handleSelectPosition"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { get } from "lodash";

import StatesSelect from "@/Support/Components/StatesSelect.vue";
import ZipCodeInput from "@/Support/Components/ZipCodeInput.vue";
import CitiesSelect from "@/Support/Components/CitiesSelect.vue";
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";

export default {

  components: {
    StatesSelect,
    CitiesSelect,
    ZipCodeInput,
    RouteMap,
  },

  props: {
    dataAddress: {
      type: Object,
      required: true,
    },

    showRegion: Boolean,
  },

  data() {
    return {
      regionItems: [],
    }
  },

  computed: {
    form: {
      get() {
        return this.dataAddress;
      },

      set(newValue) {
        return this.$emit("update:dataAddress", newValue);
      },
    },

    /**
     * Configurações do marker do mapa
     */
    marker() {
      return [
        {
          location: {
            lat: this.form.end_latitude || 0,
            lng: this.form.end_longitude || 0,
          },
          visible: true,
          color: "#00838F",
          draggable: true,
          icon: "person_outline",
        },
      ];
    },
  },

  mounted() {
    if (this.showRegion) {
      this.getCommercialRegions();
    }
  },

  methods: {
    /**
     * @void
     * Buscas as regiões do sistema
     */
    async getCommercialRegions() {
      try {
        const { data } = await this.$axios.post(`/regiao/listaJson`, this.$qs.stringify({
          tipo: 'COMERCIAL'
        }));

        this.regionItems = data;
      } catch (error) {
        console.warn(error);
      }
    },

    /**
     * @void
     * Ativada ao editar o CEP e atualiza os endereços automaticamente
     */
    async onAddress(data) {
      this.form = {
        ...this.form,
        end_rua: data.logradouro,
        end_bairro: data.bairro,
        end_cidade: data.localidade,
        end_estado: data.uf,
        end_cidade_codigo: data.ibge
      }
    },

    /**
     * @void
     * Atualiza o nome e o código ibge da cidade
     */
    onCity(data) {
      this.form = {
        ...this.form,
        end_cidade: get(data, 'city', this.form.end_cidade),
        end_cidade_codigo: get(data, 'code', this.form.end_cidade_codigo)
      }
    },

    /**
     * @void
     * Atualiza o nome da região
     */
    onRegion(id) {
      this.form = {
        ...this.form,
        regiao: id ? this.regionItems.find(o => o.id_regiao === id).nome : null
      }
    },

    /**
     * @void
     * Trata o evento de click do mapa e posiciona o novo marker
     */
    handleSelectPosition({ position }) {
      this.form = {
        ...this.form,
        end_latitude: position.lat.toFixed(7),
        end_longitude: position.lng.toFixed(7)
      }
    },
  }
}
</script>
