<template>
  <v-card>
    <v-card-title>
      <v-select
        v-model="localValue"
        :items="tables"
        label="Tabela de faixas"
        item-text="titulo"
        item-value="id_faixa_frete"
        return-object
        hide-details
      />
    </v-card-title>

    <v-divider />
    <v-simple-table>
      <template #default>
        <tbody>
          <tr
            v-for="(item, idx) in ranges"
            :key="idx"
          >
            <td class="text-left">
              {{ (idx == 0) ? 'Inicial' : '>' }}
            </td>
            <td class="text-left px-0">
              {{ formatNumber(item.km) }} Km
            </td>
            <td class="text-right">
              {{ formatCurrency(item.valor) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-actions>
      <v-spacer />

      <v-btn
        outlined
        @click="close()"
      >
        Fechar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {

  props: {
    value: Object,
    tables: Array,
  },

  data() {
    return {
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ranges() {
      if (!this.localValue) {
        return [];
      }

      const table = this.tables.find(table => table.id_faixa_frete === this.localValue.id_faixa_frete);

      if (!table) {
        return [];
      }

      return table.faixas;
    }
  },

  methods: {

    close() {
      this.$emit('close')
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
  }
}
</script>
