<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { gmapApi } from "gmap-vue";

export default {
  props: {
    location: Object,
    draggable: Boolean,
    visible: Boolean,
    color: String,
    /**
     * Ex: { url: 'https://www.milkup.com.br/global/layout/assets/img/tarro.png' }
     */
    icon: Object,
    meta: Object,
    richMeta: Object,
    title: String,
    markerData: Object,
    label: [Number, String],
    zIndex: Number
  },
  
  data() {
    return {
      marker: null
    }
  },

  computed: {
    google: gmapApi,
  },

  watch: {
    visible() {
      return this.createMarker();
    },
  },

  mounted() {
    return this.$nextTick(function() {
      this.createMarker();
    });
  },

  beforeDestroy() {
    this.marker && this.marker.setMap(null);
  },

  methods: {
    async createMarker() {
      if (!this.visible) {
        return;
      }

      const key = this.$vnode.key;
      const map = await this.$parent.$mapPromise;

      let opt = {};
      if (this.title) { opt.title = this.title; }
      if (this.zIndex) { opt.zIndex = this.zIndex; }

      const marker = new this.google.maps.Marker({
        position: new this.google.maps.LatLng(
          this.location.lat,
          this.location.lng
        ),
        map,
        draggable: this.draggable,
        icon: this.icon || this.drawMarker(),
        ...opt
      });

      if (this.meta) {
        const infoWindow = new this.google.maps.InfoWindow({
          content: `
          <div class="text-left black--text">
            <p><b>Produtor:</b> #${this.meta.code || ''} - ${this.meta.name || ''}</p>
            <p><b>Volume:</b> ${this.meta.vol || 0} L</p>
          </div>
        `
        });

        this.google.maps.event.addListener(marker, "click", function() {
          infoWindow.open(map, marker);
        });
      }
      else if (this.richMeta) {
        const richMeta = Object.entries(this.richMeta)
          .map(([key, value]) => value ? [`<b>${key}:</b> ${value}<br>`] : [])
          .join('\n');
        const infoWindow = new this.google.maps.InfoWindow({
          content: `<div class="text-left black--text">${richMeta}</div>`
        });

        this.google.maps.event.addListener(marker, "click", function() {
          infoWindow.open(map, marker);
        });
      }
      else {
        this.google.maps.event.addListener(marker, "click", () => {
          this.$emit("click:marker", { marker, data: this.markerData });
        });
      }

      const self = this;

      this.google.maps.event.addListener(marker, "dragend", function(event) {
        const position = event.latLng;

        // Usa-se o event-bus devido ao contexto desta função
        self.$emit(`onPositionChange`, {
          lat: position.lat(),
          lng: position.lng(),
        }, key);
      });
      this.marker = marker;
    },

    // Draws the Marker
    drawRect(context, x, y, width, height, radius) {
      context.beginPath();
      context.moveTo(x + radius, y);
      context.lineTo(x + width - radius, y);
      context.quadraticCurveTo(x + width, y, x + width, y + radius);
      context.lineTo(x + width, y + height - radius);
      context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
      // Starts triangle
      context.lineTo(x + (width / 2) + 8, y + height);
      context.lineTo(x + (width / 2), y + height + 10);
      context.lineTo(x + (width / 2) - 8, y + height);
      // End triangle
      context.lineTo(x + radius, y + height);
      context.quadraticCurveTo(x, y + height, x, y + height - radius);
      context.lineTo(x, y + radius);
      context.quadraticCurveTo(x, y, x + radius, y);
      context.closePath();
    },

    /**
     * Draws a Marker with Canvas
     */
    drawMarker() {
      let color = this.color || "rgba(100,181,246, 1)";
      let label = this.label || '';
      let width = (label.toString().length * 8) + 20;
      let height = 28;
      let radius = 5;
      let padding = 2; // Espaçamento para aplicar a sombra
      let x = padding;
      let y = padding;
      width = width > 40 ? width : 40;

      let canvas = document.createElement("canvas");
      canvas.width = width + padding + 5;
      canvas.height = height + padding + 15;

      let context = canvas.getContext("2d");
      context.clearRect(0, 0, width, height);

      context.fillStyle = color;
      context.strokeStyle = color;

      this.drawRect(context, x, y, width, height, radius);
      
      // Apply shadow in the marker
      context.shadowColor = "rgba(0,0,0, 0.7)";
      context.shadowBlur = 5;
      context.shadowOffsetX = 1;
      context.shadowOffsetY = 1;

      context.fill();

      // Reset the shadow
      context.shadowBlur = 0;
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;

      context.stroke();

      // Create Label
      context.fillStyle = "white";
      context.strokeStyle = "black"
      context.font = "13pt Roboto";
      context.textBaseline  = "top";

      let textWidth = context.measureText(label);
      // Center the label
      context.fillText(label, ((Math.floor((width / 2) - (textWidth.width / 2))) + padding), (5 + padding));

      return canvas.toDataURL();
    },
  },
};
</script>
