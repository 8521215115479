<template>
  <v-dialog
    v-model="visible"
    :persistent="editing"
    max-width="550"
  >
    <v-card>
      <v-card-title class="text-h5">
        Ajuste de preço
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="mt-1 pb-2">
        <b>{{ form.desc }}</b>
      </v-card-subtitle>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            cols="4"
            class="text-center d-flex flex-column justify-center pa-0"
          >
            <div>
              <span style="font-size:1.2rem">{{ formatCurrency(discountValue) }}</span>
              <span class="body-1"> ({{ formatNumber(discountPerc) }} %)</span>
            </div>
            <span class="caption text-uppercase">Desconto</span>
          </v-col>
          <v-col
            cols="4"
            class="text-center d-flex flex-column justify-center pa-0"
          >
            <span style="font-size:1.2rem">{{ formatCurrency(amount) }}</span>
            <span class="caption text-uppercase">Valor</span>
          </v-col>
          <v-col
            cols="4"
            class="text-center d-flex flex-column justify-center pa-0"
          >
            <span :class="['display-1 font-weight-light', { 'red--text': subTotalWithDiscount <= 0 }]">{{ formatCurrency(subTotalWithDiscount) }}</span>
            <span :class="['overline', (subTotalWithDiscount <= 0 ? 'red--text' : 'blue--text')]">Total</span>
          </v-col>
        </v-row>
        <v-form
          v-if="visible"
          ref="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-radio-group
                v-model="form.adjustmentType"
                label="Tipo de ajuste"
                dense
                hide-details
                class="mt-0"
                :disabled="!editing"
              >
                <v-radio
                  label="Desconto Percentual"
                  value="DESCONTO_PERCENTUAL"
                />
                <v-radio
                  label="Desconto por Valor"
                  value="DESCONTO_VALOR"
                />
                <v-radio
                  label="Incremento de Valor Unitário"
                  value="INCREMENTO_VALOR"
                />
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
            >
              <masked-text-field
                v-if="form.adjustmentType === 'INCREMENTO_VALOR'"
                v-model="form.increment"
                label="Incremento"
                prefix="R$"
                :mask="{ mask: Number, min: 0 }"
                persistent-hint
                dense
                unmask
                :disabled="!editing"
              />
              <masked-text-field
                v-else-if="form.adjustmentType === 'DESCONTO_VALOR'"
                v-model="form.discountValue"
                label="Desconto"
                prefix="R$"
                :mask="{ mask: Number, min: 0 }"
                persistent-hint
                dense
                unmask
                :disabled="!editing"
                :rules="[v => subTotalWithDiscount > 0 || 'Desconto inválido!']"
              />
              <masked-text-field
                v-else
                v-model="form.discountPerc"
                label="Desconto"
                prefix="%"
                :mask="{ mask: Number, min: 0, max: 100 }"
                persistent-hint
                dense
                unmask
                :disabled="!editing"
                :rules="[v => subTotalWithDiscount > 0 || 'Desconto inválido!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          outlined
          :disabled="!editing"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="green darken-1"
          outlined
          :disabled="!editing"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
// import _ from "lodash";
import { round, percentageBy } from "@/Support/Resources/utils";

export default {
  components: {
    MaskedTextField,
  },

  data() {
    return {
      visible: false,
      editing: true,
      form: {
        adjustmentType: 'DESCONTO_PERCENTUAL',
        price: 0
      },
    }
  },

  computed: {
    amount() {
      return this.form.price + this.increment
    },

    subTotal() {
      return this.form.quantity * this.amount
    },

    subTotalWithDiscount() {
      return this.subTotal - (this.discountValue || 0)
    },

    increment() {
      if (this.form.adjustmentType !== 'INCREMENTO_VALOR') {
        return 0
      }
      return parseFloat(this.form.increment || 0)
    },

    discountPerc: {
      get() {
        if (this.form.adjustmentType === 'INCREMENTO_VALOR') {
          return 0
        }
        if (this.form.adjustmentType === 'DESCONTO_PERCENTUAL') {
          return parseFloat(this.form.discountPerc || 0)
        }
        const discountValue = this.discountValue
        if (!discountValue) {
          return null;
        }
        return round(percentageBy(this.subTotal, discountValue), 2)
      },

      set(newValue) {
        this.form.discountPerc = newValue
      },
    },

    discountValue: {
      get() {
        if (this.form.adjustmentType === 'INCREMENTO_VALOR') {
          return 0
        }
        if (this.form.adjustmentType === 'DESCONTO_VALOR') {
          return parseFloat(this.form.discountValue || 0)
        }
        const discountPerc = this.discountPerc
        if (!discountPerc) {
          return null;
        }
        return (discountPerc * this.subTotal) / 100
      },

      set(newValue) {
        this.form.discountValue = newValue
      },
    }
  },

  methods: {
    show({ editing, data }) {
      this.$refs.form && this.$refs.form.reset();

      const adjustmentType = data.adjustmentType || 'DESCONTO_PERCENTUAL';
      let discountValue = null;
      let discountPerc = null;
      let increment = null;
      if (adjustmentType === 'INCREMENTO_VALOR') {
        increment = parseFloat(data.increment) || null
      }
      if (adjustmentType === 'DESCONTO_VALOR') {
        discountValue = parseFloat(data.discountValue) || null
      }
      else {
        discountPerc = parseFloat(data.discountPerc) || null
      }

      this.form = {
        desc: `${this.formatNumber(data.quantity)} x ${data.name}`,
        adjustmentType,
        discountValue,
        discountPerc,
        increment,
        quantity: parseFloat(data.quantity),
        price: parseFloat(data.price)
      }

      this.editing = editing

      this.visible = true
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const adjustmentType = this.form.adjustmentType;
      let discountValue = null;
      let discountPerc = null;
      let increment = null;
      if (adjustmentType === 'INCREMENTO_VALOR') {
        increment = parseFloat(this.form.increment) || null
      }
      if (adjustmentType === 'DESCONTO_VALOR') {
        discountValue = parseFloat(this.form.discountValue) || null
      }
      else {
        discountPerc = parseFloat(this.form.discountPerc) || null
      }

      this.$emit('save', {
        adjustmentType,
        discountValue,
        discountPerc,
        increment
      })

      this.visible = false;
    },

    close() {
      this.$emit('close')
      this.visible = false;
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  }
}
</script>