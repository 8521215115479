<template>
  <div class="console-layout">
    <div class="container">
      <div
        class="console"
        @click="onConsoleClick"
        @keyup.up="onUp"
      >
        <samp class="console-output">
          <span>
            Olá, para utilizar o ambiente local, por favor faça login com sua conta:
          </span>

          <br>
        </samp>

        <kbd
          class="console-input"
        >
          <span
            v-if="show.email"
            class="email-input"
          >
            <label for="email">
              Email:
            </label>
            <input
              id="email"
              ref="email"
              v-model="inputs.email"
              class="email"
              type="email"
              autofocus
              @keyup.enter.stop="onEmailEnter"
            >
          </span>

          <span
            v-if="show.password"
            class="password-input"
          >
            <label for="password">
              Password:
            </label>
            <input
              id="password"
              ref="password"
              v-model="inputs.password"
              class="password"
              type="password"
              @keyup.down="onDown"
              @keyup.enter.stop="onPasswordEnter"
            >
          </span>
        </kbd>

        <samp
          v-if="show.dairies"
          class="dairy-selection console-output"
        >
          <span style="color: white">
            Escolha um laticínio para logar:
          </span>

          <template v-for="dairy in dairies">
            <div
              :key="dairy.system"
              class="dairy-row"
            >
              <span class="selection">
                [{{ dairy.selected ? '~' : ' ' }}]
              </span>

              <span class="dairy-name">
                &nbsp;{{ dairy.system }}
              </span>
            </div>
          </template>
        </samp>

        <samp
          v-if="show.loading"
          class="loading console-output"
        >
          <span class="rotate">|</span> &nbsp;Carregando...
        </samp>

        <samp
          v-if="error.show"
          class="error-message console-output"
        >
          [!!!] {{ error.message }}
        </samp>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.console-layout {
  width: 100%;
  height: 100%;
  background: #111;

  .container {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
  }

  .console {
    background: #222;
    border: solid 1px #444;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow: auto;
    padding: 60px 30px 30px 30px;
    position: relative;
    width: 900px;

    &:before {
      background: #444;
      content: "";
      height: 30px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .console-output {
      color: #fff;
      font-size: 16px;

      span {
        display: block;
        margin-bottom: 3px;

        &:first-of-type {
          color: limegreen;
        }
      }
    }

    .console-input {
      span {
        display: block;

        input {
          background: transparent;
          border: none;
          color: #fff;
          cursor: default;
          font-family: monospace;
          font-size: 16px;
          padding: 0;

          &:focus {
            border: none;
            outline: none;
          }
        }

        label {
          color: #fff;
          font-family: monospace;
          font-size: 16px;
          margin-right: 9px;
        }
      }
    }
  }

  .dairy-row {
    display: flex;
  }

  .loading {
    display: flex;
  }

  .rotate {
    animation: spin 1s infinite linear;
    -webkit-animation: spin 1s infinite linear;
  }

  .error-message {
    color: red !important;
  }

  @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    caret-color: white;
    -webkit-text-fill-color: white;
    box-shadow: 0 0 0px 1000px #222 inset;
    -webkit-box-shadow: 0 0 0px 1000px #222 inset;
  }
}
</style>

<script>

import * as _ from "lodash";

export default {

  data() {
    return {
      show: {
        email: true,
        password: false,
        dairies: false,
        loading: false,
      },

      error: {
        show: false,

        message: 'Error de teste',
      },

      inputs: {
        email: '',
        password: '',
      },

      dairies: [],
    };
  },

  mounted() {
    if (process.env.NODE_ENV !== 'development') {
      return window.location.replace('/');
    }
  },

  created() {
    window.addEventListener('keyup', _.debounce(this.onKeyPress, 50));
  },

  methods: {

    onConsoleClick() {
      if (this.show.email) {
        return this.$refs.email.focus();
      }

      if (this.show.password) {
        return this.$refs.password.focus();
      }
    },

    onEmailEnter() {
      if (_.isEmpty(this.inputs.email)) {
        return;
      }

      this.show.email = false;
      this.show.password = true;

      return this.$nextTick(() => {
        return this.$refs.password.focus();
      });
    },

    onPasswordEnter() {
      if (_.isEmpty(this.inputs.password)) {
        return;
      }

      this.show.password = false;

      return this.login();
    },

    async login() {
      try {
        this.show.loading = true;

        const { data } = await this.$axios.post(`/auth/login`, {
          Email: this.inputs.email,
          Senha: this.inputs.password,
        });

        const dairies = _.get(data, 'acessos', []);

        if (data.codigo === 0) {
          return this.setErrorMessage(data.mensagem);
        }

        this.dairies = dairies.map((dairy, index) => {
          return {
            name: dairy.laticinio,
            system: dairy.sistema,
            selected: index === 0,
          }
        })

        this.show.dairies = true;
      } catch (e) {
        console.log(e);

        return this.setErrorMessage(e);
      } finally {
        this.show.loading = false;
      }
    },

    async authenticate() {
      try {
        this.show.dairies = false;
        this.show.loading = true;

        const selectedUser = _.find(this.dairies, 'selected');

        const user = JSON.stringify({
          email: this.inputs.email,
          senha: this.inputs.password,
          system: selectedUser.system,
        });

        const userBase64 = window.btoa(user);

        return window.location.replace(`${process.env.VUE_APP_API_BASE_URL}/auth/authenticate?_u=${userBase64}`);
      } catch (e) {
        console.log(e);

        return this.setErrorMessage(e);
      }
    },

    setErrorMessage(message) {
      this.show.dairies = false;

      this.inputs.email = ``;
      this.inputs.password = ``;

      this.error.show = true;
      this.error.message = message;

      return setTimeout(() => {
        this.error.show = false;
        this.error.message = ``;

        this.show.email = true;

        return this.$nextTick(() => {
          this.$refs.email.focus();
        })
      }, 5000);
    },

    onKeyPress({ code }) {
      if (code === 'ArrowUp') {
        return this.onUp();
      }

      if (code === 'ArrowDown') {
        return this.onDown();
      }

      if (['Enter', 'NumpadEnter'].includes(code)) {
        return this.onEnter();
      }
    },

    onUp() {
      const actualSelection = _.findIndex(this.dairies, dairy => dairy.selected);
      const previousIndex = actualSelection - 1;

      if (actualSelection === 0) {
        return;
      }

      this.dairies = this.dairies.map((dairy, index) => {
        if (index === previousIndex) {
          return {
            ...dairy,
            selected: true,
          }
        }

        if (index === actualSelection) {
          return {
            ...dairy,
            selected: false,
          }
        }

        return dairy;
      });
    },

    onDown() {
      const actualSelection = _.findIndex(this.dairies, dairy => dairy.selected);
      const nextIndex = actualSelection + 1;
      const lastIndex = this.dairies.length - 1;

      if (actualSelection === lastIndex) {
        return;
      }

      this.dairies = this.dairies.map((dairy, index) => {
        if (index === nextIndex) {
          return {
            ...dairy,
            selected: true,
          }
        }

        if (index === actualSelection) {
          return {
            ...dairy,
            selected: false,
          }
        }

        return dairy;
      });
    },

    onEnter() {
      return this.authenticate();
    },
  }
}
</script>

