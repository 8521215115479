import { render, staticRenderFns } from "./RoutePointsFormItem.vue?vue&type=template&id=735a6760"
import script from "./RoutePointsFormItem.vue?vue&type=script&lang=js"
export * from "./RoutePointsFormItem.vue?vue&type=script&lang=js"
import style0 from "./RoutePointsFormItem.vue?vue&type=style&index=0&id=735a6760&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports