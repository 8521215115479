<template>
  <div>
    <v-row
      no-gutters
      class="text-xs-center"
    >
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>

        <v-row class="mx-2">
          <v-col>
            <v-autocomplete
              v-model="filters.regions"
              :loading="loading"
              :items="filters.regionsList"
              prepend-inner-icon="map"
              multiple
              dark
              label="Regiões"
              item-text="description"
              item-value="id"
              hide-details
              return-object
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              @input="onRegionFilterSelect"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.cities"
              :loading="loading"
              :items="filters.citiesList"
              prepend-inner-icon="home"
              multiple
              dark
              label="Municípios"
              item-text="description"
              item-value="code"
              hide-details
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              @input="onFilter"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.status"
              :loading="loading"
              :items="filters.statusList"
              prepend-inner-icon="group"
              multiple
              dark
              label="Status"
              item-text="description"
              item-value="id"
              hide-details
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              @input="onFilter"
            >
              <template #item="{ item }">
                <v-list-item-action>
                  <v-checkbox :value="filters.status.includes(item.id)" />
                </v-list-item-action>
                <v-list-item-content :style="{color: statusColors[item.id]}">
                  {{ item.description }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.technicians"
              :loading="loading"
              :items="filters.techniciansList"
              prepend-inner-icon="supervisor_account"
              multiple
              dark
              label="Técnicos"
              item-text="name"
              item-value="id"
              return-object
              hide-details
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              @input="onTechnicianFilterSelect"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.producers"
              :loading="loading"
              :items="filters.producersList"
              prepend-inner-icon="person"
              multiple
              dark
              label="Produtor"
              item-text="name"
              item-value="id"
              hide-details
              filled
              chips
              small-chips
              deletable-chips
              background-color="rgba(0, 0, 0, 0.5)"
              @input="onFilter"
            />
          </v-col>
          <v-col>
            <routes-autocomplete-filter
              v-model="filters.routes"
              label="Rota"
              dark
              multiple
              hide-details
              background-color="rgba(0, 0, 0, 0.5)"
              @change="onApiFilter"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="technicians.length > 0"
          class="mx-2"
        >
          <template v-for="technician in technicians">
            <v-col
              :key="technician.id"
              :cols="technicianColSize"
            >
              <technician-resume
                :producers="technician.producers"
                :routes="technician.routes"
                text-left
              >
                <template slot="title">
                  <v-menu
                    bottom
                    right
                  >
                    <template #activator="{ on, attrs }">
                      <v-hover #default="{ hover }">
                        <v-avatar
                          :color="technician.color"
                          size="25"
                          class="mr-3"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            v-if="hover"
                            dark
                          >
                            edit
                          </v-icon>
                        </v-avatar>
                      </v-hover>
                    </template>
                    <v-color-picker
                      v-model="technician.color"
                      hide-canvas
                      hide-inputs
                      hide-sliders
                      mode="hexa"
                      show-swatches
                      swatches-max-height="145"
                      @input="onFilter"
                    />
                  </v-menu>
                  {{ technician.name }}
                </template>
              </technician-resume>
            </v-col>
          </template>
        </v-row>

        <v-row class="mx-2">
          <template v-for="(status, idx) in filters.statusList">
            <v-col
              :key="idx"
              :cols="columnSize"
            >
              <v-card
                color="transparent"
                dark
                class="region-resume"
              >
                <v-card-title
                  class="text-subtitle-2 pt-2 pb-2"
                  style="background: rgba(0, 0, 0, 0.5);"
                >
                  <v-avatar
                    :color="statusColors[status.id]"
                    size="25"
                    class="mr-3"
                  />
                  {{ status.description }}
                  <v-spacer />
                  {{ getTotalByStatus(status.id) }}
                </v-card-title>
              </v-card>
            </v-col>
          </template>
        </v-row>

        <v-row
          class="mx-2"
          wrap
        >
          <v-col cols="12">
            <v-card dark>
              <v-card-text class="pa-0">
                <route-map
                  ref="map"
                  :center="dairyLocation"
                  :markers="markers"
                  :polygons="polygons"
                  height="700px"
                  disable-recenter
                  use-simple-marker
                  @onPolygonMarkers="onPolygonMarkers"
                  @click:marker="onClickMarker"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mx-2">
          <template v-for="region in regions">
            <v-col
              :key="region.id"
              :cols="columnSize"
            >
              <region-resume
                :producers="region.producers"
                text-left
              >
                <template slot="title">
                  <v-avatar
                    :color="region.color"
                    size="25"
                    class="mr-3"
                  />
                  {{ region.description }}
                </template>
              </region-resume>
            </v-col>
          </template>
        </v-row>

        <v-overlay :value="loading">
          <v-card-text>
            Carregando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-overlay>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="light-blue"
            v-on="on"
            @click="calculateDistance()"
          >
            <v-icon>place</v-icon>
          </v-btn>
        </template>

        <span>Calcular distância</span>
      </v-tooltip>

      <v-tooltip
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="purple lighten-1"
            v-on="on"
            @click="printMap"
          >
            <v-icon>print</v-icon>
          </v-btn>
        </template>

        <span>Imprimir Mapa</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import RegionResume from "@/Domains/Map/Components/RegionResume.vue";
import TechnicianResume from "@/Domains/Map/Components/TechnicianResume.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import { debounce, groupBy } from "lodash";
import colors from 'vuetify/lib/util/colors'
import { gmapApi } from "gmap-vue";
import moment from "moment";

const regionColors = [
  '#FF5722',
  '#512DA8',
  '#8BC34A',
  '#FFA000',
];

const statusColors = {
  OBSERVACAO: colors.blue.lighten1,
  NEGOCIACAO: colors.blue.darken2,
  APROVACAO: colors.blue.darken4,
  SUSPENSO: colors.orange.darken1,
  DESVINCULADO: colors.pink.darken1,
  ATIVO: colors.green.darken1,
};

const technicianColors = [
  colors.pink.accent3,
  colors.purple.accent3,
  colors.blue.accent3,
  colors.cyan.accent3,
  colors.teal.accent3,
  colors.green.accent3,
  colors.deepPurple.accent3,
  colors.lime.accent3,
  colors.yellow.accent3,
  colors.indigo.accent3,
  colors.amber.accent3,
  colors.orange.accent3,
  colors.lightBlue.accent3,
  colors.deepOrange.accent3,
  colors.lightGreen.accent3,
];

export default {
  name: "map",

  components: {
    RouteMap,
    RegionResume,
    TechnicianResume,
    RoutesAutocompleteFilter,
  },

  mixins: [ReportMixin],

  data() {
    return {
      // Loader
      loading: false,

      // Informações das regiões
      regions: [],

      // Informações dos técnicos
      technicians: [],

      // Polígonos das regiões
      polygons: [],

      // Localização dos produtores
      markers: [],

      filters: {
        regionsList: [],
        regions: [],

        statusList: [
          { id: 'OBSERVACAO', description: "Prospectado" },
          { id: 'NEGOCIACAO', description: "Negociação" },
          { id: 'APROVACAO', description: "Aprovação" },
          { id: 'SUSPENSO', description: "Suspenso" },
          { id: 'DESVINCULADO', description: "Desvinculado" },
          { id: 'ATIVO', description: "Ativo" },
        ],
        status: [],

        techniciansList: [],
        technicians: [],

        producersList: [],
        producers: [],

        routes: [],
        citiesList: [],
        cities: [],
      },

      statusColors,
    };
  },

  computed: {
    columnSize() {
      return 12 / this.regions.length;
    },
    technicianColSize() {
      return 12 / this.technicians.length;
    },

    totalByStatus() {
      return groupBy(this.markers, 'status')
    },

    dairyLocation() {
      if (this.$store.state.settings.coordenadas) {
        return this.$store.state.settings.coordenadas;
      }

      const dairy = this.$store.state.settings.laticinios
        .find(dairy => dairy.location.lat && dairy.location.lng);

      if (dairy) {
        return dairy.location;
      }

      return  null;
    },

    google: gmapApi,
  },

  async mounted() {
    await this.loadRegionsFilter();
    await this.loadProducers();
    await this.loadTechnicians();
  },

  methods: {
    /**
     * Método responsável por carregar todas as regiões disponíveis para o laticínio
     * @returns {Promise<void>}
     */
    async loadRegionsFilter() {
      try {
        const { data } = await this.$axios.post(`/regiao/listaJson`, this.$qs.stringify({
          tipo: 'CAPTACAO'
        }));

        this.filters.regionsList = data.map((item) => {
          return {
            id: item.id_regiao,
            description: item.nome,
            coordinates: JSON.parse(item.coordenadas || '[]')
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as regiões!", "Atenção");

        console.log(err);
      }
    },

    /**
     * Método responsável por carregar os técnicos
     * @returns {Promise<void>}
     */
    async loadTechnicians() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaTecnicos`);

        this.filters.techniciansList = data
          .map((technician, idx) => ({
            id: technician.id_pessoa,
            name: technician.nome,
            routes: technician.rotas.filter(o => o.id_tipo === 1),
            color: technicianColors[idx % technicianColors.length],
            producers: [],
          }));

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o mapa!", "Atenção");

        console.log(err);
      }
    },

    /**
     * Trata os dados dos produtores para ser utilizado pelos componentes
     * @param producers
     * @returns {Array}
     */
    producersParser(producers, color = null) {
      return producers
        .filter(producer =>
          !!producer.nome && producer.end_latitude && producer.end_longitude)
        .map((producer) => {
          return {
            id: producer.id_pessoa,
            name: producer.nome,
            status: producer.ativo === 1 && (producer.status === 'APROVADO' || !producer.status) ? 'ATIVO' : producer.status,
            location: {
              lat: parseFloat(producer.end_latitude.replace(/^0+/, '')),
              lng: parseFloat(producer.end_longitude.replace(/^0+/, '')),
              cityCode: producer.end_cidade_codigo,
            },
            cbt: parseFloat(producer.cbt),
            ccs: parseFloat(producer.ccs),
            vol: parseFloat(producer.litros) || 0,
            competingDairy: producer.ativo == 0 ? producer.laticinio_concorrente : '',
            users: JSON.parse(producer.pessoa_acesso) ?? [],
            color,
          };
        })
        .filter(producer => producer.location.lat && producer.location.lng);
    },

    /**
     * Método responsável por carregar os produtores
     * @returns {Promise<void>}
     */
    async loadProducers() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/produtores/listaJson`, {
          rotas: this.filters.routes.map(item => item.id),
          exibir_pessoa_acesso: true,
        });

        this.filters.producersList = this.producersParser(data);

        this.onFilter();
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores dos mapa!", "Atenção");

        console.log(err);
      } finally {
        this.loadCities();
      }
    },

    /**
     * Método responsável por carregar as regiões
     * @returns {Promise<void>}
     */
    async loadRegions() {
      try {
        this.loading = true;

        this.regions = [...this.filters.regions];

        this.polygons = this.regions.map(({ coordinates, description }, index) =>
          ({ coordinates, title: description, editable: false, color: regionColors[index] }));

        this.regions = this.regions.map((region, index) => {
          return {
            ...region,
            producers: [],
            color: regionColors[index]
          }
        })

        setTimeout(() => {
          this.$refs.map.setMapPolygons();
        }, 300);

      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Método responsável por carregar as cidades
     * @returns {Promise<void>}
     */
    async loadCities() {
      try {
        this.loading = true;

        const codes = [...new Set(this.filters.producersList.map(item => item.location.cityCode).filter(o => o))];

        if (codes.length === 0) {
          this.filters.citiesList = [];
          return;
        }

        const { data } = await this.$queries.post(`/municipios/listaJson`, { codigos: codes });

        this.filters.citiesList = data.map(o => ({
          description: o.end_cidade,
          code: o.codigo_municipio
        }));

      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * @event array
     * Filtro por região
     */
    onRegionFilterSelect(e) {
      if (e.length > 4) {
        e.pop();
        return;
      }
      this.onFilter();

      return this.loadRegions();
    },

    /**
     * @event array
     * Filtro por técnico
     */
    onTechnicianFilterSelect() {
      this.onFilter();
    },

    onApiFilter: debounce(function () {
      this.loadProducers();
    }, 1000),

    /**
     * Utilizado por multiplos filtros, para exibir as informações dos produtores
     * através dos marcadores nos mapas
     */
    onFilter: debounce(function () {
      this.loading = true;
      let status = this.filters.status;
      let producers = this.filters.producers;
      let cities = this.filters.cities;
      let technicians = this.filters.technicians;
      let techniciansIds = this.filters.technicians.map(t => t.id);

      const onProducersStatus = producerStatus => (status.length === 0) || status.includes(producerStatus);
      const onProducers = producer => (producers.length === 0) || producers.includes(producer);
      const onProducersCity = producerCity => (cities.length === 0) || (cities.findIndex(x => x == producerCity) > -1);
      const onTechnicians = users => (technicians.length === 0) || users.some(user => techniciansIds.includes(user));

      let markers = [...this.filters.producersList];

      if (status.length > 0 || producers.length > 0 || cities.length > 0 || technicians.length > 0) {
        markers = markers.filter(marker => {
          return onProducersStatus(marker.status) && onProducers(marker.id) && onProducersCity(marker.location.cityCode) && onTechnicians(marker.users);
        })
      }

      markers = markers
        .map((producer, idx) => {
          let color = producer.color || statusColors[producer.status];

          if (technicians.length > 1) {
            color = technicians.find(t => producer.users.includes(t.id))?.color
          }

          return {
            ...producer,
            label: idx + 1,
            draggable: false,
            visible: true,
            color,
            title: producer.name
          }
        });

      // Adiciona o laticínio
      if (this.$store.state.settings.laticinio) {
        markers.push({
          key: `dairy`,
          location: {
            lat: this.$store.state.settings.coordenadas.lat,
            lng: this.$store.state.settings.coordenadas.lng,
          },
          draggable: false,
          label: this.$store.state.settings.laticinio.name,
          color: 'red',
          visible: true,
        });
      }
      else {
        for (const dairy of this.$store.state.settings.laticinios) {
          markers.push({
            key: `dairy-${dairy.id}`,
            location: dairy.location,
            draggable: false,
            label: dairy.name,
            color: 'red',
            visible: true,
          });
        }
      }

      if (technicians.length > 0) {
        const producers = this.filters.producersList;
        this.technicians = technicians.map(technician => {
          technician.producers = producers.filter(p => p.users.includes(technician.id));
          return technician;
        })
      } else {
        this.technicians = [];
      }

      this.markers = markers;
      this.loading = false;
    }, 1000),

    /**
     * @event array
     * Evento acionado pelo mapa, onde exibe os marcadores que estão dentro de cada polígono
     */
    onPolygonMarkers(polygonMarkers) {
      const regions = [];
      for (let idx in polygonMarkers) {
        const markers = polygonMarkers[idx];
        regions.push({
          ...this.regions[idx],
          producers: markers
        })
      }

      this.regions = regions;
    },

    async onClickMarker({ marker, data }) {
      if (!data.name) {
        return;
      }

      const richMeta = {
        "Produtor": data.name,
        "CPP": data.cbt,
        "CCS": data.ccs,
        "Volume": data.vol ? `${data.vol} L` : null,
        "Laticínio concorrente": data.competingDairy,
        "Status": data.status
      };

      if (data.status != 'ATIVO') {
        const response = await this.negotiationStatus(data.id);

        if (response) {
          richMeta["Data Visita"] = this.formatDate(response.data_cadastro_app, "DD/MM/YYYY");
          richMeta["Preço Oferecido"] = response.preco_leite ? this.formatCurrency(response.preco_leite) : null;
          richMeta["Preço do leite da concorrência"] = response.preco_concorrencia ? this.formatCurrency(response.preco_concorrencia) : null;
          richMeta["Laticínio entrega"] = response.laticinio_origem;
          richMeta["Observações"] = response.observacao;
        }
      }

      const res = Object.entries(richMeta)
        .map(([key, value]) => value ? [`<b>${key}:</b> ${value}<br>`] : [])
        .join('\n');

      const infoWindow = new this.google.maps.InfoWindow({
        content: `<div class="text-left black--text">${res}</div>`
      });

      infoWindow.open(marker.map, marker);
    },

    async negotiationStatus(producerId) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/produtores/statusNegociacao`,
          { id_produtor: producerId }
        );

        return data;
      } catch (e) {
        console.warn(e);
        return false;
      } finally {
        this.loading = false;
      }
    },

    getTotalByStatus(status) {
      if (status && this.totalByStatus[status]) {
        return this.totalByStatus[status].length
      }

      return 0;
    },

    async calculateDistance() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/produtores/calculateDistance`);

        this.retorno = data;

        if (!this.retorno) {
          this.$snotify.info("Nenhuma nova distancia calculada", "Atenção");
        } else {
          this.$snotify.success(+data + " Distâncias calculadas", "Sucesso");
        }

      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async printMap() {
      if (this.markers.length === 0) {
        return;
      }

      const technicians = this.filters.technicians
        .map(o => o.name.toUpperCase())
        .join(', ');

      const regions = this.filters.regions
        .map(o => o.description.toUpperCase())
        .join(', ');

      const routes = this.filters.routes
        .map(o => o.description.toUpperCase())
        .join(', ');

      const cities = this.filters.cities
        .map(o => o.description.toUpperCase())
        .join(', ');

      const producers = this.markers
        .filter(producer => !producer.name?.startsWith('dairy'))
        .map((producer, idx) => {
          return {
            Código: (idx + 10).toString(36).toUpperCase(),
            Nome: producer.name,
            Status: producer.status,
            Volume: producer.vol ? `${this.formatNumber(producer.vol)} L` : '-',
            CCS: producer.ccs ? this.formatNumber(producer.ccs) : '-',
            CPP: producer.cbt ? this.formatNumber(producer.cbt) : '-',
          }
        });

      const mapUrl = await this.$refs.map.getStaticMap("940x750");

      if (!mapUrl) {
        return;
      }

      const pages = [];

      const title = `Mapa`;

      const rightTitle = [
        {
          tag: 'div',
          class: 'row',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }
      ];

      const details = [];

      if (technicians.length > 0) {
        details.push({
          tag: 'div',
          class: 'twelve columns',
          contents: `<b>Técnicos:</b> ${technicians}`,
        })
      }

      if (regions.length > 0) {
        details.push({
          tag: 'div',
          class: 'twelve columns',
          contents: `<b>Regiões:</b> ${regions}`,
        })
      }

      if (routes.length > 0) {
        details.push({
          tag: 'div',
          class: 'twelve columns',
          contents: `<b>Rotas:</b> ${routes}`,
        })
      }

      if (cities.length > 0) {
        details.push({
          tag: 'div',
          class: 'twelve columns',
          contents: `<b>Municípios:</b> ${cities}`,
        })
      }

      if (producers.length > 200) {
        details.push({
          tag: 'div',
          class: 'twelve columns',
          contents: `obs: Impressão do mapa limitado a 200 marcadores`,
        })
      }

      const header = [{
        tag: 'div',
        class: 'row',
        children: details
      }];

      const headers = Object.keys(producers[0])
        .map(key => ({
          key,
          label: key,
          class: 'text-left'
        }));

      const body = [
        {
          tag: 'div',
          class: 'spacer'
        },
        {
          tag: 'section',
          class: 'row flex-center border-outline',
          children: [
            {
              tag: 'div',
              contents: `<b>Produtores:</b> ${producers.length}`
            },
          ]
        },
        {
          tag: 'section',
          class: 'row',
          children: [{
            tag: 'datatable',
            headers,
            items: producers,
          }]
        },
      ];

      pages.push({ title, rightTitle, header, body });

      const bodyMap = [
        {
          tag: 'div',
          class: 'spacer'
        },
        {
          tag: 'section',
          class: 'text-center border-outline',
          children: [
            {
              tag: 'img',
              src: mapUrl,
              width: 940,
              height: 750,
              style: 'transform: rotate(270deg);margin: calc((940px - 750px)/2) calc((750px - 940px)/2)'
            }
          ]
        }
      ];

      pages.push({ title, rightTitle, header: [], body: bodyMap });

      await this.printPdf({ pages });
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

  },
};
</script>
