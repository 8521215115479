<template>
  <SpotPlannedAchievedChart
    :color="color"
    :height="height"
    :loading="loading"
    :report="report"
  />
</template>

<style lang="scss">
.spot-tabs-items {
  .v-window__container, .v-window-item {
    height: 100%;
  }
}
</style>

<script>
import moment from 'moment';
import _ from 'lodash';
import SpotPlannedAchievedChart from "@/Domains/BI/Views/Platform/Components/SpotPlannedAchievedChart.vue";

export default {
  components: { SpotPlannedAchievedChart },
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '250px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      report: []
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    console.log('CREATED');

    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/spot/planejadoRealizadoDiario`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isArray(data)) {
          throw "PHP Error";
        }

        // Recupera todas as matérias primas de todas as unidades
        const allRawMaterials = _.flatten(_.map(data, 'materia_prima'));

        // Agrupa pelo tipo de matéria prima
        const spotsByRawMaterial = _.groupBy(allRawMaterials, 'nome_materia_prima');

        this.report = _.values(_.mapValues(spotsByRawMaterial, (rawMaterialByDairy, rawMaterial) => {
          const allPlannedSpots = _.flatten(_.map(rawMaterialByDairy, 'planejado'));
          const allRealizedSpots = _.flatten(_.map(rawMaterialByDairy, 'realizado'));

          const plannedByDate = _.groupBy(allPlannedSpots, 'data');
          const realizedByDate = _.groupBy(allRealizedSpots, 'data');

          const plannedVolByDate = _.mapValues(plannedByDate, (volumes) => _.sumBy(volumes, 'volume'));
          const realizedVolByDate = _.mapValues(realizedByDate, (volumes) => _.sumBy(volumes, 'volume'));

          const dates = _.uniq([
            ...Object.keys(plannedVolByDate),
            ...Object.keys(realizedVolByDate)
          ]);

          return {
            rawMaterial,
            category: dates.map(date => moment(date).format('DD/MM/YYYY')),
            planned: dates.map(date => plannedVolByDate[date] || 0),
            achieved: dates.map(date => realizedVolByDate[date] || 0),
          }
        }));
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de spot!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
