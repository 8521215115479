<template>
  <v-dialog
    v-model="visible"
    max-width="600"
    persistent
    @close="close"
  >
    <v-card>
      <v-card-title>
        Configurar Impressão
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pb-0">
        <v-row
          dense
          no-gutters
          style="margin: 8px -4px;"
        >
          <v-col
            v-for="(item, idx) in params"
            :key="idx"
            cols="4"
            class="pa-1"
          >
            <v-card
              outlined
              class="text-left"
            >
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title style="font-size: .8rem;">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-select
                      v-if="item.type === 'SELECT'"
                      v-model="item.value"
                      :items="item.items"
                      dense
                      hide-details
                      class="mb-2"
                      color="blue accent-3"
                    />
                    <v-switch
                      v-else
                      v-model="item.value"
                      :label="item.value ? 'Exibir' : 'Não exibir'"
                      dense
                      hide-details
                      class="mt-0"
                      color="blue accent-3"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          outlined
          @click="print()"
        >
          Imprimir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      item: {},
      params: []
    }
  },

  methods: {
    close() {
      this.visible = false;
      this.params = [];
    },

    show({ item, params }) {
      this.visible = true;
      this.item = item;
      this.params = params;

    },

    print() {
      this.$emit('print', {
        params: this.params.reduce((acc, param) => ({ ...acc, [param.key]: param.value }), {}),
        item: this.item
      });
      this.close();
    }
  }
}
</script>
