<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    width="700"
  >
    <v-card>
      <v-card-text>
        <producer-status-timeline
          :history="localHistory"
          list-only
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import ProducerStatusTimeline from "@/Domains/Producer/Management/Components/Timeline/ProducerStatusTimeline.vue";

export default {
  components: {
    ProducerStatusTimeline
  },

  data() {
    return {
      dialog: false,

      item: {
        history: [],

        producer: {},
      },
    }
  },

  computed: {
    localHistory() {
      const historyRows = this.item.history.map(row => {
        return {
          id: row.id_vinculacao,
          date: moment(row.date).toDate(),
          status: row.status,
          competitor: {
            id: row.dairyCompetitorId,
            name: row.dairyCompetitorName,
          },
          reason: row.reason,
          otherReasons: row.otherReasons,
          params: {
            showExcludeBtn: true,
            showStatusSelect: true,
            minInputDateLimit: null,
            maxInputDateLimit: null,
          },
        }
      });

      return [
        ...historyRows,
        {
          date: this.item.producer.createdAt ? moment(this.item.producer.createdAt).toDate() : null,
          status: 'CADASTRO',
          params: {
            showExcludeBtn: false, // Desabilita exclusão da data de cadastro (Impossível excluir)
            showStatusSelect: true,
            minInputDateLimit: null,
            maxInputDateLimit: null,
          },
        }
      ];
    }
  },

  methods: {
    show(producer) {
      this.dialog = true;

      this.item = producer;
    },

    hide() {
      this.dialog = false;

      this.item = {};
    },

    rowStyle(status) {
      if (status === 'SUSPENSO') {
        return {
          icon: 'remove_circle_outline',
          color: 'orange lighten-1'
        };
      }

      if (status === 'DESVINCULADO') {
        return {
          icon: 'person_add_disabled',
          color: 'red lighten-1'
        };
      }

      return {
        icon: 'done_all',
        color: 'blue lighten-1'
      };
    },

    dateFormat(date, format) {
      if (_.isEmpty(date)) {
        return 'Data não Localizada';
      }

      return moment(date).format(format);
    },
  }
};
</script>
