<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Ações de Visitas
    </v-card-title>
    <v-card-text
      style="background-color: #4e2bb5"
      dark
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          md="1"
          class="pb-0"
        >
          N. Visitas
          <v-text-field
            v-model.number="goals.number_visit"
            placeholder="0"
            type="number"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pb-0"
        >
          Tipo
          <v-select
            v-model="goals.type"
            :items="items"
            item-value="value"
            item-text="text"
            dense
            outlined
            hide-details
          />
          <!-- <v-text-field
            v-model.number="goals.number_visit_producer"
            placeholder="0"
            type="number"
            dense
            outlined
            hide-details
          /> -->
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pb-0"
        >
          Período
          <v-select
            v-model="goals.periodo"
            :items="[
              { text: 'Mensal', value: 'mensal' },
              { text: 'Bimestral', value: 'bimestral' },
              { text: 'Trimestral', value: 'trimestral' },
              { text: 'Quinquenal' , value: 'quinquenal' },
              { text: 'Semestral', value: 'semestral' },
              { text: 'Anual', value: 'anual' },
            ]"
            placeholder="Selecione um Período"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pb-0"
        >
          Duração Mínima (Horas)
          <masked-input
            v-model="goals.minimum_time"
            placeholder="HH:MM"
            outlined
            dense
            prepend-inner-icon="schedule"
            :mask="TimeMask"
            :rules="[
              v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
            ]"
            :max-length="5"
          />
        </v-col>
        <v-col
          md="3"
          class="pb-0F"
        >
          Questionário
          <v-select
            v-model="goals.questionnaires"
            label="Questionários"
            :items="questionnaires"
            :loading="loading"
            prepend-inner-icon="icon-cadastros"
            item-value="id"
            item-text="title"
            multiple
            outlined
            small-chips
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import IMask from "imask";

export default {

  components: {
    MaskedInput,
  },

  props: {
    value: {}
  },

  data() {
    return {
      questionnaires: [],

      loading: false,

      items: [
        { text: 'Total', value: 'TOTAL' },
        { text: 'Por produtor', value: 'POR_PRODUTOR' }
      ],

      TimeMask: {
        mask: 'HH:MM',
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            placeholderChar: 'HH',
            from: 0,
            to: 23,
            maxLength: 2,
          },
          MM: {
            mask: IMask.MaskedRange,
            placeholderChar: 'MM',
            from: 0,
            to: 59,
            maxLength: 2,
          },
        },
      },

    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  mounted() {
    this.loadQuestionnaires();
  },

  methods: {
    async loadQuestionnaires() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_PMLS'],
            ativo: 1,
          }
        );

        this.questionnaires = data.map(item => {
          let text = item.titulo ? item.titulo : ``;
          text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
          text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;
          return {
            id: item.id,
            title: text,
            types: item.tipo,
          }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
