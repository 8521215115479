<template>
  <div>
    <v-card
      v-if="file"
      elevation="4"
      :loading="file.active"
      :style="`--preview-height: ${previewHeight}px;`"
      @click.prevent="onClick"
    >
      <v-img
        v-if="type == 'image'"
        :src="file.url"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.6)"
        :height="previewHeight"
        aspect-ratio="1"
      />
      <v-img
        v-else
        class="white--text align-end text-center"
        :height="previewHeight"
        aspect-ratio="1"
      />
      <div
        :class="['file-overlay', { selected: selectable && file.selected, error: !file.success, file: ['file', 'text', 'zip'].includes(type) }]"
      >
        <div
          v-if="selectable"
          class="checkbox"
        >
          <v-icon :color="type == 'image' ? 'white' : ''">
            check_circle
          </v-icon>
        </div>
        <div class="top-row">
          <span>{{ file.name }}</span>
        </div>
        <div class="bottom-row">
          <div
            class="d-flex"
            style="width:100%"
          >
            <div>
              <v-btn
                v-if="!file.active"
                text
                :dark="type == 'image'"
                icon
                @click="downloadFile()"
              >
                <v-icon>download</v-icon>
              </v-btn>
            </div>
            <v-icon
              v-if="type == 'image'"
              small
              color="orange lighten-2"
              @click.prevent="openGallery()"
            >
              photo
            </v-icon>
            <v-icon
              v-else-if="type == 'file'"
              small
              color="cyan lighten-2"
            >
              insert_drive_file
            </v-icon>
            <v-icon
              v-else-if="type == 'zip'"
              small
              color="indigo lighten-2"
            >
              book
            </v-icon>
            <v-icon
              v-else-if="type == 'text'"
              small
              color="blue lighten-2"
            >
              description
            </v-icon>
            <span>{{ file.size | formatSize }}</span>
          </div>
          <div>
            <v-btn
              v-if="!file.active && !disableDelete"
              text
              :dark="type == 'image'"
              icon
              @click="onRemoveFile"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
    <LightBox
      v-if="pictures.length > 0"
      ref="lightbox"
      :media="pictures"
      :show-thumbs="false"
    />
  </div>
</template>

<style lang="scss">
  .file-overlay {
    width: 100%;
    height: var(--preview-height);
    position: absolute;
    top: 0px;
    left: 0px;
    border: 2px solid transparent;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center !important;
    color: #FFFFFF !important;
    caret-color: #FFFFFF !important;

    .checkbox {
      display: none;
    }

    &.file {
      color: #000 !important;
      caret-color: #000 !important;
    }

    &.selected {
      border-color: #2196F3;

      .checkbox .v-icon {
        color: #64B5F6 !important;
        caret-color: #64B5F6 !important;
      }
    }

    &.error {
      border-color: #E53935;
    }

    &:hover, &.selected {
      .checkbox {
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;
        display: flex;
        transition: all 0.3s ease 0s;
      }
    }

    .top-row {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }

    .bottom-row {
      -moz-box-align: center;
      display: flex;
      align-items: center;
      height: 16px;

      .v-icon {
        margin-bottom: 2px;
        margin-right: 4px;
      }
    }
  }
</style>

<script>
import LightBox from 'vue-image-lightbox';

export default {
  name: 'file-viewer',

  components: {
    LightBox
  },

  filters: {
    formatSize: function (size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      } else if (size > 0) {
        return size.toString() + ' B'
      }
      return ''
    }
  },

  props: {

    value: Object,

    selectable: {
      type: Boolean,
      default: false
    },

    previewHeight: {
      type: Number,
      default: 200,
    },

    disableDelete: Boolean,

  },

  data() {
    return {
      file: null,
      pictures: []
    }
  },

  computed: {
    type() {
      const extension = this.value.url
        .split(".").pop()
        .split("?").shift();

      const type = this.value.type || `/${extension}`;
      if ((/\/(gif|jpe?g|jpg|tiff?|png|webp|bmp)$/i).test(type)) {
        return "image"
      }
      if (/\/(zip|rar|7z)$/i.test(type)) {
        return "zip"
      }
      if (/\/(plain|txt|log)$/i.test(type)) {
        return "text"
      }
      return "file";
    }
  },

  watch: {
    value: {
      handler(file) {
        this.fileInfo(file);
      },
      deep: true,
    },
  },

  mounted() {
    this.fileInfo(this.value);
  },

  methods: {
    onClick() {
      if (!this.value.active) {
        this.$emit('click');
      }
    },
    onRemoveFile() {
      this.$emit('removeFile');
    },
    openGallery() {
      this.pictures = [{
        'src': this.file.url,
      }]
      this.$refs.lightbox.showImage(0);
    },
    async fileInfo(value) {
      try {
        this.file = { ...value };
        this.$set(this.file, 'active', true);

        if (!('size' in this.file)) {
          this.$set(this.file, 'success', true);
          this.$set(this.file, 'selected', true);

          const { data } = await this.$axios.post(`/arquivos/storageInfoFile`, {
            fileUrl: this.value.url
          });

          let info = data.fileInfo;

          this.$set(this.file, 'name', info.name);
          this.$set(this.file, 'created', info.created);
          this.$set(this.file, 'updated', info.updated);
          this.$set(this.file, 'size', info.size);
          this.$set(this.file, 'type', info.type);
        }
      } catch (error) {
        console.warn(error);
      } finally {
        this.$set(this.file, 'active', false);
      }
    },
    async downloadFile() {
      try {
        const { data } = await this.$axios.post(`/arquivos/storageDownloadFile`, {
          fileUrl: this.value.url
        });

        let file = data.file;

        const binary = window.atob(file.base64);
        const length = binary.length;
        const bytes = new Uint8Array(length);

        for (let i = 0; i < length; i++) {
          bytes[i] = binary.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: file.type });
        const object = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = object;
        link.download = file.name;
        link.click();

        window.URL.revokeObjectURL(object);
        link.remove();

      } catch(error) {
        console.warn(error);
      }
    }
  }
}
</script>
