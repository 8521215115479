<template>
  <v-dialog
    v-model="show"
    scrollable
    :max-width="600"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Prêmios</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model="form.description"
                  label="Descrição *"
                  required
                  autofocus
                  :rules="[v => (v && v.length >= 4) || 'A descrição deve conter no mínimo 4 caracteres']"
                />
              </v-col>
              <v-col cols="3">
                <masked-text-field
                  v-model="form.value"
                  label="Pontos *"
                  :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                  unmask
                  :rules="[v => !!v || 'Campo obrigatório!']"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

export default {

  components: {
    MaskedTextField,
  },

  props: {
    value: Boolean,
    editId: String,
    idAssistencia: String,
  },

  data() {
    return {

      form: {
        description: null,
        value: null,
      }
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          description: null,
          value: null,
        };

        if (this.editId) {
          this.loadAward();
        }
      }
    }
  },

  methods: {

    async loadAward() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/awards/${this.editId}`);

        this.form = {
          value: data.valor,
          description: data.descricao,
        };
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita um item no banco de dados
     */
    async save() {
      try {

        if (!await this.$refs.form.validate()) {
          return;
        }
        if (!this.idAssistencia) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_premiacao: this.form.id,
          descricao: this.form.description,
          valor: this.form.value,
          id_assistencia: this.idAssistencia,
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);
        this.$snotify.success("Prêmio cadastrado com sucesso", "Sucesso");
        this.close();
      } catch (e) {
        this.$snotify.error("Erro ao cadastrar prêmio", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/awards`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/awards/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    }
  }

}
</script>
