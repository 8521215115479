<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Questionários
    </v-card-title>

    <v-row class="justify-center">
      <v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="itemSelected"
          :items="questionnaires"
          item-value="value"
          item-text="text"
          dark
          hide-selected
          hide-details
          label="Escolha o questionário"
          prepend-inner-icon="search"
          filled
          return-object
          @change="addItem"
        />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="goals"
      hide-default-footer
      disable-sort
    >
      <template #[`item.period`]="{ item }">
        <v-select
          v-model="item.period"
          :items="periods"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.producer`]="{ item }">
        <v-text-field
          v-model.number="item.producer"
          placeholder="0"
          type="number"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          @keypress="disableDotAndComma"
        />
      </template>
      <template #[`item.period_reapply`]="{ item }">
        <v-select
          v-model="item.period_reapply"
          :items="periods"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.producer_reapply`]="{ item }">
        <v-text-field
          v-model.number="item.producer_reapply"
          placeholder="0"
          type="number"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          @keypress="disableDotAndComma"
        />
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          color="white"
        >
          <v-icon
            @click="removeItem(item)"
          >
            delete_outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </v-card>
</template>

<script>

export default {

  components: {
  },

  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    project: String,
  },

  data() {
    return {
      headers: [
        { text: "Questionário", value: "questionnaire.text" },
        { text: "Aplicar", value: "period", width: 200 },
        { text: "% Produtores", value: "producer", width: 30 },
        { text: "Reaplicar", value: "period_reapply", width: 200 },
        { text: "% Produtores", value: "producer_reapply", width: 30 },
        { text: "", value: "actions", width: 30 },
      ],

      periods: [
        { text: "Mensal", value: "mensal" },
        { text: "Bimestral", value: "bimestral" },
        { text: "Trimestral", value: "trimestral" },
        { text: "Quadrimestral", value: "quadrimestral" },
        { text: "Quinquenal", value: "quinquenal" },
        { text: "Semestral", value: "semestral" },
        { text: "Anual", value: "anual" },
        { text: "BiAnual", value: "bianual" },
        { text: "TriAnual", value: "trianual" },
      ],

      questionnaires: [],

      itemSelected: '',

      loading: false,
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  mounted() {
    this.loadQuestionnaires();

  },

  methods: {
    async loadQuestionnaires() {

      try {
        this.loading = true;

        let tipos_visita = [];
        if (this.project == 'PMLS') {
          tipos_visita = ['VISITA_PMLS'];
        } else if (this.project == 'Dairy') {
          tipos_visita = ['VISITA_PADRAO'];
        } else if (this.project == 'PQFL') {
          tipos_visita = ['VISITA_PQFL', 'VISITA_IN77', 'VISITA_IN59',  'VISITA_BPA', 'VISITA_ROTINA', 'VISITA_CPP', 'VISITA_CCS'];
        }

        const { data } = await this.$axios.post (
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: tipos_visita,
            ativo: 1,
          }
        );

        this.questionnaires = data.filter(item => item.status == 'ATIVO')
          .map(item => {
            let text = item.titulo ? item.titulo : ``;
            text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
            text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;

            return {
              value: item.id,
              text: text,
              types: item.tipo,
            }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    addItem(questionnaire) {
      this.goals.push({ questionnaire })
      this.$nextTick(() => {
        this.itemSelected = "";
      });
    },

    removeItem(item) {
      const idx = this.goals.findIndex(o => o.questionnaire === item.questionnaire);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
