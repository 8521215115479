<template>
  <v-dialog
    v-model="input"
    width="900"
    persistent
  >
    <v-card class="predicted-dialog">
      <v-card-title>
        Lançar crédito presumido
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="form.description"
                label="Descrição *"
                prepend-inner-icon="reorder"
                persistent-hint
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                v-model="form.value"
                label="Valor *"
                prefix="R$"
                persistent-hint
                type="number"
                min="0"
                :rules="[v => !!v && v > 0 || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col cols="4">
              <v-menu
                v-model="form.creditDate.picker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="form.creditDate.value | dateFormat('DD/MM/YYYY')"
                    label="Data do crédito *"
                    prepend-inner-icon="event"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="form.creditDate.value"
                  no-title
                  @input="form.creditDate.picker = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <v-row no-gutters>
          <v-col class="align-self-end px-0 py-0">
            <small>* Campo obrigatório</small>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="grey darken-1"
          outlined
          @click="input = false"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          outlined
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: 'credit-dialog',

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      // Model
      input: false,

      form: {
        creditDate: {
          picker: false,
          value: moment().format('YYYY-MM-DD'),
        },
      },
    }
  },

  watch: {
    value() {
      this.input = this.value;
    },
    input(value) {
      if (value) {
        this.reset();
      }
      this.$emit("input", !!value);
    },
  },

  methods: {
    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      try {
        const form = {
          ...this.form,
          creditDate: this.form.creditDate.value
        };
        console.log({form});

        const { data } = await this.$axios.post(`/${this.$store.state.settings.sistema}/projetos/salvaCredito`, form);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }
        this.input = false;
        this.$emit('newCredit', data.id);
        this.$snotify.success("Crédito salvo com sucesso!", "Sucesso");
      } catch(error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar o registro!", "Atenção");
        console.warn(error);
      }
    },
    reset() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.form = {
        creditDate: {
          picker: false,
          value: moment().format('YYYY-MM-DD'),
        },
      };
    }
  }

}
</script>
