import * as _ from "lodash";

export default class AnalysisPatternValidator {

  constructor() {
    this.rule = {};
  }

  /**
   * Carrega
   * @param rule {Boolean|Object}
   */
  fromRule(rule) {
    this.rule = rule;

    return this;
  }

  /**
   * Valida o campo informado se está dentro dos padrões determinados
   * @param value {*}
   * @returns {Boolean}
   */
  validate(value) {

    if (value === null || value === '') {
      return true;
    }

    // Caso seja um checkbox
    if (_.isBoolean(this.rule)) {
      return value === this.rule;
    }

    // Caso a regra não for um tipo aplicável
    if (!_.isObject(this.rule) || _.isEmpty(this.rule)) {
      return true;
    }

    if (this.rule.max && this.rule.min) {

      const _value = parseFloat(value);
      const max = parseFloat(this.rule.max);
      const min = parseFloat(this.rule.min);

      return _value >= min && _value <= max;
    }

    if (this.rule.max) {
      const _value = parseFloat(value);
      const max = parseFloat(this.rule.max);

      return _value <= max;
    }

    if (this.rule.min) {
      const _value = parseFloat(value);
      const min = parseFloat(this.rule.min);

      return _value >= min;
    }

    return true;
  }

}
