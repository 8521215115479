<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <!-- Lista recursos -->
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="4"
            md-4
            sm-4
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
            />
          </v-col>
        </v-card-title>
        <v-data-table
          dark
          :headers="headersResource"
          :items="list"
          maxlength="19"
          class="diff-list-table"
          :search="filters.search"
          @click:row="handleClick"
        >
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="openEdit(item);">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item
                  small
                  @click="openAlert(item), showDeleteDialog = true;"
                >
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Alert excluir -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false"
      @keydown.esc="showDeleteDialog = false"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-center text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-center black--text">
            Deseja realmente Executar a Ação ?
          </h3>
          <v-flex
            xs12
            class="font-weight-black"
          >
            <br>
          </v-flex>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="openDelete(); showDeleteDialog = false;"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Botão Add nova menu -->
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="openInsert(); cadastroSelecionado;"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Inserir Menu</span>
    </v-tooltip>
    <dialog-resource
      v-if="showCadastro"
      :visible="showCadastro"
      :dados-recurso="resource"
      @close="closeEdit()"
      @change="loadResource()"
    />
  </div>
</template>
<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import IMask from "imask";
import _ from "lodash";
import DialogResource from "@/Domains/SettingsMilkup/Components/DialogResource.vue";

export default {
  name: "Resource",

  components: {
    DialogResource,
  },

  filters: {
    //Filtro usado em todas as mascaras da view
    Mask(value, mask) {
      if (_.isEmpty(value)) {
        return value;
      }
      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  },
    
  data() {
    return {
      filters: {
        search: "",
      },
      loading: false,

      showCadastro: false,

      showDeleteDialog: false,

      cadastroSelecionado: {},

      resource: [],
            
      objetcToDelete: "",

      headersResource: [
        {
          text: "Recurso",
          value: "recurso",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {   value: "actions",
          sortable: false,
          align: "end",
          width: 20,
        },
      ],

      list: [],

      CNPJMask: {
        mask: "00.000.0000/0000-00",
        maxlength: 19,
      },
                    
    };
  },
    
  mounted() {
    this.loadResource();
  },

  methods: {

    openInsert() {
      this.showCadastro = true;
      this.resource = { id_recurso: "", recurso: "", };
      this.permssionSelected = [];
               
    },

    async openEdit(item) {
      this.showCadastro = true;
      this.resource = item;
    },

    openAlert(item) {
      this.objetcToDelete = item.id_recurso;
    },

    openDelete() {
      this.onResourceDelete(this.objetcToDelete);
    },

    async onResourceDelete(toDelete) {
      try {

        const { data } = await this.$axios.post(
          `/configuracaoMilkup/deleteRecurso`,
          this.$qs.stringify({
            id_recurso: toDelete,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw "PHP Error";
        }

        return this.loadResource();

      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Trata evento de clique na linha..
     */
    handleClick(item) {
      this.openEdit(item);
    },

    async loadResource() {

      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaRecurso`);
                
        this.list = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },
        
    closeEdit() {
      this.showCadastro = false;
    },
        
  },
}
</script>

