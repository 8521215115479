<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="quality-priority-ranking"
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Prioritários / Ranking
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="6"
        md="3"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onDateFilter"
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          hide-details
          @change="loadReport"
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          hide-details
          @change="loadReport"
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-select
          v-model="filter.type"
          :items="types"
          label="Filtro"
          dark
          filled
          hide-details
          @change="loading = true"
        />
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-autocomplete
          v-model="filter.status"
          :items="[
            { text: 'Ativos', value: 'ATIVOS' },
            { text: 'Desvinculados', value: 'DESVINCULADOS' },
          ]"
          label="Exibir"
          placeholder=" "
          hide-details
          dark
          filled
          @change="loadReport"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#graphs">
        Gráficos
      </v-tab>

      <v-tab href="#reports">
        Relatórios
      </v-tab>

      <v-tab-item value="graphs">
        <v-row>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="polarGraph.options"
                  :style="{width: '100%', height: `${polarGraph.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="ccsPie.options"
                  :style="{width: '100%', height: `${ccsPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            md="4"
            sm="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="pie"
                  :options="cbtPie.options"
                  :style="{width: '100%', height: `${cbtPie.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>
          <template v-if="filter.type == 'ranking'">
            <v-col
              md="4"
              sm="6"
              cols="12"
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="pie"
                    :options="cbtCcsOutOfNormativePie.options"
                    :style="{width: '100%', height: `${cbtCcsOutOfNormativePie.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="4"
              sm="6"
              cols="12"
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="pie"
                    :options="ccsOutOfNormativePie.options"
                    :style="{width: '100%', height: `${ccsOutOfNormativePie.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="4"
              sm="6"
              cols="12"
            >
              <v-card
                class="graph-card"
              >
                <v-card-text>
                  <v-chart
                    type="pie"
                    :options="cbtOutOfNormativePie.options"
                    :style="{width: '100%', height: `${cbtOutOfNormativePie.height}px !important`}"
                    autoresize
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </template>
          <v-col
            md="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="bar"
                  :options="ccsGraph.options"
                  :style="{width: '100%', height: `${ccsGraph.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-card
              class="graph-card"
            >
              <v-card-text>
                <v-chart
                  type="bar"
                  :options="cbtGraph.options"
                  :style="{width: '100%', height: `${cbtGraph.height}px !important`}"
                  autoresize
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="reports">
        <v-row>
          <v-col md="4">
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title>
                CCS Acima de {{ limitCCS }}
                <v-spacer />
                <v-col
                  cols="4"
                  md-4
                  sm-4
                  class="pa-0 subtitle-1"
                >
                  {{ `Produtores: ${ ccsProducers}` }}
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="ccsReport.headers"
                :items="ccsReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="ccsReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-col>

          <v-col md="4">
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title>
                CPP Acima de {{ limitCBT }}
                <v-spacer />
                <v-col
                  cols="4"
                  md-4
                  sm-4
                  class="pa-0 subtitle-1"
                >
                  {{ `Produtores: ${ cbtProducers }` }}
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="cbtReport.headers"
                :items="cbtReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="cbtReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-col>

          <v-col md="4">
            <v-card
              class="report-card"
              color="transparent"
            >
              <v-card-title>
                CPP e CCS Acima da Norma
                <v-spacer />
                <v-col
                  cols="4"
                  md-4
                  sm-4
                  class="pa-0 subtitle-1"
                >
                  {{ `Produtores: ${ ccscbtProducers }` }}
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="cbtCcsReport.headers"
                :items="cbtCcsReport.items"
                dark
                dense
                disable-sort
                hide-default-footer
                :items-per-page="cbtCcsReport.items.length"
                class="elevation-1 report-table"
              />
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template v-slot:activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
      </v-tab-item>
    </v-tabs>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.quality-priority-ranking {
  .graph-card {
    margin-top: 5px !important;
    background: rgba(0, 0, 0, 0.5) !important;

    > .v-card__title {
      color: #fff;
      background: unset;
    }

    > .v-card__text {
      color: #000 !important;
    }
  }
  .report-card {
    > .v-card__title {
      color: #fff;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import QualityMixins from "@/Domains/Quality/Mixins/QualityMixins.js";
import QualityPriorityMixin from "@/Domains/Quality/Mixins/QualityPriorityMixin.js";

import XLSX from "xlsx-js-style";
import moment from "moment";

export default {
  name: "quality-priority-ranking",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  mixins: [
    QualityMixins,
    QualityPriorityMixin
  ],

  data() {
    return {
      loading: true,

      tab: null,

      filter: {
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        type: 'priority',
        status: 'ATIVOS',
      },

      types: [
        {
          value: 'ranking',
          text: 'Ranking'
        },
        {
          value: 'priority',
          text: 'Prioritários'
        },
      ],

      totalProducers: 0,
      ccs: [],
      cbt: [],
      cbtCcs: [],

      ranges: {}
    };
  },

  computed: {
    limitCCS() {
      return this.ranges['CCS'] ? (this.ranges['CCS'].list[this.ranges['CCS'].limit].value || 500) : 500;
    },

    limitCBT() {
      return this.ranges['CPP'] ? (this.ranges['CPP'].list[this.ranges['CPP'].limit].value || 300) : 300;
    },

    ccsPriority() {
      return this.ccs.filter(o => o.ccs > this.limitCCS);
    },

    cbtPriority() {
      return this.cbt.filter(o => o.cbt > this.limitCBT);
    },

    cbtCcsPriority() {
      return this.cbtCcs.filter(o => (o.cbt > this.limitCBT && o.ccs > this.limitCCS));
    },

    polarGraph() {
      return this.getTotalOutOfNormativeGraphPercent(this.ccsPriority, this.cbtPriority, this.cbtCcsPriority, this.limitCCS, this.limitCBT);
    },

    ccsPie() {
      return this.generateGraphPiePercent('CCS', this.ccs, 'ccs', this.ranges['CCS'], this.filter.type);
    },

    cbtPie() {
      return this.generateGraphPiePercent('CPP', this.cbt, 'cbt', this.ranges['CPP'], this.filter.type);
    },

    ccsOutOfNormativePie() {
      return this.generateOutOfNormativeGraphPiePercent('CCS', this.ccs.length - this.ccsPriority.length, this.ccsPriority.length, this.limitCCS)
    },

    cbtOutOfNormativePie() {
      return this.generateOutOfNormativeGraphPiePercent('CPP', this.cbt.length - this.cbtPriority.length, this.cbtPriority.length, this.limitCBT)
    },

    cbtCcsOutOfNormativePie() {
      return this.generateOutOfNormativeGraphPiePercent('CCS E CPP', this.cbtCcs.length - this.cbtCcsPriority.length, this.cbtCcsPriority.length);
    },

    ccsGraph() {
      const data = this.filter.type == 'ranking' ? this.ccs : this.ccsPriority;
      console.log(data);
      return this.generateGraphBar('CCS', data, 'ccs', this.ranges['CCS']);
    },

    cbtGraph() {
      const data = this.filter.type == 'ranking' ? this.cbt : this.cbtPriority;
      return this.generateGraphBar('CPP', data, 'cbt', this.ranges['CPP']);
    },

    ccsReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Média Coleta', value: 'media_coleta' },
          { text: 'CCS x 1000', value: 'ccs', align: 'center', width: 100 },
        ],
        items: this.ccsPriority
      }
    },

    cbtReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Média Coleta', value: 'media_coleta' },
          { text: 'CPP x 1000', value: 'cbt', align: 'center', width: 100 },
        ],
        items: this.cbtPriority
      }
    },

    cbtCcsReport() {
      return {
        headers: [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome_produtor' },
          { text: 'Rota Principal', value: 'rota' },
          { text: 'Média Coleta', value: 'media_coleta' },
          { text: 'CCS x 1000', value: 'ccs', align: 'center', width: 100 },
          { text: 'CPP x 1000', value: 'cbt', align: 'center', width: 100 },
        ],
        items: this.cbtCcsPriority
      }
    },

    ccsProducers() {
      return this.filter.type === 'ranking' ? this.ccs.length : this.ccsReport.items.length;
    },

    cbtProducers() {
      return this.filter.type === 'ranking' ? this.cbt.length : this.cbtReport.items.length;
    },

    ccscbtProducers() {
      return this.filter.type === 'ranking' ? this.cbtCcsPriority.length : this.cbtCcsReport.items.length;
    }
  },

  async created() {
    await this.loadRanges();
    await this.loadReport();
  },

  updated() {
    this.$nextTick(function () {
      this.loading = false;
    })
  },

  methods: {

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter([month]) {
      this.filter.date.month = month;
      this.loadReport();
    },

    async loadRanges() {

      try {
        this.loading = true;

        this.ranges = await this.getRanges();

      } catch (error) {
        this.ranges = [];
        this.$snotify.error("Erro ao carregar as configurações", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadReport() {
      try {
        const month = this.filter.date.month;
        const technicianId = this.filter.technician.id;
        const routes = this.filter.routes.map(({ id }) => id);
        const status = this.filter.status;

        const { totalProducers, ccs, cbt, cbtccs } = await this.getQualityPriorityReport(month, technicianId, routes, status);

        this.totalProducers = totalProducers;
        this.ccs = ccs;
        this.cbt = cbt;
        this.cbtCcs = cbtccs;
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
    },

    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.ccsPriority.map(item => ({
        'Produtor': item.nome_produtor,
        'CCS x 1000': item.ccs,
      })), { origin: 'A3' });

      XLSX.utils.sheet_add_json(data, this.cbtPriority.map(item => ({
        'Produtor': item.nome_produtor,
        'CPP x 1000': item.cbt,
      })), { origin: 'E3' });

      XLSX.utils.sheet_add_json(data, this.cbtCcsPriority.map(item => ({
        'Produtor': item.nome_produtor,
        'CCS x 1000': item.ccs,
        'CPP x 1000': item.cbt,
      })), { origin: 'I3' });

      data["!merges"] = [
        { s: 'A1', e: 'B1' },
        { s: 'E1', e: 'F1' },
        { s: 'I1', e: 'K1' },
      ];
      data['A1'] = { v: `CCS Acima de ${this.limitCCS}` };
      data['E1'] = { v: `CPP Acima de ${this.limitCBT}` };
      data['I1'] = { v: 'CPP e CCS Acima da Norma' };

      data['!cols'] = [
        { wch: 30 },
        { wch: 9 },
        { wch: 10 },
        { wch: 10 },
        { wch: 30 },
        { wch: 9 },
        { wch: 10 },
        { wch: 10 },
        { wch: 30 },
        { wch: 9 },
        { wch: 9 },
      ];

      const workbook = XLSX.utils.book_new();

      const title = `Prioritários ${moment(this.filter.date.month).format('MM.YYYY')}"`;

      XLSX.utils.book_append_sheet(workbook, data, title);
      XLSX.writeFile(workbook, `${title}.xlsx`);
    },
  },
};
</script>
