<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Configurações Gerais
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-row>
      <v-col
        cols="12"
        class="text-left font-weight-bold"
        style="font-size: small"
      >
        Exibir dados Pessoais do Produtor nos relatórios de sanidade
        <v-divider></v-divider>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Telefone</span>
                <v-switch
                  v-model="item.mostrar_telefone"
                  :label="item.mostrar_telefone ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                />
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Cidade</span>
                <v-switch
                  v-model="item.mostrar_cidade"
                  :label="item.mostrar_cidade ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                />
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>CPF</span>
                <v-switch
                  v-model="item.mostrar_cpf"
                  :label="item.mostrar_cpf ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                />
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>Região</span>
                <v-switch
                  v-model="item.mostrar_regiao"
                  :label="item.mostrar_regiao ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                />
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="2"
      >
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <span>E-mail</span>
                <v-switch
                  v-model="item.mostrar_email"
                  :label="item.mostrar_email ? 'Visivel' : 'Não Visivel'"
                  dense
                  hide-details
                  class="mt-0"
                  color="green accent-3"
                />
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="save();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import _ from "lodash";

export default {

  components: {
  },
  props: {
    sanity: String,

  },

  data() {
    return {
      item: {
        id_configuracao: null,
        mostrar_telefone: false,
        mostrar_cidade: false,
        mostrar_cpf: false,
        mostrar_email: false,
        mostrar_regiao: false,
      },
    }

  },

  async mounted() {
    await this.getSettings();
  },

  methods: {
    async getSettings() {
      try {
        this.$root.$progressBar.loading();
        let { data } = await this.$axios.get(`settings/general`);

        if (!_.isEmpty(data)) {
          this.item = data;
        }

      } catch (e) {
        this.$snotify.error("Erro ao carregar configuracao", "Atenção");
        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      try {

        this.$root.$progressBar.saving();

        const payload = {
          mostrar_telefone: this.item.mostrar_telefone,
          mostrar_cidade: this.item.mostrar_cidade,
          mostrar_cpf: this.item.mostrar_cpf,
          mostrar_email: this.item.mostrar_email,
          mostrar_regiao: this.item.mostrar_regiao,
        };

        const response = !_.isEmpty(this.item.id_configuracao) ? await this.update(payload) : await this.store(payload);

        this.item.id_configuracao = response.id_configuracao;

        this.getSettings();

        this.$snotify.success("Configuração salva com sucesso", "Sucesso");

      } catch (e) {

        this.$snotify.error("Erro ao salvar Configuração", "Atenção");
        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`settings/general`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`settings/general/${this.item.id_configuracao}`, payload);
      return data;
    },
  }
}
</script>
