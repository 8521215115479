<template>
  <CardGauge
    title="Taxa de Ocup. Média"
    :value="occupancyRate"
    :color="color"
    :height="height"
    :loading="loading"
  />
</template>

<script>
import moment from 'moment';

import CardGauge from "@/Domains/BI/Components/CardGauge.vue";

export default {
  components: { CardGauge },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '101px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      occupancyRate: 0,
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(`/relatorios/taxaOcupacaoColeta`, {
          data_inicio: startDate, data_fim: endDate
        } );

        const occupancyRateAvg = (data.reduce((acc, cur) => acc + parseFloat(cur.taxa_ocupacao_media), 0) / data.length) || 0;

        this.occupancyRate = Math.trunc(occupancyRateAvg);
      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório de taxa de ocupação!', 'Atenção');
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
