<template>
  <index
    sanity="TUBERCULOSE"
  />
</template>

<script>
import Index from "@/Domains/Projects/HealthExamination/SanitySettingsIndex.vue"

export default {
  components: {
    Index
  },
}
</script>
