<template>
  <v-autocomplete
    v-model="input"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    :label="label"
    item-text="name"
    item-value="code"
    return-object
    cache-items
    :clearable="clearable"
    :prepend-inner-icon="prependInnerIcon"
    :rules="rules"
    hide-no-data
    :filter="(item, queryText, itemText) => item.search.includes(queryText.toLocaleUpperCase())"
    v-bind="$attrs"
    @change="onChange"
  >
    <template #selection="{ item }">
      <div
        v-if="item.code"
        class="mx-1 font-weight-light"
      >
        {{ item.code }} -
      </div>
      {{ item.name }}
    </template>

    <template #item="{ item }">
      <v-list-item-content>
        <template v-if="item.code">
          <v-list-item-title>
            <span class="mx-1 font-weight-light">
              {{ item.code }} -
            </span>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-html="item.fullName"
          />
        </template>
        <v-list-item-title
          v-else
          class="text-decoration-line-through"
        >
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { throttle, isArray } from 'lodash';

export default {

  props: {

    value: {
      type: Object,
    },

    label: {
      type: String,
      default: 'Banco'
    },

    clearable: Boolean,

    prependInnerIcon: {
      type: String,
      default: 'business',
    },

    rules: Array,
  },

  data() {
    return {
      loading: false,
      input: {},
      items: [],
      search: ''
    }
  },

  watch: {
    value() {
      this.input = this.value || {};
      if (this.input.name) {
        this.query(this.input.name);
      }
    },
    
    search(val) {
      if (val && val !== this.input.name) {
        this.loading = true;
        this.query(val);
      }
    },
  },

  created() {
    this.input = this.value || {};
  },

  mounted() {
    this.input = this.value || {};
    if (this.input.name) {
      this.query(this.input.name);
    }
  },

  methods: {
    query: throttle(async function(busca) {
      try {
        this.loading = true;

        const { data } = await this.$queries.post(`/bancos/listaJson`, { busca });

        if (!isArray(data)) {
          throw 'Error';
        }

        this.items = data.map(o => ({
          id: o.id_banco,
          code: o.codigo_banco,
          name: o.nome_reduzido,
          fullName: o.nome,
          search: `${o.codigo_banco} - ${o.ispb} - ${o.nome_reduzido} - ${o.nome}`.toLocaleUpperCase()
        }));

        this.updateBanks();
      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os bancos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    }, 500),

    /**
     * Mantém a compatibilidade com o valor que já estava salvo no BD
     */
    updateBanks() {
      if (this.input.name && !this.input.code) {
        if (!this.items.find(item => item.name === this.input.name)) {
          const name = this.input.name.toLocaleUpperCase();
          this.items.push({
            code: null,
            search: name,
            name
          });
        }
      }
    },

    onChange(event) {
      // atualiza o v-model do componente
      this.$emit("input", event || {});

      this.$emit("change");
    },
  }
}
</script>
