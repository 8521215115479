<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab>
            Geral
          </v-tab>
          <v-tab v-if="hasBIVisitsAccess">
            Visitas
          </v-tab>
          <v-tab v-if="hasBIQualityAccess">
            Qualidade
          </v-tab>
          <v-tab v-if="hasBITransportAccess">
            Transportes
          </v-tab>
          <v-tab v-if="hasBIPlatformAccess">
            Plataforma
          </v-tab>
          <v-tab v-if="hasBIMilkPriceAccess">
            Preço Leite
          </v-tab>
          <v-tab v-if="hasBIProducersAccess">
            produtores
          </v-tab>

          <v-tabs-items
            v-model="tab"
            touchless
          >
            <v-tab-item>
              <Personal />
            </v-tab-item>
            <v-tab-item v-if="hasBIVisitsAccess">
              <Visits />
            </v-tab-item>
            <v-tab-item v-if="hasBIQualityAccess">
              <Quality />
            </v-tab-item>
            <v-tab-item v-if="hasBITransportAccess">
              <Transport />
            </v-tab-item>
            <v-tab-item v-if="hasBIPlatformAccess">
              <Platform />
            </v-tab-item>
            <v-tab-item v-if="hasBIMilkPriceAccess">
              <MilkPrice />
            </v-tab-item>
            <v-tab-item v-if="hasBIProducersAccess">
              <Producers />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Demo from "@/Domains/BI/Views/Demo/Index.vue";
import Personal from "@/Domains/BI/Views/Personal/Index.vue";
// import General from "@/Domains/BI/Views/General/Index.vue";
import Visits from "@/Domains/BI/Views/Visits/Index.vue";
import Quality from "@/Domains/BI/Views/Quality/Index.vue";
import Transport from "@/Domains/BI/Views/Transport/Index.vue";
import Platform from "@/Domains/BI/Views/Platform/Index.vue";
import MilkPrice from "@/Domains/BI/Views/MilkPrice/Index.vue";
import Producers from "@/Domains/BI/Views/Producers/Index.vue";

export default {
  name: "dashboard",

  components: {
    Personal,
    Visits,
    Quality,
    Transport,
    Platform,
    MilkPrice,
    Producers,
  },

  data() {
    return {
      tab: null,
    };
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasBIVisitsAccess() {
      // Desabilita acesso à aba de Visitas temporariamente, para validar se pode ser removido em definitivo
      return false;
      //return this.isAdmin || this.userResources.some(o => o.recurso === "bi-visits" && o.tipo === "COMPONENTE");
    },

    hasBIQualityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bi-quality" && o.tipo === "COMPONENTE");
    },

    hasBITransportAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bi-transport" && o.tipo === "COMPONENTE");
    },

    hasBIPlatformAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bi-platform" && o.tipo === "COMPONENTE");
    },

    hasBIMilkPriceAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bi-milk-price" && o.tipo === "COMPONENTE");
    },

    hasBIProducersAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bi-producers" && o.tipo === "COMPONENTE");
    },
  },
};
</script>
