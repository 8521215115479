<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Logs de Data Efetivação
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="pt-0"
              >
                <v-date-range-picker
                  v-model="filters.dateRange"
                  dark
                  @change="loadReport"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.dairyCode"
                  label="Código Itinerário"
                  hide-details
                  dark
                  filled
                  @change="loadReport"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            show-expand
            single-expand
            dark
          >
            <template #[`item.data_hora_descarga`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm:ss') }}
            </template>
            <template #[`item.data_hora`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm:ss') }}
            </template>
            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pa-3"
              >
                <v-data-table
                  :headers="logsHeaders"
                  :items="item.logs"
                  hide-default-footer
                  disable-filtering
                  disable-pagination
                  disable-sort
                  dark
                >
                  <template #[`item.novo.data_hora_descarga`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY HH:mm:ss') }}
                  </template>
                  <template #[`item.anterior.data_hora_descarga`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY HH:mm:ss') }}
                  </template>
                  <template #[`item.data_hora`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY HH:mm:ss') }}
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {

  components: {
    VDateRangePicker
  },

  data() {
    return {
      filters: {
        dairyCode: '',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      headers: [
        { text: 'Código', value: 'cod_itinerario' },
        { text: 'Rota', value: 'descricao_rota' },
        { text: 'Motorista', value: 'nome_pessoa' },
        { text: 'Placa', value: 'placa' },
        { text: 'Data Efetivação', value: 'data_hora_descarga' },
        { text: 'Atualizado Em', value: 'data_hora' },
      ],

      logsHeaders: [
        { text: 'Atualizado', value: 'novo.data_hora_descarga' },
        { text: 'Anterior', value: 'anterior.data_hora_descarga' },
        { text: 'Usuário', value: 'nome_pessoa' },
        { text: 'Usuário BD', value: 'usuario_bd' },
        { text: 'Atualizado Em', value: 'data_hora' },
      ],

      items: [],
    };
  },

  methods: {
    async loadReport() {
      try {
        this.$root.$progressBar.loading();

        const [dateStart, dateEnd] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/itinerario/logs`, {
          params: {
            data_ini: dateStart,
            data_fim: dateEnd,
            cod_itinerario: this.filters.dairyCode
          }
        });

        this.items = data.map(item => {
          item.logs = JSON.parse(item.logs);
          item.data_hora = item.logs[0].data_hora;
          return item;
        });
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
