<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="monthRef.picker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="monthRef.value | dateFormat('MM/YYYY')"
                label="Mês de início"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="monthRef.value"
              :max="maxDateRef"
              type="month"
              no-title
              scrollable
              @input="monthRef.picker = false"
            />
          </v-menu>
        </v-col>

        <v-col cols="12">
          <quality-goals v-model="qualityGoals" />
        </v-col>

        <v-col cols="12">
          <v-card
            elevation="1"
            outlined
            dark
            color="transparent"
          >
            <v-card-title class="d-flex flex-column">
              Laticínio
              <span class="body-1 font-weight-light">
                Meta de assistências por Mês
              </span>
            </v-card-title>
            <v-data-table
              :headers="dairyGoals.headers"
              :items="dairyGoalsItems"
              hide-default-footer
              disable-sort
            />
          </v-card>
        </v-col>

        <v-col
          cols="12"
        >
          <v-card
            elevation="1"
            outlined
            dark
            color="transparent"
          >
            <v-card-title class="d-flex flex-column">
              Técnicos
              <span class="body-1 font-weight-light">
                Meta de assistências por Mês
              </span>
            </v-card-title>
            <v-data-table
              :headers="technicianGoals.headers"
              :items="technicianGoals.items"
              hide-default-footer
            >
              <template #[`item.BPA`]="{ item }">
                <v-text-field
                  v-model="item.BPA"
                  label="BPA"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.COMPRAR_LEITE`]="{ item }">
                <v-text-field
                  v-model="item.COMPRAR_LEITE"
                  label="Compra de Leite"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.IN76`]="{ item }">
                <v-text-field
                  v-model="item.IN76"
                  label="INT76"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.PROSPECCAO`]="{ item }">
                <v-text-field
                  v-model="item.PROSPECCAO"
                  label="Prospecção"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.ROTINA`]="{ item }">
                <v-text-field
                  v-model="item.ROTINA"
                  label="Rotina"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.SUSPENSAO`]="{ item }">
                <v-text-field
                  v-model="item.SUSPENSAO"
                  label="Suspensão"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.VENDER_PRODUTOS`]="{ item }">
                <v-text-field
                  v-model="item.VENDER_PRODUTOS"
                  label="Venda"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.VETERINARIO`]="{ item }">
                <v-text-field
                  v-model="item.VETERINARIO"
                  label="Veterinário"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.ZOO_TECNICO`]="{ item }">
                <v-text-field
                  v-model="item.ZOO_TECNICO"
                  label="Zootécnico"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.PMLS`]="{ item }">
                <v-text-field
                  v-model="item.PMLS"
                  label="PMLS"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.COLETA_AMOSTRA`]="{ item }">
                <v-text-field
                  v-model="item.COLETA_AMOSTRA"
                  label="Coleta Amostra"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
              <template #[`item.OUTROS`]="{ item }">
                <v-text-field
                  v-model="item.OUTROS"
                  label="Outros"
                  placeholder="0"
                  type="number"
                  dense
                  outlined
                  hide-details
                  @keypress="disableDotAndComma"
                />
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click.stop="save"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar configuração
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import QualityGoals from "@/Domains/Visits/Goals/Views/Components/QualityGoals.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    QualityGoals,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },

  props: {
    value: String
  },

  data() {
    return {
      id: null,

      maxDateRef: moment().format("YYYY-MM"),

      monthRef: {
        picker: false,
        value: moment().format('YYYY-MM'),
      },

      technicians: [],

      qualityGoals: {},

      quantityGoals: [],

      dairyGoals: {
        headers: [
          { text: "BPA", value: "BPA", width: 100, align: 'center' },
          { text: "Compra de Leite", value: "COMPRAR_LEITE", width: 100, align: 'center' },
          { text: "IN76", value: "IN76", width: 100, align: 'center' },
          { text: "Prospecção", value: "PROSPECCAO", width: 100, align: 'center' },
          { text: "Rotina", value: "ROTINA", width: 100, align: 'center' },
          { text: "Suspensão", value: "SUSPENSAO", width: 100, align: 'center' },
          { text: "Venda", value: "VENDER_PRODUTOS", width: 100, align: 'center' },
          { text: "Veterinário", value: "VETERINARIO", width: 100, align: 'center' },
          { text: "Zootécnico", value: "ZOO_TECNICO", width: 100, align: 'center' },
          { text: "PMLS", value: "PMLS", width: 100, align: 'center' },
          { text: "Coleta Amostra", value: "COLETA_AMOSTRA", width: 100, align: 'center' },
          { text: "Outros", value: "OUTROS", width: 100, align: 'center' },
        ],
      },

      technicianGoals: {
        headers: [
          { text: "Técnico", value: "technician.name", width: 300 },
          { text: "BPA", value: "BPA", align: 'center', sortable: false },
          { text: "Compra de Leite", value: "COMPRAR_LEITE", align: 'center', sortable: false },
          { text: "IN76", value: "IN76", align: 'center', sortable: false },
          { text: "Prospecção", value: "PROSPECCAO", align: 'center', sortable: false },
          { text: "Rotina", value: "ROTINA", align: 'center', sortable: false },
          { text: "Suspensão", value: "SUSPENSAO", align: 'center', sortable: false },
          { text: "Venda", value: "VENDER_PRODUTOS", align: 'center', sortable: false },
          { text: "Veterinário", value: "VETERINARIO", align: 'center', sortable: false },
          { text: "Zootécnico", value: "ZOO_TECNICO", align: 'center', sortable: false },
          { text: "PMLS", value: "PMLS", align: 'center', sortable: false },
          { text: "Coleta Amostra", value: "COLETA_AMOSTRA", align: 'center', sortable: false },
          { text: "Outros", value: "OUTROS", align: 'center', sortable: false },
        ],

        items: [],
      },

      saving: false,

    };
  },

  computed: {
    dairyGoalsItems() {
      const initial = {
        BPA: 0,
        COMPRAR_LEITE: 0,
        IN76: 0,
        PROSPECCAO: 0,
        ROTINA: 0,
        SUSPENSAO: 0,
        VENDER_PRODUTOS: 0,
        VETERINARIO: 0,
        ZOO_TECNICO: 0,
        PMLS: 0,
        COLETA_AMOSTRA: 0,
        OUTROS: 0,
      };
      const dairyGoals = this.technicianGoals.items.reduce((acc, cur) => {
        acc.BPA += parseInt(cur.BPA) || 0;
        acc.COMPRAR_LEITE += parseInt(cur.COMPRAR_LEITE) || 0;
        acc.IN76 += parseInt(cur.IN76) || 0;
        acc.PROSPECCAO += parseInt(cur.PROSPECCAO) || 0;
        acc.ROTINA += parseInt(cur.ROTINA) || 0;
        acc.SUSPENSAO += parseInt(cur.SUSPENSAO) || 0;
        acc.VENDER_PRODUTOS += parseInt(cur.VENDER_PRODUTOS) || 0;
        acc.VETERINARIO += parseInt(cur.VETERINARIO) || 0;
        acc.ZOO_TECNICO += parseInt(cur.ZOO_TECNICO) || 0;
        acc.PMLS += parseInt(cur.PMLS) || 0;
        acc.COLETA_AMOSTRA += parseInt(cur.COLETA_AMOSTRA) || 0;
        acc.OUTROS += parseInt(cur.OUTROS) || 0;
        return acc;
      }, initial);
      return [dairyGoals];
    }
  },

  watch: {
    value() {
      this.id = this.value;
      this.load();
    }
  },

  created() {
    this.id = this.value;
    this.load();
  },

  methods: {
    async load() {
      await this.loadTechnicians();
      if (this.id) {
        this.loadPlanning();
      }
      else {
        this.setState();
      }
    },
    /**
     * Método responsável por carregar os técnicos
     * @returns {Promise<void>}
     */
    async loadTechnicians() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaTecnicos`);

        this.technicians = data
          .map((technician) => ({
            id: technician.id_pessoa,
            name: technician.nome
          }));

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");

        console.log(err);
      }
    },

    async loadPlanning() {
      try {
        const { data } = await this.$axios.post(`/recomendacoesQualidade/detalhe`, { id: this.id });

        if (!_.isObject(data)) {
          throw data;
        }

        this.monthRef.value = data.data;

        this.setState(data);
      }
      catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas!", "Atenção");
        console.warn(err)
      }
    },

    setState(data = null) {
      /**
       * Metas de Qualidade
       */

      this.qualityGoals = {
        CRITICAL: { status: 'Críticos', visits: null, questionnaires: [] },
        PRIORITY: { status: 'Prioritários', visits: null, questionnaires: [] },
      };

      if (data && !_.isEmpty(data.qualidade)) {
        const { CRITICO, PRIORITARIO } = data.qualidade;

        this.qualityGoals = {
          CRITICAL: { status: 'Críticos', visits: CRITICO.visitas, questionnaires: CRITICO.questionarios },
          PRIORITY: { status: 'Prioritários', visits: PRIORITARIO.visitas, questionnaires: PRIORITARIO.questionarios },
        };
      }

      /**
       * Metas de Quantidade
       */
      this.quantityGoals = [];

      if (data && !_.isEmpty(data.quantidade)) {
        this.quantityGoals = data.quantidade.map(goal => ({
          indicator: goal.indicador,
          action: goal.acao,
          percent: goal.percentual
        }));
      }

      /**
       * Metas de Visitas
       */
      const savedTechnicians = data && !_.isEmpty(data.tecnico) ? data.tecnico.reduce((acc, { id_tecnico, nome_tecnico, ...goals }) => {
        acc[id_tecnico] = {
          technician: {
            id: id_tecnico,
            name: nome_tecnico,
          },
          ...goals
        };
        return acc;
      }, {}) : {};

      const technicians = this.technicians.reduce((acc, technician) => {
        acc[technician.id] = {
          technician,
          BPA: null,
          COMPRAR_LEITE: null,
          IN76: null,
          PROSPECCAO: null,
          ROTINA: null,
          SUSPENSAO: null,
          VENDER_PRODUTOS: null,
          VETERINARIO: null,
          ZOO_TECNICO: null,
          PMLS: null,
          COLETA_AMOSTRA: null,
          OUTROS: null,
        }
        return acc;
      }, {});

      this.technicianGoals.items = Object.values({ ...technicians, ...savedTechnicians });
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        this.$snotify.warning("Campos inválidos", "Atenção");
        return false;
      }
      try {
        this.saving = true;

        const [general] = this.dairyGoalsItems;

        const technicians = this.technicianGoals.items.map(({ technician, ...goals }) => ({
          id_tecnico: technician.id,
          nome_tecnico: technician.name,
          ...goals
        }));

        const { CRITICAL, PRIORITY } = this.qualityGoals;

        const quantity = this.quantityGoals.map(goal => ({
          indicador: goal.indicator,
          acao: goal.action,
          percentual: goal.percent
        }));

        const payload = {
          id_assistencia: this.id,
          data: moment(this.monthRef.value).format('YYYY-MM-01'),
          geral: general,
          tecnico: technicians,
          quantidade: quantity,
          qualidade: {
            CRITICO: { visitas: parseFloat(CRITICAL.visits), questionarios: CRITICAL.questionnaires },
            PRIORITARIO: { visitas: parseFloat(PRIORITY.visits), questionarios: PRIORITY.questionnaires },
          },
        };

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/salvaMetas`,
          payload
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.id = data.id;
        this.$emit('input', data.id);
        this.loadPlanning();
        this.$snotify.success("Planejamento salvo com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar planejamento", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
