<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Inconformidades e Descartes
        </h2>

        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      dark
      color="transparent diff-list"
      style="margin-top:20px"
    >
      <v-card-title>
        <v-row>
          <v-col class="py-0">
            <date-range-picker
              v-model="filters.date.input"
              @change="onDateFilter"
            />
          </v-col>

          <v-col class="py-0">
            <v-select
              v-model="filters.types"
              :items="[
                'INCONFORMIDADE',
                'DESCARTE',
              ]"
              clearable
              filled
              hide-details
              prepend-inner-icon="fact_check"
              label="Tipo"
              multiple
              small-chips
            />
          </v-col>

          <v-col class="py-0">
            <v-select
              v-model="filters.responsibles"
              :items="[
                'LATICINIO',
                'PRODUTOR',
                'MOTORISTA'
              ]"
              clearable
              filled
              hide-details
              prepend-inner-icon="how_to_reg"
              label="Responsável"
              multiple
              small-chips
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        v-model="selected"
        item-key="id_descarga_analise"
        name="Inconformidades e Descartes"
        dark
        :loading="loading"
        :headers="headers"
        :items="filteredList"
        :search="filters.search"
        show-custom-group
        editing
        show-select
      >
        <template #[`item.tipo`]="{ value }">
          <v-chip
            small
            :color="getTypeColor(value)"
          >
            {{ value }}
          </v-chip>
        </template>
        <template #[`item.responsavel`]="{ value }">
          <v-chip small>
            {{ value }}
          </v-chip>
        </template>

        <template #[`item.info`]="{ item }">
          <v-btn
            icon
            dark
            @click="showDiscardInfo(item)"
          >
            <v-icon>info</v-icon>
          </v-btn>

          <template v-if="item.tipo === 'DESCARTE' && item.responsavel !== 'LATICINIO'">
            <v-btn
              v-if="item.id_titulo"
              icon
              style="position:relative"
              @click="showBill(item)"
            >
              <v-icon>
                attach_money
              </v-icon>
              <v-icon
                small
                class="financial-badge"
                color="green accent-4"
              >
                check_circle
              </v-icon>
            </v-btn>
            <v-tooltip
              v-else
              top
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  style="position:relative"
                  @click.stop="generateFinancial(item)"
                  v-on="on"
                >
                  <v-icon>
                    attach_money
                  </v-icon>
                  <v-icon
                    small
                    class="financial-badge"
                    color="red accent-3"
                  >
                    remove_circle
                  </v-icon>
                </v-btn>
              </template>
              Gerar financeiro
            </v-tooltip>
          </template>
        </template>

        <template #[`footer.prepend`]>
          <div class="text-caption text-left ml-4">
            Total de
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ items.length }}
            </v-chip>
            registros
          </div>
        </template>
      </data-table>
    </v-card>

    <print-settings-dialog
      ref="print-settings"
      @print="printRecibo"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selected.length"
        color="orange darken-1"
        dark
        fab
        @click="onPrint"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              receipt_long
            </v-icon>
          </template>
          Imprimir Recibo
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <discard-info-dialog
      ref="discardInfoDialog"
    />

    <bill-dialog
      v-if="billDialog.show"
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      :type="billDialog.type"
      @input="billDialog.id = null"
      @removeBill="loadDiscard"
    />

    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </div>
</template>

<style lang="scss">
.diff-list {
  .financial-badge {
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 0px;
  }
}
</style>

<script>
import moment from "moment-timezone";
import * as _ from "lodash";
import printJS from "print-js";

import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import DiscardInfoDialog from "@/Domains/Platform/Reports/Components/DiscardInfoDialog.vue";
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";

export default {
  components: {
    DiscardInfoDialog,
    DateRangePicker,
    BillDialog,
    PrintSettingsDialog,
  },

  data() {
    return {
      loading: false,

      filters: {
        date: {
          input: 'today',
          range: [],
        },
        search: '',

        responsibles: [],

        types: [],
      },

      headers: [
        { text: 'Codigo', value: 'cod_itinerario', width: 150, align: 'center' },
        { text: 'Data/Hora', value: 'data_hora_descarga', width: 150, formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm'), align: 'center' },
        { text: 'Rota', value: 'nome_rota', width: 150, align: 'center' },
        { text: 'Tipo', value: 'tipo', width: 120, formatter: value => this.truncate(value, 11), align: 'center' },
        { text: 'Responsável', value: 'responsavel', width: 100, align: 'center' },
        { text: 'Codigo laticinio', value: 'codigo_laticinio', width: 80, align: 'center' },
        { text: 'Nome', value: 'nome_responsavel', width: 200, align: 'center' },
        { text: 'Coleta (L)', value: 'quantidade_coleta', width: 80, align: 'center', formatter: value => this.formatInt(value) },
        { text: 'Inconf. (L)', value: 'volume_liquido', width: 80, align: 'center', formatter: value => this.formatInt(value) },
        { text: 'Tanque', value: 'tanque', width: 80, align: 'center' },
        { text: 'Analises', value: 'analises', width: 200, align: 'center' },
        { text: 'Fraudes', value: 'fraudes', width: 200, align: 'center' },
        { text: '', value: 'info', width: 110, align: 'center' },
      ],

      items: [],

      billDialog: {
        show: false,
        type: 'DESCONTO',
        id: null,
      },

      selected: [],
    };
  },

  computed: {

    filteredList() {
      return this.items.filter(item => {
        const hasType = this.filters.types.some(type => item.tipo === type);
        const hasResponsible = this.filters.responsibles.some(responsible => item.responsavel === responsible);

        if (!_.isEmpty(this.filters.types) && !_.isEmpty(this.filters.responsibles)) {
          return hasType && hasResponsible;
        }

        if (!_.isEmpty(this.filters.types)) {
          return hasType;
        }

        if (!_.isEmpty(this.filters.responsibles)) {
          return hasResponsible;
        }

        return true;
      });
    },

  },

  mounted() {
    this.loadDiscard();
  },

  methods: {
    onDateFilter(event) {
      this.filters.date.range = event;

      this.loadDiscard();
    },

    async loadDiscard() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.date.range;

        const { data } = await this.$axios.post(
          `/descargaColeta/listaInconformidadesDescarte`,
          this.$qs.stringify({
            data_inicio: startDate || moment().format('YYYY-MM-DD'),
            data_fim: endDate || moment().format('YYYY-MM-DD'),
          })
        );

        this.items = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    showDiscardInfo(unload) {
      this.$refs.discardInfoDialog.show(unload);
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Inconformidades e Descartes - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `Inconformidades e Descartes - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
      this.$refs.report.print(null, title);
    },

    showBill({ responsavel, id_titulo }) {
      this.billDialog.type = responsavel === 'PRODUTOR' ? 'DESCONTO' : 'DESCONTO_PRESTADOR';
      this.billDialog.id = id_titulo;
      this.billDialog.show = true;
    },

    async generateFinancial(item) {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/financeiro/gerarContaDescarte`, { id_descarga_analise: item.id_descarga_analise });

        if (!data.codigo) {
          return this.$snotify.warning("Produtor sem volume no fechamento de preço!", "Atenção");
        }

        item.id_titulo = data.id_titulo;

        this.showBill(item);

        this.$snotify.success("Conta gerada com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao gerar conta!", "Atenção");
        console.warn(error);
      } finally {
        this.loadDiscard();
        this.loading = false;
      }
    },

    onPrint() {
      this.$refs['print-settings'].show({
        item: {
          ids: this.selected.map(e => e.id_descarga_coletas)
        },
        params: [
          { title: 'Dados do Itinerário', key: 'mostra_dados_itinerario', value: false },
          { title: 'Movimentações do Silo', key: 'mostra_movimentacoes_silo', value: false }
        ]
      });
    },

    async printRecibo({ item,  params }) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/descargaColeta/reciboDescarga`, {
          ids: item.ids,
          params,
        });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir as descargas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    getTypeColor(value) {
      if (value === 'DESCARTE')
        return 'red darken-4';

      if (value === 'INCONFORMIDADE')
        return 'yellow darken-4';

      return 'green';
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatInt: (value) => !value ? '' : parseInt(value),
    truncate: (value, length) => _.truncate(value, { length }),
  },

}
</script>
