<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Relatório de Visitas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
    </v-layout>
    
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="filters.types"
            :items="types"
            clearable
            filled
            label="Tipo"
            multiple
            prepend-inner-icon="menu"
            @change="onTypeFilter"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="pb-0"
        >
          <month-picker
            v-model="filters.date.input"
            @change="onMonthFilter"
          />
        </v-col>
      </v-row>
      <v-card
        color="transparent"
        dark
      >
        <v-card-title>
          <v-chart
            :options="options"
            :style="{width: '100%', height: `350px !important`}"
          />
        </v-card-title>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

const service = new VisitsService();

export default {
  components: {
    MonthPicker
  },

  data() {
    return {
      loading: false,

      // Lista de tipos de visitas disponíveis no formulário
      types: service.typesFilter,
  
      visits: [],

      filters: {
        date: {
          input: "thisMonth",
          range: [
            moment().startOf('month').format("YYYY-MM-DD"),
            moment().endOf('month').format("YYYY-MM-DD")
          ],
        },

        // Tipos de visita
        types: null,
      }
    };
  },

  computed: {
    options() {
      const label = {
        show: true,
        lineHeight: 15,
        height: 15,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      };
      return {
        color: ['rgba(255, 255, 0, 0.7)', 'rgba(29, 233, 182, 0.7)'],
        title: {
          text: 'Relatório de Visitas',
          textStyle: { color: '#ddd' },
          subtextStyle: { color: '#ddd' }
        },
        toolbox: {
          feature: {
            saveAsImage: {
              title: '',
              backgroundColor: '#263238',
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          }
        },
        grid: {
          left: 0,
          right: 0,
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.visits.map(o => o.nome),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Atender',
            type: 'bar',
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.visits.map(o => o.total_atender),
            label
          },
          {
            name: 'Atendidos',
            type: 'bar',
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.visits.map(o => o.total_atendido),
            label
          }
        ]
      }
    },
  },

  mounted() {
    this.loadVisits();
  },

  methods: {
    onTypeFilter: _.debounce(function () {
      this.loadVisits();
    }, 500),

    onMonthFilter(event) {
      this.filters.date.range = event;
      this.loadVisits();
    },

    async loadVisits() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filters.date.range;
        let { data } = await this.$axios.post(
          `/graficos/visitaTecnico`,
          {
            data_inicio: startDate || moment().startOf('month').format("YYYY-MM-DD"),
            data_fim: endDate || moment().endOf('month').format("YYYY-MM-DD"),
            tipos: this.filters.types
          }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.visits = data;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o grafico de visitas!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },
};
</script>
