<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Fechamento de Comissões / Data Emissão
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />

      <v-col
        cols="12"
        sm="6"
        lg="3"
        class="pt-0"
      >
        <CardInfo
          title="Total Vendas"
          icon="icon-ordem_servico"
          :value="formatCurrency(totalAmount)"
          :loading="loading"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
        class="pt-0"
      >
        <CardInfo
          title="Total Comissão"
          icon="icon-quitacao_contas"
          :value="formatCurrency(totalCommision)"
          :loading="loading"
        />
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        color="transparent"
        dark
        class="mt-5"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="2"
            >
              <v-menu
                ref="menu"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRefFilter"
                    label="Mês de Referência"
                    prepend-inner-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filters.date"
                  :max="maxDateRef"
                  type="month"
                  no-title
                  scrollable
                  @change="loadReport"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="4"
              offset-md="4"
              offset-lg="6"
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          v-model="selectedSellers"
          :headers="headers"
          :items="items"
          :search="filters.search"
          :loading="loading"
          class="elevation-1"
          :items-per-page="-1"
          item-key="id_fechamento_comissao"
          show-expand
          single-expand
          show-select
          hide-default-footer
        >
          <template
            v-if="!filters.search"
            #no-data
          >
            <div
              v-if="!loading"
              style="height: 300px; display: flex;"
            >
              <v-row
                class="justify-center align-center"
              >
                <v-btn
                  class="ma-2"
                  outlined
                  color="white"
                  @click="regenerate"
                >
                  Gerar fechamento do período
                </v-btn>
              </v-row>
            </div>
          </template>

          <template #[`item.total_valor`]="{value}">
            <span>{{ formatCurrency(value) }}</span>
          </template>

          <template #[`item.percentual_comissao`]="{value}">
            <span>{{ formatNumber(value) }}%</span>
          </template>

          <template #[`item.total_comissao`]="{value}">
            <span>{{ formatCurrency(value) }}</span>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0 text-center"
            >
              <v-data-table
                :headers="details.headers"
                :items="item.vendas"
                :items-per-page="-1"
                dense
                hide-default-footer
                class="elevation-1 ma-3"
              >
                <template #[`item.data`]="{value}">
                  <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
                </template>
                <template #[`item.valor_venda`]="{value}">
                  <span>{{ formatCurrency(value) }}</span>
                </template>
                <template #[`item.valor_comissao`]="{value}">
                  <span>{{ formatCurrency(value) }}</span>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <v-card-title>
          <v-spacer />
        </v-card-title>
      </v-card>
    </v-flex>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        color="blue darken-4"
        fab
        dark
        @click="regenerate"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              autorenew
            </v-icon>
          </template>

          Resetar Fechamento
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selectedSellers.length"
        fab
        dark
        color="orange darken-4"
        @click="printInvoice"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              receipt_long
            </v-icon>
          </template>
          Imprimir Recibo
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import CardInfo from "@/Domains/BI/Components/CardInfo.vue";

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    CardInfo,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        date: moment().subtract(1, "month").format("YYYY-MM"),
        search: ''
      },

      headers: [
        { text: "Vendedor", value: "nome_vendedor" },
        { text: "Valor Vendas", value: "total_valor", width: 180 },
        { text: "Perc. Comissão", value: "percentual_comissao", width: 180 },
        { text: "Valor Comissão", value: "total_comissao", width: 180 },
      ],

      items: [],

      details: {
        headers: [
          { text: "Código", value: "cod_movimento" },
          { text: "Cliente", value: "nome_cliente" },
          { text: "Data", value: "data" },
          { text: "Valor", value: "valor_venda" },
          { text: "Comissão", value: "valor_comissao", width: 180 },
        ],
      },

      selectedSellers: [],
    };
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY-MM");
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, "YYYY-MM").format("MM/YYYY");
    },

    /**
     * Recupera o valor líquido a pagar
     * @returns {Number}
     */
    totalAmount() {
      if (this.items.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.items, 'total_valor');
    },

    /**
     * Recupera o valor total a pagar
     * @returns {Number}
     */
    totalCommision() {
      if (this.items.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.items, 'total_comissao');
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/comercial/fechamentoComissao`,
          { mes_referencia: this.filters.date }
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data;

      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    /**
     * Action responsável por re-fazer o fechamento do período
     * @returns {Promise<void>}
     */
    async regenerate() {
      if (this.items.length > 0) {
        if (!(await this.$root.$confirm(
          'Atenção',
          'Esta ação irá descartar <b>TODOS</b> os dados preenchidos do período selecionado, independente dos filtros aplicados<br>Clique em SIM para Confirmar.',
          { color: 'orange' }
        ))) {
          return;
        }
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/comercial/gerarFechamentoComissao`,
          {
            mes_referencia: this.filters.date,
          },
        );

        if (!data.codigo) {
          throw data.mensagem;
        }

        return this.loadReport();
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    getReportJson() {
      return this.items.map((item) => ({
        'Vendedor': item.nome_vendedor,
        'Valor Vendas': this.formatCurrency(item.total_valor),
        'Perc. Comissão': this.formatNumber(item.percentual_comissao) + '%',
        'Valor Comissão': this.formatCurrency(item.total_comissao),
      }));
    },

    print() {
      return this.printFromJson(this.getReportJson(), `Comissões - ${moment(this.filters.date, 'YYYY-MM').format('MMM-YY').toUpperCase()}`);
    },

    async printInvoice() {
      try {
        this.loading = true;

        if (_.isEmpty(this.selectedSellers)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/comercial/imprimeFechamentoComissao`,
          this.selectedSellers.map(row => row.id_fechamento_comissao)
        );

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return this.printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir os recibos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const data = this.XLSX.utils.json_to_sheet(this.getReportJson());
      const workbook = this.XLSX.utils.book_new();
      const filename = `Comissões - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;

      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value) || 0,
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
