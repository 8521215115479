<template>
  <v-row>
    <v-col
      :cols="type == 'PEDIDO_PRODUTOR' ? 4 : 5"
    >
      <v-text-field
        :value="localData.name"
        label="Item *"
        prepend-inner-icon="reorder"
        readonly
        persistent-hint
        hide-details
        :disabled="!editing"
        :rules="[v => !!v || 'Campo obrigatório!']"
      />
    </v-col>
    <v-col
      v-if="type == 'PEDIDO_PRODUTOR'"
      cols="2"
      lg="3"
    >
      <person-autocomplete-filter
        v-model="localData.supplier"
        label="Fornecedor/Agropecuária"
        type="SUPPLIER"
        :filled="false"
        prepend-inner-icon="add_business"
        dark
        persistent-hint
        :disabled="supplierDisabled || !editing"
        hide-details
      />
    </v-col>
    <v-col
      cols="2"
      :lg="type == 'PEDIDO_PRODUTOR' ? 1 : 2"
    >
      <masked-text-field
        v-model="localData.quantity"
        label="Quantidade *"
        prepend-inner-icon="iso"
        persistent-hint
        hide-details
        unmask
        :mask="{ mask: Number, min: 0 }"
        :disabled="!editing"
        :rules="[v => !!v && localData.quantity > 0 || 'Campo obrigatório!']"
        @input="onItemQuantityChanged(localData)"
      />
    </v-col>
    <v-col cols="2">
      <money-input
        v-model="amount"
        label="Valor unitário"
        prefix="R$"
        persistent-hint
        hide-details
        readonly
        :disabled="!editing"
        :rules="[() => !!amount && amount > 0 || 'Campo obrigatório!']"
      />
    </v-col>
    <v-col cols="1">
      <money-input
        :value="discountValue"
        label="Desconto"
        prefix="R$"
        persistent-hint
        hide-details
        readonly
        :disabled="!editing || !['DESCONTO_VALOR', 'DESCONTO_PERCENTUAL'].includes(localData.adjustmentType)"
      />
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0">
      <money-input
        :value="subTotalWithDiscount"
        label="Subtotal"
        prefix="R$"
        persistent-hint
        hide-details
        readonly
        :disabled="!editing"
      />
    </v-col>
    <div
      class="pb-2 pr-3 align-self-end"
    >
      <v-btn
        icon
        @click="changeDiscount"
      >
        <v-icon>money_off</v-icon>
      </v-btn>
      <v-btn
        v-if="editing"
        icon
        @click.stop="removeItem"
      >
        <v-icon>delete</v-icon>
      </v-btn>
    </div>

    <price-adjustment-dialog
      ref="price-adjustment"
      @save="onSaveAdjusment"
    />
  </v-row>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import PriceAdjustmentDialog from "./PriceAdjustmentDialog.vue";
import _ from "lodash";
import { round, percentageBy } from "@/Support/Resources/utils";

export default {

  components: {
    PersonAutocompleteFilter,
    MaskedTextField,
    MoneyInput,
    PriceAdjustmentDialog,
  },

  props: {
    type: String,
    editing: Boolean,
    supplierDisabled: Boolean,
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    localData: {
      get() {
        return this.item;
      },

      set(newValue) {
        return this.$emit("update:item", newValue);
      },
    },

    subTotalWithDiscount() {
      return this.subTotal - (this.discountValue || 0)
    },

    amount() {
      return this.localData.price + (this.localData.increment || 0)
    },

    subTotal() {
      return this.localData.quantity * this.amount
    },

    discountPerc: {
      get() {
        if (this.localData.adjustmentType !== 'DESCONTO_VALOR') {
          return this.localData.discountPerc || null
        }
        const subTotal = this.subTotal;
        const discountValue = this.discountValue
        if (!discountValue) {
          return null;
        }
        return round(percentageBy(subTotal, discountValue), 2)
      },

      set(newValue) {
        this.$set(this.localData, 'discountPerc', newValue);
      },
    },

    discountValue: {
      get() {
        if (this.localData.adjustmentType !== 'DESCONTO_PERCENTUAL') {
          return this.localData.discountValue || null
        }
        const subTotal = this.subTotal;
        const discountPerc = this.discountPerc
        if (!discountPerc) {
          return null;
        }
        return (discountPerc * subTotal) / 100
      },

      set(newValue) {
        this.$set(this.localData, 'discountValue', newValue);
      },
    }
  },

  watch: {
    discountPerc: {
      immediate: true,
      handler(value) {
        this.$set(this.localData, 'discountPerc', value);
      },
    },
    discountValue: {
      immediate: true,
      handler(value) {
        this.$set(this.localData, 'discountValue', value);
      },
    },
    amount: {
      immediate: true,
      handler(value) {
        this.$set(this.localData, 'amount', value);
      },
    },
    subTotal: {
      immediate: true,
      handler(value) {
        this.$set(this.localData, 'subTotal', value);
      },
    },
    subTotalWithDiscount: {
      immediate: true,
      handler(value) {
        this.$set(this.localData, 'subTotalWithDiscount', value);
      },
    },
  },

  methods: {
    removeItem() {
      this.$emit('removeItem');
    },

    onItemQuantityChanged: _.debounce(function (item) {
      this.$emit('itemQuantityChanged', item);
    }, 500),

    changeDiscount() {
      return this.$refs['price-adjustment'].show({
        editing: this.editing,
        data: this.localData
      });
    },

    onSaveAdjusment(data) {
      this.localData = {
        ...this.localData,
        ...data
      }
    },
  }
}
</script>
