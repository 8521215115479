<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-data-table
              :headers="headers"
              :items="items"
              :search="filter.search"
              class="elevation-1"
              show-expand
              single-expand
              hide-default-footer
              item-key="id_rota"
              :items-per-page="-1"
              dense
            >
              <template #[`item.data`]="{ item }">
                <v-text-field
                  v-model="item.data"
                  label="Data"
                  type="date"
                  outlined
                  dense
                  hide-details
                  :rules="[rules.date]"
                />
              </template>
              <template #[`item.valor`]="{ item }">
                <v-text-field
                  v-model="item.valor"
                  label="Valor"
                  type="number"
                  prefix="R$"
                  outlined
                  dense
                  hide-details
                  :rules="[rules.notNegative]"
                  @keypress="onlyPositiveValues"
                />
              </template>
              <template #[`item.valor_maximo`]="{ item }">
                <div class="d-flex align-center my-1">
                  <v-text-field
                    v-model="item.valor_maximo"
                    label="Valor"
                    type="number"
                    prefix="R$"
                    outlined
                    dense
                    hide-details
                    :disabled="!item.valor"
                    class="mr-2"
                    :rules="[rules.notNegative]"
                    @keypress="onlyPositiveValues"
                  />
                  <v-tooltip left>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        @click="applyToAll(item)"
                        v-on="on"
                      >
                        <v-icon
                          style="transform: rotate(90deg);"
                        >
                          multiple_stop
                        </v-icon>
                      </v-btn>
                    </template>
                    Aplicar a todos
                  </v-tooltip>
                </div>
              </template>
              <template #[`item.data_bonus`]="{ value }">
                <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
              </template>
              <template #[`item.bonus_maximo`]="{ value, item }">
                <div class="d-flex align-center">
                  <span class="flex-grow-1 flex-shrink-0">{{ formatCurrency(value) }}</span>
                  <v-tooltip
                    v-if="item.id_tabela"
                    left
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        @click="editBonus(item)"
                        v-on="on"
                      >
                        <v-icon
                          small
                        >
                          edit
                        </v-icon>
                      </v-btn>
                    </template>
                    Editar tabela de bonifificações
                  </v-tooltip>
                  <v-tooltip left>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        @click="addBonus(item)"
                        v-on="on"
                      >
                        <v-icon
                          small
                        >
                          add
                        </v-icon>
                      </v-btn>
                    </template>
                    Inserir tabela de bonifificações
                  </v-tooltip>
                </div>
              </template>
              <template #[`item.valor_maximo_total`]="{value}">
                <span>{{ formatCurrency(value) }}</span>
              </template>

              <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
                <v-btn
                  v-if="item.tabela.length > 1 || item.tabela_bonus.length > 1"
                  icon
                  link
                  transition="fab-transition"
                  @click="expand(!isExpanded)"
                >
                  <v-icon>
                    {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                  </v-icon>
                </v-btn>
              </template>

              <template #expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0 text-center"
                >
                  <v-row no-gutters>
                    <v-col>
                      <v-data-table
                        :headers="priceDetailsHeader"
                        :items="item.tabela"
                        :items-per-page="-1"
                        dense
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template #[`item.data`]="{value}">
                          <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
                        </template>
                        <template #[`item.valor`]="{value, item}">
                          <span :class="{['text-decoration-line-through']: !item.ativo}">
                            {{ formatCurrency(value) }}
                          </span>
                        </template>
                        <template #[`item.valor_maximo`]="{value, item}">
                          <span
                            v-if="value"
                            :class="{['text-decoration-line-through']: !item.ativo}"
                          >
                            {{ formatCurrency(value) }}
                          </span>
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col>
                      <v-data-table
                        :headers="bonusDetailsHeader"
                        :items="item.tabela_bonus"
                        :items-per-page="-1"
                        dense
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template #[`item.data`]="{value}">
                          <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
                        </template>
                        <template #[`item.bonus_maximo`]="{value}">
                          <span>{{ formatCurrency(value) }}</span>
                        </template>

                        <template #[`item.action`]="{ item }">
                          <v-btn
                            icon
                            small
                            @click.stop="removeBonusConfig(item.id_tabela)"
                          >
                            <v-icon
                              color="red"
                            >
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue darken-1"
        :disabled="!hasChanges"
        @click="savePrices"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              send
            </v-icon>
          </template>
          Salvar
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        :disabled="!hasChanges"
        @click="discardChanges"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              autorenew
            </v-icon>
          </template>
          Desfazer alterações
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<style lang="scss">
  .input-has-changed.v-input:not(.v-input--is-focused):not(.v-input--has-state) {
    color: orange !important;
    caret-color: orange !important;
    .v-input__control > .v-input__slot fieldset {
      color: orange !important;
    }

    .v-label {
      color: orange
    }
  }
  .cell-border {
    border-right: thin solid rgba(255, 255, 255, 0.12);
  }
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";

export default {

  props: {
    rawMaterialId: String,
  },

  data() {
    return {

      filter: {
        search: '',
      },

      headers: [
        { text: 'Rota', value: 'descricao', class: 'cell-border', cellClass: 'cell-border' },
        { text: 'Vigência', value: 'data', width: 180, sortable: false },
        { text: 'Valor Base', value: 'valor', width: 140, sortable: false },
        { text: 'Valor Máximo', value: 'valor_maximo', width: 180, sortable: false, class: 'cell-border', cellClass: 'cell-border' },
        { text: 'Data Bônus', value: 'data_bonus', width: 120, sortable: false, align: 'center' },
        { text: 'Bônus Máximo', value: 'bonus_maximo', width: 150, sortable: false, align: 'center', class: 'cell-border', cellClass: 'cell-border' },
        { text: 'Base + Bônus Máx.', value: 'valor_maximo_total', width: 130, sortable: false, align: 'center' },
      ],

      items: [],

      originalItems: [],

      priceDetailsHeader: [
        { text: 'Data', value: 'data', sortable: false },
        { text: 'Valor', value: 'valor', width: 180, sortable: false },
      ],

      bonusDetailsHeader: [
        { text: 'Data Bônus', value: 'data', sortable: false },
        { text: 'Bônus Máximo', value: 'bonus_maximo', width: 180, sortable: false },
        { text: '', value: 'action', width: 30, sortable: false },
      ],

      rules: {
        date: v => !v || moment(v, 'YYYY-MM-DD').isValid() || 'Campo Inválido!',
        required: v => !!v || !_.isEmpty(v) || 'Campo Obrigatório!',
        notNegative: v => !v || v >= 0 || 'Valor não pode ser negativo',
      },
    };
  },

  computed: {
    hasChanges() {
      return !_.isEqual(this.items, this.originalItems)
    }
  },

  mounted() {
    this.loadRoutes();
  },

  methods: {

    async loadRoutes() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/bonus/price-table/routes`);

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data.map(route => ({
          ...route,
          valor: parseFloat(route.valor) || null
        }));

        this.originalItems = _.cloneDeep(this.items);
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    discardChanges() {
      this.items = _.cloneDeep(this.originalItems);
      this.$refs.form.resetValidation()
    },

    async savePrices() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const items = this.items.map(item => ({
          data: item.data || moment().format('YYYY-MM-DD'),
          id_rota: item.id_rota,
          valor: parseFloat(item.valor) || 0,
          valor_maximo: parseFloat(item.valor_maximo) || null,
          id_materia_prima: this.rawMaterialId,
        })).filter(item => item.valor);

        await this.$axios.post(`/bonus/milk-price/routes`, { items });

        this.$snotify.success("Tabela atualizada com sucesso", "Sucesso");
        this.loadRoutes();
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    applyToAll({ data, valor }) {
      this.items = this.items.map(item => ({
        ...item,
        data: data || moment().format('YYYY-MM-DD'),
        valor
      }))
    },

    editBonus({ id_tabela, id_rota, descricao }) {
      this.$emit(
        'selectPlanning',
        id_tabela,
        { id: id_rota, name: descricao },
      );
    },

    addBonus({ id_rota, descricao }) {
      this.$emit(
        'selectPlanning',
        null,
        { id: id_rota, name: descricao },
      );
    },

    async removeBonusConfig(id) {
      if (!(await this.$root.$confirm(
        'Confirmar remoção?',
        'Esta ação não poderá ser desfeita.',
        { color: 'red' }
      ))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/tabelaPreco/inativa`, this.$qs.stringify({ id_tabela_agrupador: id }));

        if (!data[0].codigo) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Tabela atualizada com sucesso", "Sucesso");
        this.loadRoutes();
      }
      catch (err) {
        this.$snotify.error("Erro ao atualizar tabela", "Atenção");
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    formatCurrency: (value) =>  !value ? '-' : 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    onlyPositiveValues: (e) => [45, 46].includes(e.charCode) &&  e.preventDefault(),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
