<template>
  <div>
    <v-container>
      <v-tabs
        v-model="tabQuestionnaire"
        vertical
        dark
        background-color="transparent"
        class="transparent"
      >
        <v-tab
          href="#cadastro-tab"
        >
          Cadastro
        </v-tab>
        <v-tab
          href="#configuracao-tab"
        >
          Configuração
        </v-tab>
        <v-tab
          v-if="(view == 'visits' )"
          href="#pontuacao-tab"
        >
          Pontuação
        </v-tab>
        <v-tabs-items
          v-model="tabQuestionnaire"
          class="pt-0"
        >
          <v-tab-item
            value="cadastro-tab"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-form
                ref="formContent"
                v-model="validation"
              >
                <v-row>
                  <v-col class="px-8 py-0">
                    <v-text-field
                      v-model="title"
                      prepend-inner-icon="drive_file_rename_outline"
                      outlined
                      filled
                      dark
                      class="rota-field"
                      background-color="rgba(0,0,0,.5)"
                      placeholder="Titulo"
                      :rules="[(v) => v.length > 0 || 'Campo obrigatorio']"
                      hide-details
                      :readonly="questionnaireDisabled ? true : false"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Area de renderização das questões -->
                  <v-col
                    v-for="(component, index) in questionList"
                    :key="index"
                    class="pl-8 pr-8 pb-0 col-content"
                    md="12"
                    cols="12"
                  >
                    <component
                      :is="component.component"
                      v-model="component.values"
                      :questionnaire-disabled="questionnaireDisabled"
                      :ordem="component.ordem"
                      @onDeleteQuestion="openConfirmDialog"
                      @onDisableQuestionCondition="onDisableQuestionCondition"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
            <v-speed-dial
              fixed
              dark
              bottom
              right
              open-on-hover
              direction="top"
              transition="slide-y-reverse-transition"
              class="mr-5"
            >
              <template v-slot:activator>
                <v-btn
                  color="blue darken-2"
                  dark
                  large
                  fab
                >
                  <v-icon>menu</v-icon>
                </v-btn>
              </template>

              <v-btn
                v-if="showActions"
                fab
                dark
                color="amber darken-3"
                @click="addQuestionCard()"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      help_outline
                    </v-icon>
                  </template>
                  Nova Questão
                </v-tooltip>
              </v-btn>

              <v-btn
                v-if="showActions"
                fab
                dark
                color="cyan darken-3"
                @click="addSubTitle()"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      closed_caption_off
                    </v-icon>
                  </template>
                  Novo Titulo/Grupo
                </v-tooltip>
              </v-btn>

              <v-btn
                v-if="showActions"
                fab
                dark
                color="green darken-3"
                @click="onHandleSave"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      save
                    </v-icon>
                  </template>
                  Salvar
                </v-tooltip>
              </v-btn>
              <v-btn
                fab
                dark
                color="teal darken-1"
                @click="newQuestionnaire()"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      note_add
                    </v-icon>
                  </template>
                  Novo Questionário
                </v-tooltip>
              </v-btn>
            </v-speed-dial>
          </v-tab-item>
          <v-tab-item
            value="configuracao-tab"
          >
            <v-card
              color="transparent"
              dark
              class="px-8 py-0"
            >
              <settings-questionnaire
                ref="settings"
                :settings="settings"
                :view="view"
                :project="project"
                :question-list="questionList"
                @onSave="onHandleSave"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item
            v-if="(view == 'visits' || view == 'project')"
            value="pontuacao-tab"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-form>
                <v-row>
                  <v-col class="px-8 py-0">
                    <v-text-field
                      v-model="title"
                      prepend-inner-icon="drive_file_rename_outline"
                      outlined
                      filled
                      dark
                      class="rota-field"
                      background-color="rgba(0,0,0,.5)"
                      placeholder="Titulo"
                      :rules="[(v) => v.length > 0 || 'Campo obrigatorio']"
                      hide-details
                      disabled
                    />
                  </v-col>
                  <!-- Area de renderização das questões -->
                  <v-col
                    v-for="(component, index) in questionList"
                    :key="index"
                    class="pl-8 pr-8 pb-0 col-content"
                    md="12"
                    cols="12"
                  >
                    <component
                      :is="component.component"
                      v-model="component.values"
                      :questionnaire-points="true"
                      :questionnaire-disabled="questionnaireDisabled"
                      :ordem="component.ordem"
                      @onDeleteQuestion="openConfirmDialog"
                      @onDisableQuestionCondition="onDisableQuestionCondition"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
            <v-speed-dial
              fixed
              dark
              bottom
              right
              open-on-hover
              direction="top"
              transition="slide-y-reverse-transition"
              class="mr-5"
            >
              <template v-slot:activator>
                <v-btn
                  color="blue darken-2"
                  dark
                  large
                  fab
                >
                  <v-icon>menu</v-icon>
                </v-btn>
              </template>
              <v-btn
                fab
                dark
                color="green darken-3"
                @click="onHandleSave"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      save
                    </v-icon>
                  </template>
                  Salvar
                </v-tooltip>
              </v-btn>
            </v-speed-dial>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-container>

    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>

    <v-dialog
      v-model="confirmDialog.visible"
      width="350"
    >
      <v-card>
        <v-card-title class="pa-3">
          <template v-if="confirmDialog.type === 'QUESTIONNAIRE' ">
            <span class="subtitle-1"><b>Deseja realmente fechar o questionário</b> ? <br></span>
            <span class="caption">Todas as informações serão perdidas</span>
          </template>

          <template v-if="confirmDialog.type === 'QUESTION' ">
            <span class="subtitle-1">Deseja realmente <b>EXCLUIR</b> a questão <b>{{ confirmDialog.abount.ordem }}</b> ? <br></span>
          </template>

          <template v-if="confirmDialog.type === 'SUBTITLE' ">
            <span class="subtitle-1">Deseja realmente <b>EXCLUIR</b> o subtítulo <b>{{ confirmDialog.abount.ordem }}</b> ? <br></span>
          </template>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="pa-1"
            color="blue-grey darken-1"
            dark
            text
            @click="confirmDialog.visible = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="pa-1"
            color="red lighten-2"
            dark
            text
            @click="closeConfirmDialog(confirmDialog.abount)"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

</style>

<script>
import * as _ from "lodash";

import SelectTypeQuestionCard from "@/Domains/Questionnaires/Components/SelectTypeQuestionCard.vue";
import SubtitleCard from "@/Domains/Questionnaires/Components/SubtitleCard.vue";
import SettingsQuestionnaire from "@/Domains/Questionnaires/Components/SettingsQuestionnaire.vue";

import { v4 as uuidv4 } from "uuid";

export default {
  name: "createQuestionnaire",
  components: {
    SelectTypeQuestionCard,
    SubtitleCard,
    SettingsQuestionnaire,
  },
  props: {
    view: {
      type: String,
    },
    questionnaireId: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    objectType: {
      type: String,
      default: null,
    },
    project: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tabQuestionnaire: null,

      showDialog: false,

      title: "",
      // List da componentes renderizados
      questionList: [],

      // informações da ordem dos questionários
      ordem: {
        current: 0,
        subTitle: {
          ordemSubtitle: null, // ordem correspondendo a que o subtitulo foi adicionado (Ex:3)
          ordem: 0, // leva em conta a posição do subtitlo para forma a nova ordem (Ex:1)
          current: null, // ordem do ultima card adicionado (Ex: 3.1)
        },
        list: [],
      },

      // Flag para controlar exibição do btns de eventos de adição de questoes
      showActions: true,

      loading: false,

      confirmDialog: {
        visible: false,
        type: "QUESTIONNAIRE",
        abount: {},
      },

      // Validação dor formulário
      validation: false,

      // configuração do questionário
      settings: {},

    };
  },
  computed: {
    questionnaireDisabled: {
      get() {
        return this.disabled;
      },
    },
  },
  watch: {
    questionnaireId() {
      if (this.questionnaireId) {
        this.loadQuestionnaire();
      } else if (this.questionnaireId === '') { //se nao tiver id tem que limpar formulario
        this.createQuestionnaire();
      }
    },
  },
  mounted() {
    // Caso não tiver ID um cadastro novo
    if (this.questionnaireId) {
      this.loadQuestionnaire();
    } else { //se nao tiver id tem que limpar formulario
      this.createQuestionnaire();
    }

  },
  methods: {

    newQuestionnaire() {
      this.$emit('newQuestionnaire');
    },

    createQuestionnaire() {

      this.questionList = [];

      this.title = "";

      this.version = "";

      this.code = "";

      this.showActions = true;

      this.ordem = {
        current: 0,
        subTitle: {
          ordemSubtitle: null, // ordem correspondendo a que o subtitulo foi adicionado (Ex:3)
          ordem: 0, // leva em conta a posição do subtitlo para forma a nova ordem (Ex:1)
          current: null, // ordem do ultima card adicionado (Ex: 3.1)
        },
        list: [],
      };
      this.settings = {},

      this.addQuestionCard();

    },

    /**
     * Adicina novos componentes para serem renderizados ser redereizado
     */
    addQuestionCard(values = null ) {
      try {
        // Verifica se as informações foram passadas ou será uma questão nova
        if (!values) {
          values = {
            label: "",
            required: false,
            questionType: "OPEN",
            maxAmountSelect: 1,
            condition: {
              enable: false,
              parameter: null,
              rotulosExtra: [],
            },
            filled: false,
          }
        }

        const nextOrdem = this.nextOrdem();
        // adiciona novo componente
        this.questionList.push({
          component: SelectTypeQuestionCard,
          ordem: nextOrdem,
          values: values,
        });

      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Calcula a ordem da próxima questão
     * @returns {String}
     */
    nextOrdem() {
      // Verifica se existe um subtitulo para respeitar a ordem
      if (this.ordem.subTitle.ordemSubtitle) {
        // Soma um a ordem com base na posição da ulitma questão do subtitulo
        this.ordem.subTitle.ordem++;

        const ordem = `${this.ordem.subTitle.ordemSubtitle}.${this.ordem.subTitle.ordem}`;

        this.ordem.list.push(ordem);
        this.ordem.subTitle.current = ordem;

        return ordem;
      }

      this.ordem.current++;

      this.ordem.list.push(this.ordem.current);

      return this.ordem.current;
    },

    /**
     * Trata a deleção dos cards da questão
     */
    async handleDeleteCard(question) {

      // Para questão ordenadas a partir do subtitulo
      if (this.ordem.subTitle.ordemSubtitle) {
        // Caso for o ultimo da lista basta remover
        if (_.last(this.ordem.list) == question.ordem && this.ordem.subTitle.ordem !== 0) {
          await this.removeQuestionnaireComponente(question.ordem);
          this.ordem.subTitle.current = _.last(this.ordem.list);
          this.ordem.subTitle.ordem--;
          return true
        }

        // Esta removendo o subtitulo
        if (this.ordem.subTitle.current == question.ordem) {
          await this.removeQuestionnaireComponente(question.ordem);
          this.ordem.current--;
          this.ordem.subTitle = {
            ordemSubtitle: null,
            ordem: 0,
            current: null,
          };
          return true;
        }

        await this.removeQuestionnaireComponente(question.ordem);
        this.reorderComponents();
        return true;
      }

      // Se for o ultima da lista basta remover e subtrair 1 da flag de controle
      if (_.last(this.ordem.list) == question.ordem) {
        this.ordem.current--;
        await this.removeQuestionnaireComponente(question.ordem);
        return true;
      }

      await this.removeQuestionnaireComponente(question.ordem);
      await this.reorderComponents();
    },

    /**
     * Reordena a lista de componentes
     */
    reorderComponents() {
      this.questionList.map((component, index) => {
        if (index === 0) {
          component.ordem = 1;

          // é necessário resetar as flag de controle
          this.ordem = {
            current: 1,
            subTitle: {
              ordemSubtitle: null,
              ordem: 0,
              current: null,
            },
            list: [1],
          };

          return component;
        }

        const nextOrdem = this.nextOrdem();

        if (_.get(component, "values.questionType") === "SUBTITLE") {
          this.ordem.subTitle = {
            ordemSubtitle: nextOrdem,
            ordem: 0,
            current: null,
          };
        }

        // Seta a nova ordem
        component.ordem = nextOrdem;

        return component;
      });
    },

    /**
     * @param {string} ordem  posição do componenete que deve ser removido
     * Remove card de questionário
     */
    async removeQuestionnaireComponente(ordem) {
      this.questionList = this.questionList.filter((valeu) => valeu.ordem !== ordem);
      this.ordem.list = this.ordem.list.filter((valeu) => valeu != ordem);
    },

    /**
     * Salva questionários
     */
    async onSave() {
      try {
        this.loading = true;

        let questionsParse = [];

        // Faz parse das informações para salvar no banco
        _.forEach(this.questionList, ({ values }) => {
          // Informações base
          let baseQuestion = {
            rotulo: values.label,
            obrigatorio: values.required,
            tipo: this.parseType(values.questionType),
            id: uuidv4(),
            entrada: "CARACTER",
          };

          // Seta o tipo de entrada
          if (values.questionType === "NUMBER") {
            baseQuestion = {
              ...baseQuestion,
              entrada: "NUMERO",
            };
          }

          if (values.questionType === "DATE") {
            baseQuestion = {
              ...baseQuestion,
              entrada: "DATA",
            };
          }

          if (values.questionType === "DATETIME") {
            baseQuestion = {
              ...baseQuestion,
              entrada: "DATA_HORA",
            };
          }

          if (values.questionType === "TIME") {
            baseQuestion = {
              ...baseQuestion,
              entrada: "HORA",
            };
          }

          // verificar se é um checklist
          if (values.questionType === "CHECKBOX" && _.has(values, "options") && !_.isEmpty(values["options"]) ) {
            baseQuestion = {
              ...baseQuestion,
              valores: values.options,
              pontos: values.points,
            };
          }

          // Trata questoes de multipla escolha
          if (values.questionType === "MULTIPLE-SELECT") {
            baseQuestion = {
              ...baseQuestion,
              valores: values.options,
              pontos: values.points,
              maximo_valores: parseInt(values.maxAmountSelect),
            };
          }

          questionsParse.push(baseQuestion);

          // Verifica se esta questão possui orientação(condicão)
          if (_.has(values, "condition") &&  !_.isEmpty(values["condition"]) && values.condition.enable) {
            const condition  = {
              ...baseQuestion,
              rotulo: "Recomendação",
              obrigatorio: false,
              tipo: "TEXTO",
              entrada: "CARACTER",
              id: uuidv4(),
              valor_padrao: values.standardValue,
              condicional: {
                comparacao: values.condition.parameter,
                condicao: "==",
                id_campo_comparacao: baseQuestion.id,
                informar_prazo: values.condition.enableDeadline,
                inserir_foto: values.condition.enablePicture,
              },
            };

            if (baseQuestion.entrada === "NUMERO") {
              delete condition.condicional.comparacao;
              condition.condicional.condicao = "...";
              condition.condicional.min = parseFloat(values.condition.min);
              condition.condicional.max = parseFloat(values.condition.max);

              baseQuestion.faixa_padrao = {
                min: parseFloat(values.condition.min),
                max: parseFloat(values.condition.max),
              };
            }

            _.unset(condition, "valores");
            _.unset(condition, "pontos");

            questionsParse.push(condition);

            if (values.condition.enableDeadline) {
              const deadLineCondition  = {
                rotulo: `Previsão/Prazo: ${baseQuestion.rotulo}`,
                id: uuidv4(),
                tipo: "DATA",
                entrada: "CARACTER",
                obrigatorio: true,
                gerar_agendamento: true,
                condicional: {
                  comparacao: values.condition.parameter,
                  condicao: "==",
                  id_campo_comparacao: baseQuestion.id,
                },
              };

              questionsParse.push(deadLineCondition);
            }

            if (values.condition.enablePicture) {
              const pictureCondition  = {
                rotulo: `Foto: ${baseQuestion.rotulo}`,
                id: uuidv4(),
                tipo: "FOTO",
                entrada: "CARACTER",
                obrigatorio: true,
                condicional: {
                  comparacao: values.condition.parameter,
                  condicao: "==",
                  id_campo_comparacao: baseQuestion.id,
                },
              };

              questionsParse.push(pictureCondition);
            }

            if (values.condition.rotulosExtra) {
              _.forEach(values.condition.rotulosExtra, (e) => {
                const conditionExtra  = {
                  rotulo: e.name,
                  id: e.id || uuidv4(),
                  tipo: "TEXTO",
                  entrada: "CARACTER",
                  obrigatorio: e.required,
                  gerar_agendamento: false,
                  condicional: {
                    comparacao: values.condition.parameter,
                    condicao: "==",
                    id_campo_comparacao: baseQuestion.id,
                    tipo: "extra",
                  },
                };

                if (baseQuestion.entrada === "NUMERO") {
                  delete conditionExtra.condicional.comparacao;
                  conditionExtra.condicional.condicao = "...";
                  conditionExtra.condicional.min = parseFloat(values.condition.min);
                  conditionExtra.condicional.max = parseFloat(values.condition.max);
                }

                questionsParse.push(conditionExtra);
              });

            }
          }
        });

        let payload = {
          titulo: this.title,
          codigo: this.settings.code,
          versao: this.settings.version,
          formulario: questionsParse,
          tipo: this.settings.questionnaireTypes.map((t) => t.value),
          id_laticinios: this.settings.units.map((p) => p.id_pessoa),
          obrigatorio: this.settings.required,
          preenchido: this.settings.filled,
          mostra_ordem: this.settings.showOrder,
          destacar_rotulos: this.settings.questionnaireAnswer ? this.settings.questionnaireAnswer.map((t) => t.id) : '',
          mostra_nome_empresa: this.settings.showName,
          mostra_cnpj_empresa: this.settings.showCnpj,
        };

        if ( !_.isEmpty(this.settings.ranges) ) {
          payload = {
            ...payload,
            classificacao_checklist: this.settings.ranges.map((r) => ({ id_faixa: r.id_faixa, nome: r.nome_faixa })),
          };
        }

        if (this.view === 'others') {
          payload.objeto = this.settings.object;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/salvarQuestionario`,
          this.$qs.stringify({
            questionario: JSON.stringify(payload),
          })
        );

        if (!_.isObject(data)) {
          throw `PHP error ${data}`;
        }

        if (_.get(data, "codigo") === 0) {
          throw _.get(data, "mensagem");
        }

        this.questionnaireId = _.get(data, "id") ;
        this.loadQuestionnaire();

        this.$snotify.success("Questionário salvo com Sucesso !", "Sucesso");

      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao atualizar questionário!", "Atenção");

      } finally {
        this.showDialog = false;
        this.loading = false;
      }
    },

    /**
     * Faz o parte do tipos do banco
     */
    parseType(type) {
      switch (type) {
        case "OPEN":
          return "TEXTO";
        case "NUMBER":
          return "TEXTO";
        case "CHECKBOX":
          return "CHECKBOX";
        case "MULTIPLE-SELECT":
          return "CHECKBOX";
        case "PHOTOGRAPH":
          return "FOTO";
        case "SUBTITLE":
          return "TITULO";
        case "CONDITION":
          return "TEXTO";
        case "DATE":
          return "DATA";
        case "DATETIME":
          return "DATA_HORA";
        case "TIME":
          return "HORA";
        default:
          return "TEXTO";
      }
    },
    /**
     * Adiciona um card de subTitle
     */
    addSubTitle(values = null) {
      try {

        // Caso já existem um subtitle eu preciso zerar as flag para inicar um nova ordem com o novo titulo
        if (this.ordem.subTitle.ordemSubtitle) {
          // Ajusta as flags que constrolam o sub titulo
          this.ordem.subTitle = {
            ordemSubtitle: null,
            current: null,
            ordem: 0,
          };
        }

        if (!values) {
          values = { label: "", questionType: "SUBTITLE" };
        }

        const nextOrdem = this.nextOrdem();

        // Set a flag que controla a ordem do subtitulos
        this.ordem.subTitle.current = nextOrdem;

        // adiciona novo componente
        this.questionList.push({
          component: SubtitleCard,
          ordem: nextOrdem,
          values: values,
        });

        // Zera a flag de controle das sub questões, que devem respeitar a ordem do novo subtitulo
        this.ordem.subTitle.ordem = 0;

        // inicia a flag com a posição do subtitle
        this.ordem.subTitle.ordemSubtitle = nextOrdem;

      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Trata evento de remoção de condição
     * @param {object} event
     */
    onDisableQuestionCondition(event) {
      this.removeQuestionCondition(event.ordem);
    },

    /**
     * Abre dialog de confirmação
     */
    openConfirmDialog(event) {

      if (_.includes(["QUESTION", "SUBTITLE"], _.get(event, 'type'))) {
        this.confirmDialog.visible = true;
        this.confirmDialog.type = _.get(event, 'type'),
        this.confirmDialog.abount = event;
        return true;
      }

      this.confirmDialog.visible = true;
      this.confirmDialog.type = 'QUESTIONNAIRE';

    },

    /**
     * Fecha Dialogs de confirmação
     */
    closeConfirmDialog(data) {

      if (_.includes(["QUESTION", "SUBTITLE"], this.confirmDialog.type)) {
        this.handleDeleteCard(data);
        this.confirmDialog.visible = false;
        this.confirmDialog.abount = {};
      }

      if (this.confirmDialog.type === "QUESTIONNAIRE") {
        this.confirmDialog.visible = false;
        this.showDialog = false;
      }
    },

    /**
     * Valida os campos do formulário
     */
    validate() {
      return this.$refs.formContent && this.$refs.formContent.validate();
    },

    resetValidation() {
      this.$refs.formContent.resetValidation();
    },

    /**
     * Carrega indormações do questionário para edição
     */
    async loadQuestionnaire() {
      try {

        if (this.questionnaireDisabled) {
          this.showActions = false;
        }

        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioId`,
          this.$qs.stringify({
            id_formulario: this.questionnaireId
          })
        );

        if (!_.isObject(data)) {
          throw "PHP erro"
        }

        const questionnaire = _.head(data);

        this.title = _.get(questionnaire, "titulo");

        this.ordem.current = 0;
        this.ordem.list = [];
        this.questionList = [];

        this.settings = {
          units: _.get(questionnaire, "id_laticinios") || [],
          ranges: _.get(questionnaire, "classificacao_checklist") || [],
          questionnaireTypes: _.get(questionnaire, "tipo") || [],
          object: _.get(questionnaire, "objeto") || [],
          required: _.get(questionnaire, "obrigatorio"),
          filled: _.get(questionnaire, "preenchido"),
          version: _.get(questionnaire, "versao"),
          code: _.get(questionnaire, "codigo"),
          showOrder: _.get(questionnaire, "mostra_ordem"),
          questionnaireAnswer: _.get(questionnaire, "destacar_rotulos") || [],
          showName: _.get(questionnaire, "mostra_nome_empresa"),
          showCnpj: _.get(questionnaire, "mostra_cnpj_empresa"),
        }

        this.settings.units = this.settings.units.map(unit => {return { nome: "", id_pessoa: unit }});
        this.settings.questionnaireTypes = this.settings.questionnaireTypes.map(type => {return { text: "", value: type }});
        this.settings.ranges = this.settings.ranges.map(r => {return { id_faixa: r.id_faixa, nome_faixa: r.nome }});
        this.settings.questionnaireAnswer = this.settings.questionnaireAnswer.map(question => {return { text: "", id: question }});

        // Parse para variáveis local
        let conditionExtra = [];
        _.forEach(questionnaire.formulario, (question) => {

          // campos base
          let values = {
            id: question.id,
            questionType: "OPEN",
            label: question.rotulo,
            required: question.obrigatorio,
          };

          // Subtitulo
          if (question.tipo === "TITULO") {
            values = {
              ...values,
              questionType: "SUBTITLE",
            };

            this.addSubTitle(values);

            return true;
          }

          /**
           * Se tiver o campo condicional, possui uma questão pai
           * É adicionado na questão pai o id da questão filho no campo [condition.id] ou [condition.deadlineId] ou [condition.pictureId]
           */

          if (!_.isEmpty(question["condicional"])) {

            const parentQuestion = this.questionList.find(data => data.values.id === question.condicional.id_campo_comparacao);

            if (!parentQuestion) {
              return true;
            }

            const { condition = {} } = parentQuestion.values;
            if (question.tipo === 'DATA') {
              parentQuestion.values.condition = {
                ...condition,
                deadlineId: question.id,
              };
            }
            else if (question.tipo === 'FOTO') {
              parentQuestion.values.condition = {
                ...condition,
                pictureId: question.id,
              };
            }
            //condicionais extras
            else if (question.tipo === 'TEXTO' && question.condicional.tipo === 'extra') {
              conditionExtra.push({ name: question.rotulo, id: question.id_rotulo, required: question.obrigatorio });

              parentQuestion.values.condition = {
                ...condition,
                rotulosExtra: conditionExtra,
              };
            }
            else {
              parentQuestion.values = {
                ...parentQuestion.values,
                standardValue: question.valor_padrao,
                condition: {
                  ...condition,
                  id: question.id,
                  enable: true,
                  parameter: question.condicional.comparacao,
                  min: question.condicional.min,
                  max: question.condicional.max,
                  enableDeadline: question.condicional.informar_prazo,
                  enablePicture: question.condicional.inserir_foto,
                  rotulosExtra: [],
                }
              };
            }

            return true;
          } else {
            conditionExtra = [];
          }

          if (question.tipo === "CHECKBOX" && _.isEmpty(question["maximo_valores"])) {

            values = {
              ...values,
              options: question.valores,
              points: question.pontos || [],
              questionType: "CHECKBOX",
            };
          }

          // Questões de multiplas escolha
          if (question.tipo === "CHECKBOX" && question["maximo_valores"] > 0) {
            values = {
              ...values,
              options: question.valores,
              points: question.pontos || [],
              questionType: "MULTIPLE-SELECT",
              maxAmountSelect: question.maximo_valores
            };
          }

          // Questões de multiplas escolha
          if (question.tipo === "FOTO") {
            values = {
              ...values,
              questionType: "PHOTOGRAPH",
            };
          }

          // Questões de multiplas escolha
          if (question.tipo === "TEXTO" && _.get(question, 'entrada') === "NUMERO") {
            values = {
              ...values,
              questionType: "NUMBER",
            };
          }

          if (question.tipo === "DATA") {
            values = {
              ...values,
              questionType: "DATE",
            };
          }

          if (question.tipo === "DATA_HORA") {
            values = {
              ...values,
              questionType: "DATETIME",
            };
          }

          if (question.tipo === "HORA") {
            values = {
              ...values,
              questionType: "TIME",
            };
          }

          this.addQuestionCard(values);

        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    /**
     *  Trata a persistência da informações
     */
    async onHandleSave(event) {
      if (!(await this.validate())) {
        this.$snotify.info("Preencha todos os campos obrigatórios!");
        this.tabQuestionnaire = 'cadastro-tab';
        return true;
      }

      // Caso exista Id é uma edição
      if (this.questionnaireId) {
        await this.update(event);
        return true;
      }
      if (_.isEmpty(this.settings)) {
        this.tabQuestionnaire = 'configuracao-tab';
      } else {
        await this.onSave(event);
      }
    },

    async update() {
      try {
        this.loading = true;

        let questionsParse = [];

        // Faz parse das informações para salvar no banco
        _.forEach(this.questionList, ({ values }) => {

          if (_.isEmpty(values.id)) values.id =  uuidv4(); //validacao pois algumas questoes na edicao estavam ficando sem id
          // Informações base
          let baseQuestion = {
            rotulo: values.label,
            obrigatorio: values.required,
            tipo: this.parseType(values.questionType),
            id: !_.isEmpty(values.id) ? values.id : uuidv4(),
            entrada: "CARACTER",
          };

          // Seta o tipo de entrada
          if (values.questionType === "NUMBER") {
            baseQuestion = {
              ...baseQuestion,
              entrada: "NUMERO",
            };
          }

          // verificar se é um checklist
          if (values.questionType === "CHECKBOX" && !_.isEmpty(values["options"]) && _.has(values, "options")) {
            baseQuestion = {
              ...baseQuestion,
              valores: values.options,
              pontos: values.points,
            };
          }

          // Trata questoes de multipla escolha
          if (values.questionType === "MULTIPLE-SELECT") {
            baseQuestion = {
              ...baseQuestion,
              valores: values.options,
              pontos: values.points,
              maximo_valores: parseInt(values.maxAmountSelect),
            };
          }

          questionsParse.push(baseQuestion);

          // Verifica se esta questão possui orientação(condicão)
          if (_.has(values, "condition") && !_.isEmpty(values["condition"]) && values.condition.enable) {

            const condition  = {
              ...baseQuestion,
              rotulo: "Recomendação",
              id: values.condition.id ? values.condition.id : uuidv4(),
              tipo: "TEXTO",
              entrada: "CARACTER",
              valor_padrao: values.standardValue,
              condicional: {
                comparacao: values.condition.parameter,
                condicao: "==",
                id_campo_comparacao: values.id,
                informar_prazo: values.condition.enableDeadline,
                inserir_foto: values.condition.enablePicture,
              },
            };

            if (baseQuestion.entrada === "NUMERO") {
              delete condition.condicional.comparacao;
              condition.condicional.condicao = "...";
              condition.condicional.min = parseFloat(values.condition.min);
              condition.condicional.max = parseFloat(values.condition.max);

              baseQuestion.faixa_padrao = {
                min: parseFloat(values.condition.min),
                max: parseFloat(values.condition.max),
              };
            }

            _.unset(condition, "valores");
            _.unset(condition, "pontos");

            questionsParse.push(condition);

            if (values.condition.enableDeadline) {
              const deadLineCondition  = {
                rotulo: `Previsão/Prazo: ${baseQuestion.rotulo}`,
                id: values.condition.deadlineId || uuidv4(),
                tipo: "DATA",
                entrada: "CARACTER",
                obrigatorio: true,
                gerar_agendamento: true,
                condicional: {
                  comparacao: values.condition.parameter,
                  condicao: "==",
                  id_campo_comparacao: values.id,
                },
              };

              questionsParse.push(deadLineCondition);
            }

            if (values.condition.enablePicture) {
              const pictureCondition  = {
                rotulo: `Foto: ${baseQuestion.rotulo}`,
                id: values.condition.pictureId || uuidv4(),
                tipo: "FOTO",
                entrada: "CARACTER",
                obrigatorio: true,
                condicional: {
                  comparacao: values.condition.parameter,
                  condicao: "==",
                  id_campo_comparacao: values.id,
                },
              };

              questionsParse.push(pictureCondition);
            }

            if (values.condition.rotulosExtra) {
              _.forEach(values.condition.rotulosExtra, (e) => {
                const conditionExtra  = {
                  rotulo: e.name,
                  id: e.id || uuidv4(),
                  tipo: "TEXTO",
                  entrada: "CARACTER",
                  obrigatorio: e.required,
                  gerar_agendamento: false,
                  condicional: {
                    comparacao: values.condition.parameter,
                    condicao: "==",
                    id_campo_comparacao: values.id,
                    tipo: "extra",
                  },
                };

                if (baseQuestion.entrada === "NUMERO") {
                  delete conditionExtra.condicional.comparacao;
                  conditionExtra.condicional.condicao = "...";
                  conditionExtra.condicional.min = parseFloat(values.condition.min);
                  conditionExtra.condicional.max = parseFloat(values.condition.max);
                }

                questionsParse.push(conditionExtra);
              });

            }
          }
        });

        let payload = {
          titulo: this.title,
          versao: this.settings.version,
          codigo: this.settings.code,
          formulario: questionsParse,
          tipo: this.settings.questionnaireTypes.map((t) => t.value),
          id_laticinios: this.settings.units.map((p) => p.id_pessoa),
          obrigatorio: this.settings.required,
          preenchido: this.settings.filled,
          mostra_ordem: this.settings.showOrder,
          destacar_rotulos: this.settings.questionnaireAnswer.map((t) => t.id),
          mostra_nome_empresa: this.settings.showName,
          mostra_cnpj_empresa: this.settings.showCnpj,
        };

        if ( !_.isEmpty(this.settings.ranges) ) {
          payload = {
            ...payload,
            classificacao_checklist: this.settings.ranges.map((r) => ({ id_faixa: r.id_faixa, nome: r.nome_faixa })),
          };
        }

        if (this.view === 'others') {
          payload.objeto = this.settings.object;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/updateFormularioCustomizado`,
          {
            questionario: payload,
            id_formulario: this.questionnaireId,
          }
        );

        if (!_.isObject(data)) {
          throw `PHP error ${data}`;
        }

        if (_.get(data, "codigo") === 0) {
          throw _.get(data, "mensagem");
        }

        this.$snotify.success("Questionário atualizado com Sucesso !", "Sucesso");

        this.showDialog = false;
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao atualizar questionário!", "Atenção");
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
