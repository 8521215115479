<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          BPA
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
        @change="onTabChange"
      >
        <v-tab href="#classification">
          Curva ABC
        </v-tab>
        <v-tab href="#chart">
          Gráficos
        </v-tab>
        <v-tab href="#report">
          Relatórios
        </v-tab>
        <v-tab href="#history">
          Configuração
        </v-tab>
        <v-tab
          href="#planning"
          :disabled="!planning.selected"
        >
          <v-icon>settings</v-icon>
        </v-tab>

        <v-tab-item value="classification">
          <producer-rating
            ref="classification"
            view="goal"
          />
        </v-tab-item>

        <v-tab-item value="chart">
          <chart-tab
            ref="chart"
          />
        </v-tab-item>

        <v-tab-item value="report">
          <report-tab
            ref="report"
          />
        </v-tab-item>

        <v-tab-item value="history">
          <list-goals
            ref="hh"
            project="PQFL"
            @newPlanning="onNewPlanning"
            @selectPlanning="onSelectPlanning"
          />
        </v-tab-item>

        <v-tab-item value="planning">
          <goals
            ref="planning"
            v-model="planning.id"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import ListGoals from "@/Domains/Projects/ListGoals.vue";
import Goals from "@/Domains/Projects/PQFL/BPA/Views/Goals.vue";
import ChartTab from "@/Domains/Projects/PQFL/BPA/Components/ChartTab.vue";
import ProducerRating from "@/Domains/Projects/PQFL/BPA/Components/ProducerRating.vue";
import reportTab from "@/Domains/Projects/PQFL/BPA/Components/ReportTotalsByQuestion.vue";

export default {
  components: {
    ListGoals,
    Goals,
    ChartTab,
    ProducerRating,
    reportTab,
  },

  props: ["parent"],

  data() {
    return {
      tab: null,

      planning: {
        selected: false,
        id: null
      }
    };
  },

  methods: {
    /**
     * @event void
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange() {
      if (this.tab == 'history' && this.$refs.hh) {
        this.$refs.hh.loadHistory();
      }
      this.planning.selected = false;
      this.planning.id = null;

      if (this.tab == 'chart' && this.$refs.chart) {
        this.$refs.chart.loadBpa()
      }
      if (this.tab == 'classification' && this.$refs.classification) {
        this.$refs.classification.loadProject()
      }

    },

    onNewPlanning() {
      this.planning.selected = true;
      this.planning.id = null;
      this.tab = 'planning';
    },

    onSelectPlanning(id) {
      this.planning.selected = true;
      this.planning.id = id;
      this.tab = 'planning';
    },

    navigateBack() {
      this.$store.commit("setCurrentMenu", this.parent.name);
      this.$store.commit("setCurrentMenuBackground", this.parent.background);
    },
  },
};
</script>
