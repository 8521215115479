<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="9"
          offset-md="2"
        >
          <v-text-field
            v-model="obs"
            label="Descrição"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="9"
          offset-md="2"
        >
          <v-autocomplete
            v-model="dairySelected"
            outlined
            chips
            small-chips
            dark
            filled
            multiple
            hide-details
            label="Unidades "
            :items="ListDairy"
            item-text="nome"
            item-value="id_pessoa"
          >
            <template #selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                color="primary"
                @click="data.select"
                @click:close="removeChipDairy(data.item)"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>
                {{ data.item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-flex>
            <v-tabs
              v-model="tab"
              vertical
              dark
              background-color="transparent"
              class="transparent"
            >
              <v-tab href="#aba-qualidade">
                Qualidade
              </v-tab>
              <v-tab href="#aba-produtor">
                Visitas
              </v-tab>
              <v-tab href="#aba-tecnico">
                Tecnicos
              </v-tab>
              <v-tab-item value="aba-qualidade">
                <quality-actions
                  v-model="qualityGoals"
                />
              </v-tab-item>
              <v-tab-item value="aba-produtor">
                <visits-actions
                  :goal="visitsGoals"
                  :emergency="visitsEmergency"
                />
              </v-tab-item>
              <v-tab-item value="aba-tecnico">
                <tecnical-actions
                  :goal="tecnicalGoals"
                />
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-col>
      </v-row>
    </v-form>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click.stop="save"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar configuração
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay v-model="loading">
      <v-card-text>
        Carregando
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import QualityActions from "@/Domains/Projects/Dairy/QualityGoals/Components/QualityActions.vue";
import VisitsActions from "@/Domains/Projects/Dairy/QualityGoals/Components/VisitsActions.vue";
import TecnicalActions from "@/Domains/Projects/Dairy/QualityGoals/Components/TecnicalActions.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    QualityActions,
    VisitsActions,
    TecnicalActions,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },

  props: {
    value: String
  },

  data() {
    return {
      id: null,

      loading: false,

      tab: "aba-qualidade",

      qualityGoals: [],

      visitsGoals: [],

      visitsEmergency: [],

      tecnicalGoals: [],

      saving: false,

      obs: '',

      ListDairy: [],

      dairySelected: [],

      questionnairesGoals: [],

    };
  },

  watch: {
    value() {
      this.id = this.value;
      this.load();
    }
  },
  created() {
    this.id = this.value;
    this.load();
    this.getDairies();
  },

  methods: {
    async getDairies() {
      if (this.ListDairy.length > 0) {
        return this.ListDairy;
      }
      const { data } = await this.$axios.post(`/pessoa/listaLaticinioJson`);
      this.ListDairy = data;
    },

    removeChipDairy(lat) {
      this.dairySelected = this.dairySelected.filter((dairyId) => dairyId !== lat.id_pessoa);
    },

    async load() {
      if (this.id) {
        this.loadPlanning();
      }
      else {
        this.setState();
      }
    },

    async loadPlanning() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/recomendacoesQualidade/detalhe`, { id: this.id });

        if (!_.isObject(data)) {
          throw data;
        }

        this.setState(data);

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas !", "Atenção");
        console.warn(err)
      } finally {
        this.loading = false;
      }
    },

    setState(data = null) {
      /**
       * Metas de qualidade
       */
      this.qualityGoals = [];
      this.visitsGoals = [];
      this.visitsEmergency = [];
      this.tecnicalGoals = [];

      if (!_.isEmpty(data)) {
        //meta da aba de questionario - traz somente para nao sobreescrever com null na hora de salvar
        this.questionnairesGoals = !_.isEmpty(data.questionario) ? data.questionario : [];

        if ( !_.isEmpty(data.qualidade)) {

          this.qualityGoals = data.qualidade.map(goal => ({
            indicator: goal.indicador,
            action: goal.acao,
            percent: goal.percentual,
            media: goal.media,
            type: !_.isEmpty(goal.tipo) ? goal.tipo : 'PERCENT',
            dateRange: goal.periodo,
            questionnaires: goal.questionarios,
            //time_init: goal.dias_entrada,
          }));

        }

        this.obs = data.descricao;
        this.dairySelected = data.laticinios;

        if (!_.isEmpty(data.visita) ) {
          this.visitsGoals  = data.visita
            .filter(goal => goal.emergencial != true)
            .map(goal => ({
              id: goal.id_pessoa,
              text: goal.nome,
              dateStart: goal.mes_inicio,
              value: goal.tipo_visita,
              visits: goal.visitas,
              type: !_.isEmpty(goal.tipo) ? goal.tipo : 'PERCENT',
              period: goal.periodo,
              questionnaires: goal.questionarios,
            }));

          this.visitsEmergency  = data.visita
            .filter(goal => goal.emergencial == true)
            .map(goal => ({
              id: goal.id_pessoa,
              text: goal.nome,
              value: goal.tipo_visita,
              dateStart: goal.mes_inicio,
              periodTimeDays: goal.prazoDT,
              periodQuantity: goal.prazoQuantidade,
              questionnaires: goal.questionarios,
              recurrence: goal.recorrencia,
              period: goal.periodo,
            }));
        }

        if ( !_.isEmpty(data.tecnico)) {

          this.tecnicalGoals = data.tecnico.map(goal => ({
            id: goal.id_pessoa,
            name: goal.nome,
            visits: goal.visitas,
            visitTypes: goal.tipo_visita,
            type: !_.isEmpty(goal.tipo) ? goal.tipo : 'PERCENT',
            period: goal.periodo,
          }));
        }

      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        this.$snotify.warning("Campos inválidos", "Atenção");
        return false;
      }

      try {
        this.loading = true;
        this.saving = true;

        const quality = this.qualityGoals.map(goal => ({
          indicador: goal.indicator,
          acao: goal.action,
          percentual: goal.percent,
          tipo: goal.type,
          media: goal.media,
          periodo: goal.dateRange,
          questionarios: goal.questionnaires,
          //dias_entrada: goal.time_init,
        }));

        const tecnical = this.tecnicalGoals.map(goal => ({
          id_pessoa: goal.id,
          nome: goal.name,
          visitas: goal.visits,
          tipo: goal.type,
          tipo_visita: goal.visitTypes,
          periodo: goal.period,
        }));

        const visits = this.visitsGoals.map(goal => ({
          id_pessoa: goal.id,
          nome: goal.text,
          mes_inicio: goal.dateStart,
          tipo_visita: goal.value,
          visitas: goal.visits,
          tipo: goal.type,
          periodo: goal.period,
          questionarios: goal.questionnaires,
          emergencial: '',
        }));

        const emergency = this.visitsEmergency.map(goal => ({
          id_pessoa: goal.id,
          nome: goal.text,
          tipo_visita: goal.value,
          mes_inicio: goal.dateStart,
          prazoDT: goal.periodTimeDays,
          prazoQuantidade: goal.periodQuantity,
          questionarios: goal.questionnaires,
          periodo: goal.period,
          recorrencia: goal.recurrence,
          emergencial: true,
        }));

        const payload = {
          id_assistencia: this.id,
          qualidade: quality,
          descricao: this.obs,
          tipo: "Dairy",
          questionario: this.questionnairesGoals,
          visita: _.concat(visits, emergency),
          tecnico: tecnical,
          laticinios: this.dairySelected,
        };

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/salvaMetas`,
          payload
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.id = data.id;
        this.$emit('input', data.id);
        this.loadPlanning();
        this.$snotify.success("Planejamento salvo com sucesso", "Sucesso");

      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao salvar planejamento!", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
