<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Descontos Produtor (Litros)
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          dark
          hide-details
          @change="getInstallments"
        />
      </v-col>
      <v-spacer />
      <v-col md="4">
        <v-text-field
          v-model="filter.search"
          label="Pesquisar"
          prepend-inner-icon="search"
          dark
          filled
          hide-details
          clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            Descontos
            <v-spacer />
            <div class="d-flex">
              <data-info
                title="Total Volume"
                icon="icon-coleta_principal"
                :value="`${ formatNumber(totalAmount) } L`"
              />
              <data-info
                title="Total Saldo"
                icon="icon-coleta_principal"
                :value="`${ formatNumber(totalBalance) } L`"
              />
            </div>
            <v-col
              cols="12"
              class="body-2"
            >
              Os descontos lançados aqui serão descontados no fechamento do produtor, bem como no seu extrato.
              As parcelas serão vinculadas no respectivo mês de fechamento do produtor.
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :loading="loading"
            :sort-by.sync="filter.sort.by"
            :sort-desc.sync="filter.sort.desc"
            dark
            dense
            class="elevation-1"
            @click:row="showDiscount"
          >
            <template #[`item.issueDate`]="{ value }">
              {{ dateFormat(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.dueDate`]="{ value }">
              {{ dateFormat(value, 'MM/YYYY') }}
            </template>
            <template #[`item.amount`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.balance`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.button`]="{ item }">
              <v-btn
                v-if="item.balance == 0"
                block
                small
                color="orange"
                @click.stop="chargeback(item)"
              >
                Estornar
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <discount-dialog
      v-model="discountDialog.show"
      :discount-id="discountDialog.id"
      @input="onDiscountChange"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="purple"
        @click="newDiscount"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              icon-contas_pagar
            </v-icon>
          </template>
          Novo Desconto
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import DiscountDialog from '@/Domains/Financial/MilkDiscounts/Components/DiscountDialog.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import DataInfo from '@/Domains/Financial/Components/DataInfo.vue';
import sumBy from "lodash/fp/sumBy";
import isArray from "lodash/fp/isArray";

import moment from "moment-timezone";

moment.locale('pt');

export default {
  name: "producer-discounts",

  components: {
    VDateRangePicker,
    DiscountDialog,
    DataInfo,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        sort: { by: 'issueDate', desc: false },
        search: ''
      },

      headers: [
        { text: '#', value: 'code' },
        { text: 'Produtor', value: 'producer' },
        { text: 'Descrição', value: 'description' },
        { text: 'Emissão', value: 'issueDate' },
        { text: 'Vencimento', value: 'dueDate' },
        { text: 'Volume (L)', value: 'amount' },
        { text: 'Saldo (L)', value: 'balance' },
        { text: '', value: 'button', width: 100 },
      ],
      items: [],

      discountDialog: {
        show: false,
        id: null,
      },
    };
  },

  computed: {
    filteredItems() {
      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;
      if (!search) {
        return this.items;
      }
      return this.items.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(search));
    },
    totalAmount() {
      return sumBy(x => parseFloat(x.amount) || 0, this.filteredItems);
    },
    totalBalance() {
      return sumBy(x => parseFloat(x.balance) || 0, this.filteredItems);
    },
  },

  methods: {
    async getInstallments() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filter.range;

        const { data } = await this.$axios.post(
          `/descontosProdutor/listaParcelas`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.items = data.map(p => ({
          installmentId: p.id,
          accountId: p.id_desconto,
          code: p.codigo,
          producer: `${p.codigo_laticinio ? p.codigo_laticinio + ' -' : ''} ${p.nome_produtor}`,
          description: p.descricao,
          issueDate: p.data_emissao,
          dueDate: p.data_vencimento,
          amount: p.volume,
          balance: p.saldo,
        }))
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    newDiscount() {
      this.discountDialog.id = null;
      this.discountDialog.show = true;
    },

    showDiscount({ accountId }) {
      this.discountDialog.id = accountId;
      this.discountDialog.show = true;
    },

    onDiscountChange() {
      this.discountDialog.id = null;
      this.getInstallments();
    },

    async chargeback({ installmentId }) {
      if (!(await this.$root.$confirm('Estornar Parcela', 'Tem certeza que deseja estornar esta parcela?', { color: 'orange' }))) {
        return;
      }
      try {
        const { data } = await this.$axios.post(`/descontosProdutor/estorno`, { id: installmentId });

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Estorno efetuado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao estornar a parcela", "Atenção");
        console.warn(error);
      } finally {
        this.getInstallments();
      }
    },

    getReportJson() {
      return this.items.map(item => ({
        '#': item.code,
        'Produtor': item.producer,
        'Descrição': item.description,
        'Emissão': this.dateFormat(item.issueDate, 'DD/MM/YYYY'),
        'Vencimento': this.dateFormat(item.dueDate, 'MM/YYYY'),
        'Volume (L)': this.formatNumber(item.amount),
        'Saldo (L)': this.formatNumber(item.balance),
      }));
    },

    getReportTitle() {
      const [startDate, endDate] = this.filter.range;
      return `Descontos Produtor (Litros) - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      this.exportToFile({ report: this.getReportJson(), title: this.getReportTitle() });
    },

    print() {
      return this.printFromJson(this.getReportJson(), this.getReportTitle());
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
