<template>
  <MilkAverageBaseChart
    title="Média Volume 4 x Mês Anterior"
    :color="color"
    :height="height"
    :current-period="currentPeriod"
    :previous-period="previousPeriod"
  />
</template>

<script>
import MilkAverageBaseChart from './MilkAverageBaseChart.vue';

import moment from 'moment';

export default {
  components: { MilkAverageBaseChart },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '250px',
    },
  },

  data() {
    return {
      currentPeriod: [
        moment().subtract(5, 'days').format('YYYY-MM-DD'),
        moment().subtract(1, 'days').format('YYYY-MM-DD'),
      ],
      previousPeriod: [
        moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    }
  },
}
</script>
