<template>
  <v-dialog
    v-model="show"
    width="700"
    persistent
  >
    <v-card class="discount-dialog">
      <v-card-title>
        Desconto
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-stepper
        v-model="step"
        alt-labels
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="step > 1"
            :color="color"
          >
            Dados do desconto
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="2"
            :complete="step > 2"
            :color="color"
          >
            Parcelas
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="form-1"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <person-autocomplete-filter
                    v-model="form.id_produtor"
                    label="Produtor *"
                    type="PRODUCER"
                    persistent-hint
                    :disabled="hasDischarge"
                    :return-object="false"
                    :hide-details="false"
                    :filled="false"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model="form.descricao"
                    label="Descrição"
                    prepend-inner-icon="description"
                    persistent-hint
                    :disabled="hasDischarge"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="form.numero"
                    label="Número"
                    prepend-inner-icon="receipt"
                    persistent-hint
                    :disabled="hasDischarge"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <date-picker
                    v-model="form.data_emissao"
                    label="Data de emissão *"
                    prepend-inner-icon="event"
                    persistent-hint
                    readonly
                    :disabled="hasDischarge || !!form.id "
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <money-input
                    v-model="form.volume"
                    label="Volume *"
                    prefix="(L)"
                    persistent-hint
                    :disabled="hasDischarge"
                    :rules="[() => !!form.volume && form.volume > 0 || 'Campo obrigatório!']"
                    @input="onAmountChange"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form
              ref="form-2"
              lazy-validation
            >
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.parcela_quantidade"
                    label="Parcelas *"
                    prepend-inner-icon="iso"
                    persistent-hint
                    :disabled="hasDischarge"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    @keypress="disableDotAndComma"
                    @input="onNoInstallments"
                  />
                </v-col>
                <v-col>
                  <v-menu
                    ref="menu"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        :value="dateFormat(form.data_vencimento, 'MM/YYYY')"
                        label="Vencimento *"
                        prepend-inner-icon="event"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        readonly
                        :disabled="hasDischarge"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="form.data_vencimento"
                      type="month"
                      no-title
                      scrollable
                      @change="onNoInstallments"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-divider class="mb-2" />
              <v-row
                v-for="(item, idx) of form.parcelas"
                :key="idx"
              >
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-text-field
                    :value="idx + 1"
                    label="Parcela"
                    prefix="#"
                    readonly
                    persistent-hint
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <money-input
                    v-model="item.volume"
                    label="Volume *"
                    prefix="(L)"
                    persistent-hint
                    :disabled="idx == (form.parcelas.length - 1) || hasDischarge"
                    :rules="idx != (form.parcelas.length - 1) ? getRules(idx) : []"
                    @input="onChangeAmount($event, idx)"
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="py-0"
                >
                  <v-menu
                    ref="menu"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        :value="dateFormat(item.data_vencimento, 'MM/YYYY')"
                        label="Vencimento *"
                        prepend-inner-icon="event"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        readonly
                        :disabled="hasDischarge"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="item.data_vencimento"
                      type="month"
                      no-title
                      scrollable
                      @change="onChangeDateParcel($event, idx)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card-actions>
        <v-btn
          v-if="step == 1"
          color="grey darken-1"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-else
          outlined
          @click="backStep()"
        >
          <v-icon
            left
            dark
          >
            chevron_left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <template v-if="step == 1">
          <v-tooltip
            top
            :disabled="!hasDischarge"
            :activator="$refs.deleteBtn"
          >
            Estorne os recebimentos para poder excluir
          </v-tooltip>
          <v-btn
            v-if="form.id"
            ref="deleteBtn"
            :color="!hasDischarge ? 'red darken-1' : 'grey'"
            outlined
            @click="remove()"
          >
            Excluir
          </v-btn>
        </template>
        <v-btn
          v-if="step == 1"
          color="green"
          outlined
          @click="nextStep()"
        >
          Continuar
          <v-icon
            right
            dark
          >
            chevron_right
          </v-icon>
        </v-btn>
        <template v-else-if="step == 2">
          <v-btn
            v-if="form.id && hasChanges"
            color="orange darken-1"
            outlined
            @click="loadDiscount(), step = 1"
          >
            Descartar alterações
          </v-btn>
          <v-btn
            v-if="hasChanges"
            color="blue darken-1"
            outlined
            @click="save()"
          >
            Salvar e Fechar
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            outlined
            @click="close()"
          >
            Fechar
          </v-btn>
        </template>
      </v-card-actions>

      <v-overlay
        :value="loading || saving"
        absolute
      >
        <v-card-text>
          {{ loading ? 'Carregando...' : 'Salvando...' }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.discount-dialog {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: unset;
  }
  .v-stepper__content {
    max-height: calc(100vh - 350px);
    overflow: auto;
  }
}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import DatePicker from "@/Domains/Visits/Schedule/Components/DatePicker.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    PersonAutocompleteFilter,
    DatePicker,
    MoneyInput,
  },

  props: {
    value: Boolean,
    discountId: String,
  },

  data() {
    return {
      loading: false,
      saving: false,
      step: 1,
      color: 'deep-orange',

      hasChanges: false,

      form: {
        data_emissao: moment().toDate(),
        data_vencimento: moment().format('YYYY-MM'),
        parcela_quantidade: 1,
        parcelas: []
      },
    }
  },

  computed: {
    hasDischarge() {
      return this.form.id && this.form.volume != this.form.saldo;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.reset();
        }
        this.$emit("input", value);
      },
    }
  },

  watch: {
    discountId(value) {
      if (value) {
        this.form.id = this.discountId;
        this.loadDiscount();
      }
    },
    form: {
      deep: true,
      handler() {
        this.hasChanges = true;
      }
    },
  },

  created() {
    if (this.discountId) {
      this.form.id = this.discountId;
      this.loadDiscount();
    }
  },

  mounted() {
    if (this.$refs['form-1']) {
      this.$refs['form-1'].resetValidation();
    }
    if (this.$refs['form-2']) {
      this.$refs['form-2'].resetValidation();
    }
  },

  methods: {
    async nextStep() {
      if (!await this.$refs[`form-${this.step}`].validate() && !this.hasDischarge) {
        return;
      }
      this.step++;
    },

    async validateForm() {
      if (!await this.$refs['form-1'].validate()) {
        this.step = 1;
        return false;
      }
      if (!await this.$refs['form-2'].validate()) {
        this.step = 2;
        return false;
      }
      return true;
    },

    backStep() {
      this.step--;
    },

    async save() {
      if (!await this.validateForm()) {
        return;
      }
      this.saving = true;
      try {

        let payload = {
          ...this.form,
          volume: parseFloat(this.form.volume),
          data_emissao: moment(this.form.data_emissao).format('YYYY-MM-DD'),
          data_vencimento: moment(this.form.data_vencimento, 'YYYY-MM').format('YYYY-MM-DD'),
          parcelas: this.form.parcelas.map(parcela => ({
            volume: parseFloat(parcela.volume),
            data_vencimento: moment(parcela.data_vencimento, 'YYYY-MM').format('YYYY-MM-DD'),
          }))
        };

        const action = payload.id ? 'atualiza' : 'insere';

        const { data } = await this.$axios.post(`/descontosProdutor/${action}`, payload);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.form.id = data.id;
        for (let i = 0; i < this.form.parcela_quantidade; i++) {
          this.form.parcelas[i].id = data.parcelas[i];
        }

        this.close();
        this.$emit('newDiscount', data.id);
        this.$snotify.success("Desconto salvo com sucesso!", "Sucesso");
        // Delay para evitar que as parcelas sejam alteradas.
        setTimeout(() => {
          this.hasChanges = false;
        }, 100);
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    close() {
      this.show = false;
    },

    async remove() {
      if (this.form.volume != this.form.saldo) {
        return;
      }
      if (!(await this.$root.$confirm('Excluir Desconto', 'Tem certeza que deseja excluir este desconto?', { color: 'red' }))) {
        return;
      }
      this.loading = true;
      try {

        const { data } = await this.$axios.post(`/descontosProdutor/inativa`, { id: this.form.id });

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$emit('removeDiscount');
        this.$snotify.success("Desconto excluído com sucesso!", "Sucesso");
        this.close();
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadDiscount() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(`/descontosProdutor/detalhe`, { id: this.form.id });

        if (!data || !data.id) {
          return;
        }

        this.form = {
          ...data,
          data_emissao: moment(data.data_emissao).toDate(),
          data_vencimento: moment(data.data_vencimento).format('YYYY-MM'),
          volume: parseFloat(data.volume),
          saldo: parseFloat(data.saldo),
          parcelas: data.parcelas.map(parcela => ({
            ...parcela,
            data_vencimento: moment(parcela.data_vencimento).format('YYYY-MM'),
            volume: parseFloat(parcela.volume),
            saldo: parseFloat(parcela.saldo),
          }))
        };
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o desconto!", "Atenção");
        this.close();
        console.error(e);
      } finally {
        // Delay para evitar que as parcelas sejam alteradas.
        setTimeout(() => {
          this.loading = false;
          this.hasChanges = false;
        }, 100)
      }
    },

    onAmountChange(value) {
      if (this.loading) {
        return;
      }
      this.form.saldo = value;
      this.onNoInstallments();
    },

    onNoInstallments: _.debounce(function() {
      if (this.loading || this.form.saldo != this.form.volume) {
        return;
      }
      // Quantidade de parcelas
      const noInstallments = parseInt(this.form.parcela_quantidade) || 0;
      // Volume do desconto
      const accountAmount = parseFloat(this.form.volume);
      // Volume de cada parcela, com arredondamento para 2 casas decimais (para baixo)
      const installmentAmount = Math.floor((accountAmount / noInstallments) * 100) / 100;
      // Diferença em centavos das parcelas
      const rest = accountAmount - (installmentAmount * noInstallments);
      // Na última parcela deve ser adicionado a diferença
      const lastAmount = +(installmentAmount + rest).toFixed(2);

      this.form.parcelas = [];

      let lastDueDate = moment(this.form.data_vencimento, 'YYYY-MM').format('YYYY-MM-DD');
      for (let i = 1; i <= noInstallments; i++) {
        const amount = i < noInstallments ? installmentAmount : lastAmount;
        this.form.parcelas.push({
          data_vencimento: moment(lastDueDate).format('YYYY-MM'),
          volume: amount,
          saldo: amount,
        });
        lastDueDate = moment(lastDueDate).add(1, 'M').format('YYYY-MM-DD');
      }
    }, 500),

    /**
     * Verifica o volume da parcela atual e rateia o resto entre as parcelas posteriores
     */
    onChangeAmount(amount, idx) {
      if (this.loading || (idx == this.form.parcelas.length - 1)) {
        return;
      }
      // Volume desta parcela
      amount = parseFloat(amount) || 0;
      // Índice para dividir esta parcela das posteriores
      const splitInstallment = idx + 1;
      // Quantidade de parcelas
      const noInstallments = this.form.parcelas.length;
      // Volume do desconto
      const accountAmount = parseFloat(this.form.volume);
      // Soma essa parcela com as parcelas atuais
      const currAmount = this.form.parcelas.slice(0, splitInstallment).reduce((acc, cur) => acc + (parseFloat(cur.volume) || 0), 0);
      // A diferença será rateada nas parcelas posteriores
      const restAmmount = accountAmount - currAmount;
      // Quantidade de parcelas restantes
      const restNoInstallments = noInstallments - splitInstallment;
      // Volume de cada parcela, com arredondamento para 2 casas decimais (para baixo)
      const installmentAmount = Math.floor((restAmmount / restNoInstallments) * 100) / 100;
      // Diferença em centavos das parcelas
      const rest = restAmmount - (installmentAmount * restNoInstallments);
      // Na última parcela deve ser adicionado a diferença dos centavos
      const lastAmount = +(installmentAmount + rest).toFixed(2);

      this.form.parcelas[idx].saldo =  amount;

      for (let i = splitInstallment; i < noInstallments; i++) {
        const amount = i < (noInstallments - 1) ? installmentAmount : lastAmount;
        this.form.parcelas[i].volume =  amount;
        this.form.parcelas[i].saldo =  amount;
      }
    },

    reset() {
      this.$refs['form-1'] && this.$refs['form-1'].resetValidation();
      this.$refs['form-2'] && this.$refs['form-2'].resetValidation();
      this.form = {
        data_emissao: moment().toDate(),
        data_vencimento: moment().format('YYYY-MM'),
        parcela_quantidade: 1,
        parcelas: []
      };
      this.step = 1;
    },

    /**
     * Retorna as regras de validações de cada parcela
     */
    getRules(idx) {
      // Volume do desconto
      const accountAmount = parseFloat(this.form.volume);
      // Soma essa parcela com as parcelas atuais
      const currAmount = this.form.parcelas.slice(0, idx + 1).reduce((acc, cur) => acc + cur.volume, 0);
      // Volume da parcela
      const installmentAmount = parseFloat(this.form.parcelas[idx].volume);
      return [
        () => !!installmentAmount || 'Informe o volume',
        () => installmentAmount > 0 || 'O volume não pode ser negativo',
        () => currAmount <= accountAmount || 'O volume deve ser menor ou igual ao volume do desconto',
      ]
    },

    /**
     * Se a data de vencimento da primeira parcela for alterada
     * Altera também a data de vencimento do desconto
     */
    onChangeDateParcel(value, idx) {
      if (idx == 0) {
        this.form.data_vencimento = value;
        this.onNoInstallments();
      }
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  }
}
</script>
