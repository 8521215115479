<template>
  <v-dialog
    v-model="show"
    width="30%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Edição/Cadastro de Checklist</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.description"
                  label="Descrição *"
                  :rules="isRequired"
                  :disabled="clone"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="form.questionnaire"
                  :items="questionnaires"
                  label="Questionário *"
                  item-text="title"
                  item-value="id"
                  :rules="isRequired"
                  :disabled="clone"
                />
              </v-col>
              <v-col
                v-if="clone"
                cols="12"
              >
                <v-autocomplete
                  v-model="form.dary"
                  :items="daries"
                  label="Selecionar Unidade *"
                  item-text="nome"
                  item-value="id"
                  :rules="isRequired"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import QuestionnaireTypes from "@/Domains/Questionnaires/Constants/QuestionnaireTypes";
import * as _ from "lodash";

export default {

  props: {
    value: Boolean,
    editId: String,
    type: String,
    serviceId: String,
    serviceName: String,
    clone: Boolean,
    checklistTypes: Array,
  },

  data() {
    return {
      form: {
        description: null,
        questionnaire: null,
        dary: null,
      },

      loading: false,

      questionnaires: [],

      isRequired: [(v) => !!v || 'Este campo é obrigatório'],

      daries: [],
    }
  },

  computed: {

    types() {
      return QuestionnaireTypes.filter(({ type }) => type === 'visits');
    },

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          description: null,
          questionnaire: null,
        };

        if (this.editId) {
          this.loadPan();
        }

        this.loadQuestionnaires();
      }
    }
  },

  async mounted() {
    await this.loadDary();
  },

  methods: {

    async loadQuestionnaires() {
      try {
        this.$root.$progressBar.loading();
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormulario`,
          {
            tipos: this.checklistTypes,
          }
        );

        this.questionnaires = data.map((item) => {
          return {
            id: item.id,
            title: item.titulo,
            totalAnswered: item.total_respostas,
            types: item.tipo,
            status: item.status,
          };
        });

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
        this.$root.$progressBar.hide();
      }
    },

    async loadPan() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/registrations/checklist/${this.editId}`);

        this.form = {
          description: data.descricao,
          questionnaire: data.id_checklist,
        };

        this.type = data.tipo;
        this.serviceId = data.id_servico;
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita uma causa no banco de dados
     */
    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_checklist_servico: this.editId,
          descricao: this.form.description,
          id_checklist: this.form.questionnaire,
          id_servico: this.serviceId,
          tipo: this.type,
          id_laticinio: this.form.dary,
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);

        if (this.clone) {
          this.$snotify.success("Checklist clonado com sucesso", "Sucesso");
          this.close();
          return;
        }

        this.$snotify.success("Checklist cadastrado com sucesso", "Sucesso");

        this.close();

      } catch (e) {

        this.$snotify.error("Erro ao cadastrar Checklist", "Atenção");

        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadDary() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/pessoa/listaLaticiniosUsuario`
        );

        this.daries = data.map((item) => {
          return {
            id: item.id_laticinio,
            nome: item.nome,
          };
        });

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/checklist`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/checklist/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    }
  }

}
</script>
