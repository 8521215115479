<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="freighter-vouchers"
  >
    <v-row>
      <v-spacer />
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          dark
          @change="getBills"
        />
      </v-col>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Vales a Pagar
            <v-spacer />
            <v-col
              cols="4"
              class="pa-0"
            >
              <v-text-field
                v-model="bills.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="bills.headers"
            :items="bills.items"
            :search="bills.search"
            :loading="loading"
            :sort-by.sync="filter.sort.by"
            :sort-desc.sync="filter.sort.desc"
            dark
            dense
            class="elevation-1 report-table"
            @click:row="showBill"
          >
            <template #[`item.type`]="{ value }">
              <v-chip
                x-small
                color="primary"
              >
                {{ value }}
              </v-chip>
            </template>
            <template #[`item.issueDate`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.dueDate`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.amount`]="{ value }">
              R$ {{ value | formatCurrency }}
            </template>
            <template #[`item.balance`]="{ value }">
              R$ {{ value | formatCurrency }}
            </template>
            <template #[`item.button`]="{ item }">
              <v-btn
                v-if="item.balance != 0"
                block
                small
                color="red darken-1"
                @click.stop="discharge(item)"
              >
                Pagar
              </v-btn>
              <v-btn
                v-else
                block
                small
                color="orange"
                @click.stop="chargeback(item)"
              >
                Estornar
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Pagamentos
            <v-spacer />
            <v-col
              cols="4"
              class="pa-0"
            >
              <v-text-field
                v-model="billeds.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="billeds.headers"
            :items="billeds.items"
            :search="billeds.search"
            :loading="loading"
            :sort-by.sync="filter.sort.by"
            :sort-desc.sync="filter.sort.desc"
            dark
            dense
            class="elevation-1 report-table"
            @click:row="showBill"
          >
            <template #[`item.issueDate`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.paymentDate`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.amount`]="{ value }">
              R$ {{ value | formatCurrency }}
            </template>
            <template #[`item.button`]="{ item }">
              <v-btn
                block
                small
                color="orange"
                @click.stop="chargebackPayment(item)"
              >
                Estornar
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      type="VALE_PRESTADOR"
      @input="billDialog.id = null, getBills()"
    />

    <discharge-dialog
      v-model="dischargeDialog.show"
      :installment-id="dischargeDialog.id"
      :recurrence-id="dischargeDialog.recurrenceId"
      :recurrence-due-date="dischargeDialog.recurrenceDueDate"
      @input="dischargeDialog.id = null, dischargeDialog.recurrenceId = null"
      @newPayment="getBills"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="deep-orange"
        @click="newBill"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>
          Novo Vale
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="teal darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import DischargeDialog from '@/Domains/Financial/Components/DischargeDialog.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";

moment.locale('pt');

export default {
  name: "freighter-vouchers",

  components: {
    VDateRangePicker,
    BillDialog,
    DischargeDialog,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        sort: { by: 'issueDate', desc: false }
      },

      bills: {
        search: '',
        headers: [
          { text: '#', value: 'code' },
          { text: 'Tipo', value: 'type' },
          { text: 'Prestador', value: 'person' },
          { text: 'Descrição', value: 'description' },
          { text: 'Emissão', value: 'issueDate' },
          { text: 'Vencimento', value: 'dueDate' },
          { text: 'Valor', value: 'amount' },
          { text: 'Saldo', value: 'balance' },
          { text: '', value: 'button', width: 100 },
        ],
        items: []
      },

      billeds: {
        search: '',
        headers: [
          { text: '#', value: 'code' },
          { text: 'Prestador', value: 'person' },
          { text: 'Descrição', value: 'description' },
          { text: 'Emissão', value: 'issueDate' },
          { text: 'Pagamento', value: 'paymentDate' },
          { text: 'Valor', value: 'amount' },
          { text: '', value: 'button', width: 100 },
        ],
        items: []
      },

      billDialog: {
        show: false,
        id: null,
      },

      dischargeDialog: {
        show: false,
        id: null,
        recurrenceId: null,
        recurrenceDueDate: null,
      },
    };
  },

  methods: {
    async getBills() {
      const [startDate, endDate] = this.filter.range;

      let [{ data: bills }, { data: billeds }, { data: recurrences }] = await Promise.all([
        await this.$axios.post(
          `/financeiro/listaParcelas`,
          { tipo: 'VALE_PRESTADOR', data_inicio: startDate, data_fim: endDate }
        ),
        await this.$axios.post(
          `/financeiro/listaLancamentos`,
          { tipo: 'VALE_PRESTADOR', data_inicio: startDate, data_fim: endDate }
        ),
        await this.$axios.post(
          `/financeiro/listaRecorrencias`,
          { tipo: 'VALE_PRESTADOR', data_inicio: startDate, data_fim: endDate }
        ),
      ]);

      bills = bills.map(p => ({
        type: 'PARCELA',
        recurrenceId: null,
        installmentId: p.id_parcela,
        accountId: p.id_titulo,
        code: p.cod_parcela,
        person: p.nome_pessoa,
        description: p.descricao || p.observacao,
        issueDate: p.data_emissao,
        dueDate: p.data_vencimento,
        amount: p.valor,
        balance: p.saldo,
      }))

      recurrences = recurrences.map(o => ({
        type: 'RECORRENTE',
        recurrenceId: o.id_titulo_recorrencia,
        installmentId: o.id_parcela || null,
        accountId: o.id_titulo,
        code: o.cod_titulo_recorrencia,
        person: o.nome_pessoa,
        description: o.descricao || o.observacao,
        issueDate: o.data_emissao,
        dueDate: o.data_vencimento,
        amount: o.valor,
        balance: o.saldo,
      }))

      this.bills.items = [...bills, ...recurrences];

      this.billeds.items = billeds.map(o => ({
        id: o.id_lancamento,
        accountId: o.id_titulo,
        code: o.cod_parcela,
        person: o.nome_pessoa,
        description: o.descricao || o.observacao,
        issueDate: o.data_emissao,
        paymentDate: o.data_lancamento,
        amount: o.valor,
      }))
    },

    newBill() {
      this.billDialog.id = null;
      this.billDialog.show = true;
    },

    showBill({ accountId }) {
      this.billDialog.id = accountId;
      this.billDialog.show = true;
    },

    discharge({ recurrenceId, dueDate, installmentId }) {
      this.dischargeDialog.id = installmentId;
      this.dischargeDialog.show = true;
      this.dischargeDialog.recurrenceId = recurrenceId;
      this.dischargeDialog.recurrenceDueDate = dueDate;
    },

    async chargeback({ installmentId }) {
      if (!(await this.$root.$confirm('Estornar Parcela', 'Tem certeza que deseja estornar esta parcela?', { color: 'orange' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/financeiro/estorno`, { id_parcela: installmentId });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Estorno efetuado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao estornar a parcela", "Atenção");
        console.warn(error);
      } finally {
        this.getBills();
      }
    },

    async chargebackPayment({ id }) {
      if (!(await this.$root.$confirm('Estornar Pagamento', 'Tem certeza que deseja estornar este pagamento?', { color: 'orange' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/financeiro/estorno`, { id_lancamento: id });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.$snotify.success("Estorno efetuado com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao estornar o pagamento", "Atenção");
        console.warn(error);
      } finally {
        this.getBills();
      }
    },

    getBillsJson() {
      return this.bills.items.map(item => ({
        '#': item.code,
        Tipo: item.type,
        Prestador: item.person,
        Descrição: item.description,
        Emissão: this.$options.filters.dateFormat(item.issueDate, 'DD/MM/YYYY'),
        Vencimento: this.$options.filters.dateFormat(item.dueDate, 'DD/MM/YYYY'),
        Valor: 'R$ ' + this.$options.filters.formatCurrency(item.amount),
        Saldo: 'R$ ' + this.$options.filters.formatCurrency(item.balance),
      }));
    },

    getBilledsJson() {
      return this.billeds.items.map(item => ({
        '#': item.code,
        Prestador: item.person,
        Descrição: item.description,
        Emissão: this.$options.filters.dateFormat(item.issueDate, 'DD/MM/YYYY'),
        Pagamento: this.$options.filters.dateFormat(item.paymentDate, 'DD/MM/YYYY'),
        Valor: 'R$ ' + this.$options.filters.formatCurrency(item.amount),
      }));
    },

    exportExcel() {
      const bills = this.getBillsJson();
      const billeds = this.getBilledsJson();

      let data = this.XLSX.utils.json_to_sheet(bills, { origin: 2 });

      const billedsStartRow = bills.length + 4;
      this.XLSX.utils.sheet_add_json(data, billeds, { origin: billedsStartRow + 2 });

      data["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
        { s: { r: billedsStartRow, c: 0 }, e: { r: billedsStartRow, c: 3 } },
      ];
      data['A1'] = { v: 'Vales' };
      data[`A${billedsStartRow + 1}`] = { v: 'Pagamentos' };

      data['!cols'] = [
        { wch: 10 },
        { wch: 30 },
        { wch: 40 },
        { wch: 14 },
        { wch: 14 },
        { wch: 14 },
        { wch: 14 }
      ];

      const workbook = this.XLSX.utils.book_new();

      const filename = "vales";

      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    print() {
      const bills = this.htmlTableFromJson(this.getBillsJson(), 'Vales');
      const billeds = this.htmlTableFromJson(this.getBilledsJson(), 'Pagamentos');

      const html = `
      <div>
        ${ bills }
        ${ billeds }
      </div>`;

      return this.printJS({
        documentTitle: 'Vales',
        printable: html,
        type: 'raw-html',
        css: ['https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css'],
        style: `
          table, tr, td { page-break-inside: auto; border: 1px solid lightgray !important; border-collapse: collapse; }
          td, th, tr { font-size: 7pt; color: black; }
        `
      });
    },
  },
};
</script>
