<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        local_shipping
      </v-icon>
      <span class="title font-weight-light">Rastreamento de veículos</span>
      <v-spacer />

      <img
        src="@/Assets/tank-truck.png"
        class="pr-1"
      >
      {{ totals.collect }}
      &nbsp; &nbsp;
      <img
        src="@/Assets/car.png"
        class="pr-1"
      >
      {{ totals.visit }}
    </v-card-title>

    <v-card-text class="flex-grow-1">
      <route-map
        ref="map"
        :center="center"
        :markers="markers"
        disable-recenter
        use-simple-marker
        height="100%"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";
import { isEmpty } from "lodash";
import moment from "moment";

export default {
  name: 'vehicle-tracking',

  components: {
    RouteMap,
  },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: String,
  },

  data() {
    return {
      loading: false,
      polling: null,
      markers: [],
      center: { lat: 0, lng: 0 }
    }
  },

  computed: {

    totals() {
      return this.markers.reduce((acc, item) => {
        const tags = item.tags || [];
        const type = tags.includes('COLETA') ? 'COLLECT' : 'VISIT';

        if (type === 'COLLECT') {
          return {
            ...acc,
            collect: ++acc.collect,
          }
        }

        return {
          ...acc,
          visit: ++acc.visit,
        };
      }, { collect: 0, visit: 0 });
    }

  },

  mounted() {
    this.startPolling();
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  methods: {
    startPolling() {
      this.tracking();
      this.polling = setInterval(() => {
        this.tracking();
      }, 60 * 1000) // polling a cada 1 min
    },
    async tracking() {
      this.loading = true;
      try {
        let { data } = await this.$axios.get(`/equipamento/rastreamento`);

        if (data.codigo != 1) {
          throw data.mensagem;
        }

        let markers = data.locations
          .filter(item => !!item.posicao)
          .map(item => {
            const [lat, lng] = item.posicao.split(',');
            const imgUrl = item.tags.includes('COLETA') ? require('@/Assets/tank-truck.png') : require('@/Assets/car.png');

            return {
              tags: item.tags,
              location: { lat, lng },
              draggable: false,
              visible: true,
              title: item.nome_motorista,
              icon: {
                url: imgUrl
              },
              richMeta: {
                "Motorista": item.nome_motorista,
                "Hora Posição": item.data_hora_posicao ? moment(item.data_hora_posicao).format('DD/MM/YYYY HH:mm') : '',
                "Veículo": item.placa_veiculo
              }
            }
          });

        if (this.$store.state.settings.laticinio && this.$store.state.settings.coordenadas.lat && this.$store.state.settings.coordenadas.lng) {
          // Adiciona o laticínio
          markers.unshift({
            key: `dairy`,
            location: {
              lat: this.$store.state.settings.coordenadas.lat,
              lng: this.$store.state.settings.coordenadas.lng,
            },
            title: this.$store.state.settings.laticinio.name,
            draggable: false,
            visible: true,
            icon: {
              url: require('@/Assets/laticinio.png')
            },
          });
          this.center = this.$store.state.settings.coordenadas;
        }
        else if (!isEmpty(data.dairies)) {
          const dairies = data.dairies
            .map(dairy => ({
              key: dairy.nome,
              location: {
                lat: parseFloat(dairy.end_latitude),
                lng: parseFloat(dairy.end_longitude),
              },
              title: dairy.nome,
              label: dairy.nome,
              draggable: false,
              visible: true,
              color: '#E53935',
            }))
            .filter(dairy => dairy.location.lat && dairy.location.lng);

          // Adiciona os laticínios
          markers.unshift(...dairies);

          this.center = dairies[0].location;
        }
        this.markers = markers;
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o rastreamento de veículos!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
