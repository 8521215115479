<template>
  <div class="mx-4 px-16 pt-0">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 class="menu-header white--text">
            Análise de rotina
          </h2>
          <v-btn
            text
            @click="$router.back()"
          >
            <v-icon>arrow_left</v-icon>
            <div class="pr-3">
              Voltar
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="transparent">
      <v-card-title class="pb-1 pt-1">
        <v-row>
          <v-col
            class="pb-0"
          >
            <person-autocomplete-filter
              v-model="filters.producers"
              label="Produtor"
              type="PRODUCER"
              multiple
              :return-object="false"
              style="min-width:200px"
              @change="loadRoutineAnalysis"
            />
          </v-col>
          <v-col
            class="pb-0"
          >
            <v-select
              v-model="filters.sampleType"
              :items="['Subsequente', 'Semestral','Trimestral']"
              label="Tipo de amostra"
              prepend-inner-icon="change_circle"
              dark
              filled
              clearable
              @change="loadRoutineAnalysis"
            />
          </v-col>
          <v-col
            class="pb-0"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="loadRoutineAnalysis"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        :loading="loading"
        :headers="headers"
        :items="routineAnalysis"
        show-custom-group
        name="Análise de Rotina"
        item-key="id"
        class="elevation-1"
        disable-filtering
        multi-sort
      >
        <template #[`item.estado`]="{ value }">
          <v-chip
            x-small
            :color="getStatusColor(value)"
          >
            {{ getStatusName(value) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="resources.deleteAnalysis"
                @click.capture="
                  excluirAmostra(item.id_analise)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Excluir </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        v-if="resources.insertAnalysis"
        fab
        dark
        color="blue"
        @click="openRoutineAnalysisDialog()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Nova analise
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <routine-analysis-dialog
      ref="analysisDialog"
      :analysis-parameters="analysisParams"
      @onSave="onSaveRoutineAnalysis"
    />

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import * as _ from "lodash";
import moment from "moment";

import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import RoutineAnalysisDialog from "@/Domains/Platform/RoutineAnalysis/Components/RoutineAnalysisDialog.vue";

import AnalysisDictionary from "@/Domains/Platform/Unload/Services/AnalysisDictionary.js";

const LEITE_CRU_REFRIGERADO = '8b794623-d6ff-40bd-b61d-49bbc666eb32';

export default {
  components: {
    PersonAutocompleteFilter,
    VDateRangePicker,
    RoutineAnalysisDialog,
  },

  data() {
    return {
      routineAnalysis: [],

      filters: {
        producers: [],
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        sampleType: null,
      },

      loading: false,

      resources: {
        insertAnalysis: true,
        deletAnalysis: true,
      },

      headers: [
        { text: 'Código', value: 'codigo_laticinio', align: 'center' },
        { text: 'Produtor', value: 'nome', align: 'center', width: 250 },
        { text: 'Data', value: 'data_analise', align: 'center', formatter: value => moment(value).format('DD/MM/YYYY') },
        { text: 'Estado', value: 'estado', align: 'center', formatter: value => this.getStatusName(value) },
        { text: 'Tipo de Amostra', value: 'tipo_amostra', align: 'center', formatter: value => value || '-' },
        { text: 'Acidez', value: 'acidity', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Crioscopia', value: 'cryoscopy', align: 'center', formatter: value => this.formatNumberDecimalThree(value) },
        { text: '% de água', value: 'waterPercentage', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Vol. água', value: 'waterLiters', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Densidade', value: 'density', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'ESD', value: 'esd', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'EST', value: 'est', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Gordura', value: 'fat', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Proteína', value: 'protein', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'pH', value: 'ph', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Sng', value: 'sng', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Alizarol', value: 'alizarol', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Antibiótico', value: 'antibiotic', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Sólidos Totais', value: 'totalSolids', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Álcool', value: 'alcohol', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Lactose', value: 'lactose', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Redutase', value: 'reductase', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Alizarol (Qualit.)', value: 'alizarolQualit', align: 'center', formatter: value => value ? 'Positivo' : 'Negativo' },
        { text: 'Betalactâmicos', value: 'betaLactams', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Tetraciclina', value: 'tetracycline', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Sulfonamida', value: 'sulphonamide', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Cloretos', value: 'chlorides', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Mastite', value: 'mastitis', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Soda', value: 'sodiumHydroxide', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Bicarbonato', value: 'bicarbonateOfSoda', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Formol', value: 'formol', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Peróxidos', value: 'peroxide', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Cloro/Hipoclorito', value: 'chlorine', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Ureia/Urina', value: 'ureia', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Amido', value: 'starch', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Sacarose', value: 'sucrose', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Neutralizantes', value: 'neutralising', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Reconstituintes', value: 'restoratives', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Conservantes', value: 'conservatives', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Sensorial', value: 'sensorial', align: 'center', formatter: value => value ? 'N. Característico' : 'Característico' },
        { text: 'Quinolonas', value: 'quinolones', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Aminoglicosídeos', value: 'aminoglykosides', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Macrolídeos', value: 'macrolides', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Anfenicois', value: 'anfenicois', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Fosfatase', value: 'alkalinePhosphatase', align: 'center', formatter: value => value ? 'Negativo' : 'Positivo' },
        { text: 'Grumos', value: 'clumps', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Coágulo', value: 'clots', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Alcalino', value: 'alkali', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Peroxidase', value: 'peroxidase', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Solubilidade', value: 'solubilidade', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Base seca', value: 'base_seca', align: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Neomicina', value: 'neomicina', aling: 'center', formatter: value => value ? 'Presença' : 'Ausência' },
        { text: 'Observação', value: 'observation', aling: 'center' },
        { text: '', altText: 'Opções', value: 'actions', align: 'center', sortable: false },
      ]
    };
  },

  computed: {
    analysisParams() {
      const rawProductId = LEITE_CRU_REFRIGERADO;
      const settings = this.$store.state.settings;
      const analysisParams = _.get(settings, `plataforma.parametros_analise.materia_prima.${rawProductId}`);

      if (_.isEmpty(analysisParams)) {
        return [];
      }

      return analysisParams.map(param => {
        return {
          name: AnalysisDictionary[param.parametro],
          unit: param.unidade_medida,
          rule: param.padrao,
          restrictionRule: param.restricao,
        };
      });
    },
  },
  mounted() {
    this.loadRoutineAnalysis();
    this.loadResources();
  },
  methods: {

    /**
     * @void
     * Busca as analises de rotina
     */
    async loadRoutineAnalysis() {
      try {
        this.loading = true;

        this.routineAnalysis = [];

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.post(
          `/laboratorioAnalise/listaAnalisesRotina`,
          {
            data_inicio: startDate,
            data_fim: endDate,
            produtores: this.filters.producers,
            tipo_amostra: this.filters.sampleType,
          }
        );

        // troca as key para inglês
        this.routineAnalysis = data.map(a => _.mapKeys(a, (valeu, key) => AnalysisDictionary[key] || key));
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    openRoutineAnalysisDialog() {
      this.$refs.analysisDialog.openDialog();
    },

    onSaveRoutineAnalysis() {
      this.loadRoutineAnalysis();
    },

    async excluirAmostra(id_analise) {
      if (
        !(await this.$root.$confirm("Atenção", "Deseja realmente Excluir esta amostra?", { color: "red" }))
      ) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/laboratorioAnalise/inativaAmostraRotina`,
          {
            id_analise,
          }
        );

        if (data.codigo != 1) {
          throw data.mensagem;
        }
      } catch (error) {
        console.log(error);
        this.$snotify.error(`Oops, ocorreu um erro ao excluir a amostra!\r\n${error}`, "Atenção");
      } finally {
        this.loading = false;
        this.loadRoutineAnalysis();
      }
    },

    print() {
      this.$refs.report.print(null, null, true);
    },

    exportExcel() {
      this.$refs.report.exportExcel();
    },

    getStatusName(value) {
      const statusNames = {
        'DENTRO_PADRAO': 'Dentro do Padrão',
        'FORA_PADRAO': 'Fora do Padrão',
        'RESTRICAO': 'Restrição',
      };
      return statusNames[value] || '-';
    },

    getStatusColor(value) {
      const statusColors = {
        'DENTRO_PADRAO': 'green',
        'FORA_PADRAO': 'red',
        'RESTRICAO': 'orange',
      };
      return statusColors[value] || 'grey';
    },

    loadResources() {
      const recursos = this.$store.state.settings.recursosUsuario;

      this.resources.insertAnalysis =  !! recursos.find(recurso => recurso.recurso === "insert-routine-analysis" && recurso.tipo == "COMPONENTE");
      this.resources.deleteAnalysis =  !! recursos.find(recurso => recurso.recurso === "delete-routine-analysis" && recurso.tipo == "COMPONENTE");

    },

    formatNumber: val => !val ? '0.00' : new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val).replace(/\./g, '').replace(',', '.'),
    formatNumberDecimalThree: val => !val ? '0.00' : new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(val).replace(/\./g, '').replace(',', '.'),
  },
};
</script>
