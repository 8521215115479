<template>
  <div class="mx-6 px-16 pt-0 pb-7">
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Produtores pendentes
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-card
      class="unlinked-card"
      color="transparent"
    >
      <v-card-title class="pa-2">
        <v-row
          class="pa-0"
          justify="end"
        >
          <v-col
            md="4"
            class="pa-2 mb-0 mp-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              dark
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        name="Pendentes"
        :headers="headers"
        :items="filteredProducers"
        :loading="loading"
        dark
        show-custom-group
        @click:row="onPendingProducer"
      >
        <template #[`footer.prepend`]>
          <div class="text-caption text-left ml-4">
            Total
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatNumber(totalProducers) }}
            </v-chip>
          </div>
        </template>

        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="deleteProducer(item)">
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir produtor</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <compare-producer-dialog
      ref="compare-dialog"
      @save="loadProducers()"
    />

    <v-overlay
      :value="loading"
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";

import CompareProducerDialog from "@/Domains/Producer/Management/Components/Dialogs/CompareProducerDialog.vue";

export default {
  name: "PendingProducer",

  components: {
    CompareProducerDialog,
  },

  data() {
    return {
      loading: false,

      filters: {
        search: '',
      },

      headers: [
        { text: "Código", value: "codigo_laticinio" },
        { text: "Produtor", value: "nome" },
        { text: "CPF/CNPJ", value: "cnpj_cpf" },
        { text: "RG", value: "rg" },
        { text: "Celular", value: "celular" },
        { text: "Telefone", value: "telefone" },
        { text: "E-mail", value: "email" },
        { text: "", altText: "Opções", value: "action", sortable: false, align: "end" },
      ],

      producers: [],
    };
  },

  computed: {
    filteredProducers() {
      if (!this.filters.search) {
        return this.producers;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.producers.filter(producer => {
        const searchFilter = !search || JSON.stringify(Object.values(producer)).toUpperCase().includes(search);

        return searchFilter
      });
    },

    totalProducers() {
      return this.filteredProducers.length;
    },
  },

  mounted() {
    this.loadProducers();
  },

  methods: {
    async loadProducers() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/produtores/listaProdutoresPendentes`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.producers = data;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onPendingProducer({ id_pessoa }) {
      this.$refs['compare-dialog'].show({ id: id_pessoa });
    },

    /**
     * Método para exclusão do produtor
     * @param {Object} producer
     */
    async deleteProducer(producer) {
      try {
        if (!(await this.$root.$confirm('Atenção', `Realmente deseja <b> Excluir </b>o(a) produtor(a) <b>${producer.nome}</b> ?`, { color: 'red' }))) {
          return;
        }

        this.loading = true;

        if (!producer.id_pessoa) {
          throw "Produtor não informado";
        }

        const data = await this.$axios.post(`/produtores/deleteProdutor`, {
          id_produtor: producer.id_pessoa,
        });

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        if (data.codigo === 0) {
          throw "Erro ao deletar produtor";
        }

        this.$snotify.success("Produtor deletado com sucesso!", "Sucesso");
        this.loadProducers();

      } catch (error) {
        console.log(error);

        this.$snotify.error("Erro ao deletar produtor ", "Atenção!!");
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)

  },
};
</script>
