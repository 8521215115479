<template>
  <v-card
    :width="dialog.width"
    :min-height="dialog.height"
    tile
    class="d-flex flex-column"
  >
    <v-card-title>Histórico</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-date-range-picker
            v-model="period"
            @change="load"
          />
        </v-col>

        <v-col cols="3">
          <v-select
            v-model="executionType"
            :items="executionTypeOptions"
            label="Tipo de Execução"
            filled
            hide-details
            clearable
            @input="load"
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="search"
            label="Pesquisar"
            prepend-inner-icon="search"
            filled
            hide-details
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="history"
            height="530"
          >
            <template #[`item.executedAt`]="{ value }">
              <v-chip small>
                {{ dateFormat(value, 'DD/MM/YYYY HH:mm:ss') }}
              </v-chip>
            </template>

            <template #[`item.searchRange`]="{ item }">
              <v-chip small>
                {{ dateFormat(item.searchStartAt, 'DD/MM/YYYY') }}
              </v-chip>
              <v-chip small>
                {{ dateFormat(item.searchEndAt, 'DD/MM/YYYY') }}
              </v-chip>
            </template>

            <template #[`item.status`]="{ value }">
              <v-chip small>
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.results`]="{ item }">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      bordered
                      overlap
                      :content="item.results.count"
                      class="mr-4"
                    >
                      <v-btn
                        icon
                        @click="downloadFile(item.results.url)"
                        v-on="on"
                      >
                        <v-icon>article</v-icon>
                      </v-btn>
                    </v-badge>
                  </template>

                  Resultados
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      bordered
                      overlap
                      :content="item.errors.count"
                      class="mr-4"
                    >
                      <v-btn
                        icon
                        @click="downloadFile(item.errors.url)"
                        v-on="on"
                      >
                        <v-icon>feedback</v-icon>
                      </v-btn>
                    </v-badge>
                  </template>

                  Erros
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      bordered
                      overlap
                      :content="item.newResults.count"
                      class="mr-4"
                    >
                      <v-btn
                        icon
                        @click="downloadFile(item.newResults.url)"
                        v-on="on"
                      >
                        <v-icon>next_plan</v-icon>
                      </v-btn>
                    </v-badge>
                  </template>

                  Processadas
                </v-tooltip>
              </div>
            </template>

            <template #[`item.checksum`]="{ value }">
              <v-chip small>
                {{ value }}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-spacer />

    <v-card-actions>
      <v-btn
        color="grey darken-1"
        text
        @click="close()"
      >
        Fechar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {

  components: {
    VDateRangePicker,
  },

  props: {
    dialog: {
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    settings: {
      type: Object,
      default: () => ({}),
    }
  },

  data() {
    return {
      history: [],

      search: '',

      period: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      ],

      executionType: null,

      executionTypeOptions: [
        {
          text: "Automática",
          value: "DEFAULT",
        },
        {
          text: "Manual",
          value: "MANUAL",
        },
      ],

      headers: [
        {
          text: "Data de Execução",
          value: "executedAt",
        },
        {
          text: "Range de Busca",
          value: "searchRange",
        },
        {
          text: "Resultados",
          value: "results",
          sortable: false,
        },
        {
          text: "Status",
          value: "message",
          sortable: false,
        },
        {
          text: "Checksum",
          value: "checksum",
        },
      ],
    }
  },

  computed: {
    isLoading: {
      get() {
        return this.loading;
      },
      set(loading) {
        return this.$emit('update:loading', loading);
      },
    },
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      try {
        this.isLoading = true;

        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.get(`/settings/labs/dairy/${this.settings.id}/history`, {
          params: {
            date_start: startDate,
            date_end: endDate,
            execution_type: this.executionType,
          }
        });

        this.history = data.map(history => {
          return {
            id: history.id_execucao_analise,
            executedAt: history.data_hora_registro,
            executionType: history.tipo_execucao,
            searchStartAt: history.data_inicio_busca,
            searchEndAt: history.data_fim_busca,
            results: {
              count: history.quantidade_resultados,
              url: history.url_resultados,
            },
            errors: {
              count: history.quantidade_erros,
              url: history.url_erros,
            },
            newResults: {
              count: history.quantidade_processados,
              url: history.url_processados,
            },
            checksum: history.checksum,
            status: history.status,
            message: history.mensagem,
          }
        });
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    close() {
      return this.$emit('close');
    },

    downloadFile: (file) => window.open(file, '_blank'),

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },
}
</script>
