<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <v-select
        v-model="form.estados"
        :items="estados"
        label="Quando for para os Estados"
        multiple
        chips
        deletable-chips
        persistent-placeholder
        placeholder="Todos"
        prepend-inner-icon="villa"
        clearable
      />
    </v-col>
    <v-col
      cols="12"
      class="pb-0"
    >
      <v-autocomplete
        v-model="form.produtos"
        :items="produtos"
        label="Quando for para os Produtos"
        item-value="id"
        item-text="description"
        multiple
        chips
        deletable-chips
        persistent-placeholder
        placeholder="Todos"
        prepend-inner-icon="inventory"
      />
    </v-col>
    <v-col
      cols="12"
      class="pb-0"
    >
      <span class="caption d-block ml-8">Quando for para os seguintes NCMs</span>
      <div class="d-flex flex-wrap">
        <v-chip
          v-for="(ncm, idx) of form.ncms"
          :key="idx"
          close
          class="mr-2 mb-2"
          @click:close="removeNcm(idx)"
        >
          {{ applyMask(ncm, masks.ncm) }}
        </v-chip>
      </div>
      <masked-text-field
        v-model="ncm"
        :mask="masks.ncm"
        unmask
        dense
        prepend-inner-icon="subtitles"
        :placeholder="masks.ncm.mask"
        @keyup.enter="addNcm"
      >
        <template #append>
          <v-btn
            icon
            small
            @click.stop="addNcm"
          >
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </template>
      </masked-text-field>
    </v-col>
    <v-col
      cols="12"
      class="py-0"
    >
      <v-input
        :value="form.estados.length + form.produtos.length + form.ncms.length"
        required
        :rules="[v => !!v || 'Configure ao menos uma exceção (Estado, Produto ou NCM)']"
      />
    </v-col>
  </v-row>
</template>

<script>

import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import IMask from 'imask';

export default {

  components: {
    MaskedTextField,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      masks: {
        ncm: { mask: '0000.00.00' },
      },

      estados: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'EX',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RN',
        'RS',
        'RJ',
        'RO',
        'RR',
        'SC',
        'SP',
        'TO',
      ],
      produtos: [],
      ncm: null,
    }
  },

  computed: {
    form: {
      get() {
        return this.data;
      },

      set(newValue) {
        return this.$emit('update:data', newValue);
      },
    },
  },

  created() {
    this.loadItems()
  },

  methods: {
    async loadItems() {
      const { data } = await this.$axios.get(`/item`, { params: {
        tipos: ['PRODUTO_ACABADO', 'MATERIA_PRIMA']
      } });

      this.produtos = data.map(item => ({
        id: item.id_item,
        code: item.cod_item,
        description: `${item.cod_item} - ${item.nome}`,
        type: item.tipo,
      }));
    },

    addNcm() {
      if (!this.ncm || this.ncm.length < 8) {
        return;
      }
      this.form.ncms.push(this.ncm);
      this.ncm = null;
    },

    removeNcm(idx) {
      this.form.ncms.splice(idx, 1);
    },

    applyMask(value, mask) {
      return IMask.pipe((value || '').toString(), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  }
}
</script>
