<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="dateAtMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="qualityValidityStart | dateFormat('MM/YYYY')"
                label="Mês de início"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                required
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="qualityValidityStart"
              :max="qualityValidityEnd"
              type="month"
              no-title
              scrollable
              @input="dateAtMenu = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="dateAtMenuEnd"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="qualityValidityEnd | dateFormat('MM/YYYY')"
                label="Mês de fim"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                required
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="qualityValidityEnd"
              :min="qualityValidityStart"
              type="month"
              no-title
              scrollable
              @input="dateAtMenuEnd = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <v-autocomplete
            v-model="dairySelected"
            outlined
            chips
            small-chips
            dark
            filled
            multiple
            hide-details
            label="Unidades "
            :items="ListDairy"
            item-text="nome"
            item-value="id_pessoa"
          >
            <template #selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                color="primary"
                @click="data.select"
                @click:close="removeChipDairy(data.item)"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>
                {{ data.item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="obs"
            label="Observações"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <questionnaires-actions
            :value="questionnairesGoals"
            :project="project"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click.stop="save"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar configuração
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import QuestionnairesActions from "@/Domains/Projects/Questionnaires/Components/QuestionnairesActions.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    QuestionnairesActions,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },

  props: {
    value: String,

    project: String,
  },

  data() {
    return {

      id: null,

      tab: "aba-qualidade",
      loading: false,

      questionnairesGoals: [],

      saving: false,

      protocol: '',

      obs: '',

      ListDairy: [],

      dairySelected: [],

      qualityValidityStart: null,

      qualityValidityEnd: null,

      // Date Picker
      dateAtMenu: false,

      dateAtMenuEnd: false,

      emergencyGoals: {},
      qualityGoalsPae: [],

    };
  },

  watch: {
    value() {
      this.id = this.value;
      this.load();
    }

  },
  created() {
    this.id = this.value;
    this.load();
    this.getDairies();
  },

  methods: {
    async getDairies() {
      if (this.ListDairy.length > 0) {
        return this.ListDairy;
      }
      const { data } = await this.$axios.post(`/pessoa/listaLaticinioJson`);
      this.ListDairy = data;
    },

    removeChipDairy(lat) {
      this.dairySelected = this.dairySelected.filter((dairyId) => dairyId !== lat.id_pessoa);
    },

    async load() {
      this.qualityValidityStart = null;
      this.qualityValidityEnd = null;
      this.protocol = null;
      this.obs = null;
      this.questionnairesGoals = [];

      if (this.id) {
        await this.loadPlanning();
      }

    },

    async loadPlanning() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/recomendacoesQualidade/detalhe`, { id: this.id });

        if (!_.isObject(data)) {
          throw data;
        }

        await this.setState(data);

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas!", "Atenção");
        console.warn(err)
      } finally {
        this.loading = false;
      }

    },

    async setState(data = null) {
      if (!_.isEmpty(data)) {

        this.qualityValidityStart = !_.isEmpty(data.data) ? moment(data.data).format("YYYY-MM") : moment().format("YYYY-MM");
        this.qualityValidityEnd = !_.isEmpty(data.data_fim) ? moment(data.data_fim).format("YYYY-MM") : moment().format("YYYY-MM");
        this.protocol = data.protocolo;
        this.obs = data.descricao;
        this.dairySelected = data.laticinios;
        this.questionnairesGoals = !_.isEmpty(data.questionario) ? data.questionario : [];
        //metas das outras abas (pae)
        // traz somente para nao sobreescrever com null na hora de salvar
        this.emergencyGoals      = !_.isEmpty(data.emergencial) ? data.emergencial : {};
        this.qualityGoalsPae     = !_.isEmpty(data.qualidade) ? data.qualidade : [];
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        this.$snotify.warning("Campos inválidos", "Atenção");
        return false;
      }
      if (_.isEmpty(this.qualityValidityStart) || _.isEmpty(this.qualityValidityEnd)) {
        this.$snotify.warning("Mêses de inicio e fim obrigatorios", "Atenção");
        return false;
      }
      try {
        this.loading = true;
        this.saving = true;

        this.questionnairesGoals = this.questionnairesGoals.map(q => ({
          id_formulario: q.questionnaire.value,
          ...q
        }));

        const payload = {
          id_assistencia: this.id,
          data: moment(this.qualityValidityStart).startOf("month").format('YYYY-MM-DD'),
          data_fim: moment(this.qualityValidityEnd).endOf("month").format('YYYY-MM-DD'),
          protocolo: this.protocol,
          descricao: this.obs,
          tipo: this.project ? this.project : 'PQFL',
          questionario: this.questionnairesGoals,
          emergencial: this.emergencyGoals,
          qualidade: this.qualityGoalsPae,
          laticinios: this.dairySelected,
        };

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/salvaMetas`,
          payload
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem || data);
        }

        this.id = data.id;
        this.$emit('input', data.id);
        //this.loadPlanning();
        this.$snotify.success("Metas salvas com sucesso", "Sucesso");

      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao salvar metas!", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
