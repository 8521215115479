<template>
  <v-autocomplete
    v-model="input"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    :label="label"
    item-text="name"
    item-value="id"
    return-object
    cache-items
    :filled="filled"
    :clearable="clearable"
    :prepend-inner-icon="prependInnerIcon"
    :rules="rules"
    hide-no-data
    v-bind="$attrs"
    @change="onChange"
  />
</template>

<script>
import { debounce, isArray } from 'lodash';

export default {

  props: {

    value: {
      type: [ Object, Array ],
    },

    label: {
      type: String,
      default: 'Empresa'
    },

    filled: {
      type: Boolean,
      default: true,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    prependInnerIcon: {
      type: String,
      default: 'business',
    },

    rules: Array,
  },

  data() {
    return {
      input: null,
      loading: false,
      items: [],
      search: ''
    }
  },

  watch: {
    value() {
      this.input = this.value;
      if (this.value.name) {
        this.queryDairies(this.value.name);
      }
    },
    
    search(val) {
      if (val && val !== this.input) {
        this.loading = true;
        this.queryDairies(val);
      }
    },
  },

  created() {
    this.input = this.value;
  },

  mounted() {
    if (this.value.name) {
      this.queryDairies(this.value.name);
    }
  },

  methods: {
    queryDairies: debounce(async function(nome) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/laticinio/listaJson`, { nome });

        if (!isArray(data)) {
          throw 'Error';
        }

        this.items = data.map(o => ({
          id: o.id_laticinio,
          name: o.nome,
        }));

      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os laticínios!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    }, 500),

    onChange(event) {
      // atualiza o v-model do componente
      this.$emit("input", event || {});

      this.$emit("change");
    },
  }
}
</script>
