<template>
  <div>
    <v-card
      ref="analysis-filter"
      color="transparent"
      flat
    >
      <v-row class="pa-0">
        <v-col
          cols="12"
          md="3"
        >
          <month-picker
            v-model="filter.month.input"
            @change="onMonthFilter"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <person-autocomplete-filter
            v-model="filter.technical"
            type="TECHNICAL"
            label="Técnico"
            dark
            @change="() => selectedProducers = []"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <route-regions-filter
            v-model="filter.routes"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="filter.status"
            :items="typeStatusFilter"
            label="Status"
            prepend-inner-icon="menu"
            dark
            filled
            hide-details
            @change="loadAverage"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card
      class="v-tamanho mt-2"
      color="transparent"
      dark
    >
      <v-card-title>
        {{ `Produtores: ${averagesItems.length}` }}
        <v-spacer />
        <v-col
          cols="4"
          md-4
          sm-4
          class="pa-0"
        >
          <v-text-field
            v-model="filter.search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
            dark
          />
        </v-col>
      </v-card-title>
      <v-data-table
        v-model="selectedProducers"
        :headers="headers"
        :items="averagesItems"
        class="elevation-1"
        multi-sort
        dark
        show-select
        :loading="loading"
      >
        <template #[`header.data-table-select`]="{ on: onSelect, props }">
          <v-tooltip
            top
            :disabled="!!filter.technical.id"
          >
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-simple-checkbox
                  v-bind="props"
                  :disabled="!filter.technical.id"
                  v-on="onSelect"
                />
              </span>
            </template>
            Escolha o técnico para habilitar
          </v-tooltip>
        </template>
        <template
          v-for="(month,index) in months"
          v-slot:[`header.${month}`]="{ header }"
        >
          <v-menu
            :key="month"
            ref="monthMenu"
            :close-on-content-click="false"
            left
          >
            <template v-slot:activator="{ on , attrs }">
              <v-btn
                color="blue-grey lighten-1"
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  small
                  :color="activeFilters[month] !== null? 'deep-orange darken-4':'grey darken-1'"
                >
                  filter_list
                </v-icon>
              </v-btn>
            </template>
            <v-list
              flat
              class="pa-0"
            >
              <v-list-item-group multiple>
                <template>
                  <v-list-item>
                    <template v-slot:default="{}">
                      <v-list-item-title>
                        <v-autocomplete
                          v-model="activeFilters[header.value]"
                          :items="columnFilter"
                          color="primary"
                          prepend-icon="filter_list"
                          @change="onFilterColumn(month,index)"
                        />
                      </v-list-item-title>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
              <v-divider class="pa-0 ma-0" />
              <v-row
                justify="end"
                class="pa-0"
                no-gutters
              >
                <v-col
                  align-self="center"
                  cols="5"
                  class="pa-0 text-right"
                >
                  <v-btn
                    color="teal darken-1"
                    text
                    @click="onClearFilter(header.value,index)"
                  >
                    Limpar
                  </v-btn>
                </v-col>
              </v-row>
            </v-list>
          </v-menu>

          <span :key="index">{{ header.text }}</span>
        </template>

        <template
          #[`item.data-table-select`]="{ isSelected, select }"
        >
          <v-tooltip
            top
            :disabled="!!filter.technical.id"
          >
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-simple-checkbox
                  :value="isSelected"
                  :disabled="!filter.technical.id"
                  @input="select($event)"
                />
              </span>
            </template>
            Escolha o técnico para habilitar
          </v-tooltip>
        </template>

        <template
          v-for="(month, index) in months"
          v-slot:[`item.${month}`]="{ item, value }"
        >
          <v-chip
            :key="index"
            small
            :color="analysisChipColor(value)"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="showLabelMonthVisit(month, item.data_ultima_visita, value) "
                  small
                  left
                  v-bind="attrs"
                  v-on="on"
                >
                  label_important
                </v-icon>
              </template>
              Mês referente à ultima visita
            </v-tooltip>
            {{ value | sanitizeData() }}
          </v-chip>
        </template>

        <template
          #[`item.data_ultima_visita`]="{ value, item }"
        >
          <v-chip
            small
            label
            :color="checkDateLastVisitColor(value, item)"
          >
            {{ value | dateFormat("DD/MM/YYYY") }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        v-show="!!filter.technical.id"
        fab
        dark
        color="purple"
        @click="scheduleVisit"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              schedule
            </v-icon>
          </template>
          Agendar visita
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
    <event-viewer-dialog
      ref="eventViewer"
      v-model="selectedEvent"
      :show="selectedOpen && !saving"
      :technicians="technicians"
      editing
      disable-technician
      @click:close="closeEvent"
      @click:cancel="closeEvent"
      @click:save="saveEvent"
    />
    <v-overlay
      :value="saving"
    >
      <v-card-text>
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import XLSX from "xlsx-js-style";
import qs from "qs";
import moment from "moment-timezone";

import MonthPicker from "@/Support/Components/MonthPicker.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RouteRegionsFilter from "@/Domains/Quality/Indicators/AverageOfQuality/Components/RouteRegionsFilter.vue";
import EventViewerDialog from "@/Domains/Visits/Schedule/Components/EventViewerDialog.vue";
import Ripple from 'vuetify/lib/directives/ripple';

export default {
  directives: {
    Ripple,
  },
  components: {
    MonthPicker,
    PersonAutocompleteFilter,
    RouteRegionsFilter,
    EventViewerDialog,
  },
  filters: {
    sanitizeData: (value) => {
      return _.includes([undefined, null, 0], value) ? " - " : parseInt(value);
    },
    parseInt: (value) => {
      return parseInt(value);
    },
    dateFormat: (value, format) => {

      return _.isEmpty(value) ? "-" : moment(value).format(format);
    }
  },
  data() {
    return {
      filter: {
        month: {
          input: "thisMonth",
          range: [],
        },
        technical: {
          id: "",
          description: ""
        },
        routes: [],
        search: null,
        status: "active",
      },

      data: [],

      months: [],

      listMenu: false,

      activeFilters: [],

      columnFilter: [
        { text: "Maior igual a 300", value: ">=" },
        { text: "Menor igual 299", value: "<=" },
      ],

      loading: false,

      selectedProducers: [],
      technicians: [],
      selectedEvent: {},
      selectedOpen: false,
      saving: false,

      typeStatusFilter: [
        { value: 'active', text: 'Ativos' },
        { value: 'unlinked', text: 'Desvinculados' },
        { value: 'all', text: 'Todos' },
      ],
    };
  },
  computed: {

    headers() {
      const base = [
        { text: "Código", value: "codigo_produtor", align: "start", with: 80 },
        { text: "Produtor", value: "nome", align: "start", with: 100 },
        { text: "Rota Principal", value: "rota_principal", align: "start" },
        { text: "Status", value: "status", align: "start", with: 80 },
        { text: "Volume (L)", value: "volume", align: "start", with: 80 },
      ];

      const monthsHeaders = this.handleHeaders();

      return _.concat(base, _.concat(monthsHeaders, [{ text: "Última visita", value: "data_ultima_visita", align: "center", with: 80 }]));
    },

    /**
     * Retorna as médias filtradas
     */
    averagesItems() {
      if (_.isEmpty(this.filter.technical.id) && _.isEmpty(this.filter.search) && _.isEmpty(this.filter.routes)) {
        return this.data;
      }

      const columns = _.map(this.headers, 'value');
      const search = (this.filter.search || '').toUpperCase().trim() || null;

      // Retorna as informações com base nos filtros em tela
      return this.data.filter((average) => {
        const selectedColumns = _.pick(average, columns);

        let hasFilterTechnical = _.includes(average.tecnicos, this.filter.technical.id);
        let searchFilter = JSON.stringify(Object.values(selectedColumns)).toUpperCase().includes(search);
        let hasFilterRoute = false;
        
        if (average.rotas && !_.isEmpty(this.filter.routes)) {
          this.filter.routes.forEach(rota => {
            if(_.includes(average.rotas, rota.id)) {
                hasFilterRoute = true;
            }
          });
        }
        
        if (_.isEmpty(this.filter.technical.id)) {
          hasFilterTechnical = true;
        }

        if (_.isEmpty(this.filter.search)) {
          searchFilter = true;
        }

        if (_.isEmpty(this.filter.routes)) {
          hasFilterRoute = true;
        }
    
        return hasFilterTechnical && searchFilter && hasFilterRoute;
      });
    },
  },

  mounted() {
    this.loadAverage();
  },

  methods: {
    async loadAverage() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/controleLeiteiro/listaMediaCBTGeometrica`,
          qs.stringify({
            data_inicio: !_.isEmpty(this.filter.month.range) ? _.first(this.filter.month.range) : moment().startOf('month').format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filter.month.range) ? _.last(this.filter.month.range) :  moment().endOf('month').format("YYYY-MM-DD"),
            status: this.filter.status
          })
        );

        this.data = _.map(data, item => {

          let reduce = _.reduce(JSON.parse(item.historico_medias_json), (acc, media) => {

            return {
              ... acc,
              [media.data]: media.cbt_geometrica_mes !== null ? parseInt(media.cbt_geometrica_mes) : 0
            };

          }, {})

          return {
            id: item.id_pessoa, // Obrigatório para o select
            nome: item.nome,
            data_ultima_visita: item.data_ultima_visita,
            codigo_produtor: item.codigo_laticinio,
            rota_principal: item.rota,
            id_produtor: item.id_pessoa,
            status: this.sanitizeStatus(item.status),
            volume: item.litros ? _.replace(parseFloat(item.litros).toFixed(2), '.', ',') : 0,
            rotas: item.rotas ? JSON.parse(item.rotas) : null,
            tecnicos: item.tecnicos ? JSON.parse(item.tecnicos) : null,
            ... reduce,
          };

        });
      } catch (e) {

        console.warn(e);

        this.$snotify.error("Oops, ocorreu um erro !", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    onMonthFilter(event) {
      this.filter.month.range = event;

      this.loadAverage();
    },

    handleHeaders() {
      const monthInit = _.first(this.filter.month.range);

      this.months = this.getLastMonths(monthInit);

      /**
       * Obj para o controle dos filtros
       */
      this.activeFilters = this.bindFilters(this.months);

      return this.months.map(data => {
        return {
          text: _.upperFirst(moment(data).format("MMMM/YY")),
          value: data,
          align: "center",
          filter: (value, search, item) => {
            if (search) {
              return JSON.stringify(Object.values(item)).toUpperCase().includes(search.toUpperCase().trim())
            }

            const filter = this.activeFilters[data];

            if (!filter) return true;

            switch (filter) {
              case ">=":
                return value >= 300;
              case "<=":
                return value <= 299;
              default:
                return true;
            }
          } };
      });

    },

    getLastMonths(month) {
      moment.locale('pt');

      let monthArray = [];
      let currentMonth = moment(month);
      for (let i = 0 ; i < 5 ; i++) {
        monthArray.push(moment(currentMonth).startOf('month').format("YYYY-MM-DD"));
        currentMonth = moment(currentMonth).subtract(1, "month");
      }

      return _.reverse(monthArray);
    },

    bindFilters(months) {
      return months.reduce((acc, val) => ({ ...acc, [val]: null }), {});
    },

    onClearFilter(month, index) {
      _.set(this.activeFilters, `${month}`, null)

      this.closeMenuColumn(index)
    },

    closeMenuColumn(index) {
      const parentMenu = this.$refs.monthMenu[index];

      parentMenu.isActive = false
    },

    onFilterColumn(month, index) {

      this.closeMenuColumn(index);
    },

    checkDateLastVisitColor(date, analisys) {

      if (_.isEmpty(date)) {
        return 'blue-grey darken-1';
      }

      const thisMonth = moment().startOf('month');
      const visitDate = moment(date);

      const isSame = visitDate.isSame(thisMonth, 'month');

      if (isSame && _.get(analisys, thisMonth.format('YYYY-MM-DD')) >= 300 || _.get(analisys, moment(_.first(this.filter.month.range)).startOf('month').format('YYYY-MM-DD')) >= 300 ) {
        return "yellow darken-3";
      }

      if (isSame) {
        return "light-green darken-3";
      }

      return 'blue-grey darken-1';
    },

    showLabelMonthVisit(month, visitMonth, analisy) {
      return moment(month).isSame(visitMonth, 'month') && analisy > 0;
    },

    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.averagesItems.map((value) => {

        return {
          Código: value.codigo_produtor,
          Produtor: value.nome,
          'Rota Principal': value.rota_principal,
          Status: value.status,
          Volume: value.volume,
          Rota: value.rota,
          Técnico: value.tecnico,
          [moment(this.months[0]).format("MMMM/YY")]: value[`${this.months[0]}`],
          [moment(this.months[1]).format("MMMM/YY")]: value[`${this.months[1]}`],
          [moment(this.months[2]).format("MMMM/YY")]: value[`${this.months[2]}`],
          [moment(this.months[3]).format("MMMM/YY")]: value[`${this.months[3]}`],
          [moment(this.months[4]).format("MMMM/YY")]: value[`${this.months[4]}`],
          'ultima Visita': !_.isEmpty(value.data_ultima_visita) ? moment(value.data_ultima_visita).format("DD/MM/YYYY ") : '',
        };
      }));

      const workbook = XLSX.utils.book_new();

      const filename = "geometrica_mensal";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    analysisChipColor(value) {

      if (_.includes([0, undefined, null], value)) {
        return 'blue-grey darken-1';
      }

      if (value > 300) {
        return 'red lighten-2';
      }

      return 'blue lighten-2' ;
    },
    sanitizeStatus(status) {
      switch (status) {
        case "APROVADO":
          return "Ativo";
        case "NEGOCIACAO":
          return "Negociação";
        case "DESVINCULADO":
          return "Desvinculado";
        case "SUSPENSO":
          return "Suspenso";
        case "LEAD":
          return "Consulta";
        case "APROVACAO":
          return "Aprovação";
        default:
          return "Ativo"
      }
    },

    scheduleVisit() {
      let start = new Date();
      start.setHours(7);
      let end = new Date();
      end.setHours(17);

      this.technicians = [
        this.filter.technical
      ];
      /**
       * Produtores pré-selecionados
       */
      const producers = this.selectedProducers.map(o => ({
        id: o.id_produtor,
        name: o.nome,
      }));
      this.selectedEvent = {
        idx: 0,
        id: null,
        name: '',
        start,
        end,
        color: 'grey darken-1',
        timed: false,
        type: [],
        person: this.filter.technical,
        producers,
      };
      this.selectedOpen = true;
    },

    closeEvent() {
      this.selectedOpen = false;
    },

    /**
     * Salva um agendamento
     * @returns {Promise<void>}
     */
    async saveEvent() {
      this.saving = true;
      try {
        // Valida o formulário
        if (!this.$refs.scheduleViewer.validateForm()) {
          return;
        }

        /**
         * Usando destructor para evitar o data biding
         */
        let selectedEvent = {
          ...this.selectedEvent,
          start: moment(this.selectedEvent.start).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(this.selectedEvent.end).format("YYYY-MM-DD HH:mm:ss"),
        };

        /**
         * Se não tiver id então é um cadastro novo
         */
        if (selectedEvent.id == null) {
          /**
           * Em novos eventos é possível selecionar multiplos produtores
           * Mas vamos replicar esses eventos para o técnico poder
           * ajustar o horário de cada visita individualmente no app
           */
          let forms = selectedEvent.producers.map(producer => ({
            ...selectedEvent,
            producer,
          }));

          await Promise.all(forms.map(async form => {
            return await this.$axios.post(`/agenda/salvaAgendamento`, form);
          }));

          /**
           * Adiciona os eventos no calendário
           */
          this.selectedEvent = {};
          this.selectedOpen = false;
        }

        this.selectedProducers = [];

        this.$snotify.success("Agendamento efetuado!", "Sucesso");

      } catch (e) {
        this.$snotify.error("Ocorreu um erro ao salvar o agendamento!", "Atenção");
        console.warn(e);
      } finally {
        this.saving = false;
      }
    },
  }
};
</script>

