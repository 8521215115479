<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Configurações de Metas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
        @change="onTabChange"
      >
        <v-tab>Histórico de Metas</v-tab>
        
        <v-tab
          :disabled="!planning.selected"
        >
          Metas
        </v-tab>

        <v-tab-item>
          <list-goals-tab
            ref="history"
            @newPlanning="onNewPlanning"
            @selectPlanning="onSelectPlanning"
          />
        </v-tab-item>
        
        <v-tab-item>
          <goal-planning-tab
            ref="planning"
            v-model="planning.id"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import ListGoalsTab from "@/Domains/Visits/Goals/Views/ListGoalsTab.vue";
import GoalPlanningTab from "@/Domains/Visits/Goals/Views/GoalPlanningTab.vue";

export default {
  components: {
    ListGoalsTab,
    GoalPlanningTab
  },

  data() {
    return {
      tab: null,
      planning: {
        selected: false,
        id: null
      }
    };
  },

  methods: {
    /**
     * @event void
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange(idx) {
      if (idx == 0 && this.$refs.history) {
        this.$refs.history.loadHistory()
      }
      this.planning.selected = false;
      this.planning.id = null;
    },

    onNewPlanning() {
      this.planning.selected = true;
      this.planning.id = null;
      this.tab = 1;
    },

    onSelectPlanning(id) {
      this.planning.selected = true;
      this.planning.id = id;
      this.tab = 1;
    },
  },
};
</script>