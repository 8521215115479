<template>
  <div>
    <v-row
      class="grey lighten-5"
    >
      <v-col
        md="2"
        class="pt-2 pb-0 pl-6 "
      >
        <v-select
          v-model="antibiotico.grupo"
          :items="ListGroup"
          placeholder="Selecionar"
          item-text="nome"
          hide-details
          dense
        />
      </v-col>
      <v-col
        :md="editable?2:3"
        class="pt-2 pb-0 "
      >
        <v-select
          v-model="antibiotico.principio_ativo"
          :items="ListPrinciple"
          placeholder="Selecionar"
          item-text="nome"
          hide-details
          dense
        />
      </v-col>
      <v-col
        md="2"
        class="pt-2 pb-0 "
      >
        <v-text-field
          v-model="antibiotico.nome_comercial"
          dense
        />
      </v-col>
      <v-col
        md="3"
        class="pt-2 pb-0 "
      >
        <v-text-field
          v-model="antibiotico.fornecedor"
          dense
        />
      </v-col>
      <v-col
        md="2"
        class="pt-2 pb-0 "
      >
        <masked-input
          v-model="antibiotico.valor_total"
          placeholder="0%"
          dense
          :mask="FloatNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col
        v-if="editable"
        md="1"
        class="pt-2 pb-0 "
      >
        <v-btn
          icon
          @click="openAlert(antibiotico), showDeleteDialog = true;"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <!-- Alert excluir linha -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false; antibioticoDelete = null"
      @keydown.esc="showDeleteDialog = false; antibioticoDelete = null"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-left text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-left black--text">
            Deseja realmente Executar a Ação ?
          </h3>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="AntibioticoDelete"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as _ from 'lodash';
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  name: "SanitationAntibiotic",
  components: {
    MaskedInput,
  },

  filters: {
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },
  props: {
    antibiotico: {
      require: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
    
  data() {
    return {
      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: ".",
            min: -999999,
            max: 999999,
          },
        },
      },
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      showDeleteDialog: false,

      ListGroup: [],

      ListPrinciple: [],
      
    };
  },

  computed: {
      
  },
  mounted() {
    this.loadGroup();
    this.loadPrinciple();
  },
  methods: {

    async loadGroup() {
      try {
        const { data } = await this.$queries.post(`/sanidade/listaAntibioticosGrupos`);

        if (!_.isObject(data)) {
          throw "PHP error: consulta grupo de antibiotico"
        }

        this.ListGroup = data;

      } catch (e) {
        console.log(e);
      }
    },

    async loadPrinciple() {
      try {
        const { data } = await this.$queries.post(`/sanidade/listaAntibioticosPrincipiosAtivos`);

        if (!_.isObject(data)) {
          throw "PHP error: consulta Principios Ativos antibiotico"
        }
        this.ListPrinciple = data;

      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Abre dialog de confirmações de deleção
     */
    openAlert(item) {
      this.antibioticoDelete = item;
      this.showDeleteDialog = true;
    },

    /**
     * @void
     */
    AntibioticoDelete() {
      this.$emit("onAntibioticoDelete", this.antibioticoDelete.id);
      this.showDeleteDialog = false;
    },
  },
}
</script>
