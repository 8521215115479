
export function geometricMean(values) {
  const res = values.map(a => Math.log(a));
  const sum = res.reduce((acc, cur) => acc + cur, 0);
  return Math.exp(sum / res.length) || 0;
}

export function round(number, scale, type = 'round') {
  const aux = Math.pow(10, scale);
  if (['ceil', 'up'].includes(type)) {
    return Math.ceil((number) * aux) / aux
  }
  if (['floor', 'down'].includes(type)) {
    return Math.ceil((number) * aux) / aux
  }
  return Math.round((number + Number.EPSILON) * aux) / aux
}

export function percentageBy(value, value2) {
  if (!value2)
    return null
  return (parseFloat(value2) * 100)  / parseFloat(value)
}

export function cnpjValidate(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove todos os caracteres não numéricos

  if (cnpj.length !== 14) {
    return false; // O CNPJ deve ter exatamente 14 dígitos
  }

  // Verifica se todos os dígitos são iguais (caso contrário, o CNPJ é inválido)
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  // Calcula os dígitos verificadores
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado.toString() !== digitos.charAt(0)) {
    return false;
  }

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado.toString() !== digitos.charAt(1)) {
    return false;
  }

  return true;
}

export function cpfValidate(cpf) {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove todos os caracteres não numéricos

  if (cpf.length !== 11) {
    return false; // O CPF deve ter exatamente 11 dígitos
  }

  // Verifica se todos os dígitos são iguais (caso contrário, o CPF é inválido)
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  const primeiroDigito = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

  // Calcula o segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  const segundoDigito = (soma % 11) < 2 ? 0 : 11 - (soma % 11);

  // Verifica se os dígitos calculados correspondem aos dígitos do CPF original
  if (
    parseInt(cpf.charAt(9)) === primeiroDigito &&
    parseInt(cpf.charAt(10)) === segundoDigito
  ) {
    return true;
  } else {
    return false;
  }
}

export function documentValidate(document) {

  if(document.length > 0) {
    const documentTemp = document.replace(/[^\d]+/g, '');

    if (documentTemp.length === 11) {

      if(cpfValidate(documentTemp)) {
        return {
          validation: true,
          msg: 'CPF válido'
        }
      }

      return {
        validation: false,
        msg: 'CPF inválido!'
      }

    } else if (documentTemp.length === 14) {

      if(cnpjValidate(documentTemp)) {
        return {
          validation: true,
          msg: 'CNPJ válido'
        }
      }

      return {
        validation: false,
        msg: 'CNPJ inválido'
      }
    } else {
      return {
        validation: true,
        msg: 'Documento inválido!'
      }
    }
  }
}
