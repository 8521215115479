<template>
  <v-card
    :width="dialog.width"
    :min-height="dialog.height"
    tile
    class="d-flex flex-column"
  >
    <v-card-title>Amostras com Erro</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-date-range-picker
            v-model="period"
            @change="loadErrorList"
          />
        </v-col>

        <v-col cols="4" />

        <v-col cols="4">
          <v-text-field
            v-model="search"
            label="Pesquisar"
            prepend-inner-icon="search"
            filled
            hide-details
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="errors"
            :search="search"
            height="530"
          >
            <template #[`item.sample`]="{ value }">
              <v-chip small>
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.problem`]="{ value }">
              <v-chip
                v-for="(item, key) in value"
                :key="key"
                small
              >
                {{ item }}
              </v-chip>
            </template>

            <template #[`item.ranAt`]="{ value }">
              <v-chip small>
                {{ dateFormat(value, 'DD/MM/YYYY HH:mm:ss') }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>repartition</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Re-processar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>delete_outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Excluir Definitivamente
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-spacer />

    <v-card-actions>
      <v-btn
        color="grey darken-1"
        text
        @click="close()"
      >
        Fechar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {
  components: {
    VDateRangePicker,
  },

  props: {
    dialog: {
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    settings: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      search: '',

      period: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      ],

      errors: [],

      headers: [
        {
          text: "Amostra",
          value: "sample",
        },
        {
          text: "Motivo",
          value: "problem",
        },
        {
          text: "Data Execução",
          value: "ranAt",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: 40,
          align: 'end',
        },
      ],

    }
  },

  computed: {
    isLoading: {
      get() {
        return this.loading;
      },
      set(loading) {
        return this.$emit('update:loading', loading);
      },
    },
  },

  methods: {
    async loadErrorList() {
      try {
        this.isLoading = true;

        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.get(`/settings/labs/dairy/${this.settings.id}/errors`, {
          params: {
            date_start: startDate,
            date_end: endDate,
          }
        });

        this.errors = data.map(error => {
          return {
            sample: error.numero_amostra,
            problem: error.motivo,
            ranAt: error.data_hora_registro,
          }
        });
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    close() {
      return this.$emit('close');
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
</script>
