var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"rota-container",attrs:{"dark":"","color":"transparent"}},[_c('v-card-title',{staticClass:"justify-center text-body-2",staticStyle:{"background":"rgba(0,0,0,.5)"}},[_c('span',[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',{staticClass:"pa-0 text-left"},[_c('draggable',_vm._b({staticClass:"produtores-grid drag-area noselect",attrs:{"list":_vm.customers,"group":{
          name: 'clientes',
        }},on:{"change":_vm.onCustomerPositionChange}},'draggable',{
          animation: 200,
          disabled: false,
          ghostClass: 'ghost',
          delay: 100,
          delayOnTouchOnly: true,
          handle: '.handle',
        },false),[_c('transition-group',{attrs:{"type":"transition"}},[_vm._l((_vm.customers),function(customer,index){return [_c('v-hover',{key:`${index}-${_vm.route.id}-${customer.id}`,class:{ remover: customer.remove },scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{staticClass:"list-item produtor-item",class:{ handle: customer.hasLocation },attrs:{"dark":"","dense":""}},[_c('v-list-item-avatar',{attrs:{"size":"16"}},[_vm._v(" "+_vm._s(_vm.listIndex(customer))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(customer.name)+" ")]),(customer.region.id)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(customer.region.description)+" ")]):_vm._e()],1),_c('v-list-item-avatar',{attrs:{"size":"18"}},[(hover && !_vm.disableDelete)?_c('v-btn',{attrs:{"x-small":"","icon":"","dark":"","color":"white"},on:{"click":function($event){return _vm.onRemoveCustomer(customer)}}},[_c('v-icon',[_vm._v("close")])],1):_c('v-btn',{attrs:{"x-small":"","icon":"","dark":"","color":"white"}},[(!customer.hasLocation)?_c('v-icon',{staticClass:"red--text"},[_vm._v(" gps_off ")]):_c('v-icon',[_vm._v(" place ")])],1)],1)],1)]}}],null,true)})]})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }