<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-form
      ref="formSort"
      v-model="validForm"
      lazy-validation
    >
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-row>
            <v-col
              md="4"
            >
              <v-autocomplete
                v-model="filters.route"
                :items="routes"
                item-text="descricao"
                item-value="id_rota"
                prepend-inner-icon="icon-rota"
                label="Rotas"
                multiple
                hide-details
                filled
              />
            </v-col>
            <v-col
              md="4"
            >
              <v-select
                v-model="filters.group"
                :items="groups"
                label="Grupo"
                item-text="descricao"
                item-value="id_configuracao_grupo_analise"
                prepend-inner-icon="list"
                filled
                :rules="[v => v.length>0 || 'Campo obrigatório']"
              />
            </v-col>

            <v-col>
              <v-btn
                v-if="sortProducers.length===0 && hasPeriodicDraw"
                class="ma-2"
                color="info"
                @click.stop="onSortProducer();"
              >
                <v-icon dark>
                  move_to_inbox
                </v-icon>
                Sortear
              </v-btn>
            </v-col>
            <v-col
              md="12"
            >
              <v-data-table
                dark
                :headers="headersProducer"
                :loading="loading"
                :items="sortProducers"
                class="diff-list-table"
                :items-per-page="-1"
              />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="blue"
        @click="loadRoutes();loadGroup(); loadPending();"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>
          Recarregar
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Exportar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import XLSX from "xlsx-js-style";

export default {
  components: {

  },
  filters: {

  },

  data() {
    return {

      validForm: true,

      loading: false,

      filters: {
        route: [],
        group: [],
      },

      hasPeriodicDraw: false,

      routes: [],

      groups: [],

      sortProducers: [],

      headersProducer: [
        {
          align: 'start',
          text: "codigo",
          value: "codigo_laticinio",
        },
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Rota",
          value: "rota",
        },
        {
          text: "Data última analise",
          value: "ultima_analise",
        },

        {
          text: "Data",
          value: "data_sorteio",
        },

      ],

    };

  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.loadRoutes();
    this.loadGroup();
    this.loadPending();
    this.loadResourcesUser();
  },
  methods: {

    /**
          * carrega os recursos que usuario tem permissao para acessar
          *
          */
    loadResourcesUser() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      const hasPeriodicDraw = recursosUsuario.find(recurso => recurso.recurso === "analysis-periodic-draw" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(hasPeriodicDraw)) {
        this.hasPeriodicDraw = false;
      } else {
        this.hasPeriodicDraw = true;
      }

    },

    async loadRoutes() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/listaRotaTipoJson`,
          this.$qs.stringify({
            tipo: 3,
          }),
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.routes = data;

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadGroup() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/configuracao/listaConfiguracaoGrupoAnalise`);

        this.groups = data.map(group => {
          return {
            id_configuracao_grupo_analise: group.id_configuracao_grupo_analise,
            descricao: group.descricao,
          }
        });

        //se tiver so um grupo ja traz selecionado
        if (this.groups.length === 1) {
          this.filters.group = this.groups[0].id_configuracao_grupo_analise;
        }

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadPending() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/analiseSorteio/listaPendente`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.sortProducers = data.map(producer => {
          return {
            codigo_laticinio: producer.codigo_laticinio,
            nome: producer.nome,
            rota: producer.rota,
            ultima_analise: producer.ultima_analise ?  moment(producer.ultima_analise).format("DD/MM/YYYY") : '',
            data_sorteio: producer.data_sorteio ? moment(producer.data_sorteio).format("DD/MM/YYYY") : '',

          }
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao sortear!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async onSortProducer() {
      if (!await this.$refs.formSort.validate()) {
        return;
      }

      try {
        this.loading = true;

        const request = {
          grupo: this.filters.group,
          rota: JSON.stringify(this.filters.route),
        };

        const { data } = await this.$axios.post(
          `/analiseSorteio/sortear`,
          this.$qs.stringify({
            ...request,
          }),
        );

        if (!_.isObject(data)) {
          this.$snotify.error("Oops, ocorreu um erro ao sortear!", "Atenção");
        }

        this.sortProducers = data;

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao sortear!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.sortProducers.map((item) => {

        return {
          Codigo: _.isEmpty(item.codigo_laticinio) ? '--' : item.codigo_laticinio,
          Produtor: item.nome,
          Rota: _.isEmpty(item.rota) ? '--' : item.rota,
          Analise: _.isEmpty(item.ultima_analise) ? '' : item.ultima_analise,
          Sorteio: _.isEmpty(item.data_sorteio) ? '' : item.data_sorteio,

        };
      }));

      const workbook = XLSX.utils.book_new();

      const filename = "analises_produtores";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

  },

}
</script>
