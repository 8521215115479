<template>
  <masked-input
    v-model="input"
    :mask="CEPMask"
    label="CEP"
    max-length="9"
    placeholder=""
    v-bind="$attrs"
    @input="onCep"
  />
</template>

<script>
import MaskedInput from "@/Support/Components/MaskedTextField.vue";

export default {
  name: 'zip-code-input',

  components: {
    MaskedInput
  },

  props: {
    value: String,
  },

  data() {
    return {
      input: "",

      CEPMask: {
        mask: "00000-000",
      },
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  created() {
    this.input = this.value;
  },

  methods: {
    async onCep(value) {
      const currentCep = (this.value || '').replace(/[^\d]+/g, '').trim();

      this.$emit("input", value);
      if (!value) {
        return;
      }

      const cep = value.replace(/[^\d]+/g, '').trim();

      if (currentCep === cep) {
        return;
      }

      if (cep.length == 8) {
        try {
          const { data } = await this.$partners.get(`https://viacep.com.br/ws/${cep}/json/`);

          this.$emit("address", data);
        } catch (error) {
          console.warn(error);
        }
      }
    },
  },
};
</script>
