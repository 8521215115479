<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        {{ product.name }}
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        {{ form.id ? 'Edição de dados do estoque' : 'Cadastro de estoque' }}
      </v-card-subtitle>
      <v-divider />
      <v-card-text
        class="pt-5"
      >
        <v-form
          ref="formRef"
          lazy-validation
          @submit="save"
        >
          <v-row>
            <v-col
              v-if="form.lotNumber || !form.id"
              class="pb-0"
            >
              <v-text-field
                v-model="form.lotNumber"
                label="Lote"
                :disabled="!!form.id"
                persistent-placeholder
                :rules="form.id ? [] : [v => !!v || 'Campo obrigatório!']"
              >
                <template #append>
                  <v-tooltip
                    v-if="!form.lotNumber && product?.lotFormat"
                    top
                  >
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        @click="generateLotNumber()"
                      >
                        <v-icon small>
                          autorenew
                        </v-icon>
                      </v-btn>
                    </template>
                    Gerar Lote
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="pb-0">
              <masked-text-field
                v-if="!orderId"
                v-model="form.quantity"
                label="Qtde. Estoque"
                :disabled="quantityDisabled"
                :mask="masks.float"
                unmask
                inputmode="numeric"
                :suffix="product.measurement"
                :rules="[v => !!v || 'Campo obrigatório!']"
                validate-on-blur
              />
            </v-col>
            <v-col
              v-if="!hidePrice"
              class="pb-0"
            >
              <money-input
                v-model="form.price"
                label="Preço Unitário"
                persistent-placeholder
              />
            </v-col>
          </v-row>
          <v-row v-if="form.lotNumber || !form.id">
            <v-col class="pb-0">
              <v-text-field
                v-model="form.manufacturingDate"
                label="Fabricação"
                type="date"
                persistent-placeholder
                :disabled="!form.lotNumber"
                @input="setExpirationDate()"
              />
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                v-model="form.expirationDate"
                label="Validade"
                type="date"
                persistent-placeholder
                :disabled="!form.lotNumber"
              />
            </v-col>
            <v-col
              v-if="form.id"
              class="pb-0"
            >
              <v-select
                v-model="form.lotStatus"
                :items="[
                  { text: 'LIBERADO', value: 'LIBERADO' },
                  { text: 'RESTRITO', value: 'RESTRITO' },
                  { text: 'SUSPENSO', value: 'SUSPENSO' },
                ]"
                label="Status Lote"
                clearable
                persistent-placeholder
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'

import moment from 'moment'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import MoneyInput from '@/Support/Components/MoneyInput.vue'

import productionOrderApi from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js'

const { progressBar, notify } = useUtils()

const masks = {
  float: { mask: Number, scale: 4, signed: true },
}

// eslint-disable-next-line
const props = defineProps({
  quantityDisabled: Boolean,
  orderId: String,
  hidePrice: Boolean
})

// eslint-disable-next-line
const emit = defineEmits([
  'save',
])

const dialog =  ref(false)

const formRef = ref()

const form = reactive({
  id: null,
  lotNumber: null,
  manufacturingDate: null,
  expirationDate: null,
  quantity: null,
  price: null,
  lotStatus: null,
})
const product =  ref({})

const close = () => {
  form.id = null
  form.lotNumber = null
  form.manufacturingDate = null
  form.expirationDate = null
  form.quantity = null
  form.price = null
  form.lotStatus = null

  product.value = {}

  formRef.value?.resetValidation()

  dialog.value = false
}

const show = async ({ itemId, stockId, defaultLotNumber }) => {
  dialog.value = true

  if (stockId) {
    await loadStock(stockId)
  } else if (itemId) {
    await loadProduct(itemId)

    form.lotNumber = defaultLotNumber
    form.manufacturingDate = moment().format('YYYY-MM-DD')

    setExpirationDate()
  } else {
    close()
  }

  formRef.value?.resetValidation()
}

const loadStock = async (id) => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`/stock/${id}`)

    form.id = id
    form.lotNumber = data.numero_lote
    form.manufacturingDate = data.data_fabricacao
    form.expirationDate = data.data_validade
    form.quantity = data.quantidade
    form.price = data.valor
    form.lotStatus = data.status_lote

    product.value = {
      id: data.id_item,
      name: data.item?.nome,
      measurement: data.item?.unidade,
      expirationDays: data.item?.parametros_producao?.prazos_validade?.[store.state.settings?.laticinio?.id],
      lotFormat: data.item?.parametros_producao?.formato_lote,
    }

  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
    close()
  } finally {
    progressBar?.hide()
  }
}

const loadProduct = async (id) => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`/item/${id}`)

    product.value = {
      id: data.id_item,
      name: data.nome,
      measurement: data.unidade,
      expirationDays: data.parametros_producao?.prazos_validade?.[store.state.settings?.laticinio?.id],
      lotFormat: data.parametros_producao?.formato_lote,
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
    close()
  } finally {
    progressBar?.hide()
  }
}

const save = async () => {
  if (!await formRef.value?.validate()) {
    return
  }

  try {
    progressBar?.saving()

    if (form.id) {
      const payload = {
        data_fabricacao: form.manufacturingDate,
        data_validade: form.expirationDate,
        status_lote: form.lotStatus,
        quantidade: form.quantity,
        valor: form.price,
      }

      await axios.put(`/stock/${form.id}`, payload)

      emit('save')
    } else {
      const quantidade = Math.abs(form.quantity)
      const payload = {
        id_item: product.value.id,
        quantidade,
        tipo: form.quantity >= 0 ? 'ENTRADA' : 'SAIDA',
        lotes: [
          {
            numero: form.lotNumber,
            data_fabricacao: form.manufacturingDate,
            data_validade: form.expirationDate,
            quantidade,
          }
        ],
      }

      if (props.orderId) {
        payload.id_origem = props.orderId
        payload.quantidade = 0
        payload.lotes[0].quantidade = 0
      }

      const { data } = await axios.post(`/stock`, payload)

      emit('save', data.stock)
    }

    close()

    notify.success('Estoque salvo com sucesso', 'Sucesso')
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao salvar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

const setExpirationDate = () => {
  const { expirationDays } = product.value

  if (!expirationDays) {
    return
  }

  if (!form.manufacturingDate) {
    form.expirationDate = null
    return
  }

  form.expirationDate = moment(form.manufacturingDate).add(expirationDays, 'days').format('YYYY-MM-DD')
}

// Usado apenas em Ordem de Produção
const generateLotNumber = async () => {
  try {
    progressBar.saving()

    const { data } = await productionOrderApi.generateLot({
      id_item: product.value.id,
      id_ordem_producao: props.orderId,
    })

    form.lotNumber = data.numero_lote
    form.manufacturingDate = data.data_fabricacao

    setExpirationDate()

    notify.success('Lote gerado com sucesso', 'Sucesso')
  } catch (e) {
    const message = e?.response?.data?.message || 'Erro ao gerar lote'
    notify.error(message, 'Atenção')
    console.warn(e)
  } finally {
    progressBar.hide()
  }
}

// eslint-disable-next-line
defineExpose({
  show
})
</script>
