<template>
  <div>
    <v-row no-gutters>
      <v-col
        cols="12"
        md="4"
        class="pt-1"
      >
        <warehouse-select
          v-model="warehouseId"
          label="Depósito"
          filled
          dark
          hide-details
          auto-select-default
        />
      </v-col>

      <v-spacer />

      <v-col
        cols="12"
        md="4"
        class="pt-1 text-right"
      >
        <v-btn
          dark
          large
          color="blue lighten-1"
          @click="addStorage"
        >
          <v-icon left>
            add
          </v-icon>
          Cadastrar armazenamento
        </v-btn>
      </v-col>

      <v-col cols="12">
        <div class="grid-container">
          <div
            v-for="(storage, idx) in storages"
            :key="storage.id"
            class="grid-row"
            :class="{ remove: !!storage.remove }"
          >
            <v-card
              color="transparent"
              dark
              class="mb-4"
            >
              <v-hover #default="{ hover }">
                <v-card-title
                  class="text-subtitle-2 pt-2 pb-2"
                  style="background: rgba(0, 0, 0, 0.5);"
                >
                  <template>
                    <span>
                      {{ storage.description }}
                    </span>

                    <v-spacer />

                    <v-btn
                      v-show="hover"
                      icon
                      @click="editStorage(storage.id)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>

                    <v-menu
                      bottom
                      right
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                        >
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          :disabled="storage.pallets.length > 0"
                          @click="removeStorage(idx)"
                        >
                          <v-list-item-icon>
                            <v-icon>delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Excluir</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-card-title>
              </v-hover>

              <v-card-text
                class="pa-0"
                style="background: rgba(0, 0, 0, 0.3);"
              >
                <v-row
                  class="text-body-2 pt-1 pb-1 ma-0"
                  align="center"
                  justify="center"
                >
                  <v-col cols="4">
                    Pallets: {{ storage.pallets.length }}
                  </v-col>

                  <v-col cols="4">
                    Itens: {{ storage.pallets.reduce((acc, cur) => (acc + cur.stocks.length), 0) }}
                  </v-col>

                  <v-col
                    cols="4"
                    class="text-right"
                  >
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          @click="addBox(idx)"
                        >
                          <v-icon>add</v-icon>
                        </v-btn>
                      </template>

                      Adicionar pallet
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <draggable
              class="storage-grid storage-bg noselect px-2 pt-2"
              v-bind="{
                animation: 200,
                disabled: false,
                ghostClass: 'ghost',
                delay: 100,
                delayOnTouchOnly: true,
                handle: '.handle',
              }"
              :list="storage.pallets"
              :group="{ name: 'storage' }"
            >
              <transition-group type="transition">
                <template v-for="(pallet, index) in storage.pallets">
                  <v-card
                    :key="`${index}-${storage.id}-${pallet.id}`"
                    dark
                    color="transparent"
                    class="mb-2"
                  >
                    <v-hover #default="{ hover }">
                      <v-card-title
                        class="handle text-subtitle-2 pt-2 pb-2"
                        style="background: rgba(0,0,0,.5);"
                      >
                        <template>
                          <span>
                            Pallet: {{ pallet.description }}
                          </span>

                          <v-spacer />

                          <v-tooltip
                            v-if="hover"
                            top
                          >
                            <template #activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="editPallet(idx, index)"
                              >
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </template>

                            Editar Pallet
                          </v-tooltip>

                          <v-menu
                            bottom
                            right
                          >
                            <template #activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                              >
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item
                                @click="removePallet(idx, index)"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Excluir</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template>
                      </v-card-title>
                    </v-hover>
                    <v-card-text class="pa-0 text-left">
                      <v-list-item
                        v-for="(item, index) in pallet.stocks"
                        :key="`${index}-${item.id}`"
                        dark
                        dense
                        class="storage-item"
                      >
                        <v-list-item-icon style="width:60px;">
                          {{ index + 1 }}
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }} <span class="mx-1 caption font-weight-light">({{ item.lotNumber || 'SEM LOTE' }})</span>
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            <template v-if="item.code">
                              Código: <span class="white--text">{{ item.code }}</span> -
                            </template>
                            <template>
                              Quantidade: <span class="white--text">{{ formatNumber(item.quantity) }} {{ item.measurement }}</span>
                            </template>
                            <template v-if="item.barcode">
                              - Cód. Barras: <span class="white--text">{{ item.barcode }}</span>
                            </template>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                    <v-card-actions>
                      <v-row
                        class="text-body-2 pt-1 pb-1 ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-col>
                          Peso Bruto: <b>{{ formatNumber(pallet.grossWeight) }} Kg</b>
                        </v-col>

                        <v-col>
                          Peso Líquido: <b>{{ formatNumber(pallet.netWeight) }} Kg</b>
                        </v-col>

                        <v-col>
                          Cód. Barras: <b>{{ pallet.barcode }}</b>
                        </v-col>

                        <v-col cols="12">
                          {{ pallet.details }}
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </template>
              </transition-group>
            </draggable>
          </div>
        </div>
      </v-col>
    </v-row>

    <StorageDialog
      ref="storageDialog"
      :warehouse-id="warehouseId"
      @save="loadStorages"
    />

    <PalletDialog
      ref="palletDialog"
      :warehouse-id="warehouseId"
      @save="loadStorages"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click="save()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar</span>
    </v-tooltip>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<style lang="scss">
.grid-container {
  display: grid;
  grid-column-gap: 12px;
  overflow: auto;
  grid-template-columns: repeat(auto-fit,minmax(220px,1fr));

  .grid-row {
    grid-row: 1;
    min-width: 220px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: table-cell;
    margin: 0;
    position: relative;
    vertical-align: top;
  }

  .remove {
    display: none;
  }
}

.storage-grid {
  display: flex;

  .handle {
    cursor: grab;
  }

  &.storage-bg {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.3);
    &>span {
      min-height: 220px;
    }
  }

  .storage-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &>span {
    display: inline-block;
    width: 100%;
    min-height: 100px * $scale;

    &:empty:before {
      width: 100%;
      display: inline-block;
      padding: (35px * $scale) (16px * $scale);
      content: "Vazio";
      text-overflow: ellipsis;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  > .ghost {
    opacity: 0.3;
  }
}
</style>

<script>
import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue'
import StorageDialog from '@/Domains/Shipment/StorageControl/Components/StorageDialog.vue'
import PalletDialog from '@/Domains/Shipment/StorageControl/Components/PalletDialog.vue'
import Draggable from 'vuedraggable'

import { uniq } from 'lodash'

export default {
  components: {
    WarehouseSelect,
    StorageDialog,
    PalletDialog,
    Draggable,
  },

  data() {
    return {
      loading: false,

      warehouseId: null,

      storages: [],

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        positive: v => v > 0 || 'Valor inválido',
      },
    };
  },

  mounted() {
    this.loadStorages();
  },

  methods: {
    async loadStorages() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get('storage', { params: {
          id_deposito: this.warehouseId,
        } });

        this.storages = data.map(storage => ({
          id: storage.id,
          description: storage.descricao,
          pallets: storage.pallets.map(pallet => ({
            id: pallet.id,
            description: pallet.descricao,
            palletTare: pallet.tara_pallet,
            stretchTare: pallet.tara_stretch,
            tareWeight: pallet.tara_embalagem,
            grossWeight: pallet.peso_bruto,
            netWeight: pallet.peso_bruto - ((pallet.tara_pallet) + pallet.tara_stretch + pallet.tara_embalagem),
            barcode: pallet.codigo_barras,
            details: uniq(pallet.estoques.map(stock => `${stock.item?.cod_item || ''} - ${stock.item?.nome} - Lote: ${stock.estoque?.numero_lote || 'SEM LOTE'}`))
              .join(', '),
            stocks: pallet.estoques.map(stock => ({
              id: stock.id,
              stockId: stock.id_estoque,
              quantity: stock.quantidade,
              lotNumber: stock.estoque?.numero_lote,
              measurement: stock.item?.unidade,
              code: stock.item?.cod_item,
              name: stock.item?.nome,
              query: `${stock.estoque?.numero_lote || ''} - ${stock.item?.cod_item || ''} - ${stock.item?.nome}`,
              barcode: stock.codigo_barras,
            }))
          }))
        }))

      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao carregar os armazenamentos!', 'Atenção');
      } finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        this.loading = true;

        const payload = {
          id_deposito: this.warehouseId,
          armazenamentos: this.storages.map(storage => ({
            id: storage.id,
            descricao: storage.description,
            pallets: storage.pallets.map(pallet => pallet.id)
          }))
        };

        await this.$axios.post('storage', payload);

        this.loadStorages();
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao salvar!', 'Atenção');
        this.loading = false;
      }
    },

    addBox(idx) {
      const id = this.storages[idx].id;
      this.$refs.palletDialog.show({
        storageId: id
      });
    },

    async removePallet(col, row) {
      const pallet = this.storages[col].pallets[row];

      if (!(await this.$root.$confirm(
        `Remover Pallet`,
        `Deseja realmente remover o Pallet: <b>${pallet.description}</b>?<br><br>Esta ação não poderá ser desfeita!<br><br>`,
        { color: 'red' }
      ))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`pallet/${pallet.id}`);

        this.storages[col].pallets.splice(row, 1);

        this.$snotify.success('Pallet removido com sucesso');
      } catch (error) {
        this.$snotify.error(`Erro ao remover pallet. ${error}`, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async editPallet(col, row) {
      const storageId = this.storages[col].id;
      const pallet = this.storages[col].pallets[row];

      this.$refs.palletDialog.show({
        id: pallet.id,
        storageId,
      });
    },

    addStorage() {
      this.$refs.storageDialog.show();
    },

    async removeStorage(idx) {
      const storage = this.storages[idx];

      if (!(await this.$root.$confirm(
        `Remover armazenamento`,
        `Deseja realmente remover o armazenamento: <b>${storage.description}</b>?<br><br>Esta ação não poderá ser desfeita!<br><br>`,
        { color: 'red' }
      ))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`storage/${storage.id}`);

        this.storages.splice(idx, 1, {
          ...storage,
          remove: !storage.remove,
        });

        this.$snotify.success('Armazenamento removido com sucesso');
      } catch (error) {
        this.$snotify.error(`Erro ao remover armazenamento. ${error}`, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async editStorage(id) {
      this.$refs.storageDialog.show(id);
    },

    disableDot: (e) => [45, 46, 101].includes(e.charCode) && e.preventDefault(),
    formatNumber: (value) => !value ? '-' : new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
