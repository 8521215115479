<template>
  <div>
    <v-autocomplete
      v-model="input"
      :loading="loading"
      :items="items"
      prepend-inner-icon="map"
      multiple
      dark
      label="Regiões"
      item-text="description"
      item-value="id"
      return-object
      hide-details
      filled
      chips
      small-chips
      deletable-chips
      disabled
      readonly
    >
      <template v-slot:selection="{ attr, on, item, selected }" />
    </v-autocomplete>

    <v-list
      v-if="input.length > 0"
      flat
      dark
      dense
      style="background-color: rgba(255, 255, 255, 0.08); max-height: 190px;"
      class="text-left overflow-y-auto"
    >
      <v-list-item-group>
        <v-list-item
          v-for="item in input"
          :key="item.id"
          class="pl-3"
          style="opacity: 0.5"
        >
          <v-list-item-icon class="ml-0 mr-3">
            <v-icon>
              map
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.description | uppercase }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  filters: {
    uppercase(value) {
      return (value || "").toUpperCase();
    },
  },
  props: {
    value: Array,
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // Items do Formulário (Autocomplete)
      items: [],
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/registrations/region`);

        this.items = data.map(item => ({
          id: item.id_regiao,
          description: item.descricao,
        }));
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
