<template>
  <v-autocomplete
    v-model="input"
    :loading="loading"
    :items="items"
    :label="label"
    item-text="description"
    item-value="id"
    :prepend-inner-icon="prependInnerIcon"
    :disabled="disabled || items.length === 1"
    v-bind="$attrs"
    @change="$emit('change', $event)"
  />
</template>

<script>

export default {

  props: {

    value: {
      type: [ String, Object ],
    },

    label: {
      type: String,
      default: 'Matéria Prima'
    },

    prependInnerIcon: {
      type: String,
      default: 'category',
    },

    disabled: Boolean,

    onlyParents: Boolean,

    autoSelectRawMilk: {
      type: Boolean,
    }
  },

  data() {
    return {
      loading: false,
      items: [],
      search: ''
    }
  },

  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  created() {
    this.loadRawMaterials();
  },

  methods: {
    async loadRawMaterials() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: this.onlyParents ? 'SILO' : 'TODOS'
        } });

        this.items = data
          .map(item => ({
            id: item.id_item,
            name: item.nome,
            code: item.cod_item,
            description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
            collectStandard: item.padrao_coleta,
            rawMaterialGroupId: item.id_agrupador_materia_prima,
          }));

        if (this.autoSelectRawMilk) {
          const rawProduct = this.items.find(item => item.collectStandard);

          this.$emit('input', rawProduct.id);
        }

      } catch (error) {
        console.warn(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as matérias primas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
