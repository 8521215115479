<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Relatório de diferenças de bonificações
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab>Bonificações x Coletas</v-tab>

        <v-tab-item>
          <bonus-vs-collects-tab />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import BonusVsCollectsTab from './Views/BonusVsCollectsTab.vue';

export default {
  components: {
    BonusVsCollectsTab,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>
