<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Condomínios
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <Grouping type="CONDOMINIUM" />
  </v-container>
</template>

<script>
import Grouping from '@/Domains/Registrations/Person/Components/Grouping.vue';

export default {
  name: 'condominium-registration',

  components: {
    Grouping
  },
};
</script>
