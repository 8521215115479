<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <v-select
        v-model="form.tributacao"
        :items="cst"
        label="Situação Tributária (CST) *"
        prepend-inner-icon="tag"
        :rules="[v => !!v || 'Campo obrigatório!']"
      />
    </v-col>
    <v-col
      v-if="showAliquotaEmReais"
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <money-input
        v-model="form.aliquota"
        label="Alíquota"
        prefix="R$"
      />
    </v-col>
    <template v-else-if="showAliquota">
      <v-col
        v-if="showAliquota"
        cols="12"
        sm="6"
        md="4"
        class="pb-0"
      >
        <masked-text-field
          v-model="form.aliquota"
          label="Alíquota"
          :mask="masks.percentual"
          unmask
          prepend-inner-icon="percent"
        />
      </v-col>
      <v-col
        v-if="showAliquota"
        cols="12"
        sm="6"
        md="4"
        class="pb-0"
      >
        <masked-text-field
          v-model="form.base"
          label="Base"
          :mask="masks.percentual"
          unmask
          prepend-inner-icon="percent"
        />
      </v-col>
    </template>
    <template v-if="showValores && (showAliquota || showAliquotaEmReais)">
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pb-0"
      >
        <money-input
          v-model="form.valor_base"
          label="Valor base"
          prefix="R$"
          :disabled="autocalculation"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pb-0"
      >
        <money-input
          v-model="form.valor"
          label="Valor"
          prefix="R$"
          :disabled="autocalculation"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

export default {

  components: {
    MaskedTextField,
    MoneyInput,
  },

  props: {
    data: Object,
    showValores: Boolean,
    autocalculation: Boolean,
  },

  data() {
    return {
      masks: {
        percentual: { mask: Number, min: 0, max: 100, scale: 4 }
      },

      cst: [
        { value: '01', text: '01 - Operação Tributável com Alíquota Básica' },
        { value: '02', text: '02 - Operação Tributável com Alíquota Diferenciada' },
        { value: '03', text: '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto' },
        { value: '04', text: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero' },
        { value: '05', text: '05 - Operação Tributável por Substituição Tributária' },
        { value: '06', text: '06 - Operação Tributável a Alíquota Zero' },
        { value: '07', text: '07 - Operação Isenta da Contribuição' },
        { value: '08', text: '08 - Operação sem Incidência da Contribuição' },
        { value: '09', text: '09 - Operação com Suspensão da Contribuição' },
        { value: '49', text: '49 - Outras Operações de Saída' },
        { value: '50', text: '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno' },
        { value: '51', text: '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno' },
        { value: '52', text: '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação' },
        { value: '53', text: '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno' },
        { value: '54', text: '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno' },
        { value: '55', text: '55 - Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação' },
        { value: '56', text: '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação' },
        { value: '60', text: '60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno' },
        { value: '61', text: '61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno' },
        { value: '62', text: '62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação' },
        { value: '63', text: '63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno' },
        { value: '64', text: '64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação' },
        { value: '65', text: '65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação' },
        { value: '66', text: '66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação' },
        { value: '67', text: '67 - Crédito Presumido - Outras Operações' },
        { value: '70', text: '70 - Operação de Aquisição sem Direito a Crédito' },
        { value: '71', text: '71 - Operação de Aquisição com Isenção' },
        { value: '72', text: '72 - Operação de Aquisição com Suspensão' },
        { value: '73', text: '73 - Operação de Aquisição a Alíquota Zero' },
        { value: '74', text: '74 - Operação de Aquisição sem Incidência da Contribuição' },
        { value: '75', text: '75 - Operação de Aquisição por Substituição Tributária' },
        { value: '98', text: '98 - Outras Operações de Entrada' },
        { value: '99', text: '99 - Outras Operações' },
      ]
    }
  },

  computed: {
    form: {
      get() {
        return this.data;
      },

      set(newValue) {
        return this.$emit("update:data", newValue);
      },
    },

    showAliquotaEmReais() {
      return this.form.tributacao === '03';
    },

    showAliquota() {
      return !['04', '06', '07', '08', '09'].includes(this.form.tributacao)
    }
  },

}
</script>
