<template>
  <div class="mx-6 px-md-16">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Carga/Descarga
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
          @change="onTabChange"
        >
          <v-tab href="#tab-waiting">
            Aguardando
          </v-tab>

          <v-tab href="#tab-discharged">
            Finalizados
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          touchless
          style="background: transparent;"
        >
          <v-tab-item value="tab-waiting">
            <unload-waiting-tab
              v-if="tab === 'tab-waiting'"
              @onUnloadProcessing="onUnloadProcessing"
            />
          </v-tab-item>

          <v-tab-item value="tab-discharged">
            <finished-unloads-tab
              v-if="tab === 'tab-discharged'"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UnloadWaitingTab from "@/Domains/Platform/Unload/Views/UnloadWaitingTab.vue";
import FinishedUnloadsTab from "@/Domains/Platform/Unload/Views/FinishedUnloadsTab.vue";

export default {
  name: "platform-unload",

  components: {
    FinishedUnloadsTab,
    UnloadWaitingTab,
  },

  data() {
    return {
      tab: "tab-waiting",

      processing: {},
    };
  },

  methods: {

    /**
     * @event void
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange() {
      this.processing = {};
    },

    onUnloadProcessing(item) {
      this.tab = 'tab-processing';
      this.processing = item;
    },

    onFinishedUnload() {
      this.tab = 'tab-waiting';
      this.processing = {};
    },
  },
};
</script>
