<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />

      <v-col cols="4">
        <v-autocomplete
          v-model="filter.products"
          :items="items"
          label="Produto"
          :rules="[v => !!v || 'Campo obrigatório!']"
          item-value="id"
          item-text="description"
          filled
          multiple
          clearable
          hide-details
        >
          <template #selection="{ item }">
            {{ item.description }}
            <div class="mx-1 caption font-weight-light">
              ({{ getType(item.type) }})
            </div>
          </template>
          <template #item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.description }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-chip
                  x-small
                  :color="getTypeColor(item.type)"
                  text-color="white"
                >
                  {{ getType(item.type) }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="4">
        <VDateRangePicker
          v-model="filter.date"
          :ranges="filter.dateRanges"
          dark
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <production-totals-card
          :period="filter.date"
          :products="filter.products"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="8"
      >
        <conversion-rate-chart
          :period="filter.date"
          :products="filter.products"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <order-status-chart
          :period="filter.date"
          :products="filter.products"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';

import ProductionTotalsCard from './Components/ProductionTotalsCard.vue';
import ConversionRateChart from './Components/ConversionRateChart.vue';
import OrderStatusChart from './Components/OrderStatusChart.vue';

import moment from 'moment';

export default {
  name: 'ProductionOrderIndicators',

  components: {
    VDateRangePicker,
    ProductionTotalsCard,
    ConversionRateChart,
    OrderStatusChart,
  },

  data() {
    return {
      filter: {
        dateRanges: {
          'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
          'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
        },
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],

        products: [],
      },
      items: [],
    };
  },

  created() {
    this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/item`, { params: { tipos_item: ['PRODUCAO'] } });

        this.items = data.map(item => ({
          id: item.id_item,
          code: item.cod_item,
          name: item.nome,
          description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
          type: item.tipo,
        }));
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    getType(value) {
      const types = {
        'PRODUTO_ACABADO': 'PRODUTO ACABADO',
        'MATERIA_PRIMA': 'MATÉRIA PRIMA',
        'INSUMO_PRODUCAO': 'INSUMO DE PRODUÇÃO',
        'INSUMO_AGRICOLA': 'INSUMO AGRÍCOLA',
      };

      return types[value] || value;
    },

    getTypeColor(value) {
      const statusColors = {
        'PRODUTO_ACABADO': 'blue',
        'MATERIA_PRIMA': 'orange darken-1',
        'INSUMO_PRODUCAO': 'green darken-1',
      }
      return statusColors[value] || 'grey'
    },
  }
};
</script>
