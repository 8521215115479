<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :max-width="1100"
  >
    <v-card>
      <v-card-title>
        Informar lotes {{ type === 'ENTRADA' ? 'e validades' : '' }} de produtos
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-form
          ref="formRef"
          style="max-height: calc(100vh - 250px);"
          @submit="save"
        >
          <v-simple-table
            dense
          >
            <template #default>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th :width="100">
                    Quantidade
                  </th>
                  <th>Lote</th>
                  <th>Fabricação</th>
                  <th>Validade</th>
                  <th v-if="showSupplier">
                    Fornecedor
                  </th>
                  <th>Qtde. do Lote</th>
                  <th :width="30" />
                </tr>
              </thead>

              <tbody>
                <template v-for="(item, itemIdx) in products">
                  <tr
                    v-for="(lote, idx) in item.lotes"
                    :key="`${itemIdx}-${idx}`"
                  >
                    <td>
                      <span v-if="idx === 0">
                        {{ item.nome }}
                        <span
                          v-if="!!item.codigo"
                          class="text-body-2 grey--text d-block"
                        >
                          {{ item.codigo }}
                        </span>
                      </span>
                    </td>
                    <td>
                      <masked-text-field
                        v-if="idx === 0"
                        :value="item.quantidade"
                        :mask="masks.float"
                        unmask
                        readonly
                        dense
                        :suffix="item.unidade"
                        class="mt-4"
                        :rules="getQuantityRules(itemIdx)"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-if="type === 'ENTRADA' || lote.lote_manual"
                        v-model="lote.numero"
                        label="Lote"
                        outlined
                        dense
                        class="mt-4"
                        :rules="[rules.required]"
                        :append-outer-icon="(type === 'SAIDA' && item.lote_manual) ? 'edit' : undefined"
                        @click:append-outer="(type === 'SAIDA' && item.lote_manual) ? lote.lote_manual = !lote.lote_manual : undefined"
                        @blur="checkIfHasPersistedLots(item.id_item, itemIdx, idx)"
                      />
                      <v-select
                        v-else-if="type === 'SAIDA'"
                        v-model="lote.lote"
                        :items="getPersistedLots(item.id_item, itemIdx, idx)"
                        label="Lote"
                        item-text="text"
                        item-value="numero_lote"
                        return-object
                        outlined
                        dense
                        class="mt-4"
                        :rules="[
                          v => !!v.text || 'Campo obrigatório!',
                          v => item.lote_manual || v.quantidade >= lote.quantidade || `Quantidade indisponível (${v.quantidade} disp.)`,
                        ]"
                        :append-outer-icon="item.lote_manual ? 'edit' : undefined"
                        @click:append-outer="item.lote_manual ? lote.lote_manual = !lote.lote_manual : undefined"
                      />
                    </td>
                    <td>
                      <date-text-field
                        v-if="type === 'ENTRADA' || lote.lote_manual"
                        v-model="lote.data_fabricacao"
                        label="Fabricação"
                        manual
                        outlined
                        dense
                        class="mt-4"
                        :disabled="lote.lote_existente"
                        :rules="[
                          rules.date,
                          v => validateRequiredDate(v, item, lote),
                        ]"
                        @input="setExpirationDate(itemIdx, idx)"
                      />
                      <date-text-field
                        v-else-if="type === 'SAIDA'"
                        :value="lote.lote?.data_fabricacao"
                        label="Fabricação"
                        manual
                        max=""
                        outlined
                        disabled
                        dense
                        class="mt-4"
                      />
                    </td>
                    <td>
                      <date-text-field
                        v-if="type === 'ENTRADA' || lote.lote_manual"
                        v-model="lote.data_validade"
                        label="Validade"
                        manual
                        max=""
                        outlined
                        dense
                        class="mt-4"
                        :disabled="lote.lote_existente"
                        :rules="[
                          rules.date,
                          v => validateRequiredDate(v, item, lote),
                        ]"
                      />
                      <date-text-field
                        v-else-if="type === 'SAIDA'"
                        :value="lote.lote?.data_validade"
                        label="Validade"
                        manual
                        max=""
                        outlined
                        disabled
                        dense
                        class="mt-4"
                      />
                    </td>
                    <td v-if="showSupplier">
                      <template v-if="type === 'ENTRADA' || lote.lote_manual">
                        <person-autocomplete-filter
                          v-if="!lote.fornecedor_manual"
                          v-model="lote.fornecedor"
                          label="Fornecedor"
                          type="COMMERCIAL"
                          :prepend-inner-icon="null"
                          :hide-details="false"
                          :filled="false"
                          outlined
                          dense
                          class="mt-4"
                          append-outer-icon="edit"
                          :disabled="lote.lote_existente"
                          @click:append-outer="lote.fornecedor_manual = !lote.fornecedor_manual"
                        />
                        <v-text-field
                          v-else
                          v-model="lote.fornecedor.name"
                          label="Fornecedor"
                          outlined
                          dense
                          class="mt-4"
                          append-outer-icon="edit"
                          :disabled="lote.lote_existente"
                          @click:append-outer="lote.fornecedor_manual = !lote.fornecedor_manual"
                        />
                      </template>
                      <v-text-field
                        v-else-if="type === 'SAIDA'"
                        :value="lote.lote?.nome_fornecedor"
                        label="Fornecedor"
                        readonly
                        dense
                        class="mt-4"
                      />
                    </td>
                    <td>
                      <masked-text-field
                        v-model="lote.quantidade"
                        label="Qtde. do Lote"
                        :mask="masks.float"
                        unmask
                        outlined
                        dense
                        :suffix="item.unidade"
                        class="mt-4"
                        :rules="[rules.required]"
                      />
                    </td>
                    <td>
                      <v-menu
                        bottom
                        right
                      >
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            @click="addLot(itemIdx)"
                          >
                            <v-list-item-icon>
                              <v-icon>
                                add
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Informar outro lote</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="item.lotes.length > 1"
                            @click="removeLot(itemIdx, idx)"
                          >
                            <v-list-item-icon>
                              <v-icon>
                                delete
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Remover lote</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import DateTextField from '@/Support/Components/DateTextField.vue';
import { useUtils } from '@/Support/Composables/utils.js';
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js';
import axios from '@/Support/Resources/axios-instance.js';
import store from '@/Support/Resources/vuex.js'

import moment from 'moment';
import { get } from 'lodash';

const { progressBar, notify } = useUtils()

// eslint-disable-next-line no-undef
const props = defineProps({
  value: Boolean,
  type: {
    type: String,
    default: 'ENTRADA',
    validator: (value) => ['ENTRADA', 'SAIDA'].includes(value),
  },
  items: {
    type: Array,
    default: () => ([]),
  },
  warehouseId: String,
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['input', 'save'])

const formRef = ref()

const products = ref([])
const persistedLots = ref({})

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
}

const rules = {
  required: (v) => !!v || 'Campo obrigatório!',
  date: (v) => !v || moment(v, 'YYYY-MM-DD').isValid() || 'Campo Inválido!',
}

const dialog = computed({
  get: () => props.value,
  set: (val) => emit('input', val),
})

useDialogHistory(dialog)

const quantityDiff = computed(() => {
  return products.value.map(item => parseFloat(item.quantidade) - item.lotes.reduce((acc, cur) => (acc + (parseFloat(cur.quantidade) || 0)), 0))
})

const showSupplier = computed(() =>  {
  return products.value.some(item => item.item_de_compra)
})

const init = async () => {
  formRef.value?.resetValidation()

  if (props.items.length === 0) {
    notify.error('Itens não informados para lote');
    dialog.value = false;
    return;
  }

  try {
    progressBar.loading();

    const { data } = await axios.get(`/item/items-with-lots`, { params: {
      id_deposito: props.warehouseId,
      ids: props.items.map(item => item.id_item),
    } });

    products.value = data.map(product => {
      const item = props.items.find(item => item.id_item === product.id_item) || {}

      return {
        ...item,
        id_item: product.id_item,
        codigo: product.cod_item,
        nome: product.nome,
        unidade: product.unidade,
        item_de_venda: product.item_de_venda,
        item_de_compra: product.item_de_compra,
        item_de_producao: product.exibir_no_silo,
        lote_manual: product.lote_manual,
        expirationDays: product?.parametros_producao?.prazos_validade?.[store.state.settings?.laticinio?.id],
        lotFormat: product?.parametros_producao?.formato_lote,
        quantidade: item.quantidade,
        lotes: [
          {
            numero: null,
            data_fabricacao: null,
            data_validade: null,
            quantidade: item.quantidade,
            lote: {}, // Utilizado em saídas
            lote_manual: product.lote_manual || item.lote_manual,
            fornecedor_manual: false,
            fornecedor: {},
            lote_existente: false,
          }
        ]
      }
    });

    persistedLots.value = data.reduce((acc, cur) => ({
      ...acc,
      [cur.id_item]: cur.stocks.map(lot => ({
        ...lot,
        text: lot.numero_lote || 'Sem Lote'
      }))
    }), {});

    if (props.type === 'SAIDA') {
      for (let product of products.value) {
        if (!product.lote_manual) {
          const lots = persistedLots.value[product.id_item] || []
          // Auto select first
          product.lotes[0].lote = lots[0] || {};
        }
      }
    }
  } catch (error) {
    const message = get(error, 'response.data.message', 'Erro ao carregar estoques');
    notify.error(message, 'Atenção');
    console.warn(error);
  } finally {
    progressBar.hide();
  }
}

const save = async () => {
  if (!await formRef.value.validate()) {
    return;
  }

  const savedProducts = products.value.map(product => ({
    ...product,
    lotes: product.lotes.map(item => ({
      ...item,
      quantidade: item.quantidade,
      numero: item.lote?.numero_lote || item.numero,
      data_fabricacao: item.lote?.data_fabricacao || item.data_fabricacao,
      data_validade: item.lote?.data_validade || item.data_validade,
      fornecedor: item.fornecedor,
    }))
  }))

  emit('save', savedProducts);
  close();
}

const close = () => {
  dialog.value = false;
}

const addLot = (itemIdx) => {
  products.value[itemIdx].lotes.push({
    numero: null,
    data_fabricacao: null,
    data_validade: null,
    quantidade: null,
    lote: {}, // Utilizado em saídas
  })
}

const removeLot = (itemIdx, idx) => {
  products.value[itemIdx].lotes.splice(idx, 1);
}

const getQuantityRules = (idx) => {
  const diff = quantityDiff.value[idx];

  if (diff === 0) {
    return [];
  }

  return [
    () => diff < 0 || `${diff} restantes`,
    () => diff > 0 || `Quantidade difere do informado`,
  ];
}

const getPersistedLots = (itemId, itemIdx, idx) => {
  const persisted = persistedLots.value[itemId] || [];

  const alreadySelected = products.value[itemIdx].lotes
    .map(item => item.lote.numero_lote);

  alreadySelected.splice(idx, 1);

  // Filtra os lotes já selecionados, excluindo o do index atual
  return persisted.filter(item => !alreadySelected.includes(item.numero_lote));
}

const checkIfHasPersistedLots = (itemId, itemIdx, idx) => {
  const persisted = persistedLots.value[itemId] || [];

  const lotForm = products.value[itemIdx].lotes[idx];
  const lotNumber = lotForm.numero;

  const lot = persisted.find((item) => item.numero_lote === lotNumber);

  // Insere o lote existente (lot), ou atualiza se for alterado (lote_existente)
  if (lot || lotForm.lote_existente) {
    products.value[itemIdx].lotes[idx].data_fabricacao = lot?.data_fabricacao;
    products.value[itemIdx].lotes[idx].data_validade = lot?.data_validade;
    products.value[itemIdx].lotes[idx].fornecedor = { id: lot?.id_fornecedor, name: lot?.nome_fornecedor };
    products.value[itemIdx].lotes[idx].fornecedor_manual = lot && !lot?.id_fornecedor;
    products.value[itemIdx].lotes[idx].lote_existente = !!lot;
  }
}

const validateRequiredDate = (value, item, lote) => {
  if (value || lote.lote_existente) {
    return true;
  }

  if (!item.item_de_venda && !item.item_de_producao) {
    return true;
  }

  return 'Campo obrigatório';
}

const setExpirationDate = (itemIdx, idx) => {
  const { expirationDays } = products.value[itemIdx]

  if (!expirationDays) {
    return
  }

  const manufacturingDate = products.value[itemIdx].lotes[idx].data_fabricacao

  products.value[itemIdx].lotes[idx].data_validade = manufacturingDate
    ? moment(manufacturingDate).add(expirationDays, 'days').format('YYYY-MM-DD')
    : null
}

watch(dialog, (isOpen) => {
  if (isOpen) { init() }
})

</script>
