<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-col
          cols="4"
          class="pa-0"
        >
          <date-range-picker
            v-model="filters.date.input"
            class="pa-0"
            @change="onDateFilter"
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="4"
          class="pa-0"
        >
          <v-text-field
            v-model="filters.search"
            prepend-inner-icon="search"
            label="Buscar"
            dark
            hide-details
            filled
            clearable
          />
        </v-col>
      </v-card-title>
      <data-table
        ref="report"
        :loading="loading"
        :headers="headers"
        :items="filteredItems"
        show-custom-group
        name="Justificativa de atraso do itinerário"
        item-key="id"
        class="elevation-1"
        disable-filtering
        multi-sort
      >
        <template #[`item.data`]="{ value }">
          {{ formatDate(value, 'DD/MM/YYYY') }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <template v-if="item.url_anexos && item.url_anexos.length > 0">
            <v-btn
              icon
              @click="openPhotos(item);"
            >
              <v-icon>photo</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <div>
              <v-chip
                class="ma-2"
                x-small
              >
                -
              </v-chip>
            </div>
          </template>
        </template>

        <template #[`item.edit`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                small
                @click="editItinerario(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>

              <v-list-item
                small
                @click="deleteItinerario(item.id_justificativa_itinerario)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <v-dialog
      v-model="photoDialog.show"
      width="600"
      max-width="650"
    >
      <v-card>
        <v-card-title>Fotos</v-card-title>
        <v-card-text>
          <v-carousel>
            <v-carousel-item
              v-for="(foto, i) in fotos"
              :key="i"
            >
              <img
                :src="foto"
                width="100%"
              >
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="blue--text"
            text
            @click="photoDialog.show = false;"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="itinerarioDialog.show"
      width="600"
      height="250"
    >
      <v-card>
        <v-card-title>
          {{ itinerarioDialog.form.id_justificativa_itinerario ? 'Editar Justificativa de Atraso' : 'Cadastrar Justificativa de Atraso' }}
        </v-card-title>

        <v-divider />

        <v-card-text
          class="pt-5"
          style="max-height: calc(100vh - 250px);"
        >
          <v-tabs
            v-model="tab"
            fixed-tabs
          >
            <v-tab
              href="#itinerario-tab"
            >
              Itinerario
            </v-tab>

            <v-tab
              href="#anexos-tab"
              :disabled="rotaItinerario ? false : itinerarioDialog.form.id_itinerario ? false : true"
            >
              Anexos
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              value="itinerario-tab"
              justify-center
              class="pa-6"
            >
              <v-row>
                <v-col cols="3">
                  <masked-input
                    v-model="itinerarioDialog.form.data"
                    label="Data"
                    :mask="DateMask"
                    :disabled="itinerarioDialog.form.id_justificativa_itinerario ? true : false"
                    @change="onDateFilterItinerary"
                  />
                </v-col>
                <v-col cols="9">
                  <v-autocomplete
                    v-if="!itinerarioDialog.form.id_justificativa_itinerario"
                    v-model="motorista"
                    label="Motorista"
                    :items="motoristas"
                    item-text="motorista"
                    item-value="id_motorista"
                    :disabled="itinerarioDialog.form.data ? false : true"
                    :loading="loadingMotorista"
                    @input="onRoutes"
                  />
                  <v-text-field
                    v-else
                    v-model="itinerarioDialog.form.motorista"
                    label="Motorista"
                    :disabled="itinerarioDialog.form.id_justificativa_itinerario ? true : false"
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-if="!itinerarioDialog.form.id_justificativa_itinerario"
                    v-model="rotaItinerario"
                    label="Rota"
                    :items="rotaItinerarios"
                    item-text="concat_descricao_rota"
                    item-value="id_rota"
                    :disabled="motorista ? false : true"
                    :loading="loadingRoutes"
                  />
                  <v-text-field
                    v-else
                    v-model="itinerarioDialog.form.nome_rota"
                    label="Rota"
                    :disabled="itinerarioDialog.form.id_justificativa_itinerario ? true : false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="mt-6"
                >
                  <v-textarea
                    v-if="itinerarioDialog.form.id_justificativa_itinerario"
                    v-model="itinerarioDialog.form.motivo"
                    label="Motivo"
                    height="5px"
                    class="mt-6"
                  />
                  <v-textarea
                    v-else
                    v-model="itinerarioDialog.form.motivo"
                    label="Motivo"
                    height="5px"
                    class="mt-6"
                    :disabled="!rotaItinerario"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item
              value="anexos-tab"
              justify-center
              class="pa-6"
            >
              <v-col
                cols="12"
              >
                <attachments
                  :attached.sync="attached"
                  show-upload
                  @attachment-selected="handleAttachmentSelected"
                />
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="close()"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :disabled="!itinerarioDialog.form.motivo"
            @click.stop="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template>
      <v-speed-dial
        fixed
        dark
        bottom
        right
        open-on-hover
        direction="top"
        transition="slide-y-reverse-transition"
        class="mr-5"
      >
        <template v-slot:activator>
          <v-btn
            color="blue darken-2"
            dark
            large
            fab
          >
            <v-icon>menu</v-icon>
          </v-btn>
        </template>

        <template>
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>
        </template>

        <template>
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  print
                </v-icon>
              </template>

              Imprimir
            </v-tooltip>
          </v-btn>
        </template>
        <template>
          <v-btn
            fab
            dark
            color="blue darken-1"
            @click="addItinerario"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  add
                </v-icon>
              </template>

              Inserir Itinerário
            </v-tooltip>
          </v-btn>
        </template>
      </v-speed-dial>
    </template>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import qs from "qs";
import moment from "moment-timezone";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import Attachments from "@/Domains/Collects/Reports/CollectionImpediment/Components/Attachments.vue";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    DateRangePicker,
    Attachments,
    MaskedInput
  },

  data() {
    return {
      loading: false,
      filters: {
        date: {
          input: "today",
          range: [],
        },
        search: null,
      },
      relatorio: [],
      fotos: [],
      showPhoto: false,

      photoDialog: {
        show: false,
      },

      tab: null,

      loadingRoutes: false,

      loadingMotorista: false,

      itinerarioDialog: {
        show: false,
        form: {}
      },

      motoristas: [],

      motorista: [],

      dateItinerary: {
        input: null,
        range: [],
      },

      rotaItinerarios: [],

      rotaItinerario: [],

      filterMotorista: null,

      id_itinerario: null,

      DateMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },

      attached: [],
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.relatorio;
      }
      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;
      return this.relatorio.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },
    headers() {
      let headers = [
        { text: 'Data', value: 'data', align: 'center' },
        { text: 'Motorista', value: 'motorista', align: 'start' },
        { text: 'Código Itinerário', value: 'cod_itinerario', align: 'center' },
        { text: 'Rota', value: 'nome_rota', align: 'center' },
        { text: 'Motivo', value: 'motivo', align: 'start' },
        { text: 'Fotos', value: 'action', align: 'center' },
        { text: '', value: 'edit', align: 'center' },
      ];
      return headers;
    },
  },

  mounted() {
    this.loadRelatorio();
    this.listaItinerarioColeta();
  },

  methods: {
    onDateFilter(event) {
      this.filters.date.range = event
      this.loadRelatorio();
    },

    async loadRelatorio() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/justificativaItinerarios`,
          qs.stringify({
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.first(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) :  moment().format("YYYY-MM-DD"),
          })
        );

        this.relatorio = data.map((item) => {
          return {
            ...item,
            url_anexos: JSON.parse(item.url_anexos)
          }
        })
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Justificativa de atraso do itinerário - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `Justificativa de atraso do itinerário - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      this.$refs.report.print(null, title);
    },

    openPhotos(item) {
      this.fotos = item.url_anexos;
      this.photoDialog.show = true;
    },

    clear() {
      this.itinerarioDialog.form = {};
      this.date = null;
      this.rotaItinerario = null;
      this.id_itinerario =  null;
      this.motorista = null;
      this.attached = [];
    },

    close() {
      this.itinerarioDialog.show = false;
      this.dateItinerary.input = null;
      this.tab = null;
      this.clear();
    },

    handleAttachmentSelected(url) {
      this.attached = url;
    },

    addItinerario() {
      this.itinerarioDialog.show = true;
      this.itinerarioDialog.form = {};
      this.rotaItinerario = null;
      this.id_itinerario =  null;
      this.motorista = null;
      this.tab = null;

      this.clear();
    },

    editItinerario(item) {
      this.itinerarioDialog.show = true;
      this.itinerarioDialog.form = { ...item };
      this.date =  this.itinerarioDialog.form.data;
      this.attached = this.itinerarioDialog.form.url_anexos;
      this.motorista = this.itinerarioDialog.form.motorista;

      this.tab = "itinerario-tab";

    },

    onDateFilterItinerary(event) {
      this.loadingMotorista = true;
      const formattedDate = moment(event, 'DD/MM/YYYY').format('YYYY-MM-DD');
      this.dateItinerary.range = formattedDate;

      this.listaItinerarioColeta();
    },

    async listaItinerarioColeta() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/listaItinerarioColeta`,
          qs.stringify({
            data_inicio: this.dateItinerary.range,
            id_motorista: this.filterMotorista
          })
        );

        if (_.isString(data)) {
          throw data;
        }

        this.motoristas = data.map(x => {
          return {
            id_itinerario: x.id_itinerario,
            id_motorista: x.id_motorista,
            motorista: x.motorista,
            id_rota: x.id_rota,
            name: x.descricao_rota,
            concat_descricao_rota: x.data_hora_inicio + ' - ' + x.descricao_rota,
            data_hora_inicio: x.data_hora_inicio
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
        this.loadingMotorista = false;
      }
    },

    onRoutes(event) {
      this.loadingRoutes = true;
      this.filterMotorista = event;
      this.listaItinerario();
    },

    async listaItinerario() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/listaItinerarioColeta`,
          qs.stringify({
            data_inicio: this.dateItinerary.range,
            id_motorista: this.filterMotorista
          })
        );

        if (_.isString(data)) {
          throw data;
        }

        this.rotaItinerarios = data.map(x => {
          return {
            id_itinerario: x.id_itinerario,
            id_rota: x.id_rota+'|'+x.data_hora_inicio,
            name: x.descricao_rota,
            data_hora_inicio: x.data_hora_inicio,
            concat_descricao_rota: ' Data/Hora início: ' + moment(x.data_hora_inicio).format('DD/MM/YYYY HH:mm') + ' | Rota: ' + x.descricao_rota,
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
        this.loadingRoutes = false;
      }
    },

    async save() {
      try {
        this.loading = true;

        const action = this.itinerarioDialog.form.id_justificativa_itinerario ? 'atualizaItinerario' : 'insereItinerario';

        const selectedMotorista    = this.motoristas.find(motorista => motorista.id_motorista === this.motorista);
        const selectedIdItinerario = this.rotaItinerarios.find(x => x.id_rota === this.rotaItinerario);

        for (const key in selectedIdItinerario) {
          if (key === 'id_rota') {
            const rotaSplit = selectedIdItinerario[key].split("|");
            selectedIdItinerario[key] =  rotaSplit[0];
          }
        }

        var urls = [];
        if (Array.isArray(this.attached)) {
          urls = this.attached.map(function(anexo) {
            if (typeof anexo === 'string') {
              return anexo;
            } else if (anexo.url) {
              return anexo.url;
            } else {
              return null;
            }
          }).filter(function(url) {
            return url !== null;
          });
        }

        let payload = {
          id_justificativa_itinerario: this.itinerarioDialog.form.id_justificativa_itinerario ? this.itinerarioDialog.form.id_justificativa_itinerario : '',
          data: action === 'insereItinerario' ? selectedIdItinerario.data_hora_inicio : moment(this.itinerarioDialog.form.data, 'DD/MM/YYYY').format('YYYY-MM-DD hh:mm:ss'),
          id_rota: action === 'insereItinerario' ? selectedIdItinerario.id_rota : this.itinerarioDialog.form.id_rota,
          nome_rota: action === 'insereItinerario' ? selectedIdItinerario.name : this.itinerarioDialog.form.nome_rota,
          id_pessoa_registro: action === 'insereItinerario' ? selectedMotorista.id_motorista : this.itinerarioDialog.form.id_motorista,
          motorista: action === 'insereItinerario' ? selectedMotorista.motorista : this.itinerarioDialog.form.motorista,
          motivo: this.itinerarioDialog.form.motivo,
          id_itinerario: action === 'insereItinerario' ? selectedIdItinerario.id_itinerario : this.itinerarioDialog.form.id_itinerario,
          url_anexos: urls,
        };

        const { data } = await this.$axios.post(
          `/coleta/${action}`,
          payload
        );

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.itinerarioDialog.show = false;
        this.$snotify.success("Registro salvo com sucesso", "Sucesso");
        this.$emit("save");
        this.loadRelatorio();
        this.clear();
        this.close();
      } catch (error) {
        this.$snotify.error("Erro ao salvar", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    async deleteItinerario(id) {
      try {
        if (
          !(await this.$root.$confirm("Atenção", "Deseja realmente Inativar/Excluir esta justificativa de itinerario?", { color: "red" }))
        ) {
          return;
        }
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.post(`/coleta/inativaItinerario`, this.$qs.stringify({
          id_justificativa_itinerario: id,
        }));

        if (!data.codigo) {
          throw data.mensagem || data;
        }

      } catch (error) {
        console.log(error);
        this.$snotify.error(`Oops, ocorreu um erro ao excluir!\r\n${error}`, "Atenção");
      } finally {
        this.$root.$progressBar.hide();
        this.loadRelatorio();
      }

    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },

}
</script>
