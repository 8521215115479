<template>
  <v-overlay
    v-model="dialog"
    absolute
    z-index="500"
  >
    <v-card-text>
      {{ message }}
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-card-text>
  </v-overlay>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    message: ''
  }),
  methods: {
    loading() {
      this.message = 'Carregando...'
      this.dialog = true
    },
    saving() {
      this.message = 'Salvando...'
      this.dialog = true
    },
    hide() {
      this.dialog = false
    }
  }
}

</script>