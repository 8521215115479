<template>
  <div class="dashboard">
    <v-row class="mx-2">
      <v-col cols="8">
        <v-spacer />
      </v-col>
      <v-col cols="4">
        <VDateRangePicker
          v-model="filter.date"
          :ranges="filter.dateRanges"
          dark
        />
      </v-col>
      
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsGeneralChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsBPAChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsMilkBuyChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsIN76Chart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsOthersChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsProspectionChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsRoutineChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsSuspensionChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsProductSalesChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsVetChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsZootechnicianChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsPMLSChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsSampleCollectionChart
          :period="filter.date"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <VisitsTechnicianRemoteChart
          :period="filter.date"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VisitsGeneralChart from "@/Domains/BI/Views/Visits/Components/VisitsGeneralChart.vue";
import VisitsBPAChart from "@/Domains/BI/Views/Visits/Components/VisitsBPAChart.vue";
import VisitsMilkBuyChart from "@/Domains/BI/Views/Visits/Components/VisitsMilkBuyChart.vue";
import VisitsIN76Chart from "@/Domains/BI/Views/Visits/Components/VisitsIN76Chart.vue";
import VisitsOthersChart from "@/Domains/BI/Views/Visits/Components/VisitsOthersChart.vue";
import VisitsProspectionChart from "@/Domains/BI/Views/Visits/Components/VisitsProspectionChart.vue";
import VisitsRoutineChart from "@/Domains/BI/Views/Visits/Components/VisitsRoutineChart.vue";
import VisitsSuspensionChart from "@/Domains/BI/Views/Visits/Components/VisitsSuspensionChart.vue";
import VisitsProductSalesChart from "@/Domains/BI/Views/Visits/Components/VisitsProductSalesChart.vue";
import VisitsVetChart from "@/Domains/BI/Views/Visits/Components/VisitsVetChart.vue";
import VisitsZootechnicianChart from "@/Domains/BI/Views/Visits/Components/VisitsZootechnicianChart.vue";
import VisitsPMLSChart from "@/Domains/BI/Views/Visits/Components/VisitsPMLSChart.vue";
import VisitsSampleCollectionChart from "@/Domains/BI/Views/Visits/Components/VisitsSampleCollectionChart.vue";
import VisitsTechnicianRemoteChart from "@/Domains/BI/Views/Visits/Components/VisitsTechnicianRemoteChart.vue";

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

import moment from "moment";

export default {
  components: {
    VDateRangePicker,
    VisitsGeneralChart,
    VisitsBPAChart,
    VisitsMilkBuyChart,
    VisitsIN76Chart,
    VisitsOthersChart,
    VisitsProspectionChart,
    VisitsRoutineChart,
    VisitsSuspensionChart,
    VisitsProductSalesChart,
    VisitsVetChart,
    VisitsZootechnicianChart,
    VisitsPMLSChart,
    VisitsSampleCollectionChart,
    VisitsTechnicianRemoteChart,
  },

  data() {
    return {
      filter: {
        dateRanges: {
          'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
          'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
          'Este ano': [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
        },
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },
    }
  },
};
</script>