<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Produtores sem análise
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <v-date-range-picker
                v-model="filters.dateRange"
                dark
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <routes-autocomplete-filter
                v-model="filters.routes"
                label="Rota"
                dark
                multiple
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <v-select
                v-model="filters.analysis"
                label="Sem análise de"
                :items="[
                  { value: 'ccs', text: 'CCS' },
                  { value: 'cbt', text: 'CPP' },
                  { value: 'gordura', text: 'GORDURA' },
                  { value: 'lactose', text: 'LACTOSE' },
                  { value: 'proteina', text: 'PROTEINA' },
                  { value: 'esd', text: 'ESD' },
                  { value: 'solidos_totais', text: 'S.T.' },
                  { value: 'ureia', text: 'UREIA' },
                  { value: 'caseina', text: 'CASEÍNA' },
                ]"
                clearable
                filled
                hide-details
                prepend-inner-icon="biotech"
                multiple
                small-chips
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <v-text-field
                v-model="filters.search"
                filled
                prepend-inner-icon="search"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <data-table
          ref="report"
          name="Produtores sem análise"
          :headers="headers"
          :items="filteredItems"
          :search="filters.search"
          dark
        >
          <template #[`item.ccs.text`]="{ item }">
            <analysis-result-item :item="item.ccs" />
          </template>
          <template #[`item.cbt.text`]="{ item }">
            <analysis-result-item :item="item.cbt" />
          </template>
          <template #[`item.caseina.text`]="{ item }">
            <analysis-result-item :item="item.caseina" />
          </template>
          <template #[`item.gordura.text`]="{ item }">
            <analysis-result-item :item="item.gordura" />
          </template>
          <template #[`item.ureia.text`]="{ item }">
            <analysis-result-item :item="item.ureia" />
          </template>
          <template #[`item.lactose.text`]="{ item }">
            <analysis-result-item :item="item.lactose" />
          </template>
          <template #[`item.proteina.text`]="{ item }">
            <analysis-result-item :item="item.proteina" />
          </template>
          <template #[`item.esd.text`]="{ item }">
            <analysis-result-item :item="item.esd" />
          </template>
          <template #[`item.solidos_totais.text`]="{ item }">
            <analysis-result-item :item="item.solidos_totais" />
          </template>
          <template #[`item.volume`]="{ value }">
            <v-chip
              small
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>
        </data-table>
      </v-card>
    </v-flex>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import AnalysisResultItem from "@/Domains/Analysis/Producers/Components/AnalysisResultItem.vue";

import isArray from "lodash/fp/isArray";
import map from "lodash/fp/map";
import flow from "lodash/fp/flow";
import orderBy from "lodash/fp/orderBy";
import groupBy from "lodash/fp/groupBy";
import moment from "moment-timezone";

export default {
  components: {
    VDateRangePicker,
    RoutesAutocompleteFilter,
    AnalysisResultItem,
  },

  data() {
    return {

      filters: {
        search: '',
        analysis: '',
        routes: [],
        dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },

      headers: [
        { text: "Código laticínio", value: "producer.code", align: 'center', width: 140 },
        { text: "Produtor", value: "producer.name", align: 'start' },
        { text: 'CCS', value: 'ccs.text', align: 'center' },
        { text: 'CPP', value: 'cbt.text', align: 'center' },
        { text: 'Caseína', value: 'caseina.text', align: 'center' },
        { text: 'Gordura', value: 'gordura.text', align: 'center' },
        { text: 'Uréia', value: 'ureia.text', align: 'center' },
        { text: 'Lactose', value: 'lactose.text', align: 'center' },
        { text: 'Proteina', value: 'proteina.text', align: 'center' },
        { text: 'ESD', value: 'esd.text', align: 'center' },
        { text: 'S.T', value: 'solidos_totais.text', align: 'center' },
        { text: 'Volume', value: 'volume', aling: 'center' }
      ],

      items: [],
    };
  },

  computed: {

    filteredItems() {
      if (!this.filters.analysis && !this.filters.search) {
        return this.items;
      }

      const analysis = this.filters.analysis;
      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;

      return this.items.filter(item => {
        /* const hasAnalysis = !analysis || !item[analysis].value;*/
        const hasAnalysis = !analysis || analysis.every(e => !item[e].value);
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasAnalysis && hasSearch;
      });
    },
  },

  methods: {

    async loadReport() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.post(
          `/relatorios/produtoresSemAnalise`,
          {
            data_inicio: startDate || moment().startOf("month").format("YYYY-MM-DD"),
            data_fim: endDate || moment().endOf("month").format("YYYY-MM-DD"),
            rotas: this.filters.routes.map(({ id }) => id),
          }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        const getResult = (items, col) => {
          const result = items.find(item => item[col]);

          if (!result) {
            return { value: null }
          }

          return {
            value: result[col],
            sample: result.numero_amostra,
            date: result.data,
            text: `${this.formatNumber(result[col])} - ${result.numero_amostra} - ${this.formatDate(result.data, 'DD/MM')}`
          }
        }

        this.items = flow(
          orderBy(['data'], ['desc']),
          groupBy('id_pessoa'),
          map((items) => {
            const [first] = items
            return {
              producer: {
                code: first.codigo_laticinio,
                id: first.id_pessoa,
                name: first.nome,
              },
              ccs: getResult(items, 'ccs'),
              cbt: getResult(items, 'cbt'),
              gordura: getResult(items, 'gordura'),
              lactose: getResult(items, 'lactose'),
              proteina: getResult(items, 'proteina'),
              esd: getResult(items, 'esd'),
              solidos_totais: getResult(items, 'solidos_totais'),
              ureia: getResult(items, 'ureia'),
              caseina: getResult(items, 'caseina'),
              volume: first.volume
            }
          })
        )(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Produtores sem análise - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title, true);
    },

    exportExcel() {
      const filename = this.getReportTitle();

      this.$refs.report.exportExcel(null, filename);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? '-' : new Intl.NumberFormat('pt-BR').format(value),

  },
};
</script>
