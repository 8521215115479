<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="quality-priority-ranking"
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Visitas Críticos/Prioritários/FORA DO PADRÃO (cpp)
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onDateFilter"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          hide-details
          @change="loadReport"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          hide-details
          @change="loadReport"
        />
      </v-col>
    </v-row>
    <v-flex xs12>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#critical">
          Críticos
        </v-tab>

        <v-tab href="#priority">
          Prioritários
        </v-tab>

        <v-tab href="#out-of-standard">
          Fora do Padrão
        </v-tab>

        <v-tab
          href="#details"
          :disabled="tab !== 'details'"
        >
          Detalhes
        </v-tab>

        <v-tab-item value="priority">
          <v-card
            class="graph-card"
            color="transparent"
            dark
          >
            <v-card-text>
              <v-chart
                :options="priorityOptions"
                :style="{width: '100%', height: `350px !important`}"
                autoresize
              />
            </v-card-text>
          </v-card>

          <v-card
            color="transparent"
            dark
            class="mt-5"
          >
            <v-card-title>
              <v-spacer />
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="priorityItems"
              :search="filter.search"
              :loading="loading"
              class="elevation-1"
              :items-per-page="12"
              hide-default-footer
              @click:row="showDetails"
            >
              <template #[`item.month`]="{value}">
                {{ formatMonth(value) }}
              </template>
              <template #[`item.visitsPerc`]="{value}">
                {{ formatNumber(value) }}%
              </template>
            </v-data-table>
            <v-card-title>
              <v-spacer />
            </v-card-title>
          </v-card>
        </v-tab-item>

        <v-tab-item value="critical">
          <v-card
            class="graph-card"
            color="transparent"
            dark
          >
            <v-card-text>
              <v-chart
                :options="criticalOptions"
                :style="{width: '100%', height: `350px !important`}"
                autoresize
              />
            </v-card-text>
          </v-card>

          <v-card
            color="transparent"
            dark
            class="mt-5"
          >
            <v-card-title>
              <v-spacer />
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="criticalItems"
              :search="filter.search"
              :loading="loading"
              class="elevation-1"
              :items-per-page="12"
              hide-default-footer
              @click:row="showDetails"
            >
              <template #[`item.month`]="{value}">
                {{ formatMonth(value) }}
              </template>
              <template #[`item.visitsPerc`]="{value}">
                {{ formatNumber(value) }}%
              </template>
            </v-data-table>
            <v-card-title>
              <v-spacer />
            </v-card-title>
          </v-card>
        </v-tab-item>

        <v-tab-item value="out-of-standard">
          <v-card
            class="graph-card"
            color="transparent"
            dark
          >
            <v-card-text>
              <v-chart
                :options="outOfStandardOptions"
                :style="{width: '100%', height: `350px !important`}"
                autoresize
              />
            </v-card-text>
          </v-card>

          <v-card
            color="transparent"
            dark
            class="mt-5"
          >
            <v-card-title>
              <v-spacer />
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="outOfStandardItems"
              :search="filter.search"
              :loading="loading"
              class="elevation-1"
              :items-per-page="12"
              hide-default-footer
              @click:row="showDetails"
            >
              <template #[`item.month`]="{value}">
                {{ formatMonth(value) }}
              </template>
              <template #[`item.visitsPerc`]="{value}">
                {{ formatNumber(value) }}%
              </template>
            </v-data-table>
            <v-card-title>
              <v-spacer />
            </v-card-title>
          </v-card>
        </v-tab-item>

        <v-tab-item value="details">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span
                    class="headline text-uppercase font-weight-bold"
                  >{{ details.type }}</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span
                    class="headline text-uppercase font-weight-bold text--accent-3 teal--text"
                  >
                    {{ formatMonth(details.month) }}
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  offset-md="2"
                  class="py-0"
                >
                  <v-text-field
                    v-model="details.search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="details.headers"
              :items="details.items"
              :search="details.search"
              show-expand
              single-expand
              class="elevation-1"
            >
              <template #expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0 text-center"
                >
                  <v-data-table
                    v-if="item.visits.length"
                    :headers="visits.headers"
                    :items="item.visits"
                    :items-per-page="item.visits.length"
                    dense
                    hide-default-footer
                    class="elevation-1 ma-3"
                  >
                    <template
                      #[`item.data_visita`]="{ value }"
                    >
                      <v-chip
                        x-small
                        label
                        color="blue"
                      >
                        {{ formatDate(value, "DD/MM/YYYY") }}
                      </v-chip>
                    </template>
                  </v-data-table>
                  <span v-else>
                    Nenhuma visita encontrada
                  </span>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-flex>

    <v-speed-dial
      v-if="['critical', 'priority', 'out-of-standard'].includes(tab)"
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "quality-priority-ranking",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      tab: null,

      filter: {
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        search: ''
      },

      criticalItems: [],
      priorityItems: [],
      outOfStandardItems: [],

      headers: [
        { text: "Mês", value: "month" },
        { text: "Produtores", value: "producers", width: 200 },
        { text: "Visitados", value: "visits", width: 200 },
        { text: "% Visitados", value: "visitsPerc", width: 200 },
      ],

      details: {
        headers: [],
        items: [],
        type: '',
        month: '',
        search: ''
      },

      visits: {
        headers: [
          { text: 'Técnico', value: 'nome_tecnico' },
          { text: 'Data', value: 'data_visita' },
          { text: 'Início', value: 'hora_chegada' },
          { text: 'Fim', value: 'hora_saida' },
          { text: 'Observação', value: 'observacao' },
        ]
      }
    };
  },

  computed: {
    priorityOptions() {
      return this.generateGraphOptions(this.priorityItems, 'Prioritários', 'Produtores com média geométrica trimestral acima de 300 no mês selecionado');
    },
    criticalOptions() {
      return this.generateGraphOptions(this.criticalItems, 'Críticos', 'Produtores com 2 médias trimestrais consecutivas acima de 300 no mês selecionado');
    },
    outOfStandardOptions() {
      return this.generateGraphOptions(this.outOfStandardItems, 'Fora do Padrão', 'Produtores com média geométrica mensal acima de 300 no mês selecionado', false);
    }
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter([month]) {
      this.filter.date.month = month;
      this.loadReport();
      this.loadOutOfStandardReport();
    },

    async loadReport() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/relatorioIn/visitasCriticosPrioritarios`,
          {
            date_ref: this.filter.date.month,
            id_tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
          }
        );

        this.priorityItems = data.map(o => ({
          month: o.mes,
          producers: o.total_prioritarios,
          visits: o.total_prioritarios_visitados,
          visitsPerc: o.perc_prioritarios_visitados,
          details: o.produtores_prioritarios,
          goalsPerc: o.meta_perc_visitas_prioritarios,
        }))

        this.criticalItems = data.map(o => ({
          month: o.mes,
          producers: o.total_criticos,
          visits: o.total_criticos_visitados,
          visitsPerc: o.perc_criticos_visitados,
          details: o.produtores_criticos,
          goalsPerc: o.meta_perc_visitas_criticos,
        }));

        this.tab = 'critical';
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    async loadOutOfStandardReport() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/relatorioIn/visitasForaDoPadrao`,
          {
            date_ref: this.filter.date.month,
            id_tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
          }
        );

        this.outOfStandardItems = data.map(o => ({
          month: o.mes,
          producers: o.total,
          visits: o.total_visitados,
          visitsPerc: o.perc_visitados,
          details: o.produtores,
          goalsPerc: 0
        }));
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    generateGraphOptions(data, title, subtitle, showGoals = true) {
      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(38, 198, 218, 0.7)'],
        title: {
          text: `${title} (${subtitle})`,
          textStyle: { color: '#ddd' },
          subtextStyle: { color: '#ddd' }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
          formatter: items =>  [
            items[0].name,
            ...items.map(param => `${param.marker} ${param.seriesName} <b>${param.data}</b>`),
            `Visitados: <b>${data[items[0].dataIndex].visitsPerc} %</b>`
          ].join('<br>')
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.month)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: title,
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => o.producers),
          },
          {
            name: 'Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: item => `  ${item.value} - ${data[item.dataIndex].visitsPerc}%  `,
            },
            data: data.map(o => o.visits),
          },
          showGoals ?
            {
              name: 'Meta',
              type: 'line',
              lineStyle: { width: 2 },
              emphasis: {
                focus: 'series'
              },
              label: {
                normal: {
                  show: true,
                  lineHeight: 20,
                  height: 20,
                  backgroundColor: '#6a7985',
                  color: '#fff',
                  borderRadius: 5,
                  position: 'top',
                  distance: 1,
                  formatter: o => '  ' + data[o.dataIndex].goalsPerc + '%  ',
                }
              },
              data: data.map(o => o.producers * o.goalsPerc / 100),
            }
            : null
        ]
      };
    },

    showDetails(item) {
      const tabs = {
        'critical': 'Críticos',
        'priority': 'Prioritários',
        'out-of-standard': 'Fora do Padrão',
      }
      this.details.type = tabs[this.tab];
      this.details.month = item.month;
      const months = item.details[0].historico_medias_json.map(({ data }) => data).reverse();

      this.details.headers = [
        { text: 'Código', value: 'code' },
        { text: "Produtor", value: "producer" },
        { text: 'Rota Principal', value: 'route' },
        ...months.map(date => ({
          text: _.upperFirst(moment(date).format("MMM/YY")),
          value: date,
          align: 'center',
        })),
        { text: "Visitas", value: "visits.length", align: 'center' },
      ];

      this.details.items = item.details.map(o => {
        const dates = o.historico_medias_json.reduce((acc, media) => ({
          ... acc,
          [media.data]: parseInt(this.tab === 'out-of-standard' ? media.cbt_geometrica_mes : media.cbt_geometrica) || 0
        }), {});

        return {
          id: o.id_produtor,
          code: o.codigo_laticinio,
          producer: o.nome_produtor,
          route: o.rota_principal,
          visits: o.visitas,
          ...dates,
        };
      });

      this.tab = 'details';
    },

    getReportJson(items) {
      return items.map(o => ({
        Mês: this.formatMonth(o.month),
        Produtores: o.producers,
        Visitados: o.visits,
        '% Visitados': `${this.formatNumber(o.visitsPerc)}%`,
      }));
    },

    exportExcel() {
      if (this.tab === 'priority') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.priorityItems));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Visitas Prioritários';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else if (this.tab === 'critical') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.criticalItems));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Visitas Críticos';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else if (this.tab === 'out-of-standard') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.outOfStandardItems));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Fora do Padrão';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
    },

    print() {
      if (this.tab === 'priority') {
        return this.printFromJson(this.getReportJson(this.priorityItems), 'Visitas Prioritários');
      }
      else if (this.tab === 'critical') {
        return this.printFromJson(this.getReportJson(this.criticalItems), 'Visitas Críticos');
      }
      else if (this.tab === 'out-of-standard') {
        return this.printFromJson(this.getReportJson(this.outOfStandardItems), 'Fora do Padrão');
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
