<template>
  <div>
    <v-col
      cols="12"
      class="px-0 pt-0"
    >
      <card-title-settings>
        Parâmetros Comercial
      </card-title-settings>
    </v-col>
    <v-card
      dark
      outlined
      color="rgba(255, 255, 255, 0.08)"
    >
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-select
              v-model="velocimetro_itinerario_comercial"
              :items="textRules"
              label="Foto do Odômetro"
              dense
            />
          </v-col>
          
          <v-col cols="3">
            <v-select
              v-model="forma_pagamento_pedido"
              :items="customRules"
              label="Forma de pagamento/parcelamento no pedido"
              dense
            />
          </v-col>
          
          <v-col cols="12">
            <v-combobox
              v-model="tipos_classificacao_cliente"
              label="Tipos de classificação de clientes"
              multiple
              chips
              deletable-chips
              dense
            />
          </v-col>

          <v-col cols="12">
            <v-combobox
              v-model="tipos_foto_cliente"
              label="Tipos de fotos para prospeção de clientes"
              multiple
              chips
              deletable-chips
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="saveQualitySettings()"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações do Aplicativo</span>
    </v-tooltip>

    <v-overlay v-model="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

import _ from "lodash";

export default {
  components: {
    CardTitleSettings
  },
 
  data() {
    return {

      loading: false,

      tipos_foto_cliente: null,
      tipos_classificacao_cliente: null,
      velocimetro_itinerario_comercial: null,
      forma_pagamento_pedido: null,

      textRules: [
        { text: 'Ativo', value: 'OBRIGATORIO' },
        { text: 'Inativo', value: 'NAO_PERMITIDO' },
      ],

      customRules: [
        { text: 'Ativo', value: 'PERMITIDO' },
        { text: 'Dinâmico', value: 'DINAMICO' },
        { text: 'Inativo', value: 'NAO_PERMITIDO' },
      ],

    };
  },

  mounted() {
    this.loadQualitySettings();
  },

  methods: {
    /**
     * carregar configuracoes do APP
     */
    async loadQualitySettings() {
      try {
        const { data } = await this.$axios.post(`/configuracao/listaConfiguracoesLaticinio`);

        if (_.isEmpty(data)) {
          return false;
        }

        this.handleData(data);

      } catch (e) {
        console.error(e);
      }
    },

    handleData(data) {
      this.tipos_foto_cliente = data.tipos_foto_cliente;
      this.tipos_classificacao_cliente = data.tipos_classificacao_cliente;
      this.velocimetro_itinerario_comercial = data.velocimetro_itinerario_comercial;
      this.forma_pagamento_pedido = data.forma_pagamento_pedido;
    },

    /**
     * salvar configuracoes de qualidade
     */
    async saveQualitySettings() {
      try {
        this.loading = true;

        const payLoad = {
          configuracaoQualidade: JSON.stringify([
            {
              tipos_foto_cliente: this.tipos_foto_cliente,
              tipos_classificacao_cliente: this.tipos_classificacao_cliente,
              velocimetro_itinerario_comercial: this.velocimetro_itinerario_comercial,
              forma_pagamento_pedido: this.forma_pagamento_pedido
            },
          ]),
          
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify(payLoad)
        );

        data = _.head(data);

        if (_.get(data, 'codigo') !== 1) {
          throw new Error(data);
        }
       
        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.warn(error);
      } finally {
        this.$store.dispatch('updateStoreSetting');
        this.loading = false;
      }
    },
  },
};
</script>
