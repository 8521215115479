<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Relatório Volume Rejeitado
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-container>
      <volume-list
        :expanded="produtores"
        expanded-name="Volume Rejeitado Detalhado"
        @onDate="onDate"
        @onRoute="onRoute"
        @onExport="exportExcel"
        @onChangeSearch="onChangeSearch"
      >
        <template
          slot="expanded"
          slot-scope="{ item }"
        >
          <td
            colspan="12"
            class="pa-0"
          >
            <v-container>
              <v-card
                color="transparent"
                class="mt-1 collecton-city"
              >
                <v-data-table
                  :items="produtores.filter((x) => x.nome_produtor == item.nome_produtor)"
                  class="technical-visit-table"
                  :headers="[
                    { text: 'Data', value: 'data', align: 'start', width:250 },
                    { text: 'Volume (Lts)', value: 'quantidade_coleta', width:200 },
                    { text: 'Alizarol', value: 'alizarol', width:200 },
                    { text: 'Temperatura', value: 'temperatura', width:200 },
                    { text: '', value: 'action', align: 'end',width: 5 },
                  ]"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:item.action="{ item }">
                    <template v-if="item.url_coleta && item.url_coleta.length > 0">
                      <v-btn
                        icon
                        @click="openPhotos(item);"
                      >
                        <v-icon>photo</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <div>
                        <v-chip
                          class="ma-2"
                          x-small
                        >
                          -
                        </v-chip>
                      </div>
                    </template>
                  </template>
                </v-data-table>
              </v-card>
            </v-container>
          </td>
        </template>
      </volume-list>
    </v-container>
    <v-dialog
      v-model="photoDialog.show"
      width="600"
      max-width="650"
    >
      <v-card>
        <v-card-title>Fotos</v-card-title>
        <v-card-text>
          <v-carousel>
            <v-carousel-item
              v-for="(foto, i) in fotos"
              :key="i"
            >
              <img
                :src="foto"
                width="100%"
              >
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="blue--text"
            text
            @click="photoDialog.show = false;"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay
      :value="loading"
      absolute
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import * as _ from "lodash";
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import VolumeList from "@/Domains/Collects/Reports/VolumeRejected/Components/VolumeList.vue";

export default {
  components: {
    VolumeList,
  },

  data() {
    return {
      filters: {
        date: {
          range: [],
        },
        route: null,
        search: null,
      },

      loading: false,

      produtores: [],

      photoDialog: {
        show: false,
      },

      fotos: [],
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.produtores;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.produtores.filter(item => {
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return searchFilter
      });
    },
  },

  mounted() {
    this.loadProdutorList();
  },

  methods: {

    async loadProdutorList() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/volumeRejeitado`,
          this.$qs.stringify({
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.first(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            id_rota: this.filters.route ? this.filters.route : null,
          })
        );

        this.produtores = data.map((item) => {
          return {
            ...item,
            url_coleta: JSON.parse(item.url_coleta)
          }
        })
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    onDate(event) {
      this.filters.date.range = event;

      this.loadProdutorList();
    },

    onRoute(event) {
      this.filters.route = event.id;

      this.loadProdutorList();
    },

    openPhotos(item) {
      this.fotos = item.url_coleta;
      this.photoDialog.show = true;
    },

    exportExcel() {
      let data = XLSX.utils.json_to_sheet(
        this.filteredItems.map((item) => {
          return {
            Codigo: item.codigo_produtor,
            Produtor: item.nome_produtor,
            Data: item.data,
            Volume_Lts: item.quantidade_coleta,
            Alizarol: item.alizarol,
            Temperatura: item.temperatura,
            Rota: item.nome_rota,
          };
        }),
        { header: ["Codigo", "Produtor", "Data", "Volume_Lts", "Alizarol", "Temperatura", "Rota"] }
      );

      const workbook = XLSX.utils.book_new();

      const filename = "volume_rejeitado_detalhado";

      data['!cols'] = [
        { wch: 15 },
        { wch: 50 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
      ];

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    onChangeSearch(search) {
      this.filters.search = search;
    }
  },
}
</script>
