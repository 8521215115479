var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'd-flex': true,
    'align-center': true,
    'justify-center': _vm.hideAmount
  },style:({
    width: _vm.hideAmount ? null : '90px'
  })},[(!_vm.hideAmount)?_c('span',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.installment.totalBonus, 2))+" ")]):_vm._e(),(!_vm.hideAmount)?_c('v-spacer'):_vm._e(),_c('v-icon',{staticClass:"ml-2",attrs:{"color":_vm.icon.color,"small":""}},[_vm._v(" "+_vm._s(_vm.icon.name)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }