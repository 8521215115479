
const getImpostoProduto = (natureza, uf, id, ncm) => {
  const icms_excecoes = natureza.icms_excecoes || [];
  const ipi_excecoes = natureza.ipi_excecoes || [];
  const pis_excecoes = natureza.pis_excecoes || [];
  const cofins_excecoes = natureza.cofins_excecoes || [];

  const icmsExcecao = findException(icms_excecoes, uf, id, ncm);
  const ipiExcecao = findException(ipi_excecoes, uf, id, ncm);
  const pisExcecao = findException(pis_excecoes, uf, id, ncm);
  const cofinsExcecao = findException(cofins_excecoes, uf, id, ncm);

  const impostos = {
    icms: { ...natureza.icms },
    icms_uf_dest: { ...natureza.icms_uf_dest },
    ipi: { ...natureza.ipi },
    pis: { ...natureza.pis },
    cofins: { ...natureza.cofins },
    funrural: { ...natureza.funrural },
    outros: { ...natureza.outros },
  };

  if (icmsExcecao) {
    impostos.icms = { ...icmsExcecao.icms };
    impostos.icms_uf_dest = { ...icmsExcecao.icms_uf_dest };
  }
  if (ipiExcecao) {
    impostos.ipi = { ...ipiExcecao.ipi };
  }
  if (pisExcecao) {
    impostos.pis = { ...pisExcecao.pis };
  }
  if (cofinsExcecao) {
    impostos.cofins = { ...cofinsExcecao.cofins };
  }

  return impostos;
};

const findException = (excecoes, uf, id, ncm) => {
  const excecao1 = excecoes.find(e => e.estados.includes(uf) && (e.produtos.includes(id) || e.ncms.includes(ncm)));
  if (excecao1) {
    //console.log({ excecao1 });
    return excecao1;
  }

  const excecao2 = excecoes.find(e => e.produtos.includes(id) || e.ncms.includes(ncm));
  if (excecao2) {
    //console.log({ excecao2 });
    return excecao2;
  }

  const excecao3 = excecoes.find(e => e.estados.includes(uf));
  if (excecao3) {
    //console.log({ excecao3 });
    return excecao3;
  }

  return null;
};

export { getImpostoProduto };
