<template>
  <v-dialog
    v-model="show"
    width="450"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Configurar Pagamento
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
        >
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="form.paymentMethod"
                :items="paymentMethods"
                label="Forma de Pagamento"
                item-text="description"
                item-value="id"
                return-object
                :readonly="readonly"
              />
            </v-col>
            
            <v-col cols="12">
              <v-select
                v-model="form.installmentMethod"
                :items="installmentMethods"
                label="Forma de Parcelamento"
                item-text="description"
                item-value="id"
                return-object
                :readonly="readonly"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions v-if="!readonly">
        <v-btn
          class="black--text"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading"
      absolute
    >
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import isArray from "lodash/fp/isArray";

export default {

  props: {
    value: Boolean,
    dataPayment: Object,
    readonly: Boolean,
  },

  data() {
    return {
      loading: false,

      paymentMethods: [],
      installmentMethods: [],

      form: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = { ...this.dataPayment }
      }
    }
  },

  mounted() {
    this.loadPaymmentsOptions()
  },

  methods: {
    async loadPaymmentsOptions() {
      try {
        this.loading = true;

        const [{ data: paymentMethods }, { data: installmentMethods }] = await Promise.all([
          this.$axios.post(`/formaPagamento/listaJson`),
          this.$axios.post(`/formaParcelamento/listaJson`),
        ]);

        if (!isArray(paymentMethods)) {
          throw new Error(paymentMethods);
        }

        if (!isArray(installmentMethods)) {
          throw new Error(installmentMethods);
        }

        this.paymentMethods = paymentMethods.map(item => ({
          id: item.id_forma_pagamento,
          description: item.nome
        }));

        this.installmentMethods = installmentMethods.map(item => ({
          id: item.id_forma_parcelamento,
          description: item.descricao
        }));
      }
      catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as formas de pagamento!", "Atenção");
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      this.$emit("update:dataPayment", { ...this.form });

      this.close();
    },

    close() {
      this.show = false;
    }
  }
}
</script>