<template>
  <v-card
    color="transparent"
    dark
    class="unlinked-linked-chart d-flex flex-column"
    :style="{ height: height }"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        toc
      </v-icon>
      <span class="title font-weight-light">Volume - Desvinculados x Vinculados</span>
      <v-spacer />
      <div class="hidden-xs-only">
        <div class="d-flex">
          <div
            class="d-flex flex-column justify-end px-14"
          >
            <span class="caption font-weight-light">
              Saldo Produtores
            </span>
            <span
              class="body-1 font-weight-bold"
            >{{ total.producers }}</span>
          </div>
          <div
            class="d-flex flex-column justify-end px-14"
            style="border-left: 2px solid #fff"
          >
            <span class="caption font-weight-light">
              Saldo (Média dia)
              <v-icon
                v-if="total.volume > 0"
                color="green accent-3"
                small
              >
                south_west
              </v-icon>
              <v-icon
                v-else
                color="red accent-3"
                small
              >
                north_east
              </v-icon>
            </span>
            <span
              class="headline font-weight-bold text--accent-3"
              :class="{'cyan--text': total.volume > 0, 'red--text': total.volume < 0}"
            >{{ formatNumber(total.volume) }} L</span>
          </div>
        </div>
      </div>
    </v-card-title>
    <v-row
      :class="{ 'flex-grow-1': !!height }"
      no-gutters
    >
      <v-col
        cols="6"
        class="pa-0"
      >
        <v-card-title class="py-0">
          Desvinculados
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="unlinkeds"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          dark
          class="elevation-1"
          hide-default-footer
          disable-pagination
          show-expand
          item-key="id"
        >
          <template #[`item.icon`]="{ item }">
            <v-icon
              v-if="item.volume"
              color="red accent-3"
            >
              north_east
            </v-icon>
          </template>

          <template #[`item.volume`]="{ value }">
            <v-chip
              v-if="value"
              small
              color="red accent-3"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.producers`]="{ value }">
            <template v-if="value">
              {{ formatNumber(value) }}
            </template>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-row no-gutters>
                <template>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-card
                      class="mt-3"
                      dark
                    >
                      <v-card-title>
                        <span class="text-subtitle-2">
                          Desvinculados
                        </span>
                      </v-card-title>

                      <v-data-table
                        :headers="headersDetails"
                        :items="item.producers"
                        dark
                        dense
                        hide-default-footer
                        disable-pagination
                      >
                        <template #[`item.data`]="{ value }">
                          {{ formatDate(value, 'DD/MM/YYYY') }}
                        </template>

                        <template #[`item.volume`]="{ value }">
                          <v-chip
                            small
                            :color="getChipColor(value * -1)"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </td>
          </template>
        </v-data-table>

        <v-data-table
          :headers="headersOthers"
          :items="unlinkedsOthers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          dark
          class="elevation-1"
          hide-default-footer
          disable-pagination
          show-expand
          item-key="id"
        >
          <template #[`item.icon`]="{ item }">
            <v-icon
              v-if="item.volume"
              color="red accent-3"
            >
              north_east
            </v-icon>
          </template>

          <template #[`item.volume`]="{ value }">
            <v-chip
              v-if="value"
              small
              color="red accent-3"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.producers`]="{ value }">
            <template v-if="value">
              {{ formatNumber(value) }}
            </template>
          </template>

          <template
            #[`body.append`]="{}"
          >
            <tr
              class="hidden-xs-only"
            >
              <td
                class="text-start"
                colspan="2"
              >
                <b class="title">Total</b>
              </td>
              <td class="text-center subtitle-1" />
              <td class="text-center subtitle-1">
                <b>{{ formatNumber(totalUnlinkeds.producers) }}</b>
              </td>
              <td class="text-center">
                <v-chip
                  color="red accent-3"
                >
                  <b>{{ formatNumber(totalUnlinkeds.volume) }}</b>
                </v-chip>
              </td>
            </tr>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-row no-gutters>
                <template>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-card
                      class="mt-3"
                      dark
                    >
                      <v-card-title>
                        <span class="text-subtitle-2">
                          Desvinculados
                        </span>
                      </v-card-title>

                      <v-data-table
                        :headers="headersDetails"
                        :items="item.producers"
                        dark
                        dense
                        hide-default-footer
                        disable-pagination
                      >
                        <template #[`item.data`]="{ value }">
                          {{ formatDate(value, 'DD/MM/YYYY') }}
                        </template>

                        <template #[`item.volume`]="{ value }">
                          <v-chip
                            small
                            :color="getChipColor(value * -1)"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        cols="6"
        class="pa-0"
      >
        <v-card-title class="py-0">
          Vinculados
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="linkeds"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          dark
          class="elevation-1"
          hide-default-footer
          disable-pagination
          show-expand
          item-key="id"
        >
          <template #[`item.icon`]="{ item }">
            <v-icon
              v-if="item.volume"
              color="green accent-3"
            >
              south_west
            </v-icon>
          </template>

          <template #[`item.producers.length`]="{ value }">
            <template v-if="value">
              {{ formatNumber(value) }}
            </template>
          </template>

          <template #[`item.volume`]="{ value }">
            <v-chip
              v-if="value"
              small
              color="blue accent-3"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-row no-gutters>
                <template>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-card
                      class="mt-3"
                      dark
                    >
                      <v-card-title>
                        <span class="text-subtitle-2">
                          Vinculados
                        </span>
                      </v-card-title>

                      <v-data-table
                        :headers="headersDetails"
                        :items="item.producers"
                        dark
                        dense
                        hide-default-footer
                        disable-pagination
                      >
                        <template #[`item.data`]="{ value }">
                          {{ formatDate(value, 'DD/MM/YYYY') }}
                        </template>

                        <template #[`item.volume`]="{ value }">
                          <v-chip
                            small
                            :color="getChipColor(value)"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <v-data-table
          :headers="headersOthers"
          :items="linkedsOthers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          dark
          class="elevation-1"
          hide-default-footer
          disable-pagination
          show-expand
          item-key="id"
        >
          <template #[`item.icon`]="{ item }">
            <v-icon
              v-if="item.volume"
              color="green accent-3"
            >
              south_west
            </v-icon>
          </template>

          <template #[`item.producers.length`]="{ value }">
            <template v-if="value">
              {{ formatNumber(value) }}
            </template>
          </template>

          <template #[`item.volume`]="{ value }">
            <v-chip
              v-if="value"
              small
              color="blue accent-3"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template
            #[`body.append`]="{}"
          >
            <tr
              class="hidden-xs-only"
            >
              <td
                class="text-start"
                colspan="2"
              >
                <b class="title">Total</b>
              </td>
              <td class="text-center subtitle-1" />
              <td class="text-center subtitle-1">
                <b>{{ formatNumber(totalLinkeds.producers) }}</b>
              </td>
              <td class="text-center">
                <v-chip
                  color="blue accent-3"
                >
                  <b>{{ formatNumber(totalLinkeds.volume) }}</b>
                </v-chip>
              </td>
            </tr>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-row no-gutters>
                <template>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-card
                      class="mt-3"
                      dark
                    >
                      <v-card-title>
                        <span class="text-subtitle-2">
                          Vinculados
                        </span>
                      </v-card-title>

                      <v-data-table
                        :headers="headersDetails"
                        :items="item.producers"
                        dark
                        dense
                        hide-default-footer
                        disable-pagination
                      >
                        <template #[`item.data`]="{ value }">
                          {{ formatDate(value, 'DD/MM/YYYY') }}
                        </template>

                        <template #[`item.volume`]="{ value }">
                          <v-chip
                            small
                            :color="getChipColor(value)"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-card-title class="flex-grow-0" />
  </v-card>
</template>

<style lang="scss">
.unlinked-linked-chart {
  .flex-grow-1 {
    overflow-y: auto;

    > div {
      display: flex !important;
      flex-direction: column !important;

      .v-card__title {
        flex-grow: 0 !important;
      }
      .v-data-table {
        flex-grow: 1 !important;

        .v-data-table__wrapper {
          height: 100%;
          background: rgba(255, 255, 255, 0.08);
        }
      }
    }
  }
}
</style>

<script>
import moment from 'moment';
import isObject from "lodash/fp/isObject";

export default {

  props: {
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    height: String,
  },

  data() {
    return {
      loading: false,
      sortBy: [],
      sortDesc: [],

      headers: [
        { value: 'icon', width: 50, sortable: false },
        { text: 'Empresa', value: 'group' },
        { text: 'Produtores', value: 'producers.length', align: 'center', width: 100 },
        { text: 'Volume (Média dia)', value: 'volume', align: 'center', width: 200 },
      ],
      unlinkeds: [],
      linkeds: [],

      headersOthers: [
        { value: 'icon', width: 50, sortable: false },
        { text: 'Motivo', value: 'group' },
        { text: 'Produtores', value: 'producers.length', align: 'center', width: 100 },
        { text: 'Volume (Média dia)', value: 'volume', align: 'center', width: 200 },
      ],
      unlinkedsOthers: [],
      linkedsOthers: [],

      headersDetails: [
        { text: 'Data', value: 'data' },
        { text: 'Cód. Laticínio', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome_produtor' },
        { text: 'Motivo', value: 'motivo' },
        { text: 'Volume', value: 'volume', align: 'center', cellClass: 'cell-border', width: 200 },
      ],
    }
  },

  computed: {
    totalUnlinkeds() {
      return [...this.unlinkeds, ...this.unlinkedsOthers].reduce((acc, cur) => ({
        producers: + acc.producers + cur.producers.length,
        volume: + acc.volume + cur.volume,
      }), { producers: 0, volume: 0 })
    },
    totalLinkeds() {
      return [...this.linkeds, ...this.linkedsOthers].reduce((acc, cur) => ({
        producers: + acc.producers + cur.producers.length,
        volume: + acc.volume + cur.volume,
      }), { producers: 0, volume: 0 })
    },
    total() {
      return {
        producers: this.totalLinkeds.producers - this.totalUnlinkeds.producers,
        volume: this.totalLinkeds.volume - this.totalUnlinkeds.volume
      }
    },
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(
          `/relatorios/volumeVinculadosDesvinculados`,
          {
            data_inicio: startDate,
            data_fim: endDate
          }
        );

        if (!isObject(data)) {
          throw "PHP Error";
        }

        const unlinkeds = data.desvinculados
          .filter(item => item.laticinio_concorrente)
          .reduce((acc, item) => {
            const id = item.id_laticinio_concorrente;
            if (!acc[id]) {
              acc[id] = {
                id: id,
                group: item.laticinio_concorrente,
                producers: [],
                volume: 0
              };
            }

            acc[id].volume += parseInt(item.volume || 0);
            acc[id].producers.push(item);
            return acc;
          }, {});

        const unlinkedsOthers = data.desvinculados
          .filter(item => !item.laticinio_concorrente)
          .reduce((acc, item) => {
            const id =  item.motivo;
            if (!acc[id]) {
              acc[id] = {
                id: id,
                group: item.motivo,
                producers: [],
                volume: 0
              };
            }

            acc[id].volume += parseInt(item.volume || 0);
            acc[id].producers.push(item);
            return acc;
          }, {});

        const linkeds = data.vinculados
          .filter(item => item.laticinio_concorrente)
          .reduce((acc, item) => {
            const id = item.id_laticinio_concorrente;
            if (!acc[id]) {
              acc[id] = {
                id: id,
                group: item.laticinio_concorrente,
                producers: [],
                volume: 0
              };
            }

            acc[id].volume += parseInt(item.volume || 0);
            acc[id].producers.push(item);
            return acc;
          }, {});

        const linkedsOthers = data.vinculados
          .filter(item => !item.laticinio_concorrente)
          .reduce((acc, item) => {
            const id = item.id_laticinio_concorrente || item.motivo;
            if (!acc[id]) {
              acc[id] = {
                id: id,
                group: item.motivo,
                producers: [],
                volume: 0
              };
            }

            acc[id].volume += parseInt(item.volume || 0);
            acc[id].producers.push(item);
            return acc;
          }, {});

        this.unlinkeds = Object.values(unlinkeds);
        this.unlinkedsOthers = Object.values(unlinkedsOthers);
        this.linkeds = Object.values(linkeds);
        this.linkedsOthers = Object.values(linkedsOthers);

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de Volume - Desvinculados x Vinculados!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    getChipColor(value) {
      if (value > 0) {
        return 'blue accent-3';
      }

      if (value < 0) {
        return 'red accent-3';
      }

      return null;
    },

    formatNumber: (value) => value ? new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value) : 0,
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  }
}
</script>
