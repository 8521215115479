<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <!-- Lista perfil -->
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="4"
            md-4
            sm-4
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
            />
          </v-col>
        </v-card-title>
        <v-data-table
          dark
          :headers="headersProfile"
          :items="list"
          maxlength="19"
          class="diff-list-table"
          :search="filters.search"
          @click:row="handleClick"
        >
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="openEdit(item);">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item @click="openEditPermission(item);">
                  <v-list-item-icon>
                    <v-icon>manage_accounts</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Permissões</v-list-item-title>
                </v-list-item>

                <v-list-item
                  small
                  @click="openAlert(item), showDeleteDialog = true;"
                >
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Alert excluir -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false"
      @keydown.esc="showDeleteDialog = false"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-center text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-center black--text">
            Deseja realmente Executar a Ação ?
          </h3>
          <v-flex
            xs12
            class="font-weight-black"
          >
            <br>
          </v-flex>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="openDelete(); showDeleteDialog = false;"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Botão Add nova menu -->
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="openInsert(); cadastroSelecionado;"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Inserir Perfil</span>
    </v-tooltip>

    <!-- Pagina de Cadastro de perfil -->
    <v-dialog
      width="1060"
      max-width="1060"
      :value="showCadastroProfile"
      @input="showCadastroProfile = false"
      @keydown.esc="showCadastroProfile = false"
    >
      <v-card>
        <v-card-title>Cadastro de Perfil</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="rules.isValid"
          >
            <input
              v-model="profile.id_perfil"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="profile.descricao"
                  type="text"
                  label="Descrição"
                  :rules="[v => !!v || 'Campo obrigatório']"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="profile.tipo"
                  :items="['USUARIO','PRODUTOR']"
                  label="Tipo"
                  :rules="[v => !!v || 'Campo obrigatório']"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="black--text"
            text
            @click="showCadastroProfile = false;"
          >
            cancelar
          </v-btn>
          <v-btn
            class="blue--text"
            text
            @click="
              validateForm();
              onProfileSave();"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-profile-permission
      v-if="showCadastroPermission"
      :visible="showCadastroPermission"
      :id-perfil="profile.id_perfil"
      :descricao-perfil="profile.descricao"
      @close="closeEditPermission()"
    />
  </div>
</template>
<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import IMask from "imask";
import _ from "lodash";
import DialogProfilePermission from "@/Domains/SettingsMilkup/Components/DialogProfilePermission.vue";

export default {
  name: "Profile",

  components: {
    DialogProfilePermission,
  },

  filters: {
    //Filtro usado em todas as mascaras da view
    Mask(value, mask) {
      if (_.isEmpty(value)) {
        return value;
      }
      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  },
    
  data() {
    return {
      filters: {
        search: "",
      },
      loading: false,

      showCadastroProfile: false,

      showCadastroPermission: false,

      showDeleteDialog: false,

      cadastroSelecionado: {},

      rules: {
        isValid: false,
        nome_required: [v => !!v || "Campo obrigatorio"],
        codigo_required: [v => !!v || "Campo obrigatorio"],
      },

      profile: [],
            
      objetcToDelete: "",

      headersProfile: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Descrição",
          value: "descricao",
        },
        {   value: "actions",
          sortable: false,
          align: "end",
          width: 20,
        },
      ],
      list: [],
    };
  },
    
  mounted() {
    this.loadProfile();
                
  },

  methods: {

    openInsert() {
      this.showCadastroProfile = true;
      this.profile = { id_perfil: "", descricao: "" };
    },

    async openEdit(perfil) {
      this.showCadastroProfile = true;
      this.profile = perfil;
    },

    openAlert(perfil) {
      this.objetcToDelete = perfil.id_perfil;
    },

    openDelete() {
      this.onProfileDelete(this.objetcToDelete);
    },

    validateForm() {
      this.$refs.form.validate();
    },

    async onProfileSave() {
      let perfil = this.profile;
          
      try {
        this.showCadastroProfile = false;
        this.loading = true;

        const request = {
          id_perfil: perfil.id_perfil,
          descricao: perfil.descricao,
          tipo: perfil.tipo,
        };
            
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/salvaPerfil`,
          this.$qs.stringify({
            ...request,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }
            
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar !", "Atenção");
      } finally {
        this.$snotify.success("Perfil salvo!", "Sucesso");
        await this.loadProfile();
        this.loading = false;
      }
    },

    async onProfileDelete(toDelete) {
      try {

        const { data } = await this.$axios.post(
          `/configuracaoMilkup/deletePerfil`,
          this.$qs.stringify({
            id_perfil: toDelete,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw "PHP Error";
        }

        return this.loadProfile();

      } catch (error) {
        console.log(error);
      }
    },

    async loadProfile() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaPerfil`);
                
        this.list = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Trata evento de clique na linha..
     */
    handleClick(item) {
      this.openEdit(item);
    },

    openEditPermission(perfil) {
      this.showCadastroPermission = true;
      this.profile = perfil;
    },

    closeEditPermission() {
      this.showCadastroPermission = false;
    },
        
  },
}
</script>

