<template>
  <div class="dashboard">
    <v-row class="mx-2">
      <v-col cols="8">
        <v-spacer />
      </v-col>
      <v-col cols="4">
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onDateFilter"
        />
      </v-col>

      <v-col
        cols="12"
        class="py-0"
      >
        <CriticalPriorityVisits
          :month="filter.date.month"
        />
      </v-col>
      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="polarGraph.options"
              :style="{width: '100%', height: `${polarGraph.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="ccsPriorityPie.options"
              :style="{width: '100%', height: `${ccsPriorityPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="cbtPriorityPie.options"
              :style="{width: '100%', height: `${cbtPriorityPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="cbtCcsOutOfNormativePie.options"
              :style="{width: '100%', height: `${cbtCcsOutOfNormativePie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="ccsOutOfNormativePie.options"
              :style="{width: '100%', height: `${ccsOutOfNormativePie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="cbtOutOfNormativePie.options"
              :style="{width: '100%', height: `${cbtOutOfNormativePie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="bar"
              :options="withinTheStandardGraph.options"
              :style="{width: '100%', height: `${withinTheStandardGraph.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="ranges['CCS'] && ranges['CCS'].list.length > 0"
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="ccsPie.options"
              :style="{width: '100%', height: `${ccsPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="ranges['CBT'] && ranges['CBT'].list.length > 0"
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="cbtPie.options"
              :style="{width: '100%', height: `${cbtPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mx-2">
      <v-col
        v-if="ranges['Gordura'] && ranges['Gordura'].list.length > 0"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="gorduraPie.options"
              :style="{width: '100%', height: `${gorduraPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="ranges['Proteína'] && ranges['Proteína'].list.length > 0"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="proteinaPie.options"
              :style="{width: '100%', height: `${proteinaPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="ranges['Caseína'] && ranges['Caseína'].list.length > 0"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="caseinaPie.options"
              :style="{width: '100%', height: `${caseinaPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="ranges['Sólidos Totais'] && ranges['Sólidos Totais'].list.length > 0"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="solidosTotaisPie.options"
              :style="{width: '100%', height: `${solidosTotaisPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="ranges['Volume'] && ranges['Volume'].list.length > 0"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="coletaPie.options"
              :style="{width: '100%', height: `${coletaPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="ranges['Distância'] && ranges['Distância'].list.length > 0"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="pie"
              :options="distanciaPie.options"
              :style="{width: '100%', height: `${distanciaPie.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card
          dark
          :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        >
          <v-card-text>
            <v-chart
              type="bar"
              :options="cbtCriticalGraph.options"
              :style="{width: '100%', height: `${cbtCriticalGraph.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import CriticalPriorityVisits from "@/Domains/BI/Views/Quality/Components/CriticalPriorityVisits.vue";

import QualityMixins from "@/Domains/Quality/Mixins/QualityMixins.js";
import QualityPriorityMixin from "@/Domains/Quality/Mixins/QualityPriorityMixin.js";
import QualityStandardMixin from "@/Domains/Quality/Mixins/QualityStandardMixin.js";
import QualityCBTMixin from "@/Domains/Quality/Mixins/QualityCBTMixin.js";

export default {
  components: {
    MonthPicker,
    CriticalPriorityVisits,
  },

  mixins: [
    QualityMixins,
    QualityPriorityMixin,
    QualityStandardMixin,
    QualityCBTMixin,
  ],

  data() {
    return {
      filter: {
        date: {
          input: "thisMonth",
          month: null,
        },
      },

      ranges: {},

      ccs: [],
      cbt: [],
      cbtCcs: [],

      gordura: [],
      proteina: [],
      caseina: [],
      solidosTotais: [],
      coleta: [],
      distancia: [],

      cbtCritico: [],
    }
  },

  computed: {
    limitCCS() {
      return this.ranges['CCS'] ? (this.ranges['CCS'].list[this.ranges['CCS'].limit].value || 500) : 500;
    },

    limitCBT() {
      return this.ranges['CBT'] ? (this.ranges['CBT'].list[this.ranges['CBT'].limit].value || 300) : 300;
    },

    ccsPriority() {
      return this.ccs.filter(o => o.ccs > this.limitCCS);
    },

    cbtPriority() {
      return this.cbt.filter(o => o.cbt > this.limitCBT);
    },

    cbtCcsPriority() {
      return this.cbtCcs.filter(o => (o.cbt > this.limitCBT && o.ccs > this.limitCCS));
    },

    polarGraph() {
      return this.getTotalOutOfNormativeGraph(this.ccsPriority, this.cbtPriority, this.cbtCcsPriority, this.limitCCS, this.limitCBT);
    },

    ccsPriorityPie() {
      return this.generateGraphPie('CCS - Prioritários', this.ccs, 'ccs', this.ranges['CCS'], 'priority');
    },

    cbtPriorityPie() {
      return this.generateGraphPie('CPP - Prioritários', this.cbt, 'cbt', this.ranges['CPP'], 'priority');
    },

    ccsOutOfNormativePie() {
      return this.generateOutOfNormativeGraphPie('CCS - Fora da Norma', this.ccs.length - this.ccsPriority.length, this.ccsPriority.length, this.limitCCS)
    },

    cbtOutOfNormativePie() {
      return this.generateOutOfNormativeGraphPie('CPP - Fora da Norma', this.cbt.length - this.cbtPriority.length, this.cbtPriority.length, this.limitCBT)
    },

    cbtCcsOutOfNormativePie() {
      return this.generateOutOfNormativeGraphPie('CCS E CPP - Fora da Norma', this.cbtCcs.length - this.cbtCcsPriority.length, this.cbtCcsPriority.length);
    },

    withinTheStandardGraph() {
      return this.generateWithinTheStandardGraph(this.ranges, this.ccs, this.cbt, this.gordura, this.proteina, this.caseina, this.solidosTotais)
    },

    ccsPie() {
      return this.generateGraphPie('CCS', this.ccs, 'ccs', this.ranges['CCS']);
    },

    cbtPie() {
      return this.generateGraphPie('CPP', this.cbt, 'cbt', this.ranges['CBT']);
    },

    gorduraPie() {
      return this.generateGraphPie('Gordura', this.gordura, 'gordura', this.ranges['Gordura'], null, true);
    },

    proteinaPie() {
      return this.generateGraphPie('Proteína', this.proteina, 'proteina', this.ranges['Proteína'], null, true);
    },

    caseinaPie() {
      return this.generateGraphPie('Caseína', this.caseina, 'caseina', this.ranges['Caseína'], null, true);
    },

    solidosTotaisPie() {
      return this.generateGraphPie('Sólidos Totais', this.solidosTotais, 'solidosTotais', this.ranges['Sólidos Totais'], null, true);
    },

    coletaPie() {
      return this.generateGraphPie('Volume', this.coleta, 'coleta', this.ranges['Volume'], null, true);
    },

    distanciaPie() {
      return this.generateGraphPie('Distância', this.distancia, 'distancia', this.ranges['Distância']);
    },

    cbtCriticalGraph() {
      return this.generateCbtCriticalGraph(this.cbtCritico);
    },

  },

  async created() {
    await this.loadRanges();
  },

  methods: {

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter([month]) {
      this.filter.date.month = month;
      this.loadQualityPriorityReport();
      this.loadQualityOutOfStandardReport();
      this.loadQualityCbtCriticalReport();
    },

    async loadRanges() {

      try {
        this.loading = true;

        this.ranges = await this.getRanges();

      } catch (error) {
        this.ranges = [];
        this.$snotify.error("Erro ao carregar as configurações", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadQualityPriorityReport() {
      try {
        const month = this.filter.date.month;
        const { ccs, cbt, cbtccs } = await this.getQualityPriorityReport(month);

        this.ccs = ccs;
        this.cbt = cbt;
        this.cbtCcs = cbtccs;
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
    },

    async loadQualityOutOfStandardReport() {
      try {
        const month = this.filter.date.month;
        let { gordura, proteina, caseina, solidosTotais, distancia, coleta } = await this.getQualityOutOfStandardReport(month);

        this.gordura = gordura;
        this.proteina = proteina;
        this.caseina = caseina;
        this.solidosTotais = solidosTotais;
        this.distancia = distancia;
        this.coleta = coleta;
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de padrões de qualidade", "Atenção");
      }
    },

    async loadQualityCbtCriticalReport() {
      try {
        const month = this.filter.date.month;
        let { itemsGrafico } = await this.getQualityCbtCriticalReport(month);

        this.cbtCritico = itemsGrafico;
      }
      catch (err) {
        console.warn(err)
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
    },
  }
};
</script>
