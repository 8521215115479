<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Produtores não Coletados por Período
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-container>
      <v-card
        class="v-tamanho collecton-city"
        color="transparent"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="6"
            md="4"
          >
            <v-select
              v-model="data.frequencia_coleta"
              :items="['Todos', '24 Horas', '48 Horas']"
              placeholder="Realizar coleta do leite a cada"
              hint
              @change="hoursFilter"
            />
          </v-col>
        </v-card-title>
        <v-data-table
          class="collecton-city-table"
          dark
          :items="data"
          :search="filters.search"
          :headers="[
            { text:'Nome', value:'nome'},
            { text:'Código', value:'codigo_laticinio'},
            { text:'Frequência', value:'frequencia_coleta'},
            { text:'Ultima Coleta (hrs)', value:'ultima_coleta'},
          ]"
        />
      </v-card>
    </v-container>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="#6776c7"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import XLSX from "xlsx-js-style";
import qs from "qs";
import moment from "moment-timezone";

export default {

  data() {
    return {
      dataOrigem: [],
      filters: {
        date: {
          input: "today",
          range: [],
        },
      },
      data: [],
      loading: false,
    };
  },

  mounted() {
    this.loadProdutorColeta();
  },

  methods: {
    async loadProdutorColeta() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/relatorios/produtoresPeriodo`,
          qs.stringify({
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.first(this.filters.date.range) : moment().format("YYYY-MM-DD"),
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) :  moment().format("YYYY-MM-DD"),
          })
        );

        this.data = data;
        this.dataOrigem = data;

        console.log('Retorno', this.data);
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    hoursFilter(event) {
      this.data = this.dataOrigem;
      if (event != "Todos") {
        this.data = this.data.filter(x => x.frequencia_coleta == event);
      }
    },

    exportExcel() {
      let data = XLSX.utils.json_to_sheet(this.data.map((item) => {
        return {
          Nome: item.nome,
          Código: item.codigo_laticinio,
          Frequência: item.frequencia_coleta,
          Ultima_Coleta: item.ultima_coleta,
        };
      }), { header: ['Nome', 'Código', 'Frequência', 'Ultima_Coleta'] });

      const workbook = XLSX.utils.book_new();

      const filename = "produtores_não_coletados";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },

}
</script>
