<template>
  <div>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="pt-0"
          >
            <v-select
              v-model="filters.referenceDate"
              :items="['DESCARGA', 'COLETA']"
              label="Data de referência"
              prepend-inner-icon="local_shipping"
              dark
              filled
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.date.range"
              :ranges="filters.date.ranges"
              prepend-inner-icon="today"
              dark
              label="Data"
              hide-details
              filled
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="pt-0"
          >
            <routes-autocomplete-filter
              v-model="filters.routes"
              label="Rota"
              dark
              multiple
              :return-object="false"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              label="Busca"
              filled
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        :headers="headers"
        :items="filteredItems"
        group-by="nome_agrupamento"
        :sort-by.sync="filters.sort.by"
        :sort-desc.sync="filters.sort.desc"
        light
        dark
      >
        <template #[`group.header`]="{group, isOpen, toggle}">
          <td
            class="text-start"
            :colspan="headers.length"
          >
            <v-btn
              icon
              @click="toggle"
            >
              <v-icon>
                {{ isOpen ? 'remove' : 'add' }}
              </v-icon>
            </v-btn>
            {{ group }}
          </td>
        </template>

        <template #[`item.volume_total`]="{ item }">
          <v-chip color="teal darken-1">
            {{ formatNumber(item.volume_total) }}
          </v-chip>
        </template>

        <template #[`body.append`]="{ headers }">
          <tr class="tr-tot-day">
            <td
              v-for="(header,index) in headers"
              :key="index"
            >
              <v-chip
                v-if="!['data-table-expand', 'codigo_laticinio', 'nome_produtor', 'volume_total', 'nome_agrupamento'].includes(header.value)"
                color="blue darken-4"
                label
              >
                {{ formatNumber(totalsByDay[header.value]) }}
              </v-chip>
              <v-chip
                v-else-if="header.value ==='volume_total'"
                color="blue darken-4"
                label
              >
                {{ formatNumber(totalVol) }}
              </v-chip>
              <span v-else-if="header.value ==='codigo_laticinio'">Total</span>
            </td>
          </tr>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">
  .tr-tot-day{
    span{
      font-family: Roboto, Arial, sans-serif !important;
      font-size: 1rem !important;
    }
  }
</style>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { sortItems } from 'vuetify/lib/util/helpers'
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import RoutesAutocompleteFilter from '@/Support/Components/Filters/RoutesAutocompleteFilter.vue';
import ReportMixin from '@/Support/Mixins/ReportMixin.js';

export default {
  components: {
    RoutesAutocompleteFilter,
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  props: {
    type: {
      type: String,
      default: 'CONDOMINIUM',
      validator: (value) => ['CONDOMINIUM', 'COMMUNITY-TANK', 'FAMILY-GROUP'].includes(value)
    },
  },

  data() {
    return {
      loading: false,

      filters: {
        search: '',
        routes: [],
        referenceDate: 'DESCARGA',
        date: {
          ranges: {
            'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
            'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
          },
          range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        },
        sort: { by: [], desc: [] }
      },

      items: [],
    };
  },
  computed: {
    thisMonth() {
      const firstMonthDay = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
      const lastMonthDay = moment().format('YYYY-MM-DD');

      return [firstMonthDay, lastMonthDay];
    },

    headers() {
      return [
        { text: 'Grupo', value: 'nome_agrupamento' },
        { text: 'Código', value: 'codigo_laticinio', width: 100, align: 'center' },
        { text: 'Produtor', value: 'nome_produtor', width: 250 },
        { text: 'Volume', value: 'volume_total', width: 120, align: 'center' },
        ...this.dateHeaders
      ];
    },

    dateHeaders() {
      const [startDate, endDate] = this.filters.date.range;

      let header = [];
      let currentDate = moment(startDate);
      while (currentDate <= moment(endDate)) {
        header.push({
          text: currentDate.format('DD/MM'),
          value: currentDate.format('YYYY-MM-DD'),
          width: 110,
          align: 'center',
          formatter: value => this.formatNumber(value),
        });
        currentDate = moment(currentDate).add(1, 'days');
      }

      return header;
    },

    totalsByDay() {
      return this.dateHeaders.reduce((acc, header) => ({
        ...acc,
        [header.value]: _.sumBy(this.filteredItems, values => _.get(values, header.value, '-') === '-' ? 0 : parseFloat(_.get(values, header.value)))
      }), {});
    },

    totalVol() {
      return this.filteredItems.reduce((acc, item) => (acc + item.volume_total), 0)
    },

    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.items.filter(item => {
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return searchFilter
      });
    },
  },

  mounted() {
    this.onFilter();
  },

  methods: {
    async loadDailyCollection() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filters.date.range;

        const types = {
          'CONDOMINIUM': 'CONDOMINIO',
          'COMMUNITY-TANK': 'TANQUE',
          'FAMILY-GROUP': 'FAMILIAR',
        };

        const { data } = await this.$axios.get(`/relatorios/volume-agrupamento`, { params: {
          tipo: types[this.type],
          formato: 'DIARIO',
          data_referencia: this.filters.referenceDate,
          rotas: this.filters.routes,
          data_inicio: startDate,
          data_fim: endDate,
        } });

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.items = this.handleData(data);
      } catch (error) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório!', 'Atenção');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onFilter() {
      return this.loadDailyCollection();
    },

    handleData(response) {
      //Agrupa por produtor
      const group = _.groupBy(response, 'id_produtor');

      //Preenche com todo o range date
      const fillDate = this.dateHeaders.map(({ value }) => ({ data: value }));

      //Preenche os valores correspondente a coleta de cada dia
      return _.map(group, item => {
        let volume_total = 0;

        const concat = _.concat(item, fillDate);

        item = _.uniqBy(concat, 'data');

        const accumulator = _.reduce(
          item,
          function(result, value) {
            const { data, quantidade_coleta } = value;

            return {
              ...result,
              ...value,
              [data]: parseFloat(quantidade_coleta) || '-',

            };
          },
          {}
        );

        item.forEach(e => {
          volume_total += parseFloat(e.quantidade_coleta) || 0;
        });

        _.unset(accumulator, 'quantidade_coleta');
        _.set(accumulator, 'volume_total');
        _.unset(accumulator, 'data');

        accumulator.volume_total = volume_total;
        return accumulator;
      });
    },

    orderedItems() {
      return sortItems(this.filteredItems, this.filters.sort.by, this.filters.sort.desc, 'pt-BR');
    },

    exportExcel() {
      let data = this.XLSX.utils.json_to_sheet([]);

      const header = ['Código', 'Produtor', 'Volume', ...this.dateHeaders.map(header => header.text)];

      const groups = _.groupBy(
        _.orderBy(this.orderedItems(), 'nome_agrupamento'),
        'nome_agrupamento'
      );

      let currentRow = 1;
      new Map(Object.entries(groups))
        .forEach((items, route) => {

          const json = items
            .map(item => ({
              'Código': item.codigo_laticinio,
              'Produtor': item.nome_produtor,
              'Volume': item.volume_total,
              ...this.dateHeaders
                .reduce((acc, header) => ({
                  ...acc, [header.text]: item[header.value]
                }), {})
            }));

          data[`A${currentRow}`] = { v: route };

          this.XLSX.utils.sheet_add_json(data, json, {
            origin: currentRow,
            header,
          });

          currentRow += items.length + 3;
        });

      const totals = [{
        'Código': 'Total',
        'Produtor': '',
        'Volume': this.totalVol,
        ...this.dateHeaders
          .reduce((acc, header) => ({
            ...acc, [header.text]: this.totalsByDay[header.value]
          }), {})
      }];

      this.XLSX.utils.sheet_add_json(data, totals, { origin: currentRow, header, skipHeader: true });

      data['!cols'] = [
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
      ];

      const workbook = this.XLSX.utils.book_new();
      const [startDate, endDate] = this.filters.date.range;
      const filename = `Entrada Diária - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
      this.XLSX.utils.book_append_sheet(workbook, data, _.truncate(filename, { length: 31 }));
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    async print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `Entrada Diária - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;

      if (this.filteredItems.length === 0) {
        return;
      }

      let body = [];

      const headers = this.headers
        .filter(header => header.value !== 'nome_agrupamento')
        .map(header => ({
          key: header.value,
          label: header.text,
          class: 'text-left',
          attributes: {
            style: header.width ? `width: ${header.width}px;` : ''
          }
        }));

      const totals = [{
        'codigo_laticinio': 'Total',
        'nome_produtor': '',
        'volume_total': this.formatNumber(this.totalVol),
        ...this.dateHeaders
          .reduce((acc, header) => ({
            ...acc, [header.value]: this.formatNumber(this.totalsByDay[header.value])
          }), {})
      }];

      const groups = _.groupBy(
        _.orderBy(this.orderedItems(), 'nome_agrupamento'),
        'nome_agrupamento'
      );

      new Map(Object.entries(groups))
        .forEach((items, route) => {
          body.push({
            tag: 'section',
            class: 'row',
            children: [{
              tag: 'datatable',
              title: route,
              headers,
              items,
            }]
          });
        });

      body.push({
        tag: 'section',
        class: 'row',
        children: [
          {
            tag: 'div',
            contents: '&nbsp;'
          },
          {
            tag: 'datatable',
            headers,
            items: totals,
          },
        ]
      });

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }], landscape: true });
    },

    formatNumber: val => !val || isNaN(val) ? '-' : new Intl.NumberFormat('pt-BR').format(val),
  },
};
</script>
