<template>
  <div
    class="d-flex flex-column justify-end px-14 text-center"
    style="border-left: 2px solid #fff"
  >
    <span class="caption font-weight-light">
      {{ title }}
      <v-icon small>
        {{ icon }}
      </v-icon>
    </span>
    <span
      class="body-1 font-weight-bold white--text"
    >
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    value: String
  }
}
</script>