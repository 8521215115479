<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    />

    <v-row
      xs12
      justify="center"
    >
      <v-col
        cols="12"
      >
        <h2 class="menu-header white--text">
          Volume/Município
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab>
        Mensal
      </v-tab>

      <v-tab>
        Diário
      </v-tab>

      <v-tab-item>
        <collection-volume-monthly-tab />
      </v-tab-item>

      <v-tab-item>
        <collection-volume-daily-tab />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import CollectionVolumeMonthlyTab from "@/Domains/Collects/Reports/CollectionVolumeByCity/Views/CollectionVolumeMonthlyTab.vue";
import CollectionVolumeDailyTab from "@/Domains/Collects/Reports/CollectionVolumeByCity/Views/CollectionVolumeDailyTab.vue";

export default {
  components: {
    CollectionVolumeMonthlyTab,
    CollectionVolumeDailyTab,
  },
}
</script>