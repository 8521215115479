<template>
  <div
    class="mx-6"
  >
    <v-card
      class="v-tamanho questionnaireList-list"
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.project"
              :items="projectList"
              :loading="loading"
              label="Vigência do Projeto"
              item-value="dateStart"
              item-text="text"
              return-object
              hide-details
              clearable
              dark
              filled
              :rules="[v => !!v || 'Campo obrigatório']"
              @change="loadquestionnaireList"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.questionnaireId"
              :items="questionnaireList"
              :loading="loading"
              label="Checklist"
              item-value="id"
              item-text="title"
              hide-details
              clearable
              dark
              filled
              :rules="[v => !!v || 'Campo obrigatório']"
              @change="loadAnswers"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <month-range-picker
              v-model="filters.range"
              label="Período 1"
              :ranges.sync="filters.ranges"
              :min="filters.min"
              :max="filters.max"
              dark
              @change="loadAnswers"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <month-range-picker
              v-model="filters.range2"
              label="Período 2"
              :ranges.sync="filters.ranges"
              :min="filters.min"
              :max="filters.max"
              dark
              @change="loadAnswers"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div
          v-for="(values, alternatives,index ) in questions"
          :key="index"
        >
          <v-row
            style="padding-bottom: 20px;"
          >
            <v-col
              :key="index"
              cols="12"
              height="500"
              class="pa-0 ma-0"
            >
              <table-answers-period
                :questions="values"
                :total-answered="totalAnswered"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-overlay v-model="loading">
      Carregando ...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<style>
.questionnaire-tabs {
  width: auto !important;
}
</style>

<script>
import * as _ from "lodash";
import moment from "moment-timezone";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import TableAnswersPeriod from "@/Domains/Projects/PQFL/Comparative/Components/TableAnswersPeriod.vue";

export default {

  components: {
    MonthRangePicker,
    TableAnswersPeriod,
  },

  filters: {

  },

  data() {
    return {
      questions: [],

      totalAnswered: [],

      loading: false,

      filters: {
        ranges: {
          '': [],
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
          'Ano anterior': [moment().startOf('year').subtract(1, 'years').format('YYYY-MM'), moment().endOf('year').subtract(1, 'years').format('YYYY-MM')],
        },
        range: [],
        range2: [],

        min: moment().subtract(5, 'years').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        show: "all",
        types: null,

        project: {
          start: null,
          end: null,
          protocol: '',
          id: '',
        },
        questionnaireId: null,
        producerSelected: [],
      },
      projectList: [],
      questionnaireList: [],
      producerList: [],

    };
  },

  computed: {

  },

  watch: {

  },

  async mounted() {
    this.filters.types = ["VISITA_PQFL", "VISITA_IN77", "VISITA_IN59", "VISITA_BPA", "VISITA_CPP", "VISITA_CCS", "VISITA_CHECKLIST_INICIAL", "VISITA_BRUCELOSE_TUBERCULOSE" ];
    await this.loadProject();
  },

  methods: {

    async loadProject() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL",
          }
        );

        this.projectList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            protocol: proj.protocolo,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filters.project = this.projectList[0];
          // this.filters.range = [moment(this.filters.project.dateStart).format('YYYY-MM'), moment(this.filters.project.dateEnd).format('YYYY-MM')];
          // this.filters.range2 = [moment(this.filters.project.dateStart).format('YYYY-MM'), moment(this.filters.project.dateEnd).format('YYYY-MM')];
        }

      } catch (error) {
        console.warn(error);
      } finally {
        await this.loadquestionnaireList();
        this.loading = false;
      }
    },

    async loadquestionnaireList() {
      try {
        this.loading = true;
        if (_.isEmpty(this.filters.types)) {
          return false;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: _.isEmpty(this.filters.types) ? _.map(this.types, 'value') : this.filters.types,
            nome_projeto: this.project,
            prospectado: this.filters.prospected,
          }
        );

        this.questionnaireList = data.map((item) => {
          return {
            id: item.id,
            title: item.titulo,
          };
        });

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadAnswers() {
      try {
        this.loading = true;
        this.questions = [];
        this.totalAnswered = [];

        if (_.isEmpty(this.filters.questionnaireId) || _.isEmpty(this.filters.range) || _.isEmpty(this.filters.range2)) {
          console.log('escolha os filtros');
          return false;
        }

        const {data} = await this.$axios.post(
          `/formularioCustomizado/checklistComparativoPeriodos`,
          {
            id_formulario: this.filters.questionnaireId,
            data_inicio: moment(this.filters.range[0]).startOf('month').format('YYYY-MM-DD') || moment().startOf('month').format("YYYY-MM-DD"),
            data_fim: moment(this.filters.range[1]).endOf('month').format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD'),
            data_inicio2: moment(this.filters.range2[0]).startOf('month').format('YYYY-MM-DD') || moment().startOf('month').format("YYYY-MM-DD"),
            data_fim2: moment(this.filters.range2[1]).endOf('month').format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD'),
          }
        );
        this.questions = data;

        // Agroupa por grupos de alternativas
        this.questions = _.groupBy(this.questions, "valores");

        if (!data) {
          this.questions = [];
          throw "PHP Error";
        }

        /**
         * Verifica se passou
         */
        if (_.has(_.head(data), "codigo")) {
          this.questions = [];
          throw data.mensagem;
        }

      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async loadProducerList() {
      try {
        this.loading = true;
        if (_.isEmpty(this.filters.questionnaireId)) {
          return false;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaProduresFormulario`,
          {
            id_formulario: this.filters.questionnaireId,
            projeto: 'PQFL',
            data_inicio: moment(this.filters.range[0]).startOf('month').format('YYYY-MM-DD') || moment().startOf('month').format("YYYY-MM-DD"),
            data_fim: moment(this.filters.range[1]).endOf('month').format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD'),

          }
        );

        this.producerList = data.map((item) => {
          return {
            id: item.id_produtor,
            name: item.nome_produtor,
          };
        });

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
