<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Média Volume</span>
      <v-spacer />
      <span class="caption font-weight-light">
        {{ formatDate(period[0]) }} - {{ formatDate(period[1]) }}
      </span>
      <v-menu
        v-model="showDatePicker"
        :close-on-content-click="false"
        offset-y
        bottom
        left
        content-class="menu-select"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            small
            v-on="on"
          >
            <v-icon>expand_more</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="period"
          no-title
          range
          @change="onChangeDateRange"
        />
      </v-menu>
    </v-card-title>
    <v-row class="flex-grow-1">
      <v-col
        cols="6"
        md="12"
      >
        <template v-if="collected.length > 1">
          <div class="d-flex flex-column mx-4">
            <span class="subtitle font-weight-light">
              Média Coletado: {{ formatNumber(averageCollected) }} L
            </span>
          </div>
          <v-chart
            type="pie"
            :options="optionsCollected"
            :style="{width: '100%', height: '100% !important'}"
            autoresize
          />
        </template>
        <div
          v-else
          class="d-flex flex-column mt-4 mb-6"
        >
          <span class="display-2 font-weight-bold mt-16 teal--text text--lighten-2">
            {{ formatNumber(averageCollected) }} L
          </span>
          <span class="mb-16">
            Média Coletado
          </span>
          <span v-if="collected.length === 1">
            {{ collected[0].name }}
          </span>
        </div>
      </v-col>

      <v-col
        cols="6"
        md="12"
      >
        <template v-if="measured.length > 1">
          <div class="d-flex flex-column mx-4">
            <span class="subtitle font-weight-light">
              Média Medidos: {{ formatNumber(averageMeasured) }} L
            </span>
          </div>
          <v-chart
            type="pie"
            :options="optionsMeasured"
            :style="{width: '100%', height: '100% !important'}"
            autoresize
          />
        </template>
        <div
          v-else-if="measured.length === 1"
          class="d-flex flex-column mt-4 mb-6"
        >
          <span class="display-2 font-weight-bold mt-16 cyan--text">
            {{ formatNumber(averageMeasured) }} L
          </span>
          <span class="mb-16">
            Média Medidos
          </span>
          <span>
            {{ measured[0].name }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '250px',
    },
  },

  data() {
    return {
      loading: false,
      tab: null,
      collected: [],
      measured: [],
      period: [moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
      showDatePicker: false,
    }
  },

  computed: {
    optionsCollected() {
      return {
        color: [
          'rgba(29, 233, 182, 0.7)',
          'rgba(171, 71, 188, 0.7)',
          'rgba(255, 255, 0, 0.7)'
        ],
        tooltip: {
          trigger: 'item',
          formatter: (param) => `${param.marker} ${param.name} <b>${this.formatNumber(param.value)} L</b>`,
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        series: [
          {
            name: 'Volume',
            type: 'pie',
            labelLine: { show: false },
            label: {
              show: true,
              position: 'inside',
              formatter: ({ value }) => this.formatNumber(value),
              textShadowColor: '#000',
              textShadowBlur: 0.5,
            },
            top: -50,
            radius: '60%',
            roseType: 'radius',
            data: this.collected,
          }
        ],
      }
    },

    optionsMeasured() {
      return {
        color: [
          'rgba(38, 198, 218, 0.7)',
          'rgba(171, 71, 188, 0.7)',
          'rgba(255, 255, 0, 0.7)'
        ],
        tooltip: {
          trigger: 'item',
          formatter: (param) => `${param.marker} ${param.name} <b>${this.formatNumber(param.value)} L</b>`,
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        series: [
          {
            name: 'Volume',
            type: 'pie',
            labelLine: { show: false },
            label: {
              show: true,
              position: 'inside',
              formatter: ({ value }) => this.formatNumber(value),
              textShadowColor: '#000',
              textShadowBlur: 0.5,
            },
            top: -50,
            radius: '60%',
            roseType: 'radius',
            data: this.measured,
          }
        ],
      }
    },

    averageCollected() {
      return this.collected.reduce((acc, cur) => acc + cur.value, 0)
    },

    averageMeasured() {
      return this.measured.reduce((acc, cur) => acc + cur.value, 0)
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    onChangeDateRange() {
      this.showDatePicker = false
      this.loadReport()
    },
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let [{ data: { relatorio: coletado } }, { data: { relatorio: medido } }] = await Promise.all([
          this.$axios.post(
            `/relatorios/mediaLeite`,
            { data_inicio: startDate, data_fim: endDate }
          ),
          this.$axios.post(
            `/relatorios/mediaLeiteMedido`,
            { data_inicio: startDate, data_fim: endDate }
          )
        ]);

        if (!_.isArray(coletado) || !_.isArray(medido)) {
          throw "PHP Error";
        }

        this.collected = coletado.map(o => ({
          name: o.laticinio,
          value: parseInt(o.volume)
        }));

        this.measured = medido.map(o => ({
          name: o.laticinio,
          value: parseInt(o.volume)
        }));

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de média de leite!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatDate: date => moment(date).format('DD/MM/YYYY'),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
