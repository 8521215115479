<template>
  <div>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-spacer />
        <v-col
          cols="3"
          md="4"
          class="pt-0"
        >
          <v-autocomplete
            v-model="filters.group"
            label="Grupo"
            placeholder="Selecione um Grupo"
            :items="groupList"
            item-text="group"
            item-value="id"
            hide-details
            filled
            @change="defaultLoading"
          />
        </v-col>
        <v-col
          cols="3"
          md="4"
          class="pt-0"
        >
          <v-autocomplete
            v-model="filters.product"
            label="Produto"
            placeholder="Selecione um Produto"
            :items="productsList"
            item-text="product"
            item-value="id"
            hide-details
            filled
            @change="loadItems"
          />
        </v-col>
        <v-col
          cols="3"
          md="4"
          class="pt-0"
        >
          <v-text-field
            v-model="filters.search"
            append-icon="search"
            label="Busca"
            single-line
            hide-details
            filled
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search="filters.search"
        style="white-space: nowrap"
      >
        <template #[`item.unitPrice`]="{ item }">
          <span>
            {{ formatNumber(item.unitPrice) }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {

  props: {
    groupList: [],

  },

  data() {
    return {
      items: [],
      productsList: [],

      filters: {
        search: "",
        group: null,
        product: null,
      },

      edit: false,
      editId: null,
    };
  },

  computed: {
    headers() {
      return [
        { text: 'Código', value: 'code', align: 'start' },
        { text: 'Produto', value: 'product', align: 'start' },
        { text: 'Descrição', value: 'description', align: 'start' },
        { text: 'Grupo', value: 'group', align: 'start' },
        { text: 'Unidade', value: 'unit', align: 'start' },
        { text: 'Valor Unit R$.', value: 'unitPrice', align: 'start' },
        { text: 'Na Empresa', value: 'inCompany', align: 'start' },
        { text: 'Em Consignação', value: 'onConsignment', align: 'start' },
        { text: 'Total', value: 'total', align: 'start' },
      ];
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async defaultLoading() {
      this.getProductsByGroups();
      this.loadItems();
    },

    async getProductsByGroups() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/product', { params: {
            id_grupo_produto: this.filters.group,
          }
        });

        this.productsList = data.map(item => {
          return {
            id: item.id_produto,
            product: item.nome,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/stockInCompany', { params: {
            id_grupo_produto: this.filters.group,
            id_produto: this.filters.product,
          }
        });

        this.items = data.map(item => {
          return {
            code: item.produto_codigo,
            product: item.produto_nome,
            description: item.produto_descricao,
            group: item.grupo,
            unit: item.unidade_medida,
            unitPrice: item.valor_unit,
            inCompany: item.quantidade,
            onConsignment: item.estoque_complementar,
            total: parseFloat(item.quantidade) + parseFloat(item.estoque_complementar),
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
  },

};
</script>

<style scoped>

</style>
