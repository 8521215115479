<template>
  <div>
    <v-dialog
      v-model="analysis.show"
      persistent
      max-width="630px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Análise de Silo</span>
        </v-card-title>

        <v-card-text class="py-0">
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <v-autocomplete
                  v-model="analysis.silo"
                  :items="options.silos"
                  item-text="label"
                  item-value="id"
                  label="Silo *"
                  placeholder=" "
                  small-chips
                  return-object
                  clearable
                  :rules="[ rules.required, rules.requiredId ]"
                  @click:clear.stop="onClearSilo"
                  @input="onSiloSelect"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.label }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item.rawMaterial.id">
                        {{ item.rawMaterial.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>

                  <template #selection="{ item, index }">
                    <v-chip x-small>
                      <span>{{ item.label }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="4"
                class="py-0"
              >
                <v-text-field
                  v-model="analysis.form.sampleTakenAt.date"
                  label="Data Retirada Amostra *"
                  type="date"
                  :rules="[ rules.required, rules.date ]"
                />
              </v-col>
              <v-col
                cols="4"
                class="py-0"
              >
                <v-text-field
                  v-model="analysis.form.sampleTakenAt.time"
                  label="Hora Retirada Amostra *"
                  type="time"
                  :rules="[ rules.required, rules.time ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                class="py-0"
              >
                <raw-material-autocomplete
                  v-model="analysis.form.rawMaterial"
                  label="Matéria Prima *"
                  placeholder=" "
                  return-object
                  :rules="[ rules.required, rules.requiredId ]"
                />
              </v-col>
              <v-col
                cols="6"
                class="py-0"
              >
                <v-select
                  v-model="analysis.form.stirrer"
                  label="Func. Agitador *"
                  placeholder=" "
                  prepend-inner-icon="cached"
                  :rules="[ rules.requiredBool ]"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-tabs v-if="analysis.silo.id">
                <v-tab>
                  {{ analysis.silo.label }}
                </v-tab>

                <v-tab-item>
                  <v-card>
                    <v-card-text class="pb-0">
                      <v-row>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.temp"
                            label="Temperatura"
                            name="temp"
                            :mask="FloatNumberMask"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('acidity')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.acidity"
                            label="Acidez"
                            name="acidity"
                            :mask="FloatNumberMask"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('alizarol')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.alizarol"
                            label="Alizarol"
                            name="alizarol"
                            :mask="FloatNumberMask"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('crioscopy')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.cryoscopy"
                            name="crioscopy"
                            label="Crioscopia"
                            :mask="FloatNumberMask"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('fat')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.fat"
                            name="fat"
                            label="Gordura"
                            :mask="FloatNumberMask"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('esd')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model="analysis.form.esd"
                            label="ESD"
                            name="esd"
                            :mask="FloatNumberMask"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('density')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.density"
                            label="Densidade"
                            name="density"
                            :mask="FloatNumberMask"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('ph')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.ph"
                            label="pH"
                            name="ph"
                            :mask="FloatNumberMask"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('protein')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.protein"
                            label="Proteína"
                            name="protein"
                            :mask="FloatNumberMask"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('reductase')"
                          />
                        </v-col>
                        <v-col cols="3">
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="analysis.form.reductase"
                            label="Redutase"
                            name="reductase"
                            :mask="FloatNumberMask"
                            currency
                            dense
                            return-unmasked
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="hide"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="isSaveAvailable"
            color="blue darken-1"
            text
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import MaskedInput from "@/Support/Components/MaskedInput.vue";
import RawMaterialAutocomplete from "@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue";

export default {

  components: {
    RawMaterialAutocomplete,
    MaskedInput,
  },

  data() {
    return {
      loading: false,

      analysis: {
        // Dialog
        show: false,

        silo: {
          id: null,
          label: null,
        },

        form: {
          sampleTakenAt: {
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },

          stirrer: null,

          rawMaterial: {
            id: null,
            name: null,
          },

          // Analysis
          temp: null,
          acidity: null,
          alizarol: null,
          cryoscopy: -0.5,
          fat: null,
          esd: null,
          density: null,
          ph: null,
          protein: null,
          reductase: null,
        },
      },

      options: {
        silos: [],
      },

      // Mascara usada nos campos numéricos float
      FloatNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      rules: {
        date: v => moment(v, 'YYYY-MM-DD').isValid() || 'Campo Inválido!',
        time: v => moment(v, 'HH:mm').isValid() || 'Campo Inválido!',
        required: v => !!v || !_.isEmpty(v) || 'Campo Obrigatório!',
        requiredId: v => !!v.id || 'Campo obrigatório!',
        requiredBool: v => _.isBoolean(v) || 'Campo obrigatório!',
      },
    }
  },

  computed: {

    isSaveAvailable() {
      return true;
    },

  },

  async mounted() {
    await this.loadOptions();
  },

  methods: {

    async show({ id, silo, rawMaterial }) {

      if (!_.isEmpty(id)) {
        this.analysis = {
          ...this.analysis,
          id,
        }

        return this.loadDetails(id);
      }

      this.analysis = {
        ...this.analysis,
        id: null,
        silo,
        show: true,
        form: {
          ...this.analysis.form,
          rawMaterial: {
            id: rawMaterial.id,
            name: rawMaterial.name,
          },
        }
      };
    },

    hide() {
      this.analysis = {
        show: false,
        id: null,
        silo: {
          id: null,
          label: null,
        },
        form: {
          sampleTakenAt: {
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },

          stirrer: null,

          rawMaterial: {
            id: null,
            name: null,
          },

          temp: null,
          alizarol: null,
          acidity: null,
          ph: null,
          density: null,
          esd: null,
          cryoscopy: -0.5,
        },
      };
    },

    async loadOptions() {
      try {
        const { data } = await this.$axios.post(`/silo/principal`);

        if (!_.isArray(data.silo)) {
          throw "PHP Error";
        }

        this.options.silos = data.silo.map(silo => {
          return {
            index: (silo.descricao || '').replace(/\D/g, ''),
            id: silo.id_silo,
            label: silo.descricao,
            vol: parseInt(silo.volume_atual),
            capacity: parseInt(silo.volume_total),
            rawMaterial: {
              id: silo.id_materia_prima,
              name: silo.nome_materia_prima,
            },
          }
        }).sort((a, b) => a.index - b.index);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      }
    },

    async loadDetails(id) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/silo/listaDetalheAnaliseSilo`,
          {
            id_silo_analise_detalhe: id,
          }
        );

        if (!_.isObject(data)) {
          throw 'Dados inválidos';
        }

        this.analysis = {
          show: true,
          id,
          silo: {
            id: data.id_silo,
            label: data.descricao_silo,
          },
          form: {
            sampleTakenAt: {
              date: moment(data.data_hora_retirada_amostra).format('YYYY-MM-DD'),
              time: moment(data.data_hora_retirada_amostra).format('HH:mm'),
            },

            stirrer: data.agitador,

            rawMaterial: {
              id: data.id_materia_prima,
              name: data.nome_materia_prima,
            },

            temp: parseFloat(data.temperatura) || null,
            acidity: parseFloat(data.acidez) || null,
            alizarol: parseFloat(data.alizarol) || null,
            cryoscopy: parseFloat(data.crioscopia) || null,
            fat: parseFloat(data.gordura) || null,
            esd: parseFloat(data.esd) || null,
            density: parseFloat(data.densidade) || null,
            ph: parseFloat(data.ph) || null,
            protein: parseFloat(data.proteina) || null,
            reductase: parseFloat(data.redutase) || null,
          },
        }
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os detalhes da análise!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    goToNextInput(nextInputName) {
      const inputs = this.$refs['analysis-elm'];

      const nextElm = inputs.find(elm => {
        return elm.$attrs.name === nextInputName
      });

      if (_.isEmpty(nextElm)) {
        return;
      }

      if (!_.isFunction(nextElm.focus)) {
        return;
      }

      return nextElm.focus();
    },

    onSiloSelect(silo) {
      if (_.isEmpty(_.get(silo, 'rawMaterial.id'))) {
        this.analysis = {
          ...this.analysis,
          form: {
            ...this.analysis.form,
            rawMaterial: {
              id: null,
              name: null,
            },
          },
        };

        return;
      }

      this.analysis = {
        ...this.analysis,
        form: {
          ...this.analysis.form,
          rawMaterial: {
            ...silo.rawMaterial,
          },
        },
      };
    },

    onClearSilo() {
      return this.$nextTick(() => {
        this.analysis = {
          ...this.analysis,
          silo: {
            id: null,
            name: null,
          },
        };
      })
    },

    async save() {
      try {
        this.loading = true;

        const sampleTakenAt = `${this.analysis.form.sampleTakenAt.date} ${this.analysis.form.sampleTakenAt.time}`;
        const valid = this.$refs.form.validate();

        if (!valid) {
          return;
        }

        const { data } = await this.$axios.post(
          `/silo/salvaDetalheAnaliseSilo`,
          {
            id_silo_analise_detalhe: this.analysis.id,
            data_hora_retirada_amostra: sampleTakenAt,
            agitador: this.analysis.form.stirrer,
            id_materia_prima: this.analysis.form.rawMaterial.id,
            nome_materia_prima: this.analysis.form.rawMaterial.name,
            id_silo: this.analysis.silo.id,
            descricao_silo: this.analysis.silo.label,
            temperatura: this.analysis.form.temp,
            acidez: this.analysis.form.acidity,
            alizarol: this.analysis.form.alizarol,
            crioscopia: this.analysis.form.cryoscopy,
            gordura_inicial: this.analysis.form.fat,
            esd: this.analysis.form.esd,
            densidade: this.analysis.form.density,
            ph: this.analysis.form.ph,
            proteina: this.analysis.form.protein,
            redutase: this.analysis.form.reductase,
          }
        );

        if (!data.codigo) {
          throw data;
        }

        this.hide();

        return this.$emit('onAnalysisSave');
      } catch (err) {
        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar os detalhes da análise!",
          "Atenção"
        );

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

  }
}
</script>

