<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="orders"
      :loading="loading"
      light
      dense
      disable-sort
    >
      <template #[`item.date`]="{ value }">
        {{ dateFormat(value, 'DD/MM/YYYY') }}
      </template>

      <template #[`item.total`]="{ value }">
        {{ formatCurrency(value) }}
      </template>

      <template #[`item.discount`]="{ item }">
        <span v-if="item.discount > 0">
          {{ formatCurrency(item.discount) }}
          <small>
            ({{ formatNumber(item.discountPerc) }} %)
          </small>
        </span>
      </template>

      <template #[`item.status`]="{ value }">
        <v-chip
          x-small
          dark
          :color="getStatusColor(value)"
        >
          {{ value }}
        </v-chip>
      </template>

      <template #[`item.approval.status`]="{ item }">
        <approval-chip :value="item.approval" />
      </template>

      <template #[`item.financial`]="{ value, item }">
        <template v-if="item.status == 'VENDA'">
          <v-menu
            v-if="value.length > 0"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                style="position:relative"
                v-bind="attrs"
                v-on="on"
                @click.stop
              >
                <v-icon>
                  attach_money
                </v-icon>
                <v-icon
                  small
                  class="financial-badge"
                  color="green accent-4"
                >
                  check_circle
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(conta, index) in value"
                :key="index"
                @click="showBill(conta.id_titulo)"
              >
                <v-list-item-title>{{ formatCurrency(conta.valor) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip
            v-else
            top
          >
            <template #activator="{ on }">
              <v-btn
                icon
                style="position:relative"
                v-on="on"
              >
                <v-icon>
                  attach_money
                </v-icon>
                <v-icon
                  small
                  class="financial-badge"
                  color="red accent-3"
                >
                  remove_circle
                </v-icon>
              </v-btn>
            </template>
            Financeiro não gerado
          </v-tooltip>
        </template>
      </template>
    </v-data-table>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      type="CONTA_A_RECEBER"
      @input="billDialog.id = null"
    />
  </div>
</template>

<script>
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import ApprovalChip from '@/Domains/Orders/Components/ApprovalChip.vue';

import moment from "moment-timezone";

export default {
  components: {
    BillDialog,
    ApprovalChip,
  },

  props: {
    id: String,
  },

  data() {
    return {
      loading: false,

      headers: [
        { text: 'Código', value: 'code' },
        { text: 'Data', value: 'date' },
        { text: 'Vendedor', value: 'seller' },
        { text: 'Status', value: 'status' },
        { text: 'Desconto', value: 'discount' },
        { text: 'Valor', value: 'total' },
        { text: 'Financeiro', value: 'financial', align: 'center', width: 70 },
        { text: 'Aprovação', value: 'approval.status', align: 'center', width: 150,  }
      ],
      orders: [],

      billDialog: {
        show: false,
        id: null,
      },
    }
  },

  watch: {
    id(value) {
      this.orders = [];
      if (value) {
        this.loadOrders();
      }
    }
  },

  methods: {
    async loadOrders() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/movimento/pedidosCliente`, {
          id_cliente: this.id
        });

        this.orders = data.map(p => ({
          id: p.id_movimento,
          code: p.cod_movimento,
          date: p.data_hora_cadastro,
          customer: p.nome_cliente,
          status: p.status,
          financial: p.contas,
          discount: p.desconto,
          discountPerc: p.desconto_percentual || (p.desconto ? ((parseFloat(p.desconto) * 100)  / (parseFloat(p.valor) + parseFloat(p.desconto))) : null),
          total: p.valor,
          seller: p.nome_vendedor,
          approval: {
            status: p.aprovacao || 'PENDENTE',
            date: p.data_hora_aprovacao
          },
        }))
      } catch (error) {
        this.$snotify.error("Erro ao carregar ordens", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    showBill(id) {
      this.billDialog.id = id;
      this.billDialog.show = true;
    },

    getStatusColor(value) {
      const statusColors = {
        'PEDIDO': 'blue',
        'ORCAMENTO': 'orange darken-1',
        'VENDA': 'green darken-1',
      }
      return statusColors[value]
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>