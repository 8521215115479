<template>
  <v-dialog
    v-model="dialog.show"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Log de Alterações
      </v-card-title>

      <v-card-text>
        <v-container style="max-width: 600px;">
          <v-timeline
            dense
            clipped
          >
            <template>
              <v-timeline-item
                v-for="log in logs"
                :key="log.id"
                :color="getItemColor(log)"
                :small="isItemSmall(log)"
              >
                <v-row justify="space-between">
                  <v-col cols="9">
                    {{ getItemMessage(log) }}
                    <br>
                    <v-row v-if="log.type === 'API_CHANGE_FIELD'">
                      <v-col
                        v-for="(value, key) of JSON.parse(log.message)"
                        :key="key"
                        cols="12"
                        sm="6"
                        class="pb-0"
                      >
                        <v-sheet
                          color="white"
                          elevation="1"
                          class="d-flex flex-column justify-space-between pa-2"
                        >
                          <div class="font-weight-bold">
                            {{ startCase(key) }}
                          </div>
                          <div>
                            {{ value[0] }}
                            <v-icon
                              small
                              class="mx-2 mb-1"
                              color="orange"
                            >
                              keyboard_double_arrow_right
                            </v-icon>
                            {{ value[1] }}
                          </div>
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <template v-if="log.updatedFrom">
                      Usuário: {{ log.updatedFrom }}
                    </template>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="3"
                  >
                    {{ dateFormat(log.updatedAt, 'DD/MM HH:mm:ss') }}
                  </v-col>
                </v-row>

                <template v-slot:icon>
                  <v-icon
                    color="white"
                    small
                    v-html="getItemIcon(log)"
                  />
                </template>
              </v-timeline-item>
            </template>
          </v-timeline>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import startCase from 'lodash/fp/startCase';

export default {

  data() {
    return {
      loading: false,

      dialog: {
        show: false,
      },

      logs: [],
    }
  },

  methods: {

    async show(row) {
      this.dialog.show = true;

      await this.loadBonusDetails(row);
    },

    async loadBonusDetails(row) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/tabelaPreco/listaLogBonificacao`,
          {
            id_formacao_valor_leite: row.id,
          }
        );

        if (!_.isArray(data)) {
          throw new Error('Erro ao localizar log de alterações');
        }

        this.logs = data.map(log => {
          return {
            id: log.id_formacao_valor_leite_log,
            type: log.tipo,
            updatedAt: log.data_hora_registro,
            updatedFrom: log.nome_pessoa_registro,
            message: log.mensagem,
          }
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    dateFormat(date, format) {
      return moment(date).format(format);
    },

    getItemColor(log) {
      switch (log.type) {
        case 'RESET':
          return 'deep-purple';
        case 'PRICE_LOCK':
          return 'black';
        case 'PRICE_UNLOCK':
          return 'blue-grey';
        default:
          return 'grey';
      }
    },

    isItemSmall(log) {
      return !['RESET', 'PRICE_LOCK', 'PRICE_UNLOCK'].includes(log.type)
    },

    getItemIcon(log) {
      switch (log.type) {
        case 'RESET':
          return 'attach_money';
        case 'PRICE_LOCK':
          return 'lock';
        case 'PRICE_UNLOCK':
          return 'lock_open';
        default:
          return '';
      }
    },

    getItemMessage(log) {
      switch (log.type) {
        case 'API_CHANGE_FIELD':
          return 'Campos alterados via Api!';
        default:
          return log.message;
      }
    },

    startCase
  },

}
</script>

