<template>
  <div>
    <v-select
      v-model="input"
      :items="profiles"
      :label="label"
      :prepend-inner-icon="prependInnerIcon"
      item-value="id"
      item-text="description"
      :return-object="returnObject"
      outlined
      clearable
      v-bind="$attrs"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
      <template
        #append-item
      >
        <v-divider class="mt-2" />
        <v-row no-gutters>
          <v-col>
            <v-btn
              block
              outlined
              @click="addNew"
            >
              Cadastrar novo
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-select>

    <profile-registration-dialog
      v-model="showNewDialog"
      @newProfile="onNewProfile"
    />
  </div>
</template>

<script>
import ProfileRegistrationDialog from "@/Domains/Registrations/User/Components/ProfileRegistrationDialog.vue";

export default {

  components: {
    ProfileRegistrationDialog,
  },

  props: {
    value: [Object, String],
    label: {
      type: String,
      default: 'Perfil de Aprovação'
    },
    prependInnerIcon: {
      type: String,
      default: 'add_task'
    },
    returnObject: Boolean
  },

  data() {
    return {
      input: null,
      showNewDialog: false,
      profiles: [],
    };
  },

  watch: {
    value: {
      depth: true,
      handler(value) {
        this.input = value;
      }
    }
  },

  created() {
    this.getProfiles();
  },

  methods: {
    async getProfiles() {
      const { data } = await this.$axios.post(`/comercial/perfisComercial`);

      this.profiles = data.map(o => ({
        id: o.id_perfil_comercial,
        name: o.nome,
        description: `${o.nome} | ${parseInt(o.percentual_maximo)}%`,
        percent: parseInt(o.percentual_maximo)
      }));

      if (this.value) {
        this.input = this.value;
      }
    },

    addNew() {
      this.showNewDialog = true;
    },

    async onNewProfile(value) {
      this.profiles.push({
        ...value,
        description: `${value.name} | ${value.percent}%`,
      });

      this.input = value;
      this.$emit("input", this.input);
      this.showNewDialog = false;
    },
  },
};
</script>
