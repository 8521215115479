<template>
  <div>
    <v-timeline-item
      :color="item.color || 'blue darken-1'"
      fill-dot
      right
      large
    >
      <template #icon>
        <span
          class="text-center"
          style="color: white; font-size: 12px !important;"
        >
          {{ item.date | dateFormat('HH:mm') }}
        </span>
      </template>

      <v-card class="elevation-2">
        <v-card-title
          class="text-overline pa-0 pt-1 pl-2 pr-2 pb-2"
          style="font-size: 12px !important;"
        >
          <span><b>{{ item.title }}</b>  |  {{ item.date | dateFormat('DD/MM HH:mm') }}</span>
        </v-card-title>

        <v-card-text
          v-if="item.body"
          class="pb-2"
        >
          <v-row class="text-caption pl-2 pr-2">
            <v-col
              cols="12"
              class="text-left pa-0"
            >
              {{ item.body }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {

  filters: {
    dateFormat: (value, opts) => moment(value).format(opts),
  },

  props: {
    item: {
      type: Object,
      default: () => ({
        id: '',
        type: 'ITEM',
        date: '',
        title: '',
      }),
    },
  },

}
</script>
