<template>
  <v-dialog
    v-model="isVisible"
    :max-width="200"
    persistent
  >
    <v-card>
      <v-card-text class="pa-3">
        <v-data-table
          :headers="[
            { text: 'Descrição', value: 'descricao' },
          ]"
          hide-default-footer
          disable-sort
          hide-default-header
          :items="settings.flowMeters"
          @click:row="selected"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      isVisible: false,
    }
  },

  computed: {
    settings() {
      const settings = this.$store.state.settings.plataforma;

      return {
        flowMeters: settings.configuracoes_medidores_integracao || [],
      };
    },
  },

  methods: {
    show() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },

    async selected(item) {
      this.$emit('selected', item);

      return this.close();
    }
  }
}
</script>
