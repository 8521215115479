<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-col cols="12">
      <!-- <h2 class="menu-header white--text">
        Prêmios
      </h2> -->
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filter.project"
              :items="projectList"
              label="Descrição"
              item-value="id"
              item-text="text"
              return-object
              hide-details
              clearable
              dark
              filled
              :rules="[v => !!v || 'Campo obrigatório']"
              @change="loadAwards"
            />
          </v-col>
          <v-col
            md-6
            offset-md="3"
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              single-line
              hide-details
              clearable
            />
          </v-col>
        </v-card-title>

        <v-data-table
          ref="report"
          :headers="[
            { align: 'start', text: 'Descrição', value: 'description' },
            { text: 'Pontos', value: 'value' },
            { align: 'end', text: '', value: 'actions', sortable: false },
          ]"
          :items="items"
          :items-per-page="-1"
          class="elevation-1"
          hide-default-footer
        >
          <template #[`item.value`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  dark
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="showEditingDialog(item)">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Editar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="excludeAward(item)">
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Excluir
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>

      <v-speed-dial
        fixed
        dark
        bottom
        right
        open-on-hover
        direction="top"
        transition="slide-y-reverse-transition"
        class="mr-5"
      >
        <template #activator>
          <v-btn
            color="blue darken-2"
            dark
            large
            fab
          >
            <v-icon>menu</v-icon>
          </v-btn>
        </template>

        <v-btn
          fab
          dark
          color="green darken-1"
          @click="exportExcel"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                backup_table
              </v-icon>
            </template>
            Download (Excel)
          </v-tooltip>
        </v-btn>

        <v-btn
          fab
          dark
          color="orange darken-1"
          @click="print"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                print
              </v-icon>
            </template>

            Imprimir
          </v-tooltip>
        </v-btn>
        <v-btn
          fab
          dark
          color="purple mr-5"
          @click="showCreatingDialog"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                add
              </v-icon>
            </template>

            Novo prêmio
          </v-tooltip>
        </v-btn>
      </v-speed-dial>

      <award-dialog
        v-model="edit"
        :edit-id.sync="editId"
        :id-assistencia="filter.project.id"
        @save="loadAwards"
      />
    </v-col>
  </v-container>
</template>

<script>
import _ from "lodash";
import AwardDialog from "@/Domains/Projects/Dairy/AwardPoints/Components/AwardDialog.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    AwardDialog
  },
  mixins: [ReportMixin],

  data() {
    return {
      // Dados brutos do backend
      awards: [],

      filter: {
        project: {
          description: null,
          id: '',
        },
      },

      projectList: [],

      // Filtros da tabela
      filters: {
        search: "",
      },

      // Usado no dialog de criação / edição
      edit: false,
      editId: null,
    };
  },

  computed: {
    /**
     * Exibe as regiões filtradas
     */
    items() {
      if (_.isEmpty(this.filters.search)) {
        return this.awards;
      }

      return this.awards.filter(i => {
        return (i.description || "").toUpperCase().includes((this.filters.search || "").toUpperCase());
      });
    },
  },

  async mounted() {
    await this.loadProject();
  },

  methods: {

    async loadProject() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy-Award",
            view: 'rating'
          }
        );

        this.projectList = data.map((proj) => {
          return {
            text: proj.descricao,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadAwards();
        }

      } catch (error) {
        console.warn(error);
      }
    },

    async loadAwards() {
      if (!this.filter.project.id) {
        this.$snotify.error("Escolha uma meta", "Atenção");
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(
          `/projects/awards`,
          {
            params: {
              id_assistencia: this.filter.project.id,
            }
          }
        );

        this.awards = data.map(item => {
          return {
            id: item.id_premiacao,
            description: item.descricao,
            value: item.valor,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar premios!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Mostra o dialog para a criação de uma nova região
     */
    showCreatingDialog() {
      this.edit = true;
      this.editId = null;
    },

    /**
     * Mostra o dialog para a edição de uma região da lista
     */
    showEditingDialog({ id }) {
      this.edit = true;
      this.editId = id;
    },

    /**
     * Apaga uma região
     * @returns {Promise<void>}
     */
    async excludeAward(award) {
      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir a prêmio ${award.description} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/projects/awards/${award.id}`);

        this.$snotify.success("Prêmio excluído com sucesso", "Sucesso");
        return await this.loadAwards();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Prêmio", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportJson() {
      return this.awards.map((item) => ({
        'Descrição': item.description,
        'Pontos': this.formatNumber(item.value),
      }));
    },

    print() {
      return this.printFromJson(this.getReportJson(), `Premios`);
    },

    exportExcel() {
      const data = this.XLSX.utils.json_to_sheet(this.getReportJson());
      const workbook = this.XLSX.utils.book_new();
      const filename = `Premios`;

      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),

  },
};
</script>
