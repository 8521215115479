<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.project"
          :items="projectList"
          label="Descrição"
          item-value="id"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="loadDateRange"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.curve"
          dark
          filled
          return-object
          label="Classe"
          clearable
          :items="curveItem"
          @change="loadTabs"
        />
      </v-col>

      <v-col
        md="3"
      >
        <month-range-picker
          v-model="filter.range"
          :ranges.sync="filter.ranges"
          :min="filter.min"
          :max="filter.max"
          dark
          @change="onFilterDate"
        />
      </v-col>

      <v-col
        md="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>
      <v-col
        md="3"
      >
        <v-select
          v-model="producerStatus"
          dark
          filled
          item-text="text"
          item-value="value"
          return-object
          label="Status Produtor"
          :items="[
            {text:'Todos',value:'*'},
            {text:'Ativos',value:'ativo'},
            {text:'Suspenso',value:'suspenso'},
            {text:'Prospectado',value:'prospectado'},
            {text:'Desvinculado',value:'desvinculado'},
          ]"
          multiple
          @change="onLoad"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <p>*Formula média final: (mes1 + mes2 + mes3) / quantidade de meses </p>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="onLoad"
    >
      <v-tab
        v-for="tabCurve in tabs"
        :key="tabCurve.id_meta_bpa"
      >
        {{ tabCurve.title }}
      </v-tab>

      <v-tab-item
        v-for="tabCurve in tabs"
        :key="tabCurve.id_meta_bpa"
        background-color="transparent"
        dark
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            {{ `Produtores : ${ tabCurve.items.length}` }}
            <v-spacer />
            {{ `${ tabCurve.tipo}` }} {{ `${ tabCurve.indicadores}` }}
          </v-card-title>

          <v-data-table
            :headers="tabCurve.headers"
            :items="tabCurve.items"
            :search="filter.search"
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
          >
            <template #[`item.media_final`]="{value}">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.soma_final`]="{value}">
              {{ formatNumber(value) }}
            </template>
            <template
              #[`item.ultima_visita`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template #[`item.status`]="{item}">
              <v-chip
                v-if="item.status.description == 'DESVINCULADO'"
                color="red"
                small
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      left
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </template>
                  <span>
                    Saída em {{ formatDate(item.data_saida, 'DD/MM/YYYY') }}
                  </span>
                </v-tooltip>
              </v-chip>
              <v-chip
                v-else-if="item.status.description == 'SUSPENSO'"
                color="orange"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>remove_circle</v-icon>
                </v-avatar>
                S
              </v-chip>
              <v-chip
                v-else
                color="green"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>check_circle</v-icon>
                </v-avatar>
                A
              </v-chip>
            </template>
          </v-data-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-overlay
      v-model="loading"
      relative
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
// import MonthPicker from "@/Support/Components/MonthPicker.vue";
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";

import moment from "moment-timezone";

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import RatingService from "@/Domains/Projects/PQFL/BPA/Services/RatingService.js";
import  _ from "lodash";

const ratingService = new RatingService();

export default {
  name: "producer-rating",

  components: {
    MonthRangePicker,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    // MonthPicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    parseEmpty: (value) => !value ? '-' : value,
  },

  mixins: [ReportMixin],
  props: {
    view: String,
  },

  data() {
    return {
      loading: false,

      filter: {
        project: {
          description: null,
          id: '',
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],
        min: moment().add('years', -2).format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),

        curve: {
          value: '',
          text: ''
        },

        technician: {
          id: "",
          description: ""
        },
        routes: [],

        volume: false,

        search: null,
      },
      months: [],

      tab: 0,

      projectList: [],

      producers: [],

      tabs: [],

      curveItem: [],

      ranges: [],

      producerStatus: [],

    };
  },

  created() {
    this.loadProject();

    this.producerStatus = [
      { text: 'Ativos', value: 'ativo' },
    ];
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onFilterDate() {
      this.onLoad();
    },
    async onLoad() {
      if (!_.isEmpty(this.tabs)) {
        if (this.tabs[this.tab].id_meta_bpa) {
          this.loadProducerRating();
        }
      }
    },

    async loadRanges() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/rangesBpa`,
          {
            id_assistencia: this.filter.project.id,
          }
        );

        let curve = [...new Set(data.flatMap(o => o.tipo))];
        this.curveItem = curve.map((c) => {
          return {
            text: ratingService.types[c],
            value: c,
          }
        });
        this.ranges = data;
        this.filter.curve = _.last(this.curveItem);
        await this.loadTabs();
      } catch (error) {
        console.warn(error);
      }

    },

    async loadTabs() {
      this.tabs = [];
      if (!this.filter.curve) {
        return false;
      }
      try {
        //monta aba com as faixas da curva escolhida
        const data = this.ranges.filter(c => c.tipo === this.filter.curve.value);

        this.tabs = data.map((c) => {
          let IndicadoresTitulo = '';
          if (c.indicador) {//curva simples
            IndicadoresTitulo  = c.indicador;
          } else { //combinadas

            let metas_agrupadas = JSON.parse(c.metas_agrupadas || '') || [];
            let indicadores = [...new Set(metas_agrupadas.flatMap(o => o.indicator.indicator))];
            indicadores.forEach((i) => {
              IndicadoresTitulo  = IndicadoresTitulo + ' [' + i + '] ';
            });
          }

          return {
            title: c.nome_faixa,
            id_meta_bpa: c.id_meta_bpa,
            tipo: ratingService.types[c.tipo],
            items: [],
            headers: [],
            indicadores: IndicadoresTitulo,
          }
        });

      } catch (error) {
        console.warn(error);
      }
    },

    async loadDateRange() {
      this.filter.min = moment().add('years', -2).format('YYYY-MM');
      this.filter.max = moment().format('YYYY-MM');
      this.filter.range = [moment().format('YYYY-MM'), moment().format('YYYY-MM')];
    },

    async loadProject() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy",
            view: 'rating',
          }
        );

        this.projectList = data.map((proj) => {
          return {
            text: proj.descricao,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadDateRange();
          this.loadRanges();
          this.tab = 0;
        }

      } catch (error) {
        console.warn(error);
      }
    },

    async loadProducerRating() {
      try {

        this.loading = true;
        let id_meta_bpa   = this.tabs[this.tab].id_meta_bpa;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/listaProdutoresClassificadosPeriodo`,
          {
            data_ini: moment(this.filter.range[0]).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.range[1]).endOf('month').format('YYYY-MM-DD'),
            id_faixa: id_meta_bpa,
            id_pessoa: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            produtor_status: this.producerStatus ? _.mapValues(this.producerStatus, 'value') : '*',
          }
        );

        if (!_.isArray(data)) {
          throw data;
        }

        const h = [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Nome', value: 'nome_produtor' },
          { text: 'Rota', value: 'rota_principal' },
        ];

        _.first(data).meses.forEach(m => {
          h.push({ text: moment(m, 'YYYY-MM').format("MMM/YY"), value: `m${m}`, align: 'center', width: 80});
        });

        h.push({ text: 'Soma Final', value: 'soma_final', align: 'center'  });
        h.push({ text: 'Média Final', value: 'media_final', align: 'center'  });
        h.push({ text: 'Status', value: 'status',  });

        this.tabs[this.tab].headers = h;

        this.producers = data.map(p => {

          //mapeia os meses que tem media gerada para mostrar
          p.meses.map((a) => {
            const result = p.medias.find((h) => h.mes === a);
            if (result) {
              eval(`p.m${a}= '${(this.formatNumber(result.media))}'`);
            } else {
              eval(`p.m${a}= '-'`);
            }
          })

          return {
            ...p,
            status: {
              description: p.status,
              date_end: p.data_saida ? moment(p.data_saida, 'YYYY-MM-DD').format("DD/MMM/YY")  : '',
            },
          }
        });

        if (_.isEmpty(this.producers)) {
          return;
        }

        if (_.first(this.producers).media_final > 0) {
          this.tabs[this.tab].items = _.orderBy( this.producers, 'media_final', 'desc');
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtores!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
