<template>
  <span
    v-if="rawMaterialName"
    class="d-block text-body-2 white--text"
    style="margin-top: -10px"
  >
    {{ rawMaterialName }}
  </span>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import axios from '@/Support/Resources/axios-instance.js'

// eslint-disable-next-line
const props = defineProps({
  rawMaterialId: String
})

const rawMaterialName = ref(null)

watchEffect(async () => {
  if (!props.rawMaterialId) {
    return
  }

  try {
    const { data } = await axios.get(`/item/${props.rawMaterialId}`);

    rawMaterialName.value = data.nome;

  } catch (error) {
    console.warn(error);
  }
})
</script>
