<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Ações Emergenciais
    </v-card-title>
    <v-card-text
      align="left"
    >
      <v-row
        v-for="(goal, id) in goals"
        :key="id"
        class="pt-3"
      >
        <v-col
          cols="12"
          md="2"
        >
          {{ goal.status }}
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="pt-2"
        >
          <v-select
            v-if="id != 'CRITICAL'"
            v-model="goal.field"
            :items="id==='PRIORITY' ? fiedlsCPP : fiedlsCCS"
            item-value="value"
            item-text="text"
            dense
            hide-details
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="py-0"
        >
          <v-text-field
            v-model="goal.visits"
            placeholder="0"
            type="number"
            dense
            outlined
            hide-details
            @keypress="disableDotAndComma"
          />
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="pt-2"
        >
          <v-select
            v-model="goal.type"
            :items="items"
            item-value="value"
            item-text="text"
            dense
            hide-details
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
        </v-col>
        <v-col
          md="5"
          class="py-1"
        >
          <v-select
            v-model="goal.questionnaires"
            label="Questionários"
            :items="questionnaires"
            prepend-inner-icon="icon-cadastros"
            item-value="id"
            item-text="title"
            multiple
            outlined
            small-chips
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  components: {

  },

  props: {
    value: Object
  },

  data() {
    return {
      DateMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },

      items: [
        { text: '%', value: 'PERCENT' },
        { text: 'UN', value: 'UN' }
      ],
      questionnaires: [],

      fiedlsCPP: [
        { text: 'Geometrica trimestral CPP ', value: 'cbt_geometrica' },
        { text: 'Geometrica mensal CPP ', value: 'cbt_geometrica_mes' },
        { text: 'Aritmética mensal CPP ', value: 'cbt_aritmetica' }
      ],

      fiedlsCCS: [
        { text: 'Geometrica trimestral CCS ', value: 'ccs_geometrica' },
        { text: 'Geometrica mensal CCS ', value: 'ccs_geometrica_mes' },
        { text: 'Aritmética mensal CCS ', value: 'ccs_aritmetica' }
      ],

    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

  },
  created() {
    this.loadQuestionnaires();
  },

  methods: {
    async loadQuestionnaires() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_PADRAO', 'VISITA_IN77', 'VISITA_IN59', 'VISITA_CPP', 'VISITA_CCS'],
            ativo: 1,
          }
        );

        this.questionnaires = data.map(item => {
          let text = item.titulo ? item.titulo : ``;
          text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
          text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;
          return {
            id: item.id,
            title: text,
            types: item.tipo,
          }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),

  },
};
</script>
