<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      width="80%"
      persistent
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card class="order-dialog">
        <v-card-title>
          <span class="text-h6">Apontamentos de Ordem de Produção nº {{ order.code }}</span>
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="close()"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle
          v-if="step?.description"
          class="pb-2"
        >
          Etapa atual: <b>{{ step.description }}</b>
        </v-card-subtitle>

        <div class="mx-6">
          <v-tabs
            v-model="tab"
          >
            <v-tab
              v-for="menuItem of menu"
              :key="menuItem.view"
              :href="`#${menuItem.view}`"
            >
              <v-icon left>
                {{ menuItem.icon }}
              </v-icon>
              {{ menuItem.description }}
            </v-tab>
          </v-tabs>
        </div>

        <v-card-text>
          <div style="min-height:400px">
            <v-tabs-items
              v-model="tab"
              touchless
              class="mt-2"
            >
              <v-tab-item value="raw-material-write-off">
                <raw-material-write-off
                  v-if="show && order.id && !reloading"
                  ref="raw-material-write-off"
                  :order="order"
                  :can-edit="canEdit"
                  :step="step"
                  @reload="reload"
                  @onSiloProductionTransferring="onSiloProductionTransferring"
                />
              </v-tab-item>

              <v-tab-item value="product-write-off">
                <product-write-off
                  v-if="show && order.id && !reloading"
                  ref="product-write-off"
                  :order="order"
                  :can-edit="canEdit"
                  :step="step"
                  @reload="reload"
                />
              </v-tab-item>

              <v-tab-item
                v-for="custom of customMenus"
                :key="custom.id"
                :value="`${custom.id}-write-off`"
              >
                <custom-write-off
                  v-if="show && order.id && !reloading"
                  :ref="`${custom.id}-write-off`"
                  :custom="custom"
                  :order="order"
                  :can-edit="canEdit"
                  :step="step"
                  @reload="reload"
                />
              </v-tab-item>
            </v-tabs-items>
            <v-col
              v-if="menu.length === 0"
              cols="12"
              class="text-center"
            >
              Nenhum apontamento configurado para esta etapa
            </v-col>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>

          <v-spacer />

          <v-btn
            color="primary"
            outlined
            :disabled="!canEdit"
            @click="save()"
          >
            Apontar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <production-transfer-dialog
      ref="productionDialog"
      order-disabled
      @onSiloTransferred="reload()"
    />
  </div>
</template>

<script>
import RawMaterialWriteOff from '@/Domains/Industry/ProductionOrder/Components/RawMaterialWriteOff.vue';
import ProductWriteOff from '@/Domains/Industry/ProductionOrder/Components/ProductWriteOff.vue';
import CustomWriteOff from '@/Domains/Industry/ProductionOrder/Components/CustomWriteOff.vue';
import ProductionTransferDialog from '@/Domains/Platform/Silos/Components/ProductionTransferDialog.vue';

import get from 'lodash/get';

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

export default {

  components: {
    RawMaterialWriteOff,
    ProductWriteOff,
    CustomWriteOff,
    ProductionTransferDialog,
  },

  props: {
    value: Boolean,
    orderId: String,
    step: Object,
  },

  data() {
    return {
      menu: [],

      tab: null,

      reloading: false,

      rawMaterialMenu: {
        icon: 'conveyor_belt',
        description: 'Saídas de Produção',
        view: 'raw-material-write-off',
      },

      productMenu: {
        icon: 'outbox',
        description: 'Saídas de Insumos',
        view: 'product-write-off',
      },

      customMenus: [],

      order: {
        items: []
      }
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    canEdit() {
      return ['PLANEJADO', 'LIBERADO'].includes(this.order.status)
    },

  },

  watch: {
    show(value) {
      if (value) {
        this.order = {
          items: []
        };

        this.menu = [];

        this.tab = null;

        this.customMenus = [];

        this.loadOrder();
      }
    }
  },

  methods: {
    async loadOrder() {
      try {
        this.$root.$progressBar.loading();

        this.order = await api.show(this.orderId);

        if (this.reloading) {
          return;
        }

        const hasRawMaterial = this.order.items.find(data => data.item.isRawMaterial && (!data.step || !this.step || data.step.id === this.step?.id))
        const hasProduct = this.order.items.find(data => !data.item.isRawMaterial && (!data.step || !this.step || data.step.id === this.step?.id))

        if (hasRawMaterial) {
          this.menu.push(this.rawMaterialMenu);
        }

        if (hasProduct) {
          this.menu.push(this.productMenu);
        }

        if (this.order.item?.customs) {
          this.customMenus = this.order.item.customs
            .filter(data => !data.step || !this.step || data.step.id === this.step?.id);

          this.menu.push(...this.customMenus.map(custom => ({
            icon: 'queue',
            description: custom.title,
            view: `${custom.id}-write-off`,
          })));
        }

      } catch (e) {
        const message = get(e, 'response.data.message', 'Erro ao carregar a ordem de produção');
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onSiloProductionTransferring(transferring) {
      if (!this.canEdit) {
        return;
      }

      return this.$refs.productionDialog.show(transferring);
    },

    async reload() {
      this.reloading = true;
      await this.loadOrder();
      this.reloading = false;
    },

    close() {
      this.show = false;
    },

    save() {
      const ref = this.$refs[this.tab];

      Array.isArray(ref) ? ref[0].save() : ref.save();
    },

  }

}
</script>
