<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="700"
  >
    <v-card>
      <v-card-title class="text-h5">
        Aprovação
      </v-card-title>
      <v-card-subtitle class="mt-1">
        <b>{{ desc }}</b>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
            :style="{position: 'relative'}"
          >
            <v-overlay
              :value="disabled"
              absolute
              :opacity="0"
              :style="{cursor: 'not-allowed'}"
            />
            <v-btn-toggle
              v-model="approval"
            >
              <v-btn value="PENDENTE">
                <span class="hidden-sm-and-down">PENDENTE</span>

                <v-icon
                  right
                  color="orange darken-1"
                >
                  pending_actions
                </v-icon>
              </v-btn>

              <v-btn value="APROVADO">
                <span class="hidden-sm-and-down">APROVADO</span>

                <v-icon
                  right
                  color="green darken-1"
                >
                  verified
                </v-icon>
              </v-btn>

              <v-btn value="REJEITADO">
                <span class="hidden-sm-and-down">REJEITADO</span>

                <v-icon
                  right
                  color="red darken-1"
                >
                  cancel
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            v-if="fields.length > 0"
            cols="12"
          >
            <v-card
              class="mx-auto"
            >
              <v-list>
                <template
                  v-for="(field, idx) in fields"
                >
                  <v-divider
                    v-if="idx > 0"
                    :key="`divider-${idx}`"
                  />
                  <v-list-item
                    v-if="!field.items"
                    :key="idx"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row>
                          <v-col
                            cols="7"
                            class="py-0"
                          >
                            {{ field.description }}
                          </v-col>
                          <v-col
                            cols="2"
                            :class="['pa-0 text-body-2', {'red--text': field.condition == '!=', 'orange--text': field.condition == '>' }]"
                          >
                            {{ field.value }}
                          </v-col>
                          <v-col
                            cols="1"
                            class="py-0 text-body-2"
                          >
                            {{ field.condition }}
                          </v-col>
                          <v-col
                            cols="2"
                            class="pa-0 text-body-2 green--text"
                          >
                            {{ field.default }}
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-group
                    v-else
                    :key="idx"
                    :value="true"
                  >
                    <template #activator>
                      <v-list-item-content>
                        <v-list-item-title v-text="field.description" />
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-for="(item, i) in field.items"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row>
                            <v-col
                              cols="7"
                              class="py-0"
                            >
                              <span class="ml-3">
                                {{ item.description }}
                              </span>
                            </v-col>
                            <v-col
                              cols="2"
                              :class="['pa-0 text-body-2', {'red--text': field.condition == '!=', 'orange--text': field.condition == '>' }]"
                            >
                              {{ item.value }}
                            </v-col>
                            <v-col
                              cols="1"
                              class="py-0 text-body-2"
                            >
                              {{ field.condition }}
                            </v-col>
                            <v-col
                              cols="2"
                              class="pa-0 text-body-2 green--text"
                            >
                              {{ item.default }}
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </template>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h3 class="text-h6 text-center">
              {{ selectedProducer.title }}
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p
              class="text-justify"
              v-html="selectedProducer.replacedText"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="green darken-1"
          outlined
          :disabled="disabled"
          :loading="loading || saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import isObject from "lodash/fp/isObject";

export default {

  props: {
    value: Boolean,
    id: String,
    producerDocuments: [],
  },

  data() {
    return {
      loading: false,
      saving: false,

      desc: '',
      approval: null,
      status: null,

      fields: [],

      returnApprovalData: false,
      producerApprovalDocumentId: '',

      selectedProducer: '',
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    disabled() {
      return this.status === null || this.status === 'VENDA'
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.load();
      }
      else {
        this.desc = '';
        this.approval = null;
        this.fields = [];
      }
    }
  },

  methods: {
    async load() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(`/registrations/producerApprovalDocument`, {
          params: {
            id_documento_produtor: this.id
          }
        });

        if (!isObject(data)) {
          throw data;
        }

        this.approval = 'PENDENTE';
        this.fields = [];

        if (data.length > 0) {
          this.returnApprovalData = true;
          this.producerApprovalDocumentId = data[0].id_documento_produtor_aprovacao;
          this.approval = data[0].aprovacao;
          this.fields = data[0].campos_aprovacao || [];
        }

        this.selectedProducer = this.producerDocuments.find(item => item.id === this.id);

        this.desc = `${this.selectedProducer.producer.name} - ${this.selectedProducer.title} - ${this.selectedProducer.status}`;
        this.status = this.selectedProducer.status;
      }
      catch (error) {
        this.$snotify.error("Erro ao alterar aprovação", "Atenção");
        console.warn(error)
        this.close();
      }
      finally {
        this.loading = false;
      }
    },

    close() {
      this.show = false;
      this.loading = false;
      this.saving = false;
      this.$emit('close');
    },

    async save() {
      try {
        this.saving = true;
        let route = `/registrations/producerApprovalDocument`;
        let method = 'post';

        if (this.returnApprovalData) {
          route = `/registrations/producerApprovalDocument/${this.producerApprovalDocumentId}`;
          method = 'put';
        }

        let { data } = await this.$axios({
          method: method,
          url: route,
          data: {
            id_documento_produtor: this.id,
            aprovacao: this.approval,
          }
        })

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Aprovação feita com sucesso", "Sucesso");
        this.$emit('save');
      } catch (error) {
        this.$snotify.error("Erro ao alterar aprovação", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
        this.show = false;
      }
    },
  }
}
</script>