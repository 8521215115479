<template>
  <div>
    <v-autocomplete
      v-model="computedModel"
      :loading="loading"
      :items="computedItems"
      item-text="description"
      item-value="id"
      multiple
      dark
      prepend-inner-icon="local_shipping"
      label="Equipamentos"
      return-object
      hide-details
      filled
      @change="onChange"
    >
      <template v-slot:selection="{ attr, on, item, selected }" />

      <template v-slot:item="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item-icon
              class="mr-3"
              v-on="on"
            >
              <v-icon
                :class="{
                  'green--text': item.utilization > 0 && item.utilization < 90,
                  'red--text': item.utilization > 90,
                }"
                v-text="item.cargoType === 'milk' ? 'local_shipping' : 'directions_car'"
              />
            </v-list-item-icon>
            <v-list-item-content v-on="on">
              <v-list-item-title>
                {{ item.description }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.utilization">
                Utilização: {{ item.utilization }}%
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.cost">
                Custo/Litro: R$ {{ item.cost | number }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <template v-if="!item.consultancyType">
            Capacidade do Veículo: {{ item.capacity }} L <br>
            Km/L: {{ item.averageFuelConsumption | number }}
          </template>
        </v-tooltip>
      </template>
    </v-autocomplete>
    <v-list
      v-if="computedModel.length > 0"
      flat
      dark
      dense
      class="text-left overflow-y-auto"
      style="background-color: rgba(255, 255, 255, 0.08); max-height: 190px;"
    >
      <v-list-item-group>
        <template v-for="(item, index) in computedModel">
          <v-hover
            :key="index"
            v-slot:default="{ hover }"
          >
            <v-list-item class="pl-3">
              <v-list-item-icon class="ml-0 mr-3">
                <v-icon
                  :class="{
                    'green--text': item.utilization > 0 && item.utilization < 90,
                    'red--text': item.utilization > 90,
                  }"
                  v-text="item.cargoType === 'milk' ? 'local_shipping' : 'directions_car'"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ (item.description || "").toUpperCase() }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.utilization && item.cargoType === 'milk'">
                  Utilização: {{ item.utilization }}%
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.cost && item.cargoType === 'milk'">
                  Custo/Litro: R$ {{ item.cost | number }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  x-small
                  icon
                  dark
                  color="white"
                  @click="onRemoveItem(item)"
                >
                  <v-icon v-if="hover">
                    close
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-hover>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  filters: {

    number(num) {

      const formattedNum = parseFloat(num);

      if (!formattedNum) {
        return '0.0';
      }

      return formattedNum.toFixed(2);
    },

  },

  props: {
    value: {
      type: Array,
    },
    vol: {
      type: Number,
    },
    types: {
      type: Array,
    },
    stats: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // Items do Formulário (Autocomplete)
      items: [],
    };
  },

  computed: {
    computedItems() {
      const allowedCargos = this.types.map(type => type.allowedCargo);

      if (_.isEmpty(allowedCargos)) {
        return this.parseItems(this.items);
      }

      const filteredItems = this.items.filter(item => allowedCargos.includes(item.cargoType) && item.description !== "JULIETA | JULIETA");

      return this.parseItems(filteredItems);
    },

    computedModel: {
      get() {
        return this.parseItems(this.value);
      },
      set(input) {
        return this.onChange(input);
      },
    },
  },

  watch: {
    value() {
      this.input = this.value;
    },
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/equipamento/listaJson`,
          this.$qs.stringify({
            campos: '["nome", "id_equipamento", "capacidade"]',
          })
        );

        if (_.isString(data)) {
          throw data;
        }

        this.items = data.map(eqp => {
          let tipo_carga = '';
          if (eqp.tipo_carga === "consultoria") {
            tipo_carga = "consultoria"
          }

          return {
            id: eqp.id_equipamento,
            description: (eqp.nome || "").toUpperCase(),
            capacity: parseInt(eqp.capacidade) || 0,
            cargoType: eqp.tipo_carga === "leite" ? "milk" : "general",
            averageFuelConsumption: parseFloat(eqp.km_litros) || 0.0,
            consultancyType: tipo_carga,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os equipamentos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    parseItems(items) {
      return items.map(item => {

        let newItem = {
          ...item,
        };

        const utilization = ((this.vol / item.capacity) * 100);
        const cost = ((this.stats.distance / item.averageFuelConsumption) * this.params.dieselCostPerLitre) / this.vol;

        if (_.isFinite(utilization) && utilization > 0) {
          newItem = {
            ...newItem,
            utilization: utilization.toFixed(0),
          }
        }

        if (_.isFinite(cost) && cost > 0) {
          newItem = {
            ...newItem,
            cost,
          }
        }

        return newItem;
      });
    },

    onRemoveItem(item) {
      const newList = this.input.filter(input => input.id !== item.id);

      return this.onChange(newList);
    },

    onChange(event) {
      return this.$emit("input", event);
    },
  }
};
</script>
