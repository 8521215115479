<template>
  <recommendation-index
    type="VETERINARIO"
  />
</template>

<script>
import RecommendationIndex from "@/Domains/Registrations/Cares/Recommendation/RecommendationIndex.vue";

export default {
  components: {
    RecommendationIndex
  },
}
</script>
