<template>
  <div>
    <v-card
      class="graph-card"
      color="transparent"
      dark
    >
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text class="mb-3">
        <v-row>
          <v-col
          >
            Produtores ativos/suspensos: <b> {{ totalProducers }} </b>
          </v-col>
        </v-row>
        <v-expansion-panels dark>
          <v-expansion-panel v-for="panel in panels" :key="panel.title" style="background: none">
            <v-expansion-panel-header>{{ panel.title }} {{ panel.data.length }} produtores ({{ panel.percent }}%)</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="filteredHeaders(panel.isDetailed)"
                :items="panel.data"
                class="elevation-1"
                :items-per-page="-1"
                hide-default-footer
              >
                <template #[`item.inventario.total`]="{item}">
                  {{ somarInventario(item.inventario.inventario) }}
                </template>
                <template #[`item.status`]="{value}">
                  <v-chip
                    v-if="value == 'DESVINCULADO'"
                    color="red"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>cancel</v-icon>
                    </v-avatar>
                    D
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'SUSPENSO'"
                    color="orange"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    S
                  </v-chip>
                  <v-chip
                    v-else-if="value == 'LEAD' || value == 'NEGOCIACAO' || value == 'APROVACAO' || value == 'REPROVADO'"
                    color="blue"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>remove_circle</v-icon>
                    </v-avatar>
                    P
                  </v-chip>
                  <v-chip
                    v-else
                    color="green"
                    small
                  >
                    <v-avatar
                      left
                      small
                    >
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    A
                  </v-chip>
                </template>

              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { round, percentageBy } from "@/Support/Resources/utils";

export default {
  props: {
    title: String,
    type: String,
    data: {
      type: Array,
      deep: true
    },
  },

  data() {
    return {
      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome' },

        { text: 'Data', value: 'vacinas.data', align: 'center' },
        { text: 'Qtd. Animais', value: 'vacinas.quantidade_animais', align: 'center' },
        { text: 'Veterinário', value: 'vacinas.veterinarian.nome', align: 'center' },

        { text: 'Data', value: 'brucelose.data', align: 'center' },
        { text: 'Qtd. Animais', value: 'brucelose.quantidade_animais', align: 'center' },
        { text: 'Veterinário', value: 'brucelose.veterinarian.nome', align: 'center' },
        { text: 'Vencimento', value: 'brucelose.data_vencimento', align: 'center' },

        { text: 'Data', value: 'tuberculose.data', align: 'center' },
        { text: 'Qtd. Animais', value: 'tuberculose.quantidade_animais', align: 'center' },
        { text: 'Veterinário', value: 'tuberculose.veterinarian.nome', align: 'center' },
        { text: 'Vencimento', value: 'tuberculose.data_vencimento', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
      ],

      notExam: [],
      expired: [],
      comingDue: [],
      regular: [],

      percentNotExam: 0,
      percentExpired: 0,
      percentComingDue: 0,
      percentRegular: 0,

      notVaccine: [],
      hasVaccine: [],

      percentNotVaccine: 0,
      percentHasVaccine: 0,

      notInventory: [],
      hasInventory: [],

      percentNotInventory: 0,
      percentHasInventory: 0,

      panels: [],
      totalProducers: 0,

      animalTypesList: [],
    };
  },

  async created() {
    await this.loadAnimalTypes();
    await this.loadExams();
  },

  watch: {
    data: {
      deep: true,
      handler: function() {
        this.loadExams();
      }
    }
  },

  methods: {
    async loadExams() {
      const today = moment().format("YYYY-MM-DD");
      this.totalProducers = this.data.length;

      if(this.type == 'brucelose' || this.type == 'tuberculose') {
        this.notExam = this.notHaveExam(this.data, this.type);
        this.expired = this.expiredExam(this.data, this.type, today);
        this.comingDue = this.comingDueExam(this.data, this.type, today);
        this.regular = this.regularExam(this.data, this.type, today);

        this.percentNotExam = round(percentageBy(this.totalProducers, this.notExam.length), 2);
        this.percentExpired = round(percentageBy(this.totalProducers, this.expired.length), 2);
        this.percentComingDue = round(percentageBy(this.totalProducers, this.comingDue.length), 2);
        this.percentRegular = round(percentageBy(this.totalProducers, this.regular.length), 2);

        this.panels = [
          { title: 'Sem Exames:', data: this.notExam, percent: this.percentNotExam, isDetailed: false },
          { title: 'Vencidos:', data: this.expired, percent: this.percentExpired },
          { title: 'À vencer:', data: this.comingDue, percent: this.percentComingDue },
          { title: 'Regular', data: this.regular, percent: this.percentRegular },
        ];

      } else if(this.type == 'vacinas') {
        this.notVaccine = this.notData(this.data, this.type);
        this.hasVaccine = this.hasData(this.data, this.type);

        this.percentNotVaccine = round(percentageBy(this.totalProducers, this.notVaccine.length), 2);
        this.percentHasVaccine = round(percentageBy(this.totalProducers, this.hasVaccine.length), 2);

        this.panels = [
          { title: 'Sem Vacinas:', data: this.notVaccine, percent: this.percentNotVaccine, isDetailed: false },
          { title: 'Com Vacinas:', data: this.hasVaccine, percent: this.percentHasVaccine },
        ];
      } else if(this.type == 'inventario') {
        this.notInventory = this.notData(this.data, this.type);
        this.hasInventory = this.hasData(this.data, this.type);

        this.percentNotInventory = round(percentageBy(this.totalProducers, this.notInventory.length), 2);
        this.percentHasInventory = round(percentageBy(this.totalProducers, this.hasInventory.length), 2);

        this.panels = [
          { title: 'Sem Inventário:', data: this.notInventory, percent: this.percentNotInventory, isDetailed: false },
          { title: 'Com Inventário:', data: this.hasInventory, percent: this.percentHasInventory },
        ];
      }
    },

    notData(data, type) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => !item[exam]);
      return this.formatExams(exams());
    },

    hasData(data, type) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam]);
      return this.formatExams(exams());
    },

    notHaveExam(data, type) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => !item[exam]);
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    expiredExam(data, type, today) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento < today));
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    comingDueExam(data, type, today) {
      const coming = moment().add(30, "days").format("YYYY-MM-DD");
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento < coming && item[exam].data_vencimento >= today));

      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    regularExam(data, type, today) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento > today));
      return this.formatExams(type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa'));
    },

    formatExams(data) {
      const format = (item, isVaccine = false) => {
        const { data, data_vencimento, quantidade_animais, ...rest } = item || {};
        return {
          data: data ? this.formatDate(data, 'DD/MM/YYYY') : (isVaccine ? 'Sem Vacina' : 'Sem Exame'),
          quantidade_animais: quantidade_animais || 0,
          data_vencimento: !isVaccine && data_vencimento ? this.formatDate(data_vencimento, 'DD/MM/YYYY') : null,
          ...rest
        };
      };

      return data.map(({brucelose, tuberculose, vacinas, ...rest}) => ({
        brucelose: format(brucelose),
        tuberculose: format(tuberculose),
        vacinas: format(vacinas, true),
        ...rest
      }));
    },

    formatDate(value, format) {
      if(!value) {
        return '';
      } else if(value == 'VENCIDO') {
        return 'Vencido - fora do intervalo';
      } else {
        return moment(value).format(format)
      }
    },

    async loadAnimalTypes() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('/projects/animals-type', { params: {
            deleted: true,
          } });

        this.animalTypesList = data.map(item => {
          return {
            id: item.id_tipo_animal,
            description: item.descricao,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar tipos de animal!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    filteredHeaders(isDetailed = true) {

      if (!this.type) {
        return this.headers;
      }


      if(this.type === 'inventario') {

        const width = 150;

        const headersDynamic = this.animalTypesList.map(function(field) {
          return { text: field.description, value: 'inventario.inventario.' + field.id, align: 'center', width: width};
        });

        const headers = [
          { text: 'Código', value: 'codigo_laticinio' },
          { text: 'Produtor', value: 'nome' },

          ... headersDynamic,
          { text: 'Total', value: 'inventario.total', align: 'center' },
          { text: 'Status', value: 'status', align: 'center' },
        ];

        if(!isDetailed) {
           return [
             { text: 'Código', value: 'codigo_laticinio' },
             { text: 'Produtor', value: 'nome' },
             { text: 'Status', value: 'status', align: 'center' },
           ];
        }

        return headers;
      }

      return this.headers.filter(item => {
        const typeExam = item.value.split(".")[0].toUpperCase();
        const typeExamVerify = (typeExam != 'BRUCELOSE' && typeExam != 'TUBERCULOSE' && typeExam != 'VACINAS' && typeExam != 'INVENTARIO');
        const typeExamFilter = (this.type.toUpperCase() == typeExam) && isDetailed;

        return typeExamFilter || typeExamVerify;
      });
    },

    somarInventario(inventario) {
      const inventarioData = inventario;
      let soma = 0;

      Object.values(inventarioData).forEach(valor => {
        const numero = parseInt(valor);
        if (!isNaN(numero)) {
          soma += numero;
        }
      });

      return soma;
    }
  },
}
</script>
