<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="800"
    scrollable
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="approval-dialog">
      <v-card-title class="text-h5">
        {{ producer.nome }}

        <v-btn
          v-if="hasInsertUpdateAccess"
          icon
          depressed
          class="ml-2"
          @click="editProducer()"
        >
          <v-icon>
            edit
          </v-icon>
        </v-btn>

        <v-spacer />

        <v-menu
          bottom
          right
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="showProducerVisits()"
            >
              <v-list-item-icon>
                <v-icon>event</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Visitas</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="hasPrintAccess"
              @click="printProducer()"
            >
              <v-list-item-icon>
                <v-icon>print</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Imprimir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-subtitle>
        <v-chip
          dark
          small
          :color="getColor(currentStatus)"
        >
          {{ getStatusName(currentStatus) }}
        </v-chip>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            v-if="isVisible('codigo_laticinio')"
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="producer.codigo_laticinio"
              prepend-inner-icon="subtitles"
              label="Código Laticínio"
              disabled
              dense
            />
          </v-col>
          <v-col
            v-if="isVisible('cnpj_cpf')"
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="producer.cnpj_cpf"
              prepend-inner-icon="assignment_ind"
              label="CPF/CNPJ do Titular"
              disabled
              dense
            />
          </v-col>
          <v-col
            v-if="isVisible('rg')"
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="producer.rg"
              prepend-inner-icon="assignment_ind"
              label="N° de Identidade (RG)"
              disabled
              dense
            />
          </v-col>
          <v-col
            v-if="isVisible('data_inicio_coleta')"
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="producer.data_inicio_coleta"
              prepend-inner-icon="event"
              label="Data de Início Coleta"
              disabled
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-btn-toggle
              v-model="producer.status"
              mandatory
            >
              <v-btn
                v-if="hasCaptationApprovalAccess"
                value="APROVADO_CAPTACAO"
                @click="onStatusChange('APROVADO_CAPTACAO')"
              >
                <span class="hidden-sm-and-down">Aprovado (Capt.)</span>

                <v-icon
                  right
                  color="green darken-1"
                >
                  verified
                </v-icon>
              </v-btn>

              <v-btn
                v-if="hasCaptationApprovalAccess"
                value="REPROVADO"
                @click="onStatusChange('REPROVADO')"
              >
                <span class="hidden-sm-and-down">Reprovado (Capt.)</span>

                <v-icon
                  right
                  color="red darken-1"
                >
                  cancel
                </v-icon>
              </v-btn>

              <v-btn
                v-if="hasQualityApprovaAccess"
                value="APROVADO_QUALIDADE"
                @click="onStatusChange('APROVADO_QUALIDADE')"
              >
                <span class="hidden-sm-and-down">Aprovado (Qual.)</span>

                <v-icon
                  right
                  color="green darken-1"
                >
                  verified
                </v-icon>
              </v-btn>

              <v-btn
                v-if="hasQualityApprovaAccess"
                value="REPROVADO_QUALIDADE"
                @click="onStatusChange('REPROVADO_QUALIDADE')"
              >
                <span class="hidden-sm-and-down">Reprovado (Qual.)</span>

                <v-icon
                  right
                  color="red darken-1"
                >
                  cancel
                </v-icon>
              </v-btn>

              <v-btn
                v-if="['APROVADO_CAPTACAO', 'APROVADO_QUALIDADE'].includes(currentStatus) && hasInsertUpdateAccess"
                value="VINCULAR"
                @click="editProducer(true)"
              >
                <span class="hidden-sm-and-down">Vincular</span>

                <v-icon
                  right
                  color="teal"
                >
                  link
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            cols="12"
          >
            <v-tabs>
              <v-tab href="#tab-historical-status">
                Histórico
              </v-tab>
              <v-tab href="#tab-quality">
                Qualidade e Volume
              </v-tab>
              <v-tab href="#tab-attachments">
                Anexos
              </v-tab>
              <v-tab
                href="#tab-questionnaires"
              >
                Questionários
              </v-tab>

              <v-tab-item value="tab-historical-status">
                <historical-status
                  :producer-id="producerId"
                  :producer="producer"
                  :history="producer.historico_status"
                  list-only
                  show-username
                />
              </v-tab-item>

              <v-tab-item value="tab-quality">
                <v-row>
                  <v-col
                    cols="6"
                    class="mb-0"
                  >
                    <v-text-field
                      v-model="producer.cbt"
                      type="number"
                      label="CPP"
                      disabled
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    class="mb-0"
                  >
                    <v-text-field
                      v-model="producer.ccs"
                      type="number"
                      label="CCS"
                      disabled
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    class="mb-0"
                  >
                    <v-text-field
                      v-model="producer.gordura"
                      type="number"
                      label="Gordura"
                      disabled
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    class="mb-0"
                  >
                    <v-text-field
                      v-model="producer.proteina"
                      type="number"
                      label="Proteína"
                      disabled
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    class="mb-0"
                  >
                    <v-text-field
                      v-model="producer.litros"
                      type="number"
                      label="Volume em litros"
                      disabled
                      dense
                    />
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item value="tab-attachments">
                <attachments-tab
                  :attachments="producer.anexos || []"
                />
              </v-tab-item>

              <v-tab-item value="tab-questionnaires">
                <v-data-table
                  :headers="questionnairesHeaders"
                  :items="producer.questionnaires || []"
                  @click:row="showQuestionnaireAnswers"
                >
                  <template #[`item.data_cadastro_app`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>

    <status-producer-dialog
      ref="statusProducerDialog"
      @save="onSaveProducerStatus"
    />

    <questionnaire-answers-dialog
      ref="questionnaireAnswersDialog"
    />
  </v-dialog>
</template>

<style lang="scss">
.approval-dialog {
  .theme--light.v-label--is-disabled, .theme--light.v-input--is-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
</style>

<script>
import QuestionnaireAnswersDialog from '@/Domains/Questionnaires/Components/QuestionnaireAnswersDialog.vue';
import StatusProducerDialog from '@/Domains/Producer/Management/Components/Dialogs/StatusProducerDialog.vue';
import HistoricalStatus from '@/Domains/Producer/Management/Components/Form/HistoricalStatus.vue';
import AttachmentsTab from '@/Domains/Registrations/Person/Components/AttachmentsTab.vue';

import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export default {

  components: {
    QuestionnaireAnswersDialog,
    StatusProducerDialog,
    HistoricalStatus,
    AttachmentsTab,
  },

  props: {
    value: Boolean,
    producerId: String,
  },

  data() {
    return {
      producer: {},
      currentStatus: null,

      questionnairesHeaders: [
        { text: 'Titulo', value: 'titulo' },
        { text: 'Data', value: 'data_cadastro_app' },
      ]
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    settings() {
      if (isEmpty(this.$store.state.settings.gerais.cadastro_produtor)) {
        return {}
      }
      return this.$store.state.settings.gerais.cadastro_produtor.pessoal;
    },

    /**
     * Permissões de tela
     */
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasInsertUpdateAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'producer-insert-edit' && o.tipo === 'COMPONENTE');
    },

    hasPrintAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'producer-print' && o.tipo === 'COMPONENTE');
    },

    hasCaptationApprovalAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'producer-prospection-captation-approval' && o.tipo === 'COMPONENTE');
    },

    hasQualityApprovaAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'producer-prospection-quality-approval' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.loadProducer();
      }
      else {
        this.producer = {};
        this.currentStatus = null;
        this.statusHistory = [];
      }
    }
  },

  methods: {
    async loadProducer() {
      try {
        this.$root.$progressBar.loading();

        const [{ data }, { data: questionnaires }] = await Promise.all([
          this.$axios.post(`/produtores/produtorDetalhe`, { id_pessoa: this.producerId }),
          this.$axios.get(`/questionnaires`, { params: { id_produtor: this.producerId } })
        ]);

        if (!isObject(data)) {
          throw data;
        }

        this.producer = {
          ...data,
          cbt: data.cbt ? parseFloat(data.cbt) : null,
          ccs: data.ccs ? parseFloat(data.ccs) : null,
          gordura: data.gordura ? parseFloat(data.gordura) : null,
          proteina: data.proteina ? parseFloat(data.proteina) : null,
          litros: data.litros ? parseFloat(data.litros) : null,
          data_inicio_coleta: data.data_inicio_coleta ? moment(data.data_inicio_coleta).format('DD/MM/YYYY') : null,
          data_entrada_laticinio: data.data_entrada_laticinio ? moment(data.data_entrada_laticinio).format('DD/MM/YYYY') : null,
          historico_status: JSON.parse(data.historico_status || '[]'),
          anexos: data.anexos,
          questionnaires: questionnaires,
        }
        // if (!this.hasCaptationApprovalAccess) {
        //   this.producer.anexos = data.anexos.filter(item => item.tipo === 'ANALISE_QUALIDADE');
        // }
        this.currentStatus = data.status;
      } catch (error) {
        this.$snotify.error('Erro ao carregar produtor', 'Atenção');
        console.warn(error)
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.show = false;
      this.$emit('close');
    },

    onStatusChange(status) {
      if (!['APROVADO_QUALIDADE', 'REPROVADO_QUALIDADE', 'APROVADO_CAPTACAO', 'REPROVADO'].includes(status) || status === this.currentStatus) {
        return;
      }

      const Title = {
        APROVADO_QUALIDADE: 'Aprovar Produtor (Qualidade)',
        REPROVADO_QUALIDADE: 'Reprovar Produtor (Qualidade)',
        APROVADO_CAPTACAO: 'Aprovar Produtor (Captação)',
        REPROVADO: 'Reprovar Produtor (Captação)',
      };

      return this.$refs.statusProducerDialog.show({
        title: Title[status],
        producer: {
          id: this.producerId,
          name: this.producer.nome,
        },
        form: {
          status: status,
          date: moment().toDate(),
          params: {
            showStatusSelect: false,
            showOtherReasons: ['REPROVADO_QUALIDADE', 'REPROVADO'].includes(status),
          },
        },
      });
    },

    onSaveProducerStatus() {
      this.show = false;
      this.$emit('save');
    },

    isVisible(field) {
      if (!this.settings[field]) {
        return true;
      }

      return this.settings[field].show;
    },

    getColor(status) {
      const colors = {
        NEGOCIACAO: 'blue darken-1',
        LEAD: 'orange darken-1',
        APROVACAO: 'teal darken-1',
        DESVINCULADO: 'grey darken-1',
        APROVADO_CAPTACAO: 'green darken-1',
        REPROVADO: 'red darken-4',
        APROVADO_QUALIDADE: 'green darken-1',
        REPROVADO_QUALIDADE: 'red darken-1',
      };

      return colors[status] || 'blue';
    },

    getStatusName(status) {
      const names = {
        NEGOCIACAO: 'Negociação',
        APROVACAO: 'Aprovação',
        LEAD: 'Observação',
        APROVADO_CAPTACAO: 'Aprovado (Captação)',
        REPROVADO: 'Reprovado (Captação)',
        APROVADO_QUALIDADE: 'Aprovado (Qualidade)',
        REPROVADO_QUALIDADE: 'Reprovado (Qualidade)',
        DESVINCULADO: 'Desvinculado',
      };

      return names[status] || 'Prospectado';
    },

    editProducer(openLink = false) {
      this.close();
      this.$emit('editProducer', { id_pessoa: this.producerId, nome: this.producer.nome, openLink });
    },

    showProducerVisits() {
      this.$emit('showProducerVisits', this.producerId);
    },

    printProducer() {
      this.$emit('printProducer', this.producerId);
    },

    showQuestionnaireAnswers(row) {
      return this.$refs.questionnaireAnswersDialog.show({
        id: row.id_formulario_customizado_cabecalho,
      });
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
</script>
