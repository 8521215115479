<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="bills-to-receive"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Fluxo de Caixa
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />
      <v-col md="4">
        <v-date-range-picker
          v-model="filter.range"
          dark
          @change="getBills"
        />
      </v-col>

      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            <v-spacer />
            <div class="d-flex">
              <div
                class="d-flex flex-column justify-end px-14"
                style="border-left: 2px solid #fff"
              >
                <span class="caption font-weight-light">
                  Saldo anterior
                </span>
                <span
                  class="body-1 font-weight-bold text--accent-3"
                  :class="{'green--text': previousBalance > 0, 'red--text': previousBalance < 0}"
                >R$ {{ previousBalance | formatCurrency }}</span>
              </div>
              <div
                class="d-flex flex-column justify-end px-14"
                style="border-left: 2px solid #fff"
              >
                <span class="caption font-weight-light">
                  Entradas
                  <v-icon
                    color="green accent-3"
                    small
                  >
                    south_west
                  </v-icon>
                </span>
                <span
                  class="body-1 font-weight-bold green--text text--accent-3"
                >R$ {{ incoming | formatCurrency }}</span>
              </div>
              <div
                class="d-flex flex-column justify-end px-14"
                style="border-left: 2px solid #fff;"
              >
                <span class="caption font-weight-light">
                  Saídas
                  <v-icon
                    color="red accent-3"
                    small
                  >
                    north_east
                  </v-icon>
                </span>
                <span
                  class="body-1 font-weight-bold red--text"
                >R$ {{ outcoming | formatCurrency }}</span>
              </div>
              <div
                class="d-flex flex-column justify-end px-14"
                style="border-left: 2px solid #fff"
              >
                <span class="caption font-weight-light">
                  Saldo em {{ filter.range[1] | dateFormat('DD/MM/YYYY') }}
                </span>
                <span
                  class="headline font-weight-bold text--accent-3"
                  :class="{'green--text': balance > 0, 'red--text': balance < 0}"
                >R$ {{ balance | formatCurrency }}</span>
              </div>
            </div>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            dark
            dense
            disable-pagination
            hide-default-footer
            class="elevation-1 report-table"
          >
            <template #[`item.type`]="{ value }">
              <v-icon
                v-if="value == 'RECEBIMENTO'"
                color="green accent-3"
              >
                south_west
              </v-icon>
              <v-icon
                v-else-if="value == 'PAGAMENTO'"
                color="red accent-3"
              >
                north_east
              </v-icon>
              <v-icon
                v-else-if="value == 'ESTORNO'"
                color="orange accent-3"
              >
                undo
              </v-icon>
            </template>
            <template #[`item.issueDate`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.paymentDate`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.amount`]="{ value, item }">
              <b :class="{'green--text': item.type == 'RECEBIMENTO', 'red--text': item.type == 'PAGAMENTO','orange--text': item.type == 'ESTORNO', 'text--accent-3': true}">
                R$ {{ value | formatCurrency }}
              </b>
            </template>
          </v-data-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.bills-to-receive {

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from "moment-timezone";

moment.locale('pt');

export default {
  name: "bills-to-receive",

  components: {
    VDateRangePicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      headers: [
        { text: '', value: 'type' },
        { text: 'Lançamento', value: 'paymentDate' },
        { text: 'Descrição', value: 'description' },
        { text: 'Pessoa', value: 'person' },
        { text: 'Valor', value: 'amount' },
      ],
      items: [],

      previousBalance: 0
    };
  },

  computed: {
    incoming() {
      return this.items.filter(o => o.amount > 0).reduce((acc, cur) => acc + cur.amount, 0);
    },
    outcoming() {
      return this.items.filter(o => o.amount < 0).reduce((acc, cur) => acc + cur.amount, 0);
    },
    balance() {
      return parseFloat(this.previousBalance) + this.items.reduce((acc, cur) => acc + cur.amount, 0);
    }
  },

  methods: {
    async getBills() {
      const [startDate, endDate] = this.filter.range;

      const { data } =  await this.$axios.post(
        `/financeiro/fluxoCaixaJson`,
        { tipo: 'CONTA_A_RECEBER', data_inicio: startDate, data_fim: endDate }
      );

      this.previousBalance = data.saldo_anterior || 0;

      this.items = data.lancamentos.map(o => ({
        id: o.id_lancamento,
        accountId: o.id_titulo,
        code: o.cod_parcela,
        person: o.nome_produtor ? `Produtor: ${o.nome_produtor}` : o.nome_pessoa,
        description: o.descricao || o.observacao,
        paymentDate: o.data_lancamento,
        amount: parseFloat(o.valor),
        type: o.tipo,
      }))
    },
  },
};
</script>
