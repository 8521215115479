<template>
  <VisitsChart
    title="Zootécnico"
    :data="data"
    :color="color"
    :loading="loading"
  />
</template>

<script>
import VisitsChart from "@/Domains/BI/Components/VisitsChart.vue";

import moment from 'moment';
import _ from 'lodash';

export default {

  components: {
    VisitsChart
  },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      data: {
        technician: {
          category: [],
          visits: [],
          goals: [],
          report: [],
        },
        monthly: {
          category: [],
          series: [],
          report: [],
        }
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(
          `/relatorioVisitaMeta/zootecnico`,
          this.$qs.stringify({
            data_ini: moment(startDate).format('DD/MM/YYYY'),
            data_fim: moment(endDate).format('DD/MM/YYYY'),
          })
        );

        if (!_.isObject(data) ) {
          throw "PHP Errors";
        }

        this.data.technician.category = data.meta_zootecnico_tecnico.map(o => o.Técnico);
        this.data.technician.visits = data.meta_zootecnico_tecnico.map(o => o['Nº de Visitas']);
        this.data.technician.goals = data.meta_zootecnico_tecnico.map(o => o['Meta']);

        this.data.technician.report = data.meta_zootecnico_tecnico;

        this.data.monthly.category = data.total_zootecnico_mes.map(o => `${o.Mês}/${o.Ano}`);
        this.data.monthly.series = data.total_zootecnico_mes.map(o => o['Nº de Visitas']);

        this.data.monthly.report = data.total_zootecnico_mes;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de visitas Zootécnico!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
