<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    width="500px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card>
      <v-card-title
        class="pb-1"
      >
        Impressão de Checklists
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-list>
          <v-list-item
            v-for="(checklist, index) in checklists"
            :key="index"
            @click="print(checklist)"
          >
            <v-list-item-icon>
              <v-icon>receipt_long</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ checklist.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click="close()"
        >
          FECHAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import printJS from 'print-js';

export default {
  data() {
    return {
      dialog: false,
      checklists: [],
      startDate: null,
      endDate: null,
    }
  },

  methods: {
    show(startDate = null, endDate = null) {
      this.dialog = true;
      this.startDate = startDate;
      this.endDate = endDate;
      this.loadChecklists();
    },

    async loadChecklists() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/questionnaires/forms`, { params: {
          objeto: 'DESCARGA',
        } });

        this.checklists = data.map(item => ({
          id: item.id_formulario,
          title: item.titulo,
        }));
      } catch (error) {
        this.$snotify.error('Erro ao carregar checklists', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.dialog = false;
      this.checklists = [];
    },

    async print({ id }) {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/questionnaires/checklist-platform`, { params: {
          id_formulario: id,
          data_inicio: this.startDate,
          data_fim: this.endDate,
        } });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.warn(e);
        this.$snotify.error('Oops, ocorreu um erro ao imprimir!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
        this.close();
      }
    }
  }
}
</script>
