<template>
  <div class="mx-2">
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Spots
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab>Programação Spot de Entrada</v-tab>
          <v-tab>Programação Spot de Saída</v-tab>
          <v-tab>Programação Leite</v-tab>

          <v-tab-item>
            <SpotPlanning type="ENTRADA" />
          </v-tab-item>

          <v-tab-item>
            <SpotPlanning type="SAIDA" />
          </v-tab-item>

          <v-tab-item>
            <MilkPlanning />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpotPlanning from '@/Domains/Platform/Spots/Views/SpotPlanning.vue'
import MilkPlanning from '@/Domains/Platform/Spots/Views/MilkPlanning.vue'

export default {
  name: "spots",

  components: {
    SpotPlanning,
    MilkPlanning,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>