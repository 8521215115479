<template>
  <v-dialog
    v-model="show"
    max-width="630px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">
          Importar laticínio
        </span>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        @click:row="selectDairy"
      />

      <v-card-actions>
        <v-btn
          color="grey darken-1"
          outlined
          @click="show = false"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isArray from "lodash/fp/isArray";

export default {

  data() {
    return {
      show: false,

      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Cidade', value: 'cidade' },
        { text: 'Estado', value: 'uf' },
      ],

      items: [],
    }
  },

  methods: {
    async search(name) {
      try {
        const nome = (name || '').trim();

        if (!nome) {
          return;
        }

        this.$root.$progressBar.loading();

        this.show = true;

        const { data } = await this.$queries.post(`/laticinio/listaJson`, { nome });

        if (!isArray(data)) {
          throw 'Error';
        }

        this.items = data.map(item => ({
          id: item.id_laticinio,
          nome: item.nome,
          endereco: item.endereco,
          cidade: item.cidade,
          uf: item.uf,
        }));

      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os laticínios!",
          "Atenção"
        );
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    selectDairy(dairy) {
      const endereco = dairy.endereco || '';

      // let rx = '';
      let res, rua = '', numero = '', bairro = '', cidade = '', uf = '', cep = '';

      if (!res) {
        //Rua, Numero, Bairro, Cidade, UF, CEP
        //Ex: R Gomes Cardim, 532, Sala 1 - Bras, Sao Paulo, SP, CEP 03050-000, Brasil
        const regex = /([^-,\n]+), (\d+), (.*?), ([^-,\n]+), ([^-,\n]+), CEP (\d{5}-\d+), Brasil/;

        res = endereco.match(regex);

        if (res) { // rx = 1;
          [, rua, numero, bairro, cidade, uf, cep] = res;
        }
      }

      if (!res) {
        //Rua, Numero, Bairro, Cidade, UF, CEP
        //Ex: R. Ten. Petronilo Diogo Silva, 251 - Bom Pastor, Natal - RN, 59060-11
        const regex = /([^-,\n]+), (\d+) - ([^-,\n]+), ([^-,\n]+) - ([^-,\n]+), (\d{5}-\d+)/;

        res = endereco.match(regex);

        if (res) { // rx = 2;
          [, rua, numero, bairro, cidade, uf, cep] = res;
        }
      }

      if (!res) {
        //Rua, Numero, Cidade, UF, CEP
        //Ex: R. Geminiano Costa, 95, São Carlos - SP, 13560-649
        //Ex: BR-163, 5, Mundo Novo - MS, 79980-000
        const regex = /([^,\n]+), (\d+), ([^-,\n]+) - ([^-,\n]+), (\d{5}-\d+)/;

        res = endereco.match(regex);

        if (res) { // rx = 3;
          [, rua, numero, cidade, uf, cep] = res;
        }
      }

      if (!res) {
        //Rua, Bairro, Cidade, UF, CEP
        //Ex: Rodovia PR 460 - s/n Km 167, Pitanga - PR, 85200-000
        const regex = /([^-,\n]+) - ([^-,\n]+), ([^-,\n]+) - ([^-,\n]+), (\d{5}-\d+)/;

        res = endereco.match(regex);

        if (res) { // rx = 4;
          [, rua, bairro, cidade, uf, cep] = res;
        }
      }

      if (!res) {
        //Rua, Numero, Cidade, UF, CEP
        //Ex: R. Maranata, 1-125, Jussara - GO, 76270-000
        const regex = /([^-,\n]+), (.*?), ([^-,\n]+) - ([^-,\n]+), (\d{5}-\d+)/;

        res = endereco.match(regex);

        if (res) { // rx = 5;
          [, rua, numero, cidade, uf, cep] = res;
        }
      }

      if (!res) {
        //Rua, Bairro, UF, CEP
        //Ex: Rua Projetada - s/n Qd 250 lt 559, PB, 58056-970
        const regex = /([^-,\n]+) - ([^-,\n]+), ([^-,\n]+), (\d{5}-\d+)/;

        res = endereco.match(regex);

        if (res) { // rx = 6;
          [, rua, bairro, uf, cep] = res;
        }
      }

      if (!res) {
        //Rua, Cidade, UF, CEP
        //Ex: Av. Rio Claro, Cachoeira Alta - GO, 75870-000
        const regex = /([^-,\n]+), ([^-,\n]+) - ([^-,\n]+), (\d{5}-\d+)/;

        res = endereco.match(regex);

        if (res) { // rx = 7;
          [, rua, cidade, uf, cep] = res;
        }
      }

      if (!res) {
        //Rua, Numero, Bairro, Cidade, UF, CEP
        //Ex: Rod Raposo Tavares, SN - Km 178 - Pinhal - Itapetininga/SP - 18.200-001
        const regex = /([^,\n]+), (.*?) - ([^-,\n]+) - ([^-,\n]+)\/([^-,\n]+) - ([^\n]+)/;

        res = endereco.match(regex);

        if (res) { // rx = 8;
          [, rua, numero, bairro, cidade, uf, cep] = res;
        }
      }

      if (!res) {
        [cep = ''] = endereco.match(/(\d{5}-\d{3})/) || [];

        rua = endereco.replace(cep, '').replace(', ', '')
      }

      // console.log({ rx, rua, numero, bairro, cidade, uf, cep, endereco });

      this.$emit('selected', {
        id: dairy.id,
        nome: dairy.nome,
        rua: rua,
        numero: numero,
        bairro: bairro,
        cidade: dairy.cidade || cidade,
        uf: dairy.uf || uf,
        cep: cep,
      });
      this.show = false;
    },
  }
}
</script>
