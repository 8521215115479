<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Produtos
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="pt-0"
              >
                <warehouse-select
                  v-model="filter.warehouseId"
                  label="Depósito"
                  filled
                  hide-details
                  auto-select-default
                  @change="loadProducts"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="pt-0"
              >
                <v-select
                  v-model="filter.types"
                  label="Tipo"
                  :items="[
                    { value: 'PRODUTO_ACABADO', text: 'Produto Acabado' },
                    { value: 'INSUMO_PRODUCAO', text: 'Insumos de Produção' },
                    { value: 'INSUMO_AGRICOLA', text: 'Insumos Agrícolas' },
                    { value: 'MATERIA_PRIMA', text: 'Matéria Prima' },
                  ]"
                  filled
                  hide-details
                  multiple
                  @change="loadProducts"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  filled
                  single-line
                  hide-details
                  dark
                />
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            ref="report"
            name="Produtos"
            :headers="headers"
            :items="items"
            :search="filter.search"
            dark
            @click:row="editItem"
          >
            <template #[`item.quantity`]="{ value }">
              <v-chip
                dark
                small
              >
                {{ value }}
              </v-chip>
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="hasDeleteAccess"
                    @click="removeItem(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <item-dialog
      v-model="itemDialog.show"
      :id-item="itemDialog.id"
      :raw-material-id="itemDialog.rawMaterialId"
      :name="itemDialog.name"
      @change="loadProducts"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="newItem()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo produto
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue';
import ItemDialog from '@/Domains/Registrations/Item/Components/ItemDialog.vue';

export default {
  name: "items",

  components: {
    WarehouseSelect,
    ItemDialog
  },

  data() {
    return {
      itemDialog: {
        show: false,
        id: null,
        rawMaterialId: null,
        name: null,
      },

      filter: {
        warehouseId: null,
        types: [],
        search: '',
      },

      items: [],
    };
  },

  computed: {
    headers() {
      const headers = [
        { text: 'Guid', value: 'id', width: 130, show: false },
        { text: 'Código', value: 'code', width: 100, show: true },
        { text: 'Nome', value: 'name', show: true },
        { text: 'Grupo', value: 'groupName', align: 'center', show: true },
        { text: 'Preço', value: 'price', show: true, formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.0000' },
        { text: 'Estoque', value: 'quantity', show: true },
        { text: '', altText: 'Ações', value: 'action', width: 30, show: true, sortable: false },
      ];

      return headers;
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'produto-exclusao' && o.tipo === 'COMPONENTE');
    },
  },

  mounted() {
    this.loadProducts();
  },

  methods: {

    async loadProducts() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/item`, { params: {
          id_deposito: this.filter.warehouseId,
          tipos: this.filter.types,
          exibir_quantidade: true,
        } });

        this.items = data.map(item => {
          return {
            id: item.id_item,
            code: item.cod_item,
            name: item.nome.trim(),
            type: item.tipo,
            measurement: item.unidade,
            quantity: parseFloat(item.quantidade || '0'),
            price: parseFloat(item.valor) || null,
            groupName: item.nome_item_grupo,
            rawMaterialId: item.id_materia_prima
          };
        });
      } catch (err) {
        console.warn(err)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    newItem() {
      this.itemDialog.id = null;
      this.itemDialog.rawMaterialId = null;
      this.itemDialog.name = null;
      this.itemDialog.show = true;
    },

    editItem({ id, rawMaterialId, name }) {
      this.itemDialog.id = id;
      this.itemDialog.rawMaterialId = rawMaterialId;
      this.itemDialog.name = name;
      this.itemDialog.show = true;
    },

    async removeItem({ id }) {
      if (!(await this.$root.$confirm('Remover produto', 'Tem certeza que deseja remover este produto?', { color: 'red' }))) {
        return;
      }
      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/item/${id}`);

        this.loadProducts();
        this.$snotify.success("Produto excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir produto", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    print() {
      this.$refs.report.print();
    },

    exportExcel() {
      this.$refs.report.exportExcel();
    },

    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
  },
};
</script>
