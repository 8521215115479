<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-layout row>
      <v-col cols="6">
        <v-autocomplete
          v-model="filters.routes"
          :loading="loading"
          :items="filterRoutes"
          prepend-inner-icon="map"
          multiple
          dark
          label="Rotas"
          item-text="description"
          item-value="id"
          hide-details
          filled
          chips
          small-chips
          deletable-chips
          background-color="rgba(0, 0, 0, 0.5)"
          @input="onRouteFilterSelect"
        />
      </v-col>
    </v-layout>

    <v-layout row>
      <template v-for="route in routes">
        <v-col
          :key="route.id"
          :cols="columnSize"
        >
          <route-resume-commercial
            v-if="type === 'COMERCIAL'"
            :customers="route.mapPersons"
            :stats="route.stats"
            text-left
          >
            <template slot="title">
              <v-avatar
                :color="route.color"
                size="25"
                class="mr-3"
              />
              {{ route.description }}
            </template>
          </route-resume-commercial>
          <route-resume
            v-else
            :producers="route.mapPersons"
            :stats="route.stats"
            text-left
          >
            <template slot="title">
              <v-avatar
                :color="route.color"
                size="25"
                class="mr-3"
              />
              {{ route.description }}
            </template>
          </route-resume>
        </v-col>
      </template>
    </v-layout>

    <v-layout
      row
      wrap
    >
      <v-col cols="12">
        <v-card dark>
          <v-card-text class="pa-0">
            <route-map
              ref="map"
              :center="$store.state.settings.coordenadas"
              :value="routes"
              trace-on-load
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-layout>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";

import RouteService from "@/Domains/Routes/Services/RouteService.js";
import RouteResume from "@/Domains/Routes/Components/Form/RouteResume.vue";
import RouteResumeCommercial from "@/Domains/Commercial/Routes/Components/Form/RouteResume.vue";
import RouteMap from "@/Domains/Routes/Components/Maps/RouteMap.vue";

const service = new RouteService();

const colors = [
  '#FF5722',
  '#512DA8',
  '#8BC34A',
  '#FFA000',
];

export default {
  components: {
    RouteMap,
    RouteResume,
    RouteResumeCommercial,
  },

  props: {
    type: {
      type: String,
      default: 'CAPTACAO'
    }
  },

  data() {
    return {
      // Loader
      loading: false,

      // Lista de rotas
      routes: [],

      filters: {

        routes: [],
      },

      filterRoutes: [],
    };
  },

  computed: {
    columnSize() {
      return 12 / this.routes.length;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = `${this.$options._componentTag}-${this.type}`;
        this.$store.commit("setFilter", { [filterId]: value });
      }
    }
  },

  created() {
    this.getFiltersFromStore();
  },

  async mounted() {
    await this.loadRouteFilters();
    await this.loadRoutes();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = `${this.$options._componentTag}-${this.type}`;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = this.$store.state.settings.filters[filterId];
      }
    },

    async loadRouteFilters() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/listaRotaResumidaJson`,
          { tipo: this.type }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.filterRoutes = data.map(rota => {
          return {
            id: rota.id_rota,
            description: rota.descricao,
          }
        });
      } catch (e) {

        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async loadRoutes() {
      try {
        this.loading = true;

        this.routes = await Promise.all(this.filters.routes.map(async routeId => {
          return await service
            .fromSystem(this.$store.state.settings.sistema)
            .getRoute(routeId);
        }));

        this.routes = this.routes.map((route, index) => {
          return {
            ...route,
            color: colors[index]
          }
        })
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    onRouteFilterSelect(e) {
      if (e.length > 4) {
        e.pop();

        return;
      }

      return this.loadRoutes();
    }
  },
};
</script>
