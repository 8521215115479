export const StatusEnum = {
  CADASTRO: 'Cadastrado',
  APROVADO: 'Aprovado',
  SUSPENSO: 'Suspenso',
  DESVINCULADO: 'Desvinculado',
  APROVACAO: 'Aprovação',
  APROVADO_CAPTACAO: 'Aprovado Captação',
  REPROVADO: 'Reprovado Captação',
  APROVADO_QUALIDADE: 'Aprovado Qualidade',
  REPROVADO_QUALIDADE: 'Reprovado Qualidade',
  VINCULADO_GRUPO: "Vinculado ao Grupo",
  DESVINCULADO_GRUPO: "Desvinculado do Grupo",
}
