<template>
  <v-row>
    <v-col>
      <v-card
        color="transparent"
        dark
      >
        <v-data-table
          :headers="headers"
          :items="goals"
          hide-default-footer
          disable-sort
        >
          <template #[`item.class`]="{ item }">
            <v-text-field
              v-model.number="item.class"
              placeholder="A"
              type="text"
              dense
              outlined
              hide-details
            />
          </template>
          <template #[`item.start`]="{ item }">
            <v-text-field
              v-model.number="item.start"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.end`]="{ item }">
            <v-text-field
              v-model.number="item.end"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.visits`]="{ item }">
            <v-text-field
              v-model.number="item.visits"
              placeholder="0"
              type="number"
              dense
              outlined
              hide-details
              :rules="[v => !v || v >= 0 || 'obrigatório']"
              @keypress="disableDotAndComma"
            />
          </template>
          <template #[`item.period`]="{ item }">
            <v-select
              v-model="item.period"
              :items="periods"
              item-value="value"
              item-text="text"
              dense
              outlined
              hide-details
              :rules="[v => !!v || 'Campo obrigatório!']"
            />
          </template>
          <template #[`item.questionnaires`]="{ item }">
            <v-select
              v-model="item.questionnaires"
              label="Questionários"
              :items="questionnaires"
              prepend-inner-icon="icon-cadastros"
              item-value="id"
              item-text="title"
              multiple
              outlined
              small-chips
              hide-details
              dense
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-text-field
              v-model.number="item.actions"
              placeholder=""
              type="text"
              dense
              outlined
              hide-details
            />
          </template>
          <template #[`item.show_app`]="{ item }">
            <v-switch
              v-model="item.show_app"
              hide-details
              class="ma-1 pb-1"
              color="teal accent-3"
            />
          </template>
          <template #[`item.buttons`]="{ item }">
            <v-btn
              icon
              color="white"
            >
              <v-icon
                @click="onRatingDelete(item.index)"
              >
                delete_outline
              </v-icon>
            </v-btn>
          </template>
          <template #[`body.append`]="{}">
            <tr>
              <td
                colspan="8"
                align="left"
              >
                <v-btn
                  outlined
                  rounded
                  text
                  @click="onRatingInsert();"
                >
                  <v-icon>
                    add
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {
  },

  props: {
    value: Array,
  },

  data() {
    return {
      headers: [
        { text: "Classe", value: "class", width: 80 },
        { text: "Pontos de:", value: "start", width: 100 },
        { text: "Até:", value: "end", width: 100 },
        { text: "Visitas", value: "visits", width: 100 },
        { text: 'Período', value: "period", width: 150 },
        { text: "Aplicar Questionário", value: "questionnaires", width: 200 },
        { text: "Ações", value: "actions", },
        { text: "Aplicar em campo ", value: "show_app", width: 15 },
        { text: "", align: "right", value: "buttons", width: 8 },
      ],

      periods: [
        { text: "Semanal", value: "semanal" },
        { text: "Quinzenal", value: "quinzenal" },
        { text: "Mensal", value: "mensal" },
        { text: "Bimestral", value: "bimestral" },
        { text: "Trimestral", value: "trimestral" },
        { text: "Quadrimestral", value: "quadrimestral" },
        { text: "Quinquenal", value: "quinquenal" },
        { text: "Semestral", value: "semestral" },
        { text: "Anual", value: "anual" },
      ],
      questionnaires: [],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

  },

  created() {
    this.loadQuestionnaires();
  },
  methods: {
    async loadQuestionnaires() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_BPA'],
            ativo: 1,
          }
        );

        this.questionnaires = data
          //.filter(item => !item.tipo.includes('VISITA_PADRAO'))
          .map(item => {
            let text = item.titulo ? item.titulo : ``;
            text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
            text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;
            return {
              id: item.id,
              title: text,
              types: item.tipo,
            }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onRatingInsert() {
      let range = this.goals;

      let index = !_.isEmpty(range) ? _.last(range).index + 1 : 1;
      let period = !_.isEmpty(range) ? _.last(range).period : 'mensal';
      let lastRange = range[0] ? _.last(range).end : 0;

      range.push({
        id: uuidv4(),
        index: index,
        class: '',
        start: lastRange + 1,
        end: 0,
        visits: null,
        period: period,
        questionnaires: [],
        actions: '',
        show_app: true,
      })
    },

    onRatingDelete(index) {
      const idx = this.goals.findIndex(o => o.index === index);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
}
</script>
