<template>
  <v-card>
    <v-card-title>
      Inconformidades
      <v-spacer />
      <v-btn
        icon
        small
        depressed
        @click="close()"
      >
        <v-icon small>
          close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="py-0">
      <div>
        <div>
          <div>
            <v-row class="ma-1">
              <v-col
                offset="4"
                offset-sm="3"
                offset-lg="2"
                cols="5"
                sm="4"
                lg="3"
                class="pt-0"
              >
                <v-checkbox
                  v-model="showAllParams"
                  label="Exibir todos os parâmetros"
                  single-line
                  hide-details
                />
              </v-col>
              <v-col
                offset="1"
                offset-lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="searchIn"
                  prepend-inner-icon="search"
                  label="Pesquisar"
                  single-line
                  hide-details
                  ref="searchIn"
                  @input="onFocusPersist"
                />
              </v-col>
            </v-row>
          </div>

          <div class="px-0">
            <v-row>
              <v-col
                cols="4"
                sm="3"
                lg="2"
                class="py-0"
                style="border-right: rgba(0, 0, 0, 0.07) 1px solid;"
              >
                <v-row
                  v-for="tank of tanks"
                  :key="tank.index"
                  class="pa-1 text-overline ma-0 mb-2"
                  style="border: 1px solid #ccc; font-size: 10px !important; line-height: 20px !important;"
                >
                  <v-col
                    cols="12"
                    class="pa-0 ma-o"
                  >
                    {{ tank.label }}
                    <span
                      v-if="tank.plate"
                      class="text-subtitle-2"
                    >
                      ({{ tank.plate }})
                    </span>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pa-0 ma-o"
                  >
                    &nbsp;- Vale
                  </v-col>
                  <v-col
                    cols="7"
                    class="pa-0 ma-o text-right"
                  >
                    <span>{{ tank.vol }} L</span>
                  </v-col>

                  <v-col
                    v-if="tank.measured"
                    cols="6"
                    class="pa-0 ma-o"
                  >
                    &nbsp;- Medidos
                  </v-col>
                  <v-col
                    v-if="tank.measured"
                    cols="6"
                    class="pa-0 ma-o text-right"
                  >
                    {{ tank.measured }} L
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="8"
                sm="9"
                lg="10"
                class="pt-0"
              >
                <v-card
                  v-if="discardedProducers.length"
                  class="mb-5"
                >
                  <v-card-title>
                    Tanques descartados
                  </v-card-title>

                  <v-data-table
                    :headers="[
                      { text: 'Código', value: 'code', align: 'left', width: 150 },
                      { text: 'Nome', value: 'name' },
                      { text: 'Nº Amostra', value: 'sampleNumber', width: 120 },
                      { text: 'Vol. (L)', value: 'vol', width: 120 },
                      { text: 'Descarte Vinculado', value: 'link', width: 200 },
                      { text: '', value: 'revert', width: 100 },
                    ]"
                    :items="discardedProducers"
                    :search="search"
                    group-by="tank.index"
                    item-key="key"
                    hide-default-footer
                    disable-pagination
                  >
                    <template #[`group.header`]="{ group, items, headers, isOpen, toggle }">
                      <td :colspan="headers.length - 2">
                        <v-layout
                          row
                          class="pa-0 align-center"
                        >
                          <v-flex class="pa-0 text-left">
                            <v-btn
                              icon
                              @click="toggle"
                            >
                              <v-icon v-if="isOpen">
                                remove
                              </v-icon>
                              <v-icon v-else>
                                add
                              </v-icon>
                            </v-btn>
                            <span class="text-overline">
                              {{ items[0].tank.label }}
                            </span>
                            <template v-if="items[0].tank.plate">
                              ({{ items[0].tank.plate }})
                            </template>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td>
                        <v-tooltip
                          v-if="dicardedTanksByIndex[group].driverNonconformityLink"
                          bottom
                        >
                          <template #activator="{ on }">
                            <span
                              style="cursor: help"
                              v-on="on"
                            >
                              <v-icon color="yellow darken-4">warning_amber</v-icon>
                              <span class="ma-2 yellow--text text--darken-4">Transportador</span>
                            </span>
                          </template>

                          Descarte vinculado ao transportador
                        </v-tooltip>
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              icon
                              dark
                              depressed
                              v-on="on"
                              @click="revertTankDiscard(group)"
                            >
                              <v-icon color="red">
                                settings_backup_restore
                              </v-icon>
                            </v-btn>
                          </template>

                          Reverte o descarte do tanque
                        </v-tooltip>
                      </td>
                    </template>

                    <template #[`item.vol`]="{ value }">
                      {{ value | Mask(FloatNumberMask) }}
                    </template>

                    <template #[`item.link`]="{ item }">
                      <v-tooltip
                        v-if="item.status === 'DISCARDED'"
                        bottom
                      >
                        <template #activator="{ on }">
                          <span
                            style="cursor: help"
                            v-on="on"
                          >
                            <v-icon color="yellow darken-4">warning_amber</v-icon>
                            <span class="ma-2 yellow--text text--darken-4">Produtor</span>
                          </span>
                        </template>

                        Descarte vinculado ao produtor
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>

                <v-card>
                  <v-card-title>
                    Análises de Produtor
                  </v-card-title>

                  <v-data-table
                    :headers="[
                      { text: 'Código', value: 'code', align: 'left', width: 150 },
                      { text: 'Nome', value: 'name'},
                      { text: 'Nº Amostra', value: 'sampleNumber', width: 120 },
                      { text: 'Vol. (L)', value: 'vol', width: 120 },
                      { text: 'Vincular Descarte', value: 'select', width: 300 },
                    ]"
                    :items="producers"
                    :search="search"
                    group-by="tank.index"
                    item-key="key"
                    hide-default-footer
                    disable-pagination
                    show-expand
                    single-expand
                  >
                    <template #[`group.header`]="{ group, items, headers, isOpen, toggle }">
                      <td :colspan="headers.length - 1">
                        <v-layout
                          row
                          class="pa-0 align-center"
                        >
                          <v-flex class="pa-0 text-left">
                            <v-btn
                              icon
                              @click="toggle"
                            >
                              <v-icon v-if="isOpen">
                                remove
                              </v-icon>
                              <v-icon v-else>
                                add
                              </v-icon>
                            </v-btn>
                            <span class="text-overline">
                              {{ items[0].tank.label }}
                            </span>
                            <template v-if="items[0].tank.plate">
                              ({{ items[0].tank.plate }})
                            </template>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td>
                        <v-checkbox
                          :input-value="tanksToDiscardComputed"
                          :value="`${group}-driver`"
                          label="Transportador"
                          color="yellow darken-4"
                          :disabled="!canEditConcludedProducerAnalysis"
                          @change="onTankSelect({index: group, linkId: 'driver' })"
                        />
                      </td>
                    </template>

                    <template #[`item.vol`]="{ value }">
                      {{ value | Mask(FloatNumberMask) }}
                    </template>

                    <template #[`item.select`]="{ item }">
                      <v-checkbox
                        :input-value="tanksToDiscardComputed"
                        :value="`${item.tank.index}-${item.id}`"
                        :disabled="!item.hasError"
                        label="Produtor"
                        color="red"
                        @change="onTankSelect({index: item.tank.index, linkId: item.id })"
                      />
                    </template>

                    <template #expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <analysis
                          v-if="showAllParams || item.problems.length > 0"
                          :key="item.id"
                          :value="item.analysis"
                          :visible="!showAllParams ? item.problems : visibleAnalysisParams"
                          :vol="item.vol"
                          :cryoscopys="cryoscopys"
                          :disabled="!canEditConcludedProducerAnalysis"
                          alt-checkbox
                          @input="onProducerUpdate(item, $event)"
                        />
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="grey darken-3"
        text
        @click="close()"
      >
        VOLTAR
      </v-btn>

      <v-spacer />

      <v-btn
        v-if="tanksToDiscard.length > 0"
        color="red darken-1"
        text
        outlined
        @click="saveAndClose"
      >
        SALVAR E DESCARTAR
      </v-btn>

      <v-btn
        v-else
        color="blue darken-1"
        class="ml-3"
        text
        outlined
        :disabled="!showSaveButton"
        @click="saveAndClose"
      >
        SALVAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as _ from "lodash";
import Analysis from "@/Domains/Platform/Unload/Components/Analysis.vue";
import AnalysisPatternValidator from "@/Domains/Platform/Unload/Services/AnalysisPatternValidator.js";
import IMask from "imask";

export default {

  components: {
    Analysis,
  },

  filters: {

    Mask(value, mask) {
      return IMask.pipe((value || '').toString(), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },

  },

  props: {

    unload: {
      type: Object,
      default: () => ({}),
    },

    tanks: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
    },

    visibleAnalysisParams: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      searchIn: '',

      // Mascara usada nos campos numéricos float
      FloatNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      // Tanques para descartar vinculado ao produtor ou motorista
      tanksToDiscard: [],

      // Lista de produtores para análise
      producers: [],

      // Lista de produtores dos tanques descartados
      discardedProducers: [],

      // Checkbox para exibir todos os parâmetros
      showAllParams: false,

      // Lista de crioscopias
      cryoscopys: [],

    };
  },

  computed: {

    search: {
      get() {
        return this.searchIn;
      },
      set(newValue) {
        return newValue;
      },
    },

    isLoading: {
      get() {
        return this.loading;
      },
      set(newValue) {
        return this.$emit('update:loading', newValue)
      },
    },

    invalidTanks() {
      return this.tanks.filter(tank => !_.isEmpty(tank.problems));
    },

    hasUnloadCompleted() {
      return ['LOADED', 'UNLOADED'].includes(this.unload.status);
    },

    showSaveButton() {
      if (this.hasUnloadCompleted) {
        return this.canEditConcludedProducerAnalysis;
      }

      return this.showAllParams || this.producers.some(producer => producer.problems.length > 0);
    },

    tanksToDiscardComputed() {
      return this.tanksToDiscard.map(tank => `${tank.index}-${tank.linkId}`);
    },

    dicardedTanksByIndex() {
      return this.tanks
        .filter(tank => tank.status === 'DISCARDED')
        .reduce((acc, tank) => ({ ...acc, [tank.index]: tank }), {});
    },

    /**
     * Recupera o role do usuário
     * @returns {String}
     */

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    canEditConcludedProducerAnalysis() {
      if (!this.hasUnloadCompleted) {
        return true;
      }

      return this.isAdmin || this.userResources.some(o => o.recurso === 'edit-concluded-producer-analysis' && o.tipo === 'COMPONENTE');
    },

  },

  watch: {

    invalidTanks: {
      deep: true,
      immediate: true,

      handler(newValue) {

        if (_.isEmpty(newValue)) {
          this.producers = [];
          this.discardedProducers = [];
        }

        const producers = this.tanks.map(tank => {
          return tank.producers
            .map(producer => ({
              ...producer,
              tank,
              problems: tank.problems,
              key: `t${tank.index}-${producer.id}`
            }));
        }).flat();

        this.producers = _.cloneDeep(producers).filter(producer => producer.tank.status !== 'DISCARDED');
        this.discardedProducers = _.cloneDeep(producers).filter(producer => producer.tank.status === 'DISCARDED');

      },
    },

  },

  created() {
    this.loadCryoscopys();
  },

  async mounted() {
    await this.$nextTick();

    await this.checkAllProducerAnalysis()
  },

  methods: {
    onFocusPersist() {
      this.$refs.searchIn.focus();
    },
    onTankSelect(item) {
      const alreadySelectedProducerIndex = this.tanksToDiscard.findIndex(tank => tank.linkId === item.linkId && tank.index === item.index);

      if (alreadySelectedProducerIndex !== -1) {
        this.tanksToDiscard.splice(alreadySelectedProducerIndex, 1);
        return;
      }
      else {
        if (item.linkId === 'driver') {
          this.tanksToDiscard = this.tanksToDiscard.filter(tank => tank.index !== item.index);
        }
        else {
          this.tanksToDiscard = this.tanksToDiscard.filter(tank => tank.index !== item.index || (tank.index === item.index && tank.linkId !== 'driver'));
        }

        this.tanksToDiscard.push(item);
      }
    },

    async onProducerUpdate(updatedProducer, analysis) {
      this.producers = this.producers.map(producer => {
        if (producer.tank.index !== updatedProducer.tank.index) {
          return producer;
        }

        if (producer.id !== updatedProducer.id) {
          return producer;
        }

        return {
          ...updatedProducer,
          analysis,
        };
      });

      await this.$nextTick();

      return this.checkAllProducerAnalysis();
    },

    async checkAllProducerAnalysis() {
      return this.tanks.forEach(tank => {

        const producers = tank.producers.map(producer => {
          const updatedProducer = this.producers.find(updatedProducerRow => {
            if (updatedProducerRow.tank.index !== tank.index) {
              return false;
            }

            return updatedProducerRow.id === producer.id;
          });

          if (!updatedProducer) {
            return producer;
          }

          const hasError = this.hasProducerError(updatedProducer.problems, updatedProducer.analysis)

          return  {
            ...updatedProducer,
            hasError,
          }
        });

        return this.onTankUpdated({
          ...tank,
          producers,
        });
      });
    },

    hasProducerError(visible, analysis) {
      const problems = _.map(analysis, ({ value, rule }, key) => {

        if (!visible.includes(key)) {
          return false;
        }

        return !(new AnalysisPatternValidator().fromRule(rule).validate(value));
      });

      return !(problems.every(result => !result));
    },

    async saveAllProducerAnalysis() {
      try {
        this.isLoading = true;

        const req = {
          id_descarga_coletas: this.unload.id,
          id_itinerario: this.unload.itinerary.id,
          tanques: this.tanks.map(tank => {

            const producers = this.producers.filter(producer => producer.tank.index === tank.index);

            if (_.isEmpty(producers)) {
              return;
            }

            return {
              tanque: `tanque${tank.index}`,
              volume_liquido: tank.vol,
              volume_total: tank.capacity,
              analises: producers.map(({ id, unloadId, vol, sampleNumber, name, analysis }) => {

                const cryoscopy = analysis.cryoscopy.value !== -0.5 ? analysis.cryoscopy.value : null;

                return {
                  tanque: `tanque${tank.index}`,
                  id_produtor: id,
                  id_coleta: unloadId,
                  nome_produtor: name,
                  temperatura: analysis.temp.value,
                  acidez: analysis.acidity.value,
                  alizarol: analysis.alizarol.value,
                  crioscopia: cryoscopy,
                  volume_liquido: vol,
                  numero_amostra: sampleNumber,
                  porcentagem_agua: analysis.waterPercentage.value,
                  volume_agua: analysis.waterLiters.value,
                  densidade: analysis.density.value,
                  esd: analysis.esd.value,
                  gordura: analysis.fat.value,
                  proteina: analysis.protein.value,
                  mastite: analysis.mastitis.value,
                  sensorial: analysis.sensorial.value,
                  fosfatase: analysis.alkalinePhosphatase.value,
                  solidos_totais: analysis.totalSolids.value,
                  brix: analysis.brix.value,
                  est: analysis.est.value,
                  alizarol_qualit: analysis.alizarolQualit.value,
                  alcool: analysis.alcohol.value,
                  lactose: analysis.lactose.value,
                  grumos: analysis.clumps.value,
                  coagulos: analysis.clots.value,
                  redutase: analysis.reductase.value,
                  peroxidase: analysis.peroxidase.value,
                  outras_especies: analysis.otherSpecies.value,
                  solubilidade: analysis.solubilidade.value,
                  base_seca: analysis.base_seca.value,
                  ph: analysis.ph.value,
                  cefalosporina: analysis.cefalosporina.value,
                  outros: analysis.others.value,

                  // Antibióticos
                  antibiotico: analysis.antibiotic.value,
                  betalactamico: analysis.betaLactams.value,
                  tetraciclina: analysis.tetracycline.value,
                  sulfonamida: analysis.sulphonamide.value,
                  quinolonas: analysis.quinolones.value,
                  aminoglicosideos: analysis.aminoglykosides.value,
                  macrolideos: analysis.macrolides.value,
                  anfenicois: analysis.anfenicois.value,
                  neomicina: analysis.neomicina.value,

                  // Neutralizantes
                  neutralizantes: analysis.neutralising.value,
                  soda: analysis.sodiumHydroxide.value,
                  bicarbonato: analysis.bicarbonateOfSoda.value,
                  alcalino: analysis.alkali.value,

                  // Reconstituintes
                  reconstituintes: analysis.restoratives.value,
                  sacarose: analysis.sucrose.value,
                  amido: analysis.starch.value,
                  cloretos: analysis.chlorides.value,
                  cloro: analysis.chlorine.value,
                  ureia: analysis.ureia.value,

                  // Conservantes
                  conservantes: analysis.conservatives.value,
                  peroxido: analysis.peroxide.value,
                  formol: analysis.formol.value,
                }
              }),
            };
          }).filter(n => n),
        }

        const { data } = await this.$axios.post(`/descargaColeta/salvaAnalise`, req);

        if (!data.codigo) {
          throw 'Erro ao salvar';
        }
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao atualizar as análises!", "Atenção");
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    async updateDiscardedTanks() {
      try {
        this.isLoading = true;

        const tankStatus = {
          'PENDING': 'PENDENTE',
          'TO_DISCARD': 'DESCARTAR',
          'DISCARDED': 'DESCARTADO',
          'TO_UNLOAD': 'DESCARREGAR',
          'UNLOADED': 'DESCARREGADO',
          'LOADED': 'CARREGADO',
          'TO_UNLOAD_RESTRICT': 'DESCARREGAR_RESTRITO',
          'UNLOADED_RESTRICT': 'DESCARREGADO_RESTRITO',
        };

        const request = {
          id_descarga_coletas: this.unload.id,
          tanques: this.tanks.map(tank => {

            let status = tankStatus[tank.status];
            let driverNonconformityLink = tank.driverNonconformityLink;
            let discardedProducers = [];

            const tanksToDiscard = this.tanksToDiscard.filter(t => t.index === tank.index);
            if (tanksToDiscard.length > 0) {
              status = 'DESCARTADO';
              driverNonconformityLink = tanksToDiscard.find(t => t.linkId === 'driver');
              discardedProducers = tanksToDiscard
                .filter(t => t.linkId !== 'driver')
                .map(t => ({
                  id_produtor: t.linkId,
                  status: 'DESCARTADO',
                }));
            }

            return {
              tanque: `tanque${tank.index}`,
              status,
              produtores: discardedProducers,
              vincula_inconformidade_transportador: driverNonconformityLink,
              observacao: '',
              anexos: [],
            }
          }),
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/salvaDescarte`,
          request,
        );

        const { codigo } = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }

        return this.$emit('onTanksDiscarded', this.tanksToDiscard);
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao atualizar as análises!", "Atenção");
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    onTankUpdated(updatedTank) {
      return this.$emit('onTankUpdated', updatedTank);
    },

    async revertTankDiscard(tankIndex) {
      try {
        this.isLoading = true;

        this.tanksToDiscard = this.tanksToDiscard.filter(tank => tank.index !== tankIndex);

        const tank = this.tanks.find(tank => tank.index === tankIndex);

        this.onTankUpdated({
          ...tank,
          status: 'PENDING',
          producers: tank.producers.map(producer => ({
            ...producer,
            status: 'PENDING',
          })),
        });
        this.$nextTick(() => this.checkAllProducerAnalysis());
      } catch (e) {

        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },

    async saveAndClose() {
      await this.saveAllProducerAnalysis();

      await this.updateDiscardedTanks();

      return this.close();
    },

    close() {
      return this.$emit('close');
    },

    async loadCryoscopys() {
      try {
        const { data } = await this.$axios.get('registrations/cryoscopy');
        this.cryoscopys = data;

      } catch (err) {
        this.cryoscopys = [];
      }
    },
  },

}
</script>
