<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="orders"
  >
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent text-left"
      >
        <v-tab
          href="#tab-list"
        >
          Tabelas de Preço
        </v-tab>

        <v-tab
          href="#tab-add"
        >
          Editar/Inserir Tabela
        </v-tab>

        <v-tab-item value="tab-list">
          <PriceTableListTab
            v-if="tab === 'tab-list'"
            @addPriceTable="onAddPriceTable"
            @showPriceTable="onShowPriceTable"
          />
        </v-tab-item>

        <v-tab-item value="tab-add">
          <PriceTableEditingTab
            v-if="tab === 'tab-add'"
            :table-id.sync="editId"
            @priceTableSaved="onPriceTableSaved"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import PriceTableListTab from '@/Domains/Commercial/PriceTable/Views/PriceTableListTab.vue'
import PriceTableEditingTab from '@/Domains/Commercial/PriceTable/Views/PriceTableEditingTab.vue'

export default {
  components: {
    PriceTableListTab,
    PriceTableEditingTab,
  },

  data() {
    return {
      tab: 'tab-list',
      editId: null
    };
  },

  methods: {
    onAddPriceTable() {
      this.editId = null;
      this.tab = "tab-add";
    },

    onShowPriceTable(id) {
      this.editId = id;
      this.tab = "tab-add";
    },

    onPriceTableSaved() {
      this.tab = "tab-list";
    },
  },
};
</script>