<template>
  <v-container
    text-xs-center
    grid-list-sm
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card
            dark
            color="transparent"
          >
            <v-card-text
              class="text-left pt-1 pb-1 text-subtitle-2"
              style="background: rgba(0, 0, 0, 0.3);"
            >
              <v-row dense>
                <v-col
                  md="12"
                  class="pb-0"
                >
                  DATA: {{ itinerary.collect.unloadedAt }}
                </v-col>
              </v-row>

              <v-row
                dense
              >
                <v-col
                  md="2"
                  class="pb-0"
                >
                  ROTA: {{ itinerary.route.description }}
                </v-col>
                <v-col
                  md="2"
                  class="pb-0"
                >
                  VEÍCULO: {{ itinerary.vehicle.description }}
                </v-col>
                <v-col
                  md="2"
                  class="pb-0"
                >
                  MOTORISTA: {{ itinerary.driver.name }}
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  md="7"
                >
                  VOLUME: {{ itinerary.collect.totalVol }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-card
            class="list-collections"
            color="transparent"
          >
            <v-data-table
              class="list-collections-table"
              :headers="[
                { text:'Código', width: 100, value:'producer.code'},
                { text:'Produtor', value:'producer.name'},
                { text:'Data', value:'date', align: 'center' },
                { text:'Volume (L)', value:'vol', width:150, align:'center'},
              ]"
              group-by="route"
              :items="items"
              disable-pagination
              hide-default-footer
              dark
            >
              <template v-slot:group.header="{ group, items, headers, isOpen, toggle }">
                <td :colspan="headers.length">
                  <v-layout
                    row
                    class="pa-0 align-center"
                  >
                    <v-flex class="pa-0 text-left">
                      <v-btn
                        icon
                        dark
                        @click="toggle"
                      >
                        <v-icon v-if="isOpen">
                          remove
                        </v-icon>
                        <v-icon v-else>
                          add
                        </v-icon>
                      </v-btn>
                      {{ group }}
                    </v-flex>
                    <v-flex class="pa-0 text-right">
                      <v-chip
                        small
                        v-text="sumCollects(items)"
                      />
                    </v-flex>
                  </v-layout>
                </td>
              </template>

              <template v-slot:item.date="{ item }">
                <v-chip
                  x-small
                >
                  {{ parseDate(item.date) }}
                </v-chip>
              </template>

              <template v-slot:item.vol="{ item }">
                <v-chip
                  x-small
                >
                  {{ item.vol }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="onReportItineraryPrint"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.list-collections > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.list-collections-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.2);
    }

    tbody {
      background: rgba(255, 255, 255, 0.15);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }

      .v-row-group__header {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}
</style>

<script>
import _ from "lodash";
import moment from "moment";

export default {

  components: {
  },

  props: {
    itinerary: {
      type: Object,
      default: () => ({
        route: {},
        vehicle: {},
        driver: {},
        collect: {},
      }),
    },
  },

  data() {
    return {
      // Loader
      loading: false,

      filters: {
        date: {
          input: 'today',
          range: [],
        },
        route: {},
      },

      items: [],
    };
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {

    async loadItems() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/listaRotaVolume`,
          this.$qs.stringify({
            id_itinerario: this.itinerary.id,
          }),
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data.map(row => {
          return {
            producer: {
              id: row.id_produtor,
              name: row.nome_produtor,
              code: row.codigo_produtor,
            },
            driver: {
              name: row.nome_usuario_coleta,
            },
            date: row.data_hora_descarga,
            vol: parseInt(row.quantidade_coleta) || 0,
            route: row.rota_produtor,
          };
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event

      return this.refresh();
    },

    refresh() {
      return this.loadItems();
    },

    parseDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },

    sumCollects(collects) {
      return _.sumBy(collects, 'vol');
    },

    onReportItineraryPrint() {
      return this.$emit('onReportItineraryPrint', this.itinerary);
    },

  },

}
</script>
