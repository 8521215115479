<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      class="pdf-viewer"
      :style="{ '--scale-factor': scale }"
    >
      <v-card-title class="d-flex justify-space-between">
        {{ title || 'PDF' }}
        <div
          v-if="!loading"
          class="pdf-toolbar text-body-1"
        >
          <v-btn
            color="primary"
            large
            @click="print"
          >
            <v-icon left>
              print
            </v-icon>
            Imprimir
          </v-btn>
          <v-btn
            color="primary"
            large
            outlined
            class="ml-2"
            @click="download"
          >
            <v-icon left>
              download
            </v-icon>
            Baixar
          </v-btn>
          <span class="ml-2">
            Página {{ pages.current }} de {{ pages.total }}
          </span>
          <v-btn
            icon
            outlined
            color="primary"
            class="ml-2"
            :disabled="pages.current === 1"
            @click="prevPage"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-btn
            icon
            outlined
            color="primary"
            class="ml-2"
            :disabled="pages.current === pages.total"
            @click="nextPage"
          >
            <v-icon>arrow_forward</v-icon>
          </v-btn>
          <span class="ml-2">
            Zoom: {{ Math.round((scale / 1.5) * 100) }}%
          </span>
          <v-btn
            icon
            outlined
            color="primary"
            class="ml-2"
            :disabled="scale <= 1"
            @click="zoomOut"
          >
            <v-icon>remove</v-icon>
          </v-btn>
          <v-btn
            icon
            outlined
            color="primary"
            class="ml-2"
            :disabled="scale >= 2"
            @click="zoomIn"
          >
            <v-icon>add</v-icon>
          </v-btn>
          <div
            v-if="rendering"
            class="rendering"
          />
        </div>
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="pt-4"
        style="background-color: #eee;"
      >
        <div
          v-if="loading"
          class="full-width full-height d-flex flex-column justify-center align-center text-h5"
        >
          Carregando...
          <v-progress-linear
            indeterminate
            class="mt-2"
          />
        </div>
        <template v-else>
          <div class="pdf-content">
            <div class="pdf-container">
              <canvas
                ref="pdfCanvas"
                class="pdf-canvas"
              />
              <div
                ref="textLayer"
                class="text-layer"
              />
            </div>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.pdf-viewer {
  .v-card__title {
    border-bottom: 1px #ccc solid;
  }

  .v-card__text {
    background-color: #eee;
  }

  .pdf-toolbar {
    position: relative;

    .rendering {
      position:absolute;
      width: 100%;
      height: 100%;
    }
  }

  .pdf-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pdf-container {
      position: relative;
    }
  }

  .pdf-canvas {
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  .text-layer {
    position: absolute;
    text-align: initial;
    inset: 0;
    overflow: clip;
    opacity: 1;
    line-height: 1;
    -webkit-text-size-adjust: none;
       -moz-text-size-adjust: none;
            text-size-adjust: none;
    forced-color-adjust: none;
    transform-origin: 0 0;
    caret-color: CanvasText;
    z-index: 0;
  }

  .text-layer :is(span,br) {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
  }

  .text-layer > :not(.markedContent),.text-layer .markedContent span:not(.markedContent){
    z-index: 1;
  }

  .text-layer span.markedContent {
    top: 0;
    height: 0;
  }

  .text-layer span[role="img"] {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    cursor: default;
  }

  .text-layer ::-moz-selection {
    background: rgba(0 0 255 / 0.25);
    background: color-mix(in srgb, AccentColor, transparent 75%);
  }

  .text-layer ::selection {
    background: rgba(0 0 255 / 0.25);
    background: color-mix(in srgb, AccentColor, transparent 75%);
  }

  .text-layer br::-moz-selection {
    background: transparent;
  }

  .text-layer br::selection {
    background: transparent;
  }

  .text-layer .endOfContent {
    display: block;
    position: absolute;
    inset: 100% 0 0;
    z-index: 0;
    cursor: default;
    -webkit-user-select: none;
        -moz-user-select: none;
            user-select: none;
  }

  .text-layer.selecting .endOfContent {
    top: 0;
  }

  .text-layer.selecting ~ .annotationLayer section {
    pointer-events: none;
  }

}
</style>

<script setup>
import { ref, reactive } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js'
import printJS from 'print-js'

const { notify } = useUtils()

const dialog = ref(false)
const loading = ref(true)
const title = ref(null)
const pdf = ref()

useDialogHistory(dialog)

const show = async (data) => {
  dialog.value = true

  title.value = data.title

  loadPdf(data.api)
}

const loadPdf = async (api) => {
  try {
    loading.value = true

    pdf.value = await api()

    await loadPdfJs()
    await renderPdf()

  } catch (e) {
    console.warn(e)
    notify.error('Oops, ocorreu um erro ao gerar o PDF!', 'Atenção')
  } finally {
    loading.value = false
  }
}

const close = () => {
  dialog.value = false
}

const pdfJs = ref()
const pdfDoc = ref(null)
const pages = reactive({ current: 1, total: 1 })
const pdfCanvas = ref(null)
const textLayer = ref(null)
const scale = ref(1.5)
const rendering = ref(false)

const print = () => printJS({
  printable: pdf.value,
  type: 'pdf',
  base64: true,
})

const download = () => {
  const link = document.createElement('a')
  link.setAttribute('href', 'data:application/pdf;base64,' + pdf.value)
  link.setAttribute('download', `${title.value}.pdf`)
  link.click()
}

/**
 * Carrega o PDF.js como módulo ES do CDN e configura o worker
 */
const loadPdfJs = async () => {
  // eslint-disable-next-line import/no-unresolved
  pdfJs.value = await import('https://cdn.jsdelivr.net/npm/pdfjs-dist@4/+esm')

  // Configura o worker
  pdfJs.value.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@4/build/pdf.worker.mjs'
}

const renderPdf = async () => {
  const loadingTask = pdfJs.value.getDocument({ data: window.atob(pdf.value) })
  pdfDoc.value = await loadingTask.promise
  pages.current = 1
  pages.total = pdfDoc.value.numPages
  renderPage(pages.current)
}

const renderPage = async (num) => {
  rendering.value = true
  const page = await pdfDoc.value.getPage(num)
  const canvas = pdfCanvas.value
  const context = canvas.getContext('2d')

  const viewport = page.getViewport({ scale: scale.value })
  canvas.width = viewport.width
  canvas.height = viewport.height

  page.render({
    canvasContext: context,
    viewport: viewport,
  })

  // Renderizar a camada de texto para habilitar a seleção
  const textLayerDiv = textLayer.value
  while (textLayerDiv.firstChild) {
    // Limpar a camada de texto antes de renderizar novamente
    textLayerDiv.removeChild(textLayerDiv.firstChild)
  }

  const textContent = await page.getTextContent()
  const textLayerSource = new pdfJs.value.TextLayer({
    textContentSource: textContent,
    viewport: viewport,
    container: textLayerDiv,
  })
  await textLayerSource.render()
  rendering.value = false
}

const prevPage = () => {
  if (pages.current === 1) {
    return
  }
  pages.current--
  renderPage(pages.current)
}

const nextPage = () => {
  if (pages.current === pages.total) {
    return
  }
  pages.current++
  renderPage(pages.current)
}

const zoomIn = () => {
  scale.value += 0.3
  renderPage(pages.current)
}

const zoomOut = () => {
  scale.value -= 0.3
  renderPage(pages.current)
}

// eslint-disable-next-line no-undef
defineExpose({
  show,
})
</script>
