<template>
  <v-autocomplete
    v-model="input"
    :items="cities"
    :loading="loading"
    v-bind="$attrs"
    item-text="city"
    item-value="code"
    return-object
    @input="onChange"
  />
</template>

<script>
import _ from 'lodash';

export default {
  name: 'cities-select',

  props: {
    value: String,
    uf: String,
    code: String,
  },

  data() {
    return {
      loading: false,

      input: null,

      cities: [],
    };
  },

  watch: {
    value() {
      this.input = { city: this.value, code: this.code };
      this.updateCities();
    },
    uf() {
      this.getCities();
    },
  },

  created() {
    this.getCities();
  },

  methods: {
    async getCities() {
      if (!this.uf) {
        return;
      }
      this.cities = [];
      try {
        this.loading = true;

        const { data } = await this.$queries.post(`/municipios/listaJson`, { uf: this.uf });
        
        this.cities = data.map(o => ({
          city: o.end_cidade,
          code: `${o.codigo_municipio}`
        }));
        
        this.updateCities();
      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar as cidades!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    /**
     * No cadastro de produtor não estava sendo usado esse componente ainda
     * E para manter a compatibilidade temos que atualizar a lista de cidades
     * com o valor que já estava salvo no BD antes
     */
    updateCities() {
      if (this.value && !this.code) {
        // Em caso de registros antigos e o nome da cidade for respectivo ao da lista, pega o da lista nova
        const foundCity  = _.head(_.filter(this.cities, c => c.city === this.value));

        if (!foundCity) {
          this.cities.push({ city: this.value, code: null });
          this.cities = _.uniqBy(this.cities, 'city');
          return true;
        }

        //@todo encontrar um melhor forma de fazer essa atribuição
        this.input = foundCity;
      }
    },

    onChange(value) {
      this.$emit("input", value.city);
      this.$emit("change", value);
    },
  },
};
</script>
