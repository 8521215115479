import { render, staticRenderFns } from "./BreedingStep.vue?vue&type=template&id=63a89248"
import script from "./BreedingStep.vue?vue&type=script&lang=js"
export * from "./BreedingStep.vue?vue&type=script&lang=js"
import style0 from "./BreedingStep.vue?vue&type=style&index=0&id=63a89248&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports