
import { computed } from 'vue'
import store from '@/Support/Resources/vuex.js'

export const usePermissions = () => {

  const userResources = computed(() => store.state.settings.recursosUsuario || [])

  const isAdmin = computed(() => store.state.settings.tipoAcesso === 'admin' || store.state.settings.user.id_cargo === 1)

  const hasPermission = (recurso, allowAdmin = true) => (isAdmin.value && allowAdmin) || userResources.value.find(o => o.recurso === recurso && o.tipo === 'COMPONENTE')

  return {
    hasPermission,
  }

}
