var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-6 px-16"},[_c('v-row',[_c('v-col',{staticClass:"text-center py-0",attrs:{"cols":"12"}},[_c('h2',{staticClass:"menu-header white--text"},[_vm._v(" Análises de Produtor ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("arrow_left")]),_c('div',{staticClass:"pr-3"},[_vm._v(" Voltar ")])],1)],1)],1),_c('v-card',{staticStyle:{"margin-top":"20px"},attrs:{"dark":"","color":"transparent diff-list"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"md":"3"}},[_c('v-date-range-picker',{attrs:{"dark":""},on:{"change":_vm.loadReport},model:{value:(_vm.filters.dateRange),callback:function ($$v) {_vm.$set(_vm.filters, "dateRange", $$v)},expression:"filters.dateRange"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"md":"3"}},[_c('routes-autocomplete-filter',{attrs:{"label":"Rota","dark":"","multiple":"","return-object":false},on:{"change":_vm.loadReport},model:{value:(_vm.filters.routes),callback:function ($$v) {_vm.$set(_vm.filters, "routes", $$v)},expression:"filters.routes"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"md":"3"}},[_c('person-autocomplete-filter',{attrs:{"label":"Produtor","type":"PRODUCER","multiple":"","return-object":false},on:{"change":_vm.loadReport},model:{value:(_vm.filters.producers),callback:function ($$v) {_vm.$set(_vm.filters, "producers", $$v)},expression:"filters.producers"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"md":"3"}},[_c('v-select',{attrs:{"items":[
              'INCONFORMIDADE',
              'DESCARTE',
              'ANALISE',
            ],"clearable":"","filled":"","hide-details":"","prepend-inner-icon":"fact_check","label":"Tipo","multiple":"","small-chips":""},model:{value:(_vm.filters.types),callback:function ($$v) {_vm.$set(_vm.filters, "types", $$v)},expression:"filters.types"}})],1)],1)],1),_c('data-table',{ref:"report",attrs:{"item-key":"id_descarga_analise","name":"Análises de Produtor","dark":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredItems,"show-custom-group":""},scopedSlots:_vm._u([{key:`item.tipo`,fn:function({ value }){return [_c('v-chip',{attrs:{"small":"","color":_vm.getTypeColor(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}},_vm._l((_vm.params),function(param,idx){return {key:`item.analises.${param.campo}`,fn:function({ value }){return [_c('span',{key:idx,class:{
            'red lighten-1 px-1 font-weight-bold' : value.inconforme
          }},[_vm._v(" "+_vm._s(value.resultado)+" ")])]}}}),{key:`footer.prepend`,fn:function(){return [_c('div',{staticClass:"text-caption text-left ml-4"},[_vm._v(" Total de "),_c('v-chip',{staticClass:"mx-2 mb-0",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.items.length)+" ")]),_vm._v(" registros ")],1)]},proxy:true}],null,true)})],1),_c('v-speed-dial',{staticClass:"mr-5",attrs:{"fixed":"","dark":"","bottom":"","right":"","open-on-hover":"","direction":"top","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-2","dark":"","large":"","fab":""}},[_c('v-icon',[_vm._v("menu")])],1)]},proxy:true}])},[_c('v-btn',{attrs:{"fab":"","dark":"","color":"green darken-1"},on:{"click":_vm.exportExcel}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" backup_table ")])]}}])},[_vm._v(" Download (Excel) ")])],1),_c('v-btn',{attrs:{"fab":"","dark":"","color":"orange darken-1"},on:{"click":_vm.print}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" print ")])]}}])},[_vm._v(" Imprimir ")])],1)],1),_c('v-overlay',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_vm._v(" Carregando ... ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }