<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          class="v-tamanho settings"
          color="transparent"
        >
          <v-card-subtitle
            style="height: 52px;"
          >
            Mensagem
          </v-card-subtitle>
          <v-card-text
            style="background: rgba(0, 0, 0, 0.4);"
          >
            <v-form
              ref="form"
              v-model="valid"
              class="pt-5"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="4"
                  class="pt-0"
                >
                  <v-select
                    v-model="form.filter.type"
                    :items="filters"
                    label="Filtro"
                    :rules="[v => !!v || 'Escolha o filtro']"
                    item-value="key"
                    item-text="value"
                    prepend-inner-icon="filter_list"
                    filled
                    required
                    @change="onChangeFilter"
                  />

                  <routes-autocomplete-filter
                    v-if="form.filter.type == 'ROUTE'"
                    v-model="form.filter.value"
                    label="Rota"
                    :rules="[v => !!v.id || 'Escolha a rota']"
                    required
                  />

                  <template
                    v-if="form.filter.type == 'USER'"
                  >
                    <v-select
                      v-model="personType"
                      :items="personTypes"
                      label="Tipo de pessoa"
                      :rules="[v => !!v || 'Escolha o tipo de pessoa']"
                      item-value="key"
                      item-text="value"
                      prepend-inner-icon="group"
                      filled
                      required
                    />

                    <person-autocomplete-filter
                      v-show="personType == 'TECHNICAL'"
                      v-model="form.filter.value"
                      type="TECHNICAL"
                      label="Técnico"
                      :rules="[v => !!v.id || 'Escolha a pessoa']"
                      required
                    />
                    <person-autocomplete-filter
                      v-show="personType == 'DRIVER'"
                      v-model="form.filter.value"
                      type="DRIVER"
                      label="Motorista"
                      :rules="[v => !!v.id || 'Escolha a pessoa']"
                      required
                    />
                    <person-autocomplete-filter
                      v-show="personType == 'PRODUCER'"
                      v-model="form.filter.value"
                      type="PRODUCER"
                      label="Produtor"
                      show-groupings
                      show-bonus-producers
                      :rules="[v => !!v.id || 'Escolha a pessoa']"
                      required
                    />
                  </template>

                  <v-select
                    v-if="form.filter.type == 'DAIRY_ROLE'"
                    v-model="form.filter.roles"
                    :items="roles"
                    label="Cargo"
                    :rules="[v => v.length > 0 || 'Informe o cargo']"
                    item-value="key"
                    item-text="value"
                    prepend-inner-icon="group"
                    filled
                    multiple
                    required
                  />
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    v-model="form.notification.title"
                    label="Título da notificação"
                    :rules="[v => !!v || 'Informe o título']"
                    :counter="65"
                    maxlength="65"
                    filled
                    required
                  />

                  <v-textarea
                    v-model="form.notification.body"
                    label="Descrição da notificação"
                    :rules="[v => !!v || 'Informe a descrição']"
                    :counter="240"
                    maxlength="240"
                    filled
                    required
                  />
                  <v-textarea
                    v-model="form.content.text"
                    label="Mensagem"
                    :rules="[v => !!v || !!form.images.length > 0 || 'Informe o conteúdo da mensagem']"
                    filled
                    required
                    @change="$refs.form.resetValidation()"
                  />
                    <v-row>
                        <v-col
                          v-for="(image, idx) in form.images"
                          :key="idx"
                        >
                        <file-viewer
                          :value="image"
                          @removeFile="onRemoveImage(idx)"
                        />
                      </v-col>
                      <v-col
                      class="py-0 text-center align-self-center flex-column justify-center"
                      >
                        <v-btn
                          outlined
                          class="mb-5"
                          @click="showFileUpload = !showFileUpload"
                        >
                          <v-icon>add_a_photo</v-icon>
                          <span class="ml-2">Adicionar</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green darken-1 mr-5"
          v-on="on"
          @click="sendNotification()"
        >
          <v-icon class="material-icons-outlined">
            send
          </v-icon>
        </v-btn>
      </template>

      <span>Enviar</span>
    </v-tooltip>

    <file-upload
      v-model="showFileUpload"
      :multiselect="true"
      @insertFiles="onSelectImages"
    />

    <v-overlay :value="sending">
      <v-card-text>
        Enviando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

export default {
  name: "new-notification-tab",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    FileUpload,
    FileViewer
  },

  data() {
    return {
      sending: false,
      valid: true,
      showFileUpload: false,
      filters: [
        { key: 'USER', value: 'Usuário' },
        { key: 'ROUTE', value: 'Rota' },
        { key: 'DAIRY_ROLE', value: 'Cargo' },
        { key: 'DAIRY', value: 'Todos' },
      ],
      personTypes: [
        { key: 'TECHNICAL', value: 'Técnico' },
        { key: 'DRIVER', value: 'Motorista' },
        { key: 'PRODUCER', value: 'Produtor' },
      ],
      personType: 'TECHNICAL',
      roles: [
        { key: 'TECNICO', value: 'Técnico' },
        { key: 'MOTORISTA', value: 'Motorista' },
        { key: 'PRODUTOR', value: 'Produtor' },
      ],
      form: {
        filter: {
          type: 'USER',
          value: {},
          roles: [] // used by DAIRY_ROLE
        },
        content: {
          text: '',
        },
        images: [],
        notification: {
          title: '',
          body: ''
        }
      },
    };
  },

  methods: {
    onChangeFilter() {
      this.form.filter.roles = [];
      this.form.filter.value = {};
    },
    onSelectImages(images) {
      for (let image of images) {
        if (!this.form.images.find(o => o.name == image.name)) {
          this.form.images.push(image);
        }
      }
    },
    async onRemoveImage(idx) {
      try {
        const fileUrl = this.form.images[idx].url;
        if (fileUrl && fileUrl.includes('googleapis.com')) {
          if (!(await this.$root.$confirm('Remover Imagem', 'Tem certeza que deseja remover?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.images.splice(idx, 1);
          await this.save();
        }
        else {
          this.form.images.splice(idx, 1);
        }
      } catch (err) {
        console.warn(err);
      }
    },
    async sendNotification() {
      this.sending = true;
      try {
        // Valida o formulário
        if (!this.$refs.form.validate()) {
          return;
        }
        const form = this.form;

        await this.$axios.post(`/messaging`, { ...form });
        this.$refs.form.reset();
        this.form.images = [];
        this.$snotify.success("Mensagem enviada com sucesso!", "Sucesso");

      } catch (e) {
        console.warn(e);
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
