<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Ações de Inconformidades
    </v-card-title>
    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#simple">
        Faixa Simples
      </v-tab>
      <v-tab href="#points">
        Pontuação
      </v-tab>
      <v-tab-item value="simple">
        <v-row class="justify-center">
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="itemSelected"
              :items="questionnaires"
              item-value="value"
              item-text="text"
              dark
              hide-selected
              hide-details
              label="Escolha o questionário"
              prepend-inner-icon="search"
              filled
              return-object
              @change="addItem"
            />
          </v-col>
        </v-row>
        <ranges
          :value="goals.simple"
          type-range="nonconformity"
        />
      </v-tab-item>
      <v-tab-item value="points">
        <v-row class="justify-center">
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="itemSelected"
              :items="questionnaires"
              item-value="value"
              item-text="text"
              dark
              hide-selected
              hide-details
              label="Escolha o questionário"
              prepend-inner-icon="search"
              filled
              return-object
              @change="addItem"
            />
          </v-col>
        </v-row>
        <questionnaire-points
          :value="goals.points"
        />
      </v-tab-item>
    </v-tabs>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </v-card>
</template>

<script>

import _ from "lodash";
import Ranges from "@/Domains/Projects/Dairy/ProducerRating/Components/Ranges.vue";
import QuestionnairePoints from "@/Domains/Projects/Dairy/ProducerRating/Components/QuestionnairePoints.vue";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {
    Ranges,
    QuestionnairePoints,
    
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        simple: [],
        points: []
      }),
    },
  },

  data() {
    return {

      tab: null,

      questionnaires: [],
  
      itemSelected: '',

      itemType: '',

      loading: false,
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  created() {
    this.loadQuestionnaires();
    this.loadQuestionnairesGroups();
  },

  methods: {

    loadQuestionnairesGroups() {
      if (!_.isEmpty(this.goals.points)) {
        this.goals.points.forEach(q => {
          this.loadQuestionnaireId(q.indicator);
        });
      }

      if (!_.isEmpty(this.goals.simple)) {
        this.goals.simple.forEach(q => {
          this.loadConditional(q.indicator);
        });
      }
    },

    async loadQuestionnaires() {

      try {
        this.loading = true;

        const { data } = await this.$axios.post (
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_BPA'],
            ativo: 1
          }
        );
        // console.log(data);

        this.questionnaires = data
          .map(item => {
            let text = item.titulo ? item.titulo : ``;
            text = item.versao ?  ` ${text} versão: ${item.versao} ` : text;
            text = item.codigo ?  ` ${text} - RQ ${item.codigo} ` : text;
            return {
              value: item.id,
              text: text,
              types: item.tipo,
            }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    addItem(indicator) {

      if (this.tab === 'simple') {
        this.loadConditional(indicator);
      } else {
        this.loadQuestionnaireId(indicator);
      }
      this.$nextTick(() => {
        this.itemSelected = "";
      });
      
    },

    async loadConditional(indicator) {
      try {
        this.loading = true;
        let { data } = await this.$axios.post(
          `/formularioCustomizado/listaValoresRotulos`,
          {
            id_formulario: indicator.value
          }
        );
        if (data) {
          let conditional = data.map(item => ({
            text: item,
            value: item,
          }));
          
          let index = this.goals.simple.findIndex(data => {
            return data.indicator.value == indicator.value;
          });
          if (index > 0 || index === 0) { //ja existe ranges para esse questionario
            this.goals.simple[index].conditional = conditional;
            
          } else {
            let ranges =  [{
              id: uuidv4(),
              index: 1,
              start: 0,
              end: null,
              class: 'A',
              type: 'CONFORME',
            }]
            this.goals.simple.push({ indicator, ranges: ranges, conditional })
          }
        }
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar condicionais do questionario!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadQuestionnaireId(indicator) {
      let id = indicator.value;
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioId`,
          this.$qs.stringify({
            id_formulario: id
          })
        );
       
        const questionnaire = _.head(data);
        let groupQuestions = [];
        let countGroup = 0;
        let countQuestion = 0;
        let sumPoints = 0;
        let sumConforme = 0;
        let sumNaoConforme = 0;
        let sumNA = 0;

        _.forEach(questionnaire.formulario, (question) => {
          if (question.tipo === "TITULO") {
            countGroup++;
            countQuestion = 0;
            sumPoints = 0;
            sumConforme = 0;
            sumNaoConforme = 0;
            sumNA = 0;
            groupQuestions.push({
              index: countGroup,
              grupos: question.rotulo,
              questoes_grupos: 0,
              pontos_grupo: 0,
              pontos_conforme: 0,
              pontos_nao_conforme: 0,
              pontos_na: 0,
              questions: [],
            });
          }
          if (_.isEmpty(groupQuestions)) { //nao tem subtitulo/grupo no formulario
            countGroup++;
            countQuestion = 0;
            sumPoints = 0;
            sumConforme = 0;
            sumNaoConforme = 0;
            sumNA = 0;
            groupQuestions.push({
              index: countGroup,
              grupos: 'sem grupo',
              questoes_grupos: 0,
              pontos_grupo: 0,
              pontos_conforme: 0,
              pontos_nao_conforme: 0,
              pontos_na: 0,
              questions: [],
            });
          }

          if (question.tipo === "CHECKBOX") {
            countQuestion++;
            let lastGroup = _.last(groupQuestions);
            _.set(lastGroup, "questoes_grupos", countQuestion);

            question.id_group = countGroup;
            lastGroup.questions.push(question);

            question.pontos = _.isEmpty(question.pontos) ? [] : question.pontos; //validar quando pontos nao é array

            let pontos = _.max(question.pontos) || 0;
            sumPoints += parseFloat(pontos); //soma os pontos das questoes (maximo)
            _.set(lastGroup, "pontos_grupo", sumPoints);

            let conforme = !_.isEmpty(question.pontos) ? _.head(question.pontos.slice(0, 1) ) : 0;
            sumConforme += parseFloat(conforme);
            _.set(lastGroup, "pontos_conforme", sumConforme);
            
            let nao_conforme = !_.isEmpty(question.pontos) ? _.head(question.pontos.slice(1, 2) ) : 0;
            sumNaoConforme += parseFloat(nao_conforme);
            _.set(lastGroup, "pontos_nao_conforme", sumNaoConforme);
            
            let na = !_.isEmpty(question.pontos) ? _.head(question.pontos.slice(2, 3) ) : 0;
            sumNA += parseFloat(na);
            _.set(lastGroup, "pontos_na", sumNA);
          }
        });

        //verifica se ja existe ranges para carregar
        let index = this.goals.points.findIndex(data => {
          return data.indicator.value == id;
        });
        if (index > 0 || index === 0) { //ja existe ranges para esse questionario
          this.goals.points[index].groupQuestions = groupQuestions;
          this.goals.points[index].questionnaire = questionnaire;
          
        } else {
          let ranges = [{
            id: uuidv4(),
            index: 1,
            class: '',
            start: '',
            end: '',
          }]
          
          this.goals.points.push({ indicator, groupQuestions, questionnaire, ranges });
        }
        
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    removeItem(item) {
      const idx = this.goals.findIndex(o => o.indicator === item.indicator);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>