<template>
    <div
      class="d-flex flex-column justify-end px-14 text-center"
      style="border-left: 2px solid #fff"
    >
      <span class="caption font-weight-light mb-2">
        {{ title }}
        <v-icon small>
          {{ icon }}
        </v-icon>
      </span>
      <v-chip
        pill
        small
      >
        <v-avatar
          left
          color="blue"
          class="white--text"
        >
          E
        </v-avatar>
        {{ rawIn }}  
      </v-chip>
      <v-chip
        pill
        small
      >
        <v-avatar
          left
          color="red"
          class="white--text"
        >
          S
        </v-avatar>
        {{ rawOut }}  
      </v-chip>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      icon: String,
      rawIn: String,
      rawOut: String
    }
  }
  </script>