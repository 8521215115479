<template>
  <div>
    <v-autocomplete
      v-model="input"
      :items="categories"
      :label="label"
      item-value="id"
      item-text="name"
      :disabled="loading"
      :loading="loading"
      prepend-inner-icon="category"
      return-object
      v-bind="$attrs"
      menu-props="closeOnContentClick"
      @input="onItemSelected"
    >
      <template #item="{ item, on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-content>
              <v-list-item-title> {{ item.name }} </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="hover">
              <div class="d-flex flex-row">
                <v-btn
                  icon
                  small
                  color="green"
                  class="mr-2"
                  :disabled="item.default"
                  @click.prevent="edit(item)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>

                <v-btn
                  icon
                  small
                  color="red"
                  :disabled="item.default"
                  @click.prevent="remove(item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-hover>
      </template>

      <template
        #append-item
      >
        <v-divider class="mt-2" />
        <v-row no-gutters>
          <v-col>
            <v-btn
              block
              outlined
              @click="add"
            >
              Cadastrar novo
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>

    <category-dialog
      ref="dialog"
      @save="save"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import get from 'lodash/get';

import CategoryDialog from "@/Domains/Financial/Components/CategoryDialog.vue";

export default {
  components: {
    CategoryDialog,
  },

  props: {
    value: {
      type: Object,
    },
    type: {
      type: String,
      default: 'D'
    },
    label: {
      type: String,
      default: 'Categoria'
    }
  },

  data() {
    return {
      loading: false,
      input: null,
      categories: [],
    };
  },

  watch: {
    value(value) {
      this.input = value;
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    onItemSelected() {
      return this.$emit('input', this.input);
    },

    add() {
      return this.$refs.dialog.show();
    },

    edit(category) {
      return this.$refs.dialog.show(category);
    },

    async remove(category) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }

      try {
        const { data } = await this.$axios.post(`/categoria/inativa`, {
          id_categoria: category.id,
        });

        if (!_.isArray(data) || get(data, '0.codigo') !== 1) {
          throw new Error(data);
        }

        await this.fetch();

        this.$snotify.success('Categoria removida com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as categorias!", "Atenção");
        console.warn(error);
      }
    },

    async save(category) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/categoria/salva`, {
          id_categoria: category.id,
          nome: category.name,
          debito_credito: this.type,
        });

        if (!_.isObject(data) || get(data, 'codigo') !== 1) {
          throw new Error(data);
        }

        await this.fetch();

        this.$snotify.success('Categoria salva com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as categorias!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async fetch() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/categoria/lista`);

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        this.categories = data
          .filter(o => this.type == o.debito_credito.trim())
          .map(o => ({
            id: o.id_categoria,
            name: o.nome,
            default: o.padrao || false,
          }));
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as categorias!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
