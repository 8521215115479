<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container>
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <v-date-range-picker
              v-model="localDateFilter"
              label="Data"
              dark
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <person-autocomplete-filter
              v-model="localTechnicianFilter"
              label="Técnico"
              type="TECHNICIAN"
              :return-object="false"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <person-autocomplete-filter
              v-model="localProducersFilter"
              label="Produtor"
              type="PRODUCER"
              multiple
              show-groupings
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <routes-autocomplete-filter
              v-model="localRoutesFilter"
              label="Rota"
              dark
              multiple
              @change="onFilter"
            />
          </v-col>
          <v-chart
            :options="options"
            :style="{width: '100%', height: height}"
            autoresize
          />
        </v-card-title>
      </v-card>
    </v-container>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>

import qs from "qs";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {
  components: {
    VDateRangePicker,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
  },

  props: {
    dateFilter: Array,
    technicianFilter: String,
    producersFilter: Array,
    routesFilter: Array,
  },

  data() {
    return {
      aba: "aba-relatorio",
      loading: false,
      filters: {
        search: null,
        routes: [],
      },
      coletas: [],
      height: '500px !important',
    };
  },

  computed: {
    localDateFilter: {
      get() {
        return this.dateFilter;
      },
      set(newValue) {
        return this.$emit("update:date-filter", newValue);
      },
    },
    localTechnicianFilter: {
      get() {
        return this.technicianFilter;
      },
      set(newValue) {
        return this.$emit("update:technician-filter", newValue);
      },
    },
    localProducersFilter: {
      get() {
        return this.producersFilter;
      },
      set(newValue) {
        return this.$emit("update:producers-filter", newValue);
      },
    },
    localRoutesFilter: {
      get() {
        return this.routesFilter;
      },
      set(newValue) {
        return this.$emit("update:routes-filter", newValue);
      },
    },

    options() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b>`)
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          // top: '10%',
          bottom: '1%',
          containLabel: true,
        },
        yAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.coletas.map((item) => item.nome_produtor),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)',
          }
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)',
            formatter: (value) => this.formatNumber(value)
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.8)']
            }
          }
        },
        series: [
          {
            name: 'Quantidade',
            type: 'bar',
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 17,
              height: 17,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              formatter: ({ value }) => `  ${this.formatNumber(value)}  `
            },
            data: this.coletas.map((item) => item.quantidade)
          }
        ]
      }
    }
  },

  mounted() {
    this.loadGrafico();
  },

  methods: {
    async loadGrafico() {
      try {
        this.loading = true;

        let routes = this.routesFilter.map(({ id }) => id)

        const [startDate, endDate] = this.dateFilter;

        const produtores = this.producersFilter.flatMap(p => {
          const producers = [p.id];
          if (p.grouping) {
            producers.push(...p.grouping.map(p => p.id_pessoa))
          }
          return producers;
        });

        const { data } = await this.$axios.post(
          `/relatorios/quantidadeColetas`,
          qs.stringify({
            data_inicio: startDate,
            data_fim: endDate,
            rotas: JSON.stringify(routes),
            produtores: JSON.stringify(produtores),
            id_tecnico: this.technicianFilter,
          })
        );

        this.height = (Object.keys(data).length * 20) + 'px !important';

        this.coletas = data;
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async onFilter() {
      await this.$nextTick();
      return this.loadGrafico();
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },
};
</script>
