<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Pagamento de deslocamento de visitas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
    </v-container>

    <v-row
      justify="end"
      class="row-filters"
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-date-range-picker
          v-model="filters.range"
          :ranges="filters.ranges"

          @change="onDateFilter"
        />
      </v-col>
    </v-row>

    <v-container>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent text-left"
        @change="onTabChange"
      >
        <v-tab
          href="#tab-totals-km"
        >
          Pagamento por Km
        </v-tab>

        <v-tab
          href="#tab-details"
          :disabled="tab != 'tab-details'"
        >
          Detalhes de viagens
        </v-tab>

        <v-tab
          :href="`#${tab}`"
          @click.stop="openSettings"
        >
          <v-icon>settings</v-icon>
        </v-tab>

        <v-tab-item value="tab-totals-km">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span class="caption font-weight-light">
                    Valor Total
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3 cyan--text"
                  >R$ {{ totalAmount | formatCurrency }}</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span class="caption font-weight-light">
                    Distância Total
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3 teal--text"
                  >{{ totalKm | formatNumber }} Km</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  offset-md="2"
                  md="4"
                  class="py-0"
                >
                  <v-text-field
                    v-model="report.search"
                    append-icon="search"
                    label="Busca"
                    single-line
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="reportHeaders"
              :search="report.search"
              :items="items"
              :loading="loading"
              dark
              @click:row="showDetails"
            >
              <template #[`item.volume`]="{value}">
                <span>{{ value | formatNumber }} L</span>
              </template>
              <template #[`item.totalDistance`]="{value}">
                <span>{{ value | formatNumber }} Km</span>
              </template>
              <template #[`item.totalPayment`]="{value}">
                <span>R$ {{ value | formatCurrency }}</span>
              </template>
              <template
                v-for="(range, idx) in priceRanges"
                #[`item.km_${range.km}`]="{value}"
              >
                <span :key="idx">R$ {{ value | formatCurrency }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-details">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title>
              <v-col
                md-3
                class="pa-0"
              />
              <v-col
                md-6
                offset-md="3"
                class="pa-0"
              >
                <v-text-field
                  v-model="details.search"
                  append-icon="search"
                  label="Busca"
                  single-line
                  hide-details
                />
              </v-col>
            </v-card-title>
            <v-data-table
              :headers="details.headers"
              :items="details.items"
              :search="details.search"
              dark
            >
              <template #[`item.time.startedAt`]="{ item }">
                <div style="max-width: 90px;">
                  <v-chip
                    pill
                    x-small
                  >
                    <v-avatar
                      left
                      color="green"
                      class="white--text"
                    >
                      I
                    </v-avatar>
                    {{ item.time.startedAt | dateFormat('DD/MM HH:mm') }}
                  </v-chip>
                  <div>
                    <v-chip
                      v-if="item.time.endedAt"
                      pill
                      x-small
                    >
                      <v-avatar
                        left
                        color="green"
                        class="white--text"
                      >
                        F
                      </v-avatar>
                      {{ item.time.endedAt | dateFormat('DD/MM HH:mm') }}
                    </v-chip>
                    <v-chip
                      v-else
                      pill
                      x-small
                    >
                      Em Andamento
                    </v-chip>
                  </div>
                  <v-chip
                    v-if="item.time.endedAt"
                    pill
                    x-small
                  >
                    <v-avatar
                      left
                      color="blue"
                      class="white--text"
                    >
                      =
                    </v-avatar>
                    {{ item.time.total }}
                  </v-chip>
                </div>
              </template>

              <template #[`item.distance.total`]="{ item }">
                <itinerary-odometer-table-item
                  :started-at="parseInt(item.distance.startedAt)"
                  :ended-at="parseInt(item.distance.endedAt)"
                  :total="parseInt(item.distance.total)"
                  :odometer="item.distance.odometer"
                />
              </template>

              <template #[`item.map`]="{ item }">
                <div style="min-height: 36px;">
                  <v-btn
                    v-if="item.time.endedAt"
                    icon
                    @click.stop="showItineraryMap(item)"
                  >
                    <v-icon>map</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-container>

    <v-dialog
      v-model="showConfig"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title>
          Configuração de valores
        </v-card-title>
        <v-divider />
        <v-card-text
          class="pt-5 px-0"
          style="max-height: calc(100vh - 250px);"
        >
          <v-form
            ref="form"
            lazy-validation
            @submit="savePrices"
          >
            <v-list
              class="pt-0"
            >
              <v-list-item
                v-for="(item, idx) in priceRanges"
                :key="idx"
              >
                <v-list-item-action class="mt-4 mb-0 d-inline-flex justify-center">
                  {{ (idx == 0) ? 'Inicial' : '>' }}
                </v-list-item-action>
                <v-list-item-title>
                  <v-row no-gutters>
                    <v-col
                      cols="6"
                      class="py-1"
                    >
                      <v-text-field
                        v-model="item.km"
                        type="number"
                        label="Km"
                        hide-selected
                        hide-details
                        :disabled="idx == 0"
                        :rules="idx == 0 ? [] : [v => !!v || 'Informe o limite']"
                        @keypress="disableDot"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="py-1"
                    >
                      <v-text-field
                        v-model="item.valor"
                        type="number"
                        label="Valor"
                        hide-selected
                        hide-details
                        :rules="[v => !!v || 'Informe o valor']"
                        @keypress="disableDot"
                      />
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="closeSettings"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :loading="saving"
            @click="savePrices"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <itinerary-map-dialog
      ref="map"
      type="VISITS"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import qs from "qs";
import _ from "lodash";
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ItineraryMapDialog from "@/Domains/Itineraries/Components/ItineraryMapDialog.vue";
import ItineraryOdometerTableItem from "@/Domains/Itineraries/Components/ItineraryOdometerTableItem.vue";

export default {
  components: {
    VDateRangePicker,
    ItineraryMapDialog,
    ItineraryOdometerTableItem,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  data() {
    return {
      tab: "tab-totals-km",

      filters: {
        ranges: {
          'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
          'Mês anterior': [moment().subtract(1, "month").startOf('month').format('YYYY-MM-DD'), moment().subtract(1, "month").endOf('month').format('YYYY-MM-DD')],
        },
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
      },

      report: {
        search: "",
      },

      items: [],

      details: {
        search: "",
        headers: [
          { text: "Código", value: "code", width: 30 },
          { text: "Rota", value: "route.description", width: 150 },
          { text: "Técnico", value: "driver.name", width: 140 },
          { text: "Placa", value: "vehicle.description", width: 100 },
          { text: "Saída/Chegada", value: "time.startedAt", width: 130 },
          { text: "Quilometragem", value: "distance.total", width: 90 },
          { text: "Produtores", value: "producers", width: 90 },
          { value: "map", align: 'end', width: 40 },
        ],

        items: [],
      },

      loading: false,
      saving: false,
      showConfig: false,

      /**
       * Por enquanto há apenas 2 faixas de pagamento,
       * Mas já estou salvando como uma lista
       * para o caso de ter que alterar futuramente e adicionar mais
       */
      priceRanges: [
        { km: 0, valor: 0.50 },
        { km: 2000, valor: 0.25 }
      ],
    };
  },

  computed: {
    reportHeaders() {
      let headers = [
        { text: "Técnico", value: "technician" },
        { text: "Distância", value: "totalDistance", width: 180 },
      ];

      this.priceRanges.forEach(faixa => {
        headers.push({
          text: `${faixa.km == 0 ? 'Inicial' : `> ${this.formatNumber(faixa.km)} km`} (R$ ${this.formatCurrency(faixa.valor)})`,
          value: `km_${faixa.km}`,
          width: 170
        })
      });

      headers.push({ text: "Total", value: "totalPayment", width: 150, align: 'right' })

      return headers;
    },
    totalAmount() {
      return this.items.reduce((acc, cur) => acc + cur.totalPayment, 0)
    },
    totalKm() {
      return this.items.reduce((acc, cur) => acc + cur.totalDistance, 0)
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    onTabChange(id) {
      if (id == 'tab-totals-km') {
        this.details.items = [];
      }
    },
    showDetails({ items }) {
      this.details.items = items;
      this.tab = 'tab-details';
    },
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.filters.range;

        let { data } = await this.$axios.post(
          `/itinerario/listaItinerarios`,
          { tipo: 'visita', data_ini: startDate, data_fim: endDate }
        );

        this.items = _(data)
          .map(itinerary => ({
            id: itinerary.id_itinerario,
            code: itinerary.cod_itinerario,
            route: {
              id: itinerary.id_rota,
              description: itinerary.descricao_rota,
            },
            driver: {
              id: itinerary.id_motorista,
              name: itinerary.motorista,
            },
            vehicle: {
              id: itinerary.id_equipamento,
              description: (itinerary.caminhao || '').toUpperCase(),
            },
            time: {
              startedAt: itinerary.data_hora_inicio,
              endedAt: itinerary.data_hora_fim,
              total: itinerary.data_hora_fim ? moment(itinerary.horas, 'HH:mm:ss').format('HH:ss') : null,
            },
            distance: {
              startedAt: itinerary.km_inicial,
              endedAt: itinerary.km_final,
              total: itinerary.km_total ? parseInt(itinerary.km_total) : null,
              gps: itinerary.total_km_gps ? parseInt(itinerary.total_km_gps) : null,
              odometer: [
                {
                  type: 'START',
                  src: itinerary.url_velocimentro_ini,
                  thumb: itinerary.url_velocimentro_ini,
                  caption: 'Início',
                },
                {
                  type: 'END',
                  src: itinerary.url_velocimentro_fim,
                  thumb: itinerary.url_velocimentro_fim,
                  caption: 'Fim',
                },
              ],
            },
            producers: itinerary.total_produtores,
            mobile: {
              version: itinerary.versao || null,
            }
          }))
          .groupBy('driver.name')
          .map((items, tecnico) => {
            return {
              technician: tecnico != "null" ? tecnico : "Outros",
              totalDistance: items.reduce((acc, cur) => acc + (cur.distance.total > 0 ? parseFloat(cur.distance.total || 0) : 0), 0),
              items
            }
          }).value();

        this.getPrices();
        this.calculatePrices();

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    calculatePrices() {

      const priceRanges = [...this.priceRanges].sort((a, b) => a.valor - b.valor);

      this.items = this.items.map(item => {
        let payments = [];
        let remainingDistance = item.totalDistance;

        priceRanges.forEach(range => {
          let payment = 0;

          if (remainingDistance >= range.km) {
            let distance = remainingDistance - range.km;
            remainingDistance -= distance;
            payment = distance * parseFloat(range.valor);
          }

          payments.push({ km: range.km, payment })
        })

        return {
          ...item,
          ...payments.reduce((acc, cur) => ({ ...acc, [`km_${cur.km}`]: cur.payment }), {}),
          totalPayment: payments.reduce((acc, cur) => (acc + cur.payment), 0),
        }
      })
    },

    onDateFilter() {
      this.details.items = [];
      this.tab = 'tab-totals-km';
      this.loadReport();
    },

    closeSettings() {
      this.getPrices();
      this.showConfig = false;
    },

    openSettings() {
      this.getPrices();
      this.showConfig = true;
    },

    getPrices() {
      if (this.$store.state.settings.gerais.pagamento_deslocamento) {
        this.priceRanges = [...this.$store.state.settings.gerais.pagamento_deslocamento];
      }
    },

    async savePrices() {
      this.saving = true;
      try {
        const payLoad = {
          configuracaoQualidade: JSON.stringify([{
            pagamento_deslocamento: this.priceRanges,
          }]),
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          qs.stringify(payLoad)
        );

        [data] = data;

        if (data.codigo !== 1) {
          throw data;
        }

        this.$store.dispatch('updateStoreSetting');
        this.calculatePrices();
        this.showConfig = false;
        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.error(error);
      } finally {
        this.saving = false;
      }
    },

    async showItineraryMap(itinerary) {
      try {
        this.loading = true;

        await this.$refs.map.show(itinerary);
      } catch (e) {

        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    disableDot(e) {
      e.charCode === 46 && e.preventDefault();
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),

    exportExcel() {
      if (this.tab == 'tab-totals-km') {
        const data = XLSX.utils.json_to_sheet(this.items.map(o => {
          let item = {
            Técnico: o.technician,
            'Distância': this.formatNumber(o.totalDistance) + ' Km',
          }

          this.priceRanges.forEach(faixa => {
            const text = `${faixa.km == 0 ? 'Inicial' : `> ${this.formatNumber(faixa.km)} km`} (R$ ${this.formatCurrency(faixa.valor)})`;
            item[text] = 'R$ ' + this.formatCurrency(o[`km_${faixa.km}`]);
          });

          item['Total'] = 'R$ ' + this.formatCurrency(o.totalPayment);

          return item;
        }));

        data['!cols'] = [
          { wch: 40 },
          { wch: 12 },
          { wch: 16 },
          { wch: 18 },
          { wch: 18 }
        ];

        const workbook = XLSX.utils.book_new();
        const filename = "pagamento_deslocamento";
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else {
        const data = XLSX.utils.json_to_sheet(this.details.items.map(o => ({
          Técnico: o.driver.name || '',
          Placa: o.vehicle.description || '',
          Saída: moment(o.time.startedAt).format('DD/MM/YYYY'),
          Chegada: o.time.endedAt ? moment(o.time.endedAt).format('DD/MM/YYYY') : 'Em Andamento',
          Horas: o.time.total || '',
          'Km Inicial': o.distance.startedAt,
          'Km Final': o.distance.endedAt,
          'Km Total': o.distance.total,
          Produtores: o.producers
        })));

        data['!cols'] = [
          { wch: 30 },
          { wch: 12 },
          { wch: 12 },
          { wch: 12 }
        ];

        const workbook = XLSX.utils.book_new();
        const filename = "visitas";
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
    },
  },
};
</script>
