<template>
  <div>
    <v-row>
      <v-col
        v-for="e in typeEmergency"
        :key="e.type"
        md="12"
      >
        <v-chart
          :ref="e.type"
          :options="generateGraphOptions(e)"
          :style="{width: '100%', height: `350px !important`}"
          autoresize
        />
      </v-col>
    </v-row>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando emergenciais...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";

export default {
  name: "emergency-graphic",

  components: {
    
  },
  
  props: {
    filter: Object,
  },

  data() {
    return {
      loading: false,
      //metas de PAE emergenciais
      typeEmergency: [],

      emergencyGoals: {
        CRITICAL: { status: 'Produtores Críticos', visits: 0, questionnaires: [], type: 'PERCENT' },
        PRIORITY: { status: 'Produtores Prioritários', visits: 0, questionnaires: [], type: 'UN' },
        PRIORITYCCS: { status: 'Produtores Prioritários CCS', visits: 0, questionnaires: [], type: 'UN' },
      },

    };
  },
  
  watch: {
    filter: {
      handler() {
        this.onLoad();
      },
      deep: true
    }
   
  },
   
  methods: {
    
    async onLoad() {
      if (this.filter.project) {
        this.typeEmergency = [];
        await this.loadPriorityCriticalCPPReport();
      }
    },

    async loadPriorityCriticalCPPReport() {

      this.loading = true;
      let items = [];
      
      try {
        let { data } = await this.$axios.post(
          `/relatorioIn/visitasCriticosPrioritarios`,
          {
            data_inicio: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.project.dataEnd).endOf('month').format('YYYY-MM-DD'),
          }
        );

        if (!_.isEmpty(this.filter.project.emergency) ) {
          const { CRITICO, PRIORITARIO } = JSON.parse(this.filter.project.emergency);
          this.emergencyGoals = {
            CRITICAL: { status: 'Produtores Críticos', visits: CRITICO.visitas, questionnaires: CRITICO.questionarios, type: !_.isEmpty(CRITICO.tipo) ? CRITICO.tipo : "PERCENT" },
            PRIORITY: { status: 'Produtores Prioritários', visits: PRIORITARIO.visitas, questionnaires: PRIORITARIO.questionarios, type: !_.isEmpty(PRIORITARIO.tipo) ? PRIORITARIO.tipo : "UN" },
          };
          
        }
        //criticos
        if (this.emergencyGoals.CRITICAL.visits > 0 ) {

          items = data.filter(o => !_.isEmpty(o.produtores_criticos)).map(o => {

            let quantidadeSalvo59 = 0;
            let quantidadeSalvo76 = 0;
            let quantidadeDesvinculado = 0;
            let totalVisitado = 0;

            o.produtores_criticos = o.produtores_criticos.map(p => {
              let cbt_trimestral = p.cbt_geometrica_trimestral_posterior;
              let menor_cbt = p.menor_cbt;
              let salvo = 'Não salvo';
              let visit = _.isEmpty(p.visitas) ? 0 : (p.visitas).length ;

              if ((p.status == 'DESVINCULADO' || p.status == 'SUSPENSO') && !_.isEmpty(p.data_desvinculacao) && !_.isEmpty(p.visitas)) {
                quantidadeDesvinculado++;
              }

              if (cbt_trimestral > 0  &&  cbt_trimestral <= 300) {
                //76/77
                salvo = '76/77';
                totalVisitado++;
                quantidadeSalvo76++;
              } else if (menor_cbt > 0 && menor_cbt <= 300 && visit > 0 ) {
                // 59
                salvo = '59';
                totalVisitado++;
                quantidadeSalvo59++;
              } else if (!_.isEmpty(p.visitas))  {
                totalVisitado++;
              }
              return {
                ...p,
                salvo: salvo
              }
            });
            
            let totalNaoSalvo = o.total_criticos - quantidadeSalvo59 - quantidadeSalvo76;
            let totalAtivoVisitado = totalVisitado - quantidadeDesvinculado;
            totalAtivoVisitado = (totalAtivoVisitado < 0) ? 0 : totalAtivoVisitado;
            let percentualVisitado = totalAtivoVisitado > 0 ? (totalAtivoVisitado / o.total_criticos )  * 100 : 0;
            
            let percentualMeta = (this.emergencyGoals.PRIORITY.type == "UN") ?  ( 100 * this.emergencyGoals.CRITICAL.visits / o.total_criticos ) : this.emergencyGoals.PRIORITY.visits ;
            
            let icone = 'cancel';

            if (this.emergencyGoals.CRITICAL.type == "UN"
              && (totalAtivoVisitado >= this.emergencyGoals.CRITICAL.visits
              || totalAtivoVisitado == o.total_criticos) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.CRITICAL.type == "PERCENT"
              &&  (percentualVisitado == 100 || percentualVisitado > percentualMeta )) {
              icone = 'verified';
            }

            return {
              month: o.mes,
              producers: o.total_criticos,
              producers_not_save: totalNaoSalvo,
              producers_in59: quantidadeSalvo59,
              producers_in76: quantidadeSalvo76,
              visits: o.total_criticos_visitados,
              visitsPerc: Math.abs(percentualVisitado).toFixed(2),
              goals: (this.emergencyGoals.CRITICAL.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.CRITICAL.visits / 100) : this.emergencyGoals.CRITICAL.visits,
              goalsPerc: this.emergencyGoals.CRITICAL.visits + " " + (this.emergencyGoals.CRITICAL.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.CRITICAL.type,
              icone: icone,
            }
          })

          //carrega os criticos de CPP para enviar para componente que monta tabela
          this.typeEmergency.push({
            type: 'critical',
            items: items,
            goal: this.emergencyGoals.CRITICAL,
          });
          
        }

        //prioritarios
        if (this.emergencyGoals.PRIORITY.visits > 0 ) {
         
          items = data.filter(o => !_.isEmpty(o.produtores_prioritarios)).map(o => {

            let percentualMeta = (this.emergencyGoals.PRIORITY.type == "UN") ?  ((100 * o.total_prioritarios_visitados) / this.emergencyGoals.PRIORITY.visits  ) : this.emergencyGoals.PRIORITY.visits ;
            let icone = 'cancel';

            if (this.emergencyGoals.PRIORITY.type == "UN"
              && (o.total_prioritarios_visitados >= this.emergencyGoals.PRIORITY.visits
              || o.total_prioritarios_visitados == o.total_prioritarios) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.PRIORITY.type == "PERCENT"
              &&  (o.perc_prioritarios_visitados == 100 || o.perc_prioritarios_visitados > percentualMeta )) {
              icone = 'verified';
            }
            
            return {
              month: o.mes,
              producers: o.total_prioritarios,
              visits: o.total_prioritarios_visitados,
              visitsPerc: o.perc_prioritarios_visitados,
              goals: (this.emergencyGoals.PRIORITY.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.PRIORITY.visits / 100) : this.emergencyGoals.PRIORITY.visits,
              goalsPerc: this.emergencyGoals.PRIORITY.visits + " " + (this.emergencyGoals.PRIORITY.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.PRIORITY.type,
              icone: icone,
            }
          });

          //carrega os prioritarios de CPP para enviar para componente que monta tabela
          this.typeEmergency.push({
            type: 'priority',
            items: items,
            goal: this.emergencyGoals.PRIORITY,
          });
         
        }
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
        await this.loadPriorityCCSReport();
      }
    },

    async loadPriorityCCSReport() {
     
      this.loading = true;
      if (!_.isEmpty(this.filter.project.emergency) ) {
        const { PRIORITARIOCCS } = JSON.parse(this.filter.project.emergency);
        if (PRIORITARIOCCS) {
          this.emergencyGoals = {
            PRIORITYCCS: { status: 'Produtores Prioritários CCS', visits: PRIORITARIOCCS.visitas, questionnaires: PRIORITARIOCCS.questionarios, type: !_.isEmpty(PRIORITARIOCCS.tipo) ? PRIORITARIOCCS.tipo : "UN" },
          };
        }
        
      }
      
      try {
        if (this.emergencyGoals.PRIORITYCCS.visits > 0 ) {
          let { data } = await this.$axios.post(
            `/relatorioIn/visitasPrioritariosCCS`,
            {
              data_inicio: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
              data_fim: moment(this.filter.project.dataEnd).endOf('month').format('YYYY-MM-DD'),
            }
          );

          let items = data.map(o => {

            let percentualMeta = (this.emergencyGoals.PRIORITYCCS.type == "UN") ?  ((100 * o.total_prioritarios_visitados) / this.emergencyGoals.PRIORITYCCS.visits  ) : this.emergencyGoals.PRIORITYCCS.visits ;
            let icone = 'cancel';

            if (this.emergencyGoals.PRIORITYCCS.type == "UN"
              && (o.total_prioritarios_visitados >= this.emergencyGoals.PRIORITYCCS.visits
              || o.total_prioritarios_visitados == o.total_prioritarios) ) {
              icone = 'verified';
            }
            else if (this.emergencyGoals.PRIORITYCCS.type == "PERCENT"
              &&  (o.perc_prioritarios_visitados == 100 || o.perc_prioritarios_visitados > percentualMeta )) {
              icone = 'verified';
            }
            
            return {
              month: o.mes,
              producers: o.total_prioritarios,
              visits: o.total_prioritarios_visitados,
              visitsPerc: o.perc_prioritarios_visitados,
              goals: (this.emergencyGoals.PRIORITYCCS.type == "PERCENT") ? (o.total_criticos * this.emergencyGoals.PRIORITYCCS.visits / 100) : this.emergencyGoals.PRIORITYCCS.visits,
              goalsPerc: this.emergencyGoals.PRIORITYCCS.visits + " " + (this.emergencyGoals.PRIORITYCCS.type == "PERCENT" ? "%" : ""),
              type: this.emergencyGoals.PRIORITYCCS.type,
              icone: icone,
            }
          })
          //carrega os prioritarios de CPP para enviar para componente que monta tabela
          this.typeEmergency.push({
            type: 'priority_ccs',
            items: items,
            goal: this.emergencyGoals.PRIORITYCCS,
          });
        }
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de PRIORITARIOS CCS", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    generateGraphOptions(e) {

      let data = e.items;
      let title = '';
      let subtitle = `Meta: Visitar ${e.goal.visits} ${(e.goal.type == 'PERCENT' ? '% dos produtores' : 'produtores')} por mês`;
      let showGoals = true;
      switch (e.type) {
        case 'priority' :
          title = 'Prioritários CPP - Produtores com média geométrica trimestral acima de 300 no mês';
          break;
        case 'critical' :
          title = 'Críticos CPP - Produtores com 2 médias trimestrais consecutivas acima de 300 no mês';
          break;
        case 'priority-ccs' :
          title = 'Prioritários CCS - Produtores com média geométrica mensal acima de 500 no mês';
          break;
      }
      
      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(140, 23, 23, 1)'],
        title: {
          text: `${title}`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          subtext: `${subtitle}`,
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 500,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
         
          formatter: items =>  [
            items[0].name,
            ...items.map(param => `${param.marker} ${param.seriesName} <b>${param.data}</b>`),
            e.type == 'critical' ?
              `Salvos 59: <b>${data[items[0].dataIndex].producers_in59} </b><br>` +
              `Salvos 76: <b>${data[items[0].dataIndex].producers_in76} </b>`
              : null
          ].join('<br>')
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                
                let image = '';
                switch (e.type) {
                  case 'priority' :
                    image = _.first(this.$refs.priority).getDataURL({ backgroundColor: '#0c3b69' });
                    break;
                  case 'critical' :
                    image = _.first(this.$refs.critical).getDataURL({ backgroundColor: '#0c3b69' });
                    break;
                  case 'priority_ccs' :
                    image = _.first(this.$refs.priority_ccs).getDataURL({ backgroundColor: '#0c3b69' });
                    break;
                }

                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: data.map(o => this.formatMonth(o.month)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Produtores',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: data.map(o => o.producers),
          },
                    
          {
            name: 'Visitados',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              // formatter: item => `  ${item.value} - ${data[item.dataIndex].visitsPerc}%  `,
            },
            data: data.map(o => o.visits),
          },
        
          showGoals ? {
            name: 'Meta',
            type: 'line',
            lineStyle: { width: 2 },
            emphasis: {
              focus: 'series'
            },
            label: {
              normal: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: o => '  ' + data[o.dataIndex].goals,
              }
            },
            data: data.map(o => o.goals),
          } : null,
          
          e.type == 'critical' ? {
            name: 'Não Salvos',
            type: 'bar',
            barGap: '-100%',
            data: data.map(o => o.producers_not_save),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          } : null,
        ]
      };
    },
    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
  },
};
</script>