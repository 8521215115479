<template>
  <div class="mx-2">
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Programação de Produção
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-spacer />

      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <v-date-range-picker
          v-model="filter.period"
          :ranges="dateRangeFilters"
          label="Período"
          dark
          @change="loadPlanning"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            Programação de entrada de Leite
            <v-spacer />
            <div class="hidden-xs-only">
              <div class="d-flex">
                <div
                  class="d-flex flex-column justify-end px-14"
                >
                  <span class="body-2 font-weight-light">
                    Volume Total
                  </span>
                  <span
                    class="body-1 font-weight-bold cyan--text text--accent-3"
                  >{{ formatNumber(ownMilk.total + spotTotals.total) }}</span>
                </div>
              </div>
            </div>
          </v-card-title>
          <v-simple-table
            dark
            dense
            disable-sort
            hide-default-footer
          >
            <template #default>
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :width="header.width"
                    :class="{[`text-${header.align}`]: header.align}"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr class="hidden-xs-only">
                  <td />
                  <td
                    v-for="(date, idx) in dates"
                    :key="idx"
                    class="text-start text-truncate"
                  >
                    {{ dateFormat(date.date, 'dddd') }}
                  </td>
                  <td />
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Leite Próprio</b>
                  </td>
                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    {{ formatNumber(ownMilk[column]) }}
                  </td>

                  <td class="text-end">
                    <b>{{ formatNumber(ownMilk.total) }}</b>
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td
                    class="text-start"
                    :colspan="headers.length"
                  >
                    <b>Leite Spot</b>
                  </td>
                </tr>

                <tr
                  v-for="(item, row) in spots"
                  :key="row"
                >
                  <td
                    v-for="(header, col) in headers"
                    :key="col"
                    :class="{[`text-${header.align}`]: header.align}"
                  >
                    <template
                      v-if="dateColumns.includes(header.value)"
                    >
                      <span>
                        {{ formatNumber(get(item, header.value)) }}
                      </span>
                    </template>

                    <template v-else-if="header.value === 'total'">
                      <b>{{ formatNumber(get(item, header.value)) }}</b>
                    </template>

                    <template v-else>
                      {{ get(item, header.value) }}
                    </template>
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Total Spot</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    {{ formatNumber(spotTotals[column]) }}
                  </td>

                  <td class="text-end">
                    <b>{{ formatNumber(spotTotals.total) }}</b>
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td :colspan="headers.length" />
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Total Volume</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    {{ formatNumber(ownMilk[column] + spotTotals[column]) }}
                  </td>

                  <td class="text-end">
                    <b>{{ formatNumber(ownMilk.total + spotTotals.total) }}</b>
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Kg Queijo</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ formatNumber(get(planning[column], 'queijo.total')) }}
                        </span>
                      </template>
                      {{ formatNumber(totals[column]) }} ÷ {{ formatNumber(get(planning[column], 'queijo.densidade'), 0) }}
                    </v-tooltip>
                  </td>

                  <td class="text-end">
                    <b>
                      {{ formatNumber(get(planning.total, 'queijo')) }}
                    </b>
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td
                    class="text-center py-4 text-h6"
                    :colspan="headers.length"
                  >
                    Estoques de Leite com Base Produção
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Estoque</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    {{ formatNumber(get(planning[column], 'estoque')) }}

                    <template v-if="idx === 0">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            small
                            @click="editStock"
                            v-on="on"
                          >
                            <v-icon small>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        Editar estoque
                      </v-tooltip>

                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            small
                            @click="recalculateStock"
                            v-on="on"
                          >
                            <v-icon small>
                              autorenew
                            </v-icon>
                          </v-btn>
                        </template>
                        Recalcular estoque
                      </v-tooltip>
                    </template>
                  </td>

                  <td />
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Leite Próprio</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    {{ formatNumber(get(planning[column], 'leite_proprio')) }}
                  </td>

                  <td class="text-end">
                    <b>
                      {{ formatNumber(get(planning.total, 'leite_proprio')) }}
                    </b>
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Leite Spot</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    {{ formatNumber(get(planning[column], 'leite_spot')) }}
                  </td>

                  <td class="text-end">
                    <b>
                      {{ formatNumber(get(planning.total, 'leite_spot')) }}
                    </b>
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td :colspan="headers.length" />
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Total</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ formatNumber(get(planning[column], 'total')) }}
                        </span>
                      </template>
                      {{ formatNumber(get(planning[column], 'estoque'), 0) }}
                      +
                      {{ formatNumber(get(planning[column], 'leite_proprio'), 0) }}
                      +
                      {{ formatNumber(get(planning[column], 'leite_spot'), 0) }}
                    </v-tooltip>
                  </td>

                  <td />
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Consumo</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ formatNumber(get(planning[column], 'consumo')) }}
                        </span>
                      </template>
                      {{ formatNumber(get(planning[column], 'tinas'), 0) }}
                      *
                      {{ formatNumber(get(planning[column], 'capacidade_tina'), 0) }}
                    </v-tooltip>
                  </td>

                  <td class="text-end">
                    <b>
                      {{ formatNumber(get(planning.total, 'consumo')) }}
                    </b>
                  </td>
                </tr>

                <tr class="hidden-xs-only">
                  <td :colspan="headers.length" />
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Estoque Dia</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ formatNumber(get(planning[column], 'estoque_dia')) }}
                        </span>
                      </template>
                      {{ formatNumber(get(planning[column], 'total'), 0) }}
                      -
                      {{ formatNumber(get(planning[column], 'consumo'), 0) }}
                    </v-tooltip>
                  </td>

                  <td />
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Tinas</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    {{ formatNumber(get(planning[column], 'tinas'), 0) }}
                  </td>

                  <td />
                </tr>

                <tr class="hidden-xs-only">
                  <td :colspan="headers.length" />
                </tr>

                <tr class="hidden-xs-only">
                  <td class="text-start">
                    <b>Creme à Granel Previsto</b>
                  </td>

                  <td
                    v-for="(column, idx) in dateColumns"
                    :key="idx"
                    class="text-start"
                  >
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ formatNumber(get(bulkCream[column], 'previsto'), 0) }}
                        </span>
                      </template>

                      <table>
                        <tr>
                          <td>Consumo:</td> <td>{{ formatNumber(get(bulkCream[column], 'consumo'), 0) }}</td>
                        </tr>
                        <tr>
                          <td /> <td />
                        </tr>
                        <tr>
                          <td>Padronização:</td> <td>{{ formatNumber(get(bulkCream[column], 'padronizacao_leite'), 0) }}%</td>
                        </tr>
                        <tr>
                          <td>Soro / Tina:</td> <td>{{ formatNumber(get(bulkCream[column], 'soro_por_tina'), 0) }}%</td>
                        </tr>
                        <tr>
                          <td>Gordura Soro:</td> <td>{{ formatNumber(get(bulkCream[column], 'gordura_soro'), 0) }}%</td>
                        </tr>
                        <tr>
                          <td>Gord. Desejada:</td> <td>{{ formatNumber(get(bulkCream[column], 'gordura_desejada'), 0) }}%</td>
                        </tr>
                      </table>
                    </v-tooltip>
                  </td>
                  <td />
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-title />
        </v-card>
      </v-col>
    </v-row>

    <planning-dialog
      v-model="planningDialog.show"
      :planning="planningDialog.planning"
      @change="loadPlanning"
    />

    <v-dialog
      v-model="editStockDialog.show"
      scrollable
      :max-width="300"
    >
      <v-card>
        <v-card-title>
          Editar estoque
          <v-spacer />

          <v-btn
            icon
            small
            @click="editStockDialog.show = false"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form
            ref="editStockForm"
            lazy-validation
            @submit="saveStock"
          >
            <v-text-field
              v-model="editStockDialog.stock"
              label="Estoque"
              prefix="(L)"
              type="number"
              placeholder=" "
              :rules="[v => !!v || 'Campo obrigatório!']"
              @keypress="disableDot"
            />
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            outlined
            color="grey"
            @click="editStockDialog.show = false"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            outlined
            @click="saveStock"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue darken-1"
        @click="newPlanning"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Novo planejamento
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import PlanningDialog from '@/Domains/Industry/ProductionPlanning/Components/PlanningDialog.vue';
import ReportMixin from '@/Support/Mixins/ReportMixin.js';

import _ from 'lodash';
import moment from 'moment-timezone';

moment.locale('pt');

export default {
  name: 'production-planning',

  components: {
    VDateRangePicker,
    PlanningDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      filter: {
        period: [],
      },

      spots: [],

      ownMilk: {},

      planning: {},

      bulkCream: {},

      planningDialog: {
        show: false,
        planning: null
      },

      editStockDialog: {
        show: false,
        stock: null,
      },
    };
  },

  computed: {
    spotTotals() {
      return this.spots.reduce((acc, cur) => {
        cur.items.forEach(item => {
          const date = moment(item.data).format('YYYY_MM_DD');
          if (!(date in acc)) {
            acc[date] = 0;
          }
          acc[date] += parseFloat(item.volume);
          acc.total += parseFloat(item.volume);
        });

        return acc;
      }, { total: 0 })
    },

    totals() {
      const totals = this.dateColumns.reduce((acc, column) => ({
        ...acc,
        [column]: this.ownMilk[column] + this.spotTotals[column]
      }), {});

      totals.total = this.ownMilk.total + this.spotTotals.total;

      return totals;
    },

    dates() {
      let period = [];

      const [startDate, endDate] = this.filter.period;

      let dateAux = startDate;

      while (dateAux <= endDate) {
        const date = moment(dateAux);
        period.push({
          date,
          column: date.format('YYYY_MM_DD')
        });
        dateAux = date.clone().add(1, 'day').format('YYYY-MM-DD');
      }

      return period;
    },

    dateColumns() {
      return this.dates.map(date => date.column);
    },

    headers() {
      const headers = [{ text: '', value: 'company.name', align: 'start' }];

      for (let date of this.dates) {
        headers.push({
          text: date.date.format('DD/MM/YYYY'),
          value: date.column,
          align: 'start'
        })
      }

      headers.push({ text: 'Total', value: 'total', align: 'end' });

      return headers;
    },

    dateRangeFilters() {
      const today = moment().date();
      let currrentFortnight, previousFortnight, nextFortnight;

      if (today <= 15) {
        currrentFortnight = [moment().startOf('month').format('YYYY-MM-DD'), moment().date(15).format('YYYY-MM-DD')]
        previousFortnight = [moment().subtract(1, 'month').date(16).format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')]
        nextFortnight = [moment().date(16).format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
      }
      else {
        currrentFortnight = [moment().date(16).format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
        previousFortnight = [moment().startOf('month').format('YYYY-MM-DD'), moment().date(15).format('YYYY-MM-DD')]
        nextFortnight = [moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().add(1, 'month').date(15).format('YYYY-MM-DD')]
      }

      return {
        'Esta quinzena': currrentFortnight,
        'Quinzena Anterior': previousFortnight,
        'Próxima Quinzena': nextFortnight,
      }
    }
  },

  mounted() {
    this.filter.period = this.dateRangeFilters['Esta quinzena'];
  },

  methods: {
    async loadPlanning() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filter.period;

        const [{ data: { leite_proprio, leite_spot } }, { data }] = await Promise.all([
          this.$axios.get(`/spot/programacaoLeite`, { params: {
            data_inicio: moment(startDate).subtract(1, 'day').format('YYYY-MM-DD'),
            data_fim: endDate
          } }),
          this.$axios.get(`/industry/planning`, { params: {
            start_date: startDate,
            end_date: endDate
          } })
        ]);

        if (!_.isArray(leite_proprio) || !_.isArray(leite_spot)) {
          throw 'PHP Error';
        }

        this.ownMilk = leite_proprio.reduce((acc, cur) => ({
          ...acc,
          [moment(cur.data).format('YYYY_MM_DD')]: parseFloat(cur.volume || '0'),
        }), {});

        this.ownMilk.total = this.dateColumns.reduce((acc, column) => (acc + this.ownMilk[column]), 0);

        this.spots = _(leite_spot)
          .map(o => ({
            key: `${o.id_empresa}_${o.id_materia_prima}`,
            ...o,
          }))
          .groupBy('key')
          .map((items) => {
            const { id_empresa, nome_empresa } = items[0];
            return {
              company: {
                id: id_empresa,
                name: nome_empresa
              },
              ...items.reduce((acc, cur) => ({
                ...acc,
                [moment(cur.data).format('YYYY_MM_DD')]: parseFloat(cur.volume),
              }), {}),
              total: items.reduce((acc, cur) => (acc + parseFloat(cur.volume)), 0),
              items
            }
          }).value();

        const previousColumn = moment(startDate).subtract(1, 'day').format('YYYY_MM_DD');

        let previous = {
          stock: 0,
          ownMilkTotal: this.ownMilk[previousColumn] || 0,
          spotTotal: this.spotTotals[previousColumn] || 0,
        };

        const plannings = data.reduce((acc, cur) => ({ ...acc, [moment(cur.data).format('YYYY_MM_DD')]: cur }), {});

        this.planning = this.dateColumns.reduce((acc, column) => {
          const item = plannings[column] || {};

          // Obtém o estoque salvo do item para o primeiro dia do planejamento
          const previousStock = previous.stock || _.get(item, 'estoque', 0);

          const total = previous.ownMilkTotal + previous.spotTotal + previousStock;

          const consumption = _.get(item, 'tinas', 0) * _.get(item, 'capacidade_tina', 0);

          const stock = total - consumption;

          const planning = {
            ...item,

            queijo: {
              densidade: _.get(item, 'queijo.densidade', 0),
              total: _.get(item, 'queijo.densidade') ? (this.totals[column] / _.get(item, 'queijo.densidade')) : 0,
            },
            creme_a_granel: item.creme_a_granel || {},

            estoque: previousStock,
            leite_proprio: previous.ownMilkTotal,
            leite_spot: previous.spotTotal,
            total: total,
            consumo: consumption,
            estoque_dia: stock,
          };

          previous = {
            stock,
            ownMilkTotal: this.ownMilk[column],
            spotTotal: this.spotTotals[column],
          };

          return {
            ...acc,
            [column]: planning
          };
        }, {});

        const values = Object.values(this.planning);

        this.planning.total = {
          queijo: _.sumBy(values, 'queijo.total'),
          leite_proprio: _.sumBy(values, 'leite_proprio'),
          leite_spot: _.sumBy(values, 'leite_spot'),
          consumo: _.sumBy(values, 'consumo'),
        };

        this.bulkCream = data
          .filter(item => item.creme_a_granel)
          .reduce((acc, cur) => {
            const column = moment(cur.data).format('YYYY_MM_DD');
            const { consumo = 0 } = this.planning[column];

            const percPadronizacao = cur.creme_a_granel.padronizacao_leite / 100;
            const percSoroPorTina = cur.creme_a_granel.soro_por_tina / 100;
            const percGordSoro = cur.creme_a_granel.gordura_soro / 100;
            const percGordDesejada = cur.creme_a_granel.gordura_desejada / 100;

            return {
              ...acc,
              [column]: {
                ...cur.creme_a_granel,
                consumo,
                previsto: ((consumo * percPadronizacao) / percGordDesejada) + (((consumo * percSoroPorTina) * percGordSoro) / percGordDesejada),
              }
            };
          }, {});

      } catch (error) {
        console.warn(error);

        this.$snotify.error(
          'Oops, ocorreu um erro ao carregar os spots!',
          'Atenção'
        );
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    newPlanning() {
      const [ first = {} ] = Object.values(this.planning).filter(item => item.tinas);

      if (!first.tinas) {
        this.planningDialog = {
          show: true,
          planning: null
        }
      }
      else {
        this.editPlanning(first);
      }
    },

    editPlanning(item) {
      this.planningDialog = {
        show: true,
        planning: {
          period: this.filter.period,
          tinaCapacity: _.get(item, 'capacidade_tina', 0),
          cheese: {
            density: _.get(item, 'queijo.densidade', 0)
          },
          bulkCream: {
            milkStandardization: _.get(item, 'creme_a_granel.padronizacao_leite', 0),
            serumPerPan: _.get(item, 'creme_a_granel.soro_por_tina', 0),
            wheyFat: _.get(item, 'creme_a_granel.gordura_soro', 0),
            desiredFat: _.get(item, 'creme_a_granel.gordura_desejada', 0),
          },
        }
      }
    },

    editStock() {
      const [column] = this.dateColumns;

      this.editStockDialog.show = true;
      this.editStockDialog.stock = _.get(this.planning[column], 'estoque', null);
    },

    async saveStock() {
      if (!this.$refs.editStockForm.validate()) {
        return;
      }

      try {
        this.editStockDialog.show = false;
        this.$root.$progressBar.loading();

        const [date] = this.filter.period;

        await this.$axios.post(`/industry/planning/update-stock`, {
          date,
          stock: this.editStockDialog.stock,
        });

        this.loadPlanning();
      } catch (error) {
        this.$snotify.error('Oops, ocorreu um erro ao salvar o estoque!', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async recalculateStock() {
      if (!(await this.$root.$confirm('Recalcular estoque', 'Tem certeza que deseja recalcular este estoque?', { color: 'blue' }))) {
        return;
      }
      try {
        this.$root.$progressBar.loading();

        const [date] = this.filter.period;

        await this.$axios.post(`/industry/planning/recalculate-stock`, { date });

        this.loadPlanning();
      } catch (error) {
        console.warn(error);

        const message = _.get(error, 'response.data.message', 'Erro ao recalcular estoque');

        this.$snotify.error(message);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportJson(isExcel = false) {
      const formatNumber = isExcel ? this.formatNumberExcel : this.formatNumber;

      const line = {
        Data: '',
        ...this.dates.reduce((acc, cur) => ({ ...acc, [cur.date.format('DD/MM/YYYY')]: '' }), {}),
        Total: '',
      };

      return [
        // Dias da semana
        {
          Data: '',
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: cur.date.format('dddd')
          }), {}),
          Total: '',
        },
        // Leite próprio
        {
          Data: 'Leite Próprio',
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(this.ownMilk[cur.column])
          }), {}),
          Total: formatNumber(this.ownMilk.total),
        },
        // Spots
        {
          Data: 'Leite Spot',
          ...this.dates.reduce((acc, cur) => ({ ...acc, [cur.date.format('DD/MM/YYYY')]: '' }), {}),
          Total: '',
        },
        ...this.spots.map(item => ({
          Data: item.company.name,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(item[cur.column])
          }), {}),
          Total: formatNumber(item.total),
        })),
        // Totais por dia
        {
          Data: `Total Spot`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(this.totals[cur.column])
          }), {}),
          Total: formatNumber(this.totals.total),
        },
        { ...line },
        {
          Data: `Total Volume`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(this.ownMilk[cur.column] + this.totals[cur.column])
          }), {}),
          Total: formatNumber(this.ownMilk.total + this.totals.total),
        },
        {
          Data: `Kg Queijo`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.planning[cur.column], 'queijo.total'))
          }), {}),
          Total: formatNumber(_.get(this.planning.total, 'queijo')),
        },
        { ...line },
        { ...line },
        // Estoques de Leite com Base Produção
        {
          Data: `Estoque`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.planning[cur.column], 'estoque'))
          }), {}),
          Total: '',
        },
        {
          Data: `Leite Próprio`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.planning[cur.column], 'leite_proprio'))
          }), {}),
          Total: formatNumber(_.get(this.planning.total, 'leite_proprio')),
        },
        {
          Data: `Leite Spot`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.planning[cur.column], 'leite_spot'))
          }), {}),
          Total: formatNumber(_.get(this.planning.total, 'leite_spot')),
        },
        { ...line },
        {
          Data: `Total`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.planning[cur.column], 'total'))
          }), {}),
          Total: '',
        },
        {
          Data: `Consumo`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.planning[cur.column], 'consumo'))
          }), {}),
          Total: formatNumber(_.get(this.planning.total, 'consumo')),
        },
        { ...line },
        {
          Data: `Estoque Dia`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.planning[cur.column], 'estoque_dia'))
          }), {}),
          Total: '',
        },
        {
          Data: `Tinas`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.planning[cur.column], 'tinas'))
          }), {}),
          Total: '',
        },
        { ...line },
        {
          Data: `Creme à Granel Previsto`,
          ...this.dates.reduce((acc, cur) => ({
            ...acc,
            [cur.date.format('DD/MM/YYYY')]: formatNumber(_.get(this.bulkCream[cur.column], 'previsto'))
          }), {}),
          Total: '',
        },
      ];
    },

    getReportTitle() {
      const [startDate = moment(), endDate = moment()] = this.filter.period;
      return `Programação de Produção - ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
    },

    exportExcel() {
      const title = this.getReportTitle();
      const json = this.getReportJson(true);
      const worksheet = this.XLSX.utils.json_to_sheet(json, { origin: 'A4' });

      worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 1, c: this.dates.length + 1 } }];

      worksheet['A1'] = { v: title };

      worksheet['!cols'] = [{ wch: 25 }];

      this.dates.forEach(() => {
        worksheet['!cols'].push({ wch: 12 });
      })

      worksheet['!cols'].push({ wch: 12 });

      const workbook = this.XLSX.utils.book_new();

      this.XLSX.utils.book_append_sheet(workbook, worksheet, _.truncate(title, { length: 31 }));
      this.XLSX.writeFile(workbook, `${title}.xlsx`);
    },

    print() {
      this.printFromJson(this.getReportJson(), this.getReportTitle(), true);
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value, placeholder = '-') => !value ? placeholder : new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatNumberExcel: (value) => value == null || isNaN(value) ? '' : parseFloat(value),
    get: _.get,
    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
