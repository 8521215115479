<template>
  <div>
    <v-dialog
      v-model="show"
      width="800"
      max-width="800"
      hide-overlay
    >
      <v-card flat>
        <v-card-title>Plano de Qualificação de Fornecedores de Leite</v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-iterator
            :items="dataPQFL"
            :items-per-page.sync="itemsPerPage"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.id_visita"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <v-card>
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>Data:</v-list-item-content>
                        <v-list-item-content
                          class="align-end"
                        >
                          {{ item.data_visita | dateFormat("DD/MM/YYYY") }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Rota:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ !item.rota?'-':item.rota }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Técnico:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.tecnico }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Produtor:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          {{ item.produtor }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider class="ma-1 pa-0" />
                    <div
                      class="text-right"
                      style="margin: 7px; padding-top: 2px; padding-right: 7px; padding-bottom: 7px;"
                    >
                      <v-icon
                        color="blue-grey darken-3"
                        @click="toPrintPQFL(item)"
                      >
                        print
                      </v-icon>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <template v-slot:no-data>
              <v-row class="text-center font-weight-bold">
                <v-col
                  cols="12"
                  md="12"
                >
                  <span>Não há dados disponíveis</span>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import printJS from "print-js";

export default {
  filters: {
    dateFormat: function(value, opts) {

      if (_.isEmpty(value)) {
        return '-';
      }

      return moment(value).format(opts);
    },
  },
  props: ["visible"],
  data() {
    return {
      dataPQFL: [],

      itemsPerPage: 4,

      show: false,

      producerId: null,
    };
  },
  watch: {
    show(value) {
      if (!value) {
        this.$emit("close");
        this.dataPQFL = [];

        return false;
      }
      return true;
    }
  },
  methods: {
    async loadPQFL() {
      try {
        if (!this.producerId) {
          return true;
        }

        let { data } = await this.$axios.post(
          `/pqflProdutores/obterTabelaQuestionario`,
          qs.stringify({
            id_produtor: this.producerId,
          })
        );

        data = _.get(data, "tabela_questionario");

        /**
         * Verifa se a response é um array
         */
        if (!_.isArray(data)) {
          return true;
        }
        this.dataPQFL = data;
      } catch (err) {
        console.warn(err);
      }
    },

    async openDialog(producerId) {
      this.producerId = producerId;
      await this.loadPQFL();
      this.show = true;
    },

    async toPrintPQFL(item) {
      let questions = _.groupBy(JSON.parse(item.questoes), "sumario");

      /**
       * Ordena os questinários
       */
      _.forEach(questions, (itens, key) => {
        _.set(questions, key, _.sortBy(itens, ["sumario_questao"]));
      });

      try {

        const { data } = await this.$axios.post(
          `/pqflProdutores/printcheckListPQFL`,
          qs.stringify({
            questions: questions,
            producer: {
              codigo_laticinio: item.codigo_laticinio,
              data_visita: _.isEmpty(item.data_visita) ? '' : moment(item.data_visita).format("DD/MM/YYYY"),
              nome: item.produtor,
              rota: item.rota,
              tecnico: item.tecnico,
            },
          })
        );

        return printJS({
          printable: data,
          type: "raw-html",
          css: [
            "https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css",
            "https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap-theme.min.css",
          ],
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
