<template>
  <div>
    <v-select
      v-model="input"
      :items="itineraries"
      :label="label"
      :prepend-inner-icon="prependInnerIcon"
      item-value="id"
      item-text="description"
      return-object
      v-bind="$attrs"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
      <template
        #append-item
      >
        <v-divider class="mt-2" />
        <v-row no-gutters>
          <v-col>
            <v-btn
              block
              outlined
              @click="addNew"
            >
              Cadastrar novo
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-select>

    <itinerary-registration-dialog
      v-model="showNewDialog"
      :type="type"
      spot-type-disabled
      @newItinerary="onNewItinerary"
    />
  </div>
</template>

<script>
import ItineraryRegistrationDialog from "@/Domains/Itineraries/Components/ItineraryRegistrationDialog.vue";

import moment from "moment";

export default {

  components: {
    ItineraryRegistrationDialog,
  },

  props: {
    value: {
      type: Object,
    },
    label: {
      type: String,
      default: 'Itinerário'
    },
    prependInnerIcon: {
      type: String,
      default: 'add_location_alt'
    },
    type: {
      type: String,
      default: 'transferencia',
      validator: (value) => ['transferencia', 'spot'].includes(value)
    },
    spotType: {
      type: String,
    },
  },

  data() {
    return {
      showNewDialog: false,
      itineraries: [],

      input: null,
    };
  },

  watch: {
    value: {
      depth: true,
      handler(value) {
        if (value.id) {
          this.input = this.itineraries.find(o => o.id == value.id);
          this.$emit("input", this.input);
        }
      }
    }
  },

  created() {
    this.getItineraries();
  },

  methods: {
    async getItineraries() {
      const { data } = await this.$axios.post(`/itinerario/itinerarioAberto`, { tipo: this.type, tipo_spot: this.spotType });

      if (this.type == 'transferencia') {
        this.itineraries = data.map(o => ({
          id: o.id_itinerario,
          description: `${o.nome_laticinio_transferencia} | ${o.placa} | ${moment(o.data_hora_inicio).format('DD/MM/YYYY')}`,
          tanks: JSON.parse(o.tanques || '[]'),
          rawProductId: o.id_materia_prima
        }));
      }
      if (this.type == 'spot') {
        this.itineraries = data.map(o => ({
          id: o.id_itinerario,
          description: `${o.nome_empresa} | ${o.placa} | ${moment(o.data_hora_inicio).format('DD/MM/YYYY')}`,
          tanks: JSON.parse(o.tanques || '[]'),
          rawProductId: o.id_materia_prima
        }));
      }

      if (this.value.id) {
        this.input = this.itineraries.find(o => o.id == this.value.id);
        this.$emit("input", this.input);
      }
    },

    addNew() {
      this.showNewDialog = true;
    },

    async onNewItinerary(data) {

      const value = {
        id: data.id,
        description: (this.type == 'transferencia')
          ? `${data.transferDairy.name} | ${data.vehicle.plate} | ${moment(data.startedAt, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')}`
          : `${data.company.name} | ${data.vehicle.plate} | ${moment(data.startedAt, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')}`,
        tanks: data.vehicle.tanks
      };

      this.itineraries.push(value);

      this.input = value;
      this.$emit("input", this.input);
      this.showNewDialog = false;
    },
  },
};
</script>
