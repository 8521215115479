<template>
  <v-dialog
    v-model="show"
    scrollable
    width="80%"
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="order-dialog">
      <v-card-title>
        <span class="text-h6">Entrada de Ordem de Produção nº {{ order.code }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div style="min-height:400px">
          <v-row>
            <v-col class="pt-1 pb-0">
              <v-text-field
                :value="order.item?.description"
                :label="order.item?.isRawMaterial ? 'Matéria Prima' : 'Produto'"
                disabled
              />
            </v-col>
            <v-col
              v-if="hasPlannedQuantityAccess"
              class="pt-1 pb-0"
            >
              <masked-text-field
                :value="order.quantity"
                label="Qtde. Planejada"
                unmask
                :mask="masks.float"
                :suffix="order.item?.measurement || 'LT'"
                disabled
              />
            </v-col>
            <v-col class="pt-1 pb-0">
              <masked-text-field
                :value="order.accomplishedQuantity"
                label="Qtde. Realizada"
                unmask
                :mask="masks.float"
                :suffix="order.item?.measurement || 'LT'"
                disabled
              />
            </v-col>
            <v-col
              v-if="canEdit"
              class="pl-0 d-flex align-center justify-space-between"
              style="max-width: 149px;"
            >
              <template v-if="!!order.showPacking || packings.length > 0">
                <v-tooltip
                  v-if="canEdit"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      color="primary"
                      icon
                      small
                      v-on="on"
                      @click="addEntry(order)"
                    >
                      <v-icon>system_update_alt</v-icon>
                    </v-btn>
                  </template>
                  Efetuar entrada
                </v-tooltip>
                <v-tooltip
                  v-if="canEdit"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      color="purple"
                      icon
                      small
                      v-on="on"
                      @click="addPacking()"
                    >
                      <v-icon>scale</v-icon>
                    </v-btn>
                  </template>
                  Nova Embalagem/Pesagem
                </v-tooltip>
                <v-menu
                  offset-y
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="teal"
                      icon
                      small
                      v-on.stop="on"
                    >
                      <v-icon
                        dark
                        dense
                      >
                        pallet
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="dropdown-list">
                    <v-list-item
                      v-for="(pallet, index) in pallets"
                      :key="index"
                      @click="showPallet(pallet.id)"
                    >
                      <v-list-item-icon>
                        <v-icon>pallet</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ pallet.description }}</v-list-item-title>
                        <v-list-item-subtitle>{{ pallet.barcode }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      v-if="pallets.length > 0"
                    />

                    <v-list-item
                      v-if="canEdit"
                      @click="showPallet()"
                    >
                      <v-list-item-icon>
                        <v-icon>add</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Novo Pallet</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  icon
                  class="v-icon"
                  :style="packingsExpanded ? 'transform: rotate(-180deg);' : ''"
                  @click="packingsExpanded = !packingsExpanded"
                >
                  <v-icon>keyboard_arrow_down</v-icon>
                </v-btn>
              </template>
              <v-btn
                v-else
                color="primary"
                outlined
                @click="addEntry(order)"
              >
                Efetuar entrada
              </v-btn>
            </v-col>
            <v-col
              v-if="packingsExpanded"
              cols="12"
              class="expanded-content"
            >
              <v-data-table
                :items="packings"
                :headers="packingHeaders"
                hide-default-footer
                disable-pagination
                disable-sort
                disable-filtering
                class="my-4"
              >
                <template #[`item.quantity`]="{ value, item }">
                  {{ formatNumber(value) }} {{ item.measurement }}
                </template>
                <template #[`item.grossWeight`]="{ value }">
                  {{ formatNumber(value) + ' Kg' }}
                </template>
                <template #[`item.tareWeight`]="{ value }">
                  {{ formatNumber(value) + ' Kg' }}
                </template>
                <template #[`item.netWeight`]="{ value }">
                  {{ formatNumber(value) + ' Kg' }}
                </template>
                <template #[`item.createdAt`]="{ value }">
                  {{ formatDate(value, 'DD/MM/YYYY') }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <template v-if="hasEditAccess">
                    <v-btn
                      v-if="item.palletId"
                      icon
                      @click.stop="showPallet(item.palletId)"
                    >
                      <v-icon color="teal">
                        edit
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      icon
                      @click.stop="removePacking(item.id)"
                    >
                      <v-icon color="error darken-1">
                        delete
                      </v-icon>
                    </v-btn>
                  </template>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div
            v-if="order.coproducts.length > 0"
            class="ml-2"
          >
            <span class="text-h6 d-block mt-4">Coprodutos</span>
            <v-divider class="mb-4" />
          </div>

          <v-row
            v-for="(data, idx) of order.coproducts"
            :key="idx"
            style="border-bottom: 1px #eee solid;"
          >
            <v-col class="pt-1 pb-0">
              <v-text-field
                :value="data.item?.description"
                :label="data.item?.isRawMaterial ? 'Matéria Prima' : 'Produto'"
                disabled
              />
            </v-col>
            <v-col
              class="pt-1 pb-0"
            >
              <masked-text-field
                :value="data.quantity"
                :label="order.item?.measurement && data.item?.measurement ? `Qtde. de ${order.item.measurement} por ${data.item.measurement}` : 'Fator de Conversão'"
                unmask
                :mask="masks.float"
                :suffix="order.item?.measurement || 'LT'"
                disabled
              />
            </v-col>
            <v-col class="pt-1 pb-0">
              <masked-text-field
                :value="data.accomplishedQuantity"
                label="Qtde. Realizada"
                unmask
                :mask="masks.float"
                :suffix="data.item?.measurement || 'LT'"
                disabled
              />
            </v-col>
            <v-col
              v-if="canEdit"
              class="pl-0 d-flex flex-column justify-center"
              style="max-width: 149px;"
            >
              <v-btn
                color="primary"
                outlined
                @click="addEntry(data)"
              >
                Efetuar entrada
              </v-btn>
            </v-col>
          </v-row>

          <template v-if="lots.length > 0">
            <span class="text-h6 d-block mt-4">Lotes</span>

            <v-divider class="mb-4" />

            <v-data-table
              :headers="stocksHeaders"
              :items="lots"
              light
              :items-per-page="-1"
              disable-pagination
              hide-default-footer
            >
              <template #[`item.manufacturingDate`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.expirationDate`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.quantity`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.reservedQuantity`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.availableQuantity`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.lotStatus`]="{ value }">
                <v-chip
                  v-if="value"
                  small
                  dark
                  :color="getLotStatusColor(value)"
                >
                  {{ value }}
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  v-if="hasEditAccess"
                  icon
                  @click.stop="editStock(item)"
                >
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </template>

          <template v-if="records.length > 0">
            <span class="text-h6 d-block mt-4">Registros de Estoques</span>
            <v-divider class="mb-4" />
          </template>

          <v-data-table
            v-if="records.length > 0"
            :headers="recordsHeaders"
            :items="records"
            disable-pagination
            disable-sort
            disable-filtering
            hide-default-footer
          >
            <template #[`item.date`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
            </template>
            <template #[`item.quantity`]="{ item }">
              <span
                :class="{
                  'green--text': item.operation === 'ENTRADA',
                  'red--text': item.operation === 'SAIDA',
                }"
              >
                {{ formatNumber(item.quantity) }} {{ item.measurement }}
              </span>
            </template>
            <template #[`item.manufacturingDate`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.expirationDate`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip
                v-if="hasChargebackAccess"
                top
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click.stop="chargebackStock(item)"
                  >
                    <v-icon>
                      settings_backup_restore
                    </v-icon>
                  </v-btn>
                </template>
                Estornar
              </v-tooltip>
            </template>
          </v-data-table>

          <template v-if="transfers.length > 0">
            <span class="text-h6 d-block mt-4">Registros de Silos</span>
            <v-divider class="mb-4" />
          </template>

          <v-data-table
            v-if="transfers.length > 0"
            :headers="transfersHeaders"
            :items="transfers"
            disable-pagination
            disable-sort
            disable-filtering
            hide-default-footer
          >
            <template #[`item.transferredVol`]="{ value }">
              {{ formatNumber(value) }} L
            </template>
            <template #[`item.transferredAt`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
            </template>
            <template #[`item.rawMaterial.description`]="{ value }">
              <v-chip
                v-if="value"
                x-small
              >
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip
                v-if="hasChargebackAccess"
                top
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click.stop="chargebackMovement(item)"
                  >
                    <v-icon>
                      settings_backup_restore
                    </v-icon>
                  </v-btn>
                </template>
                Estornar
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="pendingPackingStocks.length > 0"
          color="teal"
          outlined
          @click="onPendingPackingStocks()"
        >
          Processar estoque embalado
        </v-btn>
        <v-btn
          v-else-if="writeOffItems.length > 0"
          color="orange darken-3"
          outlined
          @click="onAppointment()"
        >
          Processar apontamentos pendentes
        </v-btn>
      </v-card-actions>
    </v-card>

    <production-entry-form-dialog
      ref="productionEntryFormDialog"
      :order="order"
      :silos="silos"
      :tinas="tinas"
      @save="onEntrySaved"
    />

    <edit-stock-dialog
      ref="editStockDialog"
      quantity-disabled
      hide-price
      @save="onEditStockSaved"
    />

    <product-write-off-dialog
      ref="productWriteOffDialog"
      @save="loadOrder"
    />

    <pallet-dialog
      ref="palletDialog"
      settings-id="PRODUCTION_PALLET"
      :can-edit-order="canEdit"
      @close="onPalletClosed"
    />

    <packing-dialog
      ref="packingDialog"
      settings-id="PRODUCTION_WEIGHING"
      :can-edit-order="canEdit"
      @close="onPalletClosed"
    />
  </v-dialog>
</template>

<style>
.expanded-content {
  box-shadow: inset 0 4px 8px -5px rgba(50, 50, 50, .75), inset 0 -4px 8px -5px rgba(50, 50, 50, .75);
}
</style>

<script>
import EditStockDialog from '@/Domains/Registrations/Item/Components/EditStockDialog.vue';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';
import ProductionEntryFormDialog from '@/Domains/Industry/ProductionOrder/Components/ProductionEntryFormDialog.vue';
import ProductWriteOffDialog from '@/Domains/Industry/ProductionOrder/Components/ProductWriteOffDialog.vue';
import PalletDialog from '@/Domains/Shipment/StorageControl/Components/PalletDialog.vue'
import PackingDialog from '@/Domains/Shipment/StorageControl/Components/PackingDialog.vue'

import api from '@/Domains/Industry/ProductionOrder/Api/ProductionOrder.js';

import moment from 'moment'

export default {

  components: {
    EditStockDialog,
    MaskedTextField,
    ProductionEntryFormDialog,
    ProductWriteOffDialog,
    PalletDialog,
    PackingDialog,
  },

  props: {
    value: Boolean,
    orderId: String,
    step: String,
  },

  data() {
    return {
      order: {
        item: {},
        coproducts: [],
        transfers: [],
        records: [],
        lots: [],
        pallets: [],
        packings: [],
      },

      writeOffItems: [],
      pendingPackingStocks: [],
      pallets: [],
      packings: [],
      packingsExpanded: false,

      silos: [],

      tinas: [],

      masks: {
        float: { mask: Number, min: 0, scale: 4 },
        integer: { mask: Number, min: 0, scale: 0, signed: false },
      },

      stocksHeaders: [
        { text: 'Produto', value: 'product.name' },
        { text: 'Lote', value: 'lotNumber' },
        { text: 'Fabricação', value: 'manufacturingDate' },
        { text: 'Validade', value: 'expirationDate' },
        { text: 'Depósito', value: 'warehouse.name' },
        { text: 'Qtde. Física', value: 'quantity', align: 'center' },
        { text: 'Qtde. Disponível', value: 'availableQuantity', align: 'center' },
        { text: 'Status Lote', value: 'lotStatus', align: 'center' },
        { text: '', value: 'actions', align: 'center', sortable: false },
      ],

      recordsHeaders: [
        { text: 'Produto', value: 'product.name' },
        { text: 'Data e Hora', value: 'date' },
        { text: 'Pessoa', value: 'person' },
        { text: 'Qtde.', value: 'quantity', align: 'center' },
        { text: 'Nº Lote', value: 'lotNumber' },
        { text: 'Fabricação', value: 'manufacturingDate' },
        { text: 'Validade', value: 'expirationDate' },
        { text: 'Queijomatic', value: 'tank.name', align: 'center' },
        { text: '', value: 'actions', align: 'center', width: 30, sortable: false },
      ],

      transfersHeaders: [
        { text: 'Silo', value: 'silo.description' },
        { text: 'Data e Hora', value: 'transferredAt' },
        { text: 'Volume', value: 'transferredVol', align: 'center' },
        { text: 'Nº Lote', value: 'batchNumber' },
        { text: 'Matéria Prima', value: 'rawMaterial.description' },
        { text: '', value: 'actions', align: 'center', width: 30, sortable: false },
      ],

      packingHeaders: [
        { text: 'Lote', value: 'lotNumber' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Peso Bruto', value: 'grossWeight', mask: '#,##0.00' },
        { text: 'Peso Tara', value: 'tareWeight', mask: '#,##0.00' },
        { text: 'Peso Líquido', value: 'netWeight', mask: '#,##0.00' },
        { text: 'Data', value: 'createdAt' },
        { text: 'Código de Barras', value: 'barcode' },
        { text: 'Pallet', value: 'palletCode' },
        { text: '', value: 'actions', width: 30, sortable: false },
      ],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    canEdit() {
      return ['PLANEJADO', 'LIBERADO'].includes(this.order.status)
    },

    hasRawMaterial() {
      return this.items.some(item => item.isRawMaterial);
    },

    hasProduct() {
      return this.items.some(item => !item.isRawMaterial);
    },

    items() {
      if (this.order?.coproducts?.length) {
        return [this.order.item, ...this.order.coproducts.map(coproduct => coproduct.item)];
      }

      return [this.order.item];
    },

    lots () {
      return this.order.lots.filter(item => item.quantity !== 0);
    },

    transfers() {
      const ids = this.items.map(item => item.id);

      return this.order.transfers.filter(transfer => ids.includes(transfer.rawMaterial.id));
    },

    records() {
      const ids = this.items.map(item => item.id);

      return this.order.records.filter(record => ids.includes(record.product.id));
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasPlannedQuantityAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-qtde-planejada' && o.tipo === 'COMPONENTE');
    },
    hasEditAccess() {
      if (!this.canEdit) {
        return false
      }
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-edicao-apontamento' && o.tipo === 'COMPONENTE');
    },
    hasChargebackAccess() {
      if (!this.canEdit) {
        return false
      }
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ordem-prod-exclusao-apontamento' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.order = {
          item: {},
          coproducts: [],
          transfers: [],
          records: [],
          lots: [],
          pallets: [],
          packings: [],
        };
        this.packingsExpanded = false;
        this.loadOrder();
      }
    }
  },

  methods: {
    async loadOrder() {
      try {
        this.$root.$progressBar.loading();

        this.order = await api.show(this.orderId);

        this.writeOffItems = this.order.items
          .filter(data => data.writeOffType === 'AUTOMATICA' && !data.item.isRawMaterial && (!data.baseItem?.id || data.baseItem?.id === this.order.item.id))
          .map(item => {
            const plannedQuantity = item.quantity * this.order.accomplishedQuantity;
            return {
              ...item,
              plannedQuantity,
              writeOffQuantity: plannedQuantity - item.accomplishedQuantity,
            }
          })
          .filter(item => item.writeOffQuantity > 0);

        this.packings = this.order.packings.filter((packing) => packing.item.itemId === this.order.item.id)

        this.pallets = this.order.pallets.filter((pallet) => {
          const packings = this.order.packings.filter((packing) => packing.palletId === pallet.id)
          return packings.length === 0 || packings.some((packing) => packing.item.itemId === this.order.item.id)
        })

        const pendingPackingStocks = this.packings.reduce((acc, cur) => {
          const { id } = cur.stock

          if (!(id in acc)) {
            const orderLot = this.order.lots.find(lot => lot.id === id)
            acc[id] = {
              ...cur.stock,
              itemId: cur.item.itemId,
              quantity: orderLot ? -orderLot.quantity : 0,
            }
          }

          acc[id].quantity += cur.quantity

          return acc;
        }, {});

        this.pendingPackingStocks = Object.values(pendingPackingStocks).filter(stock => stock.quantity > 0);
      } catch (e) {
        const message = e?.response?.data?.message || 'Erro ao carregar a ordem de produção'
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
        this.close();
      } finally {

        await Promise.all([
          this.loadTinas(),
          this.loadSilos(),
        ]);

        this.$root.$progressBar.hide();
      }
    },

    async loadSilos() {
      if (!this.hasRawMaterial) {
        return;
      }

      try {
        const { data } = await this.$axios.get(`/industry/silo`);

        this.silos = data
          .map(silo => ({
            id: silo.id_silo,
            label: silo.descricao,
            lotNumber: silo.numero_lote,
            vol: silo.volume_atual,
            capacity: silo.volume_total,
            volume: null,
            rawProduct: {
              id: silo.id_materia_prima,
              name: silo.nome_materia_prima,
            },
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

      } catch (e) {
        const message = e?.response?.data?.message || 'Erro ao carregar os silos'
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      }
    },

    async loadTinas() {
      if (!this.hasProduct) {
        return;
      }

      try {
        const { data } = await this.$axios.get(`/silo/listaTina`);

        this.tinas = data.map(tina => ({
          id: tina.id_tina,
          name: tina.descricao,
        }));

      } catch (e) {
        const message = e?.response?.data?.message || 'Erro ao carregar as tinas'
        this.$snotify.error(message, 'Atenção');
        console.warn(e);
      }
    },

    addEntry(data) {
      this.$refs.productionEntryFormDialog.show({
        item: data.item,
        accomplishedQuantity: data.accomplishedQuantity,
        lotNumber: this.order.lotNumber,
        lotFormat: this.order.lotFormat,
        tolerance: this.order.tolerance,
        showQueijomatic: this.order.showQueijomatic,
        transfers: this.order.transfers.filter(transfer => transfer.rawMaterial.id === data.item.id) || [],
        records: this.order.records.filter(record => record.product.id === data.item.id) || [],
      });
    },

    close() {
      this.show = false;
    },

    async onEntrySaved() {
      await this.loadOrder();

      await this.$nextTick();

      if (this.writeOffItems.length > 0) {
        this.onAppointment();
      }

      return this.$emit('save');
    },

    async onPalletClosed() {
      await this.loadOrder();

      await this.$nextTick();

      if (this.pendingPackingStocks.length > 0) {
        this.onPendingPackingStocks();
      }
    },

    editStock(stock) {
      this.$refs.editStockDialog.show({
        stockId: stock.id,
      })
    },

    onEditStockSaved() {
      this.loadOrder();
      return this.$emit('save');
    },

    onAppointment() {
      this.$refs.productWriteOffDialog.show({
        order: this.order,
        tinas: this.tinas,
        items: this.writeOffItems
      });
    },

    async onPendingPackingStocks() {
      if (this.pendingPackingStocks.length === 0) {
        return;
      }

      if (!(await this.$root.$confirm('Processar estoque embalado?', `Há estoques de produtos embalados que ainda não foram processados.`))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const stocks = this.pendingPackingStocks.map(stock => ({
          id_item: stock.itemId,
          id_deposito: stock.warehouseId,
          quantidade: stock.quantity,
          numero_lote: stock.lotNumber,
          data_fabricacao: stock.manufacturingDate,
          data_validade: stock.expirationDate,
        }))

        await Promise.all(stocks.map(payload => api.production(this.order.id, payload)))

        this.$snotify.success('Estoque gerado com sucesso', 'Sucesso');
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao gerar o estoque!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
        this.onEntrySaved();
      }
    },

    async addPacking() {
      const pendingProducts = await this.getPackingProducts(this.order.item.id)
      this.$refs.packingDialog.show({
        orderId: this.orderId,
        pendingProducts,
      });
    },

    async removePacking(packingId) {
      if (!this.canEdit) {
        return;
      }

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir esta embalagem?<br><br>`, { color: 'red', token: 'EXCLUIR' }))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.delete(`packing/${packingId}`);

        this.$snotify.success('Apagado com sucesso!', 'Sucesso')
      } catch (e) {
        console.warn(e)
        this.$snotify.error('Oops, ocorreu um erro ao excluir as embalagens!', 'Atenção')
      } finally {
        this.$root.$progressBar.hide()
        this.loadOrder();
      }
    },

    async showPallet(palletId = null) {
      const pendingProducts = await this.getPackingProducts(this.order.item.id)
      this.$refs.palletDialog.show({
        id: palletId,
        orderId: this.orderId,
        pendingPackings: this.packings.filter((packing) => !packing.palletId),
        pendingProducts,
      });
    },

    async getPackingProducts(id) {
      try {
        this.$root.$progressBar.loading();

        const products = await api.getPackingProducts(id);

        return products.map(product => ({
          ...product,
          lotInfo: {
            lotNumber: this.order.lotNumber,
            lotFormat: this.order.lotFormat,
            expirationDays: this.order.item.expirationDays,
          },
          stocks: this.order.lots
            .filter((lot) => lot.product.id === id)
            .map((lot) => ({
              id: lot.id,
              description: lot.lotNumber || 'PADRÃO',
              lotNumber: lot.lotNumber,
              manufacturingDate: lot.manufacturingDate,
              expirationDate: lot.expirationDate,
            })),
        }));

      } catch (e) {
        console.error(e)
        const message = e?.response?.data?.message || 'Erro ao carregar produto'
        this.$snotify.error(message, 'Atenção')
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async chargebackMovement(item) {
      if (!this.canEdit) {
        return;
      }

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente estornar esta movimentação?<br><br>`, { color: 'red', token: 'ESTORNAR' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.post(`/industry/silo/chargeback-movement`, {
          id: item.movementId,
        });

        this.$snotify.success('Estorno efetuado com sucesso', 'Sucesso');
        this.loadOrder();
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao estornar!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async chargebackStock(item) {
      if (!this.canEdit) {
        return;
      }

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente estornar esta entrada?<br><br>`, { color: 'red', token: 'ESTORNAR' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.post(`/stock/chargeback`, {
          id: item.id,
        });

        this.$snotify.success('Estorno efetuado com sucesso', 'Sucesso');
        this.loadOrder();
      } catch (e) {
        console.warn(e);

        this.$snotify.error('Oops, ocorreu um erro ao estornar!', 'Atenção');
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getLotStatusColor(status) {
      const colors = {
        'SUSPENSO': 'red',
        'RESTRITO': 'orange',
        'LIBERADO': 'green',
      }

      return colors[status] || 'grey';
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value),
  }

}
</script>
