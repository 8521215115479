<template>
  <div class="dashboard">
    <v-row class="mx-2">
      <v-col cols="8">
        <v-spacer />
      </v-col>
      <v-col cols="4">
        <VDateRangePicker
          v-model="filter.date"
          :ranges="filter.dateRanges"
          dark
        />
      </v-col>

      <v-col
        cols="12"
        class="py-0"
      >
        <FreightPriceCard
          :reference-month="filter.date[0]"
          :color="colors.graph7"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
        xl="2"
      >
        <CardGauge
          title="% Ocup. Própria"
          :value="occupancyRateInternal"
          :color="colors.temperature1"
          height="153px"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        xl="4"
      >
        <FleetCard
          :period="filter.date"
          fleet-type="internal"
          title="Indicadores de Coletas Próprias"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        xl="2"
      >
        <CardGauge
          title="% Ocup. de Terceiros"
          :value="occupancyRateExternal"
          :color="colors.temperature1"
          height="153px"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        xl="4"
      >
        <FleetCard
          :period="filter.date"
          fleet-type="external"
          title="Indicadores de Coletas de Terceiros"
        />
      </v-col>

      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <RoutesFreightChart
          :reference-month="filter.date[0]"
          :color="colors.graph7"
          :graph-color="colors.line7"
          height="325px"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <UnitFreightChart
          :reference-month="filter.date[0]"
          :color="colors.temperature1"
          height="325px"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <OccupancyRateChart
          :period="filter.date"
          :color="colors.graph8"
          :smooth="false"
          title="Taxa de Ocupação de Frota de Terceiros"
          fleet-type="external"
          height="188px"
          @change="onOccupancyRateChangeExternal"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <OccupancyRateChart
          :period="filter.date"
          :color="colors.graph8"
          :smooth="false"
          title="Taxa de Ocupação de Frota Própria"
          fleet-type="internal"
          height="188px"
          @change="onOccupancyRateChangeInternal"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <OccupancyRateByRouteChart
          :period="filter.date"
          :color="colors.graph7"
          height="325px"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

import OccupancyRateByRouteChart from "@/Domains/BI/Views/Transport/Components/OccupancyRateByRouteChart.vue";
import FreightPriceCard from "@/Domains/BI/Views/Transport/Components/FreightPriceCard.vue";
import RoutesFreightChart from "@/Domains/BI/Views/Transport/Components/RoutesFreightChart.vue";
import UnitFreightChart from "@/Domains/BI/Views/Transport/Components/UnitFreightChart.vue";
import OccupancyRateChart from "@/Domains/BI/Views/Transport/Components/OccupancyRateChart.vue";
import FleetCard from "@/Domains/BI/Views/Transport/Components/FleetCard.vue";

import CardGauge from "@/Domains/BI/Components/CardGauge.vue";
import moment from "moment";

export default {
  components: {
    OccupancyRateByRouteChart,
    FreightPriceCard,
    RoutesFreightChart,
    UnitFreightChart,
    VDateRangePicker,
    OccupancyRateChart,
    FleetCard,
    CardGauge,
  },
  data() {
    return {
      filter: {
        dateRanges: {
          "Este mês": [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")],
          "Mês anterior": [
            moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
            moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
          ],
          "Este ano": [moment().startOf("year").format("YYYY-MM-DD"), moment().endOf("year").format("YYYY-MM-DD")],
        },
        date: [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")],
      },
      colors: {},
      occupancyRateInternal: 0,
      occupancyRateExternal: 0,
    };
  },

  created() {
    this.setColorsPalette();
  },

  methods: {
    onOccupancyRateChangeInternal({ occupancyRateAvg }) {
      this.occupancyRateInternal = Math.max(parseInt(occupancyRateAvg), 0);
    },

    onOccupancyRateChangeExternal({ occupancyRateAvg }) {
      this.occupancyRateExternal = Math.max(parseInt(occupancyRateAvg), 0);
    },

    setColorsPalette() {
      const transparentGraph = "rgba(0, 0, 0, 0.4)";
      const lightBlue = "rgba(38, 198, 218, 0.7)";
      const purple = "rgba(171, 71, 188, 0.7)";
      const yellow = "rgba(255, 255, 0, 0.7)";
      const red = "rgba(229, 57, 53, 0.7)";
      const teal = "rgba(29, 233, 182, 0.7)";

      this.colors = {
        graph1: transparentGraph,
        line1: lightBlue,
        graph2: transparentGraph,
        line2: purple,
        graph3: transparentGraph,
        line3: yellow,
        graph4: transparentGraph,
        line4: red,
        graph5: transparentGraph,
        line5: teal,
        graph6: transparentGraph,
        graph7: transparentGraph,
        line7: lightBlue,
        graph8: transparentGraph,
        report1: transparentGraph,
        temperature1: transparentGraph,
      };
    },
  },
};
</script>
