<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <!-- Lista permissoes -->
      <v-card
        dark
        color="transparent diff-list"
        style="margin-top:20px"
      >
        <v-card-title>
          <v-spacer />
          <v-col
            cols="4"
            md-4
            sm-4
            class="pa-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
            />
          </v-col>
        </v-card-title>
        <v-data-table
          dark
          :headers="headersPermission"
          :items="list"
          maxlength="19"
          class="diff-list-table"
          :search="filters.search"
          @click:row="handleClick"
        >
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="openEdit(item);">
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>

                <v-list-item
                  small
                  @click="openAlert(item), showDeleteDialog = true;"
                >
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <!-- Alert excluir -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false"
      @keydown.esc="showDeleteDialog = false"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-center text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-center black--text">
            Deseja realmente Executar a Ação ?
          </h3>
          <v-flex
            xs12
            class="font-weight-black"
          >
            <br>
          </v-flex>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="openDelete(); showDeleteDialog = false;"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Botão Add nova menu -->
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="openInsert(); cadastroSelecionado;"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Inserir Permissão</span>
    </v-tooltip>

    <!-- Pagina de Cadastro  -->
    <v-dialog
      width="1060"
      max-width="1060"
      :value="showCadastro"
      @input="showCadastro = false"
      @keydown.esc="showCadastro = false"
    >
      <v-card>
        <v-card-title>Cadastro de Permissões</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="rules.isValid"
          >
            <input
              v-model="permission.id_permissao"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="permission.descricao"
                  outlined
                  type="text"
                  label="Descrição"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="permission.ordem"
                  outlined
                  type="text"
                  label="Ordem"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="permission.id_pai"
                  outlined
                  label="Permissão pai"
                  placeholder=" "
                  :items="listPermissionPai"
                  item-value="id"
                  item-text="descricao"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  class="mx-auto"
                  max-width="500"
                >
                  <v-autocomplete
                    v-model="resourceSelected"
                    chips
                    small-chips
                    multiple
                    hide-details
                    label="Recursos disponiveis:"
                    :items="listResource"
                    return-object
                    item-text="recurso"
                    item-value="id_recurso"
                    persistent-hint
                  >
                    <template #selection="{ }" />

                    <template #item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.recurso }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.tipo }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template #append-outer>
                      <v-slide-x-reverse-transition
                        mode="out-in"
                      >
                        <v-btn
                          small
                          icon
                          dark
                          color="light-blue mr-5"
                          @click="openInsertResource();"
                        >
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-slide-x-reverse-transition>
                    </template>
                  </v-autocomplete>
                  <v-list
                    v-if="resourceSelected.length > 0"
                    flat
                    dense
                    class="text-left overflow-y-auto"
                    style="background-color: rgba(255, 255, 255, 0.08); max-height: 190px;"
                  >
                    <v-list-item-group>
                      <template v-for="(item, index) in resourceSelected">
                        <v-hover
                          :key="index"
                          #default="{ hover }"
                        >
                          <v-list-item class="pl-3">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.recurso }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ item.tipo }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                x-small
                                icon
                                @click="onRemoveItemResource(item)"
                              >
                                <v-icon v-if="hover">
                                  close
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-hover>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  class="mx-auto"
                  max-width="500"
                >
                  <v-autocomplete
                    v-model="moduleSelected"
                    chips
                    small-chips
                    multiple
                    hide-details
                    label="Disponivel nos Módulos:"
                    :items="listModule"
                    return-object
                    item-text="descricao"
                    item-value="id_modulo"
                  >
                    <template #selection="{ }" />

                    <template #item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.descricao }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-list
                    v-if="moduleSelected.length > 0"
                    flat
                    dense
                    class="text-left overflow-y-auto"
                    style="background-color: rgba(255, 255, 255, 0.08); max-height: 190px;"
                  >
                    <v-list-item-group>
                      <template v-for="(item, index) in moduleSelected">
                        <v-hover
                          :key="index"
                          #default="{ hover }"
                        >
                          <v-list-item class="pl-3">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.descricao }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                x-small
                                icon
                                @click="onRemoveItemModule(item)"
                              >
                                <v-icon v-if="hover">
                                  close
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-hover>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  class="mx-auto"
                  max-width="500"
                >
                  <v-autocomplete
                    v-model="profileSelected"
                    chips
                    small-chips
                    multiple
                    hide-details
                    label="Perfis:"
                    :items="listProfile"
                    return-object
                    item-text="descricao"
                    item-value="id_perfil"
                  >
                    <template #selection="{ }" />

                    <template #item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.descricao }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-list
                    v-if="profileSelected.length > 0"
                    flat
                    dense
                    class="text-left overflow-y-auto"
                    style="background-color: rgba(255, 255, 255, 0.08); max-height: 190px;"
                  >
                    <v-list-item-group>
                      <template v-for="(item, index) in profileSelected">
                        <v-hover
                          :key="index"
                          #default="{ hover }"
                        >
                          <v-list-item class="pl-3">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.descricao }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                x-small
                                icon
                                @click="onRemoveItemProfile(item)"
                              >
                                <v-icon v-if="hover">
                                  close
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-hover>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="black--text"
            text
            @click="showCadastro = false;"
          >
            cancelar
          </v-btn>
          <v-btn
            class="blue--text"
            text
            @click="
              validateForm();
              onPermissionSave();"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-resource
      v-if="showCadastroRecurso"
      :visible="showCadastroRecurso"
      :dados-recurso="newResource"
      @close="closeEditRecurso()"
      @change="loadResource()"
    />
  </div>
</template>
<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import IMask from "imask";
import _ from "lodash";
import DialogResource from "@/Domains/SettingsMilkup/Components/DialogResource.vue";

export default {
  name: "Permission",

  components: {
    DialogResource,
  },

  filters: {
    //Filtro usado em todas as mascaras da view
    Mask(value, mask) {
      if (_.isEmpty(value)) {
        return value;
      }
      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  },
    
  data() {
    return {
      filters: {
        search: "",
      },
      loading: false,

      showCadastro: false,

      showCadastroRecurso: false,
            
      newResource: { id_recurso: "", recurso: "", },

      showDeleteDialog: false,

      cadastroSelecionado: {},

      rules: {
        isValid: false,
        nome_required: [v => !!v || "Campo obrigatorio"],
        codigo_required: [v => !!v || "Campo obrigatorio"],
      },

      permission: [],
            
      objetcToDelete: "",

      headersPermission: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Permissão",
          value: "permissaopai",
        },
        {
          text: "Ordem",
          value: "ordem",
        },
        {   value: "actions",
          sortable: false,
          align: "end",
          width: 20,
        },
      ],

      list: [],

      listPermissionPai: [],

      CNPJMask: {
        mask: "00.000.0000/0000-00",
        maxlength: 19,
      },

      listModule: [],

      moduleSelected: [],

      listResource: [],

      resourceSelected: [],

      listProfile: [],
            
      profileSelected: [],
    };
  },
    
  mounted() {
    this.loadPermission();
    this.loadModules();
    this.loadResource();
    this.loadProfile();
  },

  methods: {

    openInsert() {
            
      this.showCadastro = true;
      this.permission = { id_permissao: "", descricao: "", id_pai: 0, };
      this.permissionSelected = [];
      this.moduleSelected = [];
      this.resourceSelected = [];
      this.profileSelected = [];
    },

    async openEdit(permissao) {
      this.showCadastro = true;
      this.permission = permissao;
            
      this.loadModuleSelected(permissao.id_permissao);
      this.loadResourceSelected(permissao.id_permissao);
      this.loadProfileSelected(permissao.id_permissao);
            
    },

    async loadModuleSelected(id_permissao) {
      try {
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/listaModuloPermissao`,
          this.$qs.stringify({
            id_permissao: id_permissao,
          })
        );
                
        this.moduleSelected = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadResourceSelected(id_permissao) {
      try {
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/listaPermissaoRecurso`,
          this.$qs.stringify({
            id_permissao: id_permissao,
          })
        );
                
        this.resourceSelected = data;
               
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProfileSelected(id_permissao) {
      try {
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/listaPerfilPermissao`,
          this.$qs.stringify({
            id_permissao: id_permissao,
          })
        );
        this.profileSelected = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    openAlert(permissao) {
      this.objetcToDelete = permissao.id_permissao;
    },

    openDelete() {
      this.onPermissionDelete(this.objetcToDelete);
    },

    validateForm() {
      this.$refs.form.validate();
    },

    async onPermissionSave() {
      let permissao = this.permission;
          
      try {
        this.showCadastro = false;
        this.loading = true;

        const request = {
          id_permissao: permissao.id_permissao,
          id_pai: permissao.id_pai ? permissao.id_pai : 0,
          descricao: permissao.descricao,
          ordem: permissao.ordem,
          modulos: JSON.stringify(this.moduleSelected),
          recursos: JSON.stringify(this.resourceSelected),
          perfis: JSON.stringify(this.profileSelected),
        };
            
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/salvaPermissao`,
          this.$qs.stringify({
            ...request,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }
            
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar !", "Atenção");
      } finally {
        this.$snotify.success("Permissão salva!", "Sucesso");
        await this.loadPermission();
        this.loading = false;
      }
    },

    async onPermissionDelete(toDelete) {
      try {

        const { data } = await this.$axios.post(
          `/configuracaoMilkup/deletePermissao`,
          this.$qs.stringify({
            id_permissao: toDelete,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw "PHP Error";
        }

        return this.loadPermission();

      } catch (error) {
        console.log(error);
      }
    },

    async loadPermission() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaPermissao`);
                
        this.list = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
        this.loadPermissionPai();
      }
    },
    async loadPermissionPai() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaPermissaoPai`);
            
        this.listPermissionPai =  data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Trata evento de clique na linha..
     */
    handleClick(item) {
      this.openEdit(item);
    },

    async loadModules() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaModulo`);
                
        this.listModule = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadResource() {

      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaRecurso`);
                
        this.listResource = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },
    async loadProfile() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaPerfil`);
                
        this.listProfile = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onRemoveItemResource(item) {
      this.resourceSelected = _.remove(this.resourceSelected, (data) => {
        return data.id_recurso !== item.id_recurso;
      });
          
    },

    onRemoveItemModule(item) {
      this.moduleSelected = _.remove(this.moduleSelected, (data) => {
        return data.id_modulo !== item.id_modulo;
      });
          
    },

    onRemoveItemProfile(item) {
      this.profileSelected = _.remove(this.profileSelected, (data) => {
        return data.id_perfil !== item.id_perfil;
      });
          
    },

    openInsertResource() {
      this.showCadastroRecurso = true;
    },

    closeEditRecurso() {
      this.showCadastroRecurso = false;
    },
        
  },
}
</script>

