<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color, overflow: 'hidden'}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        icon-tipo_operacao
      </v-icon>
      <span class="title font-weight-light">
        Total Frete por Rota
      </span>
      <v-spacer />
      <span class="font-weight-light">
        {{ dateFormat(referenceMonth, 'MMM/YYYY') }}
      </span>
    </v-card-title>
    <v-chart
      :options="options"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: '#26c6da'
    },
    referenceMonth: {
      type: String,
      default: () => moment().subtract(1, "month").format("YYYY-MM-DD"),
    },
    height: {
      type: String,
      default: '100px',
    },
  },

  data() {
    return {
      loading: false,

      routes: [],
      average: 0
    }
  },

  computed: {
    options() {
      const label = {
        show: true,
        lineHeight: 17,
        height: 17,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatCurrency(value)}  `
      };

      return {
        color: ['rgba(38, 198, 218, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatCurrency(param.value)}</b>`)
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.routes.map(route => route.nome_rota),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
        },
        series: [
          {
            name: 'Valor Frete',
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.routes.map((route) => route.total),
            label
          }
        ]
      }
    }
  },

  watch: {
    referenceMonth() {
      this.loadReport();
    }
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {

        const { data } = await this.$axios.get(`/freight/reports/total-by-route`, { params: {
          month_reference: this.referenceMonth
        } });

        this.routes = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de Valor Frete por Rotas!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    dateFormat: (value, format) => !value ? '-' : _.upperFirst(moment(value).format(format)),
  }
}
</script>
