<template>
  <div>
    <v-dialog
      v-model="show"
      width="1060"
      fullscreen
      persistent
    >
      <v-card
        flat
        class="pa-0 "
      >
        <v-toolbar
          flat
          color="#1976D2"
          height="45px"
          class="pa-0"
        >
          <img
            src="@/Assets/logo-transparent-dark.png"
            height="30"
          >
          <v-spacer />
        </v-toolbar>
        <v-card-text class="pa-3 pb-0">
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="dataDiagnostic.produtor"
                label="Produtor"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              offset-md="7"
            >
              <masked-input-form
                v-model="dataDiagnostic.data_emissao"
                label="Data"
                :mask="dateMask"
                maxlength="10"
                :dense="true"
                :return-unmasked="false"
              />
            </v-col>
          </v-row>
          <v-stepper
            v-model="step"
          >
            <v-stepper-header>
              <v-stepper-step
                step="1"
                editable
              >
                Rebanho
              </v-stepper-step>
              <v-stepper-step
                step="2"
                editable
              >
                Reprodução
              </v-stepper-step>
              <v-stepper-step
                step="3"
                editable
              >
                Nutrição
              </v-stepper-step>
              <v-stepper-step
                step="4"
                editable
              >
                Sanidade
              </v-stepper-step>
              <v-stepper-step
                step="5"
                editable
              >
                Qualidade
              </v-stepper-step>
              <v-stepper-step
                step="6"
                editable
              >
                Infraestrutura
              </v-stepper-step>
              <v-stepper-step
                step="7"
                editable
              >
                Outros
              </v-stepper-step>
              <v-stepper-step
                step="8"
                editable
              >
                Financeiro
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                step="1"
                class="pl-3 pr-3 pb-0"
              >
                <herd-step
                  :diagnostic.sync="dataDiagnostic"
                />

                <v-row>
                  <v-col class="text-right pb-0 ">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = 2"
                    >
                      <v-icon> arrow_forward_ios</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content
                step="2"
              >
                <breeding-step
                  :diagnostic.sync="dataDiagnostic"
                />

                <v-row>
                  <v-col class="text-left pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '1'"
                    >
                      <v-icon> arrow_back_ios</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-right pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '3'"
                    >
                      <v-icon> arrow_forward_ios</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content
                step="3"
                class="pa-3"
              >
                <nutrition-step
                  v-if="step === '3'"
                  :diagnostic.sync="dataDiagnostic"
                />

                <v-row>
                  <v-col class="text-left pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '2'"
                    >
                      <v-icon> arrow_back_ios</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-right pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '4'"
                    >
                      <v-icon> arrow_forward_ios</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content
                step="4"
                class="pa-3"
              >
                <sanitation-step
                  v-if="step === '4'"
                  :diagnostic.sync="dataStepSanitition"
                />
                <v-row>
                  <v-col class="text-left pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '3'"
                    >
                      <v-icon> arrow_back_ios</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-right pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '5'"
                    >
                      <v-icon> arrow_forward_ios</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content
                step="5"
                class="pa-3"
              >
                <quality-step
                  v-if="step === '5'"
                  :diagnostic.sync="dataDiagnostic"
                />
                <v-row>
                  <v-col class="text-left pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '4'"
                    >
                      <v-icon> arrow_back_ios</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-right pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '6'"
                    >
                      <v-icon> arrow_forward_ios</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content
                step="6"
                class="pa-3"
              >
                <infrastructure-step
                  v-if="step === '6'"
                  :diagnostic.sync="dataDiagnostic"
                />
                <v-row>
                  <v-col class="text-left pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '5'"
                    >
                      <v-icon> arrow_back_ios</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-right pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '7'"
                    >
                      <v-icon> arrow_forward_ios</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content
                step="7"
                class="pa-3"
              >
                <others-step
                  v-if="step === '7'"
                  :diagnostic.sync="dataDiagnostic"
                />
                <v-row>
                  <v-col class="text-left pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '6'"
                    >
                      <v-icon> arrow_back_ios</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-right pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '8'"
                    >
                      <v-icon> arrow_forward_ios</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content
                step="8"
                class="pa-3"
              >
                <financial-step
                  v-if="step === '8'"
                  :diagnostic.sync="dataDiagnostic"
                />
                <v-row>
                  <v-col class="text-left pb-0">
                    <v-btn
                      class="black--text"
                      text
                      icon
                      @click="step = '7'"
                    >
                      <v-icon> arrow_back_ios</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-card-actions class="mx-2">
          <v-spacer />
          <v-btn
            color="red lighten-2"
            text
            large
            @click="show = false;"
          >
            cancelar
          </v-btn>
          <!-- <v-btn
            class="blue--text"
            text
            large
            @click.stop="onSave"
          >
            Salvar
          </v-btn> -->
        </v-card-actions>
      </v-card>
      <v-overlay
        :value="loadingDialogRecord"
        absolute
      >
        <v-card-text>
          Aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import IMask from "imask";
import moment from "moment";

import MaskedInputForm from "@/Domains/Producer/Management/Components/MaskedInput.vue";

//steps
import HerdStep from "@/Domains/Quality/PlanQualification/Components/Steps/HerdStep.vue";
import BreedingStep from "@/Domains/Quality/PlanQualification/Components/Steps/BreedingStep.vue";
import NutritionStep from "@/Domains/Quality/PlanQualification/Components/Steps/NutritionStep.vue";
import SanitationStep from "@/Domains/Quality/PlanQualification/Components/Steps/SanitationStep.vue";
import QualityStep from "@/Domains/Quality/PlanQualification/Components/Steps/QualityStep.vue";
import InfrastructureStep from "@/Domains/Quality/PlanQualification/Components/Steps/InfrastructureStep.vue";
import OthersStep from "@/Domains/Quality/PlanQualification/Components/Steps/OthersStep.vue";
import FinancialStep from "@/Domains/Quality/PlanQualification/Components/Steps/FinancialStep.vue";

export default {
  name: "DiagnosticRecordDialog",

  components: {
    MaskedInputForm,
    HerdStep,
    BreedingStep,
    NutritionStep,
    SanitationStep,
    QualityStep,
    InfrastructureStep,
    OthersStep,
    FinancialStep
  },

  filters: {
    /**
     * Filtro usado em todas as mascaras da view
     */
    Mask(value, mask) {
      if (_.isEmpty(value)) {
        return value;
      }

      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },

    DateFormat(value, format) {
      return moment(value).format(format);
    },
    /*strings no padrao portugues converter para float ingles
       1.000,00 para 1000.00
       */
    parseNumeric(string) {
      string = string.replace('.', '');
      string = string.replace(',', '.');
      return parseFloat(string);
    }

  },
  props: {
    visible: {
      type: Boolean,
    },
    idDiagnostic: {
      type: String,
    },
  },

  data() {
    return {

      dateMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },

      dataDiagnostic: {
        rg_identifica_animais_obj: {
          brinco: false,
          chip: false,
          nome: false,
        },
        rg_cobertura_obj: {
          data: false,
          pai: false,
          metodo: false,
        },
        rg_prenhez_obj: {
          data: false,
          positiva: false,
          metodo: false,
        },
        rg_aborto_obj: {
          data: false,
          motivo: false,
        },
        rg_partos_obj: {
          data: false,
          pai: false,
          metodo: false,
        },
        rg_secagem_obj: {
          data: false,
          produto: false,
        },
        rg_baixas_obj: {
          data: false,
          causa: false,
        },
        rg_peso_obj: {
          data: false,
        },
        rg_controle_leiteiro_obj: {
          periodo: '',
        },

      },

      step: 1,

      loadingDialogRecord: false,

      dataStepSanitition: {},

    };
  },

  computed: {
    show: {
      get() {
        if (this.visible && this.idDiagnostic) {
          this.loadDiagnostic();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
          return false;
        }
      },
    },

  },
  mounted() {

  },
  methods: {

    /**
          * carrega os recursos que usuario tem permissao para acessar
          *
          */
    loadResourcesUser() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      const tipoAdmin = recursosUsuario.find(recurso => recurso.recurso === "user-admin" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(tipoAdmin)) {
        this.resourceAdmin = false;
      } else {
        this.resourceAdmin = true;
      }

      const resourceInsertEdit = recursosUsuario.find(recurso => recurso.recurso === "user-insert-edit" && recurso.tipo == "COMPONENTE");

      if (_.isEmpty(resourceInsertEdit)) {
        this.resourceInsertEdit = false;
      } else {
        this.resourceInsertEdit = true;
      }

    },

    async onSave() {

      try {

        this.loadingDialogRecord = true;

        const request =  await this.handleSaveData();

        console.log('request', request);

        // const { data } = await this.$axios.post(
        //   `/fichaTecnicaProdutor/salva`,
        //   this.$qs.stringify({
        //     ...request,
        //   }),
        // );

        // const [ { codigo } ] = data;

        // if (!codigo) {
        //   throw 'Erro ao salvar';
        // }

        // this.$snotify.success("Dados salvos!","Sucesso");

      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar !", "Atenção");
      } finally {
        //this.show = false;

        this.loadingDialogRecord = false;

      }
    },

    async handleSaveData() {

      const request = this.dataDiagnostic;

      request.data_emissao  = request.data_emissao ?  moment(request.data_emissao).format("YYYY-MM-DD") : '';
      //monta json de dados do rebanho
      const objRebanho = {
        raca: [],
        bezerra: [],
        novilha: [],
        cria_1: [],
        cria_2: [],
        cria_3: [],
        cria_4: [],
        cria_5: [],
        mais_5: [],
        touro: [],
        financeiro: {
          bezerra: [],
          novilha: [],
          cria_1: [],
          cria_2: [],
          cria_3: [],
          cria_4: [],
          cria_5: [],
          mais_5: [],
          touro: [],
          total_bezerra: [],
          total_novilha: [],
          total_cria_1: [],
          total_cria_2: [],
          total_cria_3: [],
          total_cria_4: [],
          total_cria_5: [],
          total_mais_5: [],
          total_touro: [],
        }
      };

      this.dataDiagnostic.rebanhoRacaQuantidade.forEach((rebanho) => {
        objRebanho.raca.push(rebanho.raca);
        objRebanho.bezerra.push(rebanho.bezerra);
        objRebanho.novilha.push(rebanho.novilha);
        objRebanho.cria_1.push(rebanho.cria_1);
        objRebanho.cria_2.push(rebanho.cria_2);
        objRebanho.cria_3.push(rebanho.cria_3);
        objRebanho.cria_4.push(rebanho.cria_4);
        objRebanho.cria_5.push(rebanho.cria_5);
        objRebanho.mais_5.push(rebanho.mais_5);
        objRebanho.touro.push(rebanho.touro);

        objRebanho.financeiro.bezerra.push(rebanho.valor_medio_bezerra);
        objRebanho.financeiro.novilha.push(rebanho.valor_medio_novilha);
        objRebanho.financeiro.cria_1.push(rebanho.valor_medio_cria_1);
        objRebanho.financeiro.cria_2.push(rebanho.valor_medio_cria_2);
        objRebanho.financeiro.cria_3.push(rebanho.valor_medio_cria_3);
        objRebanho.financeiro.cria_4.push(rebanho.valor_medio_cria_4);
        objRebanho.financeiro.cria_5.push(rebanho.valor_medio_cria_5);
        objRebanho.financeiro.mais_5.push(rebanho.valor_medio_mais_5);
        objRebanho.financeiro.touro.push(rebanho.valor_medio_touro);

        objRebanho.financeiro.total_bezerra.push(rebanho.subtotal_bezerra);
        objRebanho.financeiro.total_novilha.push(rebanho.subtotal_novilha);
        objRebanho.financeiro.total_cria_1.push(rebanho.subtotal_cria_1);
        objRebanho.financeiro.total_cria_2.push(rebanho.subtotal_cria_2);
        objRebanho.financeiro.total_cria_3.push(rebanho.subtotal_cria_3);
        objRebanho.financeiro.total_cria_4.push(rebanho.subtotal_cria_4);
        objRebanho.financeiro.total_cria_5.push(rebanho.subtotal_cria_5);
        objRebanho.financeiro.total_mais_5.push(rebanho.subtotal_mais_5);
        objRebanho.financeiro.total_touro.push(rebanho.subtotal_touro);
      });
      request.rebanho = JSON.stringify(objRebanho);

      //monta json com a identificacao escolhida
      const objIdAnimal = {};
      this.dataDiagnostic.reg_idnt_animal_brinco == true ? objIdAnimal.brinco = 'on' : '';
      this.dataDiagnostic.reg_idnt_animal_chip == true   ? objIdAnimal.chip = 'on' : '';
      this.dataDiagnostic.reg_idnt_animal_nome == true   ? objIdAnimal.nome = 'on' : '';
      request.rg_identifica_animais = JSON.stringify(objIdAnimal);

      //step 2 #reproducao
      request.rg_cobertura  = JSON.stringify(this.dataDiagnostic.rg_cobertura_obj);
      request.rg_prenhez    = JSON.stringify(this.dataDiagnostic.rg_prenhez_obj);
      request.rg_aborto     = JSON.stringify(this.dataDiagnostic.rg_aborto_obj);
      request.rg_partos     = JSON.stringify(this.dataDiagnostic.rg_partos_obj);
      request.rg_secagem    = JSON.stringify(this.dataDiagnostic.rg_secagem_obj);
      request.rg_baixas     = JSON.stringify(this.dataDiagnostic.rg_baixas_obj);
      request.rg_peso       = JSON.stringify(this.dataDiagnostic.rg_peso_obj);
      request.rg_controle_leiteiro = JSON.stringify(this.dataDiagnostic.rg_controle_leiteiro_obj);

      //step 3 #nutricao
      const objConcentrado = {
        concentrado_racao: [],
        concentrado_porcentagem: [],
        concentrado_tipo_racao: [],
        concentrado_valor_medio: [],
      };
      this.dataDiagnostic.concentrado_obj.forEach((concentrado) => {
        objConcentrado.concentrado_racao.push(concentrado.concentrado_racao);
        objConcentrado.concentrado_porcentagem.push(concentrado.concentrado_porcentagem);
        objConcentrado.concentrado_tipo_racao.push(concentrado.concentrado_tipo_racao);
        objConcentrado.concentrado_valor_medio.push(concentrado.concentrado_valor_medio);
      });

      request.concentrado = JSON.stringify(objConcentrado);

      //step 4
      request.vacinacao_json =  this.dataStepSanitition.vacinacao_json;
      request.fornecedor_vacina =  this.dataStepSanitition.fornecedor_vacina;
      request.exames_json =  this.dataStepSanitition.exames_json;
      request.antibioticos_json =  this.dataStepSanitition.antibioticos_json;
      request.rg_calendario_sanitario =  this.dataStepSanitition.rg_calendario_sanitario;
      request.rg_colostragem =  this.dataStepSanitition.rg_colostragem;
      request.rg_cura_umbigo =  this.dataStepSanitition.rg_cura_umbigo;
      request.rg_sob_tratamento =  this.dataStepSanitition.rg_sob_tratamento;
      request.rg_animais_doentes =  this.dataStepSanitition.rg_animais_doentes;
      request.rg_procedimentos_vermifugacao =  this.dataStepSanitition.rg_procedimentos_vermifugacao;

      return request;
    },

    async loadDiagnostic() {

      try {

        const request = {
          id: this.idDiagnostic,
        };

        const { data } = await this.$axios.post(
          `/fichaTecnicaProdutor/fichaDetalhe`,
          this.$qs.stringify({
            ...request,
          }),
        );

        if (!data) {
          throw 'Erro ao buscar dados ';
        }

        await this.handleData(data[0]);

      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro  !", "Atenção");
      }
    },

    async handleData(data) {
      this.dataDiagnostic = data ;

      //step 1
      this.dataDiagnostic.data_emissao = this.dataDiagnostic.data_emissao ?  moment(this.dataDiagnostic.data_emissao).format("DD/MM/YYYY") : '';

      this.dataDiagnostic.rebanho = this.dataDiagnostic.rebanho ? JSON.parse(this.dataDiagnostic.rebanho) : [];

      let rebanhoRacaQuantidade = [];

      this.dataDiagnostic.rebanho.raca.forEach((num, index) => {
        rebanhoRacaQuantidade.push({
          id: index,
          raca: this.dataDiagnostic.rebanho.raca[index],
          bezerra: this.dataDiagnostic.rebanho.bezerra[index] ? parseInt(this.dataDiagnostic.rebanho.bezerra[index]) : 0,
          novilha: this.dataDiagnostic.rebanho.novilha[index] ? parseInt(this.dataDiagnostic.rebanho.novilha[index]) : 0,
          cria_1: this.dataDiagnostic.rebanho.cria_1[index] ? parseInt(this.dataDiagnostic.rebanho.cria_1[index]) : 0,
          cria_2: this.dataDiagnostic.rebanho.cria_2[index] ? parseInt(this.dataDiagnostic.rebanho.cria_2[index]) : 0,
          cria_3: this.dataDiagnostic.rebanho.cria_3[index] ? parseInt(this.dataDiagnostic.rebanho.cria_3[index]) : 0,
          cria_4: this.dataDiagnostic.rebanho.cria_4[index] ? parseInt(this.dataDiagnostic.rebanho.cria_4[index]) : 0,
          cria_5: this.dataDiagnostic.rebanho.cria_5[index] ? parseInt(this.dataDiagnostic.rebanho.cria_5[index]) : 0,
          mais_5: this.dataDiagnostic.rebanho.mais_5[index] ? parseInt(this.dataDiagnostic.rebanho.mais_5[index]) : 0,
          touro: this.dataDiagnostic.rebanho.touro[index] ? parseInt(this.dataDiagnostic.rebanho.touro[index]) : 0,
          total: this.dataDiagnostic.rebanho.total[index] ? parseInt(this.dataDiagnostic.rebanho.total[index]) : 0,
          valor_medio_bezerra: this.dataDiagnostic.rebanho.financeiro.bezerra[index] ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.bezerra[index]) : 0,
          valor_medio_novilha: this.dataDiagnostic.rebanho.financeiro.novilha[index] ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.novilha[index]) : 0,
          valor_medio_cria_1: this.dataDiagnostic.rebanho.financeiro.cria_1[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.cria_1[index]) : 0,
          valor_medio_cria_2: this.dataDiagnostic.rebanho.financeiro.cria_2[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.cria_2[index]) : 0,
          valor_medio_cria_3: this.dataDiagnostic.rebanho.financeiro.cria_3[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.cria_3[index]) : 0,
          valor_medio_cria_4: this.dataDiagnostic.rebanho.financeiro.cria_4[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.cria_4[index]) : 0,
          valor_medio_cria_5: this.dataDiagnostic.rebanho.financeiro.cria_5[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.cria_5[index]) : 0,
          valor_medio_mais_5: this.dataDiagnostic.rebanho.financeiro.mais_5[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.mais_5[index]) : 0,
          valor_medio_touro: this.dataDiagnostic.rebanho.financeiro.touro[index]   ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.touro[index])  : 0,
          subtotal_bezerra: this.dataDiagnostic.rebanho.financeiro.total_bezerra[index] ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_bezerra[index]) : 0,
          subtotal_novilha: this.dataDiagnostic.rebanho.financeiro.total_novilha[index] ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_novilha[index]) : 0,
          subtotal_cria_1: this.dataDiagnostic.rebanho.financeiro.total_cria_1[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_cria_1[index]) : 0,
          subtotal_cria_2: this.dataDiagnostic.rebanho.financeiro.total_cria_2[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_cria_2[index]) : 0,
          subtotal_cria_3: this.dataDiagnostic.rebanho.financeiro.total_cria_3[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_cria_3[index]) : 0,
          subtotal_cria_4: this.dataDiagnostic.rebanho.financeiro.total_cria_4[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_cria_4[index]) : 0,
          subtotal_cria_5: this.dataDiagnostic.rebanho.financeiro.total_cria_5[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_cria_5[index]) : 0,
          subtotal_mais_5: this.dataDiagnostic.rebanho.financeiro.total_mais_5[index]  ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_mais_5[index]) : 0,
          subtotal_touro: this.dataDiagnostic.rebanho.financeiro.total_touro[index]   ? this.$options.filters.parseNumeric(this.dataDiagnostic.rebanho.financeiro.total_touro[index])  : 0,
        });
      });
      this.dataDiagnostic.rebanhoRacaQuantidade = rebanhoRacaQuantidade;

      this.dataDiagnostic.rg_identifica_animais = this.dataDiagnostic.rg_identifica_animais ? JSON.parse(this.dataDiagnostic.rg_identifica_animais) : [];
      this.dataDiagnostic.rg_identifica_animais.brinco ? this.dataDiagnostic.reg_idnt_animal_brinco = true : false ;
      this.dataDiagnostic.rg_identifica_animais.chip ? this.dataDiagnostic.reg_idnt_animal_chip = true :  false ;
      this.dataDiagnostic.rg_identifica_animais.nome ? this.dataDiagnostic.reg_idnt_animal_nome = true : false ;

      //step 2
      this.dataDiagnostic.rg_cobertura_obj  = this.dataDiagnostic.rg_cobertura ? JSON.parse(this.dataDiagnostic.rg_cobertura) : {};
      this.dataDiagnostic.rg_prenhez_obj    = this.dataDiagnostic.rg_prenhez ? JSON.parse(this.dataDiagnostic.rg_prenhez) : {};
      this.dataDiagnostic.rg_aborto_obj     = this.dataDiagnostic.rg_aborto ? JSON.parse(this.dataDiagnostic.rg_aborto) : {};
      this.dataDiagnostic.rg_partos_obj     = this.dataDiagnostic.rg_partos ? JSON.parse(this.dataDiagnostic.rg_partos) : {};
      this.dataDiagnostic.rg_secagem_obj    = this.dataDiagnostic.rg_secagem ? JSON.parse(this.dataDiagnostic.rg_secagem) : {};
      this.dataDiagnostic.rg_baixas_obj     = this.dataDiagnostic.rg_baixas ? JSON.parse(this.dataDiagnostic.rg_baixas) : {};
      this.dataDiagnostic.rg_peso_obj       = this.dataDiagnostic.rg_peso ? JSON.parse(this.dataDiagnostic.rg_peso) : {};
      this.dataDiagnostic.rg_controle_leiteiro_obj     = this.dataDiagnostic.rg_controle_leiteiro ? JSON.parse(this.dataDiagnostic.rg_controle_leiteiro) : {};

      //step 3

      //*****concentrado
      this.dataDiagnostic.concentrado_obj = [];
      let concentrado_lactacao_obj = [];
      let concentrado_outros_obj = [];
      let concentrado_lactacao_adicionais = [];
      let concentrado_outros_adicionais = [];
      let objetoConcentrado  = this.dataDiagnostic.concentrado ? JSON.parse(this.dataDiagnostic.concentrado) : {};

      if (objetoConcentrado.concentrado_racao) {
        objetoConcentrado.concentrado_racao.forEach((num, index) => {
          this.dataDiagnostic.concentrado_obj.push({
            id: index,
            concentrado_racao: objetoConcentrado.concentrado_racao[index],
            concentrado_tipo_racao: objetoConcentrado.concentrado_tipo_racao[index],
            concentrado_porcentagem: objetoConcentrado.concentrado_porcentagem[index],
            concentrado_valor_medio: parseFloat(this.$options.filters.parseNumeric(objetoConcentrado.concentrado_valor_medio[index])),
          });

          concentrado_lactacao_obj.push({
            id: index,
            concentrado_descricao: objetoConcentrado.concentrado_racao[index] + " " + objetoConcentrado.concentrado_porcentagem[index] + "% " + objetoConcentrado.concentrado_tipo_racao[index],
            concentrado_alta_producao: objetoConcentrado.concentrado_alta_producao[index],
            concentrado_media_producao: objetoConcentrado.concentrado_media_producao[index],
            concentrado_baixa_producao: objetoConcentrado.concentrado_baixa_producao[index],
          });
          concentrado_outros_obj.push({
            id: index,
            concentrado_descricao: objetoConcentrado.concentrado_racao[index] + " " + objetoConcentrado.concentrado_porcentagem[index] + "% " + objetoConcentrado.concentrado_tipo_racao[index],
            concentrado_pre_parto: objetoConcentrado.concentrado_pre_parto[index],
            concentrado_vacas_secas: objetoConcentrado.concentrado_vacas_secas[index],
            concentrado_novilhas: objetoConcentrado.concentrado_novilhas[index],
            concentrado_bezerras: objetoConcentrado.concentrado_bezerras[index],
            concentrado_bezerras_aleitamento: objetoConcentrado.concentrado_bezerras_aleitamento[index],
          });

        });
      }

      if (objetoConcentrado.concentrado_lactacao) {
        _.forEach(objetoConcentrado.concentrado_lactacao, function(value, key) {

          concentrado_lactacao_adicionais.push({
            descricao: value.nome,
            campo: `concentrado_item_adicional${key}`
          });

          _.forEach(value.item, function(v, k) {
            let nome_campo = "concentrado_item_adicional".concat(key);
            _.set(concentrado_lactacao_obj, [k, nome_campo], v);
          });
        });
      }

      if (objetoConcentrado.concentrado_outro) {
        _.forEach(objetoConcentrado.concentrado_outro, function(value, key) {

          concentrado_outros_adicionais.push({
            descricao: value.nome,
            campo: `concentrado_item_adicional${key}`
          });

          _.forEach(value.item, function(v, k) {
            let nome_campo = "concentrado_item_adicional".concat(key);
            _.set(concentrado_outros_obj, [k, nome_campo], v);
          });
        });
      }

      this.dataDiagnostic.concentrado_lactacao_adicionais = concentrado_lactacao_adicionais;
      this.dataDiagnostic.concentrado_outros_adicionais = concentrado_outros_adicionais;
      this.dataDiagnostic.concentrado_lactacao_obj = concentrado_lactacao_obj;
      this.dataDiagnostic.concentrado_outros_obj = concentrado_outros_obj;

      //*****volumoso
      let objetoVolumoso  = this.dataDiagnostic.volumoso ? JSON.parse(this.dataDiagnostic.volumoso) : {};
      let volumoso_lactacao_obj = [];
      let volumoso_outros_obj = [];
      let volumoso_lactacao_adicionais = [];
      let volumoso_outros_adicionais = [];
      this.dataDiagnostic.volumoso_obj = [];

      if (objetoVolumoso.volumoso) {
        objetoVolumoso.volumoso.forEach((num, index) => {
          this.dataDiagnostic.volumoso_obj.push({
            id: index,
            volumoso: objetoVolumoso.volumoso[index],
            volumoso_valor_medio: objetoVolumoso.volumoso_valor_medio[index],
          });
          volumoso_lactacao_obj.push({
            id: index,
            volumoso_descricao: objetoVolumoso.volumoso[index],
            volumoso_alta_producao: objetoVolumoso.volumoso_alta_producao[index],
            volumoso_media_producao: objetoVolumoso.volumoso_media_producao[index],
            volumoso_baixa_producao: objetoVolumoso.volumoso_baixa_producao[index],
          });
          volumoso_outros_obj.push({
            id: index,
            volumoso_descricao: objetoVolumoso.volumoso[index],
            volumoso_pre_parto: objetoVolumoso.volumoso_pre_parto[index],
            volumoso_vacas_secas: objetoVolumoso.volumoso_vacas_secas[index],
            volumoso_novilhas: objetoVolumoso.volumoso_novilhas[index],
            volumoso_bezerras: objetoVolumoso.volumoso_bezerras[index],
            volumoso_bezerras_aleitamento: objetoVolumoso.volumoso_bezerras_aleitamento[index],
          });
        });
      }
      if (objetoVolumoso.volumoso_lactacao) {
        _.forEach(objetoVolumoso.volumoso_lactacao, function(value, key) {

          volumoso_lactacao_adicionais.push({
            descricao: value.nome,
            campo: `volumoso_item_adicional${key}`
          });

          _.forEach(value.item, function(v, k) {
            let nome_campo = "volumoso_item_adicional".concat(key);
            _.set(volumoso_lactacao_obj, [k, nome_campo], v);
          });
        });
      }

      if (objetoVolumoso.volumoso_outro) {
        _.forEach(objetoVolumoso.volumoso_outro, function(value, key) {

          volumoso_outros_adicionais.push({
            descricao: value.nome,
            campo: `volumoso_item_adicional${key}`
          });

          _.forEach(value.item, function(v, k) {
            let nome_campo = "volumoso_item_adicional".concat(key);
            _.set(volumoso_outros_obj, [k, nome_campo], v);
          });
        });
      }
      this.dataDiagnostic.volumoso_lactacao_adicionais = volumoso_lactacao_adicionais;
      this.dataDiagnostic.volumoso_outros_adicionais = volumoso_outros_adicionais;
      this.dataDiagnostic.volumoso_lactacao_obj = volumoso_lactacao_obj;
      this.dataDiagnostic.volumoso_outros_obj   = volumoso_outros_obj;

      //*****complemento
      let objetoComplemento  = this.dataDiagnostic.complemento ? JSON.parse(this.dataDiagnostic.complemento) : {};
      let complemento_lactacao_obj = [];
      let complemento_outros_obj = [];
      let complemento_lactacao_adicionais = [];
      let complemento_outros_adicionais = [];
      this.dataDiagnostic.complemento_obj = [];

      if (objetoComplemento.complemento) {
        objetoComplemento.complemento.forEach((num, index) => {
          this.dataDiagnostic.complemento_obj.push({
            id: index,
            complemento: objetoComplemento.complemento[index],
            complemento_valor_medio: objetoComplemento.complemento_valor_medio[index],
          });
          complemento_lactacao_obj.push({
            id: index,
            complemento_descricao: objetoComplemento.complemento[index],
            complemento_alta_producao: objetoComplemento.complemento_alta_producao[index],
            complemento_media_producao: objetoComplemento.complemento_media_producao[index],
            complemento_baixa_producao: objetoComplemento.complemento_baixa_producao[index],
          });
          complemento_outros_obj.push({
            id: index,
            complemento_descricao: objetoComplemento.complemento[index],
            complemento_pre_parto: objetoComplemento.complemento_pre_parto[index],
            complemento_vacas_secas: objetoComplemento.complemento_vacas_secas[index],
            complemento_novilhas: objetoComplemento.complemento_novilhas[index],
            complemento_bezerras: objetoComplemento.complemento_bezerras[index],
            complemento_bezerras_aleitamento: objetoComplemento.complemento_bezerras_aleitamento[index],
          });
        });
      }

      if (objetoComplemento.complemento_lactacao) {
        _.forEach(objetoComplemento.complemento_lactacao, function(value, key) {

          complemento_lactacao_adicionais.push({
            descricao: value.nome,
            campo: `complemento_item_adicional${key}`
          });

          _.forEach(value.item, function(v, k) {
            let nome_campo = "complemento_item_adicional".concat(key);
            _.set(complemento_lactacao_obj, [k, nome_campo], v);
          });
        });
      }

      if (objetoComplemento.complemento_outro) {
        _.forEach(objetoComplemento.complemento_outro, function(value, key) {

          complemento_outros_adicionais.push({
            descricao: value.nome,
            campo: `complemento_item_adicional${key}`
          });

          _.forEach(value.item, function(v, k) {
            let nome_campo = "complemento_item_adicional".concat(key);
            _.set(complemento_outros_obj, [k, nome_campo], v);
          });
        });
      }

      this.dataDiagnostic.complemento_lactacao_adicionais = complemento_lactacao_adicionais;
      this.dataDiagnostic.complemento_outros_adicionais = complemento_outros_adicionais;
      this.dataDiagnostic.complemento_lactacao_obj = complemento_lactacao_obj;
      this.dataDiagnostic.complemento_outros_obj   = complemento_outros_obj;

      //step 4
      this.dataStepSanitition.vacinacao_json    = this.dataDiagnostic.vacinacao_json;
      this.dataStepSanitition.fornecedor_vacina = this.dataDiagnostic.fornecedor_vacina;
      this.dataStepSanitition.exames_json       = this.dataDiagnostic.exames_json;
      this.dataStepSanitition.antibioticos_json = this.dataDiagnostic.antibioticos_json;
      this.dataStepSanitition.rg_calendario_sanitario = this.dataDiagnostic.rg_calendario_sanitario;
      this.dataStepSanitition.rg_colostragem = this.dataDiagnostic.rg_colostragem;
      this.dataStepSanitition.rg_cura_umbigo = this.dataDiagnostic.rg_cura_umbigo;
      this.dataStepSanitition.rg_sob_tratamento = this.dataDiagnostic.rg_sob_tratamento;
      this.dataStepSanitition.rg_animais_doentes = this.dataDiagnostic.rg_animais_doentes;
      this.dataStepSanitition.rg_procedimentos_vermifugacao = this.dataDiagnostic.rg_procedimentos_vermifugacao;
    },
  },
}
</script>
