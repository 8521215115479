<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Ações de Qualidade
    </v-card-title>
    <v-card-text>
      <v-row class="justify-center">
        <v-col
          v-if="items.length > 0"
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="itemSelected"
            :items="items"
            item-value="value"
            item-text="text"
            dark
            hide-selected
            hide-details
            label="Inserir item"
            prepend-inner-icon="search"
            filled
            @change="addItem"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="goals"
      hide-default-footer
      disable-sort
    >
      <template #[`item.indicator`]="{ item }">
        <v-select
          v-model="item.indicator"
          :items="entries"
          dense
          outlined
          hide-details
          disabled
        />
      </template>
      <template #[`item.media`]="{ item }">
        <v-text-field
          v-if="item.action==='ACIMA' || item.action==='ABAIXO'"
          v-model.number="item.media"
          placeholder="0"
          type="number"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          @keypress="disableDotAndComma"
        />
      </template>
      <template #[`item.action`]="{ item }">
        <v-select
          v-model="item.action"
          :items="options"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.percent`]="{ item }">
        <v-text-field
          v-model.number="item.percent"
          placeholder="0"
          type="number"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          @keypress="disableDotAndComma"
        />
      </template>
      <template #[`item.type`]="{ item }">
        <v-select
          v-model="item.type"
          :items="tipos"
          item-value="value"
          item-text="text"
          dense
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          color="white"
        >
          <v-icon
            @click="removeItem(item)"
          >
            delete_outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

export default {

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },

  props: {
    value: Array
  },

  data() {
    return {
      headers: [
        { text: "Indicador", value: "indicator" },
        { text: "Ação", value: "action", width: 350 },
        { text: "Média", value: "media", width: 100 },
        { text: "valor", value: "percent", width: 100 },
        { text: 'tipo', value: "type", width: 30  },
        { text: "", value: "actions", width: 30 },
      ],

      tipos: [
        { text: '%', value: 'PERCENT' },
        { text: 'UN', value: 'UN' }
      ],
      options: [
        { text: 'Reduzir média', value: 'REDUZIR' },
        { text: 'Aumentar média', value: 'AUMENTAR' },
        { text: 'Adequar Produtores com indicador acima de:', value: 'ACIMA' },
        { text: 'Adequar Produtores com indicador abaixo de:', value: 'ABAIXO' }
      ],

      entries: [
        { text: 'CCS Geo Mensal', value: 'CCS' },
        { text: 'CPP Geo Mensal', value: 'CPP' },
        // { text: 'CCS Geo Trimestral', value: 'CCS_TRIMESTRAL' },
        // { text: 'CPP Geo Trimestral', value: 'CPP_TRIMESTRAL' },
        { text: 'Gordura', value: 'gordura' },
        { text: 'Proteína', value: 'proteina' },
        { text: 'Caseína', value: 'caseina' },
        { text: 'Solídos Totais', value: 'solidos_totais' },
        { text: 'Volume', value: 'volume' },
      ],
      itemSelected: '',
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    items() {
      return this.entries;
    },
  },

  methods: {

    addItem(indicator) {
      this.goals.push({ indicator, action: 'AUMENTAR', media_produtor: 'MEDIA' })
      this.$nextTick(() => {
        this.itemSelected = "";
      });
    },

    removeItem(item) {
      const idx = this.goals.findIndex(o => _.concat(o.indicator, o.media) === _.concat(item.indicator, item.media));
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
