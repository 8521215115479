<template>
  <v-row>
    <v-col
      v-for="(graph, idx) in graphs"
      :key="idx"
      cols="12"
    >
      <v-card
        dark
        :style="{background: 'rgba(0, 0, 0, 0.5)'}"
        :loading="loading"
      >
        <v-card-text>
          <v-chart
            :options="graph"
            :style="{width: '100%', height: `350px !important`}"
            autoresize
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    questionnaireId: String,
    answer: String,
  },

  data() {
    return {
      loading: false,
      numQuestions: 0,
      ranges: [],
      report: {},
    }
  },

  computed: {
    graphs() {
      if (_.isEmpty(this.report)) {
        return [];
      }
      const categories = Object.keys(this.report).map(mes => this.formatMonth(mes));
      const data = Object.values(this.report);
      return this.ranges.map(({ faixa, meta_visitas }, idx) => {
        return {
          color: ['rgba(229, 57, 53, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(38, 198, 218, 0.7)'],
          title: {
            text: `${this.answer} (Faixa: ${idx === 0 ? 0 : this.ranges[idx - 1].faixa}% a ${faixa}%)`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: { backgroundColor: '#6a7985' }
            },
            textStyle: {
              fontSize: 12,
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            top: '20%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: categories,
            axisLabel: {
              color: '#fff'
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff'
            }
          },
          series: [
            {
              name: 'Produtores',
              type: 'bar',
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: '  {c}  ',
              },
              data: data.map(o => o[faixa].length),
            },
            {
              name: 'Visitados',
              type: 'line',
              lineStyle: { width: 2 },
              smooth: this.smooth,
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'top',
                distance: 1,
                formatter: o => `  ${o.value} - ${(+((o.value / data[o.dataIndex][faixa].length * 100)).toFixed(2))}%  '`,
              },
              data: data.map(o => o[faixa].filter(o => o.visitado).length),
            },
            {
              name: 'Meta de Visitas',
              type: 'line',
              lineStyle: { width: 2 },
              smooth: this.smooth,
              emphasis: {
                focus: 'series'
              },
              label: {
                normal: {
                  show: true,
                  lineHeight: 20,
                  height: 20,
                  backgroundColor: '#6a7985',
                  color: '#fff',
                  borderRadius: 5,
                  position: 'top',
                  distance: 1,
                  formatter: o => `  ${+((o.value / data[o.dataIndex][faixa].length * 100) || 0).toFixed(2)}%  `,
                }
              },
              data: data.map(o => parseFloat(meta_visitas || 0) * o[faixa].length / 100),
            }
          ]
        }
        
      })
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        let { data } = await this.$axios.post(
          `/formularioCustomizado/relatorioVisitas`,
          { id_formulario: this.questionnaireId, resposta: this.answer }
        );

        if (!_.isObject(data)) {
          throw new Error(data);
        }

        if (!data.codigo) {
          throw new Error(data.mensagem);
        }

        this.numQuestions = data.num_questoes;
        this.ranges = data.faixas;
        this.report = data.relatorio;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de visitas", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    
    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
  }
}
</script>
