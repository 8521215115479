<template>
  <div>
    <herd-record-table
      :herd="localData"
    />
    
    <v-row>
      <v-col
        md="12"
      >
        <v-divider class="mb-3" />
        <span
          style="
          color:black !important;
          font-size: 1.25rem;
          font-weight: 500;
          letter-spacing: 0.0125em;
          line-height: 2rem;"
        >
          Registros
        </span>
      </v-col>
      <v-col md="4">
        <v-select
          v-model="localData.cuidados_comprar_novos_animais"
          :items="care_buying_animals"
          label="Quais os cuidados para comprar novos animais?"
          hide-details
          dense
        />
      </v-col>
      <v-col
        v-if="localData.cuidados_comprar_novos_animais == 'OUTRO'"
        md="4"
      >
        <v-text-field
          v-model="localData.cuidados_comprar_novos_animais_descricao"
          label="Descreva o cuidade para comprar novos animais"
          dense
        />
      </v-col>
      <v-col md="4">
        <masked-input
          v-model="localData.vacas_lactacao"
          label="Vacas em lactação"
          placeholder="0"
          dense
          :mask="FloatNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col md="4">
        <masked-input
          v-model="localData.vacas_secas"
          label="Vacas Secas"
          placeholder="0"
          dense
          :mask="FloatNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col md="4">
        <masked-input
          v-model="localData.terneiras"
          label="Terneiras Até 12M"
          placeholder="0"
          dense
          :mask="FloatNumberMask"
          currency
          return-unmasked
        />
      </v-col>
      <v-col md="4">
        <v-select
          v-model="localData.possui_ficha_individual_para_cada_animal"
          :items="['SIM', 'NÃO']"
          label="O produtor possui ficha individual com os dados de cada animal?"
          hide-details
          dense
        />
      </v-col>
      <v-col md="4">
        <v-select
          v-model="localData.faz_separacao_dos_lotes_por_animal"
          :items="['SIM', 'NÃO']"
          label="O produtor faz separação dos animais por lote de produção?"
          hide-details
          dense
        />
      </v-col>
      <v-col md="6">
        <v-select
          v-model="localData.procedimento_adicional_no_inverno"
          :items="additional_procedure_in_the_winter"
          label="O produtor faz algum procedimento adicional para evitar a queda na produção de leite nos meses de inverno?"
          hide-details
          dense
        />
      </v-col>
      <v-col
        v-if="localData.procedimento_adicional_no_inverno == 'OUTRO'"
        md="4"
      >
        <v-text-field
          v-model="localData.procedimento_adicional_no_inverno_descricao"
          label="Descreva outro procedimento no inverno"
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <label>Como Identifica os Animais</label>
        <v-row>
          <v-col md="4">
            <v-checkbox
              v-model="localData.reg_idnt_animal_brinco"
              label="Brinco"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col md="4">
            <v-checkbox
              v-model="localData.reg_idnt_animal_chip"
              label="Chip"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col md="4">
            <v-checkbox
              v-model="localData.reg_idnt_animal_nome"
              label="Nome"
              color="primary"
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.herd-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(226, 225, 225, 0.5);
    }

    tbody {
      .subtotal,
      tr.subtotal:hover {
        background: rgba(226, 225, 225, 0.2);
      }
      .rodape {
        background: rgba(226, 225, 225, 0.5);
      }
      tr.rodape:hover {
        background: rgba(226, 225, 225, 0.5) !important;
      }

      tr:hover {
        background: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}
</style>

<script>
//import _ from "lodash";
// import IMask from "imask";
// import moment from "moment";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import HerdRecordTable from "@/Domains/Quality/PlanQualification/Components/HerdRecordTable.vue";
//import herdTotalizers from "@/Domains/Quality/PlanQualification/Components/herdTotalizers.vue";

export default {
  name: "HerdRecord",
  components: {
    MaskedInput,
    HerdRecordTable,
    //herdTotalizers,
  },

  filters: {
    formatCurrency: value => new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(value),
  },
  props: {
    diagnostic: {
      require: true,
    },
  },

  data() {
    return {
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },
      valor_total_bezerra: 0,
      valor_total_novilha: 0,
      valor_total_cria_1: 0,
      valor_total_cria_2: 0,
      valor_total_cria_3: 0,
      valor_total_cria_4: 0,
      valor_total_cria_5: 0,
      valor_total_mais_5: 0,
      valor_total_touro: 0,
      valor_total_animais: 0,

      headers: [
        { text: "Raça", align: "start", value: "raca" },
        { text: "Bezerra", value: "bezerra" },
        { text: "Novilha", value: "novilha" },
        { text: "1ª cria", value: "cria_1" },
        { text: "1ª cria", value: "cria_2" },
        { text: "3ª cria", value: "cria_3" },
        { text: "4ª cria", value: "cria_4" },
        { text: "5ª cria", value: "cria_5" },
        { text: "+ 5ª cria", value: "mais_5" },
        { text: "Touro", value: "touro" },
        { text: "Total", value: "total" },
        { value: "actions", sortable: false, align: "end", width: 20 },
      ],

      care_buying_animals: [
        "",
        "AVALIA O PREÇO",
        "SÓ COMPRA DE VENDEDOR CONHECIDO",
        "MANDA FAZER EXAME COMPROVANDO SANIDADE",
        "OUTRO",
      ],

      additional_procedure_in_the_winter: [
        "",
        "NÃO",
        "CONCENTRA OS NASCIMENTOS NO INICIO DO INVERNO",
        "FORNECE SILAGEM DE MILHO",
        "FORNECE CANA OU CAPIM PICADO",
        "FORNECE MAIS RAÇÃO",
        "UTILIZA RESERVA DE PASTO",
        "OUTRO",
      ],

      objetcToDelete: "",

      showDeleteDialog: false,
    };
  },

  computed: {
    localData: {
      get() {
        return this.diagnostic;
      },
      set(newValue) {
        return this.$emit("update:dataDiagnostic", {
          ...newValue,
        });
      },
    },
  },
  methods: {

    openAlert(item) {
      this.objetcToDelete = item.id;
    },
   
  },
};
</script>
