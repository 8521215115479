<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      max-width="750px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Planejamento de Produção
          </span>
        </v-card-title>

        <v-card-text class="px-0">
          <v-stepper
            v-model="step"
            alt-labels
            class="elevation-0"
          >
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :complete="step > 1"
              >
                Dados principais
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                step="2"
                :complete="step > 2"
              >
                Período
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <v-date-range-picker
                        v-model="form.period"
                        label="Período"
                        :filled="false"
                        :hide-details="false"
                        :ranges="dateRangeFilters"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0 d-flex"
                    >
                      <v-text-field
                        v-model="form.tinaCapacity"
                        label="Capacidade das Tinas"
                        prefix="(L)"
                        type="number"
                        placeholder=" "
                        :rules="[rules.required]"
                        @keypress="disableDot"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      class="pb-0 pt-1"
                    >
                      <span class="text-h6 d-block">Queijo</span>

                      <v-divider />
                    </v-col>

                    <v-col
                      cols="6"
                      md="3"
                      class="pb-0 d-flex"
                    >
                      <v-text-field
                        v-model="form.cheese.density"
                        label="Densidade Queijo"
                        type="number"
                        placeholder=" "
                        @keypress="disableDot"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      class="pb-0 pt-1"
                    >
                      <span class="text-h6 d-block">Creme à Granel</span>

                      <v-divider />
                    </v-col>

                    <v-col
                      cols="6"
                      md="3"
                      class="pb-0 d-flex"
                    >
                      <v-text-field
                        v-model="form.bulkCream.milkStandardization"
                        label="Padronização Leite"
                        type="number"
                        placeholder=" "
                        prefix="%"
                        @keypress="disableDot"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      md="3"
                      class="pb-0 d-flex"
                    >
                      <v-text-field
                        v-model="form.bulkCream.serumPerPan"
                        label="Soro / Tina"
                        type="number"
                        placeholder=" "
                        prefix="%"
                        @keypress="disableDot"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      md="3"
                      class="pb-0 d-flex"
                    >
                      <v-text-field
                        v-model="form.bulkCream.wheyFat"
                        label="Gordura Soro"
                        type="number"
                        placeholder=" "
                        prefix="%"
                        @keypress="disableDot"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      md="3"
                      class="pb-0 d-flex"
                    >
                      <v-text-field
                        v-model="form.bulkCream.desiredFat"
                        label="Gordura Desejada"
                        type="number"
                        placeholder=" "
                        prefix="%"
                        @keypress="disableDot"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form
                  ref="form2"
                  lazy-validation
                >
                  <v-row
                    style="max-height: calc(100vh - 445px); overflow: auto;"
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-row
                        v-for="item in form.plannings"
                        :key="item.date"
                        no-gutters
                      >
                        <v-col
                          cols="3"
                          class="pb-0"
                        >
                          <v-text-field
                            :value="formatDate(item.date, 'DD/MM/YYYY')"
                            :label="formatDate(item.date, 'dddd')"
                            disabled
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          class="pb-0"
                        >
                          <v-text-field
                            v-model="item.tinas"
                            label="Tinas"
                            type="number"
                            placeholder=" "
                            @keypress="disableDotAndComma"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          class="pb-0"
                        >
                          <v-text-field
                            v-model="item.tinaCapacity"
                            label="Capacidade da Tina"
                            type="number"
                            placeholder=" "
                            prepend-inner-icon="opacity"
                            :rules="[rules.required]"
                            @keypress="disableDot"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          class="pb-0"
                        >
                          <v-text-field
                            :value="!!item.tinaCapacity && !!item.tinas ? formatNumber(parseFloat(item.tinaCapacity) * parseFloat(item.tinas)) : null"
                            label="Consumo"
                            prepend-inner-icon="opacity"
                            placeholder=" "
                            disabled
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <small class="mx-4">* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="step == 1"
            color="grey darken-1"
            outlined
            @click="() => show = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-else
            outlined
            @click="() => step--"
          >
            <v-icon
              left
              dark
            >
              chevron_left
            </v-icon>
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="showDeleteBtn"
            color="red darken-1"
            outlined
            @click="removePlanning"
          >
            Excluir
          </v-btn>
          <v-btn
            v-if="step == 1"
            color="green"
            outlined
            @click="nextStep"
          >
            Continuar
            <v-icon
              right
              dark
            >
              chevron_right
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            outlined
            @click="saveNew"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        v-model="saving"
        absolute
      >
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-overlay>

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';

import _ from 'lodash';
import moment from 'moment';

const defaultCheese = {
  density: 9.85
};

const defaultBulkCream = {
  milkStandardization: 1.3,
  serumPerPan: 85,
  wheyFat: 0.12,
  desiredFat: 42,
};

export default {

  components: {
    VDateRangePicker,
  },

  props: {
    value: {
      type: Boolean,
    },
    planning: {
      type: Object,
      default: () => ({
        period: [],
      })
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      step: 1,

      form: {
        tinaCapacity: null,
        cheese: { ...defaultCheese },
        bulkCream: { ...defaultBulkCream },
        period: [],
        plannings: []
      },

      rules: {
        required: v => !!v || !_.isEmpty(v) || 'Campo obrigatório!',
        dateTime: v => !v || !!v && v.length == 16 && this.validateDate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!'
      },
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.reset();
        }
        this.$emit('input', value);
      },
    },

    dateRangeFilters() {
      const today = moment().date();
      let currrentFortnight, previousFortnight, nextFortnight;

      if (today <= 15) {
        currrentFortnight = [moment().startOf('month').format('YYYY-MM-DD'), moment().date(15).format('YYYY-MM-DD')]
        previousFortnight = [moment().subtract(1, 'month').date(16).format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')]
        nextFortnight = [moment().date(16).format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
      }
      else {
        currrentFortnight = [moment().date(16).format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
        previousFortnight = [moment().startOf('month').format('YYYY-MM-DD'), moment().date(15).format('YYYY-MM-DD')]
        nextFortnight = [moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().add(1, 'month').date(15).format('YYYY-MM-DD')]
      }

      return {
        'Esta quinzena': currrentFortnight,
        'Quinzena Anterior': previousFortnight,
        'Próxima Quinzena': nextFortnight,
      }
    },

    showDeleteBtn() {
      return this.step == 2 && this.form.plannings.some(o => !!o.id);
    },
  },

  watch: {
    show(value) {
      if (value) {
        if (this.planning) {
          this.$nextTick(() => {

            this.form.tinaCapacity = this.planning.tinaCapacity;
            this.form.period = [...this.planning.period];
            this.form.cheese = { ...this.planning.cheese };
            this.form.bulkCream = { ...this.planning.bulkCream };

            setTimeout(() => {
              this.nextStep();
            }, 500);
          });
        }
        else {
          this.form.period = this.dateRangeFilters['Esta quinzena'];
        }
      }
    }
  },

  methods: {

    reset() {
      this.$refs.form && this.$refs.form.resetValidation();
      this.step = 1;
      this.form = {
        tinaCapacity: null,
        cheese: { ...defaultCheese },
        bulkCream: { ...defaultBulkCream },
        period: [],
        plannings: []
      };
    },

    nextStep() {
      this.loadPeriod();
    },

    async loadPeriod() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      try {
        this.form.plannings = [];

        const [startDate, endDate] = this.form.period;

        const { data } = await this.$axios.get(`/industry/planning`, { params: {
          start_date: startDate,
          end_date: endDate
        } });

        const store = data.reduce((acc, cur) => ({ ...acc, [cur.data]: cur }), {});

        let dateAux = startDate;

        while (dateAux <= endDate) {
          const date = moment(dateAux);
          const formatedDate = date.format('YYYY-MM-DD');
          this.form.plannings.push({
            id: store[formatedDate] ? store[formatedDate].id_planejamento : null,
            tinaCapacity: store[formatedDate] ? parseFloat(store[formatedDate].capacidade_tina) : this.form.tinaCapacity,
            tinas: store[formatedDate] ? parseFloat(store[formatedDate].tinas) : null,
            date: formatedDate
          })
          dateAux = date.add(1, 'day').format('YYYY-MM-DD');
        }

        this.step++;
      } catch (error) {
        this.reset();
        this.show = false;
        this.$snotify.error('Oops, ocorreu um erro ao carregar o período!', 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    async saveNew() {
      if (!this.$refs.form2.validate()) {
        return;
      }

      this.saving = true;

      try {

        const payload = {
          planejamentos: this.form.plannings
            .filter(o => o.id != null || o.tinas != null)
            .map(o => ({
              id_planejamento: o.id || null,
              data: o.date,
              tinas: parseFloat(o.tinas),
              capacidade_tina: o.tinaCapacity || null,
              queijo: {
                densidade: parseFloat(this.form.cheese.density)
              },
              creme_a_granel: {
                padronizacao_leite: parseFloat(this.form.bulkCream.milkStandardization),
                soro_por_tina: parseFloat(this.form.bulkCream.serumPerPan),
                gordura_soro: parseFloat(this.form.bulkCream.wheyFat),
                gordura_desejada: parseFloat(this.form.bulkCream.desiredFat),
              },
            }))
        };

        await this.$axios.post(`/industry/planning`, payload);

        this.$emit('change');

        this.reset();
        this.show = false;
      } catch (error) {
        this.$snotify.error('Oops, ocorreu um erro ao salvar o planejamento!', 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async removePlanning() {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este planejamento?', { color: 'red' }))) {
        return;
      }
      try {
        this.saving = true;

        const [startDate, endDate] = this.form.period;

        await this.$axios.delete(`/industry/planning`, { params: {
          start_date: startDate,
          end_date: endDate
        } });

        this.$emit('change');

        this.reset();
        this.show = false;
      } catch (error) {
        this.$snotify.error('Oops, ocorreu um erro ao remover o planejamento!', 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    validateDate: (value, format) => !value ? '' : moment(value, format).isValid(),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
