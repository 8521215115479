<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Impedimentos de Coleta</span>
    </v-card-title>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  name: "collection-impediments",

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '100px',
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      reasons: [],
    }
  },

  computed: {
    options() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        series: [{
          name: 'Impedimentos de coleta',
          type: 'pie',
          radius: [20, 110],
          center: ['50%', '50%'],

          itemStyle: {
            borderRadius:  0
          },
          label: {
            show: true,
            position: 'inside',
            formatter: '{d} %',
            textShadowColor: '#000',
            textShadowBlur: 0.5,
            textBorderWidth: 0,
            textBorderColor: 'transparent'
          },
          data: this.reasons
        }]
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/relatorios/impedimentosDeColeta`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.reasons = data.map(item => ({ name: item.motivo, value: item.total }));
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de impedimentos de coleta!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
