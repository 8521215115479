<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">{{ form.producer?.name }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        Incluir observações
      </v-card-subtitle>

      <v-card-text class="pb-0">
        <v-form
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              class="py-0"
            >
              <v-textarea
                v-model="form.observation"
                label="Observações"
                filled
                rows="3"
                auto-grow
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const dialog = ref(false)

const form = reactive({
  id: null,
  producer: {},
  observation: null,
})

const show = async (bonus) => {
  form.id = bonus.id
  form.producer = bonus.producer
  form.observation = bonus.observation
  dialog.value = true
}

const save = () => {
  emit('save', form)
  close()
}

const close = () => {
  form.id = null
  form.producer = {}
  form.observation = null
  dialog.value = false
}

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
