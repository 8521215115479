<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h3 class="menu-header white--text">
          Ranking PQFL em Aberto
        </h3>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-row justify="end">
        <v-col
          cols="12"
          md="4"
        >
          <period-date-picker
            v-model="filters.date.input"
            @change="onDateFilter"
          />
        </v-col>
      </v-row>

      <v-flex xs12>
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#tab-ranking">
            Ranking
          </v-tab>
          <v-tab href="#tab-faixas">
            Faixas
          </v-tab>

          <v-tab-item value="tab-ranking">
            <v-card
              dark
              color="transparent"
            >
              <v-card-title>
                <v-chart
                  :options="graphRanking.options"
                  :style="{width: '100%', height: `${graphRanking.height}px !important`}"
                  autoresize
                />
              </v-card-title>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-faixas">
            <v-row>
              <v-col cols="12">
                <v-card
                  dark
                  color="transparent"
                >
                  <v-card-title>
                    <v-chart
                      :options="graphPositive.options"
                      :style="{width: '100%', height: `${graphPositive.height}px !important`}"
                      autoresize
                    />
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  dark
                  color="transparent"
                >
                  <v-card-title>
                    <v-chart
                      :options="graphNegative.options"
                      :style="{width: '100%', height: `${graphNegative.height}px !important`}"
                      autoresize
                    />
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import PeriodDatePicker from '@/Support/Components/PeriodDatePicker.vue';

export default {
  name: 'RankingPqfl',

  components: {
    PeriodDatePicker,
  },

  data() {
    return {
      tab: 'tab-ranking',

      filters: {
        date: {
          input: 'today',
          range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        },
      },

      ranking: [],
      positive: {},
      negative: {},
    };
  },

  computed: {
    graphRanking() {
      return {
        height: 70 + (this.ranking.length * 25),
        options: {
          title: {
            text: 'Ranking PQFL',
            textStyle: { color: '#ddd' },
          },
          color: ['rgba(38, 198, 218, 0.7)'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: { backgroundColor: '#6a7985' }
            },
          },
          grid: {
            left: '1%',
            right: '4%',
            top: '5%',
            bottom: '3%',
            containLabel: true,
          },
          yAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.ranking.map(item => item.produtores),
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.8)'
            }
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.8)',
              formatter: (value) => this.formatNumber(value)
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            }
          },
          series: [
            {
              name: 'Sim',
              type: 'bar',
              areaStyle: {},
              lineStyle: { width: 2 },
              showSymbol: false,
              smooth: false,
              emphasis: {
                focus: 'series'
              },
              label: {
                show: true,
                lineHeight: 17,
                height: 17,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'right',
                formatter: ({ value }) => `  ${this.formatNumber(value)}  `
              },
              data: this.ranking.map(item => item.total)
            },
          ]
        }
      }
    },

    graphPositive() {
      return {
        height: 500,
        options: {
          title: {
            text: 'Faixa De Respostas Positivas Para PQFL Aberto',
            textStyle: { color: '#ddd' },
          },
          tooltip: {
            trigger: 'item',
          },
          legend: {
            orient: 'vertical',
            right: 'right',
            icon: 'circle',
            textStyle: {
              color: '#ddd',
            }
          },
          series: [
            {
              name: 'Respostas Positivas',
              type: 'pie',
              label: {
                show: true,
                position: 'inside',
                formatter: '{d} %',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
                textBorderWidth: 0,
                textBorderColor: 'transparent'
              },
              data: [
                { name: '01 - 20', value: this.positive.acima_um || 0 },
                { name: '20 - 40', value: this.positive.acima_vinte || 0 },
                { name: '40 - 60', value: this.positive.acima_quarenta || 0 },
                { name: '60 - 80', value: this.positive.acima_sesenta || 0 },
                { name: '80 - 100', value: this.positive.acima_oitenta || 0 },
                { name: '> 100', value: this.positive.acima_cem || 0 },
              ]
            }
          ]
        }
      }
    },

    graphNegative() {
      return {
        height: 500,
        options: {
          title: {
            text: 'Faixa De Respostas Negativas Para PQFL Aberto',
            textStyle: { color: '#ddd' },
          },
          tooltip: {
            trigger: 'item',
          },
          legend: {
            orient: 'vertical',
            right: 'right',
            icon: 'circle',
            textStyle: {
              color: '#ddd',
            }
          },
          series: [
            {
              name: 'Respostas Negativas',
              type: 'pie',
              label: {
                show: true,
                position: 'inside',
                formatter: '{d} %',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
                textBorderWidth: 0,
                textBorderColor: 'transparent'
              },
              data: [
                { name: '01 - 20', value: this.negative.acima_um || 0 },
                { name: '20 - 40', value: this.negative.acima_vinte || 0 },
                { name: '40 - 60', value: this.negative.acima_quarenta || 0 },
                { name: '60 - 80', value: this.negative.acima_sesenta || 0 },
                { name: '80 - 100', value: this.negative.acima_oitenta || 0 },
                { name: '> 100', value: this.negative.acima_cem || 0 },
              ]
            }
          ]
        }
      }
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    onDateFilter(event) {
      this.filters.date.range = event;
      this.loadReport();
    },

    async loadReport() {
      try {
        this.$root.$progressBar.loading();
        const [ startDate, endDate ] = this.filters.date.range;

        const [{ data: ranking }, { data: positive }, { data: negative }] = await Promise.all([
          this.$axios.post(`/rankingPQFLAberto/ranking_pqfl`, { data_inicio: startDate, data_fim: endDate }),
          this.$axios.post(`/rankingPQFLAberto/analisePqflSim`, { data_inicio: startDate, data_fim: endDate }),
          this.$axios.post(`/rankingPQFLAberto/analisePqflNao`, { data_inicio: startDate, data_fim: endDate }),
        ]);

        this.ranking = ranking;
        this.positive = positive[0] || {};
        this.negative = negative[0] || {};
      } catch (error) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar os gráficos!', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },
};
</script>

