<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Agendados / Visitados
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#scheduled-visited-table-tab">
          Relatório
        </v-tab>

        <v-tab href="#scheduled-visited-graph-tab">
          Gráfico
        </v-tab>

        <v-tab-item value="scheduled-visited-table-tab">
          <scheduled-visited-table
            v-if="tab === 'scheduled-visited-table-tab'"
          />
        </v-tab-item>

        <v-tab-item value="scheduled-visited-graph-tab">
          <scheduled-visited-graph
            v-if="tab === 'scheduled-visited-graph-tab'"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import ScheduledVisitedTable from "@/Domains/Visits/Reports/ScheduledVisited/Views/ScheduledVisitedTable.vue";
import ScheduledVisitedGraph from "@/Domains/Visits/Reports/ScheduledVisited/Views/ScheduledVisitedGraph.vue";

export default {
  name: "scheduled-visited",

  components: {
    ScheduledVisitedTable,
    ScheduledVisitedGraph,
  },

  data() {
    return {
      tab: 'scheduled-visited-table',
    };
  },
};
</script>