<template>
  <div
    :style="{overflow: 'hidden', height: height}"
    class="d-flex flex-column"
  >
    <v-row class="flex-grow-1">
      <v-col
        cols="6"
        class="pt-0"
      >
        <CardInfo
          v-if="!showApproved"
          title="Produtores"
          icon="icon-produtores"
          height="100%"
          :value="formatNumber(active)"
          :loading="loading"
        />
        <CardInfo
          v-else
          title="Aprovados"
          icon="icon-produtores"
          height="100%"
          :loading="loading"
        >
          <template #value>
            <div
              class="headline"
            >
              <span>
                {{ formatNumber(approved) }}
              </span>
              <template v-if="hasComparison">
                <span>
                  /
                </span>
                <span
                  style="font-size: 16px; line-height: 1rem;"
                >
                  {{ formatNumber(approvedComp) }}
                </span>
              </template>
            </div>
          </template>
        </CardInfo>
      </v-col>
      <v-col
        cols="6"
        class="pt-0"
      >
        <CardInfo
          title="Prospectados"
          icon="icon-produtores"
          height="100%"
          :loading="loading"
        >
          <template #value>
            <div
              class="headline"
            >
              <span>
                {{ formatNumber(prospected) }}
              </span>
              <template v-if="hasComparison">
                <span>
                  /
                </span>
                <span
                  style="font-size: 16px; line-height: 1rem;"
                >
                  {{ formatNumber(prospectedComp) }}
                </span>
              </template>
            </div>
          </template>
        </CardInfo>
      </v-col>
    </v-row>
    <v-row class="flex-grow-1">
      <v-col
        cols="6"
        class="pb-0"
      >
        <CardInfo
          title="Suspensos"
          icon="icon-produtores"
          height="100%"
          :loading="loading"
        >
          <template #value>
            <div
              class="headline"
            >
              <span>
                {{ formatNumber(suspended) }}
              </span>
              <template v-if="hasComparison">
                <span>
                  /
                </span>
                <span
                  style="font-size: 16px; line-height: 1rem;"
                >
                  {{ formatNumber(suspendedComp) }}
                </span>
              </template>
            </div>
          </template>
        </CardInfo>
      </v-col>
      <v-col
        cols="6"
        class="pb-0"
      >
        <CardInfo
          title="Desvinculados"
          icon="icon-produtores"
          height="100%"
          :loading="loading"
        >
          <template #value>
            <div
              class="headline"
            >
              <span>
                {{ formatNumber(unlinked) }}
              </span>
              <template v-if="hasComparison">
                <span>
                  /
                </span>
                <span
                  style="font-size: 16px; line-height: 1rem;"
                >
                  {{ formatNumber(unlinkedComp) }}
                </span>
              </template>
            </div>
          </template>
        </CardInfo>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardInfo from "@/Domains/BI/Components/CardInfo.vue";

import moment from 'moment';
import isObject from "lodash/fp/isObject"
import upperFirst from "lodash/fp/upperFirst"

export default {

  components: {
    CardInfo
  },

  props: {
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    comparisonPeriod: {
      type: Array,
      default: () => []
    },
    showApproved: Boolean,
    height: {
      type: String,
      default: '147px'
    },
  },

  data() {
    return {
      loading: false,
      active: 0,
      prospected: 0,
      suspended: 0,
      unlinked: 0,
      approved: 0,
      prospectedComp: 0,
      suspendedComp: 0,
      unlinkedComp: 0,
      approvedComp: 0,
    }
  },

  computed: {
    hasComparison() {
      return this.comparisonPeriod.length === 2
    },

    comparisonType() {
      if (!this.hasComparison) {
        return 'NONE';
      }

      const [startDate] = this.period;

      const [startDateComp] = this.comparisonPeriod;

      if (moment(startDate).isSame(startDateComp, 'month') && !moment(startDate).isSame(startDateComp, 'year')) {
        return 'YEAR';
      }

      return 'MONTH';
    },

    titleExtended() {
      const [date] = this.period;
      if (this.comparisonType === 'MONTH') {
        return `- ${upperFirst(moment(date).format('MMM/YYYY'))}`;
      }
      if (this.comparisonType === 'YEAR') {
        return `- ${upperFirst(moment(date).format('YYYY'))}`;
      }
      return '';
    },

    titleComparisonExtended() {
      const [date] = this.comparisonPeriod;
      if (this.comparisonType === 'MONTH') {
        return `- ${upperFirst(moment(date).format('MMM/YYYY'))}`;
      }
      if (this.comparisonType === 'YEAR') {
        return `- ${upperFirst(moment(date).format('YYYY'))}`;
      }
      return '';
    },
  },

  watch: {
    period() {
      this.loadReport();
    },
    comparisonPeriod() {
      this.loadComparisonReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/produtores/total`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isObject(data)) {
          throw "PHP Error";
        }

        this.active = data.ativos || 0;
        this.prospected = data.prospectados || 0;
        this.suspended = data.suspensos || 0;
        this.unlinked = data.desvinculados || 0;
        this.approved = data.aprovados || 0;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o total de produtores!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async loadComparisonReport() {
      try {
        if (!this.hasComparison) {
          return;
        }
        this.loading = true;

        const [startDate, endDate] = this.comparisonPeriod;

        let { data } = await this.$axios.post(
          `/produtores/total`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isObject(data)) {
          throw "PHP Error";
        }

        this.prospectedComp = data.prospectados || 0;
        this.suspendedComp = data.suspensos || 0;
        this.unlinkedComp = data.desvinculados || 0;
        this.approvedComp = data.aprovados || 0;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o total de produtores!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
