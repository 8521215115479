<template>
  <v-row>
    <v-col
      v-if="historical.length === 0"
      md12
      xs12
      class="text-center"
    >
      <v-card>
        <v-card-text>Não há histórico de negociação</v-card-text>
      </v-card>
    </v-col>
    
    <v-col
      v-for="(historic, idx) in historical"
      :key="idx"
      cols="12"
      class="d-flex child-flex"
    >
      <v-card :style="dark ? 'background: rgba(0, 0, 0, 0.45);' : ''">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="text-subtitle-2">
              <v-icon>event</v-icon>
              {{ dateFormat(historic.data_cadastro_app, "DD/MM/YYYY") }}
            </span>
            <v-chip
              class="ma-2"
              :color="prospectionColor(historic.status)"
              text-color="white"
            >
              {{ prospectionStatus(historic.status) }}
            </v-chip>
          </div>

          <v-divider />

          <div
            v-if="historic.preco_leite"
            class="d-flex justify-space-between align-center my-2"
          >
            <span class="text-subtitle-2">Preço Oferecido</span>
            <span teal>{{ formatCurrency(historic.preco_leite) }}</span>
          </div>

          <div class="d-flex justify-space-between align-center my-2">
            <span class="text-subtitle-2">Preço do leite da concorrência</span>
            <span>{{ formatCurrency(historic.preco_concorrencia) }}</span>
          </div>

          <div class="d-flex justify-space-between align-center my-2">
            <span class="text-subtitle-2">Laticínio entrega</span>
            <span>{{ historic.laticinio_origem }}</span>
          </div>

          <div class="d-flex justify-space-between align-center my-2">
            <span class="text-subtitle-2">Observações</span>
            <span>{{ historic.observacao }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    historical: {
      type: Array,
      default: () => []
    },

    dark: Boolean
  },

  methods: {
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    prospectionStatus(value) {
      const status = {
        NEGOCIACAO: "Negociação",
        APROVACAO: "Aprovação",
        LEAD: "Observação",
        REPROVADO: "Reprovado",
        DESVINCULADO: "Desvinculado",
      };

      return status[value] || '-';
    },
    prospectionColor(value) {
      const status = {
        NEGOCIACAO: '#FF9C07',
        APROVACAO: '#4CAF50',
        LEAD: '#6B6B6B',
        REPROVADO: '#F44336',
        DESVINCULADO: '#000000',
      };

      return status[value] || '#000000';
    },
  }
}
</script>