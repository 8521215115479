<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-data-table
              :headers="headers"
              :items="items"
              :search="filter.search"
              class="elevation-1"
              show-expand
              single-expand
              hide-default-footer
              item-key="id_rota"
              :items-per-page="-1"
              dense
            >
              <template #[`item.data`]="{ item }">
                <v-text-field
                  v-model="item.data"
                  label="Data"
                  type="date"
                  outlined
                  dense
                  hide-details
                  :rules="[rules.date]"
                />
              </template>
              <template #[`item.tipo_pagamento`]="{ item }">
                <v-radio-group
                  v-model="item.tipo_pagamento"
                  dense
                  hide-details
                  class="mt-0"
                >
                  <v-radio
                    label="Km"
                    value="KM"
                  />
                  <v-radio
                    label="Volume"
                    value="VOLUME"
                  />
                  <v-radio
                    label="Carga"
                    value="CARGA"
                  />
                </v-radio-group>
              </template>
              <template #[`item.tipo_km`]="{ item }">
                <v-radio-group
                  v-model="item.tipo_km"
                  dense
                  hide-details
                  class="mt-0"
                  :disabled="item.tipo_pagamento === 'VOLUME' || item.tipo_pagamento === 'CARGA'"
                >
                  <v-radio
                    label="Planejado"
                    value="PLANEJADO"
                  />
                  <v-radio
                    label="Odômetro"
                    value="ODOMETRO"
                  />
                </v-radio-group>
              </template>
              <template #[`item.km`]="{ item }">
                <v-text-field
                  v-model="item.km"
                  label="Distância"
                  type="number"
                  prefix="Km"
                  outlined
                  dense
                  hide-details
                  :disabled="item.tipo_pagamento === 'VOLUME' || item.tipo_km === 'ODOMETRO'"
                  :rules="[rules.notNegative]"
                  @keypress="onlyPositiveValues"
                />
              </template>
              <template #[`item.valor`]="{ item }">
                <v-text-field
                  v-model="item.valor"
                  :label="`Valor por ${item.tipo_pagamento.toLowerCase()}`"
                  type="number"
                  prefix="R$"
                  outlined
                  dense
                  hide-details
                  :rules="[rules.notNegative]"
                  @keypress="onlyPositiveValues"
                />
              </template>
              <template #[`item.priorizar_tabela`]="{ item }">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Priorizar tabela
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-icon
                            small
                            color="rgba(255, 255, 255, 0.7)"
                            v-on="on"
                          >
                            info
                          </v-icon>
                        </template>
                        Prioriza a tabela de rotas na geração do pagamento de fretes
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="item.priorizar_tabela"
                        :label="item.priorizar_tabela ? 'Sim' : 'Não'"
                        dense
                        hide-details
                        class="mt-0"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template #[`item.action`]="{ item }">
                <v-menu
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="applyToAll(item)"
                    >
                      <v-list-item-icon>
                        <v-icon
                          style="transform: rotate(90deg);"
                        >
                          multiple_stop
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Aplicar a todos</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
                <v-btn
                  v-if="item.tabela.length > 1"
                  icon
                  link
                  transition="fab-transition"
                  @click="expand(!isExpanded)"
                >
                  <v-icon>
                    {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                  </v-icon>
                </v-btn>
              </template>

              <template #expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pa-0 text-center"
                >
                  <v-data-table
                    :headers="detailsDeaders"
                    :items="item.tabela"
                    :items-per-page="-1"
                    dense
                    hide-default-footer
                    class="elevation-1 ma-3"
                  >
                    <template #[`item.data`]="{value}">
                      <span>{{ formatDate(value, "DD/MM/YYYY") }}</span>
                    </template>
                    <template #[`item.valor`]="{value}">
                      <span>R$ {{ formatCurrency(value) }}</span>
                    </template>
                    <template #[`item.priorizar_tabela`]="{value}">
                      {{ value ? 'Sim' : 'Não' }}
                    </template>

                    <template #[`item.action`]="{ item }">
                      <v-btn
                        icon
                        small
                        @click.stop="removeConfig(item.id_frete_rota)"
                      >
                        <v-icon
                          color="red"
                        >
                          delete
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue darken-1"
        :disabled="!hasChanges"
        :loading="saving"
        @click="savePrices"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              send
            </v-icon>
          </template>
          Salvar
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        :disabled="!hasChanges"
        @click="discardChanges"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              autorenew
            </v-icon>
          </template>
          Desfazer alterações
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<style lang="scss">
  .input-has-changed.v-input:not(.v-input--is-focused):not(.v-input--has-state) {
    color: orange !important;
    caret-color: orange !important;
    .v-input__control > .v-input__slot fieldset {
      color: orange !important;
    }

    .v-label {
      color: orange
    }
  }
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";

export default {

  data() {
    return {

      loading: false,
      saving: false,

      filter: {
        search: '',
      },

      headers: [
        { text: 'Rota', value: 'descricao' },
        { text: 'Data', value: 'data', sortable: false },
        { text: 'Tipo de Pagamento', value: 'tipo_pagamento', sortable: false },
        { text: 'Tipo de Km', value: 'tipo_km', sortable: false },
        { text: 'Km', value: 'km', width: 180, sortable: false },
        { text: 'Valor', value: 'valor', width: 180, sortable: false },
        { text: 'Priorizar tabela', value: 'priorizar_tabela', width: 180, sortable: false },
        { text: '', value: 'action', width: 30, sortable: false },
      ],

      items: [],

      originalItems: [],

      detailsDeaders: [
        { text: 'Data', value: 'data', sortable: false },
        { text: 'Tipo de Pagamento', value: 'tipo_pagamento', sortable: false },
        { text: 'Tipo de Km', value: 'tipo_km', sortable: false },
        { text: 'Km', value: 'km', width: 180, sortable: false },
        { text: 'Valor', value: 'valor', width: 180, sortable: false },
        { text: 'Priorizar tabela', value: 'priorizar_tabela', width: 180, sortable: false },
        { text: '', value: 'action', width: 30, sortable: false },
      ],

      rules: {
        date: v => !v || moment(v, 'YYYY-MM-DD').isValid() || 'Campo Inválido!',
        required: v => !!v || !_.isEmpty(v) || 'Campo Obrigatório!',
        notNegative: v => !v || v >= 0 || 'Valor não pode ser negativo',
      },
    };
  },

  computed: {
    hasChanges() {
      return !_.isEqual(this.items, this.originalItems)
    }
  },

  mounted() {
    this.loadRoutes();
  },

  methods: {

    async loadRoutes() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/fretes/tabelaDeRotas`);

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data.map(route => ({
          ...route,
          tipo_pagamento: route.tipo_pagamento || 'KM',
          tipo_km: route.tipo_km || 'ODOMETRO',
          km: parseFloat(route.km || route.distancia) || null,
          valor: parseFloat(route.valor) || null,
          priorizar_tabela: route.priorizar_tabela ? true : false
        }));

        this.originalItems = _.cloneDeep(this.items);
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    discardChanges() {
      this.items = _.cloneDeep(this.originalItems);
      this.$refs.form.resetValidation()
    },

    async savePrices() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      const items = this.items.map(item => ({
        id_rota: item.id_rota,
        data: item.data || moment().format('YYYY-MM-DD'),
        tipo_pagamento: item.tipo_pagamento,
        tipo_km: item.tipo_km,
        km: item.tipo_pagamento === 'KM' && item.tipo_km === 'PLANEJADO' ? (parseFloat(item.km) || null) : null,
        valor: parseFloat(item.valor) || 0,
        priorizar_tabela: item.priorizar_tabela ? true : false
      }));

      try {
        this.saving = true;

        let { data } = await this.$axios.post(`/fretes/salvaValoresRota`, { items });

        if (!data || data.codigo != 1) {
          throw data;
        }
        this.$snotify.success("Tabela de valores atualizada com sucesso", "Sucesso");
        this.loadRoutes();
      }
      catch (err) {
        this.$snotify.error("Erro ao atualizar tabela de valores", "Atenção");
        console.warn(err)
      }
      finally {
        this.saving = false;
      }
    },

    applyToAll({ data, tipo_pagamento, tipo_km, valor, priorizar_tabela }) {
      this.items = this.items.map(item => ({
        ...item,
        data: data || moment().format('YYYY-MM-DD'),
        tipo_pagamento,
        tipo_km,
        valor,
        priorizar_tabela
      }))
    },

    async removeConfig(id_frete_rota) {
      if (!(await this.$root.$confirm(
        'Confirmar remoção?',
        'Esta ação não poderá ser desfeita.',
        { color: 'red' }
      ))) {
        return;
      }

      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/fretes/removerItemTabelaRota`, { id_frete_rota });

        if (!data || !data.codigo) {
          throw data;
        }
        this.$snotify.success("Tabela de valores atualizada com sucesso", "Sucesso");
        this.loadRoutes();
      }
      catch (err) {
        this.$snotify.error("Erro ao atualizar tabela de valores", "Atenção");
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    onlyPositiveValues: (e) => [45, 46].includes(e.charCode) &&  e.preventDefault(),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
