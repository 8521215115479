<template>
  <v-container class="pa-0 ma-0">
    <v-row
      align-content="center"
      class="with-border mx-0 mt-6 mb-3"
      :style="!conditionLocal.enable ? 'width:20%' : ''"
    >
      <v-col
        :md="conditionLocal.enable ? 5 : undefined"
        class="d-flex align-center py-0 mt-0"
      >
        <div class="switch-conditions">
          <span>{{ type === 'NUMBER' ? 'Faixa Padrão' : 'Orientação' }}</span>
          <v-switch
            v-model="conditionLocal.enable"
            hide-details
            class="ma-1 pb-1"
            color="teal accent-3"
            :readonly="questionnaireDisabled"
            :disabled="questionnairePoints"
            @change="setItemCondition"
          />
        </div>
        <template v-if="conditionLocal.enable === true">
          <v-autocomplete
            v-if="type === 'CHECKBOX'"
            v-model="conditionLocal.parameter"
            hide-details
            label="Condição *"
            class="pt-4 pb-2 ml-3"
            dense
            :items="options"
            max-width="50"
            :readonly="questionnaireDisabled"
            :disabled="questionnairePoints"
            :rules="[v => !!v || 'Campo obrigatório!']"
            @change="setItemCondition"
          />
          <v-tooltip
            v-if="type === 'CHECKBOX'"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>error_outline</v-icon>
              </v-btn>
            </template>
            Selecione a condição para qual a orientação deve aparecer!
          </v-tooltip>

          <v-text-field
            v-if="type === 'NUMBER'"
            v-model="conditionLocal.min"
            type="number"
            dense
            label="Mínimo"
            hide-details
            class="mx-2"
            style="max-width:200px"
            :readonly="questionnaireDisabled"
            :disabled="questionnairePoints"
          />
          <v-text-field
            v-if="type === 'NUMBER'"
            v-model="conditionLocal.max"
            type="number"
            dense
            label="Máximo"
            hide-details
            class="mx-2"
            style="max-width:200px"
            :readonly="questionnaireDisabled"
            :disabled="questionnairePoints"
          />
        </template>
      </v-col>

      <template v-if="conditionLocal.enable">
        <v-col
          cols="12"
        >
          <v-textarea
            v-model="standardValueLocal"
            label="Recomendação"
            hide-details
            filled
            :readonly="questionnaireDisabled"
            :disabled="questionnairePoints"
          />
        </v-col>

        <v-col
          cols="12"
        >
          <v-row
            v-for="list in conditionLocal.rotulosExtra"
            :key="list.id"
            style="margin-top:20px !important;"
          >
            <v-col
              cols="12"
              md="6"
              class="flex-option pb-0 pt-0"
            >
              <v-text-field
                v-model="list.name"
                append-outer-icon="close"
                label="Questão extra"
                hide-details
                dense
                :readonly="questionnaireDisabled"
                :disabled="questionnairePoints"
                :rules="[v => !!v || 'Campo obrigatório!']"
                @click:append-outer.stop="deleteExtra(list.id)"
              />
            </v-col>
            <v-col
              md="3"
              class="flex-option pb-0 pt-0"
            >
              <div class="switch-conditions">
                <span>Obrigatório</span>
                <v-switch
                  v-model="list.required"
                  hide-details
                  :readonly="questionnaireDisabled"
                  :disabled="questionnairePoints"
                  class="ma-1 pb-1"
                  color="teal accent-3"
                  @change="onChange"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="!questionnaireDisabled"
          cols="12"
          align="start"
        >
          <v-btn
            dark
            color="blue"
            @click="addExtra()"
          >
            <v-icon left>
              menu
            </v-icon>
            Inserir questão extra
          </v-btn>
        </v-col>

        <v-col
          v-if="type === 'CHECKBOX'"
          cols="12"
        >
          <div class="switch-conditions">
            <span>Informar data de previsão/prazo da solução</span>
            <v-switch
              v-model="conditionLocal.enableDeadline"
              hide-details
              class="ma-1 pb-1"
              color="teal accent-3"
              :readonly="questionnaireDisabled"
              :disabled="questionnairePoints"
              @change="setItemCondition"
            />
          </div>
        </v-col>

        <v-col
          v-if="type === 'CHECKBOX'"
          cols="12"
          class="pt-0"
        >
          <div class="switch-conditions">
            <span>Inserir foto</span>
            <v-switch
              v-model="conditionLocal.enablePicture"
              hide-details
              class="ma-1 pb-1"
              color="teal accent-3"
              :readonly="questionnaireDisabled"
              :disabled="questionnairePoints"
              @change="setItemCondition"
            />
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    type: {
      type: String,
      default: 'CHECKBOX',
    },

    options: {
      type: Array,
      default: () => ([]),
    },

    questionnaireDisabled: {
      type: Boolean,
      default: false,
    },

    questionnairePoints: {
      type: Boolean,
      default: false,
    },

    condition: {
      type: Object,
      default: () => ({
        rotulosExtra: []
      }),
    },

    standardValue: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      conditionLocal: {
        enable: false,
        parameter: null,
        enableDeadline: false,
        enablePicture: false,
        rotulosExtra: [],
      },
    };
  },

  computed: {
    standardValueLocal: {
      get() {
        return this.standardValue;
      },
      set(newValue) {
        this.$emit('update:standardValue', newValue);
      }
    },
  },
  watch: {
    condition() {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.condition) {
        this.conditionLocal = this.condition;
      }
    },

    setItemCondition() {
      if (!this.conditionLocal.enable) {
        this.conditionLocal.parameter = null;
        this.conditionLocal.min = null;
        this.conditionLocal.max = null;
        this.conditionLocal.enableDeadline = false;
        this.conditionLocal.enablePicture = false;
        this.conditionLocal.rotulosExtra = [];
      }

      this.onChange();
    },

    addExtra() {
      this.conditionLocal.rotulosExtra.push({
        id: uuidv4(),
        name: '',
        required: false
      });

      this.onChange();
    },

    deleteExtra(id) {
      if (!this.questionnaireDisabled) {
        this.conditionLocal.rotulosExtra = this.conditionLocal.rotulosExtra.filter(value => value.id !== id);

        this.onChange();
      }
    },

    onChange() {
      this.$emit('update:condition', this.conditionLocal);
    },

  },
};
</script>
