<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        icon-produtores
      </v-icon>
      <span class="title font-weight-light">Produtores Ativos Mensal</span>
    </v-card-title>

    <v-card-text class="pb-0">
      <v-row>
        <v-spacer />
        <div class="col col-4 py-0 d-flex flex-column text-right white--text">
          <span class="caption font-weight-light">{{ report.month }}</span>
          <span class="headline">{{ formatNumber(report.producers) }}</span>
        </div>
      </v-row>
    </v-card-text>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    graphColor: {
      type: String,
      default: 'rgba(38, 198, 218, 0.7)'
    },
    height: {
      type: String,
      default: '100px',
    },
    date: {
      type: String,
      default: moment().format('YYYY-MM-DD')
    },
  },

  data() {
    return {
      loading: false,
      items: [],
      report: {
        month: '',
        producers: 0,
      },
    }
  },

  computed: {
    options() {
      const getLabel = (length, index) => ({
        show: true,
        lineHeight: 20,
        height: 20,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: index === 0 ? 'right' : index === length - 1 ? 'left' : 'inside',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      });

      return {
        color: [this.graphColor],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          top: '8px',
          bottom: '0%',
          containLabel: false
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.items.map(({ mes }) => moment(mes, "YYYY-MM").format("MM/YYYY")),
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: { show: false },
        },
        series: [{
          name: 'Produtores Ativos',
          type: 'line',
          areaStyle: {},
          lineStyle: { width: 2 },
          showSymbol: true,
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          data: this.items.map(({ total }, index) => ({
            value: parseFloat(total),
            label: getLabel(this.items.length, index),
          }))
        }]
      }
    }
  },

  watch: {
    date() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const startDate = moment(this.date).startOf("month").subtract(6, "month").format('YYYY-MM-DD');
        const endDate = moment(this.date).endOf("month").format('YYYY-MM-DD');

        const { data } = await this.$axios.post(
          `/produtores/totalAtivosMes`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (data.length > 0) {
          const last = data[data.length - 1];
          this.report = {
            month: moment(last.mes).format('MM/YYYY'),
            producers: parseFloat(last.total),
          }
        }
        else {
          this.report = { month: '', producers: 0 }
        }

        this.items = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de produtores ativos por mês!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
