<template>
  <div>
    <!-- workaround para o modo fullscreen -->
    <v-overlay
      v-if="!!attach"
      v-model="dialog"
      absolute
      :attach="attach"
    />
    
    <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      :attach="attach"
      :hide-overlay="!!attach"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-card-title
          :class="`${options.color}--text text-h5`"
        >
          {{ title }}
        </v-card-title>
        <v-card-text
          v-show="!!message || options.token"
          class="pa-4"
        >
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="agree"
          >
            <span v-html="message" />

            <span v-if="options.token">
              Para continuar, por favor digite <b>{{ options.token }}</b> no campo abaixo:
              <v-text-field
                v-model.number="typedToken"
                :rules="[v => (v && v === options.token) || 'Token de confirmação incorreto!']"
              />
            </span>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            outlined
            @click.native="cancel"
          >
            {{ options.noText }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            outlined
            @click.native="agree"
          >
            {{ options.yesText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    typedToken: null,
    options: {
      color: 'primary',
      width: 380,
      // zIndex: 200,
      noText: 'Cancelar',
      yesText: 'Sim',
      token: null,
      attach: null,
    },
  }),

  computed: {
    attach() {
      return this.options.attach || false
    }
  },
  
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    async agree() {
      if (!await this.$refs.form.validate()) {
        return;
      }
      this.resolve(true);
      this.reset();
    },
    cancel() {
      this.resolve(false);
      this.reset();
    },
    reset() {
      this.dialog = false;
      this.typedToken = null;
      this.options.token = null;
      this.options.attach = null;
      this.$refs.form.resetValidation();
    }
  }
}
</script>