<template>
  <v-autocomplete
    v-model="input"
    :items="countys"
    :loading="loading"
    v-bind="$attrs"
    item-text="code"
    item-value="code"
    return-object
    @input="onChange"
  />
</template>

<script>
export default {
  name: 'countys-select',

  props: {
    value: String,
    uf: String,
    city: String
  },

  data() {
    return {
      loading: false,

      input: null,

      countys: [],
    };
  },

  watch: {
    value() {
      this.input = { code: this.value, city: this.city };
    },
    uf() {
      this.getCountys();
    },
  },

  created() {
    this.getCountys();
  },

  methods: {
    async getCountys() {
      if (!this.uf) {
        return;
      }
      this.countys = [];
      try {
        this.loading = true;

        const { data } = await this.$queries.post(`/municipios/listaJson`, { uf: this.uf });
        
        this.countys = data.map(o => ({
          code: `${o.codigo_municipio}`,
          city: o.end_cidade,
        }));
        
      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar codigo município!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onChange(value) {
      this.$emit("input", value.code);
      this.$emit("change", value);
    },
  },
};
</script>
