<template>
  <v-dialog
    v-if="dialog.show"
    v-model="dialog.show"
    width="65vw"
    persistent
    transition="dialog-bottom-transition"
  >
    <div
      class="d-flex"
    >
      <v-card
        min-height="600"
        width="15vw"
        class="mx-auto"
        tile
      >
        <v-navigation-drawer
          permanent
          width="15vw"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Laboratórios
              </v-list-item-title>
              <v-list-item-subtitle>
                Cadastro/Atualização de Laboratório
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list
            dense
            nav
          >
            <v-list-item-group>
              <v-list-item
                v-for="lab of labs"
                :key="lab.id"
                link
                @click="setView(lab)"
              >
                <v-list-item-icon>
                  <v-icon>token</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ lab.description }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item
              link
              @click.stop="addNewLab"
            >
              <v-list-item-icon>
                <v-icon>add</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Adicionar novo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <v-card
        width="50vw"
        tile
        class="d-flex flex-column"
      >
        <template
          v-if="selectedLab"
        >
          <v-card-title>
            {{ selectedLab.description }}

            <v-spacer />

            <v-menu
              bottom
              left
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  dense
                  @click="destroy"
                >
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="selectedLab.description"
                  label="Nome"
                  filled
                  dense
                  hide-details
                />
              </v-col>
              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="selectedLab.identifier"
                  label="Identificador"
                  filled
                  dense
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="4"
              >
                <v-select
                  v-model="selectedLab.integrationType"
                  :items="integrationTypes"
                  label="Tipo de Integração"
                  filled
                  dense
                  hide-details
                />
              </v-col>
              <v-col
                v-if="selectedLab.integrationType === 'DATE_RANGE'"
                cols="4"
              >
                <v-text-field
                  v-model="selectedLab.dateRangeSearch"
                  label="Dias Busca Retroativa"
                  filled
                  dense
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="selectedLab.apiUrl"
                  label="URL Base"
                  filled
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="4"
              >
                <v-select
                  v-model="selectedLab.producerSearchType"
                  :items="producerSearchTypes"
                  label="Tipo de Busca de Produtor"
                  filled
                  dense
                  persistent-hint
                  hint="Ordem da busca de produtor para o laticinio"
                />
              </v-col>

              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="selectedLab.parser.code"
                  label="Regex - Código"
                  persistent-hint
                  dense
                  filled
                  hint="Regex utilizada para o replace do campo codigo_laticinio do produtor (Não utilizar /)"
                />
              </v-col>

              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="selectedLab.parser.document"
                  label="Regex - CPF/CNPJ"
                  persistent-hint
                  dense
                  filled
                  hint="Regex utilizada para o replace do campo cpf_cnpj do produtor (Não utilizar /)"
                />
              </v-col>
            </v-row>

            <div class="d-flex">
              <span class="title">
                Parâmetros
              </span>

              <v-spacer />

              <v-btn
                icon
                @click="addNewParam"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </div>

            <v-row
              v-for="parameter of selectedLab.parameters"
              :key="parameter.id"
              class="d-flex align-center"
            >
              <v-col
                cols="3"
              >
                <v-text-field
                  v-model="parameter.key"
                  label="Parametro"
                  dense
                  hide-details
                  filled
                />
              </v-col>
              <v-col
                cols="3"
              >
                <v-text-field
                  v-model="parameter.description"
                  label="Descrição"
                  dense
                  hide-details
                  filled
                />
              </v-col>
              <v-col
                cols="2"
              >
                <v-select
                  v-model="parameter.type"
                  :items="[
                    { text: 'Texto', value: 'TEXT' },
                    { text: 'Seleção', value: 'SELECT' },
                  ]"
                  label="Tipo"
                  dense
                  hide-details
                  filled
                />
              </v-col>

              <v-col
                cols="3"
              >
                <v-text-field
                  v-if="parameter.type === 'SELECT'"
                  v-model="parameter.options"
                  label="Opções (Separado por ',')"
                  dense
                  hide-details
                  filled
                />
              </v-col>

              <v-spacer />

              <v-btn
                class="mr-3"
                icon
                color="red"
                @click="removeParam(parameter)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-row>
          </v-card-text>
        </template>

        <v-spacer />

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="close()"
          >
            Fechar
          </v-btn>

          <v-spacer />

          <v-btn
            v-if="selectedLab"
            color="blue darken-1"
            text
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
    </v-overlay>
  </v-dialog>
</template>

<style>

</style>

<script>
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/fp/isEmpty";

export default {

  data() {
    return {
      // Exibe ou não o dialog
      dialog: {
        show: false,
      },

      // loader da aplicação
      loading: false,

      // Menu selecionado
      labs: [],

      // Menu selecionado
      selectedLab: null,

      producerSearchTypes: [
        {
          text: "Apenas Código",
          value: "ONLY_CODE",
        },
        {
          text: "Apenas CPF/CNPJ",
          value: "ONLY_DOCUMENT",
        },
        {
          text: "Código -> CPF/CNPJ",
          value: "CODE_DOCUMENT",
        },
        {
          text: "CPF/CNPJ -> Código",
          value: "DOCUMENT_CODE",
        },
      ],

      integrationTypes: [
        {
          text: "Busca Retroativa",
          value: "DATE_RANGE",
        },
        {
          text: "Cursor",
          value: "CURSOR",
        },
      ],
    }
  },

  methods: {

    async show() {
      this.dialog.show = true;

      await this.load();
    },

    close() {
      this.dialog.show = false;
      this.selectedLab = null;
    },

    async save() {
      try {
        this.loading = true;

        const parametros = this.selectedLab.parameters.map(parameter => {
          return {
            id: parameter.id,
            chave: parameter.key,
            descricao: parameter.description,
            tipo: parameter.type,
            opcoes: parameter.options,
          }
        });

        const lab = {
          id_laboratorio: this.selectedLab.id,
          nome: this.selectedLab.description,
          identificador: this.selectedLab.identifier,
          tipo_integracao: this.selectedLab.integrationType,
          intervalo_busca: this.selectedLab.dateRangeSearch,
          endpoint_integracao: this.selectedLab.apiUrl,
          tipo_busca_produtor: this.selectedLab.producerSearchType,
          regex_codigo_laticinio: this.selectedLab.parser.code,
          regex_cpf_cnpj: this.selectedLab.parser.document,
          parametros,
        }

        const actions = {
          CREATE: this.create,
          UPDATE: this.update,
        }

        const response = isEmpty(lab.id_laboratorio) ? await actions['CREATE'](lab) : await actions['UPDATE'](lab);

        await this.load();

        this.selectedLab = this.labs.find(updatedLab => updatedLab.id === response.id_laboratorio);

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async create(lab) {
      const { data } = await this.$axios.post(`/settings/labs`, {
        ...lab,
        id_laboratorio: uuidv4(),
      });

      return data;
    },

    async update(lab) {
      const { data } = await this.$axios.put(`/settings/labs/${lab.id_laboratorio}`, lab);

      return data;
    },

    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/settings/labs`);

        const labs = data.map(lab => {
          const parameters = (lab.parametros || []).map(parameter => {
            return {
              id: parameter.id,
              key: parameter.chave,
              description: parameter.descricao,
              type: parameter.tipo,
              options: parameter.opcoes,
            }
          });

          return {
            id: lab.id_laboratorio,
            description: lab.nome,
            identifier: lab.identificador,
            integrationType: lab.tipo_integracao,
            dateRangeSearch: parseInt(lab.intervalo_busca) || null,
            apiUrl: lab.endpoint_integracao,
            producerSearchType: lab.tipo_busca_produtor,
            parser: {
              code: lab.regex_codigo_laticinio,
              document: lab.regex_cpf_cnpj,
            },
            parameters,
          }
        });

        this.labs = labs;
        this.selectedLab = labs[0];
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async destroy() {
      try {
        this.loading = true;

        await this.$axios.delete(`/settings/labs/${this.selectedLab.id}`);

        await this.load();
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um excluir o laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    addNewLab() {
      this.selectedLab = {
        id: null,
        description: '',
        identifier: '',
        integrationType: null,
        dateRangeSearch: null,
        apiUrl: '',
        parameters: [],
        parser: {
          code: '',
          document: '',
        }
      }
    },

    addNewParam() {
      this.selectedLab.parameters = [
        ...this.selectedLab.parameters,
        {
          id: uuidv4(),
          key: '',
          description: '',
        },
      ];
    },

    removeParam(excludedParam) {
      this.selectedLab.parameters = this.selectedLab.parameters.filter(parameter => parameter.id !== excludedParam.id);
    },

    setView(lab) {
      this.selectedLab = lab;
    },

  }

}
</script>
