<template>
  <v-dialog
    v-model="show"
    width="30%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Edição/Cadastro de Tipos de Animal</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.description"
                  label="Tipo de Animal"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  props: {
    value: Boolean,
    editId: String,
  },

  data() {
    return {
      form: {
        description: null,
      },
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          description: null,
        };

        if (this.editId) {
          this.loadPan();
        }
      }
    }
  },

  methods: {

    async loadPan() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/animals-type/${this.editId}`);

        this.form = {
          description: data.descricao,
        };
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita uma causa no banco de dados
     */
    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_tipo_animal: this.form.id,
          descricao: this.form.description,
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);

        this.$snotify.success("Tipo de Animal cadastrado com sucesso", "Sucesso");

        this.close();

      } catch (e) {

        this.$snotify.error("Erro ao cadastrar tipo de animal", "Atenção");

        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/projects/animals-type`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/projects/animals-type/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    }
  },
}
</script>
