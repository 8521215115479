<template>
  <div>
    <!-- <v-row>
      <v-col
        md="12"
        class="text-center"
      >
        <h2>Sanidade</h2>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col md="12">
        <v-card
          elevation="1"
          outlined
        >
          <v-card-title class="pb-0">
            Vacinas
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :headers="headersVaccine"
                  :items="vaccineList"
                  class="table-custom"
                  :search="filters.search"
                  hide-default-footer
                  dense
                >
                  <template #[`item.periodo`]="{ item }">
                    <v-select
                      v-model="item.periodo"
                      :items="['NÃO REALIZA', 'DIÁRIO', 'SEMANAL', 'MENSAL', 'SEMESTRAL', 'ANUAL']"
                      placeholder="Selecionar"
                      hide-details
                      dense
                    />
                  </template>
                  <template #[`item.laboratorio`]="{ item }">
                    <v-text-field
                      v-model="item.laboratorio"
                      placeholder="Laboratório"
                      dense
                    />
                  </template>
                  <template #[`item.quantidade`]="{ item }">
                    <v-text-field
                      v-model="item.quantidade"
                      placeholder="Quantidade"
                      dense
                    />
                  </template>
                  <template #[`item.valor`]="{ item }">
                    <masked-input
                      v-model="item.valor"
                      placeholder="0"
                      dense
                      :mask="FloatNumberMask"
                      currency
                      return-unmasked
                    />
                  </template>
                </v-data-table>
              </v-col>
              <v-col md="5">
                <v-text-field
                  v-model="vaccineProvider"
                  label="Fornecedor de Vacina"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-card
          elevation="1"
          outlined
        >
          <v-card-title class="pb-0">
            Exames
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :headers="headersVaccine"
                  :items="examList"
                  class="table-custom"
                  :search="filters.search"
                  hide-default-footer
                  dense
                >
                  <template #[`item.periodo`]="{ item }">
                    <v-select
                      v-model="item.periodo"
                      :items="['NÃO REALIZA', 'DIÁRIO', 'SEMANAL', 'MENSAL', 'SEMESTRAL', 'ANUAL']"
                      placeholder="Selecionar"
                      hide-details
                      dense
                    />
                  </template>
                  <template #[`item.laboratorio`]="{ item }">
                    <v-text-field
                      v-model="item.laboratorio"
                      placeholder="Laboratório"
                      dense
                    />
                  </template>
                  <template #[`item.quantidade`]="{ item }">
                    <v-text-field
                      v-model="item.quantidade"
                      placeholder="Quantidade"
                      dense
                    />
                  </template>
                  <template #[`item.valor`]="{ item }">
                    <masked-input
                      v-model="item.valor"
                      placeholder="0"
                      dense
                      :mask="FloatNumberMask"
                      currency
                      return-unmasked
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-card
          elevation="1"
          outlined
        >
          <v-card-title class="pb-0">
            Antibioticos
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="2">
                Grupo
              </v-col>
              <v-col :md="editable?2:3">
                Principio Ativo
              </v-col>
              <v-col md="2">
                Nome Comercial
              </v-col>
              <v-col md="3">
                Fornecedor
              </v-col>
              <v-col :md="editable?3:2">
                Valor Total
              </v-col>
            </v-row>
            <sanitation-antibiotic
              v-for="antibiotico in antibioticList"
              :key="antibiotico.id"
              :antibiotico="antibiotico"
              @onAntibioticoDelete="onAntibioticoDelete"
            />
            <v-row v-if="editable">
              <v-col md="6">
                <v-btn
                  icon
                  class="primary"
                  color="white mr-5"
                  @click="addAntibiotic()"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col>
        <v-card
          elevation="3"
          outlined
        >
          <v-card-title class="pb-0">
            Registros
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="localData.rg_calendario_sanitario"
                  label="Calendário Sanitário"
                  hide-details
                  :items="['NÃO REALIZA', 'FICHA EM PAPEL', 'SOFTWARES ESPECIFICOS', 'OUTROS', 'PLANILHAS/EXCEL']"
                />
              </v-col>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="localData.rg_colostragem"
                  label="Colostragem"
                  hide-details
                  :items="['SEMPRE', 'AS VEZES', 'NUNCA', 'QUANDO NECESSÁRIO']"
                />
              </v-col>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="localData.rg_cura_umbigo"
                  label="Cura de umbigo"
                  hide-details
                  :items="['SEMPRE', 'AS VEZES', 'NUNCA', 'QUANDO NECESSÁRIO']"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="localData.rg_sob_tratamento"
                  label="Animais sob tratamento"
                  hide-details
                  :items="['NENHUM', 'IDENTIFICADOS', 'PROTOCOLO']"
                />
              </v-col>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="localData.rg_animais_doentes"
                  label="Controle de animais doentes"
                  hide-details
                  :items="['NENHUM', 'PROTOCOLO', 'REGISTRO SIMPLES']"
                />
              </v-col>
              <v-col class="py-1">
                <v-autocomplete
                  v-model="localData.rg_procedimentos_vermifugacao"
                  label="Adota procedimentos de vermifugação"
                  hide-details
                  :items="['NÃO', 'SIM']"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.card-table {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;

  .table-custom {
    table {
      thead > tr {
        span {
          font-family: Roboto, Arial, sans-serif;
          font-size: 0.9rem;
          font-weight: 700;
        }
        background: rgba(226, 225, 225, 0.5);
      }
    }
  }

  .row-custom {
    padding-right: 20px !important;
  }
}
</style>

<script>
import * as _ from "lodash";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import SanitationAntibiotic from "@/Domains/Quality/PlanQualification/Components/SanitationAntibiotic.vue";

const dictionaryAutocomplete = {
  DIARIO: "DIÁRIO",
};

export default {
  name: "SanitationStep",
  components: {
    MaskedInput,
    SanitationAntibiotic,
  },
  props: {
    diagnostic: {
      require: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filters: {
        search: "",
      },

      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      headersVaccine: [
        {
          text: "Vacinas",
          value: "item",
        },
        {
          text: "Período",
          value: "periodo",
        },
        {
          text: "Laboratório",
          value: "laboratorio",
        },
        {
          text: "Quantidade",
          value: "quantidade",
        },
        {
          text: "Valor Total",
          value: "valor",
        },
      ],

      antibioticList: [],
      vaccineList: [],
      examList: [],
    };
  },
  computed: {
    localData: {
      get() {
        return this.diagnostic;
      },
      set(newValue) {
        return this.$emit("update:diagnostic", {
          ...newValue,
        });
      },
    },
    vaccineProvider: {
      get() {
        return this.diagnostic.fornecedor_vacina;
      },
      set(newValue) {
        _.set(this.diagnostic, "fornecedor_vacina", newValue);
        this.$emit("update:diagnostic.fornecedor_vacina", this.diagnostic.fornecedor_vacina);
      },
    },
  },
  watch: {
    antibioticList: {
      deep: true,
      handler: function (newValue) {
        let objAntibiotic = {
          grupo: [],
          fornecedor: [],
          valor_total: [],
          nome_comercial: [],
          principio_ativo: [],
        };
        newValue.forEach((Antibiotic) => {
          objAntibiotic.grupo.push(Antibiotic.grupo);
          objAntibiotic.fornecedor.push(Antibiotic.fornecedor);
          objAntibiotic.valor_total.push(Antibiotic.valor_total);
          objAntibiotic.nome_comercial.push(Antibiotic.nome_comercial);
          objAntibiotic.principio_ativo.push(Antibiotic.principio_ativo);
        });

        _.set(this.localData, "antibioticos_json", JSON.stringify(objAntibiotic));
      },
    },
    vaccineList: {
      deep: true,
      handler: function (newValue) {
        let objVaccine = {
          item: [],
          valor: [],
          periodo: [],
          quantidade: [],
          laboratorio: [],
        };

        newValue.forEach((vacina) => {
          objVaccine.item.push(vacina.item);
          objVaccine.valor.push(vacina.valor);
          objVaccine.periodo.push(vacina.periodo);
          objVaccine.quantidade.push(vacina.quantidade);
          objVaccine.laboratorio.push(vacina.laboratorio);
        });

        _.set(this.diagnostic, "vacinacao_json", JSON.stringify(newValue));
        this.$emit("update:diagnostic.vacinacao_json", this.diagnostic.vacinacao_json);
      },
    },
    examList: {
      deep: true,
      handler: function (newValue) {
        let objetoExames = {
          item: [],
          valor: [],
          periodo: [],
          quantidade: [],
          laboratorio: [],
        };
        newValue.forEach((exame) => {
          objetoExames.item.push(exame.item);
          objetoExames.valor.push(exame.valor);
          objetoExames.periodo.push(exame.periodo);
          objetoExames.quantidade.push(exame.quantidade);
          objetoExames.laboratorio.push(exame.laboratorio);
        });
        _.set(this.diagnostic, "exames_json", JSON.stringify(newValue));
        this.$emit("update:diagnostic.exames_json", this.diagnostic.exames_json);
      },
    },
  },

  mounted() {
    this.handleData();
  },
  methods: {
    handleData() {
      let objetoAntibiotico = this.diagnostic.antibioticos_json ? JSON.parse(this.diagnostic.antibioticos_json) : {};
      if (objetoAntibiotico.grupo) {
        objetoAntibiotico.grupo.forEach((num, index) => {
          this.antibioticList.push({
            id: index,
            grupo: objetoAntibiotico.grupo[index],
            fornecedor: objetoAntibiotico.fornecedor[index],
            valor_total: objetoAntibiotico.valor_total[index],
            nome_comercial: objetoAntibiotico.nome_comercial[index],
            principio_ativo: objetoAntibiotico.principio_ativo[index],
          });
        });
      }

      let objetoVacina = this.diagnostic.vacinacao_json ? JSON.parse(this.diagnostic.vacinacao_json) : {};
      if (objetoVacina.item) {
        objetoVacina.item.forEach((num, index) => {
          this.vaccineList.push({
            item: objetoVacina.item[index],
            valor: objetoVacina.valor[index],
            periodo: _.has(dictionaryAutocomplete, objetoVacina.periodo[index])
              ? dictionaryAutocomplete[objetoVacina.periodo[index]]
              : objetoVacina.periodo[index],
            quantidade: objetoVacina.quantidade[index],
            laboratorio: objetoVacina.laboratorio[index],
          });
        });
      }

      if (_.isEmpty(this.vaccineList)) {
        this.loadVaccines();
      }

      let objetoExames = this.diagnostic.exames_json ? JSON.parse(this.diagnostic.exames_json) : {};
      if (objetoExames.item) {
        objetoExames.item.forEach((num, index) => {
          this.examList.push({
            item: objetoExames.item[index],
            valor: objetoExames.valor[index],
            periodo: _.has(dictionaryAutocomplete, objetoExames.periodo[index])
              ? dictionaryAutocomplete[objetoExames.periodo[index]]
              : objetoExames.periodo[index],
            quantidade: objetoExames.quantidade[index],
            laboratorio: objetoExames.laboratorio[index],
          });
        });
      }
    },

    async loadVaccines() {
      try {
        if (!_.isEmpty(this.vaccineList)) {
          return true;
        }

        const { data } = await this.$queries.post(`/configuracaoLaticinio/listaVacinacaoJson`);

        if (!_.isObject(data)) {
          throw "PHP error: consulta vacinas";
        }

        this.vaccineList = data.map((vacina) => {
          return {
            item: vacina.nome,
            valor: "",
            periodo: "",
            quantidade: "",
            laboratorio: "",
          };
        });
      } catch (e) {
        console.log(e);
      }
    },

    addAntibiotic() {
      this.antibioticList.push({
        id: this.antibioticList.length,
        item: "",
        grupo: "",
        fornecedor: "",
        valor_total: 0,
        nome_comercial: "",
        principio_ativo: "",
      });
    },

    onAntibioticoDelete(id) {
      this.antibioticList = _.remove(this.antibioticList, (data) => {
        return data.id !== id;
      });
    },
  },
};
</script>
