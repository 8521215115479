<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Metas de visitas
    </v-card-title>
    <v-card-text>
      <v-row class="justify-center">
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="itemSelected"
            label="Inserir Indicador"
            :items="visitTypes"
            item-value="value"
            item-text="text"
            return-object
            dark
            outlined
            hide-details
            @change="addItem"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="goals"
      hide-default-footer
      disable-sort
    >
      <template #[`item.action`]="{ item }">
        <v-select
          v-model="item.action"
          :items="options"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.visits`]="{ item }">
        <v-text-field
          v-model.number="item.visits"
          placeholder="0"
          type="number"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          @keypress="disableDotAndComma"
        />
      </template>
      <template #[`item.type`]="{ item }">
        <v-select
          v-model="item.type"
          :items="tipos"
          item-value="value"
          item-text="text"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.period`]="{ item }">
        <v-select
          v-model="item.period"
          :items="periods"
          item-value="value"
          item-text="text"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>

      <template #[`item.questionnaires`]="{ item }">
        <v-select
          v-model="item.questionnaires"
          label="Questionários"
          :items="questionnaires"
          :loading="loading"
          prepend-inner-icon="icon-cadastros"
          item-value="id"
          item-text="title"
          multiple
          outlined
          small-chips
          hide-details
          dense
        />
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          color="white"
        >
          <v-icon
            @click="removeItem(item)"
          >
            delete_outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

export default {

  components: {
  },

  props: {
    value: Array,
  },

  data() {
    return {

      loading: false,

      headers: [
        { text: "Tipo", value: "text"},
        { text: "Quantidade", value: "visits", width: 100 },
        { text: '%', value: "type", width: 30 },
        { text: 'Período', value: "period", width: 150 },
        { text: 'Questionários', value: "questionnaires", width: 200 },
        { text: "", value: "actions", width: 30 },
      ],

      periods: [
        { text: "Quinzenal", value: "quinzenal" },
        { text: "Mensal", value: "mensal" },
        { text: "Bimestral", value: "bimestral" },
        { text: "Trimestral", value: "trimestral" },
        { text: "Quadrimestral", value: "quadrimestral" },
        { text: "Quinquenal", value: "quinquenal" },
        { text: "Semestral", value: "semestral" },
        { text: "Anual", value: "anual" },
      ],
      periodTimeDays: [
        { text: "h", value: "h" },
        { text: "d", value: "d" },
      ],

      tipos: [
        { text: '%', value: 'PERCENT' },
        { text: 'UN', value: 'UN' }
      ],

      visitTypes:[
        { text: 'CPP', value: 'CPP' },
        { text: 'CCS', value: 'CCS' },
        { text: 'BPA', value: 'BPA' },
        { text: 'COLETA DE AMOSTRA', value: 'COLETA_AMOSTRA' },
        { text: 'RECOMENDAÇÕES VETERINÁRIAS', value: 'VETERINARIO' },
        { text: 'RECOMENDAÇÕES AGRONÔMICAS', value: 'AGRONOMICO' },
        { text: 'ZOOTÉCNICO', value: 'ZOO_TECNICO' },
        { text: 'ACOMPANHAMENTO DE ORDENHA', value: 'ACOMPANHAMENTO_DE_ORDENHA' },
        { text: 'PRODUTORES', value: 'PRODUTORES' },
        { text: 'ATENDIMENTOS VETERINÁRIOS', value: 'ATENDIMENTO_VETERINARIO' },
        { text: 'ATENDIMENTOS AGRONÔMICOS', value: 'ATENDIMENTO_AGRONOMICO' },
      ],

      itemSelected: '',

      questionnaires:[],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

  },

  mounted() {
    this.loadQuestionnaires();
  },

  methods: {

    async loadQuestionnaires() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ["VISITA_PQFL", "VISITA_IN77", "VISITA_IN59", "VISITA_BPA", "VISITA_CPP", "VISITA_CCS", "VISITA_CHECKLIST_INICIAL", "VISITA_BRUCELOSE_TUBERCULOSE" ],
            ativo: 1,
          }
        );

        this.questionnaires = data.map(item => {
          let text = item.titulo ? item.titulo : ``;
          return {
            id: item.id,
            title: text,
            types: item.tipo,
          }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter([month]) {
      console.log( month);
      //this.filter.date.month = month;
    },

    addItem() {
      const p = {
        ...this.itemSelected,
        visits: 0,
        type: 'UN',
        period: 'mensal',
      };

      this.goals.push(p);
      this.$nextTick(() => {
        this.itemSelected = "";
      });
    },

    removeItem(item) {
      const idx = this.goals.findIndex(o => o.value === item.value);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
