<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-date-range-picker
          v-model="filters.range"
          dark
          @change="getInvoices"
        />
      </v-col>

      <v-spacer />

      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-text-field
          v-model="filters.search"
          label="Pesquisar"
          prepend-inner-icon="search"
          dark
          filled
          hide-details
          clearable
        />
      </v-col>
    </v-row>

    <v-card
      class="pa-0"
      color="transparent"
      dark
    >
      <v-tabs
        v-model="tab"
        background-color="rgba(0, 0, 0, 0.5)"
        class="transparent"
        dark
        icons-and-text
      >
        <v-tab
          href="#tab-TODAS"
          @click="onTabClick"
        >
          <span class="ma-0">
            <v-chip
              color="light-blue"
              small
            />
            Todas
            <span v-if="filteredItems.length > 0">({{ filteredItems.length }})</span>
          </span>
        </v-tab>

        <v-tab
          href="#tab-PENDENTE"
          @click="onTabClick"
        >
          <span class="ma-0">
            <v-chip
              color="amber"
              small
            />
            Pendentes
            <span v-if="totalByStatus['PENDENTE'] > 0">({{ totalByStatus['PENDENTE'] }})</span>
          </span>
        </v-tab>

        <v-tab
          href="#tab-EMITIDA"
          @click="onTabClick"
        >
          <span class="ma-0">
            <v-chip
              color="green"
              small
            />
            Emitidas
            <span v-if="totalByStatus['EMITIDA'] > 0">({{ totalByStatus['EMITIDA'] }})</span>
          </span>
        </v-tab>

        <v-tab
          href="#tab-REJEITADA"
          @click="onTabClick"
        >
          <span class="ma-0">
            <v-chip
              color="red"
              small
            />
            Rejeitadas
            <span v-if="totalByStatus['REJEITADA'] > 0">({{ totalByStatus['REJEITADA'] }})</span>
          </span>
        </v-tab>

        <v-tab
          href="#tab-CANCELADA"
          @click="onTabClick"
        >
          <span class="ma-0">
            <v-chip
              color="grey"
              small
            />
            Canceladas
            <span v-if="totalByStatus['CANCELADA'] > 0">({{ totalByStatus['CANCELADA'] }})</span>
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="transparent"
      >
        <v-tab-item
          v-for="status in ['TODAS', 'PENDENTE', 'EMITIDA', 'REJEITADA', 'CANCELADA']"
          :key="status"
          :value="'tab-' + status"
          eager
        >
          <v-card-title class="pt-0" />

          <data-table
            ref="report"
            v-model="selectedInvoices"
            :headers="headers"
            :items="getItems(status)"
            :name="type == 'NFPE' ? 'Notas de Produtor' : 'Notas Fiscais'"
            dark
            dense
            :show-select="['TODAS', 'PENDENTE', 'EMITIDA'].includes(status)"
            @click:row="editInvoice"
          >
            <template #[`item.status`]="{ value }">
              <v-chip
                x-small
                :color="getStatusColor(value)"
              >
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.action`]="{ item }">
              <div class="d-flex align-center justify-end">
                <v-tooltip
                  v-if="item.estoque_lancado"
                  top
                >
                  <template #activator="{ on }">
                    <v-chip
                      x-small
                      color="warning"
                      class="mb-0"
                      v-on="on"
                    >
                      E
                    </v-chip>
                  </template>
                  Estoque lançado
                </v-tooltip>
                <v-menu
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="item.tipo_emissao === 'XML' && !item.estoque_lancado"
                      @click="stockEntry(item)"
                    >
                      <v-list-item-icon>
                        <v-icon color="secondary">
                          shelves
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Lançar estoque</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="danfe([item])"
                    >
                      <v-list-item-icon>
                        <v-icon color="primary">
                          print
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Imprimir DANFE</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="['EMITIDA', 'CANCELADA', 'DENEGADA', 'CONTIGENCIA'].includes(item.status)"
                      @click="downloadXML(item)"
                    >
                      <v-list-item-icon>
                        <v-icon color="orange">
                          code
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Download XML</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.status === 'PENDENTE'"
                      @click="authorize(item)"
                    >
                      <v-list-item-icon>
                        <v-icon color="teal">
                          task_alt
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Autorizar no SEFAZ</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="['PROCESSANDO', 'EMITIDA', 'CONTIGENCIA'].includes(item.status)"
                      @click="checkStatus([item.id])"
                    >
                      <v-list-item-icon>
                        <v-icon color="info">
                          info_outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Consultar nota</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.status === 'EMITIDA'"
                      @click="cancel(item)"
                    >
                      <v-list-item-icon>
                        <v-icon color="error">
                          highlight_off
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Cancelar Nota</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="false"
                      @click="remove(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <invoice-dialog
      v-model="invoiceDialog.show"
      :type="type"
      :edit-id="invoiceDialog.id"
      @close="getInvoices()"
    />

    <invoice-auth-dialog
      ref="authDialog"
      @close="getInvoices()"
    />

    <invoice-batch-auth-dialog
      ref="batchAuthDialog"
      @close="getInvoices()"
    />

    <invoice-cancel-dialog
      ref="cancelDialog"
      @close="getInvoices()"
    />

    <invoice-batch-cancel-dialog
      ref="batchCancelDialog"
      @close="getInvoices()"
    />

    <stock-entry-dialog
      ref="stockEntryDialog"
      @save="getInvoices()"
    />

    <invoice-import-dialog
      ref="importDialog"
      @import="editInvoice"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip
        v-if="type !== 'NFPE'"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="addInvoice({id: null})"
            v-on="on"
          >
            <v-icon>
              add
            </v-icon>
          </v-btn>
        </template>
        Nova Nota Fiscal
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel"
            v-on="on"
          >
            <v-icon>
              backup_table
            </v-icon>
          </v-btn>
        </template>
        Download (Excel)
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print()"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir
      </v-tooltip>

      <v-tooltip
        v-if="type == 'ENTRADA'"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="purple"
            @click="importXml"
            v-on="on"
          >
            <v-icon>
              system_update_alt
            </v-icon>
          </v-btn>
        </template>
        Importar XML da NFe
      </v-tooltip>

      <v-tooltip
        v-if="isBatchAuthorizeVisible"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="teal"
            @click="authorizeSelecteds"
            v-on="on"
          >
            <v-icon>
              task_alt
            </v-icon>
          </v-btn>
        </template>
        Autorizar no SEFAZ
      </v-tooltip>

      <v-tooltip
        v-if="isBatchCheckStatusVisible"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="info"
            :style="!isDairySelected ? getDisabledStyle() : {}"
            v-on="on"
            @click="isDairySelected ? checkStatusSelecteds() : null"
          >
            <v-icon>
              info_outline
            </v-icon>
          </v-btn>
        </template>
        <span v-if="!isDairySelected">
          Laticínio não selecionado
        </span>
        <span v-else>
          Consultar notas
        </span>
      </v-tooltip>

      <v-tooltip
        v-if="isBatchCancelVisible"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="error"
            :style="!isDairySelected ? getDisabledStyle() : {}"
            v-on="on"
            @click="isDairySelected ? cancelSelecteds() : null"
          >
            <v-icon>
              highlight_off
            </v-icon>
          </v-btn>
        </template>
        <span v-if="!isDairySelected">
          Laticínio não selecionado
        </span>
        <span v-else>
          Cancelar notas
        </span>
      </v-tooltip>

      <v-tooltip
        v-if="selectedInvoices.length > 0"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange"
            :style="!isDairySelected ? getDisabledStyle() : {}"
            v-on="on"
            @click="isDairySelected ? danfe(selectedInvoices) : null"
          >
            <v-icon>
              receipt_long
            </v-icon>
          </v-btn>
        </template>
        <span v-if="!isDairySelected">
          Laticínio não selecionado
        </span>
        <span v-else>
          Imprimir DANFEs
        </span>
      </v-tooltip>

      <v-tooltip
        v-if="selectedInvoices.length > 0"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="grey darken-1"
            :style="!isDairySelected ? getDisabledStyle() : {}"
            v-on="on"
            @click="isDairySelected ? downloadDANFEs() : null"
          >
            <v-icon>
              folder_zip
            </v-icon>
          </v-btn>
        </template>
        <span v-if="!isDairySelected">
          Laticínio não selecionado
        </span>
        <span v-else>
          Download DANFEs
        </span>
      </v-tooltip>

      <v-tooltip
        v-if="isBatchXmlVisible"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="amber darken-1"
            :style="!isDairySelected ? getDisabledStyle() : {}"
            v-on="on"
            @click="isDairySelected ? downloadXmls() : null"
          >
            <v-icon>
              folder_zip
            </v-icon>
          </v-btn>
        </template>
        <span v-if="!isDairySelected">
          Laticínio não selecionado
        </span>
        <span v-else>
          Download XMLs
        </span>
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
import InvoiceDialog from '@/Domains/Fiscal/Components/InvoiceDialog.vue';
import InvoiceAuthDialog from '@/Domains/Fiscal/Components/InvoiceAuthDialog.vue';
import InvoiceBatchAuthDialog from '@/Domains/Fiscal/Components/InvoiceBatchAuthDialog.vue';
import InvoiceCancelDialog from '@/Domains/Fiscal/Components/InvoiceCancelDialog.vue';
import InvoiceBatchCancelDialog from '@/Domains/Fiscal/Components/InvoiceBatchCancelDialog.vue';
import InvoiceImportDialog from '@/Domains/Fiscal/Components/InvoiceImportDialog.vue';
import StockEntryDialog from '@/Domains/Fiscal/Components/StockEntryDialog.vue';
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'invoice-list',

  components: {
    InvoiceDialog,
    InvoiceAuthDialog,
    InvoiceBatchAuthDialog,
    InvoiceCancelDialog,
    InvoiceBatchCancelDialog,
    InvoiceImportDialog,
    StockEntryDialog,
    VDateRangePicker,
  },

  mixins: [ReportMixin],

  props: {
    type: {
      type: String,
      default: 'ENTRADA',
      validator: (value) => ['ENTRADA', 'SAIDA', 'NFPE'].indexOf(value) !== -1
    },
  },

  data() {
    return {
      tab: null,

      filters: {
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        search: '',
      },

      items: [],
      selectedInvoices: [],

      invoiceDialog: {
        show: false,
        id: null,
      },

    };
  },

  computed: {
    headers() {
      let headers = [
        { text: 'Série', value: 'serie' },
        { text: 'Número', value: 'numero' },
        { text: 'Data emissão', value: 'data_emissao', formatter: value => this.dateFormat(value, 'DD/MM/YYYY') },
      ];

      if (this.type == 'NFPE') {
        headers.push({ text: 'Emitente', value: 'nome_emitente' });
      } else if (this.type == 'ENTRADA') {
        headers.push({ text: 'Remetente', value: 'nome_cliente' });
      } else {
        headers.push({ text: 'Destinatário', value: 'nome_cliente' });
      }

      headers.push(
        { text: 'Chave', value: 'chave' },
        { text: 'UF', value: 'uf' },
        { text: 'Valor', value: 'valor', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Valor Líquido', value: 'valor_liquido', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Status', value: 'status' },
        { text: '', altText: 'Opções', value: 'action', width: 30, sortable: false, drag: false }
      );

      return headers;
    },

    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;

      return this.items.filter(order => {
        const hasSearch = !search || JSON.stringify(Object.values(order)).toUpperCase().includes(search);

        return hasSearch;
      });
    },

    totalByStatus() {
      return this.filteredItems.reduce((acc, cur) => {
        if (!(cur.status in acc)) {
          acc[cur.status] = 0;
        }
        acc[cur.status]++;
        return acc;
      }, {})
    },

    isBatchAuthorizeVisible() {
      return this.selectedInvoices.length > 0 && this.selectedInvoices.some(item => item.status === 'PENDENTE')
    },

    isBatchCheckStatusVisible() {
      return this.selectedInvoices.length > 0 && this.selectedInvoices.some(item => ['PROCESSANDO', 'EMITIDA', 'CONTIGENCIA'].includes(item.status))
    },

    isBatchCancelVisible() {
      return this.selectedInvoices.length > 0 && this.selectedInvoices.some(item => item.status === 'EMITIDA')
    },

    isBatchXmlVisible() {
      return this.selectedInvoices.length > 0 && this.selectedInvoices.some(item => ['EMITIDA', 'CANCELADA', 'DENEGADA', 'CONTIGENCIA'].includes(item.status))
    },

    isDairySelected() {
      return !_.isEmpty(this.$store.state.settings.laticinio)
    },
  },

  methods: {
    addInvoice() {
      this.invoiceDialog.show = true;
      this.invoiceDialog.id = null;
    },

    editInvoice({ id }) {
      this.invoiceDialog.show = true;
      this.invoiceDialog.id = id;
    },

    getItems(status) {
      if (status === 'TODAS') {
        return this.filteredItems;
      }

      return this.filteredItems.filter(item => item.status === status);
    },

    async getInvoices() {
      try {
        this.$root.$progressBar.loading();
        const [startDate, endDate] = this.filters.range;

        const { data } = await this.$axios.get(`/fiscal/invoice`, { params: {
          type: this.type,
          start_date: startDate,
          end_date: endDate
        } });

        this.items = data;
        this.selectedInvoices = [];
      } catch (error) {
        this.$snotify.error('Erro ao carregar as notas', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async danfe(items) {
      try {
        this.$root.$progressBar.loading();

        const ids = items.map(item => item.id);

        const { data } = await this.$axios.post(`/fiscal/invoice/print-danfe`, { ids }, { responseType: 'blob' });

        const dataObject = window.URL.createObjectURL(data);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = (ids.length === 1) ? `${items[0].chave}-nfe.pdf` : `DANFE-${moment().format("YYYYMMDDHHmmss")}.pdf`;
        link.click();

        window.URL.revokeObjectURL(dataObject);
        link.remove();

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async downloadXML(item) {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/fiscal/invoice/${item.id}/xml`, { responseType: 'blob' });

        const dataObject = window.URL.createObjectURL(data);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = `${item.chave}-nfe.xml`;
        link.click();

        window.URL.revokeObjectURL(dataObject);
        link.remove();

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async downloadXmls() {
      try {
        this.$root.$progressBar.loading();

        const ids = this.selectedInvoices
          .filter(item => ['EMITIDA', 'CANCELADA', 'DENEGADA', 'CONTIGENCIA'].includes(item.status))
          .map(item => item.id);

        const { data } = await this.$axios.post(`/fiscal/invoice/download-xml`, { ids }, { responseType: 'blob' });

        const dataObject = window.URL.createObjectURL(data);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = `${moment().format("YYYYMMDDHHmmss")}XMLsNfe.zip`;
        link.click();

        window.URL.revokeObjectURL(dataObject);
        link.remove();

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async downloadDANFEs() {
      try {
        this.$root.$progressBar.loading();

        const ids = this.selectedInvoices.map(item => item.id);

        const { data } = await this.$axios.post(`/fiscal/invoice/download-danfe`, { ids }, { responseType: 'blob' });

        const dataObject = window.URL.createObjectURL(data);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = `${moment().format("YYYYMMDDHHmmss")}PDFsNfe.zip`;
        link.click();

        window.URL.revokeObjectURL(dataObject);
        link.remove();

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover esta nota?', { color: 'red' }))) {
        return;
      }
      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/fiscal/invoice/${id}`);

        this.items.splice(this.items.findIndex(order => order.id == id), 1);
        this.$snotify.success('Registro excluído com sucesso', 'Sucesso');
      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async authorize(item) {
      this.$refs['authDialog'].show({
        id: item.id,
        env: item.ambiente,
        number: item.numero,
        customer: item.nome_cliente,
        key: item.chave,
      });
    },

    authorizeSelecteds() {
      const invoices = this.selectedInvoices
        .filter(item => item.status === 'PENDENTE');
      if (invoices.length === 1) {
        this.authorize(invoices[0]);
      } else if (invoices.length > 0) {
        this.$refs['batchAuthDialog'].show(invoices);
      }
    },

    checkStatusSelecteds() {
      const ids = this.selectedInvoices
        .filter(item => ['PROCESSANDO', 'EMITIDA', 'CONTIGENCIA'].includes(item.status))
        .map(item => item.id);
      this.checkStatus(ids);
    },

    async checkStatus(ids) {
      try {
        this.$root.$progressBar.loading();

        await this.$axios.post(`/fiscal/invoice/status`, {
          ids
        });

      } catch (error) {
        const message = _.get(error, 'response.data.message', error);
        this.$snotify.error(message, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.getInvoices();
      }
    },

    async cancel(item) {
      this.$refs['cancelDialog'].show({
        id: item.id,
        env: item.ambiente,
        number: item.numero,
        customer: item.nome_cliente,
      });
    },

    cancelSelecteds() {
      const invoices = this.selectedInvoices
        .filter(item => item.status === 'EMITIDA');
      if (invoices.length === 1) {
        this.cancel(invoices[0]);
      } else if (invoices.length > 0) {
        this.$refs['batchCancelDialog'].show(invoices);
      }
    },

    async importXml() {
      this.$refs['importDialog'].show();
    },

    stockEntry(item) {
      this.$refs['stockEntryDialog'].show(item.id);
    },

    onTabClick() {
      this.selectedInvoices = [];
    },

    getStatusColor(value) {
      const statusColors = {
        'PENDENTE': 'amber darken-1',
        'PROCESSANDO': 'blue',
        'REJEITADA': 'red',
        'EMITIDA': 'green',
        'CANCELADA': 'grey',
        'DENEGADA': 'red darken-1',
        'CONTIGENCIA': 'teal',
      };
      return statusColors[value];
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const idx = ['TODAS', 'PENDENTE', 'EMITIDA', 'REJEITADA', 'CANCELADA'].findIndex(item => item === (this.tab || 'TODAS').replace('tab-', ''));
      this.$refs.report[idx].exportExcel();
    },

    /**
     * Imprime o relatório
     */

    print() {
      const idx = ['TODAS', 'PENDENTE', 'EMITIDA', 'REJEITADA', 'CANCELADA'].findIndex(item => item === (this.tab || 'TODAS').replace('tab-', ''));
      this.$refs.report[idx].print(null, 'Notas de Produtor (NFP-e)');
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),

    getDisabledStyle() {
      return { background: 'rgba(255, 255, 255, 0.12) !important', cursor: 'not-allowed' }
    },
  },
};
</script>
