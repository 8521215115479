<template>
  <v-container class="report-settings">
    <v-row>
      <v-col cols="12">
        <card-title-settings class="mb-3">
          Parâmetros de Impressão
        </card-title-settings>

        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="2">
                <v-autocomplete
                  v-model="selectedReport"
                  :items="settings"
                  label="Relatório"
                  placeholder=" "
                  item-text="label"
                  item-value="id"
                  :loading="loading"
                  return-object
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="selectedReport"
      dense
      no-gutters
      style="margin: 8px -4px;"
    >
      <v-col
        v-for="(param, key) in selectedReport.params"
        :key="key"
        cols="4"
        class="pa-1"
      >
        <v-card
          dark
          outlined
          class="text-left card-field"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                {{ fields[key] }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-for="(value, key) in param"
                :key="key"
              >
                <v-textarea
                  v-if="isTextArea(key)"
                  v-model="param[key]"
                  :label="fields[key]"
                  filled
                  dense
                  auto-grow
                />
                <v-switch
                  v-else
                  v-model="param[key]"
                  :label="fields[key]"
                  dense
                  hide-details
                  class="mt-0"
                  color="blue accent-3"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          bottom
          color="green mr-5"
          dark
          fab
          fixed
          large
          right
          v-on="on"
          @click.stop="save"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar </span>
    </v-tooltip>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.report-settings {
  .card-field {
    background: rgba(255, 255, 255, 0.08);

    &.disabled {
      opacity: 0.65;
    }

    &.blocked {
      border-style: dashed;
    }
  }
}
</style>

<script>
import _ from "lodash";

import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"

export default {

  components: {
    CardTitleSettings,
  },

  data() {
    return {
      loading: false,

      selectedReport: null,

      fields: [],
      settings: [],
    };
  },

  async mounted() {
    await this.getAllReportSettings();
  },

  methods: {

    async getAllReportSettings() {
      try {
        this.loading = true;

        const [{ data: fields }, { data: settings }] = await Promise.all([
          this.$axios.get(`/settings/reports/labels`),
          this.$axios.get(`/settings/reports`),
        ]);

        this.fields = fields;

        this.settings = settings.map(row => ({
          id: row.id_relatorio,
          label: row.relatorio,
          params: row.configuracao,
        }));

        // Seleciona automaticamente o primeiro resultado
        this.selectedReport = _.first(this.settings);
      } catch (e) {
        this.$snotify.error("Erro ao listar registros", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        this.loading = true;

        if (_.isEmpty(this.selectedReport)) {
          return;
        }

        const id = this.selectedReport.id;

        const payload = {
          relatorio: this.selectedReport.label,
          configuracao: this.selectedReport.params,
        };

        id ? await this.update(id, payload) : await this.store(payload);

      } catch (e) {
        this.$snotify.error("Erro ao salvar as configurações do relatório", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`settings/reports`, payload);
      return data;
    },

    async update(id, payload) {
      const { data } = await this.$axios.put(`/settings/reports/${id}`, payload);
      return data;
    },

    isTextArea: (value) => String(value).startsWith('mensagem'),
  },
};
</script>
