<template>
  <v-dialog
    v-model="input"
    scrollable
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card
      v-if="input"
      class="upload-parent"
    >
      <div
        v-show="uploader && uploader.dropActive"
        class="drop-active"
      >
        <div class="middle">
          <br>
          <v-icon
            :style="{fontSize: '60px'}"
            color="primary"
          >
            cloud_upload
          </v-icon>
          <div class="text-h4 primary--text">
            Solte seus arquivos para enviar
          </div>
        </div>
      </div>
      <v-card-title class="text-h5">
        Upload
      </v-card-title>

      <v-card-text>
        <div class="drop-field">
          <div class="middle">
            Arraste e solte seus arquivos ou
            <br>
            <vue-upload-component
              ref="upload"
              v-model="files"
              v-ripple
              :custom-action="customAction"
              :extensions="extensions"
              :accept="accept"
              :size="size"
              :multiple="true"
              :drop="true"
              :drop-directory="true"
              class="v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--text theme--light v-size--default primary--text"
              @input-file="inputFile"
            >
              <span style="line-height: 2.4em;">
                Faça upload
              </span>
            </vue-upload-component>
          </div>
        </div>

        <div class="text-h6 secondary--text pt-4 pb-2">
          Uploads recentes
        </div>

        <div class="uploaded-files">
          <v-row>
            <v-col
              v-for="file in displayFiles"
              :key="file.id"
              cols="6"
              sm="4"
              md="3"
              lg="2"
            >
              <file-viewer
                :value="file"
                :selectable="file.success"
                @removeFile="onRemoveFile(file)"
                @click="onToggleFile(file)"
              />
            </v-col>
          </v-row>
        </div>
        <br>
        <br>
        <br>
        <div
          contenteditable="true"
          @paste="onPaste"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="selecteds.length > 0"
          color="blue"
          dark
          @click="onInsertFiles"
        >
          {{ `Inserir arquivo${multiselect ? 's' : ''}` }}
        </v-btn>
        <v-btn
          text
          @click="input = false"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
  .upload-parent {
    position: relative;

    .drop-active {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      z-index: 9999;
      text-align: center;
      background: #fff;

      h3 {
        margin: -.5em 0 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 40px;
        color: #fff;
        padding: 0;
      }
    }
  }

  .middle {
    display: block;
    float: left;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .drop-field {
    width: 100%;
    box-sizing: border-box;
    border-color: rgb(207, 212, 219);
    border-style: dashed;
    border-width: 2px;
    border-radius: 3px;
    height: 211px;
    background: #efefef;

    .v-btn {
      background: #ffffff;

      label {
        cursor: pointer;
      }
    }
  }
</style>

<script>
/**
 * VueUploadComponent
 * @docs https://lian-yue.github.io/vue-upload-component/#/en/documents
 */
import VueUploadComponent from 'vue-upload-component';
import Ripple from 'vuetify/lib/directives/ripple';
import FileViewer from "@/Support/Components/FileViewer.vue";

export default {
  name: 'file-upload',

  directives: { Ripple },

  components: {
    VueUploadComponent,
    FileViewer
  },

  props: {
    value: {
      type: Boolean,
    },
    multiselect: {
      type: Boolean,
      default: true
    },
    extensions: {
      type: String,
      default: 'gif,jpe,jpeg,jpg,tiff,png,webp,bmp'
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    size: {
      type: Number,
      default: 1024 * 1024 * 10
    },
  },

  data() {
    return {
      // Model
      input: false,

      uploader: null,

      files: [],

      serverFiles: [],
    }
  },

  computed: {
    selecteds() {
      return this.displayFiles.filter(file => file.selected);
    },
    displayFiles() {
      return this.serverFiles.concat(this.files)
        .map((file, idx) => ({ idx, ...file }))
        .slice()
        .reverse();
    }
  },

  watch: {
    value() {
      this.input = this.value;
      this.$nextTick(() => {
        // Ativa o dropzone
        this.uploader = this.$refs.upload;
      });
    },
    input(value) {
      this.$emit("input", !!value);
    },
  },

  created() {
    this.listFiles();
  },

  methods: {
    /**
     * Action de upload personalizada devido a problema com o CORS
     */
    async customAction(file, component) {
      let speedLoaded = 0;
      const formData = new FormData();
      formData.append('file', file.file);
      const { data } = await this.$axios.post('/arquivos/storageUpload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (e) => {
          file = component.update(file, {
            progress: (e.loaded / e.total * 100).toFixed(2),
            speed: e.loaded - speedLoaded,
          })
          speedLoaded = e.loaded;
        }
      })
      file = component.update(file, {
        response: data
      })
    },
    /**
     * Recupera os arquivos do servidor
     */
    async listFiles() {
      try {
        const { data } = await this.$axios.get(`/arquivos/storage`);
        const extensions = this.extensions.split(',').join('|');
        const fileExtRegex = new RegExp(`\\.(${extensions})`, 'i');

        this.serverFiles = data.files.map(file => ({
          ...file,
          success: true,
          active: false,
          selected: false,
        })).filter(file => fileExtRegex.test(file.url));
      } catch (err) {
        console.warn(err);
      }
    },
    /**
     * Evento para seleção dos arquivos
     */
    onToggleFile(file) {
      let idx = file.idx;
      if (file.active || !file.success) {
        return;
      }
      // Remove a seleção dos outros arquivos
      if (!this.multiselect) {
        // Arquivos recuperados do servidor
        for (let i in this.serverFiles) {
          if (i != idx && this.serverFiles[i].selected) {
            this.serverFiles[i].selected = false;
          }
        }
        // Arquivos upados agora
        let fIdx = idx - this.serverFiles.length;
        for (let i in this.files) {
          if (i != fIdx && this.files[i].selected) {
            this.files[i].selected = false;
          }
        }
      }

      if (idx < this.serverFiles.length) {
        this.serverFiles[idx].selected = !this.serverFiles[idx].selected;
      } else {
        idx -= this.serverFiles.length;
        this.files[idx].selected = !this.files[idx].selected;
      }
    },
    /**
     * Deleta um arquivo
     * @TODO remover arquivo do servidor
     */
    async onRemoveFile(file) {
      let idx = file.idx;
      if (idx < this.serverFiles.length) {
        this.serverFiles[idx].active = true;
        try {
          const fileUrl = this.serverFiles[idx].url;
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.serverFiles.splice(idx, 1);
        } catch (err) {
          console.warn(err);
          this.serverFiles[idx].active = false;
        }
      } else {
        idx -= this.serverFiles.length;
        this.files.splice(idx, 1);
      }
    },
    /**
     * Evento disparado pelo componente de upload
     */
    inputFile(newFile, oldFile) {
      const isNewFile = newFile && !oldFile;
      const isUpdatedFile = newFile && oldFile;
      // const isRemovedFile = !newFile && oldFile;

      // Cria um 'blob' para preview de imagem
      if (isNewFile) {
        newFile.selected = false;
        newFile.url = '';
        if (newFile.type.split("/")[0] == "image") {
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file);
          }
        }
      }

      if (isUpdatedFile) {
        // Arquivo upado para o servidor
        if (newFile.success !== oldFile.success) {
          const file = newFile.response.file;
          for (let i in this.serverFiles) {
            // Mesmo arquivo enviado no mesmo dia,
            // Remove da lista o arquivo duplicado
            if (this.serverFiles[i].url == file.url) {
              this.serverFiles.splice(i, 1);
            }
          }
          this.serverFiles.push({
            ...file,
            success: true,
            active: false,
            selected: false,
          })
          this.$refs.upload.remove(newFile);
        }
      }

      // Upload automático
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    onPaste(e) {
      // console.log(e)
      let dataTransfer = e.clipboardData;
      if (!dataTransfer) {
        return;
      }
      this.$refs.upload.addDataTransfer(dataTransfer);
    },
    /**
     * Emite o evento dos arquivos selecionados
     * e fecha o dialog
     */
    onInsertFiles() {
      this.$emit('insertFiles', this.selecteds);
      this.input = false;
    },
  }

}
</script>
