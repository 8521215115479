<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-card
      dark
      class="v-tamanho route-list"
      color="transparent"
    >
      <v-card-title>
        <v-col
          md-3
          class="pa-0"
        >
          <v-date-range-picker
            v-model="filters.dateRange"
            @change="loadRoutes"
          />
        </v-col>
        <v-col
          md-6
          offset-md="3"
          class="pa-0"
        >
          <v-text-field
            v-model="filters.search"
            label="Pesquisar"
            single-line
            hide-details
            clearable
          />
        </v-col>
      </v-card-title>

      <v-data-table
        :headers="[
          { align: 'start', text: 'Descrição', value: 'description' },
          { text: 'Tempo Previsto', value: 'expectedTime' },
          { text: 'Tempo Realizado', value: 'realizedTime' },
          { text: 'Distância Prevista', value: 'expectedDistance' },
          { text: 'Distância Realizada', value: 'realizedDistance' },
        ]"
        :items="filteredRoutes"
        :loading="onLoading"
        class="elevation-1 lista-rotas-table"
      >
        <template v-slot:item.expectedTime="{ item }">
          {{ item.expectedTime | duration }}
        </template>

        <template v-slot:item.realizedTime="{ item }">
          {{ item.realizedTime | duration }}
        </template>
      </v-data-table>
    </v-card>

    <v-overlay :value="onLoading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<style lang="scss">

.route-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.lista-rotas-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

@media print {
  body,
  * {
    visibility: hidden;
  }

  .print-area,
  * {
    visibility: visible;
  }

  .print-area {
    top: 0;
    position: absolute;
    background: white !important;
    margin: 0;
    height: 100%;
  }
}
</style>

<script>
import _ from "lodash";
import moment from "moment";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import MomentDuration from "moment-duration-format";

MomentDuration(moment);

export default {
  components: { VDateRangePicker },

  filters: {
    duration(value) {
      if (_.isEmpty(value)) {
        return '';
      }

      if (value === '00:00:00') {
        return '';
      }

      return moment.duration(value).format('HH:mm');
    }
  },

  props: {
    type: {
      type: String,
      default: 'COLETA'
    }
  },

  data() {
    return {
      // Loaders
      onLoading: true,

      // Dados brutos do backend
      routes: [],

      // Filtros da tabela
      filters: {
        search: "",
        dateRange: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
    };
  },

  computed: {
    /**
     * Exibe as rotas com base nos filtros selecionados em tela
     */
    filteredRoutes() {
      if (_.isEmpty(this.filters.search)) {
        return this.routes;
      }

      return this.routes.filter(route => {
        return (route.description || "").toUpperCase().includes((this.filters.search || "").toUpperCase());
      });
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = `${this.$options._componentTag}-${this.type}`;
        this.$store.commit("setFilter", { [filterId]: value });
      }
    }
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.loadRoutes();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = `${this.$options._componentTag}-${this.type}`;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = this.$store.state.settings.filters[filterId];
      }
    },
    /**
     * Método responsável por carregar todas as rotas disponíveis para o laticínio
     * @returns {Promise<void>}
     */
    async loadRoutes() {
      try {
        this.onLoading = true;

        const [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.post(
          `/rota/comparativoItinerarios`,
          {
            tipo: this.type,
            dataInicial: startDate,
            dataFinal: endDate,
          }
        );

        if (!_.isObject(data) || data.codigo == 0) {
          throw "PHP Error";
        }

        this.routes = data.map(route => {
          return {
            description: route.descricao_rota,
            expectedTime: route.tempo_previsto,
            realizedTime: route.tempo_realizado,
            expectedDistance: route.distancia_prevista ? parseFloat(route.distancia_prevista).toFixed(1) : null,
            realizedDistance: route.distancia_realizada ? parseFloat(route.distancia_realizada).toFixed(1) : null,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");

        console.log(err);
      } finally {
        this.onLoading = false;
      }
    },
  },
};
</script>
