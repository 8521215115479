<template>
  <v-card
    color="transparent"
    dark
    class="region-resume"
  >
    <v-card-title
      class="text-subtitle-2 pt-2 pb-2"
      style="background: rgba(0, 0, 0, 0.5);"
    >
      <slot name="title">
        Resumo da região
      </slot>

      <v-spacer />

      <slot name="actions" />
    </v-card-title>

    <v-card-text
      class="pa-0"
      style="background: rgba(0, 0, 0, 0.3);"
    >
      <v-row
        class="text-body-2 pt-1 pb-1 ma-0"
        align="center"
        justify="center"
      >
        <slot name="append" />

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          {{ customers.length }} Clientes
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.region-resume > .v-card__text {
  color: white !important;
}
</style>

<script>

export default {

  props: {
    customers: Array,
    textLeft: Boolean,
  },

  computed: {

    textClass() {
      if (this.textLeft) {
        return {
          "text-left": true,
        };
      }

      return {};
    },
  },
};
</script>
