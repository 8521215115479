<template>
  <div
    class="sanity-releases-report mx-5 px-6"
  >
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Relatório de Lançamentos
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <v-autocomplete
          v-model="filter.vaccine"
          :items="vaccinesFilter"
          label="Vacinas"
          prepend-inner-icon="icon-sanidade"
          dark
          filled
          clearable
          hide-details
          @change="filteredData()"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <v-autocomplete
          v-model="filter.exam"
          :items="examsFilter"
          label="Exames"
          prepend-inner-icon="biotech"
          dark
          filled
          clearable
          hide-details
          @change="filteredData()"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>

    </v-row>
    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        {{ `Produtores: ${ dataLength }` }}
      </v-card-title>
      <data-table
        ref="report"
        :headers="headers"
        :items="filteredData()"
        :search="filter.search"
        dark
        show-expand
        item-key="id_pessoa"
        class="elevation-1"
      >

        <template #[`item.brucelose_check`]="{ item }">
          <v-icon
            v-if="item.brucelose[0].quantidade_animais > 0"
            color="green"
          >
            check
          </v-icon>

          <v-icon
            v-if="item.brucelose[0].quantidade_animais <= 0"
            color="red"
          >
            close
          </v-icon>
        </template>

        <template #[`item.tuberculose_check`]="{ item }">
          <v-icon
            v-if="item.tuberculose[0].quantidade_animais > 0"
            color="green"
          >
            check
          </v-icon>

          <v-icon
            v-if="item.tuberculose[0].quantidade_animais <= 0"
            color="red"
          >
            close
          </v-icon>
        </template>

        <template #[`item.vacinas_check`]="{ item }">
          <v-icon
            v-if="item.vacinas.length > 0"
            color="green"
          >
            check
          </v-icon>

          <v-icon
            v-if=" item.vacinas.length <= 0"
            color="red"
          >
            close
          </v-icon>
        </template>

        <template #[`item.atestado_conformidade_check`]="{ item }">
          <v-icon
            v-if="item.atestado_conformidade.length > 0"
            color="green"
          >
            check
          </v-icon>

          <v-icon
            v-if=" item.atestado_conformidade.length <= 0"
            color="red"
          >
            close
          </v-icon>
        </template>

        <template #expanded-item="{ headers, item }"  >
          <td
            :colspan="headers.length"
            class="pa-0 text-center"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <v-data-table
                  :headers="headersBrucelose"
                  :items="item.brucelose[0].data !== '' ? item.brucelose : []"
                  dark
                  class="elevation-1 ma-3"
                  :items-per-page="item.brucelose.length"
                  hide-default-footer
                >
                  <template #[`item.anexos`]="{ item }">
                    <v-menu>
                      <template #activator="{ on }">
                        <v-btn
                          color="green darken-2"
                          x-small
                          v-on="on"
                          :disabled="!item.anexos || (typeof item.anexos === 'object'  && Object.keys(item.anexos).length === 0)"
                        >
                          <v-icon
                            x-small
                            color="yellow"
                          >
                            download
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(anexo, idx) in item.anexos"
                          :key="idx"
                          @click="showAnexos(anexo.url)"
                        >
                          <v-list-item-icon>
                            <v-icon>
                              file_open
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ anexo.name }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>

              <v-col
                cols="6"
              >
                <v-data-table
                  :headers="headersTuberculose"
                  :items="item.tuberculose[0].data !== '' ? item.tuberculose : []"
                  dark
                  class="elevation-1 ma-3"
                  :items-per-page="item.tuberculose.length"
                  hide-default-footer
                >
                  <template #[`item.anexos`]="{ item }">
                    <v-menu>
                      <template #activator="{ on }">
                        <v-btn
                          color="green darken-2"
                          x-small
                          v-on="on"
                          :disabled="!item.anexos || (typeof item.anexos === 'object'  && Object.keys(item.anexos).length === 0)"
                        >
                          <v-icon
                            x-small
                            color="yellow"
                          >
                            download
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(anexo, idx) in item.anexos"
                          :key="idx"
                          @click="showAnexos(anexo.url)"
                        >
                          <v-list-item-icon>
                            <v-icon>
                              file_open
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ anexo.name }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>

              <v-col
                cols="6"
              >
                <v-data-table
                  :headers="headersVaccines"
                  :items="item.vacinas"
                  dark
                  class="elevation-1 ma-3"
                  :items-per-page="item.vacinas.length"
                  hide-default-footer
                >
                  <template #[`item.data`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>

                  <template #[`item.anexos`]="{ item }">
                    <v-menu>
                      <template #activator="{ on }">
                        <v-btn
                          color="green darken-2"
                          x-small
                          v-on="on"
                          :disabled="!item.anexos || (typeof item.anexos === 'object'  && Object.keys(item.anexos).length === 0)"
                        >
                          <v-icon
                            x-small
                            color="yellow"
                          >
                            download
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(anexo, idx) in item.anexos"
                          :key="idx"
                          @click="showAnexos(anexo.url)"
                        >
                          <v-list-item-icon>
                            <v-icon>
                              file_open
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ anexo.name }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>

              <v-col
                cols="6"
              >
                <v-data-table
                  :headers="headersCertificateConformity"
                  :items="item.atestado_conformidade ?? []"
                  dark
                  class="elevation-1 ma-3"
                  :items-per-page="item.atestado_conformidade.length"
                  hide-default-footer
                >
                  <template #[`item.data`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY') }}
                  </template>

                  <template #[`item.totalInventario`]="{ item }">
                    {{ item.totalInventario ? item.totalInventario : 0 }}
                  </template>
                  <template #[`item.anexos`]="{ item }">
                    <v-menu>
                      <template #activator="{ on }">
                        <v-btn
                          color="green darken-2"
                          x-small
                          v-on="on"
                          :disabled="!item.anexos || (typeof item.anexos === 'object'  && Object.keys(item.anexos).length === 0)"
                        >
                          <v-icon
                            x-small
                            color="yellow"
                          >
                            download
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(anexo, idx) in item.anexos"
                          :key="idx"
                          @click="showAnexos(anexo.url)"
                        >
                          <v-list-item-icon>
                            <v-icon>
                              file_open
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ anexo.name }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </td>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="printReport(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    print
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="blue"
        @click="add()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green"
        @click="upload()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              upload
            </v-icon>
          </template>
          Upload Lançamentos
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <add-exams-releases
      v-model="showDialog"
      :edit-id="editId"
      @save="loadReleases"
    />

    <upload-releases-dialog
      v-model="showDialogUpload"
      @save="loadReleases"
    />
  </div>
</template>

<script>
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import AddExamsReleases from "@/Domains/Projects/HealthExamination/Components/AddExamsReleases.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import UploadReleasesDialog from "@/Domains/Projects/HealthExamination/Components/UploadReleasesDialog.vue";

export default {
  name: "report-releases",

  components: {
    AddExamsReleases,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    UploadReleasesDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      showDialog: false,
      showDialogUpload: false,
      editId: null,

      filter: {
        technician: {
          id: "",
          description: ""
        },
        expiration: {
          show: false,
          type: null,
          date: null
        },
        routes: [],
        cooperatives: [],
        search: '',
        exam: null,
        vaccine: null,
        typeExam: null,
        typeComingDue: null,
      },

      statusProducerFilter: [
        { value: 'WITH_PENDING', text: 'Com Pendência' },
        { value: 'WITHOUT_PENDING', text: 'Sem Pendência' },
      ],

      expirationFilter: [
        { value: 'EXPIRED', text: 'Vencido' },
        { value: 'UNEXPIRED', text: 'Não Vencido' },
        { value: 'EXPIRES_UNTIL', text: 'Vencimento até:' },
      ],

      examsFilter: [
        { value: 'WITH_EXAM', text: 'Com Exame' },
        { value: 'WITHOUT_EXAM', text: 'Sem Exame' },
      ],

      vaccinesFilter: [
        { value: 'WITH_VACCINE', text: 'Com Vacina' },
        { value: 'WITHOUT_VACCINE', text: 'Sem Vacina' },
      ],

      data: [],
      dataLength: 0,

      cooperatives: [],
      vaccinesTypes: [],

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome' },
        { text: 'CPF', value: 'cnpj_cpf' },
        { text: 'Cidade', value: 'end_cidade' },
        { text: 'Região', value: 'regiao' },
        { text: 'Brucelose', value: 'brucelose_check' },
        { text: 'Tuberculose', value: 'tuberculose_check' },
        { text: 'Vacinas', value: 'vacinas_check' },
        { text: 'Atestado Conformidade', value: 'atestado_conformidade_check' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
      ],

      headersVaccines: [
        { text: 'Vacina', value: 'tipo' },
        { text: 'Vac. (Data)', value: 'data' },
        { text: 'Vac. (Qtd. Animais)', value: 'quantidade_animais' },
        { text: 'Vac. (Veterinário)', value: 'veterinarian.nome' },
        { text: 'Anexos', altText: 'Fotos', value: 'anexos',  align: 'center' },
      ],

      headersBrucelose: [
        { text: 'Bruc. (Normativa)', value: 'brucelose_normativa' },
        { text: 'Bruc. (Data)', value: 'data' },
        { text: 'Bruc. (Qtd. Animais)', value: 'quantidade_animais' },
        { text: 'Bruc. (Veterinário)', value: 'veterinarian.nome' },
        { text: 'Bruc. (Vencimento)', value: 'data_vencimento' },
        { text: 'Anexos', altText: 'Fotos', value: 'anexos',  align: 'center' },
      ],

      headersTuberculose: [
        { text: 'Tub. (Normativa)', value: 'tuberculose_normativa' },
        { text: 'Tub. (Data)', value: 'data' },
        { text: 'Tub. (Qtd. Animais)', value: 'quantidade_animais' },
        { text: 'Tub. (veterinário)', value: 'veterinarian.nome' },
        { text: 'Tub. (Vencimento)', value: 'data_vencimento' },
        { text: 'Anexos', altText: 'Fotos', value: 'anexos',  align: 'center' },
      ],

      headersCertificateConformity: [
        { text: 'Atest. Vac. (Data)', value: 'data' },
        { text: 'Atest. Vac. (Qtd. Animais)', value: 'quantidade_animais' },
        { text: 'Atest. Vac. (Veterinário)', value: 'veterinarian.nome' },
        { text: 'Atest. Vac.', value: 'tipo' },
        { text: 'Inventário', value: 'totalInventario', align: "center"},
        { text: 'Anexos', altText: 'Fotos', value: 'anexos',  align: 'center' },
      ],
    }
  },

  async mounted() {
    await this.loadReleases();
  },

  methods: {
    onFilter: _.debounce(function () {
      this.loadReleases();
    }, 1000),

    filteredData() {
      let data = _.cloneDeep(this.data);

      switch (this.filter.vaccine) {
        case 'WITH_VACCINE':
          data = this.dataVaccines(data);
          break;
        case 'WITHOUT_VACCINE':
          data = this.dataVaccines(data, false);
          break;
      }

      switch (this.filter.exam) {
        case 'WITH_EXAM':
          data = this.dataExams(data, this.filter.typeExam);
          break;
        case 'WITHOUT_EXAM':
          data = this.dataExams(data, this.filter.typeExam, false);
          break;
      }

      return this.search(this.formatExams(data));
    },

    search(data) {
      var filtered = this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
      this.dataLength = filtered.length;
      return filtered;
    },

    dataVaccines(data, hasVaccine = true) {
      return data.filter(item => hasVaccine ? item['vacinas'].length : (item['vacinas'].length === 0));
    },

    dataExams(data, type, hasExam = true) {
      const exams = (exam = type.toLowerCase()) => hasExam ? data.filter(item => item[exam]) :  data.filter(item => !item[exam]);
      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    async loadReleases() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
            tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            cooperativas: this.filter.cooperatives,
          } });

        const dataProducer = data.map(function(value) {

          if(value.brucelose) {
            value.brucelose_check = value.brucelose.quantidade_animais > 0 ? 'Sim' : 'Não';
          } else {
            value.brucelose_check = 'Não';
          }

          if(value.tuberculose) {
            value.tuberculose_check = value.tuberculose.quantidade_animais > 0 ? 'Sim' : 'Não';
          } else {
            value.tuberculose_check = 'Não';
          }

          if(value.atestado_conformidade) {
            value.atestado_conformidade_check = value.atestado_conformidade.length > 0 ? 'Sim' : 'Não';
          } else {
            value.atestado_conformidade_check = 'Não';
          }

          if(value.vacinas) {
            value.vacinas_check = value.vacinas.length > 0 ? 'Sim' : 'Não';
          } else {
            value.vacinas_check = 'Não';
          }

          if(value.inventario) {
            const inventario = value.inventario.inventario;

            let totalInventario = 0;

            for (let chave in inventario) {
              totalInventario += parseInt(inventario[chave]);
            }
            if (value.atestado_conformidade) {
              value.atestado_conformidade.totalInventario = totalInventario;
            }
            else {
              value.atestado_conformidade = {
                totalInventario: totalInventario
              }
            }
          }
          return value;
        });

        this.data = dataProducer;

        this.dataLength = this.data.length;

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadCooperatives() {
      try {

        const { data } = await this.$axios.post(`/cooperativa/listaJson`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.cooperatives = data.map(item => ({
          id: item.id_cooperativa,
          name: item.nome_cooperativa,
        }));
      } catch (error) {
        console.warn(error);
      }
    },

    formatExams(data) {
      const format = (item, isVaccine = false, brucelose_normativa = '', tuberculose_normativa = '') => {
        const { data, data_vencimento, quantidade_animais, ...rest } = item || {};
        return {
          data: data ? this.formatDate(data, 'DD/MM/YYYY') : (isVaccine ? 'Sem Vacina' : 'Sem Exame'),
          quantidade_animais: quantidade_animais || 0,
          data_vencimento: !isVaccine && data_vencimento ? this.formatDate(data_vencimento, 'DD/MM/YYYY') : null,
          brucelose_normativa: brucelose_normativa,
          tuberculose_normativa: tuberculose_normativa,
          ...rest
        };
      };

      return data.map(({brucelose, tuberculose, vacinas, atestado_conformidade, brucelose_normativa, tuberculose_normativa, ...rest}) => ({
        brucelose: [format(brucelose, false, this.formatDate(brucelose_normativa, 'DD/MM/YYYY'))],
        tuberculose: [format(tuberculose, false, '', this.formatDate(tuberculose_normativa, 'DD/MM/YYYY'))],
        vacinas: vacinas,
        atestado_conformidade: atestado_conformidade,
        brucelose_normativa: this.formatDate(brucelose_normativa, 'DD/MM/YYYY'),
        tuberculose_normativa: this.formatDate(tuberculose_normativa, 'DD/MM/YYYY'),
        ...rest
      }));
    },

    formatDate(value, format) {
      if(!value) {
        return ' - ';
      } else {
        return moment(value).format(format);
      }
    },

    add() {
      this.editId = null;
      this.showDialog = true;
    },

    upload() {
      this.showDialogUpload = true;
    },

    edit(item) {
      this.editId = item.id;

      this.showDialog = true;
    },

    print() {
      this.$refs.report.print(null, "Relatório de Exames Sanitários");
    },

    isObject(variable) {
      return variable !== null && typeof variable === 'object';
    },

    showAnexos(anexos) {
      if (anexos && typeof anexos === 'object') {
        Object.keys(anexos).forEach(function(key) {
          window.open(encodeURI(anexos[key].url), '_blank');
        });
        return;
      }

      window.open(encodeURI(anexos), '_blank');
    },

    async printReport(item) {

      if (this.isObject(item)) {

        const { data } = await this.$axios.post(`/projects/health-exam/print-release`, {
          data: item,
        });

        let fileName = '';

        fileName = `Relatório Sanitário Produtor ${item.nome}.pdf`;

        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();

        return;
      }

      console.log('nao é objeto');
    },
  },
};
</script>
<style >
.sanity-releases-report .v-data-table__wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
