<template>
  <v-dialog
    v-model="show"
    width="30%"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Edição/Cadastro de Serviços</span>
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="form.group"
                  :items="groupServiceList"
                  item-text="nome"
                  item-value="id_grupo_servico"
                  label="Grupo de Serviços *"
                  prepend-inner-icon="design_services"
                  :loading="loading"
                  :rules="isRequired"
                  disabled
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.description"
                  label="Descrição *"
                  :rules="isRequired"
                  autofocus
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="form.associatedSubsidyValue"
                  label="Subsídio Associado *"
                  type="number"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="form.associatedSubsidyTypeValue"
                  label="Tipo"
                  :items="subsidyTypes"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  v-model="form.nonAssociatedSubsidyValue"
                  label="Subsídio Não Associado *"
                  type="number"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <v-select
                  v-model="form.nonAssociatedSubsidyTypeValue"
                  label="Tipo"
                  :items="subsidyTypes"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-switch
                  v-model="form.viewApp"
                  label="Mostrar no App"
                  dense
                  hide-details
                  class="mt-0"
                  color="blue accent-3"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="black--text"
          color="red"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  props: {
    value: Boolean,
    editId: String,
    type: String,
    groupId: String,
  },

  data() {
    return {
      form: {
        description: null,
        associatedSubsidyValue: 0,
        associatedSubsidyTypeValue: '$',
        nonAssociatedSubsidyValue: 0,
        nonAssociatedSubsidyTypeValue: '$',
        viewApp: true,
        group: null,
      },
      groupServiceList: [],
      subsidyTypes: ['$', '%'],
      isRequired: [(v) => !!v || 'Este campo é obrigatório'],
      loading: false,
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();

        this.form = {
          description: null,
          associatedSubsidyValue: 0,
          associatedSubsidyTypeValue: '$',
          nonAssociatedSubsidyValue: 0,
          nonAssociatedSubsidyTypeValue: '$',
          viewApp: true,
          group: null,
        };

        this.getGroupService();

        if (this.editId) {
          this.loadPan();
        }
      }
    }
  },


  methods: {

    async loadPan() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/registrations/service/${this.editId}`);

        this.form = {
          description: data.descricao,
          associatedSubsidyValue: data.associado_valor ?? 0,
          associatedSubsidyTypeValue: data.associado_tipo_valor ?? '$',
          nonAssociatedSubsidyValue: data.nao_associado_valor ?? 0,
          nonAssociatedSubsidyTypeValue: data.nao_associado_tipo_valor ?? '$',
          viewApp: data.mostrar_no_app,
          group: data.id_grupo_servico,
        };
      }
      catch (err) {
        console.warn(err);
        this.close();
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Cria ou edita uma causa no banco de dados
     */
    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_item: this.form.id,
          descricao: this.form.description,
          tipo: this.type,
          associado_valor: this.form.associatedSubsidyValue ?? 0,
          associado_tipo_valor: this.form.associatedSubsidyTypeValue ?? '$',
          nao_associado_valor: this.form.nonAssociatedSubsidyValue ?? 0,
          nao_associado_tipo_valor: this.form.nonAssociatedSubsidyTypeValue ?? '$',
          mostrar_no_app: this.form.viewApp,
          id_grupo_servico: this.form.group,
        };

        const response = this.editId ? await this.update(payload) : await this.store(payload);

        const editId = this.editId || response.id;

        this.$emit("update:editId", editId);
        this.$emit('save', editId);

        this.$snotify.success("Serviço cadastrado com sucesso", "Sucesso");

        this.close();

      } catch (e) {

        this.$snotify.error("Erro ao cadastrar serviço", "Atenção");

        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getGroupService() {
      try {
        this.$root.$progressBar.loading();

        this.form.group = this.groupId;

        console.log(this.groupId);
        console.log(this.type);

        const { data } = await this.$axios.get('registrations/service-group', { params: {
            tipo: this.type,
          }
        });

        this.groupServiceList = data;
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar Grupo de Serviço!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/registrations/service`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/registrations/service/${this.editId}`, payload);
      return data;
    },

    close() {
      this.show = false;
    }
  }

}
</script>
