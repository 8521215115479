<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Fechamento de Contas - Produtor
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <div>
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="2"
            >
              <v-menu
                ref="menu"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="dateRefFilter"
                    label="Mês de Referência"
                    prepend-inner-icon="event"
                    readonly
                    filled
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filters.date"
                  :max="maxDateRef"
                  type="month"
                  no-title
                  scrollable
                  @change="loadItems"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="5"
              lg="3"
            >
              <v-autocomplete
                v-model="filters.type"
                label="Tipo"
                placeholder="Selecione um Tipo"
                :items="typeList"
                item-text="type"
                item-value="id"
                hide-details
                filled
                clearable
                @change="loadItems"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon v-if="item.id === 'VISITA'"  style="color: #444444;">roofing</v-icon>
                      <v-icon v-if="item.id === 'AGRONOMICO'" color="green">front_loader</v-icon>
                      <v-icon v-if="item.id === 'VETERINARIO'" style="color: #FFA500;">pets</v-icon>
                      {{ item.type }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="5"
              lg="3"
            >
              <person-autocomplete-filter
                v-model="filters.producer"
                type="PRODUCER"
                prepend-inner-icon="person"
                hide-details
                label="Produtor"
                filled
                clearable
                @change="loadItems"
              />
            </v-col>
            <v-col
              cols="12"
              md="5"
              lg="3"
            >
              <person-autocomplete-filter
                v-model="filters.technical"
                type="TECHNICAL"
                prepend-inner-icon="person"
                hide-details
                label="Técnico"
                filled
                clearable
                @change="loadItems"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="closingList"
          :search="filters.search"
          show-expand
          item-key=producerId
          class="elevation-1"
        >
          <template #expanded-item="{ headers, item }"  >
            <td
              :colspan="headers.length"
              class="pa-0 text-center"
            >
              <v-data-table
                :headers="headersService"
                :items="item.services"
                :search="filters.search"
                :loading="loading"
                :items-per-page="item.services.length"
                hide-default-footer
                class="elevation-1 ma-3"
                style="white-space: nowrap"
                item-key="idCustomerService"
              >
                <template #[`item.service`]="{ value }">
                  <v-icon v-if="value.type === 'VISITA' " style="color: #444444;">roofing</v-icon>
                  <v-icon v-if="value.type === 'AGRONOMICO' " color="green">front_loader</v-icon>
                  <v-icon v-if="value.type === 'VETERINARIO' " style="color: #FFA500;">pets</v-icon>
                  {{ value.description }}
                </template>

                <template #[`item.producer`]="{ value }">
                  <v-icon color="green">boy</v-icon> {{ value }}
                </template>

                <template #[`item.date`]="{ value }">
                  {{ formatDate(value, 'DD/MM/YYYY')}}
                </template>

                <template #[`item.km`]="{ value }">
                  {{ formatNumber(value) }}
                </template>

                <template #[`item.kmPrice`]="{ value }">
                  {{ formatNumber(value) }}
                </template>

                <template #[`item.visitPrice`]="{ value }">
                  {{ formatNumber(value) }}
                </template>

                <template #[`item.qty`]="{ value }">
                  {{ formatInteger(value) }}
                </template>

                <template #[`item.totalPrice`]="{ value }">
                  {{ formatNumber(value) }}
                </template>
              </v-data-table>
            </td>
          </template>

          <template #[`item.producer`]="{ value }">
            <v-icon color="yellow">face</v-icon> {{ value }}
          </template>

          <template #[`item.totalKm`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.totalVisit`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.totalSubsidio`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.totalPayment`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.discountMilk`]="{ item }">
            <v-switch
              :input-value="item.discountMilk"
              :id="item.producerId"
              label=""
              dense
              hide-details
              class="mt-0"
              color="green accent-3"
              @change="updateDiscountMilk(item.producerId, $event)"
            />
          </template>

        </v-data-table>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import _, { isNull } from "lodash";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

export default {
  components: {
    PersonAutocompleteFilter,
  },

  filters: {
    uppercase(value) {
      return (value || "").toUpperCase();
    },

    date(value) {
      if (_.isEmpty(value)) {
        return;
      }

      return moment(value).format('DD/MM/YYYY');
    }
  },

  data() {

    return {

      loading: false,

      typeList: [
        {
          id: 'VISITA',
          type: 'VISITAS DEFINIDAS'
        },
        {
          id: 'AGRONOMICO',
          type: 'SERVIÇOS AGRONÔMICOS'
        },
        {
          id: 'VETERINARIO',
          type: 'SERVIÇOS VETERINÁRIOS'
        },
      ],

      filters: {
        search: null,
        type: null,
        technical: null,
        producer: null,
        date: moment().subtract(0, 'month').format('YYYY-MM'),
        dateRange: [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD")
        ],
      },

      closingList: [],

      dateRanges: {
        'Hoje': [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD")
        ],
        'Ontem': [
          moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
          moment().subtract(1, "day").endOf("day").format("YYYY-MM-DD")
        ],
        'Este Mês': [
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().endOf("month").format("YYYY-MM-DD")
        ],
        'Mês Anterior': [
          moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")
        ],
        'Este Ano': [
          moment().startOf('year').format('YYYY-MM-DD'),
          moment().endOf('year').format('YYYY-MM-DD')
        ],
      },
    };
  },

  computed: {
    maxDateRef() {
      return moment().format('YYYY-MM');
    },

    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, 'YYYY-MM').format('MM/YYYY');
    },

    headers() {
      return [
        { text: 'Produtor', value: 'producer', align: 'start' },
        { text: 'N Total de visitas', value: 'totalVisits', align: 'start' },
        { text: 'Km Total', value: 'totalKm', align: 'start' },
        { text: 'Total Visita R$', value: 'totalVisit', align: 'start' },
        { text: 'Total Subsídio R$', value: 'totalSubsidio', align: 'start' },
        { text: 'Total a pagar R$', value: 'totalPayment', align: 'start' },
        { text: 'Desconto Leite', value: 'discountMilk', align: 'start' },
      ];
    },

    headersService() {
      return [
        { text: 'Visita / Serviço', value: 'service', align: 'start' },
        { text: 'Data', value: 'date', align: 'start' },
        { text: 'Técnico', value: 'technical', align: 'start' },
        { text: 'Km', value: 'km', align: 'start' },
        { text: 'Valor Km R$', value: 'kmPrice', align: 'start' },
        { text: 'Valor Visita R$', value: 'visitPrice', align: 'start' },
        { text: 'Quantidade', value: 'qty', align: 'start' },
        { text: 'Valor Total R$', value: 'totalPrice', align: 'start' },
      ];
    },
  },

  mounted() {
    this.loadItems();
  },

  methods: {

    async updateDiscountMilk(producerId, event) {
      try {
        this.$root.$progressBar.loading();
        console.log(event);
        const payload = {
          producerId: producerId,
          discountMilk: event,
        }

        const { data } = await this.$axios.post(`financial/discount-milk`, payload);
        return data;

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar serviços!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('financial/account-closing-producer', {
          params: {
            month: this.filters.date,
            type: this.filters.type,
            technical: this.filters.technical,
            producer:this.filters.producer,
          }
        });

        this.closingList = data.map(item => {

          let totalPayment = 0;
          let totalSubsidio = 0;

          if (isNull(item.cooperativa)) {
            if (item.nao_associado_tipo_valor === '$') {
              totalSubsidio = item.nao_associado_valor;
              totalPayment = item.valor_total - totalSubsidio;
            } else {
              totalSubsidio = ((item.valor_total * item.nao_associado_valor)/100);
              totalPayment = item.valor_total - totalSubsidio;
            }
          } else {
            if (item.associado_tipo_valor === '$') {
              totalSubsidio = item.associado_valor;
              totalPayment = item.valor_total - totalSubsidio;
            } else {
              totalSubsidio = ((item.valor_total * item.associado_valor)/100);
              totalPayment = item.valor_total -totalSubsidio;
            }
          }

          if (totalPayment < 0 ) {
            totalPayment = 0;
          }

          return {
            producerId: item.id_produtor,
            producer: item.nome,
            totalVisits: item.total_visitas,
            totalKm: item.km_percorrido,
            totalBonus: 0,
            totalSubsidio: totalSubsidio,
            totalVisit: item.valor_total,
            totalPayment: totalPayment,
            discountMilk: Boolean(item.descontar_no_leite),
            services: item.services.map(service => {
              return {
                idCustomerService: service.id_atendimento,
                idService: service.id_servico,
                service: {
                 type: service.tipo,
                 description: service.descricao_servico,
                },
                date: service.data_hora_cadastro,
                idProducer: service.id_produtor,
                producer: service.nome,
                technical: service.nome_pessoa_registro,
                km: service.km_percorrido,
                kmPrice: service.valor_km,
                visitPrice: service.valor_visita,
                qty: service.quantidade_servico,
                totalPrice: service.valor_total,
              }
            }),
          }
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar serviços!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value),
    formatInteger: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value),
  },
}
</script>

<style scoped>

</style>
