<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-container>
      <v-card
        class="list-collections"
        color="transparent"
      >
        <v-card-title>
          <v-col
            cols="4"
            class="pl-0 pt-0"
          >
            <date-range-picker
              v-model="filters.date.input"
              @change="onDateFilter"
            />
          </v-col>
          <v-col
            cols="4"
            class="pt-0"
          >
            <routes-autocomplete-filter
              v-model="filters.route"
              label="Rota"
              :dark="true"
              @change="refresh"
            />
          </v-col>
        </v-card-title>
        <v-data-table
          class="list-collections-table"
          :headers="[
            { text:'Código', value:'producer.code'},
            { text:'Produtor', value:'producer.name'},
            { text:'Data', value:'date', align: 'center' },
            { text:'Volume (L)', value:'vol', width:150, align:'center'},
          ]"
          group-by="route"
          :items="items"
          disable-pagination
          hide-default-footer
          dark
        >
          <template v-slot:group.header="{ group, items, headers, isOpen, toggle }">
            <td :colspan="headers.length">
              <v-layout
                row
                class="pa-0 align-center"
              >
                <v-flex class="pa-0 text-left">
                  <v-btn
                    icon
                    dark
                    @click="toggle"
                  >
                    <v-icon v-if="isOpen">
                      remove
                    </v-icon>
                    <v-icon v-else>
                      add
                    </v-icon>
                  </v-btn>
                  {{ group }}
                </v-flex>
                <v-flex class="pa-0 text-right">
                  <v-chip
                    small
                    v-text="sumCollects(items)"
                  />
                </v-flex>
              </v-layout>
            </td>
          </template>

          <template v-slot:item.date="{ item }">
            <v-chip
              x-small
            >
              {{ parseDate(item.date) }}
            </v-chip>
          </template>

          <template v-slot:item.vol="{ item }">
            <v-chip
              x-small
            >
              {{ item.vol }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="onReportAllRoutesPrint"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<style lang="scss">
.list-collections > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.list-collections-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.2);
    }

    tbody {
      background: rgba(255, 255, 255, 0.15);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }

      .v-row-group__header {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}
</style>

<script>
import _ from "lodash";
import moment from "moment";

import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import printJS from "print-js";

export default {

  components: {
    RoutesAutocompleteFilter,
    DateRangePicker,
  },

  data() {
    return {
      // Loader
      loading: false,

      filters: {
        date: {
          input: 'today',
          range: [],
        },
        route: {},
      },

      items: [],
    };
  },

  async mounted() {
    await this.loadItems();
  },

  methods: {

    async loadItems() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/listaRotaVolume`,
          this.$qs.stringify({
            data_inicio: _.first(this.filters.date.range) || moment().format("YYYY-MM-DD"),
            data_fim: _.last(this.filters.date.range) || moment().format("YYYY-MM-DD"),
            id_rota: this.filters.route ? this.filters.route.id : null
          }),
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data.map(row => {
          return {
            producer: {
              id: row.id_produtor,
              name: row.nome_produtor,
              code: row.codigo_produtor,
            },
            driver: {
              name: row.nome_usuario_coleta,
            },
            date: row.data_hora_descarga,
            vol: parseInt(row.quantidade_coleta) || 0,
            route: row.rota_produtor,
          };
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event

      return this.refresh();
    },

    refresh() {
      return this.loadItems();
    },

    parseDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    },

    sumCollects(collects) {
      return _.sumBy(collects, 'vol');
    },

    async onReportAllRoutesPrint() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/rotaVolumeImpressao`,
          this.$qs.stringify({
            data_inicio: _.first(this.filters.date.range) || moment().format("YYYY-MM-DD"),
            data_fim: _.last(this.filters.date.range) || moment().format("YYYY-MM-DD"),
            id_rota: this.filters.route ? this.filters.route.id : null,
            tipo: 'general',
          })
        );

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir o itinerario!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

  },

}
</script>
