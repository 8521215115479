<template>
  <div class="mx-6 mb-6">
    <questions-card
      :label="questionnaireName"
    >
      <v-row>
        <v-col
          cols="4"
          class="py-0"
        >
          <v-tabs
            v-model="tab"
            dark
            background-color="transparent"
            class="questionnaire-tabs transparent"
          >
            <v-tab href="#answered-tab">
              <v-icon class="mr-2">
                check_circle_outline
              </v-icon>
              Respondidos
            </v-tab>
            <v-tab
              v-if="!project"
              href="#not-answered-tab">
              <v-icon class="mr-2">
                error_outline
              </v-icon>
              Não respondidos
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col
          cols="4"
          class="py-0 white--text"
        >
          <h2>
            {{ filters.answered.date.range[0] ? formatDate(filters.answered.date.range[0], 'DD/MM/YYYY') + ' a ': '' }}
            {{ filters.answered.date.range[1] ? formatDate(filters.answered.date.range[1], 'DD/MM/YYYY'): '' }}
          </h2>
        </v-col>
        <v-col
          cols="4"
          class="py-0"
        >
          <v-tabs
            v-if="tab === 'answered-tab'"
            v-model="answeredFilter"
            dark
            background-color="transparent"
            class="questionnaire-tabs transparent"
            right
          >
            <v-tab href="#all">
              <v-icon class="mr-2">
                grading
              </v-icon>
              Todas as Respostas
            </v-tab>
            <v-tab href="#last-producer-answer">
              <v-icon class="mr-2">
                rule
              </v-icon>
              Última Resposta
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-tabs-items v-model="tab">
        <v-tab-item value="answered-tab">
          <v-card
            class="producer-list"
            color="transparent"
          >
            <v-card-title class="pb-0">
              <v-row
                class="pa-0"
                align-content="center"
              >
                <v-col
                  md="2"
                  class="pb-0"
                >
                  <date-range-picker
                    v-model="filters.answered.date.input"
                    @change="onDateFilter"
                  />
                </v-col>
                <v-col
                  md="2"
                  class="pb-0"
                >
                  <routes-autocomplete-filter
                    v-model="filters.answered.routes"
                    label="Rota"
                    type="visita"
                    placeholder=" "
                    small-chips
                    multiple
                    dark
                  />
                </v-col>
                <v-col
                  md="2"
                  class="pb-0"
                >
                  <person-autocomplete-filter
                    v-model="filters.answered.technical"
                    label="Técnico"
                    type="TECHNICAL"
                    dark
                    @change="loadProducerwithAnswer"
                  />
                </v-col>
                <v-col
                  md="2"
                  class="pb-0"
                >
                  <v-select
                    v-model="producerStatus"
                    dark
                    filled
                    item-text="text"
                    item-value="value"
                    return-object
                    label="Status Produtor"
                    :items="[
                      {text:'Todos',value:'*'},
                      {text:'Ativos',value:'ativo'},
                      {text:'Suspenso',value:'suspenso'},
                      {text:'Prospectado',value:'prospectado'},
                      {text:'Desvinculado',value:'desvinculado'},
                    ]"
                    multiple
                    @change="loadProducerwithAnswer"
                  />
                </v-col>
                <v-col
                  md="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="filters.answered.search"
                    filled
                    label="Buscar"
                    append-icon="search"
                    single-line
                    hide-details
                    clearable
                    dark
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              v-model="selected"
              class="producer-list-table"
              dark
              :items="filteredAnsweredList"
              show-select
              :headers="[
                { text: 'Código', value: 'producer.code', width: 100 },
                { text: 'Produtor', value: 'producer.name' },
                { text: 'Status', value: 'producer.status' },
                { text: 'Rotas', value: 'routes' },
                { text: 'Técnicos', value: 'technical.name' },
                { text: 'Questionário', value: 'questionnaire' },
                { text: 'Preenchimento', value: 'responseAt', width: '150' },
                { text: '', value: 'actions', sortable: false, align: 'end', width: 20 },
              ]"
            >
              <template #[`item.responseAt`]="{ value }">
                <v-chip x-small>
                  {{ formatDate(value, 'DD/MM/YYYY') }}
                </v-chip>
              </template>

              <template #[`item.routes`]="{ item }">
                <v-chip
                  v-for="route in item.routes"
                  :key="route.id"
                  x-small
                >
                  {{ route.description }}
                </v-chip>
              </template>
              <template #[`item.questionnaire`]="{ item }">
                <v-menu>
                  <template #activator="{ on }">
                    <v-btn
                      color="orange lighten-3"
                      dark
                      icon
                      v-on="on"
                      @click="showQuestionnaire(item)"
                    >
                      <v-icon>receipt_long</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-menu
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="hasDeleteAnswersAccess"
                      small
                      @click="deleteAnswerShowDialog(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <div
              class="text-caption text-left ml-4"
              style="margin-top: -58px; height: 58px; line-height: 58px;"
            >
              <v-col
                md="4"
                align-self="center"
                class="event pa-0"
              >
                <transition name="my-transition">
                  <v-btn
                    v-show="selected.length > 0"
                    transition="my-transition"
                    fab
                    text
                    class="pa-0 ma-0 btn-event"
                    style="margin-left: -16px !important;"
                    dark
                    @click="showPrintDialog"
                  >
                    <v-icon>get_app</v-icon>
                  </v-btn>
                </transition>
              </v-col>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item value="not-answered-tab">
          <v-card
            class="producer-list"
            color="transparent"
          >
            <v-card-title class="pb-0">
              <v-row
                class="pa-0"
                align-content="center"
              >
                <v-col md="3">
                  <v-select
                    v-model="filters.notAnswered.producer.type"
                    label="Status Produtor"
                    prepend-inner-icon="person"
                    single-line
                    :items="[
                      {text:'Todos',value:'*'},
                      {text:'Ativos',value:'ATIVO'},
                      {text:'Prospecção',value:'PROSPECCAO'},
                      {text:'Desvinculados',value:'DESVINCULADO'},
                      {text:'Suspenso',value:'SUSPENSO'},
                    ]"
                    filled
                    hide-details
                    clearable
                    dark
                    @change="loadProducerNotAnswer"
                  />
                </v-col>
                <v-col
                  md="3"
                  class="pb-0"
                >
                  <routes-autocomplete-filter
                    v-model="filters.notAnswered.routes"
                    label="Rota"
                    type="visita"
                    placeholder=" "
                    small-chips
                    multiple
                    dark
                  />
                </v-col>
                <v-spacer />
                <v-col md="3">
                  <v-text-field
                    v-model="filters.notAnswered.search"
                    label="Buscar"
                    append-icon="search"
                    single-line
                    hide-details
                    clearable
                    dark
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              class="producer-list-table"
              dark
              :items="filteredNotAnsweredList"
              :headers="[
                { text: 'Código', value: 'producer.code', width: 100 },
                { text: 'Produtor', value: 'producer.name' },
                { text: 'Status', value: 'producer.status' },
                { text: 'Rotas', value: 'routes' },
              ]"
            >
              <template #[`item.routes`]="{ item }">
                <v-chip
                  v-for="route in item.routes"
                  :key="route.id"
                  x-small
                >
                  {{ route.description }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-overlay
        :value="loading"
        absolute
      >
        Carregando...
      </v-overlay>
    </questions-card>

    <print-settings-dialog
      ref="print-settings"
      @print="print"
    />

    <questionnaire-answers-dialog
      ref="questionnaireAnswersDialog"
    />

    <v-fab-transition>
      <v-btn
        v-show="tab === 'answered-tab' && selected.length > 0"
        color="yellow darken-4"
        fixed
        fab
        large
        dark
        bottom
        right
        class="mr-2"
        :style="{ marginBottom: '80px' }"
        @click="showPrintDialog"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              get_app
            </v-icon>
          </template>
          Imprimir selecionados
        </v-tooltip>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn
        color="green darken-1"
        fixed
        fab
        large
        dark
        bottom
        right
        class="mr-2"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Exportar
        </v-tooltip>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="deleteAnswersDialog.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span>Deseja realmente excluir esta resposta?</span>
        </v-card-title>
        <v-divider />
        <v-card-text
          class="pt-5"
          style="max-height: calc(100vh - 250px);"
        >
          <v-form
            ref="justifyForm"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="deleteAnswersDialog.motivo"
                  filled
                  label="Motivo da exclusão"
                  auto-grow
                  counter="100"
                  :rules="[
                    rules.required,
                    rules.length(25),
                  ]"
                  class="counter-success"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="cancelAnswerShowDialog"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAnswer"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">

.my-transition-enter {
  opacity: 0;
}

.my-transition-enter-active {
  transition: opacity 0.1s;
}

.my-transition-leave-active {
  transition: opacity 0.1s;
  opacity: 0;
}

.event {
  display: flex;
  justify-content: flex-start;
  transition: opacity 0.5s linear;
}

.btn-event {
  transition: opacity 0.5s linear;
}

.producer-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
  padding-top: 0px;
}

.producer-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-table__selected {
    background: rgba(0, 0, 0, 0) !important;
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

.v-data-footer {
  padding: 0px !important;
}

.questionnaire-tabs {
  width: auto !important;
}
</style>

<script>
import moment from "moment-timezone";
import printJS from "print-js";
import * as _ from "lodash";

import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import QuestionsCard from "@/Domains/Questionnaires/Components/QuestionsCard.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";
import QuestionnaireAnswersDialog from '@/Domains/Questionnaires/Components/QuestionnaireAnswersDialog.vue';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    DateRangePicker,
    QuestionsCard,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
    PrintSettingsDialog,
    QuestionnaireAnswersDialog,
  },

  mixins: [ReportMixin],

  props: {
    project: {
      type: String,
    },
    questionnaireId: {
      type: String,
    },
    questionnaireName: {
      type: String,
    },

    questionnaireDateStart: {
      type: String,
      default: null
    },

    questionnaireDateEnd: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      filters: {
        answered: {
          search: "",

          date: {
            input: "",
            range: [],
          },

          routes: [],

          technical: {},
        },

        notAnswered: {
          search: "",
          date: {
            input: "",
            range: [],
          },
          producer: {
            type: '*',
          },
          routes: []
        },
      },

      producers: {
        answered: [],
        notAnswered: [],
      },

      loading: false,

      selected: [],

      producerStatus: [],

      answeredFilter: 'all',

      tab: null,

      deleteAnswersDialog: {
        show: false,
        motivo: '',
      },
      rules: {
        required: v => !!v || 'Campo obrigatório!',
        dateTime: v =>  !v || !!v && v.length == 16 && this.dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!',
        length: len => v => (v || '').length >= len || 'Justificativa muito curta',
      },

      itemDeleted: [],
    };
  },

  computed: {

    filteredAnsweredList() {
      if (_.isEmpty(this.filters.answered.routes) && _.isEmpty(this.filters.answered.search)) {
        return this.producers.answered;
      }

      const search = typeof this.filters.answered.search === 'string' ? this.filters.answered.search.toUpperCase().trim() : null;

      return this.producers.answered.filter(producer => {
        const hasRoute = _.isEmpty(this.filters.answered.routes) || producer.routes.some(producerRoute => {
          return this.filters.answered.routes.some(filterRoute => filterRoute.id === producerRoute.id)
        });
        const hasSearch = !search || JSON.stringify(Object.values(producer)).toUpperCase().includes(search);

        return hasRoute && hasSearch;
      });
    },

    filteredNotAnsweredList() {
      if (_.isEmpty(this.filters.notAnswered.routes) && _.isEmpty(this.filters.notAnswered.search)) {
        return this.producers.notAnswered;
      }

      const search = typeof this.filters.notAnswered.search === 'string' ? this.filters.notAnswered.search.toUpperCase().trim() : null;

      return this.producers.notAnswered.filter(producer => {
        const hasRoute = _.isEmpty(this.filters.notAnswered.routes) || producer.routes.some(producerRoute => {
          return this.filters.notAnswered.routes.some(filterRoute => filterRoute.id === producerRoute.id)
        });
        const hasSearch = !search || JSON.stringify(Object.values(producer)).toUpperCase().includes(search);

        return hasRoute && hasSearch;
      });
    },
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasDeleteAnswersAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'delete-answers-report' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    async answeredFilter() {
      await this.loadProducerwithAnswer();
    }
  },

  mounted() {
    if (this.questionnaireDateStart) {
      this.filters.answered.date.range = [this.questionnaireDateStart, this.questionnaireDateEnd];
      this.producerStatus = [
        { text: 'Ativos', value: 'ativo' },
        { text: 'Suspenso', value: 'suspenso' },
      ];
      this.answeredFilter = 'last-producer-answer';
    } else {
      this.loadProducerwithAnswer();
    }
    this.loadProducerNotAnswer();
  },

  methods: {
    /**
     * Carrega os produtores que respoderam o questionário
     */
    async loadProducerwithAnswer() {
      try {
        this.loading = true;

        let dateFirst = null;
        let dateEnd = null;
        if (this.questionnaireDateStart && _.isEmpty(this.filters.answered.date.range)) {
          dateFirst = this.questionnaireDateStart;
          dateEnd = this.questionnaireDateEnd;
        } else if (!_.isEmpty(this.filters.answered.date.range)) {
          dateFirst = _.head(this.filters.answered.date.range);
          dateEnd = _.last(this.filters.answered.date.range) ;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaProduresFormulario`,
          {
            id_formulario: this.questionnaireId,
            data_inicio: dateFirst || null,
            data_fim: dateEnd || null,
            produtor_status: this.producerStatus ? _.mapValues(this.producerStatus, 'value') : '*',
            id_tecnico: this.filters.answered.technical ? this.filters.answered.technical.id : null,
            agrupa_respostas: this.answeredFilter !== 'all',
            projeto: this.project || null,
          }
        );

        this.producers.answered = data.map(item => {
          return {
            id: item.id_formulario_customizado_cabecalho,
            producer: {
              id: item.id_produtor,
              code: item.codigo_laticinio,
              name: item.nome_produtor,
              status: item.status === 'APROVADO' ? 'ATIVO' : item.status,
            },
            responseAt: item.data_cadastro_app,
            routes: (item.rotas || []).map(route => ({
              id: route.id_rota,
              description: route.descricao,
            })),
            technical: {
              id: item.id_tecnico,
              name: item.nome_tecnico
            },
          };
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Carrega os produtores que não respoderam o questionário
     */
    async loadProducerNotAnswer() {
      try {
        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaNaoRespondidosProdures`,
          this.$qs.stringify({
            id_formulario: this.questionnaireId,
            tipo_produtor: _.get(this.filters, 'notAnswered.producer.type'),
            projeto: this.project || null,
          })
        );

        this.producers.notAnswered = data.map(item => {
          return {
            producer: {
              id: item.id_pessoa,
              code: item.codigo_laticinio,
              name: item.nome,
              status: item.status,
            },
            routes: (item.rotas || []).map(route => ({
              id: route.id_rota,
              description: route.descricao,
            })),
          };
        });
      } catch (error) {
        console.warn(error);
      }
    },

    showPrintDialog() {
      this.$refs['print-settings'].show({
        params: [
          { title: 'Faixa (Curva ABC)', key: 'mostra_curva_abc', value: true },
          { title: 'Resultados Qualidade', key: 'mostra_resultados_qualidade', value: true },
          { title: 'Médias Qualidade', key: 'mostra_medias_qualidade', value: true },
          { title: 'Coletas de amostras', key: 'mostra_coleta_amostras', value: true },
        ]
      });
    },

    async print({ params }) {
      try {
        this.loading = true;

        if (_.isEmpty(this.selected)) {
          throw "Selecione os produtores";
        }

        const answersIds = _.map(this.selected, item => item.id);

        const { data } = await this.$axios.post(
          `/formularioCustomizado/impressaoRespotasFormulario`,
          {
            id_formulario: this.questionnaireId,
            id_respostas: answersIds,
            params,
            data_ini: !_.isEmpty(this.filters.answered.date.range) ? _.head(this.filters.answered.date.range) : null,
            data_fim: !_.isEmpty(this.filters.answered.date.range) ? _.last(this.filters.answered.date.range) : null,
          }
        );

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.answered.date.range = event;

      this.loadProducerwithAnswer();
    },

    exportExcel() {
      if (this.tab === 'not-answered-tab') {
        const report = this.filteredNotAnsweredList.map(({ producer, routes, responseAt }) => ({
          Código: producer.code,
          Produtor: producer.name,
          Rotas: routes.map(({ description }) => description).join(', '),
          Preenchimento: this.formatDate(responseAt, 'DD/MM/YYYY'),
        }));

        const title = `Produtores não respondidos`;

        this.exportToFile({ report, title });
      }
      else {
        const report = this.filteredAnsweredList.map(({ producer, routes, responseAt, technical }) => ({
          Código: producer.code,
          Produtor: producer.name,
          Rotas: routes.map(({ description }) => description).join(', '),
          Técnico: technical.name,
          Preenchimento: this.formatDate(responseAt, 'DD/MM/YYYY'),
        }));

        const title = `Produtores respondidos`;

        this.exportToFile({ report, title });
      }
    },
    deleteAnswerShowDialog(item) {
      this.itemDeleted = item;
      this.deleteAnswersDialog.show = true;
      return true;
    },
    cancelAnswerShowDialog() {
      this.itemDeleted = [];
      this.deleteAnswersDialog.motivo = '';
      this.deleteAnswersDialog.show = false;
      return true;
    },
    async deleteAnswer() {
      try {
        this.deleteAnswersDialog.show = false;
        this.loading = true;

        this.itemDeleted.justification = this.deleteAnswersDialog.motivo

        await this.$axios.post(`/formularioCustomizado/excluiRespostasFormulario`, {
          item: this.itemDeleted,
        });

        this.$snotify.success('Resposta excluida com suscesso', 'Sucesso');
        this.loadProducerwithAnswer();
        this.itemDeleted = [];

      } catch (error) {
        this.$snotify.error('Erro ao excluir resposta', 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    showQuestionnaire(item) {
      return this.$refs.questionnaireAnswersDialog.show({
        id: item.id,
      });
    },
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
