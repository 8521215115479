<template>
  <div>
    <v-row>
      <v-col>
        <v-chart
          ref="chart"
          :options="generateGraphOptions()"
          :style="{width: '100%', height: `350px !important`}"
          autoresize
        />
      </v-col>
    </v-row>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando status produtores...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";

export default {
  name: "questionnaire-graphic",

  components: {
    
  },
  
  props: {
    filter: Object,
  },

  data() {
    return {
      loading: false,

      items: [],
    };
  },
  
  watch: {
    filter: {
      handler() {
        this.loadProducersStatus();
      },
      deep: true
    }
   
  },

  mounted() {
    this.loadProducersStatus();
  },
   
  methods: {

    async loadProducersStatus() {
      this.loading = true;
      try {

        const { data }  = await this.$axios.get(
          `/producer/list-status-month`,
          { params: {
            data_inicio: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
            data_fim: moment(this.filter.project.dateEnd).endOf('month').format('YYYY-MM-DD'),
          } }
        );

        this.items = data;
        return this.$emit("onLoadProducer", this.items);
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar status dos produtores", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    generateGraphOptions() {
      if (_.isEmpty(this.items)) {
        return;
      }
      
      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(37, 251, 39, 0.8)',   'rgba(140, 23, 23, 1)',  'rgba(251, 84, 37, 0.8)'],
        title: {
          text: `Produtores`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtextStyle: {
            fontSize: 15,
            color: '#ddd'
          },
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 500,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {

                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });
                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.items.map(o => this.formatMonth(o.mes)),
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: 'Produtores ativos',
            type: 'bar',
            showSymbol: false,
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 1,
              formatter: '  {c}  ',
            },
            data: this.items.map(o => o.total_ativos),
          },
          {
            name: 'Aprovados',
            type: 'bar',
            barGap: '-100%',
            data: this.items.map(o => o.aprovados),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },

          {
            name: 'Desvinculados',
            type: 'bar',
            barGap: '-100%',
            data: this.items.map(o => o.desvinculados),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },
          {
            name: 'Suspensos',
            type: 'bar',
            barGap: '-100%',
            data: this.items.map(o => o.suspensos),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },
        ]
      };
    },
    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
  },
};
</script>