<template>
  <v-form
    ref="form"
    v-model="validation.valid"
    lazy-validation
    autocomplete="off"
  >
    <v-row align="center">
      <v-col
        v-if="localData.alreadyRegistered"
        cols="12"
        class="d-flex justify-center my-0 py-0"
      >
        <v-alert
          dense
          light
          prominent
          colored-border
          border="left"
          elevation="2"
          type="warning"
          style="max-width: 900px"
        >
          <v-row
            class="pa-0"
            align="center"
          >
            <v-col class="grow py-0">
              <div class="text-h6">
                CPF já cadastrado
              </div>
              <div class="body-1">
                Ao salvar será aberto um comparativo para mesclar os dados em apenas um cadastro.
              </div>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="pb-1"
      >
        <v-text-field
          v-model="localData.nome"
          prepend-inner-icon="person"
          label="Nome *"
          :disabled="sourceScreen==='PROJETOS'"
          :rules="validation.rules.required"
          dense
          autofocus
        >
          <template #append>
            <v-btn
              v-if="isGroupingType"
              icon
              small
              :disabled="!localData.nome"
              @click="searchProducer"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon v-on="on">
                    search
                  </v-icon>
                </template>
                Procurar produtor (Código ou Nome)
              </v-tooltip>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        v-if="isVisible('codigo_laticinio') && sourceScreen!='PROJETOS'"
        cols="12"
        md="2"
        class="pb-1"
      >
        <v-text-field
          v-model="localData.codigo_laticinio"
          prepend-inner-icon="subtitles"
          :label="`Código laticínio ${isRequired('codigo_laticinio') ? '*' : ''}`"
          :rules="type == 'ACTIVE' ? getRules('codigo_laticinio'): []"
          :disabled="type == 'ACTIVE' ? !isEditable('codigo_laticinio') : false"
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="pb-1"
      >
        <masked-input-form
          v-model.trim="localData.data_entrada_laticinio"
          prepend-inner-icon="event"
          :label="`Data de Vinculação ${isRequired('data_entrada_laticinio') ? '*' : ''}`"
          :rules="getRules('data_entrada_laticinio')"
          :mask="bornAtMask"
          :disabled="sourceScreen==='PROJETOS'"
          maxlength="10"
          dense
          :return-unmasked="false"
        />
      </v-col>
      <v-col
        v-if="isGroupingType"
        cols="12"
        sm="4"
        class="pb-1"
      >
        <masked-input-form
          v-model="localData.cnpj_cpf"
          prepend-inner-icon="assignment_ind"
          label="CPF do Responsável"
          :rules="getRules('cnpj_cpf')"
          :mask="CpfCnpjMask"
          maxlength="18"
          dense
          :disabled="verifyingDocument || sourceScreen==='PROJETOS'"
          :loading="verifyingDocument"
          @blur="searchDocument"
        />
      </v-col>
      <v-col
        v-if="isGroupingType"
        cols="12"
        sm="4"
        class="pb-1"
      >
        <v-text-field
          v-model="localData.responsavel_agrupamento"
          prepend-inner-icon="person"
          label="Responsável"
          :disabled="sourceScreen==='PROJETOS'"
          dense
        />
      </v-col>
      <v-col
        v-if="isVisible('celular')"
        cols="12"
        sm="4"
        class="pb-1"
      >
        <masked-input-form
          v-model="localData.celular"
          prepend-inner-icon="phone"
          :label="`Celular ${isRequired('celular') ? '*' : ''}`"
          :rules="getRules('celular')"
          :mask="PhoneMask"
          :disabled="sourceScreen==='PROJETOS'"
          maxlength="15"
          dense
        />
      </v-col>
      <v-col
        v-if="isVisible('telefone')"
        cols="12"
        sm="4"
        class="pb-1"
      >
        <masked-input-form
          v-model="localData.telefone"
          prepend-inner-icon="phone"
          :label="`Telefone ${isRequired('telefone') ? '*' : ''}`"
          :rules="getRules('telefone')"
          :mask="PhoneMask"
          :disabled="sourceScreen==='PROJETOS'"
          maxlength="15"
          dense
        />
      </v-col>

      <v-col
        v-if="isVisible('email')"
        cols="6"
        md="4"
        class="pb-1"
      >
        <!-- E-mail com i no final é um workaround pra desabilitar o autocomplete do chrome -->
        <v-text-field
          v-model="localData.email"
          prepend-inner-icon="email"
          :label="`E-maiI ${isRequired('email') ? '*' : ''}`"
          :rules="getRules('email')"
          dense
        />
      </v-col>

      <template v-if="!isGroupingType">
        <v-col
          v-if="isVisible('cnpj_cpf')"
          cols="12"
          sm="4"
          class="pb-1"
        >
          <masked-input-form
            v-model.trim="localData.cnpj_cpf"
            prepend-inner-icon="assignment_ind"
            :label="`CPF/CNPJ do titular ${isRequired('cnpj_cpf') ? '*' : ''}`"
            :rules="getRules('cnpj_cpf', null, localData.cnpj_cpf)"
            :mask="CpfCnpjMask"
            maxlength="18"
            dense
            :disabled="verifyingDocument"
            :loading="verifyingDocument"
            @blur="verifyDocument"
          />
        </v-col>
        <v-col
          v-if="isVisible('codigo_sda')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model.trim="localData.codigo_sda"
            prepend-inner-icon="assignment_ind"
            :label="`Código SDA ${isRequired('codigo_sda') ? '*' : ''}`"
            :rules="getRules('codigo_sda')"
            type="number"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('cidasc')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model.trim="localData.cidasc"
            prepend-inner-icon="assignment_ind"
            :label="`CIDASC/IMA ${isRequired('cidasc') ? '*' : ''}`"
            :rules="getRules('cidasc')"
            type="text"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('rg')"
          cols="12"
          sm="6"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.rg"
            prepend-inner-icon="assignment_ind"
            :label="`N° de Identidade (RG) ${isRequired('rg') ? '*' : ''}`"
            :rules="getRules('rg')"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('orgao_expedidor')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.orgao_expedidor"
            prepend-inner-icon="assignment_ind"
            :label="`Órgão expedidor ${isRequired('orgao_expedidor') ? '*' : ''}`"
            :rules="getRules('orgao_expedidor')"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('uf_expedicao')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.uf_expedicao"
            prepend-inner-icon="assignment_ind"
            :label="`UF Expedição ${isRequired('uf_expedicao') ? '*' : ''}`"
            :rules="getRules('uf_expedicao')"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('nirf')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.nirf"
            prepend-inner-icon="subtitles"
            :label="`N° NIRF ${isRequired('nirf') ? '*' : ''}`"
            :rules="getRules('nirf')"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('nrp')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.nrp"
            prepend-inner-icon="subtitles"
            :label="`Código NRP ${isRequired('nrp') ? '*' : ''}`"
            :rules="getRules('nrp')"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('inscricao_estadual')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.inscricao_estadual"
            prepend-inner-icon="subtitles"
            :label="`Inscrição estadual ${isRequired('inscricao_estadual') ? '*' : ''}`"
            :rules="getRules('inscricao_estadual')"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('data_inicio_coleta')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <masked-input-form
            v-model.trim="localData.data_inicio_coleta"
            prepend-inner-icon="event"
            :label="`Data início coleta ${isRequired('data_inicio_coleta') ? '*' : ''}`"
            :rules="getRules('data_inicio_coleta')"
            :mask="bornAtMask"
            maxlength="10"
            dense
            :return-unmasked="false"
          />
        </v-col>
        <v-col
          v-if="isVisible('data_nascimento')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <masked-input-form
            v-model.trim="localData.data_nascimento"
            prepend-inner-icon="event"
            :label="`Data de nascimento ${isRequired('data_nascimento') ? '*' : ''}`"
            :rules="getRules('data_nascimento')"
            :mask="bornAtMask"
            maxlength="10"
            dense
            :return-unmasked="false"
          />
        </v-col>
        <v-col
          v-if="isVisible('estado_civil')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-select
            v-model="localData.estado_civil"
            :items="['Casado(a)', 'Divorciado(a)', 'Separado(a)', 'Solteiro(a)', 'Viúvo(a)']"
            prepend-inner-icon="person"
            :label="`Estado Civil ${isRequired('estado_civil') ? '*' : ''}`"
            :rules="getRules('estado_civil')"
            dense
            clearable
          />
        </v-col>
        <v-col
          v-if="isVisible('sif')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.sif"
            prepend-inner-icon="subtitles"
            :label="`Sif ${isRequired('sif') ? '*' : ''}`"
            :rules="getRules('sif')"
            maxlength="10"
            dense
          />
        </v-col>
        <v-col
          v-if="isVisible('cad_pro')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.cad_pro"
            prepend-inner-icon="subtitles"
            type="number"
            :label="`Cadastro de Produtores Rurais (CAD_PRO) ${isRequired('cad_pro') ? '*' : ''}`"
            :rules="getRules('cad_pro')"
            dense
            @keypress="disableDotAndComma"
          />
        </v-col>
        <v-col
          v-if="isVisible('carta_anuencia')"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-text-field
            v-model="localData.carta_anuencia"
            prepend-inner-icon="subtitles"
            :label="`Carta de Anuência ${isRequired('carta_anuencia') ? '*' : ''}`"
            :rules="getRules('carta_anuencia')"
            dense
            @keypress="disableDotAndComma"
          />
        </v-col>
      </template>
      <v-col
        v-if="localData.id_condominio"
        cols="12"
        sm="6"
        md="3"
        class="pb-1"
      >
        <v-text-field
          v-model="localData.nome_condominio"
          label="Agrupamento"
          prepend-inner-icon="local_shipping"
          disabled
          dense
        />
      </v-col>
      <v-col
        v-if="isVisible('cooperativa') && sourceScreen!='PROJETOS'"
        cols="12"
        sm="6"
        md="3"
        class="pb-1"
      >
        <v-autocomplete
          v-model="localData.cooperativa"
          prepend-inner-icon="apartment"
          :label="`Cooperativa ${isRequired('cooperativa') ? '*' : ''}`"
          :rules="getRules('cooperativa', 'id_cooperativa')"
          :items="cooperatives"
          :disabled="!!localData.id_condominio"
          return-object
          item-text="nome_cooperativa"
          item-value="id_cooperativa"
          dense
          clearable
        />
      </v-col>
      <v-col
        v-if="isVisible('id_rota')"
        cols="12"
        sm="6"
        md="3"
        class="pb-1"
      >
        <v-select
          v-model="localData.id_rota"
          :items="listRoutes"
          prepend-inner-icon="icon-rota"
          item-text="descricao"
          item-value="id_rota"
          :label="`Rota Principal de coleta ${isRequired('id_rota') ? '*' : ''}`"
          :rules="getRules('id_rota')"
          :disabled="!!localData.id_condominio || sourceScreen==='PROJETOS'"
          dense
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <template v-if="!isGroupingType">
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-select
            v-model="routes.collects"
            :items="localData.rotas"
            label="Rotas de coletas"
            prepend-inner-icon="icon-rota"
            disabled
            multiple
            attach
            small-chips
            dense
            item-value="id_rota"
            item-text="descricao"
          >
            <template #selection="{attrs, item, selected}">
              <v-chip
                small
                v-bind="attrs"
                :input-value="selected"
                color="primary"
              >
                <v-icon
                  left
                  small
                >
                  map
                </v-icon>
                {{ item.descricao }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-autocomplete
            v-model="routes.visits"
            :items="localData.rotas"
            label="Rotas de visitas"
            prepend-inner-icon="icon-rota"
            disabled
            multiple
            attach
            small-chips
            dense
            item-value="id_rota"
            item-text="descricao"
          >
            <template #selection="{attrs, item,selected}">
              <v-chip
                small
                v-bind="attrs"
                :input-value="selected"
                color="cyan darken-1"
              >
                <v-icon
                  left
                  small
                >
                  map
                </v-icon>
                {{ item.descricao }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </template>
      <v-col
        v-if="isVisible('escolaridade')"
        cols="12"
        sm="6"
        md="3"
        class="pb-1"
      >
        <v-select
          v-model="localData.escolaridade"
          :items="schoolingOptions"
          prepend-inner-icon="person"
          item-text="text"
          item-value="value"
          label="Escolaridade"
          :rules="getRules('escolaridade')"
          :disabled="!!localData.id_condominio || sourceScreen==='PROJETOS'"
          dense
          clearable
        />
      </v-col>
      <v-col
        v-if="!localData.id_condominio && isGroupingType"
        cols="12"
      >
        <v-autocomplete
          v-model="localData.condominio"
          chips
          small-chips
          multiple
          label="Produtores"
          prepend-inner-icon="apartment"
          :items="groups"
          :disabled="sourceScreen==='PROJETOS'"
          return-object
          item-text="codigo_nome"
          item-value="id_pessoa"
          dense
        >
          <template #selection="data">
            <v-chip
              small
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="sourceScreen==='PROJETOS'"
              close
              color="primary"
              class="mt-1"
              @click="data.select"
              @click:close="removeGroupChip(data.item)"
            >
              <v-icon
                left
                small
              >
                person
              </v-icon>
              {{ data.item.codigo_nome }}
            </v-chip>
          </template>
          <template #item="{ item, attrs }">
            <v-list-item-content
              v-bind="attrs"
              @click.stop="onToggleGroup(item)"
              v-text="item.codigo_nome"
            />
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="pb-1"
        :hidden="showPassword"
      >
        <v-text-field
          v-model="localData.senha"
          :append-icon="password.current.show ? 'visibility' : 'visibility_off'"
          :type="password.current.show ? 'text' : 'password'"
          prepend-inner-icon="lock"
          label="Senha"
          :rules="showPassword ? [] : password.current.rules.required"
          :hidden="password.hidden"
          dense
          @click:append="password.current.show = !password.current.show"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        class="pb-1"
        :hidden="showPassword"
      >
        <v-text-field
          v-model="password.rewhite.data"
          label="Repita a senha"
          :append-icon="password.rewhite.show ? 'visibility' : 'visibility_off'"
          :type="password.rewhite.show ? 'text' : 'password'"
          prepend-inner-icon="lock"
          :rules="showPassword ? [] : [password.rewhite.rules.required, password.rewhite.rules.equal]"
          :hidden="password.hidden"
          dense
          @click:append="password.rewhite.show = !password.rewhite.show"
        />
      </v-col>

      <v-col
        v-if="showTempPassword"
        cols="12"
        sm="6"
        md="3"
        class="pb-1"
      >
        <v-text-field
          v-model="localData.senha_temp"
          prepend-inner-icon="lock"
          label="Senha Temporária"
          dense
        />
      </v-col>

      <v-col
        v-if="isVisible('emite_nota_propria') && sourceScreen!='PROJETOS'"
        cols="12"
        sm="6"
        md="3"
        class="pb-1"
      >
        <v-select
          v-model="localData.tipo_emissao_nota"
          :items="invoiceTypes"
          :label="`Tipo Emissão Nota ${isRequired('emite_nota_propria') ? '*' : ''}`"
          :rules="getRules('emite_nota_propria')"
          prepend-inner-icon="subtitles"
          dense
          clearable
        />
      </v-col>

      <v-col
        v-if="isVisible('observacao_tecnica') && sourceScreen!='PROJETOS'"
        cols="12"
        class="pb-1"
      >
        <v-textarea
          v-model="localData.observacao_tecnica"
          prepend-inner-icon="description"
          rows="3"
          auto-grow
          :label="`Observações ${isRequired('observacao_tecnica') ? '*' : ''}`"
          :rules="getRules('observacao_tecnica')"
          dense
        />
      </v-col>
      <template v-if="isGroupingType">
        <v-col
          cols="12"
          class="subtitle-1"
        >
          <div class="d-flex justify-between">
            Parâmetros de {{ groupName }}
          </div>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-select
            v-model="groupSettings.tipo_coleta"
            label="Tipo de coleta *"
            item-text="text"
            item-value="value"
            :items="collectTypes"
            :disabled="sourceScreen==='PROJETOS'"
            required
            :rules="validation.rules.required"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-select
            v-model="groupSettings.replicar_analises"
            label="Replicar análises em todos os produtores *"
            item-text="text"
            item-value="value"
            :items="booleanTypes"
            :disabled="sourceScreen==='PROJETOS'"
            required
            :rules="validation.rules.requiredBool"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-select
            v-model="groupSettings.bonificacao_individual"
            label="Pagamento individual *"
            item-text="text"
            item-value="value"
            :items="booleanTypes"
            :disabled="sourceScreen==='PROJETOS'"
            required
            :rules="validation.rules.requiredBool"
            dense
          />
        </v-col>
        <v-col
          v-if="groupSettings.bonificacao_individual"
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <masked-input-form
            v-model.number="groupSettings.valor_retencao_por_litro"
            :mask="{ mask: Number, min: 0, scale: 4, thousandsSeparator: '.', radix: ',' }"
            unmask
            label="Valor de Retenção por Litro"
            :disabled="sourceScreen === 'PROJETOS'"
            hint="Valor descontado por litro e compensado ao responsável do resfriador"
            persistent-hint
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-select
            v-model="groupSettings.todos_produtores_ativos"
            label="Considerar produtor em relatórios *"
            item-text="text"
            item-value="value"
            :items="groupingTypes"
            :disabled="sourceScreen==='PROJETOS'"
            required
            :rules="validation.rules.requiredBool"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-select
            v-model="groupSettings.produtores_ativos_em_qualidade"
            label="Considerar produtor em qualidade *"
            item-text="text"
            item-value="value"
            :items="groupingTypes"
            :disabled="sourceScreen==='PROJETOS'"
            required
            :rules="validation.rules.requiredBool"
            dense
          />
        </v-col>

        <v-col
          cols="12"
          class="subtitle-1"
        >
          <div class="d-flex justify-between">
            Parâmetros de {{ groupName }} para o APLICATIVO de VISITA
          </div>
          <v-divider />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
          class="pb-1"
        >
          <v-select
            v-model="groupSettings.tipo_visita"
            label="APP visita considerar*"
            item-text="text"
            item-value="value"
            :items="visitTypes"
            required
            :rules="validation.rules.required"
            dense
          />
        </v-col>

        <template v-if="groupSettings.tipo_coleta === 'TANQUE' && sourceScreen!='PROJETOS' ">
          <v-col
            cols="12"
            class="subtitle-1"
          >
            <div class="d-flex justify-between">
              Produtores (Divisão percentual de coleta)
            </div>
            <v-divider />
          </v-col>

          <v-col
            v-for="(produtor, idx) of localData.condominio"
            :key="idx"
            class="py-0 flex-grow-1"
            cols="12"
            sm="6"
            md="4"
          >
            <v-row>
              <v-col
                cols="8"
                class="pb-0 pr-0"
              >
                <v-text-field
                  :value="produtor.codigo_nome"
                  label="Produtor"
                  filled
                  dense
                  disabled
                />
              </v-col>
              <v-col
                class="flex-grow-1 pb-0"
              >
                <v-text-field
                  v-model="produtor.percentual"
                  type="number"
                  filled
                  dense
                  suffix="%"
                  label="Divisão percentual"
                  :rules="getPercentRules()"
                />
              </v-col>
              <v-divider
                class="flex-grow-0"
                vertical
              />
            </v-row>
          </v-col>
        </template>
      </template>
    </v-row>

    <v-dialog
      v-model="dialogExistingProducer.show"
      :max-width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="blue--text text-h5"
        >
          Produtor já cadastrado
        </v-card-title>
        <v-card-text>
          <p>
            <b>
              {{ dialogExistingProducer.title }}
            </b>
          </p>
          Deseja abrir o cadastro existente?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            outlined
            @click.native="closeExistingProducerDialog"
          >
            CANCELAR
          </v-btn>
          <v-btn
            color="primary darken-1"
            outlined
            @click.native="openExistingProducer"
          >
            ABRIR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogProducerFound.show"
      :max-width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="green--text text-h5"
        >
          Produtor encontrado
        </v-card-title>
        <v-card-text>
          <p>
            <b>
              {{ dialogProducerFound.title }}
            </b>
          </p>
          Deseja preencher com os dados do produtor?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            outlined
            @click.native="dialogProducerFound.show = false"
          >
            CANCELAR
          </v-btn>
          <v-btn
            color="primary darken-1"
            outlined
            @click.native="fillProducerData"
          >
            PREENCHER
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import MaskedInputForm from "@/Support/Components/MaskedTextField.vue";
import * as _ from "lodash";
import { documentValidate } from "@/Support/Resources/utils.js";

export default {
  components: {
    MaskedInputForm,
  },
  props: {
    producerId: {
      type: String,
    },
    dataProducer: {
      type: Object,
      default: () => {},
    },
    dataGroupSettings: {
      type: Object,
      required: true,
    },
    showPassword: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
    },
    sourceScreen: {
      type: String,
    },
    needSave: Boolean,
  },
  data() {
    return {
      valid: {
        infoProducer: false,
      },

      routes: {
        visits: null,
        collects: null,
      },

      cooperatives: [],

      listRoutes: [],

      groups: [],

      validation: {
        valid: true,
        rules: {
          required: [v => !!v || "Campo obrigatório"],
          requiredBool: [v => _.isBoolean(v) || 'Campo obrigatório!'],
        },
      },

      PhoneMask: {
        mask: "(00) 00000-0000",
      },

      CpfCnpjMask: {
        mask: [
          {
            mask: "000.000.000-00",
            type: "CPF",
          },
          {
            mask: "00.000.000/0000-00",
            type: "CNPJ",
          },
          {
            mask: "000000000000000000",
            startsWith: "",
            type: "unknown",
          },
        ],
        dispatch: function(appended, dynamicMasked) {
          var number = (dynamicMasked.value + appended).replace(/\D/g, "");

          //seleciona a masca correspondente
          return dynamicMasked.compiledMasks.find(function(m) {
            if (number.length > 11) {
              return m.type === "CNPJ";
            }
            return m.type === "CPF";
          });
        },
      },

      bornAtMask: {
        mask: "00/00/0000",
        maxlength: 14,
      },

      // Parametros de controle de senha
      password: {
        current: {
          show: false,
          rules: {
            required: [v => !!v || "Campo obrigatório"],
          },
        },
        rewhite: {
          data: null,
          show: false,
          rules: {
            required: p => !!p || "Campo obrigatório",
            equal: p => p === _.get(this.localData, "senha", null) || "A confimação de senha não confere.",
          },
        },
      },

      defaultRequiredFields: ['nome', 'codigo_laticinio', 'cnpj_cpf'],

      verifyingDocument: false,

      dialogExistingProducer: {
        show: false,
        title: null,
        producerId: null
      },

      dialogProducerFound: {
        show: false,
        title: null,
        producerId: null
      },

      collectTypes: [
        { value: 'PRODUTOR', text: 'Produtor (Individual)' },
        { value: 'TANQUE', text: 'Tanque (Geral)' }
      ],

      visitTypes: [
        { value: 'PRODUTOR', text: 'Produtor (Individual)' },
        { value: 'GRUPO', text: 'Grupo' }
      ],

      booleanTypes: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],

      schoolingOptions: [
        {text: 'Ensino Fundamental Incompleto', value: 'fundamental_incompleto'},
        {text: 'Ensino Fundamental Completo', value: 'fundamental_completo'},
        {text: 'Ensino Médio Incompleto', value: 'medio_incompleto'},
        {text: 'Ensino Médio Completo', value: 'medio_completo'},
        {text: 'Ensino Técnico', value: 'tecnico'},
        {text: 'Ensino Superior Incompleto', value: 'superior_incompleto'},
        {text: 'Ensino Superior Completo', value: 'superior_completo'},
        {text: 'Pós-Graduação (Especialização/MBA)', value: 'pos_graduacao'},
        {text: 'Mestrado', value: 'mestrado'},
        {text: 'Doutorado', value: 'doutorado'},
        {text: 'Pós-Doutorado', value: 'pos_doutorado'},
      ],
    };
  },

  computed: {
    localData: {
      get() {
        this.loadProducerRoute();
        return this.dataProducer;
      },

      set(newValue) {
        return this.$emit("update:dataProducer", {
          ...newValue,
          location: this.location,
          senha: _.get(newValue, "password.current", null),
        });
      },
    },
    groupSettings: {
      get() {
        return this.dataGroupSettings;
      },

      set(newValue) {
        return this.$emit("update:dataGroupSettings", newValue);
      },
    },

    groupName() {
      const types = {
        'CONDOMINIUM': 'Condomínio',
        'COMMUNITY-TANK': 'Tanque Comunitário',
        'FAMILY-GROUP': 'Grupo Familiar',
      };

      return types[this.type] || '';
    },

    groupingTypes() {
      return [
        { value: true, text: 'PRODUTOR' },
        { value: false, text: this.groupName.toUpperCase() }
      ]
    },

    isGroupingType() {
      return ['CONDOMINIUM', 'COMMUNITY-TANK', 'FAMILY-GROUP'].includes(this.type);
    },

    isCommunityTank() {
      return this.type === "COMMUNITY-TANK";
    },

    settings() {
      if (_.isEmpty(this.$store.state.settings.gerais.cadastro_produtor)) {
        return {}
      }
      return this.$store.state.settings.gerais.cadastro_produtor.pessoal;
    },

    showTempPassword() {
      return this.$store.state.settings.gerais.login_produtor_requer_senha || false;
    },

    hasCpf() {
      return (this.localData.cnpj_cpf || '').replace(/[^0-9]/g, '').length === 11;
    },

    invoiceTypes () {
      if (this.hasCpf) {
        return [
          { text: 'PRÓPRIA', value: 'PROPRIA' },
          { text: 'MILKUP', value: 'MILKUP' },
        ];
      }

      return [
        { text: 'PRÓPRIA', value: 'PROPRIA' },
      ];
    },
  },
  watch: {
    // Monitora a validação do form
    validation: {
      handler: function({ valid }) {
        this.$emit("onValidation", {
          valid: valid,
          tab: "personal",
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.loadCooperatives();
    this.loadGroups();
    this.loadRoutes();

    //Não é necessário validar propectados
    if (this.type !== "PROSPECTION") {
      this.$refs.form.validate();
    }

    if (!this.producerId) {
      this.getNextProducerCode();
    }
  },
  methods: {
    isVisible(field) {
      if (!this.settings[field]) {
        return true;
      }

      return this.settings[field].show;
    },

    isRequired(field) {
      if (!this.settings[field]) {
        return this.defaultRequiredFields.includes(field);
      }

      return this.settings[field].required;
    },

    isEditable(field) {
      if (!this.settings[field]) {
        return true;
      }

      return this.settings[field].editable;
    },

    getRules(field, name = null, value = null) {
      const rules = [];

      if (this.isRequired(field)) {
        if (name) {
          rules.push(v => !!v && !!v[name] || "Campo obrigatório");
        }
        else {
          rules.push(v => !!v || "Campo obrigatório");
        }
      }

      if (field === 'cnpj_cpf') {
        if (value !== null) {
          if (value) {
            const validate = documentValidate(value);
            rules.push(validate.validation || validate.msg);
          }
        }
      }

      return rules;
    },

    disableDotAndComma(e) {
      if (e.charCode === 46 || e.charCode === 44) {
        e.preventDefault();
      }
    },

    loadProducerRoute() {
      if (this.dataProducer.rotas) {
        this.routes.visits = this.dataProducer.rotas
          .filter(rt => rt.id_tipo === 1)
          .map(rt => ({ id_rota: rt.id_rota, descricao: rt.descricao }));

        this.routes.collects = this.dataProducer.rotas
          .filter(rt => rt.id_tipo === 3)
          .map(rt => ({ id_rota: rt.id_rota, descricao: rt.descricao }));
      }
    },

    /**
     * @void
     * Valida os campos do formulários
     */
    formValidate() {
      this.$refs.form.validate();
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /**
     * @void
     * Reseta o formulário
     */
    formReset() {
      this.$refs.form.reset();
    },

    /**
     * Ativado após digitar o cpf ou cnpj do produtor,
     * e caso encontre cadastrado exibe um modal informando o cadastro existente
     */
    async verifyDocument(document) {
      // Verifica se cnpj_cpf_multiplo está habilitado
      const settings = this.$store.state.settings.gerais.cadastro_produtor || {};
      const cnpj_cpf_multiplo = _.get(settings.configuracoes, "cnpj_cpf_multiplo", false);

      if (cnpj_cpf_multiplo && cnpj_cpf_multiplo == true) {
        return;
      }

      this.verifyingDocument = true;
      try {
        const { data } = await this.$axios.post(`/produtores/verificarDocumento`, {
          id_produtor: this.producerId,
          cnpj_cpf: document
        });

        if (data.dados) {
          this.dialogExistingProducer = {
            show: true,
            title: data.dados.codigo_laticinio ? `${data.dados.codigo_laticinio} -  ${data.dados.nome}` : data.dados.nome,
            producerId: data.dados.id_produtor,
          }
        }

      } catch (error) {
        console.log(error);
      } finally {
        this.verifyingDocument = false;
      }
    },

    /**
     * @void
     * Ativado no dialog que informa o cadastro já existente do produtor
     * carrega os dados do cadastro existente
     */
    openExistingProducer() {
      this.$emit('openExistingProducer', this.dialogExistingProducer.producerId);
      this.localData.alreadyRegistered = false;
      this.dialogExistingProducer.show = false;
    },

    closeExistingProducerDialog() {
      this.localData.alreadyRegistered = false;
      this.dialogExistingProducer.show = false;

      this.localData.cnpj_cpf = '';
    },

    /**
     * @void
     * Caso for um cadastro novo e estiver configurado para gerar o código_laticinio automaticamente
     * Busca no banco o próximo código e adiciona no formulário
     */
    async getNextProducerCode() {
      try {
        const settings = this.$store.state.settings.gerais.cadastro_produtor || {};
        const autoincrementa_codigo_laticinio = _.get(settings.configuracoes, "autoincrementa_codigo_laticinio", false);

        if (!_.isEmpty(this.dataProducer.codigo_laticinio)
          || (this.producerId && this.type !== "PROSPECTION")
          || (autoincrementa_codigo_laticinio == false)) {
          return;
        }

        const { data } = await this.$axios.post(`/produtores/proximoCodigoLaticinio`);

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.localData = {
          ...this.localData,
          codigo_laticinio: data.codigo_laticinio
        }

        this.$emit('update:needSave', true);

      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Lista as cooperativas cadastradas
     */
    async loadCooperatives() {
      try {
        const { data } = await this.$axios.post(`/cooperativa/listaJson`);

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        this.cooperatives = data;
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Lista rotas de coleta definir como rota principal do produtor
     */
    async loadRoutes() {
      try {
        const { data } = await this.$axios.post(
          `/rota/listaRotaTipoJson`,
          this.$qs.stringify({
            tipo: 3,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        this.listRoutes = data;
      } catch (error) {
        console.log(error);
      }
    },

    async loadGroups() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaProdutorJson`, {
          campos: ['id_pessoa', 'nome', 'codigo_laticinio', 'tipo_condominio'],
        });

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        this.groups = data
          .filter(person => !person.tipo_condominio)
          .map((person) => {
            return {
              id_pessoa: person.id_pessoa,
              nome: person.nome,
              codigo_nome: person.codigo_laticinio + ' ' + person.nome,
              codigo_laticinio: person.codigo_laticinio,
            };
          });

      } catch (error) {
        console.log(error);
      }
    },

    async onToggleGroup(person) {
      const index = this.localData.condominio.findIndex(item => item.id_pessoa === person.id_pessoa);

      if (index > -1) {
        this.localData.condominio.splice(index, 1);
      }
      else if (await this.canAddOnGroup(person)) {
        this.localData.condominio.push(person)
      }
      else {
        this.$snotify.info("Produtor já adicionado em outro agrupamento", "Atenção");
      }
    },

    async canAddOnGroup(person) {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/produtores/verificarAgrupamento`, {
          id_condominio: this.producerId,
          id_produtor: person.id_pessoa
        });

        return data.disponivel;
      } catch (error) {
        this.$snotify.error("Erro ao verificar produtor", "Atenção");
        console.log(error);
        return false;
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    removeGroupChip(person) {
      this.localData.condominio = _.remove(this.localData.condominio, (data) => {
        return data.id_pessoa !== person.id_pessoa;
      });
    },

    async searchDocument(document) {
      this.verifyingDocument = true;
      try {
        const { data } = await this.$axios.post(`/produtores/verificarDocumento`, {
          id_produtor: this.producerId,
          cnpj_cpf: document
        });

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        if (data.dados) {
          this.dialogProducerFound = {
            show: true,
            title: data.dados.codigo_laticinio ? `${data.dados.codigo_laticinio} -  ${data.dados.nome}` : data.dados.nome,
            producerId: data.dados.id_produtor,
          }
        }

      } catch (error) {
        console.log(error);
      } finally {
        this.verifyingDocument = false;
      }
    },

    async searchProducer() {
      const search = this.localData.nome.trim();

      if (!search) {
        return;
      }

      this.$root.$progressBar.loading();
      try {
        const { data } = await this.$axios.post(`/produtores/procurar`, {
          id_produtor: this.producerId,
          busca: search
        });

        if (data) {
          this.dialogProducerFound = {
            show: true,
            title: data.codigo_laticinio ? `${data.codigo_laticinio} -  ${data.nome}` : data.nome,
            producerId: data.id_produtor,
          }
        }

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    fillProducerData() {
      this.dialogProducerFound.show = false;
      this.$emit('fillProducerData', this.dialogProducerFound.producerId);
    },

    getPercentRules() {
      const hasPercent = this.localData.condominio.some(producer => producer.percentual);

      if (!hasPercent) {
        return [];
      }

      const percentual = this.localData.condominio.reduce((acc, cur) => acc + (parseFloat(cur.percentual) || 0), 0);

      return [
        v => !!v || 'Campo obrigatório',
        () => percentual <= 100 || 'Divisão perc. inválida',
      ];
    }
  },
};
</script>
