<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Relatório Geral PQFL
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#month">
          Mensal
        </v-tab>
        <v-tab href="#general">
          Geral
        </v-tab>
        
        <v-tab-item value="month">
          <report-month />
        </v-tab-item>

        <v-tab-item value="general">
          <graphic-general />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>
<script>

import ReportMonth from "@/Domains/Projects/PQFL/Components/ReportMonth.vue";
import GraphicGeneral from "@/Domains/Projects/PQFL/Components/GraphicGeneral.vue";

export default {
  components: {
    ReportMonth,
    GraphicGeneral,
  },

  props: ["parent"],

  data() {
    return {
      loading: false,
      tab: '',
     
    };
  },

  created() {
    
  },

  methods: {

    navigateBack() {
      this.$store.commit("setCurrentMenu", this.parent.name);
      this.$store.commit("setCurrentMenuBackground", this.parent.background);
    },
  },
};
</script>