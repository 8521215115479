<template>
  <cause-index
    type="VETERINARIO"
  />
</template>

<script>
import CauseIndex from "@/Domains/Registrations/Cares/Cause/CauseIndex.vue";

export default {
  components: {
    CauseIndex
  },
}
</script>
