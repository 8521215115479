<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Checklist PQFL
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="filter.project"
          :items="projectList"
          label="Vigência do Projeto"
          item-value="dateStart"
          item-text="text"
          return-object
          hide-details
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="loadRange"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="filter.project.protocol"
          label="Protocolo do projeto"
          type="text"
          persistent-hint
          dark
          filled
          disabled
          hide-selected
          hide-details
        />
      </v-col>
      <v-col
        md="4"
      >
        <month-picker
          v-model="filter.date.input"
          :maxMonth="filter.project.maxMonth"
          disable-last-thirty
          not-clearable
          @change="onFilterDate"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-select
          v-model="filter.questionnaire"
          :items="questionnairesGoal"
          label="Checklist"
          item-value="id_formulario"
          item-text="questionnaire.text"
          hide-details
          return-object
          clearable
          dark
          filled
          :rules="[v => !!v || 'Campo obrigatório']"
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="4"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="onLoad"
        />
      </v-col>
      <v-col
        md="4"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="onLoad"
        />
      </v-col>
    
      <v-col
        md="4"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
      @change="selectAllProducers = ''"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        {{ tab.title }}
      </v-tab>

      <v-tab-item
        v-for="tab in tabs"
        :key="tab.title"
        background-color="transparent"
        dark
      >
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            {{ `Produtores: ${ tab.items.length}` }}
          </v-card-title>
          <v-data-table
            :headers="tab.headers"
            :items="tab.items"
            :search="filter.search"
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
          >
            <template
              #[`item.data_resposta`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template
              #[`item.data_entrada_laticinio`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template
              #[`item.data_vencimento`]="{ value }"
            >
              {{ formatDate(value, "DD/MM/YYYY") }}
            </template>
            <template #[`item.status`]="{value}">
              <v-chip
                v-if="value == 'DESVINCULADO'"
                color="red"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>cancel</v-icon>
                </v-avatar>
                D
              </v-chip>
              <v-chip
                v-else-if="value == 'SUSPENSO'"
                color="orange"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>remove_circle</v-icon>
                </v-avatar>
                S
              </v-chip>
              <v-chip
                v-else
                color="green"
                small
              >
                <v-avatar
                  left
                  small
                >
                  <v-icon>check_circle</v-icon>
                </v-avatar>
                A
              </v-chip>
            </template>
          </v-data-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </v-container>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

import moment from "moment-timezone";
import XLSX from "xlsx-js-style";
import _ from "lodash";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  name: "quality-out-of-normative",

  components: {
    
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    parseEmpty: (value) => !value ? '-' : value,
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
          maxMonth: moment().format("YYYY-MM"),
        },
        questionnaire: {},
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        
        search: null,
      },
      months: [],

      tab: null,

      projectList: [],

      questionnairesGoal: [],

      response: [],
      notResponse: [],
      expired: [],
      reapply: [],
      
    };
  },

  computed: {
    
    filteredItems() {
      return this.notResponse
    },

    filteredItems2() {
      return this.expired;
    },

    filteredItems3() {
      if (!this.filter.date.month) {
        return this.reapply
      }
      
      let  data = [];
      data =  this.reapply
        .filter(item => moment(item.data_vencimento).isSame(moment(this.filter.date.month), 'month') )
        .map(item => {
          return {
            ...item
          }});
      return data;
    },
    filteredItems4() {
      return this.response
      
    },

    tabs() {
      return [
        {
          title: 'Não aplicados',
          items: this.filteredItems,
          headers: [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            { text: 'Prazo para aplicação', value: 'data_vencimento' },
            { text: 'Data entrada', value: 'data_entrada_laticinio' },
            { text: 'Status', value: 'status' },
          ],
        },
        {
          title: 'Vencidos',
          items: this.filteredItems2,
          headers: [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            { text: 'Última resposta', value: 'data_resposta' },
            { text: 'Vencimento', value: 'data_vencimento' },
            { text: 'Status', value: 'status' },
          ],
        },
        {
          title: `À vencer em ${moment(this.filter.date.month).format("MM/YYYY")}`,
          items: this.filteredItems3,
          headers: [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            { text: 'Última resposta', value: 'data_resposta' },
            { text: 'Vencimento', value: 'data_vencimento' },
            { text: 'Status', value: 'status' },
          ],
        },
        {
          title: 'Aplicados',
          items: this.filteredItems4,
          headers: [
            { text: 'Código', value: 'codigo_laticinio' },
            { text: 'Nome', value: 'nome_produtor' },
            { text: 'Rota', value: 'rota_principal' },
            { text: 'Data resposta', value: 'data_resposta' },
            { text: 'Vencimento', value: 'data_vencimento' },
            { text: 'Status', value: 'status' },
          ],
        }
      ]
    },

  },

  created() {
    this.loadProject();
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onFilterDate([month]) {
      this.filter.date.month = month;
    },
    onLoad() {
      this.reapply = [];
      this.expired = [];
      this.response = [];
      this.notResponse = [];
      if (this.filter.questionnaire) {
        this.loadResponses();
      }
    },
    async loadRange() {
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.max = moment(this.filter.project.dateEnd).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), moment().format('YYYY-MM')];
      
      this.questionnairesGoal = (this.questionnairesGoal.lenght > 0) ? this.questionnairesGoal : JSON.parse(this.filter.project.questionario) ;

      this.filter.questionnaire = _.first(this.questionnairesGoal) || [];
      this.onLoad();
    },

    async loadProject() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL"
          }
        );

        this.projectList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            questionario: proj.questionario,
            protocol: proj.protocolo,
          }
        });
        
        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          this.filter.project.maxMonth = moment(this.projectList[0].dateEnd).format("YYYY-MM");
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      }
    },

    async loadResponses() {

      let id = this.filter.questionnaire.id_formulario;
      
      if (this.filter.questionnaire.period_reapply) { //carregar somente se existir meta de reaplicar questioanrio
        try {
          this.loading = true;

          const { data } = await this.$axios.post(
            `/formularioCustomizado/listaVencimentosChecklist`,
            {
              id_formulario: id,
              data_inicio_projeto: moment(this.filter.project.dateStart).startOf('month').format('YYYY-MM-DD'),
              data_fim_projeto: moment(this.filter.project.dateEnd).endOf('month').format('YYYY-MM-DD'),
              periodo_reaplicar: this.filter.questionnaire.period_reapply,
              periodo_projeto: this.filter.questionnaire.period,
              id_pessoa: this.filter.technician.id,
              rotas: this.filter.routes.map(({ id }) => id)
            }
          );

          this.response =  data.filter(item => !!item.data_resposta)
            .map(item => ({
              ...item
            }));
       
          this.notResponse =  data.filter(item => _.isNull(item.data_resposta))
            .map(item => ({
              ...item
            }));

          this.reapply =  data
            .map(item => ({
              ...item
            }));

          this.expired =  data.filter(item => item.vencido && !!item.data_resposta)
            .map(item => ({
              ...item
            }));
          
        } catch (error) {
          this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
          console.warn(error);
        } finally {
          this.loading = false;
        }
      }
    },
    
    getReportJson() {
      const items = this.tabs[this.tab].items;

      return items.map(item => {
        let report = {
          Código: item.codigo_laticinio,
          Produtor: item.nome_produtor,
          'Rota Principal': item.rota_principal,
          Status: item.status,
          'Última resposta ': item.data_resposta ? moment(item.data_resposta).format("DD/MM/YYYY") : '',
          Vencimento: item.data_vencimento ? moment(item.data_vencimento).format("DD/MM/YYYY") : '',
        };

        return report;
      })
    },

    print() {
      const title = this.tabs[this.tab].title;

      return this.printFromJson(this.getReportJson(), title, true);
    },

    /**
     * Exporta o relatório para Excel
     */
    exportExcel() {
      const title = this.tabs[this.tab].title;

      let data = XLSX.utils.json_to_sheet(this.getReportJson());
      const filename = `Checklist ${title}`;

      const workbook = this.XLSX.utils.book_new();
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
