<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title class="text-h5">
        Aprovação de Solicitação de Análise
      </v-card-title>
      <v-card-subtitle>
        <v-chip
          dark
          x-small
          :color="statusColor"
        >
          {{ order?.status || 'PENDENTE' }}
        </v-chip>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              :value="order?.productAnalysis?.item?.description"
              prepend-inner-icon="subtitles"
              label="Produto"
              disabled
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              :value="order?.productAnalysis?.analysis?.description"
              prepend-inner-icon="assignment_ind"
              label="Análise"
              disabled
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              :value="order?.laboratory?.name"
              prepend-inner-icon="assignment_ind"
              label="Laboratório"
              disabled
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              :value="order?.quantity"
              prepend-inner-icon="event"
              label="Quantidade solicitada"
              disabled
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-btn-toggle
              v-model="status"
            >
              <v-btn value="PENDENTE">
                <span class="hidden-sm-and-down">PENDENTE</span>

                <v-icon
                  right
                  color="orange darken-1"
                >
                  pending_actions
                </v-icon>
              </v-btn>

              <v-btn value="APROVADO">
                <span class="hidden-sm-and-down">APROVADO</span>

                <v-icon
                  right
                  color="green darken-1"
                >
                  verified
                </v-icon>
              </v-btn>

              <v-btn value="REPROVADO">
                <span class="hidden-sm-and-down">REPROVADO</span>

                <v-icon
                  right
                  color="red darken-1"
                >
                  cancel
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-textarea
              v-model="justification"
              label="Justificativa"
              filled
              rows="3"
              auto-grow
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="green darken-1"
          outlined
          :disabled="isDisabled"
          :loading="loading || saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
export default {

  props: {
    value: Boolean,
    orderId: String,
  },

  data() {
    return {
      loading: false,
      saving: false,

      desc: '',
      status: null,
      justification: null,
      order: {}
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    statusColor() {
      if (this.order.status === 'APROVADO') {
        return 'green darken-1';
      }

      if (this.order.status === 'REPROVADO') {
        return 'red darken-1';
      }

      return 'orange darken-1';
    },

    isDisabled() {
      return this.status === null || this.status === this.order.status
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.loadOrder();
      }
      else {
        this.desc = '';
        this.status = null;
        this.justification = null;
        this.order = {};
      }
    }
  },

  methods: {
    async loadOrder() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(`/analysis/order-control/${this.orderId}`);

        this.order = {
          id: data.id_controle_analise,
          productAnalysis: {
            id: data.id_produto_analise,
            item: {
              id: data.produto_analise?.produto?.id_item,
              name: data.produto_analise?.produto?.nome,
              code: data.produto_analise?.produto?.cod_item,
              description: data.produto_analise?.produto?.cod_item ? `${data.produto_analise?.produto?.cod_item} - ${data.produto_analise?.produto?.nome}` : data.produto_analise?.produto?.nome,
            },
            analysis: {
              id: data.produto_analise?.analise?.id_tipo_analise,
              description: data.produto_analise?.analise?.descricao,
              type: data.produto_analise?.analise?.natureza,
            },
            periodicity: data.produto_analise?.periodicidade,
            quantity: data.produto_analise?.quantidade_periodo,
          },
          laboratory: {
            id: data.laboratorio?.id_laboratorio,
            name: data.laboratorio?.nome,
          },
          date: data.data_hora_cadastro,
          quantity: data.quantidade,
          project: data.projeto,
          description: data.descricao,
          requester: {
            id: data.id_solicitante,
            name: data.nome_solicitante,
          },
          status: data.status
        }
        this.order.status = data.status;
        this.status = data.status;
        this.justification = data.justificativa;
      }
      catch (error) {
        this.$snotify.error('Erro ao alterar aprovação', 'Atenção');
        console.warn(error)
        this.close();
      }
      finally {
        this.loading = false;
      }
    },

    close() {
      this.show = false;
      this.loading = false;
      this.saving = false;
      this.$emit('close');
    },

    async save() {
      try {
        this.saving = true;

        await this.$axios.put(`/analysis/order-control/${this.orderId}`, {
          status: this.status,
          justificativa: this.justification,
        });

        this.$snotify.success('Aprovação alterada com sucesso', 'Sucesso');
        this.$emit('save');
      } catch (error) {
        this.$snotify.error('Erro ao alterar aprovação', 'Atenção');
        console.warn(error);
      } finally {
        this.saving = false;
        this.show = false;
      }
    },
  }
}
</script>
