<template>
  <div>
    <v-row>
      <v-col cols="12">
        <card-title-settings class="mb-3">
          Parâmetros da Plataforma (descarregamento)
        </card-title-settings>
        <v-card
          dark
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="medicao_descarga"
                  :items="[
                    { text: 'Balança', value: 'balanca' },
                    { text: 'Medidor de Vazão', value: 'vazao' },
                    { text: 'Medidor (Integração)', value: 'medidor' },
                    { text: 'Vale', value: 'vale' },
                  ]"
                  label="Medição na descarga:"
                />
              </v-col>

              <v-col
                v-if="medicao_descarga === 'medidor'"
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="tipo_medidor_plataforma"
                  :items="[
                    { text: 'MilkUP', value: 'milkup' },
                    { text: 'Terceiros', value: 'terceiros' },
                  ]"
                  label="Tipo de medidor:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-text-field
                  v-model="tolerancia_dias_recebimento"
                  label="Tolerância de horas para recebimento:"
                  type="number"
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <masked-text-field
                  v-model="volume_maximo_cipagem"
                  label="Volume máximo permitido para cipagem:"
                  type="number"
                  placeholder=" "
                  :mask="{ mask: Number, min: 0, max: 99999999 }"
                  unmask
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="mostra_padroes_analise"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Mostra padrões de análise:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="altera_data_efetivacao"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Permite alterar a data de efetivação:"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="justificar_edicao_analise"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Justificativa de edição de análise obrigatória:"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="carga_lacre"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Lacre da Carga:"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="cip"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="CIP:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="obriga_lancamento_todas_analises_descarga"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Análises obrigatórias para descarga:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="bloquear_cip_cargas_nao_finalizadas"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Bloquear CIP de Silo com cargas não finalizadas:"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <masked-text-field
                  v-model="limite_diferenca_medidos_percentual"
                  label="Limite diferença entre medidos e vale por coleta (%):"
                  placeholder=" "
                  :mask="{ mask: Number, min: 0, max: 100 }"
                  unmask
                />
              </v-col>

              <v-col
                v-if="limite_diferenca_medidos_percentual"
                cols="12"
                sm="6"
                md="3"
              >
                <v-select
                  v-model="acao_diferenca_medidos"
                  :items="[
                    { text: 'Notificar', value: 'NOTIFICAR' },
                    { text: 'Bloquear', value: 'BLOQUEAR' },
                  ]"
                  label="Ação limite diferença medidos por coleta:"
                  clearable
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <masked-text-field
                  v-model="limite_diferenca_medidos_percentual_spot"
                  label="Limite diferença entre medidos e vale por spot (%):"
                  placeholder=" "
                  :mask="{ mask: Number, min: 0, max: 100 }"
                  unmask
                />
              </v-col>

              <v-col
                v-if="limite_diferenca_medidos_percentual_spot"
                cols="12"
                sm="6"
                md="3"
              >
                <v-select
                  v-model="acao_diferenca_medidos_spot"
                  :items="[
                    { text: 'Notificar', value: 'NOTIFICAR' },
                    { text: 'Bloquear', value: 'BLOQUEAR' },
                  ]"
                  label="Ação limite diferença medidos por spot:"
                  clearable
                />
              </v-col>

              <v-col
                v-if="acao_diferenca_medidos === 'NOTIFICAR' || acao_diferenca_medidos_spot === 'NOTIFICAR'"
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="usuarios_notificacao_limite_medidos"
                  label="Usuários notificação limite dif. medidos:"
                  :items="users"
                  item-value="id_pessoa"
                  item-text="nome"
                  multiple
                  clearable
                  small-chips
                  deletable-chips
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="2"
              >
                <v-select
                  v-model="invoice_number"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Número da Nota é obrigatório?"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title class="text-subtitle-1">
            Parâmetros para análise
          </v-card-title>
          <v-data-table
            dark
            :sort-by="'nome'"
            :sort-desc="false"
            :items-per-page="15"
            :headers="[
              { text: 'Matéria-prima', value: 'nome', width: 450 },
              { text: '', value: 'actions', width: 10, align: 'end'},
            ]"
            :items="rawMaterials"
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                icon
                @click.stop="openParameterizationAnalyze(item)"
              >
                <v-icon> settings_applications </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="medicao_descarga === 'medidor' && tipo_medidor_plataforma === 'terceiros'"
    >
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title class="text-subtitle-1">
            <span>Medidores de Vazão (Integração)</span>

            <v-spacer />

            <v-btn
              icon
              @click="onFlowMeterSettingAddClick"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            dark
            :headers="[
              { text: 'Descrição', value: 'descricao', width: 100 },
              { text: 'Unidade', value: 'laticinio.nome', width: 150 },
              { text: 'Identificador Integração', value: 'identificador_integracao', width: 50, align: 'end' },
              { text: '', value: 'actions', width: 10, align: 'end', sortable: false },
            ]"
            hide-default-footer
            :items="flowMetersSettings"
          >
            <template #[`item.actions`]="{ item }">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click.stop="onFlowMeterSettingEditClick(item)">
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Editar
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.stop="onFlowMeterSettingDeleteClick(item)">
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Excluir
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <card-title-settings class="mb-3">
          Analista Responsável
        </card-title-settings>
        <v-card
          outlined
          color="rgba(255, 255, 255, 0.08)"
        >
          <v-card-text>
            <v-col
              v-if="!assinatura_analista_responsavel.length"
              class="text-center"
              cols="12"
            >
              <v-btn
                outlined
                class="mb-5 mt-5"
                dark
                @click="showFileUpload = !showFileUpload"
              >
                <v-icon>attach_file</v-icon> Adicionar arquivos
              </v-btn>
            </v-col>
            <v-col
              v-for="(file, idx) in assinatura_analista_responsavel"
              :key="idx"
              cols="12"
            >
              <file-viewer
                :value="file"
                :preview-height="165"
                @removeFile="onRemoveFile(idx)"
              />
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <file-upload
      v-model="showFileUpload"
      @insertFiles="onSelectFile"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="green mr-5"
          v-on="on"
          @click.stop="savePlatformSettings();"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </template>

      <span>Salvar configurações</span>
    </v-tooltip>

    <parameterization-analyze-dialog
      ref="paramAnalyze"
      :raw-material-id="rawMaterialSelected.id"
      :raw-material-name="rawMaterialSelected.name"
      @onClose="onCloseParameterization"
    />

    <flow-meter-settings-dialog
      ref="flowMeterSettingsDialog"
      @save="onFlowMeterSettingsSave"
    />
  </div>
</template>

<script>
import _ from "lodash";

import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue"
import ParameterizationAnalyzeDialog from "@/Domains/Settings/Components/ParameterizationAnalyzeDialog.vue";
import FlowMeterSettingsDialog from "@/Domains/Settings/Components/FlowMeter/FlowMeterSettingsDialog.vue";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {
    FileUpload,
    FileViewer,
    MaskedTextField,
    CardTitleSettings,
    ParameterizationAnalyzeDialog,
    FlowMeterSettingsDialog,
  },

  data() {
    return {
      tolerancia_dias_recebimento: null,
      volume_maximo_cipagem: null,
      medicao_descarga: null,
      tipo_medidor_plataforma: null,
      mostra_padroes_analise: true,
      assinatura_analista_responsavel: [],
      altera_data_efetivacao: true,
      justificar_edicao_analise: false,
      carga_lacre: false,
      cip: false,
      obriga_lancamento_todas_analises_descarga: false,
      bloquear_cip_cargas_nao_finalizadas: false,
      limite_diferenca_medidos_percentual: null,
      acao_diferenca_medidos: null,
      usuarios_notificacao_limite_medidos: null,
      invoice_number: false,
      limite_diferenca_medidos_percentual_spot: null,
      acao_diferenca_medidos_spot: null,

      showFileUpload: false,

      rawMaterials: [],

      flowMetersSettings: [],

      // flag da matéria prima selecionada
      rawMaterialSelected: {
        id: null,
        name: null,
      },

      users: [],
    };
  },

  mounted() {
    this.loadPlatformSettings();
    this.loadRawMaterial();
    this.loadUsers();
  },

  methods: {
    updateTags() {
      this.$nextTick(() => {
        this.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },

    async loadPlatformSettings() {
      try {
        const { data } = await this.$axios.post(`/configuracao/listaConfiguracoesPlataforma`);

        if (_.isEmpty(data)) {
          return false;
        }

        this.tolerancia_dias_recebimento = data.tolerancia_dias_recebimento;
        this.volume_maximo_cipagem = data.volume_maximo_cipagem;
        this.medicao_descarga = data.medicao_descarga_plataforma || "balanca";
        this.tipo_medidor_plataforma = data.tipo_medidor_plataforma || "terceiros";
        this.mostra_padroes_analise = _.isBoolean(data.mostra_padroes_analise) ? data.mostra_padroes_analise : true;
        this.justificar_edicao_analise = data.justificar_edicao_analise || false;
        this.assinatura_analista_responsavel = !_.isEmpty(data.assinatura_analista_responsavel) ? data.assinatura_analista_responsavel : [];
        this.carga_lacre = data.carga_lacre;
        this.cip = data.cip;
        this.obriga_lancamento_todas_analises_descarga = data.obriga_lancamento_todas_analises_descarga;
        this.bloquear_cip_cargas_nao_finalizadas = data.bloquear_cip_cargas_nao_finalizadas;
        this.limite_diferenca_medidos_percentual = data.limite_diferenca_medidos_percentual;
        this.acao_diferenca_medidos = data.acao_diferenca_medidos;
        this.usuarios_notificacao_limite_medidos = data.usuarios_notificacao_limite_medidos;
        this.invoice_number = data.invoice_number;
        this.limite_diferenca_medidos_percentual_spot = data.limite_diferenca_medidos_percentual_spot;
        this.acao_diferenca_medidos_spot = data.acao_diferenca_medidos_spot;
        this.flowMetersSettings = data.configuracoes_medidores_integracao || [];

      } catch (e) {
        console.error(e);
      }
    },

    async savePlatformSettings() {
      try {
        const payLoad = {
          configuracoesPlataforma: JSON.stringify([
            {
              tolerancia_dias_recebimento: this.tolerancia_dias_recebimento || null,
              volume_maximo_cipagem: this.volume_maximo_cipagem || null,
              medicao_descarga_plataforma: this.medicao_descarga,
              tipo_medidor_plataforma: this.tipo_medidor_plataforma,
              mostra_padroes_analise: this.mostra_padroes_analise,
              assinatura_analista_responsavel: this.assinatura_analista_responsavel,
              altera_data_efetivacao: this.altera_data_efetivacao,
              justificar_edicao_analise: this.justificar_edicao_analise,
              carga_lacre: this.carga_lacre,
              cip: this.cip,
              obriga_lancamento_todas_analises_descarga: this.obriga_lancamento_todas_analises_descarga,
              bloquear_cip_cargas_nao_finalizadas: this.bloquear_cip_cargas_nao_finalizadas,
              limite_diferenca_medidos_percentual: this.limite_diferenca_medidos_percentual,
              acao_diferenca_medidos: this.acao_diferenca_medidos,
              usuarios_notificacao_limite_medidos: this.usuarios_notificacao_limite_medidos,
              invoice_number: this.invoice_number,
              limite_diferenca_medidos_percentual_spot: this.limite_diferenca_medidos_percentual_spot,
              acao_diferenca_medidos_spot: this.acao_diferenca_medidos_spot,
              configuracoes_medidores_integracao: this.flowMetersSettings,
            },
          ]),
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracoesPlataforma`,
          this.$qs.stringify(payLoad)
        );

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        //@todo tratar erro
        if (_.get(data, "codigo") !== 1) {
          throw data.mensagem;
        }

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");
        this.$store.dispatch('updatePlatformSettings');
      } catch (error) {
        console.log(error);
        this.$snotify.error("Erro ao salvar informações do produtor", "Atenção");
      }
    },

    /**
     * Busca item de máteria-prima
     */
    async loadRawMaterial() {
      try {
        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawMaterials = data;
      } catch (e) {
        console.warn(e);
      }
    },

    async loadUsers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/listaUsuarioJson`, {
          exibir: 'ATIVOS'
        });

        this.users = data;

      } catch (error) {
        this.$snotify.error('Erro ao carregar usuários', 'Atenção');
        console.warn(error);
      }
    },

    /**
     * Abre o dialog de parâmetrização
     */
    openParameterizationAnalyze(item) {

      this.rawMaterialSelected =  {
        id: item.id_item,
        name: item.nome
      };

      this.$refs.paramAnalyze.openDialog();
    },

    onSelectFile(files) {
      this.assinatura_analista_responsavel = files.map(file => ({
        success: true,
        name: file.name,
        size: file.size,
        url: file.url
      }));
    },

    onRemoveFile(idx) {
      this.assinatura_analista_responsavel.splice(idx, 1);
    },

    /**
     * Trata close do model de parâmetro
     */
    onCloseParameterization() {
      this.rawMaterialSelected =  {
        id: null,
        name: null
      };
    },

    onFlowMeterSettingAddClick() {
      return this.$refs.flowMeterSettingsDialog.show();
    },

    onFlowMeterSettingEditClick(item) {
      return this.$refs.flowMeterSettingsDialog.show(item);
    },

    onFlowMeterSettingDeleteClick(item) {
      this.flowMetersSettings = this.flowMetersSettings.filter(flowMeterSettings => {
        return flowMeterSettings.id !== item.id;
      });
    },

    onFlowMeterSettingsSave(item) {
      if (item.id) {
        this.flowMetersSettings = this.flowMetersSettings.map(flowMeterSettings => {
          if (flowMeterSettings.id !== item.id) {
            return flowMeterSettings;
          }

          return item;
        });

        return;
      }

      this.flowMetersSettings = [
        ...this.flowMetersSettings,
        {
          ...item,
          id: uuidv4(),
        },
      ]
    }
  },
};
</script>
