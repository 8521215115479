<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="text-xs-center"
      >
        <h2 class="menu-header white--text mt-0 mb-2">
          Diferença de volume medidos e vale
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    
    <v-tabs
      v-model="tab"
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#in-tab">
        Recebimento
      </v-tab>
      <v-tab href="#daily-in-tab">
        Recebimento Diário
      </v-tab>
      <v-tab href="#daily-in-total-tab">
        Recebimento Diário Total
      </v-tab>
      <v-tab-item value="in-tab">
        <diff-mesured-vale-tab />
      </v-tab-item>
      <v-tab-item value="daily-in-tab">
        <diff-mesured-vale-daily-tab />
      </v-tab-item>
      <v-tab-item value="daily-in-total-tab">
        <diff-mesured-vale-daily-total-tab />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import DiffMesuredValeTab from "./Views/DiffMesuredValeTab.vue"
import DiffMesuredValeDailyTab from "./Views/DiffMesuredValeDailyTab.vue"
import DiffMesuredValeDailyTotalTab from "./Views/DiffMesuredValeDailyTotalTab.vue"

export default {
  components: {
    DiffMesuredValeTab,
    DiffMesuredValeDailyTab,
    DiffMesuredValeDailyTotalTab
  },

  data() {
    return {
      tab: null,
    };
  },
}
</script>
