<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Usuários
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>

        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-tabs
      v-model="selectedTab"
      centered
      background-color="transparent"
      class="transparent"
      fixed-tabs
      dark
    >
      <v-tab>
        Cadastro
      </v-tab>
      <v-tab
        href="#permission-users-tab"
      >
        Permissões
      </v-tab>
      <v-tabs-items
        v-model="selectedTab"
      >
        <v-tab-item
          justify-center
          class="pa-3"
        >
          <v-card
            v-if="selectedTab === 0"
            dark
            color="transparent"
          >
            <v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="pt-0"
                >
                  <v-autocomplete
                    v-model="filters.show"
                    :items="[
                      { text: 'Ativos', value: 'ATIVOS' },
                      { text: 'Inativos', value: 'INATIVOS' },
                      { text: 'Todos', value: 'TODOS' },
                    ]"
                    label="Exibir"
                    placeholder=" "
                    hide-details
                    dark
                    filled
                    @change="loadUsers()"
                  />
                </v-col>
                <v-spacer />
                <v-col
                  cols="12"
                  md="4"
                  class="pt-0"
                >
                  <v-text-field
                    v-model="filters.search"
                    append-icon="search"
                    label="Busca"
                    single-line
                    hide-details
                    filled
                  />
                </v-col>
              </v-row>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="items"
              :search="filters.search"
              dark
              @click:row="onUserClick"
            >
              <template #[`item.actions`]="{ item }">
                <v-menu
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="hasInsertAccess && item.ativo"
                      small
                      @click="editUser(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="hasDeleteAccess && item.ativo"
                      small
                      @click="deleteUser(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>person_add_disabled</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Inativar</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="hasDeleteAccess && !item.ativo"
                      small
                      @click="activateUser(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>person_add</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Reativar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <v-overlay :value="loading">
              <v-card-text>
                Carregando...
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                />
              </v-card-text>
            </v-overlay>
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="permission-users-tab"
          justify-center
          class="permission-users-tab pa-3"
        >
          <v-card
            v-if="selectedTab === 'permission-users-tab'"
            dark
            color="transparent"
          >
            <v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                >
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-autocomplete
                        v-model="selectedPrincipalPermissions"
                        outlined
                        chips
                        small-chips
                        multiple
                        prepend-inner-icon="vpn_key"
                        hide-details
                        label="Permissões"
                        :items="listPrincipalPermissions"
                        item-text="descricao"
                        item-value="id"
                        clearable
                        dark
                        filled
                        @change="filterPrincipalPermissions"
                        ref="permissaoPrincipal"
                      >
                        <template #selection="{item}">
                          <v-chip
                            small
                            text-color="white"
                            color="green darken-1"
                          >
                            <v-icon
                              left
                              small
                            >
                              map
                            </v-icon>
                            {{ item.descricao }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="6"
                    >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <data-table
                        ref="report"
                        :headers="headersPermissions"
                        :items="listPermissionsForPrincipal"
                        :expanded.sync="selectedRow"
                        item-key="id"
                        dark
                        class="elevation-1 bonus-list-table"
                        show-expand
                        style="overflow-x: hidden !important;"
                        :items-per-page="200"
                      >
                        <template
                          #expanded-item="{ headers, item }">
                          <td></td>
                          <td>
                            <v-row>
                              <v-col
                              cols="12"
                              >
                                <v-card
                                  dark
                                >
                                  <v-card-text>
                                    <v-autocomplete
                                      v-model="item.usuarios"
                                      outlined
                                      chips
                                      small-chips
                                      multiple
                                      prepend-inner-icon="vpn_key"
                                      hide-details
                                      label="Usuários"
                                      :items="items"
                                      item-text="nome"
                                      item-value="id_pessoa"
                                      clearable
                                      dark
                                      filled
                                      :key="item.id_permissao"
                                      @change="savePermissions(item.usuarios, item)"
                                      ref="autocomplete"
                                    >
                                    </v-autocomplete>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </td>
                        </template>

                      </data-table>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card-title>
          </v-card>
          <v-overlay :value="loading">
            <v-card-text>
              Carregando...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              />
            </v-card-text>
          </v-overlay>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <dialog-user
      v-model="registrationDialog.show"
      :id-user="registrationDialog.id"
      @save="loadUsers()"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          v-if="hasInsertAccess"
          fixed
          fab
          large
          dark
          bottom
          right
          color="light-blue mr-5"
          v-on="on"
          @click="addUser()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Inserir Usuário</span>
    </v-tooltip>
  </v-container>
</template>
<style>
.permission-users-tab .v-data-table__wrapper {
  overflow-x: hidden !important;
}
</style>

<script>
import DialogUser from '@/Domains/Registrations/User/Components/DialogUser.vue';

export default {
  name: 'User',

  components: {
    DialogUser,
  },

  data() {
    return {
      filters: {
        show: 'ATIVOS',
        search: '',
      },

      loading: false,

      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Cargo', value: 'cargo' },
        { text: 'Email', value: 'email' },
        { text: 'Celular', value: 'celular' },
        { text: '', value: 'actions', sortable: false, align: 'end', width: 20 },
      ],

      items: [],

      registrationDialog: {
        show: false,
        id: null,
      },
      selectedTab: 0,
      listPermissions: [],
      selectedPrincipalPermissions: '',
      listPrincipalPermissions: [],
      listPermissionsForPrincipal: [],
      headersPermissions: [
        { text: 'Caminho', value: 'caminho', default: true, sorted: false },
      ],
      open: [1],
      selectedRow: [],
    };
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasInsertAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'user-insert-edit' && o.tipo === 'COMPONENTE');
    },

    hasDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'user-delete' && o.tipo === 'COMPONENTE');
    },
  },

  created() {
    this.loadUsers();
    this.loadPermissions();
  },

  methods: {
    onUserClick(user) {
      if (user.ativo) {
        this.editUser(user)
      }
      else {
        this.activateUser(user)
      }
    },

    addUser() {
      this.registrationDialog.show = true;
      this.registrationDialog.id = null;
    },

    editUser({ id_pessoa }) {
      this.registrationDialog.show = true;
      this.registrationDialog.id = id_pessoa;
    },

    async deleteUser({ id_pessoa }) {
      try {
        if (!(await this.$root.$confirm('Inativar usuário', 'Tem certeza que deseja inativar este usuário?', { color: 'red' }))) {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/inativa`, {
          id_pessoa: id_pessoa,
        });

        const [ response ] = data;

        if (!response.codigo) {
          throw 'PHP Error';
        }

        this.$snotify.success('Usuário inativado com sucesso', 'Sucesso');
        this.loadUsers();
      } catch (error) {
        this.$snotify.error('Erro ao inativar usuário', 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async activateUser({ id_pessoa }) {
      try {
        if (!(await this.$root.$confirm('Reativar usuário', 'Tem certeza que deseja reativar este usuário?', { color: 'green' }))) {
          return;
        }

        this.loading = true;

        await this.$axios.post(`/pessoa/reativar`, {
          id_pessoa: id_pessoa,
        });

        this.$snotify.success('Usuário reativado com sucesso', 'Sucesso');
        this.loadUsers();
      } catch (error) {
        this.$snotify.error('Erro ao reativar usuário', 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadUsers() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaUsuarioJson`, {
          exibir: this.filters.show
        });

        this.items = data;

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    handleSearchPermission: function (val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },

    async loadPermissions() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/permissaoPessoa/listaPermissaoPrincipal`,
        );

        this.listPrincipalPermissions = data;

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async filterPrincipalPermissions() {
      try {
        this.$refs.permissaoPrincipal.blur();
        this.loading = true;

        const { data } = await this.$axios.post(
          `/permissaoPessoa/filtraPermissaoPrincipal`, {
            permissions: this.selectedPrincipalPermissions
          });

        this.listPermissionsForPrincipal = data.map(function(item) {
          return {
            id_permissao: item.id_permissao,
            id: item.id,
            id_pai: item.id_pai,
            descricao: item.descricao,
            caminho: this.contarTabsParaMaior(item.caminho)+item.caminho,
            usuarios: item.usuarios,
            usuariosOriginal: item.usuariosOriginal.map(x => x.id_pessoa),
            temFilhos: item.tem_filhos,
          }
        }, this);

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    contarTabsParaMaior(str) {
      const numeroDeMaior = (str.match(/>/g) || []).length;
      return '\t'.repeat(numeroDeMaior);
    },

    async loadPermissionsUser() {
      try {
        const { data } = await this.$axios.post(
          `/permissaoPessoa/listaPermissaoPessoa`,
          this.$qs.stringify({
            id_pessoa: '470ae1b0-64a0-407b-9d94-f2106de30b83',
            id_laticinio: 'a52a0b39-14ef-494a-8042-19ff46c306d2',
          })
        );
        this.permissionSelected = data;
      } catch (error) {
        console.warn(error);
      }
    },
    async savePermissions (usuarios, item) {
      try {
        this.$refs.autocomplete.blur();
        this.loading = true;
        var usuariosSelectedAdd = '';
        var usuariosSelectedDelete = '';

        usuariosSelectedAdd = usuarios.filter(usuario => !item.usuariosOriginal.includes(usuario));
        usuariosSelectedDelete = item.usuariosOriginal.filter(usuario => !usuarios.includes(usuario));

        const { data } = await this.$axios.post(
          `/permissaoPessoa/salvaPermissoes`,
          this.$qs.stringify({
            usuariosSelectedAdd: usuariosSelectedAdd,
            usuariosSelectedDelete: usuariosSelectedDelete,
            permissao: item,
          })
        );

        this.filterPrincipalPermissions();

        if (data.codigo) {
          this.$snotify.success(data.mensagem, 'Sucesso');
          return;
        }

        this.$snotify.success(data.mensagem, 'Erro');
        return;

      } catch (error) {
        console.warn(error);
      }
    },
  },
}
</script>

