<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Diferença de volume medidos e vale por rota
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="3"
              class="pb-0"
            >
              <date-range-picker
                v-model="filters.date.input"
                @change="onDateFilter"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="pb-0"
            >
              <routes-autocomplete-filter
                v-model="filters.routes"
                label="Rota"
                dark
                multiple
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="pb-0"
            >
              <person-autocomplete-filter
                v-model="filters.freighter"
                label="Prestador"
                type="SUPPLIER"
                :return-object="false"
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="pb-0"
            >
              <v-text-field
                v-model="filters.search"
                filled
                prepend-inner-icon="search"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <data-table
          ref="report"
          name="Diferença de Volume por Rota"
          :headers="headers"
          :items="itemsWithLiquid"
          :search="filters.search"
          :loading="loading"
          group-by="nome_rota"
          dark
        >
          <template #[`item.diferenca`]="{ value }">
            <v-tooltip
              v-if="value < 0"
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  small
                  v-bind="attrs"
                  color="red darken-1"
                  v-on="on"
                >
                  {{ formatNumber(value) }}
                </v-chip>
              </template>
              Diferença = Vale -  Medidos
            </v-tooltip>
            <v-chip
              v-else
              small
              color="teal darken-1"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.desconto`]="{ value }">
            <v-tooltip
              v-if="value < 0"
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  small
                  v-bind="attrs"
                  color="red darken-1"
                  v-on="on"
                >
                  {{ formatNumber(value) }}
                </v-chip>
              </template>
              (Diferença - tolerância) > 0
            </v-tooltip>
            <v-chip
              v-else
              small
              color="teal darken-1"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`footer.prepend`]>
            <div class="text-caption text-left ml-4">
              Total Vale:
              <v-chip
                small
                class="mx-2 mb-0"
              >
                {{ formatNumber(totalVale) }}
              </v-chip>
            </div>
            <div class="text-caption text-left ml-4">
              Total Medidos:
              <v-chip
                small
                class="mx-2 mb-0"
              >
                {{ formatNumber(totalMeasured) }}
              </v-chip>
            </div>
            <div class="text-caption text-left ml-4">
              Total Diferença:
              <v-chip
                small
                class="mx-2 mb-0"
                :color="totalDiff < 0 ? 'red darken-1' : 'teal darken-1'"
              >
                {{ formatNumber(totalDiff) }}
              </v-chip>
            </div>
            <div class="text-caption text-left ml-4">
              Total Desconto:
              <v-chip
                small
                class="mx-2 mb-0"
                :color="totalDiscount < 0 ? 'red darken-1' : 'teal darken-1'"
              >
                {{ formatNumber(totalDiscount) }}
              </v-chip>
            </div>
          </template>
        </data-table>
      </v-card>
    </v-flex>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  components: {
    DateRangePicker,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
  },

  data() {
    return {
      loading: false,

      filters: {
        date: { input: 'today', range: [] },
        routes: [],
        freighter: null,
        search: '',
      },

      headers: [
        { text: 'Código', value: 'cod_itinerario' },
        { text: 'Rota', value: 'nome_rota' },
        { text: 'Data Descarga', value: 'data', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Data/Hora Início', value: 'data_hora_inicio', formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm') },
        { text: 'Prestador', value: 'nome_prestador' },
        { text: 'Vale', value: 'peso_vale', formatter: value => this.formatNumber(parseFloat(value) || 0.0), mask: '0,00' },
        { text: 'Descartado', value: 'descartado', formatter: value => this.formatNumber(parseFloat(value) || 0.0), mask: '0,00' },
        { text: 'Liquido', value: 'peso_liquido', formatter: value => this.formatNumber(parseFloat(value) || 0.0), mask: '0,00' },
        { text: 'Medidos', value: 'peso_medido', formatter: value => this.formatNumber(parseFloat(value) || 0.0), mask: '0,00' },
        { text: 'Diferença', value: 'diferenca', formatter: value => this.formatNumber(parseFloat(value) || 0.0), mask: '0,00' },
        { text: 'Desconto', value: 'desconto', formatter: value => this.formatNumber(parseFloat(value) || 0.0), mask: '0,00' },
      ],

      items: [],
    };
  },

  computed: {
    itemsWithLiquid() {
      return this.items.map(item => ({
        ...item,
        peso_liquido: item.peso_vale - item.descartado  || 0
      }))
    },
    totalVale() {
      return this.items.reduce((acc, cur) => acc + (parseFloat(cur.peso_vale) || 0), 0)
    },
    totalMeasured() {
      return this.items.reduce((acc, cur) => acc + (parseFloat(cur.peso_medido) || 0), 0)
    },
    totalDiff() {
      return this.items.reduce((acc, cur) => acc + (parseFloat(cur.diferenca) || 0), 0)
    },
    totalDiscount() {
      return this.items.reduce((acc, cur) => acc + (parseFloat(cur.desconto) || 0), 0)
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    onDateFilter(event) {
      this.filters.date.range = event;

      this.loadReport();
    },

    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.filters.date.range;

        const { data } = await this.$axios.post(
          `/descargaColeta/diferencaMedidosValePorRota`,
          {
            data_inicio: startDate || moment().format("YYYY-MM-DD"),
            data_fim: endDate || moment().format("YYYY-MM-DD"),
            rotas: this.filters.routes.map(({ id }) => id),
            id_prestador: this.filters.freighter,
          }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.items = _.sortBy(data, _.property('data'));
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async print() {
      const [startDate, endDate] = this.filters.date.range;
      const title = `DIFERENÇA DE VOLUME MEDIDOS E VALE POR ROTA - ${moment(startDate).format('DD/MM/YY').toUpperCase()} - ${moment(endDate).format('DD/MM/YY').toUpperCase()}`;

      const json = this.$refs.report.getReportJson();

      if (json.length === 0) {
        return;
      }

      let body = [];

      const headers = Object.keys(json[0])
        .map(header => ({
          key: header,
          label: header,
          class: 'text-left'
        }));

      const totals = {
        '': '<b>Total:</b>'
      };

      headers.forEach((item) => {
        switch (item.key) {
          case 'Vale':
            totals['Vale'] = this.formatNumber(this.totalVale);
            break;
          case 'Medidos':
            totals['Medidos'] = this.formatNumber(this.totalMeasured);
            break;
          case 'Diferença':
            totals['Diferença'] = this.formatNumber(this.totalDiff);
            break;
          case 'Desconto':
            totals['Desconto'] = this.formatNumber(this.totalDiscount);
            break;
        }
      });

      const groupBy = this.$refs.report.groupedColumns[0];

      const data = _.groupBy(json, groupBy.text)
      _.forEach(data, (items, name) => {
          const totalPorRotaPesoVale = items.reduce((acc, cur) => acc + (cur.Vale ? parseFloat(cur.Vale.replace('.', '').replace(',', '.')) : 0), 0);
          const totalPorRotaPesoMedio = items.reduce((acc, cur) => acc + (cur.Medidos ? parseFloat(cur.Medidos.replace('.', '').replace(',', '.')) : 0), 0);
          const totalPorRotaDiferenca = items.reduce((acc, cur) => acc + (cur.Diferença ? parseFloat(cur.Diferença.replace('.', '').replace(',', '.')) : 0), 0);
          const totalPorRotaDesconto = items.reduce((acc, cur) => acc + (cur.Desconto ? parseFloat(cur.Desconto.replace('.', '').replace(',', '.')) : 0), 0);

          const totalsForRoute = {
            '': '<b>Total:</b>'
          };

          totalsForRoute['Vale'] = this.formatNumber(totalPorRotaPesoVale);
          totalsForRoute['Medidos'] = this.formatNumber(totalPorRotaPesoMedio);
          totalsForRoute['Diferença'] = this.formatNumber(totalPorRotaDiferenca);
          totalsForRoute['Desconto'] = this.formatNumber(totalPorRotaDesconto);

          body.push({
            tag: 'section',
            class: 'row',
            children: [{
              tag: 'datatable',
              title: name,
              headers,
              items,
            },
            {
              tag: 'div',
              children: [{
                tag: 'div',
                class: 'six columns',
                contents: '&nbsp;'
              },
              {
                tag: 'div',
                class: 'six columns',
                children: [{
                  tag: 'datatable',
                  headers: Object.keys(totalsForRoute)
                    .map(header => ({
                      key: header,
                      label: header,
                      class: 'text-left'
                    })),
                  items: [totalsForRoute],
                }]
              }]
            },
            ]
          });
        });

      body.push({
        tag: 'section',
        class: 'row',
        children: [
          {
            tag: 'div',
            class: 'twelve columns',
            children: [{
              tag: 'datatable',
              headers: Object.keys(totals)
                .map(header => ({
                  key: header,
                  label: header,
                  class: 'text-left'
                })),
              items: [totals],
            }]
          },
        ]
      });

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.$refs.report.printPdf({ pages: [{ title, header, body }] });
    },

    exportExcel() {
      this.$refs.report.exportExcel();
    },

    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
