<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Fechamento de Frete T2
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Volume Total"
          icon="icon-coleta_principal"
          :value="`${ formatNumber(totalVol) } L`"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Total Fretes"
          icon="icon-frota"
          :value="formatCurrency(totalNetAmount)"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Descontos"
          icon="icon-contas_pagar"
          :value="formatCurrency(totalDiscounts)"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Total à Pagar"
          icon="icon-quitacao_contas"
          :value="formatCurrency(totalPaidAmount)"
          :loading="loading"
        />
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        color="transparent"
        dark
        class="mt-5"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="2"
            >
              <v-menu
                ref="menu"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="dateRefFilter"
                    label="Mês de Referência"
                    prepend-inner-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filters.date"
                  :max="maxDateRef"
                  type="month"
                  no-title
                  scrollable
                  @change="loadReport"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="4"
              offset-md="4"
              offset-lg="6"
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </v-card-title>
        <data-table
          ref="report"
          v-model="selectedProviders"
          :headers="headers"
          :items="items"
          :search="filters.search"
          :loading="loading"
          class="elevation-1"
          :items-per-page="-1"
          item-key="id_fechamento_frete"
          show-expand
          single-expand
          show-select
          hide-default-footer
        >
          <template
            v-if="!filters.search"
            #no-data
          >
            <div
              v-if="!loading"
              style="height: 300px; display: flex;"
            >
              <v-row
                class="justify-center align-center"
              >
                <v-btn
                  class="ma-2"
                  outlined
                  color="white"
                  @click="regenerate"
                >
                  Gerar fechamento do período
                </v-btn>
              </v-row>
            </div>
          </template>

          <template #[`item.id_titulo`]="{ item, value }">
            <v-btn
              v-if="value"
              icon
              @click.stop="showBill(item)"
            >
              <v-icon>
                attach_money
              </v-icon>
            </v-btn>
          </template>

          <template #expanded-item="{ headers, item: fechamento }">
            <td
              :colspan="headers.length"
              class="pa-0 text-center"
            >
              <data-table
                name="Fechamento de Frete de Transferência"
                :headers="details.headers"
                :items="fechamento.fretes"
                :items-per-page="-1"
                dense
                hide-default-footer
                class="elevation-1 ma-3"
              >
                <template #[`item.tipo_pagamento`]="{ value, item }">
                  {{ value }}

                  <template v-if="item.id_faixa_frete && !fechamento.id_titulo">
                    <v-menu
                      v-model="item.show"
                      :close-on-content-click="false"
                      :nudge-width="150"
                    >
                      <template #activator>
                        <v-tooltip
                          top
                        >
                          <template #activator="{ on }">
                            <v-icon
                              small
                              class="ml-3"
                              v-on="on"
                              @click="item.show = true"
                            >
                              edit
                            </v-icon>
                          </template>

                          Alterar tabela de Faixas
                        </v-tooltip>
                      </template>

                      <price-range-selector-settings
                        v-model="item.tabela"
                        :tables="tables"
                        @input="recalculate(item)"
                        @close="item.show = false"
                      />
                    </v-menu>
                  </template>
                </template>
              </data-table>
            </td>
          </template>
        </data-table>
        <v-card-title>
          <v-spacer />
        </v-card-title>
      </v-card>
    </v-flex>

    <v-dialog
      v-model="confirmBillDialog.show"
      :max-width="380"
    >
      <v-card>
        <v-card-title
          :class="`blue--text text-h5`"
        >
          Gerar financeiro
        </v-card-title>
        <v-card-text>
          <date-picker
            v-model="confirmBillDialog.dueDate"
            label="Data de vencimento"
            prepend-inner-icon="event"
            persistent-hint
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            outlined
            @click.native="confirmBillDialog.show = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue"
            outlined
            @click.native="generateFinancial"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      :type="billDialog.type"
      @input="billDialog.id = null"
      @removeBill="loadReport"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="items.length > 0"
        color="deep-purple lighten-1"
        fab
        dark
        @click="confirmBillDialog.show = true"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              attach_money
            </v-icon>
          </template>

          Gerar financeiro
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="items.length > 0"
        fab
        dark
        color="blue darken-3"
        @click="save()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>

          Salvar
        </v-tooltip>
      </v-btn>

      <v-btn
        color="blue darken-4"
        fab
        dark
        @click="regenerate"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              autorenew
            </v-icon>
          </template>

          Resetar Fechamento
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-2"
        @click="exportDetailExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Detalhes (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selectedProviders.length"
        fab
        dark
        color="orange darken-4"
        @click="onPrintInvoice"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              receipt_long
            </v-icon>
          </template>
          Imprimir Recibo
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="grey darken-4"
        @click="settingsDialog.show = true"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              settings
            </v-icon>
          </template>
          Configurações
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <print-settings-dialog
      ref="print-settings"
      @print="printInvoice"
    />

    <freight-settings-dialog
      v-model="settingsDialog.show"
    />

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import FreightSettingsDialog from '@/Domains/Collects/Management/Components/FreightSettingsDialog.vue';
import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import DatePicker from "@/Domains/Visits/Schedule/Components/DatePicker.vue";
import CardInfo from "@/Domains/BI/Components/CardInfo.vue";
import PrintSettingsDialog from '@/Support/Components/PrintSettingsDialog.vue';
import PriceRangeSelectorSettings from '@/Domains/Collects/Management/FreightValueTable/Components/PriceRangeSelectorSettings.vue'

import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    FreightSettingsDialog,
    BillDialog,
    DatePicker,
    CardInfo,
    PrintSettingsDialog,
    PriceRangeSelectorSettings,
  },

  mixins: [ReportMixin],

  data() {
    return {
      baseValue: 0,
      milkPriceAvg: 0,

      loading: false,

      filters: {
        date: moment().subtract(1, "month").format("YYYY-MM"),
        search: ''
      },

      headers: [
        { text: 'Prestador', value: 'nome_prestador' },
        { text: 'Volume (L)', value: 'volume', width: 180, formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
        { text: 'Desconto/Sobra (L)', value: 'peso_desconto', width: 180, formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
        { text: 'Medidos (L)', value: 'peso_medido', width: 180, formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
        { text: 'Distância', value: 'distancia_odometro', width: 180, formatter: value => this.formatNumber(value) + ' Km', mask: '#,##0.00' },
        { text: 'Valor Fretes', value: 'valor_fretes', width: 180, formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.0000' },
        { text: 'Valor Desc.', value: 'valor_desconto_leite', width: 180, formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.0000' },
        { text: 'Valor a Pagar', value: 'valor_total', width: 180, formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.0000' },
        { text: '', altText: 'Conta', value: 'id_titulo', width: 70, sortable: false },
      ],

      items: [],

      details: {
        headers: [
          { text: 'Código', value: 'cod_itinerario' },
          { text: 'Motorista', value: 'nome_motorista' },
          { text: 'Placa', value: 'placa' },
          { text: 'Data', value: 'data', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
          { text: 'Volume', value: 'volume', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
          { text: 'Desconto/Sobra', value: 'peso_desconto', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
          { text: 'Medidos', value: 'peso_medido', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
          { text: 'Distância', value: 'distancia_odometro', formatter: value => this.formatNumber(value) + ' Km', mask: '' },
          { text: 'Taxa de ocupação', value: 'taxa_ocupacao', formatter: value => this.formatNumber(value) + ' %', mask: '#,##0.00' },
          { text: 'Tipo pag.', value: 'tipo_pagamento' },
          { text: 'Valor', value: 'valor', formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.0000' },
          { text: 'Valor Frete', value: 'valor_frete', width: 180, formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.00' },
          { text: 'L/Km', value: 'media_litros_km', align: 'end', formatter: value => this.formatNumber(value), mask: '#,##0.00' },
          { text: 'R$/L', value: 'valor_litro', align: 'end', formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.0000' },
        ],
      },

      // Exibe dialogo de financeiro
      confirmBillDialog: {
        show: false,
        dueDate: moment().toDate(),
      },

      // Exibe dialogo de financeiro
      billDialog: {
        show: false,
        type: 'VALE_PRESTADOR',
        id: null,
      },

      settingsDialog: {
        show: false,
      },

      selectedProviders: [],

      tables: [],
    };
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY-MM");
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, "YYYY-MM").format("MM/YYYY");
    },

    /**
     * Recupera o volume total de leite
     * @returns {Number}
     */
    totalVol() {
      if (this.items.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.items, 'volume');
    },

    /**
     * Recupera o valor líquido a pagar
     * @returns {Number}
     */
    totalNetAmount() {
      if (this.items.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.items, 'valor_fretes');
    },

    /**
     * Recupera o valor total de Descontos de Valor do Leite
     * @returns {Number}
     */
    totalDiscounts() {
      if (this.items.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.items, 'valor_desconto_leite');
    },

    /**
     * Recupera o valor total a pagar
     * @returns {Number}
     */
    totalPaidAmount() {
      if (this.items.length === 0) {
        return 0.0;
      }

      return _.sumBy(this.items, 'valor_total');
    },

    respectMinimumFreight() {
      return this.$store.state.settings.gerais.considerar_frete_minimo || false;
    },
  },

  mounted() {
    this.loadRanges();
    this.loadReport();
  },

  methods: {
    async loadRanges() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/settings/freight-ranges`);

        this.tables = data;

      }
      catch (error) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar as faixas!', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/freight/transfer`, { params: {
          month_reference: this.filters.date
        } });

        this.items = data;
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    /**
     * Action responsável por re-fazer o fechamento do período
     * @returns {Promise<void>}
     */
    async regenerate() {
      if (this.items.length > 0) {
        if (!(await this.$root.$confirm(
          'Atenção',
          'Esta ação irá descartar <b>TODOS</b> os dados preenchidos do período selecionado, independente dos filtros aplicados<br>Clique em SIM para Confirmar.',
          { color: 'orange' }
        ))) {
          return;
        }
      }
      try {
        this.loading = true;

        await this.$axios.post(`/freight/transfer`, {
          month_reference: this.filters.date,
        });

        return this.loadReport();
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Action responsável por gerar o financeiro do período
     * @returns {Promise<void>}
     */
    async generateFinancial() {
      try {
        this.loading = true;

        await this.$axios.post(
          `/freight/transfer/generate-financial`,
          {
            month_reference: this.filters.date,
            due_date: moment(this.confirmBillDialog.dueDate).format('YYYY-MM-DD')
          }
        );

        this.$snotify.success("Financeiro gerado com sucesso", "Sucesso");

        return this.loadReport();
      } catch (err) {
        this.$snotify.error("Erro ao gerar financeiro", "Atenção");

        console.warn(err);
      } finally {
        this.loading = false;
        this.confirmBillDialog.show = false;
      }
    },

    recalculate(frete) {
      if (frete.tipo_pagamento === 'FAIXA') {
        // Cálculo igual no backend ao resetar o fechamento
        const distance = frete.distancia_odometro;

        const maxRanges = frete.tabela.faixas.filter((faixa) => faixa.km < distance).sort((a, b) => a.km - b.km).splice(-2);

        const range = maxRanges.at(-1);

        frete.id_faixa_frete = frete.tabela.id_faixa_frete;

        frete.valor = range.valor;

        frete.valor_frete = distance * frete.valor;

        if (this.respectMinimumFreight && maxRanges.length === 2) {
          const previousRange = maxRanges[0];

          const minDistance = range.km - 1;
          const minValue = minDistance * previousRange.valor;

          frete.valor_frete = Math.max(minValue, frete.valor_frete);

          frete.valor = frete.valor_frete / distance;
        }
      }
    },

    async save() {
      try {
        this.loading = true;

        const fretes = this.items
          .flatMap(item => item.fretes)
          .map(frete => ({
            id_itinerario: frete.id_itinerario,
            id_faixa_frete: frete.id_faixa_frete,
            valor: frete.valor,
            valor_frete: frete.valor_frete,
          }));

        await this.$axios.put(`/freight/transfer`, {
          month_reference: this.filters.date,
          freights: fretes,
        } );

        this.$snotify.success('Fechamento salvo com sucesso', 'Sucesso');

      } catch (err) {
        this.$snotify.error('Erro ao salvar fechamento', 'Atenção');

        console.warn(err);
      } finally {
        this.loading = false;
        this.loadReport();
      }
    },

    showBill(item) {
      this.billDialog.type = item.valor_total > 0 ? 'VALE_PRESTADOR' : 'DESCONTO_PRESTADOR';
      this.billDialog.id = item.id_titulo;
      this.billDialog.show = true;
    },

    async onPrintInvoice() {
      this.$refs['print-settings'].show({
        params: [
          { title: 'Dados Fretes T1', key: 'mostra_fechamento_coleta', value: false },
        ]
      });
    },

    async printInvoice({ params }) {
      try {
        this.loading = true;

        if (_.isEmpty(this.selectedProviders)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/freight/transfer/print`,
          {
            month_reference: this.filters.date,
            ids: this.selectedProviders.map(row => row.id_prestador),
            params,
          }
        );

        return this.printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao imprimir os recibos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    getReportTitle() {
      return `Fretes - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    getDetailHeaders() {
      const name = 'Fechamento de Frete - Detalhes';
      const config = this.$store.state.settings.reports[name];
      let headers = this.details.headers;
      if (config) {
        headers = config
          .filter(item => item.show && headers.some(header => header.value === item.value))
          .map(item => headers.find(header => header.value === item.value));
      }
      return [{ text: 'Transportador', value: 'nome_prestador' }, ...headers];
    },

    getDetailJson() {
      const headers = this.getDetailHeaders();
      return this.items.flatMap(item => {
        const items = item.fretes
          .map(frete => ({
            'nome_prestador': item.nome_prestador,
            ...frete,
          }));
        const totalKm = _.sumBy(items, 'distancia_odometro');
        const totalVol = _.sumBy(items, 'volume');
        const totalFrete = _.sumBy(items, 'valor_frete');

        const totals = headers.reduce((acc, { value }) => {
          if (value === 'nome_prestador') {
            acc[value] = items[0][value];
          }
          else if (value === 'media_litros_km') {
            acc[value] = totalVol / totalKm;
          }
          else if (value === 'valor_litro') {
            acc[value] = totalFrete / totalVol;
          }
          else if (['volume', 'distancia_odometro', 'valor_frete'].includes(value)) {
            acc[value] = _.sumBy(items, value);
          }
          else if (['valor', 'taxa_ocupacao'].includes(value)) {
            acc[value] = _.meanBy(items, value);
          }
          else {
            acc[value] = null;
          }
          return acc;
        }, {});

        items.push(totals);

        return items;
      });
    },

    exportDetailExcel() {
      const headers = this.getDetailHeaders();
      const report = this.getDetailJson();
      const title = this.getReportTitle();

      const groupBy = 'Transportador';

      this.exportToFile({ headers, report, title, groupBy });
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value) || 0,
    formatCurrency: (value, decimals = 2) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: decimals }).format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
