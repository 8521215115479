<template>
  <div>
    <!-- Pagina de Cadastro de RECURSO -->
    <v-dialog
      v-model="show"
      width="600"
      max-width="600"
    >
      <v-card>
        <v-card-title>Cadastro de Recurso</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="rules.isValid"
          >
            <input
              v-model="dadosRecurso.id_recurso"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="dadosRecurso.recurso"
                  outlined
                  type="text"
                  label="Recurso"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="dadosRecurso.tipo"
                  :items="['MENU','COMPONENTE','HTTP']"
                  outlined
                  label="Tipo"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-card
                  class="mx-auto"
                >
                  <v-autocomplete
                    v-if="dadosRecurso.id_recurso"
                    v-model="permissionSelected"
                    chips
                    small-chips
                    multiple
                    hide-details
                    label="Disponivel nas permissoões:"
                    :items="listPermission"
                    return-object
                    item-text="descricao"
                    item-value="id_permissao"
                  >
                    <template #selection="{ }" />

                    <template #item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.descricao }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-list
                    v-if="permissionSelected.length > 0"
                    flat
                    dense
                    class="text-left overflow-y-auto"
                    style="background-color: rgba(255, 255, 255, 0.08); max-height: 190px;"
                  >
                    <v-list-item-group>
                      <template v-for="(item, index) in permissionSelected">
                        <v-hover
                          :key="index"
                          #default="{ hover }"
                        >
                          <v-list-item class="pl-3">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.descricao }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                x-small
                                icon
                                @click="onRemoveItemPermission(item)"
                              >
                                <v-icon v-if="hover">
                                  close
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-hover>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="black--text"
            text
            @click="show = false;"
          >
            cancelar
          </v-btn>
          <v-btn
            class="blue--text"
            text
            @click="
              validateForm();
              onResourceSave();"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "dialog-resource",

  props: {
    visible: {
      type: Boolean,
    },
    dadosRecurso: {
      type: Object,
    },
  },

  data() {
    return {
      rules: {
        isValid: false,
        nome_required: [v => !!v || "Campo obrigatorio"],
        codigo_required: [v => !!v || "Campo obrigatorio"],
      },

      listPermission: [],

      permissionSelected: [],
            
      loading: false,
    }
  },

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
          return false;
        }
      },
    },
  },

  mounted() {
    this.loadPermission();
    if (this.visible && this.dadosRecurso.id_recurso) {
      this.loadPermissionSelected();
    }
  },

  methods: {
    validateForm() {
      this.$refs.form.validate();
    },

    async onResourceSave() {
      let item = this.dadosRecurso;
          
      try {
        this.loading = true;

        const request = {
          id_recurso: item.id_recurso,
          recurso: item.recurso,
          tipo: item.tipo,
          permissoes: JSON.stringify(this.permissionSelected),
        };
            
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/salvaRecurso`,
          this.$qs.stringify({
            ...request,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }
            
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar !", "Atenção");
      } finally {
        this.$emit("change");
        this.show = false;
      }
    },

    async loadPermission() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/configuracaoMilkup/listaPermissao`);
                
        this.listPermission = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadPermissionSelected() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(
          `/configuracaoMilkup/listaRecursoPermissao`,
          this.$qs.stringify({
            id_recurso: this.dadosRecurso.id_recurso,
          })
        );
            
        this.permissionSelected = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onRemoveItemPermission(item) {
      this.permissionSelected = _.remove(this.permissionSelected, (data) => {
        return data.id_permissao !== item.id_permissao;
      });
    },
  }
}
</script>
