<template>
  <div class="shipment-data-info">
    <span
      class="overline"
      style="line-height: 15px !important; font-size: 10px !important;"
    >
      Dados do Romaneio
    </span>

    <v-divider
      class="pb-1"
      style="margin-top: 0 !important; margin-bottom: 0 !important;"
    />
    <v-row v-if="shipment.itinerary">
      <v-col
        cols="3"
        md
        lg
        class="pb-1"
      >
        <v-text-field
          :value="shipment.code"
          label="Código Carga"
          hide-details
          disabled
          dense
        />
      </v-col>
      <v-col
        cols="3"
        md
        lg
        class="pb-1"
      >
        <v-text-field
          :value="shipment.vehicle.plate"
          label="Placa"
          hide-details
          disabled
          dense
        />
      </v-col>
      <v-col
        cols="3"
        md
        lg
        class="pb-1"
      >
        <v-text-field
          :value="shipment.driver.name"
          label="Motorista"
          hide-details
          unmask
          disabled
          dense
        />
      </v-col>
      <v-col
        class="pb-1"
      >
        <masked-text-field
          v-model.number="shipment.grossWeight"
          label="Peso Bruto (Kg)"
          :mask="masks.float"
          unmask
          hide-details
          disabled
          dense
        />
      </v-col>
      <v-col
        class="pb-1"
      >
        <masked-text-field
          v-model="shipment.netWeight"
          label="Peso Liquido (Kg)"
          :mask="masks.float"
          unmask
          disabled
          hide-details
          dense
        />
      </v-col>
    </v-row>

    <v-divider
      class="mb-1"
      style="margin-top: 0 !important; margin-bottom: 0 !important;"
    />
  </div>
</template>

<style lang="scss">
.shipment-data-info {
  .v-input--is-disabled .v-input__control {
    color: #1f1f1f !important;
    .v-input__slot::before {
      border: none !important;
    }
    .v-label, input {
      color: #1f1f1f !important;
    }
  }

  .v-select.v-input--is-disabled .v-select__selections,
  .v-select .v-select__selection--disabled {
    color: #1f1f1f !important;
  }
}
</style>

<script setup>
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'

// eslint-disable-next-line no-undef
defineProps({
  shipment: Object
})

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
}

</script>
