<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color, overflow: 'hidden'}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        account_balance
      </v-icon>
      <span class="title font-weight-light">
        Ganhos e Perdas por Laticínio
      </span>
    </v-card-title>

    <v-tabs
      v-model="tab"
      align-with-title
      background-color="transparent"
      class="transparent"
      dark
    >
      <v-tabs-slider color="cyan" />

      <v-tab><v-icon>analytics</v-icon></v-tab>
      <v-tab><v-icon>list</v-icon></v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-chart
          :options="options"
          :style="{width: '100%', height: `${height}px !important`}"
          autoresize
        />
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="itemsComputed"
          dark
          class="elevation-1 report-table"
          dense
          hide-default-footer
          disable-pagination
          show-expand
          single-expand
          item-key="laticinio_concorrente.id"
          @item-expanded="onDetails"
        >
          <template #[`item.vinculados.volume`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.desvinculados.volume`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.saldo.volume`]="{ value }">
            <v-chip
              small
              :color="getChipColor(value)"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.vinculados.total`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.desvinculados.total`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.saldo.total`]="{ value }">
            <v-chip
              small
              :color="getChipColor(value)"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.vinculados_comp.volume`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.desvinculados_comp.volume`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.saldo_comp.volume`]="{ value }">
            <v-chip
              small
              :color="getChipColor(value)"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template #[`item.vinculados_comp.total`]="{ value }">
            {{ formatNumber(value) }}
          </template>
          <template #[`item.desvinculados_comp.total`]="{ value }">
            {{ formatNumber(value) }}
          </template>

          <template #[`item.saldo_comp.total`]="{ value }">
            <v-chip
              small
              :color="getChipColor(value)"
            >
              {{ formatNumber(value) }}
            </v-chip>
          </template>

          <template
            #[`body.append`]="{}"
          >
            <tr
              class="hidden-xs-only"
            >
              <td
                colspan="2"
                class="text-start cell-border"
              >
                <b>Total</b>
              </td>
              <td class="text-center subtitle-1">
                <b>{{ formatNumber(total.linked.volume) }}</b>
              </td>
              <td class="text-center subtitle-1">
                <b>{{ formatNumber(total.unlinked.volume) }}</b>
              </td>
              <td class="text-center cell-border">
                <v-chip
                  small
                  :color="getChipColor(total.balance.volume)"
                >
                  <b>{{ formatNumber(total.balance.volume) }}</b>
                </v-chip>
              </td>

              <template v-if="hasComparison">
                <td class="text-center subtitle-1">
                  <b>{{ formatNumber(totalComp.linked.volume) }}</b>
                </td>
                <td class="text-center subtitle-1">
                  <b>{{ formatNumber(totalComp.unlinked.volume) }}</b>
                </td>
                <td class="text-center cell-border">
                  <v-chip
                    small
                    :color="getChipColor(totalComp.balance.volume)"
                  >
                    <b>{{ formatNumber(totalComp.balance.volume) }}</b>
                  </v-chip>
                </td>
              </template>

              <td class="text-center subtitle-1">
                <b>{{ formatNumber(total.linked.producers) }}</b>
              </td>
              <td class="text-center subtitle-1">
                <b>{{ formatNumber(total.unlinked.producers) }}</b>
              </td>
              <td class="text-center">
                <v-chip
                  small
                  :color="getChipColor(total.balance.producers)"
                >
                  <b>{{ formatNumber(total.balance.producers) }}</b>
                </v-chip>
              </td>

              <template v-if="hasComparison">
                <td class="text-center subtitle-1">
                  <b>{{ formatNumber(totalComp.linked.producers) }}</b>
                </td>
                <td class="text-center subtitle-1">
                  <b>{{ formatNumber(totalComp.unlinked.producers) }}</b>
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    :color="getChipColor(totalComp.balance.producers)"
                  >
                    <b>{{ formatNumber(totalComp.balance.producers) }}</b>
                  </v-chip>
                </td>
              </template>
            </tr>
          </template>

          <template #expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0"
            >
              <v-row no-gutters>
                <v-col
                  v-if="!item.details"
                  cols="12"
                >
                  Carregando...
                </v-col>
                <template v-else>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-card
                      class="mt-3 technical-visit-card"
                      dark
                    >
                      <v-card-title>
                        <span class="text-subtitle-2">
                          Vinculados {{ titleExtended }}
                        </span>
                      </v-card-title>

                      <v-data-table
                        :headers="headersDetails"
                        :items="item.details.linked"
                        class="technical-visit-table"
                        dark
                        dense
                        hide-default-footer
                        disable-pagination
                      >
                        <template #[`item.data`]="{ value }">
                          {{ formatDate(value, 'DD/MM/YYYY') }}
                        </template>

                        <template #[`item.volume`]="{ value }">
                          <v-chip
                            small
                            :color="getChipColor(value)"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>

                  <v-col
                    v-if="hasComparison"
                    cols="12"
                    md="6"
                  >
                    <v-card
                      class="mt-3 technical-visit-card"
                      dark
                    >
                      <v-card-title>
                        <span class="text-subtitle-2">
                          Vinculados {{ titleComparisonExtended }}
                        </span>
                      </v-card-title>

                      <v-data-table
                        :headers="headersDetails"
                        :items="item.details.linkedComp"
                        class="technical-visit-table"
                        dark
                        dense
                        hide-default-footer
                        disable-pagination
                      >
                        <template #[`item.data`]="{ value }">
                          {{ formatDate(value, 'DD/MM/YYYY') }}
                        </template>

                        <template #[`item.volume`]="{ value }">
                          <v-chip
                            small
                            :color="getChipColor(value)"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-card
                      class="mt-3 technical-visit-card"
                      dark
                    >
                      <v-card-title>
                        <span class="text-subtitle-2">
                          Desvinculados {{ titleExtended }}
                        </span>
                      </v-card-title>

                      <v-data-table
                        :headers="headersDetails"
                        :items="item.details.unlinked"
                        class="technical-visit-table"
                        dark
                        dense
                        hide-default-footer
                        disable-pagination
                      >
                        <template #[`item.data`]="{ value }">
                          {{ formatDate(value, 'DD/MM/YYYY') }}
                        </template>

                        <template #[`item.volume`]="{ value }">
                          <v-chip
                            small
                            :color="getChipColor(value * -1)"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>

                  <v-col
                    v-if="hasComparison"
                    cols="12"
                    md="6"
                  >
                    <v-card
                      class="mt-3 technical-visit-card"
                      dark
                    >
                      <v-card-title>
                        <span class="text-subtitle-2">
                          Desvinculados {{ titleComparisonExtended }}
                        </span>
                      </v-card-title>

                      <v-data-table
                        :headers="headersDetails"
                        :items="item.details.unlinkedComp"
                        class="technical-visit-table"
                        dark
                        dense
                        hide-default-footer
                        disable-pagination
                      >
                        <template #[`item.data`]="{ value }">
                          {{ formatDate(value, 'DD/MM/YYYY') }}
                        </template>

                        <template #[`item.volume`]="{ value }">
                          <v-chip
                            small
                            :color="getChipColor(value * -1)"
                          >
                            {{ formatNumber(value) }}
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style lang="scss">
.cell-border {
  border-right: thin solid rgba(255, 255, 255, 0.12);
}
</style>

<script>
import moment from 'moment';
import isArray from "lodash/fp/isArray"
import isObject from "lodash/fp/isObject"
import upperFirst from "lodash/fp/upperFirst"

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    comparisonPeriod: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      loading: false,
      tab: null,

      items: [],
      itemsComparison: [],
    }
  },

  computed: {
    hasComparison() {
      return this.itemsComparison.length > 0
    },
    headers() {
      if (!this.hasComparison) {
        return [
          { text: 'Laticínio Concorrente', value: 'laticinio_concorrente.nome', cellClass: 'cell-border' },
          { text: 'Ganho (L)', value: 'vinculados.volume', align: 'center' },
          { text: 'Perdido (L)', value: 'desvinculados.volume', align: 'center' },
          { text: 'Saldo (L)', value: 'saldo.volume', align: 'center', cellClass: 'cell-border' },
          { text: 'Ganho (Produtores)', value: 'vinculados.total', align: 'center' },
          { text: 'Perdido (Produtores)', value: 'desvinculados.total', align: 'center' },
          { text: 'Saldo (Produtores)', value: 'saldo.total', align: 'center' }
        ];
      }
      return [
        { text: 'Laticínio Concorrente', value: 'laticinio_concorrente.nome', cellClass: 'cell-border' },

        { text: `Ganho (L) ${this.titleExtended}`, value: 'vinculados.volume', align: 'center' },
        { text: `Perdido (L) ${this.titleExtended}`, value: 'desvinculados.volume', align: 'center' },
        { text: `Saldo (L) ${this.titleExtended}`, value: 'saldo.volume', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (L) ${this.titleComparisonExtended}`, value: 'vinculados_comp.volume', align: 'center' },
        { text: `Perdido (L) ${this.titleComparisonExtended}`, value: 'desvinculados_comp.volume', align: 'center' },
        { text: `Saldo (L) ${this.titleComparisonExtended}`, value: 'saldo_comp.volume', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (Pr) ${this.titleExtended}`, value: 'vinculados.total', align: 'center' },
        { text: `Perdido (Pr) ${this.titleExtended}`, value: 'desvinculados.total', align: 'center' },
        { text: `Saldo (Pr) ${this.titleExtended}`, value: 'saldo.total', align: 'center', cellClass: 'cell-border' },

        { text: `Ganho (Pr) ${this.titleComparisonExtended}`, value: 'vinculados_comp.total', align: 'center' },
        { text: `Perdido (Pr) ${this.titleComparisonExtended}`, value: 'desvinculados_comp.total', align: 'center' },
        { text: `Saldo (Pr) ${this.titleComparisonExtended}`, value: 'saldo_comp.total', align: 'center' }
      ]
    },

    headersDetails() {
      return [
        { text: 'Data', value: 'data' },
        { text: 'Cód. Laticínio', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome_produtor' },
        { text: 'Volume', value: 'volume', align: 'center', cellClass: 'cell-border' },
      ];
    },
    itemsComputed() {
      if (!this.hasComparison) {
        return this.items;
      }

      const current = this.items.reduce((acc, item) => {
        acc[item.laticinio_concorrente.id] = {
          desvinculados_comp: { total: 0, volume: 0 },
          vinculados_comp: { total: 0, volume: 0 },
          saldo_comp: { total: 0, volume: 0 },
          ...item
        };
        return acc;
      }, {})

      const items = this.itemsComparison.reduce((acc, item) => {
        const id = item.laticinio_concorrente.id;
        if (!(id in acc)) {
          acc[id] = {
            laticinio_concorrente: item.laticinio_concorrente,
            desvinculados: { total: 0, volume: 0 },
            vinculados: { total: 0, volume: 0 },
            saldo: { total: 0, volume: 0 },
          };
        }

        acc[id] = {
          ...acc[id],
          desvinculados_comp: item.desvinculados,
          vinculados_comp: item.vinculados,
          saldo_comp: item.saldo,
        }
        return acc;
      }, current);

      return Object.values(items);
    },
    height() {
      return 350
    },
    options() {
      const label = {
        show: true,
        lineHeight: 17,
        height: 17,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      };

      const series = [
        {
          name: `Perdas (L) ${this.titleExtended}`,
          type: 'bar',
          areaStyle: {},
          showSymbol: true,
          emphasis: {
            focus: 'series'
          },
          data: this.itemsComputed.map((item) => item.desvinculados.volume),
          label
        },
        {
          name: `Ganhos (L) ${this.titleExtended}`,
          type: 'bar',
          areaStyle: {},
          showSymbol: true,
          emphasis: {
            focus: 'series'
          },
          data: this.itemsComputed.map((item) => item.vinculados.volume),
          label
        }
      ];

      if (this.hasComparison) {
        series.push(
          {
            name: `Perdas (L) ${this.titleComparisonExtended}`,
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.itemsComputed.map((item) => item.desvinculados_comp.volume),
            label
          },
          {
            name: `Ganhos (L) ${this.titleComparisonExtended}`,
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.itemsComputed.map((item) => item.vinculados_comp.volume),
            label
          }
        );
      }

      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(108, 25, 25, 0.7)', 'rgba(15, 86, 94, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b>`)
            ].join('<br>')
          }
        },
        toolbox: {
          show: this.hasComparison,
          top: '0%',
          right: '4%',
          iconStyle: {
            borderColor: '#fff'
          },
          feature: {
            magicType: {
              title: '',
              type: ['line', 'bar']
            },
          }
        },
        legend: {
          show: this.hasComparison,
          textStyle: {
            color: '#ddd',
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.itemsComputed.map(item => item.laticinio_concorrente.nome),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
        },
        series
      }
    },

    total() {
      const initial = {
        linked: {
          producers: 0,
          volume: 0,
        },
        unlinked: {
          producers: 0,
          volume: 0,
        },
        balance: {
          producers: 0,
          volume: 0,
        },
      }
      return this.items.reduce((acc, cur) => {
        acc.linked.producers += cur.vinculados.total;
        acc.linked.volume += cur.vinculados.volume;
        acc.unlinked.producers += cur.desvinculados.total;
        acc.unlinked.volume += cur.desvinculados.volume;
        acc.balance.producers += cur.saldo.total;
        acc.balance.volume += cur.saldo.volume;
        return acc;
      }, initial);
    },

    totalComp() {
      const initial = {
        linked: {
          producers: 0,
          volume: 0,
        },
        unlinked: {
          producers: 0,
          volume: 0,
        },
        balance: {
          producers: 0,
          volume: 0,
        },
      }
      return this.itemsComparison.reduce((acc, cur) => {
        acc.linked.producers += cur.vinculados.total;
        acc.linked.volume += cur.vinculados.volume;
        acc.unlinked.producers += cur.desvinculados.total;
        acc.unlinked.volume += cur.desvinculados.volume;
        acc.balance.producers += cur.saldo.total;
        acc.balance.volume += cur.saldo.volume;
        return acc;
      }, initial);
    },

    comparisonType() {
      if (this.comparisonPeriod.length < 2) {
        return 'NONE';
      }

      const [startDate] = this.period;

      const [startDateComp] = this.comparisonPeriod;

      if (moment(startDate).isSame(startDateComp, 'month') && !moment(startDate).isSame(startDateComp, 'year')) {
        return 'YEAR';
      }

      return 'MONTH';
    },

    titleExtended() {
      const [date] = this.period;
      if (this.comparisonType === 'MONTH') {
        return `- ${upperFirst(moment(date).format('MMM/YYYY'))}`;
      }
      if (this.comparisonType === 'YEAR') {
        return `- ${upperFirst(moment(date).format('YYYY'))}`;
      }
      return '';
    },

    titleComparisonExtended() {
      const [date] = this.comparisonPeriod;
      if (this.comparisonType === 'MONTH') {
        return `- ${upperFirst(moment(date).format('MMM/YYYY'))}`;
      }
      if (this.comparisonType === 'YEAR') {
        return `- ${upperFirst(moment(date).format('YYYY'))}`;
      }
      return '';
    },
  },

  watch: {
    period() {
      this.loadReport();
    },
    comparisonPeriod() {
      this.loadComparisonReport();

      this.items = this.items.map(item => {
        delete item.details
        return item;
      });
    }
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(
          `/relatorios/resumoVinculacaoPorLaticinio`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.items = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async loadComparisonReport() {
      try {
        if (this.comparisonPeriod.length < 2) {
          this.itemsComparison = [];
          return;
        }
        this.loading = true;

        const [startDate, endDate] = this.comparisonPeriod;

        const { data } = await this.$axios.post(
          `/relatorios/resumoVinculacaoPorLaticinio`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.itemsComparison = data;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async onDetails({ item, value }) {
      try {
        // Verifica se os dados já foram carregados do servidor
        if (item.details) {
          return;
        }

        // Não faz nada quando fechar
        if (!value) {
          return;
        }

        this.loading = true;

        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/relatorios/volumeVinculadosDesvinculados`,
          { data_inicio: startDate, data_fim: endDate, id_laticinio_concorrente: item.laticinio_concorrente.id || 'NO_ID' }
        );

        if (!isObject(data)) {
          throw new Error(data);
        }

        let unlinkedComp = [], linkedComp = [];

        if (this.comparisonPeriod.length) {
          const [startDateComp, endDateComp] = this.comparisonPeriod;

          const { data: dataComp } = await this.$axios.post(
            `/relatorios/volumeVinculadosDesvinculados`,
            { data_inicio: startDateComp, data_fim: endDateComp, id_laticinio_concorrente: item.laticinio_concorrente.id || 'NO_ID' }
          );

          if (!isObject(dataComp)) {
            throw new Error(dataComp);
          }

          unlinkedComp = dataComp.desvinculados;
          linkedComp = dataComp.vinculados;
        }

        this.items = this.items.map(visit => {
          if (visit.laticinio_concorrente.id !== item.laticinio_concorrente.id) {
            return visit;
          }

          return {
            details: {
              unlinked: data.desvinculados,
              linked: data.vinculados,
              unlinkedComp,
              linkedComp,
            },
            ...visit,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os detalhes!", "Atenção");

        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    getChipColor(value) {
      if (value > 0) {
        return 'blue accent-3';
      }

      if (value < 0) {
        return 'red accent-3';
      }

      return null;
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  }
}
</script>
