<template>
  <div>
    <v-tabs
      grow
      color="#01579B"
    >
      <v-tab href="#inventory">
        Inventário
      </v-tab>
      <v-tab href="#recipes">
        Receitas
      </v-tab>
      <v-tab href="#expenses">
        Despesas
      </v-tab>

      <v-tab-item
        value="inventory"
        class="pt-3"
      >
        <v-row>
          <v-col cols="12">
            <FinancialTable
              :settings="[
                { text: 'Estoque', value: 'inventory', width: 250, item: { type: 'TEXTINPUT' } },
                { text: 'Quantidade', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
                { text: 'Valor', value: 'value', width: 200, item: { type: 'MONEY' } },
                { text: '% de uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
              ]"
              row-remove
              row-add
              :value="inventory.inventory"
            />
          </v-col>
          <v-divider inset />
          <v-col cols="12">
            <FinancialTable
              :settings="[
                { text: 'Máquinas e Equipamentos', value: 'equipment', width: 250, item: { type: 'TEXTINPUT' } },
                { text: 'Quantidade', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
                { text: 'Valor', value: 'value', width: 200, item: { type: 'MONEY' } },
                { text: '% de uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
              ]"
              row-remove
              row-add
              :value="inventory.equipment"
            />
          </v-col>
          <v-divider inset />
          <v-col cols="12">
            <FinancialTable
              :settings="[
                { text: 'Área', value: 'area', width: 250, item: { type: 'TEXTINPUT' } },
                { text: 'Quantidade (HÁ)', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
                { text: 'Valor de formação', value: 'value', width: 200, item: { type: 'MONEY' } },
                { text: '% uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
              ]"
              row-remove
              row-add
              :value="inventory.area"
            />
          </v-col>
          <v-divider inset />
          <v-col
            cols="12"
            class="pb-0"
          >
            <FinancialTable
              :settings="[
                { text: 'Benfeitoria', value: 'improvement', width: 250, item: { type: 'TEXTINPUT' } },
                { text: 'Quantidade', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
                { text: 'Valor', value: 'value', width: 200, item: { type: 'MONEY' } },
                { text: '% uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
              ]"
              row-remove
              row-add
              :value="inventory.improvement"
            />
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item
        value="recipes"
        class="pt-3"
      >
        <v-row>
          <v-col class="ma-2">
            <v-data-table
              class="recipe-table"
              :headers="[
                { text: 'Item', value: 'item', width: 300 },
                { text: 'Quantidade', value: 'quantity', width: 250 },
                { text: 'Valor', value: 'value', width: 250 },
              ]"
              :items="recipe"
              dense
              hide-default-footer
            >
              <template #[`item.item`]="{ item }">
                <span class="subtitle-2">{{ item.item }}</span>
              </template>

              <template #[`item.quantity`]="{item}">
                <v-text-field
                  v-model="localData[item.qtdField]"
                  hide-details
                  placeholder="Quantidade"
                />
              </template>

              <template #[`item.value`]="{item}">
                <money-input
                  v-model="localData[item.valueField]"
                  hide-details
                  placeholder="Valor"
                  prefix="R$"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      
      <v-tab-item
        value="expenses"
        class="pt-3"
      >
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <FinancialTable
              :settings="[
                { text: 'Item', value: 'item', width: 250, item: { type: 'TEXTINPUT' } },
                { text: 'Quantidade', value: 'quantity', width: 200, item: { type: 'NUMBER' } },
                { text: 'Valor', value: 'value', width: 200, item: { type: 'MONEY' } },
                { text: '% de uso', value: 'percentage_used', width: 200, item: { type: 'NUMBER' } },
              ]"
              row-remove
              row-add
              :value="expenses"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<style lang="scss">
.recipe-table {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;

  .v-input {
    padding-bottom: 10px;
    padding-top: 0;
  }

  table {
    thead > tr {
      span {
        font-family: Roboto, Arial, sans-serif;
        font-size: 0.9rem;
        font-weight: 700;
      }
      background: rgba(226, 225, 225, 0.5);
    }

    tbody {
        tr:hover {
        background-color: #ECEFF1 !important;
      }
    }
  }
}
</style>

<script>
import * as _ from "lodash";
import FinancialTable from "@/Domains/Quality/PlanQualification/Components/FinancialTable.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

const fieldDictionary = {
  valor: "value",
  estoque: "inventory",
  quantidade: "quantity",
  uso: "percentage_used",
  equipamento: "equipment",
  area: "area",
  benfeitoria: "improvement",
  item: "item",
};

export default {
  components: {
    FinancialTable,
    MoneyInput,
  },
  props: {
    diagnostic: {
      type: Object,
    },
    rowRemove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,

      inventory: {
        inventory: [],
        equipment: [],
        area: [],
        improvement: [],
      },

      expenses: [],

      recipe: [
        {
          item: "Preço do Leite",
          qtdField: "leite_quantidade",
          valueField: "leite_valor" },
        {
          item: "Leite Vendido (Litros)",
          qtdField: "leite_vendido_quantidade",
          valueField: "leite_vendido_valor" },
        {
          item: "Leite Aleitamento (Litros)",
          qtdField: "leite_aleitamento_quantidade",
          valueField: "leite_aleitamento_valor",
        },
        {
          item: "Leite Descartado (Litros)",
          qtdField: "leite_descartado_quantidade",
          valueField: "leite_descartado_valor",
        },
        {
          item: "Leite Consumo (Litros)",
          qtdField: "leite_consumo_quantidade",
          valueField: "leite_consumo_valor"
        },
        {
          item: "Venda de Animais",
          qtdField: "venda_animais_quantidade",
          valueField: "venda_animais_valor" },
        {
          item: "Outras Receitas",
          qtdField: "outras_receitas_quantidade",
          valueField: "outras_receitas_valor"
        },
      ],
    };
  },
  computed: {
    localData: {
      get() {
        return this.diagnostic;
      },
      set(newValue) {
        return this.$emit("update:diagnostic", {
          ...newValue,
        });
      },
    },
  },
  watch: {
    'inventory.inventory': {
      deep: true,
      handler: function(value) {
        this.localData.estoque = this.parseDiagnostic(value);
      }
    },
    'inventory.equipment': {
      deep: true,
      handler: function(value) {
        this.localData.equipamento_financeiro = this.parseDiagnostic(value);
      }
    },
    'inventory.area': {
      deep: true,
      handler: function(value) {
        this.localData.area_financeiro = this.parseDiagnostic(value);
      }
    },
    'inventory.improvement': {
      deep: true,
      handler: function(value) {
        this.localData.benfeitoria_financeiro = this.parseDiagnostic(value);
      }
    },
    'expenses': {
      deep: true,
      handler: function(value) {
        this.localData.despesas = this.parseDiagnostic(value);
      }
    },
  },
  mounted() {
    this.initStep();
  },
  methods: {
    initStep() {
      this.inventory.inventory = this.dataFactory(this.diagnostic.estoque);
      this.inventory.equipment = this.dataFactory(this.diagnostic.equipamento_financeiro);
      this.inventory.area = this.dataFactory(this.diagnostic.area_financeiro);
      this.inventory.improvement = this.dataFactory(this.diagnostic.benfeitoria_financeiro);

      this.expenses = this.dataFactory(this.diagnostic.despesas);
    },
    /**
     * Irá reestruturar um objeto de forma fácilitar o uso em tela
     * @param {String} info
     * @returns {Array}
     */
    dataFactory(info, base = null) {
      try {
        const data = JSON.parse(info || "[]");

        const result = base || [{}];

        _.forEach(data, (value, fieldName) => {
          // valores referente a key atual
          let currentValues = _.get(data, fieldName);

          _.forEach(currentValues, (c, index) => {
            // concatena os campos da posição existem com o novo
            let body = _.assign(result[index] || {}, { [`${fieldDictionary[fieldName]}`]: c });

            // Atribui a possição correspondendo na váriavel de resultado
            _.set(result, index, body);
          });
        });

        return result;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    /**
     * responsável em fazer o parse das informações que devem ser salvas
     * @return {String} objeto semelhante ao que esta salvo no BD
     */
    parseDiagnostic(data) {
      const fields = _.map(_.head(data), (d, i) => i);
      let response = {};

      _.forEach(fields, field => {
        // Busca o nome referente ao que deve ser salvo no banco de dados
        const key = _.findKey(fieldDictionary, o => o === field);
        
        // seta a novas as informações
        _.set(response, `${key}`, data.map(a => a[field]).filter(f => f));
      });
      
      return JSON.stringify(response);
    }
  },
};
</script>
