<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Volume de Leite por Técnico em produtores visitados no período
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-container>
      <v-row justify="end">
        <v-col
          cols="12"
          md="4"
        >
          <person-autocomplete-filter
            v-model="filters.technician"
            label="Técnico"
            type="TECHNICIAN"
            @change="onFilter"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-date-range-picker
            v-model="filters.range"
            :ranges="filters.ranges"
            @change="onFilter"
          />
        </v-col>
      </v-row>

      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent text-left"
        @change="onTabChange"
      >
        <v-tab
          href="#tab-report"
        >
          Relatório
        </v-tab>

        <v-tab
          href="#tab-details"
          :disabled="tab != 'tab-details'"
        >
          Detalhes
        </v-tab>

        <v-tab-item value="tab-report">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span class="caption font-weight-light">
                    Visitas
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3 cyan--text"
                  >{{ totalVisits }}</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  class="d-flex flex-column justify-end px-14 py-0"
                >
                  <span class="caption font-weight-light">
                    Volume Total
                  </span>
                  <span
                    class="headline font-weight-bold text--accent-3 teal--text"
                  >{{ formatNumber(totalVol) }} L</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  offset-md="2"
                  class="py-0"
                >
                  <v-text-field
                    v-model="report.search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="report.headers"
              :search="report.search"
              :items="report.items"
              :loading="loading"
              dark
              @click:row="showDetails"
            >
              <template #[`item.volume`]="{ value }">
                <span>{{ formatNumber(value) }} L</span>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-details">
          <v-card
            color="transparent"
            dark
          >
            <v-card-title>
              <v-col
                cols="12"
                sm="6"
                md="8"
                class="d-flex flex-column justify-end px-14 py-0"
              >
                <span class="caption font-weight-light">
                  Técnico
                </span>
                <span
                  class="headline font-weight-bold text--accent-3 cyan--text"
                >{{ details.technician }}</span>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <v-text-field
                  v-model="details.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                />
              </v-col>
            </v-card-title>
            <v-data-table
              :headers="details.headers"
              :items="details.items"
              :search="details.search"
              dark
            >
              <template #[`item.volume`]="{ value }">
                <span>{{ formatNumber(value) }} L</span>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-container>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    VDateRangePicker,
    PersonAutocompleteFilter,
  },

  mixins: [ReportMixin],

  data() {
    return {
      tab: "tab-report",

      loading: false,

      filters: {
        technician: {},
        ranges: {
          'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
          'Mês anterior': [moment().subtract(1, "month").startOf('month').format('YYYY-MM-DD'), moment().subtract(1, "month").endOf('month').format('YYYY-MM-DD')],
        },
        range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
      },

      report: {
        search: '',

        headers: [
          { text: "Técnico", value: "technician.name" },
          { text: "Produtores", value: "producers", width: 180, align: 'center' },
          { text: "Visitas", value: "visits", width: 180, align: 'center' },
          { text: "Volume", value: "volume", width: 180, align: 'center' },
        ],

        items: [],
      },

      details: {
        search: '',
        technician: '',

        headers: [
          { text: "Produtor", value: "producer.name" },
          { text: "Visitas", value: "visits", width: 180, align: 'center' },
          { text: "Volume", value: "volume", width: 150, align: 'center' },
        ],

        items: [],
      },
    };
  },

  computed: {
    totalVisits() {
      return this.report.items.reduce((acc, cur) => acc + cur.visits, 0)
    },
    totalVol() {
      return this.report.items.reduce((acc, cur) => acc + cur.volume, 0)
    },
  },

  methods: {
    onTabChange(id) {
      if (id == 'tab-report') {
        this.details.items = [];
      }
    },

    showDetails({ technician, items }) {
      this.details.technician = technician.name;
      this.details.items = items;
      this.tab = 'tab-details';
    },

    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.filters.range;

        let { data } = await this.$axios.post(
          `/relatorios/totalLeiteTecnicoProdutor`,
          { data_ini: startDate, data_fim: endDate, id_tecnico: this.filters.technician.id || null }
        );

        if (!_.isArray(data)) {
          throw data;
        }

        this.report.items = _(data)
          .map(item => ({
            technician: {
              id: item.id_tecnico,
              name: item.nome_tecnico,
            },
            producer: {
              id: item.id_produtor,
              name: item.nome_produtor,
            },
            visits: item.num_visitas,
            volume: item.volume_total,
          }))
          .groupBy('technician.id')
          .map((items) => {
            const [{ technician }] = items;
            return {
              technician,
              producers: items.length,
              visits: items.reduce((acc, cur) => acc + parseInt(cur.visits || 0), 0),
              volume: items.reduce((acc, cur) => acc + parseFloat(cur.volume || 0), 0),
              items
            }
          }).value();

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onFilter() {
      this.details.items = [];
      this.tab = 'tab-report';
      this.loadReport();
    },

    getReportJson() {
      return this.report.items.map(o => ({
        Técnico: o.technician.name,
        Produtores: o.producers,
        Visitas: o.visits,
        Volume: this.formatNumber(o.volume) + ' L',
      }));
    },

    getDetailsJson() {
      return this.details.items.map(o => ({
        Técnico: this.details.technician,
        Produtor: o.producer.name,
        Visitas: o.visits,
        Volume: this.formatNumber(o.volume) + ' L',
      }));
    },

    exportExcel() {
      const [startDate, endDate] = this.filters.range;
      if (this.tab == 'tab-report') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson());

        data['!cols'] = [
          { wch: 35 },
          { wch: 10 },
          { wch: 12 }
        ];

        const workbook = this.XLSX.utils.book_new();
        const filename = `Vol. por técnico ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else {
        const data = this.XLSX.utils.json_to_sheet(this.getDetailsJson());

        data['!cols'] = [
          { wch: 35 },
          { wch: 35 },
          { wch: 12 }
        ];

        const workbook = this.XLSX.utils.book_new();
        const filename = `Detalhes Vol. ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
    },

    print() {
      const [startDate, endDate] = this.filters.range;
      if (this.tab == 'tab-report') {
        return this.printFromJson(this.getReportJson(), `Vol. por técnico ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`);
      }
      else {
        return this.printFromJson(this.getDetailsJson(), `Detalhes Vol. ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`);
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
