<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Impacto dos Prioritários no volume
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-row>
        <v-col
          cols="3"
          md="3"
        >
          <month-picker
            v-model="filter.date.input"
            disable-last-thirty
            not-clearable
            @change="onDateFilter"
          />
        </v-col>
        <v-col
          cols="3"
          md="3"
        >
          <person-autocomplete-filter
            v-model="filter.technician"
            type="TECHNICAL"
            label="Técnico"
            dark
            hide-details
            @change="loadReport"
          />
        </v-col>
        <v-col
          cols="3"
          md="3"
        >
          <routes-autocomplete-filter
            v-model="filter.routes"
            label="Rota"
            dark
            multiple
            hide-details
            @change="loadReport"
          />
        </v-col>
        <v-col
          cols="3"
          md="3"
        >
          <v-autocomplete
            v-model="filter.status"
            :items="[
              { text: 'Ativos', value: 'ATIVOS' },
              { text: 'Desvinculados', value: 'DESVINCULADOS' },
            ]"
            label="Exibir"
            placeholder=" "
            hide-details
            dark
            filled
            @change="loadReport"
          />
        </v-col>
      </v-row>

      <v-flex xs12>
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#aba-impacto-cpp">
            Prioritários CPP
          </v-tab>
          <v-tab href="#aba-graficoimpacto-cpp">
            Gráfico Prioritários CPP
          </v-tab>
          <v-tab href="#aba-impacto-ccs">
            Prioritários CCS
          </v-tab>
          <v-tab href="#aba-graficoimpacto-ccs">
            Gráfico Prioritários CCS
          </v-tab>
          <v-tab href="#aba-impacto-cpp-ccs">
            CPP e CCS Acima Norma
          </v-tab>
          <v-tab href="#aba-graficoimpacto-cpp-ccs">
            Gráfico CPP e CCS Acima Norma
          </v-tab>

          <v-tab-item value="aba-impacto-cpp">
            <v-card
              color="transparent"
              dark
            >
              <v-card-title>
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4 subtitle-1"
                >
                  {{ `Volume médio do laticinio: ${volAgvDairy}` }} <br>
                  {{ `Volume médio dos produtores CPP: ${cpp.volTotal}` }}
                </v-col>
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4"
                >
                  {{ `Produtores: ${cpp.items.length}` }}
                </v-col>
                <!-- <v-spacer /> -->
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4"
                >
                  <v-text-field
                    v-model="filter.search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  />
                </v-col>
                <v-col
                  md="12"
                  class="subtitle-2"
                >
                  <p>{{ cpp.subtext }} </p>
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="cpp.headers"
                :items="cpp.items"
                :search="filter.search"
                :loading="loading"
                :items-per-page="-1"
                class="elevation-1"
              >
                <template #[`item.volume`]="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #[`item.result`]="{value}">
                  {{ formatNumber(value) }}%
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item value="aba-impacto-ccs">
            <v-card
              color="transparent"
              dark
            >
              <v-card-title>
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4 subtitle-1"
                >
                  {{ `Volume médio do laticinio: ${volAgvDairy}` }} <br>
                  {{ `Volume médio dos produtores CCS: ${ccs.volTotal}` }}
                </v-col>
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4"
                >
                  {{ `Produtores: ${ccs.items.length}` }}
                </v-col>
                <!-- <v-spacer /> -->
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4"
                >
                  <v-text-field
                    v-model="filter.search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  />
                </v-col>
                <v-col
                  md="12"
                  class="subtitle-2"
                >
                  <p>{{ ccs.subtext }} </p>
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="ccs.headers"
                :items="ccs.items"
                :search="filter.search"
                :loading="loading"
                :items-per-page="-1"
                class="elevation-1"
              >
                <template #[`item.volume`]="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #[`item.result`]="{value}">
                  {{ formatNumber(value) }}%
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item value="aba-graficoimpacto-cpp">
            <v-card
              color="transparent"
              dark
              class="mb-20"
            >
              <v-card-text>
                <v-chart
                  :options="cppGraph.options"
                  :style="{width: '100%', height: `${cppGraph.height }px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
            <v-card
              color="transparent"
              dark
            >
              <v-card-text>
                <v-chart
                  :options="cppPie"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="aba-graficoimpacto-ccs">
            <v-card
              color="transparent"
              dark
              class="mb-20"
            >
              <v-card-text>
                <v-chart
                  :options="ccsGraph.options"
                  :style="{width: '100%', height: `${ccsGraph.height }px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
            <v-card
              color="transparent"
              dark
            >
              <v-card-text>
                <v-chart
                  :options="ccsPie"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="aba-impacto-cpp-ccs">
            <v-card
              color="transparent"
              dark
            >
              <v-card-title>
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4 subtitle-1"
                >
                  {{ `Volume médio do laticinio: ${volAgvDairy}` }} <br>
                  {{ `Volume médio dos produtores CPP e CCS: ${cppCcs.volTotal}` }}
                </v-col>
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4"
                >
                  {{ `Produtores: ${cppCcs.items.length}` }}
                </v-col>
                <!-- <v-spacer /> -->
                <v-col
                  cols="4"
                  class="pa-0"
                  md="col-4"
                >
                  <v-text-field
                    v-model="filter.search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  />
                </v-col>
                <v-col
                  md="12"
                  class="subtitle-2"
                >
                  <p>{{ cppCcs.subtext }} </p>
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="cppCcs.headers"
                :items="cppCcs.items"
                :search="filter.search"
                :loading="loading"
                :items-per-page="-1"
                class="elevation-1"
              >
                <template #[`item.volume`]="{value}">
                  {{ formatNumber(value) }}
                </template>
                <template #[`item.result`]="{value}">
                  {{ formatNumber(value) }}%
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item value="aba-graficoimpacto-cpp-ccs">
            <v-card
              color="transparent"
              dark
              class="mb-20"
            >
              <v-card-text>
                <v-chart
                  :options="cppCcsGraph.options"
                  :style="{width: '100%', height: `${cppCcsGraph.height }px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
            <v-card
              color="transparent"
              dark
            >
              <v-card-text>
                <v-chart
                  :options="cppCcsPie"
                  :style="{width: '100%', height: `300px !important`}"
                  autoresize
                  :loading="loading"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>

    <v-speed-dial
      v-if="['aba-impacto-cpp', 'aba-impacto-ccs', 'aba-impacto-cpp-ccs'].includes(tab)"
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import QualityPriorityMixin from "@/Domains/Quality/Mixins/QualityPriorityMixin.js";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "PrirityVolumeImpact",

  components: {
    MonthPicker,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter
  },

  mixins: [
    ReportMixin,
    QualityPriorityMixin
  ],

  data() {
    return {
      loading: false,

      tab: "aba-impacto-cpp",
      
      filter: {
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        type: 'priority',
        status: 'ATIVOS',
      },

      totalProducers: 0,

      volAgvDairy: 0,

      cpp: {
        headers: [
          { text: "Código", value: "codigo_laticinio", width: 50 },
          { text: "Produtor", value: "nome_produtor", width: 300 },
          { text: "Rota", value: "rota" },
          { text: "CPP", value: "cbt", width: 100 },
          { text: "Volume (média)", value: "volume", width: 150 },
          { text: "Volume * CPP", value: "volume_cpp", width: 150 },
          { text: "% Impacto ", value: "result", width: 150, groupDesc: true, sortDesc: true },
        ],
        items: [],
        text: 'Impacto Qualidade CPP',
        volTotal: 0,
        subtext: '*percentual que cada produtor Impacta no volume dos prioritários CPP, considerando a média(volume) de coletas do período multiplicado pela média CPP',
      },
      ccs: {
        headers: [
          { text: "Código", value: "codigo_laticinio", width: 50 },
          { text: "Produtor", value: "nome_produtor", width: 300 },
          { text: "Rota", value: "rota" },
          { text: "CCS", value: "ccs", width: 100 },
          { text: "Volume (média)", value: "volume", width: 150 },
          { text: "Volume * CCS", value: "volume_ccs", width: 150 },
          { text: "% Impacto", value: "result", width: 150, groupDesc: true, sortDesc: true },
        ],
        items: [],
        text: 'Impacto Qualidade CCS',
        volTotal: 0,
        subtext: '*percentual que cada produtor Impacta no volume dos prioritários CCS, considerando a média(volume) de coletas do período multiplicado pela média CCS',
  
      },
      cppCcs: {
        headers: [
          { text: "Código", value: "codigo_laticinio", width: 50 },
          { text: "Produtor", value: "nome_produtor", width: 300 },
          { text: "Rota", value: "rota" },
          { text: "CPP", value: "cbt", width: 100 },
          { text: "CCS", value: "ccs", width: 100 },
          { text: "Volume (média)", value: "volume", width: 150 },
          { text: "Volume CCS CPP", value: "volume_ccs_cpp", width: 150 },
          { text: "% Impacto", value: "result", width: 150, groupDesc: true, sortDesc: true },
        ],
        items: [],
        text: 'Impacto Qualidade CPP e CCS',
        volTotal: 0,
        subtext: '*percentual que cada produtor Impacta no volume dos prioritários CPP e CCS, considerando a média(volume) de coletas do período multiplicado pela média CPP e CCS',
  
      },
    };
  },
  computed: {

    cppGraph() {
      return this.generateGraphOptions(this.cpp);
    },
    cppPie() {
      return this.generateGraphPie(this.cpp.volTotal);
    },

    ccsGraph() {
      return this.generateGraphOptions(this.ccs);
    },
    ccsPie() {
      return this.generateGraphPie(this.ccs.volTotal);
    },

    cppCcsGraph() {
      return this.generateGraphOptions(this.cppCcs);
    },
    cppCcsPie() {
      return this.generateGraphPie(this.cppCcs.volTotal);
    },
  },

  mounted() {
    this.loadReport();
  },
  
  methods: {

    onDateFilter([month]) {
      this.filter.date.month = month;
      this.loadReport();
    },
    async loadReport() {
      await this.loadMediaDairy();
      await this.loadVolReport();

    },
    async loadMediaDairy() {
      try {
        
        this.loading = true;
        let startDate = moment(this.filter.date.month).startOf("month").format("YYYY-MM-DD");
        let endDate = moment(this.filter.date.month).endOf("month").format("YYYY-MM-DD");

        let { data } = await this.$axios.post(
          `/relatorios/mediaLeite`,
          { data_inicio: startDate, data_fim: endDate, status: this.filter.status }
        );

        if (this.$store.state.settings.laticinio) {
          const dairy = data["relatorio"].find((dairy) => dairy.id_laticinio === this.$store.state.settings.laticinio.id);
          this.volAgvDairy = dairy ? dairy.volume : 0;
        } else { //senao tiver laticinio selecionado soma de todos as unidades
          this.volAgvDairy = _.sumBy(data["relatorio"], 'volume');
        }

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
   
    async loadVolReport() {
      try {
        this.loading = true;
        
        const month = this.filter.date.month;
        const technicianId = this.filter.technician.id;
        const routes = this.filter.routes.map(({ id }) => id);
        const status = this.filter.status;

        const { totalProducers, ccs, cbt, cbtccs } = await this.getQualityPriorityReport(month, technicianId, routes, status);

        this.totalProducers = totalProducers;

        let array_cpp = cbt.filter(item => item.cbt > 300).map(item => {
          return {
            volume: item.media_coleta,
            volume_cpp: item.media_coleta * item.cbt,
          }
        })
        
        let volAGVTotalCpp = _.sumBy( array_cpp, 'volume_cpp') ;
        this.cpp.volTotal = _.sumBy( array_cpp, 'volume') ;

        this.cpp.items = cbt.filter(item => item.cbt > 300).map(item => {
          let cbt = Math.abs(item.cbt).toFixed(0);
          let volume = item.media_coleta ? Math.abs(item.media_coleta).toFixed(1) : 0;
          let volume_cpp = volume * cbt;
          //let result = (volume_cpp * 100 ) / this.volAgvDairy;
          let result_cpp = (volume_cpp * 100 ) / volAGVTotalCpp;
          return {
            ...item,
            cbt: cbt,
            volume: volume,
            volume_cpp: volume_cpp,
            codigo_nome: `${item.codigo_laticinio}  - ${item.nome_produtor} `,
            result: result_cpp,
          }
        });
        this.cpp.items = _.orderBy(this.cpp.items, "result", "desc");

        //------------------------------------------------------------------------
        let array_ccs = cbt.filter(item => item.ccs > 500).map(item => {
          return {
            volume: item.media_coleta,
            volume_ccs: item.media_coleta * item.ccs,
          }
        })
        
        let volAGVTotalCcs = _.sumBy( array_ccs, 'volume_ccs') ;
        this.ccs.volTotal = _.sumBy( array_ccs, 'volume') ;

        this.ccs.items = ccs.filter(item => item.ccs > 500).map(item => {
          let ccs = Math.abs(item.ccs).toFixed(0);
          let volume = item.media_coleta ? Math.abs(item.media_coleta).toFixed(1) : 0;
          let volume_ccs = volume * ccs;
          let result_ccs = (volume_ccs * 100 ) / volAGVTotalCcs;
          return {
            ...item,
            ccs: ccs,
            volume: volume,
            volume_ccs: volume_ccs,
            codigo_nome: `${item.codigo_laticinio}  - ${item.nome_produtor} `,
            result: result_ccs,
          }
        });
        this.ccs.items = _.orderBy(this.ccs.items, "result", "desc");

        //------------------------------------------------------------------------
        let array_cpp_ccs = cbtccs.filter(item => (item.ccs > 500 && item.cbt > 300)).map(item => {
          return {
            volume: item.media_coleta,
            volume_ccs_cpp: ((item.media_coleta * item.ccs) + (item.media_coleta * item.cbt)) / 2,
          }
        })

        let volAGVTotalCppCcs = _.sumBy( array_cpp_ccs, 'volume_ccs_cpp') ;
        this.cppCcs.volTotal = _.sumBy( array_cpp_ccs, 'volume') ;

        this.cppCcs.items = cbtccs.filter(item => (item.ccs > 500 && item.cbt > 300)).map(item => {
          let volume = item.media_coleta ? Math.abs(item.media_coleta).toFixed(1) : 0;
          let volume_ccs_cpp = ((item.media_coleta * item.ccs) + (item.media_coleta * item.cbt)) / 2;
          let result_ccs = (volume_ccs_cpp * 100 ) / volAGVTotalCppCcs;
          return {
            ...item,
            ccs: Math.abs(item.ccs).toFixed(0),
            cpp: Math.abs(item.cbt).toFixed(0),
            volume: volume,
            volume_ccs_cpp: volume_ccs_cpp,
            codigo_nome: `${item.codigo_laticinio}  - ${item.nome_produtor} `,
            result: result_ccs,
          }
        });
        this.cppCcs.items = _.orderBy(this.cppCcs.items, "result", "desc");

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    generateGraphOptions(data) {
      if (_.isEmpty(data.items)) {
        return {
          height: 0,
          options: {},
        };
      }

      return {
        height: 50 + (data.items.length * 25),
        options: {
          color: ['rgba(38, 198, 218, 0.7)', 'rgba(229, 57, 53, 0.7)',   'rgba(140, 23, 23, 1)',  'rgba(255, 255, 0, 0.7)'],
          title: {
            text: `${data.text}`,
            subtext: `${data.subtext}`,
            left: "center",
            textStyle: { color: '#ddd' },
            subtextStyle: {
              fontSize: 10,
              color: '#ddd',
              margin: [0, 0, 100, 0],
            },
            padding: [0, 0, 0, 50],
          },
          tooltip: {
            rigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: { backgroundColor: '#6a7985' }
            },
            textStyle: {
              fontSize: 12,
            },
          },
          toolbox: {
            top: 20,
            right: 10,
            feature: {
              saveAsImage: {
                title: "",
                backgroundColor: "#0A2F54",
              },
            },
            iconStyle: {
              borderColor: "#F3F3F3"
            },
          },
          grid: {
            left: '0',
            right: '0',
            bottom: '0',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            // boundaryGap: false,
            axisLabel: {
              color: '#fff',
              align: 'end',
            }
          },
          yAxis: {
            type: 'category',
            inverse: true,
            data: data.items.map(({ codigo_nome }) => codigo_nome),
            axisLabel: {
              fontSize: 10,
              textStyle: { color: '#ddd' }
            },
          },
          series: [{
            name: 'Impacto na Qualidade',
            type: 'bar',
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              distance: -1,
              padding: [2.5, 10],
              borderRadius: [0, 30, 30, 0],
              textShadowColor: '#000',
              textShadowBlur: 3,
              formatter: ({ value }) => `${this.formatNumber(value)}%`
            },
            data: data.items.map(({ result }) => result),
          }],
        }
      };
     
    },

    generateGraphPie(totalAGV) {
      if (totalAGV === 0) {
        return ;
      }
      return  {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(229, 57, 53, 0.7)'],
        title: {
          text: 'Volume médio Laticinios / Prioritários',
          textStyle: { color: '#ddd' },
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/> Volume: {c} L'
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        series: [
          {
            name: 'Volume',
            type: 'pie',
            radius: '50%',
            data: [
              { value: (this.volAgvDairy - totalAGV), name: 'Produtores dentro da norma' },
              { value: totalAGV, name: 'Produtores Prioritários' },
            ],
            labelLine: { show: false },
            label: {
              show: true,
              position: 'inside',
              formatter: '{c}',
              textShadowColor: '#000',
              textShadowBlur: 0.5,
            },
          }
        ]
      }
    },

    getImpactReportJson() {
      if (this.tab === 'aba-impacto-cpp') {
        return this.cpp.items.map(o => ({
          Código: o.codigo_laticinio,
          Produtor: o.nome_produtor,
          CPP: o.cbt,
          Volume: this.formatNumber(o.volume),
          '% Participação': `${this.formatNumber(o.result)}%`,
        }));
      } else if (this.tab === 'aba-impacto-ccs') {
        return this.ccs.items.map(o => ({
          Código: o.codigo_laticinio,
          Produtor: o.nome_produtor,
          CCS: o.ccs,
          Volume: this.formatNumber(o.volume),
          '% Participação': `${this.formatNumber(o.result)}%`,
        }));
      } else if (this.tab === 'aba-impacto-cpp-ccs') {
        return this.cppCcs.items.map(o => ({
          Código: o.codigo_laticinio,
          Produtor: o.nome_produtor,
          CCS: o.ccs,
          CPP: o.cbt,
          Volume: this.formatNumber(o.volume),
          '% Participação': `${this.formatNumber(o.result)}%`,
        }));
      }
    },

    exportExcel() {
      const data = this.XLSX.utils.json_to_sheet(this.getImpactReportJson());
      const workbook = this.XLSX.utils.book_new();
      const filename = 'Impacto na Qualidade';
      this.XLSX.utils.book_append_sheet(workbook, data, filename);
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    print() {
      if (this.tab === 'aba-impacto-cpp') {
        return this.printFromJson(this.getImpactReportJson(), 'Impacto Prioritarios CPP');
      } else  if (this.tab === 'aba-impacto-ccs') {
        return this.printFromJson(this.getImpactReportJson(), 'Impacto Prioritarios CCS');
      } else  if (this.tab === 'aba-impacto-cpp-ccs') {
        return this.printFromJson(this.getImpactReportJson(), 'Impacto Produtores com CPP e CCS acima da norma');
      }

    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  },
};
</script>
