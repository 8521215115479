<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Brucelose / Tuberculose
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#report">
        Relatório
      </v-tab>
      <v-tab href="#exams">
        Cadastro de Exames
      </v-tab>
      <v-tab href="#vaccines">
        Cadastro de Vacinas
      </v-tab>
      <v-tab href="#inventory">
        Cadastro de Inventário
      </v-tab>
      <v-tab @click="loadExams" href="#graphic">
        Gráficos
      </v-tab>
      <v-tab href="#settings-normative">
        Configurações Normativa
      </v-tab>
      <v-tab href="#settings-expiration">
        Configurações Vencimento
      </v-tab>

      <v-tab-item value="report">
        <exams-report v-if="tab === 'report'" />
      </v-tab-item>
      <v-tab-item value="exams">
        <health-exam />
      </v-tab-item>
      <v-tab-item value="vaccines">
        <health-exam-vaccine />
      </v-tab-item>
      <v-tab-item value="inventory">
        <producer-inventory />
      </v-tab-item>
      <v-tab-item value="graphic">
        <v-row>
          <v-col cols="12">
            <v-card
              dark
              color="transparent"
            >
              <v-card-title class="mb-3">
                <v-col>
                  <person-autocomplete-filter
                    v-model="filter.technician"
                    type="TECHNICAL"
                    label="Técnico"
                    dark
                    @change="loadExams"
                  />
                </v-col>
                <v-col>
                  <routes-autocomplete-filter
                    v-model="filter.routes"
                    label="Rota"
                    dark
                    multiple
                    @change="loadExams"
                  />
                </v-col>
              </v-card-title>
              <exam-details title="Brucelose" type="brucelose" :data="data" />
              <exam-details title="Tuberculose" type="tuberculose" :data="data" />
              <exam-details title="Vacina" type="vacinas" :data="data" />
              <exam-details title="Inventário" type="inventario" :data="data" />

              <exam-graphic title="Geral" :data="data" />
              <exam-graphic title="Brucelose" type="brucelose" :data="data" />
              <exam-graphic title="Tuberculose" type="tuberculose" :data="data" />
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="settings-normative">
        <health-exam-normative-settings :statesList="states" />
      </v-tab-item>
      <v-tab-item value="settings-expiration">
        <health-exam-expiration-settings :statesList="states" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ExamsReport from "@/Domains/Projects/HealthExamination/Views/ExamsReport.vue";
import HealthExam from "@/Domains/Projects/HealthExamination/Views/HealthExam.vue";
import HealthExamVaccine from "@/Domains/Projects/HealthExamination/Views/HealthExamVaccine.vue";
import ProducerInventory from "@/Domains/Projects/HealthExamination/Views/ProducerInventory.vue";
import HealthExamNormativeSettings from "@/Domains/Projects/HealthExamination/Views/HealthExamNormativeSettings.vue";
import HealthExamExpirationSettings from "@/Domains/Projects/HealthExamination/Views/HealthExamExpirationSettings.vue";
import ExamGraphic from "@/Domains/Projects/HealthExamination/Components/ExamGraphic.vue";
import ExamDetails from "@/Domains/Projects/HealthExamination/Components/ExamDetails.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

export default {
  components: {
    ExamsReport,
    HealthExam,
    HealthExamVaccine,
    ProducerInventory,
    HealthExamNormativeSettings,
    HealthExamExpirationSettings,
    ExamGraphic,
    ExamDetails,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter
  },

  data() {
    return {
      tab: "tab-exams",
      filter: {
        technician: {
          id: "",
          description: ""
        },
        routes: [],
      },
      data: [],
      states: []
    };
  },

  mounted() {
    this.getStates();
  },

  methods: {
    async loadExams() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
            tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id)
        }});

        this.data = data;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getStates() {
      try {
        const { data } = await this.$queries.get(`/estados/listaJson`);

        this.states = data.reduce((result, item) => {
          result[item.uf] = item.end_estado;
          return result;
        }, {});
      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os estados!", "Atenção");
      }
    },
  }
};
</script>
