<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Entregas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab>
            Planejamento
          </v-tab>

          <v-tab-item>
            <delivery-planning />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DeliveryPlanning from "@/Domains/Commercial/Delivery/Views/DeliveryPlanning.vue";

export default {
  components: {
    DeliveryPlanning,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>
