<template>
  <type-collect-index
    type="VETERINARIO"
  />
</template>

<script>
import TypeCollectIndex from "@/Domains/Registrations/Cares/TypeCollect/TypeCollectIndex.vue";

export default {
  components: {
    TypeCollectIndex
  },
}
</script>
