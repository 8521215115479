<template>
  <div>
    <v-menu
      ref="menu"
      v-model="show"
      :close-on-content-click="false"
      transition="slide-y-transition"
      offset-y
      content-class="date-time-text-field"
    >
      <template #activator="{ on, attrs }">
        <masked-text-field
          v-if="manual"
          v-model="localizedDateTime"
          :label="label"
          placeholder="00/00/0000 00:00"
          :mask="{ mask: '00/00/0000 00:00' }"
          :append-icon="icon"
          v-bind="$attrs"
          @click:append="show = true"
          @click:clear="clear"
          @blur="onManualChange"
        />
        <v-text-field
          v-else
          :value="localizedDateTime"
          :label="label"
          readonly
          :append-icon="icon"
          v-bind="{...attrs, ...$attrs}"
          v-on="on"
          @click:clear="clear"
        />
      </template>
      <v-card
        flat
        class="d-flex"
      >
        <v-date-picker
          v-model="date"
          :min="min"
          :max="max"
          no-title
          scrollable
          @change="onChange"
        >
          <v-btn
            text
            color="primary"
            @click="clearAndClose"
          >
            Limpar
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="setNow"
          >
            Hoje
          </v-btn>
        </v-date-picker>

        <v-divider
          class="flex-grow-0"
          inset
          vertical
        />

        <v-list
          ref="hours"
          nav
          dense
          class="pr-0"
        >
          <v-list-item-group
            v-model="hourIdx"
            color="primary"
            @change="onChange"
          >
            <v-list-item
              v-for="hour in hours"
              :key="hour"
            >
              <v-list-item-content class="px-2">
                {{ hour }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-list
          ref="minutes"
          nav
          dense
        >
          <v-list-item-group
            v-model="minuteIdx"
            color="primary"
            @change="onMinutesChange"
          >
            <v-list-item
              v-for="minute in minutes"
              :key="minute"
            >
              <v-list-item-content class="px-2">
                {{ minute }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<style lang="scss">

.date-time-text-field {
  min-width: 290px * $scale !important;

  .v-picker__body {
    width: 290px * $scale !important;
  }

  .v-list::-webkit-scrollbar {
    display: none;
  }

  .v-list {
    height: 330px * $scale !important;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
</style>

<script>
import MaskedTextField from './MaskedTextField.vue';
import moment from 'moment/moment';

export default {
  components: {
    MaskedTextField
  },

  props: {
    value: String,
    label: String,
    min: String,
    max: String,
    manual: Boolean,
    inputFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm'
    },
    icon: {
      type: String,
      default: 'event'
    },
  },

  data() {
    return {
      show: false,

      localizedDateTime: null,
      dateTime: null,

      date: moment().format('YYYY-MM-DD'),
      hourIdx: null,
      minuteIdx: null,
    };
  },

  computed: {
    hours() {
      return Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
    },
    minutes() {
      return Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));
    }
  },

  watch: {
    value() {
      return this.$nextTick(() => {
        this.updateFromValue();
      });
    },

    show(value) {
      if (value) {
        setTimeout(() => this.scrollToTime(), 400);
      }
    }
  },

  created() {
    this.updateFromValue();
  },

  methods: {
    clear() {
      this.date = null;
      this.hourIdx = null;
      this.minuteIdx = null;
      this.dateTime = null;
      this.localizedDateTime = null;
      this.onChange();
    },

    clearAndClose() {
      this.clear();
      this.close();
    },

    updateFromValue() {
      if (this.value && this.isDateValid(this.value, this.inputFormat)) {
        const dateTime = moment(this.value, this.inputFormat)

        this.date = dateTime.format('YYYY-MM-DD')
        this.hourIdx = +dateTime.format('HH')
        this.minuteIdx = +dateTime.format('mm')
        this.updateInput();
      } else {
        this.clear();
      }
    },

    onMinutesChange() {
      this.onChange();

      if (this.isDateValid(this.localizedDateTime, 'DD/MM/YYYY HH:mm')) {
        this.close();
      }
    },

    onChange() {
      this.updateInput();

      this.$emit('input', this.dateTime);
    },

    updateInput() {
      if (!this.date || this.hourIdx == null || this.minuteIdx == null) {
        this.dateTime = null
        this.localizedDateTime = null
        return
      }

      const hours = this.hours[this.hourIdx]
      const minutes = this.minutes[this.minuteIdx]

      const dateTime = moment(`${this.date} ${hours}:${minutes}`)

      this.dateTime = dateTime.format(this.inputFormat)
      this.localizedDateTime = dateTime.format('DD/MM/YYYY HH:mm')
    },

    fixDateTime(value) {
      let [date, time] = value.split(' ')
      time = time ? moment(time, 'HH:mm').format('HH:mm') : '00:00'
      return `${date} ${time}`
    },

    onManualChange(value) {
      if (!this.isDateValid(value, 'DD/MM/YYYY HH:mm')) {
        if (moment(value, 'DD/MM/YYYY').isValid() && value.length >= 10) {
          value = this.fixDateTime(value)
        } else {
          this.clear();
          return;
        }
      }

      const dateTime = moment(value, 'DD/MM/YYYY HH:mm')

      this.date = dateTime.format('YYYY-MM-DD')
      this.hourIdx = +dateTime.format('HH')
      this.minuteIdx = +dateTime.format('mm')

      this.dateTime = dateTime.format(this.inputFormat)
      this.localizedDateTime = dateTime.format('DD/MM/YYYY HH:mm')

      if (!!this.date && !!this.max && this.date > this.max) {
        this.date = null;
        this.hourIdx = null;
        this.minuteIdx = null;

        this.dateTime = null;
        this.localizedDateTime = null;
      }

      this.$emit('input', this.dateTime);
    },

    setNow() {
      this.onManualChange(moment().format('DD/MM/YYYY HH:mm'));
      this.close();
    },

    scrollToTime() {
      const hoursRef = this.$refs.hours.$el;
      const minutesRef = this.$refs.minutes.$el;
      hoursRef.scrollTop = 33 * (this.hourIdx || 0)
      minutesRef.scrollTop = 33 * (this.minuteIdx || 0)
    },

    close() {
      this.show = false;
    },

    isDateValid(value, format) {
      if (!value || value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },
  },

};
</script>
