<template>
  <div
    class="mx-6"
  >
    <v-flex
      xs12
      class="text-xs-center"
    >
      <h2
        class="menu-header white--text"
      >
        Checklist: Comparativo
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-tabs
      centered
      dark
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="tab-producer">
        Por Produtor
      </v-tab>
      <v-tab href="#tab-period">
        Por Periodo
      </v-tab>

      <v-tab-item value="tab-producer">
        <checklist-producer />
      </v-tab-item>

      <v-tab-item value="tab-period">
        <checklist-period />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>

import ChecklistProducer from "@/Domains/Projects/PQFL/Comparative/Components/ChecklistProducer.vue";
import ChecklistPeriod from "@/Domains/Projects/PQFL/Comparative/Components/ChecklistPeriod.vue";

export default {

  components: {
    ChecklistProducer,
    ChecklistPeriod,
  },

  data() {
    return {
    };
  },

  computed: {

  },

  watch: {

  },

  methods: {

  },
};
</script>
