<template>
  <div
    class="mx-6 px-7"
  >
    <v-flex
      xs12
      class="text-xs-center"
    >
      <h2
        class="menu-header white--text"
      >
        Checklist Personalizado
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-tabs
      v-model="aba"
      centered
      dark
      background-color="transparent"
      class="transparent"
      @change="onTabChange"
    >
      <v-tab href="#aba-questionnaires">
        Questionários
      </v-tab>

      <v-tab
        href="#aba-edit-questionnaires"
        :disabled="questionnaire.id === null"
      >
        Inserir/Editar
      </v-tab>

      <v-tab
        href="#aba-charts"
        :disabled="questionnaire.id === null"
      >
        Gráficos
      </v-tab>

      <v-tab
        v-if="false"
        href="#aba-reports"
        :disabled="questionnaire.id === null"
      >
        Relatórios
      </v-tab>

      <v-tab
        href="#aba-answers"
        :disabled="questionnaire.id === null"
      >
        Respostas
      </v-tab>

      <v-tab-item value="aba-questionnaires">
        <list-questionnaires
          v-if="aba === 'aba-questionnaires'"
          view="others"
          @changeTab="handleChangeTab"
          @newQuestionnaire="onNewQuestionnaire"
        />
      </v-tab-item>

      <v-tab-item value="aba-edit-questionnaires">
        <create-questionnaire
          ref="createQuestionnaire"
          view="others"
          :questionnaire-id="questionnaire.id"
          :disabled="questionnaire.disabledEdition"
          @newQuestionnaire="onNewQuestionnaire"
        />
      </v-tab-item>

      <v-tab-item
        value="aba-charts"
      >
        <questionnaires-charts
          v-if="aba === 'aba-charts'"
          view="others"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
        />
      </v-tab-item>

      <v-tab-item
        value="aba-reports"
      >
        <report-totals-by-question
          v-if="aba === 'aba-reports'"
          view="others"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
        />
      </v-tab-item>

      <v-tab-item
        value="aba-answers"
      >
        <answers-list
          v-if="aba === 'aba-answers'"
          view="others"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
        />
      </v-tab-item>

      <v-tab-item
        value="aba-abc-analysis"
      >
        <questionnaires-abc-analysis
          v-if="aba === 'aba-abc-analysis'"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<style lang="scss">
.questionnaires-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.questionnaires-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>

<script>
import ListQuestionnaires from '@/Domains/Questionnaires/Views/ListQuestionnaires.vue';
import QuestionnairesCharts from '@/Domains/Questionnaires/Views/QuestionnairesCharts.vue';
import ReportTotalsByQuestion from '@/Domains/Questionnaires/Views/ReportTotalsByQuestion.vue'
import QuestionnairesAbcAnalysis from "@/Domains/Questionnaires/Views/QuestionnairesAbcAnalysis.vue";
import AnswersList from "@/Domains/Questionnaires/Views/AnswersList.vue";
import CreateQuestionnaire from "@/Domains/Questionnaires/Views/CreateQuestionnaire.vue";

export default {
  components: {
    AnswersList,
    ListQuestionnaires,
    QuestionnairesCharts,
    ReportTotalsByQuestion,
    QuestionnairesAbcAnalysis,
    CreateQuestionnaire,
  },

  data() {
    return {
      aba: 'aba-questionnaires',

      questionnaire: {
        id: null,
        name: null,
        disabledEdition: false,
      },

      visibleCharts: true,
    }
  },
  methods: {

    /**
     * Trata evento de naveção nas abas
     */
    handleChangeTab(data) {
      if (data.tab == 'aba-edit-questionnaires') {
        // Caso o questionário já possua resposta não permite editar
        const disabledEdition = data.totalAnswered > 0;
        this.questionnaire.disabledEdition = disabledEdition;
      }
      this.questionnaire.id = data.id;
      this.questionnaire.name = data.name;
      this.aba = data.tab
    },

    /**
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange() {
      if (["aba-questionnaires", "aba-questionnaires-answered"].includes(this.aba)) {
        this.questionnaire = { id: null, name: null };
      }
    },

    onNewQuestionnaire() {
      this.questionnaire.id = '';
      this.questionnaire.disabledEdition = false;
      this.aba = 'aba-edit-questionnaires';
    },
  },
}
</script>
