<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Desvio de Produção
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-container>
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <month-range-picker
                v-model="filters.range"
                :ranges.sync="filters.ranges"
                label="Período"
                @change="onFilterChange"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <routes-autocomplete-filter
                v-model="filters.routes"
                label="Rota"
                dark
                multiple
                :return-object="false"
                @change="onFilterChange"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <v-select
                v-model="filters.status"
                :items="['TODOS', 'ATIVO', 'SUSPENSO', 'DESVINCULADO']"
                label="Produtores"
                filled
                prepend-inner-icon="how_to_reg"
                @change="onFilterChange"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="pb-0"
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                filled
                prepend-inner-icon="search"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :items="report.producers"
          :search="filters.search"
          :headers="headers"
        >
          <template
            #[`item.status`]="{ value }"
          >
            <v-chip
              :color="getStatusColor(value)"
              small
            >
              {{ value }}
            </v-chip>
          </template>

          <template
            v-for="(month) in months"
            #[`item.${month}.percentual`]="{ value, item }"
          >
            <span :key="month">
              <v-tooltip
                color="#000"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      v-if="value > 0"
                      color="green accent-3"
                    >
                      trending_up
                    </v-icon>
                    <v-icon
                      v-else-if="value < 0"
                      color="red accent-3"
                    >
                      trending_down
                    </v-icon>
                    <v-icon
                      v-else
                      color="grey accent-3"
                    >
                      trending_flat
                    </v-icon>

                    {{ formatNumber(value) }}%
                  </span>
                </template>
                <div>
                  Volume: {{ formatNumber(item[month]?.total_volume) }} L
                </div>
                <div>
                  Coletas: {{ formatNumber(item[month]?.qtde_coletas) }}
                </div>
              </v-tooltip>
            </span>
          </template>

          <template
            #[`body.append`]="{}"
          >
            <tr
              v-if="!filters.search"
              class="hidden-xs-only"
            >
              <td class="text-start">
                <b>Total</b>
              </td>
              <td />
              <td />
              <td />
              <td
                v-for="(month) in months"
                :key="month"
                class="text-center"
              >
                <v-tooltip
                  color="#000"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        v-if="report.total[month]?.percentual > 0"
                        color="green accent-3"
                      >
                        trending_up
                      </v-icon>
                      <v-icon
                        v-else-if="report.total[month]?.percentual < 0"
                        color="red accent-3"
                      >
                        trending_down
                      </v-icon>
                      <v-icon
                        v-else
                        color="grey accent-3"
                      >
                        trending_flat
                      </v-icon>

                      {{ formatNumber(report.total[month]?.percentual) }}%
                    </span>
                  </template>
                  <div>
                    Volume: {{ formatNumber(report.total[month]?.total_volume) }} L
                  </div>
                  <div>
                    Coletas: {{ formatNumber(report.total[month]?.qtde_coletas) }}
                  </div>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

import MonthRangePicker from '@/Support/Components/MonthRangePicker.vue';
import RoutesAutocompleteFilter from '@/Support/Components/Filters/RoutesAutocompleteFilter.vue';
import ReportMixin from '@/Support/Mixins/ReportMixin.js';

export default {

  components: {
    MonthRangePicker,
    RoutesAutocompleteFilter,
  },

  mixins: [ReportMixin],

  data() {
    return {
      filters: {
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().format('YYYY-MM')],
        },
        range: [],
        routes: [],
        status: 'ATIVO',
        search: '',
      },

      report: {
        producers: [],
        total: [],
      },
    };
  },

  computed: {
    months() {
      const [startDate, endDate] = this.filters.range;

      let currentMonth = startDate;

      const months = [currentMonth];

      while (currentMonth < endDate) {
        currentMonth = moment(currentMonth).add(1, 'month').format('YYYY-MM');
        months.push(currentMonth);
      }

      return months;
    },

    headers() {
      const headers = [
        { text: 'Código', value: 'codigo_laticinio', align: 'start' },
        { text: 'Produtor', value: 'nome_produtor', align: 'start' },
        { text: 'Rota Principal', value: 'nome_rota', align: 'start' },
        { text: 'Status', value: 'status', align: 'center' },
      ];

      this.months.forEach(month => {
        headers.push({
          text: _.upperFirst(moment(month).format('MMM/YY')),
          value: `${month}.percentual`,
          align: 'center',
        });
      })

      return headers;
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    onFilterChange: _.debounce(function() {
      this.loadReport();
    }, 1000),

    async loadReport() {
      try {
        this.$root.$progressBar.loading();

        const [startDate, endDate] = this.filters.range;

        const { data } = await this.$axios.post(`/coleta/volumeColetaMensal`, {
          data_inicio: startDate,
          data_fim: endDate,
          rotas: this.filters.routes,
          status: this.filters.status,
        } );

        this.report = data;

      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório!', 'Atenção');
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportJson(isExcel = false) {
      const formatNumber = (value) => !isExcel ? this.formatNumber(value) + '%' : value;
      const monthColumn = (value) => isExcel ? `${_.upperFirst(moment(value).format('MMM/YY'))} (%)` : _.upperFirst(moment(value).format('MMM/YY'));
      return [
        // Produtores
        ...this.report.producers.map(item => ({
          'Código': item.codigo_laticinio,
          'Produtor': item.nome_produtor,
          'Rota Principal': item.nome_rota,
          'Status': item.status,
          ...this.months.reduce((acc, cur) => ({
            ...acc,
            [monthColumn(cur)]: formatNumber(item[cur].percentual)
          }), {}),
        })),
        // Totais
        {
          'Código': `TOTAL`,
          'Produtor': '',
          'Rota Principal': '',
          'Status': '',
          ...this.months.reduce((acc, cur) => ({
            ...acc,
            [monthColumn(cur)]: formatNumber(this.report.total[cur].percentual)
          }), {}),
        }
      ];
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.range;
      return `Desvio de Produção - ${ this.filters.status } - ${moment(startDate).format('MMM YY').toUpperCase()} - ${moment(endDate).format('MMM YY').toUpperCase()}`;
    },

    exportExcel() {
      const title = this.getReportTitle();

      const report = this.getReportJson(true);

      this.exportToFile({ report, title });
    },

    print() {
      const title = this.getReportTitle();
      const json = this.getReportJson();

      this.printFromJson(json, title, true);
    },

    getStatusColor(value) {
      const types = {
        'ATIVO': 'green',
        'SUSPENSO': 'orange',
        'DESVINCULADO': 'red',
      }

      return types[value] || 'grey';
    },

    formatNumber: (value) => value ? new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value) : 0,
  },
}
</script>
