<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        Lançamento de Notas Produtor
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <h2 v-if="!notFound">
                {{ nome_produtor }}
              </h2>
              <h2
                v-if="notFound"
                style="color:red; opacity: 0.8;"
              >
                PRODUTOR NÃO ENCONTRADO
              </h2>
            </v-col>
            <v-col md="12">
              <v-text-field
                ref="cb"
                v-model="codigo_barras"
                dense
                type="text"
                label="Código de Barras "
                @input="findProducer"
              />
            </v-col>

            <v-col md="6">
              <masked-input-form
                v-model="cpf"
                dense
                label="CPF"
                :mask="CPFMask"
                maxlength="14"
                @input="findCPF"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="producerCode"
                dense
                label="Código do Produtor"
                maxlength="14"
                @input="findProducerCode"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="serie"
                dense
                type="text"
                label="Série *"
                maxlength="10"
                :disabled="!hasCPF"
                :rules="[v => !!v || 'Campo obrigatório']"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="numero"
                dense
                type="text"
                label="Número *"
                maxlength="10"
                :disabled="!hasCPF"
                :rules="[v => !!v || 'Campo obrigatório']"
              />
            </v-col>
            <v-col md="6">
              <date-text-field
                v-model="data_emissao"
                dense
                label="Data de Emissão *"
                prepend-inner-icon="event"
                no-title
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="grey darken-1"
          text
          @click="() => show = false"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :loading="saving"
          @click="saveNote"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import DateTextField from "@/Support/Components/DateTextField.vue";
import MaskedInputForm from "@/Domains/Producer/Management/Components/MaskedInput.vue";

export default {

  components: {
    DateTextField,
    MaskedInputForm
  },

  props: {
    value: Boolean,
    producers: Array,
  },

  data() {
    return {
      saving: false,

      id: null,

      nome_produtor: "",

      notFound: false,

      codigo_barras: "",

      data_emissao: moment().format("YYYY-MM-DD"),

      cpf: null,

      serie: null,

      numero: null,

      hasCPF: false,

      CPFMask: {
        mask: "000.000.000-00",
        maxlength: 14,
      },

      producerCode: null,
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.clean();
      } else {
        this.$emit("update");
      }
    }
  },

  methods: {
    async saveNote() {

      if (!await this.$refs.form.validate()) {
        return;
      }

      if (!this.nome_produtor) {
        this.notFound = true;
        return false;
      }

      this.saving = true;
      try {

        await this.$axios.post(
          `/tabelaPreco/salvaNotaProdutor`,
          {
            id: this.id,
            codigo_barras: this.codigo_barras,
            serie: this.serie,
            numero: this.numero,
            data_emissao: this.data_emissao
          }
        );

        this.clean();
        this.$snotify.success("Nota salva com sucesso", "Sucesso");
      } catch (error) {

        this.$snotify.error("Erro ao salvar a nota", "Atenção");
        console.error(error);

      } finally {
        this.saving = false;
      }
    },

    findCPF: _.debounce(function () {
      if (this.cpf.length < 11) {
        this.nome_produtor = '';
        return;
      }

      const bonus = this.producers.find(({ producer }) => producer.document && producer.document.replace(/[^\d]+/g, '') === this.cpf);

      if (bonus) {
        this.nome_produtor = `${bonus.producer.code} - ${bonus.producer.name}`;
        this.id = bonus.id;
      }

      this.notFound = !bonus;
      this.hasCPF = !!bonus;
    }, 200),

    findProducerCode: _.debounce(function () {
      if (this.producerCode.length <= 0) {
        this.nome_produtor = '';
        return;
      }

      const bonus = this.producers.find(({ producer }) => producer.code === this.producerCode);

      if (bonus) {
        this.nome_produtor = `${bonus.producer.code} - ${bonus.producer.name}`;
        this.id = bonus.id;
      }

      this.notFound = !bonus;
      this.hasCPF = !!bonus;
    }, 200),

    findProducer: _.debounce(function () {
      this.hasCPF = false;

      this.codigo_barras = this.codigo_barras.replace(/\s+/g, '');

      if (this.codigo_barras.length < 20) {
        this.nome_produtor = '';
        return;
      }

      // Realiza a busca do produtor a partir de uma chave de acesso de NF-e/NFP-e
      if (this.codigo_barras.length === 44) {
        return this.findProducerByNFeBarcode();
      }

      const ie = this.codigo_barras.substr(1, 10);

      const bonus = this.producers.find(({ producer }) => producer.inscricao_estadual && producer.inscricao_estadual.replace(/[^\d]+/g, '') === ie);

      this.notFound = !bonus;

      if (!bonus) {
        this.nome_produtor = '';
        this.id = null;
        this.producerCode = null;
        this.cpf = null;
        this.numero = null;
        this.serie = null;

        return;
      }

      this.nome_produtor = `${bonus.producer.code} - ${bonus.producer.name}`;
      this.id = bonus.id;

      this.numero = this.codigo_barras.substr(14);
      this.serie = this.codigo_barras.substr(11, 3);
      this.producerCode = bonus.producer.code;
      this.cpf = bonus.producer.document;
    }, 200),

    findProducerByNFeBarcode() {
      /**
       * Campos e tamanho na chave de acesso
       *
       * Código da UF - 02
       * AAMM da Emissão - 04
       * CPF/CNPJ do Emitente - 14
       * Modelo - 02
       * Série - 03
       * Número da NF-e - 09
       * Forma de Emissão - 01
       * Código Numérico - 08
       * DV - 01
       */
      let yearMonthInvoice = this.codigo_barras.slice(2, 6); // YYMM
      let series = this.codigo_barras.slice(22, 25);
      let number = this.codigo_barras.slice(25, 34);
      let identifier = this.codigo_barras.slice(6, 20);

      yearMonthInvoice = moment(yearMonthInvoice, 'YYMM').endOf('month').format('YYYY-MM-DD');
      series = parseInt(series);
      number = parseInt(number);
      identifier = parseInt(identifier);
      identifier = identifier.toString().length > 11 ? _.padStart(identifier, 14, '0') : _.padStart(identifier, 11, '0');

      const bonus = this.producers.find(({ producer }) => producer.document && producer.document.replace(/[^\d]+/g, '') === identifier);

      this.notFound = !bonus;

      if (!bonus) {
        this.nome_produtor = '';
        this.id = null;
        this.producerCode = null;
        this.cpf = null;
        this.numero = null;
        this.serie = null;

        return;
      }

      this.nome_produtor = `${bonus.producer.code} - ${bonus.producer.name}`;
      this.id = bonus.id;
      this.producerCode = bonus.producer.code;
      this.cpf = bonus.producer.document;

      this.numero = number;
      this.serie = series;
      this.data_emissao = yearMonthInvoice;
    },

    focusCb() {
      setTimeout(() => {
        this.$refs.cb.focus();
      }, 200)
    },

    clean() {
      this.nome_produtor = "";
      this.codigo_barras = "";
      this.id = null;
      this.serie = null;
      this.numero = null;
      this.cpf = null;
      this.data_emissao = moment().format("YYYY-MM-DD");
      this.hasCPF = false;
      this.notFound = false;
      this.producerCode = null;
      this.focusCb();
    }
  }
}
</script>
