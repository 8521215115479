<template>
  <v-dialog
    v-model="show"
    width="600"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Configurações de Frete
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="isValid"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0 text-h5 secondary--text"
            >
              Parâmetros de Desconto/Sobra
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.unloadTolerance"
                :items="[
                  { text: 'Plataforma', value: true },
                  { text: 'Geral', value: false },
                ]"
                label="Cálculo de Tolerância"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.measuredToleranceType"
                :items="[
                  { text: 'Descarga', value: 'DESCARGA' },
                  { text: 'Percentual', value: 'PERCENTUAL' },
                  { text: 'Produtor', value: 'PRODUTOR' },
                ]"
                label="Tipo de Tolerância (Medidos)"
                :disabled="!form.unloadTolerance"
              />
            </v-col>

            <v-col
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-if="form.measuredToleranceType === 'DESCARGA'"
                v-model="form.unloadToleranceLiters"
                label="Tolerância por Descarga (Litros)"
                :mask="{ mask: Number, min: 0 }"
                unmask
                :disabled="!form.unloadTolerance"
              />
              <masked-text-field
                v-else-if="form.measuredToleranceType === 'PERCENTUAL'"
                v-model="form.unloadTolerancePerc"
                label="Tolerância por Descarga (%)"
                :mask="{ mask: Number, min: 0, max: 100 }"
                unmask
                :disabled="!form.unloadTolerance"
              />
              <masked-text-field
                v-else-if="form.measuredToleranceType === 'PRODUTOR'"
                v-model="form.collectToleranceLiters"
                label="Tolerância por Produtor (Litros)"
                :mask="{ mask: Number, min: 0 }"
                unmask
                :disabled="!form.unloadTolerance"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.includeLeftovers"
                label="Considerar Sobras na Tolerância"
                :items="[
                  { text: 'Sim', value: true },
                  { text: 'Não', value: false },
                ]"
                :disabled="!form.unloadTolerance"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.toleranceType"
                label="Tipo de Tolerância *"
                :items="[
                  { text: 'Percentual Geral', value: 'PERCENTUAL' },
                  { text: 'Passe/Falta', value: 'PASS_OR_LACK' },
                ]"
                :disabled="form.unloadTolerance"
                :rules="!form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []"
              />
            </v-col>
            <v-col
              v-if="form.toleranceType === 'PASS_OR_LACK'"
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.passOrLackTolerance"
                :label="`Tolerância Passe/Falta ${!form.unloadTolerance ? '*' : ''}`"
                sufix="L"
                :mask="{ mask: Number, min: 0 }"
                unmask
                :disabled="form.unloadTolerance"
                :rules="!form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []"
              />
            </v-col>
            <v-col
              v-else
              cols="6"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.percentualTolerance"
                :label="`Tolerância Geral ${!form.unloadTolerance ? '*' : ''}`"
                prefix="%"
                :mask="{ mask: Number, min: 0, max: 100 }"
                unmask
                :disabled="form.unloadTolerance"
                :rules="!form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.milkPriceType"
                label="Valor Litro do Leite para Cálculo de Descontos"
                :items="[
                  { text: 'Média Geral', value: 'MEDIA_GERAL' },
                  { text: 'Média Rota(s)', value: 'MEDIA_ROTA' },
                ]"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.ignoreFreightCost"
                :items="booleanRules"
                label="Ignorar Custo do Frete no Cálculo de Descontos"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0 text-h5 secondary--text"
            >
              Parâmetros Gerais
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.respectMinimumFreight"
                :items="booleanRules"
                label="Considerar Máx. da Faixa Anterior como Frete Mínimo"
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.includeNotUnloadeds"
                :items="booleanRules"
                label="Incluir Itinerários Não Descarregados (Ex: Transferências)"
                hint="Apenas para pagamento por KM"
                persistent-hint
              />
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
            >
              <v-select
                v-model="form.volumeType"
                label="Tipo de Volume para Pagamento *"
                :items="[
                  { text: 'Vale', value: 'VALE' },
                  { text: 'Medido', value: 'MEDIDO' },
                ]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="saving"
      absolute
    >
      <v-card-text>
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import get from 'lodash/get';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

export default {

  components: {
    MaskedTextField,
  },

  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      saving: false,
      isValid: true,
      form: {},

      booleanRules: [
        { text: 'Ativo', value: true },
        { text: 'Inativo', value: false },
      ],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        const generalSettings = this.$store.state.settings.gerais || {}
        const platformSettings = this.$store.state.settings.plataforma || {}
        this.form = {
          unloadTolerance: generalSettings.frete_tolerancia_descarga || false,
          measuredToleranceType: platformSettings.tipo_tolerancia_medidos_plataforma || 'DESCARGA',
          unloadToleranceLiters: platformSettings.tolerancia_medidos_plataforma,
          unloadTolerancePerc: platformSettings.tolerancia_medidos_percentual_plataforma,
          collectToleranceLiters: platformSettings.tolerancia_medidos_produtor_plataforma,
          includeLeftovers: platformSettings.considerar_sobras_tolerancia || false,
          toleranceType: generalSettings.frete_tolerancia_passe_falta ? 'PASS_OR_LACK' : 'PERCENTUAL',
          percentualTolerance: generalSettings.frete_tolerancia_percentual,
          passOrLackTolerance: generalSettings.frete_tolerancia_passe_falta,
          respectMinimumFreight: generalSettings.considerar_frete_minimo || false,
          milkPriceType: generalSettings.frete_tipo_valor_leite || 'MEDIA_GERAL',
          ignoreFreightCost: generalSettings.ignorar_custo_frete || false,
          includeNotUnloadeds: generalSettings.incluir_itinerarios_nao_descarregados || false,
          volumeType: generalSettings.frete_tipo_volume || 'VALE',
        }
      }
    }
  },

  methods: {
    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      const dairyId = get(this.$store.state.settings, 'laticinio.id');

      if (!dairyId) {
        this.$snotify.error('Escolha o laticínio!', 'Atenção');
        return;
      }

      try {
        this.saving = true;

        await Promise.all([
          this.$axios.put(
            `/settings/dairy/${dairyId}`,
            {
              frete_tolerancia_descarga: this.form.unloadTolerance,
              frete_tolerancia_percentual: this.form.percentualTolerance,
              frete_tolerancia_passe_falta: this.form.passOrLackTolerance,
              considerar_frete_minimo: this.form.respectMinimumFreight,
              frete_tipo_valor_leite: this.form.milkPriceType,
              ignorar_custo_frete: this.form.ignoreFreightCost,
              incluir_itinerarios_nao_descarregados: this.form.includeNotUnloadeds,
              frete_tipo_volume: this.form.volumeType,
            }
          ),
          this.$axios.post(
            `/configuracao/salvaConfiguracoesPlataforma`,
            this.$qs.stringify({
              configuracoesPlataforma: JSON.stringify([
                {
                  tipo_tolerancia_medidos_plataforma: this.form.measuredToleranceType,
                  tolerancia_medidos_plataforma: this.form.unloadToleranceLiters,
                  tolerancia_medidos_percentual_plataforma: this.form.unloadTolerancePerc,
                  tolerancia_medidos_produtor_plataforma: this.form.collectToleranceLiters,
                  considerar_sobras_tolerancia: this.form.includeLeftovers,
                },
              ]),
            })
          )
        ])

        this.$store.dispatch('updateStoreSetting');
        this.$store.dispatch('updatePlatformSettings');
        this.$snotify.success('Configuração salva com sucesso!', 'Sucesso');
        this.show = false;
      } catch (error) {
        const message = get(error, 'response.data.message', 'Erro ao salvar configuração');
        this.$snotify.error(message);
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    cancel() {
      this.show = false;
    }
  }
}
</script>
