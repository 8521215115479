<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="4"
          offset="4"
        >
          <v-select
            v-model="filter.project"
            :loading="loading"
            :items="projectList"
            label="Metas"
            item-value="id_assistencia"
            item-text="descricao"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadGoals"
          />
        </v-col>
      </v-row>

      <average-graphic
        v-for="q in quality"
        :key="q.indicador+q.acao"
        :goal="q"
        :filter="filter"
      />
    </v-flex>
  </div>
</template>

<script>

import AverageGraphic from "@/Domains/Projects/Dairy/QualityGoals/Components/AverageGraphic.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "quality-report",

  components: {
    AverageGraphic,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {

      loading: false,

      filter: {
        project: {
          id_assistencia: null,
          descricao: '',
        },
      },

      projectList: [],
      quality: [],

    };
  },

  created() {
    this.loadGoals();
  },

  methods: {

    async loadRange() {
      let quality = this.filter.project.quality ;

      let {data } = await this.$axios.post(
        `/relatorioIn/loadIndicatorsGoals`,
        {
          meta: quality,
        }
      );
      this.quality = data;

    },

    async loadGoals() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy",
            view: 'quality'
          }
        );

        this.projectList = data.map((proj) => {
          return {
            quality: proj.qualidade ? JSON.parse(proj.qualidade) : [],
            descricao: proj.descricao,
            id_assistencia: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
