<template>
  <div>
    <v-dialog
      v-model="show"
      width="1060"
      max-width="1060"
    >
      <v-card>
        <v-card-title>Configuração das permissões: {{ descricaoPerfil }}</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
          >
            <input
              v-model="idPerfil"
              type="hidden"
            >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="searchPermission"
                  label="Pesquisar"
                  flat
                  hide-details
                  clearable
                  @input="handleSearchPermission"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-treeview
                  ref="tree"
                  v-model="permissionSelected"
                  selectable
                  :items="listPermissions"
                  :search="searchPermission"
                  :open.sync="open"
                  open-on-click
                  return-object
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="black--text"
            text
            @click="show = false;"
          >
            cancelar
          </v-btn>
          <v-btn
            class="blue--text"
            text
            @click="
              onProfilePermissionSave();"
          >
            Salvar
          </v-btn>
        </v-card-actions>
        <v-overlay
          :value="loadingDialogProfile"
          absolute
        >
          <v-card-text>
            Aguarde...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogProfilePermission",

  props: {
    visible: {
      type: Boolean,
    },
    idPerfil: {
      type: String,
    },
    descricaoPerfil: {
      type: String,
    },
  },
  
  data() {
    return {
      listPermissions: [],

      permissionSelected: [],

      searchPermission: '',

      allOpened: false,

      open: [1],
          
      lastOpen: [],
    }
  },

  computed: {
    show: {
      get() {
          
        if (this.visible && this.idPerfil) {
          this.loadPermissions();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
          return false;
        }
      },
    },
      
  },
  methods: {
    handleSearchPermission: function (val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },

    async loadPermissions() {
      this.loadingDialogProfile = true;
      try {

        const { data } = await this.$axios.post(`/permissaoPessoa/listaPermissao`);
                
        this.listPermissions = data;
                
      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogProfile = false;
        this.loadProfilePermission();
      }
    },

    async onProfilePermissionSave() {
      this.loadingDialogProfile = true;
          
      try {
        const request = {
          id_perfil: this.idPerfil,
          permissoes: JSON.stringify(this.permissionSelected),
              
        };

        const { data } = await this.$axios.post(
          `/permissaoPessoa/salvaPerfilPermissao`,
          this.$qs.stringify({
            ...request,
          }),
        );

        const [ { codigo } ] = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }

        this.$snotify.success("Permissões Salvas!", "Sucesso");
            
      } catch (e) {
        console.log(e);
        this.$snotify.error("Oops, ocorreu um erro ao salvar permissao!", "Atenção");

      } finally {
        this.loadingDialogProfile = false;
        this.show = false;
      }
    },

    async loadProfilePermission() {
      this.loadingDialogProfile = true;
      try {
        const { data } = await this.$axios.post(
          `/permissaoPessoa/listaPerfilPermissao`,
          this.$qs.stringify({
            id_perfil: this.idPerfil,
          })
        );
        this.permissionSelected = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loadingDialogProfile = false;
      }
    }
        
  },

}
</script>
