<template>
  <v-card
    class="mx-auto"
    dark
    :style="{ background: color }"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Matérias Primas - {{ dairy }}</span>
    </v-card-title>
    <v-chart
      :options="options"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
export default {
  props: {
    dairy: String,
    color: {
      type: String,
      default: '#26c6da'
    },
    height: {
      type: String,
      default: '250px',
    },
    category: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
  },

  computed: {
    options() {
      const label = show => ({
        normal: {
          show,
          lineHeight: 20,
          height: 20,
          backgroundColor: '#6a7985',
          color: '#fff',
          borderRadius: 5,
          position: 'top',
          distance: 1,
          formatter: '  {c}  ',
        }
      });
      return {
        color: [
          'rgba(38, 198, 218, 0.7)', 'rgba(23,122,135,0.7)',
          'rgba(171, 71, 188, 0.7)', 'rgba(105,43,115,0.7)',
          'rgba(229, 57, 53, 0.7)', 'rgba(129,32,30,0.7)',
          'rgba(255, 255, 0, 0.7)', 'rgba(131,131,28,0.7)'
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.category,
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          }
        },
        series: this.data.map(item => ({
          name: item.name,
          type: 'bar',
          areaStyle: {},
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
          data: item.items.map((value) => ({
            value,
            label: label(!!value)
          }))
        })),
      }
    }
  },
}
</script>
