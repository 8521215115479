<template>
  <div>
    <v-container
      text-xs-center
      grid-list-lg
      class="pt-0"
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Estoque
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
      <v-tabs
        v-model="tab"
        centered
        dark
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#tab-company-stock">
          Estoque Empresa
        </v-tab>

        <v-tab
          href="#tab-consignment-stock"
        >
          Estoque em consignação
        </v-tab>

        <v-tab
          href="#tab-product-usage-stock"
        >
          Relatório de Utilização dos Produtos
        </v-tab>

        <v-tab-item value="tab-company-stock" eager>
          <company-stock
            ref="companyStock"
            :groupList="groupList"
          />
        </v-tab-item>

        <v-tab-item value="tab-consignment-stock" eager>
          <consignment-stock
            ref="consignmentStock"
            :groupList="groupList"
          />
        </v-tab-item>
      </v-tabs>
    </v-container>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        color="green"
        @click="showTransferStockDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              autorenew
            </v-icon>
          </template>
          Trasnferir Estoque
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        color="purple"
        @click="showAddStockDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Adicionar Estoque
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <transfer-stock-dialog
      v-model="trasnferStock"
      :group-list="groupList"
      @save="loadItems"
    >
    </transfer-stock-dialog>

    <add-stock-dialog
      v-model="addStock"
      :group-list="groupList"
      @save="loadItems"
    >
    </add-stock-dialog>
  </div>
</template>

<script>
import CompanyStock from "@/Domains/Registrations/Stock/Components/CompanyStock.vue";
import ConsignmentStock from "@/Domains/Registrations/Stock/Components/ConsignmentStock.vue";
import TransferStockDialog from "@/Domains/Registrations/Stock/Components/TransferStockDialog.vue";
import AddStockDialog from "@/Domains/Registrations/Stock/Components/AddStockDialog.vue";

export default {
  components: {
    CompanyStock,
    ConsignmentStock,
    TransferStockDialog,
    AddStockDialog,
  },

  data() {
    return {
      groupList: [],
      tab: 'tab-company-stock',
      trasnferStock: false,
      addStock: false,
    };
  },

  async mounted() {
    await this.getGroups();
  },

  methods: {
    async loadItems() {
      this.$refs.companyStock.loadItems();
      this.$refs.consignmentStock.loadItems();
    },

    async getGroups() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/product-group');

        this.groupList = data.map(item => {
          return {
            id: item.id_grupo_produto,
            group: item.grupo,
            fields: item.campos,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    showTransferStockDialog() {
      this.trasnferStock = true;
    },
    showAddStockDialog() {
      this.addStock = true;
    },
  },
}
</script>
