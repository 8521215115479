<template>
  <v-dialog
    v-model="dialog"
    :max-width="400"
  >
    <v-card>
      <v-card-title
        class="blue--text text-h5"
      >
        Calcular Frete Real
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="type"
                label="Tipo de Cálculo"
                :items="[
                  { value: 'CONFIGURACAO', text: 'Tabela de Preço de Rotas' },
                  { value: 'FECHAMENTO', text: 'Valor de Fechamento de Frete' },
                ]"
                prepend-inner-icon="price_change"
                :rules="[v => !!v || 'Escolha o Tipo de Cálculo']"
              />
            </v-col>
            <v-col
              v-if="!!type"
              cols="12"
            >
              <v-alert
                dense
                light
                colored-border
                border="left"
                elevation="2"
                type="info"
                class="text-body-1"
              >
                {{ alertMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          outlined
          @click.native="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          outlined
          @click.native="confirm()"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed  } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'
import { round } from '@/Support/Resources/utils.js'

const { progressBar, notify } = useUtils()

// eslint-disable-next-line no-undef
const emit = defineEmits([
  'confirm',
])

const dialog = ref(false)
const form = ref()
const monthReference = ref('')
const type = ref(null)

const alertMessage = computed(() => {
  if (type.value === 'CONFIGURACAO') {
    return 'O Valor será obtido da configuração da Tabela de Fretes Por Rota'
  }

  if (type.value === 'FECHAMENTO') {
    return 'O Valor será obtido a partir do Valor de Fechamento de Frete Por Rota'
  }

  return ''
})

const show = (data) => {
  monthReference.value = data.monthReference
  type.value = null
  dialog.value = true
}

const close = () => {
  dialog.value = false
}

const getFromRoutesTable = async () => {
  const { data } = await axios.get(`/fretes/tabelaDeRotas`)

  return data
    .filter(route => route.tipo_pagamento === 'VOLUME' && route.valor)
    .reduce((acc, cur) => ({ ...acc, [cur.id_rota]: round(parseFloat(cur.valor), 4) }), {})
}

const getFromFreightClosing = async () => {
  const { data } = await axios.get(`/tabelaPreco/mediaPrecoLeitePorRota`, { params: {
    mes_referencia: monthReference.value
  } })

  return data
    .filter(route => route.valor_medio_frete)
    .reduce((acc, cur) => ({ ...acc, [cur.id_rota]: round(cur.valor_medio_frete, 4) }), {})
}

const confirm = async () => {
  if (!await form.value?.validate()) {
    return
  }
  try {
    progressBar?.loading()

    const table = await (type.value === 'FECHAMENTO' ? getFromFreightClosing() : getFromRoutesTable())

    if (Object.keys(table).length === 0) {
      notify.warning('Nenhum valor encontrado!')
      return
    }

    emit('confirm', table)
    close()

  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao carregar as configurações de Fretes!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

// eslint-disable-next-line no-undef
defineExpose({
  show,
  close
})
</script>
