<template>
  <div>
    <v-form
      ref="form"
      v-model="validation.valid"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <masked-text-field
            v-model="localData.desconto_maximo_comercial"
            label="Desconto máximo"
            prefix="%"
            :mask="{ mask: Number, min: 0, max: 100 }"
            outlined
            unmask
            :rules="[v => !v || v <= localData.percentual_comissao || 'Desconto máximo deve ser menor que o valor da comissão']"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <masked-text-field
            v-model="localData.percentual_comissao"
            label="Comissão"
            prefix="%"
            :mask="{ mask: Number, min: 0, max: 100 }"
            outlined
            unmask
          />
        </v-col>
        <v-col
          cols="12"
        >
          <commercial-profile-select
            v-model="localData.id_perfil_comercial"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="black--text"
        text
        @click="$emit('cancel')"
      >
        cancelar
      </v-btn>
      <v-btn
        class="blue--text"
        text
        @click.stop="$emit('save')"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import CommercialProfileSelect from "@/Domains/Registrations/User/Components/CommercialProfileSelect.vue";

export default {
  components: {
    MaskedTextField,
    CommercialProfileSelect,
  },
  props: {
    dataUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validation: {
        valid: true,
      },

      // TODO: Carregar tabela de preço para seleção
      priceLists: [
        { text: 'Padrão', value: null }
      ]
    };
  },
  computed: {
    localData: {
      get() {
        return this.dataUser;
      },

      set(newValue) {
        return this.$emit("update:dataUser", newValue);
      },
    },

  },

  mounted() {
    this.formValidate();
  },

  methods: {

    /**
     * @void
     * Reseta a validação dos formulários
     */
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    
    /**
     * @void
     * Valida os campos do formulários
     */
    formValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta a validação dos formulários
     */
    formResetValidate() {
      this.$refs.form.validate()
    },

    /**
     * @void
     * Reseta o formulário
     */
    formReset() {
      this.$refs.form.reset()
    },
  },
};
</script>