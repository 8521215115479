<template>
  <v-card :dark="false">
    <v-card-title>
      Complementos
      <v-spacer />

      <v-btn
        v-if="!editInvoice"
        icon
        color="blue"
      >
        <v-icon
          @click="addComplement"
        >
          add
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />
    <v-simple-table>
      <template #default>
        <tbody>
          <tr
            v-for="(item, idx) in localItems"
            :key="idx"
          >
            <td class="text-left">
              <masked-text-field
                v-model.number="item.amount"
                label="Valor Complementar"
                :mask="{ mask: Number, min: 0, scale: 4, signed: false }"
                prefix="R$"
                unmask
                outlined
                dense
                :disabled="!!item.invoice || editInvoice"
                class="pt-4"
                @input="onChange"
              />
            </td>
            <td
              v-if="editInvoice"
              class="text-left pl-0"
            >
              <v-text-field
                v-model.number="item.invoice"
                label="Nota Fiscal"
                type="number"
                outlined
                dense
                :disabled="item.locked"
                class="pt-4"
              />
            </td>
            <td
              v-else
              class="text-right pl-0"
            >
              <v-btn
                v-if="!item.invoice"
                icon
                color="red"
              >
                <v-icon
                  @click="removeComplement(idx)"
                >
                  delete
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-actions>
      <v-spacer />

      <v-btn
        outlined
        @click="close()"
      >
        Fechar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

export default {

  components: {
    MaskedTextField,
  },

  props: {
    items: Array,
    editInvoice: Boolean
  },

  computed: {
    localItems: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit('update:items', value);
      },
    },
  },

  methods: {
    addComplement() {
      this.localItems.push({
        id: uuidv4(),
        amount: null,
        invoice: null
      });
    },

    removeComplement(idx) {
      this.localItems.splice(idx, 1);
    },

    calculateTotal() {
      const total = this.localItems.reduce((acc, cur) => acc + (parseFloat(cur.amount || 0)), 0);
      this.$emit('update:total', total);
    },

    onChange(value) {
      this.calculateTotal();
      this.$emit('change', value);
    },

    close() {
      this.$emit('update:show', false);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(value),
  }
}
</script>
