<template id="report-totals">
  <div
    class="mx-6"
  >
    <questions-card :label="questionnaireName">
      <v-row>
        <v-col
          cols="4"
          class="py-0"
        >
          <v-tabs
            v-model="aba"
            dark
            background-color="transparent"
            class="transparent"
            style="margin-bottom: 15px"
            @change="onChangeTab"
          >
            <v-tab href="#aba-report">
              <v-icon class="ma-1">
                list
              </v-icon>
              Relatório
            </v-tab>

            <v-tab
              href="#aba-chart"
              :disabled="aba !== 'aba-chart'"
            >
              <v-icon class="ma-1">
                insert_chart_outlined
              </v-icon>
              Gráfico
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col
          cols="4"
          class="py-0 white--text"
        >
          <h2>
            {{ questionnaireDateStart ? formatDate(questionnaireDateStart, 'DD/MM/YYYY') + ' a ': '' }}
            {{ questionnaireDateEnd ? formatDate(questionnaireDateEnd, 'DD/MM/YYYY'): '' }}
          </h2>
        </v-col>

        <v-col
          v-if="view === 'visits'"
          cols="4"
          class="py-0"
        >
          <v-tabs
            v-model="answeredFilter"
            dark
            background-color="transparent"
            class="questionnaire-tabs transparent"
            right
          >
            <v-tab href="#all">
              <v-icon class="mr-2">
                grading
              </v-icon>
              Todas as Respostas
            </v-tab>
            <v-tab href="#last-producer-answer">
              <v-icon class="mr-2">
                rule
              </v-icon>
              Última Resposta
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-tabs-items v-model="aba">
        <v-tab-item value="aba-report">
          <v-row>
            <v-col cols="4">
              <routes-autocomplete-filter
                v-if="view === 'visits'"
                v-model="filters.route"
                label="Rota"
                dark
                @change="onfilter"
              />
            </v-col>
            <v-col
              v-if="view === 'visits'"
              md="2"
              class="pb-0"
            >
              <v-select
                v-model="filters.producerStatus"
                dark
                filled
                item-text="text"
                item-value="value"
                return-object
                label="Status Produtor"
                :items="[
                  {text:'Todos',value:'*'},
                  {text:'Ativos',value:'ativo'},
                  {text:'Suspenso',value:'suspenso'},
                  {text:'Prospectado',value:'prospectado'},
                  {text:'Desvinculado',value:'desvinculado'},
                ]"
                multiple
                @change="onfilter"
              />
            </v-col>
          </v-row>
          <div
            v-for="(values, alternatives,index ) in questions"
            :key="index"
          >
            <v-col
              :key="index"
              cols="12"
              height="500"
              class="pa-0 ma-0"
            >
              <list-anwers-by-counter
                :ref="`list-${index+1}`"
                :questions="values"
                :show-check.sync="generateChart.showSeleted"
                :list-selected="ListSelected"
                :title="questionnaireName"
                @selectable="handleChartSelected"
              />
            </v-col>
          </div>
        </v-tab-item>

        <v-tab-item value="aba-chart">
          <div
            v-if="aba === 'aba-chart'"
            :style="`height: ${height}px`"
          >
            <v-chart
              ref="chart"
              :options="options"
              autoresize
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </questions-card>

    <v-fab-transition>
      <v-tooltip
        v-if="aba ==='aba-report'"
        left
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!generateChart.showSeleted"
            fixed
            fab
            large
            dark
            bottom
            right
            class="mr-5"
            color="yellow darken-4"
            v-on="on"
            @click="onVisibleCheck(true)"
          >
            <v-icon class="material-icons-outlined">
              addchart
            </v-icon>
          </v-btn>

          <v-btn
            v-else
            fixed
            fab
            large
            dark
            bottom
            right
            class="mr-5"
            color="yellow darken-4"
            @click="onVisibleCheck(false)"
            v-on="on"
          >
            <v-icon class="material-icons-outlined">
              close
            </v-icon>
          </v-btn>
        </template>

        <span>Gerar Gráfico</span>
      </v-tooltip>
    </v-fab-transition>

    <v-fab-transition>
      <v-tooltip
        v-if="generateChart.showSeleted"
        left
      >
        <template v-slot:activator="{ on }">
          <v-btn
            fixed
            fab
            large
            dark
            bottom
            right
            color="green darken-1 mr-5"
            style="margin-bottom: 70px !important"
            v-on="on"
            @click="onCreateChart"
          >
            <v-icon class="material-icons-outlined">
              done_all
            </v-icon>
          </v-btn>
        </template>

        <span>Gerar</span>
      </v-tooltip>
    </v-fab-transition>

    <v-overlay v-model="loading">
      Carregando ...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>
  </div>
</template>

<style>
.questionnaire-tabs {
  width: auto !important;
}
</style>

<script>
import * as _ from "lodash";
import printJS from "print-js";
import moment from "moment-timezone";

import QuestionsCard from "@/Domains/Questionnaires/Components/QuestionsCard.vue";
import ListAnwersByCounter from "@/Domains/Questionnaires/Components/ListAnwersByCounter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

export default {

  components: {
    QuestionsCard,
    ListAnwersByCounter,
    RoutesAutocompleteFilter
  },

  filters: {
    /**
     * Retorna o valor corresponde ao total de resposta de uma determinada alternativa da questão;
     * @returns {integer}
     */
    findSumAnswers: function (value, question) {
      if (_.isEmpty(question)) {
        return 0;
      }

      return _.get(question, `count_by_answers.${value}`, 0);
    },
  },

  props: {
    view: {
      type: String,
    },
    project: {
      type: String,
    },

    questionnaireId: {
      type: String,
    },

    questionnaireName: {
      type: String,
      default: "Resposta Questionário",
    },

    questionnaireDateStart: {
      type: String,
      default: null
    },

    questionnaireDateEnd: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      questions: [],

      loading: false,

      /**
       * Será marcada quando alguma lista for selecionada
       */
      ListSelected: false,

      /**
       * Objeto com as informações para grerar p gráfico
       */
      generateChart: {
        showSeleted: false,
        dataChart: {
          headers: [],
          data: [],
        },
      },

      /**
       * Model das tabs
       */
      aba: null,

      answeredFilter: null,

      filters: {
        route: null,
        producerStatus: [],
      },

    };
  },

  computed: {

    // dimensões do gráfico 5
    dimensions() {
      const columns = this.generateChart.dataChart.headers.map((header) => {
        return header.text;
      });

      return [ 'product', ...columns];
    },

    source() {
      // É necessário inverter a possição das informações para exibir corretamente na tabela
      return _.reverse(this.generateChart.dataChart.data.map((data) => {

        const header = this.generateChart.dataChart.headers.map(header => header.text);

        /**
         * Remove os columns que foram removida da tabela
         */
        const columnsVisible = _.pick(data, header);

        return {
          ...columnsVisible,
          'product': data.label,

        };

      }));
    },

    series() {
      return this.generateChart.dataChart.headers.map(() => {
        return {
          type: "bar",
          label: {
            show: true
          },
        };
      });
    },

    /**
     * Categoria dos gráficos
     */
    category() {
      const category = this.generateChart.dataChart.headers.map((header) => {
        return header.text;
      });

      return category;
    },

    options() {
      return {
        toolbox: {
          left: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                const image = this.$refs.chart.getDataURL({ backgroundColor: '#6a7985' });

                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        dataset: {
          dimensions: this.dimensions,
          source: this.source,
        },
        tooltip: {
          axisPointer: {
            type: "shadow",
          },
          position: "top",
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#F3F3F3",
          },
        },
        yAxis: {
          type: "category",
          axisLabel: {
            margin: 6,
            color: "#F3F3F3",
          },
        },
        grid: {
          left: "25%",
          right: 0,
          bottom: 40,
          top: 40,
        },
        series: this.series,
      };
    },
    height() {
      return 70 + (this.generateChart.dataChart.data.length * 25);
    }

  },

  watch: {
    async answeredFilter() {
      await this.loadAnswers();
    }
  },

  mounted() {
    if (this.questionnaireDateStart) {
      this.filters.producerStatus = [
        { text: 'Ativos', value: 'ativo' },
        { text: 'Suspenso', value: 'suspenso' },
      ];
      this.answeredFilter = 'last-producer-answer';
    } else {
      this.loadAnswers();
    }
  },

  methods: {
    async loadAnswers() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaRespostasFormulario`,
          {
            id_formulario: this.questionnaireId,
            id_rota: this.filters.route ? this.filters.route.id : null,
            agrupa_respostas: this.view === 'visits' && this.answeredFilter !== 'all',
            data_inicio: this.questionnaireDateStart || null,
            data_fim: this.questionnaireDateEnd || null,
            produtor_status: this.filters.producerStatus ? _.mapValues(this.filters.producerStatus, 'value') : '*',
            projeto: this.project || null,
            tipo: this.view || null,
          }
        );

        if (!data) {
          this.withAnswers = false;
          this.questions = [];
          throw "PHP Error";
        }

        /**
         * Verifica se passou
         */
        if (_.has(_.head(data), "codigo")) {
          this.withAnswers = false;
          this.questions = [];
          throw data.mensagem;
        }

        this.questions = _.orderBy(
          _.map(_.groupBy(data, "rotulo"), (itens, index) => {
            // conta quantidade de respostas e agrupando por resposta
            const count = _.countBy(itens, (value) => {

              return value.resposta;
            });

            const producer = _.reduce(_.groupBy(itens, "id_produtor"), (acc, curr) => {

              _.map(curr, data => {
                acc.push({
                  code: data.codigo_laticinio,
                  name: data.nome_produtor,
                  id: data.id_produtor,
                  answer: data.resposta,
                  date: data.data_hora_registro,
                  status: data.status === 'APROVADO' ? 'ATIVO' : data.status,
                });
              });

              return acc;
            }, []);

            return {
              label: index,
              answered_questions: itens,
              count_by_answers: count,
              detail: producer,
              answers: JSON.parse(_.get(_.head(itens), "valores")),
            };
          }),
          (value) => value.answers.length
        );

        // Agroupa por grupos de alternativas
        this.questions = _.groupBy(this.questions, "answers");
        console.log(this.questions);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Trata a exibição do checkbox do seletor dos gráficos
     * @void
     */
    onVisibleCheck(valeu) {
      this.generateChart.showSeleted = valeu;
      this.ListSelected = false;
    },

    /**
     * @void
     * Trata a lista que foi seleciona para gerar o gráfico
     */
    handleChartSelected(event) {

      if (_.isEmpty(event.data)) {
        this.generateChart.dataChart = {
          headers: [],
          data: [],
          sort: [],
        };

        this.ListSelected = false;

        return false;
      }

      // indica de já existe alguma lista selecionada
      this.ListSelected = true;

      this.generateChart.dataChart = event;
    },

    /**
     * @void
     * Evento de geração do Gráfico
     */
    onCreateChart() {
      try {

        //Verifica se foi selecionado alguma das listas
        if (_.isEmpty(this.generateChart.dataChart.data)) {
          throw "Selecione uma lista!";
        }

        const list = _.head(_.filter(this.$refs, (component) => {

          if (_.isEmpty(component)) return false;

          return component[0].ChartSelected;
        }))[0];

        /**
         *  Atuializar as informações para gerar o gráfico
         */
        this.generateChart.dataChart = list.aboutList;

        // Troca para aba do gráfico
        this.aba = "aba-chart";

        this.generateChart.showSeleted = false;
      } catch (error) {

        this.$snotify.info(`${error}`, "Atenção");
      }
    },

    /**
     * @void
     */
    onChangeTab() {
      /**
       * Quando voltar para aba do relatória reseta os campos referente ao gráfico
       */
      if (this.aba === "aba-report") {
        this.generateChart = {
          showSeleted: false,
          ChartSelected: null,
          dataChart: {
            headers: [],
            data: [],
          },
        };

        this.ListSelected = false;
      }
    },

    onfilter() {
      this.loadAnswers();
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
