<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="project-pmls"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Programa Mais Leite Saudável - PMLS
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-row>
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#financial">
            Financeiro
          </v-tab>
          <v-tab href="#panorama">
            Panorama
          </v-tab>
          <v-tab href="#payments">
            Pagamentos
          </v-tab>
          <v-tab href="#presumed_credit">
            Créditos presumidos
          </v-tab>
        </v-tabs>
      </v-row>

      <v-row>
        <v-col
          v-if="tab == 'financial' || tab == 'payments' || tab == 'presumed_credit'"
          cols="12"
          md="3"
        >
          <v-select
            v-model="filter.project"
            :items="pmlsList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadRange"
          />
        </v-col>
        <v-col
          v-if="tab == 'financial' || tab == 'payments' || tab == 'presumed_credit'"
          cols="12"
          md="3"
        >
          <month-range-picker
            v-model="filter.range"
            :ranges.sync="filter.ranges"
            :min="filter.min"
            :max="filter.max"
            dark
          />
        </v-col>
      </v-row>

      <v-row>
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
          @change="onTabChange"
        >
          <v-tab-item value="financial">
            <financial
              ref="financial"
              aba="financial"
              :filter="filter"
            />
          </v-tab-item>

          <v-tab-item value="panorama">
            <panorama
              ref="panorama"
              aba="panorama"
              :filter="filter"
            />
          </v-tab-item>

          <v-tab-item value="payments">
            <payments
              ref="payments"
              aba="payments"
              :filter="filter"
            />
          </v-tab-item>

          <v-tab-item value="presumed_credit">
            <presumed-credit
              ref="presumed_credit"
              aba="presumed_credit"
              :filter="filter"
            />
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-flex>
  </v-container>
</template>

<style lang="scss" scoped>
.project-pmls {

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script>
import Financial from '@/Domains/Projects/PMLS/Views/Financial.vue';
import Panorama from '@/Domains/Projects/PMLS/Views/Panorama.vue';
import Payments from '@/Domains/Projects/PMLS/Views/Payments.vue';
import PresumedCredit from '@/Domains/Projects/PMLS/Views/PresumedCredit.vue';
import MonthRangePicker from "@/Support/Components/MonthRangePicker.vue";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  //name: "project-pmls",

  components: {
    Financial,
    Panorama,
    Payments,
    PresumedCredit,
    MonthRangePicker
  },

  props: {
    parent: Object,
    aba: String,
  },

  data() {
    return {
      tab: null,

      planning: {
        selected: false,
        id: null
      },

      filter: {
        project: {
          start: null,
          end: null,
        },
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM'), moment().endOf('year').format('YYYY-MM')],
        },
        range: [],

        min: moment().startOf('year').format('YYYY-MM'),

        max: moment().endOf('year').format('YYYY-MM'),
      },

      pmlsList: [],
    };
  },

  created() {
    this.loadPmls();
  },

  methods: {
    onTabChange() {

      if (this.tab == 'financial' && this.$refs.financial) {
        this.$refs.financial.loadVisits()
      }
      if (this.tab == 'panorama' && this.$refs.panorama) {
        this.$refs.panorama.loadVisits()
      }
      if (this.tab == 'payments' && this.$refs.payments) {
        this.$refs.payments.loadVisits()
      }
      if (this.tab == 'presumed_credit' && this.$refs.presumed_credit) {
        this.$refs.presumed_credit.loadVisits()
      }
      this.planning.selected = false;
      this.planning.id = null;
    },

    async loadRange() {
      let dateEnd = (moment() < moment(this.filter.project.dateEnd)) ? moment().format('YYYY-MM') : moment(this.filter.project.dateEnd).format('YYYY-MM');

      this.filter.max = dateEnd;
      this.filter.min = moment(this.filter.project.dateStart).format('YYYY-MM');
      this.filter.range = [moment(this.filter.project.dateStart).format('YYYY-MM'), dateEnd];
    },

    async loadPmls() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PMLS",
          }
        );

        this.pmlsList = data.map((x) => {
          return {
            dateStart: x.data,
            dateEnd: x.data_fim,
            text: "De " + moment(x.data).format('MM/YYYY') + " até " + moment(x.data_fim).format('MM/YYYY'),
            quality: x.qualidade,
            visit: x.visita,
            //protocolo: x.protocolo,
            //descricao: x.descricao
          }
        });

        if (!_.isEmpty(this.pmlsList)) {
          this.filter.project = this.pmlsList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      }
    },

    onNewPlanning() {
      this.planning.selected = true;
      this.planning.id = null;
      this.tab = 'planning';
    },

    onSelectPlanning(id) {
      this.planning.selected = true;
      this.planning.id = id;
      this.tab = 'planning';
    },
  },
};
</script>
