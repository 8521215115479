<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <v-select
        v-model="form.tributacao"
        :items="cst"
        label="Situação Tributária (CST) *"
        prepend-inner-icon="tag"
        :rules="[v => !!v || 'Campo obrigatório!']"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.aliquota"
        label="Alíquota"
        :mask="masks.percentual"
        unmask
        prepend-inner-icon="percent"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.base"
        label="Base"
        :mask="masks.percentual"
        unmask
        prepend-inner-icon="percent"
      />
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <v-select
        v-model="form.descontar_iss"
        :items="[
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ]"
        label="Descontar ISS"
      />
    </v-col>
  </v-row>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

export default {

  components: {
    MaskedTextField,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      masks: {
        percentual: { mask: Number, min: 0, max: 100, scale: 4 }
      },

      cst: [
        { value: 'ISENTO', text: 'Isento ' },
        { value: 'TRIBUTADO', text: 'Tributado' },
        { value: 'OUTRO', text: 'Outra situação' },
      ],

    }
  },

  computed: {
    form: {
      get() {
        return this.data;
      },

      set(newValue) {
        return this.$emit("update:data", newValue);
      },
    },
  },
}
</script>
