<template>
  <div class="dashboard">
    <GridStack
      name="BI"
      :items="charts"
    >
      <template #filter>
        <VDateRangePicker
          v-model="filter.date"
          :ranges="filter.dateRanges"
          dark
        />
      </template>

      <template #default="{ widgets, isLocked, remove }">
        <GridStackItem
          v-for="widget in widgets"
          :key="widget.component"
          :widget="widget"
          :is-locked="isLocked"
          @remove="remove(widget)"
        >
          <component
            :is="widget.component"
            :date="filter.date[1]"
            :period="filter.date"
            :reference-month="referenceMonth"
            height="100%"
          />
        </GridStackItem>
      </template>
    </GridStack>
  </div>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';

import GridStack from '@/Domains/BI/Components/GridStack.vue';
import GridStackItem from '@/Domains/BI/Components/GridStackItem.vue';

//General
import VolumeByCycleChart from '@/Domains/BI/Views/General/Components/VolumeByCycleChart.vue';
import VolumeByMonthChart from '@/Domains/BI/Views/General/Components/VolumeByMonthChart.vue';
import MilkPlanningChart from '@/Domains/BI/Views/General/Components/MilkPlanningChart.vue';
import MilkAverageByDairyChart from '@/Domains/BI/Views/General/Components/MilkAverageByDairyChart.vue';
import MilkAverageEightForEight from '@/Domains/BI/Views/General/Components/MilkAverageEightForEight.vue';
import MilkAverageFourForFour from '@/Domains/BI/Views/General/Components/MilkAverageFourForFour.vue';
import MilkAverageFourForMonth from '@/Domains/BI/Views/General/Components/MilkAverageFourForMonth.vue';
import MilkAverageEightForMonth from '@/Domains/BI/Views/General/Components/MilkAverageEightForMonth.vue';
import MilkAverageWithSpotChart from '@/Domains/BI/Views/General/Components/MilkAverageWithSpotChart.vue';
import MilkAverageByStateChart from '@/Domains/BI/Views/General/Components/MilkAverageByStateChart.vue';
import ProducerVolumeAverageByMonth from '@/Domains/BI/Views/General/Components/ProducerVolumeAverageByMonth.vue';
import RegionVolumeAverageByMonth from '@/Domains/BI/Views/General/Components/RegionVolumeAverageByMonth.vue';

//Quality
import CbtChart from '@/Domains/BI/Views/Quality/Components/CBTChart.vue';
import CcsChart from '@/Domains/BI/Views/Quality/Components/CCSChart.vue';
import CbtMeanChart from '@/Domains/BI/Views/Quality/Components/CBTMeanChart.vue';
import CcsMeanChart from '@/Domains/BI/Views/Quality/Components/CCSMeanChart.vue';
import FatMeanChart from '@/Domains/BI/Views/Quality/Components/FatMeanChart.vue';
import ProteinMeanChart from '@/Domains/BI/Views/Quality/Components/ProteinMeanChart.vue';
import TotalSolidsMeanChart from '@/Domains/BI/Views/Quality/Components/TotalSolidsMeanChart.vue';
import RejectedCollectionsChart from '@/Domains/BI/Views/Quality/Components/RejectedCollectionsChart.vue';
import VolumeByDistanceChart from '@/Domains/BI/Views/Quality/Components/VolumeByDistanceChart.vue';
import CaseinMeanChart from '@/Domains/BI/Views/Quality/Components/CaseinMeanChart.vue';

// Transport
import VehicleTracking from '@/Domains/BI/Views/Transport/Components/VehicleTracking.vue';
import OccupancyRateChart from '@/Domains/BI/Views/Transport/Components/OccupancyRateChart.vue';
import OccupancyRateAverageChart from '@/Domains/BI/Views/Transport/Components/OccupancyRateAverageChart.vue';
import FleetCard from '@/Domains/BI/Views/Transport/Components/FleetCard.vue';
import LitersPerKmChart from '@/Domains/BI/Views/Transport/Components/LitersPerKmChart.vue';
import CollectionImpedimentsChart from '@/Domains/BI/Views/Transport/Components/CollectionImpedimentsChart.vue';

//Platform
import SpotPlannedAchievedChart from '@/Domains/BI/Views/Platform/Components/SpotPlannedAchievedGeneral.vue';
import RawMaterialTotalChart from '@/Domains/BI/Views/Platform/Components/RawMaterialTotalChart.vue';
import TotalMeasuredChart from '@/Domains/BI/Views/Platform/Components/TotalMeasuredChart.vue';

//Producers
import ProducersCard from '@/Domains/BI/Views/Producers/Components/ProducersCard.vue';
import UnlinkedLinkedChart from  '@/Domains/BI/Views/Producers/Components/UnlinkedLinkedChart.vue';
import LinkingProducersChart from '@/Domains/BI/Views/Producers/Components/LinkingProducersChart.vue';
import ProducersByMonthChart from "@/Domains/BI/Views/Producers/Components/ProducersByMonthChart.vue";

//MilkPrice
import TechnicianMilkPriceChart from '@/Domains/BI/Views/MilkPrice/Components/TechnicianMilkPriceChart.vue';
import RoutesFreightChart from '@/Domains/BI/Views/MilkPrice/Components/RoutesFreightChart.vue';
import MilkPriceCard from '@/Domains/BI/Views/MilkPrice/Components/MilkPriceCard.vue';
import AverageMilkPriceChart from '@/Domains/BI/Views/MilkPrice/Components/AverageMilkPriceChart.vue';
import MilkPriceCostChart from '@/Domains/BI/Views/MilkPrice/Components/MilkPriceCostChart.vue';
import AverageMilkPriceByRangeChart from '@/Domains/BI/Views/MilkPrice/Components/AverageMilkPriceByRangeChart.vue';
import RegionsFreightChart from '@/Domains/BI/Views/MilkPrice/Components/RegionsFreightChart.vue';

import moment from 'moment';

export default {
  components: {
    VDateRangePicker,
    GridStack,
    GridStackItem,
    RawMaterialTotalChart,
    TotalMeasuredChart,
    OccupancyRateAverageChart,
    LinkingProducersChart,
    ProducersByMonthChart,
    OccupancyRateChart,
    VehicleTracking,
    CollectionImpedimentsChart,
    RejectedCollectionsChart,
    VolumeByDistanceChart,
    VolumeByCycleChart,
    VolumeByMonthChart,
    FleetCard,
    LitersPerKmChart,
    ProducersCard,
    SpotPlannedAchievedChart,
    MilkPlanningChart,
    CbtChart,
    CcsChart,
    CbtMeanChart,
    CcsMeanChart,
    FatMeanChart,
    ProteinMeanChart,
    TotalSolidsMeanChart,
    UnlinkedLinkedChart,
    MilkAverageByDairyChart,
    MilkAverageEightForEight,
    MilkAverageFourForFour,
    MilkAverageFourForMonth,
    MilkAverageEightForMonth,
    MilkAverageWithSpotChart,
    MilkAverageByStateChart,
    ProducerVolumeAverageByMonth,
    RegionVolumeAverageByMonth,
    TechnicianMilkPriceChart,
    RoutesFreightChart,
    MilkPriceCard,
    AverageMilkPriceChart,
    MilkPriceCostChart,
    AverageMilkPriceByRangeChart,
    RegionsFreightChart,
    CaseinMeanChart,
  },

  data() {
    return {
      filter: {
        dateRanges: {
          'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
          'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
        },
        date: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      },

      charts: [
        { w: 3, h: 2, component: 'ProducersCard', description: 'Total Produtores', default: { x: 0, y: 0 } },
        { w: 5, h: 2, component: 'VolumeByMonthChart', description: 'Volume Mensal', default: { x: 3, y: 0 } },
        { w: 4, h: 2, component: 'TotalMeasuredChart', description: 'Total Medidos e Vales', default: { x: 8, y: 0 } },
        { w: 2, h: 2, component: 'OccupancyRateAverageChart', description: 'Taxa de Ocupação Média', default: { x: 0, y: 2 } },
        { w: 2, h: 2, component: 'RejectedCollectionsChart', description: 'Volume Rejeitado', default: { x: 2, y: 2 } },
        { w: 2, h: 2, component: 'CbtChart', description: 'Média CPP (Mês)', default: { x: 4, y: 2 } },
        { w: 2, h: 2, component: 'CcsChart', description: 'Média CCS (Mês)', default: { x: 6, y: 2 } },
        { w: 4, h: 2, component: 'FleetCard', description: 'Coletas', default: { x: 8, y: 2 } },
        { w: 9, h: 4, component: 'VolumeByCycleChart', description: 'Volume total por ciclo', default: { x: 0, y: 4 } },
        { w: 3, h: 4, component: 'CollectionImpedimentsChart', description: 'Impedimentos de Coleta', default: { x: 9, y: 4 } },
        { w: 9, h: 4, component: 'MilkPlanningChart', description: 'Programação Leite', default: { x: 0, y: 8 } },
        { w: 3, h: 8, component: 'MilkAverageByDairyChart', description: 'Média Volume', default: { x: 9, y: 8 } },
        { w: 9, h: 4, component: 'LinkingProducersChart', description: 'Produtores - Aprovados x Desvinculados', default: { x: 0, y: 12 } },
        { w: 12, h: 5, component: 'OccupancyRateChart', description: 'Taxa de Ocupação', default: { x: 0, y: 16 } },
        { w: 12, h: 4, component: 'LitersPerKmChart', description: 'Média de Litros por Km', default: { x: 0, y: 21 } },
        { w: 12, h: 5, component: 'SpotPlannedAchievedChart', description: 'Spot - Planejado x Realizado', default: { x: 0, y: 25 } },
        { w: 12, h: 5, component: 'UnlinkedLinkedChart', description: 'Volume - Desvinculados x Vinculados', default: { x: 0, y: 30 } },
        { w: 12, h: 4, component: 'RawMaterialTotalChart', description: 'Total por Matérias-primas', default: { x: 0, y: 35 } },
        { w: 12, h: 6, component: 'VehicleTracking', description: 'Rastreamento de veículos', default: { x: 0, y: 39 } },
        { w: 2, h: 2, component: 'CbtMeanChart', description: 'Média CPP (Mês) (Ponderada)', dateAdded: '2023-11-14' },
        { w: 2, h: 2, component: 'CcsMeanChart', description: 'Média CCS (Mês) (Ponderada)', dateAdded: '2023-11-14' },
        { w: 2, h: 2, component: 'FatMeanChart', description: 'Média Gordura (Mês) (Ponderada)', dateAdded: '2023-11-14' },
        { w: 2, h: 2, component: 'ProteinMeanChart', description: 'Média Proteína (Mês) (Ponderada)', dateAdded: '2023-11-14' },
        { w: 2, h: 2, component: 'TotalSolidsMeanChart', description: 'Média Sólidos Totais (Mês) (Ponderada)', dateAdded: '2023-11-14' },
        { w: 3, h: 2, component: 'MilkAverageEightForEight', description: 'Média Coletada 8 x 8', dateAdded: '2023-11-15' },
        { w: 3, h: 2, component: 'MilkAverageFourForFour', description: 'Média Coletada 4 x 4', dateAdded: '2023-11-15' },
        { w: 3, h: 2, component: 'MilkAverageFourForMonth', description: 'Média Coletada 4 x Mês Anterior', dateAdded: '2023-11-15' },
        { w: 3, h: 2, component: 'MilkAverageEightForMonth', description: 'Média Coletada 8 x Mês Anterior', dateAdded: '2023-11-15' },
        { w: 3, h: 4, component: 'MilkAverageWithSpotChart', description: 'Média Leite (com Spot)', dateAdded: '2023-12-08' },
        { w: 3, h: 4, component: 'MilkAverageByStateChart', description: 'Média Leite (por Estado)', dateAdded: '2023-12-08' },
        { w: 5, h: 2, component: 'ProducerVolumeAverageByMonth', description: 'Volume Médio por Produtor', dateAdded: '2023-12-11' },
        { w: 2, h: 2, component: 'RegionVolumeAverageByMonth', description: 'Volume Médio por Região', dateAdded: '2023-12-11' },
        { w: 4, h: 4, component: 'VolumeByDistanceChart', description: 'Volume Por Distância', dateAdded: '2024-01-31' },
        { w: 2, h: 2, component: 'CaseinMeanChart', description: 'Média Caseína (Mês) (Ponderada)', dateAdded: '2024-02-01'},
      ],
    }
  },

  computed: {
    referenceMonth() {
      if (!this.filter.date) {
        return moment().subtract(1, 'month').format('YYYY-MM')
      }

      return moment(this.filter.date[1]).format('YYYY-MM')
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasBIMilkPriceAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'bi-milk-price' && o.tipo === 'COMPONENTE');
    },

    hasBIProducersAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bi-producers" && o.tipo === "COMPONENTE");
    },
  },

  created() {
    this.addGraphs();
  },

  methods: {
    addGraphs() {
      if (this.hasBIMilkPriceAccess) {
        this.charts.push(
          { w: 12, h: 1, component: 'MilkPriceCard', description: 'Preço Leite', dateAdded: '2023-12-30' },
          { w: 12, h: 7, component: 'RoutesFreightChart', description: 'Média Preço Leite por Rota', dateAdded: '2023-12-30' },
          { w: 12, h: 4, component: 'AverageMilkPriceChart', description: 'Média Preço Leite por Mês', dateAdded: '2023-12-30' },
          { w: 12, h: 4, component: 'AverageMilkPriceByRangeChart', description: 'Média Preço Leite por Faixa', dateAdded: '2023-12-30' },
          { w: 12, h: 6, component: 'TechnicianMilkPriceChart', description: 'Média Preço Leite por Técnicos', dateAdded: '2023-12-30' },
          { w: 12, h: 6, component: 'RegionsFreightChart', description: 'Média Preço Leite por Região', dateAdded: '2023-12-30' },
          { w: 12, h: 5, component: 'MilkPriceCostChart', description: 'Custo Leite por Mês', dateAdded: '2023-12-30' },
        );
      }
      if (this.hasBIProducersAccess) {
        this.charts.push({ w: 5, h: 2, component: 'ProducersByMonthChart', description: 'Produtores Ativos Mensal', dateAdded: '2024-03-13' });
      }
    },
  }

};
</script>
