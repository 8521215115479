<template>
  <v-dialog
    v-model="dialog.show"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Ajustes Adicionais</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="4"
              class="pb-0"
            >
              <v-select
                v-model="form.column"
                label="Adicional *"
                placeholder=" "
                :items="additionalItems"
                :rules="[v => !!v || 'Escolha o Adicional']"
              />
            </v-col>

            <v-col
              cols="4"
              class="pb-0"
            >
              <v-select
                v-model="form.operation"
                label="Operação *"
                placeholder=" "
                :items="operationItems"
                :rules="[v => !!v || 'Escolha o Adicional']"
              />
            </v-col>

            <v-col
              v-if="['increase', 'decrease'].includes(form.operation)"
              cols="4"
              class="pb-0"
            >
              <v-text-field
                v-model.number="form.amount"
                :label="`${form.operation === 'decrease' ? 'Diminuir' : 'Adicionar'} *`"
                placeholder=" "
                class="text-body-2"
                prefix="R$"
                type="number"
                :rules="[v => v > 0 || 'Valor Inválido']"
              >
                <template #append>
                  <v-btn
                    icon
                    small
                    ripple
                    @click="toggleOperation"
                  >
                    <v-icon
                      small
                      :color="form.operation === 'decrease' ? 'red' : 'green'"
                      v-text="form.operation === 'decrease' ? 'remove' : 'add'"
                    />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="grey darken-1"
          text
          @click="hide()"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  data() {
    return {

      dialog: {
        show: false,
      },

      form: {
        column: 'technicalAdditionalAmount',

        operation: null,

        amount: null,
      },

      operationItems: [
        { text: 'Adicionar', value: 'increase' },
        { text: 'Remover', value: 'decrease' },
        { text: 'Diferença Cotação Ant.', value: 'difference' },
      ],
    }
  },

  computed: {
    additionalItems() {
      const items = [];

      if (this.hasTechnicalAdditionalAmountAccess) {
        items.push({ text: 'Adc. Técnico', value: 'technicalAdditionalAmount' });
      }

      if (this.hasDairyAdditionalAmountAccess) {
        items.push({ text: 'Adc. Mercado', value: 'dairyAdditionalAmount' });
      }

      return items;
    },

    /**
      * Recupera o role do usuário
      * @returns {String}
      */

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasTechnicalAdditionalAmountAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ajuste-adicional-bonificacao-tecnico' && o.tipo === 'COMPONENTE');
    },

    hasDairyAdditionalAmountAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'ajuste-adicional-bonificacao-mercado' && o.tipo === 'COMPONENTE');
    },
  },

  methods: {

    show() {
      this.reset();
      this.dialog.show = true;
    },

    hide() {
      this.reset();
      this.dialog.show = false;
    },

    reset() {
      this.form = {
        column: null,
        operation: null,
        amount: null,
      };
    },

    toggleOperation() {
      this.form.operation = this.form.operation === 'decrease' ? 'increase' : 'decrease';
    },

    save() {

      const valid = this.$refs.form.validate();

      if (!valid) {
        this.$snotify.error('Verifique os dados informados', "Atenção");

        return;
      }

      this.$emit('onAdditionalAdjust', {
        column: this.form.column,
        operation: this.form.operation,
        amount: this.form.amount,
      });

      return this.hide();
    },

  },

}
</script>

