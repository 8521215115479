<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="quality-cbt-critical"
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          CPP Críticos
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <month-picker
          v-model="filter.date.input"
          disable-last-thirty
          not-clearable
          @change="onDateFilter"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          @change="loadReport"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          @change="loadReport"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-text-field
          v-model="filter.cbt.value"
          clearable
          :prefix="filter.cbt.operator"
          hide-details
          filled
          label="CPP Limite"
          @click:prepend-inner="menu = true "
        >
          <template #[`prepend-inner`]>
            <v-menu
              v-model="menu"
              bottom
              fixed
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  rotate_right
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  class="px-2"
                  @click="
                    filter.cbt.operator = '>='
                    filter.cbt.description = 'CPP Maior igual'
                  "
                >
                  <b class="mr-3">>=</b>
                  <span class="overline">CPP MAIOR IGUAL</span>
                </v-list-item>
                <v-list-item
                  class="px-2"
                  @click="
                    filter.cbt.operator = '<='
                    filter.cbt.description = 'CPP Menor igual'
                  "
                >
                  <b class="mr-3">
                    &lt;&equals;
                  </b>
                  <span class="overline">CPP MENOR IGUAL</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-select
          v-model="filter.savedByIN"
          :items="['SALVO PELA IN 76/77', 'SALVO PELA IN 59', 'NÃO SALVO PELA IN']"
          prepend-inner-icon="task_alt"
          clearable
          hide-details
          filled
          label="Salvos"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="filter.status"
          :items="[
            { text: 'Ativos', value: 'ATIVOS' },
            { text: 'Desvinculados', value: 'DESVINCULADOS' },
          ]"
          label="Exibir"
          placeholder=" "
          hide-details
          dark
          filled
          @change="loadReport"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#graphs">
        Gráfico
      </v-tab>

      <v-tab href="#reports">
        Relatório
      </v-tab>

      <v-tab-item value="graphs">
        <v-card
          class="graph-card"
        >
          <v-card-text>
            <v-chart
              type="bar"
              :options="graph.options"
              :style="{width: '100%', height: `${graph.height}px !important`}"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item value="reports">
        <v-card
          dark
          class="report-card"
          color="transparent"
        >
          <v-card-title>
            <div class="flex-column align-start">
              <div>CPP CRÍTICOS</div>
              <div class="body-2">
                Produtores: {{ itemsGrafico.length }}
              </div>
            </div>

            <v-spacer />

            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                single-line
                hide-details
                dark
                label="Buscar"
              />
            </v-col>
          </v-card-title>
          <v-simple-table
            dense
            class="elevation-1 report-table"
            dark
          >
            <template #default>
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    :key="header.value"
                    :width="header.width"
                    :class="{[`text-${header.align}`]: header.align}"
                  >
                    {{ header.text }}
                    <v-menu
                      v-if="header.value == 'cbt_limite'"
                      top
                      max-width="230"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          x-small
                          text
                          v-bind="attrs"
                          :color="filter.cbt.value?'#E53935':'#fff'"
                          v-on="on"
                        >
                          <v-icon small>
                            filter_list
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item>
                          <v-list-item-content class="py-0">
                            <span class="overline">CPP LIMITE</span>
                            <v-row
                              align="center"
                              justify="end"
                            >
                              <v-col
                                md="3"
                                class="pa-0"
                                style="padding-left: 15px !important;"
                              >
                                <v-menu
                                  open-on-hover
                                  left
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      text
                                      small
                                      icon
                                      class="back"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <span

                                        class="overline"
                                        style="font-size: 18px !important;color: black; !important"
                                      > {{ filter.cbt.operator }} </span>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item
                                      class="px-2"
                                      @click="
                                        filter.cbt.operator = '>='
                                        filter.cbt.description = 'CPP Maior igual'
                                      "
                                    >
                                      <b class="mr-3">>=</b>
                                      <span class="overline">CPP MAIOR IGUAL</span>
                                    </v-list-item>
                                    <v-list-item
                                      class="px-2"
                                      @click="
                                        filter.cbt.operator = '<='
                                        filter.cbt.description = 'CPP Menor igual'
                                      "
                                    >
                                      <b class="mr-3">
                                        &lt;&equals;
                                      </b>
                                      <span class="overline">CPP MENOR IGUAL</span>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                              <v-col
                                md="9"
                                cols="9"
                                class="px-0"
                              >
                                <v-text-field
                                  v-model="filter.cbt.value"
                                  dense
                                  hide-details
                                  clearable
                                  :label.sync="filter.cbt.description"
                                  style="padding-right: 15px !important;padding-left:5px !important"
                                  class="input-cbt"
                                />
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, row) in reportItems"
                  :key="row"
                >
                  <td
                    v-for="(header, col) in headers"
                    :key="col"
                    :class="{[`text-${header.align}`]: header.align}"
                    v-bind="handleSpan(row, col)"
                  >
                    <template
                      v-if="lastThreeMonths.includes(header.value) && (row % 2 == 1)"
                    >
                      <span class="red--text font-weight-bold">
                        {{ item[header.value] }}
                      </span>
                    </template>
                    <template
                      v-else-if="header.value == 'menor_cbt_simples'"
                    >
                      <v-tooltip
                        color="#fff"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            :color="getSavedColor(item['salvo_pela_in'])"
                            small
                            v-on="on"
                          >
                            {{ item[header.value] }}
                          </v-chip>
                        </template>
                        <div
                          v-for="(cbt, i) in item.lista_cbt_mes"
                          :key="i"
                          class="black--text"
                        >
                          {{ dateFormat(cbt.data, 'DD/MM/YYYY') }}: {{ cbt.cbt }}
                        </div>
                        <div class="black--text">
                          {{ item['salvo_pela_in'] }}
                        </div>
                      </v-tooltip>
                    </template>
                    <template
                      v-else-if="header.value == 'ultima_visita'"
                    >
                      {{ dateFormat(item[header.value], 'DD/MM/YYYY') }}
                    </template>
                    <template v-else>
                      {{ item[header.value] }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-title>
            <v-spacer />
          </v-card-title>
        </v-card>

        <v-speed-dial
          fixed
          dark
          bottom
          right
          open-on-hover
          direction="top"
          transition="slide-y-reverse-transition"
          class="mr-5"
        >
          <template #activator>
            <v-btn
              color="blue darken-2"
              dark
              large
              fab
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel()"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  backup_table
                </v-icon>
              </template>
              Download (Excel)
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<style lang="scss">
.quality-cbt-critical {
  .graph-card {
    margin-top: 5px !important;
    background: rgba(0, 0, 0, 0.5) !important;

    > .v-card__title {
      color: #fff;
      background: unset;
    }

    > .v-card__text {
      color: #000 !important;
    }
  }

}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

import QualityCBTMixin from "@/Domains/Quality/Mixins/QualityCBTMixin.js";

import colors from 'vuetify/lib/util/colors'
import XLSX from "xlsx-js-style";
import moment from "moment-timezone";
import _ from "lodash";

moment.locale('pt');

export default {
  name: "quality-cbt-critical",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
  },

  mixins: [QualityCBTMixin],

  data() {
    return {
      tab: null,

      filter: {
        date: {
          input: "thisMonth",
          month: null,
        },
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        cbt: {
          value: null,
          operator: ">=",
          description: "CPP Maior igual"
        },
        savedByIN: null,
        search: null,
        status: 'ATIVOS',
      },

      items: [],
      grafico: [],

      curMonth: moment().format('YYYY-MM-DD'),
      months: [],

      menu: false,
    };
  },

  computed: {
    headers() {
      const headers = [
        { text: 'Código', value: 'codigo_laticinio', align: 'start' },
        { text: 'Produtor', value: 'nome_produtor', align: 'start', width: 200 },
        { text: 'Rota Principal', value: 'rota_principal', align: 'start' },
        { text: '', value: 'tipo', align: 'start' },
      ];

      this.months.forEach(month => {
        headers.push({
          text: _.upperFirst(moment(month).format("MMM/YY")),
          value: month,
          align: 'center',
        });
      })

      headers.push(
        {
          text: 'Menor CPP Mês',
          value: 'menor_cbt_simples',
          align: 'center',
          width: 120,
        },
        {
          text: 'Última visita',
          value: 'ultima_visita',
          align: 'center',
          width: 100,
        },
        {
          text: 'CPP Limite',
          value: 'cbt_limite',
          align: 'center',
          width: 150,
        }
      );

      return headers;
    },

    lastThreeMonths() {
      return [...this.months].slice(2, 5).map(month => month);
    },

    graph() {
      return this.generateCbtCriticalGraph(this.itemsGrafico);
    },

    reportItems() {
      if (!this.filter.cbt.value && !this.filter.savedByIN && !this.filter.search) {
        return this.items;
      }

      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;

      return this.items.filter((item, index) => {

        const month = index % 2 === 0 ? item : this.items[index - 1];

        const cbtFilter = !this.filter.cbt.value || eval(`${month.cbt_limite} ${this.filter.cbt.operator} ${this.filter.cbt.value}`);
        const savedFilter = !this.filter.savedByIN || month['salvo_pela_in'] === this.filter.savedByIN;
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return cbtFilter && savedFilter && searchFilter;
      });
    },

    itemsGrafico() {
      if (!this.filter.cbt.value && !this.filter.savedByIN && !this.filter.search) {
        return this.grafico;
      }

      const search = typeof this.filter.search === 'string' ? this.filter.search.toUpperCase().trim() : null;

      return this.grafico.filter(item => {

        const cbtFilter = !this.filter.cbt.value || eval(`${item.cbt_limite} ${this.filter.cbt.operator} ${this.filter.cbt.value}`);
        const savedFilter = !this.filter.savedByIN || item['salvo_pela_in'] === this.filter.savedByIN;
        const searchFilter = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);

        return cbtFilter && savedFilter && searchFilter;
      });
    }
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter([month]) {
      this.filter.date.month = month;
      this.months = this.getLastMonths(this.filter.date.month);
      this.loadReport();
    },

    async loadReport() {
      try {
        const month = this.filter.date.month;
        const technicianId = this.filter.technician.id;
        const routes = this.filter.routes.map(({ id }) => id)
        const status = this.filter.status;

        let { items, itemsGrafico } = await this.getQualityCbtCriticalReport(month, technicianId, routes, status);

        this.items = items;
        this.grafico = itemsGrafico;

      }
      catch (err) {
        console.warn(err)
        this.$snotify.error("Erro ao carregar o relatório de qualidade", "Atenção");
      }
    },

    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.reportItems.map(item => {
        let report = {
          Código: item.codigo_laticinio,
          Produtor: item.nome_produtor,
          'Rota Principal': item.rota_principal,
          Tipo: item.tipo,
        };

        this.months.forEach(month => {
          const title = _.upperFirst(moment(month).format("MMM/YY"));
          report[title] = item[month] || '-';
        });

        report['Menor CPP Mês'] = item.menor_cbt_simples;
        report['Última visita'] = this.dateFormat(item.ultima_visita, 'DD/MM/YYYY');
        report['CPP Limite'] = item.cbt_limite;

        return report;
      }), { origin: 'A3' });

      let merges = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];
      for (let row = 0; row < this.items.length; row++) {
        for (let col = 0; col < this.headers.length; col++) {
          const span = this.handleSpan(row, col)
          if (span && span.rowspan != undefined) {
            merges.push({ s: { r: row + 3, c: col }, e: { r: row + span.rowspan + 2, c: col } });
          }
        }
      }
      data["!merges"] = merges;

      data['A1'] = { v: `CPP CRÍTICOS` };

      data['!cols'] = [
        { wch: 10 },
        { wch: 30 },
        { wch: 30 },
        { wch: 30 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ];

      const workbook = XLSX.utils.book_new();

      const filename = "cbt_criticos";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /**
     * Retorna os últimos 5 meses a partir da data enviada
     */
    getLastMonths(month) {
      let currentMonth = moment(month);

      let monthArray = [currentMonth.startOf('month').format("YYYY-MM-DD")];
      for (let i = 1; i < 5; i++) {
        monthArray.push(currentMonth.subtract(1, "month").startOf('month').format("YYYY-MM-DD"));
      }

      return monthArray.reverse();
    },

    handleSpan(row, col) {

      const cols = [0, 1, 2, 9, 10, 11];

      if (cols.includes(col)) {
        if (row % 2 == 0) {
          return { rowspan: 2 };
        }
        else {
          return { style: 'display:none;' };
        }
      }

    },

    getSavedColor(status) {
      return status !== 'NÃO SALVO PELA IN' ? colors.yellow.darken3 : colors.red.darken3
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
