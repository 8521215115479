<template>
  <div class="mb-5">
    <v-row>
      <v-col
        cols="12"
        class="pa-0"
      >
        <v-data-table
          :headers="[
            { text: 'Raça', align: 'start', value: 'raca' },
            { text: 'Bezerra', value: 'bezerra' },
            { text: 'Novilha', value: 'novilha' },
            { text: '1ª cria', value: 'cria_1' },
            { text: '2ª cria', value: 'cria_2' },
            { text: '3ª cria', value: 'cria_3' },
            { text: '4ª cria', value: 'cria_4' },
            { text: '5ª cria', value: 'cria_5' },
            { text: '+ 5ª cria', value: 'mais_5' },
            { text: 'Touro', value: 'touro' },
            { text: 'Total', value: 'total' },
            { value: 'actions', sortable: false, align: 'end', width: 20 },
          ]"
          :items="breedHerdItems"
          :items-per-page="-1"
          class="elevation-1 herd-table"
          dense
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr class="tr-herd">
              <td class="td-input">
                <v-autocomplete
                  v-model="item.raca"
                  :items="breedList"
                  hide-details
                  class="pa-0"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.bezerra"
                  hide-details
                  placeholder="0"
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'bezerra')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.novilha"
                  hide-details
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'novilha')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.cria_1"
                  hide-details
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'cria_1')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.cria_2"
                  hide-details
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'cria_2')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.cria_3"
                  hide-details
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'cria_3')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.cria_4"
                  hide-details
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'cria_4')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.cria_5"
                  hide-details
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'cria_5')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.mais_5"
                  hide-details
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'mais_5')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.touro"
                  hide-details
                  dense
                  type="number"
                  @change="onchangeValueRace(item.id, 'touro')"
                />
              </td>
              <td class="td-input">
                <v-text-field
                  v-model="item.total"
                  hide-details
                  dense
                  readonly
                />
              </td>
              <td
                
                class="td-input"
              >
                <v-btn
                  v-if="editable"
                  icon
                  color="red lighten-2"
                  @click="openAlert(item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  label
                  small
                  class="mt-1"
                  color="blue lighten-4"
                >
                  Valor Médio
                </v-chip>
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_bezerra"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'bezerra')"
                />
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_novilha"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'novilha')"
                />
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_cria_1"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'cria_1')"
                />
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_cria_2"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'cria_2')"
                />
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_cria_3"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'cria_3')"
                />
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_cria_4"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'cria_4')"
                />
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_cria_5"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'cria_5')"
                />
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_mais_5"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'mais_5')"
                />
              </td>
              <td>
                <masked-input
                  v-model="item.valor_medio_touro"
                  :hide-details="true"
                  placeholder="Valor Médio"
                  dense
                  :mask="FloatNumberMask"
                  currency
                  return-unmasked
                  @change="onchangeValueRace(item.id, 'touro')"
                />
              </td>
              <td colspan="2" />
            </tr>
            <tr class="subtotal">
              <td>
                <v-chip
                  label
                  small
                  class="mt-1"
                  color="indigo lighten-4"
                >
                  SubTotal
                </v-chip>
              </td>
              <td>{{ item.subtotal_bezerra | formatCurrency }}</td>
              <td>{{ item.subtotal_novilha | formatCurrency }}</td>
              <td>{{ item.subtotal_cria_1 | formatCurrency }}</td>
              <td>{{ item.subtotal_cria_2 | formatCurrency }}</td>
              <td>{{ item.subtotal_cria_3 | formatCurrency }}</td>
              <td>{{ item.subtotal_cria_4 | formatCurrency }}</td>
              <td>{{ item.subtotal_cria_5 | formatCurrency }}</td>
              <td>{{ item.subtotal_mais_5 | formatCurrency }}</td>
              <td>{{ item.subtotal_touro | formatCurrency }}</td>
              <td colspan="2" />
            </tr>
          </template>

          <template slot="body.append">
            <tr class="rodape">
              <th>
                Qtd. Total
              </th>
              <th class="subtitle-2">
                {{ herd.previa_bezerra }}
              </th>
              <th class="subtitle-2">
                {{ herd.previa_novilha }}
              </th>
              <th class="subtitle-2">
                {{ herd.previa_1 }}
              </th>
              <th class="subtitle-2">
                {{ herd.previa_2 }}
              </th>
              <th class="subtitle-2">
                {{ herd.previa_3 }}
              </th>
              <th class="subtitle-2">
                {{ herd.previa_4 }}
              </th>
              <th class="subtitle-2">
                {{ herd.previa_5 }}
              </th>
              <th class="subtitle-2">
                {{ herd.previa_mais_5 }}
              </th>
              <th class="subtitle-2">
                {{ herd.previa_touro }}
              </th>
              <th
                class="subtitle-2"
                colspan="2"
              >
                {{ herd.previa_total }}
              </th>
            </tr>
            <tr class="rodape">
              <th>
                Valor Total
              </th>
              <th class="subtitle-2">
                {{ valor_total_bezerra | formatCurrency() }}
              </th>
              <th class="subtitle-2">
                {{ valor_total_novilha | formatCurrency() }}
              </th>
              <th class="subtitle-2">
                {{ valor_total_cria_1 | formatCurrency() }}
              </th>
              <th class="subtitle-2">
                {{ valor_total_cria_2 | formatCurrency() }}
              </th>
              <th class="subtitle-2">
                {{ valor_total_cria_3 | formatCurrency() }}
              </th>
              <th class="subtitle-2">
                {{ valor_total_cria_4 | formatCurrency() }}
              </th>
              <th class="subtitle-2">
                {{ valor_total_cria_5 | formatCurrency() }}
              </th>
              <th class="subtitle-2">
                {{ valor_total_mais_5 | formatCurrency() }}
              </th>
              <th class="subtitle-2">
                {{ valor_total_touro | formatCurrency() }}
              </th>
              <th
                class="subtitle-2"
                colspan="2"
              >
                {{ totalValueAnimals | formatCurrency() }}
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row v-if="editable">
      <v-col md="12">
        <v-btn
          icon
          class="primary"
          color="white mr-5"
          @click="addBreed()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Alert excluir linha -->
    <v-dialog
      :value="showDeleteDialog"
      max-width="400px"
      @input="showDeleteDialog = false; breedDelete = null"
      @keydown.esc="showDeleteDialog = false; breedDelete = null"
    >
      <v-card class="white">
        <v-card-title>
          <v-flex xs12>
            <h3 class="text-left text-h5 mb-3 red--text">
              Atenção
            </h3>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <h3 class="text-left black--text">
            Deseja realmente Executar a Ação ?
          </h3>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteDialog = false;"
          >
            Não
          </v-btn>
          <v-btn
            color="blue"
            text
            @click="BreedDelete"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.herd-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(226, 225, 225, 0.5);
    }

    tbody {
      .subtotal {
        background: rgba(226, 225, 225, 0.5);
      }

      .rodape {
        background: rgba(226, 225, 225, 0.5);
      }

      .tr-herd {
        background: rgba(226, 225, 225, 0.8);
      }
      
      .tr-herd:hover {
        background: rgba(226, 225, 225, 0.8) !important;
      }
      tr.rodape:hover {
        background: rgba(226, 225, 225, 0.6) !important;
      }

      .td-input {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
        
      }
    }
  }
}
</style>

<script>
import * as _ from "lodash";

import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  filters: {
    formatCurrency: value => new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(value),
  },
  components: {
    MaskedInput,
  },
  props: {
    herd: {
      require: true,
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },
      valor_total_bezerra: 0,
      valor_total_novilha: 0,
      valor_total_cria_1: 0,
      valor_total_cria_2: 0,
      valor_total_cria_3: 0,
      valor_total_cria_4: 0,
      valor_total_cria_5: 0,
      valor_total_mais_5: 0,
      valor_total_touro: 0,
      valor_total_animais: 0,

      objetcToDelete: "",

      showDeleteDialog: false,

      breedHerdItems: [],

      breedList: [],
    };
  },
  computed: {
    totalValueAnimals() {
      let bezerra = this.valor_total_bezerra ? parseFloat(this.valor_total_bezerra) : 0;
      let novilha = this.valor_total_novilha ? parseFloat(this.valor_total_novilha) : 0;
      let cria_1 = this.valor_total_cria_1 ? parseFloat(this.valor_total_cria_1) : 0;
      let cria_2 = this.valor_total_cria_2 ? parseFloat(this.valor_total_cria_2) : 0;
      let cria_3 = this.valor_total_cria_3 ? parseFloat(this.valor_total_cria_3) : 0;
      let cria_4 = this.valor_total_cria_4 ? parseFloat(this.valor_total_cria_4) : 0;
      let cria_5 = this.valor_total_cria_5 ? parseFloat(this.valor_total_cria_5) : 0;
      let mais_5 = this.valor_total_mais_5 ? parseFloat(this.valor_total_mais_5) : 0;
      let touro = this.valor_total_touro ? parseFloat(this.valor_total_touro) : 0;

      return bezerra + novilha + cria_1 + cria_2 + cria_3 + cria_4 + cria_5 + mais_5 + touro;
    },
  },
  watch: {
    herd: {
      handler: function () {
        this.breedHerdItems = _.get(this.herd, 'rebanhoRacaQuantidade', []);
        this.initTotalizers();
      },
      deep: true
    },
    breedHerdItems: {
      handler: function (newValue) {
        _.set(this.herd, 'rebanhoRacaQuantidade', newValue);
        this.$emit("update:Herd", this.herd);
      },
      deep: true
    },
  
  },
  mounted() {
    this.loadbreeds();
  },
  methods: {
    /**
     * @void
     * método de calculo de totalizadores
     */
    onchangeValueRace(id, field) {
      this.sumRaceField(field);
      this.sumSubTotal(id, field);
      this.sumTotalRace(id);
      this.sumTotalRaceValue(field);
    },

    /**
     * @void
     * soma da quantidade de rebanho campo (bezerra / novilha)
     * @param {string} field
     **/
    sumRaceField(field) {
      let total = _.sumBy(this.breedHerdItems, t => _.parseInt(_.get(t, field)));

      if (["cria_1", "cria_2", "cria_3", "cria_4", "cria_5"].includes(field)) {
        _.set(this.herd, "previa_".concat(field.substr(-1, 1)), parseInt(total));
        return true;
      }

      _.set(this.herd, "previa_".concat(field), parseInt(total));
    },
      
    /**
     * soma da quantidade de rebanho campo (bezerra / novilha)
     */
    sumSubTotal(id, field) {
      let quantidade = parseInt(_.get(this.breedHerdItems[id], field));
      let valorMedio = _.get(this.breedHerdItems[id], "valor_medio_".concat(field));

      _.set(this.breedHerdItems[id], "subtotal_".concat(field), quantidade * valorMedio);
    },

    /**
     * soma quantidade total de animais por raca
     */
    sumTotalRace(id) {
      let bezerra = parseInt(_.get(this.breedHerdItems[id], "bezerra", 0));
      let novilha = parseInt(_.get(this.breedHerdItems[id], "novilha", 0));
      let cria_1 = parseInt(_.get(this.breedHerdItems[id], "cria_1", 0));
      let cria_2 = parseInt(_.get(this.breedHerdItems[id], "cria_2", 0));
      let cria_3 = parseInt(_.get(this.breedHerdItems[id], "cria_3", 0));
      let cria_4 = parseInt(_.get(this.breedHerdItems[id], "cria_4", 0));
      let cria_5 = parseInt(_.get(this.breedHerdItems[id], "cria_5", 0));
      let mais_5 = parseInt(_.get(this.breedHerdItems[id], "mais_5", 0));
      let touro = parseInt(_.get(this.breedHerdItems[id], "touro", 0));

      this.breedHerdItems[id].total =
      bezerra + novilha + cria_1 + cria_2 + cria_3 + cria_4 + cria_5 + mais_5 + touro;

      //somatorio do total de todas as racas
      this.herd.previa_total = _.sumBy(this.breedHerdItems, "total");
    },

    /**
     * valor total por campo (bezerra / novilha) de todas as racas
     */
    sumTotalRaceValue(field) {
      let valor_total = _.sumBy(this.breedHerdItems, "subtotal_".concat(field));
      _.set(this, "valor_total_".concat(field), valor_total);
    },
    
    /**
     * Abre dialog de confirmações de deleção
     */
    openAlert(item) {
      this.breedDelete = item;
      this.showDeleteDialog = true;
    },

    /**
     * @void
     */
    BreedDelete() {
      this.breedHerdItems = _.remove(this.breedHerdItems, data => {
        return data.id !== this.breedDelete.id;
      });

      this.showDeleteDialog = false;
    },

    /**
     * Adiciona uma nova raça
     */
    addBreed() {
      this.breedHerdItems.push({
        id: this.breedHerdItems.length,
        raca: "",
        bezerra: 0,
        novilha: 0,
        cria_1: 0,
        cria_2: 0,
        cria_3: 0,
        cria_4: 0,
        cria_5: 0,
        mais_5: 0,
        touro: 0,
        total: 0,
        valor_medio_bezerra: 0,
        valor_medio_novilha: 0,
        valor_medio_cria_1: 0,
        valor_medio_cria_2: 0,
        valor_medio_cria_3: 0,
        valor_medio_cria_4: 0,
        valor_medio_cria_5: 0,
        valor_medio_mais_5: 0,
        valor_medio_touro: 0,
        subtotal_bezerra: 0,
        subtotal_novilha: 0,
        subtotal_cria_1: 0,
        subtotal_cria_2: 0,
        subtotal_cria_3: 0,
        subtotal_cria_4: 0,
        subtotal_cria_5: 0,
        subtotal_mais_5: 0,
        subtotal_touro: 0,
      });
    },

    /**
     * @void
     * Buscar as raças já cadastras no sistema
     */
    async loadbreeds() {
      try {
        const { data } = await this.$queries.post(`/raca/listaRacaJson`);

        if (!_.isObject(data)) {
          throw "PHP error: consulta raças"
        }

        this.breedList = data.map(breed => breed.raca);
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * @void
     * Recalcula os totalizadores
     */
    initTotalizers() {
      // Calculca o valor total de cada item de todas as raças
      ["bezerra", "novilha", "cria_1", "cria_2", "cria_3", "cria_4", "cria_5", "mais_5", "touro"]
        .forEach(race => this.sumTotalRaceValue(race));
    },
  },
};
</script>
