<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <v-card
        class="mx-auto"
        dark
        :style="{background: color, height: `${140 + height}px !important`}"
        :loading="loading"
      >
        <v-card-title>
          <v-icon
            large
            left
          >
            show_chart
          </v-icon>
          <span class="title font-weight-light">Visitas {{ title }} por técnico</span>
        </v-card-title>

        <v-tabs
          v-model="technician.tab"
          align-with-title
          background-color="transparent"
          class="transparent"
          dark
        >
          <v-tabs-slider color="cyan" />

          <v-tab>Gráfico</v-tab>
          <v-tab>Relatório</v-tab>
          
          <v-tab-item>
            <v-chart
              :options="technicianGraph.options"
              :style="{width: '100%', height: `${height}px !important`}"
              autoresize
            />
          </v-tab-item>
          
          <v-tab-item>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="title == 'Atendimento Remoto' ? technician.headersRemote : technician.headers"
                  :items="data.technician.report"
                  dark
                  dense
                  class="elevation-1 report-table"
                  hide-default-footer
                  disable-pagination
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-card
        class="mx-auto"
        dark
        :style="{background: color, height: `${140 + height}px !important`}"
        :loading="loading"
      >
        <v-card-title>
          <v-icon
            large
            left
          >
            show_chart
          </v-icon>
          <span class="title font-weight-light">Visitas {{ title }} por mês</span>
        </v-card-title>

        <v-tabs
          v-model="monthly.tab"
          align-with-title
          background-color="transparent"
          class="transparent"
          dark
        >
          <v-tabs-slider color="cyan" />

          <v-tab>Gráfico</v-tab>
          <v-tab>Relatório</v-tab>
          
          <v-tab-item>
            <v-chart
              :options="monthlyGraph.options"
              :style="{width: '100%', height: `${height}px !important`}"
              autoresize
            />
          </v-tab-item>
          
          <v-tab-item>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :headers="monthly.headers"
                  :items="data.monthly.report"
                  dark
                  dense
                  class="elevation-1 report-table"
                  hide-default-footer
                  disable-pagination
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    title: {
      type: String,
      default: '-'
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: Boolean,
  },

  data() {
    return {
      technician: {
        tab: null,
        headers: [
          { text: 'Técnico', value: 'Técnico' },
          { text: 'Nº de Visitas', value: 'Nº de Visitas' },
          { text: 'Meta', value: 'Meta' },
        ],
        headersRemote: [
          { text: 'Técnico', value: 'Técnico' },
          { text: 'Nº de Visitas', value: 'Nº de Visitas' },
        ],
      },
      monthly: {
        tab: null,
        headers: [
          { text: 'Mês', value: 'Mês' },
          { text: 'Ano', value: 'Ano' },
          { text: 'Nº de Visitas', value: 'Nº de Visitas' },
        ],
      }
    }
  },

  computed: {
    height() {
      return Math.max(this.technicianGraph.height, this.monthlyGraph.height)
    },
    technicianGraph() {
      return {
        height: 70 + (this.data.technician.visits.length * 30),
        options: {
          color: ['rgba(38, 198, 218, 0.7)', 'rgba(38, 198, 218, 0.2)'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: { backgroundColor: '#6a7985' }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            top: '0%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff'
            }
          },
          yAxis: {
            type: 'category',
            boundaryGap: true,
            data: this.data.technician.category,
            axisLabel: {
              color: '#fff'
            }
          },
          series: [
            {
              name: 'Visitas',
              type: 'bar',
              showSymbol: false,
              barGap: '-100%',
              emphasis: {
                focus: 'series'
              },
              data: this.data.technician.visits,
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'right',
                formatter: `  {c}  `
              }
            },
            {
              name: 'Meta',
              type: 'bar',
              barGap: '-100%',
              data: this.data.technician.goals,
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#48535c',
                color: '#fff',
                borderRadius: 5,
                position: 'right',
                formatter: ({ value }) => value ? `  ${value}  ` : ''
              }
            },
          ]
        }
      }
    },
    monthlyGraph() {
      return {
        height: 70 + (this.data.monthly.series.length * 30),
        options: {
          color: ['rgba(38, 198, 218, 0.7)', 'rgba(171, 71, 188, 0.7)', 'rgba(255, 255, 0, 0.7)'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: { backgroundColor: '#6a7985' }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            top: '0%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(255, 255, 255, 0.1)']
              }
            },
            axisLabel: {
              color: '#fff'
            }
          },
          yAxis: {
            type: 'category',
            boundaryGap: true,
            data: this.data.monthly.category,
            axisLabel: {
              color: '#fff'
            }
          },
          series: [
            {
              name: 'Visitas',
              type: 'bar',
              showSymbol: false,
              emphasis: {
                focus: 'series'
              },
              data: this.data.monthly.series,
              label: {
                show: true,
                lineHeight: 20,
                height: 20,
                backgroundColor: '#6a7985',
                color: '#fff',
                borderRadius: 5,
                position: 'right',
                formatter: `  {c}  `
              }
            }
          ]
        }
      }
    }
  },
}
</script>