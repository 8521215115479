<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Produtores - Aprovados x Desvinculados</span>
    </v-card-title>

    <v-card-text
      v-if="!hideLabels"
      class="pb-0"
    >
      <div class="d-flex white--text justify-space-between">
        <div class="d-flex flex-column text-left white--text">
          <span class="subtitle-1 font-weight-light pb-1">Aprovados no Período</span>
          <span class="display-1">{{ totalAproveds }}</span>
        </div>
        <div class="d-flex flex-column text-right white--text">
          <span class="subtitle-1 font-weight-light pt-3 pb-1">Desvinculados no Período</span>
          <span class="display-1">{{ totalUnlinkeds }}</span>
        </div>
      </div>
    </v-card-text>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import isObject from "lodash/fp/isObject"
import upperFirst from "lodash/fp/upperFirst"

export default {
  name: "linking-producers-chart",

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '100px',
    },
    smooth: [Number, Boolean],
    hideLabels: Boolean,
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    comparisonPeriod: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      loading: false,
      currentData: [],

      category: [],

      aproveds: [],
      unlinkeds: [],

      comparisonAproveds: [],
      comparisonUnlinkeds: [],
    }
  },

  computed: {
    hasComparison() {
      return this.comparisonAproveds.length > 0 || this.comparisonUnlinkeds.length > 0
    },
    totalAproveds() {
      return this.aproveds.reduce((acc, cur) => acc + cur, 0)
    },
    totalUnlinkeds() {
      return this.unlinkeds.reduce((acc, cur) => acc + cur, 0)
    },
    options() {
      const getLabel = (length, index) => ({
        show: true,
        lineHeight: 20,
        height: 20,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: index === 0 ? 'right' : index === length - 1 ? 'left' : 'inside',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      });

      const series = [
        {
          name: `Aprovados ${this.titleExtended}`,
          type: 'line',
          areaStyle: {},
          lineStyle: { width: 2 },
          showSymbol: true,
          smooth: this.smooth,
          emphasis: {
            focus: 'series'
          },
          data: this.aproveds.map((value, index) => ({
            value: parseFloat(value),
            label: getLabel(this.aproveds.length, index),
          }))
        },
        {
          name: `Desvinculados ${this.titleExtended}`,
          type: 'line',
          areaStyle: {},
          lineStyle: { width: 2 },
          showSymbol: true,
          smooth: this.smooth,
          emphasis: {
            focus: 'series'
          },
          data: this.unlinkeds.map((value, index) => ({
            value: parseFloat(value),
            label: getLabel(this.unlinkeds.length, index),
          }))
        }
      ];

      if (this.hasComparison) {
        series.push(
          {
            name: `Aprovados ${this.titleComparisonExtended}`,
            type: 'line',
            areaStyle: {},
            lineStyle: { width: 2 },
            showSymbol: true,
            smooth: this.smooth,
            emphasis: {
              focus: 'series'
            },
            data: this.comparisonAproveds.map((value, index) => ({
              value: parseFloat(value),
              label: getLabel(this.comparisonAproveds.length, index),
            }))
          },
          {
            name: `Desvinculados ${this.titleComparisonExtended}`,
            type: 'line',
            areaStyle: {},
            lineStyle: { width: 2 },
            showSymbol: true,
            smooth: this.smooth,
            emphasis: {
              focus: 'series'
            },
            data: this.comparisonUnlinkeds.map((value, index) => ({
              value: parseFloat(value),
              label: getLabel(this.comparisonUnlinkeds.length, index),
            }))
          }
        )
      }

      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(255, 255, 0, 0.7)', 'rgba(38, 135, 218, 0.7)', 'rgba(255, 130, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          }
        },
        legend: {
          show: this.hasComparison,
          textStyle: {
            color: '#ddd',
          }
        },
        grid: {
          ...(!this.hideLabels
            ? {
              left: '3%',
              right: '4%',
              top: '10%',
              bottom: '3%',
              containLabel: true,
            }
            : {
              left: '0%',
              right: '0%',
              top: '8px',
              bottom: '0%',
              containLabel: false
            })
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.category,
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: !this.hideLabels,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series
      }
    },

    comparisonType() {
      if (this.comparisonPeriod.length < 2) {
        return 'NONE';
      }

      const [startDate] = this.period;

      const [startDateComp] = this.comparisonPeriod;

      if (moment(startDate).isSame(startDateComp, 'month') && !moment(startDate).isSame(startDateComp, 'year')) {
        return 'YEAR';
      }

      return 'MONTH';
    },

    titleExtended() {
      const [date] = this.period;
      if (this.comparisonType === 'MONTH') {
        return `- ${upperFirst(moment(date).format('MMM/YYYY'))}`;
      }
      if (this.comparisonType === 'YEAR') {
        return `- ${upperFirst(moment(date).format('YYYY'))}`;
      }
      return '';
    },

    titleComparisonExtended() {
      const [date] = this.comparisonPeriod;
      if (this.comparisonType === 'MONTH') {
        return `- ${upperFirst(moment(date).format('MMM/YYYY'))}`;
      }
      if (this.comparisonType === 'YEAR') {
        return `- ${upperFirst(moment(date).format('YYYY'))}`;
      }
      return '';
    },
  },

  watch: {
    period() {
      this.loadLinkingProducers();
    },
    comparisonPeriod() {
      this.loadLinkingProducersComparison();
    }
  },

  created() {
    this.loadLinkingProducers()
  },

  methods: {
    async loadLinkingProducers() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          `/relatorios/vinculacaoProdutores`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isObject(data)) {
          throw "PHP Error";
        }

        this.currentData = data.sort((a, b) => moment(a.data).diff(b.data));

        this.init()
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de vinculação de produtores!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    init() {
      const graph = this.parseData(this.currentData);

      this.category = Object.keys(graph);
      this.aproveds = Object.values(graph).map(item => item[0]);
      this.unlinkeds = Object.values(graph).map(item => item[1]);
      this.comparisonAproveds = [];
      this.comparisonUnlinkeds = [];
    },

    parseData(data) {
      return data
        .sort((a, b) => moment(a.data).diff(b.data))
        .reduce((acc, cur) => {
          const date = moment(cur.data).format('DD/MM/YYYY');
          if (!(date in acc)) {
            acc[date] = [0, 0];
          }
          if (cur.status == 'APROVADO') {
            acc[date][0] = parseFloat(cur.total);
          }
          if (cur.status == 'DESVINCULADO') {
            acc[date][1] = parseFloat(cur.total);
          }
          return acc;
        }, { })
    },

    async loadLinkingProducersComparison() {
      try {
        if (this.comparisonPeriod.length < 2) {
          this.init();
          return;
        }

        this.loading = true;

        const [startDate, endDate] = this.comparisonPeriod;

        let { data } = await this.$axios.post(
          `/relatorios/vinculacaoProdutores`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isObject(data)) {
          throw "PHP Error";
        }

        const graph = this.parseDataComparison(data.sort((a, b) => moment(a.data).diff(b.data)));

        this.category = Object.keys(graph);
        this.aproveds = Object.values(graph).map(item => item[0]);
        this.unlinkeds = Object.values(graph).map(item => item[1]);
        this.comparisonAproveds = Object.values(graph).map(item => item[2]);
        this.comparisonUnlinkeds = Object.values(graph).map(item => item[3]);
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de vinculação de produtores!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    parseDataComparison(data) {
      const format = (this.comparisonType === 'MONTH') ? 'DD' : 'DD/MM';

      const current = this.currentData
        .reduce((acc, cur) => {
          const date = moment(cur.data).format(format);
          if (!(date in acc)) {
            acc[date] = [0, 0, 0, 0];
          }
          if (cur.status == 'APROVADO') {
            acc[date][0] = parseFloat(cur.total);
          }
          if (cur.status == 'DESVINCULADO') {
            acc[date][1] = parseFloat(cur.total);
          }
          return acc;
        }, { });

      return data
        .sort((a, b) => moment(a.data).diff(b.data))
        .reduce((acc, cur) => {
          const date = moment(cur.data).format(format);
          if (!(date in acc)) {
            acc[date] = [0, 0, 0, 0];
          }
          if (cur.status == 'APROVADO') {
            acc[date][2] = parseFloat(cur.total);
          }
          if (cur.status == 'DESVINCULADO') {
            acc[date][3] = parseFloat(cur.total);
          }
          return acc;
        }, current)
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
