<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Relatório de Tuberculose
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <sanity-report-index
      :sanity="sanity"
    >
    </sanity-report-index>

  </div>
</template>

<script>
import SanityReportIndex from "@/Domains/Projects/HealthExamination/SanityReportIndex.vue";
export default {
  components: {
    SanityReportIndex
  },

  data() {
    return {
      sanity: "TUBERCULOSE",
    };
  },

  async mounted() {
    await this.getSettings();
  },
};
</script>
