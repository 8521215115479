<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="filter.project"
            :items="projectList"
            label="Vigência do Projeto"
            item-value="dateStart"
            item-text="text"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="filter.project.protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <month-picker
            v-model="filterDate.date.input"
            not-clearable
            disable-last-thirty
            @change="onFilterDate"
          />
        </v-col>
      </v-row>
      <v-card
        class="graph-card"
        color="transparent"
        dark
      >
        <v-card-title>
          PAE: Qualidade
        </v-card-title>
        <v-card-text>
          <quality-summary
            v-for="q in quality"
            :key="q.indicador"
            :goal="q"
            :filter="filter"
            @onLoadQuality="onLoadQuality"
          />
        </v-card-text>
      </v-card>
      <v-card
        class="graph-card"
        color="transparent"
        dark
      >
        <v-card-title>
          PAE: Emergenciais
        </v-card-title>
        <v-card-text>
          <emergency-summary
            :filter="filter"
            @onLoadEmmergency="onLoadEmmergency"
          />
        </v-card-text>
      </v-card>

      <questionnaire-summary
        v-for="q in questionnairesGoal"
        :key="q.id_formulario"
        :questionnaire="q"
        :filter="filter"
        @onLoadQuestionnaire="onLoadQuestionnaire"
      />
      <v-row class="justify-center">
        <v-col
          v-for="curve in curveItem"
          :key="curve"
          cols="12"
          md="12"
        >
          <bpa-summary
            :curve="curve"
            :filter="filter"
            :ranges="ranges"
            @onLoadBpa="onLoadBpa"
          />
        </v-col>
      </v-row>
    </v-flex>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="showPrintDialog()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir Visitas
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-dialog
      v-model="printDialog.show"
      max-width="600"
      persistent
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title class="text-subtitle-2">
          Configuração de Impressão
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-0">
          <v-row
            dense
            no-gutters
            style="margin: 8px -4px;"
          >
            <v-col
              cols="12"
              class="pa-1"
            >
              <v-radio-group
                v-model="printDialog.params.type"
                row
              >
                <v-radio
                  label="Resumo das metas"
                  value="goal"
                />
                <v-radio
                  label="Visitas"
                  value="visits"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="printDialog.params.type=='visits'"
            dense
            no-gutters
            style="margin: 8px -4px;"
          >
            <v-col
              cols="12"
              class="pa-1"
            >
              <v-autocomplete
                v-model="printDialog.params.route"
                :loading="loading"
                :items="routes"
                item-text="description"
                item-value="id"
                prepend-inner-icon="icon-rota"
                label="Rota"
                return-object
                hide-details="true"
                filled="filled"
                clearable
                @change="changeRoute"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.description }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.types.length">
                      <v-chip
                        v-for="type in item.types"
                        :key="type"
                        x-small
                      >
                        {{ type }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="pages"
              cols="12"
              class="pa-1"
            >
              <v-autocomplete
                v-model="printDialog.params.lote"
                :items="pages"
                item-value="value"
                item-text="text"
                hide-selected
                hide-details
                label="Parte"
                prepend-inner-icon="pages"
                filled
                clearable
              />
            </v-col>
          </v-row>
          
          <v-row
            v-if="printDialog.params.type=='visits'"
            dense
            no-gutters
            style="margin: 8px -4px;"
          >
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Faixa (Curva ABC)
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showABCCurve"
                        :label="printDialog.params.showABCCurve ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-pages"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Resultados Qualidade
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showQualityResults"
                        :label="printDialog.params.showQualityResults ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Médias Qualidade
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showQualityAvg"
                        :label="printDialog.params.showQualityAvg ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>

          <v-row
            v-if="printDialog.params.type=='visits'"
            dense
            no-gutters
            style="margin: 8px -4px;"
          >
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Recomendações abertas
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showOpenedRecommendations"
                        :label="printDialog.params.showOpenedRecommendations ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Checklists
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showChecklists"
                        :label="printDialog.params.showChecklists ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col
              cols="4"
              class="pa-1"
            >
              <v-card
                outlined
                class="text-left card-field"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: .8rem;">
                      Coletas de amostras
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-switch
                        v-model="printDialog.params.showSampleCollects"
                        :label="printDialog.params.showSampleCollects ? 'Exibir' : 'Não exibir'"
                        dense
                        hide-details
                        class="mt-0"
                        color="blue accent-3"
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="v-btt2 black--text pa-0"
            text
            color="red darken-1"
            @click="closeDialogs"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="print(printDialog.item)"
          >
            Imprimir
          </v-btn>
        </v-card-actions>

        <v-overlay
          :value="loading"
          absolute
        >
          <v-card-text>
            Carregando...
            <v-progress-linear
              class="mb-0"
              color="white"
              indeterminate
            />
          </v-card-text>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import QualitySummary from "@/Domains/Projects/PQFL/Components/QualitySummary.vue";
import EmergencySummary from "@/Domains/Projects/PQFL/Components/EmergencySummary.vue";
import QuestionnaireSummary from "@/Domains/Projects/PQFL/Components/QuestionnaireSummary.vue"
import BpaSummary from "@/Domains/Projects/PQFL/Components/BpaSummary.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  components: {
    MonthPicker,
    QualitySummary,
    EmergencySummary,
    QuestionnaireSummary,
    BpaSummary,
  },

  mixins: [ReportMixin],

  props: ["parent"],

  data() {
    return {
      loading: false,
      // Dialogo utilizado para a impressão das visitas

      pages: [
        { text: 'Emergenciais', value: 'emergenciais' },
        { text: 'Checklist', value: 'checklist' },
        { text: 'BPA', value: 'bpa' },
      ],

      printDialog: {
        show: false,
        item: {},
        params: {
          type: "goal",
          route: [],
          showABCCurve: true,
          showQualityResults: true,
          showQualityAvg: true,
          showOpenedRecommendations: true,
          showChecklists: true,
          showSampleCollects: true,
          lote: [],
        },
      },
      
      filter: {
        project: {
          start: null,
          end: null,
          protocol: '',
        },
        date: {
          month: moment().format('YYYY-MM'),
        },
        range: [moment().format('YYYY-MM'), moment().format('YYYY-MM')],
      },
      filterDate: {
        date: {
          input: "thisMonth",
        },
      },

      projectList: [],

      //metas de PAE qualidade
      quality: [],
      qualityData: [],

      emergencyData: [],
      
      //metas de questionario
      questionnairesGoal: [],
      questionnaireData: [],

      //metas de BPA
      curveItem: [],
      ranges: [],
      bpaData: [],

      // Rotas (Autocomplete)
      routes: [],
    };
  },

  created() {
    this.loadProject();
  },

  methods: {
    onFilterDate([month]) {
      if (moment(month).format('YYYY-MM') != this.filter.date.month) {
        this.filter.date.month = month;
        this.filter.range = [moment(month).format('YYYY-MM'), moment(month).format('YYYY-MM')];

        this.qualityData = [];
        this.bpaData = [];
      }
    },

    async loadProject() {
      try {
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "PQFL"
          }
        );

        this.projectList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            quality: proj.qualidade ? JSON.parse(proj.qualidade) : [],
            protocol: proj.protocolo,
            emergency: proj.emergencial,
            questionnaire: proj.questionario,
            id: proj.id_assistencia,
          }
        });
        
        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          
          this.quality = (this.quality.lenght > 0) ? this.quality : this.filter.project.quality ;
          this.questionnairesGoal = (this.questionnairesGoal.lenght > 0) ? this.questionnairesGoal : JSON.parse(this.filter.project.questionnaire) ;

          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      }
    },

    //faixas das metas de BPA
    async loadRange() {
      this.curveItem = [];
      this.ranges = [];
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/rangesBpa`,
          {
            id_assistencia: this.filter.project.id,
            visitas: true, //somente metas com visitas definidadas
          }
        );
        this.curveItem = [...new Set(data.filter(o => o.visitas).flatMap(o => o.tipo))];
        this.ranges = data.map(d => {
          return {
            indicador: d.indicador,
            tipo: d.tipo,
            metas_agrupadas: d.metas_agrupadas,
            questionarios_aplicar: d.questionarios_aplicar
          }
        });
        
        if (!_.isObject(data)) {
          throw data;
        }
        
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar faixas!", "Atenção");
        console.warn(err);

      } finally {
        this.loading = false;
      }
    },

    async loadRoutes(visits) {
      try {
        this.loading = true;

        if (_.isEmpty(visits)) {
          return
        }

        const { data } = await this.$axios.post(
          `/rota/listaRotaVisitaJson`,
          {
            visits: visits,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.routes = data.map(route => {

          const types = !_.isEmpty(route.tipos) ? _.uniq(route.tipos.map(type => _.startCase(type.descricao))) : [];

          return {
            id: route.id_rota,
            description: route.descricao,
            types,
          }
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onLoadQuality(data) {
      this.qualityData = _.concat(this.qualityData, data);
    },
    onLoadEmmergency(data) {
      this.emergencyData = data;
    },
    onLoadQuestionnaire(data) {
      this.questionnaireData = data;
    },
    onLoadBpa(data) {
      this.bpaData.push(data);
    },

    getReportJson(items) {
      return items.map(o => {
        if (this.type === 'priority' || this.type === 'priority-ccs') {
          return {
            Mês: this.formatMonth(o.month),
            Produtores: o.producers,
            Visitados: o.visits,
            '% Visitados': `${this.formatNumber(o.visitsPerc)}%`,
            Meta: o.goalsPerc,
          }
        }
        if (this.type === 'critical') {
          return {
            Mês: this.formatMonth(o.month),
            Produtores: o.producers,
            IN59: o.producers_in59,
            IN76: o.producers_in76,
            Não_Salvos: o.producers_not_save,
            Visitados: o.visits,
            '% Visitados': `${this.formatNumber(o.visitsPerc)}%`,
            Meta: o.goalsPerc,
          }
        }
      });
    },

    exportExcel() {
      if (this.type === 'priority' || this.type === 'priority-ccs') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.items));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Visitas Prioritários';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
      else if (this.type === 'critical') {
        const data = this.XLSX.utils.json_to_sheet(this.getReportJson(this.items));
        const workbook = this.XLSX.utils.book_new();
        const filename = 'Visitas Críticos';
        this.XLSX.utils.book_append_sheet(workbook, data, filename);
        this.XLSX.writeFile(workbook, `${filename}.xlsx`);
      }
    },

    showPrintDialog() {

      this.printDialog.show = true;
      this.printDialog.params = {
        type: 'goal',
        route: [],
        showABCCurve: true,
        showQualityResults: true,
        showQualityAvg: true,
        showOpenedRecommendations: true,
        showChecklists: true,
        showSampleCollects: true
      };

      let visits = [];
      
      //pega os ids das visitas das metas para impressao
      this.emergencyData.forEach(e => {
        visits = _.concat(visits, e.itemsVisits);
      });
      
      if (!_.isEmpty(this.questionnaireData) ) {
        this.questionnaireData.ids_visita.forEach(v => {
          visits = _.concat(visits, v);
        });
      }
      
      this.bpaData.forEach(bpa => {
        bpa.items.forEach(i => {
          i.details.forEach(d => {
            d.visitas.forEach(v => {
              visits = _.concat(visits, v.id_visita);
            })
          })
        })
      });
      
      console.log('visits',visits);

      this.loadRoutes(visits);
      
      this.printDialog.item = visits;
      
    },

    async changeRoute() {

      this.loading = true;

      if (_.isEmpty(this.printDialog.params.route) &&  this.printDialog.params.type == 'visits' ) {
        this.$snotify.error("Escolha a rota para imprimir!", "Atenção");
        return;
      }
      if (_.isEmpty(this.printDialog.item)) {
        return;
      }

      const { data } = await this.$axios.post(
        `/rota/listaVisitaRota`,
        {
          visits: this.printDialog.item,
          id_rota: this.printDialog.params.route.id,
        }
      );

      let visitsRoute = data.map(o => (o.id_visita));
      console.log('visitsRoute',visitsRoute );
    
      if (!_.isEmpty(visitsRoute)) {
        let countPages = 8;
        this.pages  = visitsRoute.reduce((acumulador, item, indice) => {
          const grupo = Math.floor(indice / countPages);
          acumulador[grupo] = [...(acumulador[grupo] || []), item];
          return acumulador;
        }, []);

        this.pages  = this.pages.map((bloco, key) => (
          { text: `parte ${key + 1}`, value: bloco }
        ));

        this.printDialog.params.lote = this.pages[0].value;
      
      }
      console.log('pages',this.pages );
      this.loading = false;
      
    },

    /**
     * Fecha todos os dialogos
     */
    closeDialogs() {
     
      this.printDialog.show = false;
      this.printDialog.item = {};
      this.printDialog.params = {
        type: 'goal',
        route: [],
        showABCCurve: true,
        showQualityResults: true,
        showQualityAvg: true,
        showOpenedRecommendations: true,
        showChecklists: true,
        showSampleCollects: true,
        showPriceNegotiation: true,
      };
    },

    /**
     * @event void
     *
     * Evento acionado para imprimir a lista de visitas
     */
    async print(ids_visita) {
      try {
        this.loading = true;

        if (_.isEmpty(this.printDialog.params.route) &&  this.printDialog.params.type == 'visits' ) {
          this.$snotify.error("Escolha a rota para imprimir!", "Atenção");
          return;
        }

        if (!_.isEmpty(this.printDialog.params.lote)) { //lote informado, entao so imprimir
          console.log('this.printDialog.params.lote',this.printDialog.params.lote, ids_visita);
          ids_visita = this.printDialog.params.lote;
        }
        
        const { data } = await this.$axios.post(`/recomendacoesQualidade/imprime`, {
          mes: moment(this.filter.date.month).format("MM/YYYY"),
          qualidade: this.qualityData,
          emergencial: this.emergencyData,
          questionario: this.questionnaireData,
          bpa: this.bpaData,
          ids_visita: ids_visita,
          params: {
            type: this.printDialog.params.type,
            route: this.printDialog.params.route,
            mostra_curva_abc: this.printDialog.params.showABCCurve,
            mostra_resultados_qualidade: this.printDialog.params.showQualityResults,
            mostra_medias_qualidade: this.printDialog.params.showQualityAvg,
            mostra_recomendacoes_abertas: this.printDialog.params.showOpenedRecommendations,
            mostra_checklist: this.printDialog.params.showChecklists,
            mostra_coleta_amostras: this.printDialog.params.showSampleCollects,
          }
        });

        let fileName = '';
        fileName = `relatorio_geral_pqfl - ${this.printDialog.params.indicador} - ${this.printDialog.params.route.description} - ${moment(this.filter.date.month).format("MM/YYYY")}.pdf`;
        
        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();

        // this.removeRouteItem(this.printDialog.params.route.id);
        // if (!_.isEmpty(this.routes)) {
        //   this.printDialog.params.route.id = this.routes[0].id;//seta proxima rota
        // }

      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    removeRouteItem(id) {
      const idx = this.routes.findIndex(r => r.id === id);
      this.routes.splice(idx, 1);
    },
   
  },
};
</script>