<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Relatório de Vacinas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="sanity === 'VACINAS'"
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <v-autocomplete
          v-model="filter.vaccine"
          :items="vaccinesFilter"
          label="Vacinas"
          prepend-inner-icon="icon-sanidade"
          dark
          filled
          clearable
          hide-details
        />
      </v-col>
      <v-col
        v-if="sanity === 'BRUCELOSE' || sanity === 'TUBERCULOSE'"
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <v-autocomplete
          v-model="filter.exam"
          :items="exams"
          label="Exames"
          prepend-inner-icon="biotech"
          dark
          filled
          clearable
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <v-select
          v-model="filter.expiration.type"
          :items="expirationFilter"
          label="Vencimento"
          prepend-inner-icon="event"
          dark
          filled
          clearable
          hide-details
          @change="onExpirationFilter"
        >
          <template #item="{ item }">
            <template v-if="item.value === filter.expiration.type">
              <span class="grow"> {{ item.text }} </span>
              <v-btn
                icon
                light
                @click="onExpirationFilter"
              >
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
            <template v-else>
              {{ item.text }}
            </template>
          </template>

          <template #selection="{ item }">
            <span v-if="filter.expiration.date">
              {{ item.text }} {{ formatDate(filter.expiration.date, 'DD/MM/YYYY') }}
            </span>
            <span v-else>
              {{ item.text }}
            </span>
          </template>
        </v-select>
        <v-dialog
          v-model="filter.expiration.show"
          persistent
          width="290"
        >
          <v-date-picker
            v-model="filter.expiration.date"
            reactive
            @change="filter.expiration.show = false"
          />
        </v-dialog>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <person-autocomplete-filter
          v-model="filter.technician"
          type="TECHNICAL"
          label="Técnico"
          dark
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <routes-autocomplete-filter
          v-model="filter.routes"
          label="Rota"
          dark
          multiple
          hide-details
          @change="onFilter"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-text-field
          v-model="filter.search"
          prepend-inner-icon="search"
          label="Buscar"
          single-line
          hide-details
          dark
          filled
        />
      </v-col>

    </v-row>

    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        {{ `Produtores: ${ dataLength }` }}
      </v-card-title>
      <data-table
        ref="report"
        :headers="headers"
        :items="filteredData()"
        :search="filter.search"
        dark
        show-expand
        item-key="id_pessoa"
        class="elevation-1"
      >

        <template #expanded-item="{ headers, item }"  >
          <td
            :colspan="headers.length"
            class="pa-0 text-center"
          >
            <v-row
              v-if="item.vacinas.length <= 0"
            >
              <v-col
                class="text-center"
              >
                Nenhuma vacinação encontrada...
              </v-col>
            </v-row>

            <v-data-table
              v-if="item.vacinas.length > 0"
              :headers="headerVaccines"
              :items="item.vacinas"
              dark
              class="elevation-1 ma-3"
              @click:row="edit"
            >
              <template #[`item.data`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.data_vencimento`]="{ value }">
                {{ formatDate(value, 'DD/MM/YYYY') }}
              </template>
              <template #[`item.anexos`]="{ item }">
                <v-menu>
                  <template #activator="{ on }">
                    <v-btn
                      color="green darken-2"
                      x-small
                      v-on="on"
                      :disabled="!item.anexos || (typeof item.anexos === 'object'  && Object.keys(item.anexos).length === 0)"
                    >
                      <v-icon
                        x-small
                        color="yellow"
                      >
                        download
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(anexo, idx) in item.anexos"
                      :key="idx"
                      @click="showAnexos(anexo.url)"
                    >
                      <v-list-item-icon>
                        <v-icon>
                          file_open
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ anexo.name ?? 'Download' }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-menu>
                  <template #activator="{ on }">
                    <v-btn
                      dark
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="edit(item)">
                      <v-list-item-icon>
                        <v-icon>edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </td>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="printExameReport(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    print
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
      <v-card-title>
        <v-spacer />
      </v-card-title>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <add-vaccine-dialog
      v-model="showDialog"
      :edit-id="editId"
      :vaccinesTypes="vaccinesTypes"
      @save="loadAllVaccines"
    />
  </div>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import moment from "moment-timezone";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import AddVaccineDialog from "@/Domains/Projects/HealthExamination/Components/AddVaccineDialog.vue";

export default {
  name: "report-vacinas",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    AddVaccineDialog,
  },

  mixins: [ReportMixin],

  data() {
    return {
      filter: {
        technician: {
          id: "",
          description: ""
        },
        expiration: {
          show: false,
          type: null,
          date: null
        },
        routes: [],
        cooperatives: [],
        search: '',
        exam: null,
        vaccine: null,
        typeExam: null,
        typeComingDue: null,

      },

      showDialog: false,
      vaccinesTypes: [],
      editId: null,

      sanity: 'VACINAS',

      data: [],
      dataLength: 0,

      headers: [],

      exams: [
        { value: 'WITH_EXAM', text: 'Com Exame' },
        { value: 'WITHOUT_EXAM', text: 'Sem Exame' },
      ],

      typeExamFilter: [
        { value: 'BRUCELOSE', text: 'Brucelose' },
        { value: 'TUBERCULOSE', text: 'Tuberculose' },
      ],

      typeComingDueFilter: [
        { value: 15, text: '15 dias' },
        { value: 30, text: '30 dias' },
        { value: 60, text: '60 dias' },
        { value: 90, text: '90 dias' },
        { value: 120, text: '120 dias' },
      ],

      expirationFilter: [
        { value: 'EXPIRED', text: 'Vencido' },
        { value: 'UNEXPIRED', text: 'Não Vencido' },
        { value: 'EXPIRES_UNTIL', text: 'Vencimento até:' },
      ],

      vaccinesFilter: [
        { value: 'WITH_VACCINE', text: 'Com Vacina' },
        { value: 'WITHOUT_VACCINE', text: 'Sem Vacina' },
      ],

      cooperatives: [],

      settings: {},
    };
  },

  computed: {
    headerVaccines() {
      return [
        { text: 'Vacina', value: 'tipo' },
        { text: 'Vac. (Data)', value: 'data' },
        { text: 'Vac. (Vencimento)', value: 'data_vencimento' },
        { text: 'Vac. (Qtd. Animais)', value: 'quantidade_animais' },
        { text: 'Vac. (Veterinário)', value: 'veterinarian.nome' },
        { text: 'Anexos', altText: 'Fotos', value: 'anexos',  align: 'center' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
      ];
    },
  },


  async mounted() {
    await this.getSettings();
    await this.getHeaders();
    await this.getVaccinesType();
    await this.loadCooperatives();
    await this.loadExam();
    this.filter.typeExam =  this.sanity;
  },

  methods: {
    onFilter: _.debounce(function () {
      this.loadExam();
    }, 1000),

    filteredData() {
      let data = _.cloneDeep(this.data);
      const today = moment().format("YYYY-MM-DD");

      switch (this.filter.exam) {
        case 'WITH_EXAM':
          data = this.dataExams(data, this.filter.typeExam);
          break;
        case 'WITHOUT_EXAM':
          data = this.dataExams(data, this.filter.typeExam, false);
          break;
      }

      switch (this.filter.expiration.type) {
        case 'EXPIRED':
          data = this.expiredAt(data, this.filter.typeExam, today);
          break;
        case 'UNEXPIRED':
          data = this.regularExams(data, this.filter.typeExam, today);
          break;
        case 'EXPIRES_UNTIL':
          data = this.filter.expiration.date ? this.expiredAt(data, this.filter.typeExam, this.filter.expiration.date) : data;
          break;
      }

      switch (this.filter.vaccine) {
        case 'WITH_VACCINE':
          data = this.dataVaccines(data);
          break;
        case 'WITHOUT_VACCINE':
          data = this.dataVaccines(data, false);
          break;
      }

      if(this.filter.typeComingDue) {
        data = this.comingDueExams(data, this.filter.typeExam, today, this.filter.typeComingDue);
      }

      return this.search(this.formatExams(data));
    },

    onExpirationFilter(value) {
      this.filter.expiration.type = value;
      this.filter.expiration.date = null;
      this.filter.expiration.show = value === 'EXPIRES_UNTIL';
    },

    async loadExam() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
            tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            cooperativas: this.filter.cooperatives,
          } });

        this.data = data.map(function(item) {
          item.total_vacinas = item.vacinas.length;
          item.total_animais_vacinados = this.totalAnimaisVacinados(item);
          return item;
        }, this);

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async print() {
      //this.$refs.report.print(null, "Relatório de Exames Sanitários");
      const item = this.filteredData();

      if (this.isObject(item)) {

        const { data } = await this.$axios.post(`/projects/health-exam/printAll`, {
          data: item,
          sanity: this.sanity,
        });


        let fileName = '';

        fileName = `Relatório de ${this.sanity}.pdf`;

        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();

        return;
      }
    },

    exportExcel() {
      this.$refs.report.exportExcel(null, "Relatório de Exames Sanitários");
    },

    getHeaders() {
      let producerInfos = [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome' },
      ];

      if( this.settings.mostrar_cpf ?? false) {
        producerInfos.push({ text: 'CPF', value: 'cnpj_cpf' });
      }

      if( this.settings.mostrar_telefone) {
        producerInfos.push({ text: 'Telefone', value: 'telefone' });
      }

      if( this.settings.mostrar_email) {
        producerInfos.push({ text: 'Email', value: 'email' });
      }

      if( this.settings.mostrar_cidade) {
        producerInfos.push({ text: 'Cidade', value: 'end_cidade' });
      }

      if( this.settings.mostrar_regiao) {
        producerInfos.push({ text: 'Região', value: 'regiao' });
      }

      this.headers = [
        ...producerInfos,
        { text: 'Total de Vacinas', value: 'total_vacinas' },
        { text: 'Total de Animais Vacinados', value: 'total_animais_vacinados' },
        { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' },
      ];
    },

    dataExams(data, type, hasExam = true) {
      const exams = (exam = type.toLowerCase()) => hasExam ? data.filter(item => item[exam]) :  data.filter(item => !item[exam]);
      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    dataVaccines(data, hasVaccine = true) {
      return data.filter(item => hasVaccine ? item['vacinas'].length : (item['vacinas'].length === 0));
    },

    expiredAt(data, type, date) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento <= date));
      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    comingDueExams(data, type, today, days) {
      const coming = moment().add(days, "days").format("YYYY-MM-DD");
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento < coming && item[exam].data_vencimento >= today));

      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    regularExams(data, type, today) {
      const exams = (exam = type.toLowerCase()) => data.filter(item => item[exam] && (item[exam].data_vencimento > today));
      return type ? exams() : _.unionBy(exams('brucelose'), exams('tuberculose'), 'id_pessoa');
    },

    formatExams(data) {
      const format = (item, isVaccine = false) => {
        const { data, data_vencimento, quantidade_animais, ...rest } = item || {};
        return {
          data: data ? this.formatDate(data, 'DD/MM/YYYY') : (isVaccine ? 'Sem Vacina' : 'Sem Exame'),
          quantidade_animais: quantidade_animais || 0,
          data_vencimento: !isVaccine && data_vencimento ? this.formatDate(data_vencimento, 'DD/MM/YYYY') : null,
          ...rest
        };
      };

      return data.map(({brucelose, tuberculose, vacinas, atestado_conformidade, brucelose_normativa, tuberculose_normativa, ...rest}) => ({
        brucelose: format(brucelose),
        tuberculose: format(tuberculose),
        vacinas: vacinas,
        atestado_conformidade: format(atestado_conformidade, true),
        brucelose_normativa: this.formatDate(brucelose_normativa, 'DD/MM/YYYY'),
        tuberculose_normativa: this.formatDate(tuberculose_normativa, 'DD/MM/YYYY'),
        ...rest
      }));
    },

    formatDate(value, format) {
      if(!value) {
        return 'Sem data';
      } else {
        return moment(value).format(format);
      }
    },

    search(data) {
      var filtered = this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
      this.dataLength = filtered.length;
      return filtered;
    },

    async loadCooperatives() {
      try {

        const { data } = await this.$axios.post(`/cooperativa/listaJson`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.cooperatives = data.map(item => ({
          id: item.id_cooperativa,
          name: item.nome_cooperativa,
        }));
      } catch (error) {
        console.warn(error);
      }
    },

    showAnexos(anexos) {
      if (anexos && typeof anexos === 'object') {
        Object.keys(anexos).forEach(function(key) {
          window.open(encodeURI(anexos[key].url), '_blank');
        });
        return;
      }

      window.open(anexos, '_blank');
    },
    async printExameReport(item) {

      if (this.isObject(item)) {

        const { data } = await this.$axios.post(`/projects/health-exam/print`, {
          data: item,
          sanity: this.sanity,
        });


        let fileName = '';

        fileName = `Relatório de ${this.sanity} Produtor ${item.nome}.pdf`;

        const binaryString = window.atob(data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const newBlob = new Blob([bytes], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);

          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataObject = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        document.body.appendChild(link);

        link.href = dataObject;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(data);
        link.remove();

        return;
      }
    },

    isObject(variable) {
      return variable !== null && typeof variable === 'object';
    },

    totalAnimaisVacinados(item) {
      return item.vacinas.reduce((total, vacinacao) => total + vacinacao.quantidade_animais, 0);
    },

    edit(item) {
      this.editId = item.id;

      this.showDialog = true;
    },

    loadAllVaccines() {
      this.loadCooperatives();
      this.loadExam();
    },

    async getVaccinesType() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/product', { params: {
            grupo: 'VACINAS',
          }
        });

        this.vaccinesTypes = data.map(item => {

          var fieldsMap = [];

          for (const key in item.campos) {
            fieldsMap[key] = item.campos[key];
          }

          return {
            id: item.id_produto,
            group: item.id_grupo_produto,
            name: item.nome,
            ... fieldsMap
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar produtos!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getSettings() {
      try {
        this.$root.$progressBar.loading();
        let { data } = await this.$axios.get(`settings/general`);

        if (!_.isEmpty(data)) {
          this.settings = data;
        }

      } catch (e) {
        this.$snotify.error("Erro ao carregar configuracao", "Atenção");
        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },
  },
};
</script>
