<template>
  <v-row>
    <v-col
      v-if="showUpload"
      cols="12"
      class="text-center"
    >
      <v-row :loading="loading">
        <v-col
          class="text-center"
          cols="12"
        >
          <v-btn
            outlined
            class="mb-5"
            @click="showFileUpload"
          >
            <v-icon>attach_file</v-icon> Adicionar arquivos
          </v-btn>
        </v-col>
      </v-row>

      <file-upload
        v-model="fileUpload.show"
        extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
        accept="application/pdf,text/xml,image/*"
        @insertFiles="onSelectFiles"
      />
    </v-col>

    <v-col
      v-else-if="attachments.length === 0"
      cols="12"
      class="text-center"
    >
      <v-card>
        <v-card-text>Não há anexos disponíveis</v-card-text>
      </v-card>
    </v-col>

    <v-col
      v-for="attachment in attachments"
      :key="attachment.url"
      class="d-flex child-flex"
      cols="2"
    >
      <v-card>
        <v-row>
          <v-col
            xs2
            md2
            class="text-center"
            align-self="center"
          >
            <v-icon
              large
              class="mr-1"
              color="#0096DD"
              @click="open(attachment.url || attachment)"
            >
              photo
            </v-icon>
            <v-btn
              v-if="attachment"
              class="pt-6"
              text
              icon
              x-small
              color="red"
              @click="onRemoveFile"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FileUpload from "@/Support/Components/FileUpload.vue";

export default {
  components: {
    FileUpload,
  },

  props: {
    attachments: {
      type: Array,
      default: () => []
    },
    showUpload: Boolean,
  },

  data() {
    return {
      loading: false,

      unload: {},

      // Dados referentes ao Dialog
      dialog: {
        show: false,
      },

      fileUpload: {
        show: false,
        tankIndex: -1
      },
    }
  },

  methods: {
    /**
     * Abre a url em uma nova aba
     * @param url
     */
    open(url) {
      window.open(url);
    },

    hide() {
      this.dialog.show = false;
    },

    showFileUpload() {
      this.fileUpload.show = true;
    },

    onSelectFiles(attachments) {
      for (let file of attachments) {
        if (!this.attachments.find(o => o.name == file.name)) {
          this.attachments.push({
            success: true,
            tipo: file.name,
            size: file.size,
            url: file.url,
            formato: file.type
          });

          this.$emit("attachment-selected", this.attachments);
        }
      }
    },

    async onRemoveFile(idx) {
      try {
        this.loading = true;
        const fileUrl = this.attachments[idx].url;
        if (fileUrl && fileUrl.includes('googleapis.com')) {
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
        }
      } catch (err) {
        console.warn(err);
      } finally {
        this.attachments.splice(idx, 1);
        this.loading = false;
      }
    },
  }
}
</script>
