var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',[_c('v-container',[(_vm.visit)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","content-class":"menu-select"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data *","placeholder":" ","prepend-inner-icon":"event","readonly":""},model:{value:(_vm.visitedAt),callback:function ($$v) {_vm.visitedAt=$$v},expression:"visitedAt"}},'v-text-field',attrs,false),on))]}}],null,false,2355027420),model:{value:(_vm.visitedAtMenu),callback:function ($$v) {_vm.visitedAtMenu=$$v},expression:"visitedAtMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.visitedAtMenu = false}},model:{value:(_vm.visit.visitedAt),callback:function ($$v) {_vm.$set(_vm.visit, "visitedAt", $$v)},expression:"visit.visitedAt"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('masked-input',{attrs:{"label":"Hora Chegada *","placeholder":" ","prepend-inner-icon":"schedule","mask":{
                    mask: '00:00',
                  },"rules":[
                    v => !!v || 'Campo obrigatório!',
                    v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
                  ],"max-length":16},model:{value:(_vm.visit.startedAt),callback:function ($$v) {_vm.$set(_vm.visit, "startedAt", $$v)},expression:"visit.startedAt"}})],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('masked-input',{attrs:{"label":"Hora Saída *","placeholder":" ","prepend-inner-icon":"schedule","mask":{
                    mask: '00:00',
                  },"rules":[
                    v => !!v || 'Campo obrigatório!',
                    v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
                  ],"max-length":16},model:{value:(_vm.visit.endedAt),callback:function ($$v) {_vm.$set(_vm.visit, "endedAt", $$v)},expression:"visit.endedAt"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('person-autocomplete-filter',{attrs:{"label":"Cliente *","type":"CUSTOMER","prepend-inner-icon":"person_outline","placeholder":" ","rules":[v => !!v && !!v.id || 'Campo obrigatório!'],"hide-details":false,"filled":false,"required":""},model:{value:(_vm.visit.customer),callback:function ($$v) {_vm.$set(_vm.visit, "customer", $$v)},expression:"visit.customer"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.visitTypes,"label":"Tipo de visita *","multiple":"","required":"","rules":[v => !!v && v.length > 0 || 'Escolha o tipo de visita']},model:{value:(_vm.visit.types),callback:function ($$v) {_vm.$set(_vm.visit, "types", $$v)},expression:"visit.types"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observação","placeholder":" "},model:{value:(_vm.visit.obs),callback:function ($$v) {_vm.$set(_vm.visit, "obs", $$v)},expression:"visit.obs"}})],1)],1)],1):_vm._e(),_c('small',[_vm._v("* Campo obrigatório")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}},[_c('v-card-text',[_vm._v(" Carregando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }