var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-autocomplete',{attrs:{"loading":_vm.loading,"items":_vm.computedItems,"item-text":"description","item-value":"id","multiple":"","dark":"","prepend-inner-icon":"local_shipping","label":"Equipamentos","return-object":"","hide-details":"","filled":""},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"selection",fn:function({ attr, on, item, selected }){return undefined}},{key:"item",fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item-icon',_vm._g({staticClass:"mr-3"},on),[_c('v-icon',{class:{
                'green--text': item.utilization > 0 && item.utilization < 90,
                'red--text': item.utilization > 90,
              },domProps:{"textContent":_vm._s(item.cargoType === 'milk' ? 'local_shipping' : 'directions_car')}})],1),_c('v-list-item-content',_vm._g({},on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.description)+" ")]),(item.utilization)?_c('v-list-item-subtitle',[_vm._v(" Utilização: "+_vm._s(item.utilization)+"% ")]):_vm._e(),(item.cost)?_c('v-list-item-subtitle',[_vm._v(" Custo/Litro: R$ "+_vm._s(_vm._f("number")(item.cost))+" ")]):_vm._e()],1)]}}],null,true)},[(!item.consultancyType)?[_vm._v(" Capacidade do Veículo: "+_vm._s(item.capacity)+" L "),_c('br'),_vm._v(" Km/L: "+_vm._s(_vm._f("number")(item.averageFuelConsumption))+" ")]:_vm._e()],2)]}}]),model:{value:(_vm.computedModel),callback:function ($$v) {_vm.computedModel=$$v},expression:"computedModel"}}),(_vm.computedModel.length > 0)?_c('v-list',{staticClass:"text-left overflow-y-auto",staticStyle:{"background-color":"rgba(255, 255, 255, 0.08)","max-height":"190px"},attrs:{"flat":"","dark":"","dense":""}},[_c('v-list-item-group',[_vm._l((_vm.computedModel),function(item,index){return [_c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{staticClass:"pl-3"},[_c('v-list-item-icon',{staticClass:"ml-0 mr-3"},[_c('v-icon',{class:{
                  'green--text': item.utilization > 0 && item.utilization < 90,
                  'red--text': item.utilization > 90,
                },domProps:{"textContent":_vm._s(item.cargoType === 'milk' ? 'local_shipping' : 'directions_car')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s((item.description || "").toUpperCase())+" ")]),(item.utilization && item.cargoType === 'milk')?_c('v-list-item-subtitle',[_vm._v(" Utilização: "+_vm._s(item.utilization)+"% ")]):_vm._e(),(item.cost && item.cargoType === 'milk')?_c('v-list-item-subtitle',[_vm._v(" Custo/Litro: R$ "+_vm._s(_vm._f("number")(item.cost))+" ")]):_vm._e()],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"x-small":"","icon":"","dark":"","color":"white"},on:{"click":function($event){return _vm.onRemoveItem(item)}}},[(hover)?_c('v-icon',[_vm._v(" close ")]):_vm._e()],1)],1)],1)]}}],null,true)})]})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }