<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">
        {{ title }}
        <slot name="title" />
      </span>
      <v-spacer />
      <v-btn
        v-if="showGraph"
        icon
        small
        @click="showGraph"
      >
        <v-icon>open_in_new</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pb-0 flex-grow-0">
      <slot />
    </v-card-text>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
export default {
  name: "card-chart",

  props: {
    title: {
      type: String,
      default: 'Gráfico'
    },
    showGraph: {
      type: Function
    },
    color: {
      type: String,
      default: '#26c6da'
    },
    graphColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    height: {
      type: String,
      default: '100px',
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
    showLabels: Boolean,
    loading: Boolean,
    showSymbol: Boolean,
    decimalPlaces: Number,
  },

  computed: {
    options() {
      const values = Object.values(this.data);
      return {
        color: [this.graphColor],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => `${params[0].name}<br>${params[0].marker} ${params[0].seriesName} <b>${this.formatNumber(params[0].value)}</b>`,
        },
        grid: {
          ...(this.showLabels
            ? {
              left: '3%',
              right: '4%',
              top: '10%',
              bottom: '3%',
              containLabel: true,
            }
            : {
              left: '0%',
              right: '0%',
              top: '8px',
              bottom: '0%',
              containLabel: false
            })
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Object.keys(this.data),
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.8)'
          },
          splitLine: {
            ...(this.showLabels
              ? {
                show: true,
                lineStyle: {
                  color: ['rgba(255, 255, 255, 0.1)']
                }
              }
              : { show: false })
          },
        },
        series: [{
          name: this.title,
          type: 'line',
          areaStyle: {},
          lineStyle: { width: 2 },
          showSymbol: this.showSymbol,
          smooth: this.smooth,
          emphasis: {
            focus: 'series'
          },
          data: values.map((value, index) => ({
            value,
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: index === 0 ? 'right' : index === values.length - 1 ? 'left' : 'inside',
              formatter: ({ value }) => `  ${this.formatNumber(value)}  `
            }
          }))
        }]
      }
    }
  },
  methods: {
    formatNumber(value) {
      let options =  { maximumFractionDigits: 2 };

      if (this.decimalPlaces !== undefined) {
        options = { maximumFractionDigits: this.decimalPlaces, minimumFractionDigits: this.decimalPlaces }
      }

      return new Intl.NumberFormat('pt-BR', options).format(value)
    },
  }
}
</script>
