import Vue from 'vue';

import Echarts from 'vue-echarts';
/**
 * Gráficos ECharts usados na aplicação
 *
 * Descomente apenas os que serão usados para
 * não aumentar desnecessariamente o tamanho bundle
 */
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/gauge';
// import 'echarts/lib/chart/map';
// import 'echarts/lib/chart/radar';
// import 'echarts/lib/chart/scatter';
// import 'echarts/lib/chart/effectScatter';
import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/polar';
// import 'echarts/lib/component/geo';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/title';
import 'echarts/lib/component/visualMap';
// import 'echarts/lib/component/dataset';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/dataZoom';

/**
 * @docs https://github.com/ecomfe/vue-echarts
 * @api https://echarts.apache.org/next/en/api.html#echarts
 * @examples https://echarts.apache.org/examples/en/index.html
 */
Vue.component('v-chart', Echarts);