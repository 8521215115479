<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      max-width="750px"
      scrollable
    >
      <v-card class="card-dialog">
        <v-card-title>
          <span class="text-h6">
            Planejamento de Spot
          </span>
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="show = false"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-0">
          <v-stepper
            v-model="step"
            alt-labels
            class="elevation-0"
          >
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :complete="step > 1"
              >
                Dados principais
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                step="2"
                :complete="step > 2"
              >
                Período
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <company-autocomplete-filter
                        v-model="form.company"
                        label="Empresa *"
                        :hide-details="false"
                        :filled="false"
                        placeholder=" "
                        :rules="[v => !!v && !!v.id || 'Campo obrigatório']"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <raw-material-autocomplete
                        v-model="form.rawMaterialId"
                        label="Matéria Prima *"
                        placeholder=" "
                        :rules="[rules.required]"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0"
                    >
                      <v-date-range-picker
                        v-model="form.period"
                        label="Período"
                        :filled="false"
                        :hide-details="false"
                        :ranges="dateRangeFilters"
                      />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0 d-flex"
                    >
                      <v-text-field
                        v-model="form.literPrice"
                        label="Valor do Litro"
                        prefix="R$"
                        type="number"
                        placeholder=" "
                        @keypress="disableDot"
                      />
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="align-self-end text-end mb-4"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              color="blue"
                            >
                              help
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Informe o valor do litro negociado para calcular automaticamente o valor total de cada dia</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form
                  ref="form2"
                  lazy-validation
                >
                  <div
                    style="max-height: calc(100vh - 315px); overflow: hidden auto;"
                  >
                    <template
                      v-for="(spot, idx) in form.spots"
                    >
                      <div :key="idx">
                        <div class="d-flex">
                          <div class="flex-grow-1 d-flex flex-column">
                            <span class="text-caption">
                              {{ dateFormat(spot.date, 'dddd') }}
                            </span>
                            <span class="text-body-1">
                              {{ dateFormat(spot.date, 'DD/MM/YYYY') }}
                            </span>
                          </div>

                          <div
                            v-if="type === 'SAIDA'"
                            class="d-flex justify-end"
                          >
                            <v-tooltip top>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  color="blue"
                                  class="my-1"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="addSpot(idx)"
                                >
                                  <v-icon>add</v-icon>
                                </v-btn>
                              </template>
                              <span>Adicionar Nova Ordem no Dia</span>
                            </v-tooltip>
                          </div>
                        </div>
                        <v-divider class="mb-4" />
                      </div>

                      <v-row
                        v-for="(item, i) in spot.items"
                        :key="idx + '_' + i"
                      >
                        <v-col
                          v-if="type === 'SAIDA'"
                          class="py-0"
                        >
                          <v-text-field
                            v-model="item.order"
                            label="Ordem de Compra"
                            type="number"
                            placeholder=" "
                            prepend-inner-icon="receipt"
                            :rules="!i ? [] : [validateOrder(spot, i)]"
                            @keypress="disableDotAndComma"
                          />
                        </v-col>
                        <v-col class="py-0">
                          <v-text-field
                            v-model="item.volume"
                            label="Volume (L)"
                            type="number"
                            placeholder=" "
                            prepend-inner-icon="opacity"
                            :disabled="item.hasItinerary"
                            @keypress="disableDotAndComma"
                          />
                        </v-col>
                        <v-col class="py-0">
                          <v-text-field
                            v-model="item.literPrice"
                            label="Valor do Litro"
                            prefix="R$"
                            type="number"
                            placeholder=" "
                            :disabled="item.hasItinerary"
                            :rules="(item.volume && priceIsRequired) ? [rules.required] : []"
                            @keypress="disableDot"
                          />
                        </v-col>
                        <v-col class="py-0">
                          <v-text-field
                            :value="!!item.literPrice && !!item.volume ? formatCurrency(parseFloat(item.literPrice) * parseFloat(item.volume)) : null"
                            label="Total"
                            prefix="R$"
                            placeholder=" "
                            disabled
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <v-alert
                      dense
                      outlined
                      dismissible
                      type="info"
                    >
                      Apenas os spots com volume informado serão salvos
                    </v-alert>
                  </div>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <small class="mx-4">* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="step == 1"
            color="grey darken-1"
            outlined
            @click="() => show = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-else
            outlined
            @click="() => step--"
          >
            <v-icon
              left
              dark
            >
              chevron_left
            </v-icon>
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="showDeleteBtn"
            color="red darken-1"
            outlined
            @click="removePlanning"
          >
            Excluir
          </v-btn>
          <v-btn
            v-if="step == 1"
            color="green"
            outlined
            @click="nextStep"
          >
            Continuar
            <v-icon
              right
              dark
            >
              chevron_right
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            outlined
            @click="saveNew"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        v-model="saving"
        absolute
      >
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-overlay>

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import CompanyAutocompleteFilter from '@/Domains/Itineraries/Components/CompanyAutocompleteFilter.vue';
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

import _ from "lodash";
import moment from "moment";

export default {

  components: {
    CompanyAutocompleteFilter,
    RawMaterialAutocomplete,
    VDateRangePicker,
  },

  props: {
    value: {
      type: Boolean,
    },
    spot: {
      type: Object,
      default: () => ({
        company: {},
        rawMaterialId: null,
        period: [],
      })
    },

    type: {
      type: String,
      default: 'ENTRADA'
    },
  },

  data() {
    return {
      loading: false,
      saving: false,
      step: 1,
      isSaved: false,

      form: {
        company: {},
        rawMaterialId: null,
        literPrice: null,
        period: [],
        spots: []
      },

      rules: {
        required: v => !!v || !_.isEmpty(v) || 'Campo obrigatório!',
        dateTime: v => !v || !!v && v.length == 16 && this.dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!'
      },
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.reset();
        }
        this.$emit("input", value);
      },
    },
    dateRangeFilters() {
      const today = moment().date();
      let currrentFortnight, previousFortnight, nextFortnight;

      if (today <= 15) {
        currrentFortnight = [moment().startOf("month").format("YYYY-MM-DD"), moment().date(15).format("YYYY-MM-DD")]
        previousFortnight = [moment().subtract(1, "month").date(16).format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")]
        nextFortnight = [moment().date(16).format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")]
      }
      else {
        currrentFortnight = [moment().date(16).format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")]
        previousFortnight = [moment().startOf("month").format("YYYY-MM-DD"), moment().date(15).format("YYYY-MM-DD")]
        nextFortnight = [moment().add(1, "month").startOf("month").format("YYYY-MM-DD"), moment().add(1, "month").date(15).format("YYYY-MM-DD")]
      }

      return {
        'Esta quinzena': currrentFortnight,
        'Quinzena Anterior': previousFortnight,
        'Próxima Quinzena': nextFortnight,
      }
    },

    showDeleteBtn() {
      return this.step === 2 && this.isSaved;
    },

    priceIsRequired() {
      return !!this.$store.state.settings.gerais.valor_spot_obrigatorio;
    },
  },

  watch: {
    show(value) {
      if (value) {
        if (this.spot && this.spot.company.id && this.spot.rawMaterialId) {
          this.loading = true;

          this.$nextTick(() => {

            this.form.company = { ...this.spot.company };
            this.form.rawMaterialId = this.spot.rawMaterialId;
            this.form.period = [...this.spot.period];

            setTimeout(() => {
              this.nextStep();
            }, 500);
          });

        }
        else {
          this.form.period = this.dateRangeFilters['Esta quinzena'];
        }
      }
    }
  },

  methods: {

    reset() {
      this.$refs.form && this.$refs.form.resetValidation();
      this.step = 1;
      this.form = {
        company: {},
        rawMaterialId: null,
        literPrice: null,
        period: [],
        spots: []
      };
    },

    async nextStep() {
      await this.loadPeriod();
    },

    async loadPeriod() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      try {
        this.form.spots = [];

        const [startDate, endDate] = this.form.period;

        const { data } = await this.$axios.post(
          `/spot/listarPlanejamentos`,
          {
            data_ini: startDate,
            data_fim: endDate,
            id_empresa: this.form.company.id,
            id_materia_prima: this.form.rawMaterialId,
            tipo: this.type
          },
        );

        this.isSaved = data.length > 0;

        let dateAux = startDate;

        while (dateAux <= endDate) {
          const date = moment(dateAux);
          const formatedDate = date.format('YYYY-MM-DD');

          const items = data
            .filter(item => item.data == formatedDate)
            .map(item => ({
              id: item.id_spot,
              order: item.ordem_compra,
              hasItinerary: !!item.id_itinerario,
              literPrice: parseFloat(item.valor_litro),
              volume: parseFloat(item.volume)
            }));

          if (items.length === 0) {
            items.push({
              order: null,
              literPrice: this.form.literPrice,
              volume: null,
            })
          }

          this.form.spots.push({
            date: formatedDate,
            items
          })

          dateAux = date.add(1, 'day').format('YYYY-MM-DD');
        }

        this.step++;
      } catch (error) {
        this.reset();
        this.show = false;
        this.$snotify.error("Oops, ocorreu um erro ao carregar o período!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }

    },

    addSpot(idx) {
      this.form.spots[idx].items.push({
        order: null,
        literPrice: this.form.literPrice,
        volume: null
      })
    },

    async saveNew() {
      if (!this.$refs.form2.validate()) {
        return;
      }

      this.saving = true;

      try {

        const payload = {
          id_empresa: this.form.company.id,
          nome_empresa: this.form.company.name,
          id_materia_prima: this.form.rawMaterialId,
          tipo: this.type,
          spots: this.form.spots
            .flatMap(spot => spot.items
              .map(item => ({
                id_spot: item.id,
                data: spot.date,
                ordem_compra: item.order,
                volume: parseFloat(item.volume) || null,
                valor_litro: item.literPrice || null
              })))
            .filter(o => o.id_spot || o.volume)
        };

        const { data } = await this.$axios.post(
          `/spot/salva`,
          payload,
        );

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }

        this.$emit("change");

        this.reset();
        this.show = false;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar o planejamento!", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async removePlanning() {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este planejamento?', { color: 'red' }))) {
        return;
      }
      this.saving = true;
      try {

        const [startDate, endDate] = this.form.period;

        const { data } = await this.$axios.post(
          `/spot/remove`,
          {
            data_ini: startDate,
            data_fim: endDate,
            id_empresa: this.form.company.id,
            id_materia_prima: this.form.rawMaterialId,
            tipo: this.type,
          },
        );

        if (data.codigo != 1) {
          throw data.mensagem || data;
        }

        this.$emit("change");

        this.reset();
        this.show = false;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao remover o planejamento!", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
        this.getSpots();
      }
    },

    validateOrder(spot, i) {
      const item = spot.items[i];

      for (const j in spot.items) {
        if (i != j && item.order == spot.items[j].order) {
          if (!item.order) {
            return 'Ordem de compra obrigatória';
          }

          return 'Ordem de compra duplicada';
        }
      }

      return true;
    },

    dateValidate: (value, format) => !value ? '' : moment(value, format).isValid(),
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
