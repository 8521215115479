<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <v-select
        v-model="form.tributacao"
        :items="cst"
        label="Situação Tributária (CST) *"
        prepend-inner-icon="tag"
        :rules="[v => !!v || 'Campo obrigatório!']"
      />
    </v-col>
    <v-col
      v-if="showAliquota"
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.aliquota"
        label="Alíquota"
        :mask="masks.percentual"
        unmask
        prepend-inner-icon="percent"
      />
    </v-col>
    <v-col
      v-if="showCodigoEnquadramento"
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.codigo_enquadramento"
        label="Código de enquadramento de IPI *"
        :mask="masks.codigo_enquadramento"
        unmask
        prepend-inner-icon="tag"
        :rules="[v => !!v || 'Campo obrigatório!']"
      />
    </v-col>
    <template v-if="showValores && showAliquota">
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pb-0"
      >
        <money-input
          v-model="form.valor_base"
          label="Valor base"
          prefix="R$"
          :disabled="autocalculation"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pb-0"
      >
        <money-input
          v-model="form.valor"
          label="Valor"
          prefix="R$"
          :disabled="autocalculation"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

export default {

  components: {
    MaskedTextField,
    MoneyInput,
  },

  props: {
    inOut: {
      type: String,
      default: 'SAIDA',
      validator: (value) => ['ENTRADA', 'SAIDA'].includes(value)
    },
    data: Object,
    showValores: Boolean,
    autocalculation: Boolean,
  },

  data() {
    return {
      masks: {
        codigo_enquadramento: { mask: '000' },
        percentual: { mask: Number, min: 0, max: 100, scale: 4 },
      },

      cstEntrada: [
        { value: '00', text: '00 - Entrada com recuperação de crédito' },
        { value: '01', text: '01 - Entrada tributada com alíquota zero' },
        { value: '02', text: '02 - Entrada isenta' },
        { value: '03', text: '03 - Entrada não-tributada' },
        { value: '04', text: '04 - Entrada imune' },
        { value: '05', text: '05 - Entrada com suspensão' },
        { value: '49', text: '49 - Outras entradas' },
      ],

      cstSaida: [
        { value: '50', text: '50 - Saída tributada' },
        { value: '51', text: '51 - Saída tributável com alíquota zero' },
        { value: '52', text: '52 - Saída isenta' },
        { value: '53', text: '53 - Saída não-tributada' },
        { value: '54', text: '54 - Saída imune' },
        { value: '55', text: '55 - Saída com suspensão' },
        { value: '99', text: '99 - Outras saídas' },
      ],
    }
  },

  computed: {
    form: {
      get() {
        return this.data;
      },

      set(newValue) {
        return this.$emit("update:data", newValue);
      },
    },

    cst() {
      const naoDestacar = { value: 'NAO_DESTACAR', text: 'Não destacar IPI' };

      if (this.inOut === 'ENTRADA') {
        return [...this.cstEntrada, naoDestacar];
      }

      return [...this.cstSaida, naoDestacar];
    },

    showAliquota() {
      return ['00', '49', '50', '99'].includes(this.form.tributacao)
    },

    showCodigoEnquadramento() {
      return ['02', '04', '05', '52', '54', '55'].includes(this.form.tributacao)
    }
  },
}
</script>
