<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            Histórico de metas
          </v-card-title>
          <v-data-table
            :headers="history.headers"
            :items="history.items"
            dark
            class="elevation-1 report-table"
            @click:row="onSelectPlanning($event, true)"
          >
            <template #[`item.date`]="{ value, item }">
              <span :class="{['text-decoration-line-through']: !item.active}">
                {{ value | dateFormat('MM/YYYY') }}
              </span>
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="onSelectPlanning(item, false)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue darken-1 mr-5"
          v-on="on"
          @click="onNewPlanning"
        >
          <v-icon dark>
            add
          </v-icon>
        </v-btn>
      </template>

      <span>Novo planejamento</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";

export default {
  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  data() {
    return {
      history: {
        headers: [
          { text: 'Mês de início', value: 'date' },
          { text: '', value: 'action', width: 30 },
        ],
        items: [],
      },
    };
  },

  created() {
    this.loadHistory();
  },

  methods: {
    async loadHistory() {
      try {
        const { data } = await this.$axios.get(`/recomendacoesQualidade/historicoMetas`);

        if (!_.isArray(data)) {
          throw "PHP error";
        }

        this.history.items = data.map(o => ({
          id: o.id_assistencia,
          date: o.data,
          active: o.ativo,
          selected: null,
        }));
      }
      catch (err) {
        console.warn(err)
      }
    },

    onNewPlanning() {
      this.$emit('newPlanning');
    },

    onSelectPlanning({ id }, show) {
      this.$emit('selectPlanning', id, show);
    },
  },
};
</script>
