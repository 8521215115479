<template>
  <v-card
    elevation="4"
    class="mb-6"
  >
    <v-card-actions class="pa-1">
      <span class="body-1 font-weight-bold pl-2">{{ label.name }}</span>

      <v-spacer />

      <div class="d-flex align-center  text--darken-1">
        <span>Ativo</span>
        <v-switch
          v-model="label.enabled"
          hide-details
          class="ma-1 pb-1"
          color="deep-purple"
        />
      </div>
    </v-card-actions>
    <v-divider class="ma-0" />
    <v-list
      class="pt-0"
    >
      <v-row
        no-gutters
        class="mb-0"
      >
        <v-col
          cols="6"
          class="text-center"
        >
          Faixa
        </v-col>
        <v-col
          cols="6"
          class="text-center"
        >
          % Min. Visitas / Mês
        </v-col>
      </v-row>
      <v-row
        v-for="(item, i) in label.ranges"
        :key="i"
        no-gutters
        class="mb-0"
      >
        <v-col cols="6">
          <v-text-field
            v-model="item.value"
            type="number"
            label="Faixa"
            outlined
            dense
            suffix="%"
            hide-details
            :rules="getRules(i)"
            :disabled="!label.enabled || i == label.ranges.length - 1"
            @keypress="disableDotAndComma"
          >
            <template #prepend>
              <span
                class="d-block pt-1 text-right"
                style="width: 45px"
              >
                {{ i === 0 ? '0' : label.ranges[i-1].value }}% à
              </span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="item.visits"
            label="Visitas"
            prepend-inner-icon="trending_up"
            type="number"
            max="100"
            outlined
            hide-details
            dense
            suffix="%"
            :rules="[v => !v || Number(v) <= 100 || 'Valor inválido!']"
            :disabled="!label.enabled"
            @keypress="disableDotAndComma"
          />
        </v-col>
      </v-row>
    </v-list>
    <v-divider class="ma-0" />
    <v-card-actions class="pa-1">
      <v-spacer />
      <v-btn
        :disabled="!label.enabled || label.ranges.length === 1"
        icon
        color="red darken-4"
        @click="changeRange('remove')"
      >
        <v-icon dense>
          remove
        </v-icon>
      </v-btn>
      <v-divider
        vertical
        class="mx-1 my-0"
      />
      <v-btn
        :disabled="!label.enabled"
        icon
        color="blue darken-4"
        @click="changeRange('add')"
      >
        <v-icon dense>
          add
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Object
  },

  computed: {
    label: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {

    changeRange(type) {
      if (type == 'add') {
        this.label.ranges.unshift({ value: null });
      }
      if (type == 'remove') {
        this.label.ranges.splice(0, 1);
      }
    },

    getRules(idx) {
      const ranges = this.label.ranges;
      return [
        v => !!v || 'Informe o percentual',
        v => parseFloat(v) <= 100 || 'Percentual deve ser menor que 100',
        v => idx == 0 || parseFloat(v) > parseFloat(ranges[idx - 1].value) || 'Valor deve ser maior que o anterior',
        v => idx == ranges.length - 1 || parseFloat(v) < parseFloat(ranges[idx + 1].value) || 'Valor deve ser menor que o próximo',
      ]
    },
    
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  }
  
}
</script>