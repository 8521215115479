<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Rastreio de Lote
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  ref="lotNumberRef"
                  v-model="lotNumber"
                  label="Lote"
                  x-large
                  filled
                  hide-details
                  @keyup.enter="lotNumberRef.blur()"
                  @blur="loadProducts"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            ref="report"
            :headers="headers"
            :items="items"
            dark
            group-by="produto"
          >
            <template #[`group.header`]="{ group, isOpen, toggle, headers, items }">
              <td
                v-for="(h, idx) in headers"
                :key="h.value"
                :class="h.align ? `text-${h.align}` : 'text-start'"
              >
                <template v-if="h.value === 'data-table-expand'">
                  <v-btn
                    icon
                    @click="toggle"
                  >
                    <v-icon>
                      {{ isOpen ? 'remove' : 'add' }}
                    </v-icon>
                  </v-btn>
                </template>
                <template v-else-if="idx === 1">
                  <b v-if="group">{{ group }}</b>
                </template>
              </td>
            </template>

            <template #[`item.quantidade`]="{ value, item }">
              <v-chip
                dark
                small
                :color="getQuantityColor(value)"
              >
                {{ formatNumber(value) }}
              </v-chip>
            </template>

            <template #[`item.saldo`]="{ value, item }">
              <v-chip
                dark
                small
                :color="getQuantityColor(value)"
              >
                {{ formatNumber(value) }}
              </v-chip>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import moment from 'moment'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

const { progressBar, notify } = useUtils()

const lotNumberRef = ref()

const lotNumber = ref(null)

const headers = [
  { text: 'Produto', value: 'produto' },
  { text: 'Tipo', value: 'tipo' },
  { text: 'Data', value: 'data', formatter: value => formatDate(value, 'DD/MM/YYYY') },
  { text: 'Quantidade', value: 'quantidade' },
  { text: 'Código/Carga', value: 'codigo' },
  { text: 'Nota', value: 'numero_nota' },
  { text: 'Cliente', value: 'cliente' },
  { text: 'Motorista', value: 'motorista' },
  { text: 'Saldo', value: 'saldo' },
]

const report = ref()
const items = ref([])

const loadProducts = async () => {
  if (!lotNumber.value) {
    items.value = []
    return
  }

  try {
    progressBar?.loading()

    const { data } = await axios.get(`/stock/tracking`, { params: {
      numero_lote: lotNumber.value,
    } })

    items.value = data

  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

const getTitle = () => `Rastreio Lote - ${lotNumber.value}`

const exportExcel = () => report.value?.exportExcel(null, getTitle())

const print = () => report.value?.print(null, getTitle())

const getQuantityColor = (value) => {
  if (value < 0) {
    return 'red darken-1'
  }
  if (value > 0) {
    return 'green darken-1'
  }
  return 'grey'
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

loadProducts()
</script>
