<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="2"
        class="pt-0"
      >
        <v-menu
          ref="menu"
          transition="scale-transition"
          offset-y
          content-class="menu-select"
          light
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="dateRefFilter"
              label="Mês de Referência"
              prepend-inner-icon="event"
              hide-details
              readonly
              filled
              dark
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="filters.date"
            :max="maxDateRef"
            type="month"
            no-title
            scrollable
            @change="onFilter"
          />
        </v-menu>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        dark
        color="transparent"
      >
        <v-card-title>
          <v-row>
            <v-col
              class="align-self-center font-weight-light text-left pt-0"
            >
              Leite Próprio
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
              class="pt-0"
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
              />
            </v-col>
          </v-row>
        </v-card-title>
        <data-table
          ref="report"
          name="Custo do Leite"
          :headers="headers"
          :items="items"
          :search="filters.search"
          :loading="loading"
          dark
        >
          <template #[`body.append`]="{ headers }">
            <tr>
              <td
                v-for="(header,index) in headers"
                :key="index"
              >
                <div
                  v-if="header.value === 'nome_laticinio'"
                  class="text-left"
                >
                  <b>TOTAL</b>
                </div>
                <div
                  v-else-if="header.hasOwnProperty('formatter') && !headersToIgnore.includes(header.value)"
                  class="text-center"
                >
                  <v-chip
                    small
                    color="blue darken-4"
                  >
                    {{ header.formatter(totals[header.value]) }}
                  </v-chip>
                </div>
              </td>
            </tr>
          </template>
        </data-table>
      </v-card>

      <v-card
        v-for="(data, idx) in itemsSpot"
        :key="idx"
        dark
        color="transparent"
        class="mt-6"
      >
        <v-card-title>
          <v-row>
            <v-col
              class="align-self-center font-weight-light text-left pt-0"
            >
              Leite Spot - {{ data.dairyName }}
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
              class="pt-0"
            >
              <v-text-field
                v-model="data.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
              />
            </v-col>
          </v-row>
        </v-card-title>
        <data-table
          ref="reportSpot"
          name="Custo do Leite Spot"
          :headers="headersSpot"
          :items="data.items"
          :search="data.search"
          :loading="loading"
          dark
        >
          <template #[`body.append`]="{ headers }">
            <tr>
              <td
                v-for="(header,index) in headers"
                :key="index"
              >
                <div
                  v-if="header.value === 'nome_empresa'"
                  class="text-left"
                >
                  <b>TOTAL</b>
                </div>
                <div
                  v-else-if="header.hasOwnProperty('formatter') && !headersToIgnore.includes(header.value)"
                  class="text-center"
                >
                  <v-chip
                    small
                    color="blue darken-4"
                  >
                    {{ header.formatter(data.totals[header.value]) }}
                  </v-chip>
                </div>
              </td>
            </tr>
          </template>
        </data-table>
      </v-card>

      <v-card
        v-if="totalsSpot"
        dark
        color="transparent"
        class="mt-6"
      >
        <v-card-title>
          Totais Spot
        </v-card-title>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headersSpot"
                  :key="index"
                  :class="header.align ? `text-${header.align}` : ''"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td
                  v-for="(header, index) in headersSpot"
                  :key="index"
                >
                  <div
                    v-if="header.value === 'nome_empresa'"
                    class="text-left"
                  >
                    <b>TOTAL</b>
                  </div>
                  <div
                    v-else-if="header.hasOwnProperty('formatter') && !headersToIgnore.includes(header.value)"
                    class="text-center"
                  >
                    <v-chip
                      small
                      color="blue darken-4"
                    >
                      {{ header.formatter(totalsSpot[header.value]) }}
                    </v-chip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-flex>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import ReportMixin from '@/Support/Mixins/ReportMixin.js';
import moment from 'moment-timezone';
import _ from 'lodash';

export default {

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        date: moment().subtract(1, 'month').format('YYYY-MM'),
        search: '',
        spotSearch: '',
      },

      headers: [
        { text: 'Unidade', value: 'nome_laticinio' },
        { text: 'Produtores', value: 'produtores', align: 'center', formatter: value => this.formatNumber(value), mask: '#,##0'  },
        { text: 'Volume', value: 'volume_total', align: 'center', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
        { text: 'ICMS', value: 'tributos.icms', align: 'center', formatter: value => this.formatNumber(value) + ' %', mask: '#,##0', show: false },
        { text: 'PIS/COF', value: 'tributos.pis_cofins', align: 'center', formatter: value => this.formatNumber(value) + ' %', mask: '#,##0', show: false },
        { text: 'Valor Tributos', value: 'valor_tributos', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00', show: false },
        { text: 'Total Leite', value: 'valor_leite_total', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'ICMS Frete', value: 'tributos.icms_frete', align: 'center', formatter: value => this.formatNumber(value) + ' %', mask: '#,##0', show: false },
        { text: 'PIS/COF Frete', value: 'tributos.pis_cofins_frete', align: 'center', formatter: value => this.formatNumber(value) + ' %', mask: '#,##0', show: false },
        { text: 'Valor Trib. Frete', value: 'valor_tributos_frete', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00', show: false },
        { text: 'Total Frete', value: 'frete_total', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Média Valor (L)', value: 'valor_medio', align: 'center', formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.00' },
        { text: 'Média Frete (L)', value: 'valor_medio_frete', align: 'center', formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.00', show: false },
        { text: 'Posto Plataforma', value: 'valor_medio_com_frete', formatter: value => this.formatCurrency(value, 4), align: 'center', mask: 'R$ #,##0.00' },
        { text: 'Média Líquido', value: 'valor_final', align: 'center', formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.00', show: false },
        { text: 'Média Frete Líquido', value: 'valor_final_frete', align: 'center', formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.00', show: false },
        { text: 'Custo Final', value: 'valor_final_com_frete', formatter: value => this.formatCurrency(value, 4), align: 'center', mask: 'R$ #,##0.00', show: false },
        { text: 'Custo Final (Mês Ant.)', value: 'valor_final_com_frete_anterior', formatter: value => this.formatCurrency(value, 4), align: 'center', mask: 'R$ #,##0.00', show: false },
        { text: 'Diferença (Mês Ant.)', value: 'diferenca_anterior', formatter: value => this.formatDifference(value), align: 'center', mask: 'R$ #,##0.00', show: false },
        { text: 'Total', value: 'valor_total', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Total (Líquido)', value: 'valor_liquido', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00', show: false },
        { text: 'Complementos', value: 'complementos_total', formatter: value => this.formatCurrency(value), align: 'center', mask: 'R$ #,##0.00', show: false },
        { text: 'Complementos (Mês Ant.)', value: 'complementos_total_anterior', formatter: value => this.formatCurrency(value), align: 'center', mask: 'R$ #,##0.00' },
        { text: 'Vol. Médio/Dia', value: 'volume_medio_dia', formatter: value => this.formatNumber(value) + ' L', align: 'center', mask: '#,##0' },
      ],

      items: [],

      headersToIgnore: [
        'tributos.icms',
        'tributos.pis_cofins',
        'tributos.icms_frete',
        'tributos.pis_cofins_frete',
      ],

      headersSpot: [
        { text: 'Empresa', value: 'nome_empresa' },
        { text: 'Volume', value: 'volume_total', align: 'center', formatter: value => this.formatNumber(value) + ' L', mask: '#,##0' },
        { text: 'ICMS', value: 'tributos.icms', align: 'center', formatter: value => this.formatNumber(value) + ' %', mask: '#,##0', show: false },
        { text: 'PIS/COF', value: 'tributos.pis_cofins', align: 'center', formatter: value => this.formatNumber(value) + ' %', mask: '#,##0', show: false },
        { text: 'Valor Tributos', value: 'valor_tributos', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00', show: false },
        { text: 'Média Valor (L)', value: 'valor_medio', align: 'center', formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.00' },
        { text: 'Média Líquido (L)', value: 'valor_final', align: 'center', formatter: value => this.formatCurrency(value, 4), mask: 'R$ #,##0.00', show: false },
        { text: 'Total', value: 'valor_leite_total', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00' },
        { text: 'Total (Líquido)', value: 'valor_leite_liquido', align: 'center', formatter: value => this.formatCurrency(value), mask: 'R$ #,##0.00', show: false },
      ],

      itemsSpot: [],

      totalsSpot: null,
    };
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format('YYYY-MM');
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, 'YYYY-MM').format('MM/YYYY');
    },

    totals() {
      const totalVol = _.sumBy(this.items, 'volume_total');
      const totalMilkPrice = _.sumBy(this.items, 'valor_leite_total');
      const totalMilkPriceTaxes = _.sumBy(this.items, 'valor_tributos');
      const totalFreight = _.sumBy(this.items, 'frete_total');
      const totalFreightTaxes = _.sumBy(this.items, 'valor_tributos_frete');

      const totalMilkPriceFinal = _.sumBy(this.items, 'valor_leite_liquido');
      const totalFreightFinal = _.sumBy(this.items, 'valor_frete_liquido');
      const totalNetPriceFinal = (totalMilkPriceFinal +  totalFreightFinal) / totalVol;

      const totalVolPrevious = _.sumBy(this.items, 'volume_total_anterior');
      const totalNetPricePrevious = _.sumBy(this.items, 'valor_liquido_anterior');
      const totalNetPriceFinalPrevious = totalVolPrevious ? totalNetPricePrevious / totalVolPrevious : 0;

      const daysInMonth = moment(this.filters.date, 'YYYY-MM').daysInMonth();

      return {
        'nome_laticinio': 'Total',
        'produtores': _.sumBy(this.items, 'produtores'),
        'volume_total': totalVol,
        'valor_leite_total': totalMilkPrice,
        'frete_total': totalFreight,
        'valor_tributos': totalMilkPriceTaxes,
        'valor_tributos_frete': totalFreightTaxes,
        'valor_medio': totalMilkPrice / totalVol,
        'valor_medio_frete': totalFreight / totalVol,
        'valor_medio_com_frete': (totalMilkPrice +  totalFreight) / totalVol,
        'valor_final': totalMilkPriceFinal / totalVol,
        'valor_final_frete': totalFreightFinal / totalVol,
        'valor_final_com_frete': totalNetPriceFinal,
        'valor_total': _.sumBy(this.items, 'valor_total'),
        'valor_liquido': _.sumBy(this.items, 'valor_liquido'),
        'valor_final_com_frete_anterior': totalVolPrevious ? totalNetPricePrevious / totalVolPrevious : 0,
        'diferenca_anterior': totalNetPriceFinal - totalNetPriceFinalPrevious,
        'complementos_total': _.sumBy(this.items, 'complementos_total'),
        'complementos_total_anterior': _.sumBy(this.items, 'complementos_total_anterior'),
        'volume_medio_dia': parseInt(totalVol / daysInMonth),
      };
    },
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    onFilter() {
      this.loadReport();
    },

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {
        const [{ data }, { data: spot }] = await Promise.all([
          this.$axios.get(`/financial/reports/milk-cost`, { params: { month_reference: this.filters.date } }),
          this.$axios.get(`/financial/reports/spot-milk-cost`, { params: { month_reference: this.filters.date } })
        ]);

        this.items = data;
        this.itemsSpot = _(spot)
          .groupBy('nome_laticinio')
          .map((items, dairyName) => {
            const totalVol = _.sumBy(items, 'volume_total');
            const totalMilkPrice = _.sumBy(items, 'valor_leite_total');
            const totalMilkPriceTaxes = _.sumBy(items, 'valor_tributos');
            const totalMilkPriceFinal = _.sumBy(items, 'valor_leite_liquido');
            return {
              dairyName,
              search: null,
              items,
              totals: {
                nome_empresa: 'Total',
                volume_total: totalVol,
                valor_leite_total: totalMilkPrice,
                valor_leite_liquido: totalMilkPriceFinal,
                valor_tributos: totalMilkPriceTaxes,
                valor_medio: totalMilkPrice / totalVol,
                valor_final: totalMilkPriceFinal / totalVol,
              }
            }
          })
          .value();

        this.totalsSpot = null;

        if (this.itemsSpot.length > 1) {
          const totalVol = _.sumBy(spot, 'volume_total');
          const totalMilkPrice = _.sumBy(spot, 'valor_leite_total');
          const totalMilkPriceTaxes = _.sumBy(spot, 'valor_tributos');
          const totalMilkPriceFinal = _.sumBy(spot, 'valor_leite_liquido');
          this.totalsSpot = {
            nome_empresa: 'Total Spot',
            volume_total: totalVol,
            valor_leite_total: totalMilkPrice,
            valor_leite_liquido: totalMilkPriceFinal,
            valor_tributos: totalMilkPriceTaxes,
            valor_medio: totalMilkPrice / totalVol,
            valor_final: totalMilkPriceFinal / totalVol,
          };
        }
      }
      catch (err) {
        console.warn(err);
        this.$snotify.error('Erro ao carregar o relatório', 'Atenção');
      }
      finally {
        this.loading = false;
      }
    },

    getReportTitle() {
      return `Custo do Leite - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;
    },

    getReportJson(items, headers, isExcel = false) {
      return items
        .map(item => headers.reduce((acc, header) => ({
          ...acc,
          [header.text]: ('formatter' in header) && !(isExcel && ('mask' in header))
            ? header.formatter(_.get(item, header.value, ''))
            : _.get(item, header.value, ''),
        }), {}));
    },

    async print() {
      const title = this.getReportTitle();

      if (this.items.length === 0 && this.itemsSpot.length === 0) {
        return;
      }

      const body = [];

      if (this.items.length > 0) {
        const headers = this.$refs.report.filteredHeaders;
        const report = this.getReportJson([...this.items, this.totals], headers);

        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            tag: 'datatable',
            title: `Leite Próprio`,
            headers: Object.keys(report[0])
              .map(header => ({
                key: header,
                label: header,
                class: 'text-left'
              })),
            items: report,
          }]
        });
      }

      for (const idx in this.itemsSpot) {
        const data = this.itemsSpot[idx];
        const headers = this.$refs.reportSpot[idx].filteredHeaders;
        const report = this.getReportJson([...data.items, data.totals], headers);
        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            tag: 'datatable',
            title: `Leite Spot - ${data.dairyName}`,
            headers: Object.keys(report[0])
              .map(header => ({
                key: header,
                label: header,
                class: 'text-left'
              })),
            items: report,
          }]
        });
      }

      if (this.totalsSpot) {
        const headers = this.$refs.reportSpot[0].filteredHeaders;
        const report = this.getReportJson([this.totalsSpot], headers);
        body.push({
          tag: 'section',
          class: 'row',
          children: [{
            tag: 'datatable',
            title: `Total Spot`,
            headers: Object.keys(report[0])
              .map(header => ({
                key: header,
                label: header,
                class: 'text-left'
              })),
            items: report,
          }]
        });
      }

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }], landscape: true });
    },

    exportExcel() {
      const title = `Custo do Leite - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;

      if (this.items.length === 0 && this.itemsSpot.length === 0) {
        return;
      }

      const worksheet = this.XLSX.utils.json_to_sheet([]);
      const workbook = this.XLSX.utils.book_new();

      let currentRow = 0;

      if (this.items.length > 0) {
        const headers = this.$refs.report.filteredHeaders;
        const report = this.getReportJson(this.items, headers, true);

        worksheet[`A${currentRow + 1}`] = { v: `Leite Próprio` };
        currentRow += 1;

        this.XLSX.utils.sheet_add_json(worksheet, report, { origin: currentRow });
        this.applyStyles(worksheet, report, null, false, currentRow);
        this.applyMasks(worksheet, headers, report.length, currentRow);

        {
          const totals = this.getReportJson([this.totals], headers, true);

          currentRow += report.length + 1;
          const skipHeader = true;

          this.XLSX.utils.sheet_add_json(worksheet, totals, { origin: currentRow, skipHeader });
          this.applyStyles(worksheet, totals, null, skipHeader, currentRow);
          this.applyMasks(worksheet, headers, totals.length, currentRow - 1);
        }

        worksheet['!cols'] = this.getColsWidth(report);
      }

      for (const idx in this.itemsSpot) {
        const data = this.itemsSpot[idx];
        const headers = this.$refs.reportSpot[idx].filteredHeaders;
        const report = this.getReportJson(data.items, headers, true);

        currentRow += 2;
        worksheet[`A${currentRow + 1}`] = { v: `Leite Spot - ${data.dairyName}` };
        currentRow += 1;

        this.XLSX.utils.sheet_add_json(worksheet, report, { origin: currentRow });
        this.applyStyles(worksheet, report, null, false, currentRow);
        this.applyMasks(worksheet, headers, report.length, currentRow);

        {
          const totals = this.getReportJson([data.totals], headers, true);

          currentRow += report.length + 1;
          const skipHeader = true;

          this.XLSX.utils.sheet_add_json(worksheet, totals, { origin: currentRow, skipHeader });
          this.applyStyles(worksheet, totals, null, skipHeader, currentRow);
          this.applyMasks(worksheet, headers, totals.length, currentRow - 1);
        }
      }

      if (this.totalsSpot) {
        const headers = this.$refs.reportSpot[0].filteredHeaders;
        const totals = this.getReportJson([this.totalsSpot], headers, true);

        currentRow += 2;
        const skipHeader = true;

        this.XLSX.utils.sheet_add_json(worksheet, totals, { origin: currentRow, skipHeader });
        this.applyStyles(worksheet, totals, null, skipHeader, currentRow);
        this.applyMasks(worksheet, headers, totals.length, currentRow - 1);
      }

      this.XLSX.utils.book_append_sheet(workbook, worksheet, _.truncate(title, { length: 31 }));
      this.XLSX.writeFile(workbook, `${title}.xlsx`);
    },

    formatDifference(value) {
      const formatted = this.formatCurrency(Math.abs(value), 4);

      if (value > 0) {
        return `(+) ${formatted}`;
      }

      if (value < 0) {
        return `(-) ${formatted}`;
      }

      return formatted;
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format('MMM/YY')),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value || 0),
    formatCurrency: (value, decimals = 2) => 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: decimals }).format(value || 0),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  },
};
</script>
