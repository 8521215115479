<template>
  <v-card
    color="transparent"
    dark
    class="region-resume"
  >
    <v-card-title
      class="text-subtitle-2 pt-2 pb-2"
      style="background: rgba(0, 0, 0, 0.5); height: 52px;"
    >
      <slot name="title">
        Resumo da região
      </slot>

      <v-spacer />

      <slot name="actions" />
    </v-card-title>

    <v-card-text
      class="pa-0"
      style="background: rgba(0, 0, 0, 0.3);"
    >
      <v-row
        class="text-body-2 pt-1 pb-1 ma-0"
        align="center"
        justify="center"
      >
        <slot name="append" />

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ vol }} L <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Última coleta</span>
          </v-tooltip>
        </v-col>

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                CCS: {{ ccs }} <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Aritmética geral (Geométrica do mês)</span>
          </v-tooltip>
        </v-col>

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                CPP: {{ cbt }} <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Aritmética geral (Geométrica do mês)</span>
          </v-tooltip>
        </v-col>
        
        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          {{ producers.length }} Produtores
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.region-resume > .v-card__text {
  color: white !important;
}
</style>

<script>
import _ from "lodash";

export default {

  props: {
    producers: Array,
    textLeft: Boolean,
  },

  computed: {

    /**
     * Volume total de leite (Em Litros)
     */
    vol() {
      return _.sumBy(this.producers, "vol");
    },

    /**
     * Média aritmética do CCS
     */
    ccs() {
      if (_.isEmpty(this.producers)) {
        return 0;
      }

      const producers = this.producers.filter(producer => producer.ccs);

      if (_.isEmpty(producers)) {
        return 0;
      }

      return _.meanBy(producers, "ccs").toFixed(0);
    },

    /**
     * Média aritmética do CBT
     */
    cbt() {
      if (_.isEmpty(this.producers)) {
        return 0;
      }

      const producers = this.producers.filter(producer => producer.cbt);

      if (_.isEmpty(producers)) {
        return 0;
      }

      return _.meanBy(producers, "cbt").toFixed(0);
    },

    textClass() {
      if (this.textLeft) {
        return {
          "text-left": true,
        };
      }

      return {};
    },
  },
};
</script>
