<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    >
      <v-flex
        xs12
        class="text-xs-center"
      >
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>

      <v-flex xs12>
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab href="#tab-menu">
            Menus
          </v-tab>
          <v-tab href="#tab-permissao">
            Permissões
          </v-tab>
          <v-tab href="#tab-recurso">
            Recursos
          </v-tab>
          <v-tab href="#tab-modulo">
            Módulos
          </v-tab>
          <v-tab href="#tab-laticionio-modulo">
            Latícinios
          </v-tab>
          <v-tab href="#tab-perfil">
            Perfil usuario
          </v-tab>
          <v-tab href="#tab-web">
            Web
          </v-tab>
          <v-tab href="#tab-app">
            Aplicativo
          </v-tab>
          <v-tab href="#tab-labs">
            Laboratórios
          </v-tab>
          <v-tab href="#tab-infra">
            Infraestrutura
          </v-tab>
          <!-- <v-tab href="#tab-menu-modulo">
            Menus por Módulo
          </v-tab> -->
          <v-tab-item value="tab-menu">
            <menu-milkup />
          </v-tab-item>
          <v-tab-item value="tab-permissao">
            <permission />
          </v-tab-item>
          <v-tab-item value="tab-recurso">
            <resource />
          </v-tab-item>
          <v-tab-item value="tab-modulo">
            <module />
          </v-tab-item>
          <v-tab-item value="tab-laticionio-modulo">
            <laticinio-module />
          </v-tab-item>
          <v-tab-item value="tab-perfil">
            <profile />
          </v-tab-item>
          <v-tab-item value="tab-app">
            <app-versions-view />
          </v-tab-item>
          <v-tab-item value="tab-web">
            <web-settings />
          </v-tab-item>
          <v-tab-item value="tab-labs">
            <labs
              v-if="tab === 'tab-labs'"
            />
          </v-tab-item>
          <v-tab-item value="tab-infra">
            <infra
              v-if="tab === 'tab-infra'"
            />
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-container>
  </v-container>
</template>
<style lang="scss">
.settings > .v-card__text, .settings > .v-card__subtitle {
  background: rgba(0, 0, 0, 0.5);
}
</style>

<script>

import Module from "@/Domains/SettingsMilkup/Views/Module.vue";
import MenuMilkup from "@/Domains/SettingsMilkup/Views/Menu.vue";
import Permission from "@/Domains/SettingsMilkup/Views/Permission.vue";
import Resource from "@/Domains/SettingsMilkup/Views/Resource.vue";
import LaticinioModule from "@/Domains/SettingsMilkup/Views/LaticinioModule.vue";
import Profile from "@/Domains/SettingsMilkup/Views/Profile.vue";
import AppVersionsView from "@/Domains/SettingsMilkup/Views/AppVersionsView.vue";
import Labs from "@/Domains/SettingsMilkup/Labs/Views/Labs.vue";
import WebSettings from "@/Domains/SettingsMilkup/Views/WebSettings.vue";
import Infra from "@/Domains/SettingsMilkup/Infra/Views/Infra.vue";

export default {
  name: "settings-milkup",

  components: {
    Module,
    MenuMilkup,
    Permission,
    Resource,
    LaticinioModule,
    Profile,
    AppVersionsView,
    Labs,
    WebSettings,
    Infra
  },

  data() {
    return {
      tab: "tab-menu",
    };
  },
};
</script>
