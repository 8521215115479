<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="report-card"
          color="transparent"
          dark
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="4"
              md-4
              sm-4
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter.search"
            show-expand
            single-expand
            dark
            class="elevation-1 report-table"
          >
            <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
              <v-btn
                v-if="item.versions.length > 1"
                icon
                link
                transition="fab-transition"
                @click="expand(!isExpanded)"
              >
                <v-icon>
                  {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                </v-icon>
              </v-btn>
            </template>
            <template #expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="pa-0 text-center"
              >
                <v-data-table
                  :headers="versionsHeaders"
                  :items="item.versions"
                  :items-per-page="-1"
                  dense
                  hide-default-footer
                  item-key="id_coletas_amostra"
                  class="elevation-1 ma-3"
                >
                  <template #[`item.date`]="{ value }">
                    {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
                  </template>
                </v-data-table>
              </td>
            </template>
            <template #[`item.date`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
            </template>
            <template #[`item.version`]="{ value }">
              <v-chip
                dark
              >
                {{ value }}
              </v-chip>
            </template>
            <template #[`item.newVersion`]="{ item }">
              <v-btn
                color="blue"
                small
                @click="addVersion(item)"
              >
                <v-icon left>
                  add
                </v-icon>
                Nova Versão
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="form.show"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>{{ form.title }}</v-card-title>
        <v-card-subtitle>Nova Versão</v-card-subtitle>
        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="form.version"
              label="Versão"
              filled
              :rules="[v => !!v || 'Campo obrigatório']"
            />
            <v-text-field
              v-model="form.customTitle"
              label="Título Personalizado"
              filled
              :rules="[v => !!v || 'Campo obrigatório']"
            />
            <v-textarea
              v-model="form.extra"
              label="Extra"
              filled
              auto-grow
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="form.show = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';

export default {

  data() {
    return {
      filter: {
        search: '',
      },

      headers: [
        { text: 'Formulário/Relatório', value: 'title' },
        { text: 'Titulo Personalizado', value: 'customTitle' },
        { text: 'Data da versão', value: 'date' },
        { text: 'Versão', value: 'version' },
        { text: 'Extra', value: 'extra' },
        { text: '', align: 'right', value: 'newVersion', width: 250, sortable: false },
      ],

      versionsHeaders: [
        { text: 'Título Personalizado', value: 'customTitle' },
        { text: 'Data da versão', value: 'date' },
        { text: 'Versão', value: 'version' },
        { text: 'Extra', value: 'extra' },
      ],

      items: [],

      form: {
        show: false,
        form: null,
        version: null,
        extra: null,
      },
    };
  },

  mounted() {
    this.loadForms();
  },

  methods: {
    async loadForms() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('/relatorios/formulariosRastreabilidade');

        this.items = data.map(item => ({
          id: item.id_rastreabilidade || item.id_formulario,
          form: item.formulario,
          title: item.titulo,
          customTitle: item.titulo_customizado,
          date: item.data_versao,
          version: item.versao,
          extra: item.extra,
          versions: item.versoes.map(item => ({
            id: item.id_rastreabilidade || item.id_formulario,
            customTitle: item.titulo_customizado,
            date: item.data_versao,
            version: item.versao,
            extra: item.extra,
          })),
        }));
      } catch (err) {
        this.$snotify.error('Erro ao carregar formulários', 'Atenção');
        console.warn(err)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    addVersion(item) {
      this.form = {
        show: true,
        form: item.form,
        title: item.title,
        customTitle: item.customTitle || null,
        version: item.version || null,
        extra: item.extra || null,
      };
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.post('/relatorios/salvaVersaoFormulario', {
          formulario: this.form.form,
          titulo: this.form.customTitle,
          versao: this.form.version,
          extra: this.form.extra,
        });

        this.$snotify.success('Versão inserida com sucesso', 'Sucesso');
      } catch (err) {
        this.$snotify.error('Erro ao inserir versão', 'Atenção');
        console.warn(err)
      } finally {
        this.form.show = false;
        this.$root.$progressBar.hide();
        this.loadForms();
      }
    },

    formatDate: (value, format) => (!value ? '-' : moment(value).format(format)),
  },
};
</script>
