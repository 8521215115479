<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      max-width="630px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            {{ type == 'unidade' ? 'Transferência entre unidades' : 'Spot' }}
          </span>
        </v-card-title>

        <v-card-text
          class="px-0"
        >
          <v-stepper
            v-model="step"
            alt-labels
            class="elevation-0"
          >
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :complete="step > 1"
              >
                Silos
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                step="2"
                :complete="step > 2"
              >
                Carregamento
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form
                  ref="form1"
                  lazy-validation
                  @submit.prevent="nextStep"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="pb-0"
                    >
                      <itinerary-select
                        v-if="show"
                        v-model="form.itinerary"
                        :type="type == 'unidade' ? 'transferencia' : 'spot'"
                        :spot-type="!itineraryId ? 'SAIDA' : null"
                        label="Itinerário *"
                        :disabled="!!itineraryId"
                        placeholder=" "
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        @input="onItinerary"
                      />
                    </v-col>
                    <v-col
                      v-show="!!form.itinerary.rawProductId"
                      cols="12"
                      class="pb-0"
                    >
                      <raw-material-autocomplete
                        :value="form.itinerary.rawProductId"
                        label="Matéria Prima *"
                        placeholder=" "
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="5"
                      class="pb-0"
                    >
                      <v-select
                        v-model="form.silos"
                        :items="availableSilos"
                        label="Silos"
                        item-value="id"
                        item-text="label"
                        multiple
                        return-object
                        placeholder=" "
                        prepend-inner-icon="panorama_vertical_select"
                        :rules="[v => (v && v.length > 0) || 'Campo obrigatório!']"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content @click.stop="onSiloSelect(item)">
                            <v-list-item-title>
                              {{ item.label }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="item.rawProduct.id">
                              {{ item.rawProduct.name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>

                        <template #selection="{ item, index }">
                          <v-chip
                            v-if="index < 2"
                            small
                          >
                            <span>{{ item.label }}</span>
                          </v-chip>
                          <span
                            v-if="index === 2"
                            class="grey--text text-caption"
                          >
                            (+{{ form.silos.length - 2 }})
                          </span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="4"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="form.transferredAt.date"
                        label="Data de Baixa *"
                        type="date"
                      />
                    </v-col>
                    <v-col
                      cols="3"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="form.transferredAt.time"
                        label="Hora de Baixa *"
                        type="time"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    v-for="(silo, idx) in form.silos"
                    :key="silo.id"
                  >
                    <v-col
                      cols="5"
                      class="pb-0"
                    >
                      <v-text-field
                        :value="silo.label"
                        label="Silo"
                        prepend-inner-icon="panorama_vertical_select"
                        disabled
                      />
                    </v-col>
                    <v-col class="flex-grow-1 flex-shrink-0 pb-0">
                      <v-text-field
                        v-model="silo.volume"
                        label="Volume (L) *"
                        :rules="getTransferRules(silo)"
                        type="number"
                        placeholder=" "
                        prepend-inner-icon="opacity"
                        @keypress="disableDotAndComma"
                      />
                    </v-col>
                    <div class="pb-5 pr-3 align-self-end">
                      <v-btn
                        icon
                        @click.stop="deleteSilo(idx)"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-form
                  ref="form2"
                  lazy-validation
                  @submit.prevent="saveTransferring"
                >
                  <v-row>
                    <v-col
                      v-for="(tank, idx) in form.tanks"
                      :key="idx"
                      cols="12"
                      md="4"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="tank.volume"
                        :label="tank.label"
                        prepend-inner-icon="opacity"
                        placeholder=" "
                        required
                        type="number"
                        :disabled="idx == (form.tanks.length - 1)"
                        :rules="getRules(idx)"
                        @keypress="disableDotAndComma"
                        @input="onChangeVolume(idx)"
                      />
                    </v-col>
                    <v-col
                      v-if="!hasRegisteredTanks"
                      cols="12"
                      md="4"
                      class="pb-0 d-flex justify-center"
                    >
                      <v-btn
                        v-if="form.tanks.length > 1"
                        icon
                        color="red"
                        @click="removeTank"
                      >
                        <v-icon>remove</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="form.tanks.length < 7"
                        icon
                        color="blue"
                        @click="addTank"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <small class="mx-4">* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="step == 1"
            color="grey darken-1"
            outlined
            @click="() => show = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-else
            outlined
            @click="() => step--"
          >
            <v-icon
              left
              dark
            >
              chevron_left
            </v-icon>
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="step == 1"
            color="green"
            outlined
            @click="nextStep"
          >
            Continuar
            <v-icon
              right
              dark
            >
              chevron_right
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            outlined
            @click="saveTransferring"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay
        v-model="loading"
        absolute
      >
        Carregando...
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { isEmpty } from "lodash";

import ItinerarySelect from "@/Domains/Itineraries/Components/OpenItinerarySelect.vue";
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';

export default {
  components: {
    ItinerarySelect,
    RawMaterialAutocomplete,
  },

  props: {
    value: {
      type: Boolean,
    },
    silo: {
      type: Object,
    },
    itineraryId: {
      type: String,
    },
    vol: {
      type: Number,
    },
    type: {
      type: String,
      default: 'unidade',
      validator: (value) => ['unidade', 'spot'].includes(value)
    },
  },

  data() {
    return {
      loading: false,

      hasRegisteredTanks: true,
      step: 1,

      rawMaterials: [],

      silos: [],

      form: {
        itinerary: {},

        transferredAt: {
          date: moment().format('YYYY-MM-DD'),
          time: moment().format('HH:mm'),
        },

        silos: [],
        tanks: [],
      },
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.reset();
        }
        this.$emit("input", value);
      },
    },

    volumeTotal() {
      return this.form.silos.reduce((acc, cur) => acc + (parseInt(cur.volume) || 0), 0);
    },

    measurementParam() {
      return this.$store.state.settings.plataforma ? this.$store.state.settings.plataforma.medicao_descarga_plataforma : 'balanca';
    },

    availableSilos() {
      let { rawProductId } = this.form.itinerary;

      // Tratamento para caso o itinerário não possua ID de matéria-prima, não permitir o usuário escolher um silo incorreto
      if (isEmpty(this.silos) || isEmpty(rawProductId)) {
        return [];
      }

      // Busca a partir da lista de matérias-primas o ID da máteria prima pai (Agrupador)
      const rawMaterial = this.rawMaterials.find(({ id }) => id === rawProductId) || {};
      const parentRawMaterial = this.rawMaterials.find(({ id }) => id === rawMaterial.rawMaterialGroupId) || {};

      return this.silos.filter(silo => {
        if (!silo.rawProduct.id) {
          return true;
        }

        return parentRawMaterial.id === silo.rawProduct.id || rawMaterial.id === silo.rawProduct.id;
      })
    },
  },

  watch: {
    async show(value) {
      if (!value) {
        return;
      }

      await this.loadSilos();
      await this.loadRawMaterials();

      if (!isEmpty(this.silo)) {
        this.form.silos = [this.silo];
      }

      this.setDefaultItinerary();
    }
  },

  methods: {
    async loadRawMaterials() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipo_materia_prima: 'TODOS'
        } });

        this.rawMaterials = data.map(materiaPrima => {
          return {
            id: materiaPrima.id_item,
            description: materiaPrima.nome,
            type: materiaPrima.tipo,
            rawMaterialGroupId: materiaPrima.id_agrupador_materia_prima,
          }
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os matérias primas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    reset() {
      this.$refs.form1 && this.$refs.form1.resetValidation();
      this.$refs.form2 && this.$refs.form2.resetValidation();
      this.step = 1;
      this.form = {
        itinerary: {},
        transferredAt: {
          date: moment().format('YYYY-MM-DD'),
          time: moment().format('HH:mm'),
        },
        silos: [],
        tanks: [],
      };
      this.silos = this.silos.map(o => ({ ...o, volume: null }));
    },

    setDefaultItinerary() {
      if (this.itineraryId) {
        this.form.itinerary = { id: this.itineraryId };
      }
    },

    async loadSilos() {
      try {
        this.loading = true;

        const { data: { silo } } = await this.$axios.post(`/silo/principal`);

        if (!silo) {
          throw "PHP Error";
        }

        this.silos = silo
          .map(silo => ({
            id: silo.id_silo,
            label: silo.descricao || '',
            vol: parseInt(silo.volume_atual),
            volume: null,
            rawProduct: {
              id: silo.id_materia_prima,
              name: silo.nome_materia_prima,
            },
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

      } catch (e) {
        console.warn(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async saveTransferring() {
      if (!this.$refs.form1.validate()) {
        return;
      }
      if (!this.$refs.form2.validate()) {
        return;
      }

      try {
        this.loading = true;

        const payload = {
          tipo: this.type,
          id_itinerario: this.form.itinerary.id,
          data_baixa: `${this.form.transferredAt.date} ${this.form.transferredAt.time}`,
          silos: this.form.silos,
          volume_total: this.volumeTotal,
          peso_medido: ['vazao', 'medidor', 'vale'].includes(this.measurementParam) ? this.volumeTotal : 0,
          tanques: this.form.tanks.reduce((acc, cur) => (
            [
              ...acc,
              {
                volume: parseFloat(cur.volume),
                capacidade: cur.capacidade > parseFloat(cur.volume) ? cur.capacidade : parseFloat(cur.volume)
              }
            ]
          ), []),
        };

        const { data } = await this.$axios.post(
          `/silo/transferir`,
          payload,
        );

        if (!data.codigo) {
          throw "PHP Error";
        }
        if (data.codigo === 2) {
          this.$snotify.warning(
            data.mensagem,
            "Atenção"
          );
          return false;
        }

        this.$emit('onSiloTransferred', this.form);

        this.show = false;
      } catch (e) {
        console.warn(e);
        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar a transferência!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async onItinerary({ id, tanks, rawProductId }) {
      if (!id) {
        return;
      }

      this.form.itinerary.rawProductId = rawProductId;

      // Se não tiver a quantidade de tanques informada, habilita o botão para informar a quantidade de tanques
      this.hasRegisteredTanks = tanks.length > 0;

      if (tanks.length == 0) {
        // Adiciona pelo menos um tanque
        this.form.tanks = [{
          label: `Tanque 1`,
          capacidade: 0,
          volume: null
        }];
      }
      else {
        this.form.tanks = tanks.map((capacidade, idx) => ({
          label: `Tanque ${idx + 1}`,
          capacidade: parseFloat(capacidade),
          volume: null
        }));
      }
    },

    onSiloSelect(silo) {
      const idx = this.form.silos.findIndex(({ id }) => id === silo.id);

      if (idx === -1) {
        const volume = silo.volume || (this.form.silos.length === 0 ? this.vol : null);
        this.form.silos.push({ ...silo, volume });
      }
      else {
        this.deleteSilo(idx);
      }
    },

    async deleteSilo(idx) {
      this.form.silos.splice(idx, 1);
    },

    nextStep() {
      if (!this.$refs.form1.validate()) {
        return;
      }
      this.step = 2;
      this.updateTanks();
    },

    removeTank() {
      this.form.tanks.splice(-1, 1);
      this.updateTanks();
    },

    addTank() {
      this.form.tanks.push({
        label: `Tanque ${this.form.tanks.length + 1}`,
        capacidade: 0,
        volume: null
      })
      this.updateTanks();
    },

    updateTanks() {
      // Quantidade de tanques
      const noTanks = this.form.tanks.length;
      // Volume total
      const volumeTotal = this.volumeTotal;
      // Volume de cada tanque
      const volume = Math.floor(volumeTotal / noTanks);
      // Diferença do rateio de cada tanque
      const rest = volumeTotal - (volume * noTanks);
      // Na último tanque deve ser adicionado a diferença
      const lastVolume = parseInt(volume + rest);

      for (let i = 0; i < noTanks; i++) {
        this.form.tanks[i].volume =  i < (noTanks - 1) ? volume : lastVolume;
      }
    },

    /**
     * Verifica o volume do tanque atual e rateia o resto entre os tanques posteriores
     */
    onChangeVolume(idx) {
      if (idx == this.form.tanks.length - 1) {
        return;
      }
      // Índice para dividir este tanque dos posteriores
      const splitTank = idx + 1;
      // Quantidade de tanques
      const noTanks = this.form.tanks.length;
      // Volume total
      const volumeTotal = this.volumeTotal;
      // Soma o volume deste tanque com os tanques anteriores
      const curVolume = this.form.tanks.slice(0, splitTank).reduce((acc, cur) => acc + (parseInt(cur.volume) || 0), 0);
      // A diferença será rateada nos tanques posteriores
      const restVol = volumeTotal - curVolume;
      // Quantidade de tanques restantes
      const restNoTanks = noTanks - splitTank;
      // Volume de cado tanque (para baixo)
      const volume = Math.floor(restVol / restNoTanks);
      // Diferença dos tanques
      const rest = restVol - (volume * restNoTanks);
      // Na último tanque deve ser adicionado a diferença
      const lastVolume = parseInt(volume + rest);

      for (let i = splitTank; i < noTanks; i++) {
        this.form.tanks.splice(i, 1, {
          ...this.form.tanks[i],
          volume: i < (noTanks - 1) ? volume : lastVolume
        })
      }
    },

    /**
     * Retorna as regras de validações de cada tanque
     */
    getRules(idx) {
      // Volume total
      const volumeTotal = this.volumeTotal;
      // Soma o volume deste tanque com os tanques anteriores
      const curVolume = this.form.tanks.slice(0, idx + 1).reduce((acc, cur) => acc + (parseInt(cur.volume) || 0), 0);
      // Volume do tanque
      const volume = parseInt(this.form.tanks[idx].volume) || 0;
      return [
        () => volume >= 0 || 'O volume não pode ser negativo',
        () => curVolume <= volumeTotal || 'O volume deve ser menor ou igual ao volume total',
      ]
    },

    getTransferRules(silo) {
      const rules = [
        v => (v && v > 0) || 'Campo obrigatório!',
      ]

      if (this.$store.state.settings.gerais.validar_saldo_silo) {
        rules.push(v => v <= silo.vol || 'Volume indisponível no silo')
      }

      return rules
    },

    disableDotAndComma(e) {
      [44, 46, 101].includes(e.charCode) && e.preventDefault();
    },

  },

}
</script>

