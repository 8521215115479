<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color, overflow: 'hidden'}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        local_shipping
      </v-icon>
      <span class="title font-weight-light">
        Taxa de Ocupação por Rota
      </span>
    </v-card-title>
    <v-tabs
      v-model="tab"
      align-with-title
      background-color="transparent"
      class="transparent"
      dark
    >
      <v-tabs-slider color="cyan" />

      <v-tab>Gráfico</v-tab>
      <v-tab>Relatório</v-tab>

      <v-tab-item>
        <v-chart
          :options="options"
          :style="{width: '100%', height: `${height} !important`}"
          autoresize
        />
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="routes"
          dark
          dense
          class="elevation-1 report-table"
          hide-default-footer
          disable-pagination
        >
          <template #[`item.min`]="{ value }">
            {{ formatNumber(value) }} %
          </template>
          <template #[`item.max`]="{ value }">
            {{ formatNumber(value) }} %
          </template>
          <template #[`item.avg`]="{ value }">
            {{ formatNumber(value) }} %
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    color: {
      type: String,
      default: '#26c6da'
    },
    height: {
      type: String,
      default: '400px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
  },

  data() {
    return {
      loading: false,
      tab: null,

      headers: [
        { text: 'Rota', value: 'route' },
        { text: 'Mínima (%)', value: 'min', align: 'center' },
        { text: 'Máxima (%)', value: 'max', align: 'center' },
        { text: 'Média (%)', value: 'avg', align: 'center' },
      ],
      routes: [],
      average: 0
    }
  },

  computed: {
    options() {
      const label = {
        show: true,
        lineHeight: 17,
        height: 17,
        backgroundColor: '#6a7985',
        color: '#fff',
        borderRadius: 5,
        position: 'top',
        formatter: ({ value }) => `  ${this.formatNumber(value)}  `
      };

      return {
        color: ['rgba(229, 57, 53, 0.7)', 'rgba(38, 198, 218, 0.7)', 'rgba(255, 255, 0, 0.7)'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          formatter: (params) => {
            return [
              params[0].name,
              ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)} %</b>`),
            ].join('<br>')
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: this.routes.map(route => route.route),
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: 'rgba(255, 255, 255, 0.8)'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff',
          }
        },
        series: [
          {
            name: 'Mínima',
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.routes.map((route) => route.min),
            label
          },
          {
            name: 'Máxima',
            type: 'bar',
            areaStyle: {},
            showSymbol: true,
            emphasis: {
              focus: 'series'
            },
            data: this.routes.map((route) => route.max),
            label
          },
          {
            name: 'Média',
            type: 'line',
            lineStyle: { width: 2 },
            showSymbol: false,
            smooth: this.smooth,
            emphasis: { focus: 'series' },
            data: this.routes.map((item) => item.avg),
          }
        ]
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(`/relatorios/taxaOcupacaoPorRota`, {
          data_inicio: startDate, data_fim: endDate
        });

        this.routes = data.map(item => ({
          id: item.id_rota,
          route: item.nome_rota,

          min: item.taxa_ocupacao_min,
          max: item.taxa_ocupacao_max,
          avg: item.taxa_ocupacao_media,
        }));

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de Opcupação por Rotas!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
