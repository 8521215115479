<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Tipos de Análises de Produtos
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-select
                  v-model="filters.products"
                  :items="products"
                  label="Produto"
                  item-value="id"
                  item-text="description"
                  multiple
                  clearable
                  hide-details
                  dark
                  filled
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-select
                  v-model="filters.type"
                  :items="types"
                  label="Natureza"
                  clearable
                  hide-details
                  dark
                  filled
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  filled
                />
              </v-col>
            </v-row>
          </v-card-title>

          <data-table
            ref="report"
            :headers="headers"
            :items="filteredItems"
            dark
            @click:row="edit"
          >
            <template #[`item.analysis.type`]="{ value }">
              <v-chip
                small
                :color="getColorType(value)"
                dark
              >
                {{ getType(value) }}
              </v-chip>
            </template>

            <template #[`item.periodicity`]="{ value }">
              <span>
                {{ getPeriodicity(value) }}
              </span>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template
                  #activator="{ on }"
                >
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <product-analysis-type-dialog
      v-model="dialog.show"
      :edit-id="dialog.id"
      @save="load"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="add()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import ProductAnalysisTypeDialog from '@/Domains/Registrations/Analysis/Product/Components/ProductAnalysisTypeDialog.vue'

export default {

  components: {
    ProductAnalysisTypeDialog,
  },

  data() {
    return {
      filters: {
        products: [],
        type: null,
        search: '',
      },

      headers: [
        { text: 'Produto', value: 'item.description' },
        { text: 'Natureza', value: 'analysis.type', formatter: value => this.getType(value) },
        { text: 'Análise', value: 'analysis.description' },
        { text: 'Períodicidade', value: 'periodicity', formatter: value => this.getPeriodicity(value) },
        { text: 'Amostras período', value: 'quantity' },
        { text: '', altText: 'Ações', value: 'action', width: 30, sortable: false }
      ],

      items: [],

      types: [
        { value: 'FISICO-QUMICA', text: 'Físico-química' },
        { value: 'MICROBIOLOGICA', text: 'Microbiológica' },
      ],

      products: [],

      dialog: {
        show: false,
        id: null,
      },
    };
  },

  computed: {
    filteredItems() {
      if (this.filters.products.length === 0 && !this.filters.type && !this.filters.search) {
        return this.items;
      }

      const products = this.filters.products;
      const type = this.filters.type;
      const search = String(this.filters.search).toUpperCase().trim();

      return this.items.filter(item => {
        const hasProducts = products.length === 0 || products.includes(item.item.id);
        const hasType = !type || item.analysis.type === type;
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasProducts && hasType && hasSearch;
      });
    },
  },

  created() {
    this.getProducts();
    this.load();
  },

  methods: {
    async load() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/registrations/analysis/product`);

        this.items = data.map(item => ({
          id: item.id_produto_analise,
          item: {
            id: item.produto?.id_item,
            name: item.produto?.nome,
            code: item.produto?.cod_item,
            description: item.produto?.cod_item ? `${item.produto?.cod_item} - ${item.produto?.nome}` : item.produto?.nome,
          },
          analysis: {
            id: item.analise?.id_tipo_analise,
            description: item.analise?.descricao,
            type: item.analise?.natureza,
          },
          periodicity: item.periodicidade,
          quantity: item.quantidade_periodo,
        }));
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.dialog.id = null;
      this.dialog.show = true;
    },

    edit(item) {
      this.dialog.id = item.id;
      this.dialog.show = true;
    },

    async remove(id) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.delete(`/registrations/analysis/product/${id}`);

        this.load();
        this.$snotify.success('Item excluído com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getProducts() {
      try {
        const { data } = await this.$axios.get(`/item`, { params: {
          tipos: ['PRODUTO_ACABADO', 'INSUMO_PRODUCAO', 'MATERIA_PRIMA']
        } });

        this.products = data
          .map(item => ({
            id: item.id_item,
            name: item.nome,
            code: item.cod_item,
            description: item.cod_item ? `${item.cod_item} - ${item.nome}` : item.nome,
          }));
      } catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os produtos!', 'Atenção');
      }
    },

    getReportTitle() {
      return `Tipos de Análises de Produtos`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    getType(value) {
      const types = {
        'FISICO-QUMICA': 'Físico-química',
        'MICROBIOLOGICA': 'Microbiológica',
      }
      return types[value] || '-'
    },

    getColorType(value) {
      const types = {
        'FISICO-QUMICA': 'orange',
        'MICROBIOLOGICA': 'yellow darken-1',
      }
      return types[value] || 'grey'
    },

    getPeriodicity(value) {
      const types = {
        'ANUAL': 'Anual',
        'MENSAL': 'Mensal',
      }
      return types[value] || value
    },
  },
};
</script>
