<template>
  <v-card
    color="transparent"
    dark
    class="rota-resumo"
  >
    <v-card-title
      class="text-subtitle-2 pt-2 pb-2"
      style="background: rgba(0, 0, 0, 0.5);"
    >
      <slot name="title">
        Resumo da rota
      </slot>

      <v-spacer />

      <slot name="actions" />
    </v-card-title>

    <v-card-text
      class="pa-0"
      style="background: rgba(0, 0, 0, 0.3);"
    >
      <v-row
        class="text-body-2 pt-1 pb-1 ma-0"
        align="center"
        justify="center"
      >
        <slot name="append" />

        <v-col
          v-if="!editable"
          :cols="textLeft ? 12 : null"
          :md12="textLeft"
          :class="textClass"
        >
          <v-tooltip
            top
            attach
          >
            <template #activator="{ on }">
              <span>{{ distance.toFixed(1) }} Km</span>
              <span
                class="pl-1"
                v-on="on"
              >
                <v-icon
                  v-if="maps.needsUpdate"
                  small
                  v-text="`error_outline`"
                />
              </span>
            </template>

            <span>Esta informação pode estar desatualizada!</span>
          </v-tooltip>
        </v-col>

        <v-col
          v-if="editable"
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <masked-input
            v-model.number="distance"
            :mask="NumberMask"
            class="mt-0"
            hide-details
            dense
            return-unmasked
            currency
          >
            <template #prepend-inner>
              <v-icon dense>
                map
              </v-icon>
            </template>
          </masked-input>
        </v-col>

        <v-col
          v-if="!editable"
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip
            top
            attach
          >
            <template #activator="{ on }">
              <span>{{ duration }} h</span>
              <span
                class="pl-1"
                v-on="on"
              >
                <v-icon
                  v-if="maps.needsUpdate"
                  small
                  v-text="`error_outline`"
                />
              </span>
            </template>

            <span>Esta informação pode estar desatualizada!</span>
          </v-tooltip>
        </v-col>

        <v-col
          v-if="editable"
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <masked-input
            v-model="duration"
            :mask="TimeMask"
            class="mt-0"
            hide-details
            dense
          >
            <template #prepend-inner>
              <v-icon dense>
                alarm
              </v-icon>
            </template>
          </masked-input>
        </v-col>

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip
            top
            attach
          >
            <template #activator="{ on }">
              <span v-on="on">
                {{ vol }} L / {{ lastItinerary.vol || 0 }} L <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Média das últimas coletas / Ultimo volume coletado</span>
          </v-tooltip>
        </v-col>

        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          {{ literPerDistance }} L/Km
        </v-col>
        <v-col
          v-if="costPerLiterAverage > 0"
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          {{ costPerLiterAverage }} R$/L
        </v-col>
        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip
            top
            attach
          >
            <template #activator="{ on }">
              <span v-on="on">
                CCS: {{ ccs }} <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Aritmética geral (Geométrica do mês)</span>
          </v-tooltip>
        </v-col>
        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <v-tooltip
            top
            attach
          >
            <template #activator="{ on }">
              <span v-on="on">
                CPP: {{ cbt }} <v-icon small>help_outline</v-icon>
              </span>
            </template>

            <span>Aritmética geral (Geométrica do mês)</span>
          </v-tooltip>
        </v-col>
        <v-col
          :cols="textLeft ? 12 : null"
          :class="textClass"
        >
          <span v-if="totalProducers.points === totalProducers.general">
            {{ totalProducers.points }} Produtores
          </span>
          <template v-else>
            <span>
              {{ totalProducers.points }} Propriedades
            </span>

            <v-tooltip
              top
              attach
            >
              <template #activator="{ on }">
                <span
                  class="ml-8"
                  v-on="on"
                >
                  {{ totalProducers.general }} Total
                </span>
              </template>

              <span class="d-flex flex-column">
                <span>{{ totalProducers.groups }} Grupos</span>
                <span>{{ totalProducers.producers }} Produtores</span>
              </span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.rota-resumo > .v-card__text {
  color: white !important;
}
</style>

<script>
import moment from "moment";
import _ from "lodash";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import IMask from "imask";

export default {

  components: {
    MaskedInput,
  },

  props: {
    producers: {
      type: Array,
      default: () => ([]),
    },
    equipments: {
      type: Array,
      default: () => ([]),
    },

    params: {
      type: Object,
      default: () => ({}),
    },
    stats: {
      type: Object,
      default: () => ({}),
    },
    maps: {
      type: Object,
      default: () => ({}),
    },
    textLeft: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
    },
    lastItinerary: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      // Custo por litro gerado ao alterar o equipamento, parametro, distância, volume
      costPerLiterAverage: 0,

      TimeMask: {
        mask: 'HH:MM',
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            placeholderChar: 'HH',
            from: 0,
            to: 23,
            maxLength: 2,
          },
          MM: {
            mask: IMask.MaskedRange,
            placeholderChar: 'MM',
            from: 0,
            to: 59,
            maxLength: 2,
          },
        },
      },

      // Mascara usada nos demais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 1,
            thousandsSeparator: '.',
            min: -999999,
            max: 999999,
          },
        },
      },

    };
  },

  computed: {
    /**
     * Produtores visíveis da lista
     */
    visibleProducers() {
      return this.producers.filter(producer => !producer.remove && producer.type !== "PARADA");
    },

    totalProducers() {
      const points = this.visibleProducers.filter(producer => !producer.groupingId).length;
      const groups = this.visibleProducers.filter(producer => producer.grouping?.length > 0).length;
      const general = this.visibleProducers.length;
      const producers = general - groups;

      return {
        points,
        groups,
        producers,
        general,
      };
    },

    /**
     * Distância média da rota
     */
    distance: {
      get() {
        return (this.stats.distance || 0);
      },
      set(newValue) {
        return this.$emit('update:stats', {
          ...this.stats,
          distance: newValue,
        });
      },
    },

    /**
     * Volume total de leite da rota (Em Litros)
     * O Volume é carregado no componente pai pois é usado em mais de um componente
     */
    vol() {
      return _.sumBy(this.visibleProducers, "vol");
    },

    literPerDistance() {
      if (!this.distance || !this.vol) {
        return 0;
      }

      return (this.vol / this.distance).toFixed(1);
    },

    /**
     * Duração prevista da rota
     */
    duration: {
      get() {
        if (_.isEmpty(this.stats.duration) || this.stats.duration === "00") {
          return null;
        }

        return this.stats.duration;
      },
      set(newValue) {
        return this.$emit('update:stats', {
          ...this.stats,
          duration: newValue,
        });
      },
    },

    /**
     * Média aritmética do CCS
     */
    ccs() {
      if (_.isEmpty(this.visibleProducers)) {
        return 0;
      }

      const producers = this.visibleProducers.filter(producer => producer.ccs);

      if (_.isEmpty(producers)) {
        return 0;
      }

      return _.meanBy(producers, "ccs").toFixed(0);
    },

    /**
     * Média aritmética do CBT
     */
    cbt() {
      if (_.isEmpty(this.producers)) {
        return 0;
      }

      const producers = this.producers.filter(producer => producer.cbt);

      if (_.isEmpty(producers)) {
        return 0;
      }

      return _.meanBy(producers, "cbt").toFixed(0);
    },

    textClass() {
      if (this.textLeft) {
        return {
          "text-left": true,
        };
      }

      return {};
    },
  },

  watch: {

    equipments: {
      deep: true,

      handler() {
        return this.generateCostPerLiterAverage();
      },
    },

    params: {
      deep: true,

      handler() {
        return this.generateCostPerLiterAverage();
      },
    },

    stats: {
      deep: true,

      handler() {
        return this.generateCostPerLiterAverage();
      },
    },

    vol: {
      deep: true,

      handler() {
        return this.generateCostPerLiterAverage();
      },
    },

  },

  mounted() {
    this.generateCostPerLiterAverage();
  },

  methods: {

    /**
     * Custo de combustível por litro coletado
     */
    generateCostPerLiterAverage() {
      const costsByVehicle = this
        .equipments
        .map(eqp => {
          const cost = ((this.stats.distance / eqp.averageFuelConsumption) * this.params.dieselCostPerLitre) / this.vol;

          if (_.isFinite(cost) && cost > 0) {
            return cost;
          }

          return null;
        })
        .filter(eqp => eqp);

      if (_.isEmpty(costsByVehicle)) {
        this.costPerLiterAverage = 0;

        return;
      }

      const costs = _.mean(costsByVehicle);

      if (!_.isFinite(costs)) {
        this.costPerLiterAverage = 0;

        return;
      }

      this.costPerLiterAverage = costs.toFixed(2);
    },

    dateFormat(date, pattern) {
      return moment(date).format(pattern);
    }

  },

};
</script>
