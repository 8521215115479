<template>
  <div
    class="px-2 py-1 pt-1"
  >
    <v-form
      v-if="analysis"
      ref="form"
      lazy-validation
    >
      <v-row
        dense
        class="analysis-grid ma-0"
      >
        <v-col
          v-if="isVisible('temp')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.temp.value"
            name="temp"
            label="Temperatura"
            :rule="analysis.temp.rule"
            :restriction-rule="analysis.temp.restrictionRule"
            :unit="analysis.temp.unit"
            :decimal-places="analysis.temp.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('acidity')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.acidity.value"
            name="acidity"
            label="Acidez"
            :rule="analysis.acidity.rule"
            :restriction-rule="analysis.acidity.restrictionRule"
            :unit="analysis.acidity.unit"
            :decimal-places="analysis.acidity.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('cryoscopy')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.cryoscopy.value"
            name="cryoscopy"
            label="Crioscopia"
            mask="-0.###"
            :rule="analysis.cryoscopy.rule"
            :restriction-rule="analysis.cryoscopy.restrictionRule"
            :unit="analysis.cryoscopy.unit"
            :decimal-places="analysis.cryoscopy.decimals"
            :disabled="disabled"
            use-text-input
            @change="onCryoscopyChange"
            @onEnter="onAnalysisInputEnter"
            @focus="onCryoscopyFocus"
          />
        </v-col>
        <v-col
          v-if="isVisible('waterPercentage')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.waterPercentage.value"
            name="waterPercentage"
            label="Agua (%)"
            :rule="analysis.waterPercentage.rule"
            :restriction-rule="analysis.waterPercentage.restrictionRule"
            :unit="analysis.waterPercentage.unit"
            :decimal-places="analysis.waterPercentage.decimals"
            :disabled="disabled"
            use-input
            @change="onWaterPercentageChange"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('waterLiters')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.waterLiters.value"
            name="waterLiters"
            label="Agua (L)"
            :decimal-places="analysis.waterLiters.decimals"
            :disabled="disabled"
            hint=" "
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('density')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.density.value"
            name="density"
            label="Densidade"
            :rule="analysis.density.rule"
            :restriction-rule="analysis.density.restrictionRule"
            :unit="analysis.density.unit"
            :decimal-places="analysis.density.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('fat')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.fat.value"
            name="fat"
            label="Gordura"
            :rule="analysis.fat.rule"
            :restriction-rule="analysis.fat.restrictionRule"
            :unit="analysis.fat.unit"
            :decimal-places="analysis.fat.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('esd')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.esd.value"
            name="esd"
            label="E.S.D"
            :rule="analysis.esd.rule"
            :restriction-rule="analysis.esd.restrictionRule"
            :unit="analysis.esd.unit"
            :decimal-places="analysis.esd.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('est')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.est.value"
            name="est"
            label="EST"
            :rule="analysis.est.rule"
            :restriction-rule="analysis.est.restrictionRule"
            :unit="analysis.est.unit"
            :decimal-places="analysis.est.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('protein')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.protein.value"
            name="protein"
            label="Proteína"
            :rule="analysis.protein.rule"
            :restriction-rule="analysis.protein.restrictionRule"
            :unit="analysis.protein.unit"
            :decimal-places="analysis.protein.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('ph')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.ph.value"
            name="ph"
            label="pH"
            :rule="analysis.ph.rule"
            :restriction-rule="analysis.ph.restrictionRule"
            :decimal-places="analysis.ph.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('alizarol')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.alizarol.value"
            name="alizarol"
            label="Alizarol"
            :rule="analysis.alizarol.rule"
            :restriction-rule="analysis.alizarol.restrictionRule"
            :unit="analysis.alizarol.unit"
            :decimal-places="analysis.alizarol.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('totalSolids')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.totalSolids.value"
            name="totalSolids"
            label="Sólidos Totais"
            :rule="analysis.totalSolids.rule"
            :restriction-rule="analysis.totalSolids.restrictionRule"
            :unit="analysis.totalSolids.unit"
            :decimal-places="analysis.totalSolids.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('brix')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.brix.value"
            name="brix"
            label="Brix"
            :rule="analysis.brix.rule"
            :restriction-rule="analysis.brix.restrictionRule"
            :unit="analysis.brix.unit"
            :decimal-places="analysis.brix.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('lactose')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.lactose.value"
            name="lactose"
            label="Lactose"
            :rule="analysis.lactose.rule"
            :restriction-rule="analysis.lactose.restrictionRule"
            :unit="analysis.lactose.unit"
            :decimal-places="analysis.lactose.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('reductase')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.reductase.value"
            name="reductase"
            label="Redutase"
            :rule="analysis.reductase.rule"
            :restriction-rule="analysis.reductase.restrictionRule"
            :unit="analysis.reductase.unit"
            :decimal-places="analysis.reductase.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('numero_lacre')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.numero_lacre.value"
            name="numero_lacre"
            label="Número do Lacre"
            :rule="0"
            :decimal-places="analysis.numero_lacre.decimals"
            :disabled="disabled"
            use-input
            autofocus
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('solubilidade')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.solubilidade.value"
            name="solubilidade"
            label="Solubilidade"
            :rule="analysis.solubilidade.rule"
            :restriction-rule="analysis.solubilidade.restrictionRule"
            :unit="analysis.solubilidade.unit"
            :decimal-places="analysis.solubilidade.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('base_seca')"
          :cols="colSize"
          class="px-2 py-1"
        >
          <analysis-input
            v-model="analysis.base_seca.value"
            name="base_seca"
            label="Base Seca (%)"
            :rule="analysis.base_seca.rule"
            :restriction-rule="analysis.base_seca.restrictionRule"
            :unit="analysis.base_seca.unit"
            :decimal-places="analysis.base_seca.decimals"
            :disabled="disabled"
            use-input
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
      </v-row>

      <v-row
        dense
        class="analysis-grid ma-0"
      >
        <v-col
          v-if="isVisible('alizarolQualit')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.alizarolQualit.value"
            name="alizarolQualit"
            label="Alizarol (Qualit.)"
            :rule="analysis.alizarolQualit.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('mastitis')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.mastitis.value"
            name="mastitis"
            label="Mastite"
            :rule="analysis.mastitis.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('sensorial')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.sensorial.value"
            name="sensorial"
            label="Sensorial"
            :rule="analysis.sensorial.rule"
            :hint="analysis.sensorial.hint"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('alcohol')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.alcohol.value"
            name="alcohol"
            label="Álcool Etílico"
            :rule="analysis.alcohol.rule"
            :hint="analysis.alcohol.hint"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('alkalinePhosphatase')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.alkalinePhosphatase.value"
            name="alkalinePhosphatase"
            label="Fosfatase Alcalina"
            :rule="analysis.alkalinePhosphatase.rule"
            :hint="analysis.alkalinePhosphatase.hint"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('clumps')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.clumps.value"
            name="clumps"
            label="Grumos"
            :rule="analysis.clumps.rule"
            :hint="analysis.clumps.hint"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('clots')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.clots.value"
            name="clots"
            label="Coágulos"
            :rule="analysis.clots.rule"
            :hint="analysis.clots.hint"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('peroxidase')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.peroxidase.value"
            name="peroxidase"
            label="Peroxidase"
            :rule="analysis.peroxidase.rule"
            :hint="analysis.peroxidase.hint"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('otherSpecies')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.otherSpecies.value"
            name="otherSpecies"
            label="Outras Espécies"
            :rule="analysis.otherSpecies.rule"
            :hint="analysis.otherSpecies.hint"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="isVisible('antibiotic')"
        class="mt-3 mb-0"
      />

      <v-row
        v-if="isVisible('antibiotic')"
        dense
        class="analysis-grid ma-0 pt-6"
      >
        <span class="group-label text-body-2 grey--text text--darken-1">Antibiótico</span>
        <v-col
          v-if="isVisible('betaLactams')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.betaLactams.value"
            name="betaLactams"
            label="Betalactâmicos"
            :rule="analysis.betaLactams.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('tetracycline')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.tetracycline.value"
            name="tetracycline"
            label="Tetraciclinas"
            :rule="analysis.tetracycline.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('sulphonamide')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.sulphonamide.value"
            name="sulphonamide"
            label="Sulfonamidas"
            :rule="analysis.sulphonamide.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('quinolones')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.quinolones.value"
            name="quinolones"
            label="Quinolonas"
            :rule="analysis.quinolones.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('aminoglykosides')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.aminoglykosides.value"
            name="aminoglykosides"
            label="Aminoglicosídeos"
            :rule="analysis.aminoglykosides.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('macrolides')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.macrolides.value"
            name="macrolides"
            label="Macrolídeos"
            :rule="analysis.macrolides.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('anfenicois')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.anfenicois.value"
            name="anfenicois"
            label="Anfenicóis"
            :rule="analysis.anfenicois.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>

        <v-col
          v-if="isVisible('neomicina')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.neomicina.value"
            name="neomicina"
            label="Neomicina"
            :rule="analysis.neomicina.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('cefalosporina')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.cefalosporina.value"
            name="cefalosporina"
            label="Cefalosporina"
            :rule="analysis.cefalosporina.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="isVisible('neutralising')"
        class="mt-3 mb-0"
      />

      <v-row
        v-if="isVisible('neutralising')"
        dense
        class="analysis-grid ma-0 pt-6"
      >
        <span class="group-label text-body-2 grey--text text--darken-1">Neutralizantes</span>
        <v-col
          v-if="isVisible('sodiumHydroxide')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.sodiumHydroxide.value"
            name="sodiumHydroxide"
            label="Soda Cáustica"
            :rule="analysis.sodiumHydroxide.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('bicarbonateOfSoda')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.bicarbonateOfSoda.value"
            name="bicarbonateOfSoda"
            label="Bicarbonato"
            :rule="analysis.bicarbonateOfSoda.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('alkali')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.alkali.value"
            name="alkali"
            label="Alcalino"
            :rule="analysis.alkali.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="isVisible('restoratives')"
        class="mt-3 mb-0"
      />

      <v-row
        v-if="isVisible('restoratives')"
        dense
        class="analysis-grid ma-0 pt-6"
      >
        <span class="group-label text-body-2 grey--text text--darken-1">Reconstituintes</span>
        <v-col
          v-if="isVisible('sucrose')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.sucrose.value"
            name="sucrose"
            label="Sacarose"
            :rule="analysis.sucrose.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('starch')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.starch.value"
            name="starch"
            label="Amido"
            :rule="analysis.starch.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('chlorides')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.chlorides.value"
            name="chlorides"
            label="Cloretos"
            :rule="analysis.chlorides.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('restoratives')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.chlorine.value"
            name="chlorine"
            label="Cloro/Hipoclorito"
            :rule="analysis.chlorine.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('restoratives')"
          class="pa-2 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.ureia.value"
            name="ureia"
            label="Ureia/Urina"
            :rule="analysis.ureia.rule"
            :hint="analysis.ureia.hint"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="isVisible('conservatives')"
        class="mt-3 mb-0"
      />

      <v-row
        v-if="isVisible('conservatives')"
        dense
        class="analysis-grid ma-0 pt-6"
      >
        <span class="group-label text-body-2 grey--text text--darken-1">Conservantes</span>
        <v-col
          v-if="isVisible('peroxide')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.peroxide.value"
            name="peroxide"
            label="Peróxido de Hidrogênio"
            :rule="analysis.peroxide.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
        <v-col
          v-if="isVisible('formol')"
          class="px-2 py-1 pb-0 pt-0"
          :cols="colSize"
        >
          <analysis-input
            v-model="analysis.formol.value"
            name="formol"
            label="Formaldeído"
            :rule="analysis.formol.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-1"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="hasObservation() && isVisible('observation')"
        class="mt-3 mb-0"
      />

      <v-row
        dense
        class="ma-0"
      >
        <v-col
          v-if="hasObservation() && isVisible('observation')"
          cols="12"
          class="pb-1"
        >
          <v-textarea
            v-model="analysis.observation.value"
            rows="1"
            auto-grow
            dense
            label="Observações"
          />
        </v-col>
      </v-row>

      <v-divider
        v-if="isVisible('others')"
        class="mt-3 mb-0"
      />

      <v-row
        dense
        class="analysis-grid ma-0"
      >
        <v-col
          v-if="isVisible('others')"
          :cols="colSize"
          class="px-2 py-1 pb-0"
        >
          <analysis-input
            v-model="analysis.others.value"
            name="others"
            label="Outros"
            :rule="analysis.others.rule"
            :disabled="disabled"
            use-checkbox
            :alt-checkbox="altCheckbox"
            css-class="mt-0"
            @onEnter="onAnalysisInputEnter"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss">
.analysis-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  // grid-gap: 1em;
  position: relative;

  .group-label {
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

</style>

<script>
import * as _ from "lodash";

import AnalysisInput from "@/Domains/Platform/Unload/Components/AnalysisInput.vue";

export default {

  components: {
    AnalysisInput
  },

  props: {

    value: {
      type: Object,
    },

    vol: {
      type: Number,
    },

    visible: {
      type: Array,
      default: () => ([]),
    },

    problems: {
      type: Array,
      default: () => ([]),
    },

    ignoreErrors: {
      type: Array,
      default: () => ([]),
    },

    ignoreEmpty: {
      type: Array,
      default: () => ([]),
    },

    autoMarkOutOfRange: Boolean,

    singleForm: {
      type: Boolean,
      default: true,
    },

    altCheckbox: {
      type: Boolean,
      default: false,
    },

    validateRequiredFields: {
      type: Boolean,
      default: false,
    },

    rawProduct: {
      type: Object,
      default: () => ({}),
    },

    cryoscopys: {
      type: Array,
      default: () => ([]),
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      analysis: null,
      localCryoscopys: [],
    }
  },

  computed: {
    /**
     * Calcula o EST através da fórmula
     * 0,25 x final da densidade + 1,2 X gordura + 0,26
     */
    formulaEST() {
      if (!this.analysis || !this.analysis.density.value || !this.analysis.fat.value) {
        return null;
      }

      const est = (0.25 * (this.analysis.density.value - 1000)) + (1.2 * this.analysis.fat.value) + 0.26;

      if (!est) {
        return null;
      }

      // Converte para 2 casas decimais e retorna como float
      return parseFloat(est.toFixed(2));
    },

    /**
     * Calcula o ESD através da fórmula
     * EST - % gordura
     */
    formulaESD() {
      if (!this.formulaEST || !this.analysis || !this.analysis.fat.value) {
        return null;
      }

      const esd = this.formulaEST - this.analysis.fat.value;

      if (!esd) {
        return null;
      }

      return parseFloat(esd.toFixed(2));
    },

    /**
     * Calcula o S.T para SORO CONCENTRADO através da fórmula
     * (0,25 x final da densidade) + (1,2 X gordura) + 0,25
     */
    formulaTotalSolids() {
      if (!this.analysis || !this.analysis.density.value || !this.analysis.fat.value || !this.rawProduct?.name?.includes('SORO CONCENTRADO')) {
        return null;
      }

      const ts = (0.25 * (this.analysis.density.value - 1000)) + (1.2 * this.analysis.fat.value) + 0.25;

      if (!ts) {
        return null;
      }

      return _.floor(ts, this.analysis.totalSolids.decimals);
    },

    colSize() {
      return undefined;
    },

  },

  watch: {
    analysis: {
      deep: true,

      async handler(newValue) {
        this.$emit('input', newValue);

        await this.$nextTick();

        this.checkProblems();
        this.checkEmptyFields();
      },
    },

    formulaEST(value) {
      if (!this.isVisible('est')) {
        return;
      }
      this.analysis.est.value = value > 0 ? value : null;
    },

    formulaESD(value) {
      if (!this.isVisible('esd')) {
        return;
      }
      this.analysis.esd.value = value > 0 ? value : null;
    },

    formulaTotalSolids(value) {
      if (!this.isVisible('totalSolids')) {
        return;
      }
      this.analysis.totalSolids.value = value > 0 ? value : null;
    },

    cryoscopys: {
      immediate: true,
      handler(newVal) {
        if (newVal && Array.isArray(newVal) && newVal.length > 0) {
          this.localCryoscopys = newVal;

          if (this.analysis && this.analysis.cryoscopy.value == '-0.5') {
            this.onCryoscopyChange();
            this.onWaterPercentageChange();
          }

          return;
        }

        this.localCryoscopys = [];
      }
    },

    vol: {
      immediate: true,
      handler() {
        if (this.analysis) {
          this.onWaterPercentageChange();
        }
      }
    }
  },

  mounted() {
    this.analysis = _.cloneDeep(this.value);
  },

  methods: {

    /**
     * Método acionado ao alterar o % de agua no formulário
     */
    onWaterPercentageChange() {
      // Caso não tenha volume indentificado, ou o % seja 0, não é possível calcular o volume de água

      if (this.analysis.waterPercentage.value <= 0) {
        this.analysis.waterLiters.value = '';
        return;
      }

      if (!this.vol) {
        return;
      }

      const vol = (this.vol * (this.analysis.waterPercentage.value / 100)) || 0;

      this.analysis.waterLiters.value = _.round(vol, this.analysis.waterLiters.decimals || 0);
    },

    checkProblems() {
      if (_.isEmpty(this.$refs.form)) {
        return;
      }

      const validations = this.$refs.form.$children
        // Valida apenas os inputs/checks, ignora os demais elementos filhos
        .filter(child => child.$options.name === "AnalysisInput")
        .map(child => {
          const ignore = this.ignoreErrors.some(errorToIgnore => errorToIgnore === child.$options.propsData.name);

          const hasError = child.hasError();
          const hasRestriction = child.hasRestriction();

          return {
            name: child.$options.propsData.name,
            ignore,
            hasError,
            hasRestriction,
          };
        })

      const problems = validations.filter(item => !item.ignore && item.hasError).map(item => item.name);

      if (this.autoMarkOutOfRange) {
        const hasDiscard = validations.some(item => !item.ignore && item.hasError && !item.hasRestriction);
        const hasRestriction = validations.some(item => item.hasError && item.hasRestriction);

        this.$emit('onAutoMarkOutOfRange', {
          restriction: !hasDiscard && hasRestriction,
          discard: hasDiscard,
          problems,
        });
      }

      return this.$emit('update:problems', problems);
    },

    checkEmptyFields() {
      if (_.isEmpty(this.$refs.form)) {
        return;
      }

      const emptyFields = this.$refs.form.$children.filter(child => {

        // Valida apenas os inputs/checks, ignora os demais elementos filhos
        if (child.$options.name !== "AnalysisInput") {
          return false;
        }

        const ignore = this.ignoreEmpty.some(emptyToIgnore => emptyToIgnore === child.$options.propsData.name);

        if (ignore) {
          return false;
        }

        return child.isEmpty();
      }).map(child => child.$options.propsData.name);

      return this.$emit('update:empty-fields', emptyFields);
    },

    onAnalysisInputEnter(input) {

      if (this.singleForm) {
        return this.goToNextAnalysis(input);
      }

      return this.$emit('onAnalysisInputEnter',  input);
    },

    focus(inputName) {
      const inputs = this.$refs.form.$children.filter(child => child.$options.name === "AnalysisInput");

      if (_.isEmpty(inputs)) {
        return;
      }

      let thisIndex = inputs.findIndex(input => input.$options.propsData.name === inputName);

      if (thisIndex === -1) {
        return;
      }

      const input = inputs[thisIndex];

      if (_.isEmpty(input)) {
        return;
      }

      return input.focus();
    },

    goToNextAnalysis(inputName) {
      const inputs = this.$refs.form.$children.filter(child => child.$options.name === "AnalysisInput");

      if (_.isEmpty(inputs)) {
        return;
      }

      let thisIndex = inputs.findIndex(input => input.$options.propsData.name === inputName);

      if (thisIndex === -1) {
        return;
      }

      const nextInput = inputs[++thisIndex];

      if (_.isEmpty(nextInput)) {
        return;
      }

      return nextInput.focus();
    },

    isVisible(input) {
      if (_.isEmpty(this.visible)) {
        return true;
      }

      return this.visible.includes(input);
    },

    /**
     * Percorre todos os inputs e verifica se algum não é valido
     */
    validate() {
      return _.isEmpty(this.problems);
    },

    /**
     * Define o valor default da crioscopia apenas quando entrar no input
     */
    onCryoscopyFocus() {
      if (!this.analysis.cryoscopy.value) {
        this.analysis.cryoscopy.value = '-0.5';
      }
    },

    onCryoscopyChange() {
      if (this.localCryoscopys !== 'undefined' && this.localCryoscopys.length > 0) {

        const found = this.localCryoscopys.find(item => parseFloat(item.valor_crioscopia) == parseFloat(this.analysis.cryoscopy.value));
        this.analysis.waterPercentage.value = '';

        if (found) {
          this.analysis.waterPercentage.value = found.porcentagem_agua;
        }

        this.onWaterPercentageChange();
      }
    },

    hasObservation() {
      if (typeof this.analysis.observation === 'undefined') {
        return false;
      }

      return true;
    },

  }
}
</script>
