<template>
  <div>
    <v-row>
      <v-col
        md="12"
        class="pb-1"
      >
        <v-card
          outlined
          elevation="2"
        >
          <v-card-title>
            <!-- <v-icon class="mr-1">
              segment
            </v-icon> -->
            Boas práticas
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              dense
              :items-per-page.sync="goodHabits.length"
              :items="goodHabits"
              :headers="[
                { text: 'Ação', value: 'Habit', width: 700 },
                { text: 'Situação', value: 'situation', width: 130 },
              ]"
              :expanded="expanded"
              item-key="Habit"
              class="quality-table"
            >
              <template #[`item.situation`]="{ item }">
                <v-select
                  v-if="item.param == 'intervalCollect'"
                  v-model="item.situation"
                  class="ma-1"
                  :items="[
                    { description: 'Não realiza'},
                    { description: 'Diariamente'},
                    { description: 'A cada 2 dias'}
                  ]"
                  item-text="description"
                  hide-details
                  dense
                  persistent-hint
                  single-line
                  
                  label="Situação"
                  return-object
                />

                <v-select
                  v-else-if="item.param == 'coolerRoom'"
                  v-model="item.situation"
                  class="ma-1"
                  placeholder=""
                  :items="[
                    { description: 'Não possui'},
                    { description: 'Ao lado do curral sem separação' },
                    { description: 'Separado' },
                  ]"
                  item-text="description"
                  hide-details
                  dense
                  persistent-hint
                  single-line
                  label="Situação"
                  return-object
                />

                <v-select
                  v-else
                  v-model="item.situation"
                  class="ma-1"
                  :items="[
                    { description: 'Não realiza', item },
                    { description: 'Realiza', item },
                  ]"
                  item-text="description"
                  hide-details
                  dense
                  persistent-hint
                  single-line
                  label="Situação"
                  return-object
                  @change="expandedItem"
                />
              </template>

              <template #[`expanded-item`]="{ headers, item }">
                <td
                  :colspan="headers.length"
                  style="background:#F5F5F5"
                >
                  <div class="list-product">
                    <v-row
                      v-for="(product, index) in item.products"
                      :key="index"
                      class="ml-1 mt-2"
                      align="center"
                      justify="center"
                      no-gutters
                      style="border-bottom: 1px solid #cccc;"
                    >
                      <v-col
                        cols="3"
                        class="pa-1 pb-0"
                      >
                        <v-text-field
                          v-model="product.name"
                          label="Produto"
                          prepend-icon="water_drop"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="2"
                        class="pa-1 pb-0"
                      >
                        <v-text-field
                          v-model="product.attendance"
                          label="Frequência"
                          dense
                          prepend-icon="update"
                        />
                      </v-col>
                      <v-col
                        cols="2"
                        class="pa-1 pb-0"
                      >
                        <v-text-field
                          v-model="product.period"
                          label="Período"
                          prepend-icon="date_range"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="2"
                        class="pa-1 pb-0"
                      >
                        <money-input
                          v-model="product.value"
                          label="Valor"
                          prefix="R$"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="2"
                        class="pa-1 pb-0"
                      >
                        <v-menu
                          ref="menu"
                          v-model="datePicker.menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="product.lastDone"
                              label="Ultima Realização"
                              prepend-icon="today"
                              readonly
                              v-bind="attrs"
                              dense
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="datePicker.date"
                            :max="datePicker.max"
                            no-title
                            scrollable
                            @change="onSetProductLastDateUsed(datePicker.date, product)"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="1"
                        class="pa-1"
                      >
                        <v-row
                          v-if="false"
                          align="center"
                          justify="center"
                        >
                          <v-col
                            md="6"
                            cols="6"
                            class="pa-1 pr-0 text-right"
                          >
                            <v-btn
                              icon
                              @click.prevent="onAddproductRow(item)"
                            >
                              <v-icon color="blue lighten-1">
                                add_circle_outline
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col
                            md="6"
                            cols="6"
                            class="pa-1 pl-0 text-left"
                          >
                            <v-btn
                              icon
                              @click="onRemoveproductRow(item, index)"
                            >
                              <v-icon color="red lighten-1">
                                remove_circle_outline
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </td>
              </template>

              <template #[`item.Habit`]="{ item }">
                <v-chip
                  color="#E6E6FA"
                  label
                  small
                  class="mt-1"
                >
                  <span class="habit-name">{{ item.Habit }}</span>
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        md="12"
        class="pb-1 pt-2"
      >
        <v-card
          outlined
          elevation="2"
        >
          <v-card-title>
            <!-- <v-icon class="mr-2">
              receipt
            </v-icon> -->
            Registros
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.rg_sanitizacao_reservatorio_agua"
                  label="Sanitização dos reservatorios de água"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.rg_analise_agua"
                  label="Análise da água utilizada"
                  :items="['NÃO REALIZA', 'DIÁRIO', 'SEMANAL', 'MENSAL', 'SEMESTRAL', 'ANUAL']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.possui_clorador"
                  label="Possui Clorador?"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.possui_aquecedor"
                  label="Possui Aquecedor?"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.faz_controle_leiteiro"
                  label="Faz controle leiteiro?"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.usa_detergente"
                  label="Usa detergente?"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.usa_sanitizante"
                  label="Usa sanitizante ?"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.possiu_certificacao_da_pncebt"
                  label="A propriedade é certificada pelo PNCEBT?"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.possiu_certificacao_boas_praticas"
                  label="O produtor possui um sistema de certificação em boas práticas agropecuárias?"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
              <v-col
                md="3"
                class="pb-1 pt-1"
              >
                <v-autocomplete
                  v-model="localData.possui_programa_bpf"
                  label="Possui programa de BPF?"
                  :items="['SIM', 'NÃO']"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.quality-table {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  table {
    thead > tr {
      span {
        font-family: Roboto, Arial, sans-serif;
        font-size: 0.9rem;
        font-weight: 700;
      }
      background: rgba(226, 225, 225, 0.6);
    }

    tbody {
      tr:hover {
        background-color: #ECEFF1 !important;
      }
    }
  }

  .list-product::-webkit-scrollbar {
    display: none;
  }

  .list-product {
    max-height: 255px !important;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .habit-name {
    font-family: Roboto, Arial, sans-serif !important;
    font-size: 0.9rem !important;
    font-weight: 500;
  }
}
</style>

<script>
import * as _ from "lodash";
import moment from "moment";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

const habitDataBase  = {
  teste_cmt: "cmtTest",
  caneca_de_fundo_preto: "mugBlackBottom",
  descarte_tres_1_jato_leite: "discardFirstJet",
  pre_dipping: "preDipping",
  pos_dipping: "posDipping",
  pre_ordenha: "preMilkingSanitization",
  higiene_dos_utensilios: "UtensilHygiene",
  higiene_da_ordenhadeira: "MilkingHygiene",
  higiene_da_sala_de_ordenha: "milkingRoomHygiene",
  uso_de_agua_quente: "hotWater",
  troca_de_teteira: "changeTeatCup",
  troca_das_mangueiras: "changeHoses",
  conferencia_do_vacuo: "checkVacuum",
  limpeza_do_coador: "FilterCleaning",
  troca_de_cama_areia: "sandChange",
  troca_de_cama_serragem: "sawdustChange",
  limpeza_alcalina_do_resfriador: "alkalineCleaningCoolers",
  limpeza_acida_do_resfriador: "acidCleaningCoolers",
  sanitizacao_do_resfriador: "coolersSanitization",
  analise_da_agua: "waterAnalysis",
  tratamento_da_agua: "waterTreatment",
  uso_de_epi: "epi",
  // elemento_intervalo_de_coletas:"intervalCollect",
  // sala_resfriador:"coolerRoom",
}

const situation = {
  'REALIZA': 'Realiza',
  'NÃO REALIZA': "Não Realiza",
  'NÃO POSSUI': 'Não possui',
  'DIARIAMENTE': 'Diariamente',
  'AO LADO DO CURRAL SEM SEPARAÇAO': 'Ao lado do curral sem separação',
  'A CADA 2 DIAS': 'A cada 2 dias',
  'SEPARADO': 'Separado',
}

export default {
  components: {
    MoneyInput,
  },
  props: {
    diagnostic: {
      require: true,
    },
  },
  data() {
    return {

      loading: false,

      datePicker: {
        date: null,
        max: moment().format("DD/MM/YYYY"),
        menu: false,
      },

      goodHabits: [
        {
          Habit: "Teste CMT",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "cmtTest",
        },
        {
          Habit: "Caneca de fundo preto",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "mugBlackBottom",
        },
        {
          Habit: "Descarte dos Três 1° jatos de leite",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "discardFirstJet",
        },
        {
          Habit: "Pré-dipping",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "preDipping",
        },
        {
          Habit: "pós-dipping",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "posDipping",
        },
        {
          Habit: "Sanitização de pré-ordenha",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "preMilkingSanitization",
        },
        {
          Habit: "Higiene dos utensilios",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "UtensilHygiene",
        },
        {
          Habit: "Higiene da ordenhadeira",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "MilkingHygiene",
        },
        {
          Habit: "Higiene da sala de ordenha",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "milkingRoomHygiene",
        },
        {
          Habit: "Uso de água quente",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "hotWater",
        },
        {
          Habit: "Troca de teteira",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "changeTeatCup",
        },
        {
          Habit: "Troca das mangueiras",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "changeHoses",
        },
        {
          Habit: "conferência do vácuo",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "checkVacuum",
        },
        {
          Habit: "Limpeza do coador",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "FilterCleaning",
        },
        {
          Habit: "Troca de cama (Areia)",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "sandChange",
        },
        {
          Habit: "Troca de cama (Serragem)",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "sawdustChange",
        },
        {
          Habit: "Limpeza acida do(s) resfriador(es)",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "acidCleaningCoolers",
        },
        {
          Habit: "Limpeza alcalina do(s) resfriador(es)",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "alkalineCleaningCoolers",
        },
        {
          Habit: "Sanitização do(s) resfriador(es)",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "coolersSanitization",
        },
        {
          Habit: "Analise de água",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "waterAnalysis",
        },
        {
          Habit: "tratamento de água",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "waterTreatment",
        },
        {
          Habit: "Uso de EPI",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "epi",
        },
        {
          Habit: "Intervalos das coletas de leite",
          situation: { description: "Não realiza", item: null },
          products: [],
          param: "intervalCollect",
        },
        {
          Habit: "Sala do resfriador",
          situation: { description: "Não possui", item: null },
          products: [],
          param: "coolerRoom",
        },
      ],
    };
  },
  computed: {
    localData: {
      get() {
        return this.diagnostic;
      },
      set(newValue) {
        return this.$emit("update:diagnostic", {
          ...newValue,
        });
      },
    },
    expanded() {
      return this.goodHabits.filter( h => h.situation.description === 'Realiza');
    }
  },

  mounted() {
    this.initStep();
  },
  methods: {
    /**
     * @void
     * Trata informações do step
     */
    initStep() {
      _.forEach(habitDataBase, (field, fieldBd) => {
        let habitBd = JSON.parse(_.get(this.diagnostic, fieldBd, '[]'));
      
        // busca a posição do prática no campo local
        let indexHabit = _.findIndex(this.goodHabits, h => h.param === field);
        
        // if (indexHabit > 0) {
        //   return this.goodHabits[indexHabit];
        // }

        // caso não tenha produtos salvos não precisa fazer nada
        if (_.isEmpty(habitBd.produto)) {
          return this.goodHabits[indexHabit];
        }

        this.goodHabits[indexHabit].situation = { description: situation[_.get(habitBd, "status", null)] };
        this.goodHabits[indexHabit].products = habitBd.produto.map((product, index) => {
          return {
            name: product,
            attendance: _.get(habitBd.frequencia, index),
            value: _.get(habitBd.valor, index),
            period: _.get(habitBd.periodo, index),
            lastDone: _.get(habitBd.ultima_utilizacao, index),
          };
        });
      });
    },

    /**
     * @void
     * Trata da abertura e fechamento dos items da tabela
     */
    expandedItem({ item, description }) {
      // Caso realize a ação abre o expand
      if (description === "Realiza") {

        // linha inicial
        if (_.isEmpty(item.products)) {
          item.products.push({
            name: null,
            attendance: null,
            value: null,
            period: null,
            lastDone: moment().format("DD/MM/YYYY"),
          });
        }

        return true;
      }

      item.products = [];
    },
    /**
     * @void
     * Adicinar novo produtor
     */
    onAddproductRow(habit) {
      habit.products.push({
        name: null,
        attendance: null,
        value: null,
        period: null,
        lastDone: moment().format("DD/MM/YYYY"),
      });
    },

    onRemoveproductRow(habit, rowIndex) {
      if (habit.products.length === 1) {
        //@todo tratar quando tive apenas 1
        return true;
      }

      habit.products = _.remove(habit.products, (h, index) => index !== rowIndex);
    },

    /**
     * @void
     * Definir a última data de uso do produto
     */
    onSetProductLastDateUsed(date, product) {
      if (!date) {
        return true;
      }

      product.lastDone = moment(date).format("DD/MM/YYYY");
      this.datePicker.menu = false;
    },
  },
};
</script>
