<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Motivos de desvinculação</span>
    </v-card-title>
    <v-chart
      :options="options"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import isObject from 'lodash/fp/isObject';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '100px',
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,
      reasons: [],
    }
  },

  computed: {
    options() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [{
          name: 'Motivos de desvinculação',
          type: 'pie',
          radius: [20, 120],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 5
          },
          label: {
            color: '#fff',
            textBorderWidth: 0,
            textBorderColor: 'transparent',
            textShadowBlur: 0
          },
          data: this.reasons
        }]
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(
          `/relatorios/motivosDesvinculacao`,
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!isObject(data)) {
          throw "PHP Error";
        }

        this.reasons = data.map(item => ({ name: item.motivo, value: item.total }));
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório de motivos de desvinculação!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
