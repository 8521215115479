<template>
  <v-col
    :md="questionnairePoints ? 4 :3"
    class="flex-option pb-1 pt-0"
  >
    <v-icon
      v-if="type === 'CHECKBOX'"
      class="mt-4 mr-1"
      color=" darken-1"
    >
      radio_button_checked
    </v-icon>
    <v-icon
      v-else
      class="mt-4 mr-1"
      color=" darken-1"
    >
      check_box
    </v-icon>
    <v-text-field
      v-model="descriptionLocal"
      append-outer-icon="close"
      hide-details
      dense
      class="pt-3"
      :readonly="questionnaireDisabled ? true : false"
      :disabled="questionnairePoints ? true : false"
      @click:append-outer.stop="deleteOption"
      @change="changeOption"
    />
    <masked-input
      v-if="questionnairePoints"
      v-model.number="pointsLocal"
      hide-details
      dense
      class="pt-3 max-width"
      :readonly="questionnaireDisabled ? true : false"
      :mask="NumberMask"
      return-unmasked
      @change="changeOption"
    />
    <p
      v-if="questionnairePoints"
      class="pt-5"
    >
      Pontos
    </p>
  </v-col>
</template>
<style lang="scss">
  .max-width {
    width:50px;
  }
</style>

<script>
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  name: "OptionQuestionCheckbox",

  components: {
    MaskedInput,
  },

  props: {
    position: {
      default: 1,
    },
    description: {
      default: "",
    },
    points: {
      default: 0,
      type: Number,
    },
    type: {
      default: "CHECKBOX",
    },
    questionnaireDisabled: {
      type: Boolean,
      default: false,
    },

    questionnairePoints: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descriptionLocal: "",

      pointsLocal: 0,

      // Mascara usada nos demais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 2,
            thousandsSeparator: '.',
            min: -99,
            max: 99,
          },
        },
      },
    };
  },

  mounted() {
    this.descriptionLocal = this.description;
    this.pointsLocal      = this.points;
  },

  methods: {
    /**
     *  Emiti um evento para remover um opção da questão
     **/
    deleteOption() {
      if (!this.questionnaireDisabled) {
        this.show = false;
        this.$emit("onDeleteOption", { position: this.position });
      } else {
        this.$snotify.error("Questionário já respondido", "Atenção");
      }
    },

    /**
     * Captura o evento de change do text-field e emiti um novo avento
     **/
    changeOption() {
      this.$emit("update:description", this.descriptionLocal);
      this.$emit("update:points", (this.pointsLocal));

      this.$emit("onChange");
    },
  },
};
</script>
