<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="date | dateFormat('DD/MM/YYYY')"
        readonly
        :label="label"
        :prepend-inner-icon="prependInnerIcon"
        v-bind="{...attrs, ...$attrs}"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      :min="min"
      :max="max"
      no-title
      scrollable
      @change="onChange"
    />
  </v-menu>
</template>

<script>
import moment from "moment-timezone";

export default {

  filters: {
    dateFormat: function(value, opts) {
      return moment(value).format(opts);
    },
  },

  props: {
    value: Date,
    label: String,
    prependInnerIcon: String,
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    }
  },

  data: () => ({
    menu: false,
    date: null,
    time: null,
    min: null,
    max: null
  }),

  watch: {
    value() {
      return this.$nextTick(() => {
        this.setDateTime();
      });
    },
    minDate() {
      return this.$nextTick(() => {
        this.setMinMax();
      });
    },
    maxDate() {
      return this.$nextTick(() => {
        this.setMinMax();
      });
    }
  },

  created() {
    this.setDateTime();
    this.setMinMax();
  },

  methods: {
    setMinMax() {
      this.min = this.minDate ? moment(this.minDate).format('YYYY-MM-DD') : null;
      this.max = this.maxDate ? moment(this.maxDate).format('YYYY-MM-DD') : null;
    },
    setDateTime() {
      const datetime = moment(this.value);
      this.date = datetime.format('YYYY-MM-DD');
      this.time = datetime.format('HH:mm');
    },

    onChange(date) {
      this.$refs.menu.save(date);
      this.$emit("input", new Date(`${this.date}T${this.time}:00`));
    }
  }
}
</script>