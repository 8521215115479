<template>
  <div
    :style="{overflow: 'hidden', height: height}"
    class="d-flex flex-column"
  >
    <v-row class="flex-grow-1">
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Em Planejamento"
          icon="assignment_turned_in"
          height="100%"
          :value="formatNumber(planejamento)"
          :color="color"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Em Produção"
          icon="conveyor_belt"
          height="100%"
          :value="formatNumber(producao)"
          :color="color"
          :loading="loading"
        />
      </v-col>
      <v-col
        class="pt-0"
      >
        <CardInfo
          title="Unidades Produzidas"
          icon="shelves"
          height="100%"
          :value="formatNumber(produzido)"
          :color="color"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardInfo from "@/Domains/BI/Components/CardInfo.vue";

import moment from 'moment';
import debounce from 'lodash/debounce';

export default {

  components: {
    CardInfo
  },

  filters: {
    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  },

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
    height: {
      type: String,
      default: '65px'
    },
    products: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      loading: false,
      planejamento: 0,
      producao: 0,
      produzido: 0,
    }
  },

  watch: {
    period() {
      this.onFilter();
    },
    products() {
      this.onFilter();
    },
  },

  created() {
    this.loadReport();
  },

  methods: {
    onFilter: debounce(function () {
      this.loadReport()
    }, 500),

    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.get(`/production-order/production-report`, { params: {
          start_date: startDate, end_date: endDate, products: this.products,
        } });

        this.planejamento = data.planejamento || 0;
        this.producao = data.producao || 0;
        this.produzido = data.produzido || 0;

      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro os indicadores totais de produção!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
  }
}
</script>
