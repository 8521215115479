<template>
  <v-dialog
    v-model="input"
    width="900"
    persistent
  >
    <v-card class="payment-dialog">
      <v-stepper
        v-if="!showDetails"
        v-model="step"
        alt-labels
        class="elevation-0"
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="step > 1"
          >
            Dados principais
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="2"
            :complete="step > 2"
          >
            Fornecedor
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            step="3"
            :complete="step > 3"
          >
            Notas/Anexos
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form
              ref="form-1"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="3"
                >
                  <v-text-field
                    v-model="form.invoice"
                    label="N° Nota Fiscal *"
                    prepend-inner-icon="receipt"
                    persistent-hint
                    type="number"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    v-model="form.description"
                    label="Descrição *"
                    prepend-inner-icon="reorder"
                    persistent-hint
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col cols="4">
                  <category-select
                    v-model="form.category"
                    label="Categoria *"
                    prepend-inner-icon="category"
                    persistent-hint
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <span
                    class="text-h6 mt-4"
                    style="display: inline-flex;"
                  >
                    Produtos/Serviços
                    <v-spacer />
                    <v-btn
                      icon
                      color="blue"
                      @click="addItem"
                    >
                      <v-icon>
                        add
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="form.items.length > 1"
                      icon
                      color="red"
                      @click="removeItem"
                    >
                      <v-icon>
                        remove
                      </v-icon>
                    </v-btn>
                  </span>
                  <v-divider class="mb-2" />
                  <v-row
                    v-for="(item, idx) of form.items"
                    :key="idx"
                  >
                    <v-col
                      cols="6"
                      class="py-0"
                    >
                      <v-text-field
                        v-model="item.item"
                        label="Item *"
                        prepend-inner-icon="reorder"
                        persistent-hint
                        :rules="[v => !!v || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      cols="3"
                      class="py-0"
                    >
                      <v-text-field
                        v-model="item.quantity"
                        label="Quantidade *"
                        prepend-inner-icon="iso"
                        persistent-hint
                        type="number"
                        min="0"
                        :rules="[v => !!v && v > 0 || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      cols="3"
                      class="py-0"
                    >
                      <v-text-field
                        v-model="item.value"
                        label="Valor unitário *"
                        prefix="R$"
                        persistent-hint
                        type="number"
                        min="0"
                        :rules="[v => !!v && v > 0 || 'Campo obrigatório!']"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-form
              ref="form-2"
              lazy-validation
            >
              <v-row>
                <v-col cols="4">
                  <v-menu
                    v-model="form.paymentDate.picker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="form.paymentDate.value | dateFormat('DD/MM/YYYY')"
                        label="Data de pagamento *"
                        prepend-inner-icon="event"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="form.paymentDate.value"
                      no-title
                      @input="form.paymentDate.picker = false"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="8">
                  <supplier-select
                    v-model="form.supplier"
                    label="Fornecedor *"
                    prepend-inner-icon="business"
                    persistent-hint
                    :rules="[v => !!v || 'Campo obrigatório!']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-form
              ref="form-3"
              lazy-validation
            >
              <v-row>
                <v-col
                  class="py-0 text-center"
                  cols="12"
                >
                  <v-btn
                    outlined
                    class="mb-5"
                    @click="showFileUpload = !showFileUpload"
                  >
                    <v-icon>attach_file</v-icon> Adicionar arquivos
                  </v-btn>
                  <v-input
                    :value="form.files.length"
                    required
                    :rules="[v => !!v || 'Anexe as notas!']"
                  />
                </v-col>
                <v-col
                  v-for="(file, idx) in form.files"
                  :key="idx"
                  cols="3"
                >
                  <file-viewer
                    :value="file"
                    @removeFile="onRemoveFile(idx)"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card-text v-else>
        <v-row>
          <v-col cols="4">
            <b class="col col-12">Nº Nota Fiscal</b>
            <h1 class="col col-12">
              {{ form.invoice }}
            </h1>
          </v-col>
          <v-spacer />
          <v-col cols="4">
            <v-row>
              <b class="col col-6">Descrição:</b>
              <span class="col col-6 text-end">
                {{ form.description }}
              </span>
              <b class="col col-6 pt-0">Categoria:</b>
              <span class="col col-6 text-end pt-0">
                {{ form.categoryName }}
              </span>
              <b class="col col-6 pt-0">Fornecedor:</b>
              <span class="col col-6 text-end pt-0">
                {{ form.supplierName }}
              </span>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="form.items"
          dense
          disable-sort
          hide-default-footer
        >
          <template #[`item.value`]="{ value }">
            R$ {{ value | formatCurrency }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            v-if="!showDetails"
            class="align-self-end px-0 py-0"
          >
            <small>* Campo obrigatório</small>
          </v-col>
          <v-spacer />
          <v-col
            cols="7"
            class="align-self-end text-end px-0 py-0"
          >
            <small class="grey--text pr-1">
              Total do Pagamento
            </small>
            <span class="primary--text text-h5">
              R$ {{ totalValue | formatCurrency }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <template v-if="!showDetails">
          <v-btn
            v-if="step == 1"
            color="grey darken-1"
            outlined
            @click="input = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-else
            color="red darken-1"
            outlined
            @click="step = step - 1"
          >
            <v-icon
              left
              dark
            >
              chevron_left
            </v-icon>
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="step < 3"
            color="green"
            outlined
            @click="nextStep"
          >
            Continuar
            <v-icon
              right
              dark
            >
              chevron_right
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            outlined
            @click="save"
          >
            Salvar
          </v-btn>
        </template>
        <template v-else>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            outlined
            @click="input = false"
          >
            Fechar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>

    <file-upload
      v-model="showFileUpload"
      extensions="pdf,xml"
      accept="application/pdf,text/xml"
      @insertFiles="onSelectFile"
    />
  </v-dialog>
</template>

<style lang="scss">
.payment-dialog {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: unset;
  }
  .v-stepper__content {
    max-height: calc(100vh - 350px);
    overflow: auto;
  }
}
</style>

<script>
import CategorySelect from "@/Domains/Projects/PMLS/Components/CategorySelect.vue";
import SupplierSelect from "@/Domains/Projects/PMLS/Components/SupplierSelect.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

import moment from "moment-timezone";

export default {
  name: 'payment-dialog',

  components: {
    CategorySelect,
    SupplierSelect,
    FileUpload,
    FileViewer
  },

  filters:{
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  props: {
    value: {
      type: Boolean,
    },
    idPayment: String,
    showDetails: Boolean
  },

  data() {
    return {
      // Model
      input: false,
      step: 1,

      form: {
        paymentDate: {
          picker: false,
          value: moment().format('YYYY-MM-DD'),
        },
        items: [{
          item: '',
          quantity: null,
          value: null
        }],
        files: [],
      },

      showFileUpload: false,

      headers: [
        { text: 'Produto/Serviço', value: 'item' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Valor', value: 'value', align: 'end' },
      ],
    }
  },

  computed: {
    totalValue() {
      return this.form.items.reduce((acc, cur) => acc + (cur.quantity * parseFloat(cur.value || 0)), 0)
    }
  },

  watch: {
    value() {
      this.input = this.value;
    },
    input(value) {
      if (value) {
        if (!this.idPayment) {
          this.reset();
        }
        else {
          this.getPayment();
        }
      }
      this.$emit("input", !!value);
    },
    idPayment(value) {
      if (value) {
        this.getPayment();
      }
    },
  },

  methods: {
    async nextStep() {
      if (!await this.$refs[`form-${this.step}`].validate()) {
        return;
      }
      this.step++;
    },
    async save() {
      if (!await this.$refs[`form-${this.step}`].validate()) {
        return;
      }
      try {
        const form = {
          ...this.form,
          paymentDate: this.form.paymentDate.value
        };

        const { data } = await this.$axios.post(`/${this.$store.state.settings.sistema}/projetos/salvaPagamento`, form);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }
        this.input = false;
        this.$emit('newPayment', data.id);
        this.$snotify.success("Pagamento salvo com sucesso!", "Sucesso");
      } catch(error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar o registro!", "Atenção");
        console.warn(error);
      }
    },
    addItem() {
      this.form.items.push({
          item: '',
          quantity: null,
          value: null
      });
    },
    removeItem() {
      this.form.items.splice(-1, 1);
    },
    onSelectFile(files) {
      this.form.files = files.map(file => ({
        success: true,
        name: file.name,
        size: file.size,
        url: file.url
      }));
    },
    onRemoveFile(idx) {
      this.form.files.splice(idx, 1);
    },
    reset() {
      if (this.$refs['form-1']) {
        this.$refs['form-1'].resetValidation();
      }
      if (this.$refs['form-2']) {
        this.$refs['form-2'].resetValidation();
      }
      if (this.$refs['form-3']) {
        this.$refs['form-3'].resetValidation();
      }
      this.form = {
        paymentDate: {
          picker: false,
          value: moment().format('YYYY-MM-DD'),
        },
        items: [{
          item: '',
          quantity: null,
          value: null
        }],
        files: [],
      };
      this.step = 1;
    },
    async getPayment() {
      if (!this.idPayment) {
        return;
      }
      try {
        const { data } = await this.$axios.post(`/${this.$store.state.settings.sistema}/projetos/detalhePagamento`, { id: this.idPayment });

        if (!data.id_pagamento) {
          throw new Error(data.mensagem || data);
        }

        this.form = {
          ...this.form,
          invoice: data.numero_nota,
          description: data.descricao,
          category: data.id_categoria,
          categoryName: data.nome_categoria,
          supplier: data.id_fornecedor,
          supplierName: data.nome_fornecedor,
          files: data.anexos,
          items: data.items.map(o => ({
            id: o.id_pagamento_item,
            item: o.item,
            quantity: o.quantidade,
            value: o.valor,
          })),
        };
        this.form.paymentDate.value = data.data_pagamento;

      } catch(error) {
        this.$snotify.error("Oops, ocorreu um erro ao abrir o registro!", "Atenção");
        console.warn(error);
        this.input = false;
      }
    },
  }

}
</script>
