<template>
  <v-col
    v-if="show"
    cols="12"
    class="d-flex justify-center py-0"
  >
    <v-alert
      v-model="show"
      dense
      light
      colored-border
      border="left"
      elevation="2"
      :color="color"
      dismissible
      style="max-width: 700px; width: 100%"
    >
      <v-row
        class="pa-0 align-center"
      >
        <v-col class="grow py-0 text-left">
          <div class="text-h6">
            Resultado Importação
          </div>
          <div class="body-1">
            <v-icon color="success">
              check_circle_outline
            </v-icon> {{ successes }} análises importadas.<br>
            <span v-if="failures > 0">
              <v-icon color="warning">
                error_outline
              </v-icon>
              {{ failures }} análises não foram importadas.
              <v-btn
                v-if="showFailureLink"
                text
                color="primary"
                :to="{ name: 'error-logs-analysis' }"
              >
                Acessar o relatório de falhas
              </v-btn>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-alert>
  </v-col>
</template>

<script>
export default {
  props: {
    value: Boolean,
    successes: Number,
    failures: Number,
    showFailureLink: {
      type: Boolean,
      default: () => true,
    },
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    color() {
      if (this.successes > 0 && this.failures > 0) {
        return 'warning';
      }

      if (this.failures > 0) {
        return 'danger';
      }

      return 'success'
    }
  }
}
</script>
