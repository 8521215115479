<template>
  <service-index
    type="VETERINARIO"
  />
</template>

<script>
import ServiceIndex from "@/Domains/Registrations/Cares/Service/ServiceIndex.vue";

export default {
  components: {
    ServiceIndex
  },
}
</script>
