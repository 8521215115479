<template>
    <div class="pt-0">
      <v-row>
        <v-col cols="12">
          <v-card
            dark
            color="transparent"
          >
            <v-card-title>
              <v-spacer />
              <v-col
                cols="4"
                md-4
                sm-4
                class="pa-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  class="pt-0 my-2"
                />
              </v-col>
            </v-card-title>
            <data-table
              ref="report"
              :headers="headers"
              :items="search(items)"
              :search="filter.search"
              show-custom-group
              dark
              @click:row="edit"
            >
              <template #[`item.action`]="{ item }">
                <v-menu
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="remove(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="edit(item)">
                      <v-list-item-icon>
                        <v-icon>edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-speed-dial
        fixed
        dark
        bottom
        right
        open-on-hover
        direction="top"
        transition="slide-y-reverse-transition"
        class="mr-5"
      >
        <template #activator>
          <v-btn
            color="blue darken-2"
            dark
            large
            fab
          >
            <v-icon>menu</v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          color="blue"
          @click="add()"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                add
              </v-icon>
            </template>
            Novo
          </v-tooltip>
        </v-btn>
        <v-btn
          fab
          dark
          color="green darken-1"
          @click="exportExcel()"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                backup_table
              </v-icon>
            </template>
            Download (Excel)
          </v-tooltip>
        </v-btn>
        <v-btn
          fab
          dark
          color="orange darken-1"
          @click="print()"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                print
              </v-icon>
            </template>

            Imprimir
          </v-tooltip>
        </v-btn>
      </v-speed-dial>
      <add-inventory-dialog
        v-model="showDialog"
        :edit-id="editId"
        @save="loadInventories"
      />
    </div>
  </template>

  <script>
  import AddInventoryDialog from "@/Domains/Projects/HealthExamination/Components/AddInventoryDialog.vue";

  export default {

    components: {
      AddInventoryDialog,
    },

    data() {
      return {
        showDialog: false,
        editId: null,
        filter: {
          search: '',
        },

        headers: [
          { text: 'Produtor', value: 'producer.name', align: 'start' },
          { text: 'Bezerras', value: 'bezerras', align: 'center' },
          { text: 'Bezerros', value: 'bezerros', align: 'center' },
          { text: 'Novilhas', value: 'novilhas', align: 'center' },
          { text: 'Novilhos', value: 'novilhos', align: 'center' },
          { text: 'Vacas', value: 'vacas', align: 'center' },
          { text: 'Boi/Touros', value: 'boi_touros', align: 'center' },
          { text: 'Total', value: 'total', align: 'center' },
          { text: 'Opções', value: 'action', width: 30, align: 'center' }
        ],

        items: [],
      };
    },

    mounted() {
      this.loadInventories();
    },

    methods: {
      async loadInventories() {
        try {
          this.$root.$progressBar.loading();

          const { data } = await this.$axios.get(`/projects/producer-inventory`);

          this.items = data.map(item => {
            return {
              id: item.id,
              producer: item.producer ? {
                id: item.producer.id_pessoa,
                name: item.producer.nome,
              } : null,
              bezerros: item.bezerros ? item.bezerros : 0,
              novilhos: item.novilhos ? item.novilhos : 0,
              boi_touros: item.boi_touros ? item.boi_touros : 0,
              bezerras: item.bezerras ? item.bezerras : 0,
              novilhas: item.novilhas ? item.novilhas : 0,
              vacas: item.vacas ? item.vacas : 0,
              total: (item.bezerros + item.novilhos + item.boi_touros + item.bezerras + item.novilhas + item.vacas)
            };
          });
        }
        catch (err) {
          console.warn(err)
        }
        finally {
          this.$root.$progressBar.hide();
        }
      },

      add() {
        this.editId = null;
        this.showDialog = true;
      },

      edit(item) {
        this.editId = item.id;
        this.showDialog = true;
      },

      async remove({ id }) {
        if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
          return;
        }
        try {
          this.$root.$progressBar.loading();

          await this.$axios.delete(`/projects/producer-inventory/${id}`);
          this.$snotify.success("Registro excluído com sucesso", "Sucesso");
        } catch (error) {
          this.$snotify.error("Erro ao excluir registro", "Atenção");
          console.warn(error);
        } finally {
          this.$root.$progressBar.hide();
          await this.loadInventories();
        }
      },

      print() {
        this.$refs.report.print(this.$refs.report.getReportJson().map(({Opções, ...rest}) => rest), 'Inventários');
      },

      exportExcel() {
        this.$refs.report.exportExcel(this.$refs.report.getReportJson().map(({Opções, ...rest}) => rest), 'Inventários');
      },

      search(data) {
        return this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
      }
    },
  };
  </script>
