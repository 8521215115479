<template>
  <v-dialog
    v-if="dialog.show"
    v-model="dialog.show"
    width="500"
    persistent
  >
    <v-card
      width="500"
      class="mx-auto"
      tile
    >
      <v-card-title>
        Inserir nova configuração
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <v-autocomplete
              v-model="form.dairy"
              :items="dairies"
              label="Laticínio"
              item-text="description"
              item-value="id"
              filled
              dense
              hide-details
              return-object
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-autocomplete
              v-model="form.lab"
              :items="labs"
              label="Laboratório"
              item-text="description"
              item-value="id"
              filled
              dense
              hide-details
              return-object
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-spacer />

      <v-card-actions>
        <v-btn
          color="grey darken-1"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay
      v-model="loading"
      absolute
    >
      Carregando...
    </v-overlay>
  </v-dialog>
</template>

<style>

</style>

<script>
import isEmpty from "lodash/fp/isEmpty";
import { v4 as uuidv4 } from "uuid";

export default {

  props: {
    labs: {
      type: Array,
      default: () => ([]),
    },

    dairies: {
      type: Array,
      default: () => ([]),
    }
  },

  data() {
    return {
      // Exibe ou não o dialog
      dialog: {
        show: false,
      },

      // loader da aplicação
      loading: false,

      form: {
        dairy: {},
        lab: {},
      },
    }
  },

  methods: {

    async show() {
      this.dialog.show = true;
    },

    close() {
      this.dialog.show = false;
      this.form = {
        dairy: {},
        lab: {},
      };
    },

    async save() {
      try {
        this.loading = true;

        const dairy = this.form.dairy;
        const lab = this.form.lab;

        if (isEmpty(dairy) || isEmpty(lab)) {
          throw new Error('Formulário inválido!');
        }

        const { data } = await this.$axios.post(`/settings/labs/dairy`, {
          id_laticinio_laboratorio: uuidv4(),
          id_laticinio: dairy.id,
          nome_laticinio: dairy.name,
          system: dairy.tenant,
          id_laboratorio: lab.id,
          nome_laboratorio: lab.description,
          identificador_laboratorio: lab.identifier,
          tipo_integracao: lab.integrationType,
          intervalo_busca: lab.dateRangeSearch,
          endpoint_integracao: lab.apiUrl,
          status: 'PENDING',
          tipo_busca_produtor: lab.producerSearchType,
          regex_codigo_laticinio: lab.parser.code,
          regex_cpf_cnpj: lab.parser.document,
          parametros: lab.parameters.map(param => ({
            id: param.id,
            chave: param.key,
            valor: null,
            descricao: param.description,
          })),
        });

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");

        this.$emit('onDairyConfigInclude', data.id_laticinio_laboratorio);

        return this.close();
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

  }

}
</script>
