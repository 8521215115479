<template>
  <visits-index
    :type="type"
    subtype="VISITA"
  />
</template>

<script>
import VisitsIndex from "@/Domains/Financial/Prices/Visits/VisitsIndex.vue";

export default {
  components: {
    VisitsIndex
  },

  props: {
    type: String,
  },
}

</script>
