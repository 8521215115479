<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="planning-list"
  >
    <v-row>
      <v-col cols="6">
        <v-card
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Histórico de planejamentos
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  v-on="on"
                  @click="onNewPlanning"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </template>

              <span>Novo planejamento</span>
            </v-tooltip>
          </v-card-title>
          <v-data-table
            :headers="plannings.headers"
            :items="plannings.items"
            dark
            class="elevation-1 report-table"
            @click:row="onSelectPlanning($event, true)"
          >
            <template #[`item.date`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.maxBonus`]="{ value }">
              R$ {{ value | formatCurrency() }}
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="onSelectPlanning(item, false)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="removePlanning(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card
          class="graph-card"
          color="transparent"
          dark
        >
          <v-card-title>
            Histórico de preços
            <v-spacer />
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  v-on="on"
                  @click="openRegister"
                >
                  <v-icon dark>
                    add
                  </v-icon>
                </v-btn>
              </template>

              <span>Novo preço</span>
            </v-tooltip>
          </v-card-title>
          <v-data-table
            :headers="prices.headers"
            :items="prices.items"
            dark
            class="elevation-1 report-table"
          >
            <template #[`item.date`]="{ value }">
              {{ value | dateFormat('DD/MM/YYYY') }}
            </template>
            <template #[`item.price`]="{ value, item }">
              <span :class="{['text-decoration-line-through']: !item.active}">
                {{ value | formatCurrency() }}
              </span>
            </template>
            <template #[`item.maxPrice`]="{ value, item }">
              <span
                v-if="value"
                :class="{['text-decoration-line-through']: !item.active}"
              >
                {{ value | formatCurrency() }}
              </span>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="onEdit(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="deletePrice(item.id)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showPriceDialog"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            {{ title }} preço do Leite
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              ref="priceForm"
              lazy-validation
              @submit.prevent="saveNewPrice"
            >
              <v-row>
                <v-col>
                  <v-menu
                    v-model="priceForm.date.picker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="priceForm.date.value | dateFormat('DD/MM/YYYY')"
                        label="Data *"
                        prepend-inner-icon="event"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="priceForm.date.value"
                      no-title
                      @input="priceForm.date.picker = false"
                    />
                  </v-menu>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="priceForm.price"
                    label="Valor Base *"
                    prefix="R$"
                    persistent-hint
                    type="number"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    @keypress="disableDot"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="priceForm.maxPrice"
                    label="Valor Máximo"
                    prefix="R$"
                    persistent-hint
                    type="number"
                    @keypress="disableDot"
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="showPriceDialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            :loading="priceForm.saving"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm ref="confirm" />
  </v-container>
</template>

<style lang="scss">
.planning-history {
  .report-card {
    > .v-card__title {
      color: #fff;
    }
  }
}
</style>

<script>
import Confirm from "@/Support/Components/Confirm.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "planning-history",

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
  },

  components: {
    Confirm
  },

  props: {
    rawMaterialType: {
      type: String,
      default: 'PRINCIPAL',
    },
    rawMaterialId: String,
  },

  data() {
    return {
      plannings: {
        headers: [
          { text: 'Data', value: 'date' },
          { text: 'Bonus máximo', value: 'maxBonus' },
          { text: '', value: 'action', width: 30 },
        ],
        items: [],
      },

      prices: {
        headers: [
          { text: 'Data', value: 'date' },
          { text: 'Valor', value: 'price' },
          { text: 'Valor Máx.', value: 'maxPrice' },
          { text: '', value: 'action', width: 30 },
        ],
        items: [],
      },

      showPriceDialog: false,

      priceForm: {
        saving: false,
        date: {
          picker: false,
        },
      },

      title: '',

      id: null,
    };
  },

  created() {
    this.loadPlannings();
  },

  methods: {
    async loadPlannings() {
      try {

        const { data } = await this.$axios.get(`/tabelaPreco/listaPlanejamentos`, {
          params: {
            tipo: this.rawMaterialType,
            id_materia_prima: this.rawMaterialId
          },
        });

        if (_.isString(data)) {
          throw "PHP error";
        }

        const { historicoPrecos, planejamentos } = data;

        this.prices.items = historicoPrecos.map(o => ({
          date: o.data,
          price: o.valor,
          maxPrice: o.valor_maximo != 0 ? o.valor_maximo : '',
          active: o.ativo,
          id: o.id_valor_leite
        }));

        this.plannings.items = planejamentos.map(o => ({
          id: o.id_tabela,
          date: o.data,
          maxBonus: o.bonus_maximo,
          selected: null,
        }));
      }
      catch (err) {
        console.warn(err)
      }
    },

    onNewPlanning() {
      this.$emit('newPlanning');
    },

    onSelectPlanning({ id }, show) {
      this.$emit('selectPlanning', id, show);
    },

    async removePlanning({ id }) {
      if (!(await this.$refs.confirm.open('Remover planejamento', 'Tem certeza que deseja remover este planejamento?', { color: 'red' }))) {
        return;
      }
      try {
        let { data } = await this.$axios.post(`/tabelaPreco/inativa`, this.$qs.stringify({ id_tabela_agrupador: id }));

        [data] = data

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.loadPlannings();
        this.$snotify.success("Dados excluídos", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir dados", "Atenção");
        console.warn(error);
      }
    },

    async save() {
      if (this.priceForm.saving) {
        return;
      }
      if (!await this.$refs.priceForm.validate()) {
        return;
      }

      try {
        this.priceForm.saving = true;

        const payload = {
          data: moment(this.priceForm.date.value).format('YYYY-MM-DD'),
          valor: parseFloat(this.priceForm.price) || 0,
          valor_maximo: parseFloat(this.priceForm.maxPrice) || null,
          tipo: this.rawMaterialType,
          id_materia_prima: this.rawMaterialId,
        };

        this.id
          ? await this.$axios.put(`/bonus/milk-price/${this.id}`, payload)
          : await this.$axios.post(`/bonus/milk-price`, payload);

        this.showPriceDialog = false;
        this.loadPlannings();
        this.$snotify.success("Preço salvo com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar preço", "Atenção");
        console.warn(error);
      } finally {
        this.priceForm.saving = false;
      }
    },

    disableDot(e) {
      if (e.charCode === 46) {
        e.preventDefault()
      }
    },

    onEdit(item) {
      this.showPriceDialog = true;

      this.title = 'Editar';
      this.id = item.id;
      this.priceForm.date.value = item.date;
      this.priceForm.maxPrice = item.maxPrice;
      this.priceForm.price = item.price;
    },

    openRegister() {
      this.showPriceDialog = true;

      this.title = 'Novo';
      this.id = null;
      this.priceForm.date.value = moment().format('YYYY-MM-DD');
      this.priceForm.maxPrice = null;
      this.priceForm.price = null;
    },

    async deletePrice(id) {
      if (!(await this.$root.$confirm('Atenção', 'Deseja realmente excluir este preço?', { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();
        await this.$axios.delete(`/bonus/milk-price/${id}`)

        this.$snotify.success('Dados excluídos', 'Sucesso');
      } catch (error) {
        this.$snotify.error(error, 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadPlannings();
      }
    }
  },
};
</script>
