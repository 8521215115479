<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      v-if="serviceName==''"
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Recomendações
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <v-flex
      v-else
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        {{ serviceName }}
      </h2>
    </v-flex>

    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-spacer />
        <v-col
          cols="12"
          md="4"
          class="pt-0"
        >
          <v-text-field
            v-model="filters.search"
            append-icon="search"
            label="Busca"
            single-line
            hide-details
            filled
          />
        </v-col>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Recommendations"
        :search="filters.search"
        class="elevation-1"
        @click:row="showDialog"
      >
        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasEditRecommendationAccess"
                @click="showDialog(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    edit
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Editar
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasDeleteRecommendationAccess"
                @click="excludeMilkCan(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    delete
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasCreateRecommendationAccess"
        fab
        color="purple"
        @click="showDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Cadastrar Recommendation
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <recommendation-dialog
      v-model="edit"
      :edit-id.sync="editId"
      :service-id="serviceId"
      :service-name="serviceName"
      :type="type"
      @save="loadRecommendations"
    />
  </v-container>
</template>

<script>
import _ from "lodash";
import RecommendationDialog from "@/Domains/Registrations/Cares/Recommendation/Components/RecommendationDialog.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

export default {
  components: {
    RecommendationDialog
  },

  mixins: [ReportMixin],

  props: {
    type: String,
    serviceId: String,
    serviceName: String,
  },

  data() {

    return {

      // Dados brutos do backend
      Recommendations: [],

      // Filtros da tabela
      filters: {
        search: "",
      },

      edit: false,
      editId: null,

    };
  },

  computed: {
    /**
     * Exibe as Recommendations filtradas
     */
    filteredRecommendations() {
      if (_.isEmpty(this.filters.search)) {
        return this.Recommendations;
      }

      return this.Recommendations.filter(Recommendation => {
        return (Recommendation.description || "").toUpperCase().includes((this.filters.search || "").toUpperCase());
      });
    },

    headers() {
      if (this.hasEditRecommendationAccess || this.hasDeleteRecommendationAccess) {
        return [
          { text: 'Descrição', value: 'description', align: 'start' },
          { text: 'Serviço', value: 'service', align: 'start' },
          { text: 'Opções', altText: 'Opções', value: 'actions', align: 'end' }
        ];
      }
      return [
        { text: 'Descrição', value: 'description', align: 'start' },
        { text: 'Serviço', value: 'service', align: 'start' },
      ]
    },

    //Definindo as Permissões de tela

    //Pega os dados de permissão do usúario.
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    //Se for admin ou ter o cargo Supervisor(Milkup) libera todos os acessos.
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    //Permissão para poder CRIAR uma Recommendation.
    hasCreateRecommendationAccess() {
      return this.isAdmin || this.userResources.some(o => (o.recurso === "veterinary-care-recommendation-create" || o.recurso === "agronomic-care-recommendation-create") && o.tipo === "COMPONENTE");
    },

    //Permissão para poder EDITAR uma Recommendation.
    hasEditRecommendationAccess() {
      return this.isAdmin || this.userResources.some(o => (o.recurso === "veterinary-care-recommendation-edit" || o.recurso === "agronomic-care-recommendation-edit") && o.tipo === "COMPONENTE");
    },

    //Permissão para poder DELETAR uma Recommendation.
    hasDeleteRecommendationAccess() {
      return this.isAdmin || this.userResources.some(o => (o.recurso === "veterinary-care-recommendation-delete" || o.recurso === "agronomic-care-recommendation-delete") && o.tipo === "COMPONENTE");
    },
  },

  watch: {
    async serviceId() {
      if (!_.isEmpty(this.serviceId)) {
        await this.loadRecommendations();
      }
    }
  },

  async mounted() {
    await this.loadRecommendations();
  },

  methods: {
    async loadRecommendations() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('registrations/recommendation', { params: {
          tipo: this.type,
          id_servico: this.serviceId,
        }
        });

        this.Recommendations = data.map(item => {
          return {
            id: item.id_recomendacao,
            description: item.descricao,
            service: item.nome_servico,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Recomendações!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Mostra o dialog para a criação/edição de uma nova Recommendation
     */
    showDialog({ id }) {
      if (!this.hasEditRecommendationAccess) {
        return;
      }

      this.edit = true;
      this.editId = id;
    },

    async excludeMilkCan(Recommendation) {

      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir ${Recommendation.description} ?`, { color: 'red' }))) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`registrations/Recommendation/${Recommendation.id}`);

        this.$snotify.success("Recommendation excluída com sucesso", "Sucesso");
        return await this.loadRecommendations();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Recommendation", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getReportJson() {
      return this.Recommendations.map(Recommendation => ({
        Descrição: Recommendation.description,
        Tipo: Recommendation.type,
      }));
    },

    exportExcel() {
      this.exportToFile({ report: this.getReportJson(), title: "Recomendações" });
    },
  }
}
</script>
