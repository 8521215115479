<template>
  <v-card
    class="mx-auto d-flex flex-column"
    dark
    :style="{background: color, overflow: 'hidden', height: height}"
    :loading="loading"
  >
    <v-card-title class="flex-grow-0">
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Volume por distância</span>
    </v-card-title>
    <v-chart
      :options="options"
      class="flex-grow-1"
      :style="{width: '100%', height: '100% !important'}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';

import QualityMixins from '@/Domains/Quality/Mixins/QualityMixins.js';

export default {

  mixins: [QualityMixins],

  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '100px',
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
    period: {
      type: Array,
      default: () => [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]
    },
  },

  data() {
    return {
      loading: false,

      ranges: {},

      report: [],
    }
  },

  computed: {
    options() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: (param) => `${param.name}
              <br>${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b>
              <br>⚈ Produtores: <b>${this.formatNumber(this.report[param.dataIndex].producers)}</b>
            `
        },
        legend: {
          bottom: '0%',
          left: 'center',
          icon: 'circle',
          textStyle: {
            color: '#ddd',
          }
        },
        series: [{
          name: 'Volume',
          type: 'pie',
          radius: [20, 110],
          center: ['50%', '50%'],

          itemStyle: {
            borderRadius: 0
          },
          label: {
            show: true,
            position: 'inside',
            formatter: '{d} %',
            textShadowColor: '#000',
            textShadowBlur: 0.5,
            textBorderWidth: 0,
            textBorderColor: 'transparent'
          },
          data: this.report
        }]
      }
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  async created() {
    await this.loadRanges()
    await this.loadReport()
  },

  methods: {

    async loadRanges() {
      try {
        this.ranges = await this.getRanges();
      } catch (error) {
        this.ranges = [];
        this.$snotify.error('Erro ao carregar as faixas', 'Atenção');
        console.warn(error);
      }
    },

    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.post(`/relatorios/resumoVolumeProdutor`, { data_inicio: startDate, data_fim: endDate });

        this.generateReport(data);

      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao carregar o relatório de impedimentos de coleta!', 'Atenção');
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    generateReport(data) {
      const ranges =  this.ranges['Distância'];

      const colorsRange = this.generateColorRanges(ranges)
        .map(({ name, color, value }) => ({
          name,
          limit: value,
          value: 0,
          producers: 0,
          itemStyle: { color }
        }));

      this.report = data
        .reduce((acc, item) => {
          for (let idx in acc) {
            if (parseFloat(item.distancia) <= acc[idx].limit) {
              acc[idx].value += parseInt(item.quantidade_coleta);
              acc[idx].producers++;
              break;
            }
          }
          return acc;
        }, colorsRange);
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  }
}
</script>
