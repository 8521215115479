<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Contas Produtor
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
      >
        <v-tab>
          Vales a Pagar
        </v-tab>

        <v-tab>
          Descontos a Receber
        </v-tab>

        <v-tab-item>
          <producer-vouchers />
        </v-tab-item>

        <v-tab-item>
          <producer-discounts />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import ProducerVouchers from "@/Domains/Financial/Producer/Views/ProducerVouchers.vue";
import ProducerDiscounts from "@/Domains/Financial/Producer/Views/ProducerDiscounts.vue";

export default {
  name: "notifications",

  components: {
    ProducerVouchers,
    ProducerDiscounts,
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>