<template>
  <div class="pt-0">
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="pb-0"
          >
            <v-date-range-picker
              v-model="filters.date.range"
              :ranges="filters.date.ranges"
              prepend-inner-icon="today"
              dark
              label="Data"
              hide-details
              filled
              :max-days="30"
              @change="onDateFilter"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pb-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              label="Busca"
              filled
              single-line
              hide-details
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pb-0"
          >
            <v-select
              v-model="filters.hasDiscount"
              :items="hasDiscountOptions"
              prepend-inner-icon="reorder"
              dark
              label="Desconto"
              hide-details
              filled
              clearable
              @change="onDateFilter"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        dark
        :headers="[
          { text:'Motorista',value:'nome_motorista'},
          { text:'Placa',value:'placa_veiculo'},
          { text:'Rota',value:'nome_rota'},
          { text:'Data/Hora',value:'data_hora_entrega'},
          { text:'Vale',value:'peso_vale'},
          { text:'Medidos',value:'peso_medido'},
          { text:'Diferença',value:'diferenca'},
          { text:'Desconto',value:'desconto'},
        ]"
        :items="dataDifference"
        class="diff-list-table"
        :search="filters.search"
      >
        <template #[`item.data_hora_entrega`]="{ item }">
          <span>
            {{ item.data_hora_entrega | dateFormat() }}
          </span>
        </template>
        <template #[`item.peso_vale`]="{ item }">
          <span>
            {{ item.peso_vale | milkFormat() }}
          </span>
        </template>
        <template #[`item.peso_medido`]="{ item }">
          <span>
            {{ item.peso_medido | milkFormat() }}
          </span>
        </template>
        <template #[`item.diferenca`]="{ item }">
          <v-tooltip
            v-if="item.diferenca < 0"
            top
          >
            <template #activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                color="red darken-1"
                v-on="on"
              >
                {{ item.diferenca | santizeDifference() }}
              </v-chip>
            </template>
            Diferença = Vale -  Medidos
          </v-tooltip>
          <v-chip
            v-else
            small
            color="teal darken-1"
          >
            {{ item.diferenca | santizeDifference() }}
          </v-chip>
        </template>
        <template #[`item.desconto`]="{ item }">
          <v-tooltip
            v-if="item.desconto < 0"
            top
          >
            <template #activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                color="red darken-1"
                v-on="on"
              >
                {{ item.desconto | santizeDifference() }}
              </v-chip>
            </template>
            (Diferença - tolerância) > 0
          </v-tooltip>
          <v-chip
            v-else
            small
            color="teal darken-1"
          >
            {{ item.desconto | santizeDifference() }}
          </v-chip>
        </template>
      </v-data-table>
      <div
        class="text-caption text-left ml-4"
        style="margin-top: -58px; height: 58px; line-height: 58px;"
      >
        Total de
        <v-chip
          small
          class="mx-2"
        >
          {{ dataDifference.length }}
        </v-chip> Descarregamentos
      </div>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import moment from "moment-timezone";
import _ from "lodash";
import XLSX from "xlsx-js-style";

export default {
  components: {
    VDateRangePicker,
  },
  filters: {
    dateFormat: (valeu) => {

      if (!valeu) return '';

      return moment(valeu).format("DD/MM/YYYY HH:mm");
    },
    milkFormat: (value) => {
      if (!value) return '';

      return parseInt(value);
    },
    santizeDifference: (value) => {

      if (_.isEmpty(value) ) {
        return 0;
      }

      return parseInt(value);
    }
  },

  data() {
    return {
      loading: false,

      filters: {
        search: '',
        route: null,
        date: {
          ranges: {
            'Hoje': [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            'Ontem': [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
            'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
            'Mês anterior': [moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD")],
          },
          range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        },
      },
      dataDifference: [],
      hasDiscountOptions: [
        { value: false, text: 'Sem Desconto' },
        { value: true, text: 'Com Desconto' }
      ],
    };
  },

  mounted() {
    this.loadDifference();
  },

  methods: {

    async loadDifference() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/descargaColeta/diferencaMedidosVale`,
          {
            data_ini: _.head(this.filters.date.range) || moment().format('YYYY-MM-DD'),
            data_fim: _.last(this.filters.date.range) || moment().format('YYYY-MM-DD'),
            com_desconto: this.filters.hasDiscount,
          }
        );

        this.dataDifference = data;
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter() {
      this.loadDifference();
    },

    exportExcel() {

      let data = XLSX.utils.json_to_sheet(this.dataDifference.map((item) => {

        return {
          Motorista: item.nome_motorista,
          Placa: item.placa_veiculo,
          Rota: item.nome_rota,
          "Data/hora": _.isEmpty(item.data_hora_entrega) ? '' : moment(item.data_hora_entrega).format('DD/MM/YYYY HH:mm'),
          Vale: _.isEmpty(item.peso_vale) ? 0 : parseInt(item.peso_vale),
          Medidos: _.isEmpty(item.peso_medido) ? 0 : parseInt(item.peso_medido),
          Diferença: _.isEmpty(item.diferenca) ? 0 : parseInt(item.diferenca),
          Desconto: _.isEmpty(item.desconto) ? 0 : parseInt(item.desconto),
          "Peso Inicial": _.isEmpty(item.peso_entrada) ? 0 : parseInt(item.peso_entrada),
          "Peso Final": _.isEmpty(item.peso_saida) ? 0 : parseInt(item.peso_saida),
          "Total de coletas": item.total_produtores,
        };
      }).filter(item => item.Vale > 0));

      const workbook = XLSX.utils.book_new();

      const filename = "diferenca_vale_medidos";

      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
  },
}
</script>
