<template>
  <v-layout
    text-xs-center
    grid-list-lg
    row
    wrap
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Simulador
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-container light>
      <v-row no-gutters>
        <v-col
          sm="2"
          md="5"
        >
          <v-card
            class="v-fontsize"
            light
            outlined
            tile
          >
            <v-col class="text-center, black--text">
              <h3 class="black--text font-weight-black">
                Informações do Laticinio
              </h3>
            </v-col>

            <v-form>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="6"
                  class="black--text"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="black--text font-weight-black"
                    >
                      <h5>Valor Médio Litro/Leite (R$)</h5>

                      <v-text-field
                        v-model="valorlitro"
                        class="barjust"
                        autocomplete="off"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="black--text font-weight-black"
                    >
                      <h5>Captação Mês Quilos</h5>

                      <v-text-field
                        v-model="capmes"
                        class="barjust"
                        autocomplete="off"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  class="black--text font-weight-black"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="black--text font-weight-black"
                    >
                      <h5>Converção Atual Litros/Quilos</h5>

                      <v-text-field
                        v-model="litrosquilo"
                        class="barjust"
                        autocomplete="off"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="black--text font-weight-black"
                    >
                      <h5>Valor Médio para Venda (R$)</h5>

                      <v-text-field
                        v-model="mediovendaquilo"
                        class="barjust"
                        autocomplete="off"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <br>
            <hr class="blue">

            <v-form>
              <br>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="6"
                  class="black--text font-weight-black"
                >
                  <h4 class="black--text font-weight-black">
                    CCS
                  </h4>
                  <br>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <h5>Atual</h5>
                      <v-text-field
                        v-model="ganhoccsatual"
                        class="barjust"
                        autocomplete="off"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <h5>Projetado</h5>
                      <v-text-field
                        v-model="ganhoccsprojetado"
                        class="barjust"
                        autocomplete="off"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="12"
                    class="black--text font-weight-black"
                  >
                    <h4 class="black--text font-weight-black">
                      CBT/CPP
                    </h4>
                    <br>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <h5>Atual</h5>
                        <v-text-field
                          v-model="ganhocbtatual"
                          class="barjust"
                          autocomplete="off"
                          type="number"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <h5>Projetado</h5>
                        <v-text-field
                          v-model="ganhocbtprojetado"
                          type="number"
                          class="barjust"
                          autocomplete="off"
                        />
                        <br>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
              <hr class="blue">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="6"
                  class="black--text font-weight-black"
                >
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <h5>Ganho Estimado Com Redução (%) A Cada 100 de CCS</h5>
                    <br>
                    <v-text-field
                      v-model="porcentccs"
                      class="barjust"
                      autocomplete="off"
                      type="number"
                    />
                  </v-col>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="black--text font-weight-black"
                >
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <h5>Ganho Estimado Com Redução (%) A Cada 100 de CPP</h5>
                    <br>
                    <v-text-field
                      v-model="porcentcbt"
                      class="barjust"
                      autocomplete="off"
                      type="number"
                    />
                  </v-col>
                </v-col>
              </v-row>
              <br>
              <hr class="blue">

              <v-form>
                <hr
                  size="2"
                  class="blue"
                >

                <v-col cols="11">
                  <v-btn
                    class="v-calcbot"
                    color="indigo"
                    dark
                    @click="resetVarCcs()"
                  >
                    Novo Cálculo
                  </v-btn>
                </v-col>
              </v-form>
            </v-form>
          </v-card>
        </v-col>

        <v-col
          cols="6"
          md="7"
        >
          <v-card
            class="v-fontsize2"
            light
            outlined
            tile
          >
            <v-col class="text-center, black--text">
              <h3 class="black--text font-weight-black">
                Resultado
              </h3>
              <h4>Conversão Projetado Litros/Quilos</h4>
            </v-col>

            <br>
            <hr class="blue">
            <v-row>
              <v-col>
                <v-col
                  cols="12"
                  class="centraliz black--text font-weight-black"
                >
                  <p>CCS</p>
                  <v-data class="barjust">
                    {{ ccsconverse.toFixed(2) }}
                  </v-data>
                </v-col>
              </v-col>
              <hr
                size="155"
                width="2"
                class="blue"
              >
              <v-col>
                <v-col
                  cols="12"
                  class="centraliz black--text font-weight-black"
                >
                  <p>CPP</p>
                  <v-data class="barjust">
                    {{ cbtconverse.toFixed(2) }}
                  </v-data>
                </v-col>
              </v-col>

              <hr
                size="155"
                width="2"
                class="blue"
              >

              <v-col class="centraliz black--text font-weight-black">
                <p>Produção Atual (Mês) Quilos</p>
                <v-data>{{ produtoAtual.toFixed(2) }}</v-data>
              </v-col>
              <hr
                size="155"
                width="2"
                class="blue"
              >
              <v-col>
                <v-col class="black--text font-weight-black">
                  <p>Valor Atual Da Produção (Mês)</p>
                  <v-data>{{ formataDinheiro(valorAtualproduto) }}</v-data>
                </v-col>
              </v-col>
            </v-row>

            <hr
              size="5"
              class="blue"
            >

            <v-row>
              <v-col>
                <v-col
                  cols="12"
                  class="centraliz black--text font-weight-black"
                >
                  <p>CCS</p>
                  <p>Produção Estimada (Mês) Quilos</p>
                  <v-data>{{ produtoEstimadaccs.toFixed(2) }}</v-data>
                </v-col>
              </v-col>
              <hr
                size="225"
                width="2"
                class="blue"
              >
              <v-col>
                <v-col
                  cols="12"
                  class="centraliz black--text font-weight-black"
                >
                  <p>CPP</p>
                  <p>Produção Estimada (Mês) Quilos</p>
                  <v-data>{{ produtoEstimadacbt.toFixed(2) }}</v-data>
                </v-col>
              </v-col>

              <hr
                size="225"
                width="2"
                class="blue"
              >
              <v-col>
                <v-col class="centralizc black--text font-weight-black green--text fontsize">
                  <p class="black--text">
                    CCS/CPP Produção Estimada Consolidada
                  </p>

                  <v-data>{{ produtoEstimadacons.toFixed(2) }}</v-data>
                </v-col>
              </v-col>
              <hr
                size="225"
                width="2"
                class="blue"
              >
              <v-col>
                <v-col class="centralizb black--text font-weight-black green--text fontsize">
                  <p class="black--text">
                    Estimado Consolidada CSS/CPP
                  </p>
                  <v-data>{{ formataDinheiro(valorEstimadocons) }}</v-data>
                </v-col>
              </v-col>
            </v-row>

            <hr
              size="5"
              class="blue"
            >

            <v-row>
              <v-col>
                <v-col
                  cols="12"
                  class="centralizc black--text font-weight-black"
                >
                  <p>Valor Estimada CCS (Mês) Quilos</p>
                  <v-data>{{ formataDinheiro(valorEstimadaccs) }}</v-data>
                </v-col>
              </v-col>
              <hr
                size="169"
                width="2"
                class="blue"
              >
              <v-col>
                <v-col
                  cols="12"
                  class="centralizc black--text font-weight-black"
                >
                  <p>Valor Estimada CPP (Mês) Quilos</p>
                  <v-data>{{ formataDinheiro(valorEstimadacbt) }}</v-data>
                </v-col>
              </v-col>
              <hr
                size="169"
                width="2"
                class="blue"
              >
              <v-col>
                <v-col class="centralizc black--text font-weight-black green--text fontsize">
                  <p class="black--text">
                    Ganho de Produção Quilos
                  </p>
                  <v-data class="green--text fontsize">
                    {{ Math.abs(ganhodeProducao).toFixed(2) }}
                  </v-data>
                </v-col>
              </v-col>
              <hr
                size="169"
                width="2"
                class="blue"
              >
              <v-col>
                <v-col class="centralizb black--text font-weight-black green--text fontsize">
                  <p class="black--text">
                    Ganho Financeiro
                  </p>
                  <v-data>{{ formataDinheiro(valorGanhofinanc) }}</v-data>
                </v-col>
              </v-col>
            </v-row>
            <hr class="blue">
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "collect-simulator",

  data() {
    return {
      capmes: 0,
      prodccs: 0,
      prodcbt: 0,
      porcentccs: 0,
      porcentcbt: 0,
      litrosquilo: 0,
      mediovendaquilo: 0,
      valorlitro: 0,
      ccsconverse: 0,
      cbtconverse: 0,
      ganhoccsprojetado: 0,
      ganhoccsatual: 0,
      ganhocbtprojetado: 0,
      ganhocbtatual: 0,
      indcss: 0,
      indcbt: 0,
      ccsprojetado: null,
      cbtprojetado: null,
      ccsprocmax: null,
      cbtprocmax: null,
      cont: null,
      resultaredccs: null,
      resultaredcbt: null,
      produtoestimadoccs: null,
      produtoestimadacbt: null,
      produtoestimadacons: null,
      ganhodeproducao: null,
      valorestimadaccs: null,
      valorestimadacbt: null,
      valorestimadocons: null,
      valorganhofinanc: null,
    };
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    ccsConv() {
      var ccsAtual2 = this.ganhoccsatual;
      var ccsprojetado2 = this.ganhoccsprojetado;
      var indcss2 = this.indcss;

      if (ccsAtual2 < 100 || ccsprojetado2 < 100) {
        indcss2 = 0;
      } else {
        if (ccsAtual2 >= ccsprojetado2) {
          while (ccsAtual2 > ccsprojetado2) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.resultacss = (this.porcentccs / 100) * this.litrosquilo;
            indcss2 = indcss2 + 1;
            ccsAtual2 = ccsAtual2 - 100;
            if (ccsAtual2 <= ccsprojetado2) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.ccsconverse = (this.resultacss * indcss2 - this.litrosquilo) * -1;
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.prodccs = this.capmes / this.ccsconverse;

              return;
            }
          }
        } else {
          while (ccsprojetado2 >= ccsAtual2) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.resultacss = (this.porcentccs / 100) * this.litrosquilo;
            indcss2 = indcss2 + 1;
            ccsprojetado2 = ccsprojetado2 - 100;
            if (ccsprojetado2 <= ccsAtual2) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.ccsconverse = this.resultacss * indcss2 - this.litrosquilo;
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.prodccs = this.capmes / this.ccsconverse;

              return;
            }
          }
        }
      }
    },

    // eslint-disable-next-line vue/return-in-computed-property
    cbtConv() {
      var cbtAtual2 = this.ganhocbtatual;
      var cbtprojetado2 = this.ganhocbtprojetado;
      var indcbt2 = this.indcbt;

      if (cbtAtual2 < 100 || cbtprojetado2 < 100) {
        indcbt2 = 0;
      } else {
        if (cbtAtual2 >= cbtprojetado2) {
          while (cbtAtual2 > cbtprojetado2) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.resultacbt = (this.porcentcbt / 100) * this.litrosquilo;
            indcbt2 = indcbt2 + 1;
            cbtAtual2 = cbtAtual2 - 100;
            if (cbtAtual2 <= cbtprojetado2) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.cbtconverse = (this.resultacbt * indcbt2 - this.litrosquilo) * -1;
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.prodcbt = this.capmes / this.cbtconverse;

              return;
            }
          }
        } else {
          while (cbtprojetado2 >= cbtAtual2) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.resultacbt = (this.porcentcbt / 100) * this.litrosquilo;
            indcbt2 = indcbt2 + 1;
            cbtprojetado2 = cbtprojetado2 - 100;
            if (cbtprojetado2 <= cbtAtual2) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.cbtconverse = this.resultacbt * indcbt2 - this.litrosquilo;
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.prodcbt = this.capmes / this.cbtconverse;
            }
          }
        }
      }
    },

    produtoEstimadaccs() {
      return this.ccsConv, this.prodccs || 0;
    },
    produtoEstimadacbt() {
      return this.cbtConv, this.prodcbt || 0;
    },
    produtoAtual() {
      return this.capmes / this.litrosquilo || 0;
    },

    produtoEstimadacons() {
      return (this.produtoEstimadacbt + this.produtoEstimadaccs) / 2 || 0;
    },
    ganhodeProducao() {
      return this.produtoAtual - this.produtoEstimadacons || 0;
    },
    valorEstimadaccs() {
      return this.mediovendaquilo * this.produtoEstimadaccs || 0;
    },
    valorEstimadacbt() {
      return this.mediovendaquilo * this.produtoEstimadacbt || 0;
    },
    valorEstimadocons() {
      return (this.valorEstimadaccs + this.valorEstimadacbt) / 2 || 0;
    },
    valorAtualproduto() {
      return this.produtoAtual * this.mediovendaquilo || 0;
    },
    valorGanhofinanc() {
      return this.valorEstimadocons - this.valorAtualproduto || 0;
    },
  },
  methods: {

    formataDinheiro(n) {
      return (
        "R$ " +
        n
          .toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+,)/g, "$1.")
      );
    },

    resetVarCcs() {
      this.produtoestimadoccs = "";
      this.produtoestimadocbt = "";
      this.produtoestimadacons = "";
      this.ganhodeproducao = "";
      this.valorestimadaccs = "";
      this.valorestimadacbt = "";
      this.valorestimadocons = "";
      this.valorganhofinanc = "";
      this.prodccs = 0;
      this.prodcbt = 0;
      this.capmes = 0;
      this.ganhoccsprojetado = 0;
      this.ganhoccsatual = 0;
      this.ganhocbtprojetado = 0;
      this.ganhocbtatual = 0;
      this.porcentccs = 0;
      this.porcentcbt = 0;
      this.litrosquilo = 0;
      this.mediovendaquilo = 0;
      this.valorlitro = 0;
      this.ccsprojetado = null;
      this.cbtprojetado = null;
      this.convprojccs = null;
      this.convprojcbt = null;
      this.cont = null;
      this.reduxcss = null;
      this.reduxcbt = null;
      this.indcss = null;
      this.indcbt = null;
      this.resultadocss = null;
      this.resultadocbt = null;
      this.ccsconverse = 0;
      this.cbtconverse = 0;
    },
  },
};
</script>
