<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Importar Análises
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <analysis-import-result
        v-model="importResult.show"
        :successes="importResult.successes"
        :failures="importResult.failures"
      />

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <v-date-range-picker
                  v-model="filters.dateRange"
                  dark
                  @change="loadReport"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="py-0"
              >
                <v-text-field
                  v-model="filters.search"
                  append-icon="search"
                  label="Buscar"
                  filled
                  hide-details
                  dark
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filters.search"
            dark
            dense
          >
            <template #[`item.data_coleta`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.data_analise`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY') }}
            </template>
            <template #[`item.data_hora_registro`]="{ value }">
              {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
            </template>
            <template #[`item.ccs`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.cbt`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.caseina`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.gordura`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.ureia`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.lactose`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.proteina`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.esd`]="{ value }">
              {{ formatNumber(value) }}
            </template>
            <template #[`item.solidos_totais`]="{ value }">
              {{ formatNumber(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <import-excel-dialog
      v-model="importAnalysis.show"
      title="Importar análises"
      :fields="importFields"
      show-model
      @import="onImportAnalysis"
    />

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-5"
          v-on="on"
          @click="showImportAnalysis()"
        >
          <v-icon>
            system_update_alt
          </v-icon>
        </v-btn>
      </template>
      Importar Análises
    </v-tooltip>
  </v-container>
</template>

<script>
import moment from "moment-timezone";

import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ImportExcelDialog from '@/Support/Components/ImportExcelDialog.vue';
import AnalysisImportResult from '@/Domains/Analysis/Upload/Components/AnalysisImportResult.vue';
import AnalysisImportMixin from '@/Domains/Analysis/Upload/Mixins/AnalysisImportMixin.js';

export default {

  components: {
    VDateRangePicker,
    AnalysisImportResult,
    ImportExcelDialog,
  },

  mixins: [AnalysisImportMixin],

  data() {
    return {

      filters: {
        search: '',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      headers: [
        { text: 'Tipo', value: 'tipo', align: 'start' },
        { text: 'Data de Coleta', value: 'data_coleta', align: 'center' },
        { text: 'Data da Análise', value: 'data_analise', align: 'center' },
        { text: 'Nome', value: 'nome', align: 'start' },
        { text: 'CCS', value: 'ccs', align: 'center' },
        { text: 'CPP', value: 'cbt', align: 'center' },
        { text: 'Caseína', value: 'caseina', align: 'center' },
        { text: 'Gordura', value: 'gordura', align: 'center' },
        { text: 'Uréia', value: 'ureia', align: 'center' },
        { text: 'Lactose', value: 'lactose', align: 'center' },
        { text: 'Proteina', value: 'proteina', align: 'center' },
        { text: 'ESD', value: 'esd', align: 'center' },
        { text: 'S.T', value: 'solidos_totais', align: 'center' },
        { text: 'Amostra', value: 'numero_amostra', align: 'center' },
        { text: 'Data Importação', value: 'data_hora_registro', align: 'center' }
      ],

      items: [],
    };

  },

  methods: {
    async loadReport() {
      try {
        this.$root.$progressBar.loading();

        const [dateStart, dateEnd] = this.filters.dateRange;

        const { data } = await this.$axios.get(`/analysis/import`, {
          params: {
            date_start: dateStart,
            date_end: dateEnd,
          }
        });

        this.items = data;

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value) => !value ? '-' : new Intl.NumberFormat('pt-BR').format(value),
  },

}
</script>
