<template>
  <v-card
    flat
    class="card-table mr-1 ml-1 mt-4 mb-4"
  >
    <v-card-title v-if="label">
      {{ label }}
    </v-card-title>
    <v-data-table
      class="table-custom"
      :headers="headers"
      :items="items"
      hide-default-footer
      dense
    >
      <template #[`header.plus`]>
        <v-btn
          icon
          color="primary"
          @click="createColumn"
        >
          <v-icon
            color="primary"
          >
            add_circle_outline
          </v-icon>
        </v-btn>
      </template>

      <template
        v-for=" (column,index) in customColumns"
        #[`header.${column.campo}`]="{ header }"
      >
        <v-text-field
          :key="index"
          v-model="column.descricao"
          class="pa-0 my-1"
          dense
          hide-details
        >
          <template
            v-if="editable"
            v-slot:append-outer
          >
            <v-btn
              icon
              small
              color="red lighten-3"
              @click="removeColumn(header)"
            >
              <v-icon
                color="red lighten-3"
              >
                remove_circle_outline
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </template>

      <template
        v-for=" (column,index) in customColumns"
        #[`item.${column.campo}`]="{ item }"
      >
        <div
          :key="index"
          class="row-custom"
        >
          <v-text-field
            v-model="item[column.campo]"
            class="mb-1"
            dense
            hide-details
          />
        </div>
      </template>

      <template
        v-for=" (column,index) in standardColumns"
        #[`item.${column.value}`]="{ item }"
      >
        <span
          v-if="index === 0 "
          :key="index"
        >
          {{ item[column.value] }}
        </span>

        <div
          v-else
          :key="index"
          class="row-custom"
        >
          <v-text-field
            v-model="item[column.value]"
            class="ma-2"
            dense
            hide-details
          />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<style lang="scss">
  .card-table{
    border: 1px solid #ccc !important;
    border-radius: 4px !important;

    .table-custom{
      table{
        thead > tr {
          span{
            font-family: Roboto, Arial, sans-serif;
            font-size: 0.9rem;
            font-weight: 700;
          }
          background: rgba(226, 225, 225, 0.5);
        }
      }
    }

    .row-custom{
      padding-right: 20px !important;
    }
  }
  
</style>

<script>
import  * as _ from "lodash";

export default {
  name: "CustomDataTable",
  props: {
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    typeData: {
      type: String,
    },
    typeTable: {
      type: String,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    standardColumns: {
      type: Array,
      default: () => [],
    },
    customColumns: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    
    };
  },
  computed: {
    /**
     * Mostra um array com os headers correspondentes aos parâmetros passados
     */
    headers() {

      if (!_.isEmpty(this.customColumns)) {
        const customColumns = this.customColumns.map(column => {
          return { value: column.campo, text: column.descricao, customField: true, sortable: false, width: 180 };
        });

        if (this.editable) {
          return [
            ...this.standardColumns,
            ...customColumns,
            { text: '', value: 'plus', align: 'center', sortable: false, width: 30 }
          ]
        }
        
        return [...this.standardColumns, ...customColumns]
      }

      return this.standardColumns.map( standard => ({ ... standard, width: 190 }));
    }
  },
  methods: {

    /**
     * @void
     * Cria nova coluna na tabela
     */
    createColumn() {

      this.$emit("onCreateNewColumn", {
        tableType: this.typeTable,
        dataType: this.typeData
      });
    },
    
    /**
     * @void
     * Evento para remoção de coluna
     */
    removeColumn(header) {
      
      // remove o campo de objeto de data
      this.items.forEach(element => {
        delete element[header.value];
      });

      this.$emit("onDeleteColumn", {
        tableType: this.typeTable,
        dataType: this.typeData,
        columnName: header.value,
      });
    },

  }
};
</script>
