<template>
  <v-dialog
    v-model="dialog.show"
    persistent
    max-width="630px"
  >
    <v-card>
      <v-card-title class="px-3 pt-0">
        <v-row>
          <v-col cols="12">
            <span class="text-h6">
              Gerar devolução de Spot
            </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            v-if="unload.itinerary"
            cols="3"
            class="pb-0"
          >
            <v-text-field
              :value="unload.itinerary.code"
              prepend-inner-icon="map"
              label="Itinerário"
              disabled
            />
          </v-col>
          <v-col
            v-if="unload.route"
            cols="9"
            class="pb-0"
          >
            <v-text-field
              :value="unload.route.description"
              prepend-inner-icon="business"
              label="Empresa"
              disabled
            />
          </v-col>
          <v-col
            v-if="unload.vehicle"
            cols="6"
            class="pb-0"
          >
            <v-text-field
              :value="unload.vehicle.plate"
              prepend-inner-icon="local_shipping"
              label="Veículo"
              disabled
            />
          </v-col>
          <v-col
            v-if="unload.driver"
            cols="6"
            class="pb-0"
          >
            <v-text-field
              :value="unload.driver.name"
              prepend-inner-icon="person"
              label="Motorista"
              disabled
            />
          </v-col>
          <v-col
            cols="6"
            class="pb-0"
          >
            <v-text-field
              :value="unload.arrivalTime"
              prepend-inner-icon="access_time"
              label="Início Itinerário"
              disabled
            />
          </v-col>
          <v-col
            v-if="unload.rawProduct"
            cols="6"
            class="pb-0"
          >
            <raw-material-autocomplete
              :value="unload.rawProduct.id"
              label="Matéria Prima"
              placeholder=" "
              disabled
            />
          </v-col>
          <template v-if="unload.tanks">
            <v-col
              v-for="tank of unload.tanks"
              :key="tank.tank"
              class="pb-0"
            >
              <v-checkbox
                v-model="tanks"
                :label="tank.description"
                :value="tank.tank"
                hide-details
              />
            </v-col>
          </template>
          <v-col
            cols="12"
            class="text-center"
            :class="{ 'has-error': hasError }"
          >
            Selecione os tanques devolvidos
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          CANCELAR
        </v-btn>

        <v-spacer />

        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          SALVAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.has-error {
  animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: red;
}
</style>

<script>
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';

import get from 'lodash/get'

export default {

  components: {
    RawMaterialAutocomplete,
  },

  data() {
    return {
      unload: {},

      tanks: [],

      hasError: false,

      dialog: {
        show: false,
      },
    }
  },

  methods: {

    show(unload) {
      this.dialog.show = true;
      this.unload = {
        ...unload
      }
      this.loadSpot(unload.id);
    },

    close() {
      this.dialog.show = false;
    },

    async loadSpot(id) {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/descargaColeta/descargaDetalhe`, {
          id_descarga_coletas: id
        });

        this.unload = {
          ...this.unload,
          tanks: data.detalhe.map(tank => ({
            tank: tank.tanque,
            description: `Tanque ${(tank.tanque).replace(/\D/g, '')}: ${parseInt(tank.volume_liquido)} L`,
          })).sort((a, b) => a.tank.localeCompare(b.tank))
        };

      } catch (e) {
        const message = get(e, 'response.data.message', 'Oops, ocorreu um erro ao carregar os tanques');
        this.$snotify.error(message, 'Atenção');
        console.log(e);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async save() {
      this.hasError = this.tanks.length === 0;

      if (this.hasError) {
        setTimeout(() => {
          this.hasError = false;
        }, 2000);

        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.post(`/descargaColeta/gerarDevolucaoSpot`, {
          id: this.unload.id,
          tanques: this.tanks,
        });

        this.$snotify.success('Devolução gerada com sucesso', 'Sucesso');
        this.close();
      } catch (e) {
        const message = get(e, 'response.data.message', 'Oops, ocorreu um erro ao gerar a devolução');
        this.$snotify.error(message, 'Atenção');
        console.log(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

  },

}
</script>

