<template>
  <v-card
    class="mx-auto"
    dark
    :style="{background: color}"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        show_chart
      </v-icon>
      <span class="title font-weight-light">Total Frete por Unidade</span>
      <v-spacer />
      <span class="font-weight-light">
        {{ dateFormat(referenceMonth, 'MMM/YYYY') }}
      </span>
    </v-card-title>
    <v-chart
      :options="options"
      :style="{width: '100%', height: `${height} !important`}"
      autoresize
    />
  </v-card>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  name: "unit-freight-chart",

  props: {
    color: {
      type: String,
      default: '#26c6da'
    },
    height: {
      type: String,
      default: '100px',
    },
    smooth: {
      type: [Number, Boolean],
      default: true
    },
    referenceMonth: {
      type: String,
      default: () => moment().subtract(1, "month").format("YYYY-MM-DD"),
    },
  },

  data() {
    return {
      loading: false,
      items: [],
    }
  },

  computed: {
    options() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: (param) => [
            param.name,
            `${param.marker} ${param.seriesName}: <b>${this.formatCurrency(param.value)}</b> (${param.percent}%)`,
          ].join('<br>')
        },
        series: [{
          name: 'Fretes',
          type: 'pie',
          radius: [20, 120],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 5
          },
          label: {
            color: '#fff',
            textBorderWidth: 0,
            textBorderColor: 'transparent',
            textShadowBlur: 0
          },
          data: this.items
        }]
      }
    }
  },

  watch: {
    referenceMonth() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/freight/reports/total-by-unit`, { params: {
          month_reference: this.referenceMonth
        } });

        this.items = data.map(item => ({ name: item.nome_laticinio, value: item.total }));
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o gráfico de fretes por unidade!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
    dateFormat: (value, format) => !value ? '-' : _.upperFirst(moment(value).format(format)),
  }
}
</script>
