<template>
  <v-card
    :width="dialog.width"
    :min-height="dialog.height"
    tile
    class="d-flex flex-column"
  >
    <v-card-title>Execução Manual</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-date-range-picker
            v-model="period"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-select
            v-model="executionType"
            :items="executionTypeOptions"
            label="Tipo de Execução"
            filled
            hide-details
          />
        </v-col>

        <v-col
          v-if="executionType === 'DIRECT'"
          cols="4"
        >
          <v-select
            v-model="persistType"
            :items="persistentOptions"
            label="Modo de Execução"
            filled
            hide-details
          />
        </v-col>

        <v-col
          v-if="executionType === 'SERIALIZER'"
          cols="4"
        >
          <v-text-field
            v-model="dateRangeSearch"
            label="Divisor em Dias"
            filled
            hide-details
            :loading="isLoading"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2"
            class="mb-0"
          >
            <span v-html="executionTypeHint" />
          </v-alert>
        </v-col>
      </v-row>

      <v-spacer />

      <v-row v-if="executionResults.show">
        <v-col cols="12">
          <v-alert
            icon="download"
            prominent
            text
            type="info"
            dense
          >
            <v-row align="center">
              <v-col class="grow">
                Foram encontrados <b>{{ executionResults.totalResults }}</b> resultados
              </v-col>
              <v-col class="shrink">
                <v-btn
                  v-if="executionResults.totalResults > 0"
                  @click="downloadFile(executionResults.url)"
                >
                  Download
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>

    <v-spacer />

    <v-card-actions>
      <v-btn
        color="grey darken-1"
        text
        @click="close()"
      >
        Fechar
      </v-btn>

      <v-spacer />

      <v-btn
        color="purple darken-1"
        text
        @click="run()"
      >
        Executar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import moment from "moment";

const ExecutionType = {
  SERIALIZER: 'SERIALIZER',
  DIRECT: 'DIRECT',
};

export default {

  components: {
    VDateRangePicker,
  },

  props: {
    dialog: {
      type: Object,
    },

    settings: {
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      period: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      ],

      executionType: ExecutionType.DIRECT,

      persistentOptions: [
        {
          text: "Download",
          value: 'DRY_RUN',
        },
        {
          text: "Salvar",
          value: 'PERSIST',
        },
      ],

      executionTypeOptions: [
        {
          text: "Direta",
          value: ExecutionType.DIRECT,
        },
        {
          text: "Serializador",
          value: ExecutionType.SERIALIZER,
        },
      ],

      dateRangeSearch: 10,

      persistType: 'DRY_RUN',

      executionResults: {
        show: false,
        totalResults: 0,
        url: '',
      },
    }
  },

  computed: {
    isLoading: {
      get() {
        return this.loading;
      },
      set(loading) {
        return this.$emit('update:loading', loading);
      },
    },

    executionTypeHint() {
      if (this.executionType === ExecutionType.SERIALIZER) {
        return `
          Este tipo de execução permite buscas com ranges grandes, como 2 ou 3 meses por exemplo. <br />
          Para isto o range de busca será quebrado conforme o campo "Divisor em Dias". <br/>
          Recomenda-se utilizar ranges de busca baixos, menores que 10 dias, para evitar problemas de timeout em alguns laboratórios.
         `;
      }

      return `
        Este tipo de execução ocorre diretamente na API do Laboratório, o range de datas selecionado será utilizado para a busca. <br />
        <b>* Tomar cuidado com a quantidade de dias selecionados</b>
      `;
    },
  },

  methods: {
    async run() {
      try {
        this.isLoading = true;

        const [startDate, endDate] = this.period;

        const { data } = await this.$axios.put(`/settings/labs/dairy/${this.settings.id}/run`, {
          date_start: startDate,
          date_end: endDate,
          dry_run: this.persistType === 'DRY_RUN',
          date_range_search: this.dateRangeSearch,
          execution_type: this.executionType,
        });

        if (this.executionType === ExecutionType.DIRECT && this.persistType === 'DRY_RUN') {
          this.executionResults = {
            show: true,
            totalResults: data.results,
            url: data.resultsUrl,
          }
        }
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    close() {
      return this.$emit('close');
    },

    downloadFile: (file) => window.open(file, '_blank'),
  }
}
</script>
