<template>
  <div class="px-8 px-md-16 mx-lg-6">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          PAE
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
        @change="onTabChange"
      >
        <v-tab href="#quality">
          Qualidade
        </v-tab>
        <v-tab href="#emergency">
          Emergenciais
        </v-tab>
        <v-tab href="#visits-graph">
          Visitas
        </v-tab>
        <v-tab href="#visits-month">
          Visitados X Não visitados
        </v-tab>
        <v-tab href="#history">
          Configuração
        </v-tab>
        <v-tab
          href="#planning"
          :disabled="!planning.selected"
        >
          <v-icon>settings</v-icon>
        </v-tab>
        <v-tab-item value="quality">
          <quality-report
            ref="quality"
          />
        </v-tab-item>
        <v-tab-item value="emergency">
          <emergency-tab
            ref="emergency"
          />
        </v-tab-item>
        <v-tab-item value="visits-graph">
          <visits-report
            ref="visits"
          />
        </v-tab-item>
        <v-tab-item value="visits-month">
          <visits-report-details
            ref="visits-report"
            view="goal"
            project="PQFL"
          />
        </v-tab-item>

        <v-tab-item value="history">
          <list-goals
            ref="history"
            project="PQFL"
            @newPlanning="onNewPlanning"
            @selectPlanning="onSelectPlanning"
          />
        </v-tab-item>

        <v-tab-item value="planning">
          <goals
            ref="planning"
            v-model="planning.id"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import ListGoals from "@/Domains/Projects/ListGoals.vue";
import Goals from "@/Domains/Projects/PQFL/PAE/Views/Goals.vue";
import EmergencyTab from "@/Domains/Projects/PQFL/PAE/Components/EmergencyTab.vue";
import QualityReport from "@/Domains/Projects/PQFL/PAE/Components/QualityReport.vue";
import VisitsReport from "@/Domains/Projects/PQFL/PAE/Components/VisitsReport.vue";
import VisitsReportDetails from "@/Domains/Projects/Visits/VisitsReportDetails.vue";

export default {
  components: {
    ListGoals,
    Goals,
    EmergencyTab,
    QualityReport,
    VisitsReport,
    VisitsReportDetails,
  },

  props: ["parent"],

  data() {
    return {
      tab: null,

      planning: {
        selected: false,
        id: null
      }
    };
  },

  methods: {
    /**
     * @event void
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange() {
      if (this.tab == 'history' && this.$refs.history) {
        this.$refs.history.loadHistory()
      }
      this.planning.selected = false;
      this.planning.id = null;

      if (this.tab == 'emergency' && this.$refs.emergency) {
        this.$refs.emergency.loadPae()
      }
      if (this.tab == 'quality' && this.$refs.quality) {
        this.$refs.quality.loadPae()
      }
      if (this.tab == 'visits-graph' && this.$refs.quality) {
        this.$refs.visits.loadGoals()
      }
    },

    onNewPlanning() {
      this.planning.selected = true;
      this.planning.id = null;
      this.tab = 'planning';
    },

    onSelectPlanning(id) {
      this.planning.selected = true;
      this.planning.id = id;
      this.tab = 'planning';
    },

    navigateBack() {
      this.$store.commit("setCurrentMenu", this.parent.name);
      this.$store.commit("setCurrentMenuBackground", this.parent.background);
    },
  },
};
</script>
