<template>
  <div>
    <v-numeric-field
      v-if="useInput"
      ref="input"
      v-model.number="model"
      :label="inputLabel"
      placeholder=" "
      :hint="_hint"
      :error="error"
      :error-messages="errorMessages"
      :class="{ 'warning-input': error && restriction }"
      return-unmasked
      persistent-hint
      :autofocus="autofocus"
      :an-options="inputOptions"
      :disabled="disabled"
      inputmode="numeric"
      @change="onChange"
      @keydown.enter="onEnter"
      @focus="$event => $emit('focus', $event)"
    />
    <v-text-field
      v-if="useTextInput"
      ref="input"
      v-model="model"
      v-maska
      :data-maska="mask"
      :data-maska-reversed="reversedMask"
      :label="inputLabel"
      placeholder=" "
      :hint="_hint"
      :error="error"
      :error-messages="errorMessages"
      :class="{ 'warning-input': error && restriction }"
      return-unmasked
      persistent-hint
      :autofocus="autofocus"
      :an-options="inputOptions"
      :disabled="disabled"
      inputmode="numeric"
      @change="onChange"
      @keydown.enter="onEnter"
      @focus="$event => $emit('focus', $event)"
      @maska="onInput"
      v-on="{ ...$listeners, input: () => {} }"
    />
    <v-checkbox
      v-if="useCheckbox"
      ref="checkbox"
      v-model="model"
      :label="inputLabel"
      :hint="_hint"
      :error="error"
      :error-messages="errorMessages"
      :class="[cssClass, 'analysis-checkbox']"
      :disabled="disabled"
      :indeterminate="altCheckbox && model === false"
      persistent-hint
      @change="onChange"
      @keydown.enter.stop="onEnter"
    />
  </div>
</template>

<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.warning-input {
  .v-input__slot:after, .v-input__slot:before {
    border-color: orange !important;
  }
  .v-text-field__slot .error--text, .v-messages__message {
    color: orange !important;
  }
}

.analysis-checkbox.v-input--indeterminate {
  .v-label, .v-icon, .v-messages__message {
    color: #1976d2 !important;
  }
}
</style>

<script>
import * as _ from "lodash";

import AnalysisPatternValidator from "@/Domains/Platform/Unload/Services/AnalysisPatternValidator.js";
import VNumericField from "@/Support/Components/VNumericField.vue";

const hintMapping = {
  'Característico': 'Não Característico',
  'Ausência': 'Presença',
  'Presença': 'Ausência',
  'Positivo': 'Negativo',
  'Negativo': 'Positivo'
}

export default {

  name: 'AnalysisInput',

  components: {
    VNumericField,
  },

  filters: {

  },

  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {
      type: [ String, Number, Boolean ],
    },
    rule: {
      type: [ Object, Boolean, Number ],
    },
    restrictionRule: {
      type: [ Object, Boolean, Number ],
    },
    hint: {
      type: String,
    },
    unit: {
      type: String,
    },
    useInput: {
      type: Boolean,
      default: false,
    },
    useTextInput: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
    },
    reversedMask: {
      type: Boolean,
    },
    returnUnmasked: {
      type: Boolean,
    },
    useCheckbox: {
      type: Boolean,
      default: false,
    },
    altCheckbox: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    cssClass: {
      type: String,
    },
    decimalPlaces: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {

    inputOptions() {
      return {
        alwaysAllowDecimalCharacter: true,
        caretPositionOnFocus: 'end',
        decimalCharacter: ',',
        decimalCharacterAlternative: '.',
        digitGroupSeparator: '',
        selectOnFocus: false,
        allowDecimalPadding: false,
        decimalPlaces: this.decimalPlaces,
        emptyInputBehavior: "null",
        showWarnings: false,
      };
    },

    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        return this.$emit('input', newValue);
      }
    },

    inputLabel() {

      if (_.isEmpty(this.unit)) {
        return this.label;
      }

      return `${this.label} (${this.unit})`;
    },

    _hint() {

      if (!this.settings.showAnalysisPatternRange) {
        return '';
      }

      if (this.hint) {
        if (this.model === true && hintMapping[this.hint]) {
          return hintMapping[this.hint]
        }

        return this.hint;
      }

      if (_.isBoolean(this.rule)) {
        if (this.model === true) {
          return this.rule ? 'Negativo' : 'Positivo';
        }

        return this.rule ? 'Positivo' : 'Negativo';
      }

      if (!_.isObject(this.rule)) {
        return '';
      }

      const max = (this.rule.max || '').toString().replace('.', ',');
      const min = (this.rule.min || '').toString().replace('.', ',');

      if (this.rule.max && this.rule.min) {
        return `Entre ${(min || '').toString().replace('.', ',')} e ${max} ${this.unit || ''}`;
      }

      if (this.rule.max) {
        return `Máximo ${max} ${this.unit || ''}`;
      }

      if (this.rule.min) {
        return `Mínimo ${min} ${this.unit || ''}`;
      }

      return '';
    },

    error() {
      return !(new AnalysisPatternValidator().fromRule(this.rule).validate(this.model));
    },

    restriction() {
      if (_.isEmpty(this.restrictionRule)) {
        return false;
      }

      return (new AnalysisPatternValidator().fromRule(this.restrictionRule).validate(this.model));
    },

    errorMessages() {
      return this.error ? this._hint : null;
    },

    settings() {

      const settings = this.$store.state.settings.plataforma;

      return {
        showAnalysisPatternRange: settings ? settings.mostra_padroes_analise : true,
      };
    },

  },

  methods: {

    onInput({ detail }) {
      this.$emit('change');

      if (this.returnUnmasked) {
        return this.$emit('input', detail.unmasked);
      }

      return this.$emit('input', detail.masked);
    },

    onChange() {
      this.$emit('change');
      // Responsável por alternar o checkbox entre true/false/null
      if (this.useCheckbox && this.altCheckbox && this.model === false) {
        this.$nextTick(() => {
          this.model = null;
          this.$emit('change');
        });
      }
    },

    onEnter() {
      return this.$emit('onEnter', this.name);
    },

    hasError() {
      return this.error;
    },

    hasRestriction() {
      return this.restriction;
    },

    isEmpty() {
      if (this.useCheckbox) {
        return false;
      }

      return _.isNull(this.model) || !_.isNumber(this.model);
    },

    focus() {

      if (this.useCheckbox) {
        const checkbox = this.$refs.checkbox;

        return checkbox.$el.children[0].children[0].scrollIntoView()
      }

      return this.$refs.input.focus();
    }
  }

}
</script>
