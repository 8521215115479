<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Outras Atividades
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="loadOtherActivities"
            />
          </v-col>

          <v-spacer />

          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
              filled
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        :headers="headers"
        :items="other_activities"
        :search="filters.search"
        :items-per-page="15"
        class="elevation-1"
      >
        <template #[`item.attachments`]="{ item }">
          <v-btn
            icon
            @click="openGallery(item.attachments)"
          >
            <div v-if="!item.attachments || (item.attachments && JSON.parse(item.attachments).length < 1)">
              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-icon disabled>
                      image
                    </v-icon>
                  </div>
                </template>
                Sem Anexos
              </v-tooltip>
            </div>
            <div v-else>
              <v-icon>
                image
              </v-icon>
            </div>
          </v-btn>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasDeleteOtherActivitiesAccess"
                @click="excludeOtherActivities(item)"
              >
                <v-list-item-icon>
                  <v-icon>
                    delete
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
    <LightBox
      ref="lightbox"
      :media="pictures"
      :show-light-box="false"
    />
  </v-container>
</template>

<script>
import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue';
import moment from 'moment';
import LightBox from 'vue-image-lightbox';

export default {
  components: {
    VDateRangePicker,
    LightBox,
  },

  data() {
    
    return {
      // Loaders
      loading: true,

      // Dados brutos do backend
      other_activities: [],

      // Filtros da tabela
      filters: {
        search: '',
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },

      edit: false,
      editId: null,

      headers: [
        { text: "Rota", value: "name_route" },
        { text: "Técnico", value: "name_technical" },
        { text: "Data Hora Inicio", value: "data_start", align: 'center' },
        { text: "Data Hora Fim", value: "data_end", align: 'center' },
        { text: "Duração", value: "activity_time", align: 'center' },
        { text: "Atividade", value: "activity" },
        { text: "Motivo", value: "other_reason" },
        { text: "Local", value: "local" },
        { text: "Observação", value: "observation" },
        { text: "Imagens", value: "attachments", align: 'center' },
        { text: "Opções", value: "actions", align: 'center' },
      ],

      pictures: [],
    };
  },

  computed: {
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasDeleteOtherActivitiesAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "delete-other-activities" && o.tipo === "COMPONENTE");
    },
  },

  async mounted() {
    await this.loadOtherActivities();
  },

  methods: {
    async loadOtherActivities() {
      try {
        this.loading = true;

        let [startDate, endDate] = this.filters.dateRange;

        const { data } = await this.$axios.get('/visits/otherActivities', {
          params: {
            data_inicio: startDate,
            data_fim: endDate
          }
        });

        this.other_activities = data.map(item => {

          const diffInMillis = moment(item.data_hora_fim).diff(moment(item.data_hora_inicio));

          const diffFormatted = `${Math.floor(moment.duration(diffInMillis).asHours())}:${moment.utc(diffInMillis).format('mm')}`;

          const activityTypeMapping = {
            'ACOMPANHAMENTO_ROTA': 'Acompanhamento de Rota',
            'TREINAMENTOS': 'Treinamentos',
            'EVENTOS': 'Eventos',
            'ATIVIDADES_INTERNAS': 'Atividades Internas',

          };

          const activityType = item.atividade ? activityTypeMapping[item.atividade] || 'Tipo Desconhecido' : 'Indefinido';

          return {
            id_other_activitie: item.id_outras_visitas || null,
            id_route: item.id_rota || null,
            name_route: item.nome_rota || null,
            data_start: moment(item.data_hora_inicio).format('DD/MM/YYYY HH:mm') || null,
            data_end: item.data_hora_fim ? moment(item.data_hora_fim).format('DD/MM/YYYY HH:mm') : 'Atividade não Finalizada',
            name_technical: item.technical ? item.technical.nome : null,
            activity_time: item.data_hora_fim ? diffFormatted : 'Aguardando',
            activity: activityType,
            other_reason: item.motivo_outros || null,
            local: item.local || null,
            observation: item.observacao || null,
            latitude: item.end_latitude || null,
            longitude: item.end_longitude || null,
            attachments: item.anexos || null,
          };
        });
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as Outras Atividades!", "Atenção");

        console.warn(err);
      } finally {
        this.loading = false;
      }
    },

    async excludeOtherActivities(otherActivities) {
      
      if (!(await this.$root.$confirm('Atenção', `Deseja realmente excluir esta atividade?`, { color: 'red' }))) {
        return;
      }

      try {
        this.loading = true;

        await this.$axios.delete(`/visits/otherActivities/${otherActivities.id_other_activitie}`);
       
        this.$snotify.success("Atividade excluída com sucesso", "Sucesso");
        return await this.loadOtherActivities();
      } catch (e) {
        this.$snotify.error("Erro ao excluir Atividade", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    openGallery(images) {
      this.pictures = images ? JSON.parse(images).map(item => {
        return {
          'src': item.url || item,
          'thumb': item.url || item
        }
      }) : [];

      this.$refs.lightbox.showImage(0);
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Outras Atividades - ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
    },

    exportExcel() {
      const filename = this.getReportTitle();
      // eslint-disable-next-line
      this.$refs.report.exportExcel(this.$refs.report.getReportJson().map(({Opções, Imagens, ...rest}) => rest), filename);
    },
  }
}
</script>