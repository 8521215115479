<template>
  <div
    class="mx-6 px-16 pt-0 pb-7"
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="pt-4 mb-4 text-xs-center"
      >
        <h2 class="menu-header white--text">
          Produtores Suspensos
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-row
      justify="end"
      class="row-filters"
    >
      <v-col
        md="4"
      >
        <period-date-picker
          v-model="filters.date.input"
          @change="onDateFilter"
        />
      </v-col>
    </v-row>

    <v-card
      dark
      class="report-card"
      color="transparent"
    >
      <v-card-title>
        <v-row
          justify="end"
        >
          <v-col
            md="4"
            class="py-1"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
              dark
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        name="Produtores Suspensos"
        :headers="headers"
        :items="filteredProducers"
        :loading="loading"
        dark
        show-custom-group
        @click:row="onEditProducer"
      >
        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasInsertUpdateAccess"
                @click.stop="onEditProducer(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasUnlinkAccess"
                @click="onChangeProducerStatus(item, 'APROVADO')"
              >
                <v-list-item-icon>
                  <v-icon>person_add</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Reativar Produtor</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasUnlinkAccess"
                @click="onChangeProducerStatus(item, 'DESVINCULADO')"
              >
                <v-list-item-icon>
                  <v-icon>person_add_disabled</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Desvincular</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasDeleteAccess"
                @click="deleteProducer(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir produtor</v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="printProducer(item.id_pessoa)"
              >
                <v-list-item-icon>
                  <v-icon>print</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Imprimir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template #[`footer.prepend`]>
          <div class="text-caption text-left ml-4">
            Total
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatNumber(totalProducers) }}
            </v-chip>
          </div>
        </template>

        <template #[`item.ultimo_acesso_app`]="{ value }">
          <v-tooltip
            v-if="value"
            top
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                small
                class="ma-2"
                v-on="on"
              >
                <v-icon>
                  phone_iphone
                </v-icon>
              </v-btn>
            </template>
            {{ value }}
          </v-tooltip>
          <v-chip
            v-else
            class="ma-2"
            outlined
            x-small
          >
            -
          </v-chip>
        </template>
      </data-table>
    </v-card>

    <dialog-producer
      v-if="producerDialog.show"
      :visible="producerDialog.show"
      :producer-id.sync="producerDialog.id"
      type="SUSPENDED"
      @close="onCloseProducer"
      @onSave="onSaveProducer"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <status-producer-dialog
      ref="statusProducerDialog"
      @save="onSaveProducerStatus()"
    />

    <print-settings-dialog
      ref="print-settings"
      @print="onPrintProducer"
    />

    <v-overlay
      v-model="loading"
      absolute
    >
      <v-card-text>
        Carregando
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import printJS from "print-js";
import StatusProducerDialog from "@/Domains/Producer/Management/Components/Dialogs/StatusProducerDialog.vue";
import DialogProducer from "@/Domains/Producer/Management/Components/Dialogs/InfoProducer.vue";
import PeriodDatePicker from "@/Support/Components/PeriodDatePicker.vue";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";

export default {
  name: "SuspendedProducer",

  components: {
    PeriodDatePicker,
    StatusProducerDialog,
    DialogProducer,
    PrintSettingsDialog
  },

  data() {
    return {
      loading: false,

      filters: {
        search: '',

        date: {
          input: "trimestral",
          range: [
            moment()
              .subtract(2, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            moment()
              .endOf("month")
              .format("YYYY-MM-DD"),
          ],
        },
      },

      headers: [
        { text: "Último acesso APP", value: "ultimo_acesso_app", align: "center", formatter: value => this.formatDate(value, 'DD/MM/YYYY'), show: false },
        { text: "C. Laticínio", value: "codigo_laticinio", align: "center", show: false },
        { text: 'Produtor', value: 'nome', align: 'start' },
        { text: "CCS", value: "ccs", align: "center", show: false },
        { text: "CPP", value: "cbt", align: "center", show: false },
        { text: "Gordura", value: "gordura", align: "center", show: false },
        { text: "Proteina", value: "proteina", align: "center", show: false },
        { text: "Sif", value: "sif", align: "center", show: false },
        { text: "Vol.Leite", value: "litros", align: "center", show: false },
        { text: 'Região', value: 'regiao' },
        { text: "Rota Principal de Coleta", value: "rota", align: "center", show: false },
        { text: "Km Industria", value: "distancia_industria", align: "center" },
        { text: 'Motivo', value: 'outros_motivos' },
        { text: 'Data de Suspensão', value: 'data_entrada', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: "Celular", value: "celular", show: false },
        { text: "Telefone", value: "telefone", show: false },
        { text: "CPF/CNPJ do Titular", value: "cnpj_cpf", align: "center", show: false },
        { text: "Nº de Identidade (RG)", value: "rg", align: "center", show: false },
        { text: "Nº Nirf", value: "nirf", align: "center", show: false },
        { text: "CIDASC/IMA", value: "cidasc", align: "center" },
        { text: "Código NRP", value: "nrp", align: "center", show: false },
        { text: "Inscrição Estadual", value: "inscricao_estadual", align: "center", show: false },
        { text: "Número de Ordenhas", value: "numero_ordenha", align: "center", show: false },
        { text: "Capacidade dos Resfriadores (L)", value: "capacidade_resf_inte", align: "center", show: false },
        { text: "Janela de Coleta - Inicio", value: "hora_inicio_coleta", align: "center", show: false },
        { text: "Janela de Coleta - Final", value: "hora_fim_coleta", align: "center", show: false },
        { text: "Realizado Coleta à Cada (H)", value: "frequencia_coleta", align: "center", show: false },
        { text: "Data de Nascimento", value: "data_nascimento", align: "center", formatter: value => this.formatDate(value, 'DD/MM/YY'), show: false },
        { text: "Cadastro de Produtores rurais", value: "cad_pro", align: "center", show: false },
        { text: "Cooperativa", value: "cooperativa", align: "center", show: false },
        { text: "CEP", value: "end_cep", align: "center", show: false },
        { text: "Estado", value: "end_estado", align: "center" },
        { text: "Cod. Cidade", value: "end_cidade_codigo", align: "center", show: false },
        { text: "Cidade", value: "end_cidade", align: "center", show: false },
        { text: "Rua", value: "end_rua", align: "center", show: false },
        { text: "Número", value: "end_numero", align: "center", show: false },
        { text: "Bairro", value: "end_bairro", align: "center", show: false },
        { text: "Latitude", value: "end_latitude", align: "center", show: false },
        { text: "Longitude", value: "end_longitude", align: "center", show: false },
        { text: "Dia do Pagamento", value: "dia_pagamento", align: "center", show: false },
        { text: "Banco", value: "banco", align: "center", show: false },
        { text: "Forma de pagamento", value: "forma_pagamento", align: "center", show: false },
        { text: "Configuração de Taxas", value: "config_taxas_local", align: "center", show: false },
        { text: "E-mail", value: "email", align: "center", show: false },
        { text: "Tipo de Ordenha", value: "tipo_ordenha", align: "center", show: false },
        { text: "Código SDA", value: "codigo_sda", align: "center", show: false },
        { text: "Data Última Coleta", value: "data_ultima_coleta", align: "center", formatter: value => this.formatDate(value, 'DD/MM/YYYY'), show: false },
        { text: "Data de Vinculação", value: "data_entrada_laticinio", align: "center", formatter: value => this.formatDate(value, 'DD/MM/YYYY'), show: false },
        { text: "Acessos", value: "pessoa_acesso", align: "center", sortable: false, show: false, show: false },
        { text: '', altText: 'Opções', value: 'action', sortable: false, align: 'end' },
      ],

      producers: [],

      producerDialog: {
        show: false,
        id: null
      },
    };
  },

  computed: {
    filteredProducers() {
      if (!this.filters.search) {
        return this.producers;
      }

      const search = this.filters.search.toUpperCase().trim();

      return this.producers.filter(producer => {
        const searchFilter = !search || JSON.stringify(Object.values(producer)).toUpperCase().includes(search);

        return searchFilter
      });
    },

    totalProducers() {
      return this.filteredProducers.length;
    },

    /**
     * Permissões de tela
     */
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasInsertUpdateAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-insert-edit" && o.tipo === "COMPONENTE");
    },

    hasDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-delete" && o.tipo === "COMPONENTE");
    },

    hasUnlinkAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-unlink" && o.tipo === "COMPONENTE");
    },
  },

  mounted() {
    this.loadProducers();
  },

  methods: {
    async loadProducers() {
      try {
        this.loading = true;

        const [startDate, endDate] = this.filters.date.range;

        let { data } = await this.$axios.post(
          `/produtores/listaProdutoresSuspensos`,
          {
            data_inicio: startDate,
            data_fim: endDate,
          }
        );

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.producers = data.map(producer => {
          producer.pessoa_acesso = producer.pessoa_acesso ? JSON.parse(producer.pessoa_acesso).map(p => p.nome_usuario).join(', ') : ''
          return producer;
        });
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event;

      this.loadProducers();
    },

    /**
     * Abre o dialog de edição de produtor
     */
    onEditProducer(producer) {
      if (!this.hasInsertUpdateAccess) {
        return;
      }

      this.producerDialog = {
        show: true,
        id: producer.id_pessoa
      };
    },

    onChangeProducerStatus(producer, status) {
      const Title = {
        'DESVINCULADO': 'Desvincular Produtor',
        'APROVADO': 'Reativar Produtor',
      };

      return this.$refs.statusProducerDialog.show({
        title: Title[status] || 'Status Produtor',
        producer: {
          id: producer.id_pessoa,
          name: producer.nome,
        },
        form: {
          status: status,
          params: {
            showStatusSelect: false,
            minInputDateLimit: null,
            maxInputDateLimit: null,
          },
        }
      });
    },

    onSaveProducerStatus() {
      this.loadProducers();
    },

    /**
     * Fecha dialog de edição de produtor
     */
    onCloseProducer() {
      this.producerDialog = {
        show: false,
        id: null
      };
    },

    /**
     * Fecha dialog de edição e carrega os produtores
     */
    onSaveProducer() {
      this.producerDialog = {
        show: false,
        id: null
      };
      this.loadProducers();
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.date.range;
      return `Produtores Suspensos - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    printProducer(id) {
      this.$refs['print-settings'].show({
        item: {
          id
        },
        params: [
          { title: 'Endereço', key: 'mostra_endereco', value: true },
          { title: 'Dados de Pagamento', key: 'mostra_dados_pagamento', value: true },
          { title: 'Dados de Produção', key: 'mostra_dados_producao', value: true },
          { title: 'Checklist Prospecção', key: 'mostra_checklist', value: false },
          { title: 'Documentos assinados', key: 'mostra_documentos_assinados', value: false },
          { title: 'Preço/Negociação', key: 'mostra_negociacoes_preco', value: false },
          { title: 'Visita Prospecção', key: 'mostra_ultima_visita', value: false },
          { title: 'Dados de cônjuges', key: 'mostra_dados_conjuges', value: false },
        ]
      });
    },

    async onPrintProducer({ item,  params }) {
      try {
        this.loading = true;

        if (_.isEmpty(item.id)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/produtores/printDetalheProdutor`,
          {
            id_produtor: item.id,
            params
          }
        );

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    /**
     * Método para exclusão do produtor
     * @param {Object} producer
     */
    async deleteProducer(producer) {
      try {
        if (!(await this.$root.$confirm('Atenção', `Realmente deseja <b> Excluir </b>o(a) produtor(a) <b>${producer.nome}</b> ?`, { color: 'red' }))) {
          return;
        }

        this.loading = true;

        if (!producer.id_pessoa) {
          throw "Produtor não informado";
        }

        const data = await this.$axios.post(
          `/produtores/deleteProdutor`,
          this.$qs.stringify({
            id_produtor: producer.id_pessoa,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        if (data.codigo === 0) {
          throw "Erro ao deletar produtor";
        }

        this.$snotify.success("Produtor deletado com sucesso!", "Sucesso");
        this.loadProducers();
      } catch (error) {
        console.log(error);

        this.$snotify.error("Erro ao deletar produtor ", "Atenção!!");
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format)
  },
};
</script>
