<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#tab-payment-methods">
        Formas de Pagamento
      </v-tab>
      <v-tab href="#tab-installment-methods">
        Formas de Parcelamento
      </v-tab>
      
      <v-tab-item value="tab-payment-methods">
        <payment-methods-settings />
      </v-tab-item>
      <v-tab-item value="tab-installment-methods">
        <installment-methods-settings />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import PaymentMethodsSettings from "@/Domains/Financial/Settings/Views/PaymentMethodsSettings.vue";
import InstallmentMethodsSettings from "@/Domains/Financial/Settings/Views/InstallmentMethodsSettings.vue";

export default {
  
  components: {
    PaymentMethodsSettings,
    InstallmentMethodsSettings,
  },
 
  data() {
    return {
      tab: "tab-payment-methods",
    };
  },
};
</script>
